import React, { Component } from "react";
import { connect } from "react-redux";
import Layout from "../../../Components/Layout";
import { useHistory } from "react-router-dom";

import DatePicker from "react-datepicker";
import { CalendarIcon, CheckIcon } from "@heroicons/react/outline";
import SelectField from "../../../Components/SelectField";

import {
  timeTrackerData,
  timeTrackerDataWithoutProject,
  customReportWeekglyGraphdata,
  weeklyChartActivity,
  getProjectList,
  requestProjectChange,
  getShowcaseEntries,
  getEmployeeMoods,
  getOfflineData,
} from "../../../services/timetracker/actions";
import {
  fetchEmpLoginDetail,
  getUserProject,
} from "../../../services/employee/actions";

import moment from "moment";
import { toast } from "react-toastify";
import ReactHighcharts from "react-highcharts";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Divider } from "@material-ui/core";
import { Modaltracker } from "../modalTracker";
import { withTranslation } from "react-i18next";
import { ModalProjectSelect } from "./modalProjectSelect";
import ideltTime from "../../../assets/idelTime.png";
import ModalShowCaseEntry from "../modalShowCaseEntry";
class EmployeeTimeTracker extends Component {
  constructor() {
    super();
    this.state = {
      startDate: new Date(),
      selectedDate: new Date(),
      employeename: "",
      projectname: "",
      currentDate: new Date(),
      lastDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
      Timetracker: [],
      Timetrackerkeys: [],
      OfflineTimetracker: [],
      OfflineTimetrackerkeys: [],
      screenshotkpis: [],
      timeArray: [],
      percentages: [],
      weekGraphDate: [],
      weekGraphActIndex: [],
      weeklychartData: [],
      isScreenshotSelectActive: false,
      isScreenshotSelectActiveOffline: false,
      updateProjectStage: false,
      selectedSSid: [],
      showProjModal: false,
      startTimeSelect: [],
      endTimeSelect: [],
      selectedProject: "",
      userName: "",
      isShowcaseModalActive: false,
      showcaseEntriesList: [],
      isShowAllEntries: false,
      moodData: "",
    };
  }

  componentDidMount() {
    this.props.getOfflineData().then((res) => {
      this.setState({
        OfflineTimetracker: Object.values(res.value.data.data),
        OfflineTimetrackerkeys: Object.keys(res.value.data.data),
      });
    });
    this.props.fetchEmpLoginDetail().then((data) => {
      if (data.value.status == 200) {
        this.setState({ employeename: data.value.data.user_uuid }, () => {
          this.fetchTimeTrackerData();
          this.fetchWeeklyGraphData();

          this.props
            .getEmployeeMoods(
              moment(this.state.selectedDate).format("YYYY-MM-DD")
            )
            .then((res) => {
              if (
                res.value.status === 200 &&
                res.value.data.data !== "no data present"
              ) {
                this.setState({
                  moodData: res.value.data.data,
                });
              }
            });
          this.props
            .getShowcaseEntries(
              moment(this.state.selectedDate).format("YYYY-MM-DD"),
              `user&user_id=${data.value.data.user_uuid}`
            )
            .then((res) => {
              if (res.value.status === 200 && !res.value.data.message) {
                this.setState({
                  userName: data.value.data.username,
                  showcaseEntriesList: res.value.data.data,
                });
              }
            });
        });
      }
    });
    this.props.getProjectList();
    this.props.getUserProject();
    this.setState({ selectedDate: new Date(this.props.match.params.date) });
  }

  fetchTimeTrackerData = () => {
    if (this.state.projectname == "") {
      this.props
        .timeTrackerDataWithoutProject(
          moment(this.state.selectedDate).format("YYYY-MM-DD"),
          this.state.employeename
        )
        .then((res) => {
          if (res.value.status == 200) {
            this.setState({
              screenshotkpis: res.value.data.track_report,
            });
            let response = res.value.data;
            delete response["track_report"];

            const hourlyPercantageArray = Object.values(res.value.data).map(
              (item, idx) => {
                return item.hourly_percentage;
              }
            );

            let percentages = hourlyPercantageArray.map(
              (item, idx) => Math.round(item * 100) / 100
            );
            let modifiedHours = Object.keys(response).map(Number);
            let timingArray = modifiedHours.map((num) => {
              if (num > 12) {
                let hour = num - 12;
                return `${hour} PM`;
              } else if (num === 12) {
                return "12 PM";
              } else if (num === 0) {
                return "12 AM";
              } else {
                return `${num} AM`;
              }
            });
            this.setState({
              timeArray: timingArray,
              percentages: percentages,
              Timetracker: Object.values(res.value.data),
              Timetrackerkeys: Object.keys(response),
            });
          } else {
            toast("Something went wrong");
          }
        });
    } else {
      this.props
        .timeTrackerData(
          moment(this.state.selectedDate).format("YYYY-MM-DD"),
          this.state.projectname,
          this.state.employeename
        )
        .then((res) => {
          if (res.value.status == 200) {
            this.setState({
              screenshotkpis: res.value.data.track_report,
            });
            let response = res.value.data;
            delete response["track_report"];

            const hourlyPercantageArray = Object.values(res.value.data).map(
              (item, idx) => {
                return item.hourly_percentage;
              }
            );

            let percentages = hourlyPercantageArray.map(
              (item, idx) => Math.round(item * 100) / 100
            );
            let modifiedHours = Object.keys(response).map(Number);
            let timingArray = modifiedHours.map((num) => {
              if (num > 12) {
                let hour = num - 12;
                return `${hour} PM`;
              } else if (num === 12) {
                return "12 PM";
              } else if (num === 0) {
                return "12 AM";
              } else {
                return `${num} AM`;
              }
            });
            this.setState({
              timeArray: timingArray,
              percentages: percentages,
              Timetracker: Object.values(res.value.data),
              Timetrackerkeys: Object.keys(response),
            });
          } else {
            toast("Something went wrong");
          }
        });
    }
  };

  fetchWeeklyGraphData = () => {
    let fromdate = moment(this.state.lastDate).format("YYYY-MM-DD");
    let todate = moment(this.state.currentDate).format("YYYY-MM-DD");

    if (this.state.employeename.length != 0) {
      this.props
        .weeklyChartActivity(todate, this.state.employeename)
        .then((res) => {
          this.setState({ weeklychartData: res.value.data });
        });

      this.props.customReportWeekglyGraphdata(fromdate, todate).then((res) => {
        if (this.state.projectname.length != 0) {
          let username = null;
          let employees =
            this.props.projects && this.props.projects.AllEmployees;

          for (var i = 0; i < employees.length; i++) {
            if (employees[i].user_login_as == this.state.employeename) {
              username = employees[i];
            }
          }

          let projectDetails = res.value.data.all_project_data;
          let project = projectDetails.find(
            (pro) => pro.project_name === this.state.projectname
          );

          let userName = `${username.user_first_name} ${username.user_last_name}`;
          let z = project.users.filter((y) => {
            if (y.user === userName) {
              return y;
            }
          });

          let toBeconvertedPercVal = z.map((hou) =>
            Math.round(hou.time_logged / 60)
          );
          this.setState({
            weekGraphActIndex: toBeconvertedPercVal.map(Number),
            weekGraphDate: z.map((hou) => hou.date),
          });
        }
        // else {
        //     let username = null;

        //     let employees = this.props.projects && this.props.projects.AllEmployees;

        //     for (var i = 0; i < employees.length; i++) {
        //         if (employees[i].user_login_as == this.state.employeename) {
        //             username = employees[i];
        //         }
        //     }
        //     let filtered = res.value.data.all_project_data.map((each) => each.users);
        //     const userName = `${username.user_first_name} ${username.user_last_name}`;
        //     let ress = filtered.map((each) => {
        //         let x = each.filter((hou) => {
        //             if (hou.user === userName) {
        //                 return Math.round(hou.time_logged / 60);
        //             }
        //         });
        //         return x;
        //     });

        //     let dateandTime = {};
        //     let x = ress.map((e) => {
        //         if (e.length !== 0) {
        //             let ress = e.map((eng) => {
        //                 if (eng.date.toString() in dateandTime) {
        //                     dateandTime[eng.date] = dateandTime[eng.date] + eng.time_logged;
        //                 } else {
        //                     dateandTime[eng.date] = eng.time_logged;
        //                 }
        //             });
        //         }
        //     });
        //     let date = Object.keys(dateandTime);
        //     let hours = Object.values(dateandTime).map((hou) => Math.round(hou / 60));

        //     this.setState({
        //         weekGraphDate: date,
        //         weekGraphActIndex: hours,
        //     });
        // }
        // }
        else {
          let username = null;

          let employees =
            this.props.projects && this.props.projects.AllEmployees;

          if (employees) {
            for (var i = 0; i < employees.length; i++) {
              if (employees[i].user_login_as == this.state.employeename) {
                username = employees[i];
              }
            }
          }
          let filtered = res.value.data.all_project_data
            ? res.value.data.all_project_data.map((each) => each.users)
            : [];
          const userName = `${username ? username.user_first_name : ""} ${
            username ? username.user_last_name : ""
          }`;
          let ress = filtered.map((each) => {
            let x = each.filter((hou) => {
              if (hou.user === userName) {
                return Math.round(hou.time_logged / 60);
              }
            });
            return x;
          });

          let dateandTime = {};
          let x = ress.map((e) => {
            if (e.length !== 0) {
              let ress = e.map((eng) => {
                if (eng.date.toString() in dateandTime) {
                  dateandTime[eng.date] =
                    dateandTime[eng.date] + eng.time_logged;
                } else {
                  dateandTime[eng.date] = eng.time_logged;
                }
              });
            }
          });

          let date = Object.keys(dateandTime);
          let hours = Object.values(dateandTime).map((hou) =>
            Math.round(hou / 60)
          );

          this.setState({
            weekGraphDate: date,
            weekGraphActIndex: hours,
          });
        }
      });
    }
  };

  onProjectScreenshotChange = (e) => {
    const selectedProjectName=e.target.value
    this.setState({ project: selectedProjectName }, () => {
      this.setState({projectname:selectedProjectName},()=>{
        if (selectedProjectName == "") {
          this.fetchWeeklyGraphData();
          let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
          this.props
            .timeTrackerDataWithoutProject(date, this.state.employeename)
            .then((res) => {
              this.setState({
                screenshotkpis: res.value.data.track_report,
              });
              let response = res.value.data;
              delete response["track_report"];
              const hourlyPercantageArray = Object.values(res.value.data).map(
                (item, i) => {
                  return item.hourly_percentage;
                }
              );
              let percentages = hourlyPercantageArray.map(
                (x) => Math.round(x * 100) / 100
              );
              let modifiedHours = Object.keys(response).map(Number);
              let timingArray = modifiedHours.map((num) => {
                if (num > 12) {
                  let hour = num - 12;
                  return `${hour} PM`;
                } else if (num === 12) {
                  return "12 PM";
                } else if (num === 0) {
                  return "12 AM";
                } else {
                  return `${num} AM`;
                }
              });
              this.setState({
                percentages: percentages,
                timingArray: timingArray,
                Timetracker: Object.values(res.value.data),
                Timetrackerkeys: Object.keys(response),
              });
            });
        } else {
          this.fetchWeeklyGraphData();
          let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
          this.props
            .timeTrackerData(
              date,
              selectedProjectName,
              this.state.employeename
            )
            .then((res) => {
              this.setState({
                screenshotkpis: res.value.data.track_report,
              });
              let response = res.value.data;
              delete response["track_report"];
              const hourlyPercantageArray = Object.values(res.value.data).map(
                (item, i) => {
                  return item.hourly_percentage;
                }
              );
              let percentages = hourlyPercantageArray.map(
                (x) => Math.round(x * 100) / 100
              );
              let modifiedHours = Object.keys(response).map(Number);
              let timingArray = modifiedHours.map((num) => {
                if (num > 12) {
                  let hour = num - 12;
                  return `${hour} PM`;
                } else if (num === 12) {
                  return "12 PM";
                } else if (num === 0) {
                  return "12 AM";
                } else {
                  return `${num} AM`;
                }
              });
              this.setState({
                percentages: percentages,
                timingArray: timingArray,
                Timetracker: Object.values(res.value.data),
                Timetrackerkeys: Object.keys(response),
              });
            });
        }
      })
    });
  };

  HandleTimeSelect = (items, index, time_min, value, index1) => {
    console.log(items, index, time_min, value, index1);
    let time = moment(
      `${
        parseInt(this.state.OfflineTimetrackerkeys[index]) +
        Math.floor(parseInt(time_min) / 60)
      } : ${parseInt(time_min) % 60}`,
      ["HH:mm"]
    ).format("HH:mm");

    let time1 = moment(
      `${
        parseInt(this.state.OfflineTimetrackerkeys[index]) +
        Math.floor(parseInt(value[index1]) / 60)
      } : ${parseInt(value[index1]) % 60}`,
      ["HH:mm"]
    ).format("HH:mm");
    let screenshotIdx = this.state.selectedSSid.indexOf(time);
    if (screenshotIdx === -1) {
      this.setState({
        selectedSSid: [...this.state.selectedSSid, time],
        startTimeSelect: [...this.state.startTimeSelect, time],
        endTimeSelect: [...this.state.endTimeSelect, time1],
      });
    } else {
      let tmpArr = [...this.state.selectedSSid];
      let tmpArrStartTime = [...this.state.startTimeSelect];
      let tmpArrEndTime = [...this.state.endTimeSelect];
      tmpArr.splice(screenshotIdx, 1);
      tmpArrStartTime.splice(screenshotIdx, 1);
      tmpArrEndTime.splice(screenshotIdx, 1);
      this.setState({
        selectedSSid: tmpArr,
        startTimeSelect: tmpArrStartTime,
        endTimeSelect: tmpArrEndTime,
      });
    }
    if (this.state.selectedProject === "") {
      this.setState({
        selectedProject: {
          name: items.project,
          id: parseInt(items.project_id),
        },
      });
    }
    setTimeout(() => {
      console.log(this.state);
    });
  };
  handleScreenshotDateChange = (date) => {
    if (date == "" || date == undefined) {
      if (this.state.employeename == "") {
      } else if (this.state.projectname == "") {
        let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
        this.props
          .timeTrackerDataWithoutProject(date, this.state.employeename)
          .then((res) => {
            const selectedProjectName=this.state.project
            this.setState({
              screenshotkpis: res.value.data.track_report,
              projectname:selectedProjectName
            });
            let response = res.value.data;
            delete response["track_report"];
            const hourlyPercantageArray = Object.values(res.value.data).map(
              (item, i) => {
                return item.hourly_percentage;
              }
            );
            let percentages = hourlyPercantageArray.map(
              (x) => Math.round(x * 100) / 100
            );
            let modifiedHours = Object.keys(response).map(Number);
            let timingArray = modifiedHours.map((num) => {
              if (num > 12) {
                let hour = num - 12;
                return `${hour} PM`;
              } else if (num === 12) {
                return "12 PM";
              } else if (num === 0) {
                return "12 AM";
              } else {
                return `${num} AM`;
              }
            });
            this.setState({
              percentages: percentages,
              timingArray: timingArray,
              Timetracker: Object.values(res.value.data),
              Timetrackerkeys: Object.keys(response),
            });
          });
      } else {
        let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
        this.props
          .timeTrackerData(
            date,
            this.state.projectname,
            this.state.employeename
          )
          .then((res) => {
            const selectedProjectName=this.state.projectname
            this.setState({
              screenshotkpis: res.value.data.track_report,
              projectname:selectedProjectName
            });
            let response = res.value.data;
            delete response["track_report"];
            const hourlyPercantageArray = Object.values(res.value.data).map(
              (item, i) => {
                return item.hourly_percentage;
              }
            );
            let percentages = hourlyPercantageArray.map(
              (x) => Math.round(x * 100) / 100
            );
            let modifiedHours = Object.keys(response).map(Number);
            let timingArray = modifiedHours.map((num) => {
              if (num > 12) {
                let hour = num - 12;
                return `${hour} PM`;
              } else if (num === 12) {
                return "12 PM";
              } else if (num === 0) {
                return "12 AM";
              } else {
                return `${num} AM`;
              }
            });
            this.setState({
              percentages: percentages,
              timingArray: timingArray,
              Timetracker: Object.values(res.value.data),
              Timetrackerkeys: Object.keys(response),
            });
          });
      }
    } else {
      this.setState({ selectedDate: date }, () => {
        if (this.state.employeename == "") {
        } else if (this.state.projectname == "") {
          let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
          this.props
            // .timeTrackerDataWithoutProject(date, this.state.employeename)
            .timeTrackerDataWithoutProject(date, this.state.employeename)
            .then((res) => {
              const selectedProjectName=this.state.projectname
              this.setState({
                screenshotkpis: res.value.data.track_report,
                projectname:selectedProjectName
              });
              let response = res.value.data;
              delete response["track_report"];
              const hourlyPercantageArray = Object.values(res.value.data).map(
                (item, i) => {
                  return item.hourly_percentage;
                }
              );
              let percentages = hourlyPercantageArray.map(
                (x) => Math.round(x * 100) / 100
              );
              let modifiedHours = Object.keys(response).map(Number);
              let timingArray = modifiedHours.map((num) => {
                if (num > 12) {
                  let hour = num - 12;
                  return `${hour} PM`;
                } else if (num === 12) {
                  return "12 PM";
                } else if (num === 0) {
                  return "12 AM";
                } else {
                  return `${num} AM`;
                }
              });
              this.setState({
                percentages: percentages,
                timingArray: timingArray,
                Timetracker: Object.values(res.value.data),
                Timetrackerkeys: Object.keys(response),
              });
            });
        } else {
          let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
          this.props
            .timeTrackerData(
              date,
              this.state.projectname,
              this.state.employeename
            )
            .then((res) => {
              const selectedProjectName=this.state.projectname
              this.setState({
                screenshotkpis: res.value.data.track_report,
                projectname:selectedProjectName
              });
              let response = res.value.data;
              delete response["track_report"];
              const hourlyPercantageArray = Object.values(res.value.data).map(
                (item, i) => {
                  return item.hourly_percentage;
                }
              );
              let percentages = hourlyPercantageArray.map(
                (x) => Math.round(x * 100) / 100
              );
              let modifiedHours = Object.keys(response).map(Number);
              let timingArray = modifiedHours.map((num) => {
                if (num > 12) {
                  let hour = num - 12;
                  return `${hour} PM`;
                } else if (num === 12) {
                  return "12 PM";
                } else if (num === 0) {
                  return "12 AM";
                } else {
                  return `${num} AM`;
                }
              });
              this.setState({
                percentages: percentages,
                timingArray: timingArray,
                Timetracker: Object.values(res.value.data),
                Timetrackerkeys: Object.keys(response),
              });
            });
        }
        if (this.state.employeename) {
          this.setState({ showcaseEntriesList: [] });
          this.props
            .getShowcaseEntries(
              moment(this.state.selectedDate).format("YYYY-MM-DD"),
              `user&user_id=${this.state.employeename}`
            )
            .then((res) => {
              if (res.value.status === 200 && !res.value.data.message) {
                this.setState({
                  showcaseEntriesList: res.value.data.data,
                });
              }
            });
          let path =
            "/emp/timetracker/" +
            moment(this.state.selectedDate).format("YYYY-MM-DD");
          this.props.history.push(path);
          // console.log("onclicked");
        }
      });
    }
  };

  progressActivity = (items) => {
    let activity = [];
    let lengthImage = items && items.length > 0 ? items.length : 0;
    lengthImage = lengthImage < 5 ? lengthImage : 5;
    for (let i = 0; i < lengthImage; i++) {
      activity.push(
        <div>
          <Grid className="row" style={{ borderRadius: "2px" }}>
            <img
              alt=""
              src={items[i]}
              style={{ width: "24px", height: "24px" }}
            />
          </Grid>
        </div>
      );
    }

    return activity;
  };

  IdleTime = (
    time,
    min,
    time_min,
    text,
    items,
    index,
    value,
    index1,
    hourly_ss_img,
    hourly_thubm_img
  ) => {
    let row = [require("../../../assets/idelTime.png")];
    return (
      // <Grid item xs={12} sm={5} md={2}>
      //   <div
      //     style={{ width: "163px" }}
      //     className={
      //       "relative" +
      //       (this.state.isScreenshotSelectActiveOffline
      //         ? " hover:cursor-pointer"
      //         : "")
      //     }
      //     onClick={
      //       // this.state.isScreenshotSelectActiveOffline
      //       //   ?
      //       () => {
      //         console.log("clicked on tile");
      //         this.HandleTimeSelect(items, index, min, value, index1);
      //       }
      //       // : () => {}
      //     }
      //   >
      //     {this.state.isScreenshotSelectActiveOffline ? (
      //       <div className="absolute z-10 top-1 right-1 w-5 h-5 rounded-full bg-gray">
      //         <CheckIcon
      //           className={
      //             this.state.selectedSSid.includes(
      //               moment(
      //                 `${
      //                   parseInt(this.state.OfflineTimetrackerkeys[index]) +
      //                   Math.floor(parseInt(min) / 60)
      //                 } : ${parseInt(min) % 60}`,
      //                 ["HH:mm"]
      //               ).format("HH:mm")
      //             )
      //               ? "text-primary font-bold"
      //               : "text-white font-bold"
      //           }
      //         />
      //       </div>
      //     ) : null}
      //     <div className="idel_time">
      //       <p className="idel_text">{text}</p>
      //     </div>
      //   </div>
      // </Grid>
      <div>
        <Grid item xs={12} sm={5} md={2}>
          <div
            onClick={
              this.state.isScreenshotSelectActive ||
              this.state.isScreenshotSelectActiveOffline
                ? () => {
                    this.HandleTimeSelect(
                      items,
                      index,
                      time_min,
                      value,
                      index1
                    );
                  }
                : () => {}
            }
            style={{
              cursor: "pointer",
            }}
          >
            <Modaltracker
              image={items}
              openDisabled={
                this.state.isScreenshotSelectActive ||
                this.state.isScreenshotSelectActiveOffline
              }
              hourly_thubm_image={hourly_thubm_img}
              hourly_ss_image={hourly_ss_img}
              ActivityIndex={this.state.EmployeeTiming}
              selectedDate={this.state.selectedDate}
              userName={this.state.employeename}
              Timekey={this.state.OfflineTimetrackerkeys[index]}
              time_min={time_min}
              value={value[index1]}
              ReportName={"user"}
              active={{
                key_count: items.key_count,
                mouse_count: items.mouse_count,
                idle_time: items.idle_time,
                item: items,
              }}
            />
          </div>
        </Grid>
      </div>
    );
  };

  HandleProjectChange = (selProj) => {
    let iDs = "";
    iDs += this.state.selectedSSid.map((id, idx) => {
      return id;
    });
    let startTimeArr = [...this.state.startTimeSelect];
    let endTimeArr = [...this.state.endTimeSelect];
    const data = {
      type: "project_change",
      start_time: startTimeArr.sort()[0],
      end_time: endTimeArr.sort()[endTimeArr.length - 1],
      project_name: this.state.selectedProject.name,
      project_id: this.state.selectedProject.id,
      change_project_name: selProj.project_name,
      change_project_id: selProj.id,
      start: moment(this.state.selectedDate).format("YYYY-MM-DD"),
      activity: "workahub",
      ids: iDs,
    };
    this.props.requestProjectChange(data).then((res) => {
      if (res.value.status === 200) {
        this.fetchTimeTrackerData();
      }
      this.setState({
        showProjModal: false,
        selectedSSid: [],
        selectedProject: "",
        startTimeSelect: [],
        endTimeSelect: [],
      });
    });
  };

  HandleOfflineTimeRequest = () => {
    let startTimeArr = [...this.state.startTimeSelect];
    let endTimeArr = [...this.state.endTimeSelect];
    const data = {
      start_time: startTimeArr.sort()[0],
      end_time: endTimeArr.sort()[endTimeArr.length - 1],
      activity: "workahub",
      project_name:
        this.state.selectedProject.name === ""
          ? "workahub"
          : this.state.selectedProject.name,
      project_id: isNaN(this.state.selectedProject.id)
        ? 1
        : this.state.selectedProject.id,
      startDate: moment(this.state.selectedDate).format("YYYY-MM-DD"),
      type: "offline",
    };
    this.props.requestProjectChange(data).then((res) => {
      if (res.value.status === 200) {
        this.fetchTimeTrackerData();
      }
      this.setState({
        selectedSSid: [],
        selectedProject: "",
        startTimeSelect: [],
        endTimeSelect: [],
      });
    });
  };
  render() {
    const { t } = this.props;
    const emojiList = [
      <span>"&#128545;" {t("timeTracker.angry")}</span>,
      <span>"&#128532;" {t("timeTracker.sad")}</span>,
      <span>"&#128528;" {t("timeTracker.neutral")}</span>,
      <span>"&#128578;" {t("timeTracker.positive")}</span>,
      <span>"&#128512;" {t("timeTracker.happy")}</span>,
    ];
    let userRole = localStorage.getItem("roleUser").toLocaleLowerCase();
    let userName = localStorage.getItem("userName");
    let weekGraphActIndexConverted =
      this.state.weekGraphActIndex &&
      this.state.weekGraphActIndex.map((x) => Math.round(x * 100) / 100);
    let newWeekGraphActIndexConverted =
      this.state.weeklychartData &&
      this.state.weeklychartData.map((x) => Math.round(x.duration) / 60);
    let newWeekGraphActivityDate =
      this.state.weeklychartData &&
      this.state.weeklychartData.map((x) => x.date);
    let project =
      this.props.empDashboard && this.props.empDashboard.UserProjects;
    let projectList =
      project == undefined || project == ""
        ? ""
        : project.projects[0].projects_list.map((el) => ({
            name: el,
          }));
    let screenshotkpis = this.state.screenshotkpis;
    let projectListWithIds =
      this.props.timetracker && this.props.timetracker.projectList;
    const hourlyChart = {
      chart: {
        type: "column",
        height: 250,
        style: {
          fontFamily: "Lato",
          fontSize: "15px",
        },
      },
      title: {
        text: "",
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                let [hours, AMorPM] = this.category.split(" ");
                if (hours === "12") {
                  hours = "0";
                }
                if (AMorPM === "PM") {
                  hours = parseInt(hours, 10) + 12;
                }
                // smoothScroll(document.getElementById(hours))
              },
            },
          },
        },
        column: {
          zones: [
            {
              value: 25,
              color: "#FF0404",
            },
            {
              value: 50,
              color: "#B1B1B1",
            },
            {
              color: "#684D94",
            },
          ],
          pointPadding: 0.1,
          borderWidth: 0,
        },
      },
      xAxis: {
        categories: this.state.timeArray,
        crosshair: true,
      },
      yAxis: [
        {
          labels: {
            format: "{value} %",
          },
          title: {
            text: "Hourly Percentage",
          },
          max: 100,
        },
      ],
      tooltip: {
        valueSuffix: " %",
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          showInLegend: false,
          name: "Hourly Percentage",
          data: this.state.percentages,
        },
      ],
    };

    const weekChartsData = {
      chart: {
        type: "column",
        height: 250,
        zoomType: "x",
        style: {
          fontFamily: "Lato",
          fontSize: "15px",
        },
      },
      title: {
        text: "",
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: (function (component) {
                return function () {
                  component.setState(
                    {
                      selectedDate: new Date(this.category),
                    },
                    () => {
                      component.handleScreenshotDateChange();
                    }
                  );
                };
              })(this),
            },
          },
        },
        column: {
          zones: [
            {
              value: 4,
              color: "#FF0404",
            },
            {
              value: 8,
              color: "#B1B1B1",
            },
            {
              color: "#684D94",
            },
          ],

          pointPadding: 0.1,
          borderWidth: 0,
        },
      },
      xAxis: {
        categories: newWeekGraphActivityDate,
        type: "datetime",
        labels: {
          formatter: function () {
            return moment(this.value).format("ddd");
          },
        },
      },
      yAxis: [
        {
          title: {
            text: "Weekly Work Hours",
          },
        },
      ],
      tooltip: {
        formatter: function () {
          var tooltip;
          let x =
            "<b>" +
            moment(this.x).format("ddd") +
            "</b>" +
            ", " +
            "<b>" +
            moment(this.x).format("Do MMMM YYYY") +
            "</b><br/>";
          let z =
            '<span style="color:' +
            this.series.color +
            '">' +
            "Hours " +
            "</span>: <b>" +
            this.y +
            " Hours " +
            "</b><br/>";
          return x + z;
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          showInLegend: false,
          name: "Time",
          data: newWeekGraphActIndexConverted,
        },
      ],
    };

    // console.log(this.state.selectedDate);
    return (
        <Layout>
            <div>
                <div className="flex flex-col justify-between items-center sm:flex-row bg-white rounded-md shadow-md p-3">
                    <div className="w-52 mx-2 md:mx-3 flex items-center">
                        <DatePicker
                            className="rounded-md w-52 text-black cursor-pointer"
                            dateFormat="MMMM dd yyyy"
                            selected={this.state.selectedDate}
                            value={new Date()}
                            startDate={new Date()}
                            onChange={
                                (date) =>
                                    // this.setState({startDate: date, selectedDate: date}, () => {
                                    {
                                        this.handleScreenshotDateChange(date);
                                    }
                                // });
                            }
                        />
                        <CalendarIcon className="-ml-10 z-10 w-6"></CalendarIcon>
                    </div>
                    <div className="mx-2 flex align-bottom mt-4 sm:mt-0">
                        {userRole !== "employee" ? (
                            <button
                                onClick={() => {
                                    if (this.state.isScreenshotSelectActiveOffline) {
                                        this.HandleOfflineTimeRequest();
                                    }
                                    this.setState({
                                        isScreenshotSelectActiveOffline:
                                            !this.state.isScreenshotSelectActiveOffline,
                                    });
                                }}
                                //  ${this.state.employeename===null || this.state.employeename === undefined || this.state.employeename === ''? 'hidden':''}
                                className={`inline-block px-4 py-2 min-w-max mr-4 max-h-10 translate-y-5 bg-primary text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out`}>
                                {this.state.isScreenshotSelectActiveOffline
                                    ? `${t("request")} ${t("offline")}`
                                    : `${t("offline")} ${t("time")}`}
                            </button>
                        ) : null}

                        <button
                            onClick={() => {
                                if (
                                    this.state.isScreenshotSelectActive &&
                                    this.state.updateProjectStage
                                ) {
                                    this.setState({
                                        showProjModal: true,
                                        updateProjectStage: false,
                                        isScreenshotSelectActive: false,
                                    });
                                }
                                // else if(this.state.isScreenshotSelectActive && !this.state.updateProjectStage)
                                // {
                                //     this.setState({
                                //             isScreenshotSelectActive: false,
                                //     });
                                // }
                                else {
                                    this.setState({
                                        isScreenshotSelectActive: true,
                                        updateProjectStage: true,
                                    });
                                }
                            }}
                            //  ${this.state.employeename===null || this.state.employeename === undefined || this.state.employeename === ''? 'hidden':''}
                            className={`inline-block px-4 py-2 min-w-max mr-4 max-h-10 translate-y-5 bg-primary text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out`}>
                            {this.state.isScreenshotSelectActive
                                ? `${t("select")} ${t("project")}`
                                : `${t("project")} ${t("timeTracker.change")}`}
                        </button>
                        <ModalProjectSelect
                            projectsList={projectListWithIds}
                            selectedProject={this.state.selectedProject}
                            open={this.state.showProjModal}
                            handleClose={() => {
                                this.setState({ showProjModal: false });
                            }}
                            handleDone={this.HandleProjectChange}
                        />
                        <SelectField
                            onSelectChange={(e) => this.onProjectScreenshotChange(e)}
                            name={"Select Project"}
                            arrayOfData={projectList}
                            id={"name"}
                            FieldName={t("projects")}></SelectField>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
                    <div className="flex items-center justify-between p-5 bg-white rounded shadow-sm">
                        <div>
                            <div className="text-base text-textprimary ">
                                {t("timeTracker.totalTime")}
                            </div>
                            <div className="flex items-center pt-1">
                                <div className="text-xl font-medium text-primary ">
                                    {moment
                                        .duration(
                                            screenshotkpis && screenshotkpis.Total_online_time,
                                            "minutes"
                                        )
                                        .days() *
                                        24 +
                                        moment
                                            .duration(
                                                screenshotkpis && screenshotkpis.Total_online_time,
                                                "minutes"
                                            )
                                            .hours() +
                                        " hrs " +
                                        moment
                                            .duration(
                                                screenshotkpis && screenshotkpis.Total_online_time,
                                                "minutes"
                                            )
                                            .minutes() +
                                        " mins"}
                                </div>
                            </div>
                        </div>
                        <div className="text-gray-300">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-8 h-8"
                                fill="none"
                                viewBox="0 0 47 46">
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M41.536 30.456a19.21 19.21 0 01-5.675 7.4 19.771 19.771 0 01-8.557 3.937c-3.138.608-6.38.455-9.444-.447a19.673 19.673 0 01-8.129-4.725 19.1 19.1 0 01-4.92-7.902 18.775 18.775 0 01-.564-9.237 18.98 18.98 0 013.923-8.419 19.538 19.538 0 017.497-5.639"></path>
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M43.083 23c0-2.517-.506-5.01-1.49-7.335a19.142 19.142 0 00-4.246-6.218 19.617 19.617 0 00-6.353-4.155A19.953 19.953 0 0023.5 3.833V23h19.583z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="flex items-center justify-between p-5 bg-white rounded shadow-sm">
                        <div>
                            <div className="text-base text-textprimary ">
                                {t("timeTracker.idleTime")}
                            </div>
                            <div className="flex items-center pt-1">
                                <div className="text-xl font-medium text-primary ">
                                    {moment
                                        .duration(
                                            screenshotkpis && screenshotkpis.Total_idle_time,
                                            "minutes"
                                        )
                                        .days() *
                                        24 +
                                        moment
                                            .duration(
                                                screenshotkpis && screenshotkpis.Total_idle_time,
                                                "minutes"
                                            )
                                            .hours() +
                                        " hrs " +
                                        moment
                                            .duration(
                                                screenshotkpis && screenshotkpis.Total_idle_time,
                                                "minutes"
                                            )
                                            .minutes() +
                                        " mins"}
                                </div>
                            </div>
                        </div>
                        <div className="text-gray-300">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-8 h-8"
                                fill="none"
                                viewBox="0 0 47 46">
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M41.536 30.456a19.21 19.21 0 01-5.675 7.4 19.771 19.771 0 01-8.557 3.937c-3.138.608-6.38.455-9.444-.447a19.673 19.673 0 01-8.129-4.725 19.1 19.1 0 01-4.92-7.902 18.775 18.775 0 01-.564-9.237 18.98 18.98 0 013.923-8.419 19.538 19.538 0 017.497-5.639"></path>
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M43.083 23c0-2.517-.506-5.01-1.49-7.335a19.142 19.142 0 00-4.246-6.218 19.617 19.617 0 00-6.353-4.155A19.953 19.953 0 0023.5 3.833V23h19.583z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="flex items-center justify-between p-5 bg-white rounded shadow-sm">
                        <div>
                            <div className="text-base text-textprimary ">
                                {t("activity")} {t("requestsModule.Index")}
                            </div>
                            <div className="flex items-center pt-1">
                                <div className="text-xl font-medium text-primary">
                                    {screenshotkpis == undefined || screenshotkpis == ""
                                        ? ""
                                        : screenshotkpis.Activity_idex.toFixed(2) + " %"}
                                </div>
                            </div>
                        </div>
                        <div className="text-textprimary">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-8 h-8"
                                fill="none"
                                viewBox="0 0 47 46">
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M39.167 40.25v-3.833a7.585 7.585 0 00-2.295-5.422 7.92 7.92 0 00-5.539-2.245H15.667a7.92 7.92 0 00-5.54 2.245 7.585 7.585 0 00-2.294 5.422v3.833M23.5 21.083c4.326 0 7.833-3.432 7.833-7.666 0-4.235-3.507-7.667-7.833-7.667-4.326 0-7.833 3.432-7.833 7.667 0 4.234 3.507 7.666 7.833 7.666z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                {this.state.moodData !== "" ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                        <div className="p-5 bg-white bg-opacity-50 rounded shadow-sm">
                            <div className="text-lg mb-2">
                                {t("start")} {t("mood")}:{" "}
                                {this.state.moodData["start_mood"] ? (
                                    <span>
                                        {t("feeling")}{" "}
                                        {emojiList[this.state.moodData["start_mood"] - 1]}
                                    </span>
                                ) : (
                                    t("noData")
                                )}
                            </div>
                            <p className="min-w-[100px] text-textprimary">
                                {this.state.moodData["start_note"]}
                            </p>
                        </div>
                        <div className="p-5 bg-white bg-opacity-50 rounded shadow-sm">
                            <div className="text-lg mb-2">
                                {t("end")} {t("mood")}:{" "}
                                {this.state.moodData["end_mood"] ? (
                                    <span>
                                        {t("feeling")}{" "}
                                        {emojiList[this.state.moodData["end_mood"] - 1]}
                                    </span>
                                ) : (
                                    t("noData")
                                )}
                            </div>
                            <p className="min-w-[100px] text-textprimary">
                                {this.state.moodData["end_note"]}
                            </p>
                        </div>
                    </div>
                ) : null}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                    <div className="bg-white rounded-md shadow-md p-3">
                        <p className="text-md font-semibold">
                            {t("timeTracker.dailyActivityPerHour")}
                        </p>
                        <p className="text-gray-500 text-tiny">
                            {t("timeTracker.dailyActCharttxt2")}
                        </p>
                        <div className="flex mt-3">
                            <div className="flex mr-3 items-center">
                                <div className="h-3 w-3 rounded-sm bg-primary mr-1"></div>
                                <span className="text-gray-600 text-tiny">
                                    {t("timeTracker.above")} 50%
                                </span>
                            </div>
                            <div className="flex mr-3 items-center">
                                <div className="h-3 w-3 rounded-sm bg-gray-400 mr-1"></div>
                                <span className="text-gray-600 text-tiny">
                                    {t("timeTracker.above")} 25%
                                </span>
                            </div>
                            <div className="flex items-center">
                                <div className="h-3 w-3 rounded-sm bg-red-500 mr-1"></div>
                                <span className="text-gray-600 text-tiny">
                                    {t("timeTracker.below")} 25%
                                </span>
                            </div>
                        </div>
                        <div>
                            <ReactHighcharts config={hourlyChart}></ReactHighcharts>
                        </div>
                    </div>

                    <div className="bg-white rounded-md shadow-md p-3">
                        <p className="text-md font-semibold">
                            {t("timeTracker.weekly")} {t("timeTracker.chart")}
                        </p>
                        <p className="text-gray-500 text-tiny">
                            {t("timeTracker.averageHoursWorkedPast")} <br /> 7
                            {t("timeTracker.days")}
                        </p>
                        <div>
                            <ReactHighcharts config={weekChartsData}></ReactHighcharts>
                        </div>
                    </div>
                </div>
                <div className="p-5 mt-2 bg-white rounded shadow-sm hidden">
                    <div className="w-full pr-6 font-semibold mb-4 flex items-center justify-between">
                        <p className="text-xl text-textprimary">{`${t("showCaseEntries")} (${
                            this.state.showcaseEntriesList.length
                        })`}</p>
                        {this.state.showcaseEntriesList.length > 4 ? (
                            <p
                                className="text-lg underline text-indigo-600 hover:cursor-pointer"
                                onClick={() => {
                                    this.setState({
                                        isShowAllEntries: !this.state.isShowAllEntries,
                                    });
                                }}>
                                {this.state.isShowAllEntries
                                    ? `${t("hide")} ${t("all")}`
                                    : `${t("show")} ${t("all")}`}
                            </p>
                        ) : null}
                    </div>
                    {this.state.isShowcaseModalActive ? (
                        <ModalShowCaseEntry
                            open={this.state.isShowcaseModalActive}
                            fromDashboard={false}
                            userDetails={{
                                name: this.state.userName
                                    ? this.state.userName
                                    : this.state.selectedEntry.username,
                            }}
                            handleClose={() => {
                                this.setState({
                                    isShowcaseModalActive: false,
                                });
                            }}
                            handleModalShowChange={() => {
                                this.setState({
                                    isShowcaseModalActive: !this.state.isShowcaseModalActive,
                                });
                            }}
                            entryDetails={[this.state.selectedEntry]}
                        />
                    ) : null}
                    {this.state.showcaseEntriesList && this.state.showcaseEntriesList.length > 0 ? (
                        <div className="flex flex-wrap 2xl:grid 2xl:grid-cols-4 2xl:gap-10">
                            {this.state.showcaseEntriesList.map((entry, idx) => {
                                if (!this.state.isShowAllEntries && idx >= 4) {
                                    return null;
                                }
                                return (
                                    <div className="mr-2 mb-6 min-w-[250px] min-h-[225px] w-[250px] h-[225px] overflow-hidden rounded-lg border-2 bg-white border-gray shadow-sm lg:mr-6">
                                        <img
                                            src={entry.image}
                                            alt=""
                                            className="w-full h-[140px] hover:cursor-pointer"
                                            onClick={() => {
                                                this.setState({
                                                    isShowcaseModalActive:
                                                        !this.state.isShowcaseModalActive,
                                                    selectedEntry: entry,
                                                });
                                            }}
                                        />
                                        <div className="p-2 ">
                                            <p className=" text-sm font-semibold">
                                                {entry.file_name}
                                            </p>
                                            <p className="text-sm text-gray-400">
                                                {`${t("project")}: ${entry.project_name}`}
                                            </p>
                                            <p className="text-sm text-gray-400">
                                                {`${t("task")}: ${entry.task}`}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className=" h-40 flex justify-center items-center italic font-medium text-gray-500 text-lg">
                            {t("timeTracker.noEntries")}
                        </div>
                    )}
                </div>
                <div className="text-xl text-textprimary font-semibold mt-6 flex items-center">
                    {t("screenshots")}
                    <span>
                        {!this.state.Timetracker || this.state.Timetracker.length === 0 ? (
                            <div className="h-5 w-5 text-primary rounded-full  border border-t-0 ml-2 animate-spin"></div>
                        ) : null}
                    </span>
                </div>
                <div className="col-md-12" style={{ padding: "0px" }}>
                    {this.state.Timetracker &&
                        this.state.Timetracker.length > 0 &&
                        this.state.OfflineTimetracker &&
                        this.state.OfflineTimetracker.length > 0 &&
                        this.state.OfflineTimetracker.map((item, index) => {
                            let item1 = Object.values(item);
                            let value = Object.keys(item);
                            let findInd = this.state.Timetrackerkeys.indexOf(index + "");
                            if (findInd !== -1) {
                                item1 = Object.values(this.state.Timetracker[findInd]);
                                value = Object.keys(this.state.Timetracker[findInd]);
                                item1.pop();
                            } else if (!this.state.isScreenshotSelectActiveOffline) {
                                return null;
                            }
                            for (var i = 0; i < item1.length; i++) {
                                if (item1[i]) {
                                    var hour_img = item1[i].thumbnails;
                                    var hourly_thubm_img;
                                    if (hour_img != undefined) {
                                        if (hour_img[0] != undefined) {
                                            hourly_thubm_img = hour_img[0];
                                        }
                                    }
                                    var hour_img = item1[i].screenshots;
                                    var hourly_ss_img;
                                    if (hour_img != undefined) {
                                        if (hour_img[0] != undefined) {
                                            hourly_ss_img = hour_img[0];
                                        }
                                    }
                                }
                            }

                            return (
                                <div
                                    style={{ marginTop: "40px" }}
                                    id={this.state.OfflineTimetrackerkeys[index]}
                                    className="shadow-sm">
                                    <Grid
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                        className="img_view"
                                        xs={12}
                                        sm={12}
                                        lg={12}>
                                        {item1.map((items, index1) => {
                                            let time_min = index1 == 0 ? "00" : value[index1 - 1];
                                            // let time_duration = [
                                            //     this.state.Timetrackerkeys[index],
                                            //     time_min,
                                            // ];
                                            // const number = moment(
                                            //     `${this.state.Timetrackerkeys[index]} : ${time_min}`,
                                            //     ["HH"]
                                            // ).format("hh:mm a");
                                            return (
                                                <div key={index1}>
                                                    {value[index1] ==
                                                    "hourly_percentage" ? null : !items.id ||
                                                      items.offline ? (
                                                        <div className="relative">
                                                            {this.state
                                                                .isScreenshotSelectActiveOffline ? (
                                                                <div className="absolute z-10 top-1 right-4 w-5 h-5 rounded-full bg-gray">
                                                                    <CheckIcon
                                                                        className={
                                                                            this.state.selectedSSid.includes(
                                                                                moment(
                                                                                    `${
                                                                                        parseInt(
                                                                                            this
                                                                                                .state
                                                                                                .OfflineTimetrackerkeys[
                                                                                                index
                                                                                            ]
                                                                                        ) +
                                                                                        Math.floor(
                                                                                            parseInt(
                                                                                                time_min
                                                                                            ) / 60
                                                                                        )
                                                                                    } : ${
                                                                                        parseInt(
                                                                                            time_min
                                                                                        ) % 60
                                                                                    }`,
                                                                                    ["HH:mm"]
                                                                                ).format("HH:mm")
                                                                            )
                                                                                ? "text-primary font-bold"
                                                                                : "text-white font-bold"
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                            <div
                                                                className={
                                                                    "Inactive_sty" +
                                                                    (this.state
                                                                        .isScreenshotSelectActiveOffline
                                                                        ? " hover:cursor-pointer"
                                                                        : "")
                                                                }
                                                                onClick={
                                                                    this.state
                                                                        .isScreenshotSelectActiveOffline
                                                                        ? () => {
                                                                              this.HandleTimeSelect(
                                                                                  items,
                                                                                  index,
                                                                                  time_min,
                                                                                  value,
                                                                                  index1
                                                                              );
                                                                          }
                                                                        : () => {}
                                                                }>
                                                                <p className="inactive_text">
                                                                    {t("inactive")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : items.key_count === 0 &&
                                                      items.mouse_count === 0 ? (
                                                        this.IdleTime(
                                                            this.state.OfflineTimetrackerkeys[
                                                                index
                                                            ],
                                                            time_min,
                                                            value[index1],
                                                            t("timeTracker.idleTime"),
                                                            items,
                                                            index,
                                                            value,
                                                            index1,
                                                            hourly_ss_img,
                                                            hourly_thubm_img
                                                        )
                                                    ) : items.cancelled_time ? (
                                                        <div className="relative">
                                                            {this.state
                                                                .isScreenshotSelectActiveOffline ? (
                                                                <div className="absolute z-10 top-1 right-4 w-5 h-5 rounded-full bg-gray">
                                                                    <CheckIcon
                                                                        className={
                                                                            this.state.selectedSSid.includes(
                                                                                moment(
                                                                                    `${
                                                                                        parseInt(
                                                                                            this
                                                                                                .state
                                                                                                .OfflineTimetrackerkeys[
                                                                                                index
                                                                                            ]
                                                                                        ) +
                                                                                        Math.floor(
                                                                                            parseInt(
                                                                                                time_min
                                                                                            ) / 60
                                                                                        )
                                                                                    } : ${
                                                                                        parseInt(
                                                                                            time_min
                                                                                        ) % 60
                                                                                    }`,
                                                                                    ["HH:mm"]
                                                                                ).format("HH:mm")
                                                                            )
                                                                                ? "text-primary font-bold"
                                                                                : "text-white font-bold"
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                            <img
                                                                className={
                                                                    "Inactive_sty max-h-20" +
                                                                    (this.state
                                                                        .isScreenshotSelectActiveOffline
                                                                        ? " hover:cursor-pointer"
                                                                        : "")
                                                                }
                                                                src={ideltTime}
                                                                alt="cancelled time"
                                                                onClick={
                                                                    this.state
                                                                        .isScreenshotSelectActiveOffline
                                                                        ? () => {
                                                                              this.HandleTimeSelect(
                                                                                  items,
                                                                                  index,
                                                                                  time_min,
                                                                                  value,
                                                                                  index1
                                                                              );
                                                                          }
                                                                        : () => {}
                                                                }
                                                            />
                                                        </div>
                                                    ) : items.offline ? (
                                                        <div className="Inactive_sty bg-gray-400">
                                                            <p className="inactive_text">Offline</p>
                                                        </div>
                                                    ) : (
                                                        <Paper
                                                            style={{
                                                                marginRight: "10px",
                                                                width: "163px",
                                                            }}
                                                            className="shadowBox relative">
                                                            {this.state.isScreenshotSelectActive ||
                                                            this.state
                                                                .isScreenshotSelectActiveOffline ? (
                                                                <div
                                                                    className={
                                                                        "absolute z-10 top-1 right-1 w-5 h-5 rounded-full bg-gray border border-white"
                                                                    }>
                                                                    <CheckIcon
                                                                        className={
                                                                            this.state.selectedSSid.includes(
                                                                                moment(
                                                                                    `${
                                                                                        parseInt(
                                                                                            this
                                                                                                .state
                                                                                                .OfflineTimetrackerkeys[
                                                                                                index
                                                                                            ]
                                                                                        ) +
                                                                                        Math.floor(
                                                                                            parseInt(
                                                                                                time_min
                                                                                            ) / 60
                                                                                        )
                                                                                    } : ${
                                                                                        parseInt(
                                                                                            time_min
                                                                                        ) % 60
                                                                                    }`,
                                                                                    ["HH:mm"]
                                                                                ).format("HH:mm")
                                                                            )
                                                                                ? "text-primary font-bold bg-white rounded-full"
                                                                                : "text-white font-bold "
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                            {
                                                                // items.idle_time != 10 ?
                                                                <Grid item xs={12} sm={5} md={2}>
                                                                    <div
                                                                        onClick={
                                                                            this.state
                                                                                .isScreenshotSelectActive ||
                                                                            this.state
                                                                                .isScreenshotSelectActiveOffline
                                                                                ? () => {
                                                                                      this.HandleTimeSelect(
                                                                                          items,
                                                                                          index,
                                                                                          time_min,
                                                                                          value,
                                                                                          index1
                                                                                      );
                                                                                  }
                                                                                : () => {}
                                                                        }
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}>
                                                                        <Modaltracker
                                                                            image={items}
                                                                            openDisabled={
                                                                                this.state
                                                                                    .isScreenshotSelectActive ||
                                                                                this.state
                                                                                    .isScreenshotSelectActiveOffline
                                                                            }
                                                                            hourly_thubm_image={
                                                                                hourly_thubm_img
                                                                            }
                                                                            hourly_ss_image={
                                                                                hourly_ss_img
                                                                            }
                                                                            ActivityIndex={
                                                                                this.state
                                                                                    .EmployeeTiming
                                                                            }
                                                                            selectedDate={
                                                                                this.state
                                                                                    .selectedDate
                                                                            }
                                                                            userName={
                                                                                this.state
                                                                                    .employeename
                                                                            }
                                                                            Timekey={
                                                                                this.state
                                                                                    .OfflineTimetrackerkeys[
                                                                                    index
                                                                                ]
                                                                            }
                                                                            time_min={time_min}
                                                                            value={value[index1]}
                                                                            ReportName={"user"}
                                                                            active={{
                                                                                key_count:
                                                                                    items.key_count,
                                                                                mouse_count:
                                                                                    items.mouse_count,
                                                                                idle_time:
                                                                                    items.idle_time,
                                                                                item: items,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                                // ) : (
                                                                //         this.IdleTime(
                                                                //             this.state.Timetrackerkeys[index],
                                                                //             time_min,
                                                                //             value[index1]
                                                                //         )
                                                            }
                                                        </Paper>
                                                    )}
                                                    {/* <Divider /> */}
                                                    <Grid
                                                        style={{
                                                            width: "163px",
                                                            paddingTop: "4px",
                                                            backgroundColor: "white",
                                                        }}>
                                                        {/* <Paper style={{ width: '166px'}}>*/}
                                                        <div
                                                            className="row"
                                                            style={{
                                                                justifyContent: "space-between",
                                                                padding: "0px 10px 0px 10px",
                                                            }}>
                                                            {value[index1] ==
                                                            "hourly_percentage" ? (
                                                                <p
                                                                    style={{
                                                                        margin: "2px",
                                                                        fontSize: "14px",
                                                                    }}>
                                                                    {value[index1]}
                                                                </p>
                                                            ) : (
                                                                <p
                                                                    style={{
                                                                        margin: "2px",
                                                                        fontSize: "14px",
                                                                    }}>
                                                                    {
                                                                        this.state
                                                                            .OfflineTimetrackerkeys[
                                                                            index
                                                                        ]
                                                                    }
                                                                    :
                                                                    {time_min == 60
                                                                        ? "00"
                                                                        : time_min}{" "}
                                                                    -{" "}
                                                                    {value[index1] == 60
                                                                        ? parseInt(
                                                                              this.state
                                                                                  .OfflineTimetrackerkeys[
                                                                                  index
                                                                              ]
                                                                          ) + 1
                                                                        : this.state
                                                                              .OfflineTimetrackerkeys[
                                                                              index
                                                                          ]}
                                                                    :
                                                                    {value[index1] == 60
                                                                        ? "00"
                                                                        : value[index1]}
                                                                </p>
                                                            )}
                                                            {/* <DescriptionOutlined style={{ fontSize: '26px',paddingRight:'10px',marginTop: "-3px",color:'rgb(68, 152, 190)'}}/> */}
                                                            {/* <Modaltracxker image={items} ActivityIndex = {this.state.Trackertiming} /> */}
                                                            {/* <p>{items.key_count}</p>*/}
                                                        </div>
                                                        <div
                                                            style={{
                                                                padding: "4px",
                                                            }}>
                                                            {value[index1] ==
                                                            "hourly_percentage" ? (
                                                                <ProgressBar
                                                                    style={{
                                                                        height: "10px",
                                                                    }}
                                                                    variant="success"
                                                                    now={items}
                                                                    // now="20"
                                                                />
                                                            ) : (
                                                                <ProgressBar
                                                                    style={{
                                                                        height: "10px",
                                                                    }}
                                                                    variant="success"
                                                                    now={items.activity_points * 10}
                                                                />
                                                            )}
                                                        </div>
                                                        {items.screenshots == "" ? (
                                                            <></>
                                                        ) : (
                                                            <div className="tracker_img">
                                                                {this.progressActivity(
                                                                    items.screenshots
                                                                )}
                                                            </div>
                                                        )}
                                                        {/* </Paper> */}
                                                    </Grid>
                                                </div>
                                            );
                                        })}
                                    </Grid>
                                </div>
                            );
                        })}
                </div>
            </div>
        </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  projects: state.projects,
  timetracker: state.timetracker,
  empDashboard: state.empDashboard,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEmpLoginDetail: () => dispatch(fetchEmpLoginDetail()),
  getUserProject: () => dispatch(getUserProject()),
  timeTrackerDataWithoutProject: (date, empName) =>
    dispatch(timeTrackerDataWithoutProject(date, empName)),
  customReportWeekglyGraphdata: (last, current) =>
    dispatch(customReportWeekglyGraphdata(last, current)),
  timeTrackerData: (date, projectname, employeename) =>
    dispatch(timeTrackerData(date, projectname, employeename)),
  weeklyChartActivity: (date, user_id) =>
    dispatch(weeklyChartActivity(date, user_id)),
  getProjectList: () => dispatch(getProjectList()),
  requestProjectChange: (data) => dispatch(requestProjectChange(data)),
  getShowcaseEntries: (date, params) =>
    dispatch(getShowcaseEntries(date, params)),
  getEmployeeMoods: (date) => dispatch(getEmployeeMoods(date)),
  getOfflineData: () => dispatch(getOfflineData()),
});

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(EmployeeTimeTracker)
);
