import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./TransactionAnimation.css";

export default function PayPalTransacFailed() {
    const {t} = useTranslation('common');
  let qloc = window.location.search;
  let org_uuid = new URLSearchParams(qloc).get("org_uuid");
  let paymentId = new URLSearchParams(qloc).get("paymentId");
  let token = new URLSearchParams(qloc).get("token");
  let PayerID = new URLSearchParams(qloc).get("PayerID");

  useEffect(() => {
    timeIntervalToRedirect();
  }, []);

  const timeIntervalToRedirect = () => {
      setTimeout(() => {
          window.location.href = "/settings/";
      }, 3000);
  }

  return (
      <div className="" style={{ backgroundColor: "white", height: "100vh", width: "100%" }}>
          <div
              style={{
                  paddingTop: "100px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
              }}>
              <section className="c-container">
                  <div className="o-circle c-container__circle o-circle__sign--failure">
                      <div className="o-circle__sign"></div>
                  </div>
              </section>

              <div
                  style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                  }}>
                  <p style={{ fontSize: "35px", color: "#684d94", fontWeight: "900" }}>
                      {t("settingsTab.transactionFailed")}
                  </p>
                  <p style={{ fontSize: "18px", fontWeight: "bold", color: "#684D94" }}>
                      {t("redirecting")}...
                  </p>
              </div>
          </div>
      </div>
  );
}
