import { ACTION_TYPES } from '../../../constants/index';
import api from '../api';

export const fetchEmpLoginDetail = () => ({
    type: ACTION_TYPES.EMP_LOGIN_DETAILS,
    payload: api.fetchEmpLoginDetail()
})

export const fetchEmpDashboard = (date) => ({
    type: ACTION_TYPES.EMP_DASHBOARD_DETAILS,
    payload: api.fetchEmpDashboard(date)
})

export const fetchEmpSummary = (date) => ({
    type: ACTION_TYPES.EMP_SUMMARY_DETAILS,
    payload: api.fetchEmpSummary(date)
})

export const createLeaveRequest = (data) => ({
    type: ACTION_TYPES.CREATE_LEAVE_REQUEST,
    payload: api.createLeaveRequest(data)
})

export const deleteLeaveRequest = (data) => ({
    type: ACTION_TYPES.DELETE_LEAVE_REQUEST,
    payload: api.deleteLeaveRequest(data)
})

export const getUserProject = () => ({
    type: ACTION_TYPES.GET_USER_PROJECTS,
    payload: api.getUserProject()
})
export const getDailyActivityList = (date) => ({
    type: ACTION_TYPES.GET_DAILY_ACTITVITY_LIST,
    payload: api.getDailyActivityList(date),
});
