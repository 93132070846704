/* eslint-disable import/no-anonymous-default-export */
import { API_ENDPOINTS } from "../../constants";
import { transport, padlocktransport } from "../../Networks/Auth";

export default {
    managerdashboard: (date) => {
        let config = {
            url: API_ENDPOINTS.FETCH_MANAGER_DASHBOARD + date + "/",
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    managerDashboardObserver: (date) => {
        let config = {
            url: API_ENDPOINTS.FETCH_MANAGER_DASHBOARD + date + "/?observer=True",
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    managerdashboard_observer: (date) => {
        let config = {
            url: API_ENDPOINTS.FETCH_MANAGER_DASHBOARD_OBSERVER + date + "/",
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    teamdetailsdashboard: (date, id) => {
        let config = {
            url: API_ENDPOINTS.TEAM_DETAILS_DASHBOARD + date + "&team_uuid=" + id,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    projectdetailsdashboard: (date) => {
        let config = {
            url: API_ENDPOINTS.PROJECT_DETAILS_DASHBOARD + date,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    teamsdashboard: (date) => {
        let config = {
            url: API_ENDPOINTS.FETCH_TEAMS_DASHBOARD + date,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    notworkingemploees: (date) => {
        let config = {
            url: API_ENDPOINTS.FETCH_NOT_WORKING_EMPLOYEES + date,
            method: "GET",
        };
        return transport(config);
    },

    senduserinvite: (data) => {
        let config = {
            url: API_ENDPOINTS.SEND_USER_INVITE + data.domain_name,
            method: "POST",
            data: data,
        };
        return padlocktransport(config);
    },

    deleteorgemail: (email, id) => {
        let config = {
            url: API_ENDPOINTS.DELETE_ORG_EMAIL + email + "&id=" + id,
            method: "DELETE",
        };
        return padlocktransport(config);
    },

    orgemails: (name) => {
        let config = {
            url: API_ENDPOINTS.ORG_EMAILS + name,
            method: "GET",
        };
        return padlocktransport(config);
    },

    updateuseremail: (email, id) => {
        let config = {
            url: API_ENDPOINTS.UPDATE_USER_EMAIL + email + "&id=" + id,
            method: "PUT",
        };
        return padlocktransport(config);
    },

    rolesCheck: () => {
        let config = {
            url: API_ENDPOINTS.ROLES_CHECK,
            method: "GET",
        };
        return transport(config);
    },

    getEmpListObserver: () => {
        let config = {
            url: API_ENDPOINTS.GET_EMP_LIST_OBSERVER,
            method: "GET",
        };
        return transport(config);
    },
    getShowcaseUsersList: (date) => {
        let config = {
            url: API_ENDPOINTS.GET_SHOWCASE_USERS_LIST + date,
            method: "GET",
        };
        return transport(config);
    },
    getDashboardLayout: () => {
        let config = {
            url: API_ENDPOINTS.DASHBOARD_LAYOUT,
            method: "GET",
        };
        return transport(config);
    },
    updateDashboardLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.DASHBOARD_LAYOUT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    postDashboardLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.DASHBOARD_LAYOUT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    getAllProjectTableLayout: () => {
        let config = {
            url: API_ENDPOINTS.ALL_PROJECT_TABLE_LAYOUT,
            method: "GET",
        };
        return transport(config);
    },
    updateAllProjectTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.ALL_PROJECT_TABLE_LAYOUT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    postAllProjectTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.ALL_PROJECT_TABLE_LAYOUT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    getDashboardArrangement: () => {
        let config = {
            url: API_ENDPOINTS.DASHBOARD_ARRANGEMENT,
            method: "GET",
        };
        return transport(config);
    },
    updateDashboardArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.DASHBOARD_ARRANGEMENT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    postDashboardArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.DASHBOARD_ARRANGEMENT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    getEmployeeMoodTableData: (date) => {
        let config = {
            url: API_ENDPOINTS.GET_EMPLOYEE_MOOD + date,
            method: "GET",
        };
        return transport(config);
    },
    getEmployeeMoodTableLayout: () => {
        let config = {
            url: API_ENDPOINTS.GET_MOOD_TABLE_LAYOUT,
            method: "GET",
        };
        return transport(config);
    },
    getEmployeeMoodTableArrangement: () => {
        let config = {
            url: API_ENDPOINTS.GET_MOOD_TABLE_ARRANGEMENT,
            method: "GET",
        };
        return transport(config);
    },
    getEmployeeTableLayout: () => {
        let config = {
            url: API_ENDPOINTS.EMPLOYEE_TABLE_LAYOUT,
            method: "GET",
        };
        return transport(config);
    },
    getTeamTableLayout: () => {
        let config = {
            url: API_ENDPOINTS.TEAM_TABLE_LAYOUT,
            method: "GET",
        };
        return transport(config);
    },
    getEmployeeTableArrangement: () => {
        let config = {
            url: API_ENDPOINTS.EMPLOYEE_TABLE_ARRANGEMENT,
            method: "GET",
        };
        return transport(config);
    },
    getTeamTableArrangement: () => {
        let config = {
            url: API_ENDPOINTS.TEAM_TABLE_ARRANGEMENT,
            method: "GET",
        };
        return transport(config);
    },

    updateEmployeeMoodTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.GET_MOOD_TABLE_LAYOUT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    updateEmployeeMoodTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.GET_MOOD_TABLE_ARRANGEMENT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    updateEmployeeTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.EMPLOYEE_TABLE_LAYOUT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    updateTeamTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_TABLE_LAYOUT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    updateEmployeeTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.EMPLOYEE_TABLE_ARRANGEMENT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    updateTeamTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_TABLE_ARRANGEMENT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },

    postEmployeeMoodTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.GET_MOOD_TABLE_LAYOUT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    postEmployeeMoodTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.GET_MOOD_TABLE_ARRANGEMENT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    postEmployeeTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.EMPLOYEE_TABLE_LAYOUT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    postTeamTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_TABLE_LAYOUT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    postEmployeeTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.EMPLOYEE_TABLE_ARRANGEMENT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    postTeamTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_TABLE_ARRANGEMENT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    getAllProjectTableArrangement: () => {
        let config = {
            url: API_ENDPOINTS.ALL_PROJECT_TABLE_ARRANGEMENT,
            method: "GET",
        };
        return transport(config);
    },
    updateAllProjectTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.ALL_PROJECT_TABLE_ARRANGEMENT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    postAllProjectTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.ALL_PROJECT_TABLE_ARRANGEMENT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    getMoodGraphData: (date) => {
        let config = {
            url: API_ENDPOINTS.MOOD_GRAPH + date,
            method: "GET",
        };
        return transport(config);
    },
    getAllProjectTableData: (date) => {
        let config = {
            url: API_ENDPOINTS.ALL_PROJECT_TABLE_DATA + date,
            method: "GET",
        };
        return transport(config);
    },

    getProjectWorking: (date, id) => {
        let config = {
            url: API_ENDPOINTS.PROJECT_WORKING_USERS + "/?date=" + date + "&project_id=" + id,
            method: "GET",
        };
        return transport(config);
    },

    getProjectActivity: (date, id) => {
        let config = {
            url: API_ENDPOINTS.PROJECT_ACTIVITY + date + "/?project_id=" + id,
            method: "GET",
        };
        return transport(config);
    },

    getProjectEmployeesWorkingDetails: (date, id) => {
        let config = {
            url: API_ENDPOINTS.EMPLOYEES_WORKING_IN_PROJECT + date + "/?project_id=" + id,
            method: "GET",
        };
        return transport(config);
    },
    getFeatureListing: () => {
        let config = {
            url: API_ENDPOINTS.FEATURE_LISTING,
            method: "GET",
        };
        return padlocktransport(config);
    },

    getTeamDashboardLayout: () => {
        let config = {
            url: API_ENDPOINTS.TEAM_DASHBOARD_LAYOUT,
            method: "GET",
        };
        return transport(config);
    },
    updateTeamDashboardLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_DASHBOARD_LAYOUT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    postTeamDashboardLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_DASHBOARD_LAYOUT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    getTeamDashboardArrangement: () => {
        let config = {
            url: API_ENDPOINTS.TEAM_DASHBOARD_ARRANGEMENT,
            method: "GET",
        };
        return transport(config);
    },
    updateTeamDashboardArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_DASHBOARD_ARRANGEMENT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    postTeamDashboardArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_DASHBOARD_ARRANGEMENT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    getTeamMemberTableLayout: () => {
        let config = {
            url: API_ENDPOINTS.TEAM_MEMBER_TABLE_LAYOUT,
            method: "GET",
        };
        return transport(config);
    },
    updateTeamMemberTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_MEMBER_TABLE_LAYOUT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    postTeamMemberTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_MEMBER_TABLE_LAYOUT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    getTeamMemberTableArrangement: () => {
        let config = {
            url: API_ENDPOINTS.TEAM_MEMBER_TABLE_ARRANGEMENT,
            method: "GET",
        };
        return transport(config);
    },
    updateTeamMemberTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_MEMBER_TABLE_ARRANGEMENT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    postTeamMemberTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_MEMBER_TABLE_ARRANGEMENT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    getTeamProjectTableLayout: () => {
        let config = {
            url: API_ENDPOINTS.TEAM_PROJECT_TABLE_LAYOUT,
            method: "GET",
        };
        return transport(config);
    },
    updateTeamProjectTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_PROJECT_TABLE_LAYOUT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    postTeamProjectTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_PROJECT_TABLE_LAYOUT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    getTeamProjectTableArrangement: () => {
        let config = {
            url: API_ENDPOINTS.TEAM_PROJECT_TABLE_ARRANGEMENT,
            method: "GET",
        };
        return transport(config);
    },
    updateTeamProjectTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_PROJECT_TABLE_ARRANGEMENT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    postTeamProjectTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_PROJECT_TABLE_ARRANGEMENT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    getTeamEmployeeWorkingTableLayout: () => {
        let config = {
            url: API_ENDPOINTS.TEAM_EMPLOYEE_WORKING_TABLE_LAYOUT,
            method: "GET",
        };
        return transport(config);
    },
    updateTeamEmployeeWorkingTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_EMPLOYEE_WORKING_TABLE_LAYOUT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    postTeamEmployeeWorkingTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_EMPLOYEE_WORKING_TABLE_LAYOUT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    getTeamEmployeeWorkingTableArrangement: () => {
        let config = {
            url: API_ENDPOINTS.TEAM_EMPLOYEE_WORKING_TABLE_ARRANGEMENT,
            method: "GET",
        };
        return transport(config);
    },
    updateTeamEmployeeWorkingTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_EMPLOYEE_WORKING_TABLE_ARRANGEMENT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    postTeamEmployeeWorkingTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.TEAM_EMPLOYEE_WORKING_TABLE_ARRANGEMENT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    getProjectWorkingData: (date, id) => {
        let config = {
            url: API_ENDPOINTS.PROJECT_WORKING_DATA + date + "/?project_id=" + id,
            method: "GET",
        };
        return transport(config);
    },

    getTeamWorking: (date, name) => {
        let config = {
            url: API_ENDPOINTS.TEAM_WORKING_USERS + "?date=" + date + "&name=" + name,
            method: "GET",
        };
        return transport(config);
    },

    getTeamActivity: (date, name) => {
        let config = {
            url: API_ENDPOINTS.TEAM_ACTIVITY + date + "/?name=" + name,
            method: "GET",
        };
        return transport(config);
    },
    getUserProjectChange: (date, uuid) => {
        let config = {
            url: API_ENDPOINTS.USER_PROJECT_CHANGE_DATA + uuid + "&date=" + date,
            method: "GET",
        };
        return transport(config);
    },
    getCurrentStatus: (date, time) => {
        let config = {
            url: API_ENDPOINTS.CURRENT_STATUS + date + "&time=" + time,
            method: "GET",
        };
        return transport(config);
    },
    getEmployeeData: (date, time) => {
        let config = {
            url: API_ENDPOINTS.CURRENT_STATUS_EMPLOYEES + date + "&time=" + time,
            method: "GET",
        };
        return transport(config);
    },
};
