import { ACTION_TYPES } from "../../../constants";
import api from "../api";

export const fetchPaymentPlan = () =>({
    type:ACTION_TYPES.GET_PAYMENT_PLAN,
    payload:api.fetchPaymentPlan()
});






export const useravailablefeatures = (id) =>({
    type:ACTION_TYPES.GET_USER_AVAILABLE_FEATURES,
    payload:api.useravailablefeatures(id)
});

export const userunavailablefeatures = (id) =>({
    type:ACTION_TYPES.GET_USER_UNAVAILABLE_FEATURES,
    payload:api.userunavailablefeatures(id)
});


export const usercustompermissions = (id) =>({
    type:ACTION_TYPES.GET_USER_CUSTOM_PERMISSIONS,
    payload:api.usercustompermissions(id)
});




export const fetchOrgUuid = (org_name)=>({
    type:ACTION_TYPES.FETCH_ORG_UUID,
    payload:api.fetchOrgUuid(org_name)
})

export const fetchApiKeys = (org_uuid) =>({
    type:ACTION_TYPES.FETCH_API_KEYS,
    payload:api.fetchApiKeys(org_uuid)
})

export const fetchSecretKeys = (org_uuid, apiName) =>({
    type:ACTION_TYPES.FETCH_SECRET_KEYS,
    payload:api.fetchSecretKeys(org_uuid, apiName)
})

export const postSecretKey = (data)=>({
    type:ACTION_TYPES.POST_SECRET_KEY,
    payload:api.postSecretKey(data)
})

export const deleteSecret = (data) =>({
    type:ACTION_TYPES.DELETE_KEY,
    payload:api.deleteKey(data)
})

export const patchStatusUpdate= (data) =>({
    type:ACTION_TYPES.API_KEY_STATUS_UPDATE,
    payload:api.patchStatusUpdate(data)
})

export const getFeatureUnavailable= () =>({
    type:ACTION_TYPES.GET_FEATURE_UNAVAILABLE,
    payload:api.fetchFeatureUnavailable()
})

export const getFeatureMarket= () =>({
    type:ACTION_TYPES.GET_FEATURE_MARKET,
    payload:api.fetchFeatureMarket()
})

export const getFeatureList = () => ({
    type:ACTION_TYPES.FETCH_FEATURE_LIST,
    payload:api.fetchFeatureList()
})


export const getOverideFeatureList = (ids, type) => ({
    type:ACTION_TYPES.FETCH_OVERIDE_FEATURE_LIST,
    payload:api.fetchOverideFeatureList(ids, type)
})
export const getScreenshotTiming = (type)=>({
    type: ACTION_TYPES.FETCH_SCREENSHOT_TIMING,
    payload: api.fetchScreenSchotTiming(type)
})

export const postScreenshotTiming = (data)=>({
    type: ACTION_TYPES.POST_SCREENSHOT_TIMING,
    payload: api.postScreenshotTiming(data)
})


export const patchScreenshotTiming = (idTime)=>({
    type: ACTION_TYPES.PATCH_SCREENSHOT_TIMING,
    payload: api.patchScreenshotTiming(idTime)
})

export const postScreenshotTime = (data)=>({
    type: ACTION_TYPES.POST_SCREENSHOT_TIME,
    payload: api.postScreenshotTime(data)
})


export const getFeatureKey = () =>({
    type:ACTION_TYPES.GET_FEATURE_KEY,
    payload:api.fetchFeatureKey()
})

export const postFeatureStart = (data) =>({
    type:ACTION_TYPES.POST_FEATURE_START,
    payload:api.postFeatureStart(data)
})

export const deleteFeatureStart = (data) => ({
    type:ACTION_TYPES.DELETE_FEATURE_START,
    payload:api.deleteFeatureStart(data)
})

export const deleteFeatureMarket = (data) => ({
    type:ACTION_TYPES.DELETE_FEATURE_MARKET,
    payload:api.deleteFeatureMarket(data)
})

export const postFeatureMarket = (data)=>({
    type:ACTION_TYPES.POST_FEATURE_MARKET,
    payload:api.postFeatureMarket(data)
})

// export const postFeatureEnable = () =>({
//     type:ACTION_TYPES.postfeature
// })

export const putFeatureEnable = (data) => ({
    type:ACTION_TYPES.PUT_FEATURE_ENABLE,
    payload:api.putFeatureEnable(data)
})

export const putFeatureStop = (data) => ({
    type:ACTION_TYPES.PUT_FEATURE_STOP,
    payload:api.putFeatureStop(data)
})

export const postForgetPassword = (data) =>({
    type:ACTION_TYPES.POST_FORGET_PASSWORD,
    payload:api.postForgetPassword(data)
})

export const GetSubscribeEmail = () =>({
    type:ACTION_TYPES.GET_SUBSCRIBE_EMAIL,
    payload:api.GetSubscribeEmail()
})

export const updateSubScribeEmail = (data) =>({
    type:ACTION_TYPES.UPDATE_SUBSCRIBE_EMAIL,
    payload:api.updateSubScribeEmail(data)
})

export const getRoleUpdate = () =>({
    type:ACTION_TYPES.GET_ROLE_UPDATE,
    payload:api.getRoleUpdate()
})

export const getTypeInterval = (data) =>({
    type:ACTION_TYPES.GET_TYPE_INTERVAL,
    payload:api.fetchTypeInterval(data)
})


export const getAddress = (data) =>({
    type:ACTION_TYPES.GET_ADDRESS,
    payload:api.fetchAddress(data)
})

export const postType = (data) => ({
    type:ACTION_TYPES.POST_TYPE,
    payload:api.postType(data)
})

export const getLastPayment = (data)=>({
    type:ACTION_TYPES.GET_LAST_PAYMENT,
    payload:api.fetchLastPayment(data)
})

export const postGenerateInvoice = (data) => ({
    type:ACTION_TYPES.POST_GENERATE_INVOICE,
    payload:api.postGenerateInvoice(data)
})

export const postPdfGenerator = (data) =>({
    type:ACTION_TYPES.POST_PDF_GENERATOR,
    payload:api.postPdfGenerate(data)
})

export const postTypeInterval = (data) => ({
    type: ACTION_TYPES.POST_TYPE_INTERVAL,
    payload: api.postTypeInterval(data)
})

export const paypalPayment = () => ({
    type: ACTION_TYPES.POST_PAYPAL_PAYMENT,
    payload: api.paypalPayment()
})

export const getUnavailableFeature_team = (type, teamId) => ({
    type: ACTION_TYPES.GET_UNAVAILABLE_FEATURES_TEAM,
    payload: api.getUnavailableFeature_team(type, teamId)
})

export const getAvailableFeature_team = (type, teamId) => ({
    type: ACTION_TYPES.GET_MARKET_FEATURES_TEAM,
    payload: api.getAvailableFeature_team(type, teamId)
})

export const getPermissions_featureList_team = (type, teamId) => ({
    type: ACTION_TYPES.GET_FEATURES_LIST_TEAM,
    payload: api.getPermissions_featureList_team(type, teamId)
})

export const addFeature_team = (data) => ({
    type: ACTION_TYPES.ADD_FEATURE_TEAM,
    payload: api.addFeature_team(data)
})

export const startFeature_team = (data) => ({
    type: ACTION_TYPES.START_FEATURE_TEAM,
    payload: api.startFeature_team(data)
})

export const deleteFeature_team = (data) => ({
    type: ACTION_TYPES.DELETE_FEATURE_TEAM,
    payload: api.deleteFeature_team(data)
})

export const stopFeature_team = (data) => ({
    type: ACTION_TYPES.STOP_FEATURE_TEAM,
    payload: api.stopFeature_team(data)
})

export const stopCustomFeat_team = (data) => ({
    type: ACTION_TYPES.STOP_CUSTOM_FEATURE_TEAM,
    payload: api.stopCustomFeat_team(data)
})

export const startCustomFeat_team = (data) => ({
    type: ACTION_TYPES.START_CUSTOM_FEATURE_TEAM,
    payload: api.startCustomFeat_team(data)
})

export const cancelPlan_admin = (data) => ({
    type: ACTION_TYPES.CANCEL_PLAN_ADMIN,
    payload: api.cancelPlan_admin(data)
})

export const securePaymentStripe = (data) => ({
    type: ACTION_TYPES.SECURE_PAYMENT_STRIPE,
    payload: api.securePaymentStripe(data)
}) 

export const integrateXero = (data) => ({
    type: ACTION_TYPES.GET_XERO_INTEGRATION,
    payload: api.integrateXero(data),
});

export const getXeroTenant = () => ({
    type: ACTION_TYPES.UPDATE_XERO_INTEGRATION,
    payload: api.getXeroTenant(),
});

export const xeroIntegrationUpdate = (data) => ({
    type: ACTION_TYPES.UPDATE_XERO_INTEGRATION,
    payload: api.xeroIntegrationUpdate(data),
});

export const getXeroIntegration = () => ({
    type: ACTION_TYPES.GET_XERO_INTEGRATION,
    payload: api.getXeroIntegration(),
});

export const disconnectXero = () => ({
    type: ACTION_TYPES.UPDATE_XERO_INTEGRATION,
    payload: api.disconnectXero(),
});
export const getPaypalPaymentRequest = (invoiceID) => ({
    type: ACTION_TYPES.POST_PAYPAL_PAYMENT,
    payload: api.getPaypalPaymentRequest(invoiceID),
});
export const getOrgDetails = () => ({
    type: ACTION_TYPES.ORG_DETAILS,
    payload: api.getOrgDetails(),
});
export const updateOrgDetails = (data) => ({
    type: ACTION_TYPES.ORG_DETAILS,
    payload: api.updateOrgDetails(data),
});
export const removeOrgImage = () => ({
    type: ACTION_TYPES.ORG_DETAILS,
    payload: api.removeOrgImage(),
});
export const getEventLogs = (org_uuid) => ({
    type: ACTION_TYPES.FETCH_EVENT_LOGS,
    payload: api.getEventLogs(org_uuid),
});
