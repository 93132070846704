import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import InputField from "../../Components/InputField";

import { useTranslation } from "react-i18next";

export default function CreateTeam(props) {
  const {
    onCancelClick,
    onSubmitClick,
    submitButtonName,
    cancelButtonName,
    onFieldChange,
    open,
    ...rest
  } = props;
  const cancelButtonRef = useRef(null);
  const [paidType, setPaidType] = useState("");
  const [description, setDescription] = useState("")

  const { t } = useTranslation("common");

  return (
    <Transition.Root show={open} as={Fragment} {...rest}>
      <Dialog
        as="div"
        className="fixed z-30 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onCancelClick}
      >
        <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-bold text-textprimary"
                  >
                    {t("create")} {t("team")}
                  </Dialog.Title>
                </div>
                <div className="mt-5">
                  <InputField
                    onInputChange={onFieldChange}
                    FieldName={`${t("name")}`}
                    name={"name"}
                    type={"text"}
                    id={"name"}
                    value={props.nameValue}
                    placeholder={""}
                  ></InputField>
                </div>
                {/* <div className="mt-3">
                  <InputField
                 onInputChange={(e)=>setDescription(e.target.value)}
                    FieldName={"Description"}
                    name={"Description"}
                    type={"text"}
                    id={"Description"}
                    className="py-4"
                     value={description}
                    placeholder={""}
                  ></InputField>
                </div> */}
                {/* <div className="mt-3">
                  <div className="w-full relative">
                    <div className="w-full mx-auto relative flex justify-between border border-gray-300 rounded-md pl-3 shadow-sm  focus-within:border-primary">
                      <div className="self-stretch justify-start items-center inline-flex w-[120px]">
                        <p className="text-[#6B7280] text-sm font-medium leading-4">
                          USD ($)
                        </p>
                      </div>
                      <label
                        htmlFor="usd"
                        className="absolute -top-2 left-3 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900 leading-4"
                      >
                        Emp. Billing Rate
                      </label>
                      <input
                        type="number"
                        className={
                          "block remove-arrow w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm "
                        }
                        id="usd"
                        min="0"
                      />
                      <div className="self-stretch justify-center items-center inline-flex">
                        <select
                          className="text-tiny rounded border-gray-300 focus-within:border-primary focus:outline-none hover:cursor-pointer"
                          onChange={(e) => setPaidType(e.target.value)}
                        >
                          <option value={"Per hour"}>Per hour</option>
                          <option value={"Fixed"}>Fixed</option>
                          <option value={"Fixed + % mode"}>Fixed + % mode</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* {paidType === "Fixed + % mode" && (
                  <div className="mt-3">
                    <div className="w-full  relative">
                      <div className="w-full mx-auto relative flex justify-between border border-gray-300 rounded-md pl-1 py-2 shadow-sm  focus-within:border-primary">
                        <div className="self-stretch justify-start items-center inline-flex w-[50px] pl-2">
                          <p className="text-[#6B7280] text-sm font-medium leading-4">
                            %
                          </p>
                        </div>
                        <label
                          htmlFor="percentage"
                          className="absolute -top-2 left-3 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900 leading-4"
                        >
                          Enter Percentage
                        </label>
                        <input
                          type="number"
                          className={
                            "block remove-arrow w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm "
                          }
                          id="percentage"
                          min="0"
                        />
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onSubmitClick}
                >
                  {submitButtonName}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={onCancelClick}
                  ref={cancelButtonRef}
                >
                  {cancelButtonName}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
