import React, { useEffect, useState } from "react";
import { getEventLogs } from "../../services/settings/action";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment/moment";
import { CircularProgress } from "@mui/material";

const EventsTab = (props) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    useEffect(() => {
        const org_uuid = localStorage.getItem("org_uuid");
        props.getEventLogs(org_uuid);
        const size = Math.floor((window.innerHeight - 300) / 45);
        if (size > 10) {
            setPageSize(size);
        }

        window.addEventListener("resize", () => {
            const size = Math.floor((window.innerHeight - 300) / 45);
            if (size > 10) {
                setPageSize(size);
            }
        });
        return () => {
            window.addEventListener("resize", () => {
                const size = Math.floor((window.innerHeight - 300) / 45);
                if (size > 10) {
                    setPageSize(size);
                }
            });
        };
    }, []);
    return (
        <div className="bg-white rounded-md shadow-md mt-4 p-5">
            <table className="w-full sm-m:max-w-full sm-m:overflow-x-auto sm-m:block text-left border border-collapse">
                <thead className="bg-gray-200 border border-collapse">
                    <th className="px-2 py-1 sm:px-4 sm:py-2 border-r border-gray-50">S No.</th>
                    <th className="px-2 py-1 sm:px-4 sm:py-2 border-r border-gray-50">
                        Description
                    </th>
                    <th className="px-2 py-1 sm:px-4 sm:py-2 border-r border-gray-50">
                        Activity Date
                    </th>
                </thead>
                <tbody className="border border-collapse w-full sm-m:text-tiny">
                    {props?.settingState?.isFetchEventLogs ? (
                        <tr className="w-full">
                            <td className="text-primary p-4 w-full flex items-center justify-center">
                                <CircularProgress color="inherit" />
                            </td>
                        </tr>
                    ) : (
                        props?.settingState?.EventLogs?.slice(
                            pageSize * currentPage,
                            pageSize * (currentPage + 1)
                        )?.map((log, idx) => (
                            <tr key={log.id} className="border border-collapse">
                                <td className="px-2 py-1 sm:px-4 sm:py-2 border-r">
                                    {pageSize * currentPage + idx + 1}.
                                </td>
                                <td className="px-2 py-1 sm:px-4 sm:py-2 border-r">
                                    {log.name.split("invitation").join(" invitation ")}
                                </td>
                                <td className="px-2 py-1 sm:px-4 sm:py-2 border-r">
                                    {moment(log.created).format("YYYY-MM-DD")}
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            <div className="flex items-center justify-center gap-4 p-4">
                <button
                    className="text-white bg-primary disabled:opacity-80 px-4 py-2 rounded"
                    disabled={currentPage === 0}
                    onClick={() => setCurrentPage(currentPage - 1)}>
                    Previous
                </button>
                <button
                    className="text-white bg-primary disabled:opacity-80 px-4 py-2 rounded"
                    disabled={
                        currentPage * pageSize + pageSize >= props?.settingState?.EventLogs?.length
                    }
                    onClick={() => setCurrentPage(currentPage + 1)}>
                    Next
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    settingState: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
    getEventLogs: (org_uuid) => dispatch(getEventLogs(org_uuid)),
});

export default withTranslation("common")(connect(mapStateToProps, mapDispatchToProps)(EventsTab));
