import React, { Fragment, useState, useEffect, useLayoutEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Link, NavLink,useLocation,useRouteMatch ,matchPath  } from "react-router-dom";
import SelectField from "../SelectField";
import moment from "moment";
import {
  XIcon,
  MenuAlt2Icon,
  BellIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  CogIcon,
  DocumentReportIcon,
  ClockIcon,
  BriefcaseIcon,
  LogoutIcon,
} from "@heroicons/react/outline";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ClientBillingIcon from "../../assets/ClientBilling.svg";
import {
  DownloadIcon,
  DesktopComputerIcon,
  CheckCircleIcon,
  SearchIcon,
  UserIcon,
} from "@heroicons/react/solid";

import "./index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SecureLogoutUser } from "../../Networks/Auth";
import { BlockRounded, ContactsRounded } from "@material-ui/icons";

import { storeToLocalStorage } from "../../services/globalState/actions";
import { actionCreators } from "../../services/globalState";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { useTranslation } from "react-i18next";
import Select from "react-select";

import USAPng from "../../images/united-states.png";
import SAng from "../../images/saudi-arabia.png";
import MexicoPng from "../../images/mexico.png";
import PortugalPng from "../../images/portugal.png";
import { getUserProfileImg } from "../../services/userProfile/actions";
import { CircularProgress } from "@material-ui/core";

const userNavigation = [
  { name: "Your Profile", href: "/profile" },
  { name: "Sign out", href: "" },
];

// languages list for user to select
const countryOptions = [
  { key: "en", value: "en", flag: "in", label: "English", image: USAPng },
  { key: "es", value: "es", flag: "es", label: "Spanish", image: MexicoPng },
  {
    key: "pt",
    value: "pt",
    flag: "pt",
    label: "Portuguese",
    image: PortugalPng,
  },
  { key: "sa", value: "sa", flag: "sa", label: "Arabic", image: SAng },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [pathname, SetPathName] = useState(window.location.pathname);
  const [domainName, setDomainName] = useState("");
  const [lan, setLan] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [userRoleAPI, setUserRoleAPI] = useState("");
  const { t, i18n } = useTranslation("common");
  const date = moment(new Date()).format("YYYY-MM-DD");
  const match = useRouteMatch();
  const [selectedTab, setSelectedTab] = useState("");
  const [obsselectedTab, obssetSelectedTab] = useState("");
  const [empselectedTab, empsetSelectedTab] = useState("");
  // const [navigationmenus, setNavigationMenus] = useState([
  //   { name: `${t('dashboard')}`, href: "/dashboard", icon: HomeIcon },
  //   { name: `${t('projects')}` + " &" + ` ${t('tasks')}`, href: "/projects", icon: BriefcaseIcon },
  //   { name: `${t('team')}`, href: "/team", icon: UsersIcon },
  //   { name: `${t('timeTrackerSideBar')}`, href: "/timetracker", icon: ClockIcon },
  //   { name: `${t('requests')}`, href: "/requests", icon: CheckCircleIcon },
  //   { name: `${t('activityMonitor')}`, href: "/activity-moniter", icon: DesktopComputerIcon},
  //   { name: `${t('reports')}`, href: "/reports", icon: DocumentReportIcon },
  //   { name: `${t('settings')}`, href: "/settings", icon: CogIcon },
  //   { name: `${t('download')}`, href: "/download", icon: DownloadIcon },
  // ]);

  const navigationmenus = [
    { name: t("dashboard"), href: "/dashboard", icon: HomeIcon },
    {
      name: t("projects") + " & " + t("tasks"),
      href: "/projects",
      icon: BriefcaseIcon,
    },
    { name: t("team"), href: "/team", icon: UsersIcon },
    {
      name: t("timeTrackerSideBar"),
      href: "/timetracker",
      icon: ClockIcon,
    },
    { name: t("requests"), href: "/requests", icon: CheckCircleIcon },
    {
      name: t("Client Billing"),
      href: "/client_billing",
      icon: ArticleOutlinedIcon,
    },
    {
      name: t("activityMonitor"),
      href: "/activity-moniter",
      icon: DesktopComputerIcon,
    },
    { name: t("blockedUrls"), href: "/blockurls", icon: BlockRounded },
    { name: t("reports"), href: "/reports", icon: DocumentReportIcon },
    { name: t("settings"), href: "/settings", icon: CogIcon },
    { name: t("download"), href: "/download", icon: DownloadIcon },
  ];

  const observernavigationmenus = [
    { name: t("dashboard"), href: "/dashboard", icon: HomeIcon },
    { name: t("tasks"), href: "/projects", icon: BriefcaseIcon },
    // { name: 'Team', href: '/team', icon: UsersIcon},
    {
      name: t("timeTrackerSideBar"),
      href: "/timetracker",
      icon: ClockIcon,
    },
    { name: t("requests"), href: "/requests", icon: CheckCircleIcon },
    {
      name: t("activityMonitor"),
      href: "/activity-moniter",
      icon: DesktopComputerIcon,
    },
    // { name: 'Reports', href: '/reports', icon: DocumentReportIcon },
  ];

  const empNavigationMenu = [
    { name: t("dashboard"), href: "/emp/dashboard", icon: HomeIcon },
    { name: t("requests"), href: "/emp/requests", icon: BriefcaseIcon },
    {
      name: t("projects") + " & " + t("tasks"),
      href: "/projects",
      icon: BriefcaseIcon,
    },
    {
      name: t("timeTrackerSideBar"),
      href: "/emp/timetracker/" + date,
      icon: ClockIcon,
    },
    { name: t("download"), href: "/download", icon: DownloadIcon },
  ];

  const userAuthState = JSON.parse(localStorage.getItem("s7_auth_state"));
  let userRole = localStorage.getItem("ro");

  const isSuperUser = userAuthState && userAuthState.is_superuser;

  const state = useSelector((state) => state);

  const { storeToLocalStorage, showData } = bindActionCreators(
    actionCreators,
    dispatch
  );
  
  useEffect(() => {
    const currentTab = navigationmenus.find((item) =>
      matchPath(location.pathname, { path:item.href,exact: true })
    );
    const temp= location.pathname.includes('settings')
    if(temp){
      setSelectedTab(t("settings"));
      sessionStorage.setItem('adminselectedTab',t("settings"))
      return;
    }
    const temp2=location.pathname.includes('team')
    if(temp2){
      setSelectedTab(t("team"))
      sessionStorage.setItem('adminselectedTab',t("team"))
      return;

    }
    const temp3=location.pathname.includes('projects')
    if(temp3){
      setSelectedTab(t("projects") + " & " + t("tasks"))
      sessionStorage.setItem('adminselectedTab',t("projects") + " & " + t("tasks"))
      return;

    }
    if (!currentTab) {
      const prevTab =sessionStorage.getItem('adminselectedTab');
      if (prevTab) {
        setSelectedTab(prevTab);
        return;
      }
    }
    if (!selectedTab || (currentTab && currentTab.name !== selectedTab)) {
      setSelectedTab(currentTab ?currentTab.name : '');
      sessionStorage.setItem('adminselectedTab',currentTab?currentTab.name : '');
    }
  }, [location.pathname,navigationmenus,selectedTab]);

  useEffect(() => {
    const currentTab2 = observernavigationmenus.find((item) =>
      matchPath(location.pathname, { path:item.href,exact: true })
    );
    if (!currentTab2) {
      const prevTab =sessionStorage.getItem('obsselectedTab');
      if (prevTab) {
        obssetSelectedTab(prevTab);
        return;
      }
    }
    if (!obsselectedTab || (currentTab2 && currentTab2.name !== obsselectedTab)) {
      obssetSelectedTab(currentTab2 ?currentTab2.name : '');
      sessionStorage.setItem('obsselectedTab',currentTab2?currentTab2.name : '');
    }
  }, [location.pathname,observernavigationmenus,obsselectedTab]);

  useEffect(() => {
    const currentTab3 = empNavigationMenu.find((item) =>
      matchPath(location.pathname, { path:item.href,exact: true })
    );
    if (!currentTab3) {
      const prevTab =sessionStorage.getItem('empselectedTab');
      if (prevTab) {
        empsetSelectedTab(prevTab);
        return;
      }
    }
    if (!empselectedTab || (currentTab3 && currentTab3.name !== empselectedTab)) {
      empsetSelectedTab(currentTab3 ?currentTab3.name : '');
      sessionStorage.setItem('empselectedTab',currentTab3?currentTab3.name : '');
    }
  }, [location.pathname,empNavigationMenu,empselectedTab]);

  const handleTab = (tabName) => {
    setSelectedTab(tabName);
    sessionStorage.setItem('adminselectedTab',tabName);
  };
  const handleTab2 = (tabName) => {
    obssetSelectedTab(tabName);
  };
  const handleTab3 = (tabName) => {
    empsetSelectedTab(tabName);
  };
  

  useEffect(() => {
    const url = window.location.href;
    let appNameURL = url
      .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
      .split("/")[0];
    setDomainName(appNameURL);
    dispatch(getUserProfileImg("/users")).then((res) => {
      if (res.value.status === 200 && res.value.data && res.value.data.url) {
        setProfileImg(res.value.data.url);
      }
    });
    const lang = localStorage.getItem("language");
    const defaultLang = lang;
    if (defaultLang == "en") {
      localStorage.setItem("languageFlag", JSON.stringify(countryOptions[0]));
    } else if (defaultLang == "es") {
      localStorage.setItem("languageFlag", JSON.stringify(countryOptions[1]));
    } else if (defaultLang == "pt") {
      localStorage.setItem("languageFlag", JSON.stringify(countryOptions[2]));
    } else if (defaultLang == "sa") {
      localStorage.setItem("languageFlag", JSON.stringify(countryOptions[3]));
    }
    rolesCheck();
  }, [state.globalLocalStorageReducer, lan, localStorage.getItem("ro")]);

  const rolesCheck = () => {
    fetch(localStorage.getItem("apiURL") + `/api/roles_check/`, {
      method: "GET",
      headers: {
        Authorization: "JWT " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 401) {
          let refresh_token = localStorage.getItem("refresh_token");
          fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              refresh: refresh_token,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              localStorage.setItem("access_token", data.access);
              localStorage.setItem("refresh_token", data.refresh);
              rolesCheck();
            });
        }
      })
      .then((data) => {
        if (data && data.length > 0) {
          localStorage.setItem("roleUser", data[0].role);
          localStorage.setItem("roleUserORG", data[0].org);
          storeToLocalStorage(data[0].role);
        }
      });
  };

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.value);
    localStorage.setItem("language", e.value);
    localStorage.setItem("languageFlag", JSON.stringify(e));
    setLan(e.value);
  };
  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>

              <div className="flex-shrink-0 flex items-center px-4">
                {domainName ==
                  `${localStorage.getItem("org_name")}.joinworkahub.com` ||
                "app.joinworkahub.com" ? (
                  <>
                    <a href="/dashboard">
                      <img
                        className="block mt-1 h-6 w-auto"
                        src={require("../../images/WLogo.png")}
                        alt=""
                      />
                    </a>
                    <a>
                      <img
                        className="block ml-1 h-5 w-auto"
                        src={require("../../images/workahub.png")}
                        alt=""
                      />
                    </a>
                  </>
                ) : (
                  <>
                    <a href="/dashboard">
                      <img
                        className="block mt-1 h-6 w-auto"
                        src={require("../../images/newSamtap_logo.png")}
                        alt=""
                      />
                    </a>
                  </>
                )}
              </div>

              {!state.globalLocalStorageReducer ? (
                <div className="w-full flex flex-col justify-center items-center">
                  <CircularProgress className="text-primary" />
                </div>
              ) : state.globalLocalStorageReducer == "admin" ? (
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {navigationmenus.map((item) => {  
                      return (
                      <Link
                        key={item.name}
                        to={item.href}
                        onClick={() => handleTab(item.name)}
                        className={classNames(
                          // pathname == item.href
                          // location.pathname.includes(item.href)
                          selectedTab === item.name
                            ? "bg-gray-200 text-gray-900"
                            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-gray-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                      )
                    })}
                    {/* <select className='h-9 rounded-md border-gray-400' defaultValue={localStorage.getItem('roleUser') == 'admin' ? `${localStorage.getItem('roleUserORG')} admin` : localStorage.getItem('roleUser') == 'observer' ? `${localStorage.getItem('roleUserORG')} observer` : `${localStorage.getItem('roleUserORG')} manager` } onChange={(e) => {setUserRoleAPI(e.target.value); setUserRole(e.target.value)}}>
                            { state.globalLocalStorageReducer == 'admin' ? 
                                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                    <nav className="px-2 space-y-1">
                                        {navigationmenus.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.href}
                                                
                                                className={classNames(
                                                    pathname == item.href ? 'bg-gray-200 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                                )}
                                            >
                                                <item.icon
                                                    className={classNames(
                                                        item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                        'mr-3 flex-shrink-0 h-6 w-6'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </Link>
                                        ))}
                                        
                                         {/* <select className='h-9 rounded-md border-gray-400' defaultValue={localStorage.getItem('roleUser') == 'admin' ? `${localStorage.getItem('roleUserORG')} admin` : localStorage.getItem('roleUser') == 'observer' ? `${localStorage.getItem('roleUserORG')} observer` : `${localStorage.getItem('roleUserORG')} manager` } onChange={(e) => {setUserRoleAPI(e.target.value); setUserRole(e.target.value)}}>
                                                <option className=''>{localStorage.getItem('roleUserORG')} admin</option>
                                                <option>{localStorage.getItem('roleUserORG')} observer</option>
                                                <option>{localStorage.getItem('roleUserORG')} manager</option>
                                            </select> */}
                  </nav>
                </div>
              ) : state.globalLocalStorageReducer == "observer" ? (
                <div className="mt-5 flex-1 flex flex-col">
                  <nav className="flex-1 px-2 pb-4 space-y-1">
                    {observernavigationmenus.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        onClick={() => handleTab2(item.name)}
                        className={classNames(
                          // pathname == item.href
                          obsselectedTab === item.name
                            ? "bg-gray-200 text-gray-900"
                            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-gray-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                    {/* <select className='h-9 rounded-md border-gray-400' defaultValue={localStorage.getItem('roleUser') == 'admin' ? `${localStorage.getItem('roleUserORG')} admin` : localStorage.getItem('roleUser') == 'observer' ? `${localStorage.getItem('roleUserORG')} observer` : `${localStorage.getItem('roleUserORG')} manager` } onChange={(e) => {setUserRoleAPI(e.target.value); setUserRole(e.target.value)}}>
                                    </nav>
                                </div>

                            : state.globalLocalStorageReducer == 'observer' ? 
                            <div className="mt-5 flex-1 flex flex-col">
                                <nav className="flex-1 px-2 pb-4 space-y-1">
                                    {observernavigationmenus.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                        
                                            className={classNames(
                                                pathname == item.href ? 'bg-gray-200 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                    'mr-3 flex-shrink-0 h-6 w-6'
                                                )}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </Link>
                                    ))}
                                    
                                     {/* <select className='h-9 rounded-md border-gray-400' defaultValue={localStorage.getItem('roleUser') == 'admin' ? `${localStorage.getItem('roleUserORG')} admin` : localStorage.getItem('roleUser') == 'observer' ? `${localStorage.getItem('roleUserORG')} observer` : `${localStorage.getItem('roleUserORG')} manager` } onChange={(e) => {setUserRoleAPI(e.target.value); setUserRole(e.target.value)}}>
                                            <option className=''>{localStorage.getItem('roleUserORG')} admin</option>
                                            <option>{localStorage.getItem('roleUserORG')} observer</option>
                                            <option>{localStorage.getItem('roleUserORG')} manager</option>
                                        </select> */}
                  </nav>
                </div>
              ) : (
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {empNavigationMenu.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        onClick={() => handleTab3(item.name)}
                        className={classNames(
                          // pathname == item.href
                          empselectedTab === item.name
                            ? "bg-gray-200 text-gray-900"
                            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-gray-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </nav>
                </div>
              )}
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/*Static sidebar for desktop*/}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like*/}
        <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-gray overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            {domainName ==
              `${localStorage.getItem("org_name")}.joinworkahub.com` ||
            "app.joinworkahub.com" ? (
              <>
                <a href="/dashboard">
                  <img
                    className="block mt-1 h-6 w-auto"
                    src={require("../../images/WLogo.png")}
                    alt=""
                  />
                </a>
                <a>
                  <img
                    className="block ml-1 h-5 w-auto"
                    src={require("../../images/workahub.png")}
                    alt=""
                  />
                </a>
              </>
            ) : (
              <>
                <a href="/dashboard">
                  <img
                    className="block mt-1 h-[45px] w-auto -translate-y-1"
                    src={require("../../images/newSamtap_logo.png")}
                    alt=""
                  />
                </a>
              </>
            )}
          </div>
          {!state.globalLocalStorageReducer ? (
            <div className="w-full flex min-h-[50vh] flex-col justify-center items-center">
              <CircularProgress className="text-primary" />
            </div>
          ) : state.globalLocalStorageReducer == "admin" ? (
            <div className="mt-5 flex-1 flex flex-col">
              <nav className="flex-1 px-2 pb-4 space-y-1">
                {navigationmenus.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => handleTab(item.name)}
                    className={classNames(
                      // pathname == item.href
                      // location.pathname.includes(item.href)
                      selectedTab === item.name
                        ? "bg-gray-200 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
          ) : state.globalLocalStorageReducer == "observer" ? (
            <div className="mt-5 flex-1 flex flex-col">
              <nav className="flex-1 px-2 pb-4 space-y-1">
                {observernavigationmenus.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => handleTab2(item.name)}
                    className={classNames(
                      // pathname == item.href
                      obsselectedTab === item.name
                        ? "bg-gray-200 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
          ) : (
            <div className="mt-5 flex-1 flex flex-col">
              <nav className="flex-1 px-2 pb-4 space-y-1">
                {empNavigationMenu.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => handleTab3(item.name)}
                    className={classNames(
                      // pathname == item.href
                      empselectedTab === item.name
                        ? "bg-gray-200 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
          )}
        </div>
      </div>
      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-20 flex-shrink-0 flex h-16 bg-white shadow">
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-between">
            <div className="flex-1 flex"></div>
            {/* dropdown for the language translate */}
            <div className="mx-1 w-45 mt-3">
              <div>
                <Select
                  value={JSON.parse(localStorage.getItem("languageFlag"))}
                  options={countryOptions}
                  defaultValue={countryOptions[0]}
                  onChange={(e) => {
                    handleLanguageChange(e);
                  }}
                  isSearchable={false}
                  formatOptionLabel={(country) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={country.image}
                        alt={country.label}
                        height={20}
                        width={20}
                        style={{ marginRight: "4px" }}
                      />
                      <span>{country.label}</span>
                    </div>
                  )}
                  // styles={customStyles}
                />
              </div>
            </div>
            <div className="ml-2 flex items-center md:ml-6">
              {/* Profile dropdowns*/}
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button
                    className={`max-w-md w-8 h-8 overflow-hidden bg-gray-400 border-2 border-gray-500 flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`}
                  >
                    <span className="sr-only">Open user menu</span>
                    {profileImg ? (
                      <img
                        className="h-7 w-7 min-w-[28px] min-h-[28px] rounded-full"
                        src={profileImg}
                        alt="profilephoto"
                      />
                    ) : (
                      <UserIcon className="h-7 w-7 text-white m-1" />
                    )}
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {state.globalLocalStorageReducer !== "admin" && (
                      <Menu.Item key={userNavigation[0].name}>
                        {({ active }) => (
                          <Link
                            to={userNavigation[0].href}
                            className={classNames(
                              active ? "bg-gray-200" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            {
                              <div className="flex">
                                <div className="bg-white w-5 h-5 rounded-full border border-textprimary text-textprimary mr-4">
                                  <UserIcon />
                                </div>
                                <div>{userNavigation[0].name}</div>
                              </div>
                            }
                          </Link>
                        )}
                      </Menu.Item>
                    )}
                    <Menu.Item key={userNavigation[1].name}>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            SecureLogoutUser(domainName);
                          }}
                          className={classNames(
                            active ? "bg-gray-200" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          {
                            <div className="flex">
                              <div className="w-5 h-5 text-textprimary mr-4">
                                <LogoutIcon />
                              </div>
                              <div>{userNavigation[1].name}</div>
                            </div>
                          }
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="bg-gray-100">
          <div className="pt-6 pb-2 px-4 sm:px-6 md:px-8 min-h-[91vh]">
            {props.children}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover={false}
            />
          </div>
        </main>
      </div>
    </div>
  );
}
