import { ACTION_TYPES } from "../../../constants";

const settings = (state = {},action) =>{
    switch (action.type) {
        case ACTION_TYPES.GET_PAYMENT_PLAN_PENDING: {
            return {
                ...state,
                isFetchingPaymentPlan: true,
                isFetchingPaymentPlanFailed: false,
                fetchingPaymentPlanError: undefined,
                paymentPlan: [],
            };
        }

        case ACTION_TYPES.GET_PAYMENT_PLAN_FULFILLED: {
            return {
                ...state,
                isFetchingPaymentPlan: false,
                isFetchingPaymentPlanFailed: false,
                fetchingPaymentPlanError: undefined,
                paymentPlan: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_PAYMENT_PLAN_REJECTED: {
            return {
                ...state,
                isFetchingPaymentPlan: false,
                isFetchingPaymentPlanFailed: true,
                fetchingPaymentPlanError: action.payload,
                paymentPlan: [],
            };
        }

        case ACTION_TYPES.FETCH_ORG_UUID_PENDING: {
            return {
                ...state,
                isFetchOrgUuid: true,
                isFetchOrgUuidFailed: false,
                fetchingOrgUuidError: undefined,
                orgUuid: [],
            };
        }

        case ACTION_TYPES.FETCH_ORG_UUID_FULFILLED: {
            return {
                ...state,
                isFetchOrgUuid: false,
                isFetchOrgUuidFailed: false,
                fetchingOrgUuidError: undefined,
                orgUuid: action.payload.data,
            };
        }

        case ACTION_TYPES.FETCH_ORG_UUID_REJECTED: {
            return {
                ...state,
                isFetchOrgUuid: false,
                isFetchOrgUuidFailed: true,
                fetchingOrgUuidError: action.payload,
                orgUuid: [],
            };
        }

        case ACTION_TYPES.FETCH_API_KEYS_PENDING: {
            return {
                ...state,
                isFetchApiKeys: true,
                isFetchApiKeysFailed: false,
                fetchingApiKeysError: undefined,
                apiKeys: [],
            };
        }

        case ACTION_TYPES.FETCH_API_KEYS_FULFILLED: {
            return {
                ...state,
                isFetchApiKeys: false,
                isFetchApiKeysFailed: false,
                isFetchApiKeysError: undefined,
                apiKeys: action.payload.data,
            };
        }

        case ACTION_TYPES.FETCH_API_KEYS_REJECTED: {
            return {
                ...state,
                isFetchApiKeys: false,
                isFetchApiKeysFailed: false,
                isFetchApiKeysError: action.payload,
                apiKeys: [],
            };
        }

        case ACTION_TYPES.FETCH_SECRET_KEYS_PENDING: {
            return {
                ...state,
                isFetchSecretKey: true,
                isFetchSecretKeyFailed: false,
                isFetchSecretKeyError: undefined,
                secretKey: [],
            };
        }

        case ACTION_TYPES.FETCH_SECRET_KEYS_FULFILLED: {
            return {
                ...state,
                isFetchSecretKey: false,
                isFetchSecretKeyFailed: false,
                isfetchsecretkeyError: undefined,
                secretKey: action.payload.data,
            };
        }

        case ACTION_TYPES.FETCH_SECRET_KEYS_REJECTED: {
            return {
                ...state,
                isFetchSecretKey: false,
                isFetchSecretKeyFailed: true,
                isFetchSecretKeyError: action.payload,
                secretKey: [],
            };
        }

        case ACTION_TYPES.POST_SECRET_KEY_PENDING: {
            return {
                ...state,
                isPostSecretKey: false,
                isPostSecretKeyFailed: false,
                isPostSecretKeyError: undefined,
                postSecretKey: [],
            };
        }

        case ACTION_TYPES.POST_SECRET_KEY_FULFILLED: {
            return {
                ...state,
                isPostSecretKey: false,
                isPostSecretKeyFailed: false,
                isPostSecretKeyError: undefined,
                postSecretKey: action.payload.data,
            };
        }

        case ACTION_TYPES.POST_SECRET_KEY_REJECTED: {
            return {
                ...state,
                isPostSecretKey: false,
                isPostSecretkeyFailed: false,
                isPostSecretKeyError: action.payload,
                postSecretKey: [],
            };
        }

        case ACTION_TYPES.DELETE_KEY_PENDING: {
            return {
                ...state,
                isDeleteKey: true,
                isDeleteKeyFailed: false,
                isDeletekeyError: undefined,
                deleteKey: [],
            };
        }

        case ACTION_TYPES.DELETE_KEY_FULFILLED: {
            return {
                ...state,
                isDeleteKey: false,
                isDeleteKeyFailed: false,
                isDeletekeyError: undefined,
                deleteKey: action.payload.data,
            };
        }

        case ACTION_TYPES.DELETE_KEY_REJECTED: {
            return {
                ...state,
                isDeleteKey: false,
                isDeleteKeyFailed: true,
                isDeletekeyError: action.payload,
                deleteKey: [],
            };
        }

        case ACTION_TYPES.API_KEY_STATUS_UPDATE_PENDING: {
            return {
                ...state,
                isPatchStatusUpdate: true,
                isPatchStatusUpdateFailed: false,
                isPatchStatusUpdateError: undefined,
                apiPatchStatus: [],
            };
        }

        case ACTION_TYPES.API_KEY_STATUS_UPDATE_FULFILLED: {
            return {
                ...state,
                isPatchStatusUpdate: false,
                isPatchStatusUpdateFailed: false,
                isPatchStatusUpdateError: undefined,
                apiPatchStatus: action.payload.data,
            };
        }

        case ACTION_TYPES.API_KEY_STATUS_UPDATE_REJECTED: {
            return {
                ...state,
                isPatchStatusUpdate: false,
                isPatchStatusUpdateFailed: true,
                isPatchStatusUpdateError: action.payload,
                apiPatchStatus: [],
            };
        }

        case ACTION_TYPES.GET_FEATURE_UNAVAILABLE_PENDING: {
            return {
                ...state,
                isGetFeatureUnavailable: true,
                isGetFeatureUnavailableFailed: false,
                isGetFeatureUnavailableError: undefined,
                getFeatureUnavailable: [],
            };
        }

        case ACTION_TYPES.GET_FEATURE_UNAVAILABLE_FULFILLED: {
            return {
                ...state,
                isGetFeatureUnavailable: false,
                isGetFeatureUnavailableFailed: false,
                isGetFeatureUnavailableError: undefined,
                getFeatureUnavailable: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_FEATURE_UNAVAILABLE_REJECTED: {
            return {
                ...state,
                isGetFeatureUnavailable: false,
                isGetFeatureUnavailableFailed: true,
                isGetFeatureUnavailableError: action.payload,
                getFeatureUnavailable: [],
            };
        }

        case ACTION_TYPES.GET_FEATURE_MARKET_PENDING: {
            return {
                ...state,
                isGetFeatureMarket: true,
                isGetFeatureMarketFailed: false,
                isGetFeatureMarketError: undefined,
                getFeatureMarket: [],
            };
        }

        case ACTION_TYPES.GET_FEATURE_MARKET_FULFILLED: {
            return {
                ...state,
                isGetFeatureMarket: false,
                isGetFeatureMarketFailed: false,
                isGetFeatureMarketError: undefined,
                getFeatureMarket: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_FEATURE_MARKET_REJECTED: {
            return {
                ...state,
                isGetFeatureMarket: false,
                isGetFeatureMarketFailed: false,
                isGetFeatureMarketError: action.payload,
                getFeatureMarket: [],
            };
        }

        case ACTION_TYPES.FETCH_FEATURE_LIST_PENDING: {
            return {
                ...state,
                isFetchingFeatureList: true,
                isFetchingFeatureListFailed: false,
                isFetchingFeatureListError: undefined,
                featureList: [],
            };
        }

        case ACTION_TYPES.FETCH_FEATURE_LIST_FULFILLED: {
            return {
                ...state,
                isFetchingFeatureList: false,
                isFetchingFeatureListFailed: false,
                isFetchingFeatureListError: undefined,
                featureList: action.payload.data,
            };
        }

        case ACTION_TYPES.FETCH_FEATURE_LIST_REJECTED: {
            return {
                ...state,
                isFetchingFeatureList: false,
                isFetchingFeatureListFailed: true,
                isFetchingFeatureListError: action.payload,
                featureList: [],
            };
        }

        case ACTION_TYPES.FETCH_OVERIDE_FEATURE_LIST_PENDING: {
            return {
                ...state,
                isFetchingOverideFeatureList: true,
                isFetchingOverideFeatureListFailed: false,
                isFetchingOverideFeatureListError: undefined,
                overideFeatureList: [],
            };
        }

        case ACTION_TYPES.FETCH_OVERIDE_FEATURE_LIST_FULFILLED: {
            return {
                ...state,
                isFetchingOverideFeatureList: false,
                isFetchingOverideFeatureListFailed: false,
                isFetchingOverideFeatureListError: undefined,
                overideFeatureList: action.payload.data.Status,
            };
        }

        case ACTION_TYPES.FETCH_OVERIDE_FEATURE_LIST_REJECTED: {
            return {
                ...state,
                isFetchingOverideFeatureList: false,
                isFetchingOverideFeatureListFailed: true,
                isFetchingOverideFeatureListError: action.payload,
                overideFeatureList: [],
            };
        }

        case ACTION_TYPES.FETCH_SCREENSHOT_TIMING_PENDING: {
            return {
                ...state,
                isFetchingOverideFeatureList: true,
                isFetchingOverideFeatureListFailed: false,
                isFetchingOverideFeatureListError: undefined,
                overideFeatureList: [],
            };
        }

        case ACTION_TYPES.FETCH_SCREENSHOT_TIMING_FULFILLED: {
            return {
                ...state,
                isFetchingScreenShotTiming: false,
                isFetchingScreenShotTimingFailed: false,
                isFetchingScreenShotTimingError: undefined,
                screenShotTiming: action.payload.data,
            };
        }

        case ACTION_TYPES.FETCH_SCREENSHOT_TIMING_REJECTED: {
            return {
                ...state,
                isFetchingScreenShotTiming: false,
                isFetchingScreenShotTimingFailed: true,
                isFetchingScreenShotTimingError: action.payload,
                screenShotTiming: [],
            };
        }

        case ACTION_TYPES.POST_SCREENSHOT_TIMING_PENDING: {
            return {
                ...state,
                isPostScreenShotTiming: true,
                isPostScreenShotTimingFailed: false,
                isPostScreenShotTimingError: undefined,
                postScreenShotTiming: [],
            };
        }

        case ACTION_TYPES.POST_SCREENSHOT_TIMING_FULFILLED: {
            return {
                ...state,
                isPostScreenShotTiming: false,
                isPostScreenShotTimingFailed: false,
                isPostScreenShotTimingError: undefined,
                postScreenShotTiming: action.payload.data,
            };
        }

        case ACTION_TYPES.POST_SCREENSHOT_TIMING_REJECTED: {
            return {
                ...state,
                isPostScreenShotTiming: false,
                isPostScreenShotTimingFailed: true,
                isPostScreenShotTimingError: action.payload,
                postScreenShotTiming: [],
            };
        }

        case ACTION_TYPES.PATCH_SCREENSHOT_TIMING_PENDING: {
            return {
                ...state,
                isPatchScreenShotTiming: true,
                isPatchScreenShotTimingFailed: false,
                isPatchScreenShotTimingError: undefined,
                patchScreenShotTiming: [],
            };
        }
        case ACTION_TYPES.PATCH_SCREENSHOT_TIMING_FULFILLED: {
            return {
                ...state,
                isPatchScreenShotTiming: false,
                isPatchScreenShotTimingFailed: false,
                isPatchScreenShotTimingError: undefined,
                patchScreenShotTiming: action.payload.data,
            };
        }
        case ACTION_TYPES.PATCH_SCREENSHOT_TIMING_REJECTED: {
            return {
                ...state,
                isPatchScreenShotTiming: false,
                isPatchScreenShotTimingFailed: true,
                isPatchScreenShotTimingError: action.payload,
                patchScreenShotTiming: [],
            };
        }

        case ACTION_TYPES.GET_FEATURE_KEY_PENDING: {
            return {
                ...state,
                isGetFeatureKey: true,
                isGetFeatureKeyFailed: false,
                isGetFeaturekeyEror: undefined,
                getFeaturekey: [],
            };
        }

        case ACTION_TYPES.GET_FEATURE_KEY_FULFILLED: {
            return {
                ...state,
                isGetFeatureKey: false,
                isGetFeatureKeyFailed: false,
                isGetFeatureKeyError: undefined,
                getFeatureKey: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_FEATURE_KEY_REJECTED: {
            return {
                ...state,
                isGetFeatureKey: false,
                isGetFeatureKeyFailed: true,
                isGetFeatureKeyError: action.payload,
                getFeatureKey: [],
            };
        }

        case ACTION_TYPES.POST_FEATURE_START_PENDING: {
            return {
                ...state,
                isPostFeatureStart: true,
                isPostFeatureStartFailed: false,
                isPostFeatureStartError: undefined,
                postFeatureStart: [],
            };
        }

        case ACTION_TYPES.POST_FEATURE_START_FULFILLED: {
            return {
                ...state,
                isPostFeatureStart: false,
                isPOstFeatureStartFailed: false,
                isPostFeatureStartError: undefined,
                postFeatureStart: action.payload.data,
            };
        }

        case ACTION_TYPES.POST_FEATURE_START_REJECTED: {
            return {
                ...state,
                isPostFeatureStart: false,
                isPostFeatureStartFailed: true,
                isPostFeatureStartError: action.payload,
                postFeatureStart: [],
            };
        }

        case ACTION_TYPES.DELETE_FEATURE_MARKET_PENDING: {
            return {
                ...state,
                isDeleteFeatureMarket: true,
                isDeleteFeatureMarketFailed: false,
                isDeleteFeatureMarketError: undefined,
                deleteFeatureMarket: [],
            };
        }

        case ACTION_TYPES.DELETE_FEATURE_MARKET_FULFILLED: {
            return {
                ...state,
                isDeleteFeatureMarket: false,
                isDeleteFeatureMarketFailed: false,
                isDeleteFeatureMarketError: undefined,
                deleteFeatureMarket: action.payload.data,
            };
        }

        case ACTION_TYPES.DELETE_FEATURE_MARKET_REJECTED: {
            return {
                ...state,
                isDeleteFeatureMarket: false,
                isDeleteFeatureMarketFailed: true,
                isDeleteFeatureMarketError: action.payload,
                deleteFeatureMarket: [],
            };
        }

        case ACTION_TYPES.DELETE_FEATURE_START_PENDING: {
            return {
                ...state,
                isDeleteFeatureStart: true,
                isDeleteFeatureStartFailed: false,
                isDeleteFeatureStartError: undefined,
                deleteFeatureStart: [],
            };
        }

        case ACTION_TYPES.DELETE_FEATURE_START_FULFILLED: {
            return {
                ...state,
                isDeleteFeatureStart: false,
                isDeleteFeatureStartFailed: false,
                isDeleteFeatureStartError: undefined,
                deleteFeatureStart: action.payload.data,
            };
        }

        case ACTION_TYPES.DELETE_FEATURE_START_REJECTED: {
            return {
                ...state,
                isDeleteFeatureStart: false,
                isDeleteFeatureStartFailed: true,
                isDeleteFeatureStartError: action.payload,
                deleteFeatureStart: [],
            };
        }

        case ACTION_TYPES.POST_FEATURE_MARKET_PENDING: {
            return {
                ...state,
                isPostFeatureMarket: true,
                isPostFeatureMarketFailed: false,
                isPostFeatureMarketError: undefined,
                postFeatureMarket: [],
            };
        }

        case ACTION_TYPES.POST_FEATURE_MARKET_FULFILLED: {
            return {
                ...state,
                isPostFeatureMarket: false,
                isPostFeatureMarketFailed: false,
                isPostFeatureMarketError: undefined,
                postFeatureMarket: action.payload.data,
            };
        }

        case ACTION_TYPES.POST_FEATURE_MARKET_REJECTED: {
            return {
                ...state,
                isPostFeatureMarket: false,
                isPostFeatureMarketFailed: true,
                isPostFeatureMarketError: action.payload,
                postFeatureMarket: [],
            };
        }

        case ACTION_TYPES.PUT_FEATURE_ENABLE_PENDING: {
            return {
                ...state,
                isPutFeatureEnable: true,
                isPutFeatureEnableFailed: false,
                isPutFeatureEnableError: undefined,
                putFeatureEnable: [],
            };
        }

        case ACTION_TYPES.PUT_FEATURE_ENABLE_FULFILLED: {
            return {
                ...state,
                isPutFeatureEnable: false,
                isPutFeatureEnableFailed: false,
                isPutFeatureEnableError: undefined,
                putFeatureEnable: action.payload.data,
            };
        }

        case ACTION_TYPES.PUT_FEATURE_ENABLE_REJECTED: {
            return {
                ...state,
                isPutFeatureEnable: false,
                isPutFeatureEnableFailed: true,
                isPutFeatureEnableError: action.payload,
                putFeatureStop: [],
            };
        }

        case ACTION_TYPES.PUT_FEATURE_STOP_PENDING: {
            return {
                ...state,
                isPutFeatureStop: true,
                isPutFeatureStopFailed: false,
                isPutFeatureStopError: undefined,
                putFeatureStop: [],
            };
        }

        case ACTION_TYPES.PUT_FEATURE_STOP_FULFILLED: {
            return {
                ...state,
                isPutFeatureStop: false,
                isPutFeatureStopFailed: false,
                isPutFeatureStopError: undefined,
                putFeatureStop: action.payload.data,
            };
        }

        case ACTION_TYPES.PUT_FEATURE_STOP_REJECTED: {
            return {
                ...state,
                isPutFeatureStop: false,
                isPutFeatureStopFailed: false,
                isPutFeatureStopError: action.payload,
                putFeatureStop: [],
            };
        }

        case ACTION_TYPES.POST_FORGET_PASSWORD_PENDING: {
            return {
                ...state,
                isPostForgetPassword: true,
                isPostForgetPasswordFailed: false,
                isPostForgetPasswordError: undefined,
                postForgetPassword: [],
            };
        }

        case ACTION_TYPES.POST_FORGET_PASSWORD_FULFILLED: {
            return {
                ...state,
                isPostForgetPassword: false,
                isPostForgetPasswordFailed: false,
                isPostForgetPasswordError: undefined,
                postForgetPassword: action.payload.data,
            };
        }

        case ACTION_TYPES.POST_FORGET_PASSWORD_REJECTED: {
            return {
                ...state,
                isPostForgetPassword: false,
                isPostForgetPasswordFailed: false,
                isPostForgetPasswordError: action.payload,
                postForgetPassword: [],
            };
        }

        case ACTION_TYPES.GET_SUBSCRIBE_EMAIL_PENDING: {
            return {
                ...state,
                isPostSubscribeEmail: true,
                isPostSubscribeEmailFailed: false,
                isPostSubscribeEmailError: undefined,
                postSubscribeEmail: [],
            };
        }

        case ACTION_TYPES.GET_SUBSCRIBE_EMAIL_FULFILLED: {
            return {
                ...state,
                isPostSubscribeEmail: false,
                isPostSubscribeEmailFailed: false,
                isPostSubscribeEmailError: undefined,
                postSubscribeEmail: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_SUBSCRIBE_EMAIL_REJECTED: {
            return {
                ...state,
                isPostSubscribeEmail: false,
                isPostSubscribeEmailFailed: true,
                isPostSubscribeEmailError: action.payload,
                postSubscribeEmail: [],
            };
        }

        case ACTION_TYPES.UPDATE_SUBSCRIBE_EMAIL_PENDING: {
            return {
                ...state,
                isPutSubscribeEmail: true,
                isPutSubscribeEmailFailed: false,
                isPutSubscribeEmailError: undefined,
                putSubscribeEmail: [],
            };
        }

        case ACTION_TYPES.UPDATE_SUBSCRIBE_EMAIL_FULFILLED: {
            return {
                ...state,
                isPutSubscribeEmail: false,
                isPutSubscribeEmailFailed: false,
                isPutSubscribeEmailError: undefined,
                putSubscribeEmail: action.payload.data,
            };
        }

        case ACTION_TYPES.UPDATE_SUBSCRIBE_EMAIL_REJECTED: {
            return {
                ...state,
                isPutSubscribeEmail: false,
                isPutSubscribeEmailFailed: true,
                isPutSubscribeEmailError: action.payload,
                putSubscribeEmail: [],
            };
        }

        case ACTION_TYPES.GET_ROLE_UPDATE_PENDING: {
            return {
                ...state,
                isPostRoleUpdate: true,
                isPostRoleUpdayeFailed: false,
                isPostRoleUpdateError: undefined,
                postSubscribeEmail: [],
            };
        }

        case ACTION_TYPES.GET_ROLE_UPDATE_FULFILLED: {
            return {
                ...state,
                isPostRoleUpdate: false,
                isPostRoleUpdateFailed: false,
                isPostRoleUpdateError: undefined,
                postSubscribeEmail: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_ROLE_UPDATE_REJECTED: {
            return {
                ...state,
                isPostRoleUpdate: false,
                isPostRoleUpdateFailed: true,
                isPostRoleUpdateError: action.payload,
                postRoleUpdate: [],
            };
        }

        case ACTION_TYPES.GET_TYPE_INTERVAL_PENDING: {
            return {
                ...state,
                isGetTypeInterval: true,
                isGetTypeIntervalFailed: false,
                isGetTypeIntervalError: undefined,
                getTypeInterval: [],
            };
        }

        case ACTION_TYPES.GET_TYPE_INTERVAL_FULFILLED: {
            return {
                ...state,
                isGetTypeInterval: false,
                isGetTypeIntervalFailed: false,
                isGetTypeIntervalError: undefined,
                getTypeInterval: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_TYPE_INTERVAL_REJECTED: {
            return {
                ...state,
                isGetTypeInterval: false,
                isGetTypeIntervalFailed: true,
                isGetTypeIntervalError: action.payload,
                getTypeInterval: [],
            };
        }

        case ACTION_TYPES.GET_ADDRESS_PENDING: {
            return {
                ...state,
                isGetAddress: true,
                isGetAddressFailed: false,
                isGetAddressError: undefined,
                getAddress: [],
            };
        }

        case ACTION_TYPES.GET_ADDRESS_FULFILLED: {
            return {
                ...state,
                isGetAddress: false,
                isGetAddressFailed: false,
                isGetAddressError: undefined,
                getAddress: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_ADDRESS_REJECTED: {
            return {
                ...state,
                isGetAddress: false,
                isGetAddressFailed: true,
                isGetAddressError: action.payload,
                getAddress: [],
            };
        }

        case ACTION_TYPES.POST_TYPE_PENDING: {
            return {
                ...state,
                isGetAddress: true,
                isGetAddressFailed: false,
                isGetAddressError: undefined,
                getAddress: [],
            };
        }

        case ACTION_TYPES.POST_TYPE_FULFILLED: {
            return {
                ...state,
                isGetAddress: false,
                isGetAddressFailed: false,
                isGetAddressError: undefined,
                getAddress: action.payload.data,
            };
        }

        case ACTION_TYPES.POST_TYPE_REJECTED: {
            return {
                ...state,
                isGetAddress: false,
                isGetAddressFailed: true,
                isGetAddressError: action.payload,
                getAddress: [],
            };
        }

        case ACTION_TYPES.GET_LAST_PAYMENT_PENDING: {
            return {
                ...state,
                isGetLastPayment: true,
                isGetLastPaymentFailed: false,
                isGetLastPaymentError: undefined,
                getLastPayment: [],
            };
        }

        case ACTION_TYPES.GET_LAST_PAYMENT_FULFILLED: {
            return {
                ...state,
                isGetLastPayment: false,
                isGetLastPaymentFailed: false,
                isGetLastPaymentError: undefined,
                getLastPayment: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_LAST_PAYMENT_REJECTED: {
            return {
                ...state,
                isGetLastPayment: false,
                isGetLastPaymentFailed: true,
                isGetLastPaymentError: action.payload,
                getLastPayment: [],
            };
        }

        case ACTION_TYPES.POST_GENERATE_INVOICE_PENDING: {
            return {
                ...state,
                isPostGenerateInvoice: true,
                isPostGenerateInvoiceFailed: false,
                isPostGenerateInvoiceError: undefined,
                postGenerateInvoice: [],
            };
        }

        case ACTION_TYPES.POST_GENERATE_INVOICE_FULFILLED: {
            return {
                ...state,
                isPostGenerateInvoice: false,
                isPostGenerateInvoiceFailed: false,
                isPostGenerateInvoiceError: undefined,
                postGenerateInvoice: action.payload.data,
            };
        }

        case ACTION_TYPES.POST_GENERATE_INVOICE_REJECTED: {
            return {
                ...state,
                isPostGenerateInvoice: false,
                isPostGenerateInvoiceFailed: true,
                isPostGenerateInvoiceError: action.payload,
                postGenerateInvoice: [],
            };
        }

        case ACTION_TYPES.POST_PDF_GENERATOR_PENDING: {
            return {
                ...state,
                isPostPdfGenerator: true,
                isPostPdfGeneratorFailed: false,
                isPostPdfGeneratorError: undefined,
                postGeneratorInvoice: [],
            };
        }

        case ACTION_TYPES.POST_PDF_GENERATOR_FULFILLED: {
            return {
                ...state,
                isPostPdfGenerator: false,
                isPostPdfGeneratorFailed: false,
                isPostPdfGeneratorError: undefined,
                postPdfGenerator: action.payload.data,
            };
        }

        case ACTION_TYPES.POST_PDF_GENERATOR_REJECTED: {
            return {
                ...state,
                isPostPdfGenerator: true,
                isPostPdfGeneratorFailed: true,
                isPostPdfGeneratorError: action.payload,
                postPdfGenerator: [],
            };
        }

        case ACTION_TYPES.POST_PAYPAL_PAYMENT_PENDING: {
            return {
                ...state,
                isPostPaypalPayment: true,
                isPostPaypalPaymentFailed: false,
                isPostPaypalPaymentError: undefined,
                postPaypalPayment: [],
            };
        }

        case ACTION_TYPES.POST_PAYPAL_PAYMENT_FULFILLED: {
            return {
                ...state,
                isPostPaypalPayment: false,
                isPostPaypalPaymentFailed: false,
                isPostPaypalPaymentError: undefined,
                postPaypalPayment: action.payload.data,
            };
        }

        case ACTION_TYPES.POST_PAYPAL_PAYMENT_REJECTED: {
            return {
                ...state,
                isPostPaypalPayment: false,
                isPostPaypalPaymentFailed: true,
                isPostPaypalPaymentError: action.payload,
                postPaypalPayment: [],
            };
        }

        case ACTION_TYPES.GET_UNAVAILABLE_FEATURES_TEAM_PENDING: {
            return {
                ...state,
                isGetUnavailableFeaturesTeam: true,
                isGetUnavailableFeaturesTeamFailed: false,
                isGetUnavailableFeaturesTeamError: undefined,
                getUnavailableFeaturesTeam: [],
            };
        }

        case ACTION_TYPES.GET_UNAVAILABLE_FEATURES_TEAM_FULFILLED: {
            return {
                ...state,
                isGetUnavailableFeaturesTeam: false,
                isGetUnavailableFeaturesTeamFailed: false,
                isGetUnavailableFeaturesTeamError: undefined,
                getUnavailableFeaturesTeam: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_UNAVAILABLE_FEATURES_TEAM_REJECTED: {
            return {
                ...state,
                isGetUnavailableFeaturesTeam: false,
                isGetUnavailableFeaturesTeamFailed: true,
                isGetUnavailableFeaturesTeamError: action.payload,
                getUnavailableFeaturesTeam: [],
            };
        }

        case ACTION_TYPES.GET_MARKET_FEATURES_TEAM_PENDING: {
            return {
                ...state,
                isGetMarketFeaturesTeam: true,
                isGetMarketFeaturesTeamFailed: false,
                isGetMarketFeaturesTeamError: undefined,
                getMarketFeaturesTeam: [],
            };
        }

        case ACTION_TYPES.GET_MARKET_FEATURES_TEAM_FULFILLED: {
            return {
                ...state,
                isGetMarketFeaturesTeam: false,
                isGetMarketFeaturesTeamFailed: false,
                isGetMarketFeaturesTeamError: undefined,
                getMarketFeaturesTeam: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_MARKET_FEATURES_TEAM_REJECTED: {
            return {
                ...state,
                isGetMarketFeaturesTeam: false,
                isGetMarketFeaturesTeamFailed: true,
                isGetMarketFeaturesTeamError: action.payload,
                getMarketFeaturesTeam: [],
            };
        }

        case ACTION_TYPES.GET_FEATURES_LIST_TEAM_PENDING: {
            return {
                ...state,
                isGetFeaturesListTeam: true,
                isGetFeaturesListTeamFailed: false,
                isGetFeaturesListTeamError: undefined,
                getFeaturesListTeam: [],
            };
        }

        case ACTION_TYPES.GET_FEATURES_LIST_TEAM_FULFILLED: {
            return {
                ...state,
                isGetFeaturesListTeam: false,
                isGetFeaturesListTeamFailed: false,
                isGetFeaturesListTeamError: undefined,
                getFeaturesListTeam: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_FEATURES_LIST_TEAM_REJECTED: {
            return {
                ...state,
                isGetFeaturesListTeam: false,
                isGetFeaturesListTeamFailed: true,
                isGetFeaturesListTeamError: action.payload,
                getFeaturesListTeam: [],
            };
        }

        case ACTION_TYPES.ADD_FEATURE_TEAM_PENDING: {
            return {
                ...state,
                isAddFeatureTeam: true,
                isAddFeatureTeamFailed: false,
                isAddFeatureTeamError: undefined,
                addFeatureTeam: [],
            };
        }

        case ACTION_TYPES.ADD_FEATURE_TEAM_FULFILLED: {
            return {
                ...state,
                isAddFeatureTeam: false,
                isAddFeatureTeamFailed: false,
                isAddFeatureTeamError: undefined,
                addFeatureTeam: action.payload.data,
            };
        }

        case ACTION_TYPES.ADD_FEATURE_TEAM_REJECTED: {
            return {
                ...state,
                isAddFeatureTeam: false,
                isAddFeatureTeamFailed: true,
                isAddFeatureTeamError: action.payload,
                addFeatureTeam: [],
            };
        }

        case ACTION_TYPES.START_FEATURE_TEAM_PENDING: {
            return {
                ...state,
                isStartFeatureTeam: true,
                isStartFeatureTeamFailed: false,
                isStartFeatureTeamError: undefined,
                startFeatureTeam: [],
            };
        }

        case ACTION_TYPES.START_FEATURE_TEAM_FULFILLED: {
            return {
                ...state,
                isStartFeatureTeam: false,
                isStartFeatureTeamFailed: false,
                isStartFeatureTeamError: undefined,
                startFeatureTeam: action.payload.data,
            };
        }

        case ACTION_TYPES.START_FEATURE_TEAM_REJECTED: {
            return {
                ...state,
                isStartFeatureTeam: false,
                isStartFeatureTeamFailed: true,
                isStartFeatureTeamError: action.payload,
                startFeatureTeam: [],
            };
        }

        case ACTION_TYPES.STOP_FEATURE_TEAM_PENDING: {
            return {
                ...state,
                isStopFeatureTeam: true,
                isStopFeatureTeamFailed: false,
                isStopFeatureTeamError: undefined,
                stopFeatureTeam: [],
            };
        }

        case ACTION_TYPES.STOP_FEATURE_TEAM_FULFILLED: {
            return {
                ...state,
                isStopFeatureTeam: false,
                isStopFeatureTeamFailed: false,
                isStopFeatureTeamError: undefined,
                stopFeatureTeam: action.payload.data,
            };
        }

        case ACTION_TYPES.STOP_FEATURE_TEAM_REJECTED: {
            return {
                ...state,
                isStopFeatureTeam: false,
                isStopFeatureTeamFailed: true,
                isStopFeatureTeamError: action.payload,
                stopFeatureTeam: [],
            };
        }

        case ACTION_TYPES.DELETE_FEATURE_TEAM_PENDING: {
            return {
                ...state,
                isDeleteFeatureTeam: true,
                isDeleteFeatureTeamFailed: false,
                isDeleteFeatureTeamError: undefined,
                deleteFeatureTeam: [],
            };
        }

        case ACTION_TYPES.DELETE_FEATURE_TEAM_FULFILLED: {
            return {
                ...state,
                isDeleteFeatureTeam: false,
                isDeleteFeatureTeamFailed: false,
                isDeleteFeatureTeamError: undefined,
                deleteFeatureTeam: action.payload.data,
            };
        }

        case ACTION_TYPES.DELETE_FEATURE_TEAM_REJECTED: {
            return {
                ...state,
                isDeleteFeatureTeam: false,
                isDeleteFeatureTeamFailed: true,
                isDeleteFeatureTeamError: action.payload,
                deleteFeatureTeam: [],
            };
        }

        case ACTION_TYPES.STOP_CUSTOM_FEATURE_TEAM_PENDING: {
            return {
                ...state,
                isStopCustomFeatureTeam: true,
                isStopCustomFeatureTeamFailed: false,
                isStopCustomFeatureTeamError: undefined,
                stopCustomFeatureTeam: [],
            };
        }

        case ACTION_TYPES.STOP_CUSTOM_FEATURE_TEAM_FULFILLED: {
            return {
                ...state,
                isStopCustomFeatureTeam: false,
                isStopCustomFeatureTeamFailed: false,
                isStopCustomFeatureTeamError: undefined,
                stopCustomFeatureTeam: action.payload.data,
            };
        }

        case ACTION_TYPES.STOP_CUSTOM_FEATURE_TEAM_REJECTED: {
            return {
                ...state,
                isStopCustomFeatureTeam: false,
                isStopCustomFeatureTeamFailed: true,
                isStopCustomFeatureTeamError: action.payload,
                stopCustomFeatureTeam: [],
            };
        }

        case ACTION_TYPES.START_CUSTOM_FEATURE_TEAM_PENDING: {
            return {
                ...state,
                isStartCustomFeatureTeam: true,
                isStartCustomFeatureTeamFailed: false,
                isStartCustomFeatureTeamError: undefined,
                startCustomFeatureTeam: [],
            };
        }

        case ACTION_TYPES.START_CUSTOM_FEATURE_TEAM_FULFILLED: {
            return {
                ...state,
                isStartCustomFeatureTeam: false,
                isStartCustomFeatureTeamFailed: false,
                isStartCustomFeatureTeamError: undefined,
                startCustomFeatureTeam: action.payload.data,
            };
        }

        case ACTION_TYPES.START_CUSTOM_FEATURE_TEAM_REJECTED: {
            return {
                ...state,
                isStartCustomFeatureTeam: false,
                isStartCustomFeatureTeamFailed: true,
                isStartCustomFeatureTeamError: action.payload,
                startCustomFeatureTeam: [],
            };
        }

        case ACTION_TYPES.CANCEL_PLAN_ADMIN_PENDING: {
            return {
                ...state,
                isCancelPlanAdmin: true,
                isCancelPlanAdminFailed: false,
                isCancelPlanAdminError: undefined,
                cancelPlanAdmin: [],
            };
        }

        case ACTION_TYPES.CANCEL_PLAN_ADMIN_FULFILLED: {
            return {
                ...state,
                isCancelPlanAdmin: false,
                isCancelPlanAdminFailed: false,
                isCancelPlanAdminError: undefined,
                cancelPlanAdmin: action.payload.data,
            };
        }

        case ACTION_TYPES.CANCEL_PLAN_ADMIN_REJECTED: {
            return {
                ...state,
                isCancelPlanAdmin: false,
                isCancelPlanAdminFailed: true,
                isCancelPlanAdminError: action.payload,
                cancelPlanAdmin: [],
            };
        }

        case ACTION_TYPES.SECURE_PAYMENT_STRIPE_PENDING: {
            return {
                ...state,
                isSecurePaymentStripe: true,
                isSecurePaymentStripeFailed: false,
                isSecurePaymentStripeError: undefined,
                securePaymentStripe: [],
            };
        }

        case ACTION_TYPES.SECURE_PAYMENT_STRIPE_FULFILLED: {
            return {
                ...state,
                isSecurePaymentStripe: false,
                isSecurePaymentStripeFailed: false,
                isSecurePaymentStripeError: undefined,
                securePaymentStripe: action.payload.data,
            };
        }

        case ACTION_TYPES.SECURE_PAYMENT_STRIPE_REJECTED: {
            return {
                ...state,
                isSecurePaymentStripe: false,
                isSecurePaymentStripeFailed: true,
                isSecurePaymentStripeError: action.payload,
                securePaymentStripe: [],
            };
        }

        case ACTION_TYPES.GET_XERO_INTEGRATION_PENDING: {
            return {
                ...state,
                isXeroIntegration: true,
                isXeroIntegrationFailed: false,
                isXeroIntegrationError: undefined,
                XeroIntegration: [],
            };
        }

        case ACTION_TYPES.GET_XERO_INTEGRATION_FULFILLED: {
            return {
                ...state,
                isXeroIntegration: false,
                isXeroIntegrationFailed: false,
                isXeroIntegrationError: undefined,
                XeroIntegration: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_XERO_INTEGRATION_REJECTED: {
            return {
                ...state,
                isXeroIntegration: false,
                isXeroIntegrationFailed: true,
                isXeroIntegrationError: action.payload,
                XeroIntegration: [],
            };
        }

        case ACTION_TYPES.UPDATE_XERO_INTEGRATION_PENDING: {
            return {
                ...state,
                isUpdateXeroIntegration: true,
                isUpdateXeroIntegrationFailed: false,
                isUpdateXeroIntegrationError: undefined,
                UpdateXeroIntegration: [],
            };
        }

        case ACTION_TYPES.UPDATE_XERO_INTEGRATION_FULFILLED: {
            return {
                ...state,
                isUpdateXeroIntegration: false,
                isUpdateXeroIntegrationFailed: false,
                isUpdateXeroIntegrationError: undefined,
                UpdateXeroIntegration: action.payload.data,
            };
        }

        case ACTION_TYPES.UPDATE_XERO_INTEGRATION_REJECTED: {
            return {
                ...state,
                isUpdateXeroIntegration: false,
                isUpdateXeroIntegrationFailed: true,
                isUpdateXeroIntegrationError: action.payload,
                UpdateXeroIntegration: [],
            };
        }
        case ACTION_TYPES.FETCH_EVENT_LOGS_PENDING: {
            return {
                ...state,
                isFetchEventLogs: true,
                isFetchEventLogsFailed: false,
                isFetchEventLogsError: undefined,
                EventLogs: [],
            };
        }

        case ACTION_TYPES.FETCH_EVENT_LOGS_FULFILLED: {
            return {
                ...state,
                isFetchEventLogs: false,
                isFetchEventLogsFailed: false,
                isFetchEventLogsError: undefined,
                EventLogs: action.payload.data?.data,
            };
        }

        case ACTION_TYPES.FETCH_EVENT_LOGS_REJECTED: {
            return {
                ...state,
                isFetchEventLogs: false,
                isFetchEventLogsFailed: true,
                isFetchEventLogsError: action.payload,
                EventLogs: [],
            };
        }
        default:
            return state;
    }
}

export default settings

