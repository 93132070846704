import { Component, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


const checkBoxStyle = 'mr-4 p-2 rounded-md text-primary';

class FilterTableModal extends Component {
    constructor() {
        super();
    }

    componentDidMount() {}

    render () {

        const {t} = this.props;
        const {filterColumn1, filterColumn2, filterColumn3, filterColumn4, filterColumn5, filterColumn6, open, onCancelClick, data, ...rest} = this.props
    
        return (
            <Transition.Root show={this.props.open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onCancelClick}>
                    <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                                <div className='flex flex-col justify-center items-center'>
                                    <div className='flex flex-col justify-center items-start my-2'>
                                        <div className='flex justify-end items-center my-1'>
                                            <input type='checkbox' checked={data.col1} onChange={()=>{}} onClick={filterColumn1} className={checkBoxStyle}/>
                                            <p className='text-md font-semibold'>{t("name")}</p>   
                                        </div>
                                        <div className='flex justify-end items-center my-1'>
                                            <input type='checkbox' checked={data.col2} onChange={()=>{}} onClick={filterColumn2} className={checkBoxStyle}/>
                                            <p className='text-md font-semibold'>{t("activityMonitorModule.totalComputerTime")}</p>   
                                        </div>
                                        <div className='flex justify-center items-center my-1'>
                                            <input type='checkbox' checked={data.col3} onChange={()=>{}} onClick={filterColumn3} className={checkBoxStyle}/>
                                            <p className='text-md font-semibold'>{t("ReportsTab.productive")}</p>   
                                        </div>
                                        <div className='flex justify-center items-center my-1'>
                                            <input type='checkbox' checked={data.col4} onChange={()=>{}} onClick={filterColumn4} className={checkBoxStyle}/>
                                            <p className='text-md font-semibold'>% {t("ReportsTab.productive")}</p>   
                                        </div>
                                        <div className='flex justify-center items-center my-1'>
                                            <input type='checkbox' checked={data.col5} onChange={()=>{}} onClick={filterColumn5} className={checkBoxStyle}/>
                                            <p className='text-md font-semibold'>{t("ReportsTab.unproductive")}</p>   
                                        </div>
                                        <div className='flex justify-center items-center my-1'>
                                            <input type='checkbox' checked={data.col6} onChange={()=>{}} onClick={filterColumn6} className={checkBoxStyle}/>
                                            <p className='text-md font-semibold'>% {t("ReportsTab.unproductive")}</p>   
                                        </div>
                                    </div>
                                    <div className='bg-primary text-white text-md font-medium mt-4 p-1 -ml-2 rounded-md px-5 cursor-pointer' onClick={onCancelClick}>
                                    {t("activityMonitorModule.close")}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
   }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});


export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(FilterTableModal));
