import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../../Components/Layout';
import ApprovalPending_tab from './ApprovalPending_tab';
import PastRequests_tab from './PastRequests_tab';

import { UsersIcon, UserGroupIcon, DesktopComputerIcon, DocumentSearchIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid'
import { withTranslation } from 'react-i18next';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


class Requests extends Component {
    constructor() {
        super();
        this.state = {
            SelectedTab: "approval-pending",
        }
    }

    componentDidMount() {
        const url = window.location.pathname;
        setTimeout(() => {
            this.setState({ urlpath: url })
        }, 200);
        
        setTimeout(() => {
            if (url == "/requests/approval-pending" || url == "/requests/past-requests") {
                this.setState({ SelectedTab: "approval-pending" });
            }
        }, 200);
        setTimeout(() => {
            if(this.state.urlpath == '/requests/approval-pending'){
                this.setState({SelectedTab : 'approval-pending'})
            } else if (this.state.urlpath == '/requests/past-requests') {
                this.setState({SelectedTab : 'past-requests'})
            }  else {
                this.setState({SelectedTab : 'approval-pending'})
            }
            window.history.pushState({}, null, `/requests/${this.state.SelectedTab}`);
        }, 300);
    }

    HandleSelectTab = (e) => {
        this.setState({ SelectedTab: e })
        window.history.pushState({}, null, `/requests/${e}`);

    }

    HandleMobileSelectTab = (e) => {
        this.setState({ SelectedTab: e.target.value })
        window.history.pushState({}, null, `/requests/${e.target.value}`);

    }


    render() {
        const {t} = this.props;
        return (
            <Layout>
                <div>
                    <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                                {t("requestsModule.selectATab")}
                            </label>
                            <select
                                id="tabs"
                                name="tabs"
                                onChange={(e) => this.HandleMobileSelectTab(e)}
                                className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                                defaultValue={this.state.SelectedTab}>
                                <option key="ApprovalPending" value="approval-pending">
                                    {t("requestsModule.approvalPending")}
                                </option>
                                <option key="PastRequests" value="past-requests">
                                    {t("requestsModule.pastRequests")}
                                </option>
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                <div
                                    key="approval-pending"
                                    onClick={() => this.HandleSelectTab("approval-pending")}
                                    className={classNames(
                                        this.state.SelectedTab == "approval-pending" ||
                                            this.state.SelectedTab == "TeamMembers"
                                            ? "border-primary text-primary"
                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                        "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                    )}
                                    aria-current={
                                        this.state.SelectedTab == "approval-pending"
                                            ? "page"
                                            : undefined
                                    }>
                                    <UserGroupIcon
                                        className={classNames(
                                            this.state.SelectedTab == "approval-pending" ||
                                                this.state.SelectedTab == "TeamMembers"
                                                ? "text-primary"
                                                : "text-gray-400 group-hover:text-gray-500",
                                            "-ml-0.5 mr-2 h-5 w-5"
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span>{t("requestsModule.approvalPending")}</span>
                                </div>

                                <div
                                    key="past-requests"
                                    onClick={() => this.HandleSelectTab("past-requests")}
                                    className={classNames(
                                        this.state.SelectedTab == "past-requests"
                                            ? "border-primary text-primary"
                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                        "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                    )}
                                    aria-current={
                                        this.state.SelectedTab == "past-requests"
                                            ? "page"
                                            : undefined
                                    }>
                                    <UsersIcon
                                        className={classNames(
                                            this.state.SelectedTab == "past-requests"
                                                ? "text-primary"
                                                : "text-gray-400 group-hover:text-gray-500",
                                            "-ml-0.5 mr-2 h-5 w-5"
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span>{t("requestsModule.pastRequests")}</span>
                                </div>
                            </nav>
                        </div>
                    </div>
                    {this.state.SelectedTab === "approval-pending" && <ApprovalPending_tab />}
                    {this.state.SelectedTab === "past-requests" && <PastRequests_tab />}
                </div>
            </Layout>
        );

    }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

Requests.propTypes = {};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Requests));