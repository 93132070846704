import React, { Component } from "react";
import Layout from "../../Components/Layout";
import BeatLoader from "react-spinners/BeatLoader";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import InputField from "../../Components/InputField";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";

import {
    getBlockedUrls,
    updateBlockedUrls,
    postBlockedUrls,
    deleteBlockedUrls,
} from "../../services/blockUrls/actions";

import SelectField from "../../Components/SelectField";
import { FetchactiveTeams } from "../../services/teams/actions";

import { fetchallEmployees } from "../../services/projects/actions";
import { toast } from "react-toastify";
import ConfirmationDialogue from "../../Components/ConfirmationDialogue";
import EditUrlModal from "./editUrlModal";
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

class BlockUrls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            urlToBlock: "",
            SelectedTab: "Global",
            blockedUrls: [],
            showDeleteProjectModal: false,
            teamId: "",
            employeeId: "",
            urlId: "",
            editUrl: false,
            urlToEdit: "",
        };
    }
    componentDidMount() {
        // setTimeout(() => {
        //     this.props.getBlockedUrls("?type=org").then((res) => {
        //         this.setState({ blockedUrls: res.value.data.data });
        //     });
        // }, 200);
        this.props.getBlockedUrls("?type=org").then((res) => {
            this.setState({ blockedUrls: res.value.data.data });
        });
        const org_uuid = localStorage.getItem("org_uuid");
        this.props.FetchactiveTeams(org_uuid);
        this.props.fetchallEmployees();
    }
    HandleSelectTab = (e) => {
        if (e === "Global") {
            this.props.getBlockedUrls("?type=org").then((res) => {
                this.setState({ blockedUrls: res.value.data.data });
            });
        }
        this.setState({ SelectedTab: e, employeeId: "", teamId: "" });
    };

    HandleMobileSelectTab = (e) => {
        if (e.target.value === "Global") {
            this.props.getBlockedUrls("?type=org").then((res) => {
                this.setState({ blockedUrls: res.value.data.data });
            });
        }
        this.setState({ SelectedTab: e.target.value, employeeId: "", teamId: "" });
    };
    onSelectEmployeeChange = (e) => {
        this.setState({ employeeId: e.target.value, blockedUrls: [] }, () => {
            if (e.target.value) {
                this.props.getBlockedUrls("?type=user&user_id=" + e.target.value).then((res) => {
                    this.setState({ blockedUrls: res.value.data.data });
                });
            }
        });
    };
    onSelectTeamChange = (e) => {
        this.setState({ teamId: e.target.value, blockedUrls: [] }, () => {
            if (e.target.value) {
                this.props.getBlockedUrls("?type=team&team_id=" + e.target.value).then((res) => {
                    this.setState({ blockedUrls: res.value.data.data });
                });
            }
        });
    };
    blockUrl = (url) => {
        if (url) {
            const data = new FormData();
            data.append("url", url);
            if (this.state.SelectedTab === "Teams") {
                if (this.state.teamId) {
                    data.append("team_id", this.state.teamId);
                    data.append("type", "team");
                } else {
                    toast.error("Please select a team to add url!!");
                    return;
                }
            } else if (this.state.SelectedTab === "Users") {
                if (this.state.employeeId) {
                    data.append("user_id", this.state.employeeId);
                    data.append("type", "user");
                } else {
                    toast.error("Please select a user to add url!!");
                    return;
                }
            } else {
                data.append("type", "org");
            }
            this.props.postBlockedUrls(data).then((res) => {
                if (res.value.status === 200) {
                    toast.success("Blocked Successfully!!");
                    if (data.get("type") === "user") {
                        this.props
                            .getBlockedUrls("?type=user&user_id=" + this.state.employeeId)
                            .then((res) => {
                                this.setState({ blockedUrls: res.value.data.data });
                            });
                    } else if (data.get("type") === "team") {
                        this.props
                            .getBlockedUrls("?type=team&team_id=" + this.state.teamId)
                            .then((res) => {
                                this.setState({ blockedUrls: res.value.data.data });
                            });
                    } else {
                        this.props.getBlockedUrls("?type=org").then((res) => {
                            this.setState({ blockedUrls: res.value.data.data });
                        });
                    }
                    this.setState({ urlToBlock: "" });
                } else {
                    toast.error("Blocking Unsuccessful!!");
                }
            });
        } else {
            toast.error("Please enter a url!!");
        }
    };
    deleteUrl = (id) => {
        const data = new FormData();
        data.append("id", id);
        this.props.deleteBlockedUrls(data).then((res) => {
            if (res.value.status === 200) {
                if (this.state.SelectedTab === "Users") {
                    this.props
                        .getBlockedUrls("?type=user&user_id=" + this.state.employeeId)
                        .then((res) => {
                            this.setState({ blockedUrls: res.value.data.data });
                        });
                } else if (this.state.SelectedTab === "Teams") {
                    this.props
                        .getBlockedUrls("?type=team&team_id=" + this.state.teamId)
                        .then((res) => {
                            this.setState({ blockedUrls: res.value.data.data });
                        });
                } else {
                    this.props.getBlockedUrls("?type=org").then((res) => {
                        this.setState({ blockedUrls: res.value.data.data });
                    });
                }
            }
            this.setState({ showDeleteProjectModal: false, urlId: "" });
        });
    };
    onUrlToEditChange = (e) => {
        this.setState({ urlToEdit: e.target.value });
    };
    onModalUrlUpdateSubmit=()=>{
        const data = new FormData();
        data.append("url", this.state.urlToEdit);
        data.append("id", this.state.urlId);
        this.props.updateBlockedUrls(data).then((res)=>{
            if(res.value.status===200)
            {
                if (this.state.SelectedTab === "Users") {
                    this.props
                        .getBlockedUrls("?type=user&user_id=" + this.state.employeeId)
                        .then((res) => {
                            this.setState({ blockedUrls: res.value.data.data });
                        });
                } else if (this.state.SelectedTab === "Teams") {
                    this.props
                        .getBlockedUrls("?type=team&team_id=" + this.state.teamId)
                        .then((res) => {
                            this.setState({ blockedUrls: res.value.data.data });
                        });
                } else {
                    this.props.getBlockedUrls("?type=org").then((res) => {
                        this.setState({ blockedUrls: res.value.data.data });
                    });
                }
            }
            this.setState({ editUrl: false, urlId: "" });
        })
    }
    render() {
        const { t } = this.props;
        let teams =
            this.props.teams && this.props.teams.ActiveTeam && this.props.teams.ActiveTeam.info;
        let employees = this.props.projects && this.props.projects.AllEmployees;
        let EmployeeData =
            employees &&
            employees.map((el) => ({
                name: el.user_first_name + " " + el.user_last_name,
                id: el.user_id,
            }));
        let TeamData =
            teams &&
            teams.map((el) => ({
                name: el.team.name,
                id: el.team.team_uuid,
            }));
        return (
            <Layout>
                <div className="mt-2 bg-white px-2 py-2 border-b border-gray-200 rounded-lg flex items-center">
                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            {t("requestsModule.selectATab")}
                        </label>
                        <select
                            id="tabs"
                            name="tabs"
                            onChange={(e) => this.HandleMobileSelectTab(e)}
                            className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                            defaultValue={this.state.SelectedTab}>
                            <option key="Global" value="Global">
                                {t("settingsTab.global")} {t("blockedUrls")}
                            </option>
                            <option key="Teams" value="Teams">
                                {t("teams")} {t("blockedUrls")}
                            </option>
                            <option key="Users" value="Users">
                                {t("user")} {t("blockedUrls")}
                            </option>
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <div className="-mb-px flex space-x-8" aria-label="Tabs">
                            <div
                                key="Global"
                                onClick={() => this.HandleSelectTab("Global")}
                                className={classNames(
                                    this.state.SelectedTab === "Global"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "Global" ? "page" : undefined
                                }>
                                <span>
                                    {t("settingsTab.global")} {t("blockedUrls")}
                                </span>
                            </div>

                            <div
                                key="Teams"
                                onClick={() => this.HandleSelectTab("Teams")}
                                className={classNames(
                                    this.state.SelectedTab === "Teams"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "Teams" ? "page" : undefined
                                }>
                                <span>
                                    {t("teams")} {t("blockedUrls")}
                                </span>
                            </div>

                            <div
                                key="Users"
                                onClick={() => this.HandleSelectTab("Users")}
                                className={classNames(
                                    this.state.SelectedTab === "Users"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "Users" ? "page" : undefined
                                }>
                                <span>
                                    {t("user")} {t("blockedUrls")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4 bg-white px-2 py-2 border-b border-gray-200 rounded-lg flex items-end gap-5 flex-wrap">
                    <div className="flex-1 max-w-lg">
                        <InputField
                            className="focus:border-none focus:outline-none"
                            value={this.state.urlToBlock}
                            placeholder={t("blockurlplaceholder")}
                            onInputChange={(ev) => {
                                this.setState({ urlToBlock: ev.target.value });
                            }}
                        />
                    </div>
                    {this.state.SelectedTab === "Teams" ? (
                        <div className="w-52 xl:w-60">
                            <SelectField
                                onSelectChange={(e) => this.onSelectTeamChange(e)}
                                FieldName={t("select") + " " + t("team")}
                                name={"teamName"}
                                arrayOfData={TeamData}
                                value={this.state.teamId}
                                id={"team"}
                            />
                        </div>
                    ) : this.state.SelectedTab === "Users" ? (
                        <div className="w-52 xl:w-60">
                            <SelectField
                                onSelectChange={(e) => this.onSelectEmployeeChange(e)}
                                FieldName={t("select") + " " + t("employee")}
                                name={"employee"}
                                value={this.state.employeeId}
                                arrayOfData={EmployeeData}
                                id={"employee"}
                            />
                        </div>
                    ) : null}
                    <button
                        className="bg-primary text-white px-4 py-2 rounded-md min-w-max uppercase"
                        onClick={() => this.blockUrl(this.state.urlToBlock)}>
                        {t("blockUrl")}
                    </button>
                </div>
                <div className="mt-5 bg-white px-2 py-4 border-b border-gray-200 rounded-lg">
                    <div className="border border-gray-300 rounded-lg overflow-x-hidden">
                        <div
                            key={"headerBlockedUrlsTable"}
                            className="flex text-gray-500 font-semibold bg-gray items-center">
                            <div className="px-4 py-2.5 xl:py-4 xl:px-5 flex-1 no-scrollbar overflow-x-auto border-r border-gray-300">
                                {t("blockedUrls")}
                            </div>
                            <div className="w-32 px-4 py-2.5 xl:py-4 xl:px-5 text-center">
                                {t("action")}
                            </div>
                        </div>
                        <div className="overflow-y-auto max-h-[70vh] no-scrollbar">
                            {this.state.SelectedTab === "Teams" && !this.state.teamId ? (
                                <div className="w-full text-center py-4 border-t border-gray-300">
                                    {t("please")} {t("select")} {t("team")}
                                </div>
                            ) : this.state.SelectedTab === "Users" && !this.state.employeeId ? (
                                <div className="w-full text-center py-4 border-t border-gray-300">
                                    {t("please")} {t("select")} {t("user")}
                                </div>
                            ) : this.state.blockedUrls.length > 0 ? (
                                this.state.blockedUrls.map((url, index) => (
                                    <div
                                        className="flex text-textprimary items-center text-tiny border-t border-gray-300"
                                        key={url.type + url.id}>
                                        <div className="px-4 py-2.5 xl:py-4 xl:px-5 flex-1 no-scrollbar overflow-x-auto border-r border-gray-300">
                                            {url.url}
                                        </div>
                                        <div className="w-32 flex gap-1">
                                            <button
                                                className="px-4 py-2.5 xl:py-4 xl:px-5 flex justify-center"
                                                onClick={() =>
                                                    this.setState({
                                                        editUrl: true,
                                                        urlToEdit: url.url,
                                                        urlId: url.id,
                                                    })
                                                }>
                                                <PencilIcon className="text-primary w-4 h-4 xl:w-5 xl:h-5" />
                                            </button>
                                            <button
                                                className="px-4 py-2.5 xl:py-4 xl:px-5 flex justify-center"
                                                onClick={() =>
                                                    this.setState({
                                                        showDeleteProjectModal: true,
                                                        urlId: url.id,
                                                    })
                                                }>
                                                <TrashIcon className="text-danger w-4 h-4 xl:w-5 xl:h-5" />
                                            </button>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="w-full text-center py-4">{t("noDataFound")}</div>
                            )}
                        </div>
                    </div>
                </div>
                <ConfirmationDialogue
                    open={this.state.showDeleteProjectModal}
                    onCancelClick={() => {
                        this.setState({ showDeleteProjectModal: false });
                    }}
                    onSubmitClick={() => this.deleteUrl(this.state.urlId)}
                    info={"Are you sure you want to Delete ?"}
                    submitButtonName={"Delete"}
                    cancelButtonName={"Cancel"}></ConfirmationDialogue>
                <EditUrlModal
                    open={this.state.editUrl}
                    onCancelClick={() => this.setState({ editUrl: false })}
                    onFieldChange={(e) => this.onUrlToEditChange(e)}
                    onSubmitClick={() => this.onModalUrlUpdateSubmit()}
                    url={this.state.urlToEdit}
                    submitButtonName={"Update"}
                    cancelButtonName={"Cancel"}></EditUrlModal>
            </Layout>
        );
    }
}
const mapStateToProps = (state) => ({
    projects: state.projects,
    teams: state.teams,
});

const mapDispatchToProps = (dispatch) => ({
    getBlockedUrls: (params) => dispatch(getBlockedUrls(params)),
    updateBlockedUrls: (data) => dispatch(updateBlockedUrls(data)),
    postBlockedUrls: (data) => dispatch(postBlockedUrls(data)),
    deleteBlockedUrls: (data) => dispatch(deleteBlockedUrls(data)),
    FetchactiveTeams: (org_uuid) => dispatch(FetchactiveTeams(org_uuid)),
    fetchallEmployees: () => dispatch(fetchallEmployees()),
});
BlockUrls.propTypes = {};

export default withTranslation("common")(connect(mapStateToProps, mapDispatchToProps)(BlockUrls));
