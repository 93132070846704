import React, { Component, Fragment } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import InputField from '../../Components/InputField';

import { editTimeTrackerTiming, createTimeTrackerTiming, getTimeTrackerInterval } from '../../services/timetracker/actions';
import SelectField from '../../Components/SelectField';

import { PencilAltIcon, DocumentAddIcon } from '@heroicons/react/outline';
import { withTranslation } from 'react-i18next';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const minuteselect = [
    {
        "value":'0',
        "minute":'0'    
    },
    {
        "value":'1',
        "minute":'1'
    },
    {
        "value":'2',
        "minute":'2'
    },
    {
        "value":'3',
        "minute":'3'
    },
    {
        "value":'4',
        "minute":'4'
    },
    {
        "value":'5',
        "minute":'5'
    },
    {
        "value":'6',
        "minute":'6'
    },
    {
        "value":'7',
        "minute":'7'
    },
    {
        "value":'8',
        "minute":'8'
    },
    {
        "value":'9',
        "minute":'9'
    },
    
]

const userRole = localStorage.getItem('ro');

class ScreenShotSetting_modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            screenShottimeout: 0,
            screenShotInterval: "",
            selectedEmployee: {},
            screenshotIntervalType: "",
            showScreenShotEditModal: false,
            screenshotIntervalData: [],
            initialScreenShotIntervalData: [],
        }
    }

    componentDidMount(){
        this.fetchTimeTrackerTiming();
    }


    fetchTimeTrackerTiming = () => {
        this.props.getTimeTrackerInterval().then((res) => {
            if(res.value.status == 200){
                this.setState({screenshotIntervalData: res.value.data})
            }
        })
    }

    render() {
        const {t} = this.props
        
        const {open, onCancelClick, allempData} = this.props;


        let timingArray = minuteselect && minuteselect.map(el => ({
            name: el.minute,
        }));

       
        const selectEmployee = (person, type) => {
            this.setState({
                showScreenShotEditModal: true, 
                selectedEmployee: person, 
                screenshotIntervalType: type
            })

            setTimeout(() => {
                let initalData = this.state.screenshotIntervalData && this.state.screenshotIntervalData.filter(el => {
                    return el.user_id == this.state.selectedEmployee.user_id
                });
                this.setState({initialScreenShotIntervalData: initalData})
            }, 100);
        }


        const ScreenShotInterval = () => {
            if(userRole == 'Observer'){return}
            const dataa = {
                "id": this.state.selectedEmployee.id,
                "userName": this.state.selectedEmployee.user_login_as,
                "timeoutInterval": this.state.screenShottimeout,
                "screenshotInterval": this.state.screenShotInterval,
            }

            if(this.state.screenshotIntervalType == 'edit'){
                this.props.editTimeTrackerTiming(dataa).then((res) => {
                    if(res.value.status == 200){
                        if(res.value.data.message == 'id not available'){
            
                            toast.error('User Does not have Screenshot Interval, Please create Screenshot Interval');
                            this.setState({
                                showScreenShotEditModal: false,
                            })
                            return;
                        }
                        toast.success('Screen shot Setting Updated Successfully');
                        this.setState({
                            showScreenShotEditModal: false,
                            selectedEmployee: {},
                            screenshotIntervalType: "",
                            screenShottimeout: 0,
                            screenShotInterval: "",
                        })
                        this.fetchTimeTrackerTiming();
                    } else {
                        toast.error('Something went wrong');
                        this.setState({
                            showScreenShotEditModal: false,
                            selectedEmployee: {},
                            screenshotIntervalType: "",
                            screenShottimeout: 0,
                            screenShotInterval: "",
                        })
                    }
                })
            } else {
                this.props.createTimeTrackerTiming(dataa).then((res) => {
                    if(res.value.status == 200){
                        toast.success('ScreenShot Setting Created Successfully');
                        this.setState({
                            showScreenShotEditModal: false,
                            selectedEmployee: {},
                            screenshotIntervalType: "",
                            screenShottimeout: 0,
                            screenShotInterval: "",
                        })
                    } else if (res.value.status == 400) {
                        toast.error('User Already have Screenshot Interval');
                    }
                    else {
                        toast.error('Something went wrong');
                        this.setState({
                            showScreenShotEditModal: false,
                            selectedEmployee: {},
                            screenshotIntervalType: "",
                            screenShottimeout: 0,
                            screenShotInterval: "",
                        })
                    }
                })
            }
        }
       // console.log(this.state.screenshotIntervalData)
        return (
            <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onCancelClick}>
                <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                            <div>
                                <p className='text-lg font-semibold'>{t("screenshots")} {t("settings")}</p>
                                <div className="bg-white px-1 py-5 mt-2 border-b border-gray-200 sm:px-1 rounded-lg" style={{ maxHeight: "500px", overflowY: "scroll" }}>
                                    <div className="mt-2 flex flex-col overflow-y-auto bg-gray-100">
                                        <div className="inline-block min-w-full align-middle">
                                            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                                <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12"
                                                            >
                                                                {t("name")}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                                            >
                                                                {t("settingsTab.edit")}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                                                            >
                                                                {t("create")}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white">
                                                        {allempData.map((person, personIdx) => (
                                                            <tr key={person.email}>
                                                                <td
                                                                    className={classNames(
                                                                        personIdx !== allempData.length - 1 ? 'border-b border-gray-200' : '',
                                                                        'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12'
                                                                    )}
                                                                >
                                                                    {person.user_first_name} {" "} {person.user_last_name}
                                                                </td>
                                                                <td
                                                                    className={classNames(
                                                                        personIdx !== allempData.length - 1 ? 'border-b border-gray-200' : '',
                                                                        'whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell'
                                                                    )}
                                                                    
                                                                >
                                                                    <p className='' onClick={(e) => {selectEmployee(person, 'edit')}}><PencilAltIcon className='h-5 w-5 cursor-pointer hover:text-primary'/></p>
                                                                </td>
                                                                <td
                                                                    className={classNames(
                                                                        personIdx !== allempData.length - 1 ? 'border-b border-gray-200' : '',
                                                                        'whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell'
                                                                    )}
                                                                >
                                                                    <p className='' onClick={() => {this.setState({showScreenShotEditModal: true, selectedEmployee: person, screenshotIntervalType: 'create'})}}><DocumentAddIcon className='h-5 w-6 cursor-pointer hover:text-primary'/></p>

                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div className='mt-4 flex justify-end items-center'>
                                    <button className='bg-primary text-white font-semibold p-1 px-4 rounded-md shadow-sm transition duration-100 ease-in-out hover:-translate-y-1 hover:shadow-md' onClick={onCancelClick}>{t("activityMonitorModule.close")}</button>
                                </div>  
                            </div>                      
                        </div>
                    </Transition.Child>
                </div>
                <Transition.Root show={this.state.showScreenShotEditModal} as={Fragment}>
                    <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => {this.setState({showScreenShotEditModal: false})}}>
                        <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                                    <div>
                                        <p className='text-lg font-semibold'>{t("timeTracker.timeIntervalSettings")}</p>  
                                        <div className='mt-4'><InputField FieldName='Timeout Interval(Seconds)' onInputChange={(e) => {this.setState({screenShottimeout: e.target.value})}} type="number" placeholder={`Default: ${this.state.initialScreenShotIntervalData == "" ? "Not found" : this.state.initialScreenShotIntervalData[0].time_out_interval}`}/></div>
                                        <SelectField
                                            onSelectChange={(e) => {this.setState({screenShotInterval: e.target.value})}}
                                            FieldName={t("timeTracker.selectInterval")}
                                            name={"Screen Interval"}
                                            arrayOfData={timingArray}
                                            mainclassName={"mt-3"}
                                            id={"Screen Interval"}
                                            defaultValue = {this.state.initialScreenShotIntervalData == "" ? "" : this.state.initialScreenShotIntervalData[0].time_interval}
                                            // value={this.state.initialScreenShotIntervalData == "" ? "" : this.state.initialScreenShotIntervalData[0].screenshot_interval}
                                        >
                                        </SelectField>

                                        <div className='mt-5'>
                                            <button className={`${userRole ? 'bg-primary opacity-70' : 'bg-primary hover:-translate-y-1'} p-1 px-6 text-white font-semibold mr-3 rounded-md transition duration-150 ease-in-out `} onClick={() => {ScreenShotInterval()}}>{t("timeTracker.submit")}</button>
                                            <button className='border border-gray-400 font-semibold p-1 px-3 rounded-md transition duration-150 ease-in-out hover:-translate-y-1' onClick={() => {this.setState({showScreenShotEditModal: false})}}>{t("timeTracker.cancelBtn")}</button>
                                        </div>
                                    </div>                      
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
            </Dialog>
            </Transition.Root>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    getTimeTrackerInterval: () => dispatch(getTimeTrackerInterval()),
    editTimeTrackerTiming: (data) => dispatch(editTimeTrackerTiming(data)),
    createTimeTrackerTiming: (data) => dispatch(createTimeTrackerTiming(data)),
})

ScreenShotSetting_modal.propTypes = {
    open: PropTypes.bool,
    onCancelClick: PropTypes.func,
    allempData: PropTypes.array,
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ScreenShotSetting_modal));
