import React, { useEffect, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Layout from "./Components/Layout";
import Dashboard from "./scenes/Dashboard";
import UserProfile from "./scenes/UserProfile";
import Projects from "./scenes/Projects";
import Teams from "./scenes/Teams";
import TimeTracker from "./scenes/TimeTracker";
import Requests from "./scenes/Requests";
import ActivityMoniter from "./scenes/ActivityMoniter";
import Reports from "./scenes/Reports";
import Settings from "./scenes/Settings";
import Download from "./scenes/Download";
import Redirect from "./scenes/Redirect";
import ReusableComponents from "./scenes/ReusableComponents";
import ClientBilling from "./scenes/ClientBilling";
import InvoiceDetails from "./scenes/Teams/ClientInvoice";
import previewInvoice from "./scenes/Teams/ClientInvoice/previewInvoice";
import ClientProfile from "./scenes/Teams/ClientProfile";
import InviteUser from "./scenes/InviteUser";
import TeamDashboard from "./scenes/Dashboard/TeamDashboard";
import ShowCaseDashboard from "./scenes/Dashboard/ShowCaseDashboard";
import PayPalTransacComp from "./scenes/Settings/PayPal_transSuccessfull";
import PayPalTransacFailed from "./scenes/Settings/PayPal_transFailed";
import Employee_dashboard from "./scenes/Dashboard/Employee_DashBoard/Employee_dashboard";
import Employee_genericReq from "./scenes/Requests/employee_request/Employee_genericReq";
import EmployeeTimeTracker from "./scenes/TimeTracker/employee_timeTracker";
import EmployeeProfile from "./scenes/Teams/EmployeeProfile";
import Teams_sub_tab from "./scenes/Settings/Permission_sub_tab/Teams_sub_tab";
import { Dialog, Transition } from "@headlessui/react";
import InputField from "./Components/InputField";
import { GetOrganizationUUID } from "./Networks/Auth";
import AccessDenied from "./scenes/AccessDenied/Acess_Denied.js";
import moment from "moment";
import BlockUrls from "./scenes/BlockUrls";
import DetailedProjectPage from "./scenes/Dashboard/DetailedProjectPage";
import EditUserProfilePage from "./scenes/Teams/EditUserProfile.js";

function App() {
    const [showLoginModal, setShowLoginModal] = React.useState(false);

    const [showAskEmail, setShowAskEmail] = React.useState(true);
    const [showAskPassword, setShowAskPassword] = React.useState(false);

    const [orgList, setOrgList] = React.useState([]);

    const [email, setEmail] = React.useState("");
    const [org, setOrg] = React.useState("");
    const [password, setPassword] = React.useState("");

    // useEffect(() => {
    //   const access_token = localStorage.getItem('access_token');
    //   const refresh_token = localStorage.getItem('refresh_token');
    //   // setTimeout(() => {
    //     if((access_token == undefined || access_token == "undefined" || access_token == null || access_token == "")&&(refresh_token == undefined || refresh_token == "undefined" || refresh_token == null || refresh_token == "")){
    //       window.location.href = "https://www.joinworkahub.com/en/home";
    //     }
    //       // }, 200);
    // }, [])

    const getOrganization = (e) => {
        fetch("https://api.trypadlock.com/users/organization_require", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                setOrgList(data);
                setShowAskEmail(false);
                setShowAskPassword(true);
            });
    };

    const handleLoginSubmit = () => {
        fetch("https://api.trypadlock.com/users/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                org_name: org,
                password: password,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                localStorage.setItem("token", data.access_token);
                fetch(localStorage.getItem("apiURL") + "/api/token_verify/", {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json",
                    },
                    body: JSON.stringify({
                        marketing_access_key: data.access_token,
                        date: moment(new Date()).format("YYYY-MM-DD"),
                    }),
                })
                    .then((res) => res.json())
                    .then((data) => {
                        fetch(
                            localStorage.getItem("apiURL") +
                                `/api/role_check/?marketing_access_key=${localStorage.getItem(
                                    "token"
                                )}`,
                            {
                                method: "GET",
                            }
                        )
                            .then((res) => res.json())
                            .then((data) => {
                                localStorage.setItem("ro", data.message);
                            });
                        localStorage.setItem("user_email", data.email);
                        localStorage.setItem("org_name", data.org_name);
                        localStorage.setItem("org_username", data.username);
                        GetOrganizationUUID(data.org_name);
                        fetch(localStorage.getItem("apiURL") + "/api/token/obtain/", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                uu_id: data.uu_id,
                                username: data.username,
                            }),
                        })
                            .then((res) => res.json())
                            .then((data) => {
                                localStorage.setItem("access_token", data.access);
                                localStorage.setItem("refresh_token", data.refresh);
                                setShowLoginModal(false);
                                window.location.reload();
                            });
                    });
            });
    };

    return (
        <Router>
            {/* {showLoginModal == true 
        ?
         <div>
         <Transition.Root show={showLoginModal} as={Fragment}>
           <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => {}}>
               <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                   <Transition.Child
                       as={Fragment}
                       enter="ease-out duration-300"
                       enterFrom="opacity-0"
                       enterTo="opacity-100"
                       leave="ease-in duration-200"
                       leaveFrom="opacity-100"
                       leaveTo="opacity-0"
                   >
                       <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                   </Transition.Child>

                   <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                       &#8203;
                   </span>
                   <Transition.Child
                       as={Fragment}
                       enter="ease-out duration-300"
                       enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                       enterTo="opacity-100 translate-y-0 sm:scale-100"
                       leave="ease-in duration-200"
                       leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                       leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                   >
                       <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
                           <div className='p-5'>
                           <h1 className='text-2xl font-semibold'>Login</h1>
                           {showAskEmail 
                               ?
                                <div className='mt-6'>
                                  <InputField onInputChange={(e) => setEmail(e.target.value)} FieldName={"Email"} type={'text'}/>
                                  <div className='mt-5'>
                                      <button onClick={() => {getOrganization()}} className='p-1 px-4 rounded-md bg-primary text-white cursor-pointer duration-300 hover:-translate-y-1'>Proceed</button>
                                  </div>
                                </div>
                               :   null
                           }
                           {showAskPassword
                               ? <div className='mt-5'>
                                   <p>Email : {email}</p>
                                   <select onChange={(e) => {setOrg(e.target.value)}} className="rounded-md mt-2">
                                    <option className="p-1">Select Organisation</option>
                                    {orgList && orgList.list.map((el, idx) => (
                                      <option value={el} key={idx} className="p-1 rounded-sm">{el}</option>
                                    ))}
                                   </select>
                                   <div className="mt-4">
                                       <InputField FieldName={"Password"} type={'password'} onInputChange={(e) => setPassword(e.target.value)}/>
                                   </div>
                                   <div className='mt-8'>
                                       <button onClick={() => {setEmail(''); setOrg(''); setPassword(''); setShowAskPassword(false); setShowAskEmail(true)}} className='p-1 px-4 rounded-md border border-primary text-primary mr-3 cursor-pointer duration-300 hover:-translate-y-1'>Back</button>
                                       <button onClick={handleLoginSubmit} className='p-1 px-4 rounded-md bg-primary text-white cursor-pointer duration-300 hover:-translate-y-1'>Submit</button>
                                   </div>    
                               </div>
                               : null
                           }
                       </div>
                           
                       
                       </div>
                   </Transition.Child>
               </div>
           </Dialog>
         </Transition.Root>
         </div>
        : <div></div>
      } */}

            <Route
                exact
                path="/"
                component={() => {
                    window.location.href = "/dashboard";
                }}
            />
            <Route exact path="/redirect" component={Redirect} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/profile" component={UserProfile} />
            <Route exact path="/profile/skills" component={UserProfile} />
            <Route exact path="/profile/projects" component={UserProfile} />
            <Route exact path="/profile/timesheet" component={UserProfile} />
            <Route exact path="/profile/payinfo" component={UserProfile} />
            <Route exact path="/profile/payrollhistory" component={UserProfile} />
            <Route exact path="/emp/dashboard" component={Employee_dashboard} />
            <Route exact path="/emp/requests" component={Employee_genericReq} />
            <Route exact path="/emp/timetracker" component={EmployeeTimeTracker} />
            <Route exact path="/emp/timetracker/:date" component={EmployeeTimeTracker} />
            <Route exact path="/team/?teamName=:teamName&date=:date" component={TeamDashboard} />
            <Route exact path="/projects/?project=:project" component={DetailedProjectPage} />
            <Route exact path="/dashboard/showcase" component={ShowCaseDashboard} />
            <Route exact path="/dashboard/showcase/:date" component={ShowCaseDashboard} />
            {/* <Route exact path="/dashboard/?teamName=:teamName&date=:date" component={TeamDashboard} /> */}
            {/* <Route exact path="/dashboard/?project=:project" component={DetailedProjectPage} /> */}
            <Route exact path="/inviteuser" component={InviteUser} />
            <Route exact path="/team" component={Teams} />
            <Route exact path="/team/teams" component={Teams} />
            <Route exact path="/team/teams/*" component={Teams} />
            <Route exact path="/team/users" component={Teams} />
            <Route exact path="/team/workstations" component={Teams} />
            <Route exact path="/team/observers" component={Teams} />
            <Route exact path="/team/manager" component={Teams} />
            <Route exact path="/team/client" component={Teams} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/tasks" component={Projects} />
            <Route exact path="/tasks/:taskname" component={Projects} />

            <Route exact path="/timetracker" component={TimeTracker} />
            <Route exact path="/timetracker/screenshots" component={TimeTracker} />
            <Route exact path="/timetracker/cpuinformation" component={TimeTracker} />
            <Route exact path="/timetracker/cpuinformation/cpu" component={TimeTracker} />
            <Route exact path="/timetracker/cpuinformation/memory" component={TimeTracker} />
            <Route exact path="/timetracker/cpuinformation/network" component={TimeTracker} />
            <Route exact path="/timetracker/cpuinformation/disk" component={TimeTracker} />
            <Route exact path="/timetracker/applications" component={TimeTracker} />
            <Route exact path="/timetracker/activitylog" component={TimeTracker} />
            <Route exact path="/timetracker/location" component={TimeTracker} />

            <Route exact path="/requests" component={Requests} />
            <Route exact path="/requests/approval-pending" component={Requests} />
            <Route exact path="/requests/past-requests" component={Requests} />
            <Route exact path="/client_billing" component={ClientBilling} />
            <Route exact path="/edit_Profile" component={EmployeeProfile} />
            <Route exact path="/activity-moniter" component={ActivityMoniter} />
            <Route exact path="/blockurls" component={BlockUrls} />
            {/* <Route exact path="/activity-moniter" component={ActivityMoniter} /> */}

            <Route exact path="/reports" component={Reports} />
            <Route exact path="/reports/attendance" component={Reports} />
            <Route exact path="/reports/productivity" component={Reports} />
            <Route exact path="/reports/hour-report" component={Reports} />
            <Route exact path="/reports/booking-report" component={Reports} />
            <Route exact path="/reports/payroll-report" component={Reports} />
            <Route exact path="/reports/total-hour-report" component={Reports} />

            <Route exact path="/settings" component={Settings} />
            <Route exact path="/settings/payments" component={Settings} />
            <Route exact path="/settings/payments/plan" component={Settings} />
            <Route exact path="/settings/payments/billings" component={Settings} />
            <Route exact path="/settings/payments/invoices" component={Settings} />
            <Route exact path="/settings/integrations" component={Settings} />
            <Route exact path="/settings/permissions" component={Settings} />
            <Route exact path="/settings/permissions/global-permission" component={Settings} />
            <Route exact path="/settings/permissions/teams-permission" component={Settings} />
            <Route exact path="/settings/permissions/teams-permission/*" component={Settings} />
            <Route exact path="/settings/permissions/users-permission" component={Settings} />
            <Route exact path="/settings/permissions/users-permission/*" component={Settings} />
            <Route exact path="/settings/general-settings" component={Settings} />
            <Route
                exact
                path="/settings/general-settings/organisation-settings"
                component={Settings}
            />
            <Route exact path="/settings/general-settings/account-settings" component={Settings} />
            <Route exact path="/settings/general-settings/email-settings" component={Settings} />
            <Route exact path="/settings/features" component={Settings} />
            <Route exact path="/settings/features/available-features" component={Settings} />
            <Route exact path="/settings/features/unavailable-features" component={Settings} />
            <Route exact path="/settings/connectors" component={Settings} />
            <Route exact path="/settings/events" component={Settings} />
            {/* <Route exact path="/settings/indexes" component={Settings} /> */}

            <Route exact path="/download" component={Download} />
            <Route exact path="/access-denied" component={AccessDenied} />

            <Route exact path="/users/cancel" component={PayPalTransacFailed} />
            <Route exact path="/users/:success_payment" component={PayPalTransacComp} />
            <Route exact path="/users/:process_payment" component={PayPalTransacComp} />
            <Route exact path="/team/client_details" component={InvoiceDetails} />
            <Route path="/client_details" component={ClientProfile} />
            <Route exact path="/bill/preview_invoice" component={previewInvoice} />
            <Route exact path="/edit_user_profile" component={EditUserProfilePage} />
            {/* Reusable Components */}
            <Route exact path="/rc" component={ReusableComponents} />
        </Router>
    );
}

export default App;
