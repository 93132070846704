const globalLocalStorageReducer = (state = "", action) => {
    switch (action.type) {
        case "STORE_TO_LOCALSTORAGE":
            return action.payload;

        // case 'SHOW_DATA':
        //     return action.payload;

        default:
            return state;
    }
};

export default globalLocalStorageReducer;
