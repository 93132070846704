import React, { Component } from 'react'
import { connect } from 'react-redux';
import SelectField from '../../Components/SelectField';
import AdminAppRejectRequest from './Admin_approveReject_modal';
import ViewDetailedRequest from './ViewRequestDetail_modal';

//actions
import { fetchAllRequests } from '../../services/requests/actions';
import { fetchallEmployees } from '../../services/projects/actions';

//dependencies
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

//icons
import { CalendarIcon } from '@heroicons/react/outline';

import noDataSVG from '../../images/nodata.svg';
import { withTranslation } from 'react-i18next';
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

class ApprovalPending_tab extends Component {
    constructor() {
        super();
        this.state = {
            selectedDate: new Date(),
            selectedRequest: "",
            selectedEmp: "",
            empSort: false,
            selectReqType: "",
            reqSort: false,
            filteredData: [],
            showViewDetailedRequestModal: false,
            isUserNotFound: false,
            isRequestTypeNotFound: false,
            sortOrder: "descending",
        };
    }

    componentDidMount() {
        this.props.fetchallEmployees();

        this.props.fetchAllRequests().then((data) => {
            const approvalPending_requestsList =
                data &&
                data.value.data.filter((request) => {
                    return request.status === "pending";
                });
            this.setState({
                filteredData: approvalPending_requestsList,
            });
        });
    }

    handleDateChange = (date) => {
        console.log("date changed occur");
        const getAllRequests = this.props.requests;
        const listOfAllRequest = getAllRequests && getAllRequests.AllRequests;
        const approvalPending_requestsList =
            listOfAllRequest &&
            listOfAllRequest.filter((request) => {
                return request.status === "pending";
            });
        this.setState({ selectedDate: date }, () => {
            const filteredDate = approvalPending_requestsList.filter((request) => {
                return moment(request.created).isBefore(moment(date));
            });
            this.setState({
                filteredData:
                    this.state.selectedDate == null ? approvalPending_requestsList : filteredDate,
            });
        });
    };

    render() {
        const { t } = this.props;
        const requestTypes = [
            { type: "Sick" },
            { type: "Family Emergency" },
            { type: "Vacation Leave" },
            { type: "Work from home" },
            { type: "offline" },
            { type: "Night Shift" },
            { type: "Partial" },
            { type: "HR" },
            { type: "Equipment" },
            { type: "Salary Revision" },
            { type: "Team Help" },
        ];

        const getAllRequests = this.props.requests;
        const listOfAllRequest = getAllRequests && getAllRequests.AllRequests;

        const approvalPending_requestsList =
            listOfAllRequest &&
            listOfAllRequest.filter((request) => {
                return request.status === "pending";
            });
        let empList = this.props.allEmployees;
        let listofAllEmployees = empList && empList.AllEmployees;

        let Select_emp_dropdown =
            listofAllEmployees &&
            listofAllEmployees.map((emp, index) => ({
                name: emp.user_first_name + " " + emp.user_last_name,
                id: emp.user_login_as,
            }));
        Select_emp_dropdown =
            Select_emp_dropdown &&
            Select_emp_dropdown?.sort((a, b) => a.name.localeCompare(b.name));

        // console.log("Select_emp_dropdown",Select_emp_dropdown)

        const onSelectEmployee = (e) => {
            const filteredEmp = approvalPending_requestsList.filter((request) => {
                return request.user === e.target.value;
            });
            if (e.target.value === "") {
                this.setState({
                    filteredData: approvalPending_requestsList,
                    empSort: false,
                    isUserNotFound: false,
                });
            } else if (filteredEmp.length === 0) {
                this.setState({
                    isUserNotFound: true,
                    empSort: false,
                });
            } else {
                this.setState({
                    selectedEmp: filteredEmp,
                    isUserNotFound: false,
                });
                this.setState({
                    empSort: true,
                });
            }
        };

        const handleSelectRequestType = (e) => {
            const selectedRequestType = e.target.value;
            const filteredType = approvalPending_requestsList.filter((request) => {
                return request.type === selectedRequestType;
            });
            if (selectedRequestType == "") {
                this.setState({
                    filteredData: approvalPending_requestsList,
                    reqSort: false,
                    isRequestTypeNotFound: false,
                });
            } else if (filteredType.length === 0) {
                this.setState({
                    isRequestTypeNotFound: true,
                    reqSort: false,
                });
            } else {
                this.setState({
                    selectReqType: filteredType,
                    reqSort: true,
                    isRequestTypeNotFound: false,
                });
            }
        };

        const listOfreq = requestTypes
            ?.sort((a, b) => a.type.localeCompare(b.type))
            .map((el) => ({
                name: el.type,
            }));

        return (
            <>
                <div className="bg-white px-1 py-1 mr-2 mt-6 border-b mb-5 lg:mb-0 border-gray-200 sm:px-6 rounded-lg flex-none shadow-lg">
                    <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
                        <div className="flex text-textprimary text-lg font-semibold relative">
                            <DatePicker
                                className="rounded-md w-52 text-black cursor-pointer"
                                selected={this.state.selectedDate}
                                dateFormat="MMMM dd yyyy"
                                value={this.state.selectedDate}
                                startDate={this.state.selectedDate}
                                onChange={(date) => this.handleDateChange(date)}
                            />
                            <CalendarIcon className="absolute top-2 right-2 w-6"></CalendarIcon>
                        </div>
                        <div className="flex flex-col lg:flex-row justify-around mt-5 xl:mt-0">
                            <div className="my-3 lg:my-0 mr-3 w-52">
                                <SelectField
                                    onSelectChange={(e) => {
                                        onSelectEmployee(e);
                                    }}
                                    name={"Select Employee"}
                                    FieldName={t("select") + " " + t("employee")}
                                    mainclassName={"mr-4"}
                                    arrayOfData={Select_emp_dropdown}
                                    id={"name"}></SelectField>
                            </div>
                            <div className="flex flex-shrink-0">
                                <SelectField
                                    onSelectChange={(e) => {
                                        handleSelectRequestType(e);
                                    }}
                                    name={"Select Status"}
                                    FieldName={t("select") + " " + t("type")}
                                    arrayOfData={listOfreq}
                                    id={"name"}></SelectField>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 flex flex-col bg-gray-100" style={{ maxHeight: "auto" }}>
                        <div className="inline-block min-w-full align-middle">
                            <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[63vh] overflow-y-auto">
                                <table className="min-w-full height-100vh border-separate">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="sticky top-0  border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                {t("name")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="sticky top-0  hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                {t("type")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="sticky top-0 hidden lg:!flex gap-2 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                {t("date")}
                                                {this.state.sortOrder !== "ascending" ? (
                                                    <button
                                                        title="Sort Ascending"
                                                        onClick={() => {
                                                            this.setState({
                                                                sortOrder: "ascending",
                                                            });
                                                        }}
                                                        className={`flex flex-col ml-3`}>
                                                        <IoMdArrowDropup className="my-0 text-xs" />
                                                        <IoMdArrowDropdown className="my-0 text-xs" />
                                                    </button>
                                                ) : (
                                                    <button
                                                        title="Sort Descending"
                                                        onClick={() => {
                                                            this.setState({
                                                                sortOrder: "descending",
                                                            });
                                                        }}
                                                        className={`flex flex-col ml-3`}>
                                                        <IoMdArrowDropup className="my-0 text-xs" />
                                                        <IoMdArrowDropdown className="my-0 text-xs" />
                                                    </button>
                                                )}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {this.state.isUserNotFound ||
                                        this.state.isRequestTypeNotFound ||
                                        (this.state.empSort &&
                                            this.state.reqSort &&
                                            this.state.filteredData.length === 0) ||
                                        (this.state.empSort &&
                                            this.state.reqSort &&
                                            this.state.filteredData.length > 0 &&
                                            !this.state.filteredData.some(
                                                (item) =>
                                                    this.state.selectedEmp.includes(item) &&
                                                    this.state.selectReqType.includes(item)
                                            )) ? (
                                            <tr>
                                                <td
                                                    colSpan={3}
                                                    className="flex-1 text-center py-6 italic text-[#6B7280]">
                                                    User or Request type not found
                                                </td>
                                            </tr>
                                        ) : this.state.filteredData === "" ||
                                          this.state.filteredData.length === 0 ? (
                                            <tr>
                                                <td
                                                    colSpan={3}
                                                    className="flex-1 text-center py-6 italic text-[#6B7280]">
                                                    No Records Found
                                                </td>
                                            </tr>
                                        ) : (
                                            this.state.filteredData &&
                                            (this.state.empSort && this.state.reqSort
                                                ? this.state.filteredData.filter(
                                                      (item) =>
                                                          this.state.selectedEmp.includes(item) &&
                                                          this.state.selectReqType.includes(item)
                                                  )
                                                : this.state.empSort
                                                ? this.state.filteredData.filter((item) =>
                                                      this.state.selectedEmp.includes(item)
                                                  )
                                                : this.state.reqSort
                                                ? this.state.filteredData.filter((item) =>
                                                      this.state.selectReqType.includes(item)
                                                  )
                                                : this.state.filteredData
                                            )
                                                .sort((a, b) =>
                                                    this.state.sortOrder === "descending"
                                                        ? new Date(b.created) - new Date(a.created)
                                                        : new Date(a.created) - new Date(b.created)
                                                )
                                                .map((request, idx) => (
                                                    <tr
                                                        key={idx}
                                                        onClick={() => {
                                                            this.setState({
                                                                selectedRequest: request,
                                                                showViewDetailedRequestModal: true,
                                                            });
                                                        }}
                                                        className="cursor-pointer">
                                                        <td
                                                            className={classNames(
                                                                idx !==
                                                                    this.state.filteredData.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-primary sm:pl-6 lg:pl-12"
                                                            )}>
                                                            {request.user}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                idx !==
                                                                    this.state.filteredData.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-base text-gray-500 hidden sm:table-cell"
                                                            )}>
                                                            {request.type}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                idx !==
                                                                    this.state.filteredData.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-base text-gray-500 hidden lg:table-cell"
                                                            )}>
                                                            {request.created}
                                                        </td>
                                                    </tr>
                                                ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showViewDetailedRequestModal && (
                    <ViewDetailedRequest
                        open={this.state.showViewDetailedRequestModal}
                        onCancelClick={() => {
                            this.setState({ showViewDetailedRequestModal: false });
                        }}
                        successSubmit={() => {
                            this.props.fetchAllRequests().then((data) => {
                                const approvalPending_requestsList =
                                    data &&
                                    data.value.data.filter((request) => {
                                        return request.status === "pending";
                                    });
                                this.setState({
                                    filteredData: approvalPending_requestsList,
                                });
                            });
                        }}
                        data={this.state.selectedRequest}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    requests: state.get_all_request,
    allEmployees: state.projects,
})

const mapDispatchToProps = (dispatch) => ({
    fetchallEmployees: () => dispatch(fetchallEmployees()),
    fetchAllRequests: () => dispatch(fetchAllRequests()),
})

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ApprovalPending_tab));
