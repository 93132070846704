import { CashIcon, ChevronDownIcon, PlusIcon } from "@heroicons/react/outline";
import {
  CameraIcon,
  DeviceMobileIcon,
  DotsVerticalIcon,
  PencilIcon,
  TrashIcon,
  UserIcon,
} from "@heroicons/react/solid";
import timeSuccessIcon from "../../assets/timeSuccess.svg";
import React, { Component } from "react";
import { BsEnvelopeFill } from "react-icons/bs";
import ReactHighcharts from "react-highcharts";
import moment from "moment";
import Layout from "../../Components/Layout";
import BeatLoader from "react-spinners/BeatLoader";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ChangeProfileModal } from "./ChangeProfileModal";

import {
  getUserProfileImg,
  getSkills,
  getLocDesg,
  getProjectData,
  updateUserProfileImg,
  postLocDesg,
  postSkills,
  updateLocDesg,
  updateSkills,
  getUserData,
} from "../../services/userProfile/actions";

import {
  getEmployeePhoneNumber,
  getSalaryDaysData,
  getSalaryYearData,
  removeUserbasedProject,
  addUserbasedProject,
  DeleteUserfromPadlock,
  DeleteUser,
  getSalaryHistory,getUserBasedProjects
} from "../../services/teams/actions";
import { fetchAttendanceTimings } from "../../services/reports/actions";
import { getUserProject } from "../../services/employee/actions";
import { Link } from "react-router-dom";
import SelectField from "../../Components/SelectField";
import { AssignNewProjectModal } from "./AssignNewProjectModal";
import { toast } from "react-toastify";
import ConfirmationDialogue from "../../Components/ConfirmationDialogue";
import { ChevronLeftRounded } from "@material-ui/icons";
// import ConfirmationDialoag from "../../../Components/ConfirmationDialog";
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "Novemeber",
    "December",
];
class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SelectedTab: "skills",
            skills: [],
            isAdmin: localStorage.getItem("ro") === "Admin",
            userId: "",
            inputSkill: false,
            selectedDate: new Date(),
            showChangeProfileModal: false,
            userDetails: "",
            selectedFinancialYear: new Date().getFullYear(),
            yearOptions: [],
            profileImage: "",
            phoneNumber: "",
            workingHours: "",
            inputLoc: false,
            inputDesg: false,
            assignProject: false,
            showDeleteProjectModal: false,
            selectdProject: "",
            singlePayInfo: false,
            payInfoMonth: "",
            enableEdit: false,
            editProfile: false,
            enableDeleteUserConfirmationModal: false,
            userBasedProjects:[],
        };
    }
    async componentDidMount() {
        let yearsOption = [];
        for (let currentYear = new Date().getFullYear(); currentYear >= 2019; currentYear--) {
            yearsOption.push({
                name: currentYear + " - " + (currentYear + 1),
                id: currentYear,
            });
        }
        this.setState({ yearOptions: yearsOption });
        const url = window.location.pathname;
        const query = new URLSearchParams(this.props.location.search);
        const user = query.get("user");
        let params = "";
        let params1 = "";
        if (user) {
            params = "?user_id=" + user;
            params1 = "?user=" + user;
            this.setState({ userId: user });
            this.props.getUserProject();
        }
        await this.props.getUserData(params1).then((res) => {
        this.setState({ userDetails: res.value.data.data });
        });
        await this.getUserBasedProjects().then(() => {
        console.log(this.state.userBasedProjects, "user");
        });
        setTimeout(() => {
            this.getUserBasedProjects();
            const url = window.location.pathname;
            const query = new URLSearchParams(this.props.location.search);
            const user = query.get("user");
            let params = "";
            let params1 = "";
            if (user) {
                params = "?user_id=" + user;
                params1 = "?user=" + user;
                this.setState({ userId: user });
                this.props.getUserProject();
            }
            this.props
                .getUserProfileImg(user ? "/admins/?user_uuid=" + user : "/users")
                .then((res) => {
                    if (res.value.status === 200 && res.value.data && res.value.data.url) {
                        this.setState({ profileImage: res.value.data.url });
                    }
                });
            this.props.getEmployeePhoneNumber(user).then((res) => {
                if (res.value.status === 200) {
                    this.setState({ phoneNumber: res.value.data.phone_number });
                }
            });
            this.props.getUserData(params1).then((res) => {
                this.setState({ userDetails: res.value.data.data });
            });
            this.props.getSkills(params1).then((res) => {
                if (res.value.data.data !== "no data is found") {
                    this.setState({ skills: res.value.data.data });
                }
            });

            if (url.includes("/profile/skills")) {
                this.setState({ SelectedTab: "skills" });
            } else if (url.includes("/profile/projects")) {
                this.setState({ SelectedTab: "projects" }, () => this.props.getProjectData(params));
            } else if (url.includes("/profile/timesheet")) {
                this.setState({ SelectedTab: "timesheet" }, () =>
                    this.props.getSalaryDaysData(
                        user +
                            "&start_date=" +
                            moment(this.state.selectedDate).startOf("month").format("YYYY-MM-DD") +
                            "&end_date=" +
                            moment(this.state.selectedDate).endOf("month").format("YYYY-MM-DD")
                    )
                );
            } else if (url.includes("/profile/payinfo")) {
                this.setState({ SelectedTab: "payinfo" }, () =>
                    this.props.getSalaryYearData(
                        user +
                            "&start_year=" +
                            this.state.selectedFinancialYear +
                            "&end_year=" +
                            (this.state.selectedFinancialYear + 1)
                    )
                );
            } else if (url.includes("/profile/payrollhistory")) {
                this.setState({ SelectedTab: "payrollhistory" }, () =>
                    this.props.getSalaryHistory(user)
                );
            } else {
                this.setState({ SelectedTab: "skills" }, () => {
                    window.history.replaceState(
                        {},
                        null,
                        "/profile/skills/" + this.props.location.search
                    );
                });
            }
        }, 200);
        this.props.fetchAttendanceTimings().then((res) => {
            if (res.value.status === 200) {
                this.setState({
                    workingHours: res.value.data.number,
                });
            } else {
                this.setState({
                    workingHours: 480,
                });
            }
        });
    }
    // getUserBasedProjects = () => {
    //     this.props.getUserBasedProjects(this.state.userDetails.user_login_as).then((res) => {
    //         if (res.value.status === 201) {
    //             this.setState({
    //                 userBasedProjects: res.value.data,
    //             });
    //         } else if (res.value.status === 400) {
    //             this.setState({
    //                 userBasedProjects: [],
    //             });
    //         }
    //     });
    // };
    getUserBasedProjects = async () => {
        try {
          const res = await this.props.getUserBasedProjects(this.state.userDetails.user_login_as);
          if (res.value.status === 201) {
            this.setState({
              userBasedProjects: res.value.data,
            });
          } else if (res.value.status === 400) {
            this.setState({
              userBasedProjects: [],
            });
          }
        } catch (error) {
          console.error("Error fetching user-based projects:", error);
        }
      };
    HandleSelectTab = (e) => {
        this.setState(
            {
                SelectedTab: e,
            },
            e === "projects"
                ? () =>
                      this.props.getProjectData(
                          this.state.isAdmin ? "?user_id=" + this.state.userId : ""
                      )
                : e === "timesheet"
                ? () =>
                      this.props.getSalaryDaysData(
                          this.state.userId +
                              "&start_date=" +
                              moment(this.state.selectedDate)
                                  .startOf("month")
                                  .format("YYYY-MM-DD") +
                              "&end_date=" +
                              moment(this.state.selectedDate).endOf("month").format("YYYY-MM-DD")
                      )
                : e === "payinfo"
                ? () =>
                      this.props.getSalaryYearData(
                          this.state.userId +
                              "&start_year=" +
                              this.state.selectedFinancialYear +
                              "&end_year=" +
                              (this.state.selectedFinancialYear + 1)
                      )
                : e === "payrollhistory"
                ? () => this.props.getSalaryHistory(this.state.userId)
                : () => {}
        );
        window.history.pushState(
            {},
            null,
            `/profile/${e}${this.state.isAdmin ? "/?user=" + this.state.userId : ""}`
        );
    };

    HandleMobileSelectTab = (e) => {
        this.setState(
            { SelectedTab: e.target.value },
            e.target.value === "projects"
                ? () =>
                      this.props.getProjectData(
                          this.state.isAdmin ? "?user_id=" + this.state.userId : ""
                      )
                : e.target.value === "timesheet"
                ? () =>
                      this.props.getSalaryDaysData(
                          this.state.userId +
                              "&start_date=" +
                              moment(this.state.selectedDate)
                                  .startOf("month")
                                  .format("YYYY-MM-DD") +
                              "&end_date=" +
                              moment(this.state.selectedDate).endOf("month").format("YYYY-MM-DD")
                      )
                : e.target.value === "payinfo"
                ? () =>
                      this.props.getSalaryYearData(
                          this.state.userId +
                              "&start_year=" +
                              this.state.selectedFinancialYear +
                              "&end_year=" +
                              (this.state.selectedFinancialYear + 1)
                      )
                : e.target.value === "payrollhistory"
                ? () => this.props.getSalaryHistory(this.state.userId)
                : () => {}
        );
        window.history.pushState(
            {},
            null,
            `/profile/${e.target.value}${this.state.isAdmin ? "/?user=" + this.state.userId : ""}`
        );
    };
    onHandleDeleteUserClick = () => {
        const orgemail = localStorage.getItem("user_email");
        this.props.DeleteUserfromPadlock(this.state.userDetails["email"], orgemail);
        this.props.DeleteUser(this.state.userDetails["user_id"]).then(() => {
            toast.success("User Deleted Successfully");
            window.location.href = "/team/users";
        });
        this.setState({ enableDeleteUserConfirmationModal: false });
    };
    render() {
        // const { t } = this.props;
        let Select_project_dropdown =
            this.props.empDashboard &&
            this.props.empDashboard.UserProjects &&
            this.props.empDashboard.UserProjects.projects &&
            this.props.empDashboard.UserProjects.projects.map((el) => ({
                name: el.project_name,
            }));
        const projectsData = this.props.userprofile && this.props.userprofile.UserProjectData;
        if(this.state.userBasedProjects && this.state.userBasedProjects.length>0 &&
            projectsData && projectsData["ongoing"]){
              this.state.userBasedProjects.forEach((project)=>{
                if(!projectsData["ongoing"].find((item)=>item.name ===project)){
                  projectsData["ongoing"].push({status:"On going",name:project})
                }
              })
            }
        const ongoingProjects =
        this.state.userBasedProjects &&
        this.state.userBasedProjects.length > 0 &&
        this.state.userBasedProjects.filter((item) => {
            const completedProjects = projectsData && projectsData["completed"];
            return !completedProjects?.find((iteem) => iteem.name === item);
        });
        const loadingProject =
            this.props.userprofile && this.props.userprofile.fetchingUserProjectData;
        const timeSheetData = this.props.teams && this.props.teams.SalaryDaysData;
        let yearSalaryData = this.props.teams && this.props.teams.SalaryYearData;
        let yearSalaryChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let salaryData = this.props.teams && this.props.teams.SalaryDaysData;
        for (let i = 0; i < (yearSalaryData && yearSalaryData.length); i++) {
            yearSalaryChartData[yearSalaryData[i].month - 1] = yearSalaryData[i].cost;
        }
        const payrollHistory =
            this.props.teams &&
            this.props.teams.SalaryHistory &&
            this.props.teams.SalaryHistory.data;
        const chartData = {
            chart: {
                type: "spline",
                height: 350,
                style: {
                    fontFamily: "Lato",
                    fontSize: "15px",
                },
            },
            title: {
                text: "",
            },
            plotOptions: {
                series: {
                    cursor: "pointer",
                    allowPointSelect: true,
                },
                column: {
                    zones: [
                        {
                            value: 0,
                            color: "#684D94",
                        },
                    ],
                    pointPadding: 0.1,
                    borderWidth: 0,
                },
            },
            xAxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
                crosshair: true,
            },
            yAxis: [
                {
                    title: {
                        text: "",
                    },
                },
            ],
            tooltip: {
                valuePrefix: "$",
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    showInLegend: false,
                    color: "#684D94",
                    name: "In Hand Salary",
                    point: {
                        events: {
                            select: (e) => {
                                // let endTimeHour = parseInt(e.target.category.slice(0, 2));
                                // let endTimeMin = parseInt(e.target.category.slice(3, 5));
                                // this.onDataPointSelect(endTimeHour, endTimeMin);
                            },
                        },
                    },
                    data: yearSalaryChartData,
                },
            ],
        };
        const handleEmployeeProfileNav = (data) => {
            const dataVal = { ...data };
            dataVal["user_email"] = data["email"];
            dataVal["user_first_name"] = data["first_name"];
            dataVal["user_last_name"] = data["last_name"];
            localStorage.setItem("data", JSON.stringify(dataVal));
        };
        const showDeleteProjectModal = (project) => {
            this.setState({
                showDeleteProjectModal: true,
                selectedProject: project,
            });
        };
        const handleDeleteProject = () => {
            const dataa = {
                projectName: this.state.selectedProject,
                userName: this.state.userDetails.user_login_as,
            };

            this.props.removeUserbasedProject(dataa).then((res) => {
                if (res.value.status === 200) {
                    toast.success("Project removed successfully");
                    this.setState({
                        showDeleteProjectModal: false,
                        selectedProject: "",
                    });
                    this.getUserBasedProjects();
                } else {
                    toast.error("Something went wrong");
                }
            });
        };

        // console.log(this.props,this.state.userDetails)

        return (
            <Layout>
                <div className="pb-3">
                    {this.state.isAdmin ? (
                        <Link
                            to="/team/users"
                            className="rounded-md shadow-md border max-w-max bg-white border-fiver border-opacity-60 py-2 px-4 hover:cursor-pointer">
                            Back
                        </Link>
                    ) : (
                        <div className="text-2xl">Your Profile</div>
                    )}
                </div>
                <div className="w-full bg-white pt-6 px-6 rounded-md shadow-md mt-4">
                    {this.state.userDetails ? (
                        <div className="flex">
                            <div className="relative">
                                <div className=" text-gray-400 overflow-hidden border-4 flex items-center justify-center border-[#D1D5DB] h-20 w-20 xl:h-24 xl:w-24 2xl:h-28 2xl:w-28 rounded-full">
                                    {this.state.profileImage ? (
                                        <img src={this.state.profileImage} alt="User Profile" />
                                    ) : (
                                        <UserIcon />
                                    )}
                                </div>
                                {!this.state.isAdmin && (
                                    <button
                                        className="absolute bottom-3 right-0 xl:bottom-1.5 rounded-full bg-[#1055EB] text-white p-1"
                                        onClick={() =>
                                            this.setState({ showChangeProfileModal: true })
                                        }>
                                        <CameraIcon className="w-4 h-4 xl:w-5 xl:h-5" />
                                    </button>
                                )}
                            </div>
                            <div className="ml-2 flex flex-col flex-1 justify-around sm:ml-4">
                                <div className="flex relative justify-between items-end w-full">
                                    <div className="flex items-center">
                                        <div className="text-2xl">
                                            {this.state.userDetails["first_name"] +
                                                " " +
                                                this.state.userDetails["last_name"]}
                                        </div>
                                        <div className="text-gray-400 pl-1">
                                            (
                                            {this.state.userDetails.admin
                                                ? "Admin"
                                                : this.state.userDetails.observer
                                                ? "Observer"
                                                : this.state.userDetails.manager
                                                ? "Manager"
                                                : "Regular"}
                                            )
                                        </div>
                                    </div>
                                    {this.state.isAdmin && (
                                        <DotsVerticalIcon
                                            className="w-5 h-5 hover:cursor-pointer"
                                            onClick={() =>
                                                this.setState({
                                                    editProfile: !this.state.editProfile,
                                                })
                                            }
                                        />
                                    )}
                                    {this.state.isAdmin && this.state.editProfile && (
                                        <div className="absolute py-2 text-gray-500 right-0 top-10 w-44 rounded-lg border border-gray-200 shadow-md">
                                            <Link
                                                to="/edit_Profile"
                                                onClick={() => {
                                                    handleEmployeeProfileNav(
                                                        this.state.userDetails
                                                    );
                                                }}
                                                className="flex items-center gap-2 px-4 pb-1 hover:bg-gray hover:cursor-pointer">
                                                <PencilIcon className="w-4 h-4" />
                                                Edit profile
                                            </Link>
                                            <div
                                                className="flex items-center gap-2 px-4 pt-1 hover:bg-gray hover:cursor-pointer"
                                                onClick={() =>
                                                    this.setState({
                                                        enableDeleteUserConfirmationModal: true,
                                                    })
                                                }>
                                                <TrashIcon className="w-4 h-4" />
                                                Inactive
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="flex items-center py-1 gap-4">
                                    {this.state.inputDesg ? (
                                        <div className="flex items-center border rounded-md px-4">
                                            <input
                                                className="w-28 focus:ring-0 focus:border-none focus:outline-none"
                                                autoFocus={true}
                                                onBlur={(e) => {
                                                    if (e.target.value.trim() !== "") {
                                                        let val = e.target.value.trim();
                                                        this.setState(
                                                            {
                                                                inputDesg: false,
                                                            },
                                                            () => {
                                                                const data = new FormData();
                                                                data.append("designation", val);
                                                                data.append(
                                                                    "location",
                                                                    this.state.userDetails[
                                                                        "location"
                                                                    ]
                                                                );
                                                                if (this.state.isAdmin) {
                                                                    data.append(
                                                                        "user_id",
                                                                        this.state.userId
                                                                    );
                                                                }

                                                                this.props
                                                                    .postLocDesg(data)
                                                                    .then((res) => {
                                                                        if (
                                                                            res.value.status === 200
                                                                        ) {
                                                                            let userDet = {
                                                                                ...this.state
                                                                                    .userDetails,
                                                                            };
                                                                            userDet["designation"] =
                                                                                val;
                                                                            this.setState({
                                                                                userDetails:
                                                                                    userDet,
                                                                            });
                                                                        }
                                                                    });
                                                            }
                                                        );
                                                    } else {
                                                        this.setState({ inputDesg: false });
                                                    }
                                                }}
                                                onKeyUp={(e) => {
                                                    if (
                                                        e.key === "Enter" &&
                                                        e.target.value.trim() !== ""
                                                    ) {
                                                        if (e.target.value.trim() !== "") {
                                                            let val = e.target.value.trim();
                                                            this.setState(
                                                                {
                                                                    inputDesg: false,
                                                                },
                                                                () => {
                                                                    const data = new FormData();
                                                                    data.append("designation", val);
                                                                    data.append(
                                                                        "location",
                                                                        this.state.userDetails[
                                                                            "location"
                                                                        ]
                                                                    );
                                                                    if (this.state.isAdmin) {
                                                                        data.append(
                                                                            "user_id",
                                                                            this.state.userId
                                                                        );
                                                                    }

                                                                    this.props
                                                                        .postLocDesg(data)
                                                                        .then((res) => {
                                                                            if (
                                                                                res.value.status ===
                                                                                200
                                                                            ) {
                                                                                let userDet = {
                                                                                    ...this.state
                                                                                        .userDetails,
                                                                                };
                                                                                userDet[
                                                                                    "designation"
                                                                                ] = val;
                                                                                this.setState({
                                                                                    userDetails:
                                                                                        userDet,
                                                                                });
                                                                            }
                                                                        });
                                                                }
                                                            );
                                                        }
                                                    } else if (e.key === "Enter") {
                                                        this.setState({
                                                            inputDesg: false,
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    ) : this.state.userDetails["designation"] ? (
                                        <div className="flex items-center">
                                            <div className="min-w-[10px] min-h-[10px] rounded-full bg-[#D1D5DB] mr-2"></div>
                                            <div>{this.state.userDetails["designation"]}</div>
                                        </div>
                                    ) : (
                                        <button
                                            className="flex items-center text-[#1055EB]"
                                            onClick={() => this.setState({ inputDesg: true })}>
                                            <PlusIcon className="w-4 h-4 -ml-0.5 mr-1" />
                                            <div>Add Designation</div>
                                        </button>
                                    )}
                                    {this.state.inputLoc ? (
                                        <div className="flex items-center border rounded-md px-4">
                                            <input
                                                className="w-28 focus:ring-0 focus:border-none focus:outline-none"
                                                autoFocus={true}
                                                onBlur={(e) => {
                                                    if (e.target.value.trim() !== "") {
                                                        let val = e.target.value.trim();
                                                        this.setState(
                                                            {
                                                                inputLoc: false,
                                                            },
                                                            () => {
                                                                const data = new FormData();
                                                                data.append("location", val);
                                                                data.append(
                                                                    "designation",
                                                                    this.state.userDetails[
                                                                        "designation"
                                                                    ]
                                                                );
                                                                if (this.state.isAdmin) {
                                                                    data.append(
                                                                        "user_id",
                                                                        this.state.userId
                                                                    );
                                                                }
                                                                this.props
                                                                    .postLocDesg(data)
                                                                    .then((res) => {
                                                                        if (
                                                                            res.value.status === 200
                                                                        ) {
                                                                            let userDet = {
                                                                                ...this.state
                                                                                    .userDetails,
                                                                            };
                                                                            userDet["location"] =
                                                                                val;
                                                                            this.setState({
                                                                                userDetails:
                                                                                    userDet,
                                                                            });
                                                                        }
                                                                    });
                                                            }
                                                        );
                                                    } else {
                                                        this.setState({ inputLoc: false });
                                                    }
                                                }}
                                                onKeyUp={(e) => {
                                                    if (
                                                        e.key === "Enter" &&
                                                        e.target.value.trim() !== ""
                                                    ) {
                                                        if (e.target.value.trim() !== "") {
                                                            let val = e.target.value.trim();
                                                            this.setState(
                                                                {
                                                                    inputLoc: false,
                                                                },
                                                                () => {
                                                                    const data = new FormData();
                                                                    data.append("location", val);
                                                                    data.append(
                                                                        "designation",
                                                                        this.state.userDetails[
                                                                            "designation"
                                                                        ]
                                                                    );
                                                                    if (this.state.isAdmin) {
                                                                        data.append(
                                                                            "user_id",
                                                                            this.state.userId
                                                                        );
                                                                    }
                                                                    // if (this.state.userDetails["location"] ||this.state.userDetails["designation"]) {
                                                                    //    this.props.updateLocDesg(data).then((res)=>{
                                                                    //     if(res.value.status === 200)
                                                                    //     {
                                                                    //         let userDet = {...this.state.userDetails};
                                                                    //         userDet["location"] = val;
                                                                    //         this.setState({userDetails:userDet});
                                                                    //     }
                                                                    //    })
                                                                    // } else {
                                                                    this.props
                                                                        .postLocDesg(data)
                                                                        .then((res) => {
                                                                            if (
                                                                                res.value.status ===
                                                                                200
                                                                            ) {
                                                                                let userDet = {
                                                                                    ...this.state
                                                                                        .userDetails,
                                                                                };
                                                                                userDet[
                                                                                    "location"
                                                                                ] = val;
                                                                                this.setState({
                                                                                    userDetails:
                                                                                        userDet,
                                                                                });
                                                                            }
                                                                        });
                                                                    // }
                                                                }
                                                            );
                                                        }
                                                    } else if (e.key === "Enter") {
                                                        this.setState({
                                                            inputLoc: false,
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    ) : this.state.userDetails["location"] ? (
                                        <div className="flex items-center mr-4">
                                            <div className="min-w-[10px] min-h-[10px] rounded-full bg-[#D1D5DB] mr-2"></div>
                                            <div>{this.state.userDetails["location"]}</div>
                                        </div>
                                    ) : (
                                        <button
                                            className="flex items-center text-[#1055EB]"
                                            onClick={() => this.setState({ inputLoc: true })}>
                                            <PlusIcon className="w-4 h-4 -ml-0.5 mr-1" />
                                            <div>Add location</div>
                                        </button>
                                    )}
                                </div>
                                <div className="flex items-center text-xs lg:text-tiny gap-4 py-1 text-[#6B7280]">
                                    {this.state.userDetails["email"] && (
                                        <div className="flex items-center">
                                            <BsEnvelopeFill className="w-3 h-3 md:w-4 md:h-4 mr-1.5" />
                                            <div>{this.state.userDetails["email"]}</div>
                                        </div>
                                    )}
                                    {this.state.phoneNumber && (
                                        <div className="flex items-center">
                                            <DeviceMobileIcon className="w-3 h-3 md:w-4 md:h-4 mr-0.5" />
                                            <div>{"+" + this.state.phoneNumber}</div>
                                        </div>
                                    )}
                                    <div className="flex uppercase items-center">
                                        <CashIcon className="w-3 h-3 md:w-4 md:h-4 mr-1.5" />
                                        <div>{this.state.userDetails["currency"]}</div>
                                    </div>

                                    <div className="flex items-center">
                                        <div className="w-3 h-3 md:w-4 md:h-4 mr-1.5">
                                            <img src={timeSuccessIcon} alt="time icon" />
                                        </div>
                                        <p>
                                            {Number(
                                                this.state.userDetails["over_ride_hours"]
                                                    ? this.state.userDetails["over_ride_hours"]
                                                    : 0
                                            ) +
                                                Number(
                                                    this.state.workingHours
                                                        ? (this.state.workingHours / 60) * 24
                                                        : 0
                                                )}
                                        </p>
                                    </div>
                                    {/* <div className="flex items-center">
                                        <div className="w-3 h-3 md:w-4 md:h-4 mr-1.5">
                                            <img src={userIcon} alt="time icon"/>
                                        </div>
                                        <p>Reprting Manager Name</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="h-28 flex items-center pl-10">
                            <BeatLoader size={20} color="#684994" />
                        </div>
                    )}
                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            Select a tab
                        </label>
                        <select
                            id="tabs"
                            name="tabs"
                            onChange={(e) => this.HandleMobileSelectTab(e)}
                            className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                            defaultValue={this.state.SelectedTab}>
                            <option key="skills" value="skills">
                                Skills
                            </option>
                            <option key="projects" value="projects">
                                Projects
                            </option>
                            <option key="timesheet" value="timesheet">
                                Time Sheet
                            </option>
                            <option key="payinfo" value="payinfo">
                                Pay Info
                            </option>
                            {this.state.isAdmin && (
                                <option key="payrollhistory" value="payrollhistory">
                                    Payroll History
                                </option>
                            )}
                        </select>
                    </div>
                    <div className="hidden sm:block pt-4 pl-2 xl:pt-6">
                        <div className="-mb-px flex space-x-8" aria-label="Tabs">
                            <div
                                key="skills"
                                onClick={() => this.HandleSelectTab("skills")}
                                className={classNames(
                                    this.state.SelectedTab === "skills"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "skills" ? "page" : undefined
                                }>
                                Skills
                            </div>

                            <div
                                key="projects"
                                onClick={() => this.HandleSelectTab("projects")}
                                className={classNames(
                                    this.state.SelectedTab === "projects"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "projects" ? "page" : undefined
                                }>
                                Projects
                            </div>
                            <div
                                key="timesheet"
                                onClick={() => this.HandleSelectTab("timesheet")}
                                className={classNames(
                                    this.state.SelectedTab === "timesheet"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "timesheet" ? "page" : undefined
                                }>
                                Time Sheet
                            </div>
                            <div
                                key="payinfo"
                                onClick={() => this.HandleSelectTab("payinfo")}
                                className={classNames(
                                    this.state.SelectedTab === "payinfo"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "payinfo" ? "page" : undefined
                                }>
                                Pay Info
                            </div>
                            {this.state.isAdmin && (
                                <div
                                    key="payrollhistory"
                                    onClick={() => this.HandleSelectTab("payrollhistory")}
                                    className={classNames(
                                        this.state.SelectedTab === "payrollhistory"
                                            ? "border-primary text-primary"
                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                        "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-md cursor-pointer"
                                    )}
                                    aria-current={
                                        this.state.SelectedTab === "payrollhistory"
                                            ? "page"
                                            : undefined
                                    }>
                                    Payroll History
                                </div>
                            )}
                        </div>
                    </div>
                    {this.state.SelectedTab === "skills" && (
                        <div className="pt-4 pl-2 xl:pt-6">
                            <div className="py-4 px-4 rounded-lg border border-gray-300">
                                <div className="flex mb-4 items-center justify-between">
                                    <div className="flex items-end">
                                        <div className="text-lg">
                                            {this.state.isAdmin ? "Skills" : "Add Skills"}
                                        </div>
                                        <div className="ml-1.5 text-gray-500">(max 15)</div>
                                    </div>
                                    {!this.state.isAdmin && (
                                        <button
                                            className="flex text-tiny items-center px-2 py-1 rounded-md border border-gray-300 bg-white text-gray-500"
                                            onClick={() =>
                                                this.setState({
                                                    enableEdit: !this.state.enableEdit,
                                                })
                                            }>
                                            <PencilIcon className="h-4 w-4 mr-1" />
                                            Edit
                                        </button>
                                    )}
                                </div>
                                <div className="flex flex-wrap gap-4">
                                    {this.state.skills.length > 0
                                        ? this.state.skills.map((skill, index) => (
                                              <div
                                                  key={skill + index}
                                                  className="py-1.5 capitalize px-4 border rounded-md border-gray-500">
                                                  {skill}
                                              </div>
                                          ))
                                        : this.state.isAdmin && (
                                              <div className="flex-1 text-center py-6 italic text-[#6B7280]">
                                                  No skills are added
                                              </div>
                                          )}

                                    {this.state.inputSkill && (
                                        <div className="flex items-center border rounded-md px-4">
                                            <input
                                                className="w-28 focus:ring-0 focus:border-none focus:outline-none"
                                                autoFocus={true}
                                                onBlur={(e) => {
                                                    const skilsList = [...this.state.skills];
                                                    if (
                                                        !skilsList
                                                            .map((val) => val.toLowerCase())
                                                            .includes(
                                                                e.target.value.toLowerCase()
                                                            ) &&
                                                        e.target.value.trim() !== ""
                                                    ) {
                                                        skilsList.push(e.target.value);
                                                    }
                                                    this.setState({
                                                        inputSkill: false,
                                                        skills: skilsList,
                                                    });
                                                }}
                                                onKeyUp={(e) => {
                                                    if (
                                                        e.key === "Enter" &&
                                                        e.target.value.trim() !== ""
                                                    ) {
                                                        const skilsList = [...this.state.skills];
                                                        if (
                                                            !skilsList
                                                                .map((val) => val.toLowerCase())
                                                                .includes(
                                                                    e.target.value.toLowerCase()
                                                                )
                                                        ) {
                                                            skilsList.push(e.target.value);
                                                        }
                                                        this.setState({
                                                            inputSkill: false,
                                                            skills: skilsList,
                                                        });
                                                    } else if (e.key === "Enter") {
                                                        this.setState({
                                                            inputSkill: false,
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                    {!this.state.isAdmin &&
                                        this.state.enableEdit &&
                                        this.state.skills.length < 15 &&
                                        !this.state.inputSkill && (
                                            <button
                                                className="self-center w-8 h-8 rounded-full p-1 border border-dashed text-textprimary"
                                                onClick={() => this.setState({ inputSkill: true })}>
                                                <PlusIcon />
                                            </button>
                                        )}
                                </div>
                                {this.state.enableEdit && (
                                    <div className="flex justify-end">
                                        <button
                                            className="px-4 rounded-md py-2 text-white bg-primary"
                                            onClick={() => {
                                                const skilsList = [...this.state.skills];
                                                const data = new FormData();
                                                for (let skill of skilsList) {
                                                    data.append("skills", skill);
                                                }
                                                if (this.state.skills.length === 0) {
                                                    this.props.postSkills("", data).then((res) => {
                                                        if (res.value.status === 200) {
                                                            this.setState({
                                                                skills: skilsList,
                                                            });
                                                        }
                                                        this.props
                                                            .getSkills("?user=" + this.state.userId)
                                                            .then((res) => {
                                                                if (
                                                                    res.value.data.data !==
                                                                    "no data is found"
                                                                ) {
                                                                    this.setState({ skills: [] });
                                                                }
                                                            });
                                                    });
                                                } else {
                                                    this.props
                                                        .updateSkills("", data)
                                                        .then((res) => {
                                                            if (res.value.status === 200) {
                                                                this.setState({
                                                                    skills: skilsList,
                                                                });
                                                            }
                                                            this.props
                                                                .getSkills(
                                                                    "?user=" + this.state.userId
                                                                )
                                                                .then((res) => {
                                                                    if (
                                                                        res.value.data.data !==
                                                                        "no data is found"
                                                                    ) {
                                                                        this.setState({
                                                                            skills: res.value.data
                                                                                .data,
                                                                        });
                                                                    } else {
                                                                        this.setState({
                                                                            skills: [],
                                                                        });
                                                                    }
                                                                });
                                                        });
                                                }
                                            }}>
                                            Save Changes
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {this.state.SelectedTab === "projects" && (
                        <div className="pt-4 pl-2 xl:pt-6">
                            <div className="mb-3 flex justify-between items-center">
                                <div className="text-xl">
                                    Ongoing Projects (
                                    {projectsData &&
                                    projectsData["ongoing"] &&
                                    projectsData["ongoing"].length > 0
                                        ? projectsData["ongoing"].length
                                        : 0}
                                    )
                                </div>
                                {this.state.isAdmin && (
                                    <button
                                        className="flex items-center text-[#1055EB]"
                                        onClick={() => this.setState({ assignProject: true })}>
                                        <PlusIcon className="w-4 h-4 -ml-0.5 mr-1" />
                                        <div>Assign New Project</div>
                                    </button>
                                )}
                            </div>
                            <div className="border border-collapse rounded-lg border-gray-300 mb-5">
                                <div className="text-gray-500 grid grid-cols-2 md:grid-cols-3 justify-between px-4 py-3 rounded-t-lg bg-gray">
                                    <div className="col-span-2">Projects</div>
                                    {this.state.isAdmin && <div>Action</div>}
                                </div>
                               {/*
                                <div className="w-full max-h-64 overflow-auto">
                                    {projectsData &&
                                    projectsData["ongoing"] &&
                                    projectsData["ongoing"].length > 0 ? (
                                        projectsData["ongoing"].map((val, idx) => (
                                            <div
                                                key={val.status + idx}
                                                className="px-4 py-3 grid grid-cols-2 md:grid-cols-3 border-t border-gray-300 w-full">
                                                <div className="col-span-2">{val.name}</div>
                                                {this.state.isAdmin && (
                                                    <button
                                                        className="flex text-tiny xl:text-base items-center gap-2 text-danger"
                                                        onClick={() => {
                                                            showDeleteProjectModal(val.name);
                                                        }}>
                                                        <TrashIcon className="w-4 h-4" />
                                                        <div>Remove from this project</div>
                                                    </button>
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        <div className="px-4 py-3 border-t border-gray-300 w-full text-center">
                                            {loadingProject ? (
                                                <BeatLoader color={"#684994"} />
                                            ) : (
                                                "None"
                                            )}
                                        </div>
                                    )}
                                </div>
                                */} 
                                <div className="w-full max-h-64 overflow-auto">
                  {
                    ongoingProjects ?
                    ongoingProjects.map((val,idx)=>(
                      <div
                        key={idx}
                        className="px-4 py-3 grid grid-cols-2 md:grid-cols-3 border-t border-gray-300 w-full"
                      >
                        <div className="col-span-2">{val}</div>
                        {this.state.isAdmin && (
                          <button
                            className="flex text-tiny xl:text-base items-center gap-2 text-danger"
                            onClick={() => {
                              showDeleteProjectModal(val);
                            }}
                          >
                            <TrashIcon className="w-4 h-4" />
                            <div>Remove from this project</div>
                          </button>
                        )}
                      </div>
                    ))
                    :

                    projectsData &&
                  projectsData["ongoing"] &&
                  projectsData["ongoing"].length > 0 ? (
                    projectsData["ongoing"].map((val, idx) => (
                      <div
                        key={val.status + idx}
                        className="px-4 py-3 grid grid-cols-2 md:grid-cols-3 border-t border-gray-300 w-full"
                      >
                        <div className="col-span-2">{val.name}</div>
                        {this.state.isAdmin && (
                          <button
                            className="flex text-tiny xl:text-base items-center gap-2 text-danger"
                            onClick={() => {
                              showDeleteProjectModal(val.name);
                            }}
                          >
                            <TrashIcon className="w-4 h-4" />
                            <div>Remove from this project</div>
                          </button>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="px-4 py-3 border-t border-gray-300 w-full text-center">
                      {loadingProject ? (
                        <BeatLoader color={"#684994"} />
                      ) : (
                        "None"
                      )}
                    </div>
                  )}
                </div>
                               
                            </div>
                            <div className="text-xl mb-3">
                                Completed Projects (
                                {projectsData &&
                                projectsData["completed"] &&
                                projectsData["completed"].length > 0
                                    ? projectsData["completed"].length
                                    : 0}
                                )
                            </div>
                            <div className="border border-collapse rounded-lg border-gray-300 w-full ">
                                <div className="text-gray-500 px-4 py-3 rounded-t-lg bg-gray">
                                    Projects
                                </div>
                                <div className="w-full max-h-64 overflow-auto">
                                    {projectsData &&
                                    projectsData["completed"] &&
                                    projectsData["completed"].length > 0 ? (
                                        projectsData["completed"].map((val, idx) => (
                                            <div
                                                key={val.status + idx}
                                                className="px-4 py-3 border-t border-gray-300 w-full">
                                                {val.name}
                                            </div>
                                        ))
                                    ) : (
                                        <div className="px-4 py-3 border-t border-gray-300 w-full text-center">
                                            {loadingProject ? (
                                                <BeatLoader color={"#684994"} />
                                            ) : (
                                                "None"
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {this.state.SelectedTab === "timesheet" && (
                        <div className="pt-4 pl-2 xl:pt-6 w-full">
                            <div className="flex items-center justify-between py-2 px-4 w-full">
                                <div className="text-xl">Time Sheet</div>
                                <div className="relative">
                                    <ChevronDownIcon className="absolute w-4 h-4 top-3 right-3" />
                                    <DatePicker
                                        className="rounded-md w-56 bg-transparent hover:cursor-pointer"
                                        dateFormat="MMMM yyyy"
                                        showMonthYearPicker
                                        selected={new Date(this.state.selectedDate)}
                                        onChange={(date) =>
                                            this.setState({ selectedDate: date }, () =>
                                                this.props.getSalaryDaysData(
                                                    this.state.userId +
                                                        "&start_date=" +
                                                        moment(date)
                                                            .startOf("month")
                                                            .format("YYYY-MM-DD") +
                                                        "&end_date=" +
                                                        moment(date)
                                                            .endOf("month")
                                                            .format("YYYY-MM-DD")
                                                )
                                            )
                                        }
                                    />
                                    <div className="absolute -top-2.5 left-2 text-xs bg-white py-0.5 px-1">
                                        Select Month
                                    </div>
                                </div>
                            </div>
                            <div className="overflow-auto max-h-[43vh]">
                                <table className="min-w-max w-full border-separate rounded-xl">
                                    <thead className="bg-gray-50 sticky top-0 min-w-full">
                                        <tr className={`grid grid-cols-4 min-w-full`}>
                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 px-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                Date
                                            </th>
                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell`}>
                                                Project
                                            </th>
                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell`}>
                                                Task
                                            </th>
                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell`}>
                                                Time Spend
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {timeSheetData &&
                                        timeSheetData.data &&
                                        timeSheetData.data.length > 0 ? (
                                            timeSheetData.data.map((reportRow, idx) => {
                                                if (
                                                    idx > 0 &&
                                                    timeSheetData.data[idx - 1].date ===
                                                        reportRow.date
                                                ) {
                                                    if (
                                                        timeSheetData.data[idx - 1].project ===
                                                        reportRow.project
                                                    ) {
                                                        return (
                                                            <tr
                                                                className={`grid grid-cols-4 min-w-full ${
                                                                    (idx ===
                                                                        timeSheetData.data.length -
                                                                            1 ||
                                                                        (idx <
                                                                            timeSheetData.data
                                                                                .length -
                                                                                1 &&
                                                                            timeSheetData.data[
                                                                                idx + 1
                                                                            ].date !==
                                                                                reportRow.date)) &&
                                                                    "border-b border-gray-300"
                                                                }`}
                                                                key={reportRow.date + idx}>
                                                                <td
                                                                    className={`px-3 py-2 text-base sm:table-cell`}></td>
                                                                <td
                                                                    className={`px-3 py-2 text-base sm:table-cell`}>
                                                                    {reportRow.project}
                                                                </td>
                                                                <td
                                                                    className={`px-3 py-2 text-base sm:table-cell`}>
                                                                    {reportRow.task}
                                                                </td>
                                                                <td
                                                                    className={`px-3 py-2 text-base sm:table-cell`}>
                                                                    {Math.floor(
                                                                        parseInt(reportRow.time) /
                                                                            60
                                                                    ).toLocaleString("en-US", {
                                                                        minimumIntegerDigits: 2,
                                                                        useGrouping: false,
                                                                    })}
                                                                    hr:
                                                                    {(
                                                                        parseInt(reportRow.time) %
                                                                        60
                                                                    ).toLocaleString("en-US", {
                                                                        minimumIntegerDigits: 2,
                                                                        useGrouping: false,
                                                                    })}
                                                                    mins
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                    return (
                                                        <tr
                                                            className={`grid grid-cols-4 min-w-full ${
                                                                (idx ===
                                                                    timeSheetData.data.length - 1 ||
                                                                    (idx <
                                                                        timeSheetData.data.length -
                                                                            1 &&
                                                                        timeSheetData.data[idx + 1]
                                                                            .date !==
                                                                            reportRow.date)) &&
                                                                "border-b border-gray-300"
                                                            }`}
                                                            key={reportRow.date + idx}>
                                                            <td
                                                                className={`px-3 py-2 text-base sm:table-cell`}></td>
                                                            <td
                                                                className={`px-3 py-2 text-base sm:table-cell`}></td>
                                                            <td
                                                                className={`px-3 py-2 text-base sm:table-cell`}>
                                                                {reportRow.task}
                                                            </td>
                                                            <td
                                                                className={`px-3 py-2 text-base sm:table-cell`}>
                                                                {Math.floor(
                                                                    parseInt(reportRow.time) / 60
                                                                ).toLocaleString("en-US", {
                                                                    minimumIntegerDigits: 2,
                                                                    useGrouping: false,
                                                                })}
                                                                hr:
                                                                {(
                                                                    parseInt(reportRow.time) % 60
                                                                ).toLocaleString("en-US", {
                                                                    minimumIntegerDigits: 2,
                                                                    useGrouping: false,
                                                                })}
                                                                mins
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                                return (
                                                    <tr
                                                        className={`grid grid-cols-4 min-w-full  ${
                                                            (idx ===
                                                                timeSheetData.data.length - 1 ||
                                                                (idx <
                                                                    timeSheetData.data.length - 1 &&
                                                                    timeSheetData.data[idx + 1]
                                                                        .date !==
                                                                        reportRow.date)) &&
                                                            "border-b border-gray-300"
                                                        }`}
                                                        key={reportRow.date + idx}>
                                                        <td
                                                            className={`sm:table-cell text-primary hover:cursor-pointer`}>
                                                            <Link
                                                                to={
                                                                    this.state.isAdmin
                                                                        ? "/timetracker/screenshots/?empname=" +
                                                                          this.state.userId +
                                                                          "&date=" +
                                                                          reportRow.date
                                                                        : "/emp/timetracker/" +
                                                                          moment(
                                                                              new Date(
                                                                                  reportRow.date
                                                                              )
                                                                          ).format("YYYY-MM-DD")
                                                                }
                                                                className="px-3 py-2 text-base ">
                                                                {reportRow.date}
                                                            </Link>
                                                        </td>
                                                        <td
                                                            className={`px-3 py-2 text-base sm:table-cell`}>
                                                            {reportRow.project}
                                                        </td>
                                                        <td
                                                            className={`px-3 py-2 text-base sm:table-cell`}>
                                                            {reportRow.task}
                                                        </td>
                                                        <td
                                                            className={`px-3 py-2 text-base sm:table-cell`}>
                                                            {Math.floor(
                                                                parseInt(reportRow.time) / 60
                                                            ).toLocaleString("en-US", {
                                                                minimumIntegerDigits: 2,
                                                                useGrouping: false,
                                                            })}
                                                            hr:
                                                            {(
                                                                parseInt(reportRow.time) % 60
                                                            ).toLocaleString("en-US", {
                                                                minimumIntegerDigits: 2,
                                                                useGrouping: false,
                                                            })}
                                                            mins
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : timeSheetData &&
                                          !timeSheetData.data &&
                                          timeSheetData.status ? (
                                            <tr className="min-w-full flex justify-center items-center h-28">
                                                <td className="text-center flex-1" colSpan={6}>
                                                    No Data of user for the month found
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr className="min-w-full flex justify-center">
                                                <td className="text-center flex-1" colSpan={6}>
                                                    <BeatLoader
                                                        size={20}
                                                        color="rgba(104, 77, 148,1)"
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                    {this.state.SelectedTab === "payinfo" && this.state.singlePayInfo ? (
                        <div className=" pt-4 pl-2 xl:pt-6">
                            <div className="flex gap-2 text-xl items-center">
                                <ChevronLeftRounded
                                    fontSize="medium"
                                    className="hover:cursor-pointer -mb-0.5"
                                    onClick={() => this.setState({ singlePayInfo: false })}
                                />
                                <div>
                                    {months[this.state.payInfoMonth.month - 1]}{" "}
                                    {this.state.payInfoMonth.year}
                                </div>
                            </div>
                            <div className="w-full grid grid-cols-3 gap-4">
                                <div className="bg-gray-100 p-4">
                                    <div className="text-gray-400 text-tiny mb-1">
                                        Total Time Spend
                                    </div>
                                    <div className="text-primary text-xl">
                                        {this.state.payInfoMonth.time_spent
                                            ? (this.state.payInfoMonth.time_spent / 60 >= 1
                                                  ? parseInt(
                                                        this.state.payInfoMonth.time_spent / 60
                                                    ) + "hr "
                                                  : "") +
                                              (this.state.payInfoMonth.time_spent % 60) +
                                              "mins"
                                            : "0mins"}
                                    </div>
                                </div>
                                <div className="bg-gray-100 p-4">
                                    <div className="text-gray-400 text-tiny mb-1">
                                        Pay Rate
                                        {this.state.payInfoMonth.type === "daily"
                                            ? "/day"
                                            : this.state.payInfoMonth.type === "yearly"
                                            ? "/yr"
                                            : "/hr"}
                                    </div>
                                    <div className="text-primary text-xl mb-1">
                                        {this.state.payInfoMonth["currency"] === "inr" ? (
                                            <>&#8377;</>
                                        ) : (
                                            "$"
                                        )}
                                        {this.state.payInfoMonth.pay_rate}
                                    </div>
                                </div>
                                <div className="bg-gray-100 p-4">
                                    <div className="text-gray-400 text-tiny">
                                        Total Payable Amount
                                    </div>
                                    <div className="text-primary text-xl">
                                        {this.state.payInfoMonth["currency"] === "inr" ? (
                                            <>&#8377;</>
                                        ) : (
                                            "$"
                                        )}
                                        {this.state.payInfoMonth.cost}
                                    </div>
                                </div>
                            </div>
                            <div className="py-4">
                                <table className="min-w-max w-full border border-gray-300 border-separate rounded-xl">
                                    <thead className="bg-gray-50 min-w-full rounded-xl">
                                        <tr className={`grid grid-cols-6 min-w-full`}>
                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 px-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                Date
                                            </th>
                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell`}>
                                                Projects
                                            </th>
                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell`}>
                                                Tasks
                                            </th>
                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell`}>
                                                Time Spend
                                            </th>
                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell`}>
                                                Bill Rate/hr
                                            </th>
                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell`}>
                                                Total Bill
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {salaryData &&
                                        salaryData.data &&
                                        salaryData.data.length > 0 ? (
                                            salaryData.data.map((reportRow, idx) => {
                                                if (
                                                    idx > 0 &&
                                                    salaryData.data[idx - 1].date === reportRow.date
                                                ) {
                                                    if (
                                                        salaryData.data[idx - 1].project ===
                                                        reportRow.project
                                                    ) {
                                                        return (
                                                            <tr
                                                                className={`grid grid-cols-6 min-w-full ${
                                                                    (idx ===
                                                                        salaryData.data.length -
                                                                            1 ||
                                                                        (idx <
                                                                            salaryData.data.length -
                                                                                1 &&
                                                                            salaryData.data[idx + 1]
                                                                                .date !==
                                                                                reportRow.date)) &&
                                                                    "border-b border-gray-300"
                                                                }`}
                                                                key={reportRow.date + idx}>
                                                                <td
                                                                    className={`px-3 py-2 text-base sm:table-cell`}></td>
                                                                <td
                                                                    className={`px-3 py-2 text-base sm:table-cell`}>
                                                                    {reportRow.project}
                                                                </td>
                                                                <td
                                                                    className={`px-3 py-2 text-base sm:table-cell`}>
                                                                    {reportRow.task}
                                                                </td>
                                                                <td
                                                                    className={`px-3 py-2 text-base sm:table-cell`}>
                                                                    {Math.floor(
                                                                        parseInt(reportRow.time) /
                                                                            60
                                                                    ).toLocaleString("en-US", {
                                                                        minimumIntegerDigits: 2,
                                                                        useGrouping: false,
                                                                    })}
                                                                    hr:
                                                                    {(
                                                                        parseInt(reportRow.time) %
                                                                        60
                                                                    ).toLocaleString("en-US", {
                                                                        minimumIntegerDigits: 2,
                                                                        useGrouping: false,
                                                                    })}
                                                                    mins
                                                                </td>
                                                                <td
                                                                    className={`px-3 py-2 text-base sm:table-cell`}>
                                                                    {reportRow["currency"] ===
                                                                    "inr" ? (
                                                                        <>&#8377;</>
                                                                    ) : (
                                                                        "$"
                                                                    )}
                                                                    {reportRow.rate}
                                                                </td>

                                                                <td
                                                                    className={`px-3 py-2 text-base lg:table-cell`}>
                                                                    {reportRow["currency"] ===
                                                                    "inr" ? (
                                                                        <>&#8377;</>
                                                                    ) : (
                                                                        "$"
                                                                    )}
                                                                    {reportRow.bill}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                    return (
                                                        <tr
                                                            className={`grid grid-cols-6 min-w-full ${
                                                                (idx ===
                                                                    salaryData.data.length - 1 ||
                                                                    (idx <
                                                                        salaryData.data.length -
                                                                            1 &&
                                                                        salaryData.data[idx + 1]
                                                                            .date !==
                                                                            reportRow.date)) &&
                                                                "border-b border-gray-300"
                                                            }`}
                                                            key={reportRow.date + idx}>
                                                            <td
                                                                className={`px-3 py-2 text-base sm:table-cell`}></td>
                                                            <td
                                                                className={`px-3 py-2 text-base sm:table-cell`}></td>
                                                            <td
                                                                className={`px-3 py-2 text-base sm:table-cell`}>
                                                                {reportRow.task}
                                                            </td>
                                                            <td
                                                                className={`px-3 py-2 text-base sm:table-cell`}>
                                                                {Math.floor(
                                                                    parseInt(reportRow.time) / 60
                                                                ).toLocaleString("en-US", {
                                                                    minimumIntegerDigits: 2,
                                                                    useGrouping: false,
                                                                })}
                                                                hr:
                                                                {(
                                                                    parseInt(reportRow.time) % 60
                                                                ).toLocaleString("en-US", {
                                                                    minimumIntegerDigits: 2,
                                                                    useGrouping: false,
                                                                })}
                                                                mins
                                                            </td>
                                                            <td
                                                                className={`px-3 py-2 text-base sm:table-cell`}>
                                                                {reportRow["currency"] === "inr" ? (
                                                                    <>&#8377;</>
                                                                ) : (
                                                                    "$"
                                                                )}
                                                                {reportRow.rate}
                                                            </td>

                                                            <td
                                                                className={`px-3 py-2 text-base lg:table-cell`}>
                                                                {reportRow["currency"] === "inr" ? (
                                                                    <>&#8377;</>
                                                                ) : (
                                                                    "$"
                                                                )}
                                                                {reportRow.bill}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                                return (
                                                    <tr
                                                        className={`grid grid-cols-6 min-w-full  ${
                                                            (idx === salaryData.data.length - 1 ||
                                                                (idx < salaryData.data.length - 1 &&
                                                                    salaryData.data[idx + 1]
                                                                        .date !==
                                                                        reportRow.date)) &&
                                                            "border-b border-gray-300"
                                                        }`}
                                                        key={reportRow.date + idx}>
                                                        <td
                                                            className={`px-3 py-2 text-base sm:table-cell`}>
                                                            {reportRow.date}
                                                        </td>
                                                        <td
                                                            className={`px-3 py-2 text-base sm:table-cell`}>
                                                            {reportRow.project}
                                                        </td>
                                                        <td
                                                            className={`px-3 py-2 text-base sm:table-cell`}>
                                                            {reportRow.task}
                                                        </td>
                                                        <td
                                                            className={`px-3 py-2 text-base sm:table-cell`}>
                                                            {Math.floor(
                                                                parseInt(reportRow.time) / 60
                                                            ).toLocaleString("en-US", {
                                                                minimumIntegerDigits: 2,
                                                                useGrouping: false,
                                                            })}
                                                            hr:
                                                            {(
                                                                parseInt(reportRow.time) % 60
                                                            ).toLocaleString("en-US", {
                                                                minimumIntegerDigits: 2,
                                                                useGrouping: false,
                                                            })}
                                                            mins
                                                        </td>
                                                        <td
                                                            className={`px-3 py-2 text-base sm:table-cell`}>
                                                            {reportRow["currency"] === "inr" ? (
                                                                <>&#8377;</>
                                                            ) : (
                                                                "$"
                                                            )}
                                                            {reportRow.rate}
                                                        </td>

                                                        <td
                                                            className={`px-3 py-2 text-base lg:table-cell`}>
                                                            {reportRow["currency"] === "inr" ? (
                                                                <>&#8377;</>
                                                            ) : (
                                                                "$"
                                                            )}
                                                            {reportRow.bill}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : salaryData &&
                                          salaryData.data &&
                                          salaryData.data.length === 0 ? (
                                            <tr className="min-w-full flex justify-center items-center h-28">
                                                <td className="text-center flex-1" colSpan={6}>
                                                    No Data of user for the month found
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr className="min-w-full flex justify-center">
                                                <td className="text-center flex-1" colSpan={6}>
                                                    <BeatLoader
                                                        size={20}
                                                        color="rgba(104, 77, 148,1)"
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : this.state.SelectedTab === "payinfo" ? (
                        <div className="pt-4 pl-2 xl:pt-6">
                            <div className="flex justify-end">
                                <div className="relative w-1/3 min-w-[20rem]">
                                    <div className="absolute bg-white px-1 py-0.5 text-xs left-4 -top-1">
                                        Financial Year
                                    </div>
                                    <SelectField
                                        onSelectChange={(e) => {
                                            this.setState(
                                                {
                                                    selectedFinancialYear: parseInt(e.target.value),
                                                },
                                                () =>
                                                    this.props.getSalaryYearData(
                                                        this.state.userId +
                                                            "&start_year=" +
                                                            e.target.value +
                                                            "&end_year=" +
                                                            (parseInt(e.target.value) + 1)
                                                    )
                                            );
                                        }}
                                        FieldName=""
                                        name={"financialYear"}
                                        arrayOfData={this.state.yearOptions}
                                        id={"employee"}
                                        hideDefault={true}
                                        value={this.state.selectedFinancialYear}
                                    />
                                </div>
                            </div>
                            <div className="my-2 pb-4">
                                <ReactHighcharts config={chartData} />
                            </div>

                            <div className="px-4 py-4 2xl:px-6">
                                <table className="w-full text-left border-separate rounded-xl">
                                    <thead>
                                        <tr>
                                            <th className="border-b border-gray-300 bg-gray-200 bg-opacity-50 p-3">
                                                Pay Date
                                            </th>
                                            <th className="border-b border-gray-300 bg-gray-200 bg-opacity-50 p-3">
                                                Total Time Spend
                                            </th>
                                            <th className="border-b border-gray-300 bg-gray-200 bg-opacity-50 p-3">
                                                Pay Rate
                                            </th>
                                            <th className="border-b border-gray-300 bg-gray-200 bg-opacity-50 p-3">
                                                Total Pay
                                            </th>
                                            <th className="border-b border-gray-300 bg-gray-200 bg-opacity-50 p-3">
                                                YTD Net
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="w-full">
                                        {yearSalaryData && yearSalaryData.length > 0 ? (
                                            yearSalaryData.reverse().map((val) => (
                                                <tr>
                                                    <td
                                                        className="border-b border-gray-300 px-3 py-2 text-primary hover:cursor-pointer"
                                                        onClick={() =>
                                                            this.setState(
                                                                {
                                                                    showMonthActivity: true,
                                                                    startDate: new Date(
                                                                        parseInt(val.year),
                                                                        parseInt(val.month) - 1,
                                                                        1
                                                                    ),
                                                                    singlePayInfo: true,
                                                                    payInfoMonth: val,
                                                                },
                                                                () =>
                                                                    this.props.getSalaryDaysData(
                                                                        this.state.userId +
                                                                            "&start_date=" +
                                                                            moment(
                                                                                new Date(
                                                                                    parseInt(
                                                                                        val.year
                                                                                    ),
                                                                                    parseInt(
                                                                                        val.month
                                                                                    ) - 1,
                                                                                    1
                                                                                )
                                                                            )
                                                                                .startOf("month")
                                                                                .format(
                                                                                    "YYYY-MM-DD"
                                                                                ) +
                                                                            "&end_date=" +
                                                                            moment(
                                                                                new Date(
                                                                                    parseInt(
                                                                                        val.year
                                                                                    ),
                                                                                    parseInt(
                                                                                        val.month
                                                                                    ) - 1,
                                                                                    1
                                                                                )
                                                                            )
                                                                                .endOf("month")
                                                                                .format(
                                                                                    "YYYY-MM-DD"
                                                                                )
                                                                    )
                                                            )
                                                        }>
                                                        {new Date(
                                                            parseInt(val.year),
                                                            parseInt(val.month) - 1,
                                                            1
                                                        ).toLocaleString("en-US", {
                                                            month: "long",
                                                            day: "numeric",
                                                            year: "numeric",
                                                        })}
                                                    </td>
                                                    <td className="border-b border-gray-300 px-3 py-2">
                                                        {val.time_spent
                                                            ? (val.time_spent / 60 >= 1
                                                                  ? parseInt(val.time_spent / 60) +
                                                                    "hr "
                                                                  : "") +
                                                              (val.time_spent % 60) +
                                                              "mins"
                                                            : "0mins"}
                                                    </td>
                                                    <td className="border-b border-gray-300 px-3 py-2">
                                                        {val.pay_rate}
                                                        {val.type === "daily"
                                                            ? "/day"
                                                            : val.type === "yearly"
                                                            ? "/yr"
                                                            : "/hr"}
                                                    </td>
                                                    <td className="border-b border-gray-300 px-3 py-2">
                                                        {val["currency"] === "inr" ? (
                                                            <>&#8377;</>
                                                        ) : (
                                                            "$"
                                                        )}
                                                        {val.cost}
                                                    </td>

                                                    <td className="border-b border-gray-300 px-3 py-2">
                                                        {val["currency"] === "inr" ? (
                                                            <>&#8377;</>
                                                        ) : (
                                                            "$"
                                                        )}
                                                        {val.ynet ?? val.cost}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td className="text-center py-4" colSpan={3}>
                                                    No Data Found for the Year{" "}
                                                    {this.state.selectedFinancialYear +
                                                        " - " +
                                                        (this.state.selectedFinancialYear + 1)}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        this.state.SelectedTab === "payrollhistory" && (
                            <div className="mt-2 py-4 px-5">
                                <div className="text-xl w-full">Payroll History</div>
                                <table className="mt-2 rounded-sm w-full border-separate xl-m:text-tiny">
                                    <thead className="w-full text-gray-500 bg-gray">
                                        <tr>
                                            <th className="py-2 px-4 border-b text-left border-[#D1D5DB] font-normal">
                                                Effective Date
                                            </th>
                                            <th className="py-2 px-4 border-b text-left border-[#D1D5DB] font-normal">
                                                Pay rate
                                            </th>
                                            <th className="py-2 px-4 border-b text-left border-[#D1D5DB] font-normal">
                                                Comment
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {payrollHistory &&
                                            payrollHistory.map((row) => (
                                                <tr>
                                                    <td className="py-2 px-4 border-b border-[#D1D5DB]">
                                                        {new Date(
                                                            parseInt(
                                                                row.effective_date.split("-")[0]
                                                            ),
                                                            parseInt(
                                                                row.effective_date.split("-")[1]
                                                            ) - 1,
                                                            parseInt(
                                                                row.effective_date.split("-")[2]
                                                            )
                                                        ).toLocaleString("en-US", {
                                                            month: "long",
                                                            day: "numeric",
                                                            year: "numeric",
                                                        })}
                                                    </td>
                                                    <td className="py-2 px-4 border-b border-[#D1D5DB]">
                                                        {row.currency === "inr" ? (
                                                            <>&#8377;</>
                                                        ) : (
                                                            "$"
                                                        )}
                                                        {row.pay_rate}
                                                        {row.type_payment === "daily"
                                                            ? "/day"
                                                            : row.type_payment === "yearly"
                                                            ? "/yr"
                                                            : "/hr"}
                                                    </td>
                                                    <td className="py-2 px-4 border-b border-[#D1D5DB]">
                                                        {row.comment ? row.comment : "None"}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        )
                    )}
                </div>
                <ChangeProfileModal
                    open={this.state.showChangeProfileModal}
                    onCancelClick={() => this.setState({ showChangeProfileModal: false })}
                    onSubmitClick={(img) => {
                        const data = new FormData();
                        data.append("avatar", img);
                        this.props.updateUserProfileImg(data).then((res) => {
                            if (res.value.status === 200) {
                                this.setState({ showChangeProfileModal: false }, () =>
                                    this.props
                                        .getUserProfileImg(
                                            this.state.userId
                                                ? "/admins/?user_uuid=" + this.state.userId
                                                : "/users"
                                        )
                                        .then((res) => {
                                            if (
                                                res.value.status === 200 &&
                                                res.value.data &&
                                                res.value.data.url
                                            ) {
                                                this.setState({ profileImage: res.value.data.url });
                                            }
                                        })
                                );
                            }
                        });
                    }}
                />
                <AssignNewProjectModal
                    open={this.state.assignProject}
                    projectOptions={Select_project_dropdown}
                    onCancelClick={() => this.setState({ assignProject: false })}
                    onSubmitClick={(project) => {
                        const dataa = {
                            projectName: project,
                            userName: this.state.userDetails.user_login_as,
                        };

                        this.props.addUserbasedProject(dataa).then((res) => {
                            if (res.value.status === 201) {
                                toast.success("Project added successfully");
                                this.getUserBasedProjects();
                            } else {
                                toast.error("Something went wrong");
                            }
                            this.setState({ assignProject: false });
                        });
                    }}
                />
                <ConfirmationDialogue
                    open={this.state.showDeleteProjectModal}
                    onCancelClick={() => {
                        this.setState({ showDeleteProjectModal: false });
                    }}
                    onSubmitClick={() => handleDeleteProject()}
                    info={"Are you sure you want to Delete ?"}
                    submitButtonName={"Delete"}
                    cancelButtonName={"Cancel"}></ConfirmationDialogue>

                <ConfirmationDialogue
                    open={this.state.enableDeleteUserConfirmationModal}
                    onCancelClick={() => {
                        this.setState({ enableDeleteUserConfirmationModal: false });
                    }}
                    onSubmitClick={() => this.onHandleDeleteUserClick()}
                    info={"Are you sure you want to Delete ?"}
                    submitButtonName={"Delete"}
                    cancelButtonName={"Cancel"}></ConfirmationDialogue>
            </Layout>
        );
    }
}
const mapStateToProps = (state) => ({
  userprofile: state.userprofile,
  teams: state.teams,
  empDashboard: state.empDashboard,
});

const mapDispatchToProps = (dispatch) => ({
  getUserProfileImg: (params) => dispatch(getUserProfileImg(params)),
  getSkills: (params) => dispatch(getSkills(params)),
  getLocDesg: (params) => dispatch(getLocDesg(params)),
  getProjectData: (params) => dispatch(getProjectData(params)),
  updateUserProfileImg: (data) => dispatch(updateUserProfileImg(data)),
  postLocDesg: (data) => dispatch(postLocDesg(data)),
  getUserBasedProjects: (username) => dispatch(getUserBasedProjects(username)),
  postSkills: (params, data) => dispatch(postSkills(params, data)),
  updateLocDesg: (data) => dispatch(updateLocDesg(data)),
  updateSkills: (params, data) => dispatch(updateSkills(params, data)),
  getSalaryDaysData: (params) => dispatch(getSalaryDaysData(params)),
  getSalaryYearData: (params) => dispatch(getSalaryYearData(params)),
  getUserData: (params) => dispatch(getUserData(params)),
  getEmployeePhoneNumber: (user_id) =>
    dispatch(getEmployeePhoneNumber(user_id)),
  fetchAttendanceTimings: () => dispatch(fetchAttendanceTimings()),
  removeUserbasedProject: (data) => dispatch(removeUserbasedProject(data)),
  getUserProject: () => dispatch(getUserProject()),
  addUserbasedProject: (data) => dispatch(addUserbasedProject(data)),
  DeleteUserfromPadlock: (useremail, orgemail) =>
    dispatch(DeleteUserfromPadlock(useremail, orgemail)),
  DeleteUser: (userloginas) => dispatch(DeleteUser(userloginas)),
  getSalaryHistory: (user_id) => dispatch(getSalaryHistory(user_id)),
});
UserProfile.propTypes = {};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(UserProfile)
);
