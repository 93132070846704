import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { connect } from 'react-redux';
import { CheckCircleIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import {
    getRoleUpdate,
    postForgetPassword,
    GetSubscribeEmail,
    updateSubScribeEmail,
    getOrgDetails,
    updateOrgDetails,
    removeOrgImage,
} from "../../services/settings/action";
import InputField from "../../Components/InputField";
import flags from "react-phone-number-input/flags";
import { toast } from "react-toastify";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import OrgPlaceholder from "../../assets/orglogoplaceholder.svg";
import { withTranslation } from "react-i18next";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-number-input";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import moment from "moment-timezone";
import ConfirmationDialogue from '../../Components/ConfirmationDialogue';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

class GeneralSettingTab extends Component {
    constructor() {
        super();
        this.state = {
            GenerelSettingdSubSelectedTab: "organisation-settings",
            user_email: "",
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            subScribeEmail: "",
            // selectedTimezone: "America/Lima",
            selectedTimezone:"",
            receiveOffers: false,
            showChangePassword: false,
            obscureCurrentPassword: true,
            obscurePassword: true,
            obscureConfirmPassword: true,
            // today: new Date(),
            today: moment(),
            logoImg: null,
            ogranisationProfile: {},
            email: "",
            phoneNumber: "",
            showConfirmationDialog: false,
            
        };
        this.ref = React.createRef();
    }

    componentDidMount() {
        let user = localStorage.getItem("user_email");
        const storedTimezone = sessionStorage.getItem("timezone") || "";
        const url = window.location.pathname;
        setTimeout(() => {
            this.setState({ user_email: user, currentURL: url});
        }, 100);

        setTimeout(() => {
            if (this.state.currentURL == "/settings/general-settings/account-settings") {
                this.setState({ GenerelSettingdSubSelectedTab: "account-settings" });
            } else if (this.state.currentURL == "/settings/general-settings/email-settings") {
                this.setState({ GenerelSettingdSubSelectedTab: "email-settings" });
            }
            this.props.getOrgDetails().then((res) => {
                if (res.value.status === 200) {
                    let countryval = res.value.data.country;
                    if (countryval && countryval?.charAt(0)) {
                        countryval = countryval[0].toUpperCase() + countryval.substring(1);
                    }

                    if (res.value.data.org_image) {
                        this.setState({
                            ogranisationProfile: { ...res.value.data, country: countryval },
                            email: localStorage.getItem("user_email"),
                            phoneNumber: res.value.data?.phone ?? "",
                            logoImg: res.value.data.org_image,
                        });
                    } else {
                        this.setState({
                            ogranisationProfile: { ...res.value.data, country: countryval },
                            email: localStorage.getItem("user_email"),
                            phoneNumber: res.value.data?.phone ?? "",
                        });
                    }
                }
            });
            setTimeout(() => {
                window.history.pushState(
                    {},
                    null,
                    `/settings/general-settings/${this.state.GenerelSettingdSubSelectedTab}`
                );
            }, 150);
        }, 200);

        this.props.GetSubscribeEmail().then((data) => {
            if (data.value.status == 200) {
                this.setState({ subScribeEmail: data.value.data.status });
            }
        });     
        this.setState({ selectedTimezone: storedTimezone });
    }

    HandleMobileSelectTab = (e) => {
        this.setState({ GenerelSettingdSubSelectedTab: e.target.value });
        setTimeout(() => {
            window.history.pushState(
                {},
                null,
                `/settings/general-settings/${this.state.GenerelSettingdSubSelectedTab}`
            );
        }, 200);
    };

    HandleSelectTab = (e) => {
        this.setState({ GenerelSettingdSubSelectedTab: e });
        setTimeout(() => {
            window.history.pushState(
                {},
                null,
                `/settings/general-settings/${this.state.GenerelSettingdSubSelectedTab}`
            );
        }, 200);
    };

    onTimeZoneChange = (timezone) => {
        let z = this.state.today.toLocaleString("en-US", { timeZone: timezone.value });
        this.setState({selectedTimezone:timezone.value},()=>{
            moment.tz.setDefault(timezone.value)
            console.log(timezone.value)
            let currentTimezone=moment().tz(timezone.value).format('YYYY-MM-DD HH:mm:ss')
    
            console.log(currentTimezone,"current timezone")
        })
    };
    onSubmitClick = () => {
        sessionStorage.setItem('timezone', this.state.selectedTimezone);
        this.setState({
            defaultTimezone: this.state.selectedTimezone,
            showConfirmationDialog: false,
        });
        window.location.reload();
    };
    toggleConfirmationDialog = () => {
        this.setState((prevState) => ({
            showConfirmationDialog: !prevState.showConfirmationDialog,
        }));
    };
    onSaveChanges = () => {
        // sessionStorage.setItem('timezone', this.state.selectedTimezone);
        // this.setState({ defaultTimezone: this.state.selectedTimezone });
        // window.location.reload();
        this.toggleConfirmationDialog();
    };
    render() {
        const { t } = this.props;
        const changePassword = () => {
            if (
                this.state.currentPassword == "" ||
                this.state.newPassword == "" ||
                this.state.confirmNewPassword == ""
            ) {
                toast("Please fill all the fields");
            } else {
                if (this.state.newPassword === this.state.confirmNewPassword) {
                    let user = localStorage.getItem("user_email");

                    this.props.getRoleUpdate().then((res) => {
                        if (res.value.status == 200) {
                            let email = res.value.data;
                            let userEmail = email && email.filter((u) => u.username == user);

                            const dataa = {
                                new_password: this.state.newPassword,
                                password: this.state.currentPassword,
                                uu_id: userEmail[0].uu_id,
                            };

                            userEmail == undefined
                                ? toast("User not found")
                                : this.props
                                      .postForgetPassword(dataa)
                                      .then((res) => {
                                          if (res.value.status == 200) {
                                              toast("Password changed successfully");
                                              this.setState({
                                                  currentPassword: "",
                                                  newPassword: "",
                                                  confirmNewPassword: "",
                                              });
                                          } else {
                                              toast("Password not changed");
                                          }
                                      })
                                      .catch((err) => {
                                          toast("Password not changed");
                                          console.log(err);
                                      });
                        }
                    });
                } else {
                    toast("New password and confirm new password does not match");
                }
            }
        };

        // const onTimeZoneChange = (timezone) => {
        //     let z = this.state.today.toLocaleString("en-US", { timeZone: timezone.value });
        //     // this.setState({selectedDate: new Date(z)}, ()=>{
        //     //     let date = moment(this.state.selectedDate).format("YYYY-MM-DD")
        //     // })
        //     this.setState({selectedTimezone:timezone.value})
        //     console.log(this.state.selectedTimezone)
        //     moment.tz.setDefault(timezone.value)
        //     console.log(moment.tz.setDefault(timezone.value),"hi")
        // };

        const unSubscribeEmail = () => {
            if (this.state.subScribeEmail == true) {
                const dataa = {
                    data: "True",
                };
                this.props.updateSubScribeEmail(dataa).then((res) => {
                    if (res.value.status == 200) {
                        toast("Email Subscribed successfully");
                    } else {
                        toast("Something went wrong, Try Again");
                    }
                });
            } else {
                const dataa = {
                    data: "False",
                };
                this.props.updateSubScribeEmail(dataa).then((res) => {
                    if (res.value.status == 200) {
                        toast("Email Unsubscribed successfully");
                    } else {
                        toast("Something went wrong, Try Again");
                    }
                });
            }
        };
        const handleBasicClientInfo = () => {
            const profileData = new FormData();
            profileData.append("phone", this.state.ogranisationProfile["phone"]);
            profileData.append("address1", this.state.ogranisationProfile["address1"]);
            profileData.append("address2", this.state.ogranisationProfile["address2"]);
            profileData.append("city", this.state.ogranisationProfile["city"]);
            profileData.append("state", this.state.ogranisationProfile["state"]);
            profileData.append("country", this.state.ogranisationProfile["country"]);
            profileData.append("zip", this.state.ogranisationProfile["Zip"]);
            profileData.append("file", this.state.logoImg);
            this.props.updateOrgDetails(profileData).then((res) => {
                if (res.value.status === 200) {
                    toast.success("Organisation updated successfully");
                }
            });
        };
        return (
            <>
                <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg mt-2 flex justify-between items-center">
                    <h4 className="text-primary">{t("settingsTab.generalSettings")}</h4>

                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            {t("requestsModule.selectATab")}
                        </label>
                        <select
                            id="tabs"
                            name="tabs"
                            onChange={(e) => this.HandleMobileSelectTab(e)}
                            className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                            defaultValue={this.state.SelectedTab}>
                            <option key="organsation-settings" value="organisation-settings">
                                Organisation {t("settings")}
                            </option>
                            <option key="account-settings" value="account-settings">
                                {t("account")} {t("settings")}
                            </option>
                            <option key="email-settings" value="email-settings">
                                {t("email")} {t("settings")}
                            </option>
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <div
                            key="organisation-settings"
                            onClick={() => this.HandleSelectTab("organisation-settings")}
                            className={classNames(
                                this.state.GenerelSettingdSubSelectedTab == "organisation-settings"
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "group inline-flex items-center py-4 px-1 border-b-2 mx-8 font-medium text-md cursor-pointer"
                            )}
                            aria-current={
                                this.state.GenerelSettingdSubSelectedTab == "organisation-settings"
                                    ? "page"
                                    : undefined
                            }>
                            <span>Organisation {t("settings")}</span>
                        </div>
                        <div
                            key="account-settings"
                            onClick={() => this.HandleSelectTab("account-settings")}
                            className={classNames(
                                this.state.GenerelSettingdSubSelectedTab == "account-settings"
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "group inline-flex items-center py-4 px-1 border-b-2 mr-8 font-medium text-md cursor-pointer"
                            )}
                            aria-current={
                                this.state.GenerelSettingdSubSelectedTab == "account-settings"
                                    ? "page"
                                    : undefined
                            }>
                            <span>
                                {t("account")} {t("settings")}
                            </span>
                        </div>
                        <div
                            key="email-settings"
                            onClick={() => this.HandleSelectTab("email-settings")}
                            className={classNames(
                                this.state.GenerelSettingdSubSelectedTab == "email-settings"
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                            )}
                            aria-current={
                                this.state.GenerelSettingdSubSelectedTab == "email-settings"
                                    ? "page"
                                    : undefined
                            }>
                            <span>
                                {t("email")} {t("settings")}
                            </span>
                        </div>
                    </div>
                </div>

                {this.state.GenerelSettingdSubSelectedTab == "account-settings" ? (
                    <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg my-6">
                        <h2 className="primary-text my-4">
                            {t("account")} {t("settings")}
                        </h2>
                        <hr className="my-3" />

                        <div className="border relative border-fiver rounded-lg p-8 my-8">
                            <h1 className="absolute -top-3 -mt-px bg-white text-lg font-medium left-3 inline-block px-1">
                                {t("settingsTab.emailAndLoginPreferences")}
                            </h1>
                            <h3 className="my-2">{this.state.user_email}</h3>
                            <h5
                                className="text-primary cursor-pointer w-1/3"
                                onClick={() => {
                                    this.setState({ showChangePassword: true });
                                }}>
                                [{t("settingsTab.changePassword")}]
                            </h5>
                        </div>

                        <div className="border relative border-fiver rounded-lg p-8 my-8">
                            <h1 className="absolute -top-3 -mt-px bg-white text-lg font-medium left-3 inline-block px-1">
                                {t("settingsTab.timePreferences")}
                            </h1>
                            <h3 className="my-3">{t("settingsTab.yourPrimaryTimeZone")}: </h3>
                            {/* <h5 className="text-primary">[{t("settingsTab.changeTimeZone")}]</h5> */}
                            {this.state.selectedTimezone !== null && this.state.selectedTimezone !== "" ? (
                                    <h5 className="text-primary">[{this.state.selectedTimezone}]</h5>
                                ) : (
                                    <h5 className="text-primary">[{t("settingsTab.changeTimeZone")}]</h5>
                                )}
                            <div className="w-56 mt-4">
                                <TimezoneSelect
                                    placeholder={t("pleaseSelectTimeZone")}
                                    className="rounded-lg border-none mr-4 lg:mr-4 w-60"
                                    labelStyle={{ color: "pink", borderRadius: "20px" }}
                                    value={this.state.selectedTimezone}
                                    timezones={{
                                        ...allTimezones,
                                        "America/Lima": "Pittsburgh",
                                        "Europe/Berlin": "Frankfurt",
                                    }}
                                    onChange={(e) => {
                                        this.setState({
                                            selectedTimezone: e.value,
                                        });
                                        this.onTimeZoneChange(e);
                                    }}
                                />
                            </div>
                            <p className="text-gray-400 mt-2">
                                {t("settingsTab.settingPrimaryTimeZoneMessage")}.
                            </p>
                        </div>
                        <div className="flex justify-end items-center my-4">
                            <button
                                onClick={this.onSaveChanges}
                                className="bg-primary rounded-md p-1 px-4 text-white font-medium shadow-md">
                                {t("settingsTab.saveChanges")}
                            </button>
                            {/* <TimeZoneProvider>

                            <SaveChangeButton selectedTimeZone={this.state.selectedTimezone}/>
                            </TimeZoneProvider> */}
                        </div>
                    </div>
                ) : null}
                {this.state.GenerelSettingdSubSelectedTab == "organisation-settings" ? (
                    <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg my-6">
                        <div className="w-[705px] max-w-full flex flex-col gap-x-6 gap-y-2 items-stretch">
                            <div>
                                <p className="pb-2 text-[#6B7280] font-medium">Company Logo</p>
                                <div className="flex items-center gap-3">
                                    <div className="h-14 w-36 flex items-center justify-center">
                                        <img
                                            src={
                                                this.state.logoImg
                                                    ? typeof this.state.logoImg === "string"
                                                        ? this.state.logoImg
                                                        : URL.createObjectURL(this.state.logoImg)
                                                    : OrgPlaceholder
                                            }
                                            alt="logo"
                                            className="h-full"
                                        />
                                    </div>
                                    <input
                                        type="file"
                                        accept="image/png,image/jpeg,image/svg+xml"
                                        hidden
                                        id="logo"
                                        ref={this.ref}
                                        name="logo"
                                        onChange={(ev) =>
                                            this.setState({ logoImg: ev.target.files[0] })
                                        }
                                    />
                                    <label
                                        htmlFor="logo"
                                        className="bg-primary cursor-pointer rounded-md px-3 py-1.5 text-white leading-5 text-xs font-medium">
                                        {!this.state.logoImg ? "Upload Logo" : "Change Logo"}
                                    </label>
                                    {this.state.ogranisationProfile?.org_image && (
                                        <button
                                            onClick={() => {
                                                this.props.removeOrgImage().then((res) => {
                                                    if (res.value.status === 200) {
                                                        this.setState({ logoImg: null });
                                                        if (this.ref?.current?.value) {
                                                            this.ref.current.value = "";
                                                        }
                                                    }
                                                });
                                            }}
                                            className="border border-[#EF4444] text-[#EF4444] bg-white cursor-pointer rounded-md px-3 py-1.5 leading-5 text-xs font-medium">
                                            Remove
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="lg:grid lg:grid-cols-4 gap-4 ">
                                <div className="col-span-4">
                                    <div className="w-full mt-6">
                                        <div className="pb-2 text-[#6B7280] font-medium text-base">
                                            Company name
                                        </div>
                                        <InputField
                                            value={this.state.ogranisationProfile?.company}
                                            onInputChange={(e) => {
                                                const details = {
                                                    ...this.state.ogranisationProfile,
                                                };
                                                details.company = e.target.value;
                                                this.setState({ ogranisationProfile: details });
                                            }}
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="w-full mt-6">
                                        <div className="pb-2 text-[#6B7280] font-medium text-base">
                                            Email Address
                                        </div>
                                        <InputField
                                            value={this.state.email}
                                            disabled={this.state.email}
                                            onInputChange={(e) => {
                                                const details = {
                                                    ...this.state.ogranisationProfile,
                                                };
                                                details.email = e.target.value;
                                                this.setState({ ogranisationProfile: details });
                                            }}
                                            type="text"
                                        />
                                    </div>
                                </div>

                                <div className="col-span-2">
                                    <div className="pb-2 text-[#6B7280] mt-6 font-medium text-base">
                                        Phone Number
                                    </div>
                                    <div className="w-full border border-gray-300 focus-within:border-gray-500 relative px-3 py-1 rounded-md">
                                        <PhoneInput
                                            flags={flags}
                                            displayInitialValueAsLocalNumber={true}
                                            defaultCountry="IN"
                                            onChange={(val) => {
                                                this.setState({
                                                    phoneNumber: formatPhoneNumberIntl(val),
                                                });
                                                const details = {
                                                    ...this.state.ogranisationProfile,
                                                };
                                                details.phone = this.state.phoneNumber;
                                                this.setState({ ogranisationProfile: details });
                                            }}
                                            value={this.state.ogranisationProfile.phone}
                                            placeholder={
                                                !this.state.phoneNumber ? "No Contact found" : ""
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="w-full mt-6">
                                        <div className="pb-2 text-[#6B7280] font-medium text-base">
                                            Address Line 1
                                        </div>
                                        <InputField
                                            value={
                                                !this.state.ogranisationProfile
                                                    ? ""
                                                    : this.state.ogranisationProfile.address1
                                            }
                                            onInputChange={(e) => {
                                                const details = {
                                                    ...this.state.ogranisationProfile,
                                                };
                                                details.address1 = e.target.value;
                                                this.setState({ ogranisationProfile: details });
                                            }}
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="w-full mt-6">
                                        <div className="pb-2 text-[#6B7280] font-medium text-base">
                                            Address Line 2
                                        </div>
                                        <InputField
                                            value={
                                                !this.state.ogranisationProfile
                                                    ? ""
                                                    : this.state.ogranisationProfile.address2
                                            }
                                            onInputChange={(e) => {
                                                const details = {
                                                    ...this.state.ogranisationProfile,
                                                };
                                                details.address2 = e.target.value;
                                                this.setState({ ogranisationProfile: details });
                                            }}
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="w-full mt-6 relative">
                                        <div className="pb-2 text-[#6B7280] font-medium text-base">
                                            Country
                                        </div>
                                        <div className="text-tiny text-[#333333] bg-white focus-within:outline-none border-none focus-within:border-none">
                                            <CountryDropdown
                                                value={this.state.ogranisationProfile.country}
                                                className="w-full text-tiny rounded border-gray-300 focus:border-primary focus:outline-none hover:cursor-pointer"
                                                onChange={(e) => {
                                                    const details = {
                                                        ...this.state.ogranisationProfile,
                                                    };
                                                    details.country = e;
                                                    this.setState({ ogranisationProfile: details });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="w-full mt-6 relative">
                                        <div className="pb-2 text-[#6B7280] font-medium text-base">
                                            State
                                        </div>
                                        <div className="px-1 text-tiny text-[#333333] bg-white focus-within:outline-none border-none focus-within:border-none">
                                            <RegionDropdown
                                                country={this.state.ogranisationProfile.country}
                                                value={this.state.ogranisationProfile.state}
                                                classes="w-full text-tiny rounded border-gray-300 focus:border-primary focus:outline-none hover:cursor-pointer"
                                                onChange={(e) => {
                                                    const details = {
                                                        ...this.state.ogranisationProfile,
                                                    };
                                                    details.state = e;
                                                    this.setState({ ogranisationProfile: details });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="w-full mt-6">
                                        <div className="pb-2 text-[#6B7280] font-medium text-base">
                                            City
                                        </div>
                                        <InputField
                                            value={
                                                !this.state.ogranisationProfile
                                                    ? ""
                                                    : this.state.ogranisationProfile.city
                                            }
                                            onInputChange={(e) => {
                                                const details = {
                                                    ...this.state.ogranisationProfile,
                                                };
                                                details.city = e.target.value;
                                                this.setState({ ogranisationProfile: details });
                                            }}
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="w-full mt-6">
                                        <div className="pb-2 text-[#6B7280] font-medium text-base">
                                            Zip Code
                                        </div>
                                        <InputField
                                            value={
                                                !this.state.ogranisationProfile
                                                    ? ""
                                                    : this.state.ogranisationProfile.Zip
                                            }
                                            onInputChange={(e) => {
                                                const details = {
                                                    ...this.state.ogranisationProfile,
                                                };
                                                details.Zip = e.target.value;
                                                this.setState({ ogranisationProfile: details });
                                            }}
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="col-span-5">
                                    <div className="flex justify-start items-center pt-4">
                                        <button
                                            className="bg-primary text-white py-2 px-6 rounded-md shadow-md"
                                            onClick={() => {
                                                handleBasicClientInfo();
                                            }}>
                                            Save Changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {this.state.GenerelSettingdSubSelectedTab == "email-settings" ? (
                    <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg mt-4 ">
                        <div className="my-4">
                            <h2 className="text-primary">
                                {t("settingsTab.yourCap")} {t("email")} {t("settings")}
                            </h2>
                            <p>{t("settingsTab.whatEmailsYouLikeToReceive")}</p>
                        </div>
                        <div className="my-4 ">
                            <h2 className="text-primary h-4">
                                {t("settingsTab.unsubscribeMails")}
                            </h2>
                            <div className="flex justify-between border-b py-4 items-center ">
                                <p>{t("settingsTab.stopReceiveMailsMessage")}</p>
                                <input
                                    type="checkbox"
                                    checked={this.state.subScribeEmail}
                                    name="email"
                                    id="emailTest"
                                    className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                                    onClick={() => {
                                        this.setState({
                                            subScribeEmail: !this.state.subScribeEmail,
                                        });
                                    }}
                                />
                            </div>
                            <div className="my-6">
                                <h2 className="text-primary h-4">
                                    {t("settingsTab.discountAndOffers")}
                                </h2>
                                <div className=" flex justify-between border-b py-4 items-center">
                                    <p>{t("settingsTab.discountAndOffersMessage")}</p>
                                    <input
                                        type="checkbox"
                                        checked={this.state.subScribeEmail}
                                        name="email"
                                        id="emailTest"
                                        className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                                        onClick={() => {
                                            this.setState({
                                                subScribeEmail: !this.state.subScribeEmail,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-end items-center my-4">
                            <button
                                onClick={() => {
                                    unSubscribeEmail();
                                }}
                                className="bg-primary rounded-md p-1 px-4 text-white font-medium shadow-md">
                                {t("settingsTab.saveChanges")}
                            </button>
                        </div>
                    </div>
                ) : null}
                <ConfirmationDialogue
                    onCancelClick={this.toggleConfirmationDialog}
                    onSubmitClick={this.onSubmitClick}
                    title="Reload Confirmation"
                    info="Please reload the screen by clicking OK to get the time according to the selected Timezone."
                    // info={(<span>Please reload the screen by clicking <strong style={{fontWeight:'700', color:"black"}}>OK</strong> to get the time according to the selected Timezone.</span>)}
                    submitButtonName="OK"
                    cancelButtonName="Cancel"
                    open={this.state.showConfirmationDialog}
                />
                <Transition.Root show={this.state.showChangePassword} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed z-10 inset-0 overflow-y-auto"
                        onClose={() => {
                            this.setState({ showChangePassword: false });
                        }}>
                        <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                                    <div className="flex justify-center items-center my-6 mr-3">
                                        <div className="w-full">
                                            <div className="flex items-center mb-4">
                                                <InputField
                                                    value={this.state.currentPassword}
                                                    onInputChange={(e) => {
                                                        this.setState({
                                                            currentPassword: e.target.value,
                                                        });
                                                    }}
                                                    FieldName={"Current Password"}
                                                    type={
                                                        this.state.obscureCurrentPassword
                                                            ? "password"
                                                            : "text"
                                                    }
                                                />
                                                {this.state.obscureCurrentPassword ? (
                                                    <EyeOffIcon
                                                        className="h-4 w-4 -ml-10 z-10"
                                                        onClick={() => {
                                                            this.setState({
                                                                obscureCurrentPassword: false,
                                                            });
                                                        }}
                                                    />
                                                ) : (
                                                    <EyeIcon
                                                        className="h-4 w-4 -ml-10 z-10"
                                                        onClick={() => {
                                                            this.setState({
                                                                obscureCurrentPassword: true,
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div className="flex items-center mb-4">
                                                <InputField
                                                    value={this.state.newPassword}
                                                    onInputChange={(e) => {
                                                        this.setState({
                                                            newPassword: e.target.value,
                                                        });
                                                    }}
                                                    FieldName={"New Password"}
                                                    type={
                                                        this.state.obscurePassword
                                                            ? "password"
                                                            : "text"
                                                    }
                                                />
                                                {this.state.obscurePassword ? (
                                                    <EyeOffIcon
                                                        className="h-4 w-4 -ml-10 z-10"
                                                        onClick={() => {
                                                            this.setState({
                                                                obscurePassword: false,
                                                            });
                                                        }}
                                                    />
                                                ) : (
                                                    <EyeIcon
                                                        className="h-4 w-4 -ml-10 z-10"
                                                        onClick={() => {
                                                            this.setState({
                                                                obscurePassword: true,
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div className="flex items-center mb-7">
                                                <InputField
                                                    value={this.state.confirmNewPassword}
                                                    onInputChange={(e) => {
                                                        this.setState({
                                                            confirmNewPassword: e.target.value,
                                                        });
                                                    }}
                                                    FieldName={"Confirm New Password"}
                                                    type={
                                                        this.state.obscureConfirmPassword
                                                            ? "password"
                                                            : "text"
                                                    }
                                                />
                                                {this.state.obscureConfirmPassword ? (
                                                    <EyeOffIcon
                                                        className="h-4 w-4 -ml-10 z-10"
                                                        onClick={() => {
                                                            this.setState({
                                                                obscureConfirmPassword: false,
                                                            });
                                                        }}
                                                    />
                                                ) : (
                                                    <EyeIcon
                                                        className="h-4 w-4 -ml-10 z-10"
                                                        onClick={() => {
                                                            this.setState({
                                                                obscureConfirmPassword: true,
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div className="flex items-center">
                                                <button
                                                    className="rounded-md shadow-sm hover:shadow-lg p-1 px-6 mr-4 border border-primary bg-primary text-white"
                                                    onClick={() => {
                                                        changePassword();
                                                    }}>
                                                    {t("timeTracker.submit")}
                                                </button>
                                                <button
                                                    className="rounded-md shadow-sm hover:shadow-lg p-1 px-6 border-red-500 borde"
                                                    onClick={() => {
                                                        this.setState({
                                                            showChangePassword: false,
                                                        });
                                                    }}>
                                                    {t("requestsModule.cancel")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
            </>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    postForgetPassword: (data) => dispatch(postForgetPassword(data)),
    getRoleUpdate: (data) => dispatch(getRoleUpdate(data)),
    GetSubscribeEmail: () => dispatch(GetSubscribeEmail()),
    updateSubScribeEmail: (data) => dispatch(updateSubScribeEmail(data)),
    getOrgDetails: () => dispatch(getOrgDetails()),
    updateOrgDetails: (data) => dispatch(updateOrgDetails(data)),
    removeOrgImage: () => dispatch(removeOrgImage()),
});

GeneralSettingTab.propTypes = {

};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(GeneralSettingTab));



//get role update 
//postForgetPassword
// unsubsrcibe mail