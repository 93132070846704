import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Dialog, Menu, Transition } from "@headlessui/react";
import docPNG from "../../../images/quickimg.png";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";

import FileSaver from "file-saver";
import BeatLoader from "react-spinners/BeatLoader";
import "../css/cssHourlyReport_tab.css";

//actions
import {
  fetchHourQuickReport,
  fetchHourCustomReport,
  downloadHourReport,
} from "../../../services/reports/actions";
import { fetchallEmployees } from "../../../services/projects/actions";

//icons
import { CalendarIcon } from "@heroicons/react/outline";
import DateRangeFilter from "./DatePicker";
import SelectField from "../../../Components/SelectField";

import { withTranslation } from "react-i18next";
import { data } from "autoprefixer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

let d = new Date();

let firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
let c = convert(firstDay);

let lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
let cc = convert(lastDay);

class HourReport_tab extends Component {
  constructor() {
    super();
    this.state = {
      startDate: c,
      endDate: cc,
      initailQuickReport: [],
      filteredQuickReport: [],
      showDateRange: false,
      selectedEmployee: "",
      sumOfProjectHours: 0,
      selectedProject: "",
      userId:'',
    };
  }

  componentDidMount() {
    this.props.fetchallEmployees();
    this.quickReport(this.state.startDate, this.state.endDate);

    this.props.fetchHourCustomReport();
  }

  quickReport = (startDate, endDate) => {
    this.props.fetchHourQuickReport(startDate, endDate).then((data) => {
      if (data.value.status == 200) {
        this.setState({
          initailQuickReport: data.value.data.all_project_data,
          filteredQuickReport: data.value.data.all_project_data,
        });
      } else {
        this.setState({
          initailQuickReport: [],
          filteredQuickReport: [],
        });
      }
    });
  };
  

  convertMintoHr(minutes) {
    let hours = minutes / 60;
    let minutesLeft = minutes % 60;
    return `${Math.trunc(hours)}hr : ${minutesLeft}min`;
  }

  

  render() {
    const { t } = this.props;
    // console.log(this.state.filteredQuickReport);

    const report = this.props.hourReport;

    const quickReport = report && report.HourQuickReport;

    const quickReportLoading = report && report.isFetchingHourQuickReport;

    let empList = this.props.allEmployees;
    let listofAllEmployees = empList && empList.AllEmployees;

    let allProjectNameArr = [];

    let listOfAllProjects =
      quickReport == undefined || quickReport == ""
        ? ""
        : quickReport.all_project_data.map((data, idx) => {
            allProjectNameArr.push(data.project_name);
          });

    let Select_emp_dropdown =
      listofAllEmployees &&
      listofAllEmployees.map((el) => ({
        id: el.user_id,
        name: el.user_first_name + " " + el.user_last_name,
      }));

      Select_emp_dropdown = listofAllEmployees && [...Select_emp_dropdown]?.sort((a,b)=>a.name.localeCompare(b.name));

    // console.log(listofAllEmployees)

    let Select_project_dropdown =
      allProjectNameArr &&
      allProjectNameArr.map((el) => ({
        name: el,
        // id: el.user_id
      }));

    const onClickDescending = () => {
      let newList = [];
      [...this.state.filteredQuickReport].map((eachItem) => {
        eachItem.users.sort((a, b) => b.time_logged - a.time_logged);
        let newItem = {
          project_name: eachItem.project_name,
          project_total: eachItem.project_total,
          users: eachItem.users,
        };
        newList.push(newItem);
      });

      this.setState({
        filteredQuickReport: [...newList],
      });
    };


    const onClickAssending = () => {
      let newList = [];
      [...this.state.filteredQuickReport].map((eachItem) => {
        eachItem.users.sort((a, b) => a.time_logged - b.time_logged);
        let newItem = {
          project_name: eachItem.project_name,
          project_total: eachItem.project_total,
          users: eachItem.users,
        };
        newList.push(newItem);
      });

      this.setState({
        filteredQuickReport: [...newList],
      });
    };

    // const downloadHourReportCSV = () => {
    //   this.props
    //     .downloadHourReport(
    //       this.state.startDate,
    //       this.state.endDate
    //        +
    //         (this.state.userId !== "" && this.state.userId
    //           ? "&type=user&user=" + this.state.userId
    //           : ""
    //           )
    //     )
    //     .then((data) => {
    //       if (data.value.status == 200) {
    //         var blob = new Blob([data.value.data], {
    //           type: "application/xlsx",
    //         });
    //         FileSaver.saveAs(blob, "report.xlsx");
    //       }
    //     });
    // };
    const downloadHourReportCSV = () => {
      const selectedEmployeeName = this.state.selectedEmployee.trim().toLowerCase();
      const selectedProjectName = this.state.selectedProject.trim().toLowerCase();
      let filteredData;
      if(selectedEmployeeName && selectedProjectName){

        filteredData = this.state.filteredQuickReport.filter((item) => {
          const isEmployeeAssociated = item.users.some(
            (user) =>
              user.user.trim().toLowerCase() === selectedEmployeeName
          );
      
          const isProjectMatched =
            selectedProjectName === "" ||
            item.project_name.trim().toLowerCase() === selectedProjectName
      
            return isEmployeeAssociated && isProjectMatched;
        })
        .map((item)=>{
          const selectedEmployeeData=item.users.filter((user)=>user.user.trim().toLowerCase()===selectedEmployeeName)
          return {
            project_name:item.project_name,
            project_total:item.project_total,
            users:selectedEmployeeData
          }
        })
      } else{
        filteredData = this.state.filteredQuickReport.filter((item) => {
        const selectedEmployeeName = this.state.selectedEmployee.trim().toLowerCase();
        const selectedProjectName = this.state.selectedProject.trim().toLowerCase();
        const isEmployeeAssociated = item.users.some(
          (user) =>
            user.user.trim().toLowerCase() === selectedEmployeeName
        );
    
        const isProjectMatched =
          selectedProjectName === "" ||
          item.project_name.trim().toLowerCase() === selectedProjectName
    
      
      if (selectedEmployeeName) {
        return isEmployeeAssociated;
      } else if (selectedProjectName) {
        return isProjectMatched;
      }
      return true;
      });
      }
      
      this.props
        .downloadHourReport(
          this.state.startDate,
          this.state.endDate +
            (this.state.userId !== "" && this.state.userId
              ? "&type=user&user=" + this.state.userId
              : "")+
              (this.state.selectedProject !== ""
        ? "&project=" + encodeURIComponent(this.state.selectedProject)
        : ""),
        filteredData
        )
        .then((data) => {
          if (data.value.status === 200) {
            var blob = new Blob([data.value.data], {
              type: "application/xlsx",
            });
            FileSaver.saveAs(blob, "report.xlsx");
          }
        });
    };   

    const onChange = (ranges) => {
      let dd = convert(ranges.startDate);
      let rr = convert(ranges.endDate);
      this.setState({ startDate: dd, endDate: rr });
      this.quickReport(dd, rr);
      this.setState({selectedProject:""})
    };

    this.onSelectEmployee = (e) => {
    //  this.setState({
    //     [e.target.name]: e.target.value,
    //     selectedEmployee: e.target.value,
    //   });

      const selectedEmployeeId = e.target.value;
      const selectedEmployee = listofAllEmployees.find(employee => employee.user_id === selectedEmployeeId);
      this.setState({userId:e.target.value})
      this.setState({
        [e.target.name]: selectedEmployeeId, 
        selectedEmployee: selectedEmployee ? selectedEmployee.user_first_name + " " + selectedEmployee.user_last_name : '', 
      });
    };

    this.onSelectProject = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
        selectedProject: e.target.value,
      });
    };
    
    const totaltimeEmployee=(selectedEmployee,selectedProject)=>{
      if(selectedEmployee && selectedProject){
        let totaltime=0
        const selectedProjectData=quickReport?.all_project_data?.find(project=>project.project_name===selectedProject)
        if(selectedProjectData && selectedProjectData?.users){
          const userproject=selectedProjectData?.users?.filter(
            user=>user.user===selectedEmployee
          )
          userproject?.forEach(item=>{
            totaltime+=item.time_logged
          })
        }
        return totaltime
      }
      if (selectedEmployee){

        let totaltime=0
        quickReport?.all_project_data?.forEach(project=>{
          if((!selectedProject || project.project_name===selectedProject) && project.users){

            const userproject=project.users?.filter(
              user=> user.user === selectedEmployee
            )
            userproject?.forEach(item=>{
              totaltime+=item.time_logged
            })
          }
        })
        return totaltime
      }
      if(selectedProject !==""){

        const selectedProjectData=quickReport?.all_project_data?.find(project=>project.project_name===selectedProject)
        return selectedProjectData ? selectedProjectData?.project_total?.total_time_logged:0

      }
      return quickReport?.grand_total_time
    }
    // console.log(this.state.selectedProject);

    let hours = [];
    return (
      <div className="mt-5">
        <div className="flex justify-between items-center">
          <div className="gap-2 px-3 py-2 bg-white flex items-center rounded-full justify-start cursor-pointer">
            <CalendarIcon
              className="h-6 w-6"
              onClick={() => {
                this.setState({ showDateRange: true });
              }}
            />
            <input
              value={`${new Date(
                this.state.startDate
              ).toDateString()} - ${new Date(
                this.state.endDate
              ).toDateString()}`}
              disabled
              className="w-[260px] bg-white rounded-full font-medium text-gray-600"
              onClick={() => {
                this.setState({ showDateRange: true });
              }}
            />
          </div>
        </div>

        <div className="bg-white px-4 py-5 mt-4 border-b border-gray-200 lg:justify-center justify-start sm:px-6 rounded-lg">
          <div className="flex flex-col justify-between items-start xl:flex-row mb-6 xl:items-center">
            <div>
              <div className="flex mr-4 lg:mr-0">
                <p className="mr-2 font-semibold">
                  {t("ReportsTab.grandTotal")}:
                </p>
                <p>
                  {/* {quickReport &&
                    this.convertMintoHr(quickReport.grand_total_time)} */}
                    {quickReport && (
                    this.convertMintoHr(totaltimeEmployee(this.state.selectedEmployee,this.state.selectedProject))
                    )}
                </p>
              </div>
              <div className="flex">
                <p className="mr-2 font-semibold">
                  {t("ReportsTab.groupedBy")}:
                </p>
                <p>{t("projects")}</p>
              </div>
            </div>

            <div className="flex items-end flex-col lg:flex-row mt-4 xl:mt-0">
              <div className=" flex justify-between">
                <div className="my-3 mr-3 lg:my-0 w-52 flex-1">
                  <SelectField
                    FieldName={`${t("select")}` + ` ${t("employee")}`}
                    onSelectChange={(e) => this.onSelectEmployee(e)}
                    name={"Select Employee"}
                    arrayOfData={Select_emp_dropdown}
                    id={"name"}
                  ></SelectField>
                </div>
                <div className="my-3 mr-3 lg:my-0 w-52 flex-1">
                  <SelectField
                    FieldName={`${t("select")}` + ` ${t("project")}`}
                    onSelectChange={(e) => this.onSelectProject(e)}
                    name={"Select Project"}
                    arrayOfData={Select_project_dropdown}
                    id={"name"}
                  ></SelectField>
                </div>
              </div>
              <div className="sm-m:w-full">
                <div className="flex items-center">
                  <Menu as="div" className="relative w-full">
                    <div className="w-full">
                      <Menu.Button className="max-w-md p-1 flex w-full justify-end items-center lg:items-center text-sm rounded-full">
                        <div className="p-2 px-5 w-full font-medium bg-primary text-white rounded-full">
                          Action
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute z-20 right-0 mt-2 w-full sm:w-48 rounded-md shadow-lg py-1 bg-white cursor-pointer">
                        <Menu.Item
                          onClick={() => {
                            downloadHourReportCSV();
                          }}
                        >
                          {({ active }) => (
                            <p
                              className={classNames(
                                active
                                  ? "bg-primary text-white"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm "
                              )}
                            >
                              Export as CSV
                            </p>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          {quickReportLoading ? (
            <div className="text-center h-64 w-full flex justify-center items-center">
              <BeatLoader
                color={"#684994"}
                quickReportLoading={quickReportLoading}
                size={15}
                margin={2}
              />{" "}
            </div>
          ) : (
            <div
              className="mt-2 flex flex-col overflow-y-auto bg-gray-100"
              style={{ maxHeight: "auto" }}
            >
              <div className="inline-block min-w-full align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[51vh] overflow-auto">
                  <table
                    className="min-w-full border-separate"
                    style={{ borderSpacing: 0 }}
                  >
                    <thead className="bg-gray-50 sticky top-0 z-10">
                      <tr>
                        <th
                          scope="col"
                          className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold  text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12"
                        >
                          {t("TeamTab.users")}
                        </th>
                        <th
                          scope="col"
                          className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold  text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                          
                        >
                          {t("task")}
                        </th>
                        <th
                          scope="col"
                          className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold  text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                        >
                          {t("date")}
                        </th>
                        <th
                          scope="col"
                          className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                        >
                          {t("ReportsTab.typeActivityIndex")}
                        </th>
                      
                          <th
                            scope="col"
                            className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur  font-family: 'Times New Roman',  serif text-20  backdrop-filter sm:pr-6 lg:pr-12"
                          >
                            <div className="flex gap-2 justify-center">{t("timeLogged")}
                            {this.state.selectedEmployee === "" &&
                            this.state.selectedProject === "" ? (
                              <div className="flex flex-col">
                                <button
                                  className="upArrow"
                                  onClick={onClickAssending}
                                >
                                  <IoMdArrowDropup className="my-0" />
                                </button>
                                <button
                                  className="downArrow"
                                  onClick={onClickDescending}
                                >
                                  <IoMdArrowDropdown className="my-0" />
                                </button>
                              </div>
                            ) : null}
                            </div>
                          </th>
                     
                      </tr>
                    </thead>
                    {this.state.filteredQuickReport == "" ||
                    this.state.filteredQuickReport == undefined
                      ? ""
                      : this.state.filteredQuickReport.filter((item)=>{ 
                        const selectedEmployeeName = this.state.selectedEmployee;              
                        const isEmployeeAssociated = item.users.some(
                          (user) => user.user.trim().toLowerCase() === selectedEmployeeName.trim().toLowerCase()
                        );
                       if(selectedEmployeeName){
                        return isEmployeeAssociated 
                       }
                       return true;                      
                      
                      }).
                      map((item, index) => {
                        const selectedEmployeeName = this.state.selectedEmployee;
                        const isEmployeeAssociated = item.users.some(
                          (user) => user.user.trim().toLowerCase() === selectedEmployeeName.trim().toLowerCase()
                        );
                          return (
                            (item.project_name === this.state.selectedProject ||
                              !this.state.selectedProject) && (
                              <tbody key={index + "tbody"} className="bg-white">
                                <tr key={index}>
                                  <td
                                    colSpan={5}
                                    className={classNames(
                                      index !== index.length - 1
                                        ? "border-b border-gray-200 flex"
                                        : "",
                                      "whitespace-nowrap py-4 pl-4 flex pr-3 items-center text-lg font-semibold text-gray-900 sm:pl-6 lg:pl-12"
                                    )}
                                  >
                                    <img
                                      alt=""
                                      src={docPNG}
                                      className="h-4 w-4 mr-1"
                                    />
                                    {item.project_name}
                                  </td>
                                </tr>
                                {item.users == "" ? (
                                  <tr key={index}>
                                    <td
                                      colSpan={5}
                                      className="border-b border-gray-200 whitespace-nowrap py-4 flex justify-start items-start pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                    >
                                      {t("user")} did not work in this Project
                                    </td>
                                  </tr>
                                ) : (
                                  item.users.map(
                                    (el, idx) =>
                                      (!this.state.selectedEmployee ||
                                        this.state.selectedEmployee.toLowerCase() ===
                                          el.user.toLowerCase()) && (
                                        <tr key={el.user + index + idx}>
                                          <td
                                            className={classNames(
                                              idx !== idx.length - 1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                            )}
                                          >
                                            {el.user}
                                          </td>
                                          <td
                                            className={classNames(
                                              idx !== idx.length - 1
                                                ? "border-b border-gray-200 flex justify-start items-start"
                                                : "",
                                              "whitespace-nowrap py-4 flex justify-start items-start pr-3 text-sm font-medium text-gray-900"
                                            )}
                                          >
                                           {/* {el.task == ""
                                            ? ""
                                            : el.task.length > 25
                                              ? el.task.substring(0, 45) + "..."
                                              : el.task} */}
                                          {el.task == ""
                                            ? "0:00min"
                                            : el.task.length > 25
                                              ? el.task.substring(0, 45) + "..."
                                              : el.task}
                                          </td>

                                          <td
                                            className={classNames(
                                              idx !== idx.length - 1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900"
                                            )}
                                          >
                                            {el.date}
                                          </td>
                                          <td
                                            className={classNames(
                                              idx !== idx.length - 1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                            )}
                                          >
                                            {el.type}(
                                            {el.Activity_index.substring(0, 4)}
                                            %)
                                          </td>
                                          <td
                                            className={classNames(
                                              idx !== idx.length - 1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                            )}
                                          >
                                            {this.convertMintoHr(
                                              el.time_logged
                                            )}
                                          </td>
                                        </tr>
                                      )
                                  )
                                )}
                                {/* {item.users == "" ? (
                                  <tr key={index + "key"}>
                                    <td
                                      className={classNames(
                                        index !== index.length - 1
                                          ? "border-b border-gray-200 flex"
                                          : "",
                                        "whitespace-nowrap py-4 pl-4 flex pr-3 items-center text-lg font-semibold text-gray-900 sm:pl-6 lg:pl-12"
                                      )}
                                    >
                                      {t("project")} {t("total")}
                                    </td>
                                    <td className="border-b border-gray-200"></td>
                                    <td className="border-b border-gray-200"></td>
                                    <td
                                      className={classNames(
                                        index !== index.length - 1
                                          ? "border-b border-gray-200"
                                          : "",
                                        "whitespace-nowrap py-4 pl-4 pr-3 text-md font-semibold text-gray-900 sm:pl-6 lg:pl-12"
                                      )}
                                    >
                                      {t("ReportsTab.online")}
                                      (0.00%)
                                    </td>
                                    <td
                                      className={classNames(
                                        index !== index.length - 1
                                          ? "border-b border-gray-200"
                                          : "",
                                        "whitespace-nowrap py-4 pl-4 pr-3 text-md font-semibold text-gray-900 sm:pl-6 lg:pl-12"
                                      )}
                                    >
                                      00hr:00min
                                    </td>
                                  </tr>
                                )  */}
                                {item.users.length === 0 || (!isEmployeeAssociated && selectedEmployeeName !== "") ? (
                                <tr></tr>
                              ) 
                                : (
                                  <tr key={index + "key"}>
                                    <td
                                      className={classNames(
                                        index !== index.length - 1
                                          ? "border-b border-gray-200 flex"
                                          : "",
                                        "whitespace-nowrap py-4 pl-4 flex pr-3 items-center text-lg font-semibold text-gray-900 sm:pl-6 lg:pl-12"
                                      )}
                                    >
                                      {t("project")} {t("total")}
                                    </td>
                                    <td className="border-b border-gray-200"></td>
                                    <td className="border-b border-gray-200"></td>
                                    <td
                                      className={classNames(
                                        index !== index.length - 1
                                          ? "border-b border-gray-200"
                                          : "",
                                        "whitespace-nowrap py-4 pl-4 pr-3 text-md font-semibold text-gray-900 sm:pl-6 lg:pl-12"
                                      )}
                                    >
                                      {item.project_total.type}(
                                      {item.project_total.total_activity_percent.toFixed(
                                        2
                                      )}
                                      %)
                                    </td>
                                    <td
                                      className={classNames(
                                        index !== index.length - 1
                                          ? "border-b border-gray-200"
                                          : "",
                                        "whitespace-nowrap py-4 pl-4 pr-3 text-md font-semibold text-gray-900 sm:pl-6 lg:pl-12"
                                      )}
                                    >
                                      {/* {item.users.map((data, idx) => {
                                        if(data.user === this.state.selectedEmployee)
                                        hours.push(data.time_logged);

                                      
                                      })}
                                      {this.state.selectedEmployee != ""
                                        ? this.convertMintoHr(
                                            hours.reduce((a, b) => a + b, 0)
                                          )
                                        : this.convertMintoHr(
                                            item.project_total.total_time_logged
                                          )} */}
                                          {this.state.selectedEmployee != "" && 

                                          this.convertMintoHr(
                                            item.users.filter((data) => data.user === this.state.selectedEmployee)
                                              .reduce((acc, user) => acc + user.time_logged, 0)
                                          )}
                                        {this.state.selectedEmployee === "" &&
                                          this.convertMintoHr(item.project_total.total_time_logged)}
                                    </td>
                                  </tr>
                                )}
                                <tr></tr>
                                
                              </tbody>
                            )
                          );
                        })}
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
        <Transition.Root show={this.state.showDateRange} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => {
              this.setState({ showDateRange: false });
            }}
          >
            <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[600px] sm:w-full sm:p-6">
                  <DateRangeFilter onChange={onChange} />
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allEmployees: state.projects,
  hourReport: state.report,
});

const mapDispatchToProps = (dispatch) => ({
  fetchallEmployees: () => dispatch(fetchallEmployees()),
  fetchHourQuickReport: (startDate, endDate) =>
    dispatch(fetchHourQuickReport(startDate, endDate)),
  fetchHourCustomReport: () => dispatch(fetchHourCustomReport()),
  downloadHourReport: (startDate, endDate) =>
    dispatch(downloadHourReport(startDate, endDate)),
});

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(HourReport_tab)
);
