import { ACTION_TYPES } from "../../../constants";
import api from "../api";

export const getUserProfileImg = (params) => ({
    type: ACTION_TYPES.GET_PROFILE_IMG,
    payload: api.getUserProfileImg(params),
});

export const updateUserProfileImg = (data) => ({
    type: ACTION_TYPES.UPDATE_PROFILE_IMG,
    payload: api.updateUserProfileImg(data),
});

export const getSkills = (params) => ({
    type: ACTION_TYPES.GET_USER_SKILLS,
    payload: api.getSkills(params),
});

export const updateSkills = (params, data) => ({
    type: ACTION_TYPES.UPDATE_USER_SKILLS,
    payload: api.updateSkills(params, data),
});

export const postSkills = (params, data) => ({
    type: ACTION_TYPES.UPDATE_USER_SKILLS,
    payload: api.postSkills(params, data),
});

export const getLocDesg = (params) => ({
    type: ACTION_TYPES.GET_USER_LOC_DESG,
    payload: api.getLocDesg(params),
});

export const updateLocDesg = (data) => ({
    type: ACTION_TYPES.UPDATE_USER_LOC_DESG,
    payload: api.updateLocDesg(data),
});

export const postLocDesg = (data) => ({
    type: ACTION_TYPES.UPDATE_USER_LOC_DESG,
    payload: api.postLocDesg(data),
});

export const getProjectData = (params) => ({
    type: ACTION_TYPES.GET_PROJECT_DATA,
    payload: api.getProjectData(params),
});

export const getUserData = (params) => ({
    type: ACTION_TYPES.GET_USER_DATA,
    payload: api.getUserData(params),
});

export const editUserDetails = (data) => ({
    type: ACTION_TYPES.EDIT_EMPLOYEE_BASIC_INFO,
    payload: api.editUserDetails(data),
});