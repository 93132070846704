/* eslint-disable import/no-anonymous-default-export */
import { API_ENDPOINTS } from "../../constants";
import { transport, padlocktransport } from "../../Networks/Auth";

export default {
    fetchActiveUsers: () => {
        let config = {
            url: API_ENDPOINTS.ACTIVE_USER_LIST,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    fetchActiveUsersObserver: () => {
        let config = {
            url: API_ENDPOINTS.ACTIVE_USER_LIST + "?observer=True",
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    fetchInActiveUsers: () => {
        let config = {
            url: API_ENDPOINTS.INACTIVE_USER_LIST,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    getusersfromteam: (id) => {
        let config = {
            url: API_ENDPOINTS.GET_USERS_FROM_TEAM + id,
            method: "GET",
        };
        return padlocktransport(config);
    },

    getclient: (id) => {
        let config = {
            url: API_ENDPOINTS.GET_CLIENT + id,
            method: "GET",
        };
        return padlocktransport(config);
    },

    editClientProfileInfo: (data, id) => {
        let config = {
            url: API_ENDPOINTS.EDIT_CLIENT_PROFILE_INFO + "?id=" + id,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    addclientToTeam: (client_id, team_id) => {
        let config = {
            url: API_ENDPOINTS.ADD_CLIENT_TO_TEAM,
            method: "PUT",
            data: { client_id, team_id },
            withCredentials: true,
        };
        return transport(config);
    },

    addmembertoteam: (data) => {
        let config = {
            url: API_ENDPOINTS.ADD_MEMBER_TO_TEAM,
            method: "POST",
            data: data,
            withCredentials: true,
        };
        return transport(config);
    },

    addmembertoteaminpadlock: (data) => {
        let config = {
            url:
                API_ENDPOINTS.ADD_MEMBER_TO_TEAM_IN_PADLOCK +
                data.org_uuid +
                "/" +
                data.team_uuid +
                "/?userid=" +
                data.users +
                "&team=" +
                data.name,
            method: "PUT",
            data: data,
        };
        return padlocktransport(config);
    },

    fetchAllWorkstatons: (email) => {
        let config = {
            url: API_ENDPOINTS.WORKSTATIONS_LIST + email,
            method: "GET",
        };
        return padlocktransport(config);
    },

    createTeamPadlock: (data) => {
        let config = {
            url: API_ENDPOINTS.CREATE_TEAM,
            method: "POST",
            data: data,
        };
        return padlocktransport(config);
    },

    createTeam: (data) => {
        let config = {
            url: API_ENDPOINTS.DELETE_TEAM,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    createClient: (data) => {
        let config = {
            url: API_ENDPOINTS.CREATE_CLIENT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    getclient: (months, year, status) => {
        const apiURL = localStorage.getItem("apiURL");
        console.log("API URL:", apiURL);

        let config = {
            url: `${API_ENDPOINTS.GET_CLIENT}?months=${months}&year=${year}&status=${status}`,
            method: "GET",
            // withCredentials: true,
        };
        return transport(config);
    },

    getinactiveclient: (months, year, status) => {
        const apiURL = localStorage.getItem("apiURL");
        console.log("API URL:", apiURL);

        let config = {
            url: `${API_ENDPOINTS.GET_CLIENT}?months=${months}&year=${year}&status=${status}`,
            method: "GET",
            // withCredentials: true,
        };
        return transport(config);
    },

    // createClient:(data)=>{
    //     let config = {
    //         url: API_ENDPOINTS.CREATE_CLIENT,
    //         method: "POST",
    //         data: data,
    //     };
    //     return padlocktransport(config);
    // },

    createWorkstation: (data) => {
        let config = {
            url: API_ENDPOINTS.CREATE_WORKSTATION,
            method: "POST",
            data: data,
        };
        return padlocktransport(config);
    },

    updateWorkstation: (data) => {
        let config = {
            url:
                API_ENDPOINTS.UPDATE_WORKSTATION +
                data.id +
                "&workstation=" +
                data.name +
                "&description=" +
                data.description,
            method: "PATCH",
            data: data,
        };
        return padlocktransport(config);
    },

    activeTeams: (org_uuid) => {
        let config = {
            url: API_ENDPOINTS.ACTIVE_TEAMS + org_uuid,
            method: "GET",
        };
        return padlocktransport(config);
    },

    activeTeamObserver: (org_uuid) => {
        let config = {
            url: API_ENDPOINTS.ACTIVE_TEAMS + org_uuid + "?observer=true",
            method: "GET",
        };
        return padlocktransport(config);
    },

    InactiveTeams: (org_uuid) => {
        let config = {
            url: API_ENDPOINTS.INACTIVE_TEAMS + org_uuid,
            method: "GET",
        };
        return padlocktransport(config);
    },

    deleteTeamfrompadlock: (org_uuid, team_uuid) => {
        let config = {
            url: API_ENDPOINTS.DELETE_TEAM_PADLOCK + "?org=" + org_uuid + "&teamid=" + team_uuid,
            method: "DELETE",
        };
        return padlocktransport(config);
    },

    deleteteammember: (team_uuid, user_uuid) => {
        let config = {
            url: API_ENDPOINTS.DELETE_TEAM_MEMBER + team_uuid + "/" + user_uuid,
            method: "DELETE",
        };
        return padlocktransport(config);
    },
    deleteteammembers: (teamid, userids) => {
        let config = {
            url: API_ENDPOINTS.DELETE_TEAM_MEMBERS,
            method: "DELETE",
            data: { uu_id: teamid, user_uuid: userids },
        };
        return transport(config);
    },
    deleteteammembersPadlock: (teamid, userids) => {
        let config = {
            url: API_ENDPOINTS.DELETE_PADLOCK_TEAM_MEMBERS,
            method: "DELETE",
            data: { userid: userids, teamid },
        };
        return padlocktransport(config);
    },

    deleteTeam: (org_uuid, team_uuid) => {
        let config = {
            url: API_ENDPOINTS.DELETE_TEAM,
            method: "DELETE",
            data: {
                uu_id: team_uuid,
            },
            withCredentials: true,
        };
        return transport(config);
    },

    reactivateTeam: (org_uuid, team_uuid) => {
        let config = {
            url: API_ENDPOINTS.REACTIVATE_TEAM,
            method: "POST",
            data: JSON.stringify({
                org: org_uuid,
                teamid: team_uuid,
            }),
        };
        return padlocktransport(config);
    },

    deleteUserfrompadlock: (useremail, orgemail) => {
        let config = {
            url: API_ENDPOINTS.DELETE_USER_PADLOCK,
            method: "POST",
            data: {
                email: useremail,
                admin: orgemail,
            },
        };
        return padlocktransport(config);
    },
    deleteUser: (userloginas) => {
        let config = {
            url: API_ENDPOINTS.DELETE_USER,
            method: "PUT",
            data: {
                uu_id: userloginas,
            },
        };
        return transport(config);
    },

    reactivateuserfrompadlock: (useremail, orgemail) => {
        console.log(useremail, orgemail);
        let config = {
            url: API_ENDPOINTS.REACTIVATE_USER_PADLOCK,
            method: "POST",
            data: {
                email: useremail,
                emailid: orgemail,
            },
        };
        return padlocktransport(config);
    },
    reactivateUser: (useremail) => {
        let config = {
            url: API_ENDPOINTS.REACTIVATE_USER,
            method: "POST",
            data: {
                email: useremail,
            },
        };
        return transport(config);
    },

    delteWorkstation: (id) => {
        let config = {
            url: API_ENDPOINTS.DELETE_WORKSTATION + id,
            method: "DELETE",
        };
        return padlocktransport(config);
    },

    deleteClient: (id, status) => {
        let config = {
            url: API_ENDPOINTS.DELETE_CLIENT,
            method: "DELETE",
            data: { id, status },
            withCredentials: true,
        };
        return transport(config);
    },

    getEmployeePhoneNumber: (user_uuid) => {
        let config = {
            url: API_ENDPOINTS.GET_EMPLOYEE_PHONE_NUMBER + user_uuid,
            method: "GET",
        };
        return padlocktransport(config);
    },

    editEmployeePhoneNumber: (user_uuid, data) => {
        let config = {
            url: API_ENDPOINTS.EDIT_EMPLOYEE_PHONE_NUMBER + `uu_id=${user_uuid}&phone=${data}`,
            method: "PUT",
        };
        return padlocktransport(config);
    },

    editEmployeeBasicInfo: (data) => {
        let config = {
            url: API_ENDPOINTS.EDIT_EMPLOYEE_BASIC_INFO,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    getUserBasedProjects: (username) => {
        let config = {
            url: API_ENDPOINTS.GET_USERBASED_PROJECTS + username + "/",
            method: "GET",
        };
        return transport(config);
    },

    removeUserbasedProject: (data) => {
        let config = {
            url: API_ENDPOINTS.REMOVE_USERBASED_PROJECTS,
            method: "DELETE",
            data: data,
        };
        return transport(config);
    },

    addUserbasedProject: (data) => {
        let config = {
            url: API_ENDPOINTS.ADD_USERBASED_PROJECTS,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    createWorkstationWorkahub: (data) => {
        let config = {
            url: API_ENDPOINTS.CREATE_WORKSTATION_JOINWORKAHUB,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    createClientWorkahub: (data) => {
        let config = {
            url: API_ENDPOINTS.CREATE_CLIENT_JOINWORKAHUB,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    getObserverList: () => {
        let config = {
            url: API_ENDPOINTS.GET_OBSERVERS_LIST,
            method: "GET",
        };
        return padlocktransport(config);
    },

    getManagerList: () => {
        let config = {
            url: API_ENDPOINTS.GET_MANAGERS_LIST,
            method: "GET",
        };
        return padlocktransport(config);
    },

    //added for client_profile_info
    getClientProfileInfo: (id) => {
        let config = {
            url: API_ENDPOINTS.GET_CLIENT_PROFILE_INFO + id,
            method: "GET",
        };
        return transport(config);
    },

    getPayrollData: (user_id) => {
        let config = {
            url: API_ENDPOINTS.PAYROLL + "?user_id=" + user_id,
            method: "GET",
        };
        return transport(config);
    },
    postPayrollData: (data) => {
        let config = {
            url: API_ENDPOINTS.PAYROLL,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    updatePayrollData: (data) => {
        let config = {
            url: API_ENDPOINTS.PAYROLL,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    getSalaryDaysData: (params) => {
        let config = {
            url: API_ENDPOINTS.SALARY_DAYS + params,
            method: "GET",
        };
        return transport(config);
    },
    getSalaryDayDownload: (params) => {
        let config = {
            url: API_ENDPOINTS.SALARY_DAY_DOWNLOAD + params,
            method: "GET",
            responseType: "blob",
        };
        return transport(config);
    },
    getSalaryYearData: (params) => {
        let config = {
            url: API_ENDPOINTS.SALARY_YEAR + params,
            method: "GET",
        };
        return transport(config);
    },
    getSalaryHistory: (user_id) => {
        let config = {
            url: API_ENDPOINTS.SALARY_HISTORY + user_id,
            method: "GET",
        };
        return transport(config);
    },
    getClientOverview: (date, user_id, month, year) => {
        let config = {
            url:
                API_ENDPOINTS.CLIENT_DATA_OVERVIEW +
                user_id +
                "&date=" +
                date +
                "&months=" +
                month +
                "&year=" +
                year,
            method: "GET",
        };
        return transport(config);
    },
    getClientTeam: (date, user_id) => {
        let config = {
            url: API_ENDPOINTS.CLIENT_DATA_TEAM + date + "&id=" + user_id,
            method: "GET",
        };
        return transport(config);
    },
    getClientBillingHistory: (user_id, year) => {
        let config = {
            url: API_ENDPOINTS.CLIENT_BILLING_HISTORY + user_id + "&year=" + year,
            method: "GET",
        };
        return transport(config);
    },
    getClientBillingInvoice: (user_id, year, month) => {
        let config = {
            url: API_ENDPOINTS.CLIENT_INVOICE + user_id + "&month=" + month + "&year=" + year,
            method: "GET",
        };
        return transport(config);
    },
    getClientTeamDetailed: (user_id, team_name, date) => {
        let config = {
            url: API_ENDPOINTS.CLIENT_TEAM_DETAILED,
            method: "POST",
            data: {
                id: user_id,
                name: team_name,
                date: date,
            },
        };
        return transport(config);
    },
    getClientBillingData: (year, month) => {
        let config = {
            url: API_ENDPOINTS.CLIENT_BILLING_DATA + year + "&month=" + month,
            method: "GET",
        };
        return transport(config);
    },
    downloadInvoice: (data) => {
        let config = {
            url: API_ENDPOINTS.DOWNLOAD_INVOICE,
            method: "POST",
            data: data,
            responseType: "blob",
        };
        return padlocktransport(config);
    },
    sendInvoiceEmail: (data) => {
        let config = {
            url: API_ENDPOINTS.EMAIL_INVOICE,
            method: "POST",
            data: data,
        };
        return padlocktransport(config);
    },
};
