import React, { Component } from "react";
import { connect } from "react-redux";
import { ChevronDownIcon, CalendarIcon } from "@heroicons/react/solid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import SelectField from "../../../Components/SelectField";
import { withTranslation } from "react-i18next";
import { fetchactiveprojects } from "../../../services/projects/actions";
import { BeatLoader } from "react-spinners";

class PayrollReportTab extends Component {
    constructor() {
        super();
        this.state = {
            selectedProject: "",
            selectedDate: new Date(),
        };
    }
    componentDidMount() {
        this.props.fetchactiveprojects();
    }
    onSelectProject = (e) => {
        this.setState({
            selectedProject: e.target.value,
        });
    };
    render() {
        const { t } = this.props;
        let activeprojects =
            this.props.projects &&
            this.props.projects.ActiveProjects &&
            this.props.projects.ActiveProjects.response_data;

        let ProjectData =
            activeprojects &&
            activeprojects.map((el) => ({
                name: el.project_name,
                id: el.project_name,
            }));

        let activityData = [1, 2, 3];
        return (
            <div>
                <div className="bg-white mt-4 px-2 h-full py-2 border-b border-gray-200 sm:px-6 rounded-lg flex justify-between items-center">
                    <div className="my-3 mr-3 lg:my-0 w-52 max-w-xs flex-1">
                        <SelectField
                            FieldName={`${t("select")} ${t("project")}`}
                            onSelectChange={(e) => this.onSelectProject(e)}
                            name={"Select Project"}
                            arrayOfData={ProjectData}
                            id={"name"}></SelectField>
                    </div>
                    <div className="relative min-w-max">
                        <div className="absolute right-2 top-2 z-10">
                            <ChevronDownIcon color="#6B7280" width={25} height={25} />
                        </div>
                        <div className="absolute -top-2 left-2 px-1 py-0.5 bg-[#ffffff] text-xs z-[1]">
                            Select Month
                        </div>
                        <DatePicker
                            selected={this.state.selectedDate}
                            className="rounded-md min-w-max w-56"
                            dateFormat="MMM yyyy"
                            onChange={(date) => this.setState({ selectedDate: date })}
                            showMonthYearPicker
                        />
                    </div>
                </div>
                <div className="bg-white mt-4 px-2 h-full py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                    <div className="mt-4 flex justify-between items-start">
                        <div>
                            <div className="text-2xl">Team Workahub</div>
                            <div className=" text-[#6B7280]">Workahub Project Title</div>
                        </div>
                        <div className="flex text-[#6B7280] text-tiny">
                            <CalendarIcon className=" w-5 mr-2"></CalendarIcon>
                            <div>{moment(this.state.selectedDate).format("MMM DD, YYYY")}</div>
                        </div>
                    </div>
                    <div className="flex justify-between items-start p-4 pb-6">
                        <div className="pl-2">
                            <div className=" xl:text-xl">
                                For the Month of{" "}
                                {moment(this.state.selectedDate).format("MMMM YYYY")}
                            </div>
                            <div className="text-xl xl:text-3xl text-primary font-semibold">
                                $2322
                            </div>
                        </div>
                        <div className="rounded-md text-[#ffffff] py-1.5 px-4 bg-primary hover:cursor-pointer">
                            Download Invoice
                        </div>
                    </div>
                    <div className="grid grid-cols-5 border border-fiver border-opacity-80 rounded-lg px-4 py-2 2xl:py-4">
                        <div className="py-1 lg:px-4">
                            <div className="text-xs mb-1 2xl:text-tiny opacity-50">
                                Hours Booked
                            </div>
                            <div className="text-xl xl:text-2xl font-semibold">140 hrs</div>
                        </div>
                        <div className="py-1 border-l border-fiver border-opacity-80 px-2 lg:pl-4 lg:pr-4 2xl:pl-8">
                            <div className="text-xs mb-1 2xl:text-tiny opacity-50">
                                Hours Booked
                            </div>
                            <div className="text-xl xl:text-2xl font-semibold">140 hrs</div>
                        </div>
                        <div className="py-1 border-l border-fiver border-opacity-80 px-2 lg:pl-4 lg:pr-4 2xl:pl-8">
                            <div className="text-xs mb-1 2xl:text-tiny opacity-50">
                                Hours Booked
                            </div>
                            <div className="text-xl xl:text-2xl font-semibold">140 hrs</div>
                        </div>
                        <div className="py-1 border-l border-fiver border-opacity-80 px-2 lg:pl-4 lg:pr-4 2xl:pl-8">
                            <div className="text-xs mb-1 2xl:text-tiny opacity-50">
                                Hours Booked
                            </div>
                            <div className="text-xl xl:text-2xl font-semibold">140 hrs</div>
                        </div>
                        <div className="py-1 border-l border-fiver border-opacity-80 px-2 lg:pl-4 lg:pr-4 2xl:pl-8">
                            <div className="text-xs mb-1 2xl:text-tiny opacity-50">
                                Hours Booked
                            </div>
                            <div className="text-xl xl:text-2xl font-semibold">140 hrs</div>
                        </div>
                    </div>
                    <div
                        className="mt-4 flex flex-col overflow-y-auto bg-gray-100"
                        style={{ maxHeight: "auto" }}>
                        <div className="inline-block min-w-full align-middle">
                            <div className="shadow-sm ring-1 ring-black ring-opacity-5 border-0 rounded-xl overflow-x-auto">
                                <table className="min-w-max w-full border-separate rounded-xl">
                                    <thead className="bg-gray-50 min-w-full">
                                        <tr className={`grid grid-cols-6 min-w-full`}>
                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 px-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                Date
                                            </th>
                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell`}>
                                                Member Worked
                                            </th>
                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell`}>
                                                Tasks
                                            </th>
                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell`}>
                                                Bill Rate/hr
                                            </th>
                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell`}>
                                                Time Spend
                                            </th>

                                            <th
                                                scope="col"
                                                className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell`}>
                                                Total Bill
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {activityData && activityData.length > 0 ? (
                                            activityData.map((reportRow, idx) => {
                                                return (
                                                    <tr
                                                        className={`grid grid-cols-6 min-w-full border-b border-gray-300`}
                                                        key={reportRow + idx}>
                                                        <td
                                                            className={` px-3 py-2 text-base sm:table-cell`}>
                                                            Jun 14, 2022
                                                        </td>
                                                        <td
                                                            className={`text-primary px-3 py-2 text-base sm:table-cell`}>
                                                            {reportRow}
                                                        </td>
                                                        <td
                                                            className={` px-3 py-2 text-base sm:table-cell`}>
                                                            {[1, 2, 3].map(() => {
                                                                return <div>Lorem Ipsum</div>;
                                                            })}
                                                        </td>
                                                        <td
                                                            className={` px-3 py-2 text-base sm:table-cell`}>
                                                            $12
                                                        </td>
                                                        <td
                                                            className={` px-3 py-2 text-base sm:table-cell`}>
                                                            5hr:30mins
                                                        </td>

                                                        <td
                                                            className={` px-3 py-2 text-base lg:table-cell`}>
                                                            $66
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr className="min-w-full flex justify-center">
                                                <td className="text-center flex-1">
                                                    <BeatLoader />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    projects: state.projects,
    hourReport: state.report,
});

const mapDispatchToProps = (dispatch) => ({
    fetchactiveprojects: () => dispatch(fetchactiveprojects()),
});
export default withTranslation("common")(
    connect(mapStateToProps, mapDispatchToProps)(PayrollReportTab)
);
