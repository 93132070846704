import { Component } from "react";
import { Dialog } from "@headlessui/react";
import { BsSearch } from "react-icons/bs";



class SortByEmail extends Component {
    state = { searchInput: "" }


    getSearchResults = () => {
        const { searchInput } = this.state
        const { activeUsers } = this.props
        let searchResults
        if (searchInput) {
            searchResults = activeUsers.filter(eachUser => eachUser.user_email.toLowerCase().includes(searchInput.toLowerCase()))
            return (<> <div className="flex flex-col justify-start">
                <ul className="flex flex-col mt-[13.5px]">
                    {searchResults.map(eachResult => (
                        <li className="flex items-center justify-start mb-[10px]">
                            <input type="radio" onClcik={this.handleClick} id={eachResult.id} className="text-[#684D94] focus:ring-0 focus:ring-offset-0" />
                            <label htmlFor={eachResult.id} className="text-black font-medium text-[16px] font-inter ml-[8px]">{eachResult.user_email}</label>
                        </li>
                    ))}
                </ul>
            </div></>)
        } else {
            return null
        }

    }


    onChangeSearch = (event) => {
        const { searchInput } = this.state
        this.setState({
            searchInput: event.target.value
        })
        console.log(searchInput)
    }


    render() {
        const { isSortOpenEmail, sortEmailStatus } = this.props
        return (
            <Dialog
                open={isSortOpenEmail ?? false}
                onClose={() => {
                    sortEmailStatus();
                }}
                className="min-w-full">
                <Dialog.Panel className="fixed inset-0 w-3/12 z-10 flex justify-center mt-[280px] ml-[40vw] mr-auto">
                    <div className="bg-white p-4 min-w-full absolute mt-4 rounded-md shadow-lg">
                        <div className="flex flex-row justify-between">
                            <Dialog.Title>
                                <h1 className="text-black font-medium text-[16px] font-inter">
                                    Sort by
                                </h1>
                            </Dialog.Title>
                            <button className="text-[#1055EB] underline font-medium text-[12px] font-inter">
                                Reset
                            </button>
                        </div>
                        <div className="mt-[16px] mb-[16px] flex flex-row items-center bg-transparent border-solid px-[9px] border-2 border-[#D1D5DB] rounded">
                            <BsSearch className="" />
                            <input
                                type="text"
                                placeholder="Search by email"
                                className="h-8 border-transparent focus outline-none border"
                                onChange={this.onChangeSearch}
                            />
                        </div>

                        <Dialog.Description>{this.getSearchResults()}</Dialog.Description>
                    </div>
                </Dialog.Panel>
            </Dialog>
        );
    }


}
export default SortByEmail