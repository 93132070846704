import React, { Component } from "react";
import { connect } from "react-redux";
import Layout from "../../../Components/Layout";
import { InformationCircleIcon, TrashIcon, CalendarIcon } from "@heroicons/react/solid";
import InputField from "../../../Components/InputField";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import "./style.css";
// import moment from "moment/moment";
import empProfileIcon from "../../../images/empProfileIcon.png";
import SelectField from "../../../Components/SelectField";
import { AccountTreeOutlined } from "@material-ui/icons";
import PayrollUpdated from "../EmployeePayroll/PayrollUpdatedModal";

import {
    getEmployeePhoneNumber,
    editEmployeePhoneNumber,
    editEmployeeBasicInfo,
    getUserBasedProjects,
    removeUserbasedProject,
    addUserbasedProject,
    getEmployeePayrollData,
    updateEmployeePayrollData,
    getManagerList,
    postEmployeePayrollData,
} from "../../../services/teams/actions";

import { getUserProject } from "../../../services/employee/actions";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { withTranslation } from "react-i18next";
import { CurrencyDollarIcon } from "@heroicons/react/outline";
import ConfirmationDialogue from "../../../Components/ConfirmationDialogue";
import { getUserData, editUserDetails } from "../../../services/userProfile/actions";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const userRole = localStorage.getItem("ro");

class EmployeeProfile extends Component {
    constructor() {
        super();
        this.state = {
            SelectedTab: "basic_info",
            selectedDate: "",
            selectedTimezone: "",
            userDetails: {
                employee: false,
                admin: false,
                manager: false,
                observer: false,
                first_name: "",
                last_name: "",
                user_login_as: "",
                currency: "",
                email: "",
                designation: "",
                location: "",
                over_ride_hours: 0,
                user_id: "",
                user_email: "",
                user_first_name: "",
                user_last_name: "",
                fullname: "",
            },
            userRole: {
                is_Admin: false,
                is_Manager: false,
                is_Employee: false,
                is_Observer: false,
            },
            userPhoneNumber: "",
            userBasedProjects: [],
            selectedProject: "",
            userId: "",
            allProjectList: [],
            showDeleteProjectModal: false,
            payrollData: { type_payment: "yearly" },
            payrollDataExist: true,
            showPayrollUpdatedModal: false,
            payrollDataTitl: "Pay rate updated",
            managerList: [],
            editEvent: false,
            payrollDataMsg: "Pay rate will change to ₹12,00,000 per year on February 12, 2023.",
        };
    }

    componentDidMount() {
        const user = localStorage.getItem("data");
        const userDetails = JSON.parse(user);
        this.setState({ userId: JSON.parse(user).user_id });
        setTimeout(() => {
            this.setState({
                userRole: {
                    is_Admin: userDetails.data? userDetails.data[0].is_Admin: userDetails.admin,
                    is_Manager: userDetails.data? userDetails.data[0].is_Manager: userDetails.manager,
                    is_Employee: userDetails.data? userDetails.data[0].is_Employee: userDetails.employee,
                    is_Observer: userDetails.data? userDetails.data[0].is_Observer: userDetails.observer,
                },
                selectedTimezone: userDetails?.timezone ?? "",
            });
            this.getEmpContact(userDetails["user_id"]);

            this.props.getUserData("?user=" + userDetails["user_id"]).then((res) => {
                if (res.value.status === 200) {
                    userDetails["fullname"] =
                        userDetails.user_first_name + " " + userDetails.user_last_name;
                }
                this.setState({ userDetails: userDetails }, () => {
                    this.getUserBasedProjects();
                });
            });
        }, 200);
        if (this.state.SelectedTab === "projects") {
            this.getUserBasedProjects();
        }

        this.props.getUserProject().then((res) => {
            if (res.value.status === 200) {
                this.setState({
                    allProjectList: res.value.data,
                });
            }
        });
        this.props.getEmployeePayrollData(userDetails.user_id).then((res) => {
            if (res.value.status === 200 && res.value.data.data !== "no data found") {
                const data = res.value.data.data;
                if (data["effective_date"]) {
                    const dateData = data["effective_date"].split("-");
                    data["effective_date"] = new Date(
                        parseInt(dateData[0]),
                        parseInt(dateData[1]) - 1,
                        parseInt(dateData[2])
                    );
                }
                this.setState({
                    payrollData: data,
                });
            } else {
                this.setState({
                    payrollDataExist: false,
                });
            }
        });
        this.props.getManagerList().then((res) => {
            this.setState({ managerList: res.value.data.users });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.SelectedTab !== this.state.SelectedTab &&
            this.state.SelectedTab === "projects"
        ) {
            this.getUserBasedProjects();
        }
    }

    getEmpContact = (user_id) => {
        this.props.getEmployeePhoneNumber(!user_id ? "" : user_id).then((res) => {
            if (res.value.status === 200) {
                this.setState({
                    userPhoneNumber: "+" + res.value.data.phone_number,
                });
            }
        });
    };

    getUserBasedProjects = () => {
        const { userDetails } = this.state;
        this.props.getUserBasedProjects(userDetails.user_login_as).then((res) => {
            if (res.value.status === 200 || res.value.status === 201) {
                this.setState({
                    userBasedProjects: res.value.data,
                });
            } else {
                toast.error("Failed to fetch user based projects");
            }
        });
    };

    HandleSelectTab = (e) => {
        this.setState({ SelectedTab: e });
    };

    HandleMobileSelectTab = (e) => {
        this.setState({ SelectedTab: e.target.value });
    };

    render() {
        const { t } = this.props;
        let Select_project_dropdown =
            !this.state.allProjectList || !this.state.allProjectList.projects
                ? ""
                : this.state.allProjectList.projects.map((el) => ({
                      name: el.project_name,
                      // id: el.user_id
                  }));
        const payInterval = [
            { name: "Year", id: "yearly" },
            { name: "Day", id: "daily" },
            { name: "Hour", id: "hours" },
        ];
        const onRoleChange = (type) => {
            switch (type) {
                case "Regular":
                    this.setState({
                        userRole: {
                            is_Admin: false,
                            is_Manager: false,
                            is_Employee: true,
                            is_Observer: false,
                        },
                    });
                    break;

                case "Admin":
                    this.setState({
                        userRole: {
                            is_Admin: true,
                            is_Manager: false,
                            is_Employee: false,
                            is_Observer: false,
                        },
                    });
                    break;

                case "Manager":
                    this.setState({
                        userRole: {
                            is_Admin: false,
                            is_Manager: true,
                            is_Employee: false,
                            is_Observer: false,
                        },
                    });
                    break;

                case "Observer":
                    this.setState({
                        userRole: {
                            is_Admin: false,
                            is_Manager: false,
                            is_Employee: false,
                            is_Observer: true,
                        },
                    });
                    break;

                default:
                    break;
            }
        };

        const handleBasicInfoSubmit = () => {
            if (userRole === "Observer") {
                return;
            }
            if (!this.state.userDetails["fullname"]) {
                toast.error("Please enter name of user");
                return;
            }
            const firstName = this.state.userDetails["fullname"].split(" ").at(0);
            const lastName =
                this.state.userDetails["fullname"].split(" ").length > 1
                    ? this.state.userDetails["fullname"].split(" ").at(-1)
                    : "";
            const dataa = new FormData();
            dataa.append("user", this.state.userDetails["user_id"]);
            dataa.append("location", this.state.userDetails["location"]);
            dataa.append("designation", this.state.userDetails["designation"]);
            dataa.append("employee", this.state.userRole.is_Employee ? "True" : "False");
            dataa.append("admin", this.state.userRole.is_Admin ? "True" : "False");
            dataa.append("observer", this.state.userRole.is_Observer ? "True" : "False");
            dataa.append("manager", this.state.userRole.is_Manager ? "True" : "False");
            dataa.append("username", this.state.userDetails["user_login_as"]);
            dataa.append("first_name", firstName);
            dataa.append("last_name", lastName);
            dataa.append("time_zone", this.state.selectedTimezone);
            dataa.append("overworkhours", this.state.userDetails["over_ride_hours"]);
            dataa.append("currency", this.state.userDetails["currency"]);
            // const dataa = {
            //     manager: this.state.userRole.is_Manager,
            //     member: this.state.userRole.is_Employee,
            //     observer: this.state.userRole.is_Observer,
            //     owner: this.state.userRole.is_Admin,
            //     timezone: this.state.selectedTimezone,
            //     uu_id: this.state.userDetails.user_id,
            // };

            if (!this.state.userPhoneNumber) {
                toast.error("Please enter phone number");
                return;
            } else if (!isValidPhoneNumber(this.state.userPhoneNumber)) {
                toast.error("Please enter a valid phone number");
                return;
            } else {
                this.props
                    .editEmployeePhoneNumber(
                        this.state.userDetails.user_id,
                        this.state.userPhoneNumber
                    )
                    .then((res) => {
                        if (res.value.status === 200) {
                            // toast.success("Phone number updated successfully");
                        } else {
                            toast.error("Phone number not updated, Try Again");
                        }
                    });
                this.props.editUserDetails(dataa).then((res) => {
                    if (res.value.status === 200) {
                        if (this.state.editEvent) {
                            toast.success("Basic info updated successfully");
                        }
                        const userDetails = { ...this.state.userDetails };
                        userDetails.user_first_name = firstName;
                        userDetails.user_last_name = lastName;
                        const data = { ...this.state.userRole };
                        data["timezone"] = this.state.selectedTimezone;
                        userDetails["data"] = [data];
                        localStorage.setItem("data", JSON.stringify(userDetails));
                        this.setState({ userDetails: userDetails });
                    } else {
                        toast.error("Something went wrong");
                    }
                });
                // this.props.editEmployeeBasicInfo(dataa).then((res) => {
                //     if (res.value.status === 200) {
                //         toast.success("Basic info updated successfully");
                //     } else {
                //         toast.error("Something went wrong");
                //     }
                // });
            }
        };
        const showDeleteProjectModal = (project) => {
            this.setState({
                showDeleteProjectModal: true,
                selectedProject: project,
            });
        };
        const handleDeleteProject = () => {
            if (userRole === "Observer") {
                return;
            }
            const dataa = {
                projectName: this.state.selectedProject,
                userName: this.state.userDetails.user_login_as,
            };

            this.props.removeUserbasedProject(dataa).then((res) => {
                if (res.value.status === 200) {
                    toast.success("Project removed successfully");
                    this.setState({
                        showDeleteProjectModal: false,
                        selectedProject: "",
                    });
                    this.getUserBasedProjects();
                } else {
                    toast.error("Something went wrong");
                }
            });
        };

        const add_user_to_project = () => {
            if (userRole === "Observer") {
                return;
            }
            const dataa = {
                projectName: this.state.selectedProject,
                userName: this.state.userDetails.user_login_as,
            };

            this.props.addUserbasedProject(dataa).then((res) => {
                if (res.value.status === 201) {
                    toast.success("Project added successfully");
                    this.setState({ selectedProject: "" });
                    this.getUserBasedProjects();
                } else {
                    toast.error("Something went wrong");
                }
            });
        };
        return (
            <Layout>
                <div className="">
                    <Link
                        to={`/profile/skills/?user=${this.state.userId}`}
                        className="rounded-md shadow-md border max-w-max bg-white border-fiver border-opacity-60 py-2 px-4 cursor-pointer">
                        Back
                    </Link>
                    {/* <div className='flex justify-start items-center'><button className='bg-primary text-white rounded-md p-1 px-4' onClick={() => {this.props.history.goBack()}}>Back</button></div> */}
                    <div className="bg-white p-6 rounded-md shadow-md mt-3 text-xl">
                        <nav className="rounded-md w-full">
                            <ol className="list-reset flex">
                                <li>
                                    <Link
                                        to="/team/users"
                                        className="text-primary hover:text-primary">
                                        {t("TeamTab.users")}
                                    </Link>
                                </li>
                                <li>
                                    <span className="text-textprimary mx-2">/</span>
                                </li>
                                <li className="text-textprimary">Manage {t("employee")}</li>
                            </ol>
                        </nav>
                        {/* <h1 className='text-xl font-semibold'>Manage Employee</h1> */}
                    </div>
                    <div className="bg-white p-6 rounded-md shadow-md mt-4 flex items-center">
                        <div>
                            <img
                                src={empProfileIcon}
                                alt="empProfileIcon"
                                className="w-12 h-14 mr-4"
                            />
                        </div>
                        <div>
                            <p className="text-md font-semibold">
                                {!this.state.userDetails
                                    ? ""
                                    : `${this.state.userDetails.user_first_name} ${this.state.userDetails.user_last_name}`}
                            </p>
                            <p className="text-gray-600">
                                {!this.state.userDetails ? "" : this.state.userDetails.user_email}
                            </p>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="bg-white rounded-md shadow-md">
                            <div className=" px-2 py-1 sm:px-6 rounded-lg">
                                <div className="sm:hidden">
                                    <label htmlFor="tabs" className="sr-only">
                                        Select a tab
                                    </label>
                                    <select
                                        id="tabs"
                                        name="tabs"
                                        onChange={(e) => this.HandleMobileSelectTab(e)}
                                        className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                                        defaultValue={this.state.SelectedTab}>
                                        <option key="basic_info" value="basic_info">
                                            {t("settingsTab.basic")}-Info
                                        </option>
                                        <option key="projects" value="projects">
                                            {t("projects")}
                                        </option>
                                        <option key="payroll" value="payroll">
                                            Payroll
                                        </option>
                                    </select>
                                </div>
                                <div className="hidden sm:block">
                                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                        <div
                                            key="basic_info"
                                            onClick={() => this.HandleSelectTab("basic_info")}
                                            className={classNames(
                                                this.state.SelectedTab === "basic_info"
                                                    ? "border-primary text-primary"
                                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                                "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                            )}
                                            aria-current={
                                                this.state.SelectedTab === "basic_info"
                                                    ? "page"
                                                    : undefined
                                            }>
                                            <InformationCircleIcon
                                                className={classNames(
                                                    this.state.SelectedTab === "basic_info"
                                                        ? "text-primary"
                                                        : "text-gray-400 group-hover:text-gray-500",
                                                    "-ml-0.5 mr-2 h-5 w-5"
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span>{t("settingsTab.basic")}-Info</span>
                                        </div>

                                        <div
                                            key="projects"
                                            onClick={() => this.HandleSelectTab("projects")}
                                            className={classNames(
                                                this.state.SelectedTab === "projects"
                                                    ? "border-primary text-primary"
                                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                                "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                            )}
                                            aria-current={
                                                this.state.SelectedTab === "projects"
                                                    ? "page"
                                                    : undefined
                                            }>
                                            <AccountTreeOutlined
                                                className={classNames(
                                                    this.state.SelectedTab === "projects"
                                                        ? "text-primary"
                                                        : "text-gray-400 group-hover:text-gray-500",
                                                    "-ml-0.5 mr-2 h-5 w-5"
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span>{t("projects")}</span>
                                        </div>
                                        <div
                                            key="payroll"
                                            onClick={() => this.HandleSelectTab("payroll")}
                                            className={classNames(
                                                this.state.SelectedTab === "payroll"
                                                    ? "border-primary text-primary"
                                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                                "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                            )}
                                            aria-current={
                                                this.state.SelectedTab === "payroll"
                                                    ? "page"
                                                    : undefined
                                            }>
                                            <CurrencyDollarIcon
                                                className={classNames(
                                                    this.state.SelectedTab === "payroll"
                                                        ? "text-primary"
                                                        : "text-gray-400 group-hover:text-gray-500",
                                                    "-ml-0.5 mr-2 h-5 w-5"
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span>Payroll</span>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            {this.state.SelectedTab === "basic_info" ? (
                                <div className="mt-4 p-4 w-full">
                                    <div className="lg:grid lg:grid-cols-5 lg:gap-y-4 lg:gap-x-8">
                                        <div className="col-span-2">
                                            <div className="w-full mt-4">
                                                <InputField
                                                    value={
                                                        !this.state.userDetails
                                                            ? ""
                                                            : this.state.userDetails.fullname
                                                    }
                                                    FieldName={`Full ${t("name")}`}
                                                    onInputChange={(ev) => {
                                                        const details = {
                                                            ...this.state.userDetails,
                                                        };
                                                        details["fullname"] = ev.target.value;
                                                        this.setState({ userDetails: details });
                                                        this.setState({ editEvent: true });
                                                    }}
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="w-full mt-4">
                                                <InputField
                                                    value={
                                                        !this.state.userDetails
                                                            ? ""
                                                            : this.state.userDetails.user_login_as
                                                    }
                                                    FieldName={`${t("user")} ${t("name")}`}
                                                    onInputChange={(ev) => {
                                                        const details = {
                                                            ...this.state.userDetails,
                                                        };
                                                        details.user_login_as = ev.target.value;
                                                        this.setState({ userDetails: details });
                                                        this.setState({ editEvent: true });
                                                    }}
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="w-full mt-4">
                                                <InputField
                                                    value={
                                                        !this.state.userDetails
                                                            ? ""
                                                            : this.state.userDetails.designation
                                                    }
                                                    onInputChange={(ev) => {
                                                        const details = {
                                                            ...this.state.userDetails,
                                                        };
                                                        details.designation = ev.target.value;
                                                        this.setState({ userDetails: details });
                                                        this.setState({ editEvent: true });
                                                    }}
                                                    FieldName="Designation"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="w-full mt-4">
                                                <InputField
                                                    value={
                                                        !this.state.userDetails
                                                            ? ""
                                                            : this.state.userDetails.location
                                                    }
                                                    onInputChange={(ev) => {
                                                        const details = {
                                                            ...this.state.userDetails,
                                                        };
                                                        details.location = ev.target.value;
                                                        this.setState({ userDetails: details });
                                                        this.setState({ editEvent: true });
                                                    }}
                                                    FieldName="Location"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="w-full mt-4">
                                                <InputField
                                                    value={
                                                        !this.state.userDetails
                                                            ? ""
                                                            : this.state.userDetails.user_email
                                                    }
                                                    disabled
                                                    onInputChange={() => {}}
                                                    FieldName={`${t("email")} Address`}
                                                    type="text"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-span-2">
                                            <div className="w-full mt-4 border border-gray-300 focus-within:border-gray-500 relative px-3 py-1 rounded-md">
                                                <div className="absolute -top-2 left-3 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                                    Phone Number
                                                </div>
                                                <PhoneInput
                                                    flags={flags}
                                                    displayInitialValueAsLocalNumber={true}
                                                    defaultCountry="IN"
                                                    onChange={(val) =>
                                                        this.setState({
                                                            userPhoneNumber:
                                                                formatPhoneNumberIntl(val),
                                                        })
                                                    }
                                                    value={this.state.userPhoneNumber}
                                                    placeholder={
                                                        !this.state.userPhoneNumber
                                                            ? "No Contact found"
                                                            : ""
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="w-full mt-4 relative">
                                                <div className="absolute -top-2 left-3 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                                    Currency
                                                </div>
                                                <SelectField
                                                    onSelectChange={(ev) => {
                                                        const details = {
                                                            ...this.state.userDetails,
                                                        };
                                                        details["currency"] = ev.target.value;
                                                        this.setState({ userDetails: details });
                                                        this.setState({ editEvent: true });
                                                    }}
                                                    name="currency"
                                                    hideDefault={true}
                                                    arrayOfData={[
                                                        { name: "INR", id: "inr" },
                                                        { name: "USD", id: "usd" },
                                                    ]}
                                                    id="currency"
                                                    value={
                                                        this.state.userDetails.currency
                                                    }></SelectField>
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="w-full mt-4">
                                                <TimezoneSelect
                                                    placeholder="Select Timezone"
                                                    styles={{
                                                        borderRadius: "5px",
                                                        outline: "none",
                                                        border: "none",
                                                    }}
                                                    labelStyle={{
                                                        color: "pink",
                                                        borderRadius: "20px",
                                                    }}
                                                    value={this.state.selectedTimezone}
                                                    timezones={{ ...allTimezones }}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            selectedTimezone: e.value,
                                                        });
                                                        this.setState({ editEvent: true });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="w-full mt-4 relative">
                                                <div className="w-full mx-auto relative flex justify-between border border-gray-300 rounded-md px-3 py-2 shadow-sm  focus-within:border-primary">
                                                    <label
                                                        htmlFor="hour"
                                                        className="absolute -top-2 left-3 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900 leading-4">
                                                        Override work hours per month
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className={
                                                            "block remove-arrow w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm "
                                                        }
                                                        placeholder="Enter over ride work hours"
                                                        id="hour"
                                                        min="0"
                                                        value={
                                                            !this.state.userDetails
                                                                ? ""
                                                                : this.state.userDetails
                                                                      .over_ride_hours
                                                        }
                                                        onChange={(e) => {
                                                            const details = {
                                                                ...this.state.userDetails,
                                                            };
                                                            details.over_ride_hours =
                                                                e.target.value;
                                                            this.setState({ userDetails: details });
                                                            this.setState({ editEvent: true });
                                                        }}
                                                    />
                                                    <div className="self-stretch justify-center items-center inline-flex">
                                                        <p className="text-[#6B7280] text-sm font-medium leading-4">
                                                            hrs
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full text-[#666] text-tiny font-medium leading-normal">
                                                Note: This working hours will be override only for
                                                this user
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="w-full mt-4 relative"></div>
                                        </div>

                                        {/*                     
                    <div className="col-span-2">
                      <div className="w-full mt-4 relative">
                        <div className="absolute -top-2 left-3 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                        Reporting Manager
                        </div>
                        <SelectField
                          onSelectChange={(ev) => {
                            const details = {
                              ...this.state.userDetails,
                            };
                            details["currency"] = ev.target.value;
                            this.setState({ userDetails: details });
                          }}
                          placeholder="select manager"
                          name="currency"
                          hideDefault={true}
                          arrayOfData={[
                            { name: "Raj Kumar", id: "Raj Kumar" },
                            { name: "Gaurav Kumar", id: "Gaurav Kumar" },
                            { name: "Saumya Garg", id: "Saumya Garg" },
                          ]}
                          id="currency"
                          value={this.state.userDetails.currency}
                        ></SelectField>
                      </div>
                    </div> */}

                                        <div className="col-span-2">
                                            <div className="flex justify-start items-start sm:items-center mt-4 flex-col sm:flex-row">
                                                <p className="text-lg font-semibold mr-4">
                                                    {t("settingsTab.role")}:
                                                </p>
                                                <div className="flex items-center mr-4">
                                                    <input
                                                        type="checkbox"
                                                        checked={
                                                            !this.state.userRole
                                                                ? ""
                                                                : this.state.userRole.is_Employee
                                                        }
                                                        className="text-primary mr-1 lg:mr-3 rounded-full"
                                                        onChange={() => {
                                                            onRoleChange("Regular");
                                                            this.setState({ editEvent: true });
                                                        }}
                                                    />
                                                    <p>Regular</p>
                                                </div>
                                                <div className="flex items-center mr-4">
                                                    <input
                                                        type="checkbox"
                                                        checked={
                                                            !this.state.userRole
                                                                ? ""
                                                                : this.state.userRole.is_Manager
                                                        }
                                                        className="text-primary mr-1 lg:mr-3 rounded-full"
                                                        onChange={() => {
                                                            onRoleChange("Manager");
                                                            this.setState({ editEvent: true });
                                                        }}
                                                    />
                                                    <p>{t("TeamTab.manager")}</p>
                                                </div>
                                                <div className="flex items-center mr-4">
                                                    <input
                                                        type="checkbox"
                                                        checked={
                                                            !this.state.userRole
                                                                ? ""
                                                                : this.state.userRole.is_Admin
                                                        }
                                                        className="text-primary mr-1 lg:mr-3 rounded-full"
                                                        onChange={() => {
                                                            onRoleChange("Admin");
                                                            this.setState({ editEvent: true });
                                                        }}
                                                    />
                                                    <p>Admin</p>
                                                </div>
                                                <div className="flex items-center mr-4">
                                                    <input
                                                        type="checkbox"
                                                        checked={
                                                            !this.state.userRole
                                                                ? ""
                                                                : this.state.userRole.is_Observer
                                                        }
                                                        className="text-primary mr-1 lg:mr-3 rounded-full"
                                                        onChange={() => {
                                                            onRoleChange("Observer");
                                                            this.setState({ editEvent: true });
                                                        }}
                                                    />
                                                    <p>{t("TeamTab.observers")}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-5">
                                            <div className="flex justify-start items-center mt-4">
                                                <button
                                                    className="bg-primary text-white p-1 px-6 rounded-md shadow-md"
                                                    onClick={() => {
                                                        handleBasicInfoSubmit();
                                                    }}>
                                                    Save Changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {this.state.SelectedTab === "projects" ? (
                                <div className="mt-4 p-4">
                                    <div>
                                        <div className="flex justify-between items-end">
                                            <div className="w-2/5">
                                                <SelectField
                                                    arrayOfData={Select_project_dropdown}
                                                    FieldName="Add Project to user"
                                                    onSelectChange={(e) => {
                                                        this.setState({
                                                            selectedProject: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <button
                                                className="bg-primary text-white rounded-md p-1 px-6"
                                                onClick={() => {
                                                    add_user_to_project();
                                                }}>
                                                ADD
                                            </button>
                                        </div>

                                        <div className="">
                                            <div
                                                className="mt-4 border border-gray-50 rounded-md flex flex-col overflow-y-auto bg-gray-100 "
                                                style={{ maxHeight: "250px" }}>
                                                <div className="inline-block min-w-full align-middle">
                                                    <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                                        <table
                                                            className="min-w-full border-separate"
                                                            style={{
                                                                borderSpacing: 0,
                                                            }}>
                                                            <thead className="bg-gray-50">
                                                                <tr>
                                                                    <th
                                                                        scope="col"
                                                                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                                        {t("project")}
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                                        {t("action")}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white">
                                                                {(this.state.userBasedProjects &&
                                                                    this.state.userBasedProjects
                                                                        .length <= 0) ||
                                                                !this.state.userBasedProjects ? (
                                                                    <tr>
                                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12 border-b border-gray-200">
                                                                            No Data Found
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    this.state.userBasedProjects.map(
                                                                        (project, idx) => (
                                                                            <tr key={idx}>
                                                                                <td
                                                                                    className={classNames(
                                                                                        idx !==
                                                                                            this
                                                                                                .state
                                                                                                .userBasedProjects
                                                                                                .length -
                                                                                                1
                                                                                            ? "border-b border-gray-200"
                                                                                            : "",
                                                                                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                                                                    )}>
                                                                                    {project}
                                                                                </td>
                                                                                <td
                                                                                    className={classNames(
                                                                                        idx !==
                                                                                            this
                                                                                                .state
                                                                                                .userBasedProjects
                                                                                                .length -
                                                                                                1
                                                                                            ? "border-b border-gray-200"
                                                                                            : "",
                                                                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
                                                                                    )}>
                                                                                    <TrashIcon
                                                                                        className="h-5 w-5 text-red-500 cursor-pointer"
                                                                                        onClick={() => {
                                                                                            showDeleteProjectModal(
                                                                                                project
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    )
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {this.state.SelectedTab === "payroll" ? (
                                <div className="mt-4 p-8">
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            const newData = {
                                                type: this.state.payrollData["type_payment"],
                                                account_number:
                                                    this.state.payrollData["bank_account_number"],
                                                ifsc_code: this.state.payrollData["ifsc_code"],
                                                bank_name: this.state.payrollData["bank_name"],
                                                name_account_holder:
                                                    this.state.payrollData["name_account_holder"],
                                                pay_rate: this.state.payrollData["pay_rate"],
                                                monthly_ctc:
                                                    this.state.payrollData["monthly_ctc"] ?? "",
                                                pan_details: this.state.payrollData["pan_details"],
                                                special_allowance:
                                                    this.state.payrollData["special_allowance"] ??
                                                    "",
                                                travel_allowance:
                                                    this.state.payrollData["travel_allowance"] ??
                                                    "",
                                                basic_salary:
                                                    this.state.payrollData["basic_salary"] ?? "",
                                                house_rent:
                                                    this.state.payrollData[
                                                        "house_rent_allowance"
                                                    ] ?? "",
                                                comment: this.state.payrollData["comment"] ?? "",
                                                effective_date:
                                                    this.state.payrollData["effective_date"] ?? "",
                                                user_id: this.state.userDetails.user_id,
                                            };
                                            if (this.state.payrollDataExist) {
                                                this.props
                                                    .updateEmployeePayrollData(newData)
                                                    .then((res) => {
                                                        if (res.value.status !== 200) {
                                                            this.props
                                                                .getEmployeePayrollData(
                                                                    this.state.userDetails.user_id
                                                                )
                                                                .then((res) => {
                                                                    if (
                                                                        res.value.status === 200 &&
                                                                        res.value.data.data !==
                                                                            "no data found"
                                                                    ) {
                                                                        const data =
                                                                            res.value.data.data;
                                                                        this.setState({
                                                                            payrollData: data,
                                                                        });
                                                                    }
                                                                });
                                                        } else {
                                                            this.setState({
                                                                showPayrollUpdatedModal: true,
                                                            });
                                                        }
                                                    });
                                            } else {
                                                this.props
                                                    .postEmployeePayrollData(newData)
                                                    .then((res) => {
                                                        if (res.value.status !== 200) {
                                                            this.props
                                                                .getEmployeePayrollData(
                                                                    this.state.userDetails.user_id
                                                                )
                                                                .then((res) => {
                                                                    if (
                                                                        res.value.status === 200 &&
                                                                        res.value.data.data !==
                                                                            "no data found"
                                                                    ) {
                                                                        const data =
                                                                            res.value.data.data;
                                                                        this.setState({
                                                                            payrollData: data,
                                                                        });
                                                                    }
                                                                });
                                                        } else {
                                                            this.setState({
                                                                showPayrollUpdatedModal: true,
                                                            });
                                                        }
                                                    });
                                            }
                                        }}>
                                        <div className="grid grid-cols-3 gap-10 mb-8">
                                            <div className="w-full flex">
                                                <div className="flex-1 relative">
                                                    <label
                                                        htmlFor="payrate"
                                                        className="absolute px-1 z-10 bg-white whitespace-nowrap text-xs left-1.5 -top-2 xl:-top-3 md:left-3 xl:left-5 md:text-tiny xl:text-base text-gray-600">
                                                        Pay Rate
                                                    </label>
                                                    <div className="relative max-w-full text-base xl:text-lg">
                                                        <span className="absolute top-2 left-3 font-semibold">
                                                            {this.state.payrollData["currency"] ===
                                                            "inr" ? (
                                                                <>&#8377;</>
                                                            ) : (
                                                                "$"
                                                            )}
                                                        </span>
                                                        <input
                                                            type="text"
                                                            required
                                                            name="payrate"
                                                            id="payrate"
                                                            value={
                                                                this.state.payrollData["pay_rate"]
                                                                    ? this.state.payrollData[
                                                                          "pay_rate"
                                                                      ]
                                                                    : ""
                                                            }
                                                            placeholder="0"
                                                            onChange={(e) => {
                                                                const matchResult =
                                                                    e.target.value.match(
                                                                        /([0-9]*([.][0-9]*)?|[.][0-9]+)/
                                                                    );
                                                                if (
                                                                    matchResult &&
                                                                    matchResult[0] ===
                                                                        matchResult.input
                                                                ) {
                                                                    const data =
                                                                        this.state.payrollData;
                                                                    data["pay_rate"] =
                                                                        e.target.value;
                                                                    this.setState({
                                                                        payrollData: data,
                                                                    });
                                                                }
                                                            }}
                                                            className="bg-gray-50 text-gray-600 dark:text-gray-600 placeholder-gray-400 border w-full pl-7 border-fiver font-semibold rounded-l-lg block p-2.5 focus:ring-0 focus:border-primary dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-1/3">
                                                    <select
                                                        // arrayOfData={payInterval}
                                                        // value={
                                                        //   this.state.payrollData["type_payment"]
                                                        //     ? this.state.payrollData["type_payment"]
                                                        //     : "yearly"
                                                        // }
                                                        // hideDefault={true}
                                                        className="py-2.5 block min-h-full rounded-l-none focus:ring-0 focus:border-primary sm:py-2 pl-3 pr-8 text-base focus:outline-none focus:ring-indigo-500 sm:text-sm rounded-md border-gray-300 dark:bg-gray-100 dark:border-gray-400 dark:placeholder-gray-400 dark:text-gray-400"
                                                        // onSelectChange={(e) => {
                                                        //   const data = this.state.payrollData;
                                                        //   data["type_payment"] = e.target.value;
                                                        //   this.setState({
                                                        //     payrollData: data,
                                                        //   });
                                                        // }}
                                                        onChange={(e) => {
                                                            const data = this.state.payrollData;
                                                            data["type_payment"] = e.target.value;
                                                            this.setState({
                                                                payrollData: data,
                                                            });
                                                        }}
                                                        // defaultValue={
                                                        //   this.state.payrollData["type_payment"]
                                                        // }
                                                        defaultValue={
                                                            this.state.payrollData["type_payment"]
                                                        }>
                                                        {!true && (
                                                            <option value="">
                                                                {this.state.payrollData[
                                                                    "type_payment"
                                                                ]
                                                                    ? this.state.payrollData[
                                                                          "type_payment"
                                                                      ]
                                                                    : t("select")}
                                                            </option>
                                                        )}
                                                        {payInterval.map((data, idx) => (
                                                            <option key={idx} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="flex-1 relative">
                                                <label
                                                    htmlFor="panDetails"
                                                    className="absolute px-1 z-10 bg-white whitespace-nowrap text-xs left-1.5 -top-2 xl:-top-3 md:left-3 xl:left-5 md:text-tiny xl:text-base text-gray-600">
                                                    Effective Date
                                                </label>
                                                <div className="flex text-textprimary max-w-full text-base xl:text-lg dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400">
                                                    <DatePicker
                                                        className="rounded-md w-full font-medium border-fiver pr-6 bg-transparent focus:ring-0 focus:border-primary  dark:text-gray-400"
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={
                                                            this.state.payrollData["effective_date"]
                                                                ? this.state.payrollData[
                                                                      "effective_date"
                                                                  ]
                                                                : new Date()
                                                        }
                                                        onChange={(date) => {
                                                            let payrolldata =
                                                                this.state.payrollData;
                                                            payrolldata["effective_date"] = date;
                                                            this.setState({
                                                                payrollData: payrolldata,
                                                            });
                                                        }}
                                                    />
                                                    <CalendarIcon className="-ml-6 -z-10 w-5 text-[#6B7280] xl:-ml-8 xl:w-6"></CalendarIcon>
                                                    {/* className="bg-gray-50 border w-full pl-5 uppercase border-fiver font-semibold rounded-lg block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" */}
                                                </div>
                                            </div>
                                            <div className="flex-1 relative">
                                                <label
                                                    htmlFor="monthlyCTC"
                                                    className={`absolute px-1 z-10 bg-white whitespace-nowrap text-xs left-1.5 -top-2 xl:-top-3 md:left-3 xl:left-5 md:text-tiny xl:text-base ${
                                                        this.state.payrollData["type_payment"] !==
                                                        "yearly"
                                                            ? "text-gray-300"
                                                            : "text-gray-600"
                                                    }`}>
                                                    Monthly CTC
                                                </label>
                                                <div className="relative max-w-full text-base xl:text-lg">
                                                    <span
                                                        className={`absolute top-2.5 xl:top-2 left-3 font-semibold ${
                                                            this.state.payrollData[
                                                                "type_payment"
                                                            ] !== "yearly" && "text-gray-300"
                                                        }`}>
                                                        {this.state.payrollData["currency"] ===
                                                        "inr" ? (
                                                            <>&#8377;</>
                                                        ) : (
                                                            "$"
                                                        )}
                                                    </span>
                                                    <input
                                                        type="text"
                                                        required
                                                        name="monthlyCTC"
                                                        id="monthlyCTC"
                                                        disabled={
                                                            this.state.payrollData[
                                                                "type_payment"
                                                            ] !== "yearly"
                                                        }
                                                        placeholder="0"
                                                        value={
                                                            this.state.payrollData["monthly_ctc"]
                                                                ? this.state.payrollData[
                                                                      "monthly_ctc"
                                                                  ]
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            const matchResult =
                                                                e.target.value.match(
                                                                    /([0-9]*([.][0-9]*)?|[.][0-9]+)/
                                                                );
                                                            if (
                                                                matchResult &&
                                                                matchResult[0] === matchResult.input
                                                            ) {
                                                                const data = this.state.payrollData;
                                                                data["monthly_ctc"] =
                                                                    e.target.value;
                                                                this.setState({
                                                                    payrollData: data,
                                                                });
                                                            }
                                                        }}
                                                        className={`bg-gray-50 border w-full pl-7 border-fiver text-lg font-semibold rounded-lg block p-2.5 focus:ring-0 focus:border-primary dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${
                                                            this.state.payrollData[
                                                                "type_payment"
                                                            ] !== "yearly"
                                                                ? "placeholder-gray-300 text-gray-300"
                                                                : "placeholder-gray-400"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-3 h-28 flex-1 relative max-w-full text-base xl:text-lg">
                                                <textarea
                                                    name="comments"
                                                    id="comments"
                                                    value={
                                                        this.state.payrollData["comment"]
                                                            ? this.state.payrollData["comment"]
                                                            : ""
                                                    }
                                                    placeholder="Comments"
                                                    onChange={(e) => {
                                                        const data = this.state.payrollData;
                                                        data["comment"] = e.target.value;
                                                        this.setState({
                                                            payrollData: data,
                                                        });
                                                    }}
                                                    className="bg-gray-50 border h-full max-h-full w-full pl-5 border-fiver font-semibold rounded-lg block p-2.5 focus:ring-0 focus:border-primary dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                                />
                                            </div>
                                            <div className="col-span-3 flex items-center justify-between">
                                                <div className=" font-bold text-2xl">Earnings</div>
                                                <button className="bg-white text-white text-lg rounded-md text-center py-2 px-5">
                                                    Add
                                                </button>
                                            </div>
                                            <div className="flex-1 relative">
                                                <label
                                                    htmlFor="basicSalary"
                                                    className={`absolute px-1 z-10 bg-white whitespace-nowrap text-xs left-1.5 -top-2 xl:-top-3 md:left-3 xl:left-5 md:text-tiny xl:text-base ${
                                                        this.state.payrollData["type_payment"] !==
                                                        "yearly"
                                                            ? "text-gray-300"
                                                            : "text-gray-600"
                                                    }`}>
                                                    Basic Salary
                                                </label>
                                                <div className="relative max-w-full text-base xl:text-lg">
                                                    <span
                                                        className={`absolute top-2 left-3 font-semibold ${
                                                            this.state.payrollData[
                                                                "type_payment"
                                                            ] !== "yearly" && "text-gray-300"
                                                        }`}>
                                                        {this.state.payrollData["currency"] ===
                                                        "inr" ? (
                                                            <>&#8377;</>
                                                        ) : (
                                                            "$"
                                                        )}
                                                    </span>
                                                    <input
                                                        type="text"
                                                        required
                                                        name="basicSalary"
                                                        id="basicSalary"
                                                        disabled={
                                                            this.state.payrollData[
                                                                "type_payment"
                                                            ] !== "yearly"
                                                        }
                                                        placeholder="0"
                                                        value={
                                                            this.state.payrollData["basic_salary"]
                                                                ? this.state.payrollData[
                                                                      "basic_salary"
                                                                  ]
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            const matchResult =
                                                                e.target.value.match(
                                                                    /([0-9]*([.][0-9]*)?|[.][0-9]+)/
                                                                );
                                                            if (
                                                                matchResult &&
                                                                matchResult[0] === matchResult.input
                                                            ) {
                                                                const data = this.state.payrollData;
                                                                data["basic_salary"] =
                                                                    e.target.value;
                                                                this.setState({
                                                                    payrollData: data,
                                                                });
                                                            }
                                                        }}
                                                        className={`bg-gray-50 border w-full pl-7 border-fiver font-semibold rounded-lg block p-2.5 focus:ring-0 focus:border-primary dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${
                                                            this.state.payrollData[
                                                                "type_payment"
                                                            ] !== "yearly"
                                                                ? "placeholder-gray-300 text-gray-300"
                                                                : "placeholder-gray-400"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-1 relative">
                                                <label
                                                    htmlFor="houseRentAllowance"
                                                    className={`absolute px-1 z-10 bg-white whitespace-nowrap text-xs left-1.5 -top-2 xl:-top-3 md:left-3 xl:left-5 md:text-tiny xl:text-base ${
                                                        this.state.payrollData["type_payment"] !==
                                                        "yearly"
                                                            ? "text-gray-300"
                                                            : "text-gray-600"
                                                    }`}>
                                                    House Rent Allowance
                                                </label>
                                                <div className="relative max-w-full text-base xl:text-lg">
                                                    <span
                                                        className={`absolute top-2 left-3 font-semibold ${
                                                            this.state.payrollData[
                                                                "type_payment"
                                                            ] !== "yearly" && "text-gray-300"
                                                        }`}>
                                                        {this.state.payrollData["currency"] ===
                                                        "inr" ? (
                                                            <>&#8377;</>
                                                        ) : (
                                                            "$"
                                                        )}
                                                    </span>
                                                    <input
                                                        type="text"
                                                        required
                                                        name="houseRentAllowance"
                                                        id="houseRentAllowance"
                                                        disabled={
                                                            this.state.payrollData[
                                                                "type_payment"
                                                            ] !== "yearly"
                                                        }
                                                        placeholder="0"
                                                        value={
                                                            this.state.payrollData[
                                                                "house_rent_allowance"
                                                            ]
                                                                ? this.state.payrollData[
                                                                      "house_rent_allowance"
                                                                  ]
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            const matchResult =
                                                                e.target.value.match(
                                                                    /([0-9]*([.][0-9]*)?|[.][0-9]+)/
                                                                );
                                                            if (
                                                                matchResult &&
                                                                matchResult[0] === matchResult.input
                                                            ) {
                                                                const data = this.state.payrollData;
                                                                data["house_rent_allowance"] =
                                                                    e.target.value;
                                                                this.setState({
                                                                    payrollData: data,
                                                                });
                                                            }
                                                        }}
                                                        className={`bg-gray-50 border w-full pl-7 border-fiver font-semibold rounded-lg block p-2.5 focus:ring-0 focus:border-primary dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${
                                                            this.state.payrollData[
                                                                "type_payment"
                                                            ] !== "yearly"
                                                                ? "placeholder-gray-300 text-gray-300"
                                                                : "placeholder-gray-400"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-1 relative">
                                                <label
                                                    htmlFor="specialAllowance"
                                                    className={`absolute px-1 z-10 bg-white whitespace-nowrap text-xs left-1.5 -top-2 xl:-top-3 md:left-3 xl:left-5 md:text-tiny xl:text-base ${
                                                        this.state.payrollData["type_payment"] !==
                                                        "yearly"
                                                            ? "text-gray-300"
                                                            : "text-gray-600"
                                                    }`}>
                                                    Special Allowance
                                                </label>
                                                <div className="relative max-w-full text-base xl:text-lg">
                                                    <span
                                                        className={`absolute top-2 left-3 font-semibold ${
                                                            this.state.payrollData[
                                                                "type_payment"
                                                            ] !== "yearly" && "text-gray-300"
                                                        }`}>
                                                        {this.state.payrollData["currency"] ===
                                                        "inr" ? (
                                                            <>&#8377;</>
                                                        ) : (
                                                            "$"
                                                        )}
                                                    </span>
                                                    <input
                                                        type="text"
                                                        required
                                                        name="specialAllowance"
                                                        id="specialAllowance"
                                                        disabled={
                                                            this.state.payrollData[
                                                                "type_payment"
                                                            ] !== "yearly"
                                                        }
                                                        placeholder="0"
                                                        value={
                                                            this.state.payrollData[
                                                                "special_allowance"
                                                            ]
                                                                ? this.state.payrollData[
                                                                      "special_allowance"
                                                                  ]
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            const matchResult =
                                                                e.target.value.match(
                                                                    /([0-9]*([.][0-9]*)?|[.][0-9]+)/
                                                                );
                                                            if (
                                                                matchResult &&
                                                                matchResult[0] === matchResult.input
                                                            ) {
                                                                const data = this.state.payrollData;
                                                                data["special_allowance"] =
                                                                    e.target.value;
                                                                this.setState({
                                                                    payrollData: data,
                                                                });
                                                            }
                                                        }}
                                                        className={`bg-gray-50 border w-full pl-7 border-fiver font-semibold rounded-lg block p-2.5 focus:ring-0 focus:border-primary dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${
                                                            this.state.payrollData[
                                                                "type_payment"
                                                            ] !== "yearly"
                                                                ? "placeholder-gray-300 text-gray-300"
                                                                : "placeholder-gray-400"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-1 relative">
                                                <label
                                                    htmlFor="travelAllowance"
                                                    className={`absolute px-1 z-10 bg-white whitespace-nowrap text-xs left-1.5 -top-2 xl:-top-3 md:left-3 xl:left-5 md:text-tiny xl:text-base ${
                                                        this.state.payrollData["type_payment"] !==
                                                        "yearly"
                                                            ? "text-gray-300"
                                                            : "text-gray-600"
                                                    }`}>
                                                    Travel Allowance
                                                </label>
                                                <div className="relative max-w-full text-base xl:text-lg">
                                                    <span
                                                        className={`absolute top-2 left-3 font-semibold ${
                                                            this.state.payrollData[
                                                                "type_payment"
                                                            ] !== "yearly" && "text-gray-300"
                                                        }`}>
                                                        {this.state.payrollData["currency"] ===
                                                        "inr" ? (
                                                            <>&#8377;</>
                                                        ) : (
                                                            "$"
                                                        )}
                                                    </span>
                                                    <input
                                                        type="text"
                                                        required
                                                        name="travelAllowance"
                                                        id="travelAllowance"
                                                        disabled={
                                                            this.state.payrollData[
                                                                "type_payment"
                                                            ] !== "yearly"
                                                        }
                                                        placeholder="0"
                                                        value={
                                                            this.state.payrollData[
                                                                "travel_allowance"
                                                            ]
                                                                ? this.state.payrollData[
                                                                      "travel_allowance"
                                                                  ]
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            const matchResult =
                                                                e.target.value.match(
                                                                    /([0-9]*([.][0-9]*)?|[.][0-9]+)/
                                                                );
                                                            if (
                                                                matchResult &&
                                                                matchResult[0] === matchResult.input
                                                            ) {
                                                                const data = this.state.payrollData;
                                                                data["travel_allowance"] =
                                                                    e.target.value;
                                                                this.setState({
                                                                    payrollData: data,
                                                                });
                                                            }
                                                        }}
                                                        className={`bg-gray-50 border w-full pl-7 border-fiver font-semibold rounded-lg block p-2.5 focus:ring-0 focus:border-primary dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${
                                                            this.state.payrollData[
                                                                "type_payment"
                                                            ] !== "yearly"
                                                                ? "placeholder-gray-300 text-gray-300"
                                                                : "placeholder-gray-400"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-3 font-bold text-2xl">
                                                Bank Details
                                            </div>
                                            <div className="flex-1 relative">
                                                <label
                                                    htmlFor="acountNumber"
                                                    className="absolute px-1 z-10 bg-white whitespace-nowrap text-xs left-1.5 -top-2 xl:-top-3 md:left-3 xl:left-5 md:text-tiny xl:text-base text-gray-600">
                                                    Account Number
                                                </label>
                                                <input
                                                    type="text"
                                                    required
                                                    name="acountNumber"
                                                    id="acountNumber"
                                                    placeholder="00000000000000000"
                                                    value={
                                                        this.state.payrollData[
                                                            "bank_account_number"
                                                        ]
                                                            ? this.state.payrollData[
                                                                  "bank_account_number"
                                                              ]
                                                            : ""
                                                    }
                                                    onChange={(e) => {
                                                        const matchResult =
                                                            e.target.value.match(/[0-9]*/);
                                                        if (
                                                            matchResult &&
                                                            matchResult[0] === matchResult.input
                                                        ) {
                                                            const data = this.state.payrollData;
                                                            data["bank_account_number"] =
                                                                e.target.value;
                                                            this.setState({
                                                                payrollData: data,
                                                            });
                                                        }
                                                    }}
                                                    className="bg-gray-50 text-gray-600 placeholder-gray-400 border w-full pl-5 border-fiver text-base xl:text-lg font-semibold rounded-lg block p-2.5 focus:ring-0 focus:border-primary dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400"
                                                />
                                            </div>
                                            <div className="flex-1 relative">
                                                <label
                                                    htmlFor="ifscCode"
                                                    className="absolute px-1 z-10 bg-white whitespace-nowrap text-xs left-1.5 -top-2 xl:-top-3 md:left-3 xl:left-5 md:text-tiny xl:text-base text-gray-600">
                                                    IFSC Code
                                                </label>
                                                <input
                                                    type="text"
                                                    required
                                                    name="ifscCode"
                                                    id="ifscCode"
                                                    placeholder="AAAA1234L"
                                                    value={
                                                        this.state.payrollData["ifsc_code"]
                                                            ? this.state.payrollData["ifsc_code"]
                                                            : ""
                                                    }
                                                    onChange={(e) => {
                                                        const data = this.state.payrollData;
                                                        data["ifsc_code"] = e.target.value;
                                                        this.setState({
                                                            payrollData: data,
                                                        });
                                                    }}
                                                    className="bg-gray-50 text-gray-600 placeholder-gray-400 border w-full pl-5 uppercase border-fiver text-base xl:text-lg font-semibold rounded-lg block p-2.5 focus:ring-0 focus:border-primary dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400"
                                                />
                                            </div>
                                            <div className="flex-1 relative">
                                                <label
                                                    htmlFor="bankName"
                                                    className="absolute px-1 z-10 bg-white whitespace-nowrap text-xs left-1.5 -top-2 xl:-top-3 md:left-3 xl:left-5 md:text-tiny xl:text-base text-gray-600">
                                                    Bank Name
                                                </label>
                                                <input
                                                    type="text"
                                                    required
                                                    name="bankName"
                                                    id="bankName"
                                                    placeholder="ABC Bank"
                                                    value={
                                                        this.state.payrollData["bank_name"]
                                                            ? this.state.payrollData["bank_name"]
                                                            : ""
                                                    }
                                                    onChange={(e) => {
                                                        const data = this.state.payrollData;
                                                        data["bank_name"] = e.target.value;
                                                        this.setState({
                                                            payrollData: data,
                                                        });
                                                    }}
                                                    className="bg-gray-50 text-gray-600 placeholder-gray-400 border w-full pl-5 uppercase border-fiver text-base xl:text-lg font-semibold rounded-lg block p-2.5 focus:ring-0 focus:border-primary dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400"
                                                />
                                            </div>
                                            <div className="flex-1 relative">
                                                <label
                                                    htmlFor="acountHolderName"
                                                    className="absolute px-1 z-10 bg-white whitespace-nowrap text-xs left-1.5 -top-2 xl:-top-3 md:left-3 xl:left-5 md:text-tiny xl:text-base text-gray-600">
                                                    Name of the Account Holder
                                                </label>
                                                <input
                                                    type="text"
                                                    required
                                                    name="acountHolderName"
                                                    id="acountHolderName"
                                                    placeholder="Account Holder"
                                                    value={
                                                        this.state.payrollData[
                                                            "name_account_holder"
                                                        ] ?? ""
                                                    }
                                                    onChange={(e) => {
                                                        const data = this.state.payrollData;
                                                        data["name_account_holder"] =
                                                            e.target.value;
                                                        this.setState({
                                                            payrollData: data,
                                                        });
                                                    }}
                                                    className="bg-gray-50 text-gray-600 placeholder-gray-400 border w-full pl-5 capitalize border-fiver text-base xl:text-lg font-semibold rounded-lg block p-2.5 focus:ring-0 focus:border-primary dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400"
                                                />
                                            </div>
                                            <div className="flex-1 relative">
                                                <label
                                                    htmlFor="panDetails"
                                                    className="absolute px-1 z-10 bg-white whitespace-nowrap text-xs left-1.5 -top-2 xl:-top-3 md:left-3 xl:left-5 md:text-tiny xl:text-base text-gray-600">
                                                    PAN Details
                                                </label>
                                                <div className="max-w-full text-base xl:text-lg">
                                                    <input
                                                        type="text"
                                                        required
                                                        name="panDetails"
                                                        id="panDetails"
                                                        value={
                                                            this.state.payrollData["pan_details"]
                                                                ? this.state.payrollData[
                                                                      "pan_details"
                                                                  ]
                                                                : ""
                                                        }
                                                        placeholder="ABCDE1234X"
                                                        onChange={(e) => {
                                                            const data = this.state.payrollData;
                                                            data["pan_details"] = e.target.value;
                                                            this.setState({
                                                                payrollData: data,
                                                            });
                                                        }}
                                                        className="bg-gray-50 text-gray-600 border w-full pl-5 uppercase border-fiver font-semibold rounded-lg block p-2.5 focus:ring-0 focus:border-primary dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            className="bg-primary text-white rounded-md py-2 px-6 text-lg xl:text-xl"
                                            type="submit">
                                            Save Changes
                                        </button>
                                    </form>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <PayrollUpdated
                    open={this.state.showPayrollUpdatedModal}
                    onClick={() => {
                        this.setState({ showPayrollUpdatedModal: false });
                    }}
                    titleInfo={this.state.payrollDataTitl}
                    msg={this.state.payrollDataMsg}
                />
                <ConfirmationDialogue
                    open={this.state.showDeleteProjectModal}
                    onCancelClick={() => {
                        this.setState({ showDeleteProjectModal: false });
                    }}
                    onSubmitClick={() => handleDeleteProject()}
                    info={"Are you sure you want to Delete ?"}
                    submitButtonName={"Delete"}
                    cancelButtonName={"Cancel"}></ConfirmationDialogue>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({
    teams: state.teams,
});

const mapDispatchToProps = (dispatch) => ({
    getEmployeePhoneNumber: (user_uuid) => dispatch(getEmployeePhoneNumber(user_uuid)),
    editEmployeePhoneNumber: (user_uuid, phone_number) =>
        dispatch(editEmployeePhoneNumber(user_uuid, phone_number)),
    editEmployeeBasicInfo: (data) => dispatch(editEmployeeBasicInfo(data)),
    getUserBasedProjects: (username) => dispatch(getUserBasedProjects(username)),
    removeUserbasedProject: (data) => dispatch(removeUserbasedProject(data)),
    getUserProject: () => dispatch(getUserProject()),
    addUserbasedProject: (data) => dispatch(addUserbasedProject(data)),
    getEmployeePayrollData: (user_id) => dispatch(getEmployeePayrollData(user_id)),
    postEmployeePayrollData: (data) => dispatch(postEmployeePayrollData(data)),
    updateEmployeePayrollData: (data) => dispatch(updateEmployeePayrollData(data)),
    getManagerList: () => dispatch(getManagerList()),
    getUserData: (params) => dispatch(getUserData(params)),
    editUserDetails: (data) => dispatch(editUserDetails(data)),
});

export default withTranslation("common")(
    connect(mapStateToProps, mapDispatchToProps)(EmployeeProfile)
);
