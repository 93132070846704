import React, { Component } from 'react'
import { connect } from 'react-redux'
import SelectField from '../../Components/SelectField';

//actions
import { fetchallEmployees } from '../../services/projects/actions';
import { fetchAllRequests } from '../../services/requests/actions';

//dependencies
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
//icons
import { CalendarIcon } from '@heroicons/react/outline';
import { withTranslation } from 'react-i18next';
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const requestTypes = [
    { type: "Sick" },
    { type: "Family Emergency" },
    { type: "Vacation Leave" },
    { type: "Work from Home" },
    { type: "offline" },
    { type: "Night Shift" },
    { type: "Partial" },
    { type: "HR" },
    { type: "Equipment" },
    { type: "Salary Revision" },
    { type: "Team Help" },
    { type: "Request Offline Time" },
];
const status = [{ status: "Approved" }, { status: "Rejected" }];

class PastRequests_tab extends Component {
    constructor() {
        super();
        this.state = {
            selectedDate: new Date(),
            initialData: [],
            filteredData: [],
            selectedEmp: "",
            reqType: "",
            reqSort: false,
            empSort: false,
            isUserNotFound: false,
            isRequestTypeNotFound: false,
            sortOrder: "descending",
        };
    }

    componentDidMount() {
        this.props.fetchallEmployees();

        this.props
            .fetchAllRequests()
            .then((data) => {
                const pastRequestsList =
                    data &&
                    data.value.data.filter((request) => {
                        return request.status === "Approved" || request.status === "Rejected";
                    });
                this.setState({
                    filteredData: pastRequestsList,
                    initialData: pastRequestsList,
                });
            })
            .catch((error) => {
                console.log("Error Fetching all Requests");
            });
    }

    render() {
        const { t } = this.props;
        let empList = this.props.allEmployees;
        let listofAllEmployees = empList && empList.AllEmployees;

        let Select_emp_dropdown =
            listofAllEmployees &&
            listofAllEmployees.map((emp, index) => ({
                name: emp.user_first_name + " " + emp.user_last_name,
                id: emp.user_login_as,
            }));
        Select_emp_dropdown =
            Select_emp_dropdown &&
            Select_emp_dropdown?.sort((a, b) => a.name.localeCompare(b.name));

        const listOfreq = requestTypes
            ?.sort((a, b) => a.type.localeCompare(b.type))
            .map((el) => ({
                name: el.type,
            }));

        const listOfStatus = status.map((el) => ({
            name: el.status,
        }));

        const onSelectEmployee = (e) => {
            const filteredEmp = this.state.initialData.filter(
                (request) => request.user === e.target.value
            );

            if (e.target.value === "") {
                this.setState({
                    filteredData: this.state.initialData,
                    empSort: false,
                    isUserNotFound: false,
                });
            } else if (filteredEmp.length === 0) {
                this.setState({
                    isUserNotFound: true,
                    empSort: false,
                });
            } else {
                this.setState({
                    selectedEmp: filteredEmp,
                    empSort: true,
                    isUserNotFound: false,
                });
            }
        };

        const handleSelectRequestType = (e) => {
            const selectedRequestType = e.target.value;
            const filteredType = this.state.initialData.filter(
                (request) => request.type === selectedRequestType
            );

            if (selectedRequestType === "") {
                this.setState({
                    filteredData: this.state.initialData,
                    reqSort: false,
                    isRequestTypeNotFound: false,
                });
            } else if (filteredType.length === 0) {
                this.setState({
                    isRequestTypeNotFound: true,
                    reqSort: false,
                });
            } else {
                this.setState({
                    reqType: filteredType,
                    reqSort: true,
                    isRequestTypeNotFound: false,
                });
            }
        };

        const handleStatusRequest = (e) => {
            const filteredStatus = this.state.initialData.filter((request) => {
                return request.status === e.target.value;
            });
            if (e.target.value === "") {
                this.setState({
                    filteredData: this.state.initialData,
                });
            } else {
                this.setState({
                    filteredData: filteredStatus,
                });
            }
        };

        const handledateChange = (date) => {
            this.setState({ selectedDate: date }, () => {
                const filteredDate = this.state.initialData.filter((request) => {
                    return (
                        moment(request.created).format("YYYY-MM-DD") ===
                        moment(date).format("YYYY-MM-DD")
                    );
                });
                this.setState({
                    filteredData:
                        this.state.selectedDate == null ? this.state.initialData : filteredDate,
                });
            });
        };
        return (
            <div className="mt-2">
                <div className="bg-white mt-4 px-4 py-3 border-b border-gray-200 rounded-lg">
                    <div className="flex items-start xl:items-end justify-between flex-col xl:flex-row flex-wrap sm:flex-nowrap">
                        <div className="flex text-textprimary mb-2 lg:mb-0 text-lg font-semibold relative">
                            <DatePicker
                                className="rounded-md cursor-pointer"
                                selected={this.state.selectedDate}
                                dateFormat="MMMM dd yyyy"
                                value={this.state.selectedDate}
                                startDate={this.state.selectedDate}
                                onChange={(date) => handledateChange(date)}
                            />
                            <CalendarIcon className="absolute right-2 top-2 w-6"></CalendarIcon>
                        </div>

                        <div className="flex flex-col lg:flex-row justify-around mt-5 xl:mt-0">
                            <div className="my-3 lg:my-0 mr-3 w-52">
                                <SelectField
                                    onSelectChange={(e) => {
                                        onSelectEmployee(e);
                                    }}
                                    name={"Select Employee"}
                                    FieldName={t("select") + " " + t("employee")}
                                    mainclassName={"mr-4"}
                                    arrayOfData={Select_emp_dropdown}
                                    id={"name"}></SelectField>
                            </div>
                            <div className="my-3 lg:my-0 mr-3 w-52">
                                <SelectField
                                    onSelectChange={(e) => {
                                        handleSelectRequestType(e);
                                    }}
                                    name={"Select Request Type"}
                                    FieldName={t("requestsModule.requestType")}
                                    mainclassName={"mr-4"}
                                    arrayOfData={listOfreq}
                                    id={"name"}></SelectField>
                            </div>
                            <div className="w-52">
                                <SelectField
                                    onSelectChange={(e) => {
                                        handleStatusRequest(e);
                                    }}
                                    name={"Select Request Status"}
                                    FieldName={t("status")}
                                    arrayOfData={listOfStatus}
                                    id={"name"}></SelectField>
                            </div>
                        </div>
                    </div>

                    {/* Requests Table */}

                    <div className="bg-white px-4 py-5 mt-4 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div
                            className="mt-2 flex flex-col overflow-y-auto bg-gray-100"
                            style={{ maxHeight: "auto" }}>
                            <div className="inline-block min-w-full align-middle">
                                <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[55vh] overflow-auto">
                                    <table className="min-w-full height-100vh border-separate">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                    {t("name")}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                    {t("type")}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 hidden lg:!flex gap-2 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                    {t("date")}
                                                    {this.state.sortOrder !== "ascending" ? (
                                                        <button
                                                            title="Sort Ascending"
                                                            onClick={() => {
                                                                this.setState({
                                                                    sortOrder: "ascending",
                                                                });
                                                            }}
                                                            className={`flex flex-col ml-3`}>
                                                            <IoMdArrowDropup className="my-0 text-xs" />
                                                            <IoMdArrowDropdown className="my-0 text-xs" />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            title="Sort Descending"
                                                            onClick={() => {
                                                                this.setState({
                                                                    sortOrder: "descending",
                                                                });
                                                            }}
                                                            className={`flex flex-col ml-3`}>
                                                            <IoMdArrowDropup className="my-0 text-xs" />
                                                            <IoMdArrowDropdown className="my-0 text-xs" />
                                                        </button>
                                                    )}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                    {t("status")}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 text-left text-base backdrop-blur backdrop-filter sm:pr-6 lg:pr-12">
                                                    {t("requestsModule.remarks")}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {this.state.isUserNotFound ||
                                            this.state.isRequestTypeNotFound ||
                                            (this.state.empSort &&
                                                this.state.reqSort &&
                                                this.state.filteredData.length === 0) ||
                                            (this.state.empSort &&
                                                this.state.reqSort &&
                                                this.state.filteredData.length > 0 &&
                                                !this.state.filteredData.some(
                                                    (item) =>
                                                        this.state.selectedEmp.includes(item) &&
                                                        this.state.reqType.includes(item)
                                                )) ? (
                                                <tr>
                                                    <td
                                                        colSpan={5}
                                                        className="flex-1 text-center py-6 italic text-[#6B7280]">
                                                        User or Request type not found
                                                    </td>
                                                </tr>
                                            ) : this.state.filteredData === "" ||
                                              this.state.filteredData.length === 0 ? (
                                                <tr>
                                                    <td
                                                        colSpan={5}
                                                        className="flex-1 text-center py-6 italic text-[#6B7280]">
                                                        No Records Found
                                                    </td>
                                                </tr>
                                            ) : (
                                                this.state.filteredData &&
                                                (this.state.empSort && this.state.reqSort
                                                    ? this.state.filteredData.filter(
                                                          (item) =>
                                                              this.state.reqType.includes(item) &&
                                                              this.state.selectedEmp.includes(item)
                                                      )
                                                    : this.state.empSort
                                                    ? this.state.filteredData.filter((item) =>
                                                          this.state.selectedEmp.includes(item)
                                                      )
                                                    : this.state.reqSort
                                                    ? this.state.filteredData.filter((item) =>
                                                          this.state.reqType.includes(item)
                                                      )
                                                    : this.state.filteredData
                                                )
                                                    .sort((a, b) =>
                                                        this.state.sortOrder === "descending"
                                                            ? new Date(b.created) -
                                                              new Date(a.created)
                                                            : new Date(a.created) -
                                                              new Date(b.created)
                                                    )
                                                    .map((person, personIdx) => (
                                                        <tr key={personIdx}>
                                                            <td
                                                                className={classNames(
                                                                    personIdx !==
                                                                        this.state.filteredData
                                                                            .length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-primary sm:pl-6 lg:pl-12"
                                                                )}>
                                                                {person.user}
                                                            </td>
                                                            <td
                                                                className={classNames(
                                                                    personIdx !==
                                                                        this.state.filteredData
                                                                            .length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap px-3 py-4 text-base text-gray-500 hidden sm:table-cell"
                                                                )}>
                                                                {person.type}
                                                            </td>
                                                            <td
                                                                className={classNames(
                                                                    personIdx !==
                                                                        this.state.filteredData
                                                                            .length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap px-3 py-4 text-base text-gray-500 hidden lg:table-cell"
                                                                )}>
                                                                {person.created}
                                                            </td>
                                                            <td
                                                                className={classNames(
                                                                    personIdx !==
                                                                        this.state.filteredData
                                                                            .length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap px-3 py-4 text-base text-gray-500"
                                                                )}>
                                                                <div
                                                                    className={
                                                                        person.status === "Approved"
                                                                            ? "text-secondary"
                                                                            : "text-danger"
                                                                    }>
                                                                    {" "}
                                                                    {person.status}{" "}
                                                                </div>
                                                            </td>
                                                            <td
                                                                className={classNames(
                                                                    personIdx !==
                                                                        this.state.filteredData
                                                                            .length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap px-3 py-4 text-base text-gray-500"
                                                                )}>
                                                                {person.remarks}
                                                            </td>
                                                        </tr>
                                                    ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    allEmployees: state.projects,
})

const mapDispatchToProps = (dispatch) => ({
    fetchallEmployees: () => dispatch(fetchallEmployees()),
    fetchAllRequests: () => dispatch(fetchAllRequests()),

})

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(PastRequests_tab))