import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import closeImg from "../../assets/close.png";
import { DownloadIcon } from "@heroicons/react/outline";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const ModalShowCaseEntry = (props) => {
  const { t } = useTranslation("common");
  const [activeTab, setActiveTab] = useState(0);

  let tabs = [];
  for (var i = 0; i < props.entryDetails.length; i++) {
    console.log(props.entryDetails);
    tabs.push(i);
  }

  const download = (thumbnail, filename) => {
    fetch(localStorage.getItem("apiURL") + "/api/image_download_timetracker/", {
      method: "POST",
      headers: {
        authorization: "JWT " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: thumbnail,
      }),
    })
      .then((res) => {
        if (res.status == 200) {
          res
            .arrayBuffer()
            .then((response) => {
              const url = window.URL.createObjectURL(
                new Blob([response, { type: "application/png" }])
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", filename); //or any other extension
              document.body.appendChild(link);
              link.click();
            })
            .catch((error) => {
              console.log("error details", error.message);
            });
        } else if (res.status == 401) {
          toast.error("Something went wrong!, Refresh and Try Again");
          return;
        }
      })
      .catch((error) => {
        console.log("error details", error.message);
      });
  };
  return (
    <>
      {props.open ? (
        <div className="flex bg-textprimary z-10 bg-opacity-40 justify-center items-center fixed top-0 left-0 inset-0 outline-none focus:outline-none ">
          <Modal open={props.open} onClose={props.handleClose}>
            <div className="absolute top-0 left-0 translate-x-1/4 translate-y-1/4 min-w-[70%] min-h-[70%] max-w-3xl z-10 bg-white p-1">
              <div
                className=" absolute close_png border border-gray-400 z-10 translate-x-5 translate-y-5"
                onClick={props.handleClose}
              >
                <img alt="" src={closeImg} />
              </div>
              <div className="w-full min-h-full flex">
                {!props.fromDashboard ? (
                  <div className="w-3/5 flex flex-col pt-6 min-h-[70vh] max-h-[70vh] px-6 ml-4 mr-4 border-r-2 border-gray-200">
                    <div className=" flex justify-between mb-2">
                      <p className="font-semibold">
                        {props.entryDetails[activeTab].file_name}
                      </p>
                      <div
                        className="flex justify-between items-baseline border-[1px] px-2 border-gray-300 rounded-sm hover:cursor-pointer"
                        onClick={() => {
                          download(
                            props.entryDetails[activeTab].image,
                            props.entryDetails[activeTab].file_name
                          );
                        }}
                      >
                        <p className="text-textprimary"> {t("download")}</p>
                        <DownloadIcon className="text-gray-900 h-4 translate-y-0.5" />
                      </div>
                    </div>
                    <div className="mb-2 h-4/6 max-h-[67%] text-center rounded-md overflow-hidden">
                      {props.entryDetails[activeTab].video ? (
                        <iframe
                          title={props.entryDetails[activeTab].file_name}
                          className=" max-h-full min-w-full min-h-full text-center rounded-md"
                          src={props.entryDetails[activeTab].image}
                          allowFullScreen
                        ></iframe>
                      ) : (
                        <img
                          src={props.entryDetails[activeTab].image}
                          alt=""
                          className=" max-h-full min-w-full min-h-full text-center rounded-md"
                        />
                      )}
                    </div>
                    <div className=" text-gray-400 text-sm">
                      {t("timeTracker.posted")} {t("by")}{" "}
                      {props.userDetails.name}
                    </div>
                  </div>
                ) : (
                  <div className="w-3/5 flex flex-col pt-6 min-h-[70vh] max-h-[70vh] px-4 ml-6 mr-4 border-r-2 border-gray-200">
                    <div
                      className={
                        "mb-4 grid grid-rows-1 gap-x-2 grid-cols-" + tabs.length
                      }
                    >
                      {tabs.map((val, idx) => {
                        return (
                          <div
                            className={
                              "min-h-[4px] rounded-sm hover:cursor-pointer " +
                              (activeTab === idx ? "bg-primary" : "bg-gray-200")
                            }
                            onClick={() => {
                              setActiveTab(idx);
                            }}
                          ></div>
                        );
                      })}
                    </div>
                    <div className="flex justify-between items-center mb-4">
                      <div className="flex items-center">
                        <div className="border-2 h-10 w-10 rounded-full border-gray-400 bg-gray-200 text-gray-500 text-center flex flex-col justify-center uppercase">
                          {props.userDetails
                            ? props.userDetails.name
                                .split(" ")
                                .map((word) => word.charAt(0))
                            : ""}
                        </div>
                        <div className="ml-2 text-lg capitalize">
                          {props.userDetails ? props.userDetails.name : ""}
                        </div>
                      </div>
                      <div className="flex justify-between items-baseline border-[1px] px-2 border-gray-300 rounded-sm">
                        <p className="text-textprimary capitalize">
                          {t("download")}
                        </p>
                        <DownloadIcon className="text-gray-900 h-4 translate-y-0.5" />
                      </div>
                    </div>
                    <div className="mb-2 max-h-[70%] text-center rounded-md">
                      {props.entryDetails[activeTab].video ? (
                        <iframe
                          title={props.entryDetails[activeTab].file_name}
                          style={{ marginTop: "16px" }}
                          width="600"
                          height="400"
                          src={props.entryDetails[activeTab].image}
                        ></iframe>
                      ) : (
                        <img
                          src={props.entryDetails[activeTab].image}
                          alt=""
                          className=" max-h-full min-w-full min-h-full text-center rounded-md"
                        />
                      )}
                    </div>
                    <p className="font-semibold">
                      {props.entryDetails[activeTab].file_name}
                    </p>
                    <div className=" text-gray-400 text-sm">
                      {t("timeTracker.posted")} {t("by")}{" "}
                      {props.userDetails.name}
                    </div>
                  </div>
                )}
                <div className="w-2/5 pt-6 pr-4">
                  <div className="text-lg font-semibold text-textprimary mb-4 capitalize">
                    {t("timeTracker.showcaseInfo")}
                  </div>
                  <div>
                    <div className="flex mb-4">
                      <p className="pr-2 font-semibold text-gray-400">
                        {t("project")}:
                      </p>
                      <p className="text-sm font-semibold text-textprimary pt-0.5">
                        {props.entryDetails[activeTab].project_name
                          ? props.entryDetails[activeTab].project_name
                          : props.entryDetails[activeTab].project}
                      </p>
                    </div>
                    <div className="flex mb-4">
                      <p className="pr-2 font-semibold text-gray-400">
                        {t("team")}:
                      </p>
                      <p className="text-sm font-semibold text-textprimary pt-0.5">
                        {props.entryDetails[activeTab].team}
                      </p>
                    </div>
                    <div className="flex mb-4">
                      <p className="pr-2 font-semibold text-gray-400">
                        {t("task")}:
                      </p>
                      <p className="text-sm font-semibold text-textprimary pt-0.5">
                        {props.entryDetails[activeTab].task}
                      </p>
                    </div>
                    <div className="flex mb-4">
                      <p className="pr-2 font-semibold text-gray-400">
                        {t("timeTracker.note")}:
                      </p>
                      <p className="text-sm font-semibold text-textprimary pt-0.5">
                        {props.entryDetails[activeTab].note}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      ) : null}
    </>
  );
};

export default ModalShowCaseEntry;
