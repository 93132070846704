import React, { Component, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { connect } from "react-redux";
import Layout from "../../Components/Layout";
import { RadioGroup } from "@headlessui/react";
import PrimaryButton from "../../Components/PrimaryButton";
import {
  MailOpenIcon,
  CollectionIcon,
  ShieldCheckIcon,
  MailIcon,
  ExclamationCircleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import {
  sendUserInvite,
  orgEmails,
  DeleteOrgUser,
  updateuseremail,
} from "../../services/dashboard/actions";
import { FetchactiveTeams } from "../../services/teams/actions";
import { ArrowCircleRightIcon } from "@heroicons/react/outline";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpdateUserModal from "./UpdateUserModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Roles = [
  {
    name: "Regular User",
    description:
      "Meant for most employees and individual contributors to track time with screenshots. Regular users can control when they are tracked and view reports about their own productivity.",
  },
  {
    name: "Manager",
    description:
      "Managers have the power to do everything that regular users can. They can also view reports about individual work activity of everybody they oversee see screenshots and edits made by folks on their team and create new projects for people and invite new users Invite new users to the projects that they own.",
  },
  {
    name: "Admin",
    description:
      "Admins can do everything that regular users and managers can including logging time and have access to all projects as well. Admins can also create workstation users.",
  },
  {
    name: "Observer (for clients)",
    description:
      "Observers are project obeservers have limited read-only access. Observers can not track their own work and only see the projects and tasks for the people that they have been granted permission to view, such as screenshots or reports on projects completed by these individuals.",
  },
];

class InviteUser extends Component {
  constructor() {
    super();
    this.state = {
      SelectedTab: "Invite by email",
      email: "",
      selectedrole: Roles[0],
      rolevalue: "member",
      rolename: "Regular",
      emailerror: false,
      appName: "workahub",
      updateduseremail: "",
      updateusermodal: false,
      updateduserid: "",
      showInviteModal: false,
      teamsList: [],
      selectedTeamUUID: [],
    };
  }

  componentDidMount() {
    let orgname = localStorage.getItem("org_name");
    this.props.orgEmails(orgname);
    let org_uuid = localStorage.getItem("org_uuid");
    this.props.FetchactiveTeams(org_uuid).then((res) => {
      this.setState({ teamsList: res.value.data.info });
    });
  }

  HandleSelectTab = (e) => {
    this.setState({ SelectedTab: e });
  };

  HandleMobileSelectTab = (e) => {
    this.setState({ SelectedTab: e.target.value });
  };

  onChangeHandler = (e) => {
const inputValue = e.target.value;
const capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.setState({ email: capitalizedValue }, () => {
      let emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.state.email.match(emailRegex)) {
        this.setState({ emailerror: false });
      }
    });
  };

  HandleRolesChange = (e) => {
    this.setState({ selectedrole: e }, () => {
      if (this.state.selectedrole.name == "Regular User") {
        this.setState({ rolevalue: "member", rolename: "Regular" });
      } else if (this.state.selectedrole.name == "Manager") {
        this.setState({
          rolevalue: "manager",
          rolename: "Manager",
          showInviteModal: true,
        });
      } else if (this.state.selectedrole.name == "Admin") {
        this.setState({ rolevalue: "admin", rolename: "Admin" });
      } else if (this.state.selectedrole.name == "Observer (for clients)") {
        this.setState({
          rolevalue: "observer",
          rolename: "Observer",
          showInviteModal: true,
        });
      } else {
        this.setState({ rolevalue: "member", rolename: "Regular" });
      }
    });
  };

  SendInvite = () => {
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.email == "" || !this.state.email.match(emailRegex)) {
      this.setState({ emailerror: true });
    } else {
      let adminemail = localStorage.getItem("user_email");
      let data = {
        email: adminemail,
        useremail: this.state.email,
        type: this.state.rolevalue,
        role: this.state.rolename,
        domain_name: this.state.appName,
      };
      this.props.sendUserInvite(data).then((res) => {
        if (res.value.status == 200) {
          toast.success(res.value.data.Status);
          this.setState({ email: "" });
        }
      });
    }
  };
  submitObserverInvite = (data) => {
    fetch("https://api.trypadlock.com/observer/invite", {
      method: "POST",
      headers: {
        authorization: "JWT " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status === 200) {
        toast.success("Observer Invited Successfully");
        this.setState({
          email: "",
          showInviteModal: false,
          selectedTeamUUID: "",
        });
      } else if (res.status === 401) {
        let refresh_token = localStorage.getItem("refresh_token");
        fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            refresh: refresh_token,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            localStorage.setItem("access_token", data.access);
            localStorage.setItem("refresh_token", data.refresh);
            this.submitObserverInvite(data);
          });
      } else {
        toast.error("Observer Invitation Failed");
      }
    });
  };
  submitManagerInvite = (data) => {
    fetch("https://api.trypadlock.com/manager/invite", {
      method: "POST",
      headers: {
        authorization: "JWT " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status === 200) {
        toast.success("Manager Invited Successfully");
        this.setState({
          email: "",
          showInviteModal: false,
          selectedTeamUUID: "",
        });
      } else if (res.status === 401) {
        let refresh_token = localStorage.getItem("refresh_token");
        fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            refresh: refresh_token,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            localStorage.setItem("access_token", data.access);
            localStorage.setItem("refresh_token", data.refresh);
            this.submitObserverInvite(data);
          });
      } else {
        toast.error("Manager Invitation Failed");
      }
    });
  };
  sendInviteObsMngr = () => {
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.email == "" || !this.state.email.match(emailRegex)) {
      this.setState({ emailerror: true });
    } else {
      let adminemail = localStorage.getItem("user_email");
      let data = {
        email: adminemail,
        useremail: this.state.email,
        type: this.state.rolevalue,
        role: this.state.rolename,
        domain_name: this.state.appName,
        teams: this.state.selectedTeamUUID.toString(),
      };
      if (this.state.rolevalue.toLowerCase() === "observer") {
        this.submitObserverInvite(data);
      } else if (this.state.rolevalue.toLowerCase() === "manager") {
        this.submitManagerInvite(data);
      } else {
        this.props.sendUserInvite(data).then((res) => {
          if (res.value.status == 200) {
            toast.success(res.value.data.Status);
            this.setState({
              email: "",
              showInviteModal: false,
              selectedTeamUUID: "",
            });
          }
        });
      }
    }
  };

  handleUpdateuser = (useremail, id) => {
    this.setState({
      updateduseremail: useremail,
      updateduserid: id,
      updateusermodal: true,
    });
  };

  onModalClose = () => {
    this.setState({ updateusermodal: false });
  };

  onUpdateUserChange = (e) => {
    this.setState({ updateduseremail: e.target.value });
  };

  onModalUpdateSubmit = () => {
    this.setState({ updateusermodal: false });
    this.props
      .updateuseremail(this.state.updateduseremail, this.state.updateduserid)
      .then((res) => {
        if (res.value.status == 200) {
          toast.success("User Updated Successfully");
          let orgname = localStorage.getItem("org_name");
          this.props.orgEmails(orgname);
        }
      });
  };

  handleDeleteuser = (id) => {
    let email = localStorage.getItem("user_email");
    this.props.DeleteOrgUser(email, id).then((res) => {
      if (res.value.status == 200) {
        toast.success("User Deleted Successfully");
      }
      let orgname = localStorage.getItem("org_name");
      this.props.orgEmails(orgname);
    });
  };

  ResendInvite = (useremail, id) => {
    let email = localStorage.getItem("user_email");
    toast.success("Invitation Resent Successfully");
  };

  render() {
    const { selectedrole } = this.state;
    let userslist =
      this.props.dashboard && this.props.dashboard.organizationEmails
    console.log(userslist, "userlistinvitr")

    let team_uuid = [];
    const handleTeamSelection = (e, uuid) => {
      if (e.target.checked) {
        team_uuid.push(...this.state.selectedTeamUUID, uuid);
        this.setState({ selectedTeamUUID: team_uuid });
      } else if (!e.target.checked) {
        let b = this.state.selectedTeamUUID.filter((el) => el !== uuid);
        this.setState({ selectedTeamUUID: b });
      }
    };

    return (
     
      <Layout>
        <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg overflow-hidden">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              onChange={(e) => this.HandleMobileSelectTab(e)}
              className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
              defaultValue={this.state.SelectedTab}
            >
              <option key="Invite by email" value="Invite by email">
                Invite by email
              </option>
              <option key="Invite list" value="Invite list">
                Invite list
              </option>{" "}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              <div
                key="Invite by email"
                onClick={() => this.HandleSelectTab("Invite by email")}
                className={classNames(
                  this.state.SelectedTab == "Invite by email"
                    ? "border-primary text-primary"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                )}
                aria-current={
                  this.state.SelectedTab == "Invite by email"
                    ? "page"
                    : undefined
                }
              >
                <MailOpenIcon
                  className={classNames(
                    this.state.SelectedTab == "Invite by email"
                      ? "text-primary"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5"
                  )}
                  aria-hidden="true"
                />
                <span>Invite by email</span>
              </div>

              <div
                key="Invite list"
                onClick={() => this.HandleSelectTab("Invite list")}
                className={classNames(
                  this.state.SelectedTab == "Invite list"
                    ? "border-primary text-primary"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                )}
                aria-current={
                  this.state.SelectedTab == "Invite list" ? "page" : undefined
                }
              >
                <CollectionIcon
                  className={classNames(
                    this.state.SelectedTab == "Invite list"
                      ? "text-primary"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5"
                  )}
                  aria-hidden="true"
                />
                <span>Invite list</span>
              </div>
            </nav>
          </div>

          {this.state.SelectedTab == "Invite by email" ? (
            <div>
              <div className="mt-8">
                <label
                  htmlFor="email"
                  className="block text-base font-medium text-textprimary"
                >
                  Email
                </label>
                <div className="mt-1 relative rounded-md">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <MailIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={this.onChangeHandler}
                    className="focus:ring-primary focus:border-primary block w-4/12 pl-10 sm:text-sm border-primary rounded-md"
                    placeholder="Email Address"
                  />
                </div>
                {this.state.emailerror ? (
                  <div className="text-danger text-base mt-2 flex">
                    <ExclamationCircleIcon className="h-5 w-5 text-danger"></ExclamationCircleIcon>{" "}
                    <span className="ml-2">Please Enter Valid Email</span>
                  </div>
                ) : null}
              </div>
              <div className="mt-4 flex items-center justify-between flex-wrap sm:flex-nowrap">
                <div className="mt-3 text-primary text-2xl">Access level </div>
                <div className="mt-2 flex-shrink-0">
                  <PrimaryButton
                    onClick={() => this.SendInvite()}
                    buttontext="Send Invite"
                  ></PrimaryButton>
                </div>
              </div>

              <RadioGroup
                value={selectedrole}
                onChange={this.HandleRolesChange}
              >
                <RadioGroup.Label className="sr-only">
                  Privacy setting
                </RadioGroup.Label>
                <div className="bg-white mt-5 mb-5 rounded-md -space-y-px">
                  {Roles.map((setting, settingIdx) => (
                    <RadioGroup.Option
                      key={setting.name}
                      value={setting}
                      className={({ checked }) =>
                        classNames(
                          settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                          settingIdx === Roles.length - 1
                            ? "rounded-bl-md rounded-br-md"
                            : "",
                          checked
                            ? "bg-gray border-gray z-10"
                            : "border-gray-200",
                          "relative border p-4 flex cursor-pointer focus:outline-none mr-4"
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span
                            className={classNames(
                              checked
                                ? "bg-indigo-600 border-transparent"
                                : "bg-white border-gray-300",
                              active
                                ? "ring-2 ring-offset-2 ring-indigo-500"
                                : "",
                              "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
                            )}
                            aria-hidden="true"
                          >
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <div className="ml-2 flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className={classNames(
                                checked ? "text-primary" : "text-textprimary ",
                                "block text-base font-bold"
                              )}
                            >
                              {setting.name}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={classNames(
                                checked ? "text-primary" : "text-textprimary",
                                "block text-base"
                              )}
                            >
                              {setting.description}
                            </RadioGroup.Description>
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
          ) : null}
          {this.state.SelectedTab == "Invite list" ? (
            <div className="mt-4 flex flex-col overflow-y-auto bg-gray-100">
              <div className="inline-block min-w-full align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[75vh] overflow-auto">
                  <table className="min-w-full border-separate">
                    <thead className="bg-gray-50 sticky top-0 z-10">
                      <tr>
                        <th
                          scope="col"
                          className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12"
                        >
                          {" "}
                          Email{" "}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                        >
                          {" "}
                          Role{" "}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                        >
                          {" "}
                          Status{" "}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0  border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12"
                        >
                          Edit
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {userslist &&
                        userslist.map((el, i) => (
                          <tr key={i}>
                            <td
                              className={classNames(
                                i !== userslist.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6 lg:pl-12"
                              )}
                            >
                              {el.email}
                            </td>
                            <td
                              className={classNames(
                                i !== userslist.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap px-3 py-4 text-base text-gray-500 sm:table-cell flex"
                              )}
                            >
                              {el.roles}
                              {/* {el.status ? <PencilIcon className='ml-8 cursor-pointer w-5' /> : null} */}
                            </td>
                            <td
                              className={classNames(
                                i !== userslist.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap px-3 py-4 text-base text-gray-500 hidden lg:table-cell"
                              )}
                            >
                              {el.status
                                ? "Accepted invitation"
                                : "Hasn't accepted invitation"}
                            </td>

                            <td
                              className={classNames(
                                i !== userslist.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-base font-medium sm:pr-6 lg:pr-12"
                              )}
                            >
                              <div className="flex">
                                {el.status ? null : (
                                  <div className="text-primary hover:text-primary">
                                    <PencilIcon
                                      className="mr-4 w-5"
                                      onClick={() =>
                                        this.handleUpdateuser(el.email, el.id)
                                      }
                                    /> edit
                                    <span className="sr-only">, {el.name}</span>
                                  </div>
                                )}
                                <div className="text-danger hover:text-danger">
                                  <TrashIcon
                                    className="w-5 mr-4"
                                    onClick={() => this.handleDeleteuser(el.id)}
                                  /> delete
                                  <span className="sr-only">, {el.name}</span>
                                </div>

                                {el.status ? null : (
                                  <div className="text-primary hover:text-primary">
                                    <ArrowCircleRightIcon
                                      className="w-5"
                                      onClick={() =>
                                        this.ResendInvite(el.email, el.type)
                                      }
                                    /> resend
                                    <span className="sr-only">, {el.name}</span>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null}

          {this.state.showInviteModal ? (
            <Transition.Root show={this.state.showInviteModal} as={Fragment}>
              <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                onClose={() => {
                  this.setState({ showInviteModal: false });
                }}
              >
                <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <div className="relative inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
                      <div className="bg-primary text-white rounded-t-lg p-4">
                        <p className="font-semibold text-lg">Select Teams</p>
                      </div>
                      <div className="p-4">
                        {this.state.teamsList &&
                          this.state.teamsList.map((el, idx) => (
                            <div
                              className="p-2 border-b border-gray-200"
                              key={idx}
                            >
                              <input
                                type="checkbox"
                                id={el.team.id}
                                name={el.team.name}
                                value={el.team.team_uuid}
                                onChange={(e) => {
                                  handleTeamSelection(e, el.team.team_uuid);
                                }}
                                className="rounded-[4px] mr-4"
                              />
                              <label htmlFor={el.team.id}>{el.team.name}</label>
                              <br />
                            </div>
                          ))}
                      </div>
                      <div className="w-full flex justify-end items-center p-2">
                        <button
                          onClick={() => {
                            this.sendInviteObsMngr();
                          }}
                          className="p-2 px-4 rounded-md bg-primary text-white cursor-pointer"
                        >
                          Send Invite
                        </button>
                      </div>
                      {this.state.emailerror ? (
                        <div className="text-danger text-base mt-2 flex">
                          <ExclamationCircleIcon className="h-5 w-5 text-danger"></ExclamationCircleIcon>{" "}
                          <span className="ml-2">Please Enter Valid Email</span>
                        </div>
                      ) : null}
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
          ) : null}
        </div>
        <UpdateUserModal
          open={this.state.updateusermodal}
          value={this.state.updateduseremail}
          onCancelClick={() => this.onModalClose()}
          onFieldChange={(e) => this.onUpdateUserChange(e)}
          onSubmitClick={() => this.onModalUpdateSubmit()}
          submitButtonName={"Submit"}
          cancelButtonName={"Cancel"}
        ></UpdateUserModal>
      </Layout>
     
    );
  }
}
const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

const mapDispatchToProps = (dispatch) => ({
  sendUserInvite: (data) => dispatch(sendUserInvite(data)),
  orgEmails: (name) => dispatch(orgEmails(name)),
  DeleteOrgUser: (email, id) => dispatch(DeleteOrgUser(email, id)),
  updateuseremail: (email, id) => dispatch(updateuseremail(email, id)),
  FetchactiveTeams: (org_uuid) => dispatch(FetchactiveTeams(org_uuid)),
});

InviteUser.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(InviteUser);
