import React, { useState, Fragment } from "react";
import AccessDeniedPNG from "../../assets/accessDenied.png";

import { Dialog, Transition } from "@headlessui/react";
import InputField from "../../Components/InputField";
import { GetOrganizationUUID } from "../../Networks/Auth";

import { ToastContainer, toast } from "react-toastify";
import { MdKeyboardBackspace } from "react-icons/md";
import moment from "moment";

const AccessDenied = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showAskEmail, setShowAskEmail] = useState(true);
  const [showAskPassword, setShowAskPassword] = useState(false);

  const [orgList, setOrgList] = useState([]);

  const [email, setEmail] = useState("");
  const [org, setOrg] = useState("");
  const [password, setPassword] = useState("");

  const getOrganization = (e) => {
    if (email == "") {
      toast.dark("Please Fill All Fields!");
      return;
    }
    fetch("https://api.trypadlock.com/users/organization_require", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setOrgList(data);
        setShowAskEmail(false);
        setShowAskPassword(true);
      });
  };

  const handleLoginSubmit = () => {
    fetch("https://api.trypadlock.com/users/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        org_name: org,
        password: password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        localStorage.setItem("token", data.access_token);
        fetch(localStorage.getItem("apiURL") + "/api/token_verify/", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            marketing_access_key: data.access_token,
            date: moment(new Date()).format("YYYY-MM-DD"),
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            fetch(
              localStorage.getItem("apiURL") +
                `/api/role_check/?marketing_access_key=${localStorage.getItem(
                  "token"
                )}`,
              {
                method: "GET",
              }
            )
              .then((res) => res.json())
              .then((data) => {
                localStorage.setItem("ro", data.message);
              });
            localStorage.setItem("user_email", data.email);
            localStorage.setItem("org_name", data.org_name);
            localStorage.setItem("org_username", data.username);
            GetOrganizationUUID(data.org_name);
            fetch(localStorage.getItem("apiURL") + "/api/token/obtain/", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                uu_id: data.uu_id,
                username: data.username,
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                localStorage.setItem("access_token", data.access);
                localStorage.setItem("refresh_token", data.refresh);
                setShowLoginModal(false);
                window.location.reload();
              });
          });
      });
  };

  return (
    <div className="p-5 overflow-hidden">
      <div className="w-full justify-center items-center mx-20 mt-16">
        <p className="text-xl font-semibold">workahub</p>
      </div>
      <div>
        <div className="mx-44 mt-8">
          <p>
            <MdKeyboardBackspace className="w-10 h-8 cursor-pointer" />
          </p>
        </div>
        <div className="flex justify-center items-center w-full mt-8">
          <div className="flex flex-col w-full justify-center items-center ml-10">
            <div className="justify-start">
              <h2 className="text-4xl font-semibold">
                Login to access this document
              </h2>
              <p className="text-gray-400 mt-4">
                You dont have permission to access the requested page
              </p>
              <div className="mt-8">
                <button
                  className="bg-blue-600 text-white rounded-md p-2 px-6 mr-5 cursor-pointer hover:-translate-y-1 duration-200 hover:shadow-md"
                  onClick={() => {
                    setShowLoginModal(true);
                  }}
                >
                  Login
                </button>
                <button className="text-blue-600 font-semibold hover:text-blue-800">
                  Request Access
                </button>
              </div>
            </div>
          </div>
          <div className="w-full">
            <img src={AccessDeniedPNG} alt="access denied" />
          </div>
        </div>
      </div>
      {showLoginModal ? (
        <Transition.Root show={showLoginModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => {
              setShowLoginModal(true);
            }}
          >
            <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
                  <div className="p-5">
                    <h1 className="text-2xl font-semibold">Login</h1>
                    {showAskEmail ? (
                      <div className="mt-6">
                        <InputField
                          onInputChange={(e) => setEmail(e.target.value)}
                          FieldName={"Email"}
                          type={"text"}
                        />
                        <div className="mt-8">
                          <button
                            onClick={() => {
                              setShowLoginModal(false);
                            }}
                            className="p-1 px-4 rounded-md border border-primary text-primary mr-3 cursor-pointer duration-300 hover:-translate-y-1"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => {
                              getOrganization();
                            }}
                            className="p-1 px-4 rounded-md bg-primary text-white cursor-pointer duration-300 hover:-translate-y-1"
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {showAskPassword ? (
                      <div className="mt-5">
                        <p>Email : {email}</p>
                        <select
                          onChange={(e) => {
                            setOrg(e.target.value);
                          }}
                          className="rounded-md mt-2"
                        >
                          <option className="p-1">Select Organisation</option>
                          {orgList &&
                            orgList.list.map((el, idx) => (
                              <option
                                value={el}
                                key={idx}
                                className="p-1 rounded-sm"
                              >
                                {el}
                              </option>
                            ))}
                        </select>
                        <div className="mt-4">
                          <InputField
                            FieldName={"Password"}
                            type={"password"}
                            onInputChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="mt-8">
                          <button
                            onClick={() => {
                              setEmail("");
                              setOrg("");
                              setPassword("");
                              setShowAskPassword(false);
                              setShowAskEmail(true);
                            }}
                            className="p-1 px-4 rounded-md border border-primary text-primary mr-3 cursor-pointer duration-300 hover:-translate-y-1"
                          >
                            Back
                          </button>
                          <button
                            onClick={handleLoginSubmit}
                            className="p-1 px-4 rounded-md bg-primary text-white cursor-pointer duration-300 hover:-translate-y-1"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      ) : (
        <div></div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
    </div>
  );
};

export default AccessDenied;
