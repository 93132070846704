import { API_ENDPOINTS } from "../../constants";
import { transport } from "../../Networks/Auth";

export default {
    fetchAllRequests: () => {
        let config = {
            url: API_ENDPOINTS.GET_ALL_REQUEST,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    admin_AppRejRequest: (data, offline) => {
        let config = {
            url: offline ? API_ENDPOINTS.UPDATE_OFFLINE_REQUEST : API_ENDPOINTS.UPDATE_REQUEST,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },

    requestOfflineTime: (data) => {
        let config = {
            url: API_ENDPOINTS.REQUEST_OFFLINE_TIME,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
};
