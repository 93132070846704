import { ACTION_TYPES } from '../../../constants';
import api from '../api';

export const fetchactiveuserslist = () => ({
    type: ACTION_TYPES.FETCH_ACTIVE_USERS_LIST,
    payload: api.fetchActiveUsers()
});

export const fetchActiveUsersObserver = () => ({
    type: ACTION_TYPES.FETCH_ACTIVE_USERS_LIST,
    payload: api.fetchActiveUsersObserver()
});

export const fetchInactiveuserslist = () => ({
    type: ACTION_TYPES.FETCH_INACTIVE_USERS_LIST,
    payload: api.fetchInActiveUsers()
});

export const DeleteTeammember = (org_uuid, team_uuid, user_uuid) => ({
    type: ACTION_TYPES.DELETE_TEAM_MEMBER,
    payload: api.deleteteammember(org_uuid, team_uuid, user_uuid)
});
export const DeleteTeammembers = (teamid, userids) => ({
    type: ACTION_TYPES.DELETE_TEAM_MEMBER,
    payload: api.deleteteammembers(teamid, userids),
});
export const DeleteTeammembersPadlock = (teamid, userids) => ({
    type: ACTION_TYPES.DELETE_TEAM_MEMBER,
    payload: api.deleteteammembersPadlock(teamid, userids),
});

export const createWorkstationWorkahub = (data) => ({
    type: ACTION_TYPES.CREATE_WORKSTATION_JOINWORKAHUB,
    payload: api.createWorkstationWorkahub(data)
})

// export const getClientProfileInfo=(id)=>({
//     type: ACTION_TYPES.GET_CLIENT_PROFILE_INFO,
//     payload:api.getClientProfileInfo(id),
// })

// export const addclientToTeam=(client_id,team_id)=>({
//     type:ACTION_TYPES.ADD_CLIENT_TO_TEAM,
//     payload:api.addclientToTeam(client_id,team_id)
// })

export const editClientProfileInfo=(data, id)=>({
    type: ACTION_TYPES.EDIT_CLIENT_PROFILE_INFO,
    payload:api.editClientProfileInfo(data, id),
})

export const createClientWorkahub=(data)=>({
    type:ACTION_TYPES.CREATE_CLIENT_JOINWORKAHUB,
    payload:api.createClientWorkahub(data)
})

export const fetchAllWorkstatons = (email) => ({
    type: ACTION_TYPES.FETCH_WORKSTATIONS_LIST,
    payload: api.fetchAllWorkstatons(email)
});


export const fetchClient = (months, year, status) => {
    console.log("Action Creator - months:", months);
    console.log("Action Creator - year:", year);
    console.log("Action Creator - status:", status);

    return {
        type: ACTION_TYPES.GET_CLIENT,
        payload: api.getclient(months, year, status),
    };
};


export const fetchInactiveClient = (months, year, status) => {
    console.log("Action Creator - months:", months);
    console.log("Action Creator - year:", year);
    console.log("Action Creator - status:", status);

    return {
        type: ACTION_TYPES.GET_INACTIVE_CLIENT,
        payload: api.getinactiveclient(months, year, status),
    };
};

export const deleteClient = (id, status) => ({
    type: ACTION_TYPES.DELETE_CLIENT,
    payload: api.deleteClient(id, status), 
    // onSuccess: (response) => {
    //     // Handle success actions if needed
    // },
    // onError: (error) => {
    //     // Handle error actions if needed
    // },
});

//added for client_profile_info

export const getClientProfileInfo=(id)=>({
    type: ACTION_TYPES.GET_CLIENT_PROFILE_INFO,
    payload:api.getClientProfileInfo(id),
})


export const CreateTeamPadlock = (data) => ({
    type: ACTION_TYPES.CREATE_TEAM,
    payload: api.createTeamPadlock(data),
});
export const CreateTeam = (data) => ({
    type: ACTION_TYPES.CREATE_TEAM,
    payload: api.createTeam(data)
});

export const CreateClient=(data)=>({
    type: ACTION_TYPES.CREATE_CLIENT,
    payload: api.createClient(data),

    onSuccess: (response) => {
        
        localStorage.setItem('clientData', JSON.stringify(response.data));
      },
})


export const fetchAllClients = (email) => ({
    type: ACTION_TYPES.FETCH_CLIENTS_LIST,
    payload: api.fetchAllClients(email)
});

export const CreateClientPadlock = (data) => ({
    type: ACTION_TYPES.CREATE_CLIENT,
    payload: api.createClientPadlock(data),
});


export const createWorkstation = (data) => ({
    type: ACTION_TYPES.CREATE_WORKSTATION,
    payload: api.createWorkstation(data)
});

export const updateWorkstation = (data) => ({
    type: ACTION_TYPES.UPDATE_WORKSTATION,
    payload: api.updateWorkstation(data)
});

export const FetchactiveTeams = (org_uuid) => ({
    type: ACTION_TYPES.ACTIVE_TEAMS,
    payload: api.activeTeams(org_uuid)
});

// export const FetchactiveClients = (org_uuid) => ({
//     type: ACTION_TYPES.ACTIVE_CLIENTS,
//     payload: api.activeClients(org_uuid)
// });


export const FetchInactiveTeams = (org_uuid) => ({
    type: ACTION_TYPES.INACTIVE_TEAMS,
    payload: api.InactiveTeams(org_uuid)
});

// export const FetchInactiveClients = (org_uuid) => ({
//     type: ACTION_TYPES.INACTIVE_CLIENTS,
//     payload: api.InactiveClients(org_uuid)
// });

export const DeleteteamfromPadlock = (org_uuid, team_uuid) => ({
    type: ACTION_TYPES.DELETE_TEAM_PADLOCK,
    payload: api.deleteTeamfrompadlock(org_uuid, team_uuid)
});

export const Deleteteam = (org_uuid, team_uuid) => ({
    type: ACTION_TYPES.DELETE_TEAM,
    payload: api.deleteTeam(org_uuid, team_uuid)
});

export const ReactivateTeam = (org_uuid, team_uuid) => ({
    type: ACTION_TYPES.REACTIVATE_TEAM,
    payload: api.reactivateTeam(org_uuid, team_uuid)
});

export const DeleteUserfromPadlock = (useremail, orgemail) => ({
    type: ACTION_TYPES.DELETE_USER_PADLOCK,
    payload: api.deleteUserfrompadlock(useremail, orgemail)
});

export const DeleteUser = (userloginas) => ({
    type: ACTION_TYPES.DELETE_USER,
    payload: api.deleteUser(userloginas)
});

export const ReactivateUserFromPadlock = (useremail, orgemail) => ({
    type: ACTION_TYPES.REACTIVATE_USER_PADLOCK,
    payload: api.reactivateuserfrompadlock(useremail, orgemail)
});

export const ReactivateUser = (useremail) => ({
    type: ACTION_TYPES.REACTIVATE_USER,
    payload: api.reactivateUser(useremail)
});

export const DeleteWorkstation = (id) => ({
    type: ACTION_TYPES.DELETE_WORKSTATION,
    payload: api.delteWorkstation(id)
});

export const getUsersfromTeam = (id) => ({
    type: ACTION_TYPES.GET_USERS_FROM_TEAM,
    payload: api.getusersfromteam(id)
});


//

export const getClient=(id)=>({
    type: ACTION_TYPES.GET_CLIENT,
    payload: api.getclient(id)
})

export const addclientToTeam=(client_id,team_id)=>({
    type:ACTION_TYPES.ADD_CLIENT_TO_TEAM,
    payload:api.addclientToTeam(client_id,team_id)
})

//

export const addmembertoTeaminPadlock = (data) => ({
    type: ACTION_TYPES.ADD_MEMBER_TO_TEAM_IN_PADLOCK,
    payload: api.addmembertoteaminpadlock(data)
});

export const addmembertoTeam = (data) => ({
    type: ACTION_TYPES.ADD_MEMBER_TO_TEAM,
    payload: api.addmembertoteam(data)
});


export const getEmployeePhoneNumber = (user_uuid) => ({
    type: ACTION_TYPES.GET_EMPLOYEE_PHONE_NUMBER,
    payload: api.getEmployeePhoneNumber(user_uuid)
})

export const editEmployeePhoneNumber = (user_uuid, data) => ({
    type: ACTION_TYPES.EDIT_EMPLOYEE_PHONE_NUMBER,
    payload: api.editEmployeePhoneNumber(user_uuid, data)
})

export const editEmployeeBasicInfo = (data) => ({
    type: ACTION_TYPES.EDIT_EMPLOYEE_BASIC_INFO,
    payload: api.editEmployeeBasicInfo(data)
})

export const getUserBasedProjects = (username) => ({
    type: ACTION_TYPES.GET_USERBASED_PROJECTS,
    payload: api.getUserBasedProjects(username)
})

export const removeUserbasedProject = (data) => ({
    type: ACTION_TYPES.REMOVE_USERBASED_PROJECTS,
    payload: api.removeUserbasedProject(data)
})

export const addUserbasedProject = (data) => ({
    type: ACTION_TYPES.ADD_USERBASED_PROJECTS,
    payload: api.addUserbasedProject(data)
})

export const getObserverList = () => ({
    type: ACTION_TYPES.GET_OBSERVERS_LIST,
    payload: api.getObserverList()
})

// export const updateObserverList=(obsdata)=>({
//     type:ACTION_TYPES.UPDATE_OBSERVERS_LIST,
//     payload:obsdata
// })

export const getManagerList = () => ({
    type: ACTION_TYPES.GET_MANAGERS_LIST,
    payload: api.getManagerList()
})

export const activeTeamObserver = (data) => ({
    type: ACTION_TYPES.ACTIVE_TEAMS,
    payload: api.activeTeamObserver(data)
})

export const getEmployeePayrollData = (user_uuid) => ({
    type: ACTION_TYPES.GET_PAYROLL_DATA,
    payload: api.getPayrollData(user_uuid),
});

export const postEmployeePayrollData = (data) => ({
    type: ACTION_TYPES.UPDATE_PAYROLL_DATA,
    payload: api.postPayrollData(data),
});

export const updateEmployeePayrollData = (data) => ({
    type: ACTION_TYPES.UPDATE_PAYROLL_DATA,
    payload: api.updatePayrollData(data),
});

export const getSalaryDaysData = (params) => ({
    type: ACTION_TYPES.GET_SALARY_DAYS_DATA,
    payload: api.getSalaryDaysData(params),
});

export const getSalaryDayDownload = (params) => ({
    type: ACTION_TYPES.GET_SALARY_DAY_DOWNLOAD,
    payload: api.getSalaryDayDownload(params),
});

export const getSalaryYearData = (params) => ({
    type: ACTION_TYPES.GET_SALARY_YEAR_DATA,
    payload: api.getSalaryYearData(params),
});
export const getSalaryHistory = (user_id) => ({
    type: ACTION_TYPES.GET_SALARY_HISTORY,
    payload: api.getSalaryHistory(user_id),
});
export const getClientOverview = (date, user_id, month, year) => ({
    type: ACTION_TYPES.GET_CLIENT_OVERVIEW,
    payload: api.getClientOverview(date, user_id, month, year),
});
export const getClientTeam = (date, user_id) => ({
    type: ACTION_TYPES.GET_CLIENT_TEAM,
    payload: api.getClientTeam(date, user_id),
});
export const getClientBillingHistory = (user_id, year) => ({
    type: ACTION_TYPES.GET_CLIENT_BILLING_HISTORY,
    payload: api.getClientBillingHistory(user_id, year),
});
export const getClientBillingInvoice = (user_id, year, month) => ({
    type: ACTION_TYPES.GET_CLIENT_INVOICE,
    payload: api.getClientBillingInvoice(user_id, year, month),
});
export const getClientTeamDetailed = (user_id, team_name, date) => ({
    type: ACTION_TYPES.GET_CLIENT_TEAM_DETAILED,
    payload: api.getClientTeamDetailed(user_id, team_name, date),
});
export const getClientBillingData = (year, month) => ({
    type: ACTION_TYPES.GET_CLIENT_TEAM_DETAILED,
    payload: api.getClientBillingData(year, month),
});
export const downloadInvoice = (data) => ({
    type: ACTION_TYPES.INVOICE_ACTION,
    payload: api.downloadInvoice(data),
});
export const sendInvoiceEmail = (data) => ({
    type: ACTION_TYPES.INVOICE_ACTION,
    payload: api.sendInvoiceEmail(data),
});
