/* eslint-disable import/no-anonymous-default-export */
import { API_ENDPOINTS } from "../../constants";
import { transport } from "../../Networks/Auth";

export default {
    getBlockedUrls: (params) => {
        let config = {
            url: API_ENDPOINTS.BLOCKED_URLS + params,
            method: "GET",
        };
        return transport(config);
    },

    updateBlockedUrls: (data) => {
        let config = {
            url: API_ENDPOINTS.BLOCKED_URLS,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },

    postBlockedUrls: (data) => {
        let config = {
            url: API_ENDPOINTS.BLOCKED_URLS,
            method: "POST",
            data: data,
            withCredentials: true,
        };
        return transport(config);
    },
    deleteBlockedUrls: (data) => {
        let config = {
            url: API_ENDPOINTS.BLOCKED_URLS,
            method: "DELETE",
            data: data,
            withCredentials: true,
        };
        return transport(config);
    },
};
