import React, { Component, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { connect } from "react-redux";
import Layout from "../../../Components/Layout";
import { fetchAllRequests } from "../../../services/requests/actions";
import { fetchactiveTasks } from "../../../services/projects/actions";
import {
    createLeaveRequest,
    deleteLeaveRequest,
} from "../../../services/employee/actions";

import sickSvg from "../../../images/icons/sick.svg";
import emergencySvg from "../../../images/icons/emergency.svg";
import vacationSvg from "../../../images/icons/vacation.svg";
import workfromHomeSvg from "../../../images/icons/work_from_home.svg";
import offlineSvg from "../../../images/icons/offline.svg";
import partialSvg from "../../../images/icons/partial.svg";
import hrSvg from "../../../images/icons/hr.svg";
import nightShiftSvg from "../../../images/icons/overtime.svg";
import salaryrevisionSvg from "../../../images/icons/revision.svg";
import equipmentSvg from "../../../images/icons/equipment.svg";
import teamhelpSvg from "../../../images/icons/support.svg";
import noDataSVG from "../../../images/nodata.svg";
import idelTime from "../../../assets/idelTime.png";
import SelectField from "../../../Components/SelectField";
import caseIcon from '../../../assets/thumb.png'
import { CalendarIcon, TrashIcon, BriefcaseIcon } from "@heroicons/react/outline";

import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import moment from "moment";
import InputField from "../../../Components/InputField";
import DateRangeFilter from "../../Reports/tabs/DatePicker";
import Dropzone from "react-dropzone";
import ConfirmationDialogue from "../../../Components/ConfirmationDialogue";
import EmployeeTimeTracker from "../../TimeTracker/employee_timeTracker/index";
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Modaltracker } from "../../TimeTracker/modalTracker";
import { Divider } from "@material-ui/core";
import { CheckIcon } from "@heroicons/react/outline";
import {
    timeTrackerData,
    timeTrackerDataWithoutProject,
    customReportWeekglyGraphdata,
    weeklyChartActivity,
    getProjectList,
    requestProjectChange,
    getShowcaseEntries,
    getEmployeeMoods,
    getOfflineData,
} from "../../../services/timetracker/actions";
import {
    fetchEmpLoginDetail,
    getUserProject,
} from "../../../services/employee/actions";

import { getCategoriesData } from "../../../services/projects/actions";

import { FetchactiveTeams } from "../../../services/teams/actions";
import { orgEmails } from "../../../services/dashboard/actions";
import { requestOfflineTime } from "../../../services/requests/actions";
import { ModalProjectSelect } from "../../TimeTracker/employee_timeTracker/modalProjectSelect";
import { active } from "sortablejs";
const kleavesList = [
    { icon: sickSvg, type: "Sick", color: "red-500" },
    { icon: emergencySvg, type: "Family Emergency", color: "blue-600" },
    { icon: vacationSvg, type: "Vacation Leave", color: "yellow-500" },
    { icon: workfromHomeSvg, type: "Work from home", color: "gray-600" },
    { icon: partialSvg, type: "Partial", color: "yellow-500" },
    { icon: hrSvg, type: "HR", color: "green-700" },
    { icon: nightShiftSvg, type: "Night Shift", color: "red-500" },
    { icon: salaryrevisionSvg, type: "Salary Revision", color: "blue-600" },
    { icon: teamhelpSvg, type: "Team Help", color: "gray-600" },
    { icon: equipmentSvg, type: "Equipment", color: "yellow-500" },
    { icon: offlineSvg, type: "Request Offline Time", color: "gray-600" },
];

const leavelist = [
    { icon: sickSvg, type: "Sick", color: "red-500" },
    { icon: emergencySvg, type: "Family Emergency", color: "blue-600" },
    { icon: vacationSvg, type: "Vacation Leave", color: "yellow-500" },
    { icon: workfromHomeSvg, type: "Work from home", color: "gray-600" },
    { icon: offlineSvg, type: "Request Offline Time", color: "gray-600" },
];

const requestTypes = [
    { type: "Sick" },
    { type: "Family Emergency" },
    { type: "Vacation Leave" },
    { type: "Work from home" },
    { type: "Request Offline Time" },
    { type: "Night Shift" },
    { type: "Partial" },
    { type: "HR" },
    { type: "Equipment" },
    { type: "Salary Revision" },
    { type: "Team Help" },
];
const status = [
    { status: "Approved" },
    { status: "Rejected" },
    { status: "Pending" },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

class Employee_genericReq extends Component {
    constructor() {
        super();
        this.state = {
            selectedDate: new Date(),
            selectedRequest: {},
            showAllRequest: false,
            showReqDetails: false,
            initialData: [],
            filteredData: [],
            showDateRange: false,
            showDeleteConfirm: false,
            showScreenshots: false,
            showReqs: true,
            startDate: new Date(),
            selectedDate: new Date(),
            employeename: "",
            projectname: "",
            currentDate: new Date(),
            lastDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
            Timetracker: [],
            Timetrackerkeys: [],
            OfflineTimetracker: [],
            OfflineTimetrackerkeys: [],
            screenshotkpis: [],
            timeArray: [],
            percentages: [],
            weekGraphDate: [],
            weekGraphActIndex: [],
            weeklychartData: [],
            isScreenshotSelectActive: false,
            isScreenshotSelectActiveOffline: true,
            updateProjectStage: false,
            selectedSSid: [],
            showProjModal: false,
            startTimeSelect: [],
            endTimeSelect: [],
            selectedProject: "",
            userName: "",
            isShowcaseModalActive: false,
            showcaseEntriesList: [],
            isShowAllEntries: false,
            moodData: "",
            project: "",
            showCreateNewReq_Type: false,
            showCreateNewReq_Date: false,
            showCreateNewReq_Reason: false,
            showCreateNewReq_File: false,
            teamsList: [],
            selectedReq_type: "",
            reqReason: "",
            selectedFile: null,
            selectedReqID: "",
            selectedTeam: "",
            startDate: new Date(),
            endDate: new Date(),
            domain_name: "",
            selectedTaskData: "",
            myActiveTasks: [],
            selectedProject_id: null,
            selectedTeamUUID: "",
            inactiveScreenshotState: "Inactive",
            categoriesData: [],
            selectedCategorykData: "",
            selectedReqType:'',
            reqSort:false,
            selectStatus:'',
            statusSort:false,
        };
    }
    componentDidMount() {
        const url = window.location.href;
        this.setState({
            domain_name: url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0],
        });
        let orgname = localStorage.getItem("org_name");
        this.props.orgEmails(orgname);
        let org_uuid = localStorage.getItem("org_uuid");
        this.props.FetchactiveTeams(org_uuid).then((res) => {
            this.setState({ teamsList: res.value.data.info });
        });
        this.props.getOfflineData().then((res) => {
            this.setState({
                OfflineTimetracker: Object.values(res.value.data.data),
                OfflineTimetrackerkeys: Object.keys(res.value.data.data),
            });
        });

        this.fetchAllRequests();
        this.props.fetchEmpLoginDetail().then((data) => {
            if (data.value.status === 200) {
                this.setState(
                    { employeename: data.value.data.user_uuid },

                    () => {
                        this.fetchTimeTrackerData();
                       // console.log(this.state.employeename, "here");
                        this.props
                            .getEmployeeMoods(moment(this.state.selectedDate).format("YYYY-MM-DD"))
                            .then((res) => {
                                if (
                                    res.value.status === 200 &&
                                    res.value.data.data !== "no data present"
                                ) {
                                    this.setState({
                                        moodData: res.value.data.data,
                                    });
                                }
                            });
                        this.props
                            .getShowcaseEntries(
                                moment(this.state.selectedDate).format("YYYY-MM-DD"),
                                `user&user_id=${data.value.data.user_uuid}`
                            )
                            .then((res) => {
                                if (res.value.status === 200 && !res.value.data.message) {
                                    this.setState({
                                        userName: data.value.data.username,
                                        showcaseEntriesList: res.value.data.data,
                                    });
                                }
                            });
                    }
                );
            }
        });
        this.props.getProjectList();
        this.props.getUserProject();
    }

    // handleActiveTasksData = () => {
    //     this.props
    //         .fetchactiveTasks()
    //         .then((res) => {
    //             if (res.value.status === 200) {
    //                 const activeTasksData = res.value.data;
    //                 const activeTaskNames = activeTasksData.map(
    //                     (task) => task.name
    //                 );
    //                 const activeTaskNamesList = activeTaskNames
    //                     ? activeTaskNames
    //                     : null;
    //                 this.setState({
    //                     myActiveTasks: activeTaskNamesList,
    //                 });
    //                 console.log(
    //                     activeTaskNamesList,
    //                     this.state.myActiveTasks,
    //                     "stateTask"
    //                 );
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });

    //     console.log(this.state.myActiveTasks, "end");
    // };

    handleActiveTasksData = async () => {
        try {
            const res = await this.props.fetchactiveTasks();
            if (res.value.status === 200) {
                const activeTasksData = res.value.data;
                const activeTaskNames = activeTasksData.map((task) => task.name);
                const activeTaskNamesList = activeTaskNames ? activeTaskNames : null;
                this.setState({
                    myActiveTasks: activeTaskNamesList,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    postRequestOfflineTime = async () => {
        let startTimeArr = [...this.state.startTimeSelect];
        let endTimeArr = [...this.state.endTimeSelect];
        const date = moment(this.state.startDate).format("YYYY-MM-DD");
        const data = {
            type: "offline",
            startDate: date,
            start_time: startTimeArr.sort()[0],
            end_time: endTimeArr.sort()[endTimeArr.length - 1],
            activity: "default",
            project_name: JSON.parse(this.state.selectedProject).project_name,
            project_id: JSON.parse(this.state.selectedProject).id,
            category: this.state.selectedCategorykData,
            task: this.state.selectedTaskData,
            team: this.state.selectedTeamUUID,
        };

        this.props
            .getProjectList()
            .then((response) => {
                if (response.value.status === 200) {
                    const projectName = this.state.selectedProject;
                    const project = response.value.data.find(
                        (item) => item.project_name === projectName
                    );
                    const projectId = project ? project.id : null;
                    this.setState({
                        selectedProject_id: projectId,
                    });
                    data.project_id = projectId;
                }
            })
            .catch((error) => {
                console.error(error);
            });

        const teams = this.props.teams.ActiveTeam.info;
        for (let i = 0; i < teams.length; i++) {
            const team = teams[i];
            if (team.team.name === this.state.selectedTeam) {
                this.setState({
                    selectedTeamUUID: team.team.team_uuid,
                });
                data.team = team.team.team_uuid;
                break; // Exit the loop once a match is found
            }
        }

        this.props
            .requestOfflineTime(data)
            .then((res) => {
                if (res.value.status === 200) {
                    toast("Request created successfully");
                    this.setState({
                        showScreenshots: false,
                        showCreateNewReq_Date: false,
                        showCreateNewReq_Reason: false,
                        showCreateNewReq_File: false,
                        showCreateNewReq_Type: false,
                        showReqs: true,
                        selectedFile: null,
                        selectedReq_type: "",
                        reqReason: "",
                    });
                    this.fetchAllRequests();
                }
            })
            .catch((err) => {
                console.log(console.error(err));
                toast("Something went wrong");
            });

        this.setState({
            isScreenshotSelectActiveOffline: true,
        });
    };

    fetchTimeTrackerData = () => {
        if (this.state.projectname === "") {
            this.props
                .timeTrackerDataWithoutProject(
                    moment(this.state.startDate).format("YYYY-MM-DD"),
                    this.state.employeename
                )
                .then((res) => {
                    if (res.value.status === 200) {
                        this.setState({
                            screenshotkpis: res.value.data.track_report,
                        });
                        let response = res.value.data;
                        delete response["track_report"];

                        const hourlyPercantageArray = Object.values(res.value.data).map(
                            (item, idx) => {
                                return item.hourly_percentage;
                            }
                        );

                        let percentages = hourlyPercantageArray.map(
                            (item, idx) => Math.round(item * 100) / 100
                        );
                        let modifiedHours = Object.keys(response).map(Number);
                        let timingArray = modifiedHours.map((num) => {
                            if (num > 12) {
                                let hour = num - 12;
                                return `${hour} PM`;
                            } else if (num === 12) {
                                return "12 PM";
                            } else if (num === 0) {
                                return "12 AM";
                            } else {
                                return `${num} AM`;
                            }
                        });
                        this.setState({
                            timeArray: timingArray,
                            percentages: percentages,
                            Timetracker: Object.values(res.value.data),
                            Timetrackerkeys: Object.keys(response),
                        });
                    } else {
                        toast("Something went wrong");
                    }
                });
        } else {
            this.props
                .timeTrackerData(
                    moment(this.state.startDate).format("YYYY-MM-DD"),
                    this.state.projectname,
                    this.state.employeename
                )
                .then((res) => {
                    if (res.value.status === 200) {
                        this.setState({
                            screenshotkpis: res.value.data.track_report,
                        });
                        let response = res.value.data;
                        delete response["track_report"];

                        const hourlyPercantageArray = Object.values(res.value.data).map(
                            (item, idx) => {
                                return item.hourly_percentage;
                            }
                        );

                        let percentages = hourlyPercantageArray.map(
                            (item, idx) => Math.round(item * 100) / 100
                        );
                        let modifiedHours = Object.keys(response).map(Number);
                        let timingArray = modifiedHours.map((num) => {
                            if (num > 12) {
                                let hour = num - 12;
                                return `${hour} PM`;
                            } else if (num === 12) {
                                return "12 PM";
                            } else if (num === 0) {
                                return "12 AM";
                            } else {
                                return `${num} AM`;
                            }
                        });
                        this.setState({
                            timeArray: timingArray,
                            percentages: percentages,
                            Timetracker: Object.values(res.value.data),
                            Timetrackerkeys: Object.keys(response),
                        });
                    } else {
                        toast("Something went wrong");
                    }
                });
        }
    };

    fetchCateGoriesData = () => {
        const categoryData = {
            user_id: this.state.employeename,
            name: JSON.parse(this.state.selectedProject).project_name,
        };
        this.props.getCategoriesData(categoryData).then((res) => {
            if (res.value.status === 200) {
              //  console.log(res.value.data.data, "category");
                this.setState({
                    categoriesData: res.value.data.data,
                });
              //  console.log(this.state.categoriesData, "state data");
            }
        });
    };

    IdleTime = (time, min, time_min, text, items, index, value, index1) => {
        let row = [require("../../../assets/idelTime.png")];
        return (
            <Grid item xs={12} sm={5} md={2}>
                <div
                    style={{ width: "163px" }}
                    className={
                        "relative" +
                        (this.state.isScreenshotSelectActiveOffline ? " hover:cursor-pointer" : "")
                    }
                    onClick={
                        this.state.isScreenshotSelectActiveOffline
                            ? () => {
                                  this.HandleTimeSelect(items, index, min, value, index1);
                              }
                            : () => {}
                    }>
                    {this.state.isScreenshotSelectActiveOffline ? (
                        <div className="absolute z-10 top-1 right-1 w-5 h-5 rounded-full bg-gray">
                            <CheckIcon
                                className={
                                    this.state.selectedSSid.includes(
                                        moment(
                                            `${
                                                parseInt(this.state.OfflineTimetrackerkeys[index]) +
                                                Math.floor(parseInt(min) / 60)
                                            } : ${parseInt(min) % 60}`,
                                            ["HH:mm"]
                                        ).format("HH:mm")
                                    )
                                        ? "text-primary font-bold"
                                        : "text-white font-bold"
                                }
                            />
                        </div>
                    ) : null}
                    <div className="idel_time">
                        <p className="idel_text">{text}</p>
                    </div>
                </div>
            </Grid>
        );
    };

    HandleTimeSelect = (items, index, time_min, value, index1) => {
        let time = moment(
            `${
                parseInt(this.state.OfflineTimetrackerkeys[index]) +
                Math.floor(parseInt(time_min) / 60)
            } : ${parseInt(time_min) % 60}`,
            ["HH:mm"]
        ).format("HH:mm");

        let time1 = moment(
            `${
                parseInt(this.state.OfflineTimetrackerkeys[index]) +
                Math.floor(parseInt(value[index1]) / 60)
            } : ${parseInt(value[index1]) % 60}`,
            ["HH:mm"]
        ).format("HH:mm");
        let screenshotIdx = this.state.selectedSSid.indexOf(time);
        if (screenshotIdx === -1) {
            this.setState({
                selectedSSid: [...this.state.selectedSSid, time],
                startTimeSelect: [...this.state.startTimeSelect, time],
                endTimeSelect: [...this.state.endTimeSelect, time1],
            });
        } else {
            let tmpArr = [...this.state.selectedSSid];
            let tmpArrStartTime = [...this.state.startTimeSelect];
            let tmpArrEndTime = [...this.state.endTimeSelect];
            tmpArr.splice(screenshotIdx, 1);
            tmpArrStartTime.splice(screenshotIdx, 1);
            tmpArrEndTime.splice(screenshotIdx, 1);
            this.setState({
                selectedSSid: tmpArr,
                startTimeSelect: tmpArrStartTime,
                endTimeSelect: tmpArrEndTime,
            });
        }
        if (this.state.selectedProject === "") {
            this.setState({
                selectedProject: {
                    name: items.project,
                    id: parseInt(items.project_id),
                },
            });
        }
    };
    handleScreenshotDateChange = (date) => {
        if (date === "" || date === undefined) {
            if (this.state.employeename === "") {
            } else if (this.state.projectname === "") {
                let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                this.props
                    .timeTrackerDataWithoutProject(date, this.state.employeename)
                    .then((res) => {
                        this.setState({
                            screenshotkpis: res.value.data.track_report,
                        });
                        let response = res.value.data;
                        delete response["track_report"];
                        const hourlyPercantageArray = Object.values(res.value.data).map(
                            (item, i) => {
                                return item.hourly_percentage;
                            }
                        );
                        let percentages = hourlyPercantageArray.map(
                            (x) => Math.round(x * 100) / 100
                        );
                        let modifiedHours = Object.keys(response).map(Number);
                        let timingArray = modifiedHours.map((num) => {
                            if (num > 12) {
                                let hour = num - 12;
                                return `${hour} PM`;
                            } else if (num === 12) {
                                return "12 PM";
                            } else if (num === 0) {
                                return "12 AM";
                            } else {
                                return `${num} AM`;
                            }
                        });
                        this.setState({
                            percentages: percentages,
                            timingArray: timingArray,
                            Timetracker: Object.values(res.value.data),
                            Timetrackerkeys: Object.keys(response),
                        });
                    });
            } else {
                let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                this.props
                    .timeTrackerData(date, this.state.projectname, this.state.employeename)
                    .then((res) => {
                        this.setState({
                            screenshotkpis: res.value.data.track_report,
                        });
                        let response = res.value.data;
                        delete response["track_report"];
                        const hourlyPercantageArray = Object.values(res.value.data).map(
                            (item, i) => {
                                return item.hourly_percentage;
                            }
                        );
                        let percentages = hourlyPercantageArray.map(
                            (x) => Math.round(x * 100) / 100
                        );
                        let modifiedHours = Object.keys(response).map(Number);
                        let timingArray = modifiedHours.map((num) => {
                            if (num > 12) {
                                let hour = num - 12;
                                return `${hour} PM`;
                            } else if (num === 12) {
                                return "12 PM";
                            } else if (num === 0) {
                                return "12 AM";
                            } else {
                                return `${num} AM`;
                            }
                        });
                        this.setState({
                            percentages: percentages,
                            timingArray: timingArray,
                            Timetracker: Object.values(res.value.data),
                            Timetrackerkeys: Object.keys(response),
                        });
                    });
            }
        } else {
            this.setState({ selectedDate: date }, () => {
                if (this.state.employeename === "") {
                } else if (this.state.projectname === "") {
                    let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                    this.props
                        .timeTrackerDataWithoutProject(date, this.state.employeename)
                        .then((res) => {
                            this.setState({
                                screenshotkpis: res.value.data.track_report,
                            });
                            let response = res.value.data;
                            delete response["track_report"];
                            const hourlyPercantageArray = Object.values(res.value.data).map(
                                (item, i) => {
                                    return item.hourly_percentage;
                                }
                            );
                            let percentages = hourlyPercantageArray.map(
                                (x) => Math.round(x * 100) / 100
                            );
                            let modifiedHours = Object.keys(response).map(Number);
                            let timingArray = modifiedHours.map((num) => {
                                if (num > 12) {
                                    let hour = num - 12;
                                    return `${hour} PM`;
                                } else if (num === 12) {
                                    return "12 PM";
                                } else if (num === 0) {
                                    return "12 AM";
                                } else {
                                    return `${num} AM`;
                                }
                            });
                            this.setState({
                                percentages: percentages,
                                timingArray: timingArray,
                                Timetracker: Object.values(res.value.data),
                                Timetrackerkeys: Object.keys(response),
                            });
                        });
                } else {
                    let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                    this.props
                        .timeTrackerData(date, this.state.projectname, this.state.employeename)
                        .then((res) => {
                            this.setState({
                                screenshotkpis: res.value.data.track_report,
                            });
                            let response = res.value.data;
                            delete response["track_report"];
                            const hourlyPercantageArray = Object.values(res.value.data).map(
                                (item, i) => {
                                    return item.hourly_percentage;
                                }
                            );
                            let percentages = hourlyPercantageArray.map(
                                (x) => Math.round(x * 100) / 100
                            );
                            let modifiedHours = Object.keys(response).map(Number);
                            let timingArray = modifiedHours.map((num) => {
                                if (num > 12) {
                                    let hour = num - 12;
                                    return `${hour} PM`;
                                } else if (num === 12) {
                                    return "12 PM";
                                } else if (num === 0) {
                                    return "12 AM";
                                } else {
                                    return `${num} AM`;
                                }
                            });
                            this.setState({
                                percentages: percentages,
                                timingArray: timingArray,
                                Timetracker: Object.values(res.value.data),
                                Timetrackerkeys: Object.keys(response),
                            });
                        });
                }
                if (this.state.employeename) {
                    this.setState({ showcaseEntriesList: [] });
                    this.props
                        .getShowcaseEntries(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            `user&user_id=${this.state.employeename}`
                        )
                        .then((res) => {
                            if (res.value.status === 200 && !res.value.data.message) {
                                this.setState({
                                    showcaseEntriesList: res.value.data.data,
                                });
                            }
                        });
                }
            });
        }
    };

    onProjectScreenshotChange = (e) => {
        this.setState({ project: e.target.value }, () => {
            if (this.state.projectname === "") {
                let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                this.props
                    .timeTrackerDataWithoutProject(date, this.state.employeename)
                    .then((res) => {
                        this.setState({
                            screenshotkpis: res.value.data.track_report,
                        });
                        let response = res.value.data;
                        delete response["track_report"];
                        const hourlyPercantageArray = Object.values(res.value.data).map(
                            (item, i) => {
                                return item.hourly_percentage;
                            }
                        );
                        let percentages = hourlyPercantageArray.map(
                            (x) => Math.round(x * 100) / 100
                        );
                        let modifiedHours = Object.keys(response).map(Number);
                        let timingArray = modifiedHours.map((num) => {
                            if (num > 12) {
                                let hour = num - 12;
                                return `${hour} PM`;
                            } else if (num === 12) {
                                return "12 PM";
                            } else if (num === 0) {
                                return "12 AM";
                            } else {
                                return `${num} AM`;
                            }
                        });
                        this.setState({
                            percentages: percentages,
                            timingArray: timingArray,
                            Timetracker: Object.values(res.value.data),
                            Timetrackerkeys: Object.keys(response),
                        });
                    });
            } else {
                let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                this.props
                    .timeTrackerData(date, this.state.projectname, this.state.employeename)
                    .then((res) => {
                        this.setState({
                            screenshotkpis: res.value.data.track_report,
                        });
                        let response = res.value.data;
                        delete response["track_report"];
                        const hourlyPercantageArray = Object.values(res.value.data).map(
                            (item, i) => {
                                return item.hourly_percentage;
                            }
                        );
                        let percentages = hourlyPercantageArray.map(
                            (x) => Math.round(x * 100) / 100
                        );
                        let modifiedHours = Object.keys(response).map(Number);
                        let timingArray = modifiedHours.map((num) => {
                            if (num > 12) {
                                let hour = num - 12;
                                return `${hour} PM`;
                            } else if (num === 12) {
                                return "12 PM";
                            } else if (num === 0) {
                                return "12 AM";
                            } else {
                                return `${num} AM`;
                            }
                        });
                        this.setState({
                            percentages: percentages,
                            timingArray: timingArray,
                            Timetracker: Object.values(res.value.data),
                            Timetrackerkeys: Object.keys(response),
                        });
                    });
            }
        });
    };

    fetchAllRequests = () => {
        this.props.fetchAllRequests().then((data) => {
            if (data.value.status === 200) {
                this.setState({
                    initialData: data.value.data,
                    filteredData: data.value.data,
                });
            } else {
                toast("Something went wrong");
            }
        });
    };

    HandleOfflineTimeRequest = () => {
        let startTimeArr = [...this.state.startTimeSelect];
        let endTimeArr = [...this.state.endTimeSelect];
        const data = {
            start_time: startTimeArr.sort()[0],
            end_time: endTimeArr.sort()[endTimeArr.length - 1],
            activity: "workahub",
            project_name:
                this.state.selectedProject.name === ""
                    ? "workahub"
                    : this.state.selectedProject.name,
            project_id: !this.state.selectedProject.id ? 1 : this.state.selectedProject.id,
            startDate: moment(this.state.selectedDate).format("YYYY-MM-DD"),
            type: "offline",
        };
        this.props.requestProjectChange(data).then((res) => {
            if (res.value.status === 200) {
                this.fetchTimeTrackerData();
            }
            this.setState({
                selectedSSid: [],
                selectedProject: "",
                startTimeSelect: [],
                endTimeSelect: [],
            });
        });
    };
    progressActivity = (items) => {
        let activity = [];
        let lengthImage = items && items.length > 0 ? items.length : 0;
        lengthImage = lengthImage < 5 ? lengthImage : 5;
        for (let i = 0; i < lengthImage; i++) {
            activity.push(
                <div>
                    <Grid className="row" style={{ borderRadius: "2px" }}>
                        <img alt="" src={items[i]} style={{ width: "24px", height: "24px" }} />
                    </Grid>
                </div>
            );
        }

        return activity;
    };

    render() {
        const { t } = this.props;

        const listOfreq1 = requestTypes.map((el) => ({
            name: el.type,
        }));
        const myActiveTasks = this.state.myActiveTasks.map((el) => ({
            name: el,
        }));
        const categoriesData = this.state.categoriesData.map((el) => ({
            name: el,
        }));

        const listOfreq = listOfreq1.filter((el) => el.name.toLowerCase() !== "offline");
        const listOfStatus = status.map((el) => ({
            name: el.status,
        }));
        let projects = this.props.timetracker && this.props.timetracker.projectList;
        let projectList =
            projects === undefined ||
            projects === "" ||
            projects.length === 0 ||
            !Array.isArray(projects)
                ? []
                : projects.map((el) => ({
                      name: el.project_name,
                      id: JSON.stringify(el),
                  }));
       // console.log(projectList)
        let teams =
            this.props.teams && this.props.teams.ActiveTeam && this.props.teams.ActiveTeam.info;
        let TeamData =
            teams &&
            teams.map((el) => ({
                name: el.team.name,
                id: el.team.name,
            }));

        const pendingReq =
            this.state.initialData &&
            this.state.initialData.filter((el) => el.status === "pending");
        const approvedReq =
            this.state.initialData &&
            this.state.initialData.filter((el) => el.status === "Approved");
        const rejectedReq =
            this.state.initialData &&
            this.state.initialData.filter((el) => el.status === "Rejected");
        const emergencyReq =
            this.state.initialData &&
            this.state.initialData.filter((el) => el.status === "Emergency");

        const onChange = (ranges) => {
            let dd = moment(ranges.startDate).format("YYYY-MM-DD");
            let rr = moment(ranges.endDate).format("YYYY-MM-DD");
            this.setState({ startDate: dd, endDate: rr });
        };

        const handleSelectRequestType = (e) => {
            const filteredType = this.state.initialData.filter((request) => {
                if(e.target.value === 'Request Offline Time'){
                    return request.type === 'offline'
                }
                return request.type === e.target.value;
            });
            if (e.target.value === "") {
                this.setState({
                    filteredData: this.state.initialData,
                    reqSort:false,
                });
            } else {
                this.setState({
                    selectedReqType: filteredType,
                    reqSort:true,
                });
            }
        };

        const handleStatusRequest = (e) => {
            const filteredStatus = this.state.initialData.filter((request) => {
                return request.status.toLowerCase() === e.target.value.toLowerCase();
            });
            if (e.target.value === "") {
                this.setState({
                    filteredData: this.state.initialData,
                    statusSort:false,
                });
            } else {
                this.setState({
                    selectStatus: filteredStatus,
                    statusSort:true,
                });
            }
        };

        const handledateChange = (date) => {
            this.setState({ selectedDate: date }, () => {
                const filteredDate = this.state.initialData.filter((request) => {
                    return (
                        moment(request.created).format("YYYY-MM-DD") ===
                        moment(date).format("YYYY-MM-DD")
                    );
                });
                this.setState({
                    filteredData:
                        this.state.selectedDate == null ? this.state.initialData : filteredDate,
                });
            });
        };

        const handleFileDrop = (files) => {
            this.setState({ selectedFile: files });
        };

        const handleCloseCreateNewReq = () => {
            this.setState({
                showCreateNewReq_Date: false,
                showCreateNewReq_Reason: false,
                showCreateNewReq_File: false,
                showCreateNewReq_Type: false,
                showReqs: true,
            });
            this.setState({
                selectedFile: null,
                selectedReq_type: "",
                reqReason: "",
            });
        };

        const handleCreateNewReq_type = () => {
            if (this.state.selectedReq_type === "") {
                toast("Please select a request type");
                return;
            }
            if (this.state.selectedReq_type !== "Request Offline Time") {
                this.setState({
                    showCreateNewReq_Date: true,
                    showCreateNewReq_Type: false,
                    showReqs: false,
                    showCreateNewReq_Reason: false,
                    showCreateNewReq_File: false,
                });
            } else {
                this.setState({
                    showCreateNewReq_Date: false,
                    showCreateNewReq_Type: false,
                    showReqs: false,
                    showCreateNewReq_Reason: true,
                    showCreateNewReq_File: false,
                });
            }
        };

        const handleCreateNewReq_date = () => {
            this.setState({
                showCreateNewReq_Date: false,
                showCreateNewReq_Type: false,
                showReqs: false,
                showCreateNewReq_Reason: true,
                showCreateNewReq_File: false,
            });
        };

        const handleCreateNewReq_reason = () => {
            if (this.state.reqReason === "") {
                toast("Please enter a reason");
                return;
            }
            this.setState({
                showCreateNewReq_Date: false,
                showCreateNewReq_Type: false,
                showReqs: false,
                showCreateNewReq_Reason: false,
                showCreateNewReq_File: true,
            });

            if (this.state.selectedReq_type === "Request Offline Time") {
                this.setState({
                    showCreateNewReq_Date: false,
                    showCreateNewReq_Type: false,
                    showReqs: false,
                    showCreateNewReq_Reason: false,
                    showCreateNewReq_File: false,
                    showScreenshots: true,
                });
               
            }
        };

        const submitLeaveRequest = () => {
            let leave_req_data = new FormData();

            if (this.state.selectedFile) {
                for (const file of this.state.selectedFile) {
                    leave_req_data.append("file", file);
                }
            }
            leave_req_data.append("type", this.state.selectedReq_type);
            leave_req_data.append("info", this.state.reqReason);
            leave_req_data.append("startDate", moment(this.state.startDate).format("YYYY-MM-DD"));
            leave_req_data.append("endDate", moment(this.state.endDate).format("YYYY-MM-DD"));
            leave_req_data.append("domain_name", this.state.domain_name);

            this.props.createLeaveRequest(leave_req_data).then((data) => {
                if (data.value.status === 200) {
                    toast("Request created successfully");
                    this.setState({
                       
                        showCreateNewReq_Date: false,
                        showCreateNewReq_Reason: false,
                        showCreateNewReq_File: false,
                        showCreateNewReq_Type: false,
                        showReqs: true,
                        selectedFile: null,
                        selectedReq_type: "",
                        reqReason: "",
                    });
                    this.fetchAllRequests();
                } else {
                    toast("Something went wrong");
                    this.setState({
                        showCreateNewReq_Date: false,
                        showCreateNewReq_Reason: false,
                        showCreateNewReq_File: false,
                        showCreateNewReq_Type: false,
                        showReqs: true,
                        selectedFile: null,
                        selectedReq_type: "",
                        reqReason: "",
                    });
                }
            });
        };

        const handleDeleteModal = (id) => {
            this.setState({ showDeleteConfirm: true, selectedReqID: id });
        };

        const deleteRequest = () => {
            if (this.state.selectedReqID === "") {
                toast("Please select a request");
                return;
            }
            const dataa = {
                id: this.state.selectedReqID,
            };
            this.props.deleteLeaveRequest(dataa).then((data) => {
                if (data.value.status === 200) {
                    toast("Request Deleted successfully");

                    this.fetchAllRequests();
                    this.setState({
                        showDeleteConfirm: false,
                        selectedReqID: "",
                    });
                } else {
                    toast("Something went wrong");
                }
            });
        };
        return (
            <Layout>
                <div>
                    <div className="bg-white rounded-md shadow-md p-4 text-lg font-semibold">
                        {t("requests")}
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-5">
                        <div className="bg-white rounded-lg shadow-md p-4">
                            <p className="text-gray-600">
                                {t("requestsModule.pending")} {t("requests")}
                            </p>
                            <p className="text-primary font-semibold text-md">
                                {pendingReq === "" ? 0 : pendingReq.length}
                            </p>
                        </div>
                        <div className="bg-white rounded-lg shadow-md p-4">
                            <p className="text-gray-600">
                                {t("requestsModule.approved")} {t("requests")}
                            </p>
                            <p className="text-primary font-semibold text-md">
                                {approvedReq === "" ? 0 : approvedReq.length}
                            </p>
                        </div>
                        <div className="bg-white rounded-lg shadow-md p-4">
                            <p className="text-gray-600">
                                {t("requestsModule.rejected")} {t("requests")}
                            </p>
                            <p className="text-primary font-semibold text-md">
                                {rejectedReq === "" ? 0 : rejectedReq.length}
                            </p>
                        </div>
                        <div className="bg-white rounded-lg shadow-md p-4">
                            <p className="text-gray-600">{t("requestsModule.emergencyLeaves")}</p>
                            <p className="text-primary font-semibold text-md">
                                {emergencyReq === "" ? 0 : emergencyReq.length}
                            </p>
                        </div>
                    </div>

                    <div className="bg-white rounded-md shadow-md mt-5 p-5">
                        <div className="flex justify-between items-center mb-5">
                            <p className="text-lg font-semibold">
                                {t("requestsModule.addNewRequest")}
                            </p>
                            {this.state.selectedReq_type === "" ? (
                                <div
                                    className="bg-gray-400 hover:bg-gray-500 hover:shadow-md shadow-sm rounded-md p-1 px-2 text-white text-tiny font-medium cursor-pointer"
                                    onClick={() => {
                                        this.setState({
                                            showAllRequest: !this.state.showAllRequest,
                                        });
                                    }}>
                                    {this.state.showAllRequest
                                        ? t("requestsModule.closeAllReq")
                                        : t("requestsModule.viewAllReq")}
                                </div>
                            ) : null}
                        </div>
                        {this.state.showReqs ? (
                            <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-2">
                                {this.state.showAllRequest
                                    ? kleavesList.map((item, idx) => {
                                          return (
                                              <div key={idx} className="w-11/12 mb-1">
                                                  <div
                                                      className={`bg-${item.color} capitalize flex justify-start items-center text-white rounded-md pl-3 font-medium py-4 cursor-pointer transition duration-150 ease-in-out hover:-translate-y-1 hover:shadow-md`}
                                                      onClick={() => {
                                                          this.setState({
                                                              showCreateNewReq_Type: true,
                                                              showReqs: false,
                                                              selectedReq_type: item.type,
                                                          });
                                                      }}>
                                                      <img
                                                          alt=""
                                                          src={item.icon}
                                                          className="text-white h-6 w-6 mr-2"
                                                      />
                                                      <p>{item.type}</p>
                                                  </div>
                                              </div>
                                          );
                                      })
                                    : leavelist.map((item, idx) => {
                                          return (
                                              <div key={idx} className="w-11/12">
                                                  <div
                                                      className={`bg-${item.color} capitalize flex justify-start items-center text-white font-medium rounded-md pl-3 py-4 cursor-pointer transition duration-150 ease-in-out hover:-translate-y-1 hover:shadow-md`}
                                                      onClick={() => {
                                                          this.setState({
                                                              showCreateNewReq_Type: true,
                                                              showReqs: false,
                                                              selectedReq_type: item.type,
                                                          });
                                                      }}>
                                                      <img
                                                          alt=""
                                                          src={item.icon}
                                                          className="text-white h-6 w-6 mr-2"
                                                      />
                                                      <p>{item.type}</p>
                                                  </div>
                                              </div>
                                          );
                                      })}
                            </div>
                        ) : null}
                        {this.state.showCreateNewReq_Type ? (
                            <div>
                                <div>
                                    <div className="w-1/4">
                                        <SelectField
                                            onSelectChange={(e) => {
                                                this.setState({
                                                    selectedReq_type: e.target.value,
                                                });
                                            }}
                                            name={"Select Request Type"}
                                            FieldName={"Request Type"}
                                            mainclassName={"mr-4"}
                                            arrayOfData={listOfreq}
                                            id={"name"}
                                            defaultValue={
                                                this.state.selectedReq_type
                                            }></SelectField>
                                    </div>
                                    <div className="flex justify-start items-center mt-3">
                                        <button
                                            className="p-1 px-3 rounded-md bg-primary border border-primary text-white mr-3"
                                            onClick={() => {
                                                handleCreateNewReq_type();
                                            }}>
                                            {t("requestsModule.continue")}
                                        </button>
                                        <button
                                            className="p-1 px-3 rounded-md border border-gray-400"
                                            onClick={() => {
                                                handleCloseCreateNewReq();
                                            }}>
                                            {t("requestsModule.discardChanges")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {this.state.showCreateNewReq_Date &&
                        this.state.selectedReq_type !== "Request Offline Time" ? (
                            <div>
                                <p>Select Date</p>
                                <div className="flex text-textprimary text-lg font-semibold mb-8 shadow-sm lg:mb-0 h-10 w-52 mt-3">
                                    <input
                                        value={`${moment(this.state.startDate).format(
                                            "YYYY-MM-DD"
                                        )} - ${moment(this.state.endDate).format("YYYY-MM-DD")}`}
                                        className="border rounded-md w-full text-tiny text-gray-600 px-2 cursor-default"
                                    />
                                    <CalendarIcon
                                        className="-ml-6 z-10 w-5 cursor-pointer"
                                        onClick={() => {
                                            this.setState({
                                                showDateRange: true,
                                            });
                                        }}></CalendarIcon>
                                </div>
                                <div className="flex justify-start items-center mt-3">
                                    <button
                                        className="p-1 px-3 rounded-md bg-primary border border-primary text-white mr-3"
                                        onClick={() => {
                                            handleCreateNewReq_date();
                                        }}>
                                        {t("requestsModule.continue")}
                                    </button>
                                    <button
                                        className="p-1 px-3 rounded-md border border-gray-400"
                                        onClick={() => {
                                            handleCloseCreateNewReq();
                                        }}>
                                        {t("requestsModule.discardChanges")}
                                    </button>
                                </div>
                            </div>
                        ) : null}
                        {this.state.showCreateNewReq_Reason ? (
                            <div>
                                <div className="w-2/3">
                                    <InputField
                                        FieldName="Reason"
                                        value={this.state.reqReason}
                                        onInputChange={(e) => {
                                            this.setState({
                                                reqReason: e.target.value,
                                            });
                                        }}
                                        type="text"
                                    />
                                </div>
                                <div className="flex justify-start items-center mt-3">
                                    <button
                                        className="p-1 px-3 rounded-md bg-primary border border-primary text-white mr-3"
                                        onClick={() => {
                                            handleCreateNewReq_reason();
                                            this.handleActiveTasksData();
                                        }}>
                                        {t("requestsModule.continue")}
                                    </button>
                                    <button
                                        className="p-1 px-3 rounded-md border border-gray-400"
                                        onClick={() => {
                                            handleCloseCreateNewReq();
                                        }}>
                                        {t("requestsModule.discardChanges")}
                                    </button>
                                </div>
                            </div>
                        ) : null}
                        {this.state.showCreateNewReq_File ? (
                            <div>
                                <p>{t("requestsModule.uploadFile")}</p>
                                <div className="bg-gray-400 border-2 border-dashed rounded-lg w-1/3 h-36 flex items-center justify-center mt-2 cursor-pointer">
                                    <Dropzone onDrop={handleFileDrop} multiple={false}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="dropzonecontainer">
                                                <div
                                                    {...getRootProps({
                                                        className: "dropzone",
                                                    })}
                                                    className="w-full h-36 flex justify-center items-center">
                                                    <input {...getInputProps()} />
                                                    <p>{t("requestsModule.dragDropSelectFiles")}</p>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                <div className="mt-3">
                                    <ul>
                                        {this.state.selectedFile === "" ||
                                        this.state.selectedFile == null
                                            ? ""
                                            : this.state.selectedFile[0].name}
                                    </ul>
                                </div>
                                <div className="flex justify-start items-center mt-3">
                                    <button
                                        className="p-1 px-3 rounded-md bg-primary border border-primary text-white mr-3"
                                        onClick={() => {
                                            submitLeaveRequest();
                                        }}>
                                        {t("requestsModule.submitRequest")}
                                    </button>
                                    <button
                                        className="p-1 px-3 rounded-md border border-gray-400"
                                        onClick={() => {
                                            handleCloseCreateNewReq();
                                        }}>
                                        {t("requestsModule.discardChanges")}
                                    </button>
                                </div>
                            </div>
                        ) : null}{" "}
                        {this.state.showScreenshots && (
                            <div>
                                <p>Select Date</p>
                                <div className="flex text-textprimary text-lg font-semibold mb-8 shadow-sm lg:mb-0 h-10 w-52 mt-3">
                                    <DatePicker
                                        className="rounded-md w-52 text-black cursor-pointer"
                                        dateFormat="MMMM dd yyyy"
                                        selected={this.state.selectedDate}
                                        value={new Date()}
                                        startDate={new Date()}
                                        onChange={
                                            (date) =>
                                                // this.setState({startDate: date, selectedDate: date}, () => {
                                                this.handleScreenshotDateChange(date)
                                            // });
                                        }
                                    />
                                    <CalendarIcon className="-ml-10 z-10 w-6"></CalendarIcon>
                                </div>
                                <div className="text-xl text-textprimary font-semibold mt-6 flex items-center justify-between">
                                    <div className=" mt-3 mb-6 gap-2.5 flex items-center justify-between">
                                        <SelectField
                                            onSelectChange={(e) => {
                                                this.setState({
                                                    selectedProject: e.target.value,
                                                });
                                            }}
                                            name={"Select Project"}
                                            arrayOfData={projectList}
                                            id={"name"}
                                            FieldName={t("projects")}></SelectField>

                                        <SelectField
                                            onSelectChange={(e) => {
                                                this.setState({
                                                    selectedTeam: e.target.value,
                                                });

                                                this.fetchCateGoriesData();
                                            }}
                                            FieldName={`${t("select")} ${t("team")}`}
                                            name={"teamName"}
                                            arrayOfData={TeamData}
                                            id={"team"}></SelectField>
                                        <SelectField
                                            onSelectChange={(e) => {
                                                this.setState({
                                                    selectedTaskData: e.target.value,
                                                });
                                            }}
                                            FieldName={`${t("select")} ${t("task")}`}
                                            name={"taskname"}
                                            arrayOfData={myActiveTasks}
                                            id={"task"}></SelectField>
                                        <SelectField
                                            onSelectChange={(e) => {
                                                this.setState({
                                                    selectedCategorykData: e.target.value,
                                                });
                                            }}
                                            FieldName={`${t("select")} ${t("Category")}`}
                                            name={"Category name"}
                                            arrayOfData={categoriesData}
                                            id={"task"}></SelectField>
                                    </div>
                                </div>
                                <div className="flex justify-between">
                                    <div>
                                        <span>{t("screenshots")}</span>
                                        <span>
                                            {!this.state.Timetracker ||
                                            this.state.Timetracker.length === 0 ? (
                                                <div className="h-5 w-5 text-primary rounded-full border border-t-0 ml-2 animate-spin"></div>
                                            ) : null}
                                        </span>
                                    </div>

                                    <button
                                        onClick={() => {
                                            this.postRequestOfflineTime();
                                        }}
                                        //  ${this.state.employeename===null || this.state.employeename === undefined || this.state.employeename === ''? 'hidden':''}
                                        className={`px-4 py-2 min-w-max mr-4 max-h-10 translate-y-5 bg-primary text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out ml-auto`}>
                                        {`${t("Request Offline Time")}`}
                                    </button>
                                </div>

                                <div className="col-md-12" style={{ padding: "0px" }}>
                                    {this.state.Timetracker &&
                                        this.state.Timetracker.length > 0 &&
                                        this.state.OfflineTimetracker &&
                                        this.state.OfflineTimetracker.length > 0 &&
                                        this.state.OfflineTimetracker.map((item, index) => {
                                            let item1 = Object.values(item);
                                            let value = Object.keys(item);
                                            let findInd = this.state.Timetrackerkeys.indexOf(
                                                index + ""
                                            );
                                            if (findInd !== -1) {
                                                item1 = Object.values(
                                                    this.state.Timetracker[findInd]
                                                );
                                                value = Object.keys(
                                                    this.state.Timetracker[findInd]
                                                );
                                                item1.pop();
                                            } else if (
                                                !this.state.isScreenshotSelectActiveOffline
                                            ) {
                                                return null;
                                            }
                                            for (var i = 0; i < item1.length; i++) {
                                                if (item1[i]) {
                                                    var hour_img = item1[i].thumbnails;
                                                    var hourly_thubm_img;
                                                    if (hour_img !== undefined) {
                                                        if (hour_img[0] !== undefined) {
                                                            hourly_thubm_img = hour_img[0];
                                                        }
                                                    }
                                                    var hour_img = item1[i].screenshots;
                                                    var hourly_ss_img;
                                                    if (hour_img !== undefined) {
                                                        if (hour_img[0] !== undefined) {
                                                            hourly_ss_img = hour_img[0];
                                                        }
                                                    }
                                                }
                                            }

                                            return (
                                                <div
                                                    style={{
                                                        marginTop: "40px",
                                                    }}
                                                    id={this.state.OfflineTimetrackerkeys[index]}
                                                    className="shadow-sm">
                                                    <Grid
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                        className="img_view"
                                                        xs={12}
                                                        sm={12}
                                                        lg={12}>
                                                        {item1.map((items, index1) => {
                                                            let time_min =
                                                                index1 === 0
                                                                    ? "00"
                                                                    : value[index1 - 1];
                                                            // let time_duration = [
                                                            //     this.state.Timetrackerkeys[index],
                                                            //     time_min,
                                                            // ];
                                                            // const number = moment(
                                                            //     `${this.state.Timetrackerkeys[index]} : ${time_min}`,
                                                            //     ["HH"]
                                                            // ).format("hh:mm a");
                                                            return (
                                                                <div key={index1}>
                                                                    {value[index1] ===
                                                                    "hourly_percentage" ? null : !items.id ||
                                                                      items.offline ? (
                                                                        <div className="relative">
                                                                            {this.state
                                                                                .isScreenshotSelectActiveOffline ? (
                                                                                <div className="absolute z-10 top-1 right-4 w-5 h-5 rounded-full bg-gray">
                                                                                    <CheckIcon
                                                                                        className={
                                                                                            this.state.selectedSSid.includes(
                                                                                                moment(
                                                                                                    `${
                                                                                                        parseInt(
                                                                                                            this
                                                                                                                .state
                                                                                                                .OfflineTimetrackerkeys[
                                                                                                                index
                                                                                                            ]
                                                                                                        ) +
                                                                                                        Math.floor(
                                                                                                            parseInt(
                                                                                                                time_min
                                                                                                            ) /
                                                                                                                60
                                                                                                        )
                                                                                                    } : ${
                                                                                                        parseInt(
                                                                                                            time_min
                                                                                                        ) %
                                                                                                        60
                                                                                                    }`,
                                                                                                    [
                                                                                                        "HH:mm",
                                                                                                    ]
                                                                                                ).format(
                                                                                                    "HH:mm"
                                                                                                )
                                                                                            )
                                                                                                ? "text-primary font-bold"
                                                                                                : "text-white font-bold"
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            ) : null}
                                                                            <div
                                                                                className={
                                                                                    "Inactive_sty" +
                                                                                    (this.state
                                                                                        .isScreenshotSelectActiveOffline
                                                                                        ? " hover:cursor-pointer"
                                                                                        : "")
                                                                                }
                                                                                onClick={
                                                                                    this.state
                                                                                        .isScreenshotSelectActiveOffline
                                                                                        ? () => {
                                                                                              this.HandleTimeSelect(
                                                                                                  items,
                                                                                                  index,
                                                                                                  time_min,
                                                                                                  value,
                                                                                                  index1
                                                                                              );
                                                                                          }
                                                                                        : () => {
                                                                                              if (
                                                                                                  this
                                                                                                      .state
                                                                                                      .isScreenshotSelectActiveOffline
                                                                                              ) {
                                                                                                  this.HandleOfflineTimeRequest();
                                                                                              }
                                                                                            //   this.setState(
                                                                                            //       {
                                                                                            //           isScreenshotSelectActiveOffline:
                                                                                            //               !this
                                                                                            //                   .state
                                                                                            //                   .isScreenshotSelectActiveOffline,
                                                                                            //       }
                                                                                            //   );
                                                                                          }
                                                                                }>
                                                                                <p className="inactive_text">
                                                                                    {
                                                                                        this.state
                                                                                            .inactiveScreenshotState
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    ) : items.key_count === 0 &&
                                                                      items.mouse_count === 0 ? (
                                                                        this.IdleTime(
                                                                            this.state
                                                                                .OfflineTimetrackerkeys[
                                                                                index
                                                                            ],
                                                                            time_min,
                                                                            value[index1],
                                                                            t(
                                                                                "timeTracker.idleTime"
                                                                            ),
                                                                            items,
                                                                            index,
                                                                            value,
                                                                            index1
                                                                        )
                                                                    ) : items.cancelled_time ? (
                                                                        <div className="relative">
                                                                            {this.state
                                                                                .isScreenshotSelectActiveOffline ? (
                                                                                <div className="absolute z-10 top-1 right-4 w-5 h-5 rounded-full bg-gray">
                                                                                    <CheckIcon
                                                                                        className={
                                                                                            this.state.selectedSSid.includes(
                                                                                                moment(
                                                                                                    `${
                                                                                                        parseInt(
                                                                                                            this
                                                                                                                .state
                                                                                                                .OfflineTimetrackerkeys[
                                                                                                                index
                                                                                                            ]
                                                                                                        ) +
                                                                                                        Math.floor(
                                                                                                            parseInt(
                                                                                                                time_min
                                                                                                            ) /
                                                                                                                60
                                                                                                        )
                                                                                                    } : ${
                                                                                                        parseInt(
                                                                                                            time_min
                                                                                                        ) %
                                                                                                        60
                                                                                                    }`,
                                                                                                    [
                                                                                                        "HH:mm",
                                                                                                    ]
                                                                                                ).format(
                                                                                                    "HH:mm"
                                                                                                )
                                                                                            )
                                                                                                ? "text-primary font-bold"
                                                                                                : "text-white font-bold"
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            ) : null}
                                                                            <img
                                                                                className={
                                                                                    "Inactive_sty max-h-20" +
                                                                                    (this.state
                                                                                        .isScreenshotSelectActiveOffline
                                                                                        ? " hover:cursor-pointer"
                                                                                        : "")
                                                                                }
                                                                                src={idelTime}
                                                                                alt="cancelled time"
                                                                                onClick={
                                                                                    this.state
                                                                                        .isScreenshotSelectActiveOffline
                                                                                        ? () => {
                                                                                              this.HandleTimeSelect(
                                                                                                  items,
                                                                                                  index,
                                                                                                  time_min,
                                                                                                  value,
                                                                                                  index1
                                                                                              );
                                                                                          }
                                                                                        : () => {}
                                                                                }
                                                                            />
                                                                        </div>
                                                                    ) : items.offline ? (
                                                                        <div className="Inactive_sty bg-gray-400">
                                                                            <p className="inactive_text">
                                                                                Offline
                                                                            </p>
                                                                        </div>
                                                                    ) : (
                                                                        <Paper
                                                                            style={{
                                                                                marginRight: "10px",
                                                                                width: "163px",
                                                                            }}
                                                                            className="shadowBox relative">
                                                                            {this.state
                                                                                .isScreenshotSelectActive ||
                                                                            this.state
                                                                                .isScreenshotSelectActiveOffline ? (
                                                                                <div className="absolute z-10 top-1 right-1 w-5 h-5 rounded-full bg-gray">
                                                                                    <CheckIcon
                                                                                        className={
                                                                                            this.state.selectedSSid.includes(
                                                                                                moment(
                                                                                                    `${
                                                                                                        parseInt(
                                                                                                            this
                                                                                                                .state
                                                                                                                .OfflineTimetrackerkeys[
                                                                                                                index
                                                                                                            ]
                                                                                                        ) +
                                                                                                        Math.floor(
                                                                                                            parseInt(
                                                                                                                time_min
                                                                                                            ) /
                                                                                                                60
                                                                                                        )
                                                                                                    } : ${
                                                                                                        parseInt(
                                                                                                            time_min
                                                                                                        ) %
                                                                                                        60
                                                                                                    }`,
                                                                                                    [
                                                                                                        "HH:mm",
                                                                                                    ]
                                                                                                ).format(
                                                                                                    "HH:mm"
                                                                                                )
                                                                                            )
                                                                                                ? "text-primary font-bold"
                                                                                                : "text-white font-bold"
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            ) : null}
                                                                            {
                                                                                // items.idle_time != 10 ?
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    sm={5}
                                                                                    md={2}>
                                                                                    <div
                                                                                        onClick={
                                                                                            this
                                                                                                .state
                                                                                                .isScreenshotSelectActive ||
                                                                                            this
                                                                                                .state
                                                                                                .isScreenshotSelectActiveOffline
                                                                                                ? () => {
                                                                                                      this.HandleTimeSelect(
                                                                                                          items,
                                                                                                          index,
                                                                                                          time_min,
                                                                                                          value,
                                                                                                          index1
                                                                                                      );
                                                                                                  }
                                                                                                : () => {}
                                                                                        }
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                        }}>
                                                                                        <Modaltracker
                                                                                            image={
                                                                                                items
                                                                                            }
                                                                                            openDisabled={
                                                                                                this
                                                                                                    .state
                                                                                                    .isScreenshotSelectActive ||
                                                                                                this
                                                                                                    .state
                                                                                                    .isScreenshotSelectActiveOffline
                                                                                            }
                                                                                            hourly_thubm_image={
                                                                                                hourly_thubm_img
                                                                                            }
                                                                                            hourly_ss_image={
                                                                                                hourly_ss_img
                                                                                            }
                                                                                            ActivityIndex={
                                                                                                this
                                                                                                    .state
                                                                                                    .EmployeeTiming
                                                                                            }
                                                                                            selectedDate={
                                                                                                this
                                                                                                    .state
                                                                                                    .selectedDate
                                                                                            }
                                                                                            userName={
                                                                                                this
                                                                                                    .state
                                                                                                    .employeename
                                                                                            }
                                                                                            Timekey={
                                                                                                this
                                                                                                    .state
                                                                                                    .OfflineTimetrackerkeys[
                                                                                                    index
                                                                                                ]
                                                                                            }
                                                                                            time_min={
                                                                                                time_min
                                                                                            }
                                                                                            value={
                                                                                                value[
                                                                                                    index1
                                                                                                ]
                                                                                            }
                                                                                            ReportName={
                                                                                                "user"
                                                                                            }
                                                                                            active={{
                                                                                                key_count:
                                                                                                    items.key_count,
                                                                                                mouse_count:
                                                                                                    items.mouse_count,
                                                                                                idle_time:
                                                                                                    items.idle_time,
                                                                                                item: items,
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </Grid>
                                                                                // ) : (
                                                                                //         this.IdleTime(
                                                                                //             this.state.Timetrackerkeys[index],
                                                                                //             time_min,
                                                                                //             value[index1]
                                                                                //         )
                                                                            }
                                                                        </Paper>
                                                                    )}
                                                                    {/* <Divider /> */}
                                                                    <Grid
                                                                        style={{
                                                                            width: "163px",
                                                                            paddingTop: "4px",
                                                                            backgroundColor:
                                                                                "white",
                                                                        }}>
                                                                        {/* <Paper style={{ width: '166px'}}>*/}
                                                                        <div
                                                                            className="row"
                                                                            style={{
                                                                                justifyContent:
                                                                                    "space-between",
                                                                                padding:
                                                                                    "0px 10px 0px 10px",
                                                                            }}>
                                                                            {value[index1] ===
                                                                            "hourly_percentage" ? (
                                                                                <p
                                                                                    style={{
                                                                                        margin: "2px",
                                                                                        fontSize:
                                                                                            "14px",
                                                                                    }}>
                                                                                    {value[index1]}
                                                                                </p>
                                                                            ) : (
                                                                                <p
                                                                                    style={{
                                                                                        margin: "2px",
                                                                                        fontSize:
                                                                                            "14px",
                                                                                    }}>
                                                                                    {
                                                                                        this.state
                                                                                            .OfflineTimetrackerkeys[
                                                                                            index
                                                                                        ]
                                                                                    }
                                                                                    :
                                                                                    {time_min === 60
                                                                                        ? "00"
                                                                                        : time_min}{" "}
                                                                                    -{" "}
                                                                                    {value[
                                                                                        index1
                                                                                    ] === 60
                                                                                        ? parseInt(
                                                                                              this
                                                                                                  .state
                                                                                                  .OfflineTimetrackerkeys[
                                                                                                  index
                                                                                              ]
                                                                                          ) + 1
                                                                                        : this.state
                                                                                              .OfflineTimetrackerkeys[
                                                                                              index
                                                                                          ]}
                                                                                    :
                                                                                    {value[
                                                                                        index1
                                                                                    ] === 60
                                                                                        ? "00"
                                                                                        : value[
                                                                                              index1
                                                                                          ]}
                                                                                </p>
                                                                            )}
                                                                            {/* <DescriptionOutlined style={{ fontSize: '26px',paddingRight:'10px',marginTop: "-3px",color:'rgb(68, 152, 190)'}}/> */}
                                                                            {/* <Modaltracxker image={items} ActivityIndex = {this.state.Trackertiming} /> */}
                                                                            {/* <p>{items.key_count}</p>*/}
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                padding: "4px",
                                                                            }}>
                                                                            {value[index1] ===
                                                                            "hourly_percentage" ? (
                                                                                <ProgressBar
                                                                                    style={{
                                                                                        height: "10px",
                                                                                    }}
                                                                                    variant="success"
                                                                                    now={items}
                                                                                    // now="20"
                                                                                />
                                                                            ) : (
                                                                                <ProgressBar
                                                                                    style={{
                                                                                        height: "10px",
                                                                                    }}
                                                                                    variant="success"
                                                                                    now={
                                                                                        items.activity_points *
                                                                                        10
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        {items.screenshots ===
                                                                        "" ? (
                                                                            <></>
                                                                        ) : (
                                                                            <div className="tracker_img">
                                                                                {this.progressActivity(
                                                                                    items.screenshots
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        {/* </Paper> */}
                                                                    </Grid>
                                                                </div>
                                                            );
                                                        })}
                                                    </Grid>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="bg-white rounded-md shadow-md mt-5 p-5">
                        <div>
                            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start lg:justify-between flex-wrap sm:flex-nowrap">
                                <div className="flex text-textprimary text-lg font-semibold mb-8 lg:mb-0">
                                    <DatePicker
                                        className="rounded-md cursor-pointer"
                                        selected={this.state.selectedDate}
                                        dateFormat="MMMM dd yyyy"
                                        value={this.state.selectedDate}
                                        startDate={this.state.selectedDate}
                                        onChange={(date) => handledateChange(date)}
                                    />
                                    <CalendarIcon className="-ml-6 z-10 w-5"></CalendarIcon>
                                </div>

                                <div className="flex  -translate-y-2">
                                    <SelectField
                                        onSelectChange={(e) => {
                                            handleSelectRequestType(e);
                                        }}
                                        name={"Select Request Type"}
                                        FieldName={"Request Type"}
                                        mainclassName={"mr-4"}
                                        arrayOfData={listOfreq}
                                        id={"name"}></SelectField>

                                    <SelectField
                                        onSelectChange={(e) => {
                                            handleStatusRequest(e);
                                        }}
                                        name={"Select Request Status"}
                                        FieldName={"Status"}
                                        arrayOfData={listOfStatus}
                                        id={"name"}></SelectField>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white px-2 py-5 mt-4 border-b border-gray-200 sm:px-6 rounded-lg">
                            <div
                                className="mt-2 flex flex-col overflow-y-auto bg-gray-100"
                                style={{ maxHeight: "auto" }}>
                                <div className="inline-block min-w-full align-middle max-h-[60vh] overflow-auto no-scrollbar">
                                    <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                        <table
                                            className="min-w-full border-separate"
                                            style={{ borderSpacing: 0 }}>
                                            <thead className="bg-gray-50 sticky top-0 z-10">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className=" border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                        {t("requestsModule.requestType")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                        {t("requestsModule.reason")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                                                        {t("requestsModule.approvedBy")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                        {t("status")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12">
                                                        {t("requestsModule.remarks")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12">
                                                        {t("TeamTab.created")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12">
                                                        {t("action")}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {this.state.filteredData === "" ||
                                                this.state.filteredData.length === 0 ? (
                                                    <tr>
                                                        <td
                                                            colSpan={7}
                                                            className="text-center pt-2">
                                                            {t("no")} {t("requests")}{" "}
                                                            {t("timeTracker.found")}
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    this.state.filteredData &&
                                                    (this.state.reqSort && this.state.statusSort
                                                        ?
                                                        this.state.filteredData.filter((item)=>this.state.selectStatus.includes(item) && this.state.selectedReqType.includes(item) )
                                                        :
                                                        this.state.reqSort
                                                        ?
                                                        this.state.filteredData.filter((item)=>this.state.selectedReqType.includes(item))
                                                        :
                                                        this.state.statusSort
                                                        ?
                                                        this.state.filteredData.filter((item)=>this.state.selectStatus.includes(item))
                                                        :
                                                        this.state.filteredData
                                                    )
                                                    .map((req, idx) => (
                                                        <tr key={idx}>
                                                            <td
                                                                className={classNames(
                                                                    idx !==
                                                                        this.state.filteredData
                                                                            .length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12 cursor-pointer hover:underline"
                                                                )}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        selectedRequest: req,
                                                                        showReqDetails: true,
                                                                    });
                                                                }}>
                                                                {req.type}
                                                            </td>
                                                            <td
                                                                className={classNames(
                                                                    idx !==
                                                                        this.state.filteredData
                                                                            .length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
                                                                )}>
                                                                {req.info === "" ||
                                                                req.info == null ? (
                                                                    <p className="text-black">
                                                                        {t("no")}{" "}
                                                                        {t("requestsModule.reason")}{" "}
                                                                        {t(
                                                                            "requestsModule.provided"
                                                                        )}
                                                                    </p>
                                                                ) : (
                                                                    req.info
                                                                )}
                                                            </td>
                                                            <td
                                                                className={classNames(
                                                                    idx !==
                                                                        this.state.filteredData
                                                                            .length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"
                                                                )}>
                                                                {req.approved_by}
                                                            </td>
                                                            <td
                                                                className={classNames(
                                                                    idx !==
                                                                        this.state.filteredData
                                                                            .length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    `whitespace-nowrap px-3 py-4 text-sm font-medium ${
                                                                        req.status === "Approved"
                                                                            ? "text-green-600"
                                                                            : "text-red-600"
                                                                    }`
                                                                )}>
                                                                {req.status}
                                                            </td>
                                                            <td
                                                                className={classNames(
                                                                    idx !==
                                                                        this.state.filteredData
                                                                            .length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"
                                                                )}>
                                                                {req.remarks === "" ||
                                                                req.remarks == null ? (
                                                                    <p className="text-black">
                                                                        {t("no")}{" "}
                                                                        {t(
                                                                            "requestsModule.remarks"
                                                                        )}{" "}
                                                                        {t(
                                                                            "requestsModule.provided"
                                                                        )}
                                                                    </p>
                                                                ) : (
                                                                    req.remarks
                                                                )}
                                                            </td>
                                                            <td
                                                                className={classNames(
                                                                    idx !==
                                                                        this.state.filteredData
                                                                            .length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"
                                                                )}>
                                                                {req.created}
                                                            </td>
                                                            <td
                                                                className={classNames(
                                                                    idx !==
                                                                        this.state.filteredData
                                                                            .length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                                                )}>
                                                                <TrashIcon
                                                                    className="h-5 w-6 text-red-500 cursor-pointer"
                                                                    onClick={() => {
                                                                        handleDeleteModal(req.id);
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.showReqDetails ? (
                        <Transition.Root show={this.state.showReqDetails} as={Fragment}>
                            <Dialog
                                as="div"
                                className="fixed z-10 inset-0 overflow-y-auto"
                                onClose={() => {
                                    this.setState({ showReqDetails: false });
                                }}>
                                <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0">
                                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                    </Transition.Child>

                                    {/* This element is to trick the browser into centering the modal contents. */}
                                    <span
                                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                        aria-hidden="true">
                                        &#8203;
                                    </span>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                                            {/* <div className='bg-white rounded-lg p-4 shadow-lg'> */}
                                            {this.state.selectedRequest === "" ? (
                                                <div className="flex justify-center items-center flex-col">
                                                    <div className="text-xl font-semibold text-primary my-2">
                                                        {t("requestsModule.selectReqViewDetails")}
                                                    </div>
                                                    <img
                                                        alt=""
                                                        src={noDataSVG}
                                                        className="my-3 h-24 w-24"
                                                    />
                                                    <div className="font-medium mt-1">
                                                        {t("no")} {t("timeTracker.records")}{" "}
                                                        {t("timeTracker.found")}
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <div>
                                                        <p className="text-primary text-lg font-semibold">
                                                            {this.state.selectedRequest &&
                                                                this.state.selectedRequest.user}
                                                        </p>
                                                        <p className="text-gray-500">
                                                            {this.state.selectedRequest &&
                                                                this.state.selectedRequest.created}
                                                        </p>
                                                    </div>
                                                    <div className="bg-gray-200 my-4 rounded-xl h-1 w-full"></div>
                                                    <div>
                                                        <div className="flex my-2 items-center">
                                                            <p>
                                                                {t("requestsModule.requestType")}:{" "}
                                                            </p>
                                                            <p className="text-md font-semibold ml-2">
                                                                {this.state.selectedRequest &&
                                                                    this.state.selectedRequest.type}
                                                            </p>
                                                        </div>
                                                        <div className="flex my-2 items-center">
                                                            <p>{t("Date")}:{""} </p>
                                                            <p className="text-md font-semibold ml-2">
                                                                {this.state.selectedRequest &&
                                                                    this.state.selectedRequest
                                                                        .start_date}{" "}
                                                                to{" "}
                                                                {this.state.selectedRequest &&
                                                                    this.state.selectedRequest
                                                                        .end_date}
                                                            </p>
                                                        </div>
                                                        <div className=" my-2 items-center">
                                                            <p>
                                                                {t("requestsModule.whyTakeLeave")}
                                                            </p>
                                                            <p className="text-md font-semibold">
                                                                {(this.state.selectedRequest &&
                                                                    this.state.selectedRequest
                                                                        .info === "") ||
                                                                this.state.selectedRequest.info ==
                                                                    null
                                                                    ? t("no") +
                                                                      " " +
                                                                      t("requestsModule.reason") +
                                                                      " " +
                                                                      t("requestsModule.provided")
                                                                    : this.state.selectedRequest
                                                                          .info}
                                                            </p>
                                                        </div>
                                                        <div className=" my-2 items-center">
                                                            <p>{t("requestsModule.remarks")}: </p>
                                                            <p className="text-md font-semibold">
                                                                {(this.state.selectedRequest &&
                                                                    this.state.selectedRequest
                                                                        .remarks === "") ||
                                                                this.state.selectedRequest
                                                                    .remarks == null
                                                                    ? t("no") +
                                                                      " " +
                                                                      t("requestsModule.remarks") +
                                                                      " " +
                                                                      t("requestsModule.provided")
                                                                    : this.state.selectedRequest
                                                                          .remarks}
                                                            </p>
                                                        </div>
                                                        <div className="flex my-2 items-center">
                                                            <p>
                                                                {t("requestsModule.attachedFile")}:{" "}
                                                            </p>
                                                            <p className="text-md font-semibold ml-2">
                                                                {this.state.selectedRequest &&
                                                                    this.state.selectedRequest
                                                                        .files}
                                                            </p>
                                                        </div>
                                                        {this.state.selectedRequest &&
                                                            this.state.selectedRequest.type ===
                                                                "offline" && (
                                                                <div className="flex my-2 items-center">
                                                                    <p>{t("project")} </p>
                                                                    <p className="text-lg font-semibold ml-2">
                                                                        {this.state
                                                                            .selectedRequest &&
                                                                            this.state
                                                                                .selectedRequest
                                                                                .project}
                                                                    </p>
                                                                </div>
                                                            )}
                                                        {this.state.selectedRequest &&
                                                            this.state.selectedRequest.type ===
                                                                "offline" && (
                                                                <div className="flex my-2 items-center">
                                                                    <p>{t("team")} </p>
                                                                    <p className="text-lg font-semibold ml-2">
                                                                        {this.state
                                                                            .selectedRequest &&
                                                                            this.state
                                                                                .selectedRequest
                                                                                .team}
                                                                    </p>
                                                                </div>
                                                            )}
                                                        {this.state.selectedRequest &&
                                                            this.state.selectedRequest.type ===
                                                                "offline" && (
                                                                <div className="flex my-2 items-center">
                                                                    <p>{t("task")} </p>
                                                                    <p className="text-lg font-semibold ml-2">
                                                                        {this.state
                                                                            .selectedRequest &&
                                                                            this.state
                                                                                .selectedRequest
                                                                                .task}
                                                                    </p>
                                                                </div>
                                                            )}
                                                        {this.state.selectedRequest &&
                                                            this.state.selectedRequest.type ===
                                                                "offline" && (
                                                                <div className="flex my-2 items-center">
                                                                    <p>Category </p>
                                                                    <p className="text-lg font-semibold ml-2">
                                                                        {this.state
                                                                            .selectedRequest &&
                                                                            this.state
                                                                                .selectedRequest
                                                                                .category}
                                                                    </p>
                                                                </div>
                                                            )}
                                                        <div className="flex my-4 mt-6">
                                                            <button
                                                                className="bg-primary p-2 px-9 sm:px-12 rounded-full text-white font-medium"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showReqDetails: false,
                                                                    });
                                                                }}>
                                                                {t("activityMonitorModule.close")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {/* </div> */}
                                        </div>
                                    </Transition.Child>
                                </div>
                            </Dialog>
                        </Transition.Root>
                    ) : null}

                    {this.state.showDateRange ? (
                        <Transition.Root show={this.state.showDateRange} as={Fragment}>
                            <Dialog
                                as="div"
                                className="fixed z-10 inset-0 overflow-y-auto"
                                onClose={() => {
                                    this.setState({ showDateRange: false });
                                }}>
                                <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0">
                                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                    </Transition.Child>

                                    {/* This element is to trick the browser into centering the modal contents. */}
                                    <span
                                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                        aria-hidden="true">
                                        &#8203;
                                    </span>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[600px] sm:w-full sm:p-6">
                                            <DateRangeFilter onChange={onChange} />
                                        </div>
                                    </Transition.Child>
                                </div>
                            </Dialog>
                        </Transition.Root>
                    ) : null}

                    <ConfirmationDialogue
                        open={this.state.showDeleteConfirm}
                        onCancelClick={() => {
                            this.setState({ showDeleteConfirm: false });
                        }}
                        onSubmitClick={() => deleteRequest()}
                        title={"Delete Request"}
                        info={"Are you sure you want to Delete your Request?"}
                        submitButtonName={"Delete"}
                        cancelButtonName={"Cancel"}></ConfirmationDialogue>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({
    projects: state.projects,
    timetracker: state.timetracker,
    empDashboard: state.empDashboard,
    teams: state.teams,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAllRequests: () => dispatch(fetchAllRequests()),
    createLeaveRequest: (data) => dispatch(createLeaveRequest(data)),
    deleteLeaveRequest: (id) => dispatch(deleteLeaveRequest(id)),
    getOfflineData: () => dispatch(getOfflineData()),
    fetchEmpLoginDetail: () => dispatch(fetchEmpLoginDetail()),
    timeTrackerDataWithoutProject: (date, empName) =>
        dispatch(timeTrackerDataWithoutProject(date, empName)),
    weeklyChartActivity: (date, user_id) =>
        dispatch(weeklyChartActivity(date, user_id)),
    customReportWeekglyGraphdata: (last, current) =>
        dispatch(customReportWeekglyGraphdata(last, current)),
    getEmployeeMoods: (date) => dispatch(getEmployeeMoods(date)),
    getShowcaseEntries: (date, params) =>
        dispatch(getShowcaseEntries(date, params)),
    requestOfflineTime: (data) => dispatch(requestOfflineTime(data)),
    getProjectList: () => dispatch(getProjectList()),
    getUserProject: () => dispatch(getUserProject()),
    FetchactiveTeams: (org_uuid) => dispatch(FetchactiveTeams(org_uuid)),
    orgEmails: (name) => dispatch(orgEmails(name)),
    fetchactiveTasks: () => dispatch(fetchactiveTasks()),
    requestProjectChange: (data) => dispatch(requestProjectChange(data)),
    getCategoriesData: (data) => dispatch(getCategoriesData(data)),
});

export default withTranslation("common")(
    connect(mapStateToProps, mapDispatchToProps)(Employee_genericReq)
);
