import { ACTION_TYPES } from "../../../constants";
import api from "../api";

export const fetchAllRequests = () => ({
    type: ACTION_TYPES.GET_ALL_REQUEST,
    payload: api.fetchAllRequests(),
});

export const admin_AppRejRequest = (data, offline) => ({
    type: ACTION_TYPES.APPROVE_REJECT_REQUEST,
    payload: api.admin_AppRejRequest(data, offline),
});

export const requestOfflineTime = (data) => ({
    type: ACTION_TYPES.OFFLINE_REQUEST_TIME,
    payload: api.requestOfflineTime(data),
});
