/* eslint-disable import/no-anonymous-default-export */
import { API_ENDPOINTS } from "../../constants";
import { transport, padlocktransport } from "../../Networks/Auth";

export default {
  fetchActiveProjects: () => {
    let config = {
      url: API_ENDPOINTS.ACTIVE_PROJECTS,
      method: "GET",
      withCredentials: true,
    };
    return transport(config);
  },

  createTask: (data) => {
    let config = {
      url: API_ENDPOINTS.CREATE_TASK,
      method: "POST",
      withCredentials: true,
      data: data,
    };
    return transport(config);
  },

  updateproject: (data) => {
    let config = {
      url: API_ENDPOINTS.UPDATE_PROJECT,
      method: "PUT",
      withCredentials: true,
      data: data,
    };
    return transport(config);
  },

  addusertoproject: (data) => {
    let config = {
      url: API_ENDPOINTS.ADD_USER_TO_PROJECT,
      method: "POST",
      withCredentials: true,
      data: data,
    };
    return transport(config);
  },

  updateTask: (data) => {
    let config = {
      url: API_ENDPOINTS.UPDATE_TASK,
      method: "PUT",
      withCredentials: true,
      data: data,
    };
    return transport(config);
  },

  fetchActiveTasks: () => {
    let config = {
      url: API_ENDPOINTS.ACTIVE_TASKS,
      method: "GET",
      withCredentials: true,
    };
    return transport(config);
  },

  fetchActiveTaskOserver: () => {
    let config = {
      url: API_ENDPOINTS.ACTIVE_TASKS + "?observer=True",
      method: "GET",
      withCredentials: true,
    };
    return transport(config);
  },

  fetchAllEmployees: () => {
    let config = {
      url:
        localStorage.getItem("ro") === "Observer"
          ? API_ENDPOINTS.GET_EMP_LIST_OBSERVER
          : API_ENDPOINTS.FETCH_ALL_EMPLOYEES,
      method: "GET",
      withCredentials: true,
    };
    return transport(config);
  },

  deleteProject: (name) => {
    let config = {
      url: API_ENDPOINTS.DELETE_PROJECT,
      method: "DELETE",
      data: {
        project_name: name,
      },
      withCredentials: true,
    };
    return transport(config);
  },

  deleteUserFromProject: (data) => {
    let config = {
      url: API_ENDPOINTS.DELETE_USER_FROM_PROJECT,
      method: "DELETE",
      data: data,
      withCredentials: true,
    };
    return transport(config);
  },

  deleteTask: (id) => {
    let config = {
      url: API_ENDPOINTS.DELETE_TASK,
      method: "DELETE",
      data: {
        id: id,
      },
      withCredentials: true,
    };
    return transport(config);
  },

  fetchallusersFromProject: (name) => {
    let config = {
      url: API_ENDPOINTS.GET_USERS_FROM_PROJECT + name,
      method: "GET",
      withCredentials: true,
    };
    return transport(config);
  },

  createProject: (data) => {
    let config = {
      url: API_ENDPOINTS.CREATE_PROJECT,
      method: "POST",
      data: data,
      withCredentials: true,
    };
    return transport(config);
  },

  taskListObserver: () => {
    let config = {
      url: API_ENDPOINTS.TASK_LIST_OBSERVER,
      method: "GET",
      withCredentials: true,
    };
    return transport(config);
  },

  detailedTaskInfo: (taskname) => {
    let config = {
      url: API_ENDPOINTS.GET_DETAILED_TASK_INFO,
      method: "POST",
      data: taskname,
      withCredentials: true,
    };
    return transport(config);
  },
  getProjectDetails: () => {
    let config = {
      url: API_ENDPOINTS.GET_PROJECT_DETAILS,
      method: "GET",
    };
    return transport(config);
  },
  postProjectHrsBooked: (data) => {
    let config = {
      url: API_ENDPOINTS.GET_PROJECT_DETAILS,
      method: "POST",
      data: data,
    };
    return transport(config);
  },
  updateProjectHrsBooked: (data) => {
    let config = {
      url: API_ENDPOINTS.GET_PROJECT_DETAILS,
      method: "PUT",
      data: data,
    };
    return transport(config);
  },
  getProjectTableLayout: () => {
    let config = {
      url: API_ENDPOINTS.PROJECT_TABLE_LAYOUT,
      method: "GET",
    };
    return transport(config);
  },
  updateProjectTableLayout: (data) => {
    let config = {
      url: API_ENDPOINTS.PROJECT_TABLE_LAYOUT,
      method: "PUT",
      data: data,
    };
    return transport(config);
  },
  postProjectTableLayout: (data) => {
    let config = {
      url: API_ENDPOINTS.PROJECT_TABLE_LAYOUT,
      method: "POST",
      data: data,
    };
    return transport(config);
  },
  getProjectTableArrangement: () => {
    let config = {
      url: API_ENDPOINTS.PROJECT_TABLE_ARRANGEMENT,
      method: "GET",
    };
    return transport(config);
  },
  updateProjectTableArrangement: (data) => {
    let config = {
      url: API_ENDPOINTS.PROJECT_TABLE_ARRANGEMENT,
      method: "PUT",
      data: data,
    };
    return transport(config);
  },
  postProjectTableArrangement: (data) => {
    let config = {
      url: API_ENDPOINTS.PROJECT_TABLE_ARRANGEMENT,
      method: "POST",
      data: data,
    };
    return transport(config);
  },
  getUserBasedProjects: (userId) => {
    let config = {
      url: API_ENDPOINTS.USER_PROJECTS + userId,
      method: "GET",
    };
    return transport(config);
  },
  getUserBasedTeams: (userId) => {
    let config = {
      url: API_ENDPOINTS.USER_TEAMS + userId,
      method: "GET",
    };
    return padlocktransport(config);
  },
  getTaskTableLayout: () => {
    let config = {
      url: API_ENDPOINTS.TASK_TABLE_LAYOUT,
      method: "GET",
    };
    return transport(config);
  },
  updateTaskTableLayout: (data) => {
    let config = {
      url: API_ENDPOINTS.TASK_TABLE_LAYOUT,
      method: "PUT",
      data: data,
    };
    return transport(config);
  },
  postTaskTableLayout: (data) => {
    let config = {
      url: API_ENDPOINTS.TASK_TABLE_LAYOUT,
      method: "POST",
      data: data,
    };
    return transport(config);
  },
  getTaskTableArrangement: () => {
    let config = {
      url: API_ENDPOINTS.TASK_TABLE_ARRANGEMENT,
      method: "GET",
    };
    return transport(config);
  },
  updateTaskTableArrangement: (data) => {
    let config = {
      url: API_ENDPOINTS.TASK_TABLE_ARRANGEMENT,
      method: "PUT",
      data: data,
    };
    return transport(config);
  },
  postTaskTableArrangement: (data) => {
    let config = {
      url: API_ENDPOINTS.TASK_TABLE_ARRANGEMENT,
      method: "POST",
      data: data,
    };
    return transport(config);
  },
  updateActiveTask: (data) => {
    let config = {
      url: API_ENDPOINTS.ACTIVE_TASKS,
      method: "PUT",
      data: data,
    };
    return transport(config);
  },
  postActiveTask: (data) => {
    let config = {
      url: API_ENDPOINTS.ACTIVE_TASKS,
      method: "POST",
      data: data,
    };
    return transport(config);
  },
  getProjectCustomFields: (id) => {
    let config = {
      url: API_ENDPOINTS.PROJECT_CUSTOM_FIELDS + id,
      method: "GET",
    };
    return transport(config);
  },
  getXeroProject: (id) => {
    let config = {
      url: API_ENDPOINTS.XERO_PROJECT + "?id=" + id,
      method: "GET",
    };
    return transport(config);
  },
  postXeroProject: (data) => {
    let config = {
      url: API_ENDPOINTS.XERO_PROJECT,
      method: "POST",
      data: data,
    };
    return transport(config);
  },

  getCategoriesData: (data) => {
    let config = {
      url: API_ENDPOINTS.CATEGORIES_DATA,
      method: "POST",
      data: data,
    };
    return transport(config);
  },
  getNotes: (task_id) => {
    let config = {
      url: API_ENDPOINTS.NOTES + "?task_id=" + task_id,
      method: "GET",
    };
    return transport(config);
  },
  addNote: (data) => {
    let config = {
      url: API_ENDPOINTS.NOTES,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return transport(config);
  },

  fetchUserDailyReports: (date) => {
    let config = {
      url: API_ENDPOINTS.FETCH_USER_DAILY_REPORTS + date,
      method: "GET",
    };
    return transport(config);
  },

  fetchUserMonthlyReports: () => {
    let config = {
      url: API_ENDPOINTS.FETCH_USER_MONTHLY_REPORTS,
      method: "GET",
    };
    return transport(config);
  },
};
