import React, { useState, useEffect } from "react";
import Layout from "../../../Components/Layout";
import moment from "moment";
import {
    UsersIcon,
    PencilIcon,
    DotsVerticalIcon,
    TrashIcon,
    CalendarIcon,
} from "@heroicons/react/solid";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useHistory } from "react-router-dom";
import { IoIosArrowBack, IoMdClose } from "react-icons/io";
// import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ConfirmationDialoag from "../../../Components/ConfirmationDialogue";
import { toast } from "react-toastify";
import {
    getClientBillingHistory,
    getClientOverview,
    getClientTeam,
    getClientTeamDetailed,
    getClientProfileInfo,
    deleteClient,
} from "../../../services/teams/actions";
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const InvoiceDetails = () => {
    const [editClient, setEditClient] = useState(false);
    const [selectTab, setSelectTab] = useState("overview");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [subTeamTab, setSubTeamTab] = useState("Active");
    const [viewDetails, setViewDetails] = useState(false);
    const [teamName, setTeamName] = useState("");
    const params = new URLSearchParams(window.location.search);
    const [clientDataOverview, setClientDataOverview] = useState({});
    const [clientTeams, setClientTeams] = useState({});
    const [clientBillingHistory, setClientBillingHistory] = useState({});
    const [teamDetailedData, setTeamDetailedData] = useState({});
    const [clientProfile, setClientProfile] = useState("");
    const [confirmationTrue, setConfirmationTrue]= useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const id = params.get("client");
    useEffect(() => {
        getOverview(selectedDate);
    }, [params.get("client")]);
    const getOverview = (date) => {
        const clientId = params.get("client");
        dispatch(
            getClientOverview(
                moment(date).format("YYYY-MM-DD"),
                clientId,
                date.getMonth() + 1,
                date.getFullYear()
            )
        ).then((res) => {
            if (res.value.status === 200) {
                setClientDataOverview(res.value.data);
            }
        });
    };
    const getTeams = (date) => {
        const clientId = params.get("client");
        dispatch(getClientTeam(moment(date).format("YYYY-MM-DD"), clientId)).then((res) => {
            if (res.value.status === 200) {
                setClientTeams(res.value.data);
            }
        });
    };
    const getBillingHIstory = (date) => {
        const clientId = params.get("client");
        dispatch(getClientBillingHistory(clientId, date.getFullYear())).then((res) => {
            if (res.value.status === 200) {
                setClientBillingHistory(res.value.data);
            }
        });
    };
    const getClientInfo = () => {
        const clientId = params.get("client");
        dispatch(getClientProfileInfo(clientId)).then((res)=>{
            if(res.value.status === 200){
                setClientProfile(res.value.data)
            }
        })
    };
    const handleDateChange = (date) => {
        setSelectedDate(date);
        if (selectTab === "overview") {
            getOverview(date);
        } else if (selectTab === "teams") {
            getTeams(date);
        } else if (selectTab === "billinghistory") {
            getBillingHIstory(date);
        }
    };
    const handleTabSelect = (tab) => {
        setSelectTab(tab);
        if (tab && tab !== selectTab) {
            if (tab === "overview") {
                setViewDetails(false);
                getOverview(selectedDate);
            } else if (tab === "teams") {
                setViewDetails(false);
                getTeams(selectedDate);
            } else if (tab === "billinghistory") {
                setViewDetails(false);
                getBillingHIstory(selectedDate);
            }else{
                setViewDetails(false)
                getClientInfo()
            }
        }
    };
      const handleInactiveClient=(id,status)=>{
        dispatch(deleteClient(id,status)).then((res)=>{
            if(res.value.status===200){
                toast.success("Client Deleted Successfully");
                setTimeout(()=>{
                   history.goBack();
                },2000)
            }
        })
      }
    return (
        <Layout>
            <div className="pb-3 flex gap-6">
                <button
                    onClick={() => history.goBack()}
                    className="rounded-md shadow-md border max-w-max bg-white border-fiver border-opacity-60 py-2 px-4 cursor-pointer">
                    Back
                </button>
                <div className="flex gap-6 justify-between items-center w-full">
                    <span className="leading-normal font-medium text-2xl">
                        {clientDataOverview?.client_name}
                    </span>
                    <Link
                        to="/bill/preview_invoice"
                        className="px-4 py-3 cursor-pointer bg-[#684D94] rounded-md text-white leading-normal font-medium text-sm">
                        Preview Invoice
                    </Link>
                </div>
            </div>
            <div className="w-full bg-[#FBF9FF] px-6 pt-6 pb-3 rounded-md shadow-md mt-4">
                <div className="flex relative justify-between items-start w-full">
                    <div className="grid grid-cols-2 w-full gap-4">
                        <div className="flex flex-col gap-4">
                            <div className="flex gap-2 flex-col justify-start items-start">
                                <span className="text-[#6B7280] font-medium leading-normal text-base">
                                    Client Name
                                </span>
                                <div className="flex flex-wrap w-full text-[#1D1D1D] font-normal text-lg leading-normal">
                                    {clientDataOverview?.client_name}
                                </div>
                            </div>
                            <div className="flex gap-2 flex-col justify-start items-start">
                                <span className="text-[#6B7280] font-medium leading-normal text-base">
                                    Client Description
                                </span>
                                <div className="flex flex-wrap w-full text-[#1D1D1D] font-normal text-lg leading-normal">
                                    {clientDataOverview.description}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2 justify-start items-start">
                                <span className="text-[#6B7280] font-medium leading-normal text-base">
                                    Billing Rate
                                </span>
                                <div className="flex flex-wrap w-full text-[#1D1D1D] font-normal text-lg leading-normal">
                                    ${clientDataOverview.bill_rate}/hr
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 justify-start items-start">
                                <span className="text-[#6B7280] font-medium leading-normal text-base">
                                    Billing Started from
                                </span>
                                <div className="flex flex-wrap w-full text-[#1D1D1D] font-normal text-lg leading-normal">
                                    {moment(clientDataOverview.created ?? new Date()).format(
                                        "D MMMM YYYY"
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <DotsVerticalIcon
                        className="w-5 h-5 hover:cursor-pointer"
                        onClick={() => setEditClient((prev) => !prev)}
                    />
                    {editClient && (
                        <div className="absolute bg-white py-2 text-gray-500 right-0 top-7 w-44 rounded-lg border border-gray-200 shadow-md">
                            <Link
                                to={`/client_details/?id=${id}&date=${moment(selectedDate).format("YYYY-MM-DD")}`}
                                className="flex items-center gap-2 px-4 pb-1 hover:bg-gray cursor-pointer">
                                <PencilIcon className="w-4 h-4" />
                                Edit Client
                            </Link>
                            <div className="flex items-center gap-2 px-4 pt-1 hover:bg-gray cursor-pointer"
                             onClick={()=> setConfirmationTrue(true)}
                            >
                                <TrashIcon className="w-4 h-4"   
                                />
                                Inactive
                            </div>
                        </div>
                    )}
                </div>
                <div className="hidden sm:block pt-4 xl:pt-6">
                    <div className="-mb-px flex space-x-8" aria-label="Tabs">
                        <div
                            key="overview"
                            onClick={() => {
                                handleTabSelect("overview");
                            }}
                            className={classNames(
                                selectTab === "overview"
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-md cursor-pointer"
                            )}
                            aria-current={selectTab === "overview" ? "page" : undefined}>
                            Overview
                        </div>

                        <div
                            key="teams"
                            onClick={() => {
                                handleTabSelect("teams");
                            }}
                            className={classNames(
                                selectTab === "teams" || viewDetails
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-md cursor-pointer"
                            )}
                            aria-current={
                                selectTab === "teams" || viewDetails ? "page" : undefined
                            }>
                            Teams
                        </div>
                        <div
                            key="billinghistory"
                            onClick={() => {
                                handleTabSelect("billinghistory");
                            }}
                            className={classNames(
                                selectTab === "billinghistory"
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-md cursor-pointer"
                            )}
                            aria-current={selectTab === "billinghistory" ? "page" : undefined}>
                            Billing History
                        </div>
                        <div
                            key="clientinfo"
                            onClick={() => {
                                handleTabSelect("clientinfo");
                            }}
                            className={classNames(
                                selectTab === "clientinfo"
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-md cursor-pointer"
                            )}
                            aria-current={selectTab === "clientinfo" ? "page" : undefined}>
                            Client Info
                        </div>
                    </div>
                </div>
                {selectTab === "overview" && (
                    <div className="w-full bg-white mt-4 px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="-ml-4 -mt-2 flex items-center lg-m:items-stretch lg-m:flex-col justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2">
                                <span className="text-[#1D1D1D] text-lg font-semibold leading-5">
                                    Overview
                                </span>
                            </div>
                            <div className="flex relative mt-4 cursor-pointer">
                                <DatePicker
                                    className="rounded-md cursor-pointer w-full"
                                    dateFormat="MMMM dd yyyy"
                                    selected={new Date(selectedDate)}
                                    onChange={(date) => handleDateChange(date)}
                                />
                                <CalendarIcon className="absolute top-2 right-2 w-6"></CalendarIcon>
                            </div>
                        </div>

                        <div className="w-full grid grid-flow-col gap-3 flex-wrap justify-stretch mt-4">
                            <div className="flex flex-col px-6 py-4 gap-3 rounded-lg shadow-md">
                                <span className="text-[#6B7280] font-medium leading-normal text-base">
                                    Online Time
                                </span>
                                <span className="text-[#684D94] text-lg font-bold leading-normal">
                                    {Math.floor(clientDataOverview.online_time / 60) +
                                        "hrs " +
                                        (clientDataOverview.online_time % 60) +
                                        "mins"}
                                </span>
                            </div>
                            <div className="flex flex-col px-6 py-4 gap-3 rounded-lg shadow-md">
                                <span className="text-[#6B7280] font-medium leading-normal text-base">
                                    Total Worked Time
                                </span>
                                <span className="text-[#684D94] text-lg font-bold leading-normal">
                                    {Math.floor(clientDataOverview.total_time / 60) +
                                        "hrs " +
                                        (clientDataOverview.total_time % 60) +
                                        "mins"}
                                </span>
                            </div>
                            <div className="flex flex-col px-6 py-4 gap-3 rounded-lg shadow-md">
                                <span className="text-[#6B7280] font-medium leading-normal text-base">
                                    Active Teams
                                </span>
                                <span className="text-[#684D94] text-lg font-bold leading-normal">
                                    {clientDataOverview.teams?.length}
                                </span>
                            </div>
                            <div className="flex flex-col px-6 py-4 gap-3 rounded-lg shadow-md">
                                <span className="text-[#6B7280] font-medium leading-normal text-base">
                                    Employee working
                                </span>
                                <span className="text-[#684D94] text-lg font-bold leading-normal">
                                    {clientDataOverview.members}
                                </span>
                            </div>
                            <div className="flex flex-col px-6 py-4 gap-3 rounded-lg shadow-md">
                                <span className="text-[#6B7280] font-medium leading-normal text-base">
                                    Bill Amt Till Date
                                </span>
                                <span className="text-[#684D94] text-lg font-bold leading-normal">
                                    ${clientDataOverview.total_cost}
                                </span>
                            </div>
                        </div>

                        <div className="flex flex-col gap-3 mt-5 w-full">
                            <span className="text-[#1D1D1D] font-semibold leading-normal text-lg">
                                Active Teams for the current month
                            </span>
                            <div className="mt-4 flex flex-col overflow-y-auto bg-gray-100 rounded-lg">
                                <div className="inline-block min-w-full align-middle">
                                    <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[640px] overflow-y-auto no-scrollbar">
                                        <table className="min-w-full border-separate">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                        Team Name
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                        Members
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                        Time Spent
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {clientDataOverview.teams?.map((teamVal, index) => (
                                                    <tr key={"clientteams" + index}>
                                                        <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                            {teamVal.name}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                            <div className="flex">
                                                                <UsersIcon className="mr-4 w-5"></UsersIcon>
                                                                <span className="text-[#1D1D1D] text-tiny font-medium leading-5">
                                                                    {teamVal.members}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                            {teamVal.duration}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {selectTab === "teams" && (
                    <div className="w-full bg-white mt-4 px-2 py-1 sm:px-6 rounded-lg">
                        <div className="-ml-4 -mt-2 flex items-center lg-m:items-stretch lg-m:flex-col justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2">
                                <span className="text-[#1D1D1D] text-lg font-semibold leading-5">
                                    Teams
                                </span>
                            </div>
                            <div className="flex relative mt-4 cursor-pointer">
                                <DatePicker
                                    className="rounded-md"
                                    dateFormat="MMMM dd yyyy"
                                    selected={new Date(selectedDate)}
                                    onChange={(date) => handleDateChange(date)}
                                />
                                <CalendarIcon className="absolute top-1 right-2 w-8"></CalendarIcon>
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 mt-3 w-full">
                            <div className="flex gap-2">
                                <div
                                    key="Active"
                                    onClick={() => setSubTeamTab("Active")}
                                    className={classNames(
                                        subTeamTab === "Active"
                                            ? "border-primary text-primary"
                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                        "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                    )}
                                    aria-current={subTeamTab === "Active" ? "page" : undefined}>
                                    Active
                                    <span
                                        className={classNames(
                                            subTeamTab === "Active"
                                                ? "bg-indigo-100 text-primary"
                                                : "bg-gray-100 text-gray-900",
                                            "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                                        )}>
                                        {clientTeams?.teams?.length ?? 0}
                                    </span>
                                </div>

                                {/* <div
                                    key="InActive"
                                    onClick={() => setSubTeamTab("InActive")}
                                    className={classNames(
                                        subTeamTab === "InActive"
                                            ? "border-primary text-primary"
                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                        "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                    )}
                                    aria-current={subTeamTab === "InActive" ? "page" : undefined}>
                                    Inactive
                                    <span
                                        className={classNames(
                                            subTeamTab === "InActive"
                                                ? "bg-indigo-100 text-primary"
                                                : "bg-gray-100 text-gray-900",
                                            "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                                        )}>
                                        8
                                    </span>
                                </div> */}
                            </div>
                            {subTeamTab === "Active" && (
                                <div className="flex flex-col overflow-y-auto bg-gray-100 rounded-lg">
                                    <div className="inline-block min-w-full align-middle">
                                        <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[640px] overflow-y-auto no-scrollbar">
                                            <table className="min-w-full border-separate">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                            Teams
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                            Members
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                            Time Spent
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {clientTeams.teams?.map((teamVal, idx) => (
                                                        <tr key={"teamClients" + idx}>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                                {teamVal.name}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                                <div className="flex">
                                                                    <UsersIcon className="mr-4 w-5"></UsersIcon>
                                                                    <span className="text-[#1D1D1D] text-tiny font-medium leading-5">
                                                                        {teamVal.members}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                                {teamVal.duration}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                                <div className="flex gap-2">
                                                                    <span
                                                                        onClick={() => {
                                                                            setViewDetails(true);
                                                                            setSelectTab("");
                                                                            // setSubTeamTab("");
                                                                            setTeamName(
                                                                                teamVal.name
                                                                            );
                                                                            dispatch(
                                                                                getClientTeamDetailed(
                                                                                    params.get(
                                                                                        "client"
                                                                                    ),
                                                                                    teamVal.name,
                                                                                    moment(
                                                                                        selectedDate
                                                                                    ).format(
                                                                                        "YYYY-MM-DD"
                                                                                    )
                                                                                )
                                                                            ).then((res) => {
                                                                                if (
                                                                                    res.value
                                                                                        .status ===
                                                                                    200
                                                                                ) {
                                                                                    setTeamDetailedData(
                                                                                        res.value
                                                                                            .data
                                                                                    );
                                                                                }
                                                                            });
                                                                        }}
                                                                        className="text-[#684D94] underline text-tiny font-medium leading-5 cursor-pointer">
                                                                        View Details
                                                                    </span>

                                                                    <TrashIcon className="w-5 h-5 text-danger cursor-pointer" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {subTeamTab === "InActive" && (
                                <div className="flex flex-col overflow-y-auto bg-gray-100 rounded-lg">
                                    <div className="inline-block min-w-full align-middle">
                                        <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[640px] overflow-y-auto no-scrollbar">
                                            <table className="min-w-full border-separate">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                            Teams
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                            Members
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                            Time Spent
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    <tr>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                            Team Name A
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                            <div className="flex">
                                                                <UsersIcon className="mr-4 w-5"></UsersIcon>
                                                                <span className="text-[#1D1D1D] text-tiny font-medium leading-5">
                                                                    28
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                            39hrs 30min
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                            <div className="flex gap-2">
                                                                <span className="text-[#684D94] underline text-tiny font-medium leading-5 decoration-5 cursor-pointer underline-offset-auto">
                                                                    Reactive Client
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {viewDetails && (
                    <div className="w-full bg-white mt-4 px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="-ml-4 -mt-2 flex items-center lg-m:items-stretch lg-m:flex-col justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2 gap-2 flex justify-center items-center">
                                <button
                                    className="bg-white border border-primary h-6 w-6 rounded-full flex justify-center items-center"
                                    onClick={() => {
                                        handleTabSelect("teams");
                                        setViewDetails(false);
                                    }}>
                                    <IoIosArrowBack className="text-gray-500" />
                                </button>
                                <span>{teamName}</span>
                            </div>
                            <div className="flex relative mt-4 cursor-pointer">
                                <DatePicker
                                    className="rounded-md"
                                    dateFormat="MMMM dd yyyy"
                                    selected={new Date(selectedDate)}
                                    onChange={(date) => handleDateChange(date)}
                                />
                                <CalendarIcon className="absolute top-1 right-2 w-8"></CalendarIcon>
                            </div>
                        </div>
                        <div className="mt-4 flex flex-col overflow-y-auto bg-gray-100 rounded-lg">
                            <div className="inline-block min-w-full align-middle">
                                <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[640px] overflow-y-auto no-scrollbar">
                                    <table className="min-w-full border-separate">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                    Employee Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                    Time Spent
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {teamDetailedData.teams?.map((userVal, idx) => (
                                                <tr key={"userval" + idx}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                        {userVal.employee_name}
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                        <div className="flex">
                                                            <UsersIcon className="mr-4 w-5"></UsersIcon>
                                                            <span className="text-[#1D1D1D] text-tiny font-medium leading-5">
                                                                {userVal.duration}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        onClick={() => {}}
                                                        className="whitespace-nowrap underline py-4 pl-4 pr-3 text-[#684D94] text-tiny font-medium leading-5 cursor-pointer text-sm sm:pl-4 border-b border-gray-200">
                                                        view screenshot
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {selectTab === "billinghistory" && (
                    <div className="w-full bg-white mt-4 px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="-ml-4 -mt-2 flex items-center lg-m:items-stretch lg-m:flex-col justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2 gap-2 flex justify-center items-center">
                                <span className="text-[#1D1D1D] font-semibold text-lg leading-normal">
                                    Billing History
                                </span>
                            </div>
                        </div>
                        <div className="mt-4 flex flex-col overflow-y-auto bg-gray-100 rounded-lg">
                            <div className="inline-block min-w-full align-middle">
                                <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[640px] overflow-y-auto no-scrollbar">
                                    <table className="min-w-full border-separate">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                    Bill Month
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 pl-4 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                    Teams
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                    Total Time Spent
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                    Billing Amount
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {clientBillingHistory.teams?.map(
                                                (billingHistory, idx) => (
                                                    <tr key={"billingHistory" + idx}>
                                                        <td className="whitespace-nowrap capitalize py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                            {billingHistory.time}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                            {billingHistory.teams.toString()}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                            {billingHistory.duration}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                            ${billingHistory.billing}
                                                        </td>
                                                        <td
                                                            onClick={() =>
                                                                history.push(
                                                                    "/bill/preview_invoice?client=" +
                                                                        params.get("client") +
                                                                        "&date=" +
                                                                        billingHistory.time?.replace(
                                                                            " ",
                                                                            "-"
                                                                        )
                                                                )
                                                            }
                                                            className="whitespace-nowrap underline py-4 pr-3 text-[#684D94] text-tiny font-medium leading-5 cursor-pointer text-sm sm:pl-4 border-b border-gray-200">
                                                            Preview Invoice
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {selectTab === "clientinfo" && (
                    <div className="w-full bg-white mt-4 px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="-ml-4 -mt-2 flex items-center lg-m:items-stretch lg-m:flex-col justify-between flex-wrap sm:flex-nowrap">
                            <div className="flex relative justify-between items-start w-full mt-4 ml-2 mb-10">
                                <div className="grid grid-cols-2 w-full gap-4">
                                    <div className="flex flex-col gap-8">
                                        <div className="flex gap-1 flex-col justify-start items-start">
                                            <span className="text-[#6B7280] font-medium leading-normal text-base">
                                                Email
                                            </span>
                                              {clientProfile["email"]?(
                                                    <div className="flex flex-wrap w-full text-[#1D1D1D] font-medium text-base leading-normal">{clientProfile["email"]}</div>
                                                 ):(
                                                    <div className="flex flex-wrap w-full text-[#6B7280] font-medium text-base leading-normal">not provided</div>
                                                 )} 
                                               </div>
                                        <div className="flex gap-1 flex-col justify-start items-start">
                                            <span className="text-[#6B7280] font-medium leading-normal text-base">
                                                Address Line 1
                                            </span>
                                            {clientProfile["address1"] ? (
                                             <div className="flex flex-wrap w-full text-[#1D1D1D] font-medium text-base leading-normal">{clientProfile["address1"]}</div>
                                          ):(
                                            <div className="flex flex-wrap w-full text-[#6B7280] font-medium text-base leading-normal">not provided</div>
                                          )}
                                        </div>
                                        <div className="flex gap-1 flex-col justify-start items-start">
                                            <span className="text-[#6B7280] font-medium leading-normal text-base">
                                                City
                                            </span>
                                            {clientProfile["city"] ? (
                                            <div className="flex flex-wrap w-full text-[#1D1D1D] font-medium text-base leading-normal">{clientProfile["city"]}</div>
                                            ):(
                                            <div className="flex flex-wrap w-full text-[#6B7280] font-medium text-base leading-normal">not provided</div>
                                            )}
                                        </div>
                                        <div className="flex gap-1 flex-col justify-start items-start">
                                            <span className="text-[#6B7280] font-medium leading-normal text-base">
                                                Country
                                            </span>
                                            {clientProfile["country"] ? (
                                         <div className="flex flex-wrap w-full text-[#1D1D1D] font-medium text-base leading-normal">{clientProfile["country"]}</div>
                                           ):(
                                            <div className="flex flex-wrap w-full text-[#6B7280] font-medium text-base leading-normal">not provided</div>
                                           )}
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-1 justify-start items-start">
                                            <span className="text-[#6B7280] font-medium leading-normal text-base">
                                                Phone number
                                            </span>
                                            {clientProfile["phone"] ? (
                                         <div className="flex flex-wrap w-full text-[#1D1D1D] font-medium text-base leading-normal">{clientProfile["phone"]}</div>
                                           ):(
                                            <div className="flex flex-wrap w-full text-[#6B7280] font-medium text-base leading-normal">not provided</div>
                                           )}
                                        </div>
                                        <div className="flex flex-col gap-1 justify-start items-start">
                                            <span className="text-[#6B7280] font-medium leading-normal text-base">
                                                Address Line2
                                            </span>
                                            {clientProfile["address2"]? (
                                                 <div className="flex flex-wrap w-full text-[#1D1D1D] font-medium text-base leading-normal">{clientProfile["address2"]}</div>
                                             ):(
                                                <div className="flex flex-wrap w-full text-[#6B7280] font-medium text-base leading-normal">not provided</div> 
                                             )}
                                        </div>
                                        <div className="flex flex-col gap-1 justify-start items-start">
                                            <span className="text-[#6B7280] font-medium leading-normal text-base">
                                                State
                                            </span>
                                            {clientProfile["state"] ? (
                                           <div className="flex flex-wrap w-full text-[#1D1D1D] font-medium text-base leading-normal">{clientProfile["state"] }</div>
                                              ):(
                                                <div className="flex flex-wrap w-full text-[#6B7280] font-medium text-base leading-normal">not provided</div>
                                              )}
                                        </div>
                                        <div className="flex flex-col gap-1 justify-start items-start">
                                            <span className="text-[#6B7280] font-medium leading-normal text-base">
                                                Zip Code
                                            </span>
                                            {clientProfile["zip"]? (
                                           <div className="flex flex-wrap w-full text-[#1D1D1D] font-medium text-base leading-normal">{clientProfile["zip"] }</div>
                                                ):(
                                           <div className="flex flex-wrap w-full text-[#6B7280] font-medium text-base leading-normal">not provided</div>  
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <Link
                                    to={`/client_details/?id=${id}&date=${moment(selectedDate).format("YYYY-MM-DD")}`}
                                    className="flex items-center gap-1 px-4 py-3 border border-[#D1D5DB] rounded-md hover:bg-gray cursor-pointer">
                                    <PencilIcon className="w-4 h-4 text-[#684D94]" />
                                    <pre className="text-[#684D94] font-medium text-base font-sans">
                                        Edit Client
                                    </pre>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        <ConfirmationDialoag
          open={confirmationTrue}
          onCancelClick={() => setConfirmationTrue(false)}
          onSubmitClick={() => {handleInactiveClient(id,"False");setConfirmationTrue(false);}}
          info={"Are you sure, you want to Inactive this Client ?"}
          submitButtonName={"Inactive"}
          cancelButtonName={"Cancel"}
        ></ConfirmationDialoag>
        </Layout>
    );
};
const mapStateToProps = (state) => ({
  teams: state.teams,
});

const mapDispatchToProps = (dispatch) => ({
  getClientProfileInfo: (id)=> dispatch(getClientProfileInfo(id)),
});

export default (
  connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails)
);


