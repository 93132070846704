import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import "./tracker.css";
import { Divider } from "@material-ui/core";
import { toast } from "react-toastify";
// import 'leaflet/dist/leaflet.css'
import { getActivityTimeTracker,timeTrackerData } from "../../services/timetracker/actions";
import Geocode from "react-geocode";
import { PhotographIcon, GlobeIcon } from "@heroicons/react/solid";

// import {GoogleMap, useJsApiLoader, MarkerClusterer, Marker, InfoBox, Polyline } from '@react-google-maps/api';

import { MapContainer, TileLayer, Marker, useMap, Popup, useMapEvents } from "react-leaflet";
import ReactAudioPlayer from "react-audio-player";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function rand() {
    return Math.round(Math.random() * 20) - 15;
}

function getModalStyle() {
    const top = 20;
    const left = 8;
    return {
        top: `${top}%`,
        left: `${left}%`,
        // transform: `translate(-${top}%, -${left}%)`,
        width: "83%",
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        top: 0,
        height: "70%",
        minHeight: "660px",
        width: 550,
        backgroundColor: theme.palette.background.paper,
        //border: "2px solid #000",
        boxShadow: theme.shadows[5],
        //padding: theme.spacing(2, 4, 3),
        padding: "20px",
    },
}));

export const Modaltracker = (props) => {
    // const {isLoaded} = useJsApiLoader({
    //     googleMapsApiKey: "AIzaSyDJg7NQ3472NFL_hOywV9M2tAYmCe6L8Jc"
    // });
    const { t } = useTranslation("common");
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [videoEnabled, setVideoEnabled] = React.useState(false);
    const [videoUrl, setVideoUrl] = React.useState([]);
    const [reportMessage, setreportMessage] = React.useState([]);

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [audioSrc, setAudioSrc] = useState("");
    const audio = new Audio(audioSrc == "" ? "" : audioSrc);

    const [selectedTab, setSelectedTab] = React.useState("screenshot");
    const dispatch = useDispatch();
    const [map, setMap] = React.useState(null);
    const [showMap, setShowMap] = React.useState(false);

    const [showMarkerLabel, setShowMarkerLabel] = React.useState(false);

    const [activityHistory, setActivityHistory] = useState("");
    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    const handleOpen = () => {
        if (disabled === true) {
            return;
        }
        setOpen(true);
        viewDetailss();
        getActivityHistory();
    };

    const handleClose = () => {
        props.closeHandle();
        setOpen(false);
    };

    const [viewdetails, setViewdetails] = React.useState([]);
    let start_time = `${props.Timekey}:${props.time_min}`;
    let end_time = `${props.value == 60 ? parseInt(props.Timekey) + 1 : props.Timekey}:${
        props.value == 60 ? "00" : props.value
    }`;
    const disabled = props.openDisabled;
    const imageData = (thumbnails) => {
        let activity = [];
        let lengthImage = thumbnails && thumbnails.length > 0 ? thumbnails.length : 0;
        for (let i = 0; i < lengthImage; i++) {
            activity.push(
                <div key={i} className="row" style={{ marginTop: "5px", marginBottom: "10px" }}>
                    <Grid>
                        <img
                            alt=""
                            src={thumbnails[i]}
                            style={{
                                width: "50px",
                                height: "45px",
                                marginLeft: "8px",
                            }}
                        />
                    </Grid>
                </div>
            );
        }
        return activity;
    };

    // console.log('PROPSMODAL', props);
    React.useEffect(() => {
        if (props.open) {
            setOpen(true);
            viewDetailss();
            getActivityHistory();
        }
    }, [props.open]);
    const ImageScreen =
        props.image.thumbnails && props.image.thumbnails.length > 0
            ? props.image.thumbnails[0]
            : props.hourly_thubm_image;

    const ImageScreenMulti =
        props.image.thumbnail_list && props.image.thumbnail_list.length > 0
            ? props.image.thumbnail_list[0].length > 1
                ? props.image.thumbnail_list[0]
                : props.image.thumbnail_list
            : "";
    const ImageScreens =
        props.image.screenshots && props.image.screenshots.length > 0
            ? props.image.screenshots[0]
            : props.hourly_ss_image;

    const ImageScreensMulti =
        props.image.screenshot_list && props.image.screenshot_list.length > 0
            ? props.image.screenshot_list[0].length > 1
                ? props.image.screenshot_list[0]
                : props.image.screenshot_list
            : "";

    const download = (e) => {
        fetch(localStorage.getItem("apiURL") + "/api/image_download_timetracker/", {
            method: "POST",
            headers: {
                authorization: "JWT " + localStorage.getItem("access_token"),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                url: e[0],
            }),
        })
            .then((res) => {
                if (res.status == 200) {
                    res.arrayBuffer()
                        .then((response) => {
                            const url = window.URL.createObjectURL(
                                new Blob([response, { type: "application/png" }])
                            );
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", response.byteLength + ".png"); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                        })
                        .catch((error) => {
                            console.log("error details", error.message);
                        });
                } else if (res.status == 401) {
                    toast.error("Something went wrong!, Refresh and Try Again");
                    return;
                }
            })
            .catch((error) => {
                console.log("error details", error.message);
            });
    };
    const getActivityHistory = () => {
        dispatch(
            getActivityTimeTracker(
                moment(props.selectedDate).format("YYYY-MM-DD"),
                props.userName,
                start_time,
                end_time
            )
        ).then((res) => {
            setActivityHistory(res.value.data);
        });
    };
    const viewDetailss = () => {
        fetch(
            localStorage.getItem("apiURL") +
                `/api/view_details_data/${moment(props.selectedDate).format(
                    "YYYY-MM-DD"
                )}/${start_time}/${end_time}/?username=${props.userName}`,
            {
                method: "GET",
                headers: {
                    authorization: "JWT " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                },
            }
        )
            .then((res) => {
                if (res.status == 200) {
                    return res.json();
                } else if (res.status == 401) {
                    let refresh_token = localStorage.getItem("refresh_token");
                    fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            refresh: refresh_token,
                        }),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            localStorage.setItem("access_token", data.access);
                            localStorage.setItem("refresh_token", data.refresh);
                            viewDetailss();
                        });
                }
            })
            .then((response) => {
                if (response === undefined) {
                    return;
                }
                for (let [key, value] of Object.entries(response.response_data)) {
                    setViewdetails(value);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        if (props.image.video) {
            if (props.active.idle_time == 10) {
                setVideoEnabled(false);
            } else if (props.image.video.length > 0) {
                setVideoUrl(props.image.video.filter((val) => val !== "no file"));
                setVideoEnabled(true);
                setAudioSrc(props.image.audio.find((val) => val !== "no file"));
            } else {
                setVideoEnabled(false);
                // if(props.active.item.project == ""){
                //     setShowMap(true);
                //     setSelectedTab('leafletMap');
                // }
            }
        }
    };
    const playAudio = () => {
        try {
            if (isVideoPlaying) {
                audio.play();
            } else {
                audio.pause();
            }
        } catch (error) {}
    };

    const getAddressFromLatLng = (lat, lng) => {
        Geocode.fromLatLng(lat, lng).then((res) => {
            console.log("ressAddrees", res);
        });
    };

    let faceSnap = "";
    if (props.image.webcam) {
        if (props.image.webcam.length > 0 && props.image.webcam[0] != "no file") {
            faceSnap = props.image.webcam[0];
        } else {
            faceSnap =
                "https://thumbs.dreamstime.com/b/default-avatar-profile-flat-icon-vector-contact-symbol-illustration-184752213.jpg";
        }
    } else {
        faceSnap =
            "https://thumbs.dreamstime.com/b/default-avatar-profile-flat-icon-vector-contact-symbol-illustration-184752213.jpg";
    }

    const HandleSelectTab = (e) => {
        setSelectedTab(e);
    };

    const HandleMobileSelectTab = (e) => {
        setSelectedTab(e.target.value);
    };

    return (
        <div>
            {
                props.active != undefined && props.active.item.project != "" ? (
                    // &&
                    // props.active.idle_time != 10
                    ImageScreenMulti == undefined || ImageScreenMulti == "" ? (
                        <div
                            className="screenshotWithSnap"
                            style={{ backgroundImage: `url(${ImageScreen})` }}
                            onClick={handleOpen}>
                            <img src={faceSnap} alt="" className="snapImage" />
                        </div>
                    ) : (
                        <div className={`"grid grid-cols-1 w-[163px] h-[103px] gap-1`}>
                            {ImageScreenMulti.length <= 1 ? (
                                <div
                                    className={
                                        "h-full w-full flex justify-end items-end" +
                                        (props.active.idle_time == 10 ? " relative" : "")
                                    }
                                    style={{ backgroundImage: `url(${ImageScreenMulti})` }}
                                    onClick={handleOpen}>
                                    <img
                                        src={faceSnap}
                                        alt=""
                                        className="h-5 w-5 mb-2 mr-1 z-10 rounded-[50%]"
                                    />
                                    {props.active.idle_time == 10 ? (
                                        <div className="absolute min-w-full text-center top-0 left-0 flex items-center justify-center w-full h-full bg-black opacity-50">
                                            <p className="text-white text-xl font-bold">
                                                IDLE TIME
                                            </p>
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <div
                                    className={
                                        "grid grid-cols-2 min-h-full relative" +
                                        (props.active.idle_time == 10 ? " relative" : "")
                                    }
                                    onClick={handleOpen}>
                                    {ImageScreenMulti.map((item, idx) => (
                                        <div
                                            key={idx}
                                            className={`${
                                                idx == 0 ? "col-span-2" : ""
                                            } h-full w-full flex justify-end items-end`}
                                            style={{ backgroundImage: `url(${item})` }}></div>
                                    ))}
                                    {props.active.idle_time == 10 ? (
                                        <div className="absolute min-w-full text-center top-0 left-0 flex items-center justify-center w-full h-full bg-black opacity-50">
                                            <p className="text-white text-xl font-bold">
                                                IDLE TIME
                                            </p>
                                        </div>
                                    ) : null}
                                    <img
                                        src={faceSnap}
                                        alt=""
                                        className="h-5 w-5 z-10 rounded-[50%] absolute right-1 bottom-1"
                                    />
                                </div>
                            )}
                        </div>
                    )
                ) : (
                    <div className="flex justify-center items-center bg-gray-400 w-[163px] h-24">
                        <span className=" w-full h-full flex justify-center items-center text-xl text-white font-semibold">
                            {t("inactive")}
                        </span>
                    </div>
                )

                //  `${props.Timekey}:${props.time_min}` > moment(new Date()).format('HH:mm') ?
                //         <div className='screenshotWithSnap flex justify-center items-center'><span className=" w-full h-full flex justify-center items-center">Inactive</span></div>
                //      :
                //     <div
                //         className='screenshotWithSnap'
                //         onClick={handleOpen}
                //     >
                //         <div className="w-full h-32">
                //             <MapContainer boxZoom={false} attributionControl={false} dragging = {false}  style={{height: '100%', width: '100%'}} center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
                //                 <TileLayer
                //                     attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                //                     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                //                 />
                //                 <Marker position={[51.505, -0.09]}>
                //                 </Marker>
                //             </MapContainer>
                //         </div>
                //     </div>
            }

            <Modal open={open} onClose={handleClose}>
                <div style={modalStyle} className={classes.paper}>
                    <div className="close_png border border-gray-400 z-10" onClick={handleClose}>
                        <img alt="" src={require("../../assets/close.png")} />
                    </div>

                    {/* <div className="bg-white border px-2 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                                Select a tab
                            </label>
                            <select
                                id="tabs"
                                name="tabs"
                                onChange={(e) => {HandleMobileSelectTab(e)}}
                                className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                                defaultValue={selectedTab}
                            >
                                <option key="screenshot" value="screenshot">Screenshots</option>
                                <option key="leafletMap" value="leafletMap">GPS</option>
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                <a key="screenshot" onClick={() => {HandleSelectTab("screenshot")}}
                                    className={classNames(
                                        selectedTab == "screenshot" 
                                            ? 'border-primary text-primary'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer'
                                    )}
                                    aria-current={selectedTab == "screenshot" ? 'page' : undefined}
                                >
                                    <PhotographIcon
                                        className={classNames(
                                            selectedTab == "screenshot" ? 'text-primary' : 'text-gray-400 group-hover:text-gray-500',
                                            '-ml-0.5 mr-2 h-5 w-5'
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span>Screenshots</span>
                                </a>
                                <a key="leafletMap" onClick={() => {HandleSelectTab("leafletMap")}}
                                    className={classNames(
                                        selectedTab == "leafletMap"
                                            ? 'border-primary text-primary'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer'
                                    )}
                                    aria-current={selectedTab == "leafletMap" ? 'page' : undefined}
                                >
                                    <GlobeIcon
                                        className={classNames(
                                            selectedTab == "leafletMap" ? 'text-primary' : 'text-gray-400 group-hover:text-gray-500',
                                            '-ml-0.5 mr-2 h-5 w-5'                                         
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span>GPS</span>
                                </a>
                            </nav>
                        </div>
                    </div> */}
                    <div className="container mx-auto mt-3 ">
                        {/* {selectedTab == "screenshot"
                         ?   */}
                        <div className="grid gap-2 sm:grid-cols-2 lg:grid-cols-2">
                            <div className=" h-full overflow-y-auto">
                                {/* {`${props.Timekey}:${props.time_min}` > moment(new Date()).format('HH:mm') ?
                                <div className='flex justify-center items-center bg-gray-400 w-full h-96'><span className=" w-full h-full flex justify-center items-center text-xl text-white font-semibold">Inactive</span></div>
                                : <> */}
                                <div className="close_view">
                                    <div style={{ display: "flex" }}>
                                        {props.ReportName == "admin" ? <div></div> : <div></div>}

                                        <div
                                            className="flex cursor-pointer"
                                            onClick={() => {
                                                download(ImageScreensMulti[0]);
                                            }}>
                                            {t("download")} {t("screenshot")}
                                            <span>
                                                <img
                                                    alt=""
                                                    src={require("../../assets/download.png")}
                                                    style={{ marginTop: "8px", marginLeft: "4px" }}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {videoEnabled && videoUrl?.length > 0 ? (
                                    <div className=" h-[530px] max-h-full overflow-y-auto">
                                        {videoUrl.map((vidUrl, index) => (
                                            <video
                                                key={"videoUrls" + index}
                                                title="video"
                                                style={{ marginTop: "16px" }}
                                                width="600"
                                                height="400"
                                                src={vidUrl}
                                                onPlay={() => {
                                                    setIsVideoPlaying(true);
                                                    playAudio();
                                                }}
                                                onPause={() => {
                                                    setIsVideoPlaying(false);
                                                    playAudio();
                                                }}
                                                allowFullScreen
                                                controls // Keep this for other controls
                                                controlsList="nodownload" // Hide the download button
                                                disablekb="1" // Disable keyboard controls
                                            ></video>
                                        ))}
                                    </div>
                                ) : ImageScreensMulti == undefined || ImageScreensMulti == "" ? (
                                    <img
                                        style={{
                                            backgroundColor: "transparent",
                                            width: "100%",
                                            marginTop: "16px",
                                        }}
                                        src={ImageScreens}
                                        alt=""
                                    />
                                ) : (
                                    <div className=" h-[530px] max-h-full overflow-y-auto">
                                        {ImageScreensMulti.map((item, idx) => (
                                            <div key={idx} className="relative">
                                                <img
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        width: "100%",
                                                        marginTop: "16px",
                                                    }}
                                                    src={item}
                                                    alt=""
                                                />
                                                {props.image.activity_points &&
                                                props.image.activity_points * 10 !== 0 ? null : (
                                                    <div className="absolute min-w-full h-full text-right top-0 left-0">
                                                        <div className="absolute w-full h-full bg-black opacity-50"></div>
                                                        <div className="w-full h-full flex items-center justify-center">
                                                            <p className="text-white z-10 text-2xl font-bold">
                                                                IDLE TIME
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <div
                                    style={{
                                        display: "flex",
                                        padding: "20px",
                                    }}>
                                    {videoEnabled ? <div></div> : imageData(props.image.thumbnails)}
                                </div>
                                {/* {
                                        videoEnabled 
                                        ? <div></div>
                                        : <div>
                                            <ReactAudioPlayer src={props.image.audio[1]} autoPlay={false} controls={true}/>
                                          </div>
                                    } */}

                                {/* </> */}
                                {/* } */}
                            </div>
                            <div>
                                <Grid>
                                    <Paper className="div_scroll">
                                        <div style={{ padding: "10px" }}>
                                            <div>
                                                <p className="text-lg text-textprimary font-semibold">
                                                    {t("requestsModule.base")}{" "}
                                                    {t("timeTracker.information")}
                                                </p>
                                                <div style={{ marginTop: "10px", display: "flex" }}>
                                                    <p style={{ fontSize: "15px" }}>
                                                        {t("requestsModule.duration")}:
                                                    </p>
                                                    <p
                                                        style={{
                                                            fontSize: "15px",
                                                            marginLeft: "5px",
                                                        }}>
                                                        10 {t("min")}
                                                        {"("}
                                                        {start_time} to {end_time}
                                                        {")"}
                                                    </p>
                                                </div>
                                                <div style={{ marginTop: "10px", display: "flex" }}>
                                                    <p style={{ fontSize: "15px" }}>
                                                        {t("project")}:
                                                    </p>
                                                    <p
                                                        style={{
                                                            fontSize: "15px",
                                                            marginLeft: "5px",
                                                        }}>
                                                        {props.image.project}
                                                    </p>
                                                </div>
                                                <div style={{ marginTop: "10px", display: "flex" }}>
                                                    <p style={{ fontSize: "15px" }}>
                                                        {t("activity")} {t("requestsModule.Index")}:
                                                    </p>
                                                    <p
                                                        style={{
                                                            fontSize: "15px",
                                                            marginLeft: "5px",
                                                        }}>
                                                        {props.image.activity_points
                                                            ? props.image.activity_points * 10
                                                            : 0}
                                                        %
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="text-lg text-textprimary font-semibold mt-4">
                                                    {" "}
                                                    {t("requestsModule.webcamShot")}{" "}
                                                </p>
                                                <img
                                                    src={faceSnap}
                                                    alt=""
                                                    className="detailedSnap"
                                                />
                                            </div>

                                            <Divider />
                                            <p
                                                style={{
                                                    fontSize: "15px",
                                                    fontWeight: "bold",
                                                    marginTop: "10px",
                                                }}>
                                                {t("activity")} {t("requestsModule.history")}
                                            </p>
                                            <table
                                                style={{
                                                    borderCollapse: "collapse",
                                                    width: "100%",
                                                }}>
                                                <tbody>
                                                    <tr style={{ backgroundColor: "#eeeeee" }}>
                                                        <th className="tableActivity">
                                                            <p className="tableHistory">
                                                                {" "}
                                                                {t("time")}
                                                            </p>
                                                        </th>
                                                        <th className="tableActivity">
                                                            <p className="tableHistory">
                                                                <img
                                                                    src={require("../../assets/mouseimg.webp")}
                                                                    style={{ width: "25px" }}
                                                                    alt=""
                                                                />
                                                            </p>
                                                        </th>
                                                        <th className="tableActivity">
                                                            <p className="tableHistory">
                                                                <img
                                                                    alt=""
                                                                    src={require("../../assets/keyboard.png")}
                                                                />
                                                            </p>
                                                        </th>
                                                        <th className="tableActivity">
                                                            <p className="tableHistory">
                                                                {" "}
                                                                {t("requestsModule.applicationCap")}
                                                            </p>
                                                        </th>
                                                    </tr>

                                                    <tr
                                                        style={{
                                                            borderBottom: "1px solid #eeeeee",
                                                        }}>
                                                        <td className="tableActivity">
                                                            <p className="tableHistory">
                                                                {viewdetails.start_time}
                                                            </p>
                                                        </td>
                                                        <td className="tableActivity">
                                                            <p className="tableHistory">
                                                                {props.image.mouse_count}
                                                            </p>
                                                        </td>
                                                        <td className="tableActivity">
                                                            <p className="tableHistory">
                                                                {props.image.key_count}
                                                            </p>
                                                        </td>
                                                        <td className="tableActivity">
                                                            <p className="tableHistory">
                                                                {viewdetails.program}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    {activityHistory &&
                                                        activityHistory.length > 0 &&
                                                        activityHistory.map((val, idx) => {
                                                            return (
                                                                <tr
                                                                    key={"history" + idx}
                                                                    style={{
                                                                        borderBottom:
                                                                            "1px solid #eeeeee",
                                                                    }}>
                                                                    <td className="tableActivity">
                                                                        <p className="tableHistory">
                                                                            {val.time}
                                                                        </p>
                                                                    </td>
                                                                    <td className="tableActivity">
                                                                        <p className="tableHistory"></p>
                                                                    </td>
                                                                    <td className="tableActivity">
                                                                        <p className="tableHistory"></p>
                                                                    </td>
                                                                    <td className="tableActivity">
                                                                        <p className="tableHistory">
                                                                            {val.program}
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Paper>
                                </Grid>
                            </div>
                        </div>
                        {/* :   <div className="grid gap-2 sm:grid-cols-2 lg:grid-cols-2">
                                <div className="w-full h-96">
                                    <MapContainer style={{height: '100%', width: '100%'}} center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
                                        <TileLayer 
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        <Marker position={[51.505, -0.09]}>
                                            <Popup>
                                                A pretty CSS3 popup. <br /> Easily customizable.
                                            </Popup>
                                        </Marker>
                                    </MapContainer>
                                </div> 
                                <div>
                                    <Grid>
                                        <Paper className='div_scroll'>
                                            <div style={{ padding: "10px" }}>
                                                
                                                    <div>
                                                        <p className="text-lg text-textprimary font-semibold">
                                                            Base information
                                                        </p>
                                                        
                                                        <div style={{ marginTop: "10px", display: 'flex' }}>
                                                            <p style={{ fontSize: "15px" }}>Duration:</p>
                                                            <p style={{ fontSize: "15px", marginLeft: '5px' }}>
                                                                10 min({props.Timekey}:{props.time_min} to{" "}
                                                                {props.Timekey}:{props.value})
                                                            </p>
                                                        </div>
                                                        <div style={{ marginTop: "10px", display: 'flex' }}>
                                                            <p style={{ fontSize: "15px" }}>Project:</p>
                                                            <p style={{ fontSize: "15px", marginLeft: '5px' }}>
                                                                {props.image.project}
                                                            </p>
                                                        </div>
                                                        <div style={{ marginTop: "10px", display: 'flex' }}>
                                                            <p style={{ fontSize: "15px" }}>
                                                                Activity Index:
                                                            </p>
                                                        
                                                            
                                                                <p style={{ fontSize: "15px", marginLeft: '5px'}}
                                                                >
                                                                    {props.image.activity_points
                                                                        ? props.image.activity_points * 10
                                                                        : 0}
                                                                    %
                                                                </p>
                                                        
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className="text-lg text-textprimary font-semibold mt-4"> Webcam shot </p>
                                                        <img
                                                            src={faceSnap}
                                                            alt=''
                                                            className='detailedSnap'
                                                        />
                                                    </div>
                                                
                                                <Divider />
                                                <p
                                                    style={{
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    Activity History
                                                </p>
                                                <table
                                                    style={{ borderCollapse: "collapse", width: "100%" }}
                                                >
                                                    <tbody>
                                                        <tr style={{ backgroundColor: "#eeeeee" }}>
                                                            <th className='tableActivity'>
                                                                <p className='tableHistory'>Time</p>
                                                            </th>
                                                            <th className='tableActivity'>
                                                                <p className='tableHistory'>
                                                                    <img
                                                                        src={require("../../assets/mouseimg.webp")}
                                                                        style={{ width: "25px" }}
                                                                    />
                                                                </p>
                                                            </th>
                                                            <th className='tableActivity'>
                                                                <p className='tableHistory'>
                                                                    <img src={require("../../assets/keyboard.png")} />
                                                                </p>
                                                            </th>
                                                            <th className='tableActivity'>
                                                                <p className='tableHistory'>Application</p>
                                                            </th>
                                                        </tr>
                                                        
                                                        <tr  style={{ borderBottom: "1px solid #eeeeee" }}>
                                                            <td className='tableActivity'>
                                                                <p className='tableHistory'>
                                                                    {viewdetails.start_time}
                                                                </p>
                                                            </td>
                                                            <td className='tableActivity'>
                                                                <p className='tableHistory'>
                                                                    {viewdetails.mouse_count}
                                                                </p>
                                                            </td>
                                                            <td className='tableActivity'>
                                                                <p className='tableHistory'>
                                                                    {viewdetails.key_count}
                                                                </p>
                                                            </td>
                                                            <td className='tableActivity'>
                                                                <p className='tableHistory'>
                                                                    {viewdetails.program}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Paper>
                                    </Grid>
                                </div>
                          </div>
                        } */}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

{
    /* <GoogleMap
        mapContainerStyle={{width: '100%', height: '100%'}}
        center={{lat: 19.170188, lng: 73.248164}}
        zoom={17}
        onLoad={onLoad}
        onUnmount={onUnmount}
        >
            <Marker  position={{lat: 19.170188, lng: 73.248164}} label = "" cursor="aks" onMouseDown={(e) => {setShowMarkerLabel(!showMarkerLabel)}}> {showMarkerLabel ? <div className="z-30 ">hiiii</div> : <div></div>}</Marker>
            <Marker  position={{lat: 19.170700, lng: 73.248700}} label = "" cursor="aks" onMouseDown={(e) => {setShowMarkerLabel(!showMarkerLabel)}}> {showMarkerLabel ? <div className="z-30 ">hiiii</div> : <div></div>}</Marker>
            {showMarkerLabel ? 
                <InfoBox
                    // onLoad={onLoad}
                    // options={options}
                    position={{lat: 19.170188, lng: 73.248164}}
                    >
                    <div style={{ backgroundColor: '#684D94', opacity: 0.75, padding: 12, borderRadius: 8,  }}>
                        <div style={{ fontSize: 16, color: `white` }}>
                            Hello, World!
                        </div>
                    </div>
                </InfoBox>
            : null}
            <Polyline options={ployLineOPtions} path={path} />
    </GoogleMap> */
}
