import { ACTION_TYPES } from '../../../constants';
import api from '../api';

export const fetchSavedcards = (email) => ({
    type: ACTION_TYPES.GET_SAVED_PAYMENT_CARDS,
    payload: api.fetchSavedCards(email)
})

export const payForApp = (data) => ({
    type: ACTION_TYPES.PAY_FOR_APP,
    payload: api.payForApp(data)
})