import { API_ENDPOINTS } from '../../constants';
import {transport, padlocktransport} from '../../Networks/Auth.js';

export default {
    fetchSavedCards: (data) => {
        let config = {
            url: API_ENDPOINTS.GET_SAVED_PAYMENT_CARDS,
            method: 'POST',
            data: data,
            // withCredentials: true,
        };
        return padlocktransport(config);
    },

    payForApp: (data) => {
        let config = {
            url: API_ENDPOINTS.PAY_FOR_APP,
            method: 'POST',
            data: data,
        };
        return padlocktransport(config);
    }
}