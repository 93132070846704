import React, { Component, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { connect } from "react-redux";
import SelectField from "../../../Components/SelectField";
import DateRangeFilter from "./DatePicker";
import InputField from "../../../Components/InputField";

//actions
import { fetchallEmployees } from "../../../services/projects/actions";
import {
  fetchEmployeeAttendance,
  fetchAttendanceTimings,
  updateAttendanceTimings,
} from "../../../services/reports/actions";

//icons
import { CalendarIcon, CogIcon, DownloadIcon } from "@heroicons/react/outline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import MoreTimeIcon from "@mui/icons-material/MoreTime";

//dependencies
import moment from "moment";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import "jspdf-autotable";
import BeatLoader from "react-spinners/BeatLoader";

import { withTranslation } from "react-i18next";

const userRole = localStorage.getItem("ro");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

let d = new Date();

let firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
let c = convert(firstDay);

let lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
let cc = convert(lastDay);

class Attendence_tab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activetab: 1,
      startDate: c,
      endDate: cc,
      empAttendance: [],
      empAttendance1: [],
      empAttendance2: [],
      selectedEmployee: "",
      userId: "",
      showDateRange: false,
      showSettingsModal: false,
      workingHours: 0,
      setWorkingHours: 8,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const url = window.location.pathname;
      const query = new URLSearchParams(url);
      const empname = query.get("employee");
      this.setState({
        selectedEmployee: empname == "" || empname == null ? "" : empname,
      });
      window.history.pushState(
        {},
        null,
        `/reports/attendance/?employee=${this.state.selectedEmployee}`
      );
    }, 200);
    // const project = query.get('project');
    this.props.fetchallEmployees();
    this.fetch_employee_attendance();
    this.fetchAttendanceTiming();
  }

  fetchAttendanceTiming = () => {
    this.props.fetchAttendanceTimings().then((res) => {
      if (res.value.status == 200) {
        this.setState({
          workingHours: res.value.data.number,
        });
      } else {
        this.setState({
          workingHours: 480,
        });
      }
    });
  };


  fetch_employee_attendance = () => {
    let startYear = moment(this.state.startDate).format("YYYY");
    let startMonth = moment(this.state.startDate).format("M");
    let endMonth = moment(this.state.endDate).format("M");
    let userId = this.state.userId;

      this.props
      .fetchEmployeeAttendance(startYear, startMonth, userId)
      .then((data) => {
        if (data.value.status == 200) {
          this.setState({ empAttendance: data.value.data });
        } else {
          this.setState({ empAttendance: [] });
        }
      });

    // if (startMonth !== endMonth) {
    //   console.log("i m running",endMonth)
    //   this.props
    //   .fetchEmployeeAttendance(startYear, endMonth, userId)
    //   .then((data) => {
    //     if (data.value.status == 200) {
    //       this.setState({ empAttendance: data.value.data });
    //     } else {
    //       this.setState({ empAttendance: [] });
    //     }
    //   }); 
    // }
  };

  render() {
    const { t } = this.props;

    const allEmployees = this.props.allEmployees;
    let listofAllEmployees = allEmployees && allEmployees.AllEmployees;

    const attendance = this.props.attendance;
    const attendanceLoading =
      attendance && attendance.isFetchingEmployeesAttendance;

    let Select_emp_dropdown =
      listofAllEmployees &&
      listofAllEmployees.map((emp, index) => ({
        name: emp.user_first_name + " " + emp.user_last_name,
        id: emp.user_id,
      }));
      
    Select_emp_dropdown = listofAllEmployees && [...Select_emp_dropdown]?.sort((a, b) => a.name.localeCompare(b.name))
    
    const initalData = this.state.empAttendance;

    let employeesAttendanceArray =
      initalData && initalData.map((emp, idx) => emp.username);

    let employeesAttendanceDateArray =
      initalData &&
      initalData.map((emp, idx) => moment(emp.date).format("DD MMM"));
    let uniqueEmployee = [...new Set(employeesAttendanceArray)];
    let uniqueDates = [...new Set(employeesAttendanceDateArray)];

    const handleSelectEmployee = (e) => {
      this.setState(
        { [e.target.value]: e.target.value, userId: e.target.value },
        () => {
          if (e.target.value == "") {
            this.props
              .fetchEmployeeAttendance(
                moment(this.state.startDate).format("YYYY"),
                moment(this.state.startDate).format("M"),
                ""
              )
              .then((data) => {
                this.setState({ empAttendance: data.value.data });
              });
          } else {
            this.props
              .fetchEmployeeAttendance(
                moment(this.state.startDate).format("YYYY"),
                moment(this.state.startDate).format("M"),
                e.target.value
              )
              .then((data) => {
                this.setState({ empAttendance: data.value.data });
              });
          }
        }
      );
    };


    const handeThisMonth = () => {
      this.setState({ startDate: c, endDate: cc }, () => {
        this.fetch_employee_attendance();
      });
    };

    const onChange = (ranges) => {
      let dd = convert(ranges.startDate);
      let rr = convert(ranges.endDate);
      // this.setState({ startDate: dd, endDate: rr });
      // this.fetch_employee_attendance();

      this.setState({ startDate: dd, endDate: rr }, () => {
        this.fetch_employee_attendance();
      });
    };

    const updateAttendancetiming = () => {
      if (userRole == "Observer") {
        return;
      }
      const dataa = {
        number: (this.state.setWorkingHours) * 60,
      };

      this.props.updateAttendanceTimings(dataa).then((data) => {
        if (data.value.status == 200) {
          toast("Updated Successfully");
          this.fetchAttendanceTiming();
          this.setState({ showSettingsModal: false });
        } else {
          toast("Something went wrong, Try Again");
          this.setState({ showSettingsModal: false });
        }
      });
    };

    const exportPDF = () => {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape
      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);
      const title = "Employees Report";
      const headers = [["Name", "Total Time(min)", "Hours", "Date"]];
      const data = initalData.filter((el)=>moment(el.date).isSameOrBefore(moment(new Date()))).map((elt) => [
        elt.username,
        elt.time,
        (elt.time / 60).toFixed(2),
        elt.date,
      ]);
      let content = {
        startY: 50,
        head: headers,
        theme: "grid",
        body: data,
      };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save("report.pdf");
    };

    // console.log(uniqueDates,"startmonth,endmonth",moment(this.state.startDate).format("M") === moment(this.state.startDate).format("M"))
    return (
        <div>
            <div className="flex justify-between items-start mb-8 lg:items-center flex-col lg:flex-row mt-5">
                {/* <div className='flex rounded-full justify-center -translate-y-2 items-center mt-5 bg-white'>
                    <div className={`transition ease-in-out delay-110  ${this.state.activetab == 1 ? "bg-primary text-white" : "bg-white text-gray-500"} font-medium p-2 px-4 rounded-full cursor-pointer`} onClick={() => {this.setState({activetab: 1})}}>Overview</div>
                    <div className={`transition ease-in-out delay-110  ${this.state.activetab == 2 ? "bg-primary text-white" : "bg-white text-gray-500"} font-medium p-2 px-4 w-full rounded-full cursor-pointer`} onClick={() => {this.setState({activetab: 2})}}>Detailed</div>
                </div> */}
                <div className="flex flex-col sm:flex-row">
                    <button
                        className="bg-secondary rounded-full p-2 px-5 w-32 mb-3 sm:mb-0 mt-4 sm:mt-0 sm:w-auto text-white font-medium mr-3"
                        onClick={() => {
                            handeThisMonth();
                        }}>
                        {t("ReportsTab.this")} {t("ReportsTab.month")}
                    </button>
                    <div className="gap-2 px-3 py-2 bg-white flex items-center rounded-full justify-start cursor-pointer">
                        <CalendarIcon
                            className="h-6 w-6"
                            onClick={() => {
                                this.setState({ showDateRange: true });
                            }}
                        />
                        <input
                            value={`${new Date(this.state.startDate).toDateString()} - ${new Date(
                                this.state.endDate
                            ).toDateString()}`}
                            disabled
                            className="w-[260px] bg-white rounded-full font-medium text-gray-600"
                            onClick={() => {
                                this.setState({ showDateRange: true });
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="bg-white px-4 py-5 mt-4 border-b border-gray-200 sm:px-6 rounded-lg">
                <div className="flex justify-end items-center mt-2">
                    <div>
                        <CogIcon
                            className="h-6 w-6 mr-2 cursor-pointer"
                            onClick={() => {
                                this.setState({ showSettingsModal: true });
                            }}
                        />
                    </div>
                    <div>
                        <DownloadIcon
                            className="h-6 w-6 cursor-pointer"
                            onClick={() => {
                                exportPDF();
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-start justify-between md:items-center my-2">
                    <div className="w-52">
                        <SelectField
                            FieldName={t("select") + " " + t("employee")}
                            onSelectChange={(e) => {
                                handleSelectEmployee(e);
                            }}
                            name={"Select Employee"}
                            arrayOfData={Select_emp_dropdown}
                            id={"name"}></SelectField>
                    </div>
                    <div className="flex mt-4 md:mt-0 md:translate-y-2">
                        <div className="flex items-center mr-4">
                            <div>
                                <CheckCircleOutlineIcon className="text-secondary mr-1" />
                            </div>
                            <div className="text-md font-medium text-secondary">
                                {t("ReportsTab.present")}
                            </div>
                        </div>
                        <div className="flex items-center mr-4">
                            <div>
                                <CancelOutlinedIcon className="text-red-500 mr-1" />
                            </div>
                            <div className="text-md font-medium text-red-500">
                                {t("ReportsTab.absent")}
                            </div>
                        </div>
                        <div className="flex items-center mr-4">
                            <div>
                                <MoreTimeIcon className="text-[#A99B19] mr-1" />
                                <div className="opacity-0 group-hover:opacity-100 duration-300 absolute inset-x-0 bottom-0 flex justify-center items-end text-xl text-black font-semibold">
                                    overtime
                                </div>
                            </div>
                            <div className="text-md font-medium text-[#A99B19]">
                                {t("Overtime")}
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div>
                                <AccessTimeIcon className="text-blue-500 mr-1" />
                            </div>
                            <div className="text-md font-medium text-blue-500">
                                {t("ReportsTab.late")}
                            </div>
                        </div>
                    </div>
                </div>
                {attendanceLoading ? (
                    <div className="text-center h-64 w-full flex justify-center items-center">
                        <BeatLoader
                            color={"#684994"}
                            attendanceLoading={attendanceLoading}
                            size={15}
                            margin={2}
                        />{" "}
                    </div>
                ) : (
                    <div className="mt-7 flex flex-col bg-gray-100" style={{ maxHeight: "auto" }}>
                        <div className="inline-block min-w-full align-middle">
                            <div className="shadow-sm ring-1 ring-black ring-opacity-5 w-full h-[640px] overflow-auto no-scrollbar">
                                <table
                                    className="min-w-full border-separate"
                                    style={{ borderSpacing: 0 }}>
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="sticky left-0 z-20 top-0 text-left border-b border-[#8C8C8C] bg-gray-50 py-3.5 pl-4 pr-3 text-sm font-normal leading-normal text-black sm:pl-6 lg:pl-12">
                                                {t("employee")} {t("name")}
                                            </th>
                                            {uniqueDates
                                                .filter(
                                                    (ele) =>
                                                        ele.split(" ")[0] >=
                                                            this.state.startDate.split("-")[2] &&
                                                        ele.split(" ")[0] <=
                                                            this.state.endDate.split("-")[2]
                                                )

                                                .map((date, idx) => (
                                                    <th
                                                        key={idx}
                                                        scope="col"
                                                        className="sticky top-0 z-10 shadow text-left border-x border-b border-[#8C8C8C] bg-gray-50 py-3.5 pl-4 pr-3 text-[16px] font-medium leading-normal text-[#1D1D1D] sm:pl-6 lg:pl-12">
                                                        {date}
                                                    </th>
                                                ))}
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {uniqueEmployee.map((user, idx) => (
                                            <tr key={user}>
                                                <td
                                                    className={classNames(
                                                        idx !== idx.length - 1
                                                            ? "border-b border-gray-200"
                                                            : "",
                                                        "sticky left-0 w-[20%] z-10 top-0 py-4 pl-4 border-y border-[#8C8C8C]  bg-gray-50 pr-3 text-sm font-medium text-[#684D94] leading-normal font-sans sm:pl-6 lg:pl-12"
                                                    )}>
                                                    {user}
                                                </td>
                                                {this.state.empAttendance
                                                    .filter(
                                                        (emp, idx) =>
                                                            emp.username == user &&
                                                            emp.date.split("-")[2] >=
                                                                this.state.startDate.split(
                                                                    "-"
                                                                )[2] &&
                                                            emp.date.split("-")[2] <=
                                                                this.state.endDate.split("-")[2]
                                                    )
                                                    .map((empp, indx) => (
                                                        <td
                                                            key={empp.username + indx}
                                                            className={classNames(
                                                                idx !== idx.length - 1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "py-4 pl-4 pr-3 text-sm font-medium border border-[#8C8C8C] text-gray-900 sm:pl-6 lg:pl-12"
                                                            )}>
                                                            {moment(empp.date).isAfter(moment()) ? (
                                                                <></>
                                                            ) : empp.time >
                                                              this.state.workingHours ? (
                                                                <div className="relative group cursor-pointer">
                                                                    <MoreTimeIcon className="text-[#A99B19] mr-1" />
                                                                    <div className="opacity-0 group-hover:opacity-100 duration-300 absolute inset-x-0 bottom-5 flex justify-center items-end text-xl text-black font-semibold">
                                                                        {Math.floor(
                                                                            (empp.time -
                                                                                this.state
                                                                                    .workingHours) /
                                                                                60
                                                                        ) +
                                                                            "hr" +
                                                                            ((empp.time -
                                                                                this.state
                                                                                    .workingHours) %
                                                                                60) +
                                                                            "min"}
                                                                    </div>
                                                                </div>
                                                            ) : empp.status || empp.time ? (
                                                                <CheckCircleOutlineIcon className="text-secondary" />
                                                            ) : empp.time ? (
                                                                <AccessTimeIcon className="text-blue-500" />
                                                            ) : (
                                                                <CancelOutlinedIcon className="text-red-500" />
                                                            )}
                                                        </td>
                                                    ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <Transition.Root show={this.state.showDateRange} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-30 inset-0 overflow-y-auto"
                    onClose={() => {
                        this.setState({ showDateRange: false });
                    }}>
                    <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[600px] sm:w-full sm:p-6">
                                <DateRangeFilter onChange={onChange} />
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={this.state.showSettingsModal} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-30 inset-0 overflow-y-auto"
                    onClose={() => {
                        this.setState({ showSettingsModal: false });
                    }}>
                    <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            {/* <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[600px] sm:w-full sm:p-6">
                  <div>
                    <p className="text-md font-semibold">Set Working Hours</p>
                    <div className="mt-4 w-4/6">
                      <InputField
                        FieldName="Minutes"
                        value={this.state.setWorkingHours}
                        onInputChange={(e) => {
                          this.setState({
                            setWorkingHours: e.target.value,
                          });
                        }}
                        type="number"
                        placeholder={`default: ${this.state.workingHours}`}
                      />
                    </div>
                    <div className="mt-4 flex justify-start items-center">
                      <button
                        className={`${
                          userRole == "Observer"
                            ? "bg-primary opacity-70"
                            : "bg-primary"
                        } rounded-md p-1 px-3 text-white mr-2`}
                        onClick={() => {
                          updateAttendancetiming();
                        }}
                      >
                        Submit
                      </button>
                      <button
                        className="rounded-md p-1 px-3 border border-gray-600"
                        onClick={() => {
                          this.setState({ showSettingsModal: false });
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div> */}

                            <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-[520px] sm:w-full">
                                <div className="flex flex-col">
                                    <div className="flex justify-between items-start w-full p-4 border-b border-[#EAEAEA]">
                                        <p className="text-[#1D1D1D] font-medium text-base leading-normal">
                                            Default working hours
                                        </p>
                                        <button
                                            className="w-6 h-6"
                                            onClick={() => {
                                                this.setState({ showSettingsModal: false });
                                            }}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none">
                                                <path
                                                    d="M6 18L18 6M6 6L18 18"
                                                    stroke="#6B7280"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="flex flex-col gap-[9px] px-4 py-3">
                                        <p className="self-stretch text-[#1D1D1D] text-tiny font-medium leading-normal">
                                            Time to work per day
                                        </p>
                                        <div className="w-44 h-10 flex justify-center items-center">
                                            <div className="w-full mx-auto pl-3 relative justify-end items-center flex border border-gray-300 rounded-md focus:outline-none">
                                                <input
                                                    type="number"
                                                    className={
                                                        "block remove-arrow w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm "
                                                    }
                                                    placeholder={`default: ${
                                                        this.state.workingHours / 60
                                                    }`}
                                                    min="0"
                                                    value={this.state.setWorkingHours}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            setWorkingHours: e.target.value,
                                                        });
                                                    }}
                                                />
                                                <div className="self-stretch  bg-gray-200 p-3 justify-center items-center inline-flex">
                                                    <p className="text-[#6B7280] text-sm font-medium leading-4">
                                                        hrs
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-[9px] px-4 py-3">
                                        <p className="self-stretch text-[#1D1D1D] text-tiny font-medium leading-normal">
                                            Time to work per month
                                        </p>
                                        <div className="w-44 h-10 bg-[#F5F5F5] rounded-lg border border-gray-300 justify-between items-center gap-2 inline-flex">
                                            <div className="pl-3 text-[#666] text-tiny font-medium leading-4">
                                                {this.state.setWorkingHours * 24}
                                            </div>
                                            <div className="self-stretch bg-gray-200 justify-center items-center gap-2.5 p-3 inline-flex">
                                                <p className="text-[#6B7280] text-sm font-medium leading-4">
                                                    hrs
                                                </p>
                                            </div>
                                        </div>
                                        <p className="self-stretch text-[#6B7280] text-sm font-sm leading-normal">
                                            Note: Automatically calculate monthly work time based on
                                            work per day input.
                                        </p>
                                    </div>
                                    <div className="w-full flex items-center justify-center pl-4 pr-4 pb-4 pt-3">
                                        <button
                                            className="w-full p-3 bg-[#684D94] rounded-md self-stretch text-white text-tiny font-medium leading-normal"
                                            onClick={() => {
                                                updateAttendancetiming();
                                            }}>
                                            Save changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allEmployees: state.projects,
  attendance: state.report,
});

const mapDispatchToProps = (dispatch) => ({
  fetchallEmployees: () => dispatch(fetchallEmployees()),
  fetchAttendanceTimings: () => dispatch(fetchAttendanceTimings()),
  fetchEmployeeAttendance: (year, month, userId) =>
    dispatch(fetchEmployeeAttendance(year, month, userId)),
  updateAttendanceTimings: (workingHours) =>
    dispatch(updateAttendanceTimings(workingHours)),
});

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(Attendence_tab)
);
