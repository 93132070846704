import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { connect } from 'react-redux';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/solid';
import PrimaryButton from '../../Components/PrimaryButton';
import {fetchPaymentPlan , getTypeInterval,getAddress , postType, getLastPayment, postGenerateInvoice, postPdfGenerator, postTypeInterval, paypalPayment, cancelPlan_admin} from '../../services/settings/action';
import { fetchactiveuserslist } from '../../services/teams/actions';
import moment from 'moment';
import {toast} from 'react-toastify';
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';


import FileSaver from 'file-saver';
import './loader.css';
import SavedCards from '../Payments/SavedCards';
import { withTranslation } from 'react-i18next';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


class PaymentsTab extends Component {
    constructor() {
        super();
        this.state = {
            PaymentsSubSelectedTab: "plan",
            intervalType: "",
            showUpdateTypeInterval: false,
            showPaymentSelection: false,
            paymentType: "",
            paymentRedirectLoading: false,
            showCancelPlan: false,

            showSavedCards: false,
            invoiceId: "",
            currentURL: "",
        };
    }

    HandleMobileSelectTab = (e) => {
        this.setState({ PaymentsSubSelectedTab: e.target.value });
        setTimeout(() => {
            window.history.pushState(
                {},
                null,
                `/settings/payments/${this.state.PaymentsSubSelectedTab}`
            );
        }, 200);
    };

    HandleSelectTab = (e) => {
        this.setState({ PaymentsSubSelectedTab: e });
        setTimeout(() => {
            window.history.pushState(
                {},
                null,
                `/settings/payments/${this.state.PaymentsSubSelectedTab}`
            );
        }, 200);
    };

    componentDidMount() {
        const email = localStorage.getItem("user_email");
        const url = window.location.pathname;
        setTimeout(() => {
            this.setState({ currentURL: url });
        }, 100);

        setTimeout(() => {
            if (this.state.currentURL == "/settings/payments/billings") {
                this.setState({ PaymentsSubSelectedTab: "billings" });
            } else if (this.state.currentURL == "/settings/payments/invoices") {
                this.setState({ PaymentsSubSelectedTab: "invoices" });
            } else if (this.state.currentURL == "/settings/payments/plan") {
                this.setState({ PaymentsSubSelectedTab: "plan" });
            }
            setTimeout(() => {
                window.history.pushState(
                    {},
                    null,
                    `/settings/payments/${this.state.PaymentsSubSelectedTab}`
                );
            }, 150);
        }, 200);

        this.props.fetchPaymentPlan();
        this.props.getLastPayment(email);
        this.props.getTypeInterval(email);

        const dataa = {
            email: email,
        };
        this.props.postType(dataa);

        this.props.fetchactiveuserslist();
        this.props.postGenerateInvoice(dataa);
    }
    render() {
        const { t } = this.props;
        const paymentPlan = this.props.settings.paymentPlan;
        const lastPayment = this.props.settings.getLastPayment;
        const activeUser = this.props.team.ActiveUsers;
        const typeInterval = this.props.settings.getTypeInterval;
        const invoicesList = this.props.settings.postGenerateInvoice;

        const cost =
            (activeUser == "" || activeUser == undefined ? "" : activeUser.length) *
            (paymentPlan == "" || paymentPlan == undefined ? "" : paymentPlan[2].cost);

        const downloadInvoicePDF = (id) => {
            console.log("id", id);
            const dataa = {
                id: id,
            };
            this.props.postPdfGenerator(dataa).then((data) => {
                console.log("data", data);
                if (data.value.status == 200) {
                    var blob = new Blob([data.value.data], { type: "application/pdf" });
                    FileSaver.saveAs(blob, "invoice.pdf");

                    // const url = window.URL.createObjectURL(
                    //     new Blob([data.value.data, { type: 'application/pdf' }])
                    // );
                    // const link = document.createElement('a');
                    // link.href = url;
                    // link.setAttribute('download', 'Invoice.pdf');
                    // document.body.appendChild(link);
                    // link.click();
                }
            });
        };

        const editType_interval = () => {
            const dataa = {
                email: localStorage.getItem("user_email"),
                type: this.state.intervalType,
            };

            this.props.postTypeInterval(dataa).then((data) => {
                if (data.value.status == 200) {
                    const email = localStorage.getItem("user_email");
                    toast.success("Payment interval updated successfully");
                    this.props.getTypeInterval(email);
                    this.setState({ showUpdateTypeInterval: false });
                } else {
                    toast.error("Something went wrong");
                    this.setState({ showUpdateTypeInterval: false });
                }
            });
        };

        const paypalPayment = () => {
            this.setState({ paymentRedirectLoading: true });
            this.props.paypalPayment().then((data) => {
                if (data.value.status == 200) {
                    this.setState({
                        paymentRedirectLoading: false,
                        showSavedCards: true,
                        showPaymentSelection: false,
                        paymentType: "",
                    });
                    window.location.href = data.value.data.url;
                } else {
                    toast.error("Something went wrong");
                    this.setState({ paymentRedirectLoading: false });
                }
            });
        };

        const hideSavedCards = () => {
            this.setState({ showSavedCards: false, showSavedCardsInvoice: false });
        };

        const paymentSuccess = () => {
            this.setState({ PaymentsSubSelectedTab: "invoices" });
        };

        const handlePayment = () => {
            if (this.state.paymentType == "") {
                toast.error("Please select payment type");
                return;
            }
            this.setState({
                PaymentsSubSelectedTab: "invoices",
                showPaymentSelection: false,
                paymentType: "",
            });
        };

        const cancelPlan = () => {
            const user_email = localStorage.getItem("user_email");

            this.props.cancelPlan_admin(user_email).then((res) => {
                if (res.value.status == 200) {
                    toast.success("Plan cancelled successfully");
                    this.props.fetchPaymentPlan();
                    this.setState({ showCancelPlan: false });
                } else {
                    toast.error("Something went wrong");
                    this.setState({ showCancelPlan: false });
                }
            });
        };

        return (
            <>
                <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg mt-2 flex justify-between items-center">
                    <h4 className="text-primary">{t("settingsTab.paymentSettings")}</h4>

                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            {t("requestsModule.selectATab")}
                        </label>
                        <select
                            id="tabs"
                            name="tabs"
                            onChange={(e) => this.HandleMobileSelectTab(e)}
                            className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                            defaultValue={this.state.PaymentsSubSelectedTab}>
                            <option key="plan" value="plan">
                                {t("settingsTab.plan")}
                            </option>
                            <option key="billings" value="billings">
                                {t("settingsTab.billings")}
                            </option>
                            <option key="Users" value="Users">
                                {t("settingsTab.invoices")}
                            </option>
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <div
                            key="plan"
                            onClick={() => this.HandleSelectTab("plan")}
                            className={classNames(
                                this.state.PaymentsSubSelectedTab == "plan"
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "group inline-flex items-center py-4 px-1 mx-4 border-b-2 font-medium text-md cursor-pointer"
                            )}
                            aria-current={
                                this.state.PaymentsSubSelectedTab == "plan" ? "page" : undefined
                            }>
                            <span>{t("settingsTab.plan")}</span>
                        </div>
                        <div
                            key="billings"
                            onClick={() => this.HandleSelectTab("billings")}
                            className={classNames(
                                this.state.PaymentsSubSelectedTab == "billings"
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "group inline-flex items-center py-4 px-1 mx-4 border-b-2 font-medium text-md cursor-pointer"
                            )}
                            aria-current={
                                this.state.PaymentsSubSelectedTab == "billings" ? "page" : undefined
                            }>
                            <span>{t("settingsTab.billings")}</span>
                        </div>
                        <div
                            key="invoices"
                            onClick={() => this.HandleSelectTab("invoices")}
                            className={classNames(
                                this.state.PaymentsSubSelectedTab == "invoices"
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                            )}
                            aria-current={
                                this.state.PaymentsSubSelectedTab == "invoices" ? "page" : undefined
                            }>
                            <span>{t("settingsTab.invoices")}</span>
                        </div>
                    </div>
                </div>
                {this.state.PaymentsSubSelectedTab == "plan" ? (
                    <div className="mt-4">
                        <div className="flex justify-center items-center bg-white flex-col mb-4 h-40">
                            <p className="text-4xl text-primary font-semibold mt-4 mb-3">
                                {t("settingsTab.simpleAndFlexPrice")}.
                            </p>
                            <p className="text-md text-primary font-medium">
                                {t("settingsTab.noCardRequired")}.
                            </p>
                        </div>

                        <div className="bg-white rounded-md shadow-md">
                            <div className="grid grid-cols-1 md:grid-cols-3">
                                <div className="grid col-span-2 grid-cols-3 p-5 gap-4">
                                    <p className="w-full col-span-3 p-1">
                                        {t("settingsTab.numberOfUsersInBelowPlanMessage")}
                                    </p>
                                    <p className="flex items-center col-span-3 my-5">
                                        <p className="bg-white text-[16px] mr-6 w-1/5">
                                            {t("settingsTab.whats")}
                                            <br />
                                            {t("settingsTab.included")}
                                        </p>
                                        <div className="bg-gray-300 rounded-full h-[2px] w-full"></div>
                                    </p>
                                    <p className="flex items-center">
                                        <CheckCircleIcon className="-ml-0.5 mr-2 h-5 w-5 text-green-500 group-hover:text-gray-500" />{" "}
                                        {t("time")} {t("settingsTab.tracking")}
                                    </p>
                                    <p className="flex items-center">
                                        <CheckCircleIcon className="-ml-0.5 mr-2 h-5 w-5 text-green-500 group-hover:text-gray-500" />{" "}
                                        {t("settingsTab.edit")} {t("time")}
                                    </p>
                                    <p className="flex items-center">
                                        <CheckCircleIcon className="-ml-0.5 mr-2 h-5 w-5 text-green-500 group-hover:text-gray-500" />{" "}
                                        {t("settingsTab.managerLogin")}
                                    </p>
                                    <p className="flex items-center">
                                        <CheckCircleIcon className="-ml-0.5 mr-2 h-5 w-5 text-green-500 group-hover:text-gray-500" />{" "}
                                        {t("settingsTab.unlimited")} {t("screenshots")}
                                    </p>
                                    <p className="flex items-center">
                                        <CheckCircleIcon className="-ml-0.5 mr-2 h-5 w-5 text-green-500 group-hover:text-gray-500" />{" "}
                                        {t("settingsTab.trackAppsAndUrls")}
                                    </p>
                                    <p className="flex items-center">
                                        <CheckCircleIcon className="-ml-0.5 mr-2 h-5 w-5 text-green-500 group-hover:text-gray-500" />{" "}
                                        {t("settingsTab.employeeLogin")}
                                    </p>
                                    <p className="flex items-center">
                                        <CheckCircleIcon className="-ml-0.5 mr-2 h-5 w-5 text-green-500 group-hover:text-gray-500" />{" "}
                                        {t("settingsTab.unlimited")} {t("integrations")}
                                    </p>
                                    <p className="flex items-center">
                                        <CheckCircleIcon className="-ml-0.5 mr-2 h-5 w-5 text-green-500 group-hover:text-gray-500" />{" "}
                                        {t("reports")}
                                    </p>
                                    <p className="flex items-center">
                                        <CheckCircleIcon className="-ml-0.5 mr-2 h-5 w-5 text-green-500 group-hover:text-gray-500" />{" "}
                                        {t("settingsTab.unlimited")} {t("projects")}
                                    </p>
                                    <p className="flex items-center">
                                        <CheckCircleIcon className="-ml-0.5 mr-2 h-5 w-5 text-green-500 group-hover:text-gray-500" />{" "}
                                        {t("settingsTab.holidays")}
                                    </p>
                                    <p className="flex items-center">
                                        <CheckCircleIcon className="-ml-0.5 mr-2 h-5 w-5 text-green-500 group-hover:text-gray-500" />{" "}
                                        {t("settingsTab.exports")}
                                    </p>
                                    <p className="flex items-center">
                                        <CheckCircleIcon className="-ml-0.5 mr-2 h-5 w-5 text-green-500 group-hover:text-gray-500" />{" "}
                                        {t("settingsTab.roleBasedAccess")}
                                    </p>
                                </div>
                                <div className="bg-gray-200 w-full col-span-1 flex flex-col justify-center items-center sm:p-4">
                                    <div className="h-9 mb-7 font-bold text-2xl">
                                        {t("settingsTab.basic")} {t("settingsTab.plan")}
                                    </div>
                                    <div className="h-8 mb-5">
                                        <p>
                                            <span className="font-bold text-xl sm:text-2xl">
                                                $
                                                {paymentPlan == "" || paymentPlan == undefined
                                                    ? ""
                                                    : paymentPlan[2].cost}
                                            </span>
                                            <span>/{t("settingsTab.perUser")}</span>
                                        </p>
                                    </div>
                                    <div className="text-justify mb-6">
                                        {t("settingsTab.basicPlanCancelMessage")}.
                                    </div>
                                    <PrimaryButton buttontext={t("settingsTab.currentPlan")} />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {this.state.PaymentsSubSelectedTab == "billings" ? (
                    this.state.showSavedCards ? (
                        <div>
                            <SavedCards
                                closeSavedCards={hideSavedCards}
                                paySuccesRedirect={paymentSuccess}
                                invoiceID={this.state.invoiceId}
                            />
                        </div>
                    ) : (
                        <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg mt-4 ">
                            <h2 className="text-primary my-4">{t("settingsTab.billings")}</h2>
                            <hr className="mb-8" />
                            {/* <div className='flex flex-col sm:flex-row '>
                            <p>Your Personal plan of 23 users is set to $0 per month at a rate of $0 per user and will renew on April 15th 2022. Adjusting for your account balance, you will be charged $0. PAY NOW (View upcoming invoice)</p>
                            <PrimaryButton buttontext='Cancel Plan' />
                        </div>  */}
                            <div className="flex flex-col sm:flex-row justify-center items-start sm:items-center">
                                <div className="mr-3 sm:mr-1">
                                    <p className="">
                                        {`${t("settingsTab.youpersonalPlanof")} ${
                                            activeUser == "" ? "" : activeUser.length
                                        } ${t("settingsTab.usersIsSetTo")} $${cost} ${t(
                                            "settingsTab.perMonthAtRateOf"
                                        )} $${
                                            paymentPlan == "" || paymentPlan == undefined
                                                ? ""
                                                : paymentPlan[2].cost
                                        } ${t("settingsTab.perUserAndWillRenewOn")} ${
                                            typeInterval == ""
                                                ? ""
                                                : moment(typeInterval.renewal).format(
                                                      "MMMM Do YYYY"
                                                  )
                                        }. ${t(
                                            "settingsTab.adjustingForYourAccountCharge"
                                        )} $${cost}.  `}
                                        <span
                                            onClick={() => {
                                                this.setState({
                                                    PaymentsSubSelectedTab: "invoices",
                                                });
                                            }}
                                            className="hover:underline cursor-pointer font-semibold text-primary">
                                            {" "}
                                            {t("settingsTab.payNow")}{" "}
                                        </span>
                                        {t("settingsTab.viewUpcomingInvoices")}
                                    </p>
                                </div>
                                <div className="mt-3 sm:mt-0">
                                    <PrimaryButton
                                        buttontext={t("settingsTab.cancelPlan")}
                                        onClick={() => {
                                            this.setState({ showCancelPlan: true });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="px-4 sm:px-6 lg:px-8">
                                <div className="mt-8 flex flex-col">
                                    <div className="-my-2 mb-7 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                <table className="min-w-full divide-y ">
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        <tr>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {t("settingsTab.usersInCompany")}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {activeUser == ""
                                                                    ? ""
                                                                    : activeUser.length}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {t("settingsTab.paymentMethod")}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {t("settingsTab.credit")}/
                                                                {t("settingsTab.debit")}{" "}
                                                            </td>
                                                            <td
                                                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-pointer hover:underline"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showPaymentSelection: true,
                                                                    });
                                                                }}>
                                                                {t("settingsTab.update")}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {t("settingsTab.billingInterval")}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
                                                                {typeInterval == ""
                                                                    ? ""
                                                                    : typeInterval.data == "monthly"
                                                                    ? t("settingsTab.monthly")
                                                                    : t("settingsTab.yearly")}{" "}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-pointer hover:underline">
                                                                <p
                                                                    className=""
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            showUpdateTypeInterval: true,
                                                                        });
                                                                    }}>
                                                                    {t("settingsTab.update")}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {t("settingsTab.lastPayment")}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {lastPayment == ""
                                                                    ? ""
                                                                    : moment(
                                                                          lastPayment.date
                                                                      ).format("MMMM Do yyyy")}
                                                            </td>
                                                            <td
                                                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-pointer hover:underline"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        PaymentsSubSelectedTab:
                                                                            "invoices",
                                                                    });
                                                                }}>
                                                                {t("settingsTab.seeInvoices")}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {t("settingsTab.balance")}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                ${cost}
                                                            </td>
                                                            <td
                                                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-pointer hover:underline"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showSavedCards: true,
                                                                    });
                                                                }}>
                                                                {t("settingsTab.makePayment")}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ) : null}
                {this.state.PaymentsSubSelectedTab == "invoices" ? (
                    this.state.showSavedCardsInvoice ? (
                        <SavedCards
                            closeSavedCards={hideSavedCards}
                            paySuccesRedirect={paymentSuccess}
                            invoiceID={this.state.invoiceId}
                        />
                    ) : (
                        <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg mt-4 ">
                            <h2 className="text-primary my-4">{t("settingsTab.invoices")}</h2>
                            <hr />

                            <div
                                className="mt-2 flex flex-col overflow-y-auto overflow-x-auto bg-gray-100"
                                style={{ maxHeight: "auto" }}>
                                <div className="inline-block min-w-full align-middle">
                                    <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                        <table className="min-w-full border-separate">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                        {t("settingsTab.invoiceNumber")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                        {t("settingsTab.issue")} {t("date")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                                                        {t("settingsTab.due")} {t("date")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                                                        {t("status")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                                                        {t("settingsTab.receipt")}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {invoicesList == "" || invoicesList == undefined ? (
                                                    <tr>
                                                        <td>{t("loading")}...</td>
                                                    </tr>
                                                ) : invoicesList.status ==
                                                  "user is not a manager" ? (
                                                    <>
                                                        <td>{t("settingsTab.userNotPermitted")}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </>
                                                ) : (
                                                    invoicesList &&
                                                    invoicesList
                                                        .sort(function (a, b) {
                                                            return a.id - b.id;
                                                        })
                                                        .map((invoice, idx) => (
                                                            <tr key={idx}>
                                                                <td
                                                                    className={classNames(
                                                                        idx !==
                                                                            invoicesList.length - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                                                    )}>
                                                                    {idx < 100 ? (
                                                                        <div>00{idx + 1}</div>
                                                                    ) : (
                                                                        <div>{idx + 1}</div>
                                                                    )}
                                                                </td>
                                                                <td
                                                                    className={classNames(
                                                                        idx !==
                                                                            invoicesList.length - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
                                                                    )}>
                                                                    {moment(invoice.time).format(
                                                                        "MMM Do YYYY"
                                                                    )}
                                                                </td>
                                                                <td
                                                                    className={classNames(
                                                                        idx !==
                                                                            invoicesList.length - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"
                                                                    )}>
                                                                    {moment(invoice.duedate).format(
                                                                        "MMM Do yyyy"
                                                                    )}
                                                                </td>
                                                                <td
                                                                    className={classNames(
                                                                        idx !==
                                                                            invoicesList.length - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"
                                                                    )}>
                                                                    {invoice.payment_status ==
                                                                    true ? (
                                                                        t("settingsTab.paid")
                                                                    ) : (
                                                                        <p
                                                                            className="text-red-500 font-medium cursor-pointer hover:underline"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    showSavedCardsInvoice: true,
                                                                                    invoiceId:
                                                                                        invoice.id,
                                                                                });
                                                                            }}>
                                                                            {t(
                                                                                "settingsTab.payNow"
                                                                            )}
                                                                        </p>
                                                                    )}
                                                                </td>
                                                                <td
                                                                    className={classNames(
                                                                        idx !==
                                                                            invoicesList.length - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"
                                                                    )}>
                                                                    <PrimaryButton
                                                                        buttontext={t(
                                                                            "downloadBtn"
                                                                        )}
                                                                        onClick={() => {
                                                                            downloadInvoicePDF(
                                                                                invoice.id
                                                                            );
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ) : null}

                <Transition.Root show={this.state.showUpdateTypeInterval} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed z-10 inset-0 overflow-y-auto"
                        onClose={() => {
                            this.setState({ showUpdateTypeInterval: false });
                        }}>
                        <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                                    <div className="flex justify-center items-center my-6">
                                        <div className="flex justify-center items-start flex-col">
                                            <p className="text-lg font-semibold mb-3">
                                                {t("settingsTab.setPaymentIntervalType")}
                                            </p>
                                            <div classNames="">
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        this.state.intervalType == "monthly"
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={() => {
                                                        this.setState({ intervalType: "monthly" });
                                                    }}
                                                    className="mr-4 p-2 rounded-md text-primary"
                                                />
                                                <label>{t("settingsTab.monthly")}</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        this.state.intervalType == "yearly"
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={() => {
                                                        this.setState({ intervalType: "yearly" });
                                                    }}
                                                    className="mr-4 p-2 rounded-md text-primary"
                                                />
                                                <label>{t("settingsTab.yearly")}</label>
                                            </div>
                                            <div className="flex mt-4">
                                                <button
                                                    className="p-1 px-4 rounded-md shadow-sm hover:shadow-md bg-primary mr-2 text-white font-medium"
                                                    onClick={() => {
                                                        editType_interval();
                                                    }}>
                                                    {t("settingsTab.proceed")}
                                                </button>
                                                <button
                                                    className="p-1 px-4 rounded-md shadow-sm hover:shadow-md border border-primary font-medium"
                                                    onClick={() => {
                                                        this.setState({
                                                            showUpdateTypeInterval: false,
                                                        });
                                                    }}>
                                                    {t("requestsModule.cancel")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

                <Transition.Root show={this.state.showPaymentSelection} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed z-10 inset-0 overflow-y-auto"
                        onClose={() => {
                            this.setState({ showPaymentSelection: false });
                        }}>
                        <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                                    <div className="flex justify-center items-center my-6">
                                        <div className="flex justify-center items-start flex-col">
                                            <p className="text-lg font-semibold mb-3">
                                                {t("settingsTab.selectPaymentType")}
                                            </p>
                                            <div classNames="">
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        this.state.paymentType == "card"
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={() => {
                                                        this.setState({ paymentType: "card" });
                                                    }}
                                                    className="mr-4 p-2 rounded-md text-primary"
                                                />
                                                <label>{t("settingsTab.payWithCard")}</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        this.state.paymentType == "paypal"
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={() => {
                                                        this.setState({ paymentType: "paypal" });
                                                    }}
                                                    className="mr-4 p-2 rounded-md text-primary"
                                                />
                                                <label>{t("settingsTab.payWithPaypal")}</label>
                                            </div>
                                            <div className="flex mt-4">
                                                {this.state.paymentType == "" ? (
                                                    <p className="p-1 px-4 rounded-md shadow-sm hover:shadow-md bg-primary mr-2 text-white font-medium">
                                                        {t("settingsTab.selectPaymentType")}
                                                    </p>
                                                ) : this.state.paymentType == "card" ? (
                                                    <button
                                                        className="p-1 px-4 rounded-md shadow-sm hover:shadow-md bg-primary mr-2 text-white font-medium flex"
                                                        onClick={() => {
                                                            handlePayment();
                                                        }}>
                                                        {t("continueBtn")}
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="p-1 px-4 rounded-md shadow-sm hover:shadow-md bg-primary mr-2 text-white font-medium flex"
                                                        onClick={() => {
                                                            paypalPayment();
                                                        }}>
                                                        {this.state.paymentRedirectLoading ? (
                                                            <div className="buttonload">
                                                                <i className="fa fa-spinner fa-spin"></i>{" "}
                                                                {t("loading")}{" "}
                                                            </div>
                                                        ) : (
                                                            "CONTINUE"
                                                        )}
                                                    </button>
                                                )}
                                                <button
                                                    className="p-1 px-4 rounded-md shadow-sm hover:shadow-md border border-primary font-medium"
                                                    onClick={() => {
                                                        this.setState({
                                                            showPaymentSelection: false,
                                                            paymentRedirectLoading: false,
                                                            paymentType: "",
                                                        });
                                                    }}>
                                                    {t("requestsModule.cancel")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

                <Transition.Root show={this.state.showCancelPlan} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed z-10 inset-0 overflow-y-auto"
                        onClose={() => {
                            this.setState({ showCancelPlan: false });
                        }}>
                        <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                    <div className="">
                                        <p className="text-xl font-semibold">
                                            {t("settingsTab.weReSorryToSeeYouGo")}
                                        </p>
                                        <p className="text-gray-700 font-medium mt-3">{`${t(
                                            "settingsTab.subscriptionIsPaid"
                                        )} ${
                                            typeInterval == "" || typeInterval == undefined
                                                ? ""
                                                : moment(typeInterval.renewal).format(
                                                      "MMMM Do YYYY"
                                                  )
                                        }. ${t("settingsTab.cancelSubscriptionMsg")}`}</p>
                                        <div className="bg-primary text-white mt-4 rounded-md p-3">
                                            <div className="flex flex-row justify-center items-start">
                                                <div>
                                                    <InformationCircleIcon className="h-6 w-6 mr-2" />
                                                </div>
                                                <p className="word-break font-medium">
                                                    {t("settingsTab.areYouSureAfter")}{" "}
                                                    {typeInterval == "" || typeInterval == undefined
                                                        ? ""
                                                        : moment(typeInterval.renewal).format(
                                                              "MMMM Do YYYY"
                                                          )}{" "}
                                                    {t("settingsTab.noFurtherInvoicesMsg")}
                                                </p>
                                            </div>
                                            <p></p>
                                        </div>
                                        <div className="mt-5 flex justify-end items-center">
                                            <button
                                                className="p-1 px-6 rounded-md border border-primary font-medium mr-3 transition duration-150 ease-in-out hover:-translate-y-1 hover:shadow-md"
                                                onClick={() => {
                                                    this.setState({ showCancelPlan: false });
                                                }}>
                                                {t("settingsTab.undo")}
                                            </button>
                                            <button
                                                className="p-1 px-4 rounded-md border border-primary bg-primary text-white font-medium transition duration-150 ease-in-out hover:-translate-y-1 hover:shadow-md"
                                                onClick={() => {
                                                    cancelPlan();
                                                }}>
                                                {t("settingsTab.cancelSubscription")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    settings:state.settings,
    team: state.teams
});

const mapDispatchToProps = (dispatch) => ({
    fetchPaymentPlan: () => dispatch(fetchPaymentPlan()),
    getTypeInterval: (data) =>dispatch(getTypeInterval(data)),
    getAddress:() =>dispatch(getAddress()),
    postType:(data)=>dispatch(postType(data)),
    getLastPayment:(data)=>dispatch(getLastPayment(data)),
    postGenerateInvoice:(data)=>dispatch(postGenerateInvoice(data)),
    fetchactiveuserslist:()=>dispatch(fetchactiveuserslist()),
    postPdfGenerator: (data)=>dispatch(postPdfGenerator(data)),
    postTypeInterval: (data)=>dispatch(postTypeInterval(data)),
    paypalPayment: ()=> dispatch(paypalPayment()),
    cancelPlan_admin: (data)=>dispatch(cancelPlan_admin(data)),
});

PaymentsTab.propTypes = {

};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(PaymentsTab));






// type_interval
// getAddress 
// getType
// paymentPlan
// lastPayment
// generate Invoice
// generate pdf
