/* eslint-disable import/no-anonymous-default-export */
import { API_ENDPOINTS } from "../../constants";
import { transport } from "../../Networks/Auth";

// import { transport, padlocktransport } from '../../Networks/Auth'

export default {
    getapplog: (date, id) => {
        let config = {
            url: API_ENDPOINTS.GET_APP_LOG + date + "&uu_id=" + id,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },
    getapplogdata: (date, id) => {
        let config = {
            url: API_ENDPOINTS.GET_APP_LOG_DATA + id + "&date=" + date,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },
    timetrackerdata: (date, projectname, employeename) => {
        let config = {
            url:
                API_ENDPOINTS.TIME_TRACKER_DATA +
                date +
                "&project_name=" +
                projectname +
                "&username=" +
                employeename,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    timetrackerdatawithoutproject: (date, employeename) => {
        let config = {
            url: API_ENDPOINTS.TIME_TRACKER_DATA + date + "&username=" + employeename,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    timetrackerDataObserver: (date, projectname, employeename) => {
        let config = {
            url:
                API_ENDPOINTS.TIME_TRACKER_DATA_OBSERVER +
                date +
                "&project_name=" +
                projectname +
                "&username=" +
                employeename +
                "$observer=True",
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    timetrackerdatawithoutprojectObserver: (date, employeename) => {
        let config = {
            url:
                API_ENDPOINTS.TIME_TRACKER_DATA_OBSERVER +
                date +
                "&username=" +
                employeename +
                "&observer=True",
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    fetchsysteminfo: (date, id) => {
        let config = {
            url: API_ENDPOINTS.FETCH_SYSTEM_INFO + date + "&username=" + id,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    customreportweeklygraphdata: (last, current) => {
        let config = {
            url: API_ENDPOINTS.CUSTOM_WEEKLY_GRAPH_DATA + last + "/" + current + "/",
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    editTimeTrackerTiming: (data) => {
        let config = {
            url: API_ENDPOINTS.EDIT_TIME_TRACKER_SCREENSHOT,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },

    createTimeTrackerTiming: (data) => {
        let config = {
            url: API_ENDPOINTS.EDIT_TIME_TRACKER_SCREENSHOT,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    getTimeTrackerInterval: () => {
        let config = {
            url: API_ENDPOINTS.GET_TIME_TRACKER_SCREENSHOT_INTERVAL,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    getEmployeeBroswerLog: (user_id, start_date, end_date) => {
        let config = {
            url:
                API_ENDPOINTS.EMPLOYEE_BRWOSER_ACTIVITY_LOG +
                "?user_id=" +
                user_id +
                "&start_date=" +
                start_date +
                "&end_date=" +
                end_date,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    getTimeTracker_viewDetails: () => {
        let config = {
            url:
                API_ENDPOINTS.TIME_TRACKER_VIEW_DETAILS +
                `/2022-04-20/8:10/8:20/?username=rajkumark`,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    getSystemInfoObserver: (date, id) => {
        let config = {
            url: API_ENDPOINTS.GET_SYSTEM_INFO_OBSERVER + date + "&username=" + id,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    weeklyChartActivity: (date, user_id) => {
        let config = {
            url: API_ENDPOINTS.WEEKLYCHART_ACTIVITY + `${date}/?user_id=${user_id}`,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },
    requestProjectChange: (data) => {
        let config = {
            url: API_ENDPOINTS.REQUEST_PROJECT_CHANGE,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    getProjectList: () => {
        let config = {
            url: API_ENDPOINTS.GET_PROJECT_LIST,
            method: "GET",
        };
        return transport(config);
    },
    deleteTime: (data) => {
        let config = {
            url: API_ENDPOINTS.DELETE_TIME,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    cancelTime: (data) => {
        let config = {
            url: API_ENDPOINTS.CANCEL_TIME,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    getUserDetail: (date, user_id) => {
        let config = {
            url: API_ENDPOINTS.GET_USER_DETAIL + date + "&user_id=" + user_id,
            method: "GET",
        };
        return transport(config);
    },
    getShowcaseEntries: (date, params) => {
        let config = {
            url: API_ENDPOINTS.GET_SHOWCASE_ENTRIES + date + "&type=" + params,
            method: "GET",
        };
        return transport(config);
    },
    getEmployeeMoods: (date) => {
        let config = {
            url: API_ENDPOINTS.EMPLOYEE_MOODS + date,
            method: "GET",
        };
        return transport(config);
    },
    getAppsLogged: (params) => {
        let config = {
            url: API_ENDPOINTS.APPS_LOGGED + params,
            method: "GET",
        };
        return transport(config);
    },
    getSingleAppLog: (params) => {
        let config = {
            url: API_ENDPOINTS.SINGLE_APP_LOG + params,
            method: "GET",
        };
        return transport(config);
    },
    getOfflineData: () => {
        let config = {
            url: API_ENDPOINTS.OFFLINE_DATA,
            method: "GET",
        };
        return transport(config);
    },
    getActivityTimeTracker: (date, user_id, start_time, end_time) => {
        let config = {
            url:
                API_ENDPOINTS.ACTIVITY_TIME_TRACKER +
                date +
                "&start_time=" +
                start_time +
                "&end_time=" +
                end_time +
                "&user_id=" +
                user_id,
            method: "GET",
        };
        return transport(config);
    },
};
