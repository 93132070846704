import { CalendarIcon } from "@heroicons/react/outline";
import React, { Component } from "react";
import { connect } from "react-redux";
import Layout from "../../../Components/Layout";
import DatePicker from "react-datepicker";

import {
  fetchEmpLoginDetail,
  fetchEmpDashboard,
  fetchEmpSummary,
  getDailyActivityList,
} from "../../../services/employee/actions";

import { fetchEmployeeWebsiteReport } from "../../../services/reports/actions";
import { getShowcaseEntries } from "../../../services/timetracker/actions";
import moment from "moment";
import Slider from "react-slick";
import "./slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ReactHighcharts from "react-highcharts";
import noDataPng from "../../../images/nodata.png";

import { withTranslation } from "react-i18next";
import ModalShowCaseEntry from "../../TimeTracker/modalShowCaseEntry";
import { BeatLoader } from "react-spinners";

const ProgressBar = ({ data }) => (
  <div className="w-full bg-gray-200 h-3 rounded-full">
    <div
      className="bg-primary h-3 rounded-full"
      style={{ width: data + "%" }}
    ></div>
  </div>
);

class Employee_dashboard extends Component {
  constructor() {
    super();
    this.state = {
      startDate: new Date(),
      emp_dashboard_details: [],
      emp_summary_details: [],
      graphData: [],
      userName: "",
      isShowcaseModalActive: false,
      showcaseEntriesList: [],
      isShowAllEntries: false,
      productivitygraphloading: true,
      user_uuid: "",
    };
  }

  componentDidMount() {
    window.history.pushState(
      {},
      null,
      `/emp/dashboard/?date=${moment(this.state.startDate).format(
        "YYYY-MM-DD"
      )}`
    );

    this.props.fetchEmpLoginDetail().then((loginData) => {
      this.setState({ user_uuid: loginData.value.data.user_uuid });
      let startdate = moment(
        new Date(
          this.state.startDate.getFullYear(),
          this.state.startDate.getMonth(),
          1
        )
      ).format("YYYY-MM-DD");
      let endDate = moment(
        new Date(
          this.state.startDate.getFullYear(),
          this.state.startDate.getMonth() + 1,
          0
        )
      ).format("YYYY-MM-DD");
      if (
        localStorage.getItem("graphData") &&
        localStorage.getItem("graphTime") &&
        moment(new Date()).isSameOrBefore(
          moment(localStorage.getItem("graphTime")).add(15, "minutes")
        )
      ) {
        this.setState({
          graphData: JSON.parse(localStorage.getItem("graphData")),
          productivitygraphloading: false,
        });
      } else {
        this.props
          .fetchEmployeeWebsiteReport(
            startdate,
            endDate,
            loginData.value.data.user_uuid
          )
          .then((data) => {
            this.setState({
              graphData: data.value.data.data,
              productivitygraphloading: false,
            });
            localStorage.setItem(
              "graphData",
              JSON.stringify(data.value.data.data)
            );
            localStorage.setItem("graphTime", new Date());
          });
      }
      this.props
        .getShowcaseEntries(
          moment(this.state.startDate).format("YYYY-MM-DD"),
          `user&user_id=${loginData.value.data.user_uuid}`
        )
        .then((res) => {
          if (res.value.status === 200 && !res.value.data.message) {
            this.setState({
              userName: loginData.value.data.username,
              showcaseEntriesList: res.value.data.data,
            });
          }
        });
      this.props.getDailyActivityList(
        moment(this.state.startDate).format("YYYY-MM-DD")
      );
    });

    this.props
      .fetchEmpDashboard(moment(this.state.startDate).format("YYYY-MM-DD"))
      .then((data) => {
        if (data.value.status === 200) {
          this.setState({
            emp_dashboard_details: data.value.data,
          });
        }
      });

    this.props
      .fetchEmpSummary(moment(this.state.startDate).format("YYYY-M"))
      .then((data) => {
        if (data.value.status === 200) {
          this.setState({
            emp_summary_details: data.value.data,
          });
        }
      });
  }

  convertMintoHr(minutes) {
    let hours = minutes / 60;
    let minutesLeft = minutes % 60;
    return `${Math.trunc(hours)}hr : ${minutesLeft}min`;
  }

  handleDateChange = (date) => {
    this.setState({ startDate: date }, () => {
      window.history.pushState(
        {},
        null,
        `/emp/dashboard/?date=${moment(date).format("YYYY-MM-DD")}`
      );

      this.props
        .fetchEmpDashboard(moment(date).format("YYYY-MM-DD"))
        .then((data) => {
          this.setState({ emp_dashboard_details: data.value.data });
        });

      this.props.fetchEmpSummary(moment(date).format("YYYY-M")).then((data) => {
        this.setState({ emp_summary_details: data.value.data });
      });

      let activeUsers = this.props.teams && this.props.teams.ActiveUsers;
      let loginuserdetails =
        this.props.dashboard && this.props.dashboard.EmpLoginDetails;
      let loginusername = loginuserdetails && loginuserdetails.login_name;
      let loginuserId =
        activeUsers &&
        activeUsers.find((el) => el.user_login_as === loginusername);
      let user_id = loginuserId && loginuserId.user_id;

      let startdate = moment(
        new Date(
          this.state.startDate.getFullYear(),
          this.state.startDate.getMonth(),
          1
        )
      ).format("YYYY-MM-DD");
      let endDate = moment(
        new Date(
          this.state.startDate.getFullYear(),
          this.state.startDate.getMonth() + 1,
          0
        )
      ).format("YYYY-MM-DD");
      this.props
        .fetchEmployeeWebsiteReport(startdate, endDate, user_id)
        .then((data) => {
          this.setState({
            graphData: data.value.data.data,
            productivitygraphloading: false,
          });
        });
      this.setState({ showcaseEntriesList: [] });
      this.props
        .getShowcaseEntries(
          moment(date).format("YYYY-MM-DD"),
          `user&user_id=${this.state.user_uuid}`
        )
        .then((res) => {
          if (res.value.status === 200 && !res.value.data.message) {
            this.setState({
              showcaseEntriesList: res.value.data.data,
            });
          }
        });

      this.props.getDailyActivityList(moment(date).format("YYYY-MM-DD"));
    });
  };

  render() {
    const { t } = this.props;

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 7,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 4,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };

    let activeUsers = this.props.teams && this.props.teams.ActiveUsers;
    let loginuserdetails =
      this.props.dashboard && this.props.dashboard.EmpLoginDetails;
    let loginusername = loginuserdetails && loginuserdetails.login_name;
    let loginuserId =
      activeUsers &&
      activeUsers.find((el) => el.user_login_as === loginusername);
    let user_id = loginuserId && loginuserId.user_id;
    let EmployeesAttendance =
      this.props.report && this.props.report.EmployeesAttendance;
    let todaydate = moment(this.state.startDate).format("YYYY-MM-DD");
    let todaysemployeesattendance =
      EmployeesAttendance &&
      EmployeesAttendance.filter((el) => el.date === todaydate);
    let lateemployees =
      todaysemployeesattendance &&
      todaysemployeesattendance.filter((el) => el.time > 0 && el.time <= 480);
    let presentemployees =
      todaysemployeesattendance &&
      todaysemployeesattendance.filter((el) => el.time > 480);
    let absentemployees =
      todaysemployeesattendance &&
      todaysemployeesattendance.filter((el) => el.time === 0);
    let activityList =
      this.props.dashboard && this.props.dashboard.dailyActivityList;

    const AttendanceChart = {
      chart: {
        type: "pie",
        height: 250,
        style: {
          fontFamily: "Lato",
          fontSize: "15px",
        },
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.y}</b>",
      },
      title: {
        text: "",
      },

      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.y}",
          },
          showInLegend: true,
        },
      },

      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Employees",
          innerSize: "60%",
          data: [
            {
              name: "Present",
              y: presentemployees && presentemployees.length,
            },
            {
              name: "Leave",
              y: absentemployees && absentemployees.length,
            },
            {
              name: "Idle",
              y: lateemployees && lateemployees.length,
            },
          ],
        },
      ],
    };

    let arrayProductive = [];
    let arrayUnproductive = [];
    let arrayUndefined = [];
    let arrayDates = [];

    let prodArr =
      this.state.graphData === undefined || this.state.graphData === ""
        ? ""
        : this.state.graphData.map((data) => {
            if (data === "") {
              console.log("Empty Data");
            } else {
              arrayProductive.push(parseInt(data.productive / 60));
            }
            if (data === "") {
              console.log("Empty Data");
            } else {
              arrayUnproductive.push(parseInt(data.unproductive / 60));
            }
            if (data === "") {
              console.log("Empty Data");
            } else {
              arrayUndefined.push(parseInt(data.undefined / 60));
            }
            if (data === "") {
              console.log("error");
            } else {
              arrayDates.push(
                new Date(data.date + "T05:12:59Z").toDateString().slice(4, 11)
              );
            }
            return null;
          });

    const productivitychart = {
      chart: {
        type: "area",
        height: 250,
        zoomType: "x",
        style: {
          fontFamily: "Lato",
          fontSize: "15px",
        },
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: "",
        },
      },

      xAxis: {
        categories: arrayDates,
        crosshair: true,
      },
      tooltip: {
        valueSuffix: "Hr",
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          showInLegend: true,
          color: "#684D94",
          name: "Productive",
          data: arrayProductive,
        },
        {
          showInLegend: true,
          color: "#9279b9",
          name: "Unproductive",
          data: arrayUnproductive,
        },
        {
          showInLegend: true,
          color: "#E6D6F3",
          name: "Undefined",
          data: arrayUndefined,
        },
      ],
    };

    return (
        <Layout>
            <div>
                <div className="flex justify-end items-center my-4">
                    <div className="w-52 mx-2 md:mx-3 flex items-center calendericonindex">
                        <DatePicker
                            className="rounded-md w-52 text-black cursor-pointer"
                            selected={this.state.startDate}
                            value={this.state.startDate}
                            startDate={this.state.startDate}
                            onChange={(date) => this.handleDateChange(date)}
                        />
                        <CalendarIcon className="-ml-10 z-10 w-6"></CalendarIcon>
                    </div>
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-5 gap-4">
                    <div className="flex justify-center items-start flex-col bg-white rounded-md shadow-sm w-auto h-full p-3">
                        <div className="-translate-y-2">
                            <p className="text-gray-500">
                                {t("total")} {t("time")}
                            </p>
                            <p className="text-primary text-lg mt-1 font-semibold">
                                {this.state.emp_dashboard_details &&
                                    this.convertMintoHr(
                                        this.state.emp_dashboard_details.daily_worked_min
                                    )}
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center items-start flex-col bg-white rounded-md shadow-sm w-auto h-full p-3">
                        <div className="-translate-y-2">
                            <p className="text-gray-500">{t("totalIdleTime")}</p>
                            <p className="text-primary text-lg mt-1 font-semibold">
                                {this.state.emp_dashboard_details &&
                                    this.convertMintoHr(this.state.emp_dashboard_details.idle_time)}
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center items-start flex-col bg-white rounded-md shadow-sm w-auto h-full p-3">
                        <p className="text-gray-500">
                            {t("time")} {t("worked")}
                        </p>
                        <p className="text-primary text-lg mt-1 font-semibold">
                            {this.state.emp_dashboard_details &&
                                this.convertMintoHr(
                                    this.state.emp_dashboard_details
                                        .month_start_date_to_current_date
                                )}
                        </p>
                        <p className="text-gray-400 text-[12px] mt-2 flex justify-center items-end">
                            {this.state.emp_dashboard_details &&
                                moment(
                                    this.state.emp_dashboard_details.worked_days_from_date
                                ).format("ll") +
                                    " - " +
                                    moment(
                                        this.state.emp_dashboard_details.worked_days_to_date
                                    ).format("ll")}
                        </p>
                    </div>
                    <div className="flex justify-center items-start flex-col bg-white rounded-md shadow-sm w-auto h-full p-2 capitalize">
                        <p className="text-gray-500">
                            {t("time")} {t("to")} {t("work")} {t("ReportsTab.this")}{" "}
                            {t("ReportsTab.month")}
                        </p>
                        <p className="text-primary text-lg mt-1 font-semibold">
                            {this.state.emp_dashboard_details &&
                                this.state.emp_dashboard_details.total_monthly_working_hrs}
                            Hr
                        </p>
                        <p className="text-gray-400 text-[12px] mt-2">
                            {this.state.emp_dashboard_details &&
                                moment(
                                    this.state.emp_dashboard_details.working_days_from_date
                                ).format("ll") +
                                    " - " +
                                    moment(
                                        this.state.emp_dashboard_details.working_days_to_date
                                    ).format("ll")}
                        </p>
                    </div>
                    <div className="flex justify-center items-start flex-col bg-white rounded-md shadow-sm w-auto h-full p-2">
                        <p className="text-gray-500">
                            No. {t("of")} {t("timeTracker.days")} {t("worked")}
                        </p>
                        <p className="text-primary text-lg mt-1 font-semibold">
                            {this.state.emp_dashboard_details &&
                                this.state.emp_dashboard_details.total_worked_days}
                        </p>
                        <p className="text-gray-400 text-[12px] mt-2">
                            {this.state.emp_dashboard_details &&
                                moment(
                                    this.state.emp_dashboard_details.worked_days_from_date
                                ).format("ll") +
                                    " - " +
                                    moment(
                                        this.state.emp_dashboard_details.worked_days_to_date
                                    ).format("ll")}
                        </p>
                    </div>
                </div>

                <div className="bg-white rounded-md shadow-sm mt-4 h-32 flex items-center">
                    {this.state.emp_summary_details &&
                    this.state.emp_summary_details.length === 0 ? (
                        <div className="text-primary text-lg font-semibold flex justify-center items-center flex-col w-full text-center">
                            <img src={noDataPng} alt="no data" />
                            {t("no")} {t("requestsModule.data")} {t("timeTrakcer.found")}
                        </div>
                    ) : (
                        <Slider className="w-full left-0 p-4" {...settings}>
                            {this.state.emp_summary_details &&
                                this.state.emp_summary_details.map((item, idx) => (
                                    <div key={idx} className="mr-12">
                                        <div className="mr-12">
                                            <p className="text-[14px] text-gray-400">
                                                {moment(item.date).format("ll")}
                                            </p>
                                            <p>
                                                <ProgressBar data={item.duration / 6} />
                                            </p>
                                            <p className="text-md text-gray-500">
                                                {this.convertMintoHr(item.duration)}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                        </Slider>
                    )}
                </div>

                <div className="flex mt-4 items-center bg-gray-100">
                    <div className="w-full mx-auto">
                        <div className="w-full grid grid-cols-3">
                            <div className=" p-5 bg-white rounded shadow-sm w-full col-span-2">
                                <div className="text-textprimary text-lg font-semibold">
                                    {t("ReportsTab.productivity")}
                                </div>
                                <div className="mt-4">
                                    {this.state.productivitygraphloading ? (
                                        <div className="text-center h-64 w-full flex justify-center items-center">
                                            <BeatLoader
                                                color={"#684994"}
                                                productivitygraphloading={
                                                    this.state.productivitygraphloading
                                                }
                                                size={15}
                                                margin={2}
                                            />
                                        </div>
                                    ) : (
                                        <ReactHighcharts
                                            config={productivitychart}></ReactHighcharts>
                                    )}
                                </div>
                            </div>
                            <div className="bg-white ml-2 col-span-1">
                                <div className="">
                                    <p className="font-semibold text-lg p-3">
                                        {t("todaysActivity")}
                                    </p>
                                    {activityList && activityList.length > 0 ? (
                                        <div className="overflow-y-scroll scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-primary h-72 py-2 px-3">
                                            {activityList.map((details) => {
                                                return (
                                                    <div className="shadow-lg p-3 rounded-lg mt-3">
                                                        <p className="">
                                                            <span className="mx-2 text-gray-500 opacity-80">
                                                                {t("project")}:
                                                            </span>
                                                            <span>{details.project_name}</span>
                                                        </p>
                                                        <p className="">
                                                            <span className="mx-2 text-gray-500 opacity-80">
                                                                {t("task")}:
                                                            </span>
                                                            {details.task}
                                                        </p>
                                                        <p className="">
                                                            <span className="mx-2 text-gray-500 opacity-80">
                                                                {t("team")}:
                                                            </span>
                                                            {details.team}
                                                        </p>
                                                        <p className="">
                                                            <span className="mx-2 text-gray-500 opacity-80">
                                                                {t("StartTime")}:
                                                            </span>
                                                            {details.start_time}
                                                        </p>
                                                        <p className="">
                                                            <span className="mx-2 text-gray-500 opacity-80">
                                                                {t("EndTime")}:
                                                            </span>
                                                            {details.end_time}
                                                        </p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div className="h-72 py-2 px-3 flex flex-col justify-center text-center italic font-medium text-gray-500">
                                            {t("timeTracker.noRecordsFound")}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* <div className="p-5 bg-white rounded">
                                    <div className='text-textprimary text-lg font-semibold'> Today's Attendance </div>
                                    <div className='mt-4'> <ReactHighcharts config={AttendanceChart}> </ReactHighcharts> </div>
                                </div> */}
                        </div>
                    </div>
                </div>
                <div className="p-5 mt-2 bg-white rounded shadow-sm hidden">
                    <div className="w-full pr-6 font-semibold mb-4 flex items-center justify-between">
                        <p className="text-xl text-textprimary">{`${t("showCaseEntries")} (${
                            this.state.showcaseEntriesList.length
                        })`}</p>
                        {this.state.showcaseEntriesList.length > 4 ? (
                            <p
                                className="text-lg underline text-indigo-600 hover:cursor-pointer"
                                onClick={() => {
                                    this.setState({
                                        isShowAllEntries: !this.state.isShowAllEntries,
                                    });
                                }}>
                                {this.state.isShowAllEntries
                                    ? `${t("hide")} ${t("all")}`
                                    : `${t("show")} ${t("all")}`}
                            </p>
                        ) : null}
                    </div>
                    {this.state.isShowcaseModalActive ? (
                        <ModalShowCaseEntry
                            open={this.state.isShowcaseModalActive}
                            fromDashboard={false}
                            userDetails={{
                                name: this.state.userName
                                    ? this.state.userName
                                    : this.state.selectedEntry.username,
                            }}
                            handleClose={() => {
                                this.setState({ isShowcaseModalActive: false });
                            }}
                            handleModalShowChange={() => {
                                this.setState({
                                    isShowcaseModalActive: !this.state.isShowcaseModalActive,
                                });
                            }}
                            entryDetails={[this.state.selectedEntry]}
                        />
                    ) : null}
                    {this.state.showcaseEntriesList && this.state.showcaseEntriesList.length > 0 ? (
                        <div className="flex flex-wrap lg:grid lg:grid-cols-4 lg:gap-10">
                            {this.state.showcaseEntriesList.map((entry, idx) => {
                                if (!this.state.isShowAllEntries && idx >= 4) {
                                    return null;
                                }
                                return (
                                    <div className="mr-2 mb-6 min-w-[250px] flex flex-col min-h-[225px] w-[250px] h-[225px] overflow-hidden rounded-lg border-2 bg-white border-gray shadow-sm lg:mr-6">
                                        <img
                                            src={entry.image}
                                            alt=""
                                            className="w-full h-[140px] hover:cursor-pointer"
                                            onClick={() => {
                                                this.setState({
                                                    isShowcaseModalActive:
                                                        !this.state.isShowcaseModalActive,
                                                    selectedEntry: entry,
                                                });
                                            }}
                                        />
                                        <div className="p-2 ">
                                            <p className=" text-sm font-semibold">
                                                {entry.file_name}
                                            </p>
                                            <p className="text-sm text-gray-400">
                                                {`${t("project")}: ${entry.project_name}`}
                                            </p>
                                            <p className="text-sm text-gray-400">
                                                {`${t("task")}: ${entry.task}`}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className=" h-40 flex justify-center items-center italic font-medium text-gray-500 text-lg">
                            {t("timeTracker.noEntries")}
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.empDashboard,
  teams: state.teams,
  report: state.report,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEmpLoginDetail: () => dispatch(fetchEmpLoginDetail()),
  fetchEmpDashboard: (date) => dispatch(fetchEmpDashboard(date)),
  fetchEmpSummary: (date) => dispatch(fetchEmpSummary(date)),
  fetchEmployeeWebsiteReport: (startDate, endDate, userId) =>
    dispatch(fetchEmployeeWebsiteReport(startDate, endDate, userId)),
  getShowcaseEntries: (date, params) =>
    dispatch(getShowcaseEntries(date, params)),
  getDailyActivityList: (date) => dispatch(getDailyActivityList(date)),
});

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(Employee_dashboard)
);
