import { Component, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { connect } from 'react-redux';
import InputField from '../../Components/InputField';
import AdminAppRejectRequest from './Admin_approveReject_modal';

import { admin_AppRejRequest } from '../../services/requests/actions';

import noDataSVG from '../../images/nodata.svg';
import { withTranslation } from 'react-i18next';


class ViewDetailedRequest extends Component {
    constructor() {
        super();
        this.state = {
            remark: "",
            showAppRejectModal: false,
            requestStatus: "",
        }
    }

    componentDidMount() {

    }

    remarkModal = (el) => {
        if (el == "Approve") {
            this.setState({ showAppRejectModal: true, requestStatus: "Approve" })

        }
        if (el == "Reject") {
            this.setState({ showAppRejectModal: true, requestStatus: "Reject" })
        }
       
    }

    closeAppRejectModal = () => {
        this.setState({ showAppRejectModal: false })
        this.props.onCancelClick() 
    }

    render() {
        const {t}=this.props
        const { open, successSubmit, onCancelClick, data, ...rest } = this.props

        return (
            <>
                <Transition.Root show={open} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed z-10 inset-0 overflow-y-auto"
                        onClose={onCancelClick}>
                        <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                                    {/* <div className='bg-white rounded-lg p-4 shadow-lg'> */}
                                    {data == "" ? (
                                        <div className="flex justify-center items-center flex-col">
                                            <div className="text-xl font-semibold text-primary my-2">
                                                {t("requestsModule.selectReqViewDetails")}
                                            </div>
                                            <img src={noDataSVG} className="my-3 h-24 w-24" />
                                            <div className="font-medium mt-1">
                                                {t("no")} {t("timeTracker.records")}{" "}
                                                {t("timeTracker.found")}
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div>
                                                <p className="text-primary text-lg font-semibold">
                                                    {data && data.user}
                                                </p>
                                                <p className="text-gray-500">
                                                    {data && data.created}
                                                </p>
                                            </div>
                                            <div className="bg-gray-200 my-4 rounded-xl h-1 w-full"></div>
                                            <div>
                                                <div className="flex my-2 items-center">
                                                    <p>{t("requestsModule.requestType")}: </p>
                                                    <p className="text-lg font-semibold ml-2">
                                                        {data && data.type}
                                                    </p>
                                                </div>
                                                <div className="flex my-2 items-center">
                                                    <p>{t("date")}: </p>
                                                    <p className="text-lg font-semibold ml-2">
                                                        {data && data.start_date} to{" "}
                                                        {data && data.end_date}
                                                    </p>
                                                </div>
                                                <div className=" my-2 items-center">
                                                    <p>{t("requestsModule.whyTakeLeave")} </p>
                                                    <p className="text-lg font-semibold">
                                                        {data && data.info}
                                                    </p>
                                                </div>
                                                <div className="flex my-2 items-center">
                                                    <p>{t("requestsModule.attachedFile")} </p>
                                                    <p className="text-lg font-semibold ml-2">
                                                        {data && data.files}
                                                    </p>
                                                </div>
                                                {data && data.type === "offline" && (
                                                    <div className="flex my-2 items-center">
                                                        <p>{t("project")} </p>
                                                        <p className="text-lg font-semibold ml-2">
                                                            {data && data.project}
                                                        </p>
                                                    </div>
                                                )}
                                                {data && data.type === "offline" && (
                                                    <div className="flex my-2 items-center">
                                                        <p>{t("team")} </p>
                                                        <p className="text-lg font-semibold ml-2">
                                                            {data && data.team}
                                                        </p>
                                                    </div>
                                                )}
                                                {data && data.type === "offline" && (
                                                    <div className="flex my-2 items-center">
                                                        <p>{t("task")} </p>
                                                        <p className="text-lg font-semibold ml-2">
                                                            {data && data.task}
                                                        </p>
                                                    </div>
                                                )}
                                                {data && data.type === "offline" && (
                                                    <div className="flex my-2 items-center">
                                                        <p>Category </p>
                                                        <p className="text-lg font-semibold ml-2">
                                                            {data && data.category}
                                                        </p>
                                                    </div>
                                                )}
                                                <div className="flex my-3">
                                                    <button
                                                        className="bg-primary p-2 rounded-full px-9 sm:px-12 text-center text-white font-medium mr-2"
                                                        onClick={() => {
                                                            this.remarkModal("Approve");
                                                        }}>
                                                        {t("requestsModule.approve")}
                                                    </button>
                                                    <button
                                                        className="bg-primary p-2 rounded-full px-9 sm:px-12 text-center text-white font-medium mr-2"
                                                        onClick={() => {
                                                            this.remarkModal("Reject");
                                                        }}>
                                                        {t("requestsModule.reject")}
                                                    </button>
                                                    <button
                                                        className="border border-danger p-2 px-9 sm:px-12 rounded-full text-red-600 font-medium"
                                                        onClick={onCancelClick}>
                                                        {t("requestsModule.cancel")}
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {/* </div> */}
                                </div>
                            </Transition.Child>
                        </div>
                        {this.state.showAppRejectModal && (
                            <AdminAppRejectRequest
                                open={this.state.showAppRejectModal}
                                successSubmit={successSubmit}
                                onCancelClick={this.closeAppRejectModal}
                                data={data}
                                status={this.state.requestStatus}
                            />
                        )}
                    </Dialog>
                </Transition.Root>
            </>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    admin_AppRejRequest: (data, offline) => dispatch(admin_AppRejRequest(data, offline)),
});


export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ViewDetailedRequest));
