import { Component } from "react";
import { Dialog } from "@headlessui/react";
import { BsSearch } from "react-icons/bs";




class SortByProjects extends Component {
    sortProjects1 = () => {
        const { activeUsers } = this.props
        console.log("projects")
        activeUsers.sort((a, b) => {
            if (a.projects_count < b.projects_count) {
                return -1;
            }
            if (a.projects_count> b.projects_count) {
                return 1;
            }
            return 0;
        })
    };

    sortProjects2 = () => {
        const { activeUsers } = this.props
        console.log("projects")
        activeUsers.sort((a, b) => {
            if (a.projects_count > b.projects_count) {
                return -1;
            }
            if (a.projects_count< b.projects_count) {
                return 1;
            }
            return 0;
        })
    };

    sortProjects3 = () => {
        const { activeUsers } = this.props
        console.log("projects")
        activeUsers.sort((a, b) => {
            if (a.projects_count < b.projects_count) {
                return -1;
            }
            if (a.projects_count> b.projects_count) {
                return 1;
            }
            return 0;
        })
    };

    sortProjects4 = () => {
        const { activeUsers } = this.props
        console.log("projects")
        activeUsers.sort((a, b) => {
            if (a.projects_count < b.projects_count) {
                return -1;
            }
            if (a.projects_count> b.projects_count) {
                return 1;
            }
            return 0;
        })
    };

    sortProjects5 = () => {
        const { activeUsers } = this.props
        console.log("projects")
        activeUsers.sort((a, b) => {
            if (a.projects_count < b.projects_count) {
                return -1;
            }
            if (a.projects_count> b.projects_count) {
                return 1;
            }
            return 0;
        })
    };
    render() {
        const { isSortOpenProjects, sortProjectsStatus } = this.props
        return (
            <Dialog
                open={isSortOpenProjects ?? false}
                onClose={() => {
                    sortProjectsStatus();
                }}
                className="min-w-full">
                <Dialog.Panel className="fixed inset-0 w-3/12 z-10 flex justify-end mt-[280px] ml-auto mr-[12vw]">
                    <div className="bg-white p-4 min-w-full absolute mt-4 rounded-md shadow-lg">
                        <div className="flex flex-row justify-between">
                            <Dialog.Title>
                                <h1 className="text-black font-medium text-[16px] font-inter">
                                    Sort by
                                </h1>
                            </Dialog.Title>
                            <button className="text-[#1055EB] underline font-medium text-[12px] font-inter">
                                Reset
                            </button>
                        </div>
                        <div className="mt-[16px] mb-[16px] flex flex-row items-center bg-transparent border-solid px-[9px] border-2 border-[#D1D5DB] rounded">
                            <BsSearch className="" />
                            <input
                                type="text"
                                placeholder="Search by Project"
                                className="h-8 border-transparent border outline-none"
                            />
                        </div>
                        <Dialog.Description>
                            <div className="flex flex-col">
                                <div className="flex flex-row items-center mt-[13.5px]">
                                    <input
                                        type="radio"
                                        onClick={this.sortProjects1}
                                        id="checkbox"
                                        className="text-[#684D94] focus:ring-0 focus:ring-offset-0"
                                    />
                                    <label
                                        htmlFor="checkbox"
                                        className="text-black font-medium text-[16px] font-inter ml-[8px]">
                                        Project 1
                                    </label>
                                </div>
                                <div className="flex flex-row items-center mt-[16px]">
                                    <input
                                        type="radio"
                                        onClick={this.sortProjects2}
                                        id="checkbox"
                                        className="text-[#684D94] focus:ring-0 focus:ring-offset-0"
                                    />
                                    <label
                                        htmlFor="checkbox"
                                        className="text-black font-medium text-[16px] font-inter ml-[8px]">
                                        Project 2
                                    </label>
                                </div>
                                <div className="flex flex-row items-center  mt-[16px]">
                                    <input
                                        type="radio"
                                        onClick={this.sortProjects3}
                                        id="checkbox"
                                        className="text-[#684D94] focus:ring-0 focus:ring-offset-0"
                                    />
                                    <label
                                        htmlFor="checkbox"
                                        className="text-black font-medium text-[16px] font-inter ml-[8px]">
                                        Project 3
                                    </label>
                                </div>
                                <div className="flex flex-row items-center  mt-[16px]">
                                    <input
                                        type="radio"
                                        onClick={this.sortProjects4}
                                        id="checkbox"
                                        className="text-[#684D94] focus:ring-0 focus:ring-offset-0"
                                    />
                                    <label
                                        htmlFor="checkbox"
                                        className="text-black font-medium text-[16px] font-inter ml-[8px]">
                                        Project 4
                                    </label>
                                </div>
                                <div className="flex flex-row items-center  mt-[16px]">
                                    <input
                                        type="radio"
                                        onClick={this.sortProjects5}
                                        id="checkbox"
                                        className="text-[#684D94] focus:ring-0 focus:ring-offset-0"
                                    />
                                    <label
                                        htmlFor="checkbox"
                                        className="text-black font-medium text-[16px] font-inter ml-[8px]">
                                        Project 5
                                    </label>
                                </div>
                            </div>
                        </Dialog.Description>
                    </div>
                </Dialog.Panel>
            </Dialog>
        );
    }


}
export default SortByProjects