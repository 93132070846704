import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";

const AddNewCard = (props) => {
  const { open, onCancelClick } = props;

  const stripe = useStripe();
  const elements = useElements();

  const handleCreateCard = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(
      CardNumberElement,
      CardExpiryElement,
      CardCvcElement
    );
    const result = await stripe.createToken(card);

    if (result.error) {
      toast.error(result.error.message);
      onCancelClick();
    } else {
      console.log("resulttttttt", result);
      stripeTokenHandler(result.token);
      onCancelClick();
    }
  };

  const stripeTokenHandler = (token) => {
    const response = fetch("https://api.trypadlock.com/users/card_create", {
      method: "POST",
      headers: {
        authorization: "JWT " + localStorage.getItem("access_token"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: localStorage.getItem("user_email"),
        stripeToken: token,
      }),
    })
      .then((response) => {
        if (response.status == 200) {
          console.log("dataatatat", response);
          toast("Card Added Successfully");
        } else {
          console.log("Err transaction resposne", response);
          toast("Failed to add Card");
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onCancelClick}
      >
        <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <p className="text-lg font-semibold">Add Card Details</p>
                <div className="bg-gray-200 rounded-full h-1 my-4"></div>
                <div className="mt-4">
                  <p className="text-md font-medium">Enter Card Number</p>
                  <CardNumberElement className="border border-gray-400 rounded-md mt-1 p-1 h-9" />
                </div>
                <div>
                  <p className="text-md font-medium mt-3">Enter Expiry Date</p>
                  <CardExpiryElement className="border border-gray-400 rounded-md mt-1 p-1 h-9" />
                </div>
                <div>
                  <p className="text-md font-medium mt-3">Enter CVV</p>
                  <CardCvcElement className="border border-gray-400 rounded-md mt-1 p-1 h-9" />
                </div>
                <div className="flex items-center mt-5">
                  <button
                    className="bg-primary border border-primary rounded-md p-1 px-4 text-white shadow-sm transition duration-100 ease-in-out hover:-translate-y-1 hover:shadow-md mr-3"
                    onClick={(e) => {
                      handleCreateCard(e);
                    }}
                  >
                    Create
                  </button>
                  <button
                    className="border border-gray-400 rounded-md p-1 px-4 shadow-sm transition duration-100 ease-in-out hover:-translate-y-1 hover:shadow-md"
                    onClick={onCancelClick}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

// const mapStateToProps = (state) => ({})

// const mapDispatchToProps = (dispatch) => ({})

AddNewCard.propTypes = {
  open: PropTypes.bool,
  onCancelClick: PropTypes.func,
};

export default AddNewCard;
