import React, { Component, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { payForApp } from "../../services/payments/actions";
import { securePaymentStripe } from "../../services/settings/action";
import {
  useStripe,
  useElements,
  CardElement,
  PaymentElement,
  Elements,
  ElementsConsumer,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_live_8TSDRYUEWcKegk80c0R96LEb00IeVwJBMg");

const Pay = (props) => {
  const {
    open,
    onCancelClick,
    cardDetails,
    paySuccesRedirectm,
    invoiceID,
    clientSecret,
  } = props;

  const stripe = useStripe();
  const elements = useElements();

  console.log("cardDetailssssssss", cardDetails);

  const handlePayment = async () => {
    const dataa = {
      id: invoiceID,
      // "email" : localStorage.getItem('user_email'),
      // "stripeToken": cardDetails.customer
    };

    // this.props.payForApp(dataa).then((res) => {
    //     console.log('res', res)
    //     if(res.value.status == 200){
    //         onCancelClick();
    //         toast("Payment Successfull!")
    //         cardDetails = {}
    //         paySuccesRedirect();
    //     } else {
    //         toast("Payment Failed!")
    //     }
    // })

    fetch("https://api.trypadlock.com/users/create_paymentIntent", {
      method: "POST",
      headers: {
        authorization: "JWT " + localStorage.getItem("access_token"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: invoiceID,
      }),
    })
      .then((response) => response.json())
      .then((dataa) => {
        console.log("payData", dataa);
        const cardElement = elements.getElement(CardElement);
        if (!stripe || !elements) {
          console.log("stripe or elements not loaded", stripe, elements);
          return;
        }
        // console.log('cradElement', cardElement)
        const pay = async () => {
          const confirmPayment = await stripe
            .confirmPayment({
              elements,
              confirmParams: {
                return_url:
                  "http://app.joinworkahub.com/users/:success_payment",
              },
            })
            .then((result) => {
              console.log("result", result);
              stripe
                .retrievePaymentIntent(clientSecret)
                .then(({ paymentIntent }) => {
                  switch (paymentIntent.status) {
                    case "succeeded":
                      console.log("Payment succeeded!");
                      break;
                    case "processing":
                      console.log("Your payment is processing.");
                      break;
                    case "requires_payment_method":
                      console.log(
                        "Your payment was not successful, please try again."
                      );
                      break;
                    default:
                      console.log("Something went wrong.");
                      break;
                  }
                });
            })
            .catch((error) => {
              console.log("err", error);
            });
        };
        pay();
      });

    console.log("dataa", dataa);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onCancelClick}
      >
        <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <p className="text-lg font-semibold">Confirm Payment?</p>
                {/* <div className='my-5'>
                                    <CardElement />   
                                </div> */}
                <div className="mt-5">
                  <PaymentElement />
                </div>
                <div className="mt-4">
                  <button
                    className="bg-primary p-1 px-6 text-white font-semibold mr-3 rounded-md transition duration-150 ease-in-out hover:-translate-y-1"
                    onClick={() => {
                      handlePayment();
                    }}
                  >
                    PAY
                  </button>
                  <button
                    className="border border-gray-400 font-semibold p-1 px-3 rounded-md transition duration-150 ease-in-out hover:-translate-y-1"
                    onClick={onCancelClick}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Pay.propTypes = {
  open: PropTypes.bool,
  onCancelClick: PropTypes.func,
  cardDetails: PropTypes.object,
  paySuccesRedirect: PropTypes.func,
};

// const mapStateToProps = (state) => ({})

// const mapDispatchToProps = (dispatch) => ({
//     payForApp: (data) => dispatch(payForApp(data)),
//     securePaymentStripe: (data) => dispatch(securePaymentStripe(data)),
// })

export default Pay;
