import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import InputField from "../../Components/InputField";

import { useTranslation } from "react-i18next";
import { Checkbox } from "@material-ui/core";
import { PlusIcon } from "@heroicons/react/outline";
import { CalendarIcon } from "@heroicons/react/outline";
import DatePicker from "react-datepicker";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useEffect } from "react";
import moment from "moment/moment";
import Select from "react-select";
import { InformationCircleIcon } from "@heroicons/react/solid";

export default function CreateProjectModal(props) {
    const {
        onCancelClick,
        onSubmitClick,
        submitButtonName,
        cancelButtonName,
        open,
        postHours,
        updateHours,
        hoursData,
        ...rest
    } = props;
    const cancelButtonRef = useRef(null);
    const [everyMonth, setEveryMonth] = useState(true);
    const [overrideBill, setoverrideBill] = useState(false);
    const [empBillRate, setEmpBillRate] = useState("");
    const [selectedCurr, setselectedCurr] = useState("usd");
    const [selectedHrsDate, setselectedHrsDate] = useState([
        {
            id: null,
            hoursBooked: 0,
            edit_status: false,
            date: new Date(),
        },
    ]);
    const [customFields, setCustomFields] = useState([{ key: "", value: "" }]);
    useEffect(() => {
        setEveryMonth(props.everyMonthSame);
        if (props.hoursData) {
            let dataList = [];
            for (var data of props.hoursData) {
                if (data.date) {
                    var date = data.date.split("-");
                    dataList.push({
                        id: data.id,
                        hoursBooked: data.hours,
                        edit_status: data.edit_status,
                        date: new Date(date[0], parseInt(date[1]) - 1, date[2]),
                    });
                }
            }
            setselectedHrsDate(dataList);
        }
        if (props.empBillRate && props.currency) {
            setselectedCurr(props.currency);
            setEmpBillRate(props.empBillRate);
        }
        if (props.customFields && props.customFields.length > 0) {
            const customF = [];
            for (let field of props.customFields) {
                customF.push({
                    key: Object.keys(field),
                    value: Object.values(field),
                });
            }
            setCustomFields(customF);
        }
    }, [props]);
    const { t } = useTranslation("common");
    const addHours = () => {
        let datesList = [...selectedHrsDate];
        datesList.push({
            id: null,
            hoursBooked: 0,
            edit_status: false,
            date: new Date(),
        });
        setselectedHrsDate(datesList);
    };
    const removeHours = (idx) => {
        let datesList = [...selectedHrsDate];
        datesList = datesList.filter((_, index) => {
            return index !== idx;
        });

        setselectedHrsDate(datesList);
    };
    const handleHoursBookedChange = (ev, index) => {
        let monthsList = [...selectedHrsDate];
        monthsList[index].hoursBooked = ev.target.value;
        setselectedHrsDate(monthsList);
    };
    const handleDateChange = (date, index) => {
        let monthsList = [...selectedHrsDate];
        monthsList[index].date = date;
        setselectedHrsDate(monthsList);
    };

    return (
        <Transition.Root show={open} as={Fragment} {...rest}>
            <Dialog
                as="div"
                className="fixed z-30 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                onClose={onCancelClick}>
                <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="relative inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all max-h-[610px] overflow-hidden sm:max-w-lg sm:align-middle  sm:my-8 sm:w-full">
                            <div className="max-h-[600px] overflow-y-auto px-4 pt-5 pb-4 sm:p-6">
                                <div className="sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg leading-6 font-bold text-textprimary">
                                            {props.editMode
                                                ? `${t("settingsTab.update")} ${t("project")}`
                                                : `${t("create")} ${t("project")}`}
                                        </Dialog.Title>
                                    </div>
                                    <div className="mt-8">
                                        <Dialog.Description>
                                            <div className="flex justify-between">
                                                <div className="flex-1 flex-shrink-0">
                                                    <InputField
                                                        onInputChange={props.handlenameChange}
                                                        FieldName={`${t("project")} ${t("name")}`}
                                                        name={"projectname"}
                                                        value={props.projectname}
                                                        type={"text"}
                                                        id={"name"}
                                                        placeholder={""}></InputField>
                                                </div>
                                                <div className="flex-1 flex-shrink-0 pl-2 z-10">
                                                    <Select
                                                        value={props.selectedTeam}
                                                        onChange={props.selectteamhandlechange}
                                                        options={props.teamOptions}
                                                        className="text-md"
                                                        placeholder="Select Team"
                                                        isMulti={false}
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-4 flex-shrink-0">
                                                <InputField
                                                    onInputChange={
                                                        props.handledescriptionhandlechange
                                                    }
                                                    FieldName={`${t("project")} ${t(
                                                        "description"
                                                    )}`}
                                                    name={"projectdescription"}
                                                    value={props.projectdescription}
                                                    type={"text"}
                                                    id={"description"}
                                                    placeholder={""}></InputField>
                                            </div>
                                            <div className="relative mt-4 flex-shrink-0">
                                                <div className="absolute top-2 left-2.5 z-10">
                                                    {selectedCurr === "usd" ? "$" : "₹"}
                                                </div>
                                                <div className="absolute top-1 right-1 z-[1]">
                                                    <select
                                                        name="cars"
                                                        id="cars"
                                                        onChange={(ev) => {
                                                            setselectedCurr(ev.target.value);
                                                        }}
                                                        defaultValue={selectedCurr}
                                                        className="text-tiny py-1 focus:outline-none focus:ring-0 focus:border-none border-none">
                                                        <option value="usd" className="py-1 px-2">
                                                            USD
                                                        </option>
                                                        <option value="inr" className="py-1 px-2">
                                                            INR
                                                        </option>
                                                    </select>
                                                </div>
                                                <InputField
                                                    onInputChange={(ev) => {
                                                        setEmpBillRate(ev.target.value);
                                                    }}
                                                    FieldName={`Employee Bill Rate (Optional)`}
                                                    name={"Employee Bill Rate (Optional)"}
                                                    value={empBillRate}
                                                    style={{
                                                        paddingLeft: "1rem",
                                                        paddingRight: "1rem",
                                                    }}
                                                    type={"text"}
                                                    id={"empBillRate"}
                                                    placeholder={"0.00"}></InputField>
                                            </div>
                                            <div className="flex items-center">
                                                <Checkbox
                                                    id="overrideBillCheck"
                                                    style={{ color: "#684D94" }}
                                                    checked={overrideBill}
                                                    onClick={() => {
                                                        setoverrideBill(!overrideBill);
                                                    }}
                                                />
                                                <label
                                                    htmlFor="overrideBillCheck"
                                                    className="flex items-center">
                                                    <div className="mr-2">
                                                        Override with employee pay rate
                                                    </div>
                                                    <div className="group relative">
                                                        <InformationCircleIcon
                                                            color="#666666"
                                                            width={22}
                                                            height={22}
                                                            className="hover: cursor-pointer"
                                                        />
                                                        <div className="absolute top-6 -left-24 hidden group-hover:flex flex-col items-center z-20 w-52 text-[#ffffff]">
                                                            <div className="max-h-1.5 z-[-10]">
                                                                <div className="bg-black bg-opacity-80 rounded min-w-[1rem] min-h-[1rem] max-w-[1rem] max-h-[1rem] rotate-45"></div>
                                                            </div>
                                                            <div className="bg-black bg-opacity-80 rounded text-xs px-2 py-0.5">
                                                                By activating this option, the
                                                                current employee's bill rate will be
                                                                replaced with the project bill rate.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            {!props.editMode ? (
                                                <div className="mt-4 z-10 flex-shrink-0">
                                                    <Select
                                                        value={props.selectedusers}
                                                        onChange={props.selectusershandlechange}
                                                        options={props.useroptions}
                                                        placeholder="Select Users"
                                                        isMulti
                                                    />
                                                </div>
                                            ) : null}
                                            {everyMonth ? (
                                                <div className="mt-4 flex-shrink-0">
                                                    <InputField
                                                        onInputChange={(ev) => {
                                                            handleHoursBookedChange(ev, 0);
                                                        }}
                                                        FieldName={`Hours Booked`}
                                                        name={"projecthoursBooked"}
                                                        value={
                                                            selectedHrsDate.length > 0
                                                                ? selectedHrsDate[0].hoursBooked +
                                                                  ""
                                                                : "36"
                                                        }
                                                        type={"number"}
                                                        id={"hoursBooked"}
                                                        placeholder={
                                                            "Hours booked here"
                                                        }></InputField>
                                                </div>
                                            ) : (
                                                <div className="flex flex-col">
                                                    {selectedHrsDate.map((el, idx) => {
                                                        return (
                                                            <div
                                                                key={idx * idx + idx}
                                                                className="mt-4 grid grid-cols-2 gap-6">
                                                                <InputField
                                                                    onInputChange={(ev) => {
                                                                        handleHoursBookedChange(
                                                                            ev,
                                                                            idx
                                                                        );
                                                                    }}
                                                                    FieldName={`Hours Booked`}
                                                                    name={"projecthoursBooked"}
                                                                    value={el.hoursBooked}
                                                                    type={"number"}
                                                                    id={"hoursBooked"}
                                                                    placeholder={
                                                                        "Hours booked here"
                                                                    }></InputField>
                                                                <div className="flex relative text-textprimary text-lg font-medium">
                                                                    <div className="relative w-5/6 rounded-md border border-gray-300 shadow-sm">
                                                                        <DatePicker
                                                                            className="w-full rounded-md border-0 text-textprimary"
                                                                            dateFormat="MMMM yyyy"
                                                                            showMonthYearPicker
                                                                            disabled={
                                                                                el.edit_status
                                                                            }
                                                                            selected={el.date}
                                                                            onChange={(date) =>
                                                                                handleDateChange(
                                                                                    date,
                                                                                    idx
                                                                                )
                                                                            }
                                                                        />
                                                                        <CalendarIcon className="absolute top-2 right-2 w-6"></CalendarIcon>
                                                                    </div>
                                                                    <div
                                                                        className=" ml-2 min-w-[1.5rem] min-h-[1.5rem] hover:cursor-pointer"
                                                                        onClick={() => {
                                                                            removeHours(idx);
                                                                        }}>
                                                                        <IoIosCloseCircleOutline
                                                                            strokeWidth={20}
                                                                            className=" min-h-full min-w-full text-gray-500"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </Dialog.Description>
                                        <div className="flex justify-between text-sm">
                                            <div>
                                                <Checkbox
                                                    id="everyMonthCheck"
                                                    style={{ color: "#684D94" }}
                                                    checked={everyMonth}
                                                    onClick={() => {
                                                        setEveryMonth(!everyMonth);
                                                    }}
                                                />
                                                <label htmlFor="everyMonthCheck">
                                                    Same for every month
                                                </label>
                                            </div>
                                            {!everyMonth ? (
                                                <div
                                                    className="flex items-center text-primary hover:cursor-pointer"
                                                    onClick={() => addHours()}>
                                                    <PlusIcon className="h-4" />
                                                    <div>Add hours</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {customFields.map((field, idx) => (
                                        <div
                                            key={idx / (customFields.length + 1)}
                                            className="flex gap-4 py-2">
                                            <InputField
                                                onInputChange={(ev) => {
                                                    let fields = [...customFields];
                                                    fields[idx]["key"] = ev.target.value;
                                                    setCustomFields(fields);
                                                }}
                                                FieldName={`Key`}
                                                name={"Key"}
                                                value={field.key}
                                                type={"text"}
                                                id={"key"}></InputField>
                                            <InputField
                                                onInputChange={(ev) => {
                                                    let fields = [...customFields];
                                                    fields[idx]["value"] = ev.target.value;
                                                    setCustomFields(fields);
                                                }}
                                                FieldName={`Value`}
                                                name={"Value"}
                                                value={field.value}
                                                type={"text"}
                                                id={"value"}></InputField>
                                        </div>
                                    ))}
                                </div>
                                <button
                                    className="flex gap-2 py-2 text-primary w-full justify-end whitespace-nowrap flex-nowrap items-center"
                                    onClick={() => {
                                        let fields = [...customFields];
                                        fields.push({ key: "", value: "" });
                                        setCustomFields(fields);
                                    }}>
                                    <PlusIcon className="w-4 h-4" />
                                    <div>Add Activity</div>
                                </button>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={async () => {
                                            if (!props.editMode) {
                                                onSubmitClick((projectId) => {
                                                    if (everyMonth) {
                                                        const data = {
                                                            id: projectId,
                                                            hrs: selectedHrsDate[0].hoursBooked,
                                                            status: "True",
                                                            date: moment(
                                                                selectedHrsDate[0].date
                                                            ).format("YYYY-MM-DD"),
                                                            type: "single",
                                                        };
                                                        postHours(data);
                                                    } else {
                                                        let postDateList = [];
                                                        let postHoursList = [];
                                                        for (var selectedData of selectedHrsDate) {
                                                            postDateList.push(
                                                                moment(selectedData.date).format(
                                                                    "YYYY-MM-DD"
                                                                )
                                                            );
                                                            postHoursList.push(
                                                                selectedData.hoursBooked
                                                            );
                                                        }
                                                        let dateListParam = "";
                                                        let hoursListParam = "";
                                                        dateListParam += postDateList.map(
                                                            (date) => date
                                                        );
                                                        hoursListParam += postHoursList.map(
                                                            (hour) => hour
                                                        );
                                                        const data = {
                                                            id: projectId,
                                                            hrs: hoursListParam,
                                                            status: "False",
                                                            date: dateListParam,
                                                            type: "more",
                                                        };
                                                        postHours(data);
                                                    }
                                                }, customFields);
                                            } else {
                                                if (everyMonth) {
                                                    if (
                                                        selectedHrsDate[0].edit_status &&
                                                        props.editMode
                                                    ) {
                                                        const data = {
                                                            id: selectedHrsDate[0].id,
                                                            hours: selectedHrsDate[0].hoursBooked,
                                                            currency: selectedCurr,
                                                            amount: parseFloat(empBillRate),
                                                        };
                                                        updateHours(data);
                                                    } else {
                                                        const data = {
                                                            id: props.projectId,
                                                            hrs: selectedHrsDate[0].hoursBooked,
                                                            status: "True",

                                                            date: moment(
                                                                selectedHrsDate[0].date
                                                            ).format("YYYY-MM-DD"),
                                                            type: "single",
                                                            currency: selectedCurr,
                                                            amount: parseFloat(empBillRate),
                                                        };
                                                        postHours(data);
                                                    }
                                                } else {
                                                    let postDateList = [];
                                                    let postHoursList = [];
                                                    for (var selectedData of selectedHrsDate) {
                                                        if (
                                                            selectedData.edit_status &&
                                                            props.editMode
                                                        ) {
                                                            const data = {
                                                                id: selectedData.id,
                                                                hours: selectedData.hoursBooked,
                                                                currency: selectedCurr,
                                                                amount: parseFloat(empBillRate),
                                                            };
                                                            await updateHours(data);
                                                        } else {
                                                            postDateList.push(
                                                                moment(selectedData.date).format(
                                                                    "YYYY-MM-DD"
                                                                )
                                                            );
                                                            postHoursList.push(
                                                                selectedData.hoursBooked
                                                            );
                                                        }
                                                    }
                                                    let dateListParam = "";
                                                    let hoursListParam = "";
                                                    dateListParam += postDateList.map(
                                                        (date) => date
                                                    );
                                                    hoursListParam += postHoursList.map(
                                                        (hour) => hour
                                                    );
                                                    const data = {
                                                        id: props.projectId,
                                                        hrs: hoursListParam,
                                                        status: "False",
                                                        date: dateListParam,
                                                        type: "more",
                                                        currency: selectedCurr,
                                                        amount: parseFloat(empBillRate),
                                                    };
                                                    postHours(data);
                                                }
                                                onSubmitClick(customFields);
                                            }
                                        }}>
                                        {submitButtonName}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={onCancelClick}
                                        ref={cancelButtonRef}>
                                        {cancelButtonName}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
