import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import InputField from '../../Components/InputField';

export default function AddClientToTeamModal(props) {
  const {
    onCancelClick,
    onSubmitClick,
    onCreateNewClientClick,
    submitButtonName,
    cancelButtonName,
    open,
    teamName,
    clients,
    selectedClient,
    onClientSelectChange,
    setSuccessMessage,
    selectedTeamName,
    team_uuid,
    ...rest
  } = props;

  const cancelButtonRef = useRef(null);

  // const clientsData = props.clients && props.clients.data ? props.clients.data : [];
  const clientsData = props.clients && props.clients.data ? props.clients.data : [];


  return (
    <Transition.Root show={open} as={Fragment} {...rest}>
      <Dialog
        as="div"
        className="fixed z-30 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onCancelClick}
      >
        <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-bold text-textprimary">
                    Add Client to team {props.selectedTeamName} 
                    {/* {props.teamName} */}
                  </Dialog.Title>
                </div>
                <div className="mt-8" >
                  <Dialog.Description>
                    <label htmlFor="clientSelect" className="block text-sm font-medium text-gray-700">
                      Select Client
                    </label>
                    <select
                      id="id"
                      className="mt-1 block w-full p-2 border rounded-md shadow-sm focus:ring focus:ring-opacity-50 max-h-[200px] overflow-y-auto"
                      value={selectedClient}
                      onChange={onClientSelectChange}

                    >
                      {/* <option value="">Select a client</option> */}
                      {/* {clients.map((client) => (
                        <option key={client.id} value={client.id}>
                          {client.client_name}
                        </option>
                      ))} */}

                      <option value="">Select a client</option>
                      {clientsData.map((client) => (
                        <option key={client.id} value={client.id}>
                          {client.client_name}
                        </option>
                      ))}
                    </select>
                  </Dialog.Description>
                </div>

                <p className='mt-5 mb-5 block text-center text-sm font-medium text-gray-700 justify-center'>OR</p>



              </div>

              <div className="w-full mt-5 mb-10 sm:mt-4 sm:flex sm:flex-row-reverse justify-center align-middle">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 text-primary text-base font-medium bg-white hover:bg-white focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={onCreateNewClientClick}
                >
                  Create New Client
                </button>
              </div>

              <div className='mt-3 w-75 border-b border-gray mx-auto'></div>

              <div className="mt-10 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onSubmitClick}
                >
                  {submitButtonName}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={onCancelClick}
                  ref={cancelButtonRef}
                >
                  {cancelButtonName}
                </button>
              </div>

            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

