import {ACTION_TYPES} from '../../../constants';

const payments = (state = {}, action) => {
    switch(action.type) {
        case ACTION_TYPES.GET_SAVED_PAYMENT_CARDS_PENDING: {
            return {
                ...state,
                isFetchingSavedCards: true,
                isFetchingSavedCardsFailed: false,
                SavedCardsFetchError: undefined,
                SavedCards: [],
            }
        }

        case ACTION_TYPES.GET_SAVED_PAYMENT_CARDS_FULFILLED: {
            return {
                ...state,
                isFetchingSavedCards: false,
                isFetchingSavedCardsFailed: false,
                SavedCardsFetchError: undefined,
                SavedCards: action.payload.data,
            }
        }

        case ACTION_TYPES.GET_SAVED_PAYMENT_CARDS_REJECTED: {
            return {
                ...state,
                isFetchingSavedCards: false,
                isFetchingSavedCardsFailed: true,
                SavedCardsFetchError: action.payload,
                SavedCards: [],
            }
        }

        //pay for app
        case ACTION_TYPES.PAY_FOR_APP_PENDING: {
            return {
                ...state,
                isPayingForApp: true,
                isPayingForAppFailed: false,
                PayForAppFetchError: undefined,
                PayForApp: [],
            }
        }

        case ACTION_TYPES.PAY_FOR_APP_FULFILLED: {
            return {
                ...state,
                isPayingForApp: false,
                isPayingForAppFailed: false,
                PayForAppFetchError: undefined,
                PayForApp: action.payload.data,
            }
        }

        case ACTION_TYPES.PAY_FOR_APP_REJECTED: {
            return {
                ...state,
                isPayingForApp: false,
                isPayingForAppFailed: true,
                PayForAppFetchError: action.payload,
                PayForApp: [],
            }
        }

        default: {
            return state;
        }

    }
}

export default payments;