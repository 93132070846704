import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./TransactionAnimation.css";

export default function PayPalTransacComp() {
  const { t } = useTranslation("common");
  let qloc = window.location.search;
  let invoice_id = new URLSearchParams(qloc).get("invoice_id");
  let paymentId = new URLSearchParams(qloc).get("paymentId");
  let token = new URLSearchParams(qloc).get("token");
  let PayerID = new URLSearchParams(qloc).get("PayerID");
  useEffect(() => {
    paypalPaymentConfirm();
  }, []);

  const paypalPaymentConfirm = async () => {
    fetch(
      `https://api.trypadlock.com/users/success_payment?invoice_id=${invoice_id}&paymentId=${paymentId}&token=${token}&PayerID=${PayerID}`,
      {
        method: "GET",
        headers: {
          authorization: "JWT " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          window.location.href = "/settings/";
        } else {
          return response.json();
        }
      })
      .then((data) => {
        console.log("data", data);
        window.location.href = "/users/cancel";
      })
      .catch((err) => {});
  };

  return (
      <div className="" style={{ backgroundColor: "white", height: "100vh", width: "100%" }}>
          <div
              style={{
                  paddingTop: "100px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
              }}>
              <section className="c-container">
                  <div className="o-circle c-container__circle o-circle__sign--success">
                      <div className="o-circle__sign"></div>
                  </div>
              </section>

              <div
                  style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                  }}>
                  <p style={{ fontSize: "35px", color: "#684d94", fontWeight: "900" }}>
                      {t("settingsTab.transactionComplete")}
                  </p>
                  <p style={{ fontSize: "18px", fontWeight: "bold", color: "#684D94" }}>
                      {t("redirecting")}...
                  </p>
              </div>
          </div>
      </div>
  );
}
