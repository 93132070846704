import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from 'moment'

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays, subDays , startOfMonth} from "date-fns";

const DateRangeFilter = ({ onChange }) => {
  const [state, setState] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  const handleOnChange = ranges => {
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);
  };

  return (
    <DateRangePicker
      onChange={handleOnChange}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={1}
      ranges={state}
      direction="horizontal"
    />
  );
};

DateRangeFilter.propTypes = {
  onChange: PropTypes.func
};

export default DateRangeFilter;
