import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Layout from "../../Components/Layout";
// import moment from "moment";
import moment from "moment-timezone";
import { CalendarIcon, CheckIcon, MoonIcon, TrashIcon } from "@heroicons/react/outline";
import ReactHighcharts from "react-highcharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/global.css";
import SelectField from "../../Components/SelectField";
import ModalShowCaseEntry from "./modalShowCaseEntry";
// import { GoogleMap, LoadScript, Marker, OverlayView, InfoBox, InfoWindow } from "@react-google-maps/api";
import GoogleMapReact from "google-map-react";
import idelTime from "../../assets/idelTime.png";
import selectEmployeeTimeTracker from "../../assets/selectEmployeeTimeTracker.jpg";

import {
    ServerIcon,
    DesktopComputerIcon,
    PhotographIcon,
    CameraIcon,
    ExclamationCircleIcon,
    CollectionIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    CogIcon,
} from "@heroicons/react/solid";

import { fetchallEmployees, fetchactiveprojects } from "../../services/projects/actions";
import {
    GetAppLog,
    GetAppLogData,
    timeTrackerData,
    timeTrackerDataWithoutProject,
    fetchSystemInfo,
    customReportWeekglyGraphdata,
    getEmployeeBroswerLog,
    timeTrackerDataWithoutProjectObserver,
    timeTrackerDataObserver,
    getSystemInfoObserver,
    weeklyChartActivity,
    deleteTime,
    cancelTime,
    getUserDetail,
    getShowcaseEntries,
    getEmployeeMoods,
} from "../../services/timetracker/actions";
import { fetchActivityIndex, fetchAllUserURLs } from "../../services/activityMonitor/actions";

import { getRolesCheck, getEmpListObserver } from "../../services/dashboard/actions";
import { storeToLocalStorage, showData } from "../../services/globalState/actions";

import noDataSVG from "../../images/nodata.svg";
import { Dialog, Menu, Transition } from "@headlessui/react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Divider } from "@material-ui/core";
import { Web, DesktopMacRounded, SmartphoneRounded, PinDrop } from "@material-ui/icons";
import { toast } from "react-toastify";

import { Modaltracker } from "./modalTracker";
import ScreenShotSetting_modal from "./ScreenShotSetting_modal";
import DateRangeFilter from "./DatePickerAct";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import BeatLoader from "react-spinners/BeatLoader";
import { getListItemSecondaryActionClassesUtilityClass } from "@mui/material";

import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DesktopApps from "./ApplicationsSubTab/desktopApps";
import MobileApps from "./ApplicationsSubTab/mobileApps";
import { MapMarker } from "../../Components/MapMarker";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const NoDataContainer = () => (
    <div className="h-64 w-full bg-white mt-4 shadow-md rounded-md p-2 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center mt-5">
            <img src={noDataSVG} alt="no data" className="h-16 w-16 mb-3" />
            <div className="text-lg text-primary font-semibold">
                Select Employee to view detailed Graph
            </div>
        </div>
    </div>
);

class TimeTracker extends Component {
    constructor() {
        super();
        this.state = {
            SelectedTab: "screenshots",
            selectedDate: new Date(),
            apploguser: "",
            projectname: "",
            employeeId: "",
            applicationsactivities: [
                {
                    name: "Google Chrome",
                    activity: "User system performance - figma - Google chrome",
                },
                { name: "Figma", activity: "User system performance - figma" },
                {
                    name: "Google Chrome",
                    activity: "User system performance - github - Google chrome",
                },
                { name: "Figma", activity: "Sales Reach - figma" },
                {
                    name: "Teams",
                    activity: "saumya garg and siva sai varma - microsoft teams",
                },
            ],
            screenshotkpis: {
                Activity_idex: 0,
                Total_idle_time: 0,
                Total_online_time: 0,
            },
            cpu_info: [],
            percentages: [],
            timingArray: [],
            Timetracker: [],
            Timetrackerkeys: [],
            ttks: [],
            currentDate: new Date(),
            lastDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
            cpuTab: "cpu",
            showChart: false,
            weekGraphDate: [],
            weekGraphActIndex: [],
            displayName: [],
            showScreenShotSetting: false,
            applicationsSubTab: "desktop",
            startDate: moment(new Date()).format("YYYY-MM-DD"),
            endDate: moment(new Date()).format("YYYY-MM-DD"),
            activitySubTabs: "BrowserActivity",
            showDateRange: false,
            browserActivityData: [],
            url: "",
            selectedTimezone: "",
            loadingScreenshotData: false,
            employeeList: [],
            applogs: [],
            applogsdata: [],
            // userROLE: "",
            userRoleList: [],
            weeklychartData: [],
            isEmpObserverState: false,
            showProgramList: false,
            isScreenshotSelectActive: false,
            selectedSSid: [],
            userName: "",
            isShowcaseModalActive: false,
            showcaseEntriesList: [],
            employeeMood: "",
            showLocInfo: "",
            markers: [],
            currFocCoordinates: {},
            openFromEnter: false,
        };
    }

    componentDidMount() {
        // this.props.weeklyChartActivity().then((res) => {this.setState({weeklychartData: res.value.data})})
        // const ur = localStorage.getItem('userRole');
        // this.setState({userROLE:ur});
        const locState = this.props.location.state;
        const url = window.location.pathname;
        this.props.getRolesCheck().then((res) => {
            this.setState({ userRoleList: res.value.data });
        });
        setTimeout(() => {
            this.setState({ url: url });
        }, 200);
        setTimeout(() => {
            if (this.state.url === "/timetracker/screenshots/") {
                this.setState({ SelectedTab: "screenshots" });
            } else if (this.state.url.startsWith("/timetracker/cpuinformation/", 0)) {
                let subTab = this.state.url.split("/");
                if (subTab.at(-2) === "cpuinformation") {
                    subTab = subTab.at(-1);
                } else {
                    subTab = subTab.at(-2);
                }
                this.setState({ SelectedTab: "cpuinformation", cpuTab: subTab ? subTab : "cpu" });
            } else if (this.state.url === "/timetracker/applications/") {
                this.setState({ SelectedTab: "applications" });
            } else if (this.state.url === "/timetracker/activitylog/") {
                this.setState({ SelectedTab: "activitylog" });
            } else if (this.state.url === "/timetracker/location/") {
                this.setState({ SelectedTab: "location" });
            } else {
                this.setState({ SelectedTab: "screenshots" });
            }
        }, 250);

        // setTimeout(() => {
        const query = new URLSearchParams(this.props.location.search);
        const empname = query.get("empname");
        const project = query.get("project");
        const paramDate = query.get("date");
        const paramTimezone = query.get("timezone");
        setTimeout(() => {
            this.props.fetchallEmployees().then((res) => {
                let user_id =
                    empname || locState
                        ? empname
                            ? empname
                            : locState.data.selectedEmployee
                        : null;
                if (res.value.status === 200) {
                    this.setState({ employeeList: res.value.data }, () => {
                        let empnamee = res.value.data.filter((el) => el.user_id === user_id);
                        let isEmpObserver =
                            empnamee === "" || empnamee === undefined || empnamee.length === 0
                                ? ""
                                : empnamee[0].user_login_as.includes("from");
                        this.setState({ isEmpObserverState: isEmpObserver });
                    });
                    let emp = res.value.data.find((emp) => emp.user_id === user_id);
                    let uu_id = emp && emp.user_id;
                    if (user_id && user_id !== "" && uu_id) {
                        let userDate = paramDate
                            ? paramDate
                            : moment(locState.data.selectedDate).format("YYYY-MM-DD");
                        this.props.getUserDetail(userDate, user_id);
                        this.setState({
                            showcaseEntriesList: [],
                            employeeMood: "",
                            userName: emp.user_first_name + " " + emp.user_last_name,
                        });
                        this.props
                            .getShowcaseEntries(userDate, `user&user_id=${user_id}`)
                            .then((res) => {
                                if (res.value.status === 200 && !res.value.data.message) {
                                    this.setState({
                                        showcaseEntriesList: res.value.data.data,
                                    });
                                }
                            });
                        this.props
                            .getEmployeeMoods(`${userDate}&user_id=${user_id}`)
                            .then((res) => {
                                if (
                                    res.value.status === 200 &&
                                    res.value.data.data !== "no data present"
                                ) {
                                    this.setState({
                                        employeeMood: res.value.data.data,
                                    });
                                }
                            });
                    }
                    this.setState({
                        employeeId: uu_id,
                        showProgramList: false,
                    });
                }
            });
        }, 200);

        this.setState({
            employeeId:
                locState === undefined || locState === ""
                    ? empname === null
                        ? ""
                        : empname
                    : locState.data.selectedEmployee,
            // projectname: locState === undefined || locState === "" ? project === null ? "" : project : locState.data.selectedProject,
            selectedDate:
                paramDate === null
                    ? locState === "" || locState === undefined
                        ? new Date()
                        : locState.data.selectedDate
                    : new Date(paramDate),
            selectedTimezone: paramTimezone === null ? "" : paramTimezone,
        });

        if (!paramTimezone && sessionStorage.getItem("timezone")) {
            const defaultTimezone = sessionStorage.getItem("timezone");
            this.onTimeZoneChange({ value: defaultTimezone });
        }
        setTimeout(() => {
            if (empname&& empname!=='undefined') {
                this.setState({ employeeId: empname }, () => {
                    if (localStorage.getItem("roleUser") === "admin") {
                        this.props
                            .fetchSystemInfo(
                                moment(this.state.selectedDate).format("YYYY-MM-DD"),
                                this.state.employeeId
                            )
                            .then((res) => {
                                if (res.value.status === 200) {
                                    this.setState({
                                        cpu_info: res.value.data,
                                        showChart: true,
                                    });
                                } else if (res.value.status === 404) {
                                    this.setState({ cpu_info: [] });
                                }
                            });
                    } else {
                        this.props
                            .getSystemInfoObserver(
                                moment(this.state.selectedDate).format("YYYY-MM-DD"),
                                this.state.employeeId
                            )
                            .then((res) => {
                                if (res.value.status === 200) {
                                    this.setState({
                                        cpu_info: res.value.data,
                                        showChart: true,
                                    });
                                } else if (res.value.status === 404) {
                                    this.setState({ cpu_info: [] });
                                }
                            });
                    }
                });
            }
            if (this.state.SelectedTab === "activitylog") {
                if (this.state.employeeId) {
                    this.props
                        .fetchAllUserURLs(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            this.state.employeeId
                        )
                        .then((res) => {
                            if (res.value.status === 200) {
                                this.setState({
                                    browserActivityData: res.value.data,
                                });
                            }
                        });
                }
            }
        }, 350);

        setTimeout(() => {
            if (url.includes("cpuinformation")) {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/${
                        this.state.cpuTab ?? "cpu"
                    }/?empname=${this.state.employeeId}&project=${
                        this.state.projectname
                    }&date=${moment(this.state.selectedDate).format("YYYY-MM-DD")}&timezone=${""}`
                );
            } else {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/?empname=${
                        this.state.employeeId
                    }&project=${this.state.projectname}&date=${moment(
                        this.state.selectedDate
                    ).format("YYYY-MM-DD")}&timezone=${""}`
                );
            }
        }, 300);

        // }, 200);

        this.props.fetchactiveprojects();
        setTimeout(() => {
            this.CustomReportList();
            this.handleScreenshotDateChange();
        }, 650);
        // this.props.getEmpListObserver().then((res) => {
        // })
        document.addEventListener("keydown", this.bindingKeyHandler);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.bindingKeyHandler);
    }

    bindingKeyHandler = (ev) => {
        if (this.state.Timetracker?.length > 0) {
            if (
                !this.state.currFocCoordinates["min"]&&
                (ev.keyCode === 37 || ev.keyCode === 38 || ev.keyCode === 39 || ev.keyCode === 40)
            ) {
                ev.preventDefault();
                let coord = {};
                for (let idx = 0; idx< this.state.Timetracker.length; idx++) {
                    let items = this.state.Timetracker[idx];
                    for (let i = 10; i <= 60; i += 10) {
                        if (items[i] && items[i].id != null) {
                            coord["hr"]= idx;
                            coord["min"] = i;
                            break;
                        }
                    }
                    if (coord["min"]) {
                        break;
                    }
                }
                if (coord["min"]) {   
                    this.setState({
                        isScreenshotSelectActive: true,
                        currFocCoordinates:coord
                    });
                }
            } else if (ev.keyCode === 37) {
                // Arrow left
                let coord = {...this.state.currFocCoordinates};
                for (let idx = coord?.hr??0; idx>= 0; idx--) {
                    let items = this.state.Timetracker[idx];
                    for (let i =  coord?.min && idx===this.state.currFocCoordinates["hr"]?coord?.min-10:60; i >= 0; i -= 10) {
                        if (items[i] && items[i].id != null) {
                            coord["hr"]= idx;
                            coord["min"] = i;
                            break;
                        }
                    }
                    if (coord["min"]!==this.state.currFocCoordinates["min"] || coord["hr"]!==this.state.currFocCoordinates["hr"]) {
                        break;
                    }
                }
                if (coord["min"]!==this.state.currFocCoordinates["min"] || coord["hr"]!==this.state.currFocCoordinates["hr"]) {
                    this.setState({
                        currFocCoordinates:coord
                    });
                }
            } else if (ev.keyCode === 38) {
                // Arrow up
                ev.preventDefault();
                let coord = {...this.state.currFocCoordinates};
                for (let idx = (coord?.hr??0)-1; idx>= 0; idx--) {
                    let items = this.state.Timetracker[idx];
                    if (items[coord?.min??10] && items[coord?.min??10].id != null) {
                        coord["hr"]= idx;
                        coord["min"] = coord?.min??10;
                        break;
                    }
                }
                if (coord["min"]!==this.state.currFocCoordinates["min"] || coord["hr"]!==this.state.currFocCoordinates["hr"]) {
                    this.setState({
                        currFocCoordinates:coord
                    });
                }
            } else if (ev.keyCode === 39) {
                // Arrow right
                let coord = {...this.state.currFocCoordinates};
                for (let idx = coord?.hr??0; idx< this.state.Timetracker.length; idx++) {
                    let items = this.state.Timetracker[idx];
                    for (let i =  coord?.min && idx===this.state.currFocCoordinates["hr"]?coord?.min+10:10; i <= 60; i += 10) {
                        if (items[i] && items[i].id != null) {
                            coord["hr"]= idx;
                            coord["min"] = i;
                            break;
                        }
                    }
                    if (coord["min"]!==this.state.currFocCoordinates["min"] || coord["hr"]!==this.state.currFocCoordinates["hr"]) {
                        break;
                    }
                }
                if (coord["min"]!==this.state.currFocCoordinates["min"] || coord["hr"]!==this.state.currFocCoordinates["hr"]) {
                    this.setState({
                        currFocCoordinates:coord
                    });
                }
            } else if (ev.keyCode === 40) {
                // Arrow down
                ev.preventDefault();
                let coord = {...this.state.currFocCoordinates};
                for (let idx = (coord?.hr??0)+1; idx< this.state.Timetracker.length; idx++) {
                    let items = this.state.Timetracker[idx];
                    if (items[coord?.min??10] && items[coord?.min??10].id != null) {
                        coord["hr"]= idx;
                        coord["min"] = coord?.min??10;
                        break;
                    }
                }
                if (coord["min"]!==this.state.currFocCoordinates["min"] || coord["hr"]!==this.state.currFocCoordinates["hr"]) {
                    this.setState({
                        currFocCoordinates:coord
                    });
                }
            } else if (ev.keyCode === 27) {
                // escape
                this.setState({
                    selectedSSid: [],
                    isScreenshotSelectActive: false,
                    currFocCoordinates:{}
                });
            } else if (ev.keyCode === 32) {
                // space
                ev.preventDefault();
                if(this.state.currFocCoordinates["min"]){
                    let item = this.state.Timetracker[this.state.currFocCoordinates['hr']][this.state.currFocCoordinates['min']];
                   if(item?.id){
                       let index = this.state.selectedSSid.findIndex((val)=>val===item.id);
                       if(index===-1){
                           this.setState({
                               selectedSSid: [...this.state.selectedSSid, item.id]
                           })
                        }else{
                            const ssids = [...this.state.selectedSSid]
                            ssids.splice(index,1);
                           this.setState({
                               selectedSSid: ssids
                           })
                       }
                   }
                }

            } else if (ev.keyCode === 13) {
                // enter
                this.setState({
                    openFromEnter: true,
                });
            }
        }
    };
    onTimeZoneChange = (timezone) => {
        let z = this.state.currentDate.toLocaleString("en-US", {
            timeZone: timezone.value,
        });
        let convertedDate = new Date(z);
        if (convertedDate.getDate() > this.state.currentDate.getDate()) {
            convertedDate.setDate(this.state.currentDate.getDate() + 1);
            // console.log(convertedDate,"I")
        } else if (convertedDate.getDate() < this.state.currentDate.getDate()) {
            convertedDate.setDate(this.state.currentDate.getDate() - 1);
            // console.log(convertedDate,"II")
        }

        // console.log(z,"zz",convertedDate,"z")

        this.setState({ selectedDate: new Date(z), selectedTimezone: timezone.value }, () => {
            // this.setState({ selectedDate: convertedDate ,selectedTimezone: timezone.value}, () => {
            if (this.state.SelectedTab === "screenshots") {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/?empname=${
                        this.state.employeeId
                    }&project=${this.state.projectname}&date=${moment(
                        this.state.selectedDate
                    ).format("YYYY-MM-DD")}&timezone=${timezone.value}`
                );
            } else if (this.state.SelectedTab === "cpuinformation") {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}//${this.state.cpuTab}/?empname=${
                        this.state.employeeId
                    }&date=${moment(this.state.selectedDate).format("YYYY-MM-DD")}&timezone=${
                        timezone.value
                    }`
                );
            } else {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/?empname=${
                        this.state.employeeId
                    }&date=${moment(this.state.selectedDate).format("YYYY-MM-DD")}&timezone=${
                        timezone.value
                    }`
                );
            }
            let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
            this.CustomReportList();
            this.handleScreenshotDateChange();
        });
    };

    HandleSelectTab = (e) => {
        this.setState({ SelectedTab: e });
        setTimeout(() => {
            if (e === "screenshots") {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/?empname=${
                        this.state.employeeId
                    }&project=${this.state.projectname}&date=${moment(
                        this.state.selectedDate
                    ).format("YYYY-MM-DD")}&timezone=${""}`
                );
                this.CustomReportList();
                this.handleScreenshotDateChange();
            } else if (e === "cpuinformation") {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/${this.state.cpuTab}/?empname=${
                        this.state.employeeId
                    }&date=${moment(this.state.selectedDate).format("YYYY-MM-DD")}&timezone=${""}`
                );
                if (localStorage.getItem("roleUser") === "admin" && this.state.employeeId) {
                    this.props
                        .fetchSystemInfo(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            this.state.employeeId
                        )
                        .then((res) => {
                            if (res.value.status === 200) {
                                this.setState({
                                    cpu_info: res.value.data,
                                    showChart: true,
                                    employeeId: this.state.employeeId,
                                    showProgramList: false,
                                });
                            } else if (res.value.status === 404) {
                                this.setState({ cpu_info: [] });
                            }
                        });
                } else if (this.state.employeeId) {
                    this.props
                        .getSystemInfoObserver(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            this.state.employeeId
                        )
                        .then((res) => {
                            if (res.value.status === 200) {
                                this.setState({
                                    cpu_info: res.value.data,
                                    showChart: true,
                                    employeeId: this.state.employeeId,
                                    showProgramList: false,
                                });
                            } else if (res.value.status === 404) {
                                this.setState({ cpu_info: [] });
                            }
                        });
                }
            } else if (e === "applications") {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/?empname=${
                        this.state.employeeId
                    }&date=${moment(this.state.selectedDate).format("YYYY-MM-DD")}&timezone=${""}`
                );
                if (this.state.employeeId) {
                    this.props
                        .GetAppLog(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            this.state.employeeId
                        )
                        .then((res) => {
                            if (res.value.status === 200) {
                                this.setState({
                                    applogs: res.value.data,
                                    apploguser: this.state.employeeId,
                                });
                            } else if (res.value.status === 404) {
                                this.setState({ applogs: [], apploguser: "" });
                            }
                        });
                }
            } else if (e === "activitylog") {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/?empname=${
                        this.state.employeeId
                    }&date=${moment(this.state.selectedDate).format("YYYY-MM-DD")}&timezone=${""}`
                );
                if (this.state.employeeId) {
                    this.props
                        .fetchAllUserURLs(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            this.state.employeeId
                        )
                        .then((res) => {
                            if (res.value.status === 200) {
                                this.setState({
                                    browserActivityData: res.value.data,
                                });
                            }
                        });
                }
            } else if (e === "location") {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/?empname=${
                        this.state.employeeId
                    }&date=${moment(this.state.selectedDate).format("YYYY-MM-DD")}&timezone=${""}`
                );
            }
        }, 200);
    };

    HandleMobileSelectTab = (e) => {
        this.setState({ SelectedTab: e.target.value });
        setTimeout(() => {
            if (e === "screenshots") {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/?empname=${
                        this.state.employeeId
                    }&project=${this.state.projectname}&date=${moment(
                        this.state.selectedDate
                    ).format("YYYY-MM-DD")}&timezone=${""}`
                );
                this.CustomReportList();
                this.handleScreenshotDateChange();
            } else if (e === "cpuinformation") {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/${this.state.cpuTab}/?empname=${
                        this.state.employeeId
                    }&date=${moment(this.state.selectedDate).format("YYYY-MM-DD")}&timezone=${""}`
                );
                if (localStorage.getItem("roleUser") === "admin" && this.state.employeeId) {
                    this.props
                        .fetchSystemInfo(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            this.state.employeeId
                        )
                        .then((res) => {
                            if (res.value.status === 200) {
                                this.setState({
                                    cpu_info: res.value.data,
                                    showChart: true,
                                    employeeId: this.state.employeeId,
                                    showProgramList: false,
                                });
                            } else if (res.value.status === 404) {
                                this.setState({ cpu_info: [] });
                            }
                        });
                } else if (this.state.employeeId) {
                    this.props
                        .getSystemInfoObserver(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            this.state.employeeId
                        )
                        .then((res) => {
                            if (res.value.status === 200) {
                                this.setState({
                                    cpu_info: res.value.data,
                                    showChart: true,
                                    employeeId: this.state.employeeId,
                                    showProgramList: false,
                                });
                            } else if (res.value.status === 404) {
                                this.setState({ cpu_info: [] });
                            }
                        });
                }

                this.props
                    .GetAppLogData(
                        moment(this.state.selectedDate).format("YYYY-MM-DD"),
                        this.state.employeeId
                    )
                    .then((res) => {
                        if (res.value.status === 200) {
                            this.setState({
                                applogsdata: res.value.data.message,
                                showProgramList: true,
                            });
                        } else if (res.value.status === 404) {
                            this.setState({
                                applogs: [],
                                showProgramList: true,
                            });
                        }
                    });
            } else if (e === "applications") {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/?empname=${
                        this.state.employeeId
                    }&date=${moment(this.state.selectedDate).format("YYYY-MM-DD")}&timezone=${""}`
                );
                if (this.state.employeeId) {
                    this.props
                        .GetAppLog(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            this.state.employeeId
                        )
                        .then((res) => {
                            if (res.value.status === 200) {
                                this.setState({
                                    applogs: res.value.data,
                                    apploguser: this.state.employeeId,
                                });
                            } else if (res.value.status === 404) {
                                this.setState({ applogs: [], apploguser: "" });
                            }
                        });
                }
            } else if (e === "activitylog") {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/?empname=${
                        this.state.employeeId
                    }&date=${moment(this.state.selectedDate).format("YYYY-MM-DD")}&timezone=${""}`
                );
                if (this.state.employeeId) {
                    this.props
                        .fetchAllUserURLs(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            this.state.employeeId
                        )
                        .then((res) => {
                            if (res.value.status === 200) {
                                this.setState({
                                    browserActivityData: res.value.data,
                                });
                            }
                        });
                }
            } else if (e === "location") {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/?empname=${
                        this.state.employeeId
                    }&date=${moment(this.state.selectedDate).format("YYYY-MM-DD")}&timezone=${""}`
                );
            }
        }, 200);
    };

    handleDateChange = (date) => {
        this.setState({ selectedDate: date }, () => {
            if (this.state.apploguser === "") {
            } else {
                let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                this.props.GetAppLog(date, this.state.apploguser);
            }
        });
    };

    onDataPointSelect = (endTimeHour, endTimeMin) => {
        this.props
            .GetAppLogData(
                moment(this.state.selectedDate).format("YYYY-MM-DD"),
                this.state.employeeId
            )
            .then((res) => {
                if (res.value.status === 200) {
                    let data = res.value.data.message.filter((applog) => {
                        let startTimeMin = endTimeMin;
                        let startTimeHour = endTimeHour;
                        if (endTimeMin === 0 || endTimeMin === 60) {
                            startTimeHour -= 1;
                            startTimeMin = 50;
                            endTimeMin = 60;
                        } else {
                            startTimeMin = endTimeMin - 10;
                        }
                        let applogTimeHour = parseInt(
                            applog.time[1] !== ":" ? applog.time.slice(0, 2) : applog.time[0]
                        );
                        let applogTimeMin = parseInt(
                            applog.time[1] !== ":"
                                ? applog.time[4] !== ":"
                                    ? applog.time.slice(3, 5)
                                    : applog.time[3]
                                : applog.time[3] !== ":"
                                ? applog.time.slice(2, 4)
                                : applog.time[2]
                        );
                        return (
                            applog.name !== " ''" &&
                            applogTimeHour >= startTimeHour &&
                            applogTimeHour <= endTimeHour &&
                            applogTimeMin >= startTimeMin &&
                            applogTimeMin <= endTimeMin
                        );
                    });
                    this.setState({
                        applogsdata: data,
                        showProgramList: true,
                    });
                } else if (res.value.status === 404) {
                    this.setState({ applogs: [], showProgramList: true });
                }
            });
    };
    onEmployeeAppLogChange = (e) => {
        this.setState(
            {
                apploguser: e.target.value,
                employeeId: e.target.value,
                showProgramList: false,
            },
            () => {
                if (this.state.apploguser === "") {
                } else {
                    window.history.pushState(
                        {},
                        null,
                        `/timetracker/${this.state.SelectedTab}/?empname=${
                            this.state.employeeId
                        }&date=${moment(this.state.selectedDate).format("YYYY-MM-DD")}&timezone=${
                            this.state.selectedTimezone
                        }`
                    );
                    let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                    this.props.GetAppLog(date, this.state.apploguser).then((res) => {
                        if (res.value.status === 200) {
                            this.setState({ applogs: res.value.data });
                        } else if (res.value.status === 404) {
                            this.setState({ applogs: [] });
                        }
                    });
                }
            }
        );
    };

    handleCPUInformationDateChange = (date) => {
        const storedTimezone = sessionStorage.getItem("timezone") || "";
        const selectedTimezone =
            this.state.selectedTimezone !== "" ? this.state.selectedTimezone : storedTimezone;
        this.setState({ selectedDate: date, selectedTimezone: selectedTimezone }, () => {
            if (this.state.employeeId === "") {
            } else {
                let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                if (localStorage.getItem("ro") === "Admin" && this.state.employeeId) {
                    this.props.fetchSystemInfo(date, this.state.employeeId).then((res) => {
                        if (res.value.status === 200) {
                            this.setState({ cpu_info: res.value.data });
                        } else if (res.value.status === 404) {
                            this.setState({ cpu_info: [] });
                        }
                    });
                } else if (this.state.employeeId) {
                    this.props.getSystemInfoObserver(date, this.state.employeeId).then((res) => {
                        if (res.value.status === 200) {
                            this.setState({ cpu_info: res.value.data });
                        } else if (res.value.status === 404) {
                            this.setState({ cpu_info: [] });
                        }
                    });
                }
            }
        });
    };
    componentDidUpdate(prevProps, prevState) {
        const storedTimezone = sessionStorage.getItem("timezone") || "";
        const selectedTimezone =
            this.state.selectedTimezone !== "" ? this.state.selectedTimezone : storedTimezone;
        const keys = this.state.Timetrackerkeys;
        const updatedTimetrackerkeys = keys.map((hour) => {
            const hourMoment = moment(hour, "HH:mm:ss").tz(selectedTimezone);
            return hourMoment?.format("HH");
        });
        if (JSON.stringify(updatedTimetrackerkeys) !== JSON.stringify(this.state.ttks)) {
            this.setState({
                ttks: updatedTimetrackerkeys,
            });
        }
    }
    handleScreenshotDateChange = (date) => {
        let empname =
            this.state.employeeList &&
            this.state.employeeList.filter((el) => el.user_id === this.state.employeeId);
        let isEmpObserver =
            empname === undefined || empname === "" || empname.length === 0
                ? false
                : empname[0].user_login_as.includes("from");
        // console.log('isEmpObserver IN DATE CHNG', isEmpObserver)
        const storedTimezone = sessionStorage.getItem("timezone") || "";
        const selectedTimezone =
            this.state.selectedTimezone !== "" ? this.state.selectedTimezone : storedTimezone;
        this.setState(
            {
                selectedTimezone: selectedTimezone,
                selectedSSid: [],
                isScreenshotSelectActive: false,
                currFocCoordinates: {},
            },
            () => {
                if (!date) {
                    if (!this.state.employeeId || this.state.employeeId === "undefined") {
                        this.setState({ employeeId: "" });
                    } else if (this.state.projectname === "") {
                        this.setState({ loadingScreenshotData: true });
                        this.CustomReportList();
                        let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                        const screenshotDate = moment.tz(date, selectedTimezone).toDate();
                        window.history.pushState(
                            {},
                            null,
                            `/timetracker/${this.state.SelectedTab}/?empname=${
                                this.state.employeeId
                            }&project=${this.state.projectname}&date=${moment(
                                screenshotDate
                            ).format("YYYY-MM-DD")}&timezone=${selectedTimezone}`
                        );

                        if (isEmpObserver === false || this.state.isEmpObserverState === false) {
                            this.props
                                .timeTrackerDataWithoutProject(date, this.state.employeeId)
                                .then((res) => {
                                    this.setState({
                                        screenshotkpis: res.value.data.track_report,
                                        Timetrackerkeys: Object.keys(res.value.data),
                                    });
                                    let response = res.value.data;
                                    const timezone = this.state.selectedTimezone;
                                    const newTimetrackerkeys = this.state.Timetrackerkeys.map(
                                        (hour) => {
                                            const hourMoment = moment(hour, "HH:mm:ss").tz(
                                                timezone
                                            );
                                            return hourMoment?.format("HH:mm:ss");
                                        }
                                    );
                                    const updatedTimetrackerkeys = newTimetrackerkeys.map(
                                        (hour) => {
                                            return hour?.split(":")[0];
                                        }
                                    );
                                    // const updated = newTimetrackerkeys.map((hour) => parseInt(hour.split(':')[0]));
                                    // const updated = newTimetrackerkeys.map((hour)=>{
                                    //     const ph=parseInt(hour.split(':')[0])
                                    //     return ph>12 ? ph-12 : ph===12?0 :ph;
                                    // })
                                    this.setState({
                                        ttks: updatedTimetrackerkeys,
                                    });
                                    delete response["track_report"];

                                    const hourlyPercantageArray = Object.values(res.value.data).map(
                                        (item, i) => {
                                            return item.hourly_percentage;
                                        }
                                    );
                                    let percentages = hourlyPercantageArray.map(
                                        (x) => Math.round(x * 100) / 100
                                    );
                                    let modifiedHours = Object.keys(response).map(Number);
                                    // let modifiedHours =this.state.selectedTimezone=="" || !this.state.selectedTimezone? Object.keys(response).map(Number):updated;
                                    let timingArray = modifiedHours.map((num) => {
                                        if (num > 12) {
                                            let hour = num - 12;
                                            return `${hour} PM`;
                                        } else if (num === 12) {
                                            return "12 PM";
                                        } else if (num === 0) {
                                            return "12 AM";
                                        } else {
                                            return `${num} AM`;
                                        }
                                    });
                                    const markerArr = [];
                                    for (let hour of modifiedHours) {
                                        const hoursData = response[hour];
                                        for (let min of Object.keys(hoursData).map(Number)) {
                                            const item = hoursData[min];
                                            if (item && item["longitude"] && item["latitude"]) {
                                                const tempMin = min === 60 ? 0 : min;
                                                const tempHour = min === 60 ? hour + 1 : hour;
                                                const time1 =
                                                    hour > 12
                                                        ? hour -
                                                          12 +
                                                          ":" +
                                                          (min - 10).toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " pm"
                                                        : hour +
                                                          ":" +
                                                          (min - 10).toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " am";
                                                const time2 =
                                                    tempHour > 12
                                                        ? tempHour -
                                                          12 +
                                                          ":" +
                                                          tempMin.toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " pm"
                                                        : tempHour +
                                                          ":" +
                                                          tempMin.toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " am";
                                                const address =
                                                    item["street"] +
                                                    ", " +
                                                    item["locality"] +
                                                    (item["administrativeArea"]
                                                        ? ", " + item["administrativeArea"]
                                                        : "") +
                                                    (item["country"] ? ", " + item["country"] : "");
                                                markerArr.push({
                                                    time: time1 + " to " + time2,
                                                    address: address,
                                                    lat: item["latitude"],
                                                    lng: item["longitude"],
                                                });
                                            }
                                        }
                                    }

                                    this.setState({
                                        percentages: percentages,
                                        timingArray: timingArray,
                                        markers: markerArr,
                                        showLocInfo:
                                            markerArr.length > 0 ? markerArr.at(-1)["time"] : "",
                                        Timetracker: Object.values(res.value.data),
                                        Timetrackerkeys: Object.keys(response),
                                        ttks: updatedTimetrackerkeys,
                                        loadingScreenshotData: false,
                                    });

                                    // console.log(this.state.ttks, this.state.Timetrackerkeys, this.state.percentages, this.state.timingArray, this.state.markers, this.state.Timetracker, "information")
                                });
                        } else if (
                            isEmpObserver === true ||
                            this.state.isEmpObserverState === true
                        ) {
                            this.props
                                .timeTrackerDataWithoutProjectObserver(date, this.state.employeeId)
                                .then((res) => {
                                    this.setState({
                                        screenshotkpis: res.value.data.track_report,
                                    });
                                    let response = res.value.data;
                                    const timezone = this.state.selectedTimezone;
                                    const newTimetrackerkeys = this.state.Timetrackerkeys.map(
                                        (hour) => {
                                            const hourMoment = moment(hour, "HH:mm:ss").tz(
                                                timezone
                                            );
                                            return hourMoment?.format("HH:mm:ss");
                                        }
                                    );

                                    const updatedTimetrackerkeys = newTimetrackerkeys.map(
                                        (hour) => {
                                            return hour?.split(":")[0];
                                        }
                                    );
                                    this.setState(
                                        {
                                            ttks: updatedTimetrackerkeys,
                                        },
                                        () => {
                                            console.log("updatedTimetrackerkeys:", this.state.ttks);
                                        }
                                    );
                                    delete response["track_report"];
                                    const hourlyPercantageArray = Object.values(res.value.data).map(
                                        (item, i) => {
                                            return item.hourly_percentage;
                                        }
                                    );
                                    let percentages = hourlyPercantageArray.map(
                                        (x) => Math.round(x * 100) / 100
                                    );
                                    let modifiedHours = Object.keys(response).map(Number);
                                    let timingArray = modifiedHours.map((num) => {
                                        if (num > 12) {
                                            let hour = num - 12;
                                            return `${hour} PM`;
                                        } else if (num === 12) {
                                            return "12 PM";
                                        } else if (num === 0) {
                                            return "12 AM";
                                        } else {
                                            return `${num} AM`;
                                        }
                                    });
                                    const markerArr = [];
                                    for (let hour of modifiedHours) {
                                        const hoursData = response[hour];
                                        for (let min of Object.keys(hoursData).map(Number)) {
                                            const item = hoursData[min];
                                            if (item && item["longitude"] && item["latitude"]) {
                                                const tempMin = min === 60 ? 0 : min;
                                                const tempHour = min === 60 ? hour + 1 : hour;
                                                const time1 =
                                                    hour > 12
                                                        ? hour -
                                                          12 +
                                                          ":" +
                                                          (min - 10).toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " pm"
                                                        : hour +
                                                          ":" +
                                                          (min - 10).toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " am";
                                                const time2 =
                                                    tempHour > 12
                                                        ? tempHour -
                                                          12 +
                                                          ":" +
                                                          tempMin.toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " pm"
                                                        : tempHour +
                                                          ":" +
                                                          tempMin.toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " am";
                                                const address =
                                                    item["street"] +
                                                    ", " +
                                                    item["locality"] +
                                                    (item["administrativeArea"]
                                                        ? ", " + item["administrativeArea"]
                                                        : "") +
                                                    (item["country"] ? ", " + item["country"] : "");
                                                markerArr.push({
                                                    time: time1 + " to " + time2,
                                                    address: address,
                                                    lat: item["latitude"],
                                                    lng: item["longitude"],
                                                });
                                            }
                                        }
                                    }
                                    this.setState({
                                        percentages: percentages,
                                        timingArray: timingArray,
                                        markers: markerArr,
                                        showLocInfo:
                                            markerArr.length > 0 ? markerArr.at(-1)["time"] : "",
                                        Timetracker: Object.values(res.value.data),
                                        Timetrackerkeys: Object.keys(response),
                                        // Timetrackerkeys: updatedTimetrackerkeys,
                                        ttks: updatedTimetrackerkeys,
                                        loadingScreenshotData: false,
                                    });
                                });
                        }
                    } else {
                        this.CustomReportList();
                        this.setState({ loadingScreenshotData: true });
                        let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                        window.history.pushState(
                            {},
                            null,
                            `/timetracker/${this.state.SelectedTab}/?empname=${
                                this.state.employeeId
                            }&project=${this.state.projectname}&date=${moment(
                                this.state.selectedDate
                            ).format("YYYY-MM-DD")}&timezone=${selectedTimezone}`
                        );

                        if (isEmpObserver === false || this.state.isEmpObserverState === false) {
                            this.props
                                .timeTrackerData(
                                    date,
                                    this.state.projectname,
                                    this.state.employeeId
                                )
                                .then((res) => {
                                    this.setState({
                                        screenshotkpis: res.value.data.track_report,
                                    });
                                    let response = res.value.data;
                                    const timezone = this.state.selectedTimezone;
                                    const newTimetrackerkeys = this.state.Timetrackerkeys.map(
                                        (hour) => {
                                            const hourMoment = moment(hour, "HH:mm:ss").tz(
                                                timezone
                                            );
                                            return hourMoment?.format("HH:mm:ss");
                                        }
                                    );

                                    const updatedTimetrackerkeys = newTimetrackerkeys.map(
                                        (hour) => {
                                            return hour?.split(":")[0];
                                        }
                                    );
                                    this.setState(
                                        {
                                            ttks: updatedTimetrackerkeys,
                                        },
                                        () => {
                                            console.log("updatedTimetrackerkeys:", this.state.ttks);
                                        }
                                    );
                                    delete response["track_report"];
                                    const hourlyPercantageArray = Object.values(res.value.data).map(
                                        (item, i) => {
                                            return item.hourly_percentage;
                                        }
                                    );
                                    let percentages = hourlyPercantageArray.map(
                                        (x) => Math.round(x * 100) / 100
                                    );
                                    let modifiedHours = Object.keys(response).map(Number);
                                    let timingArray = modifiedHours.map((num) => {
                                        if (num > 12) {
                                            let hour = num - 12;
                                            return `${hour} PM`;
                                        } else if (num === 12) {
                                            return "12 PM";
                                        } else if (num === 0) {
                                            return "12 AM";
                                        } else {
                                            return `${num} AM`;
                                        }
                                    });
                                    const markerArr = [];
                                    for (let hour of modifiedHours) {
                                        const hoursData = response[hour];
                                        for (let min of Object.keys(hoursData).map(Number)) {
                                            const item = hoursData[min];
                                            if (item && item["longitude"] && item["latitude"]) {
                                                const tempMin = min === 60 ? 0 : min;
                                                const tempHour = min === 60 ? hour + 1 : hour;
                                                const time1 =
                                                    hour > 12
                                                        ? hour -
                                                          12 +
                                                          ":" +
                                                          (min - 10).toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " pm"
                                                        : hour +
                                                          ":" +
                                                          (min - 10).toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " am";
                                                const time2 =
                                                    tempHour > 12
                                                        ? tempHour -
                                                          12 +
                                                          ":" +
                                                          tempMin.toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " pm"
                                                        : tempHour +
                                                          ":" +
                                                          tempMin.toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " am";
                                                const address =
                                                    item["street"] +
                                                    ", " +
                                                    item["locality"] +
                                                    (item["administrativeArea"]
                                                        ? ", " + item["administrativeArea"]
                                                        : "") +
                                                    (item["country"] ? ", " + item["country"] : "");
                                                markerArr.push({
                                                    time: time1 + " to " + time2,
                                                    address: address,
                                                    lat: item["latitude"],
                                                    lng: item["longitude"],
                                                });
                                            }
                                        }
                                    }
                                    this.setState({
                                        percentages: percentages,
                                        timingArray: timingArray,
                                        markers: markerArr,
                                        showLocInfo:
                                            markerArr.length > 0 ? markerArr.at(-1)["time"] : "",
                                        Timetracker: Object.values(res.value.data),
                                        Timetrackerkeys: Object.keys(response),
                                        // Timetrackerkeys: updatedTimetrackerkeys,
                                        ttks: updatedTimetrackerkeys,
                                        loadingScreenshotData: false,
                                    });
                                });
                        } else if (
                            isEmpObserver === true ||
                            this.state.isEmpObserverState === true
                        ) {
                            this.props
                                .timeTrackerDataObserver(
                                    date,
                                    this.state.projectname,
                                    this.state.employeeId
                                )
                                .then((res) => {
                                    this.setState({
                                        screenshotkpis: res.value.data.track_report,
                                    });
                                    let response = res.value.data;
                                    const timezone = this.state.selectedTimezone;
                                    const newTimetrackerkeys = this.state.Timetrackerkeys.map(
                                        (hour) => {
                                            const hourMoment = moment(hour, "HH:mm:ss").tz(
                                                timezone
                                            );
                                            return hourMoment?.format("HH:mm:ss");
                                        }
                                    );

                                    const updatedTimetrackerkeys = newTimetrackerkeys.map(
                                        (hour) => {
                                            return hour?.split(":")[0];
                                        }
                                    );
                                    this.setState(
                                        {
                                            ttks: updatedTimetrackerkeys,
                                        },
                                        () => {
                                            console.log("updatedTimetrackerkeys:", this.state.ttks);
                                        }
                                    );
                                    delete response["track_report"];
                                    const hourlyPercantageArray = Object.values(res.value.data).map(
                                        (item, i) => {
                                            return item.hourly_percentage;
                                        }
                                    );
                                    let percentages = hourlyPercantageArray.map(
                                        (x) => Math.round(x * 100) / 100
                                    );
                                    let modifiedHours = Object.keys(response).map(Number);
                                    let timingArray = modifiedHours.map((num) => {
                                        if (num > 12) {
                                            let hour = num - 12;
                                            return `${hour} PM`;
                                        } else if (num === 12) {
                                            return "12 PM";
                                        } else if (num === 0) {
                                            return "12 AM";
                                        } else {
                                            return `${num} AM`;
                                        }
                                    });
                                    const markerArr = [];
                                    for (let hour of modifiedHours) {
                                        const hoursData = response[hour];
                                        for (let min of Object.keys(hoursData).map(Number)) {
                                            const item = hoursData[min];
                                            if (item && item["longitude"] && item["latitude"]) {
                                                const tempMin = min === 60 ? 0 : min;
                                                const tempHour = min === 60 ? hour + 1 : hour;
                                                const time1 =
                                                    hour > 12
                                                        ? hour -
                                                          12 +
                                                          ":" +
                                                          (min - 10).toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " pm"
                                                        : hour +
                                                          ":" +
                                                          (min - 10).toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " am";
                                                const time2 =
                                                    tempHour > 12
                                                        ? tempHour -
                                                          12 +
                                                          ":" +
                                                          tempMin.toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " pm"
                                                        : tempHour +
                                                          ":" +
                                                          tempMin.toLocaleString("en-US", {
                                                              minimumIntegerDigits: 2,
                                                              useGrouping: false,
                                                          }) +
                                                          " am";
                                                const address =
                                                    item["street"] +
                                                    ", " +
                                                    item["locality"] +
                                                    (item["administrativeArea"]
                                                        ? ", " + item["administrativeArea"]
                                                        : "") +
                                                    (item["country"] ? ", " + item["country"] : "");
                                                markerArr.push({
                                                    time: time1 + " to " + time2,
                                                    address: address,
                                                    lat: item["latitude"],
                                                    lng: item["longitude"],
                                                });
                                            }
                                        }
                                    }
                                    this.setState({
                                        percentages: percentages,
                                        timingArray: timingArray,
                                        markers: markerArr,
                                        showLocInfo:
                                            markerArr.length > 0 ? markerArr.at(-1)["time"] : "",
                                        Timetracker: Object.values(res.value.data),
                                        Timetrackerkeys: Object.keys(response),
                                        // Timetrackerkeys: updatedTimetrackerkeys,
                                        ttks: updatedTimetrackerkeys,
                                        loadingScreenshotData: false,
                                    });
                                });
                        }
                    }
                } else {
                    this.setState({ selectedDate: date }, () => {
                        if (this.state.employeeId === "") {
                        } else if (this.state.projectname === "") {
                            this.CustomReportList();
                            window.history.pushState(
                                {},
                                null,
                                `/timetracker/${this.state.SelectedTab}/?empname=${
                                    this.state.employeeId
                                }&project=${this.state.projectname}&date=${moment(
                                    this.state.selectedDate
                                ).format("YYYY-MM-DD")}&timezone=${selectedTimezone}`
                            );
                            this.setState({ loadingScreenshotData: true });
                            let date = moment(this.state.selectedDate).format("YYYY-MM-DD");

                            if (
                                isEmpObserver === false ||
                                this.state.isEmpObserverState === false
                            ) {
                                this.props
                                    .timeTrackerDataWithoutProject(date, this.state.employeeId)
                                    .then((res) => {
                                        this.setState({
                                            screenshotkpis: res.value.data.track_report,
                                        });
                                        let response = res.value.data;
                                        const timezone = this.state.selectedTimezone;
                                        const newTimetrackerkeys = this.state.Timetrackerkeys.map(
                                            (hour) => {
                                                const hourMoment = moment(hour, "HH:mm:ss").tz(
                                                    timezone
                                                );
                                                return hourMoment?.format("HH:mm:ss");
                                            }
                                        );

                                        const updatedTimetrackerkeys = newTimetrackerkeys.map(
                                            (hour) => {
                                                return hour?.split(":")[0];
                                            }
                                        );
                                        this.setState(
                                            {
                                                ttks: updatedTimetrackerkeys,
                                            },
                                            () => {
                                                console.log(
                                                    "updatedTimetrackerkeys:",
                                                    this.state.ttks
                                                );
                                            }
                                        );
                                        delete response["track_report"];
                                        const hourlyPercantageArray = Object.values(
                                            res.value.data
                                        ).map((item, i) => {
                                            return item.hourly_percentage;
                                        });
                                        let percentages = hourlyPercantageArray.map(
                                            (x) => Math.round(x * 100) / 100
                                        );
                                        let modifiedHours = Object.keys(response).map(Number);
                                        let timingArray = modifiedHours.map((num) => {
                                            if (num > 12) {
                                                let hour = num - 12;
                                                return `${hour} PM`;
                                            } else if (num === 12) {
                                                return "12 PM";
                                            } else if (num === 0) {
                                                return "12 AM";
                                            } else {
                                                return `${num} AM`;
                                            }
                                        });
                                        const markerArr = [];
                                        for (let hour of modifiedHours) {
                                            const hoursData = response[hour];
                                            for (let min of Object.keys(hoursData).map(Number)) {
                                                const item = hoursData[min];
                                                if (item && item["longitude"] && item["latitude"]) {
                                                    const tempMin = min === 60 ? 0 : min;
                                                    const tempHour = min === 60 ? hour + 1 : hour;
                                                    const time1 =
                                                        hour > 12
                                                            ? hour -
                                                              12 +
                                                              ":" +
                                                              (min - 10).toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " pm"
                                                            : hour +
                                                              ":" +
                                                              (min - 10).toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " am";
                                                    const time2 =
                                                        tempHour > 12
                                                            ? tempHour -
                                                              12 +
                                                              ":" +
                                                              tempMin.toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " pm"
                                                            : tempHour +
                                                              ":" +
                                                              tempMin.toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " am";
                                                    const address =
                                                        item["street"] +
                                                        ", " +
                                                        item["locality"] +
                                                        (item["administrativeArea"]
                                                            ? ", " + item["administrativeArea"]
                                                            : "") +
                                                        (item["country"]
                                                            ? ", " + item["country"]
                                                            : "");
                                                    markerArr.push({
                                                        time: time1 + " to " + time2,
                                                        address: address,
                                                        lat: item["latitude"],
                                                        lng: item["longitude"],
                                                    });
                                                }
                                            }
                                        }
                                        this.setState({
                                            percentages: percentages,
                                            timingArray: timingArray,
                                            markers: markerArr,
                                            showLocInfo:
                                                markerArr.length > 0
                                                    ? markerArr.at(-1)["time"]
                                                    : "",
                                            Timetracker: Object.values(res.value.data),
                                            Timetrackerkeys: Object.keys(response),
                                            // Timetrackerkeys: updatedTimetrackerkeys,
                                            ttks: updatedTimetrackerkeys,
                                            loadingScreenshotData: false,
                                        });
                                    });
                            } else if (
                                isEmpObserver === true ||
                                this.state.isEmpObserverState === true
                            ) {
                                this.props
                                    .timeTrackerDataWithoutProjectObserver(
                                        date,
                                        this.state.employeeId
                                    )
                                    .then((res) => {
                                        this.setState({
                                            screenshotkpis: res.value.data.track_report,
                                        });
                                        let response = res.value.data;
                                        const timezone = this.state.selectedTimezone;
                                        const newTimetrackerkeys = this.state.Timetrackerkeys.map(
                                            (hour) => {
                                                const hourMoment = moment(hour, "HH:mm:ss").tz(
                                                    timezone
                                                );
                                                return hourMoment?.format("HH:mm:ss");
                                            }
                                        );

                                        const updatedTimetrackerkeys = newTimetrackerkeys.map(
                                            (hour) => {
                                                return hour?.split(":")[0];
                                            }
                                        );
                                        this.setState(
                                            {
                                                ttks: updatedTimetrackerkeys,
                                            },
                                            () => {
                                                console.log(
                                                    "updatedTimetrackerkeys:",
                                                    this.state.ttks
                                                );
                                            }
                                        );
                                        delete response["track_report"];
                                        const hourlyPercantageArray = Object.values(
                                            res.value.data
                                        ).map((item, i) => {
                                            return item.hourly_percentage;
                                        });
                                        let percentages = hourlyPercantageArray.map(
                                            (x) => Math.round(x * 100) / 100
                                        );
                                        let modifiedHours = Object.keys(response).map(Number);
                                        let timingArray = modifiedHours.map((num) => {
                                            if (num > 12) {
                                                let hour = num - 12;
                                                return `${hour} PM`;
                                            } else if (num === 12) {
                                                return "12 PM";
                                            } else if (num === 0) {
                                                return "12 AM";
                                            } else {
                                                return `${num} AM`;
                                            }
                                        });
                                        const markerArr = [];
                                        for (let hour of modifiedHours) {
                                            const hoursData = response[hour];
                                            for (let min of Object.keys(hoursData).map(Number)) {
                                                const item = hoursData[min];
                                                if (item && item["longitude"] && item["latitude"]) {
                                                    const tempMin = min === 60 ? 0 : min;
                                                    const tempHour = min === 60 ? hour + 1 : hour;
                                                    const time1 =
                                                        hour > 12
                                                            ? hour -
                                                              12 +
                                                              ":" +
                                                              (min - 10).toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " pm"
                                                            : hour +
                                                              ":" +
                                                              (min - 10).toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " am";
                                                    const time2 =
                                                        tempHour > 12
                                                            ? tempHour -
                                                              12 +
                                                              ":" +
                                                              tempMin.toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " pm"
                                                            : tempHour +
                                                              ":" +
                                                              tempMin.toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " am";
                                                    const address =
                                                        item["street"] +
                                                        ", " +
                                                        item["locality"] +
                                                        (item["administrativeArea"]
                                                            ? ", " + item["administrativeArea"]
                                                            : "") +
                                                        (item["country"]
                                                            ? ", " + item["country"]
                                                            : "");
                                                    markerArr.push({
                                                        time: time1 + " to " + time2,
                                                        address: address,
                                                        lat: item["latitude"],
                                                        lng: item["longitude"],
                                                    });
                                                }
                                            }
                                        }
                                        this.setState({
                                            percentages: percentages,
                                            timingArray: timingArray,
                                            markers: markerArr,
                                            showLocInfo:
                                                markerArr.length > 0
                                                    ? markerArr.at(-1)["time"]
                                                    : "",
                                            Timetracker: Object.values(res.value.data),
                                            Timetrackerkeys: Object.keys(response),
                                            // Timetrackerkeys: updatedTimetrackerkeys,
                                            ttks: updatedTimetrackerkeys,
                                            loadingScreenshotData: false,
                                        });
                                    });
                            }
                        } else {
                            this.CustomReportList();
                            window.history.pushState(
                                {},
                                null,
                                `/timetracker/${this.state.SelectedTab}/?empname=${
                                    this.state.employeeId
                                }&project=${this.state.projectname}&date=${moment(
                                    this.state.selectedDate
                                ).format("YYYY-MM-DD")}&timezone=${selectedTimezone}`
                            );
                            this.setState({ loadingScreenshotData: true });
                            let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                            if (
                                isEmpObserver === false ||
                                this.state.isEmpObserverState === false
                            ) {
                                this.props
                                    .timeTrackerData(
                                        date,
                                        this.state.projectname,
                                        this.state.employeeId
                                    )
                                    .then((res) => {
                                        this.setState({
                                            screenshotkpis: res.value.data.track_report,
                                        });
                                        let response = res.value.data;
                                        const timezone = this.state.selectedTimezone;
                                        const newTimetrackerkeys = this.state.Timetrackerkeys.map(
                                            (hour) => {
                                                const hourMoment = moment(hour, "HH:mm:ss").tz(
                                                    timezone
                                                );
                                                return hourMoment?.format("HH:mm:ss");
                                            }
                                        );

                                        const updatedTimetrackerkeys = newTimetrackerkeys.map(
                                            (hour) => {
                                                return hour?.split(":")[0];
                                            }
                                        );
                                        this.setState(
                                            {
                                                ttks: updatedTimetrackerkeys,
                                            },
                                            () => {
                                                console.log(
                                                    "updatedTimetrackerkeys:",
                                                    this.state.ttks
                                                );
                                            }
                                        );
                                        delete response["track_report"];
                                        const hourlyPercantageArray = Object.values(
                                            res.value.data
                                        ).map((item, i) => {
                                            return item.hourly_percentage;
                                        });
                                        let percentages = hourlyPercantageArray.map(
                                            (x) => Math.round(x * 100) / 100
                                        );
                                        let modifiedHours = Object.keys(response).map(Number);
                                        let timingArray = modifiedHours.map((num) => {
                                            if (num > 12) {
                                                let hour = num - 12;
                                                return `${hour} PM`;
                                            } else if (num === 12) {
                                                return "12 PM";
                                            } else if (num === 0) {
                                                return "12 AM";
                                            } else {
                                                return `${num} AM`;
                                            }
                                        });
                                        const markerArr = [];
                                        for (let hour of modifiedHours) {
                                            const hoursData = response[hour];
                                            for (let min of Object.keys(hoursData).map(Number)) {
                                                const item = hoursData[min];
                                                if (item && item["longitude"] && item["latitude"]) {
                                                    const tempMin = min === 60 ? 0 : min;
                                                    const tempHour = min === 60 ? hour + 1 : hour;
                                                    const time1 =
                                                        hour > 12
                                                            ? hour -
                                                              12 +
                                                              ":" +
                                                              (min - 10).toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " pm"
                                                            : hour +
                                                              ":" +
                                                              (min - 10).toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " am";
                                                    const time2 =
                                                        tempHour > 12
                                                            ? tempHour -
                                                              12 +
                                                              ":" +
                                                              tempMin.toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " pm"
                                                            : tempHour +
                                                              ":" +
                                                              tempMin.toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " am";
                                                    const address =
                                                        item["street"] +
                                                        ", " +
                                                        item["locality"] +
                                                        (item["administrativeArea"]
                                                            ? ", " + item["administrativeArea"]
                                                            : "") +
                                                        (item["country"]
                                                            ? ", " + item["country"]
                                                            : "");
                                                    markerArr.push({
                                                        time: time1 + " to " + time2,
                                                        address: address,
                                                        lat: item["latitude"],
                                                        lng: item["longitude"],
                                                    });
                                                }
                                            }
                                        }
                                        this.setState({
                                            percentages: percentages,
                                            timingArray: timingArray,
                                            markers: markerArr,
                                            showLocInfo:
                                                markerArr.length > 0
                                                    ? markerArr.at(-1)["time"]
                                                    : "",
                                            Timetracker: Object.values(res.value.data),
                                            Timetrackerkeys: Object.keys(response),
                                            // Timetrackerkeys: updatedTimetrackerkeys,
                                            ttks: updatedTimetrackerkeys,
                                            loadingScreenshotData: false,
                                        });
                                    });
                            } else if (
                                isEmpObserver === true ||
                                this.state.isEmpObserverState === true
                            ) {
                                this.props
                                    .timeTrackerDataObserver(
                                        date,
                                        this.state.projectname,
                                        this.state.employeeId
                                    )
                                    .then((res) => {
                                        this.setState({
                                            screenshotkpis: res.value.data.track_report,
                                        });
                                        let response = res.value.data;
                                        const timezone = this.state.selectedTimezone;
                                        const newTimetrackerkeys = this.state.Timetrackerkeys.map(
                                            (hour) => {
                                                const hourMoment = moment(hour, "HH:mm:ss").tz(
                                                    timezone
                                                );
                                                return hourMoment?.format("HH:mm:ss");
                                            }
                                        );

                                        const updatedTimetrackerkeys = newTimetrackerkeys.map(
                                            (hour) => {
                                                return hour?.split(":")[0];
                                            }
                                        );
                                        this.setState(
                                            {
                                                ttks: updatedTimetrackerkeys,
                                            },
                                            () => {
                                                console.log(
                                                    "updatedTimetrackerkeys:",
                                                    this.state.ttks
                                                );
                                            }
                                        );
                                        delete response["track_report"];
                                        const hourlyPercantageArray = Object.values(
                                            res.value.data
                                        ).map((item, i) => {
                                            return item.hourly_percentage;
                                        });
                                        let percentages = hourlyPercantageArray.map(
                                            (x) => Math.round(x * 100) / 100
                                        );
                                        let modifiedHours = Object.keys(response).map(Number);
                                        let timingArray = modifiedHours.map((num) => {
                                            if (num > 12) {
                                                let hour = num - 12;
                                                return `${hour} PM`;
                                            } else if (num === 12) {
                                                return "12 PM";
                                            } else if (num === 0) {
                                                return "12 AM";
                                            } else {
                                                return `${num} AM`;
                                            }
                                        });
                                        const markerArr = [];
                                        for (let hour of modifiedHours) {
                                            const hoursData = response[hour];
                                            for (let min of Object.keys(hoursData).map(Number)) {
                                                const item = hoursData[min];
                                                if (item && item["longitude"] && item["latitude"]) {
                                                    const tempMin = min === 60 ? 0 : min;
                                                    const tempHour = min === 60 ? hour + 1 : hour;
                                                    const time1 =
                                                        hour > 12
                                                            ? hour -
                                                              12 +
                                                              ":" +
                                                              (min - 10).toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " pm"
                                                            : hour +
                                                              ":" +
                                                              (min - 10).toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " am";
                                                    const time2 =
                                                        tempHour > 12
                                                            ? tempHour -
                                                              12 +
                                                              ":" +
                                                              tempMin.toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " pm"
                                                            : tempHour +
                                                              ":" +
                                                              tempMin.toLocaleString("en-US", {
                                                                  minimumIntegerDigits: 2,
                                                                  useGrouping: false,
                                                              }) +
                                                              " am";
                                                    const address =
                                                        item["street"] +
                                                        ", " +
                                                        item["locality"] +
                                                        (item["administrativeArea"]
                                                            ? ", " + item["administrativeArea"]
                                                            : "") +
                                                        (item["country"]
                                                            ? ", " + item["country"]
                                                            : "");
                                                    markerArr.push({
                                                        time: time1 + " to " + time2,
                                                        address: address,
                                                        lat: item["latitude"],
                                                        lng: item["longitude"],
                                                    });
                                                }
                                            }
                                        }
                                        this.setState({
                                            percentages: percentages,
                                            timingArray: timingArray,
                                            markers: markerArr,
                                            showLocInfo:
                                                markerArr.length > 0
                                                    ? markerArr.at(-1)["time"]
                                                    : "",
                                            Timetracker: Object.values(res.value.data),
                                            Timetrackerkeys: Object.keys(response),
                                            // Timetrackerkeys: updatedTimetrackerkeys,
                                            ttks: updatedTimetrackerkeys,
                                            loadingScreenshotData: false,
                                        });
                                    });
                            }
                        }
                    });
                    this.setState({
                        showcaseEntriesList: [],
                        employeeMood: "",
                        // userName: empname[0].user_first_name + " " + empname[0].user_last_name,
                        userName:
                            empname && empname.length > 0
                                ? empname[0].user_first_name + " " + empname[0].user_last_name
                                : "",
                    });
                    this.props.getUserDetail(
                        moment(date).format("YYYY-MM-DD"),
                        this.state.employeeId
                    );
                    this.props
                        .getShowcaseEntries(
                            moment(date).format("YYYY-MM-DD"),
                            `user&user_id=${this.state.employeeId}`
                        )
                        .then((res) => {
                            if (res.value.status === 200 && !res.value.data.message) {
                                this.setState({
                                    showcaseEntriesList: res.value.data.data,
                                });
                            }
                        });

                    this.props
                        .getEmployeeMoods(
                            `${moment(date).format("YYYY-MM-DD")}&user_id=${this.state.employeeId}`
                        )
                        .then((res) => {
                            if (
                                res.value.status === 200 &&
                                res.value.data.data !== "no data present"
                            ) {
                                this.setState({
                                    employeeMood: res.value.data.data,
                                });
                            }
                        });
                }
            }
        );
    };

    onProjectScreenshotChange = (e) => {
        let empname = this.state.employeeList.filter((el) => el.user_id === this.state.employeeId);
        let isEmpObserver = empname[0].user_login_as.includes("from");
        const storedTimezone = sessionStorage.getItem("timezone") || "";
        const selectedTimezone =
            this.state.selectedTimezone !== "" ? this.state.selectedTimezone : storedTimezone;
        this.setState({ projectname: e.target.value, selectedTimezone: selectedTimezone }, () => {
            this.setState({ loadingScreenshotData: true });
            window.history.pushState(
                {},
                null,
                `/timetracker/${this.state.SelectedTab}/?empname=${this.state.employeeId}&project=${
                    this.state.projectname
                }&date=${moment(this.state.selectedDate).format("YYYY-MM-DD")}&timezone=${
                    this.state.selectedTimezone
                }`
            );

            if (isEmpObserver === false) {
                if (this.state.projectname === "") {
                    this.CustomReportList();
                    let date = moment(this.state.selectedDate).format("YYYY-MM-DD");

                    this.props
                        .timeTrackerDataWithoutProject(date, this.state.employeeId)
                        .then((res) => {
                            this.setState({
                                screenshotkpis: res.value.data.track_report,
                            });
                            let response = res.value.data;
                            const timezone = this.state.selectedTimezone;
                            const newTimetrackerkeys = this.state.Timetrackerkeys.map((hour) => {
                                const hourMoment = moment(hour, "HH:mm:ss").tz(timezone);
                                return hourMoment?.format("HH:mm:ss");
                            });

                            const updatedTimetrackerkeys = newTimetrackerkeys.map((hour) => {
                                return hour?.split(":")[0];
                            });
                            delete response["track_report"];
                            const hourlyPercantageArray = Object.values(res.value.data).map(
                                (item, i) => {
                                    return item.hourly_percentage;
                                }
                            );
                            let percentages = hourlyPercantageArray.map(
                                (x) => Math.round(x * 100) / 100
                            );
                            let modifiedHours = Object.keys(response).map(Number);
                            let timingArray = modifiedHours.map((num) => {
                                if (num > 12) {
                                    let hour = num - 12;
                                    return `${hour} PM`;
                                } else if (num === 12) {
                                    return "12 PM";
                                } else if (num === 0) {
                                    return "12 AM";
                                } else {
                                    return `${num} AM`;
                                }
                            });
                            this.setState({
                                percentages: percentages,
                                timingArray: timingArray,
                                Timetracker: Object.values(res.value.data),
                                Timetrackerkeys: Object.keys(response),
                                ttks: updatedTimetrackerkeys,
                                loadingScreenshotData: false,
                            });
                        });
                } else {
                    this.CustomReportList();
                    let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                    this.setState({ loadingScreenshotData: true });
                    this.props
                        .timeTrackerData(date, this.state.projectname, this.state.employeeId)
                        .then((res) => {
                            this.setState({
                                screenshotkpis: res.value.data.track_report,
                            });
                            let response = res.value.data;
                            const timezone = this.state.selectedTimezone;
                            const newTimetrackerkeys = this.state.Timetrackerkeys.map((hour) => {
                                const hourMoment = moment(hour, "HH:mm:ss").tz(timezone);
                                return hourMoment?.format("HH:mm:ss");
                            });

                            const updatedTimetrackerkeys = newTimetrackerkeys.map((hour) => {
                                return hour?.split(":")[0];
                            });
                            delete response["track_report"];
                            const hourlyPercantageArray = Object.values(res.value.data).map(
                                (item, i) => {
                                    return item.hourly_percentage;
                                }
                            );
                            let percentages = hourlyPercantageArray.map(
                                (x) => Math.round(x * 100) / 100
                            );
                            let modifiedHours = Object.keys(response).map(Number);
                            let timingArray = modifiedHours.map((num) => {
                                if (num > 12) {
                                    let hour = num - 12;
                                    return `${hour} PM`;
                                } else if (num === 12) {
                                    return "12 PM";
                                } else if (num === 0) {
                                    return "12 AM";
                                } else {
                                    return `${num} AM`;
                                }
                            });
                            this.setState({
                                percentages: percentages,
                                timingArray: timingArray,
                                Timetracker: Object.values(res.value.data),
                                Timetrackerkeys: Object.keys(response),
                                ttks: updatedTimetrackerkeys,
                                loadingScreenshotData: false,
                            });
                        });
                }
            } else if (isEmpObserver === true) {
                if (this.state.projectname === "") {
                    this.CustomReportList();
                    let date = moment(this.state.selectedDate).format("YYYY-MM-DD");

                    this.props
                        .timeTrackerDataWithoutProjectObserver(date, this.state.employeeId)
                        .then((res) => {
                            this.setState({
                                screenshotkpis: res.value.data.track_report,
                            });
                            let response = res.value.data;
                            const timezone = this.state.selectedTimezone;
                            const newTimetrackerkeys = this.state.Timetrackerkeys.map((hour) => {
                                const hourMoment = moment(hour, "HH:mm:ss").tz(timezone);
                                return hourMoment?.format("HH:mm:ss");
                            });

                            const updatedTimetrackerkeys = newTimetrackerkeys.map((hour) => {
                                return hour?.split(":")[0];
                            });
                            delete response["track_report"];
                            const hourlyPercantageArray = Object.values(res.value.data).map(
                                (item, i) => {
                                    return item.hourly_percentage;
                                }
                            );
                            let percentages = hourlyPercantageArray.map(
                                (x) => Math.round(x * 100) / 100
                            );
                            let modifiedHours = Object.keys(response).map(Number);
                            let timingArray = modifiedHours.map((num) => {
                                if (num > 12) {
                                    let hour = num - 12;
                                    return `${hour} PM`;
                                } else if (num === 12) {
                                    return "12 PM";
                                } else if (num === 0) {
                                    return "12 AM";
                                } else {
                                    return `${num} AM`;
                                }
                            });
                            this.setState({
                                percentages: percentages,
                                timingArray: timingArray,
                                Timetracker: Object.values(res.value.data),
                                Timetrackerkeys: Object.keys(response),
                                ttks: updatedTimetrackerkeys,
                                loadingScreenshotData: false,
                            });
                        });
                } else {
                    this.CustomReportList();
                    let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                    this.setState({ loadingScreenshotData: true });
                    this.props
                        .timeTrackerDataObserver(
                            date,
                            this.state.projectname,
                            this.state.employeeId
                        )
                        .then((res) => {
                            this.setState({
                                screenshotkpis: res.value.data.track_report,
                            });
                            let response = res.value.data;
                            const timezone = this.state.selectedTimezone;
                            const newTimetrackerkeys = this.state.Timetrackerkeys.map((hour) => {
                                const hourMoment = moment(hour, "HH:mm:ss").tz(timezone);
                                return hourMoment?.format("HH:mm:ss");
                            });

                            const updatedTimetrackerkeys = newTimetrackerkeys.map((hour) => {
                                return hour?.split(":")[0];
                            });
                            delete response["track_report"];
                            const hourlyPercantageArray = Object.values(res.value.data).map(
                                (item, i) => {
                                    return item.hourly_percentage;
                                }
                            );
                            let percentages = hourlyPercantageArray.map(
                                (x) => Math.round(x * 100) / 100
                            );
                            let modifiedHours = Object.keys(response).map(Number);
                            let timingArray = modifiedHours.map((num) => {
                                if (num > 12) {
                                    let hour = num - 12;
                                    return `${hour} PM`;
                                } else if (num === 12) {
                                    return "12 PM";
                                } else if (num === 0) {
                                    return "12 AM";
                                } else {
                                    return `${num} AM`;
                                }
                            });
                            this.setState({
                                percentages: percentages,
                                timingArray: timingArray,
                                Timetracker: Object.values(res.value.data),
                                Timetrackerkeys: Object.keys(response),
                                ttks: updatedTimetrackerkeys,
                                loadingScreenshotData: false,
                            });
                        });
                }
            }
            // }
        });
    };

    onEmployeeScreenshotChange = (e) => {
        // console.log('employeeId', e.target.id)
        let empname = this.state.employeeList.filter((el) => el.user_id === e.target.value);
        let isEmpObserver = empname[0].user_login_as.includes("from");
        // console.log('isEmpObserver', isEmpObserver);
        const storedTimezone = sessionStorage.getItem("timezone") || "";
        const selectedTimezone =
            this.state.selectedTimezone !== "" ? this.state.selectedTimezone : storedTimezone;
        this.setState(
            {
                employeeId: e.target.value,
                selectedTimezone: selectedTimezone,
                selectedSSid: [],
                isScreenshotSelectActive: false,
                currFocCoordinates: {},
            },
            () => {
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/?empname=${
                        this.state.employeeId
                    }&project=${this.state.projectname}&date=${moment(
                        this.state.selectedDate
                    ).format("YYYY-MM-DD")}&timezone=${this.state.selectedTimezone}`
                );
                if (!this.state.employeeId) {
                } else if (this.state.projectname === "") {
                    // this.CustomReportList();
                    this.setState({ loadingScreenshotData: true });
                    let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                    this.CustomReportList();
                    if (isEmpObserver === false) {
                        this.props
                            .timeTrackerDataWithoutProject(date, this.state.employeeId)
                            .then((res) => {
                                this.setState({
                                    screenshotkpis: res.value.data.track_report,
                                });
                                let response = res.value.data;
                                const timezone = this.state.selectedTimezone;
                                const newTimetrackerkeys = this.state.Timetrackerkeys.map(
                                    (hour) => {
                                        const hourMoment = moment(hour, "HH:mm:ss").tz(timezone);
                                        return hourMoment?.format("HH:mm:ss");
                                    }
                                );

                                const updatedTimetrackerkeys = newTimetrackerkeys.map((hour) => {
                                    return hour?.split(":")[0];
                                });
                                // const updatedTimetrackerkeys=this.state.Timetrackerkeys.map((hour)=>{
                                //     const utcTime=date
                                //     utcTime.setUTCHours(hour)
                                //     return utcTime.toLocaleString('en-US', { hour: 'numeric', timeZone: this.state.selectedTimezone })
                                // })
                                delete response["track_report"];
                                const hourlyPercantageArray = Object.values(res.value.data).map(
                                    (item, i) => {
                                        return item.hourly_percentage;
                                    }
                                );
                                let percentages = hourlyPercantageArray.map(
                                    (x) => Math.round(x * 100) / 100
                                );
                                let modifiedHours = Object.keys(response).map(Number);
                                let timingArray = modifiedHours.map((num) => {
                                    if (num > 12) {
                                        let hour = num - 12;
                                        return `${hour} PM`;
                                    } else if (num === 12) {
                                        return "12 PM";
                                    } else if (num === 0) {
                                        return "12 AM";
                                    } else {
                                        return `${num} AM`;
                                    }
                                });
                                const markerArr = [];
                                for (let hour of modifiedHours) {
                                    const hoursData = response[hour];
                                    for (let min of Object.keys(hoursData).map(Number)) {
                                        const item = hoursData[min];
                                        if (item && item["longitude"] && item["latitude"]) {
                                            const tempMin = min === 60 ? 0 : min;
                                            const tempHour = min === 60 ? hour + 1 : hour;
                                            const time1 =
                                                hour > 12
                                                    ? hour -
                                                      12 +
                                                      ":" +
                                                      (min - 10).toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " pm"
                                                    : hour +
                                                      ":" +
                                                      (min - 10).toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " am";
                                            const time2 =
                                                tempHour > 12
                                                    ? tempHour -
                                                      12 +
                                                      ":" +
                                                      tempMin.toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " pm"
                                                    : tempHour +
                                                      ":" +
                                                      tempMin.toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " am";
                                            const address =
                                                item["street"] +
                                                ", " +
                                                item["locality"] +
                                                (item["administrativeArea"]
                                                    ? ", " + item["administrativeArea"]
                                                    : "") +
                                                (item["country"] ? ", " + item["country"] : "");
                                            markerArr.push({
                                                time: time1 + " to " + time2,
                                                address: address,
                                                lat: item["latitude"],
                                                lng: item["longitude"],
                                            });
                                        }
                                    }
                                }
                                this.setState({
                                    percentages: percentages,
                                    timingArray: timingArray,
                                    markers: markerArr,
                                    showLocInfo:
                                        markerArr.length > 0 ? markerArr.at(-1)["time"] : "",
                                    Timetracker: Object.values(res.value.data),
                                    Timetrackerkeys: Object.keys(response),
                                    ttks: updatedTimetrackerkeys,
                                    loadingScreenshotData: false,
                                });
                            });
                    } else if (isEmpObserver === true) {
                        this.props
                            .timeTrackerDataWithoutProjectObserver(date, this.state.employeeId)
                            .then((res) => {
                                this.setState({
                                    screenshotkpis: res.value.data.track_report,
                                });
                                let response = res.value.data;
                                delete response["track_report"];
                                const timezone = this.state.selectedTimezone;
                                const newTimetrackerkeys = this.state.Timetrackerkeys.map(
                                    (hour) => {
                                        const hourMoment = moment(hour, "HH:mm:ss").tz(timezone);
                                        return hourMoment?.format("HH:mm:ss");
                                    }
                                );

                                const updatedTimetrackerkeys = newTimetrackerkeys.map((hour) => {
                                    return hour?.split(":")[0];
                                });
                                const hourlyPercantageArray = Object.values(res.value.data).map(
                                    (item, i) => {
                                        return item.hourly_percentage;
                                    }
                                );
                                let percentages = hourlyPercantageArray.map(
                                    (x) => Math.round(x * 100) / 100
                                );
                                let modifiedHours = Object.keys(response).map(Number);
                                let timingArray = modifiedHours.map((num) => {
                                    if (num > 12) {
                                        let hour = num - 12;
                                        return `${hour} PM`;
                                    } else if (num === 12) {
                                        return "12 PM";
                                    } else if (num === 0) {
                                        return "12 AM";
                                    } else {
                                        return `${num} AM`;
                                    }
                                });
                                const markerArr = [];
                                for (let hour of modifiedHours) {
                                    const hoursData = response[hour];
                                    for (let min of Object.keys(hoursData).map(Number)) {
                                        const item = hoursData[min];
                                        if (item && item["longitude"] && item["latitude"]) {
                                            const tempMin = min === 60 ? 0 : min;
                                            const tempHour = min === 60 ? hour + 1 : hour;
                                            const time1 =
                                                hour > 12
                                                    ? hour -
                                                      12 +
                                                      ":" +
                                                      (min - 10).toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " pm"
                                                    : hour +
                                                      ":" +
                                                      (min - 10).toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " am";
                                            const time2 =
                                                tempHour > 12
                                                    ? tempHour -
                                                      12 +
                                                      ":" +
                                                      tempMin.toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " pm"
                                                    : tempHour +
                                                      ":" +
                                                      tempMin.toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " am";
                                            const address =
                                                item["street"] +
                                                ", " +
                                                item["locality"] +
                                                (item["administrativeArea"]
                                                    ? ", " + item["administrativeArea"]
                                                    : "") +
                                                (item["country"] ? ", " + item["country"] : "");
                                            markerArr.push({
                                                time: time1 + " to " + time2,
                                                address: address,
                                                lat: item["latitude"],
                                                lng: item["longitude"],
                                            });
                                        }
                                    }
                                }
                                this.setState({
                                    percentages: percentages,
                                    timingArray: timingArray,
                                    markers: markerArr,
                                    showLocInfo:
                                        markerArr.length > 0 ? markerArr.at(-1)["time"] : "",
                                    Timetracker: Object.values(res.value.data),
                                    Timetrackerkeys: Object.keys(response),
                                    ttks: updatedTimetrackerkeys,
                                    loadingScreenshotData: false,
                                });
                            });
                    }
                } else {
                    let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                    // this.CustomReportList()
                    this.setState({ loadingScreenshotData: true });
                    if (isEmpObserver === false) {
                        this.props
                            .timeTrackerData(date, this.state.projectname, this.state.employeeId)
                            .then((res) => {
                                this.setState({
                                    screenshotkpis: res.value.data.track_report,
                                });
                                let response = res.value.data;
                                const timezone = this.state.selectedTimezone;
                                const newTimetrackerkeys = this.state.Timetrackerkeys.map(
                                    (hour) => {
                                        const hourMoment = moment(hour, "HH:mm:ss").tz(timezone);
                                        return hourMoment?.format("HH:mm:ss");
                                    }
                                );

                                const updatedTimetrackerkeys = newTimetrackerkeys.map((hour) => {
                                    return hour?.split(":")[0];
                                });
                                delete response["track_report"];
                                const hourlyPercantageArray = Object.values(res.value.data).map(
                                    (item, i) => {
                                        return item.hourly_percentage;
                                    }
                                );
                                let percentages = hourlyPercantageArray.map(
                                    (x) => Math.round(x * 100) / 100
                                );
                                let modifiedHours = Object.keys(response).map(Number);
                                let timingArray = modifiedHours.map((num) => {
                                    if (num > 12) {
                                        let hour = num - 12;
                                        return `${hour} PM`;
                                    } else if (num === 12) {
                                        return "12 PM";
                                    } else if (num === 0) {
                                        return "12 AM";
                                    } else {
                                        return `${num} AM`;
                                    }
                                });
                                const markerArr = [];
                                for (let hour of modifiedHours) {
                                    const hoursData = response[hour];
                                    for (let min of Object.keys(hoursData).map(Number)) {
                                        const item = hoursData[min];
                                        if (item && item["longitude"] && item["latitude"]) {
                                            const tempMin = min === 60 ? 0 : min;
                                            const tempHour = min === 60 ? hour + 1 : hour;
                                            const time1 =
                                                hour > 12
                                                    ? hour -
                                                      12 +
                                                      ":" +
                                                      (min - 10).toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " pm"
                                                    : hour +
                                                      ":" +
                                                      (min - 10).toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " am";
                                            const time2 =
                                                tempHour > 12
                                                    ? tempHour -
                                                      12 +
                                                      ":" +
                                                      tempMin.toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " pm"
                                                    : tempHour +
                                                      ":" +
                                                      tempMin.toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " am";
                                            const address =
                                                item["street"] +
                                                ", " +
                                                item["locality"] +
                                                (item["administrativeArea"]
                                                    ? ", " + item["administrativeArea"]
                                                    : "") +
                                                (item["country"] ? ", " + item["country"] : "");
                                            markerArr.push({
                                                time: time1 + " to " + time2,
                                                address: address,
                                                lat: item["latitude"],
                                                lng: item["longitude"],
                                            });
                                        }
                                    }
                                }
                                this.setState({
                                    percentages: percentages,
                                    timingArray: timingArray,
                                    markers: markerArr,
                                    showLocInfo:
                                        markerArr.length > 0 ? markerArr.at(-1)["time"] : "",
                                    Timetracker: Object.values(res.value.data),
                                    Timetrackerkeys: Object.keys(response),
                                    ttks: updatedTimetrackerkeys,
                                    loadingScreenshotData: false,
                                });
                            });
                    } else if (isEmpObserver === true) {
                        this.props
                            .timeTrackerDataObserver(
                                date,
                                this.state.projectname,
                                this.state.employeeId
                            )
                            .then((res) => {
                                this.setState({
                                    screenshotkpis: res.value.data.track_report,
                                });
                                let response = res.value.data;
                                const timezone = this.state.selectedTimezone;
                                const newTimetrackerkeys = this.state.Timetrackerkeys.map(
                                    (hour) => {
                                        const hourMoment = moment(hour, "HH:mm:ss").tz(timezone);
                                        return hourMoment?.format("HH:mm:ss");
                                    }
                                );

                                const updatedTimetrackerkeys = newTimetrackerkeys.map((hour) => {
                                    return hour?.split(":")[0];
                                });
                                delete response["track_report"];
                                const hourlyPercantageArray = Object.values(res.value.data).map(
                                    (item, i) => {
                                        return item.hourly_percentage;
                                    }
                                );
                                let percentages = hourlyPercantageArray.map(
                                    (x) => Math.round(x * 100) / 100
                                );
                                let modifiedHours = Object.keys(response).map(Number);
                                let timingArray = modifiedHours.map((num) => {
                                    if (num > 12) {
                                        let hour = num - 12;
                                        return `${hour} PM`;
                                    } else if (num === 12) {
                                        return "12 PM";
                                    } else if (num === 0) {
                                        return "12 AM";
                                    } else {
                                        return `${num} AM`;
                                    }
                                });
                                const markerArr = [];
                                for (let hour of modifiedHours) {
                                    const hoursData = response[hour];
                                    for (let min of Object.keys(hoursData).map(Number)) {
                                        const item = hoursData[min];
                                        if (item && item["longitude"] && item["latitude"]) {
                                            const tempMin = min === 60 ? 0 : min;
                                            const tempHour = min === 60 ? hour + 1 : hour;
                                            const time1 =
                                                hour > 12
                                                    ? hour -
                                                      12 +
                                                      ":" +
                                                      (min - 10).toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " pm"
                                                    : hour +
                                                      ":" +
                                                      (min - 10).toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " am";
                                            const time2 =
                                                tempHour > 12
                                                    ? tempHour -
                                                      12 +
                                                      ":" +
                                                      tempMin.toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " pm"
                                                    : tempHour +
                                                      ":" +
                                                      tempMin.toLocaleString("en-US", {
                                                          minimumIntegerDigits: 2,
                                                          useGrouping: false,
                                                      }) +
                                                      " am";
                                            const address =
                                                item["street"] +
                                                ", " +
                                                item["locality"] +
                                                (item["administrativeArea"]
                                                    ? ", " + item["administrativeArea"]
                                                    : "") +
                                                (item["country"] ? ", " + item["country"] : "");
                                            markerArr.push({
                                                time: time1 + " to " + time2,
                                                address: address,
                                                lat: item["latitude"],
                                                lng: item["longitude"],
                                            });
                                        }
                                    }
                                }
                                this.setState({
                                    percentages: percentages,
                                    timingArray: timingArray,
                                    markers: markerArr,
                                    showLocInfo:
                                        markerArr.length > 0 ? markerArr.at(-1)["time"] : "",
                                    Timetracker: Object.values(res.value.data),
                                    Timetrackerkeys: Object.keys(response),
                                    ttks: updatedTimetrackerkeys,
                                    loadingScreenshotData: false,
                                });
                            });
                    }
                }

                if (e.target.value !== "") {
                    this.setState({
                        userName: empname[0].user_first_name + " " + empname[0].user_last_name,
                        showcaseEntriesList: [],
                        employeeMood: "",
                    });
                    this.props.getUserDetail(
                        moment(this.state.selectedDate).format("YYYY-MM-DD"),
                        e.target.value
                    );
                    this.props
                        .getShowcaseEntries(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            `user&user_id=${e.target.value}`
                        )
                        .then((res) => {
                            if (res.value.status === 200 && !res.value.data.message) {
                                this.setState({
                                    showcaseEntriesList: res.value.data.data,
                                });
                            }
                        });
                    this.props
                        .getEmployeeMoods(
                            `${moment(this.state.selectedDate).format("YYYY-MM-DD")}&user_id=${
                                e.target.value
                            }`
                        )
                        .then((res) => {
                            if (
                                res.value.status === 200 &&
                                res.value.data.data !== "no data present"
                            ) {
                                this.setState({
                                    employeeMood: res.value.data.data,
                                });
                            }
                        });
                }
            }
        );
        this.setState({ selectedSSid: [] });
    };

    onEmployeeCpuInformationChange = (e, employees) => {
        const storedTimezone = sessionStorage.getItem("timezone") || "";
        const selectedTimezone =
            this.state.selectedTimezone !== "" ? this.state.selectedTimezone : storedTimezone;
        this.setState(
            {
                employeeId: e.target.value,
                showProgramList: false,
                selectedTimezone: selectedTimezone,
            },
            () => {
                // let emp = employees.find((emp) => emp.user_id === e.target.value);
                window.history.pushState(
                    {},
                    null,
                    `/timetracker/${this.state.SelectedTab}/${this.state.cpuTab}/?empname=${
                        e.target.value
                    }&date=${moment(this.state.selectedDate).format("YYYY-MM-DD")}&timezone=${
                        this.state.selectedTimezone
                    }`
                );
                if (this.state.employeeId === "") {
                    this.setState({ showChart: false });
                } else {
                    let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                    if (localStorage.getItem("ro") === "Admin" && this.state.employeeId) {
                        this.props.fetchSystemInfo(date, this.state.employeeId).then((res) => {
                            this.setState({
                                cpu_info: res.value.data,
                                showChart: true,
                            });
                        });
                        // this.setState({showChart: true})
                    } else if (this.state.employeeId) {
                        this.props
                            .getSystemInfoObserver(date, this.state.employeeId)
                            .then((res) => {
                                this.setState({
                                    cpu_info: res.value.data,
                                    showChart: true,
                                });
                            });
                    }
                }
            }
        );
    };

    lastWeekGraph = () => {
        let current = this.state.lastDate;
        let last_date = new Date(current.getTime() - 7 * 24 * 60 * 60 * 1000);
        const { displayName: username, Projectname } = this.state;
        let fromdate = moment(last_date).format("YYYY-MM-DD");
        let todate = moment(current).format("YYYY-MM-DD");
        this.props.weeklyChartActivity(fromdate, this.state.employeeId).then((res) => {
            this.setState({
                weeklychartData: res.value.data,
                currentDate: current,
                lastDate: last_date,
            });
        });

        this.props.customReportWeekglyGraphdata(fromdate, todate).then((result) => {
            let response = result.value;
            if (this.state.projectname.length !== 0) {
                this.setState({ weeksLoading: false });
                let username = null;

                let employees = this.props.projects && this.props.projects.AllEmployees;

                for (var i = 0; i < employees.length; i++) {
                    if (employees[i].user_login_as === this.state.employeeId) {
                        username = employees[i];
                    }
                }

                let projectDetails = result.value.data.all_project_data;
                let project = projectDetails.find(
                    (pro) => pro.project_name === this.state.projectname
                );

                const userFullName = `${username.user_first_name} ${username.user_last_name}`;
                let z = project.users.filter((y) => {
                    return y.user === userFullName;
                });
                let toBeconvertedPercVal = z.map((hou) => Math.round(hou.time_logged / 60));
                this.setState({
                    allProjectData: response.data.all_project_data,
                    weekGraphActIndex: toBeconvertedPercVal.map(Number),
                    weekGraphDate: z.map((hou) => hou.date),
                    currentDate: current,
                    lastDate: last_date,
                });
            } else {
                let username = null;

                let employees = this.props.projects && this.props.projects.AllEmployees;

                for (var i = 0; i < employees.length; i++) {
                    if (employees[i].user_login_as === this.state.employeeId) {
                        username = employees[i];
                    }
                }
                let filtered = response.data.all_project_data.map((each) => each.users);
                const userFullName = `${username.user_first_name} ${username.user_last_name}`;
                let res = filtered.map((each) => {
                    let x = each.filter((hou) => {
                        if (hou.user === userFullName) {
                            return Math.round(hou.time_logged / 60);
                        }
                    });
                    return x;
                });

                let dateandTime = {};
                let x = res.map((e) => {
                    if (e.length !== 0) {
                        let res = e.map((eng) => {
                            if (eng.date.toString() in dateandTime) {
                                dateandTime[eng.date] = dateandTime[eng.date] + eng.time_logged;
                            } else {
                                dateandTime[eng.date] = eng.time_logged;
                            }
                        });
                    }
                });

                let date = Object.keys(dateandTime);
                let hours = Object.values(dateandTime).map((hou) => Math.round(hou / 60));

                this.setState({
                    allProjectData: response.data.all_project_data,
                    weekGraphDate: date,
                    weekGraphActIndex: hours,
                    currentDate: current,
                    lastDate: last_date,
                });
            }
        });
    };

    CustomReportList = () => {
        const { lastDate, currentDate } = this.state;
        let fromdate = moment(lastDate).format("YYYY-MM-DD");
        let todate = moment(currentDate).format("YYYY-MM-DD");

        if (this.state.employeeId && this.state.employeeId.length !== 0) {
            this.props.weeklyChartActivity(todate, this.state.employeeId).then((res) => {
                this.setState({ weeklychartData: res.value.data });
            });

            this.props.customReportWeekglyGraphdata(fromdate, todate).then((res) => {
                if (
                    this.state.projectname === undefined ? "" : this.state.projectname.length != 0
                ) {
                    let username = null;

                    let employees = this.props.projects && this.props.projects.AllEmployees;

                    for (var i = 0; i < employees.length; i++) {
                        if (
                            employees[i].user_login_as.replace(/\s/g, "").toLowerCase() ===
                            this.state.employeeId.replace(/\s/g, "").toLowerCase()
                        ) {
                            username = employees[i];
                        }
                    }

                    let projectDetails = res.value.data.all_project_data;
                    let project = projectDetails.find(
                        (pro) => pro.project_name === this.state.projectname
                    );

                    let userName =
                        username && `${username.user_first_name} ${username.user_last_name}`;
                    let z = project.users.filter((y) => {
                        if (y.user === userName) {
                            return y;
                        }
                    });

                    let toBeconvertedPercVal = z.map((hou) => Math.round(hou.time_logged / 60));
                    this.setState({
                        weekGraphActIndex: toBeconvertedPercVal.map(Number),
                        weekGraphDate: z.map((hou) => hou.date),
                    });
                } else {
                    let username = null;

                    let employees = this.props.projects && this.props.projects.AllEmployees;

                    for (var j = 0; j < employees.length; j++) {
                        if (employees[j].user_id === this.state.employeeId) {
                            username = employees[j];
                        }
                    }
                    let filtered = res.value.data.all_project_data.map((each) => each.users);
                    const userName = `${username && username.user_first_name} ${
                        username && username.user_last_name
                    }`;
                    let ress = filtered.map((each) => {
                        let x = each.filter((hou) => {
                            if (hou.user === userName) {
                                return Math.round(hou.time_logged / 60);
                            }
                        });
                        return x;
                    });

                    let dateandTime = {};
                    let x = ress.map((e) => {
                        if (e.length !== 0) {
                            let ress = e.map((eng) => {
                                if (eng.date.toString() in dateandTime) {
                                    dateandTime[eng.date] = dateandTime[eng.date] + eng.time_logged;
                                } else {
                                    dateandTime[eng.date] = eng.time_logged;
                                }
                            });
                        }
                    });
                    let date = Object.keys(dateandTime);
                    let hours = Object.values(dateandTime).map((hou) => Math.round(hou / 60));

                    this.setState({
                        weekGraphDate: date,
                        weekGraphActIndex: hours,
                    });
                }
            });
        }
    };

    nextWeekGraph = () => {
        let lastDate = this.state.currentDate;
        let current = new Date(lastDate.getTime() + 7 * 24 * 60 * 60 * 1000);
        const { displayName: username, Projectname } = this.state;
        let fromdate = moment(lastDate).format("YYYY-MM-DD");
        let todate = moment(current).format("YYYY-MM-DD");
        this.props.weeklyChartActivity(todate, this.state.employeeId).then((res) => {
            this.setState({
                weeklychartData: res.value.data,
                currentDate: current,
                lastDate: lastDate,
            });
        });

        this.props.customReportWeekglyGraphdata(fromdate, todate).then((result) => {
            let response = result.value;
            if (this.state.projectname.length !== 0) {
                this.setState({ weeksLoading: false });

                let username = null;
                let employees = this.props.projects && this.props.projects.AllEmployees;

                for (var i = 0; i < employees.length; i++) {
                    if (employees[i].user_login_as === this.state.employeeId) {
                        username = employees[i];
                    }
                }

                let projectDetails = result.value.data.all_project_data;
                let project = projectDetails.find(
                    (pro) => pro.project_name === this.state.projectname
                );

                const userFullName = `${username.user_first_name} ${username.user_last_name}`;
                let z = project.users.filter((y) => {
                    return y.user === userFullName;
                });
                let toBeconvertedPercVal = z.map((hou) => Math.round(hou.time_logged / 60));
                this.setState({
                    allProjectData: response.data.all_project_data,
                    weekGraphActIndex: toBeconvertedPercVal.map(Number),
                    weekGraphDate: z.map((hou) => hou.date),
                    currentDate: current,
                    lastDate: lastDate,
                });
            } else {
                this.setState({ weeksLoading: false });
                let filtered = response.data.all_project_data.map((each) => each.users);
                const userFullName = `${this.state.displayName.user_first_name} ${this.state.displayName.user_last_name}`;
                let res = filtered.map((each) => {
                    let x = each.filter((hou) => {
                        if (hou.user === userFullName) {
                            return Math.round(hou.time_logged / 60);
                        }
                    });
                    return x;
                });

                let dateandTime = {};
                let x = res.map((e) => {
                    if (e.length !== 0) {
                        let res = e.map((eng) => {
                            if (eng.date.toString() in dateandTime) {
                                dateandTime[eng.date] = dateandTime[eng.date] + eng.time_logged;
                            } else {
                                dateandTime[eng.date] = eng.time_logged;
                            }
                        });
                    }
                });

                let date = Object.keys(dateandTime);
                let hours = Object.values(dateandTime).map((hou) => Math.round(hou / 60));

                this.setState({
                    allProjectData: response.data.all_project_data,
                    weekGraphDate: date,
                    weekGraphActIndex: hours,
                    currentDate: current,
                    lastDate: lastDate,
                });
            }
        });
    };

    progressActivity = (items) => {
        let activity = [];
        let lengthImage = items && items.length > 0 ? items.length : 0;
        lengthImage = lengthImage < 5 ? lengthImage : 5;
        for (let i = 0; i < lengthImage; i++) {
            activity.push(
                <div key={i}>
                    <Grid className="row" style={{ borderRadius: "2px" }}>
                        <img alt="" src={items[i]} style={{ width: "24px", height: "24px" }} />
                    </Grid>
                </div>
            );
        }

        return activity;
    };

    IdleTime = (time, min, time_min, text) => {
        // let row = [require("../../assets/idelTime.png")];
        return (
            <Grid item xs={12} sm={5} md={2}>
                <div style={{ width: "163px", height: "140px" }} className="bg-white">
                    <div className="idel_time">
                        <div className="idel_text">{text}</div>
                    </div>
                    <div
                        className="row"
                        style={{
                            justifyContent: "space-between",
                            padding: "0px 10px 0px 20px",
                        }}>
                        {time_min === "60" ? (
                            <div style={{ margin: "2px", fontSize: "14px" }}>
                                {time}:{min} - {parseInt(time) + 1}:00
                            </div>
                        ) : (
                            <div style={{ margin: "2px", fontSize: "14px" }}>
                                {time}:{min} - {time}:{time_min}
                            </div>
                        )}
                    </div>
                </div>
            </Grid>
        );
    };

    render() {
        const { t } = this.props;
        let userName = localStorage.getItem("userName");
        const emojiList = [
            <span>"&#128545;" {t("timeTracker.angry")}</span>,
            <span>"&#128532;" {t("timeTracker.sad")}</span>,
            <span>"&#128528;" {t("timeTracker.neutral")}</span>,
            <span>"&#128578;" {t("timeTracker.positive")}</span>,
            <span>"&#128512;" {t("timeTracker.happy")}</span>,
        ];
        const {
            applicationsactivities,
            percentages,
            timingArray,
            weekGraphActIndex,
            weekGraphDate,
        } = this.state;
        let employees = this.props.projects && this.props.projects.AllEmployees;
        let userDetails = this.props.timetracker.userDetail && this.props.timetracker.userDetail;
        // let applogs = this.props.timetracker && this.props.timetracker.applogList;
        let activeprojects =
            this.props.projects &&
            this.props.projects.ActiveProjects &&
            this.props.projects.ActiveProjects.response_data;
        let TimeTrackerData = this.props.timetracker && this.props.timetracker.TimeTrackerData;
        const timezone = this.state.selectedTimezone;
        let systemInfo = this.props.timetracker && this.props.timetracker.systemInfo;

        let EmployeeData =
            employees &&
            employees.map((el) => ({
                name: el.user_login_as.includes("from")
                    ? el.user_first_name + " " + el.user_last_name + " (obs)"
                    : el.user_first_name + " " + el.user_last_name,
                id: el.user_id,
            }));

        EmployeeData =
            EmployeeData && [...EmployeeData]?.sort((a, b) => a.name.localeCompare(b.name));

        let screenshotEmployeeData =
            employees &&
            employees.map((el) => ({
                name: el.user_login_as.includes("from")
                    ? el.user_first_name + " " + el.user_last_name + " (obs)"
                    : el.user_first_name + " " + el.user_last_name,
                id: el.user_id,
            }));

        screenshotEmployeeData =
            screenshotEmployeeData &&
            [...screenshotEmployeeData]?.sort((a, b) => a.name.localeCompare(b.name));

        let CPUEmployeeData =
            employees &&
            employees.map((el) => ({
                name: el.user_first_name + " " + el.user_last_name,
                id: el.user_id,
            }));

        CPUEmployeeData =
            CPUEmployeeData && [...CPUEmployeeData]?.sort((a, b) => a.name.localeCompare(b.name));

        let ProjectData =
            activeprojects &&
            activeprojects.map((el) => ({
                name: el.project_name,
                id: el.project_name,
            }));

        // console.log("project data", ProjectData)

        ProjectData = ProjectData && [...ProjectData]?.sort((a, b) => a.name.localeCompare(b.name));

        let screenshotkpis = this.state.screenshotkpis;
        let timearr = [];
        let cpuGraphData = [];
        let totalMemory = [];
        let consumedMemoryArr = [];
        let uploadSpeedarr = [];
        let downloadSpeedarr = [];
        let freeDiskSpace = [];
        let usedDiskSpace = [];

        const arraySysInfo =
            systemInfo &&
            systemInfo.length !== 0 &&
            typeof systemInfo !== "string" &&
            systemInfo.map((el, index) => {
                if (el === "") {
                    console.log("empty");
                } else {
                    cpuGraphData.push(
                        el.cpu_per === "" || el.cpu_per === undefined || el.cpu_per === null
                            ? 0
                            : parseInt(el.cpu_per)
                    );
                }
                if (el === "") {
                    console.log("Error fetching data");
                } else {
                    timearr.push(el.end_time.slice(0, 5));
                }
                if (el === "") {
                    console.log("Error fetching data");
                } else {
                    totalMemory.push(
                        el.total_memory === "" ||
                            el.total_memory === undefined ||
                            el.total_memory === null
                            ? 0
                            : parseInt(el.total_memory / (1000 * 1000 * 1000).toFixed(2))
                    );
                }
                if (el === "") {
                    console.log("Error fetching data");
                } else {
                    consumedMemoryArr.push(
                        el.consumed_memory === "" ||
                            el.consumed_memory === undefined ||
                            el.consumed_memory === null
                            ? 0
                            : parseInt(el.consumed_memory / (1000 * 1000 * 1000).toFixed(2))
                    );
                }
                if (el === "") {
                    console.log("Error fetching data");
                } else {
                    uploadSpeedarr.push(
                        el.uploadspeed === "" ||
                            el.uploadspeed === undefined ||
                            el.uploadspeed === null
                            ? 0
                            : parseInt(el.uploadspeed)
                    );
                }
                if (el === "") {
                    console.log("Error fetching data");
                } else {
                    downloadSpeedarr.push(
                        el.downloadspeed === "" ||
                            el.downloadspeed === undefined ||
                            el.downloadspeed === null
                            ? 0
                            : parseInt(el.downloadspeed)
                    );
                }
                if (el === "") {
                    console.log("Error fetching data");
                } else {
                    freeDiskSpace.push(
                        el.free === "" || el.free === undefined || el.free === null
                            ? 0
                            : parseInt(el.free / (1000 * 1000 * 1000))
                    );
                }
                if (el === "") {
                    console.log("Error fetching data");
                } else {
                    usedDiskSpace.push(
                        el.free === "" ||
                            el.free === undefined ||
                            el.free === null ||
                            el.all === "" ||
                            el.all === undefined ||
                            el.all === null
                            ? 0
                            : parseInt((el.all - el.free) / (1000 * 1000 * 1000))
                    );
                }
            });
        let weekGraphActIndexConverted = weekGraphActIndex.map((x) => Math.round(x * 100) / 100);
        let newWeekGraphActivityDate =
            this.state.weeklychartData && this.state.weeklychartData.map((x) => x.date);
        let newWeeGraphActivityHours =
            this.state.weeklychartData &&
            this.state.weeklychartData.map((x) => Math.round(x.duration / 60));
        let newWeekGraphActIndexConverted =
            this.state.weeklychartData &&
            this.state.weeklychartData.map((x) => Math.round(x.activity_index * 100) / 100);
        // console.log(this.state.Timetrackerkeys,this.state.ttks,"ttks,time")
        const storedTimezone = sessionStorage.getItem("timezone") || "";
        const selectedTimezone =
            this.state.selectedTimezone !== "" ? this.state.selectedTimezone : storedTimezone;
        const keys = this.state.Timetrackerkeys;
        const updatedTimetrackerkeys = keys.map((hour) => {
            const hourMoment = moment(hour, "HH:mm:ss").tz(selectedTimezone);
            return hourMoment?.format("HH");
        });
        const formattedTimeArr = timearr?.map((time) => {
            try {
                if (time) {
                    const newtimearr = moment(time, "HH:mm").tz(timezone);
                    if (newtimearr?.isValid()) {
                        return newtimearr?.format("HH:mm");
                    } else {
                        // console.error('Invalid time:', time);
                        return null;
                    }
                }
                return null;
            } catch (error) {
                // console.error('Error formatting time:', error);
                return null;
            }
        });

        // console.log(formattedTimeArr);
        const smoothScroll = (target) => {
            var scrollContainer = target;
            do {
                //find scroll container
                scrollContainer = scrollContainer.parentNode;
                if (!scrollContainer) return;
                scrollContainer.scrollTop += 1;
            } while (scrollContainer.scrollTop === 0);
            var targetY = 0;
            do {
                //find the top of target relatively to the container
                if (target === scrollContainer) break;
                targetY += target.offsetTop;
            } while ((target = target.offsetParent));
            window.scroll = function (c, a, b, i) {
                i++;
                if (i > 27) return;
                c.scrollTop = a + ((b - a) / 30) * i;
                setTimeout(function () {
                    window.scroll(c, a, b, i);
                }, 20);
            };
            // start scrolling
            window.scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
        };

        const hourlyChart = {
            chart: {
                type: "column",
                height: 250,
                style: {
                    fontFamily: "Lato",
                    fontSize: "15px",
                },
            },
            title: {
                text: "",
            },
            plotOptions: {
                series: {
                    cursor: "pointer",
                    point: {
                        events: {
                            click: function () {
                                let [hours, AMorPM] = this.category.split(" ");
                                if (hours === "12") {
                                    hours = "0";
                                }
                                if (AMorPM === "PM") {
                                    hours = parseInt(hours, 10) + 12;
                                }
                                smoothScroll(document.getElementById(hours));
                            },
                        },
                    },
                },
                column: {
                    zones: [
                        {
                            value: 25,
                            color: "#FF0404",
                        },
                        {
                            value: 50,
                            color: "#B1B1B1",
                        },
                        {
                            color: "#684D94",
                        },
                    ],
                    pointPadding: 0.1,
                    borderWidth: 0,
                },
            },
            xAxis: {
                // categories: timingArray,
                categories:
                    this.state.selectedTimezone !== ""
                        ? timingArray.map((time) =>
                              moment(time, "hA").tz(this.state.selectedTimezone).format("hA")
                          )
                        : timingArray,
                crosshair: true,
            },
            yAxis: [
                {
                    labels: {
                        format: "{value} %",
                    },
                    title: {
                        text: t("hourlyPercentage"),
                    },
                    max: 100,
                },
            ],
            tooltip: {
                valueSuffix: " %",
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    showInLegend: false,
                    name: t("hourlyPercentage"),
                    data: percentages,
                },
            ],
        };
        const cpu_info_Chart = {
            chart: {
                type: "spline",
                height: 350,
                style: {
                    fontFamily: "Lato",
                    fontSize: "15px",
                },
            },
            title: {
                text: "",
            },
            plotOptions: {
                series: {
                    cursor: "pointer",
                    allowPointSelect: true,
                },
                column: {
                    zones: [
                        {
                            value: 0,
                            color: "#684D94",
                        },
                    ],
                    pointPadding: 0.1,
                    borderWidth: 0,
                },
            },
            xAxis: {
                // categories: timearr,
                categories: this.state.selectedTimezone ? formattedTimeArr : timearr,
                crosshair: true,
            },
            yAxis: [
                {
                    title: {
                        text: "",
                    },
                },
            ],
            tooltip: {
                valueSuffix: "%",
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    showInLegend: true,
                    color: "#684D94",
                    name: "CPU",
                    point: {
                        events: {
                            select: (e) => {
                                let endTimeHour = parseInt(e.target.category.slice(0, 2));
                                let endTimeMin = parseInt(e.target.category.slice(3, 5));
                                this.onDataPointSelect(endTimeHour, endTimeMin);
                            },
                        },
                    },
                    data: cpuGraphData,
                },
            ],
        };

        const memoryChart = {
            chart: {
                type: "spline",
                height: 350,
                style: {
                    fontFamily: "Lato",
                    fontSize: "15px",
                },
            },
            title: {
                text: "",
            },
            plotOptions: {
                series: {
                    cursor: "pointer",
                    allowPointSelect: true,
                },
                column: {
                    zones: [
                        {
                            value: 0,
                            color: "#684D94",
                        },
                    ],
                    pointPadding: 0.1,
                    borderWidth: 0,
                },
            },
            xAxis: {
                // categories: timearr,
                categories: this.state.selectedTimezone ? formattedTimeArr : timearr,
                crosshair: true,
            },
            yAxis: [
                {
                    title: {
                        text: "",
                    },
                },
            ],
            tooltip: {
                valueSuffix: "gb",
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    showInLegend: true,
                    color: "#684D94",
                    name: "Consumed Memory",
                    point: {
                        events: {
                            select: (e) => {
                                let endTimeHour = parseInt(e.target.category.slice(0, 2));
                                let endTimeMin = parseInt(e.target.category.slice(3, 5));
                                this.onDataPointSelect(endTimeHour, endTimeMin);
                            },
                        },
                    },
                    data: consumedMemoryArr,
                },
            ],
        };

        const networkChart = {
            chart: {
                type: "column",
                height: 350,
                style: {
                    fontFamily: "Lato",
                    fontSize: "15px",
                },
            },
            title: {
                text: "",
            },
            plotOptions: {
                series: {
                    cursor: "pointer",
                },
                column: {
                    zones: [
                        {
                            value: 0,
                            color: "#684D94",
                        },
                        {
                            value: 0,
                            color: "#9279b9",
                        },
                    ],
                    pointPadding: 0.1,
                    borderWidth: 0,
                },
            },
            xAxis: {
                // categories: timearr,
                categories: this.state.selectedTimezone ? formattedTimeArr : timearr,
                crosshair: true,
            },
            yAxis: [
                {
                    title: {
                        text: "",
                    },
                },
            ],
            tooltip: {
                valueSuffix: "MB/s",
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    showInLegend: true,
                    color: "#684D94",
                    name: "Upload Speed",
                    data: uploadSpeedarr,
                },
                {
                    showInLegend: true,
                    color: "#9279b9",
                    name: "Download Speed",
                    data: downloadSpeedarr,
                },
            ],
        };

        const diskChart = {
            chart: {
                type: "column",
                height: 350,
                style: {
                    fontFamily: "Lato",
                    fontSize: "15px",
                },
            },
            title: {
                text: "",
            },
            plotOptions: {
                series: {
                    cursor: "pointer",
                },
                column: {
                    zones: [
                        {
                            value: 0,
                            color: "#684D94",
                        },
                        {
                            value: 0,
                            color: "#9279b9",
                        },
                    ],
                    pointPadding: 0.1,
                    borderWidth: 0,
                },
            },
            xAxis: {
                // categories: timearr,
                categories: this.state.selectedTimezone ? formattedTimeArr : timearr,
                crosshair: true,
            },
            yAxis: [
                {
                    title: {
                        text: "",
                    },
                },
            ],
            tooltip: {
                valueSuffix: "GB",
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    showInLegend: true,
                    color: "#684D94",
                    name: "Free Space",
                    data: freeDiskSpace,
                },
                {
                    showInLegend: true,
                    color: "#9279b9",
                    name: "Used Space",
                    data: usedDiskSpace,
                },
            ],
        };

        const weekChartsData = {
            chart: {
                type: "column",
                height: 250,
                zoomType: "x",
                style: {
                    fontFamily: "Lato",
                    fontSize: "15px",
                },
            },
            title: {
                text: "",
            },
            plotOptions: {
                series: {
                    cursor: "pointer",
                    point: {
                        events: {
                            click: (function (component) {
                                return function () {
                                    component.setState(
                                        {
                                            selectedDate: new Date(this.category),
                                        },
                                        () => {
                                            component.handleScreenshotDateChange();
                                        }
                                    );
                                };
                            })(this),
                        },
                    },
                },
                column: {
                    zones: [
                        {
                            value: 4,
                            color: "#FF0404",
                        },
                        {
                            value: 8,
                            color: "#B1B1B1",
                        },
                        {
                            color: "#684D94",
                        },
                    ],
                    pointPadding: 0.1,
                    borderWidth: 0,
                },
            },
            xAxis: {
                categories: newWeekGraphActivityDate,
                type: "datetime",
                labels: {
                    formatter: function () {
                        return moment(this.value).format("ddd");
                    },
                },
            },
            yAxis: [
                {
                    title: {
                        text: t("timeTracker.workHours"),
                    },
                },
            ],
            tooltip: {
                formatter: function () {
                    let x =
                        "<b>" +
                        moment(this.x).format("ddd") +
                        "</b>" +
                        ", " +
                        "<b>" +
                        moment(this.x).format("Do MMMM YYYY") +
                        "</b><br/>";
                    let z =
                        '<span style="color:' +
                        this.series.color +
                        '">' +
                        t("hours") +
                        " " +
                        "</span>: <b>" +
                        this.y +
                        " " +
                        t("hours") +
                        " " +
                        "</b><br/>";
                    return x + z;
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    showInLegend: false,
                    name: "Time",
                    data: newWeeGraphActivityHours,
                },
            ],
        };

        const onChange = (ranges) => {
            let dd = moment(ranges.startDate).format("YYYY-MM-DD");
            let rr = moment(ranges.endDate).format("YYYY-MM-DD");
            this.setState({ startDate: dd, endDate: rr }, () => {
                if (new Date(this.state.endDate) > new Date()) {
                    toast("Data Not Available for Future Date");
                    this.props
                        .getEmployeeBroswerLog(
                            this.state.employeeId,
                            this.state.startDate,
                            moment(new Date()).format("YYYY-MM-DD")
                        )
                        .then((res) => {
                            this.setState({
                                browserActivityData: res.value.data.data,
                            });
                        });
                } else if (this.state.startDate === this.state.endDate) {
                    this.props
                        .fetchAllUserURLs(
                            moment(this.state.startDate).format("YYYY-MM-DD"),
                            this.state.employeeId
                        )
                        .then((res) => {
                            if (res.value.status === 200) {
                                this.setState({
                                    browserActivityData: res.value.data,
                                });
                            }
                        });
                } else {
                    this.props
                        .getEmployeeBroswerLog(
                            this.state.employeeId,
                            this.state.startDate,
                            this.state.endDate
                        )
                        .then((res) => {
                            this.setState({
                                browserActivityData: res.value.data.data,
                            });
                        });
                }
            });
        };

        //calling api each time for a range of date , but it is a bad way of doing it,
        // as the  getEmployeeBroswerLog (which is for date range) is giving an empty data in response
        // const onChange = (ranges) => {
        //     let dd = moment(ranges.startDate);
        //     let rr = moment(ranges.endDate);
        //     let dd1 = moment(ranges.startDate);
        //     let rr1 = moment(ranges.endDate);
        //     let arr=[]
        //     this.state.startDate2=dd1
        //     this.state.endDate2=rr1
        //     this.setState({ startDate: dd.format("YYYY-MM-DD"), endDate: rr.format("YYYY-MM-DD") },async () => {
        //         if (new Date(this.state.endDate) > new Date()) {
        //                 toast("Data Not Available for Future Date");
        //         }
        //         else{

        //             while(dd.isSameOrBefore(rr,'day')){
        //                 const datefetch=dd.format("YYYY-MM-DD")
        //                 try{
        //                     const response=await this.props.fetchAllUserURLs(datefetch,this.state.employeeId)
        //                     if(response.value.status === 200){
        //                         arr.push(...response.value.data)
        //                     }
        //                 }
        //                 catch(e){
        //                     console.log("error",datefetch,e)
        //                 }
        //                 dd.add(1,'day')

        //             }
        //             this.setState({
        //                 browserActivityData: arr,
        //             });
        //         }
        //     });
        // };
        //

        const fetchEmpBrowserActivity = (e) => {
            this.setState({ employeeId: e.target.value, showProgramList: false }, () => {
                if (this.state.employeeId === "") {
                } else {
                    this.props
                        .fetchAllUserURLs(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            this.state.employeeId
                        )
                        .then((res) => {
                            if (res.value.status === 200) {
                                this.setState({
                                    browserActivityData: res.value.data,
                                });
                            }
                        });
                }
            });
        };

        const setUserRole = (e) => {
            let ee = e.split(" ");
            setTimeout(() => {
                if (ee[1] === "admin") {
                    localStorage.setItem("roleUser", "admin");
                    localStorage.setItem("ro", "Admin");
                    // this.props.storeToLocalStorage('admin')
                    this.handleScreenshotDateChange();
                } else if (ee[1] === "observer") {
                    localStorage.setItem("roleUser", "observer");
                    localStorage.setItem("ro", "Observer");
                    // this.props.storeToLocalStorage('observer')
                    this.handleScreenshotDateChange();
                } else if (e === `${localStorage.getItem("roleUserORG")} employee`) {
                    localStorage.setItem("roleUser", "employee");
                } else {
                    localStorage.setItem("roleUser", "manager");
                }
            }, 200);
        };
        const firstDay = this.state.startDate;
        const lastDay = this.state.endDate;
        return (
            <Layout>
                {/* <div className='w-full flex justify-end items-center'>
                    <select className='h-9 rounded-md border-gray-400' defaultValue={localStorage.getItem('roleUser') === 'admin' ? `${localStorage.getItem('roleUserORG')} admin` : localStorage.getItem('roleUser') === 'observer' ? `${localStorage.getItem('roleUserORG')} observer` : `${localStorage.getItem('roleUserORG')} manager` } onChange={(e) => {setUserRole(e.target.value)}}>
                        {this.state.userRoleList && this.state.userRoleList.map((el, idx) => (
                          <option key={idx}>{el.org} {el.role}</option>
                        ))}
                    </select>
                </div> */}

                <div className="bg-white px-2 py-1 mt-6 border-b border-gray-200 sm:px-6 rounded-lg">
                    <div className="md:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            {t("requestsModule.selectATab")}
                        </label>
                        <select
                            id="tabs"
                            name="tabs"
                            onChange={(e) => this.HandleMobileSelectTab(e)}
                            className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                            defaultValue={this.state.SelectedTab}>
                            <option key="screenshots" value="screenshots">
                                {t("screenshots")}
                            </option>
                            <option key="CPU Information" value="cpuinformation">
                                CPU {t("timeTracker.information")}
                            </option>
                            <option key="App Log" value="applications">
                                {t("ReportsTab.applications")}
                            </option>
                            <option key="Activity Log" value="activitylog">
                                {t("timeTracker.activity")} {t("timeTracker.log")}
                            </option>
                            <option key="location" value="location">
                                {t("location")}
                            </option>
                        </select>
                    </div>
                    <div className="hidden md:block">
                        <nav className="-mb-px flex space-x-4" aria-label="Tabs">
                            <div
                                key="screenshots"
                                onClick={() => this.HandleSelectTab("screenshots")}
                                className={classNames(
                                    this.state.SelectedTab === "screenshots"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "screenshots" ? "page" : undefined
                                }>
                                <PhotographIcon
                                    className={classNames(
                                        this.state.SelectedTab === "screenshots"
                                            ? "text-primary"
                                            : "text-textprimary group-hover:text-gray-500",
                                        "-ml-0.5 mr-2 h-5 w-5"
                                    )}
                                    aria-hidden="true"
                                />
                                <span>{t("screenshots")}</span>
                            </div>

                            <div
                                key="CPU Information"
                                onClick={() => this.HandleSelectTab("cpuinformation")}
                                className={classNames(
                                    this.state.SelectedTab === "cpuinformation"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "cpuinformation" ? "page" : undefined
                                }>
                                <DesktopComputerIcon
                                    className={classNames(
                                        this.state.SelectedTab === "cpuinformation"
                                            ? "text-primary"
                                            : "text-textprimary group-hover:text-gray-500",
                                        "-ml-0.5 mr-2 h-5 w-5"
                                    )}
                                    aria-hidden="true"
                                />
                                <span>CPU {t("timeTracker.information")}</span>
                            </div>

                            <div
                                key="App Log"
                                onClick={() => this.HandleSelectTab("applications")}
                                className={classNames(
                                    this.state.SelectedTab === "applications"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "applications" ? "page" : undefined
                                }>
                                <CameraIcon
                                    className={classNames(
                                        this.state.SelectedTab === "applications"
                                            ? "text-primary"
                                            : "text-textprimary group-hover:text-gray-500",
                                        "-ml-0.5 mr-2 h-5 w-5"
                                    )}
                                    aria-hidden="true"
                                />
                                <span>{t("ReportsTab.applications")}</span>
                            </div>
                            <div
                                key="Activity Log"
                                onClick={() => this.HandleSelectTab("activitylog")}
                                className={classNames(
                                    this.state.SelectedTab === "activitylog"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "activitylog" ? "page" : undefined
                                }>
                                <ServerIcon
                                    className={classNames(
                                        this.state.SelectedTab === "activitylog"
                                            ? "text-primary"
                                            : "text-textprimary group-hover:text-gray-500",
                                        "-ml-0.5 mr-2 h-5 w-5"
                                    )}
                                    aria-hidden="true"
                                />
                                <span>
                                    {t("activity")} {t("timeTracker.log")}
                                </span>
                            </div>
                            <div
                                key="location"
                                onClick={() => this.HandleSelectTab("location")}
                                className={classNames(
                                    this.state.SelectedTab === "location"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "location" ? "page" : undefined
                                }>
                                <div className="-ml-0.5 mr-2 h-5 overflow-y-hidden">
                                    <PinDrop
                                        className={classNames(
                                            this.state.SelectedTab === "location"
                                                ? "text-primary"
                                                : "text-textprimary group-hover:text-gray-500",
                                            "h-6 w-5"
                                        )}
                                        aria-hidden="true"
                                    />
                                </div>
                                <span>{t("location")}</span>
                            </div>
                        </nav>
                    </div>
                </div>

                {this.state.SelectedTab === "applications" ? (
                    <div>
                        <div className="bg-white rounded-md shadow-sm my-4">
                            <div className=" px-2 py-1 sm:px-6 rounded-lg">
                                <div className="sm:hidden">
                                    <label htmlFor="tabs" className="sr-only">
                                        {t("requestsModule.selectATab")}
                                    </label>
                                    <select
                                        id="tabs"
                                        name="tabs"
                                        onChange={(e) =>
                                            this.setState({ applicationsSubTab: e.target.value })
                                        }
                                        className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                                        defaultValue={this.state.applicationsSubTab}>
                                        <option key="desktop" value="desktop">
                                            {t("timeTracker.desktop")}{" "}
                                            {t("ReportsTab.applications")}
                                        </option>
                                        <option key="mobile" value="mobile">
                                            {t("timeTracker.mobile")} {t("ReportsTab.applications")}
                                        </option>
                                    </select>
                                </div>
                                <div className="hidden sm:block">
                                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                        <div
                                            key="DesktopApplications"
                                            onClick={() =>
                                                this.setState({ applicationsSubTab: "desktop" })
                                            }
                                            className={classNames(
                                                this.state.applicationsSubTab === "desktop"
                                                    ? "border-primary text-primary"
                                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                                "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                            )}
                                            aria-current={
                                                this.state.applicationsSubTab === "desktop"
                                                    ? "page"
                                                    : undefined
                                            }>
                                            <DesktopMacRounded
                                                className={classNames(
                                                    this.state.applicationsSubTab === "desktop"
                                                        ? "text-primary"
                                                        : "text-gray-400 group-hover:text-gray-500",
                                                    "-ml-0.5 mr-2 h-5 w-5"
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span>
                                                {t("timeTracker.desktop")}{" "}
                                                {t("ReportsTab.applications")}
                                            </span>
                                        </div>
                                        <div
                                            key="MobileApplications"
                                            onClick={() =>
                                                this.setState({ applicationsSubTab: "mobile" })
                                            }
                                            className={classNames(
                                                this.state.applicationsSubTab === "mobile"
                                                    ? "border-primary text-primary"
                                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                                "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                            )}
                                            aria-current={
                                                this.state.applicationsSubTab === "mobile"
                                                    ? "page"
                                                    : undefined
                                            }>
                                            <SmartphoneRounded
                                                className={classNames(
                                                    this.state.applicationsSubTab === "mobile"
                                                        ? "text-primary"
                                                        : "text-gray-400 group-hover:text-gray-500",
                                                    "-ml-0.5 mr-2 h-5 w-5"
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span>
                                                {t("timeTracker.mobile")}{" "}
                                                {t("ReportsTab.applications")}
                                            </span>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        {this.state.applicationsSubTab === "desktop" ? (
                            <DesktopApps history={this.props.history} />
                        ) : (
                            <MobileApps />
                        )}
                    </div>
                ) : null}

                {this.state.SelectedTab === "screenshots" ? (
                    <>
                        <div className="mt-2 bg-white px-2 py-2 border-b border-gray-200 rounded-lg">
                            <div className="flex justify-between flex-col xl:flex-row xl:items-center flex-wrap lg:p-0 p-3">
                                <div className="flex flex-col justify-between lg:flex-1 lg:flex-row">
                                    <div className="flex relative text-textprimary text-lg font-medium mb-2 mr-2 lg:mb-0">
                                        <DatePicker
                                            className="rounded-md min-w-max w-full"
                                            dateFormat="MMMM dd yyyy"
                                            value={new Date()}
                                            startDate={new Date()}
                                            selected={this.state.selectedDate}
                                            onChange={(date) =>
                                                this.handleScreenshotDateChange(date)
                                            }
                                        />
                                        <CalendarIcon className=" absolute top-2.5 right-2 w-5"></CalendarIcon>
                                    </div>
                                </div>
                                <div className="flex flex-shrink-0 flex-col items-start lg:items-center lg:flex-row mt-4 lg:flex-1 xl:mt-0">
                                    <SelectField
                                        onSelectChange={(e) => this.onEmployeeScreenshotChange(e)}
                                        FieldName={t("select") + " " + t("employee")}
                                        name={"employee"}
                                        arrayOfData={screenshotEmployeeData}
                                        mainclassName={"lg:mb-0 mb-2 min-w-[130px]"}
                                        id={"employee"}
                                        value={
                                            this.state.employeeId === ""
                                                ? ""
                                                : this.state.employeeId
                                        }></SelectField>

                                    <SelectField
                                        onSelectChange={(e) => this.onProjectScreenshotChange(e)}
                                        FieldName={t("select") + " " + t("project")}
                                        name={"projectName"}
                                        arrayOfData={ProjectData}
                                        mainclassName={"lg:ml-3 min-w-[130px]"}
                                        id={"project"}
                                        value={
                                            this.state.projectname === ""
                                                ? ""
                                                : this.state.projectname
                                        }></SelectField>

                                    <div className="flex justify-between w-full items-center translate-y-[10px]">
                                        <div className="w-60 ml-0 lg:ml-3 ">
                                            <TimezoneSelect
                                                placeholder={t("pleaseSelectTimeZone")}
                                                className="rounded-lg border-none mr-4 lg:mr-4 w-60"
                                                value={this.state.selectedTimezone}
                                                timezones={{ ...allTimezones }}
                                                // onChange={(e) => this.onTimeZoneChange(e)}
                                                onChange={(e) => {
                                                    this.setState({
                                                        selectedTimezone: e.value,
                                                    });
                                                    this.onTimeZoneChange(e);
                                                }}
                                            />
                                        </div>

                                        <Link
                                            to="/settings/permissions/global-permission"
                                            className="ml-0 lg:ml-3  hover:text-primary">
                                            <CogIcon className="h-6 w-6 cursor-pointer" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.employeeId ? (
                            <div>
                                <div className="flex mt-2 items-center bg-gray-100">
                                    <div className="w-full mx-auto">
                                        <div className="grid gap-2 grid-cols-2 lg-m:grid-cols-1">
                                            {this.state.employeeId !== "" &&
                                            this.state.userName !== "" ? (
                                                <div className="p-5 bg-white bg-opacity-50 rounded shadow-sm">
                                                    <div className="flex justify-between mb-4">
                                                        <div className="flex items-center">
                                                            <div className="border-2 h-10 w-10 rounded-full border-gray-400 bg-gray-200 text-gray-500 text-center flex flex-col justify-center">
                                                                {this.state.userName
                                                                    .split(" ")
                                                                    .map((word) => word.charAt(0))}
                                                            </div>
                                                            <div className="ml-2 text-lg">
                                                                {this.state.userName}
                                                            </div>
                                                        </div>
                                                        <button className="text-indigo-700 text-sm px-3 rounded-md bg-indigo-100 shadow-sm hidden">
                                                            {t("timeTracker.change")}
                                                        </button>
                                                    </div>
                                                    {userDetails !== "" &&
                                                    userDetails &&
                                                    userDetails.status !== "no data found" &&
                                                    (userDetails.team ||
                                                        userDetails.project_name ||
                                                        userDetails.task) ? (
                                                        <div>
                                                            <div className="pl-2 mb-4 grid grid-cols-5 gap-2">
                                                                <div className="text-gray-500">{`${t(
                                                                    "team"
                                                                )}: `}</div>
                                                                <div className=" col-span-4 text-textprimary">
                                                                    {userDetails.team}
                                                                </div>
                                                            </div>
                                                            <div className="pl-2 mb-4 grid grid-cols-5 gap-2">
                                                                <div className="text-gray-500">{`${t(
                                                                    "project"
                                                                )}: `}</div>
                                                                <div className=" col-span-4 text-textprimary">
                                                                    {userDetails.project_name}
                                                                </div>
                                                            </div>
                                                            <div className="pl-2 mb-4 grid grid-cols-5 gap-2">
                                                                <div className="text-gray-500">{`${t(
                                                                    "task"
                                                                )}: `}</div>
                                                                <div className=" col-span-4 text-textprimary">
                                                                    {userDetails.task}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="text-center pt-8 bg-white bg-opacity-50 rounded shadow-sm">
                                                            <img
                                                                alt=""
                                                                className="m-auto"
                                                                src={require("../../images/nodata.png")}
                                                            />
                                                            <div className="text-lg mt-2 text-textprimary uppercase italic font-medium">
                                                                {`${t(
                                                                    "timeTracker.noRecordsFound"
                                                                )} ${t("timeTracker.forUser")}`}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="text-center pt-8 bg-white bg-opacity-50 rounded shadow-sm">
                                                    <img
                                                        alt=""
                                                        className="m-auto"
                                                        src={require("../../images/nodata.png")}
                                                    />
                                                    <div className="text-lg mt-2 text-primary uppercase italic font-medium">
                                                        {`${t("timeTracker.noRecordsFound")} ${t(
                                                            "timeTracker.forUser"
                                                        )}`}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="grid gap-2 lg-m:grid-cols-3 lg:grid-cols-2 md-m:grid-cols-1">
                                                <div className="flex items-center justify-between p-5 bg-white bg-opacity-50 rounded shadow-sm">
                                                    <div>
                                                        <div className="text-base text-textprimary ">
                                                            {t("timeTracker.totalTime")}
                                                        </div>
                                                        <div className="flex items-center pt-1">
                                                            <div
                                                                className={`text-xl font-medium text-primary flex items-center ${
                                                                    this.state.loadingScreenshotData
                                                                        ? "animate-pulse"
                                                                        : ""
                                                                }`}>
                                                                {moment
                                                                    .duration(
                                                                        screenshotkpis &&
                                                                            screenshotkpis.Total_online_time,
                                                                        "minutes"
                                                                    )
                                                                    .days() *
                                                                    24 +
                                                                    moment
                                                                        .duration(
                                                                            screenshotkpis &&
                                                                                screenshotkpis.Total_online_time,
                                                                            "minutes"
                                                                        )
                                                                        .hours() +
                                                                    " hrs " +
                                                                    moment
                                                                        .duration(
                                                                            screenshotkpis &&
                                                                                screenshotkpis.Total_online_time,
                                                                            "minutes"
                                                                        )
                                                                        .minutes() +
                                                                    " " +
                                                                    t("mins")}
                                                                {this.state
                                                                    .loadingScreenshotData ? (
                                                                    <div className="h-4 w-4 rounded-full  border border-t-0 ml-2 animate-spin"></div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-gray-300">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="w-8 h-8"
                                                            fill="none"
                                                            viewBox="0 0 47 46">
                                                            <path
                                                                stroke="currentColor"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M41.536 30.456a19.21 19.21 0 01-5.675 7.4 19.771 19.771 0 01-8.557 3.937c-3.138.608-6.38.455-9.444-.447a19.673 19.673 0 01-8.129-4.725 19.1 19.1 0 01-4.92-7.902 18.775 18.775 0 01-.564-9.237 18.98 18.98 0 013.923-8.419 19.538 19.538 0 017.497-5.639"></path>
                                                            <path
                                                                stroke="currentColor"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M43.083 23c0-2.517-.506-5.01-1.49-7.335a19.142 19.142 0 00-4.246-6.218 19.617 19.617 0 00-6.353-4.155A19.953 19.953 0 0023.5 3.833V23h19.583z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-between p-5 bg-white bg-opacity-50 rounded shadow-sm">
                                                    <div>
                                                        <div className="text-base text-textprimary ">
                                                            {t("timeTracker.idleTime")}
                                                        </div>
                                                        <div className="flex items-center pt-1">
                                                            <div
                                                                className={`text-xl font-medium text-primary flex items-center ${
                                                                    this.state.loadingScreenshotData
                                                                        ? "animate-pulse"
                                                                        : ""
                                                                }`}>
                                                                {moment
                                                                    .duration(
                                                                        screenshotkpis &&
                                                                            screenshotkpis.Total_idle_time,
                                                                        "minutes"
                                                                    )
                                                                    .days() *
                                                                    24 +
                                                                    moment
                                                                        .duration(
                                                                            screenshotkpis &&
                                                                                screenshotkpis.Total_idle_time,
                                                                            "minutes"
                                                                        )
                                                                        .hours() +
                                                                    " hrs " +
                                                                    moment
                                                                        .duration(
                                                                            screenshotkpis &&
                                                                                screenshotkpis.Total_idle_time,
                                                                            "minutes"
                                                                        )
                                                                        .minutes() +
                                                                    " " +
                                                                    t("mins")}
                                                                {this.state
                                                                    .loadingScreenshotData ? (
                                                                    <div className="h-4 w-4 rounded-full  border border-t-0 ml-2 animate-spin"></div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-gray-300">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="w-8 h-8"
                                                            fill="none"
                                                            viewBox="0 0 47 46">
                                                            <path
                                                                stroke="currentColor"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M41.536 30.456a19.21 19.21 0 01-5.675 7.4 19.771 19.771 0 01-8.557 3.937c-3.138.608-6.38.455-9.444-.447a19.673 19.673 0 01-8.129-4.725 19.1 19.1 0 01-4.92-7.902 18.775 18.775 0 01-.564-9.237 18.98 18.98 0 013.923-8.419 19.538 19.538 0 017.497-5.639"></path>
                                                            <path
                                                                stroke="currentColor"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M43.083 23c0-2.517-.506-5.01-1.49-7.335a19.142 19.142 0 00-4.246-6.218 19.617 19.617 0 00-6.353-4.155A19.953 19.953 0 0023.5 3.833V23h19.583z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="lg:col-span-2 flex items-center justify-between p-5 bg-white bg-opacity-50 rounded shadow-sm">
                                                    <div>
                                                        <div className="text-base text-textprimary ">
                                                            {t("activity") +
                                                                " " +
                                                                t("requestsModule.Index")}
                                                        </div>
                                                        <div className="flex items-center pt-1">
                                                            <div
                                                                className={`text-xl font-medium text-primary flex items-center ${
                                                                    this.state.loadingScreenshotData
                                                                        ? "animate-pulse"
                                                                        : ""
                                                                }`}>
                                                                {screenshotkpis === undefined ||
                                                                screenshotkpis === ""
                                                                    ? ""
                                                                    : screenshotkpis.Activity_idex.toFixed(
                                                                          2
                                                                      ) + " %"}
                                                                {this.state
                                                                    .loadingScreenshotData ? (
                                                                    <div className="h-4 w-4 rounded-full  border border-t-0 ml-2 animate-spin"></div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-textprimary">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="w-8 h-8"
                                                            fill="none"
                                                            viewBox="0 0 47 46">
                                                            <path
                                                                stroke="currentColor"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M39.167 40.25v-3.833a7.585 7.585 0 00-2.295-5.422 7.92 7.92 0 00-5.539-2.245H15.667a7.92 7.92 0 00-5.54 2.245 7.585 7.585 0 00-2.294 5.422v3.833M23.5 21.083c4.326 0 7.833-3.432 7.833-7.666 0-4.235-3.507-7.667-7.833-7.667-4.326 0-7.833 3.432-7.833 7.667 0 4.234 3.507 7.666 7.833 7.666z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.employeeMood !== "" ? (
                                                <div className="p-5 bg-white bg-opacity-50 rounded shadow-sm">
                                                    <div className="text-lg mb-2">
                                                        {t("start")} {t("mood")}:{" "}
                                                        {this.state.employeeMood["start_mood"] ? (
                                                            <span>
                                                                {t("feeling")}{" "}
                                                                {
                                                                    emojiList[
                                                                        this.state.employeeMood[
                                                                            "start_mood"
                                                                        ] - 1
                                                                    ]
                                                                }
                                                            </span>
                                                        ) : (
                                                            t("noData")
                                                        )}
                                                    </div>
                                                    <div className="min-w-[100px] text-textprimary">
                                                        {this.state.employeeMood["start_note"]}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {this.state.employeeMood !== "" ? (
                                                <div className="p-5 bg-white bg-opacity-50 rounded shadow-sm">
                                                    <div className="text-lg mb-2">
                                                        {t("end")} {t("mood")}:{" "}
                                                        {this.state.employeeMood["end_mood"] ? (
                                                            <span>
                                                                {t("feeling")}{" "}
                                                                {
                                                                    emojiList[
                                                                        this.state.employeeMood[
                                                                            "end_mood"
                                                                        ] - 1
                                                                    ]
                                                                }
                                                            </span>
                                                        ) : (
                                                            t("noData")
                                                        )}
                                                    </div>
                                                    <div className="min-w-[100px] text-textprimary">
                                                        {this.state.employeeMood["end_note"]}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex mt-2 items-center bg-gray-100">
                                    <div className="w-full mx-auto">
                                        <div className="grid gap-2 sm:grid-cols-1 lg:grid-cols-2">
                                            <div className="p-5 bg-white rounded shadow-sm">
                                                <div className="text-lg text-textprimary">
                                                    {t("timeTracker.dailyActivityPerHour")}
                                                </div>
                                                <div className="text-xs text-textprimary">
                                                    {t("timeTracker.dailyActCharttxt2")}
                                                </div>
                                                {percentages.length !== 0 ? (
                                                    <div className="flex mt-3 mb-6">
                                                        <div className="flex">
                                                            <div className="bg-primary rounded-full mt-1 w-4 h-4"></div>
                                                            <span className="ml-1">
                                                                {t("timeTracker.above")} 50%
                                                            </span>
                                                        </div>
                                                        <div className="flex">
                                                            <div className="bg-[#B1B1B1] rounded-full mt-1 ml-3 w-4 h-4"></div>
                                                            <span className="ml-1">
                                                                {t("timeTracker.above")} 25%
                                                            </span>
                                                        </div>
                                                        <div className="flex">
                                                            <div className="bg-danger rounded-full mt-1 ml-3 w-4 h-4"></div>
                                                            <span className="ml-1">
                                                                {t("timeTracker.below")} 25%
                                                            </span>
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {this.state.loadingScreenshotData ? (
                                                    <div className="text-center h-64 w-full flex justify-center items-center">
                                                        <BeatLoader
                                                            color={"#684994"}
                                                            percentages={
                                                                percentages === "" ? true : false
                                                            }
                                                            size={15}
                                                            margin={2}
                                                        />
                                                    </div>
                                                ) : percentages.length !== 0 ? (
                                                    <ReactHighcharts config={hourlyChart}>
                                                        {" "}
                                                    </ReactHighcharts>
                                                ) : (
                                                    <div className="text-center mt-8">
                                                        <img
                                                            alt=""
                                                            className="m-auto"
                                                            src={require("../../images/nodata.png")}
                                                        />
                                                        <div className="text-lg mt-2 italic font-medium text-gray-500">
                                                            {t("timeTracker.noRecordsFound")}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="p-5 bg-white rounded shadow-sm">
                                                <div className="text-lg text-textprimary">
                                                    {t("timeTracker.weekly")}{" "}
                                                    {t("timeTracker.chart")}
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <div className="text-xs text-textprimary">
                                                        {t("timeTracker.hoursWorkedPastDays")}
                                                    </div>
                                                    <div className="text-sm text-textprimary flex">
                                                        <ChevronLeftIcon
                                                            className="w-5 h-5 cursor-pointer"
                                                            onClick={this.lastWeekGraph}>
                                                            {" "}
                                                        </ChevronLeftIcon>
                                                        <div className="ml-2 mr-2">
                                                            {moment(this.state.lastDate).format(
                                                                "ll"
                                                            )}{" "}
                                                            {t("to")}{" "}
                                                            {moment(this.state.currentDate).format(
                                                                "ll"
                                                            )}
                                                        </div>
                                                        <ChevronRightIcon
                                                            className="w-5 h-5 cursor-pointer"
                                                            onClick={this.nextWeekGraph}>
                                                            {" "}
                                                        </ChevronRightIcon>
                                                    </div>
                                                </div>

                                                {this.state.loadingScreenshotData ? (
                                                    <div className="text-center h-full w-full flex justify-center items-center">
                                                        <BeatLoader
                                                            color={"#684994"}
                                                            newWeekGraphActIndexConverted={
                                                                newWeekGraphActIndexConverted === ""
                                                                    ? true
                                                                    : false
                                                            }
                                                            size={15}
                                                            margin={2}
                                                        />{" "}
                                                    </div>
                                                ) : newWeekGraphActIndexConverted.length !== 0 ? (
                                                    <div className="mt-14">
                                                        {" "}
                                                        <ReactHighcharts config={weekChartsData}>
                                                            {" "}
                                                        </ReactHighcharts>
                                                    </div>
                                                ) : (
                                                    <div className="flex justify-center items-center h-full w-full flex-col">
                                                        <img
                                                            alt=""
                                                            className=""
                                                            src={require("../../images/nodata.png")}
                                                        />
                                                        <div className="text-lg mt-2 text-primary italic font-medium">
                                                            {t("timeTracker.noRecordsFound")}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.showcaseEntriesList &&
                                this.state.showcaseEntriesList.length > 0 ? (
                                    <div className="p-5 mt-2 bg-white rounded shadow-sm">
                                        <div className="text-xl text-textprimary font-semibold mb-4 flex items-center">
                                            {`${t("showCaseEntries")} (${
                                                this.state.showcaseEntriesList.length
                                            })`}
                                            <span>
                                                {this.state.loadingScreenshotData ? (
                                                    <div className="h-5 w-5 text-primary rounded-full border border-t-0 ml-2 animate-spin"></div>
                                                ) : null}
                                            </span>
                                        </div>
                                        {this.state.isShowcaseModalActive ? (
                                            <ModalShowCaseEntry
                                                open={this.state.isShowcaseModalActive}
                                                fromDashboard={false}
                                                userDetails={{
                                                    name: this.state.userName
                                                        ? this.state.userName
                                                        : this.state.selectedEntry.username,
                                                }}
                                                handleClose={() => {
                                                    this.setState({ isShowcaseModalActive: false });
                                                }}
                                                handleModalShowChange={() => {
                                                    this.setState({
                                                        isShowcaseModalActive:
                                                            !this.state.isShowcaseModalActive,
                                                    });
                                                }}
                                                entryDetails={[this.state.selectedEntry]}
                                            />
                                        ) : null}
                                        <div className="flex flex-wrap">
                                            {this.state.showcaseEntriesList.map((entry) => {
                                                return (
                                                    <div className="mr-2 mb-6 min-w-[250px] min-h-[225px] w-[250px] h-[225px] overflow-hidden rounded-lg border-2 bg-white border-gray shadow-sm lg:mr-6">
                                                        {entry.video ? (
                                                            <video
                                                                src={entry.image}
                                                                className="w-full h-[140px] hover:cursor-pointer"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        isShowcaseModalActive:
                                                                            !this.state
                                                                                .isShowcaseModalActive,
                                                                        selectedEntry: entry,
                                                                    });
                                                                }}></video>
                                                        ) : (
                                                            <img
                                                                src={entry.image}
                                                                alt=""
                                                                className="w-full h-[140px] hover:cursor-pointer"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        isShowcaseModalActive:
                                                                            !this.state
                                                                                .isShowcaseModalActive,
                                                                        selectedEntry: entry,
                                                                    });
                                                                }}
                                                            />
                                                        )}
                                                        <div className="p-2 ">
                                                            <div className=" text-sm font-semibold">
                                                                {entry.file_name}
                                                            </div>
                                                            <div className="text-sm text-gray-400">
                                                                {`${t("project")}: ${
                                                                    entry.project_name
                                                                }`}
                                                            </div>
                                                            <div className="text-sm text-gray-400">
                                                                {`${t("task")}: ${entry.task}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                ) : null}
                                <div className="text-xl text-textprimary font-semibold mt-6 flex items-center justify-between">
                                    <div>
                                        {" "}
                                        {t("screenshots")}
                                        <span>
                                            {this.state.loadingScreenshotData ? (
                                                <div className="h-5 w-5 text-primary rounded-full border border-t-0 ml-2 animate-spin"></div>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="flex items-center text-tiny justify-end text-textprimary font-medium pr-4">
                                        <button
                                            disabled={!this.state.isScreenshotSelectActive}
                                            onClick={() => {
                                                if (this.state.isScreenshotSelectActive) {
                                                    let iDs = "";
                                                    iDs += this.state.selectedSSid.map((id) => {
                                                        return id;
                                                    });
                                                    this.props
                                                        .cancelTime({ ids: iDs })
                                                        .then((res) => {
                                                            if (res.value.status === 200) {
                                                                this.setState({
                                                                    isScreenshotSelectActive: false,
                                                                    selectedSSid: [],
                                                                });
                                                                this.onEmployeeScreenshotChange({
                                                                    target: {
                                                                        value: this.state
                                                                            .employeeId,
                                                                    },
                                                                });
                                                            }
                                                        });
                                                }
                                            }}
                                            className={`border-l border-gray-300 px-4 2xl:px-6 py-1 flex items-center gap-1 ${
                                                !this.state.isScreenshotSelectActive &&
                                                "text-[#6B7280]"
                                            }`}>
                                            {" "}
                                            <MoonIcon className="w-4 h-4" />{" "}
                                            <span className="whitespace-nowrap">
                                                UnProductive Time
                                            </span>
                                        </button>
                                        <button
                                            disabled={!this.state.isScreenshotSelectActive}
                                            onClick={() => {
                                                if (this.state.isScreenshotSelectActive) {
                                                    let iDs = "";
                                                    iDs += this.state.selectedSSid.map((id) => {
                                                        return id;
                                                    });
                                                    this.props
                                                        .deleteTime({ ids: iDs })
                                                        .then((res) => {
                                                            if (res.value.status === 200) {
                                                                this.setState({
                                                                    isScreenshotSelectActive: false,
                                                                    selectedSSid: [],
                                                                });
                                                                this.onEmployeeScreenshotChange({
                                                                    target: {
                                                                        value: this.state
                                                                            .employeeId,
                                                                    },
                                                                });
                                                            }
                                                        });
                                                }
                                            }}
                                            className={`border-l border-gray-300 px-4 2xl:px-6 py-1 flex items-center gap-1 ${
                                                !this.state.isScreenshotSelectActive &&
                                                "text-[#6B7280]"
                                            }`}>
                                            <TrashIcon className="w-4 h-4" />{" "}
                                            <span className="whitespace-nowrap">Delete Time</span>
                                        </button>
                                    </div>
                                </div>
                                <div
                                    id="screenshots_section"
                                    className="col-md-12"
                                    style={{ padding: "0px" }}>
                                    {this.state.Timetracker.map((item, index) => {
                                        let item1 = Object.values(item);
                                        let value = Object.keys(item);
                                        for (var i = 0; i < item1.length; i++) {
                                            if (item1[i]) {
                                                var hour_img = item1[i].thumbnails;
                                                var hour_img_multi = item1[i].thumbnail_list;
                                                var hourly_thubm_img;
                                                var hourly_thubm_img_multi;
                                                if (hour_img) {
                                                    if (hour_img[0]) {
                                                        hourly_thubm_img = hour_img[0];
                                                        // hourly_thubm_img_multi = hour_img_multi[0]
                                                    }
                                                }
                                                hour_img = item1[i].screenshots;
                                                hour_img_multi = item1[i].screenshot_list;
                                                var hourly_ss_img;
                                                var hourly_ss_img_multi;
                                                if (hour_img) {
                                                    if (hour_img[0]) {
                                                        hourly_ss_img = hour_img[0];
                                                        if (hour_img_multi) {
                                                            hourly_ss_img_multi = hour_img_multi;
                                                        }
                                                        // hourly_ss_img_multi = hour_img_multi[0]
                                                    }
                                                }
                                            }
                                        }
                                        return (
                                            <Grid
                                                container={true}
                                                key={index}
                                                style={{ marginTop: "40px" }}
                                                // id={this.state.Timetrackerkeys[index]}>
                                                id={
                                                    this.state.selectedTimezone === "" ||
                                                    !this.state.ttks[index]
                                                        ? this.state.Timetrackerkeys[index]
                                                        : this.state.ttks[index]
                                                }>
                                                <Grid
                                                    item={true}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                    className="img_view"
                                                    xs={12}
                                                    sm={12}
                                                    lg={12}>
                                                    {item1.map((items, index1) => {
                                                        let time_min =
                                                            index1 === 0 ? "00" : value[index1 - 1];
                                                        return typeof items !== "object" ? null : (
                                                            <div
                                                                key={index1}
                                                                className={`rounded-md overflow-hidden w-[163px] ${
                                                                    this.state.currFocCoordinates
                                                                        ?.hr === index &&
                                                                    this.state.currFocCoordinates
                                                                        ?.min /
                                                                        10 ===
                                                                        index1 + 1
                                                                        ? "ring-[3px] ring-secondary rounded shadow-secondary shadow-2xl"
                                                                        : ""
                                                                } border-2 lg-m:rounded ${
                                                                    this.state.selectedSSid.includes(
                                                                        items.id
                                                                    )
                                                                        ? "border-primary"
                                                                        : "border-gray-200"
                                                                }`}>
                                                                {value[index1] ===
                                                                "hourly_percentage" ? null : !items.id ||
                                                                  items.offline ? (
                                                                    <div
                                                                        className="Inactive_sty"
                                                                        key={index1}>
                                                                        {/* <p className="inactive_text">inactive</p> */}
                                                                        <Modaltracker
                                                                            image={items}
                                                                            hourly_thubm_image={
                                                                                hourly_thubm_img
                                                                            }
                                                                            hourly_ss_image={
                                                                                hourly_ss_img
                                                                            }
                                                                            hourly_thum_imge_multi={
                                                                                hourly_thubm_img_multi
                                                                            }
                                                                            hourly_ss_image_multi={
                                                                                hourly_ss_img_multi
                                                                            }
                                                                            ActivityIndex={
                                                                                this.state
                                                                                    .Trackertiming
                                                                            }
                                                                            selectedDate={
                                                                                this.state
                                                                                    .selectedDate
                                                                            }
                                                                            userName={
                                                                                this.state
                                                                                    .employeeId
                                                                            }
                                                                            Timekey={
                                                                                this.state
                                                                                    .selectedTimezone ===
                                                                                    "" ||
                                                                                !this.state.ttks[
                                                                                    index
                                                                                ]
                                                                                    ? this.state
                                                                                          .Timetrackerkeys[
                                                                                          index
                                                                                      ]
                                                                                    : this.state
                                                                                          .ttks[
                                                                                          index
                                                                                      ]
                                                                            }
                                                                            time_min={time_min}
                                                                            value={value[index1]}
                                                                            ReportName={"admin"}
                                                                            uniqueItem={items.video}
                                                                            active={{
                                                                                key_count:
                                                                                    items.key_count,
                                                                                mouse_count:
                                                                                    items.mouse_count,
                                                                                idle_time:
                                                                                    items.idle_time,
                                                                                item: items,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : items.key_count === 0 &&
                                                                  items.mouse_count === 0 ? (
                                                                    <>
                                                                        <div className="shadowBox relative group">
                                                                            <div
                                                                                className={`absolute z-10 top-1 left-1 w-5 h-5 rounded border border-gray-500 bg-gray ${
                                                                                    !this.state
                                                                                        .isScreenshotSelectActive &&
                                                                                    "hidden group-hover:block"
                                                                                }`}>
                                                                                <CheckIcon
                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            {
                                                                                                isScreenshotSelectActive: true,
                                                                                            }
                                                                                        );
                                                                                        let screenshotIdx =
                                                                                            this.state.selectedSSid.indexOf(
                                                                                                items.id
                                                                                            );
                                                                                        if (
                                                                                            screenshotIdx ===
                                                                                            -1
                                                                                        ) {
                                                                                            this.setState(
                                                                                                {
                                                                                                    selectedSSid:
                                                                                                        [
                                                                                                            ...this
                                                                                                                .state
                                                                                                                .selectedSSid,
                                                                                                            items.id,
                                                                                                        ],
                                                                                                }
                                                                                            );
                                                                                        } else {
                                                                                            let tmpArr =
                                                                                                [
                                                                                                    ...this
                                                                                                        .state
                                                                                                        .selectedSSid,
                                                                                                ];
                                                                                            tmpArr.splice(
                                                                                                screenshotIdx,
                                                                                                1
                                                                                            );

                                                                                            if (
                                                                                                tmpArr.length ===
                                                                                                0
                                                                                            ) {
                                                                                                this.setState(
                                                                                                    {
                                                                                                        isScreenshotSelectActive: false,
                                                                                                        selectedSSid:
                                                                                                            [],
                                                                                                    }
                                                                                                );
                                                                                            } else {
                                                                                                this.setState(
                                                                                                    {
                                                                                                        selectedSSid:
                                                                                                            tmpArr,
                                                                                                    }
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    className={
                                                                                        this.state.selectedSSid.includes(
                                                                                            items.id
                                                                                        )
                                                                                            ? "text-primary font-bold"
                                                                                            : "text-gray-300 font-bold"
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <Modaltracker
                                                                                image={items}
                                                                                open={
                                                                                    this.state
                                                                                        .openFromEnter &&
                                                                                    this.state
                                                                                        .currFocCoordinates
                                                                                        ?.hr ===
                                                                                        index &&
                                                                                    this.state
                                                                                        .currFocCoordinates
                                                                                        ?.min /
                                                                                        10 ===
                                                                                        index1 + 1
                                                                                }
                                                                                closeHandle={() => {
                                                                                    this.setState({
                                                                                        openFromEnter: false,
                                                                                    });
                                                                                }}
                                                                                hourly_thubm_image={
                                                                                    hourly_thubm_img
                                                                                }
                                                                                hourly_ss_image={
                                                                                    hourly_ss_img
                                                                                }
                                                                                hourly_thum_imge_multi={
                                                                                    hourly_thubm_img_multi
                                                                                }
                                                                                hourly_ss_image_multi={
                                                                                    hourly_ss_img_multi
                                                                                }
                                                                                ActivityIndex={
                                                                                    this.state
                                                                                        .Trackertiming
                                                                                }
                                                                                selectedDate={
                                                                                    this.state
                                                                                        .selectedDate
                                                                                }
                                                                                userName={
                                                                                    this.state
                                                                                        .employeeId
                                                                                }
                                                                                // Timekey={
                                                                                //     this.state
                                                                                //         .Timetrackerkeys[
                                                                                //         index
                                                                                //     ]
                                                                                // }
                                                                                Timekey={
                                                                                    this.state
                                                                                        .selectedTimezone ===
                                                                                        "" ||
                                                                                    !this.state
                                                                                        .ttks[index]
                                                                                        ? this.state
                                                                                              .Timetrackerkeys[
                                                                                              index
                                                                                          ]
                                                                                        : this.state
                                                                                              .ttks[
                                                                                              index
                                                                                          ]
                                                                                }
                                                                                time_min={time_min}
                                                                                value={
                                                                                    value[index1]
                                                                                }
                                                                                ReportName={"admin"}
                                                                                uniqueItem={
                                                                                    items.video[0]
                                                                                }
                                                                                active={{
                                                                                    key_count:
                                                                                        items.key_count,
                                                                                    mouse_count:
                                                                                        items.mouse_count,
                                                                                    idle_time:
                                                                                        items.idle_time,
                                                                                    item: items,
                                                                                }}
                                                                            />
                                                                            <div
                                                                                className="row"
                                                                                style={{
                                                                                    justifyContent:
                                                                                        "space-between",
                                                                                    padding:
                                                                                        "0px 10px 0px 20px",
                                                                                }}>
                                                                                {value[index1] ===
                                                                                "hourly_percentage" ? (
                                                                                    <div
                                                                                        style={{
                                                                                            margin: "2px",
                                                                                            fontSize:
                                                                                                "14px",
                                                                                        }}>
                                                                                        {
                                                                                            value[
                                                                                                index1
                                                                                            ]
                                                                                        }
                                                                                    </div>
                                                                                ) : value[
                                                                                      index1
                                                                                  ] !== "60" ? (
                                                                                    <div
                                                                                        style={{
                                                                                            margin: "2px",
                                                                                            fontSize:
                                                                                                "14px",
                                                                                        }}>
                                                                                        {/* {this.state.Timetrackerkeys[index]}: {time_min} - {this.state.Timetrackerkeys[index]}: {value[index1]} */}
                                                                                        {this.state
                                                                                            .selectedTimezone ===
                                                                                            "" ||
                                                                                        !this.state
                                                                                            .ttks[
                                                                                            index
                                                                                        ]
                                                                                            ? this
                                                                                                  .state
                                                                                                  .Timetrackerkeys[
                                                                                                  index
                                                                                              ]
                                                                                            : this
                                                                                                  .state
                                                                                                  .ttks[
                                                                                                  index
                                                                                              ]}
                                                                                        : {time_min}{" "}
                                                                                        -{" "}
                                                                                        {this.state
                                                                                            .selectedTimezone ===
                                                                                            "" ||
                                                                                        !this.state
                                                                                            .ttks[
                                                                                            index
                                                                                        ]
                                                                                            ? this
                                                                                                  .state
                                                                                                  .Timetrackerkeys[
                                                                                                  index
                                                                                              ]
                                                                                            : this
                                                                                                  .state
                                                                                                  .ttks[
                                                                                                  index
                                                                                              ]}
                                                                                        :{" "}
                                                                                        {
                                                                                            value[
                                                                                                index1
                                                                                            ]
                                                                                        }
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        style={{
                                                                                            margin: "2px",
                                                                                            fontSize:
                                                                                                "14px",
                                                                                        }}>
                                                                                        {/* {this.state.Timetrackerkeys[index]}: {time_min} - {1 + parseInt(this.state.Timetrackerkeys[index])}: 00 */}
                                                                                        {this.state
                                                                                            .selectedTimezone ===
                                                                                            "" ||
                                                                                        !this.state
                                                                                            .ttks[
                                                                                            index
                                                                                        ]
                                                                                            ? this
                                                                                                  .state
                                                                                                  .Timetrackerkeys[
                                                                                                  index
                                                                                              ]
                                                                                            : this
                                                                                                  .state
                                                                                                  .ttks[
                                                                                                  index
                                                                                              ]}
                                                                                        : {time_min}{" "}
                                                                                        -{" "}
                                                                                        {1 +
                                                                                            parseInt(
                                                                                                this
                                                                                                    .state
                                                                                                    .selectedTimezone ===
                                                                                                    "" ||
                                                                                                    !this
                                                                                                        .state
                                                                                                        .ttks[
                                                                                                        index
                                                                                                    ]
                                                                                                    ? this
                                                                                                          .state
                                                                                                          .Timetrackerkeys[
                                                                                                          index
                                                                                                      ]
                                                                                                    : this
                                                                                                          .state
                                                                                                          .ttks[
                                                                                                          index
                                                                                                      ]
                                                                                            )}
                                                                                        : 00
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : items.cancelled_time ? (
                                                                    <img
                                                                        className="Inactive_sty max-h-20"
                                                                        src={idelTime}
                                                                        alt="cancelled time"
                                                                    />
                                                                ) : (
                                                                    <Paper
                                                                        style={{
                                                                            width: "163px",
                                                                            height: "140px",
                                                                            // items.screenshots == ""
                                                                            //     ? "140px"
                                                                            //     : "190px",
                                                                        }}
                                                                        className="shadowBox relative group">
                                                                        <div
                                                                            className={`absolute z-10 top-1 left-1 w-5 h-5 rounded border border-gray-500 bg-gray ${
                                                                                !this.state
                                                                                    .isScreenshotSelectActive &&
                                                                                "hidden group-hover:block"
                                                                            }`}>
                                                                            <CheckIcon
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        isScreenshotSelectActive: true,
                                                                                    });
                                                                                    let screenshotIdx =
                                                                                        this.state.selectedSSid.indexOf(
                                                                                            items.id
                                                                                        );
                                                                                    if (
                                                                                        screenshotIdx ===
                                                                                        -1
                                                                                    ) {
                                                                                        this.setState(
                                                                                            {
                                                                                                selectedSSid:
                                                                                                    [
                                                                                                        ...this
                                                                                                            .state
                                                                                                            .selectedSSid,
                                                                                                        items.id,
                                                                                                    ],
                                                                                            }
                                                                                        );
                                                                                    } else {
                                                                                        let tmpArr =
                                                                                            [
                                                                                                ...this
                                                                                                    .state
                                                                                                    .selectedSSid,
                                                                                            ];
                                                                                        tmpArr.splice(
                                                                                            screenshotIdx,
                                                                                            1
                                                                                        );

                                                                                        if (
                                                                                            tmpArr.length ===
                                                                                            0
                                                                                        ) {
                                                                                            this.setState(
                                                                                                {
                                                                                                    isScreenshotSelectActive: false,
                                                                                                    selectedSSid:
                                                                                                        [],
                                                                                                }
                                                                                            );
                                                                                        } else {
                                                                                            this.setState(
                                                                                                {
                                                                                                    selectedSSid:
                                                                                                        tmpArr,
                                                                                                }
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                className={
                                                                                    this.state.selectedSSid.includes(
                                                                                        items.id
                                                                                    )
                                                                                        ? "text-primary font-bold"
                                                                                        : "text-gray-300 font-bold"
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={5}
                                                                            md={2}>
                                                                            <div
                                                                                onClick={
                                                                                    this.state
                                                                                        .isScreenshotSelectActive &&
                                                                                    !this.state
                                                                                        .openFromEnter
                                                                                        ? () => {
                                                                                              let screenshotIdx =
                                                                                                  this.state.selectedSSid.indexOf(
                                                                                                      items.id
                                                                                                  );
                                                                                              if (
                                                                                                  screenshotIdx ===
                                                                                                  -1
                                                                                              ) {
                                                                                                  this.setState(
                                                                                                      {
                                                                                                          selectedSSid:
                                                                                                              [
                                                                                                                  ...this
                                                                                                                      .state
                                                                                                                      .selectedSSid,
                                                                                                                  items.id,
                                                                                                              ],
                                                                                                      }
                                                                                                  );
                                                                                              } else {
                                                                                                  let tmpArr =
                                                                                                      [
                                                                                                          ...this
                                                                                                              .state
                                                                                                              .selectedSSid,
                                                                                                      ];
                                                                                                  tmpArr.splice(
                                                                                                      screenshotIdx,
                                                                                                      1
                                                                                                  );
                                                                                                  if (
                                                                                                      tmpArr.length ===
                                                                                                      0
                                                                                                  ) {
                                                                                                      this.setState(
                                                                                                          {
                                                                                                              isScreenshotSelectActive: false,
                                                                                                              selectedSSid:
                                                                                                                  [],
                                                                                                          }
                                                                                                      );
                                                                                                  } else {
                                                                                                      this.setState(
                                                                                                          {
                                                                                                              selectedSSid:
                                                                                                                  tmpArr,
                                                                                                          }
                                                                                                      );
                                                                                                  }
                                                                                              }
                                                                                          }
                                                                                        : () => {}
                                                                                }
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                }}>
                                                                                <Modaltracker
                                                                                    image={items}
                                                                                    openDisabled={
                                                                                        this.state
                                                                                            .isScreenshotSelectActive
                                                                                    }
                                                                                    open={
                                                                                        this.state
                                                                                            .openFromEnter &&
                                                                                        this.state
                                                                                            .currFocCoordinates
                                                                                            ?.hr ===
                                                                                            index &&
                                                                                        this.state
                                                                                            .currFocCoordinates
                                                                                            ?.min /
                                                                                            10 ===
                                                                                            index1 +
                                                                                                1
                                                                                    }
                                                                                    closeHandle={() => {
                                                                                        this.setState(
                                                                                            {
                                                                                                openFromEnter: false,
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                    hourly_thubm_image={
                                                                                        hourly_thubm_img
                                                                                    }
                                                                                    hourly_ss_image={
                                                                                        hourly_ss_img
                                                                                    }
                                                                                    hourly_thum_imge_multi={
                                                                                        hourly_thubm_img_multi
                                                                                    }
                                                                                    hourly_ss_image_multi={
                                                                                        hourly_ss_img_multi
                                                                                    }
                                                                                    ActivityIndex={
                                                                                        this.state
                                                                                            .Trackertiming
                                                                                    }
                                                                                    selectedDate={
                                                                                        this.state
                                                                                            .selectedDate
                                                                                    }
                                                                                    userName={
                                                                                        this.state
                                                                                            .employeeId
                                                                                    }
                                                                                    Timekey={
                                                                                        this.state
                                                                                            .selectedTimezone ===
                                                                                            "" ||
                                                                                        !this.state
                                                                                            .ttks[
                                                                                            index
                                                                                        ]
                                                                                            ? this
                                                                                                  .state
                                                                                                  .Timetrackerkeys[
                                                                                                  index
                                                                                              ]
                                                                                            : this
                                                                                                  .state
                                                                                                  .ttks[
                                                                                                  index
                                                                                              ]
                                                                                    }
                                                                                    time_min={
                                                                                        time_min
                                                                                    }
                                                                                    value={
                                                                                        value[
                                                                                            index1
                                                                                        ]
                                                                                    }
                                                                                    ReportName={
                                                                                        "admin"
                                                                                    }
                                                                                    active={{
                                                                                        key_count:
                                                                                            items.key_count,
                                                                                        mouse_count:
                                                                                            items.mouse_count,
                                                                                        idle_time:
                                                                                            items.idle_time,
                                                                                        item: items,
                                                                                    }}
                                                                                />
                                                                            </div>

                                                                            <Divider />

                                                                            <Grid
                                                                                style={{
                                                                                    width: "163px",
                                                                                }}>
                                                                                <div
                                                                                    className="row"
                                                                                    style={{
                                                                                        justifyContent:
                                                                                            "space-between",
                                                                                        padding:
                                                                                            "0px 10px 0px 20px",
                                                                                    }}>
                                                                                    {value[
                                                                                        index1
                                                                                    ] ===
                                                                                    "hourly_percentage" ? (
                                                                                        <div
                                                                                            style={{
                                                                                                margin: "2px",
                                                                                                fontSize:
                                                                                                    "14px",
                                                                                            }}>
                                                                                            {
                                                                                                value[
                                                                                                    index1
                                                                                                ]
                                                                                            }
                                                                                        </div>
                                                                                    ) : value[
                                                                                          index1
                                                                                      ] !== "60" ? (
                                                                                        <>
                                                                                            {/* <div 
                                                                                            style={{
                                                                                                margin: "2px",
                                                                                                fontSize:
                                                                                                    "14px",
                                                                                            }}>
                                                                                            {settingTime(index1,time_min,this.state.selectedTimezone
                                                                                                )}
                                                                                        </div> */}

                                                                                            <div
                                                                                                style={{
                                                                                                    margin: "2px",
                                                                                                    fontSize:
                                                                                                        "14px",
                                                                                                }}>
                                                                                                {this
                                                                                                    .state
                                                                                                    .selectedTimezone ===
                                                                                                    "" ||
                                                                                                !this
                                                                                                    .state
                                                                                                    .ttks[
                                                                                                    index
                                                                                                ]
                                                                                                    ? this
                                                                                                          .state
                                                                                                          .Timetrackerkeys[
                                                                                                          index
                                                                                                      ]
                                                                                                    : this
                                                                                                          .state
                                                                                                          .ttks[
                                                                                                          index
                                                                                                      ]}
                                                                                                :
                                                                                                {
                                                                                                    time_min
                                                                                                }{" "}
                                                                                                -{" "}
                                                                                                {this
                                                                                                    .state
                                                                                                    .selectedTimezone ===
                                                                                                    "" ||
                                                                                                !this
                                                                                                    .state
                                                                                                    .ttks[
                                                                                                    index
                                                                                                ]
                                                                                                    ? this
                                                                                                          .state
                                                                                                          .Timetrackerkeys[
                                                                                                          index
                                                                                                      ]
                                                                                                    : this
                                                                                                          .state
                                                                                                          .ttks[
                                                                                                          index
                                                                                                      ]}
                                                                                                :
                                                                                                {
                                                                                                    value[
                                                                                                        index1
                                                                                                    ]
                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <div
                                                                                            style={{
                                                                                                margin: "2px",
                                                                                                fontSize:
                                                                                                    "14px",
                                                                                            }}>
                                                                                            {this
                                                                                                .state
                                                                                                .selectedTimezone ===
                                                                                                "" ||
                                                                                            !this
                                                                                                .state
                                                                                                .ttks[
                                                                                                index
                                                                                            ]
                                                                                                ? this
                                                                                                      .state
                                                                                                      .Timetrackerkeys[
                                                                                                      index
                                                                                                  ]
                                                                                                : this
                                                                                                      .state
                                                                                                      .ttks[
                                                                                                      index
                                                                                                  ]}
                                                                                            :
                                                                                            {
                                                                                                time_min
                                                                                            }{" "}
                                                                                            -{" "}
                                                                                            {1 +
                                                                                                parseInt(
                                                                                                    this
                                                                                                        .state
                                                                                                        .selectedTimezone ===
                                                                                                        "" ||
                                                                                                        !this
                                                                                                            .state
                                                                                                            .ttks[
                                                                                                            index
                                                                                                        ]
                                                                                                        ? this
                                                                                                              .state
                                                                                                              .Timetrackerkeys[
                                                                                                              index
                                                                                                          ]
                                                                                                        : this
                                                                                                              .state
                                                                                                              .ttks[
                                                                                                              index
                                                                                                          ]
                                                                                                )}
                                                                                            : 00
                                                                                        </div>
                                                                                    )}
                                                                                </div>

                                                                                {value[index1] ===
                                                                                "hourly_percentage" ? (
                                                                                    <div
                                                                                        style={{
                                                                                            padding:
                                                                                                "4px",
                                                                                        }}>
                                                                                        <ProgressBar
                                                                                            style={{
                                                                                                height: "10px",
                                                                                            }}
                                                                                            className="custom"
                                                                                            now={
                                                                                                items
                                                                                            }
                                                                                            // now="20"
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        style={{
                                                                                            padding:
                                                                                                "4px",
                                                                                        }}>
                                                                                        <ProgressBar
                                                                                            className="custom"
                                                                                            variant="purple"
                                                                                            style={{
                                                                                                height: "10px",
                                                                                            }}
                                                                                            // now={items.activity_points * 10}
                                                                                            now={
                                                                                                items.activity_points *
                                                                                                10
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                )}

                                                                                {/* {items.screenshots == "" ? (
                                                                            <></>
                                                                        ) : (
                                                                            <div className="tracker_img">
                                                                                {this.progressActivity(
                                                                                    items.screenshots
                                                                                )}
                                                                            </div>
                                                                        )} */}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Paper>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="flex justify-center items-end min-w-full h-[45vh]">
                                    <div className="text-center">
                                        <img
                                            src={selectEmployeeTimeTracker}
                                            className="w-56 h-56"
                                            alt="select employee"
                                        />
                                        <div className="italic text-gray-500">
                                            Select an Employee to see Data
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap items-center justify-start gap-3 min-w-full mt-10">
                                    {screenshotEmployeeData &&
                                        screenshotEmployeeData.map((emp, idx) => (
                                            <div
                                                key={idx + "emp"}
                                                className="flex justify-center items-center m-1 py-2 px-3 bg-white cursor-pointer rounded-full text-primary font-medium text-base leading-none"
                                                id={emp.id}
                                                onClick={(e) => {
                                                    const em = {
                                                        target: {
                                                            value: e.target.id,
                                                        },
                                                    };
                                                    this.onEmployeeScreenshotChange(em);
                                                }}>
                                                {emp.name}
                                            </div>
                                        ))}
                                </div>
                            </>
                        )}
                    </>
                ) : null}
                {this.state.SelectedTab === "cpuinformation" ? (
                    <>
                        <div className="mt-2 bg-white px-2 py-2 border-b border-gray-200 rounded-lg">
                            <div className="flex flex-col items-start lg:items-center justify-start lg:justify-between flex-wrap lg:flex-row">
                                <div className="flex text-textprimary text-lg font-medium lg-m:w-full">
                                    <DatePicker
                                        className="rounded-md lg-m:w-full"
                                        dateFormat="MMMM dd yyyy"
                                        selected={this.state.selectedDate}
                                        onChange={(date) =>
                                            this.handleCPUInformationDateChange(date)
                                        }
                                    />
                                    <CalendarIcon className="-ml-6 z-10 w-5"></CalendarIcon>
                                </div>
                                <div className="flex flex-row flex-wrap items-end justify-between mt-4 lg:mt-0 lg-m:w-full">
                                    <div className="ml-0 lg:ml-4 w-56 md-m:w-full">
                                        <SelectField
                                            onSelectChange={(e) =>
                                                this.onEmployeeCpuInformationChange(e, employees)
                                            }
                                            FieldName={t("select") + " " + t("employee")}
                                            name={"employee"}
                                            arrayOfData={CPUEmployeeData}
                                            id={"employee"}
                                            value={
                                                this.state.employeeId === ""
                                                    ? ""
                                                    : this.state.employeeId
                                            }></SelectField>
                                    </div>
                                    <div className="w-60 md-m:w-full ml-2 lg:ml-3 mt-4 lg:mt-0">
                                        <TimezoneSelect
                                            placeholder={t("pleaseSelectTimeZone")}
                                            className="rounded-lg border-none mr-0 lg:mr-4 w-60 md-m:w-full"
                                            value={this.state.selectedTimezone}
                                            timezones={{ ...allTimezones }}
                                            // onChange={(e) => this.onTimeZoneChange(e)}
                                            onChange={(e) => {
                                                this.setState({
                                                    selectedTimezone: e.value,
                                                });
                                                this.onTimeZoneChange(e);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 bg-white px-3 py-3 border-b border-gray-200 rounded-lg">
                            <div className="text-lg text-textprimary font-semibold">
                                {t("timeTracker.userSystemPerformanceInfo")}
                            </div>
                            <div className="flex items-center bg-white mt-4 dark:bg-white">
                                <div className="container mx-auto">
                                    <div className="grid gap-2 sm:grid-cols-2 lg:grid-cols-4">
                                        <div
                                            className={`items-center justify-between p-3 bg-cpucolor rounded shadow-sm cursor-pointer ${
                                                this.state.cpuTab === "cpu"
                                                    ? "transition ease-in-out delay-110 ring-2 ring-primary ring-offset-2"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                this.setState({
                                                    cpuTab: "cpu",
                                                });
                                                window.history.replaceState(
                                                    {},
                                                    null,
                                                    `/timetracker/${
                                                        this.state.SelectedTab
                                                    }/cpu/?empname=${
                                                        this.state.employeeId
                                                    }&project=${
                                                        this.state.projectname
                                                    }&date=${moment(this.state.selectedDate).format(
                                                        "YYYY-MM-DD"
                                                    )}&timezone=${""}`
                                                );
                                            }}>
                                            <div className="text-lg font-semibold text-primary ">
                                                CPU
                                            </div>
                                            <div className="text-base text-textprimary mt-3">
                                                {t("timeTracker.latest")}{" "}
                                                <span className="float-right text-base">
                                                    {this.state.cpu_info.length === 0
                                                        ? 0
                                                        : this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].cpu_per === undefined ||
                                                          this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].cpu_per === ""
                                                        ? 0
                                                        : parseInt(
                                                              this.state.cpu_info[
                                                                  this.state.cpu_info.length - 1
                                                              ].cpu_per
                                                          )}
                                                    % &nbsp;&nbsp;
                                                    {this.state.cpu_info.length === 0 ||
                                                    !this.state.cpu_info[0].cpu_speed
                                                        ? 0
                                                        : this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].cpu_speed
                                                              .slice(0, 2)
                                                              .replace(
                                                                  /\B(?=(\d{1})+(?!\d))/g,
                                                                  "."
                                                              )}{" "}
                                                    GHz
                                                </span>
                                            </div>
                                            <div className="text-base text-textprimary mt-2">
                                                {t("last")} {t("timeTracker.record")}{" "}
                                                <span className="float-right text-base">
                                                    {this.state.cpu_info.length < 2
                                                        ? 0
                                                        : this.state.cpu_info[
                                                              this.state.cpu_info.length - 2
                                                          ].cpu_per === undefined ||
                                                          this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].cpu_per === ""
                                                        ? 0
                                                        : parseInt(
                                                              this.state.cpu_info[
                                                                  this.state.cpu_info.length - 2
                                                              ].cpu_per
                                                          )}
                                                    % &nbsp;&nbsp;
                                                    {this.state.cpu_info.length === 0 ||
                                                    this.state.cpu_info[0].cpu_speed === null ||
                                                    undefined
                                                        ? 0
                                                        : this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].cpu_speed
                                                              .slice(0, 2)
                                                              .replace(
                                                                  /\B(?=(\d{1})+(?!\d))/g,
                                                                  "."
                                                              )}{" "}
                                                    GHz
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className={`items-center justify-between p-3 bg-memorycolor rounded shadow-sm cursor-pointer ${
                                                this.state.cpuTab === "memory"
                                                    ? "transition ease-in-out delay-110 ring-2 ring-primary ring-offset-2"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                this.setState({
                                                    cpuTab: "memory",
                                                });
                                                window.history.replaceState(
                                                    {},
                                                    null,
                                                    `/timetracker/${
                                                        this.state.SelectedTab
                                                    }/memory/?empname=${
                                                        this.state.employeeId
                                                    }&project=${
                                                        this.state.projectname
                                                    }&date=${moment(this.state.selectedDate).format(
                                                        "YYYY-MM-DD"
                                                    )}&timezone=${""}`
                                                );
                                            }}>
                                            <div className="text-lg font-semibold text-primary ">
                                                {t("timeTracker.memory")}
                                            </div>
                                            <div className="text-base text-textprimary mt-3">
                                                {t("timeTracker.latest")}{" "}
                                                <span className="float-right text-base">
                                                    {this.state.cpu_info.length === 0
                                                        ? 0
                                                        : this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].cpu_per === undefined
                                                        ? 0
                                                        : (
                                                              parseInt(
                                                                  this.state.cpu_info[
                                                                      this.state.cpu_info.length - 1
                                                                  ].consumed_memory
                                                              ) /
                                                              (1000 * 1000 * 1000)
                                                          ).toFixed(2)}{" "}
                                                    &nbsp;&nbsp; /
                                                    {this.state.cpu_info.length === 0
                                                        ? 0
                                                        : this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].cpu_per === undefined
                                                        ? 0
                                                        : (
                                                              parseInt(
                                                                  this.state.cpu_info[
                                                                      this.state.cpu_info.length - 1
                                                                  ].total_memory
                                                              ) /
                                                              (1000 * 1000 * 1000)
                                                          ).toFixed(2)}
                                                    GB &nbsp;&nbsp;
                                                </span>
                                            </div>
                                            <div className="text-base text-textprimary mt-2">
                                                {t("last")} {t("timeTracker.record")}{" "}
                                                <span className="float-right text-base">
                                                    {this.state.cpu_info.length < 2
                                                        ? 0
                                                        : this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].cpu_per === undefined
                                                        ? 0
                                                        : (
                                                              parseInt(
                                                                  this.state.cpu_info[
                                                                      this.state.cpu_info.length - 2
                                                                  ].consumed_memory
                                                              ) /
                                                              (1000 * 1000 * 1000)
                                                          ).toFixed(2)}{" "}
                                                    &nbsp;&nbsp; /
                                                    {this.state.cpu_info.length === 0
                                                        ? 0
                                                        : this.state.cpu_info.length < 2
                                                        ? (
                                                              parseInt(
                                                                  this.state.cpu_info[
                                                                      this.state.cpu_info.length - 1
                                                                  ].total_memory
                                                              ) /
                                                              (1000 * 1000 * 1000)
                                                          ).toFixed(2)
                                                        : this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].cpu_per === undefined
                                                        ? 0
                                                        : (
                                                              parseInt(
                                                                  this.state.cpu_info[
                                                                      this.state.cpu_info.length - 2
                                                                  ].total_memory
                                                              ) /
                                                              (1000 * 1000 * 1000)
                                                          ).toFixed(2)}
                                                    GB &nbsp;&nbsp;
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className={`items-center justify-between p-3 bg-diskcolor rounded shadow-sm cursor-pointer ${
                                                this.state.cpuTab === "disk"
                                                    ? "transition ease-in-out delay-110 ring-2 ring-primary ring-offset-2"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                this.setState({
                                                    cpuTab: "disk",
                                                });
                                                window.history.replaceState(
                                                    {},
                                                    null,
                                                    `/timetracker/${
                                                        this.state.SelectedTab
                                                    }/disk/?empname=${
                                                        this.state.employeeId
                                                    }&project=${
                                                        this.state.projectname
                                                    }&date=${moment(this.state.selectedDate).format(
                                                        "YYYY-MM-DD"
                                                    )}&timezone=${""}`
                                                );
                                            }}>
                                            <div className="text-lg font-semibold text-primary ">
                                                {t("timeTracker.disk")}
                                            </div>
                                            <div className="text-base text-textprimary mt-3">
                                                {t("timeTracker.latest")}{" "}
                                                <span className="float-right text-base">
                                                    {this.state.cpu_info.length === 0
                                                        ? 0
                                                        : this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].disk_percentage === null
                                                        ? 0
                                                        : parseInt(
                                                              this.state.cpu_info[
                                                                  this.state.cpu_info.length - 1
                                                              ].disk_percentage
                                                          )}{" "}
                                                    %
                                                </span>
                                            </div>
                                            <div className="text-base text-textprimary mt-2">
                                                {t("last")} {t("timeTracker.record")}{" "}
                                                <span className="float-right text-base">
                                                    {this.state.cpu_info.length < 2
                                                        ? 0
                                                        : !this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ] ||
                                                          this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].disk_percentage === null
                                                        ? 0
                                                        : parseInt(
                                                              this.state.cpu_info[
                                                                  this.state.cpu_info.length - 2
                                                              ].disk_percentage
                                                          )}{" "}
                                                    %
                                                </span>
                                            </div>
                                        </div>

                                        <div
                                            className={`items-center justify-between p-3 bg-networkcolor rounded shadow-sm cursor-pointer ${
                                                this.state.cpuTab === "network"
                                                    ? "transition ease-in-out delay-110 ring-2 ring-primary ring-offset-2"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                this.setState({
                                                    cpuTab: "network",
                                                });
                                                window.history.replaceState(
                                                    {},
                                                    null,
                                                    `/timetracker/${
                                                        this.state.SelectedTab
                                                    }/network/?empname=${
                                                        this.state.employeeId
                                                    }&project=${
                                                        this.state.projectname
                                                    }&date=${moment(this.state.selectedDate).format(
                                                        "YYYY-MM-DD"
                                                    )}&timezone=${""}`
                                                );
                                            }}>
                                            <div className="text-lg font-semibold text-primary ">
                                                {t("timeTracker.network")}
                                            </div>
                                            <div className="text-base text-textprimary mt-3">
                                                {t("timeTracker.latest")}{" "}
                                                <span className="float-right text-sm">
                                                    S:{" "}
                                                    {this.state.cpu_info.length < 1 ||
                                                    this.state.cpu_info[
                                                        this.state.cpu_info.length - 1
                                                    ].uploadspeed === ""
                                                        ? 0
                                                        : parseFloat(
                                                              this.state.cpu_info[
                                                                  this.state.cpu_info.length - 1
                                                              ].uploadspeed
                                                          ).toFixed(2)}{" "}
                                                    MB/s R:{" "}
                                                    {this.state.cpu_info.length === 0 ||
                                                    this.state.cpu_info[
                                                        this.state.cpu_info.length - 1
                                                    ].downloadspeed === 0
                                                        ? 0
                                                        : parseFloat(
                                                              this.state.cpu_info[
                                                                  this.state.cpu_info.length - 1
                                                              ].downloadspeed
                                                          ).toFixed(2)}{" "}
                                                    MB/s
                                                </span>
                                            </div>
                                            <div className="text-base text-textprimary mt-2">
                                                {t("last")} {t("timeTracker.record")}
                                                <span className="float-right text-sm">
                                                    S:{" "}
                                                    {this.state.cpu_info.length < 2 ||
                                                    this.state.cpu_info[
                                                        this.state.cpu_info.length - 1
                                                    ].uploadspeed === ""
                                                        ? 0
                                                        : parseFloat(
                                                              this.state.cpu_info[
                                                                  this.state.cpu_info.length - 2
                                                              ].uploadspeed
                                                          ).toFixed(2)}{" "}
                                                    MB/s R:{" "}
                                                    {this.state.cpu_info.length < 2 ||
                                                    this.state.cpu_info[
                                                        this.state.cpu_info.length - 1
                                                    ].downloadspeed === 0
                                                        ? 0
                                                        : parseFloat(
                                                              this.state.cpu_info[
                                                                  this.state.cpu_info.length - 2
                                                              ].downloadspeed
                                                          ).toFixed(2)}{" "}
                                                    MB/s
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.cpuTab === "cpu" ? (
                            <div>
                                {this.state.showChart === true ? (
                                    <div className="mt-4 bg-white rounded-md shadow-md p-4">
                                        <ReactHighcharts config={cpu_info_Chart}></ReactHighcharts>
                                        <div className="flex flex-col mt-3">
                                            <div className="flex mr-6">
                                                <div className="mr-2 flex items-center">
                                                    <div className="h-2 w-2 rounded-full bg-black mr-1"></div>
                                                    {t("settingsTab.cpuInfo")}:
                                                </div>
                                                <div>
                                                    {this.state.cpu_info.length === 0
                                                        ? 0
                                                        : this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].cpu_per === undefined
                                                        ? ""
                                                        : this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].cpu_info}{" "}
                                                    &nbsp;&nbsp;
                                                </div>
                                            </div>
                                            <div className="flex mt-1">
                                                <div className="mr-2 flex items-center">
                                                    <div className="h-2 w-2 rounded-full bg-black mr-1"></div>
                                                    {t("timeTracker.cpuSpeed")}:
                                                </div>
                                                <div>
                                                    {this.state.cpu_info.length === 0
                                                        ? 0
                                                        : this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].cpu_per === undefined ||
                                                          this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].cpu_per === ""
                                                        ? 0
                                                        : parseInt(
                                                              this.state.cpu_info[
                                                                  this.state.cpu_info.length - 1
                                                              ].cpu_per
                                                          )}
                                                    % &nbsp;&nbsp;
                                                    {this.state.cpu_info.length === 0 ||
                                                    !this.state.cpu_info[0].cpu_speed
                                                        ? 0
                                                        : this.state.cpu_info[
                                                              this.state.cpu_info.length - 1
                                                          ].cpu_speed
                                                              .slice(0, 2)
                                                              .replace(
                                                                  /\B(?=(\d{1})+(?!\d))/g,
                                                                  "."
                                                              )}{" "}
                                                    GHz
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <NoDataContainer />
                                )}
                                {this.state.showProgramList ? (
                                    <div>
                                        <div className="bg-white rounded-md shadow-sm mt-3 p-5">
                                            <div className="flex mt-4">
                                                {" "}
                                                <ExclamationCircleIcon className="h-6 w-6 mr-2 text-primary" />{" "}
                                                <span className="text-base text-textprimary font-semibold">
                                                    {t("timeTracker.outputLogsAlert")}
                                                    ..
                                                </span>
                                            </div>
                                            {this.state.applogsdata.length === 0 ? (
                                                <div className="mt-12 text-center mb-12 italic font-medium text-gray-500">
                                                    <CollectionIcon className="m-auto w-24 h-24"></CollectionIcon>
                                                    {t("timeTracker.noRecordsFoundForSelectedTime")}
                                                </div>
                                            ) : (
                                                <div className="mt-6 flex flex-col overflow-y-auto">
                                                    <div className="flex flex-wrap">
                                                        {this.state.applogsdata.length !== 0 &&
                                                            this.state.applogsdata.map(
                                                                (el, idx) => {
                                                                    return (
                                                                        <div
                                                                            key={idx}
                                                                            className="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-primary bg-gray-100">
                                                                            <div className="text-base font-normal leading-none max-w-full flex-initial p-2">
                                                                                {el.name}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                        {this.state.cpuTab === "memory" ? (
                            <div>
                                <div>
                                    {this.state.showChart === true ? (
                                        <div className="mt-4 bg-white rounded-md shadow-md p-4">
                                            <ReactHighcharts config={memoryChart}></ReactHighcharts>
                                            <div className="flex flex-col mt-3">
                                                <div className="flex mr-6">
                                                    <div className="mr-2 flex items-center">
                                                        <div className="h-2 w-2 rounded-full bg-black mr-1"></div>
                                                        {t("timeTracker.inUse")}:
                                                    </div>
                                                    <div>
                                                        {this.state.cpu_info.length === 0
                                                            ? 0
                                                            : this.state.cpu_info[
                                                                  this.state.cpu_info.length - 1
                                                              ].cpu_per === undefined
                                                            ? 0
                                                            : (
                                                                  parseInt(
                                                                      this.state.cpu_info[
                                                                          this.state.cpu_info
                                                                              .length - 1
                                                                      ].consumed_memory
                                                                  ) /
                                                                  (1000 * 1000 * 1000)
                                                              ).toFixed(2)}
                                                        GB &nbsp;&nbsp;
                                                    </div>
                                                </div>
                                                <div className="flex mt-1">
                                                    <div className="mr-2 flex items-center">
                                                        <div className="h-2 w-2 rounded-full bg-black mr-1"></div>
                                                        {t("settingsTab.available")}:
                                                    </div>
                                                    <div>
                                                        {this.state.cpu_info.length === 0
                                                            ? 0
                                                            : this.state.cpu_info[
                                                                  this.state.cpu_info.length - 1
                                                              ].cpu_per === undefined
                                                            ? 0
                                                            : (
                                                                  parseInt(
                                                                      this.state.cpu_info[
                                                                          this.state.cpu_info
                                                                              .length - 1
                                                                      ].total_memory
                                                                  ) /
                                                                  (1000 * 1000 * 1000)
                                                              ).toFixed(2)}
                                                        GB &nbsp;&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <NoDataContainer />
                                    )}
                                </div>

                                {this.state.showProgramList ? (
                                    <div>
                                        <div className="bg-white rounded-md shadow-sm mt-3 p-5">
                                            <div className="flex mt-4">
                                                {" "}
                                                <ExclamationCircleIcon className="h-6 w-6 mr-2 text-primary" />{" "}
                                                <span className="text-base text-textprimary font-semibold">
                                                    {t("timeTracker.outputLogsAlert")}
                                                    ..
                                                </span>
                                            </div>
                                            {this.state.applogsdata.length === 0 ? (
                                                <div className="mt-12 text-center mb-12 italic font-medium text-gray-500">
                                                    <CollectionIcon className="m-auto w-24 h-24"></CollectionIcon>
                                                    {t("timeTracker.noRecordsFoundForSelectedTime")}
                                                </div>
                                            ) : (
                                                <div className="mt-6 flex flex-col overflow-y-auto">
                                                    <div className="flex flex-wrap">
                                                        {this.state.applogsdata.length > 0 &&
                                                            this.state.applogsdata.map(
                                                                (el, idx) => {
                                                                    return (
                                                                        <div
                                                                            key={idx}
                                                                            className="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-primary bg-gray-100">
                                                                            <div className="text-base font-normal leading-none max-w-full flex-initial p-2">
                                                                                {el.name}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                        {this.state.cpuTab === "disk" ? (
                            <div>
                                <div>
                                    {this.state.showChart === true ? (
                                        <div className="mt-4 bg-white rounded-md shadow-md p-4">
                                            <ReactHighcharts config={diskChart}></ReactHighcharts>
                                            <div className="flex flex-col mt-3">
                                                <div className="flex mr-6">
                                                    <div className="mr-2 flex items-center">
                                                        <div className="h-2 w-2 rounded-full bg-black mr-1"></div>
                                                        {t("timeTracker.inUse")}:
                                                    </div>
                                                    <div>
                                                        {this.state.cpu_info.length === 0
                                                            ? 0
                                                            : this.state.cpu_info[
                                                                  this.state.cpu_info.length - 1
                                                              ].used === undefined ||
                                                              this.state.cpu_info[
                                                                  this.state.cpu_info.length - 1
                                                              ].used === "no data"
                                                            ? "Data not found"
                                                            : (
                                                                  parseInt(
                                                                      this.state.cpu_info[
                                                                          this.state.cpu_info
                                                                              .length - 1
                                                                      ].used
                                                                  ) /
                                                                  (1000 * 1000 * 1000)
                                                              ).toFixed(2)}
                                                        GB &nbsp;&nbsp;
                                                    </div>
                                                </div>
                                                <div className="flex mt-1">
                                                    <div className="mr-2 flex items-center">
                                                        <div className="h-2 w-2 rounded-full bg-black mr-1"></div>
                                                        {t("total")}:
                                                    </div>
                                                    <div>
                                                        {this.state.cpu_info.length === 0
                                                            ? 0
                                                            : this.state.cpu_info[
                                                                  this.state.cpu_info.length - 1
                                                              ].all === undefined ||
                                                              this.state.cpu_info[
                                                                  this.state.cpu_info.length - 1
                                                              ].all === "no data"
                                                            ? "Data not found"
                                                            : (
                                                                  parseInt(
                                                                      this.state.cpu_info[
                                                                          this.state.cpu_info
                                                                              .length - 1
                                                                      ].all
                                                                  ) /
                                                                  (1000 * 1000 * 1000)
                                                              ).toFixed(2)}
                                                        GB &nbsp;&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <NoDataContainer />
                                    )}
                                </div>
                            </div>
                        ) : null}
                        {this.state.cpuTab === "network" ? (
                            <div>
                                <div>
                                    {this.state.showChart === true ? (
                                        <div className="mt-4 bg-white rounded-md shadow-md p-4">
                                            <ReactHighcharts
                                                config={networkChart}></ReactHighcharts>
                                            <div className="flex flex-col mt-3">
                                                <div className="flex mr-6">
                                                    <div className="mr-2 flex items-center">
                                                        <div className="h-2 w-2 rounded-full bg-black mr-1"></div>
                                                        {t("timeTracker.uploadSpeed")}:
                                                    </div>
                                                    <div>
                                                        {this.state.cpu_info.length === 0
                                                            ? 0
                                                            : this.state.cpu_info[
                                                                  this.state.cpu_info.length - 1
                                                              ].cpu_per === undefined ||
                                                              this.state.cpu_info[
                                                                  this.state.cpu_info.length - 1
                                                              ].uploadspeed === 0
                                                            ? 0
                                                            : parseInt(
                                                                  this.state.cpu_info[
                                                                      this.state.cpu_info.length - 1
                                                                  ].uploadspeed
                                                              ).toFixed(2)}
                                                        MB/s &nbsp;&nbsp;
                                                    </div>
                                                </div>
                                                <div className="flex mt-1">
                                                    <div className="mr-2 flex items-center">
                                                        <div className="h-2 w-2 rounded-full bg-black mr-1"></div>
                                                        {t("timeTracker.downloadSpeed")}:
                                                    </div>
                                                    <div>
                                                        {this.state.cpu_info.length === 0
                                                            ? 0
                                                            : this.state.cpu_info[
                                                                  this.state.cpu_info.length - 1
                                                              ].cpu_per === undefined ||
                                                              this.state.cpu_info[
                                                                  this.state.cpu_info.length - 1
                                                              ].downloadspeed === 0
                                                            ? 0
                                                            : parseInt(
                                                                  this.state.cpu_info[
                                                                      this.state.cpu_info.length - 1
                                                                  ].downloadspeed
                                                              ).toFixed(2)}
                                                        MB/s &nbsp;&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <NoDataContainer />
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </>
                ) : null}
                {this.state.SelectedTab === "activitylog" ? (
                    <div>
                        <div className="bg-white rounded-md shadow-sm mt-4">
                            <div className=" px-2 py-1 sm:px-6 rounded-lg">
                                <div className="sm:hidden">
                                    <label htmlFor="tabs" className="sr-only">
                                        {t("requestsModule.selectATab")}
                                    </label>
                                    <select
                                        id="tabs"
                                        name="tabs"
                                        onChange={(e) => {}}
                                        className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                                        defaultValue={this.state.activitySubTabs}>
                                        {/* <option key="basic_info" value="basic_info">Basic-Info</option> */}
                                        <option key="projects" value="projects">
                                            Browser Activity Log
                                        </option>
                                    </select>
                                </div>
                                <div className="hidden sm:block">
                                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                        {/* <a key="basic_info" onClick={() => this.HandleSelectTab("basic_info")}
                                        className={classNames(
                                            this.state.SelectedTab === "basic_info"
                                                ? 'border-primary text-primary'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                            'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer'
                                        )}
                                        aria-current={this.state.SelectedTab === "basic_info" ? 'page' : undefined}
                                    >
                                        <InformationCircleIcon
                                            className={classNames(
                                                this.state.SelectedTab === "basic_info" ? 'text-primary' : 'text-gray-400 group-hover:text-gray-500',
                                                '-ml-0.5 mr-2 h-5 w-5'
                                            )}
                                            aria-hidden="true"
                                        />
                                        <span>Basic_Info</span>
                                    </a> */}

                                        <div
                                            key="BrowserActivity"
                                            onClick={() => {}}
                                            className={classNames(
                                                this.state.activitySubTabs === "BrowserActivity"
                                                    ? "border-primary text-primary"
                                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                                "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                            )}
                                            aria-current={
                                                this.state.activitySubTabs === "BrowserActivity"
                                                    ? "page"
                                                    : undefined
                                            }>
                                            <Web
                                                className={classNames(
                                                    this.state.activitySubTabs === "BrowserActivity"
                                                        ? "text-primary"
                                                        : "text-gray-400 group-hover:text-gray-500",
                                                    "-ml-0.5 mr-2 h-5 w-5"
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span>{t("timeTracker.browserActivityLog")}</span>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-start lg-m:justify-between items-stretch flex-col lg:flex-row lg:justify-between lg:items-end bg-white rounded-md shadow-sm mt-3 p-5">
                            <div className="flex items-center cursor-pointer">
                                <input
                                    value={`${new Date(
                                        this.state.startDate
                                    ).toDateString()} - ${new Date(
                                        this.state.endDate
                                    ).toDateString()}`}
                                    disabled
                                    className="bg-white w-[310px] lg-m:w-full p-2 rounded-md font-medium text-gray-600 border border-gray-400 shadow-sm"
                                    onClick={() => {
                                        this.setState({ showDateRange: true });
                                    }}
                                />
                                <CalendarIcon
                                    className="h-6 w-6 -ml-7"
                                    onClick={() => {
                                        this.setState({ showDateRange: true });
                                    }}
                                />
                            </div>
                            <div className="w-[295px] lg-m:w-full lg:w-52 mt-3 lg:mt-0">
                                <SelectField
                                    onSelectChange={(e) => {
                                        fetchEmpBrowserActivity(e);
                                    }}
                                    name={"Select Employee"}
                                    arrayOfData={EmployeeData}
                                    id={"name"}
                                    FieldName={t("select") + " " + t("employee")}
                                    value={
                                        this.state.employeeId === ""
                                            ? "Select Employee"
                                            : this.state.employeeId
                                    }></SelectField>
                            </div>
                        </div>
                        <div className="bg-white rounded-md shadow-sm p-5 mt-3">
                            <div>
                                <div className="text-xl font-semibold">
                                    {t("timeTracker.browserActivityLog")}
                                </div>
                                <div className="text-gray-500 text-[14px]">
                                    {t("timeTracker.browserActivity")}
                                </div>
                            </div>
                            <div className="bg-gray-200 rounded-full h-1 my-5"></div>
                            <div className="bg-white py-5 sm:px-6 ">
                                <div
                                    className="mt-2 flex flex-col overflow-y-auto bg-gray-100 rounded-md border border-gray-50"
                                    style={{ maxHeight: "700px" }}>
                                    <div className="inline-block min-w-full align-middle">
                                        <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                            <table
                                                className="min-w-full border-separate"
                                                style={{ borderSpacing: 0 }}>
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                            {t("requestsModule.status")}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                            {t("requestsModule.title")}
                                                        </th>
                                                        {/* <th
                                                            scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12"
                                                        >
                                                            Date
                                                        </th> */}
                                                        <th
                                                            scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                            {t("timeTracker.urlVisited")}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                                                            {/* {t("timeTracker.timeSpend")} */}
                                                            Time Spent(mins)
                                                        </th>
                                                        {/* <th
                                                            scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                                        >
                                                            Duration
                                                        </th> */}
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {this.state.employeeId === "" ? (
                                                        <tr>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12 border-b border-gray-200">
                                                                {t(
                                                                    "timeTracker.selectEmployeeForDetail"
                                                                )}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12 border-b border-gray-200"></td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12 border-b border-gray-200"></td>
                                                            {/* <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12 border-b border-gray-200'></td> */}
                                                        </tr>
                                                    ) : this.state.browserActivityData &&
                                                      this.state.browserActivityData.length <= 0 ? (
                                                        <tr>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 lg:pl-12 border-b border-gray-200 italic font-medium text-gray-500">
                                                                {t("noDataFound")}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12 border-b border-gray-200"></td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12 border-b border-gray-200"></td>
                                                            {/* <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12 border-b border-gray-200'></td> */}
                                                        </tr>
                                                    ) : this.state.browserActivityData === "" ? (
                                                        ""
                                                    ) : (
                                                        this.state.browserActivityData.map(
                                                            (el, idx) => (
                                                                <tr key={idx}>
                                                                    <td
                                                                        className={classNames(
                                                                            idx !==
                                                                                this.state
                                                                                    .browserActivityData
                                                                                    .length -
                                                                                    1
                                                                                ? "border-b border-gray-200"
                                                                                : "",
                                                                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                                                        )}>
                                                                        <div className="flex space-x-4">
                                                                            <label className="inline-flex items-center">
                                                                                <input
                                                                                    type="radio"
                                                                                    name={`productivity-${idx}`} // Use a unique name for each radio button group
                                                                                    className="form-radio text-blue-500"
                                                                                />
                                                                                <span className="ml-2">
                                                                                    Productive
                                                                                </span>
                                                                            </label>

                                                                            <label className="inline-flex items-center">
                                                                                <input
                                                                                    type="radio"
                                                                                    name={`productivity-${idx}`}
                                                                                    className="form-radio text-red-500"
                                                                                />
                                                                                <span className="ml-2">
                                                                                    Unproductive
                                                                                </span>
                                                                            </label>

                                                                            <label className="inline-flex items-center">
                                                                                <input
                                                                                    type="radio"
                                                                                    name={`productivity-${idx}`}
                                                                                    className="form-radio text-gray-500"
                                                                                />
                                                                                <span className="ml-2">
                                                                                    Undefined
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        className={classNames(
                                                                            idx !==
                                                                                this.state
                                                                                    .browserActivityData
                                                                                    .length -
                                                                                    1
                                                                                ? "border-b border-gray-200"
                                                                                : "",
                                                                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                                                        )}>
                                                                        {el.title === ""
                                                                            ? ""
                                                                            : el.title.length >= 10
                                                                            ? `${el.title.slice(
                                                                                  0,
                                                                                  15
                                                                              )}....`
                                                                            : el.title}
                                                                    </td>
                                                                    {/* <td
                                                                    className={classNames(
                                                                        idx !== this.state.browserActivityData.length - 1 ? 'border-b border-gray-200' : '',
                                                                        'whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell'
                                                                    )}
                                                                >
                                                                    {moment}
                                                                </td> */}
                                                                    <td
                                                                        className={classNames(
                                                                            idx !==
                                                                                this.state
                                                                                    .browserActivityData
                                                                                    .length -
                                                                                    1
                                                                                ? "border-b border-gray-200"
                                                                                : "",
                                                                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
                                                                        )}>
                                                                        <a
                                                                            href={el.url}
                                                                            target="blank"
                                                                            className="word-break text-blue-500 hover:underline">
                                                                            {el.url.length > 15
                                                                                ? `${el.url.slice(
                                                                                      0,
                                                                                      40
                                                                                  )}....`
                                                                                : el.url}
                                                                        </a>
                                                                    </td>

                                                                    <td
                                                                        className={classNames(
                                                                            idx !==
                                                                                this.state
                                                                                    .browserActivityData
                                                                                    .length -
                                                                                    1
                                                                                ? "border-b border-gray-200"
                                                                                : "",
                                                                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                                                        )}>
                                                                        {el.duration}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {this.state.SelectedTab === "location" ? (
                    <>
                        <div className="mt-2 bg-white px-2 py-2 border-b border-gray-200 rounded-lg">
                            <div className="flex justify-between flex-col xl:flex-row xl:items-center flex-wrap lg:p-0 p-3">
                                <div className="flex relative text-textprimary text-lg font-medium mb-2 mr-2 lg:mb-0">
                                    <DatePicker
                                        className="rounded-md min-w-max w-full"
                                        dateFormat="MMMM dd yyyy"
                                        value={new Date()}
                                        startDate={new Date()}
                                        selected={this.state.selectedDate}
                                        onChange={(date) => this.handleScreenshotDateChange(date)}
                                    />
                                    <CalendarIcon className="absolute top-2.5 right-2 w-5"></CalendarIcon>
                                </div>
                                <div className="flex xl:max-w-xl flex-shrink-0 flex-col items-start lg:items-center lg:flex-row mt-4 lg:flex-1 xl:mt-0">
                                    <SelectField
                                        onSelectChange={(e) => this.onEmployeeScreenshotChange(e)}
                                        FieldName={t("select") + " " + t("employee")}
                                        name={"employee"}
                                        arrayOfData={screenshotEmployeeData}
                                        mainclassName={"lg:mb-0 mb-2 min-w-[130px] flex-1"}
                                        id={"employee"}
                                        value={
                                            this.state.employeeId === ""
                                                ? ""
                                                : this.state.employeeId
                                        }></SelectField>

                                    <div className="flex items-end flex-1">
                                        <SelectField
                                            onSelectChange={(e) =>
                                                this.onProjectScreenshotChange(e)
                                            }
                                            FieldName={t("select") + " " + t("project")}
                                            name={"projectName"}
                                            arrayOfData={ProjectData}
                                            mainclassName={"lg:ml-3 min-w-[130px]"}
                                            id={"project"}
                                            value={
                                                this.state.projectname === ""
                                                    ? ""
                                                    : this.state.projectname
                                            }></SelectField>

                                        <Link
                                            to="/settings/permissions/global-permission"
                                            className="ml-0 lg:ml-3  hover:text-primary">
                                            <CogIcon className="h-6 w-6 cursor-pointer" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.employeeId && !this.state.loadingScreenshotData ? (
                            <div className="max-w-full max-h-full flex bg-white mt-4 border-b border-gray-200 lg-m:flex-wrap xl-m:overflow-x-auto no-scrollbar">
                                <div className="min-w-[320px]">
                                    <div className="flex items-center w-full p-4">
                                        <div className="border-2 h-10 w-10 uppercase rounded-full border-gray-400 bg-gray-200 text-gray-500 text-center flex flex-col justify-center">
                                            {this.state.userName
                                                .split(" ")
                                                .map((word) => word.charAt(0))}
                                        </div>
                                        <div className="ml-2">
                                            <div>{this.state.userName}</div>
                                            {/* <div className="text-tiny text-gray-400">
                                            Logged in through Mobile
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="w-full p-4">
                                        <div className="pb-2">
                                            {t("project")}:{" "}
                                            {userDetails ? userDetails.project_name : ""}
                                        </div>
                                        <div>
                                            {t("team")}: {userDetails ? userDetails.task : ""}
                                        </div>
                                    </div>
                                    <div
                                        className={`pl-5 lg-m:flex lg-m:flex-row-reverse mr-2 2xl:mr-4 pt-2 pb-4 overflow-auto max-h-[450px] lg-m:max-w-[600px] no-scrollbar`}>
                                        {this.state.markers.length > 0 ? (
                                            this.state.markers.reverse().map((item, index) => {
                                                return (
                                                    <div
                                                        key={item.lat}
                                                        className="flex justify-between lg-m:flex-col">
                                                        <div className="flex lg:flex-col items-center justify-start">
                                                            <div
                                                                className={`h-8 w-8 rounded-full ${
                                                                    this.state.showLocInfo ===
                                                                    item.time
                                                                        ? "text-[#ffffff] bg-primary"
                                                                        : "text-primary bg-white"
                                                                } border-primary border-2 justify-center font-bold flex items-center text-tiny hover:cursor-pointer lg-m:mb-2 max-w-xs`}
                                                                onClick={() =>
                                                                    this.setState({
                                                                        showLocInfo: item.time,
                                                                    })
                                                                }>
                                                                {this.state.markers.length - index}
                                                            </div>
                                                            <div
                                                                className={`min-w-[2px] bg-primary flex-1 lg-m:min-h-[2px] ${
                                                                    index ===
                                                                        this.state.markers.length -
                                                                            1 && "lg:hidden"
                                                                } ${
                                                                    index === 0 && "lg-m:hidden"
                                                                }`}></div>
                                                        </div>
                                                        <div
                                                            className={`${
                                                                this.state.showLocInfo === item.time
                                                                    ? "bg-primary"
                                                                    : "bg-[#F3F3F3]"
                                                            } p-2 ml-2 mb-4 rounded-md lg-m:mr-4 hover:cursor-pointer`}
                                                            onClick={() =>
                                                                this.setState({
                                                                    showLocInfo: item.time,
                                                                })
                                                            }>
                                                            <div
                                                                className={`pb-1 text-tiny ${
                                                                    this.state.showLocInfo ===
                                                                    item.time
                                                                        ? "text-[#ffffff]"
                                                                        : "text-primary"
                                                                } min-w-max`}>
                                                                {item.time}
                                                            </div>
                                                            <div className="p-2 flex items-center rounded bg-white">
                                                                <div className="h-5 overflow-hidden lg-m:hidden">
                                                                    <PinDrop className="h-7 text-danger" />
                                                                </div>
                                                                <div className="text-tiny max-w-[250px] 2xl:max-w-xs">
                                                                    {item.address}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="flex-1 text-center">
                                                {t("no")} {t("location")} {t("requestsModule.data")}
                                                !
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex-1 min-w-[600px] min-h-[60vh] lg-m:min-h-[70vh]">
                                    <GoogleMapReact
                                        resetBoundsOnResize={true}
                                        bootstrapURLKeys={{ key: "" }}
                                        defaultCenter={{
                                            lat:
                                                this.state.markers.length > 0
                                                    ? parseFloat(
                                                          this.state.markers[
                                                              Math.floor(
                                                                  this.state.markers.length / 2
                                                              )
                                                          ].lat
                                                      )
                                                    : 59.95,
                                            lng:
                                                this.state.markers.length > 0
                                                    ? parseFloat(
                                                          this.state.markers[
                                                              Math.floor(
                                                                  this.state.markers.length / 2
                                                              )
                                                          ].lng
                                                      )
                                                    : 30.53,
                                        }}
                                        defaultZoom={11}>
                                        {this.state.markers.reverse().map((val, index) => (
                                            <MapMarker
                                                key={val.lng}
                                                onClick={() =>
                                                    this.setState({ showLocInfo: val.time })
                                                }
                                                lat={val["lat"]}
                                                lng={val["lng"]}
                                                address={val["address"]}
                                                selected={this.state.showLocInfo === val.time}
                                                active={index === this.state.markers.length - 1}
                                                time={val.time}
                                                value={index + 1}
                                            />
                                        ))}
                                    </GoogleMapReact>
                                </div>
                            </div>
                        ) : this.state.employeeId && this.state.loadingScreenshotData ? (
                            <div className="max-w-full flex bg-white mt-4 border-b border-gray-200 min-h-[30vh] items-center justify-center">
                                <BeatLoader size={24} color="#684994" />
                            </div>
                        ) : this.state.employeeId ? (
                            <div className="max-w-full flex bg-white mt-4 border-b border-gray-200 min-h-[30vh] items-center justify-center">
                                No Data Found at the moment
                            </div>
                        ) : (
                            <div className="max-w-full flex bg-white mt-4 border-b border-gray-200 min-h-[30vh] items-center justify-center">
                                Please Select an employee to view location details!
                            </div>
                        )}
                    </>
                ) : null}

                {/* {this.state.showScreenShotSetting ? (
                    <ScreenShotSetting_modal
                        open={this.state.showScreenShotSetting}
                        onCancelClick={() => {
                            this.setState({ showScreenShotSetting: false });
                        }}
                        allempData={employees}
                    />
                ) : null} */}

                <Transition.Root show={this.state.showDateRange} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed z-10 inset-0 overflow-y-auto"
                        onClose={() => {
                            this.setState({ showDateRange: false });
                        }}>
                        <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[600px] sm:w-full sm:p-6">
                                    <DateRangeFilter
                                        onChange={onChange}
                                        firstDay={firstDay}
                                        lastDay={lastDay}
                                    />
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({
    projects: state.projects,
    timetracker: state.timetracker,
    emplistOBS: state.dashboard,
    localStorageState: state.globalLocalStorageReducer,
});

const mapDispatchToProps = (dispatch) => ({
    fetchactiveprojects: () => dispatch(fetchactiveprojects()),
    fetchallEmployees: () => dispatch(fetchallEmployees()),
    GetAppLog: (date, id) => dispatch(GetAppLog(date, id)),
    GetAppLogData: (date, id) => dispatch(GetAppLogData(date, id)),
    fetchSystemInfo: (date, id) => dispatch(fetchSystemInfo(date, id)),
    customReportWeekglyGraphdata: (last, current) =>
        dispatch(customReportWeekglyGraphdata(last, current)),
    timeTrackerData: (date, projectname, employeeId) =>
        dispatch(timeTrackerData(date, projectname, employeeId)),
    timeTrackerDataObserver: (date, projectname, employeeId) =>
        dispatch(timeTrackerDataObserver(date, projectname, employeeId)),
    timeTrackerDataWithoutProject: (date, employeeId) =>
        dispatch(timeTrackerDataWithoutProject(date, employeeId)),
    timeTrackerDataWithoutProjectObserver: (date, employeeId) =>
        dispatch(timeTrackerDataWithoutProjectObserver(date, employeeId)),
    getEmployeeBroswerLog: (user_id, start_date, end_date) =>
        dispatch(getEmployeeBroswerLog(user_id, start_date, end_date)),
    fetchAllUserURLs: (date, uu_id) => dispatch(fetchAllUserURLs(date, uu_id)),
    getRolesCheck: () => dispatch(getRolesCheck()),
    getSystemInfoObserver: (date, id) => dispatch(getSystemInfoObserver(date, id)),
    getEmpListObserver: () => dispatch(getEmpListObserver()),
    storeToLocalStorage: (data) => dispatch(storeToLocalStorage(data)),
    weeklyChartActivity: (date, user_id) => dispatch(weeklyChartActivity(date, user_id)),
    deleteTime: (data) => dispatch(deleteTime(data)),
    cancelTime: (data) => dispatch(cancelTime(data)),
    getUserDetail: (date, user_id) => dispatch(getUserDetail(date, user_id)),
    getShowcaseEntries: (date, params) => dispatch(getShowcaseEntries(date, params)),
    getEmployeeMoods: (date) => dispatch(getEmployeeMoods(date)),
});

TimeTracker.propTypes = {};

export default withTranslation("common")(connect(mapStateToProps, mapDispatchToProps)(TimeTracker));