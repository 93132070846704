import { ACTION_TYPES } from '../../../constants';
import api from '../api';

export const GetAppLog = (date, id) => ({
    type: ACTION_TYPES.GET_APP_LOG,
    payload: api.getapplog(date, id)
});

export const GetAppLogData = (date, id) => ({
    type: ACTION_TYPES.GET_APP_LOG_DATA,
    payload: api.getapplogdata(date, id)
});

export const timeTrackerData = (date, projectname, employeename) => ({
    type: ACTION_TYPES.TIME_TRACKER_DATA,
    payload: api.timetrackerdata(date, projectname, employeename)
});


export const requestProjectChange = (data) => ({
    type: ACTION_TYPES.PROJECT_CHANGE,
    payload: api.requestProjectChange(data),
});

export const timeTrackerDataWithoutProject = (date, employeename) => ({
    type: ACTION_TYPES.TIME_TRACKER_DATA,
    payload: api.timetrackerdatawithoutproject(date, employeename),
});

export const timeTrackerDataObserver = (date, projectname, employeename) => ({
    type: ACTION_TYPES.TIME_TRACKER_DATA_OBSERVER,
    payload: api.timetrackerDataObserver(date, projectname, employeename),
});

export const timeTrackerDataWithoutProjectObserver = (date, employeename) => ({
    type: ACTION_TYPES.TIME_TRACKER_DATA_OBSERVER,
    payload: api.timetrackerdatawithoutprojectObserver(date, employeename),
});

export const fetchSystemInfo = (date, id) => ({
    type: ACTION_TYPES.FETCH_SYSTEM_INFO,
    payload: api.fetchsysteminfo(date, id),
});

export const customReportWeekglyGraphdata = (last, current) => ({
    type: ACTION_TYPES.CUSTOM_WEEKLY_GRAPH_DATA,
    payload: api.customreportweeklygraphdata(last, current),
});

export const editTimeTrackerTiming = (data) => ({
    type: ACTION_TYPES.EDIT_TIME_TRACKER_SCREENSHOT,
    payload: api.editTimeTrackerTiming(data),
});

export const createTimeTrackerTiming = (data) => ({
    type: ACTION_TYPES.CREATE_TIME_TRACKER_SCREENSHOT_TIMING,
    payload: api.createTimeTrackerTiming(data),
});

export const getTimeTrackerInterval = () => ({
    type: ACTION_TYPES.GET_TIME_TRACKER_SCREENSHOT_INTERVAL,
    payload: api.getTimeTrackerInterval(),
});

export const getEmployeeBroswerLog = (user_id, start_date, end_date) => ({
    type: ACTION_TYPES.EMPLOYEE_BRWOSER_ACTIVITY_LOG,
    payload: api.getEmployeeBroswerLog(user_id, start_date, end_date),
});

export const getTimeTracker_viewDetails = () => ({
    type: ACTION_TYPES.TIMETRACKER_VIEWDETAILS,
    payload: api.getTimeTracker_viewDetails(),
});

export const getSystemInfoObserver = (date, id) => ({
    type: ACTION_TYPES.GET_SYSTEM_INFO_OBSERVER,
    payload: api.getSystemInfoObserver(date, id),
});

export const weeklyChartActivity = (project, username) => ({
    type: ACTION_TYPES.WEEKLYCHART_ACTIVITY,
    payload: api.weeklyChartActivity(project, username),
});

export const getProjectList = () => ({
    type: ACTION_TYPES.GET_PROJECT_LIST,
    payload: api.getProjectList(),
});

export const deleteTime = (data) => ({
    type: ACTION_TYPES.DELETE_TIME,
    payload: api.deleteTime(data),
});

export const cancelTime = (data) => ({
    type: ACTION_TYPES.CANCEL_TIME,
    payload: api.cancelTime(data),
});

export const getUserDetail = (date, user_id) => ({
    type: ACTION_TYPES.USER_DETAIL,
    payload: api.getUserDetail(date, user_id),
});

export const getShowcaseEntries = (date, params) => ({
    type: ACTION_TYPES.GET_SHOWCASE_ENTRIES,
    payload: api.getShowcaseEntries(date, params),
});

export const getEmployeeMoods = (date) => ({
    type: ACTION_TYPES.GET_EMPLOYEE_MOOD,
    payload: api.getEmployeeMoods(date),
});

export const getAppsLogged = (params) => ({
    type: ACTION_TYPES.GET_APP_LOG,
    payload: api.getAppsLogged(params),
});

export const getSingleAppLog = (params) => ({
    type: ACTION_TYPES.GET_APP_LOG,
    payload: api.getSingleAppLog(params),
});

export const getOfflineData = () => ({
    type: ACTION_TYPES.GET_OFFLINE_DATA,
    payload: api.getOfflineData(),
});

export const getActivityTimeTracker = (date, user_id, start_time, end_time) => ({
    type: ACTION_TYPES.GET_ACTIVITY_TIME_TRACKER,
    payload: api.getActivityTimeTracker(date, user_id, start_time, end_time),
});














