import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function SelectField(props) {
  const {t} = useTranslation('common');
  const arrayOfData = props.arrayOfData;
    const options =
      arrayOfData &&
      arrayOfData.map((data, idx) => (
        <option key={idx} value={data.id}>
          {data.name}
        </option>
      ));
    return (
      <div className={`${props.mainclassName} w-full mx-auto`}>
        <div className={`${props.mainclassName} w-full mx-auto`}>
              <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                  {props.FieldName}
              </label>
              <select
                  id={props.id}
                  name={props.name}
                  className={`${props.className} mt-1 block w-full pl-3 pr-8 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                  defaultValue={props.defaultValue}
                  onChange={props.onSelectChange}
                  value={props.value}>
                  {!props.hideDefault && (
                      <option value="">
                          {props.defaultOption ? props.defaultOption : t("select")}
                      </option>
                  )}
                  {options}
              </select>
          </div>

         
      </div>
        
    );
  }

  SelectField.propTypes = {
      onSelectChange: PropTypes.func.isRequired,
      FieldName: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.string,
      defaultValue: PropTypes.string,
  };