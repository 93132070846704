import { ACTION_TYPES } from '../../../constants';
import api from '../api';

export const fetchEmployeeAttendance = (year, month, userId) => ({
    type: ACTION_TYPES.FETCH_EMPLOYEES_ATTENDANCE,
    payload: api.fetchEmployeeAttendance(year, month, userId)
});

export const fetchEmployeeApplicationReport = (startDate, endDate, userId) => ({
    type: ACTION_TYPES.FETCH_EMPLOYEES_APPLICATION_REPORT,
    payload: api.fetchEmployeeApplicationReport(startDate, endDate, userId)
})

export const fetchEmployeeWebsiteReport = (startDate, endDate, userId, type) => ({
    type: ACTION_TYPES.FETCH_EMPLOYEE_WEBSITE_REPORT,
    payload: api.fetchEmployeeWebsiteReport(startDate, endDate, userId, type)
})
export const fetchEmployeeWebsiteReportDashboard = (startDate, endDate) => ({
    type: ACTION_TYPES.FETCH_EMPLOYEE_WEBSITE_REPORT,
    payload: api.fetchEmployeeWebsiteReportDashboard(startDate, endDate),
});

export const fetchHourQuickReport = (startDate, endDate) => ({
    type: ACTION_TYPES.FETCH_HOUR_REPORT_QUICK,
    payload: api.fetchHourQuickReport(startDate, endDate)
})

export const fetchHourCustomReport = () => ({
    type: ACTION_TYPES.FETCH_HOUR_REPORT_CUSTOM,
    payload: api.fetchHourCustomReport()
})

export const downloadHourReport = (startDate, endDate) => ({
    type: ACTION_TYPES.DOWNLOAD_HOUR_REPORT,
    payload: api.downloadHourReport(startDate, endDate)
})

export const fetchAttendanceTimings = () => ({
    type: ACTION_TYPES.ATTENDANCE_TIMING_DETAILS,
    payload: api.fetchAttendanceTimings()
})

export const updateAttendanceTimings = (data) => ({
    type: ACTION_TYPES.UPDATE_ATTENDANCE_TIMING_DETAILS,
    payload: api.updateAttendanceTimings(data)
})

export const fetchBookingReportTableLayout = () => ({
    type: ACTION_TYPES.GET_BOOKING_REPORT_TABLE_LAYOUT,
    payload: api.fetchBookingReportTableLayout(),
});

export const updateBookingReportTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_BOOKING_REPORT_TABLE_LAYOUT,
    payload: api.updateBookingReportTableLayout(data),
});

export const postBookingReportTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_BOOKING_REPORT_TABLE_LAYOUT,
    payload: api.postBookingReportTableLayout(data),
});

export const getBookingReportTable = (date) => ({
    type: ACTION_TYPES.GET_BOOKING_REPORT_TABLE,
    payload: api.getBookingReportTable(date),
});

export const downloadBookingCSVReport = (date) => ({
    type: ACTION_TYPES.DOWNLOAD_BOOKING_REPORT_TABLE,
    payload: api.downloadBookingReportTable(date),
});

export const fetchBookingReportTableArrangement = () => ({
    type: ACTION_TYPES.GET_BOOKING_REPORT_TABLE_ARRANGEMENT,
    payload: api.fetchBookingReportTableArrangement(),
});

export const updateBookingReportTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_BOOKING_REPORT_ARRANGEMENT,
    payload: api.updateBookingReportTableArrangement(data),
});

export const postBookingReportTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_BOOKING_REPORT_ARRANGEMENT,
    payload: api.postBookingReportTableArrangement(data),
});


export const getTotalHourReportTable = (date) => ({
    type: ACTION_TYPES.GET_TOTAL_HOUR_REPORT,
    payload: api.getTotalHourReportTable(date),
});