/* eslint-disable import/no-anonymous-default-export */
import { API_ENDPOINTS } from "../../constants";
import { transport, padlocktransport } from "../../Networks/Auth";

export default {
    getUserProfileImg: (params) => {
        let config = {
            url: API_ENDPOINTS.PROFILE_IMG + params,
            method: "GET",
        };
        return padlocktransport(config);
    },
    updateUserProfileImg: (data) => {
        let config = {
            url: API_ENDPOINTS.PROFILE_IMG,
            method: "POST",
            data: data,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        return padlocktransport(config);
    },
    getSkills: (params) => {
        let config = {
            url: API_ENDPOINTS.USER_SKILLS + params,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },
    updateSkills: (params, data) => {
        let config = {
            url: API_ENDPOINTS.USER_SKILLS + params,
            method: "PUT",
            data: data,
            withCredentials: true,
        };
        return transport(config);
    },
    postSkills: (params, data) => {
        let config = {
            url: API_ENDPOINTS.USER_SKILLS + params,
            method: "POST",
            data: data,
            withCredentials: true,
        };
        return transport(config);
    },
    getLocDesg: (params) => {
        let config = {
            url: API_ENDPOINTS.USER_LOC_DESG + params,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },
    updateLocDesg: (data) => {
        let config = {
            url: API_ENDPOINTS.USER_LOC_DESG,
            method: "PUT",
            data: data,
            withCredentials: true,
        };
        return transport(config);
    },
    postLocDesg: (data) => {
        let config = {
            url: API_ENDPOINTS.USER_LOC_DESG,
            method: "POST",
            data: data,
            withCredentials: true,
        };
        return transport(config);
    },
    getProjectData: (params) => {
        let config = {
            url: API_ENDPOINTS.PROJECT_DATA + params,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },
    getUserData: (params) => {
        let config = {
            url: API_ENDPOINTS.USER_DATA + params,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },
    editUserDetails: (data) => {
        let config = {
            url: API_ENDPOINTS.EDIT_USER_DETAILS,
            method: "PUT",
            data: data,
            withCredentials: true,
        };
        return transport(config);
    },
};
