import React, { useRef, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import InputField from '../../Components/InputField';
import SelectField from '../../Components/SelectField'; 
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Select from 'react-select';

export default function CreateNewClientModal(props) {
    const [teams, setTeams] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showOverTimeBilling, setShowOverTimeBilling] = useState(false);
    const [selectedRate, setSelectedRate] = useState(null);


    const {
        name,
        onClientNameChange,
        description,
        onClientDescriptionChange,
        onTeamSelectChange,
        hours_booked,
        roll_over,
        onHoursBookedChange,
        onMaxRollOverHoursChange,
        over_billing,
        onEnabledRollingTimeChange,
        onCancelClick,
        onSubmitClick,
        submitButtonName,
        cancelButtonName,
        setSuccessMessage,
        rate,
        open,
        // teams,
        selectedTeams,
        over_billing_rate,
        onMaxBillRateChange,


        ...rest
    } = props;

    const cancelButtonRef = useRef(null);
    const { t } = useTranslation('common');

   

    const options = [
        { value: 'Per hour', label: 'Per hour' },
        { value: 'fixed', label: 'fixed' },

    ]

    const handleTeamSelectChange = (selectedValue) => {
        setTeams(selectedValue);

    };

    // const handleRateChange = (val) => {
    //     setSelectedRate(val)
    //     console.log("helloooo", val);
    // }

    const handleEnabledRollingBillingRate = () => {
        setShowOverTimeBilling(!showOverTimeBilling);
        
        // onEnabledRollingTimeChange(!over_billing);


      };

    const handleSubmit = () => {

        const selectedTeamInfo = teams.map((option) => ({
            id: option.value,
            name: option.label,
        }));

        // const selectedTeamInfo = teams.map(obj=>obj.name).join(',');
        console.log(selectedTeamInfo)

        const selectedRateInfo = selectedRate
            ? {
                value: selectedRate.value,
                label: selectedRate.label,
            }
            : null;
        props.onSubmitClick({
            name,
            teams: selectedTeamInfo,
            hours_booked,
            roll_over,
            over_billing_rate,
            description,
            over_billing:showOverTimeBilling,
            // over_billing 
            rate: selectedRateInfo

        });

        // props.onSubmitClick({
        //   clientName,
        //   selectedTeam: selectedTeam.map((option) => option.value), 
        // });

    };


    return (
        <Transition.Root show={open} as={Fragment} {...rest}>
            <Dialog
                as="div"
                className="fixed z-30 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                onClose={onCancelClick}
            >
                <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                            {/* <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"> */}
                            <div className="sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg leading-6 font-bold text-textprimary"
                                    >
                                        {t('create')} {t('client')}
                                    </Dialog.Title>
                                </div>
                                <div className="mt-8">
                                    <Dialog.Description>
                                        <InputField
                                            onInputChange={onClientNameChange}
                                            FieldName={`${t('Client name')}`}
                                            name={'name'}
                                            type={'text'}
                                            id={'name'}
                                            value={name}
                                            placeholder={''}
                                        />
                                    </Dialog.Description>

                                </div>
                                <div className="mt-8 ">
                                    <Dialog.Description>
                                        <InputField
                                            className='h-20'
                                            onInputChange={onClientDescriptionChange}
                                            FieldName={`${t('Description')}`}
                                            name={'description'}
                                            type={'text'}
                                            id={'namdescriptione'}
                                            value={description} 
                                            placeholder={''}
                                        />
                                    </Dialog.Description>

                                </div>
                                <div className="mt-8 ">


                                    <Select
                                        // value={selectedTeam.map(teamValue => ({value:teamValue, label: teamValue.label }))}
                                        value={teams}
                                        onChange={(selectedOptions) => handleTeamSelectChange(selectedOptions.map((option) => ({ value: option.value, label: option.label })))}
                                        isClearable={true}
                                        options={props.teams.map((team) => ({ value: team.value, label: team.label }))}
                                        isMulti={true}
                                    />

                                </div>

                                <div className="mt-8 w-full flex justify-start items-start ">
                                    <div className='flex-1 relative w-216.50 left-0  bg-white rounded-6 border-1 border-gray-300'>
                                        <Dialog.Description>
                                            <InputField
                                                onInputChange={onHoursBookedChange}
                                                FieldName={`${t('Hours Booked')}`}
                                                name={'hoursBooked'}
                                                type={'number'}
                                                id={'hoursBooked'}
                                                value={hours_booked} 
                                                placeholder={''}
                                            />
                                        </Dialog.Description>
                                    </div>

                                    <div className='flex-1 relative w-216.50 left-3  bg-white rounded-6 border-1 border-gray-300'>
                                        <Dialog.Description>
                                            <InputField

                                                onInputChange={onMaxRollOverHoursChange}
                                                FieldName={`${t('Max Roll Over Hours')}`}
                                                name={'maxRollOverHours'}
                                                type={'number'}
                                                id={'maxRollOverHours'}
                                                value={roll_over} 
                                                placeholder={''}
                                            />
                                        </Dialog.Description>
                                    </div>

                                </div>


                                <div className="mt-5 flex items-center">
                                    <input
                                        type="checkbox"
                                        id="enabledRollingTime"
                                        checked={over_billing}
                                        // onChange={onEnabledRollingTimeChange}
                                        onChange={handleEnabledRollingBillingRate}
                                        className='h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded cursor-pointer'
                                    />
                                    <label htmlFor='EnabledRollingTime' className='ml-2 block text-sm text-gray-900'>
                                        Enabled Rolling Time
                                    </label>
                                </div>

                                {showOverTimeBilling && (
                                    <div className="mt-8 w-full flex justify-start items-start ">
                                        <div className='flex-1 relative w-516.50 left-0  bg-white rounded-6 border-1 border-gray-300'>
                                            <Dialog.Description>
                                                <InputField
                                                    className="border-0  flex-grow"
                                                    onInputChange={onMaxBillRateChange}
                                                    FieldName={`${t('Overtime Billing Rate')}`}
                                                    name={'maxOverBillRate'}
                                                    type={'number'}
                                                    id={'hoursBooked'}
                                                    value={over_billing_rate} // Use hoursBooked as the value
                                                    placeholder={'USD ($)'}
                                                />
                                            </Dialog.Description>
                                        </div>

                                        <div className='flex-1 relative w-50.50 left-3  bg-white rounded-6 border-1 border-gray-300'>

                                            <Dialog.Description>

                                                <Select
                                                    options={options}
                                                    value={selectedRate}
                                                    onChange={(selectedOption) => setSelectedRate(selectedOption)}

                                                />


                                            </Dialog.Description>
                                        </div>
                                    </div>

                                )}

                            </div>






                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                                    // onClick={() => onSubmitClick(clientName, selectedTeam)}
                                    onClick={handleSubmit}
                                >
                                    {submitButtonName}
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:w-auto sm:text-sm"
                                    onClick={onCancelClick}
                                    ref={cancelButtonRef}
                                >
                                    {cancelButtonName}
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}






