import { ACTION_TYPES } from "../../../constants";

const get_all_request = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_ALL_REQUEST_PENDING: {
            return {
                ...state,
                isFetchingAllRequests: true,
                isFetchingAllRequestsFailed: false,
                AllRequestsFetchError: undefined,
                AllRequests: [],
            };
        }

        case ACTION_TYPES.GET_ALL_REQUEST_FULFILLED: {
            return {
                ...state,
                isFetchingAllRequests: false,
                isFetchingAllRequestsFailed: false,
                AllRequestsFetchError: undefined,
                AllRequests: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_ALL_REQUEST_REJECTED: {
            return {
                ...state,
                isFetchingAllRequests: false,
                isFetchingAllRequestsFailed: true,
                AllRequestsFetchError: action.payload,
                AllRequests: [],
            };
        }

        //admin approve reject request

        case ACTION_TYPES.APPROVE_REJECT_REQUEST_PENDING: {
            return {
                ...state,
                isAppRejRequest: true,
                isAppRejRequestFailed: false,
                AppRejRequestError: undefined,
                AppRejRequest: [],
            };
        }

        case ACTION_TYPES.APPROVE_REJECT_REQUEST_FULFILLED: {
            return {
                ...state,
                isAppRejRequest: false,
                isAppRejRequestFailed: false,
                AppRejRequestError: undefined,
                AppRejRequest: action.payload.data,
            };
        }

        case ACTION_TYPES.APPROVE_REJECT_REQUEST_REJECTED: {
            return {
                ...state,
                isAppRejRequest: false,
                isAppRejRequestFailed: true,
                AppRejRequestError: action.payload,
                AppRejRequest: [],
            };
        }

        case ACTION_TYPES.OFFLINE_REQUEST_TIME_PENDING: {
            return {
                ...state,
                isSendingOfflineRequestTime: true,
                isSendingOfflineRequestTimeFailed: false,
                isSendingOfflineRequestTImeError: undefined,
                offlineTimeRequests: [],
            };
        }

        case ACTION_TYPES.OFFLINE_REQUEST_TIME_FULFILLED: {
            return {
                ...state,
                isSendingOfflineRequestTime: false,
                isSendingOfflineRequestTimeFailed: false,
                isSendingOfflineRequestTImeError: undefined,
                offlineTimeRequests: action.payload.data,
            };
        }

        case ACTION_TYPES.OFFLINE_REQUEST_TIME_REJECTED: {
            return {
                ...state,
                isSendingOfflineRequestTime: false,
                isSendingOfflineRequestTimeFailed: true,
                isSendingOfflineRequestTImeError: action.payload,
                offlineTimeRequests: [],
            };
        }

        default:
            return state;
    }
};

export default get_all_request;
