import { Component, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { connect } from 'react-redux';
import InputField from '../../Components/InputField';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { admin_AppRejRequest } from '../../services/requests/actions';
import { withTranslation } from 'react-i18next';

const userRole = localStorage.getItem('ro');
const checkBoxStyle = 'mr-4 p-2 rounded-md text-primary';

class AdminAppRejectRequest extends Component {
    constructor() {
        super();
        this.state = {
            remark: "",
        };
    }

    componentDidMount() {}

    render() {
        const { t } = this.props;
        const { open, onCancelClick, data, status, ...rest } = this.props;

        const handleAdminRequest_appRej = (status) => {
            let dataa = {
                id: data.id,
                status: status,
                remark: this.state.remark,
            };

            this.props.admin_AppRejRequest(dataa, data && data.type === "offline").then((data) => {
                if (userRole == "Observer") {
                    return;
                }
                if (data.action.payload.status === 200) {
                    if (status === "Approved") {
                        toast.success("Request Approved Successfully");
                        this.props.onCancelClick();
                        this.props.successSubmit();
                    } else {
                        toast.success("Request Rejected Successfully");
                        this.props.onCancelClick();
                        this.props.successSubmit();
                    }
                }
            });
        };
        return (
            <Transition.Root show={this.props.open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto"
                    onClose={onCancelClick}>
                    <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                                <div className="">
                                    <InputField
                                        label={"Remark"}
                                        type={"text"}
                                        FieldName={"Remark"}
                                        value={this.state.remark}
                                        onInputChange={(e) => {
                                            this.setState({ remark: e.target.value });
                                        }}
                                    />
                                    <div className="flex justify-between items-center mt-4">
                                        <div className="flex ">
                                            {status === "Approve" ? (
                                                <button
                                                    className="bg-primary p-2 rounded-full px-9 sm:px-12 text-center text-white font-medium mr-2 shadow-md"
                                                    onClick={() => {
                                                        handleAdminRequest_appRej("Approved");
                                                    }}>
                                                    {t("requestsModule.approve")}
                                                </button>
                                            ) : (
                                                <button
                                                    className="border border-red-600 p-2 px-9 sm:px-12 rounded-full text-red-600 font-medium shadow-md"
                                                    onClick={() => {
                                                        handleAdminRequest_appRej("Rejected");
                                                    }}>
                                                    {t("requestsModule.reject")}
                                                </button>
                                            )}
                                        </div>
                                        <button
                                            className="border border-gray-500 p-2 px-9 sm:px-12 rounded-full text-gray-800 font-medium shadow-md"
                                            onClick={onCancelClick}>
                                            {t("requestsModule.cancel")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    admin_AppRejRequest: (data, offline) => dispatch(admin_AppRejRequest(data, offline)),
});


export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(AdminAppRejectRequest));
