import { ACTION_TYPES } from '../../../constants';



const teams = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_ACTIVE_USERS_LIST_PENDING: {
            return {
                ...state,
                isFetchingActiveUsersList: true,
                isFetchingActiveUsersFailed: false,
                ActiveUsersFetchError: undefined,
                ActiveUsers: [],
            };
        }
        case ACTION_TYPES.FETCH_ACTIVE_USERS_LIST_FULFILLED: {
            return {
                ...state,
                isFetchingActiveUsersList: false,
                isFetchingActiveUsersFailed: false,
                ActiveUsersFetchError: undefined,
                ActiveUsers: action.payload.data,
            };
        }
        case ACTION_TYPES.FETCH_ACTIVE_USERS_LIST_REJECTED: {
            return {
                ...state,
                isFetchingActiveUsersList: false,
                isFetchingActiveUsersFailed: true,
                ActiveUsersFetchError: action.payload,
                ActiveUsers: [],
            };
        }

        case ACTION_TYPES.FETCH_INACTIVE_USERS_LIST_PENDING: {
            return {
                ...state,
                isFetchingInActiveUsersList: true,
                isFetchingInActiveUsersFailed: false,
                InActiveUsersFetchError: undefined,
                InActiveUsers: [],
            };
        }
        case ACTION_TYPES.FETCH_INACTIVE_USERS_LIST_FULFILLED: {
            return {
                ...state,
                isFetchingInActiveUsersList: false,
                isFetchingInActiveUsersFailed: false,
                InActiveUsersFetchError: undefined,
                InActiveUsers: action.payload.data,
            };
        }
        case ACTION_TYPES.FETCH_INACTIVE_USERS_LIST_REJECTED: {
            return {
                ...state,
                isFetchingInActiveUsersList: false,
                isFetchingInActiveUsersFailed: true,
                InActiveUsersFetchError: action.payload,
                InActiveUsers: [],
            };
        }

        case ACTION_TYPES.FETCH_WORKSTATIONS_LIST_PENDING: {
            return {
                ...state,
                isFetchingWorkstationsList: true,
                isFetchingWorkstationsFailed: false,
                WorkstationsFetchError: undefined,
                Workstations: [],
            };
        }
        case ACTION_TYPES.FETCH_WORKSTATIONS_LIST_FULFILLED: {
            return {
                ...state,
                isFetchingWorkstationsList: false,
                isFetchingWorkstationsFailed: false,
                WorkstationsFetchError: undefined,
                Workstations: action.payload.data,
            };
        }
        case ACTION_TYPES.FETCH_WORKSTATIONS_LIST_REJECTED: {
            return {
                ...state,
                isFetchingWorkstationsList: false,
                isFetchingWorkstationsFailed: true,
                WorkstationsFetchError: action.payload,
                Workstations: [],
            };
        }

        //added for client info
        case ACTION_TYPES.FETCH_CLIENT_PROFILE_INFO_PENDING: {
            return {
                ...state,
                isFetchingClientInfoList: true,
                isFetchingClientInfoFailed: false,
                ClientInfoFetchError: undefined,
                ClientInfo: [],
            };
        }
        case ACTION_TYPES.FETCH_CLIENT_PROFILE_INFO_FULFILLED: {
            return {
                ...state,
                isFetchingClientInfoList: true,
                isFetchingClientInfoFailed: false,
                ClientInfoFetchError: undefined,
                ClientInfo: action.payload.data,
            };
        }
        case ACTION_TYPES.FETCH_CLIENT_PROFILE_INFO_REJECTED: {
            return {
                ...state,
                isFetchingClientInfoList: false,
                isFetchingClientInfoFailed: true,
                CleintInfoFetchError: action.payload,
                ClientInfo: [],
            };
        }



        case ACTION_TYPES.GET_CLIENT_PENDING: {
            return {
                ...state,
                isFetchingClient: true,
                isFetchingClientFailed: false,
                clientFetchError: undefined,
                clients: [],
            };
        }
        case ACTION_TYPES.GET_CLIENT_FULFILLED: {
            return {
                ...state,
                isFetchingClient: false,
                isFetchingClientFailed: false,
                clientFetchError: undefined,
                clients: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_CLIENT_REJECTED: {
            return {
                ...state,
                isFetchingClient: false,
                isFetchingClientFailed: true,
                clientFetchError: action.payload,
                clients: [],
            };
        }


        case ACTION_TYPES.GET_INACTIVE_CLIENT_PENDING: {
            return {
                ...state,
                isFetchingClient: true,
                isFetchingClientFailed: false,
                clientFetchError: undefined,
                inactiveclients: [],
            };
        }
        case ACTION_TYPES.GET_INACTIVE_CLIENT_FULFILLED: {
            return {
                ...state,
                isFetchingClient: false,
                isFetchingClientFailed: false,
                clientFetchError: undefined,
                inactiveclients: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_INACTIVE_CLIENT_REJECTED: {
            return {
                ...state,
                isFetchingClient: false,
                isFetchingClientFailed: true,
                clientFetchError: action.payload,
                inaciveclients: [],
            };
        }
        case ACTION_TYPES.FETCH_CLIENT_PROFILE_INFO_PENDING: {
            return {
                ...state,
                isFetchingClientInfoList: true,
                isFetchingClientInfoFailed: false,
                ClientInfoFetchError: undefined,
                ClientInfo: [],
            };
        }
        case ACTION_TYPES.FETCH_CLIENT_PROFILE_INFO_FULFILLED: {
            return {
                ...state,
                isFetchingClientInfoList: true,
                isFetchingClientInfoFailed: false,
                ClientInfoFetchError: undefined,
                ClientInfo: action.payload.data,
            };
        }
        case ACTION_TYPES.FETCH_CLIENT_PROFILE_INFO_REJECTED: {
            return {
                ...state,
                isFetchingClientInfoList: false,
                isFetchingClientInfoFailed: true,
                CleintInfoFetchError: action.payload,
                ClientInfo: [],
            };
        }
        
        
        case ACTION_TYPES.EDIT_CLIENT_PROFILE_INFO_PENDING: {
            return {
                ...state,
                isFetchingClientInfoList: true,
                isFetchingClientInfoFailed: false,
                ClientInfoFetchError: undefined,
                ClientInfo: [],
            };
        }
        case ACTION_TYPES.EDIT_CLIENT_PROFILE_INFO_FULFILLED: {
            return {
                ...state,
                isFetchingClientInfoList: true,
                isFetchingClientInfoFailed: false,
                ClientInfoFetchError: undefined,
                ClientInfo: action.payload.data,
            };
        }
        case ACTION_TYPES.EDIT_CLIENT_PROFILE_INFO_REJECTED: {
            return {
                ...state,
                isFetchingClientInfoList: false,
                isFetchingClientInfoFailed: true,
                CleintInfoFetchError: action.payload,
                ClientInfo: [],
            };
        }

        case ACTION_TYPES.ADD_CLIENT_TO_TEAM_PENDING:{
            return {
                ...state,
                isAddingClientToTeam:true,
                isAddingClientToTeamFailed:false,
                isAddingClientToTeamError:undefined,
                teams:"",
            }
        }

        case ACTION_TYPES.ADD_CLIENT_TO_TEAM_FULFILLED:{
            return {
                ...state,
                isAddingClientToTeam:false,
                isAddingClientToTeamFailed:false,
                isAddingClientToTeamError:undefined,
                teams:action.payload.data,
            }
        }

        case ACTION_TYPES.ADD_CLIENT_TO_TEAM_REJECTED:{
            return {
                ...state,
                isAddingClientToTeam:false,
                isAddingClientToTeamFailed:true,
                isAddingClientToTeamError:action.payload,
                teams:"",
            }
        }

        case ACTION_TYPES.CREATE_TEAM_PENDING: {
            return {
                ...state,
                isFetchingCreateTeam: true,
                isFetchingcreateTeamFailed: false,
                createTeamFetchError: undefined,
                createTeam: [],
            };
        }
        case ACTION_TYPES.CREATE_TEAM_FULFILLED: {
            return {
                ...state,
                isFetchingCreateTeam: false,
                isFetchingcreateTeamFailed: false,
                createTeamFetchError: undefined,
                createTeam: action.payload.data,
            };
        }
        case ACTION_TYPES.CREATE_TEAM_REJECTED: {
            return {
                ...state,
                isFetchingCreateTeam: false,
                isFetchingcreateTeamFailed: true,
                createTeamFetchError: action.payload,
                createTeam: [],
            };
        }


        case ACTION_TYPES.CREATE_CLIENT_PENDING: {
            return {
                ...state,
                isFetchingCreateClient: true,
                isFetchingcreateClientFailed: false,
                createClientFetchError: undefined,
                createClient: [],
            };
        }
        case ACTION_TYPES.CREATE_CLIENT_FULFILLED: {
            return {
                ...state,
                isFetchingCreateClient: false,
                isFetchingcreateClientFailed: false,
                createClientFetchError: undefined,
                clientData: action.payload.data,
            };
        }
        case ACTION_TYPES.CREATE_CLIENT_REJECTED: {
            return {
                ...state,
                isFetchingCreateClient: false,
                isFetchingcreateClientFailed: true,
                createClientFetchError: action.payload,
                createClient: [],
            };
        }

        case ACTION_TYPES.ADD_CLIENT_TO_TEAM_PENDING:{
            return {
                ...state,
                isAddingClientToTeam:true,
                isAddingClientToTeamFailed:false,
                isAddingClientToTeamError:undefined,
                teams:"",
            }
        }

        case ACTION_TYPES.ADD_CLIENT_TO_TEAM_FULFILLED:{
            return {
                ...state,
                isAddingClientToTeam:false,
                isAddingClientToTeamFailed:false,
                isAddingClientToTeamError:undefined,
                teams:action.payload.data,
            }
        }

        case ACTION_TYPES.ADD_CLIENT_TO_TEAM_REJECTED:{
            return {
                ...state,
                isAddingClientToTeam:false,
                isAddingClientToTeamFailed:true,
                isAddingClientToTeamError:action.payload,
                teams:"",
            }
        }

        case ACTION_TYPES.ADD_CLIENT_TO_TEAM_PENDING:{
            return {
                ...state,
                isAddingClientToTeam:true,
                isAddingClientToTeamFailed:false,
                isAddingClientToTeamError:undefined,
                teams:"",
            }
        }

        


        case ACTION_TYPES.ACTIVE_TEAMS_PENDING: {
            return {
                ...state,
                isFetchingActiveTeam: true,
                isFetchingActiveTeamFailed: false,
                ActiveTeamFetchError: undefined,
                ActiveTeam: [],
            };
        }
        case ACTION_TYPES.ACTIVE_TEAMS_FULFILLED: {
            return {
                ...state,
                isFetchingActiveTeam: false,
                isFetchingActiveTeamFailed: false,
                ActiveTeamFetchError: undefined,
                ActiveTeam: action.payload.data,
            };
        }
        case ACTION_TYPES.ACTIVE_TEAMS_REJECTED: {
            return {
                ...state,
                isFetchingActiveTeam: false,
                isFetchingActiveTeamFailed: true,
                ActiveTeamFetchError: action.payload,
                ActiveTeam: [],
            };
        }

        case ACTION_TYPES.INACTIVE_TEAMS_PENDING: {
            return {
                ...state,
                isFetchingInActiveTeam: true,
                isFetchingInActiveTeamFailed: false,
                InActiveTeamFetchError: undefined,
                InActiveTeam: [],
            };
        }
        case ACTION_TYPES.INACTIVE_TEAMS_FULFILLED: {
            return {
                ...state,
                isFetchingInActiveTeam: false,
                isFetchingInActiveTeamFailed: false,
                InActiveTeamFetchError: undefined,
                InActiveTeam: action.payload.data,
            };
        }
        case ACTION_TYPES.INACTIVE_TEAMS_REJECTED: {
            return {
                ...state,
                isFetchingInActiveTeam: false,
                isFetchingInActiveTeamFailed: true,
                InActiveTeamFetchError: action.payload,
                InActiveTeam: [],
            };
        }

        case ACTION_TYPES.DELETE_TEAM_PADLOCK_PENDING: {
            return {
                ...state,
                isFetchingDeletePadlockTeam: true,
                isFetchingDeleteTeamPadlockFailed: false,
                DeleteTeamPadlockFetchError: undefined,
                DeleteTeamPadlock: {},
            };
        }
        case ACTION_TYPES.DELETE_TEAM_PADLOCK_FULFILLED: {
            return {
                ...state,
                isFetchingDeletePadlockTeam: false,
                isFetchingDeleteTeamPadlockFailed: false,
                DeleteTeamPadlockFetchError: undefined,
                DeleteTeamPadlock: action.payload.data,
            };
        }
        case ACTION_TYPES.DELETE_TEAM_PADLOCK_REJECTED: {
            return {
                ...state,
                isFetchingDeletePadlockTeam: false,
                isFetchingDeleteTeamPadlockFailed: true,
                DeleteTeamPadlockFetchError: action.payload,
                DeleteTeamPadlock: {},
            };
        }

        case ACTION_TYPES.DELETE_TEAM_PENDING: {
            return {
                ...state,
                isFetchingDeleteTeam: true,
                isFetchingDeleteTeamFailed: false,
                DeleteTeamFetchError: undefined,
                DeleteTeam: {},
            };
        }
        case ACTION_TYPES.DELETE_TEAM_FULFILLED: {
            return {
                ...state,
                isFetchingDeleteTeam: false,
                isFetchingDeleteTeamFailed: false,
                DeleteTeamFetchError: undefined,
                DeleteTeam: action.payload.data,
            };
        }
        case ACTION_TYPES.DELETE_TEAM_REJECTED: {
            return {
                ...state,
                isFetchingDeleteTeam: false,
                isFetchingDeleteTeamFailed: true,
                DeleteTeamFetchError: action.payload,
                DeleteTeam: {},
            };
        }

        case ACTION_TYPES.REACTIVATE_TEAM_PENDING: {
            return {
                ...state,
                isFetchingreactivateTeam: true,
                isFetchingreactivateTeamFailed: false,
                reactivateTeamFetchError: undefined,
                reactivateTeam: {},
            };
        }
        case ACTION_TYPES.REACTIVATE_TEAM_FULFILLED: {
            return {
                ...state,
                isFetchingreactivateTeam: false,
                isFetchingreactivateTeamFailed: false,
                reactivateTeamFetchError: undefined,
                reactivateTeam: action.payload.data,
            };
        }
        case ACTION_TYPES.REACTIVATE_TEAM_REJECTED: {
            return {
                ...state,
                isFetchingreactivateTeam: false,
                isFetchingreactivateTeamFailed: true,
                reactivateTeamFetchError: action.payload,
                reactivateTeam: {},
            };
        }

        case ACTION_TYPES.DELETE_USER_PADLOCK_PENDING: {
            return {
                ...state,
                isFetchingDeletePadlockUser: true,
                isFetchingDeleteUserPadlockFailed: false,
                DeleteUserPadlockFetchError: undefined,
                DeleteUserPadlock: {},
            };
        }
        case ACTION_TYPES.DELETE_USER_PADLOCK_FULFILLED: {
            return {
                ...state,
                isFetchingDeletePadlockUser: false,
                isFetchingDeleteUserPadlockFailed: false,
                DeleteUserPadlockFetchError: undefined,
                DeleteUserPadlock: action.payload.data,
            };
        }
        case ACTION_TYPES.DELETE_USER_PADLOCK_REJECTED: {
            return {
                ...state,
                isFetchingDeletePadlockUser: false,
                isFetchingDeleteUserPadlockFailed: true,
                DeleteUserPadlockFetchError: action.payload,
                DeleteUserPadlock: {},
            };
        }

        case ACTION_TYPES.DELETE_USER_PENDING: {
            return {
                ...state,
                isFetchingDeleteUser: true,
                isFetchingDeleteUserFailed: false,
                DeleteUserFetchError: undefined,
                DeleteUser: {},
            };
        }
        case ACTION_TYPES.DELETE_USER_FULFILLED: {
            return {
                ...state,
                isFetchingDeleteUser: false,
                isFetchingDeleteUserFailed: false,
                DeleteUserFetchError: undefined,
                DeleteUser: action.payload.data,
            };
        }
        case ACTION_TYPES.DELETE_USER_REJECTED: {
            return {
                ...state,
                isFetchingDeleteUser: false,
                isFetchingDeleteUserFailed: true,
                DeleteUserFetchError: action.payload,
                DeleteUser: {},
            };
        }

        case ACTION_TYPES.REACTIVATE_USER_PADLOCK_PENDING: {
            return {
                ...state,
                isFetchingReactivatePadlockUser: true,
                isFetchingReactivatePadlockUserFailed: false,
                ReactivatePadlockUserFetchError: undefined,
                ReactivatepadlockUser: {},
            };
        }
        case ACTION_TYPES.REACTIVATE_USER_PADLOCK_FULFILLED: {
            return {
                ...state,
                isFetchingReactivatePadlockUser: false,
                isFetchingReactivatePadlockUserFailed: false,
                ReactivatePadlockUserFetchError: undefined,
                ReactivatepadlockUser: action.payload.data,
            };
        }
        case ACTION_TYPES.REACTIVATE_USER_PADLOCK_REJECTED: {
            return {
                ...state,
                isFetchingReactivatePadlockUser: false,
                isFetchingReactivatePadlockUserFailed: true,
                ReactivatePadlockUserFetchError: action.payload,
                ReactivatepadlockUser: {},
            };
        }

        case ACTION_TYPES.REACTIVATE_USER_PENDING: {
            return {
                ...state,
                isFetchingReactivateUser: true,
                isFetchingReactivateUserFailed: false,
                ReactivateUserFetchError: undefined,
                ReactivateUser: {},
            };
        }
        case ACTION_TYPES.REACTIVATE_USER_FULFILLED: {
            return {
                ...state,
                isFetchingReactivateUser: false,
                isFetchingReactivateUserFailed: false,
                ReactivateUserFetchError: undefined,
                ReactivateUser: action.payload.data,
            };
        }
        case ACTION_TYPES.REACTIVATE_USER_REJECTED: {
            return {
                ...state,
                isFetchingReactivateUser: false,
                isFetchingReactivateUserFailed: true,
                ReactivateUserFetchError: action.payload,
                ReactivateUser: {},
            };
        }



        case ACTION_TYPES.GET_USERS_FROM_TEAM_PENDING: {
            return {
                ...state,
                isFetchingUsersFromTeamList: true,
                isFetchingUsersFromTeamFailed: false,
                UsersFromTeamFetchError: undefined,
                UsersFromTeam: [],
            };
        }
        case ACTION_TYPES.GET_USERS_FROM_TEAM_FULFILLED: {
            return {
                ...state,
                isFetchingUsersFromTeamList: false,
                isFetchingUsersFromTeamFailed: false,
                UsersFromTeamFetchError: undefined,
                UsersFromTeam: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_USERS_FROM_TEAM_REJECTED: {
            return {
                ...state,
                isFetchingUsersFromTeamList: false,
                isFetchingUsersFromTeamFailed: true,
                UsersFromTeamFetchError: action.payload,
                UsersFromTeam: [],
            };
        }



        case ACTION_TYPES.GET_EMPLOYEE_PHONE_NUMBER_PENDING: {
            return {
                ...state,
                isFetchingEmployeePhoneNumber: true,
                isFetchingEmployeePhoneNumberFailed: false,
                EmployeePhoneNumberFetchError: undefined,
                EmployeePhoneNumber: [],
            };
        }

        case ACTION_TYPES.GET_EMPLOYEE_PHONE_NUMBER_FULFILLED: {
            return {
                ...state,
                isFetchingEmployeePhoneNumber: false,
                isFetchingEmployeePhoneNumberFailed: false,
                EmployeePhoneNumberFetchError: undefined,
                EmployeePhoneNumber: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_EMPLOYEE_PHONE_NUMBER_REJECTED: {
            return {
                ...state,
                isFetchingEmployeePhoneNumber: false,
                isFetchingEmployeePhoneNumberFailed: true,
                EmployeePhoneNumberFetchError: action.payload,
                EmployeePhoneNumber: [],
            };
        }

        case ACTION_TYPES.EDIT_EMPLOYEE_PHONE_NUMBER_PENDING: {
            return {
                ...state,
                isFetchingEditEmployeePhoneNumber: true,
                isFetchingEditEmployeePhoneNumberFailed: false,
                EditEmployeePhoneNumberFetchError: undefined,
                EditEmployeePhoneNumber: [],
            };
        }

        case ACTION_TYPES.EDIT_EMPLOYEE_PHONE_NUMBER_FULFILLED: {
            return {
                ...state,
                isFetchingEditEmployeePhoneNumber: false,
                isFetchingEditEmployeePhoneNumberFailed: false,
                EditEmployeePhoneNumberFetchError: undefined,
                EditEmployeePhoneNumber: action.payload.data,
            };
        }

        case ACTION_TYPES.EDIT_EMPLOYEE_PHONE_NUMBER_REJECTED: {
            return {
                ...state,
                isFetchingEditEmployeePhoneNumber: false,
                isFetchingEditEmployeePhoneNumberFailed: true,
                EditEmployeePhoneNumberFetchError: action.payload,
                EditEmployeePhoneNumber: [],
            };
        }

        case ACTION_TYPES.EDIT_EMPLOYEE_BASIC_INFO_PENDING: {
            return {
                ...state,
                isFetchingEditEmployeeBasicInfo: true,
                isFetchingEditEmployeeBasicInfoFailed: false,
                EditEmployeeBasicInfoFetchError: undefined,
                EditEmployeeBasicInfo: [],
            };
        }

        case ACTION_TYPES.EDIT_EMPLOYEE_BASIC_INFO_FULFILLED: {
            return {
                ...state,
                isFetchingEditEmployeeBasicInfo: false,
                isFetchingEditEmployeeBasicInfoFailed: false,
                EditEmployeeBasicInfoFetchError: undefined,
                EditEmployeeBasicInfo: action.payload.data,
            };
        }

        case ACTION_TYPES.EDIT_EMPLOYEE_BASIC_INFO_REJECTED: {
            return {
                ...state,
                isFetchingEditEmployeeBasicInfo: false,
                isFetchingEditEmployeeBasicInfoFailed: true,
                EditEmployeeBasicInfoFetchError: action.payload,
                EditEmployeeBasicInfo: [],
            };
        }

        case ACTION_TYPES.GET_USERBASED_PROJECTS_PENDING: {
            return {
                ...state,
                isFetchingUserBasedProjects: true,
                isFetchingUserBasedProjectsFailed: false,
                UserBasedProjectsFetchError: undefined,
                UserBasedProjects: [],
            };
        }

        case ACTION_TYPES.GET_USERBASED_PROJECTS_FULFILLED: {
            return {
                ...state,
                isFetchingUserBasedProjects: false,
                isFetchingUserBasedProjectsFailed: false,
                UserBasedProjectsFetchError: undefined,
                UserBasedProjects: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_USERBASED_PROJECTS_REJECTED: {
            return {
                ...state,
                isFetchingUserBasedProjects: false,
                isFetchingUserBasedProjectsFailed: true,
                UserBasedProjectsFetchError: action.payload,
                UserBasedProjects: [],
            };
        }

        case ACTION_TYPES.REMOVE_USERBASED_PROJECTS_PENDING: {
            return {
                ...state,
                isFetchingRemoveUserBasedProjects: true,
                isFetchingRemoveUserBasedProjectsFailed: false,
                RemoveUserBasedProjectsFetchError: undefined,
                RemoveUserBasedProjects: [],
            };
        }

        case ACTION_TYPES.REMOVE_USERBASED_PROJECTS_FULFILLED: {
            return {
                ...state,
                isFetchingRemoveUserBasedProjects: false,
                isFetchingRemoveUserBasedProjectsFailed: false,
                RemoveUserBasedProjectsFetchError: undefined,
                RemoveUserBasedProjects: action.payload.data,
            };
        }

        case ACTION_TYPES.REMOVE_USERBASED_PROJECTS_REJECTED: {
            return {
                ...state,
                isFetchingRemoveUserBasedProjects: false,
                isFetchingRemoveUserBasedProjectsFailed: true,
                RemoveUserBasedProjectsFetchError: action.payload,
                RemoveUserBasedProjects: [],
            };
        }

        case ACTION_TYPES.ADD_USERBASED_PROJECTS_PENDING: {
            return {
                ...state,
                isFetchingAddUserBasedProjects: true,
                isFetchingAddUserBasedProjectsFailed: false,
                AddUserBasedProjectsFetchError: undefined,
                AddUserBasedProjects: [],
            };
        }

        case ACTION_TYPES.ADD_USERBASED_PROJECTS_FULFILLED: {
            return {
                ...state,
                isFetchingAddUserBasedProjects: false,
                isFetchingAddUserBasedProjectsFailed: false,
                AddUserBasedProjectsFetchError: undefined,
                AddUserBasedProjects: action.payload.data,
            };
        }

        case ACTION_TYPES.ADD_USERBASED_PROJECTS_REJECTED: {
            return {
                ...state,
                isFetchingAddUserBasedProjects: false,
                isFetchingAddUserBasedProjectsFailed: true,
                AddUserBasedProjectsFetchError: action.payload,
                AddUserBasedProjects: [],
            };
        }

        case ACTION_TYPES.CREATE_WORKSTATION_JOINWORKAHUB_PENDING: {
            return {
                ...state,
                isFetchingCreateWorkstationJoinWorkahub: true,
                isFetchingCreateWorkstationJoinWorkahubFailed: false,
                CreateWorkstationJoinWorkahubFetchError: undefined,
                CreateWorkstationJoinWorkahub: [],
            };
        }

        case ACTION_TYPES.CREATE_WORKSTATION_JOINWORKAHUB_FULFILLED: {
            return {
                ...state,
                isFetchingCreateWorkstationJoinWorkahub: false,
                isFetchingCreateWorkstationJoinWorkahubFailed: false,
                CreateWorkstationJoinWorkahubFetchError: undefined,
                CreateWorkstationJoinWorkahub: action.payload.data,
            };
        }

        case ACTION_TYPES.CREATE_WORKSTATION_JOINWORKAHUB_REJECTED: {
            return {
                ...state,
                isFetchingCreateWorkstationJoinWorkahub: false,
                isFetchingCreateWorkstationJoinWorkahubFailed: true,
                CreateWorkstationJoinWorkahubFetchError: action.payload,
                CreateWorkstationJoinWorkahub: [],
            };
        }

        case ACTION_TYPES.GET_OBSERVERS_LIST_PENDING: {
            return {
                ...state,
                isFetchingObserversList: true,
                isFetchingObserversListFailed: false,
                ObserversListFetchError: undefined,
                ObserversList: [],
            };
        }

        case ACTION_TYPES.GET_OBSERVERS_LIST_FULFILLED: {
            return {
                ...state,
                isFetchingObserversList: false,
                isFetchingObserversListFailed: false,
                ObserversListFetchError: undefined,
                ObserversList: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_OBSERVERS_LIST_REJECTED: {
            return {
                ...state,
                isFetchingObserversList: false,
                isFetchingObserversListFailed: true,
                ObserversListFetchError: action.payload,
                ObserversList: [],
            };
        }

        // case ACTION_TYPES.UPDATE_OBSERVERS_LIST_PENDING:{
        //     return {
        //         ...state,
        //         isUpdateFetchingObserversList: true,
        //         isUpdateFetchingObserversListFailed: false,
        //         UpdateObserversListFetchError: undefined,
        //         ObserversList:[]
        //     }
        // }
        // case ACTION_TYPES.UPDATE_OBSERVERS_LIST_FULFILLED:{
        //     return {
        //         ...state,
        //         isUpdateFetchingObserversList: false,
        //         isUpdateFetchingObserversListFailed: false,
        //         UpdateObserversListFetchError: undefined,
        //         ObserversList:action.payload.data
        //     }
        // }
        // case ACTION_TYPES.UPDATE_OBSERVERS_LIST_REJECTED:{
        //     return {
        //         ...state,
        //         isUpdateFetchingObserversList: false,
        //         isUpdateFetchingObserversListFailed: true,
        //         UpdateObserversListFetchError: action.payload,
        //         ObserversList:[]
        //     }
        // }

        case ACTION_TYPES.GET_MANAGERS_LIST_PENDING: {
            return {
                ...state,
                isFetchingManagersList: true,
                isFetchingManagersListFailed: false,
                ManagersListFetchError: undefined,
                ManagersList: [],
            };
        }

        case ACTION_TYPES.GET_MANAGERS_LIST_FULFILLED: {
            return {
                ...state,
                isFetchingManagersList: false,
                isFetchingManagersListFailed: false,
                ManagersListFetchError: undefined,
                ManagersList: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_MANAGERS_LIST_REJECTED: {
            return {
                ...state,
                isFetchingManagersList: false,
                isFetchingManagersListFailed: true,
                ManagersListFetchError: action.payload,
                ManagersList: [],
            };
        }

        case ACTION_TYPES.GET_PAYROLL_DATA_PENDING: {
            return {
                ...state,
                isFetchingPayrollData: true,
                isFetchingPayrollDataFailed: false,
                PayrollDataFetchError: undefined,
                PayrollData: [],
            };
        }
        case ACTION_TYPES.GET_PAYROLL_DATA_FULFILLED: {
            return {
                ...state,
                isFetchingPayrollData: false,
                isFetchingPayrollDataFailed: false,
                PayrollDataFetchError: false,
                PayrollData: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_PAYROLL_DATA_REJECTED: {
            return {
                ...state,
                isFetchingPayrollData: false,
                isFetchingPayrollDataFailed: true,
                PayrollDataFetchError: action.payload,
                PayrollData: [],
            };
        }
        case ACTION_TYPES.UPDATE_PAYROLL_DATA_PENDING: {
            return {
                ...state,
                isUpdatingPayrollData: true,
                isUpdatingPayrollDataFailed: false,
                PayrollDataUpdateError: undefined,
                PayrollData: [],
            };
        }
        case ACTION_TYPES.UPDATE_PAYROLL_DATA_FULFILLED: {
            return {
                ...state,
                isUpdatingPayrollData: false,
                isUpdatingPayrollDataFailed: false,
                PayrollDataUpdateError: false,
                PayrollData: action.payload.data,
            };
        }
        case ACTION_TYPES.UPDATE_PAYROLL_DATA_REJECTED: {
            return {
                ...state,
                isUpdatingPayrollData: false,
                isUpdatingPayrollDataFailed: true,
                PayrollDataUpdateError: action.payload,
                PayrollData: [],
            };
        }
        case ACTION_TYPES.GET_SALARY_DAYS_DATA_PENDING: {
            return {
                ...state,
                isFetchingSalaryDaysData: true,
                isFetchingSalaryDaysDataFailed: false,
                SalaryDaysDataFetchError: undefined,
                SalaryDaysData: [],
            };
        }
        case ACTION_TYPES.GET_SALARY_DAYS_DATA_FULFILLED: {
            return {
                ...state,
                isFetchingSalaryDaysData: false,
                isFetchingSalaryDaysDataFailed: false,
                SalaryDaysDataFetchError: false,
                SalaryDaysData: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_SALARY_DAYS_DATA_REJECTED: {
            return {
                ...state,
                isFetchingSalaryDayDownload: false,
                isFetchingSalaryDayDownloadFailed: true,
                SalaryDayDownloadFetchError: action.payload,
                SalaryDayDownload: [],
            };
        }
        case ACTION_TYPES.GET_SALARY_DAY_DOWNLOAD_PENDING: {
            return {
                ...state,
                isFetchingSalaryDayDownload: true,
                isFetchingSalaryDayDownloadFailed: false,
                SalaryDayDownloadFetchError: undefined,
                SalaryDayDownload: [],
            };
        }
        case ACTION_TYPES.GET_SALARY_DAY_DOWNLOAD_FULFILLED: {
            return {
                ...state,
                isFetchingSalaryDayDownload: false,
                isFetchingSalaryDayDownloadFailed: false,
                SalaryDayDownloadFetchError: false,
                SalaryDayDownload: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_SALARY_DAY_DOWNLOAD_REJECTED: {
            return {
                ...state,
                isFetchingSalaryDayDownload: false,
                isFetchingSalaryDayDownloadFailed: true,
                SalaryDayDownloadFetchError: action.payload,
                SalaryDayDownload: [],
            };
        }
        case ACTION_TYPES.GET_SALARY_YEAR_DATA_PENDING: {
            return {
                ...state,
                isFetchingSalaryYearData: true,
                isFetchingSalaryYearDataFailed: false,
                SalaryYearDataFetchError: undefined,
                SalaryYearData: [],
            };
        }
        case ACTION_TYPES.GET_SALARY_YEAR_DATA_FULFILLED: {
            return {
                ...state,
                isFetchingSalaryYearData: false,
                isFetchingSalaryYearDataFailed: false,
                SalaryYearDataFetchError: false,
                SalaryYearData: action.payload.data.data,
            };
        }
        case ACTION_TYPES.GET_SALARY_YEAR_DATA_REJECTED: {
            return {
                ...state,
                isFetchingSalaryYearData: false,
                isFetchingSalaryYearDataFailed: true,
                SalaryYearDataFetchError: action.payload,
                SalaryYearData: [],
            };
        }
        case ACTION_TYPES.GET_SALARY_HISTORY_PENDING: {
            return {
                ...state,
                isFetchingSalaryHistory: true,
                isFetchingSalaryHistoryFailed: false,
                SalaryHistoryFetchError: undefined,
                SalaryHistory: [],
            };
        }
        case ACTION_TYPES.GET_SALARY_HISTORY_FULFILLED: {
            return {
                ...state,
                isFetchingSalaryHistory: false,
                isFetchingSalaryHistoryFailed: false,
                SalaryHistoryFetchError: false,
                SalaryHistory: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_SALARY_HISTORY_REJECTED: {
            return {
                ...state,
                isFetchingSalaryHistory: false,
                isFetchingSalaryHistoryFailed: true,
                SalaryHistoryFetchError: action.payload,
                SalaryHistory: [],
            };
        }
        default:
            return state;
    }
}

export default teams;