/* eslint-disable import/no-anonymous-default-export */
import { API_ENDPOINTS } from "../../constants/index";
import { transport, padlocktransport } from "../../Networks/Auth";

export default {
    fetchPaymentPlan: () => {
        let config = {
            url: API_ENDPOINTS.GET_PAYMENT_PLAN,
            method: "GET",
        };

        return padlocktransport(config);
    },

    useravailablefeatures: (id) => {
        let config = {
            url: API_ENDPOINTS.GET_USER_AVAILABLE_FEATURES + id,
            method: "GET",
        };
        return padlocktransport(config);
    },

    userunavailablefeatures: (id) => {
        let config = {
            url: API_ENDPOINTS.GET_USER_UNAVAILABLE_FEATURES + id,
            method: "GET",
        };
        return padlocktransport(config);
    },

    usercustompermissions: (id) => {
        let config = {
            url: API_ENDPOINTS.GET_USER_CUSTOM_PERMISSIONS + id,
            method: "GET",
        };
        return padlocktransport(config);
    },

    fetchOrgUuid: (org_name) => {
        let config = {
            url: API_ENDPOINTS.GET_ORG_UUID + org_name,
            method: "GET",
        };
        return padlocktransport(config);
    },

    fetchApiKeys: (org_uuid) => {
        let config = {
            url: API_ENDPOINTS.GET_API_KEYS + org_uuid,
            method: "GET",
        };
        return padlocktransport(config);
    },

    fetchSecretKeys: (org_uuid, apiName) => {
        let config = {
            url: API_ENDPOINTS.GET_SECRET_KEY + org_uuid + "&name=" + apiName,
            method: "GET",
        };
        return padlocktransport(config);
    },

    postSecretKey: (data) => {
        let config = {
            url: API_ENDPOINTS.POST_SECRET_KEY,
            method: "POST",
            data: data,
        };
        return padlocktransport(config);
    },

    deleteKey: (data) => {
        let config = {
            url: API_ENDPOINTS.DELETE_KEY,
            method: "DELETE",
            data: data,
        };
        return padlocktransport(config);
    },

    patchStatusUpdate: (data) => {
        let config = {
            url: API_ENDPOINTS.API_KEY_STATUS,
            method: "PATCH",
            data: data,
        };
        return padlocktransport(config);
    },

    fetchFeatureUnavailable: () => {
        let config = {
            url: API_ENDPOINTS.GET_FEATURE_UNAVAILABLE,
            method: "GET",
        };

        return padlocktransport(config);
    },

    fetchFeatureMarket: () => {
        let config = {
            url: API_ENDPOINTS.GET_FEATURE_MARKET,
            method: "GET",
        };

        return padlocktransport(config);
    },

    fetchFeatureList: () => {
        let config = {
            url: API_ENDPOINTS.GET_FEATURE_LIST,
            method: "GET",
        };
        return padlocktransport(config);
    },

    fetchOverideFeatureList: (ids, type) => {
        let config = {
            url: API_ENDPOINTS.GET_OVERIDE_FEATURE_LIST + ids + "&type=" + type,
            method: "GET",
        };
        return padlocktransport(config);
    },
    fetchScreenSchotTiming: (type) => {
        let config = {
            url: API_ENDPOINTS.GET_SCREENSHOT_TIMING + type,
            method: "GET",
        };
        return transport(config);
    },
    postScreenshotTiming: (data) => {
        let config = {
            url: API_ENDPOINTS.POST_SCREENSHOT_TIMING,
            method: "POST",
            data: data,
        };
        return padlocktransport(config);
    },
    patchScreenshotTiming: (idTime) => {
        let config = {
            url: API_ENDPOINTS.PATCH_SCREENSHOT_TIMING + idTime,
            method: "PATCH",
        };
        return padlocktransport(config);
    },
    postScreenshotTime: (data) => {
        let config = {
            url: API_ENDPOINTS.POST_SCREENSHOT_TIME,
            method: "POST",
            data: data,
        };
        return transport(config);
    },
    fetchFeatureKey: () => {
        let config = {
            url: API_ENDPOINTS.GET_FEATURE_KEY,
            method: "get",
        };

        return padlocktransport(config);
    },

    postFeatureStart: (data) => {
        let config = {
            url: API_ENDPOINTS.POST_FEATURE_START,
            method: "POST",
            data: data,
        };

        return padlocktransport(config);
    },

    deleteFeatureMarket: (data) => {
        let config = {
            url: API_ENDPOINTS.DELETE_FEATURE_MARKET,
            method: "DELETE",
            data: data,
        };
        return padlocktransport(config);
    },

    deleteFeatureStart: (id) => {
        let config = {
            url: API_ENDPOINTS.DELETE_FEATURE_START + id,
            method: "DELETE",
        };
        return padlocktransport(config);
    },

    postFeatureMarket: (data) => {
        let config = {
            url: API_ENDPOINTS.POST_FEATURE_MARKET,
            method: "POST",
            data: data,
        };
        return padlocktransport(config);
    },

    putFeatureEnable: (id) => {
        let config = {
            url: API_ENDPOINTS.PUT_FEATURE_ENABLE + id,
            method: "PUT",
        };
        return padlocktransport(config);
    },

    putFeatureStop: (id) => {
        let config = {
            url: API_ENDPOINTS.PUT_FEATURE_STOP + id,
            method: "PUT",
        };
        return padlocktransport(config);
    },

    postForgetPassword: (data) => {
        let config = {
            url: API_ENDPOINTS.POST_FORGET_PASSWORD,
            method: "POST",
            data: data,
        };
        return padlocktransport(config);
    },

    GetSubscribeEmail: () => {
        let config = {
            url: API_ENDPOINTS.SUBSCRIBE_EMAIL,
            method: "GET",
        };
        return transport(config);
    },

    updateSubScribeEmail: (data) => {
        let config = {
            url: API_ENDPOINTS.SUBSCRIBE_EMAIL,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    getRoleUpdate: () => {
        let config = {
            url: API_ENDPOINTS.GET_ROLE_UPDATE,
            method: "GET",
        };
        return transport(config);
    },

    fetchTypeInterval: (email) => {
        let config = {
            url: API_ENDPOINTS.GET_TYPE_INTERVAL + email,
            method: "GET",
        };
        return padlocktransport(config);
    },

    fetchAddress: (email) => {
        let config = {
            url: API_ENDPOINTS.GET_ADDRESS + email,
            method: "GET",
        };
        return padlocktransport(config);
    },

    postType: (data) => {
        let config = {
            url: API_ENDPOINTS.POST_TYPE,
            method: "POST",
            data: data,
        };
        return padlocktransport(config);
    },

    fetchLastPayment: (email) => {
        let config = {
            url: API_ENDPOINTS.GET_LAST_PAYMENT + email,
            method: "GET",
        };
        return padlocktransport(config);
    },

    postGenerateInvoice: (data) => {
        let config = {
            url: API_ENDPOINTS.POST_GENERATE_INVOICE,
            method: "POST",
            data: data,
        };
        return padlocktransport(config);
    },

    postPdfGenerate: (data) => {
        let config = {
            url: API_ENDPOINTS.POST_PDF_GENERATOR,
            method: "POST",
            data: data,
            responseType: "blob",
        };
        return padlocktransport(config);
    },

    postTypeInterval: (data) => {
        let config = {
            url: API_ENDPOINTS.POST_TYPE_INTERVAL,
            method: "POST",
            data: data,
        };
        return padlocktransport(config);
    },

    paypalPayment: () => {
        let config = {
            url: API_ENDPOINTS.POST_PAYPAL_PAYMENT,
            method: "GET",
        };
        return padlocktransport(config);
    },

    getUnavailableFeature_team: (type, teamId) => {
        let config = {
            url: API_ENDPOINTS.GET_UNAVAILABLE_FEATURES_TEAM + `type=${type}&teamid=${teamId}`,
            method: "GET",
        };
        return padlocktransport(config);
    },

    getAvailableFeature_team: (type, teamId) => {
        let config = {
            url: API_ENDPOINTS.GET_MARKET_FEATURES_TEAM + `type=${type}&teamid=${teamId}`,
            method: "GET",
        };
        return padlocktransport(config);
    },

    getPermissions_featureList_team: (type, teamId) => {
        let config = {
            url: API_ENDPOINTS.GET_FEATURES_LIST_TEAM + `type=${type}&teamid=${teamId}`,
            method: "GET",
        };
        return padlocktransport(config);
    },

    addFeature_team: (data) => {
        let config = {
            url: API_ENDPOINTS.POST_FEATURE_MARKET,
            method: "POST",
            data: data,
        };
        return padlocktransport(config);
    },

    startFeature_team: (data) => {
        let config = {
            url: API_ENDPOINTS.POST_FEATURE_START,
            method: "POST",
            data: data,
        };
        return padlocktransport(config);
    },

    deleteFeature_team: (featureId) => {
        let config = {
            url: API_ENDPOINTS.DELETE_FEATURE_MARKET,
            method: "DELETE",
            data: featureId,
        };
        return padlocktransport(config);
    },

    stopFeature_team: (featureId) => {
        let config = {
            url: API_ENDPOINTS.DELETE_FEATURE_START + featureId,
            method: "DELETE",
        };
        return padlocktransport(config);
    },

    stopCustomFeat_team: (featureId) => {
        let config = {
            url: API_ENDPOINTS.PUT_FEATURE_STOP + featureId,
            method: "PUT",
        };
        return padlocktransport(config);
    },

    startCustomFeat_team: (featureId) => {
        let config = {
            url: API_ENDPOINTS.PUT_FEATURE_ENABLE + featureId,
            method: "PUT",
        };
        return padlocktransport(config);
    },

    cancelPlan_admin: (data) => {
        let config = {
            url: API_ENDPOINTS.CANCEL_PLAN_ADMIN,
            method: "POST",
            data: data,
        };
        return padlocktransport(config);
    },

    securePaymentStripe: (data) => {
        let config = {
            url: API_ENDPOINTS.SECURE_PAYMENT_STRIPE,
            method: "POST",
            data: data,
        };
        return padlocktransport(config);
    },

    integrateXero: (data) => {
        let config = {
            url: API_ENDPOINTS.XERO_INTEGRATION,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    getXeroTenant: () => {
        let config = {
            url: API_ENDPOINTS.XERO_TENANT,
            method: "POST",
        };
        return transport(config);
    },

    xeroIntegrationUpdate: (data) => {
        let config = {
            url: API_ENDPOINTS.XERO_INTEGRATION,
            method: "PUT",
            data: data,
        };
        return transport(config);
    },
    getXeroIntegration: () => {
        let config = {
            url: API_ENDPOINTS.XERO_INTEGRATION,
            method: "GET",
        };
        return transport(config);
    },
    disconnectXero: () => {
        let config = {
            url: API_ENDPOINTS.XERO_INTEGRATION,
            method: "DELETE",
        };
        return transport(config);
    },
    getPaypalPaymentRequest: (invoiceid) => {
        let config = {
            url: API_ENDPOINTS.PAYPAL_REQUEST + invoiceid,
            method: "GET",
        };
        return padlocktransport(config);
    },
    getOrgDetails: () => {
        let config = {
            url: API_ENDPOINTS.ORG_DETAILS,
            method: "GET",
        };
        return transport(config);
    },
    updateOrgDetails: (data) => {
        let config = {
            url: API_ENDPOINTS.ORG_DETAILS,
            data: data,
            method: "PUT",
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        return transport(config);
    },
    removeOrgImage: () => {
        let config = {
            url: API_ENDPOINTS.ORG_DETAILS,
            method: "DELETE",
        };
        return transport(config);
    },
    getEventLogs: (org_uuid) => {
        let config = {
            url: API_ENDPOINTS.EVENTS_LOGS + org_uuid,
            method: "GET",
        };
        return padlocktransport(config);
    },
};
