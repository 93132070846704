import { Component } from "react";
import { Dialog } from "@headlessui/react";

class Filter extends Component {
     
 
    handleClickOnRegular = () => {
        const {onRoleChange}=this.props
        onRoleChange("Regular")
    }

    handleClickOnObserver = () => {
        const {onRoleChange}=this.props
        onRoleChange("Observer") 
    }

    handleClickOnManager = () => {
        const {onRoleChange}=this.props
        onRoleChange("Manager") 
    }

    handleClickOnAdmin = () => {
        const {onRoleChange}=this.props
        onRoleChange("Admin") 
    }
    render() {
        const { isOpen, setIsOpen } = this.props
        
        return (
            <Dialog open={isOpen ?? false}
                onClose={() => {
                    setIsOpen()
                }}>
                <div className="" />
                <div className="fixed inset-20  z-10 flex justify-end p-4 mr-64 mt-[120px] pr-12" aria-hidden="true">
                    <Dialog.Panel className="bg-white rounded-lg  p-4 w-3/12 absolute mt-4 rounded-md shadow-lg" >
                        <div className="">
                            <div className="flex flex-row justify-between">
                                <Dialog.Title>
                                    <h1 className="text-black font-medium text-[16px] font-inter">Filter by</h1>
                                </Dialog.Title>
                                <a href="#" className="text-[#1055EB] underline font-medium text-[12px] font-inter">Reset</a>
                            </div>
                            <p className="mt-[16px] text-[#6B7280] text-[14px] font-medium font-inter">Role</p>
                            <Dialog.Description>
                                <div className="flex flex-col">
                                    <div className="flex flex-row items-center mt-[13.5px]">
                                        <input type="checkbox" onClick={this.handleClickOnRegular} id="checkbox" className="text-[#684D94] rounded-[4px] focus:ring-0 focus:ring-offset-0" />
                                        <label htmlFor="checkbox" className="text-black font-medium text-[16px] font-inter ml-[8px]">Regular</label>
                                    </div>
                                    <div className="flex flex-row items-center mt-[16px]">
                                        <input type="checkbox" onClick={this.handleClickOnObserver} id="checkbox" className="text-[#684D94] rounded-[4px] focus:ring-0 focus:ring-offset-0" />
                                        <label htmlFor="checkbox" className="text-black font-medium text-[16px] font-inter ml-[8px]">Observer</label>
                                    </div>
                                    <div className="flex flex-row items-center  mt-[16px]">
                                        <input type="checkbox" onClick={this.handleClickOnManager} id="checkbox" className="text-[#684D94] rounded-[4px] focus:ring-0 focus:ring-offset-0" />
                                        <label htmlFor="checkbox" className="text-black font-medium text-[16px] font-inter ml-[8px]">Manager</label>
                                    </div>
                                    <div className="flex flex-row items-center  mt-[16px]">
                                        <input type="checkbox" onClick={this.handleClickOnAdmin} id="checkbox" className="text-[#684D94] rounded-[4px] focus:ring-0 focus:ring-offset-0" />
                                        <label htmlFor="checkbox" className="text-black font-medium text-[16px] font-inter ml-[8px]">Admin</label>
                                    </div>
                                </div>
                            </Dialog.Description>

                        </div>
                    </Dialog.Panel>
                </div>

            </Dialog>
        )
    }


}






export default Filter