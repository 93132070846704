import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../../Components/Layout';
import AttendenceTab from "./tabs/Attendence_tab";
import HourReportTab from "./tabs/HourReport_tab";
import ProductivityTab from "./tabs/Productivity_tab";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import { DesktopComputerIcon } from "@heroicons/react/solid";
import { UsersIcon, UserGroupIcon } from "@heroicons/react/solid";
import { AccessTime, Assessment, MonetizationOn } from "@material-ui/icons";
import { DocumentSearchIcon } from "@heroicons/react/solid";
import { withTranslation } from "react-i18next";
import BookingReportTab from "./tabs/BookingReport_tab";
import PayrollReportTab from "./tabs/PayrollReport_tab";
import TotalHoursReport from "./tabs/TotalHoursReport";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

class Reports extends Component {
    constructor() {
        super();
        this.state = {
            SelectedTab: "attendance",
            urlpath: "",
        };
    }

    componentDidMount() {
        const url = window.location.pathname;
        setTimeout(() => {
            this.setState({ urlpath: url });
        }, 200);

        // setTimeout(() => {
        //     if (
        //         url === "/reports/attendance" ||
        //         url === "/reports/productivity" ||
        //         url === "/reports/hour-report"
        //     ) {
        //         this.setState({ SelectedTab: "attendance" });
        //     }
        // }, 200);
        setTimeout(() => {
            if (this.state.urlpath === "/reports/attendance") {
                this.setState({ SelectedTab: "attendance" });
            } else if (this.state.urlpath === "/reports/productivity") {
                this.setState({ SelectedTab: "productivity" });
            } else if (this.state.urlpath === "/reports/hour-report") {
                this.setState({ SelectedTab: "hour-report" });
            } else if (this.state.urlpath === "/reports/booking-report") {
                this.setState({ SelectedTab: "booking-report" });
            } else if (this.state.urlpath === "/reports/total-hour-report") {
                this.setState({ SelectedTab: "total-hour-report" });
            }
            // else if (this.state.urlpath === "/reports/payroll-report") {
            //     this.setState({ SelectedTab: "payroll-report" });
            // }
            else {
                this.setState({ SelectedTab: "attendance" });
            }
            window.history.pushState({}, null, `/reports/${this.state.SelectedTab}`);
        }, 300);
    }

    HandleSelectTab = (e) => {
        this.setState({ SelectedTab: e });
        window.history.pushState({}, null, `/reports/${e}`);
    };

    HandleMobileSelectTab = (e) => {
        this.setState({ SelectedTab: e.target.value });
        window.history.pushState({}, null, `/reports/${e.target.value}`);
    };

    render() {
        const { t } = this.props;

        return (
            <Layout>
                <div>
                    <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                                Select a tab
                            </label>
                            <select
                                id="tabs"
                                name="tabs"
                                onChange={(e) => this.HandleMobileSelectTab(e)}
                                className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                                value={this.state.SelectedTab}>
                                <option key="attendance" value="attendance">
                                    {t("ReportsTab.attendance")}
                                </option>
                                <option key="productivity" value="productivity">
                                    {t("ReportsTab.productivity")}
                                </option>
                                {localStorage.getItem("ro") === "Observer" ? null : (
                                    <option key="hour-report" value="hour-report">
                                        {t("hour")} {t("ReportsTab.report")}
                                    </option>
                                )}
                                <option key="booking-report" value="booking-report">
                                    {t("ReportsTab.booking")} {t("ReportsTab.report")}
                                </option>
                                <option key="total-hour-report" value="total-hour-report">
                                    Total {t("hour")} {t("ReportsTab.report")}
                                </option>
                                {/* <option key="payroll-report" value="payroll-report">
                                    {"Payroll"} {t("ReportsTab.report")}
                                </option> */}
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                <div
                                    key="attendance"
                                    onClick={() => this.HandleSelectTab("attendance")}
                                    className={classNames(
                                        this.state.SelectedTab === "attendance"
                                            ? "border-primary text-primary"
                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                        "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                    )}
                                    aria-current={
                                        this.state.SelectedTab === "attendance" ? "page" : undefined
                                    }>
                                    <UserGroupIcon
                                        className={classNames(
                                            this.state.SelectedTab === "attendance"
                                                ? "text-primary"
                                                : "text-gray-400 group-hover:text-gray-500",
                                            "-ml-0.5 mr-2 h-5 w-5"
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span>{t("ReportsTab.attendance")}</span>
                                </div>

                                <div
                                    key="productivity"
                                    onClick={() => this.HandleSelectTab("productivity")}
                                    className={classNames(
                                        this.state.SelectedTab === "productivity"
                                            ? "border-primary text-primary"
                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                        "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                    )}
                                    aria-current={
                                        this.state.SelectedTab === "productivity"
                                            ? "page"
                                            : undefined
                                    }>
                                    <UsersIcon
                                        className={classNames(
                                            this.state.SelectedTab === "productivity"
                                                ? "text-primary"
                                                : "text-gray-400 group-hover:text-gray-500",
                                            "-ml-0.5 mr-2 h-5 w-5"
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span>{t("ReportsTab.productivity")}</span>
                                </div>

                                {localStorage.getItem("ro") === "Observer" ? null : (
                                    <div
                                        key="hour-report"
                                        onClick={() => this.HandleSelectTab("hour-report")}
                                        className={classNames(
                                            this.state.SelectedTab === "hour-report"
                                                ? "border-primary text-primary"
                                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                            "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                        )}
                                        aria-current={
                                            this.state.SelectedTab === "hour-report"
                                                ? "page"
                                                : undefined
                                        }>
                                        <DesktopComputerIcon
                                            className={classNames(
                                                this.state.SelectedTab === "hour-report"
                                                    ? "text-primary"
                                                    : "text-gray-400 group-hover:text-gray-500",
                                                "-ml-0.5 mr-2 h-5 w-5"
                                            )}
                                            aria-hidden="true"
                                        />
                                        <span>
                                            {t("hour")} {t("ReportsTab.report")}
                                        </span>
                                    </div>
                                )}
                                {localStorage.getItem("ro") === "Observer" ? null : (
                                    <div
                                        key="booking-report"
                                        onClick={() => this.HandleSelectTab("booking-report")}
                                        className={classNames(
                                            this.state.SelectedTab === "booking-report"
                                                ? "border-primary text-primary"
                                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                            "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                        )}
                                        aria-current={
                                            this.state.SelectedTab === "booking-report"
                                                ? "page"
                                                : undefined
                                        }>
                                        <DocumentSearchIcon
                                            className={classNames(
                                                this.state.SelectedTab === "booking-report"
                                                    ? "text-primary"
                                                    : "text-gray-400 group-hover:text-gray-500",
                                                "-ml-0.5 mr-2 h-5 w-5"
                                            )}
                                            aria-hidden="true"
                                        />
                                        <span>
                                            {t("ReportsTab.booking")} {t("ReportsTab.report")}
                                        </span>
                                    </div>
                                )}
                                <div
                                    key="total-hour-report"
                                    onClick={() => this.HandleSelectTab("total-hour-report")}
                                    className={classNames(
                                        this.state.SelectedTab === "total-hour-report"
                                            ? "border-primary text-primary"
                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                        "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                    )}
                                    aria-current={
                                        this.state.SelectedTab === "total-hour-report"
                                            ? "page"
                                            : undefined
                                    }>
                                    <Assessment
                                        className={classNames(
                                            this.state.SelectedTab === "total-hour-report"
                                                ? "text-primary"
                                                : "text-gray-400 group-hover:text-gray-500",
                                            "-ml-0.5 mr-2 h-5 w-5"
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span>
                                        Total {t("hour")} {t("ReportsTab.report")}
                                    </span>
                                </div>
                                {/* {localStorage.getItem("ro") === "Observer" ? null : (
                                    <div
                                        key="payroll-report"
                                        onClick={() => this.HandleSelectTab("payroll-report")}
                                        className={classNames(
                                            this.state.SelectedTab === "payroll-report"
                                                ? "border-primary text-primary"
                                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                            "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                        )}
                                        aria-current={
                                            this.state.SelectedTab === "payroll-report"
                                                ? "page"
                                                : undefined
                                        }>
                                        <MonetizationOn
                                            className={classNames(
                                                this.state.SelectedTab === "payroll-report"
                                                    ? "text-primary"
                                                    : "text-gray-400 group-hover:text-gray-500",
                                                "-ml-0.5 mr-2 h-5 w-5"
                                            )}
                                            aria-hidden="true"
                                        />
                                        <span>Payroll {t("ReportsTab.report")}</span>
                                    </div>
                                )} */}
                            </nav>
                        </div>
                    </div>
                    {this.state.SelectedTab === "attendance" && <AttendenceTab />}
                    {this.state.SelectedTab === "productivity" && <ProductivityTab />}
                    {this.state.SelectedTab === "hour-report" && <HourReportTab />}
                    {this.state.SelectedTab === "booking-report" && <BookingReportTab />}
                    {this.state.SelectedTab === "payroll-report" && <PayrollReportTab />}
                    {this.state.SelectedTab === "total-hour-report" && <TotalHoursReport />}
                </div>
            </Layout>
        );
    }
}
const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

Reports.propTypes = {

};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Reports));