import { ACTION_TYPES } from "../../../constants";
import api from "../api";

export const managerDashboard = (date) => ({
    type: ACTION_TYPES.FETCH_MANAGER_DASHBOARD,
    payload: api.managerdashboard(date),
});

export const managerDashboardObserver = (date) => ({
    type: ACTION_TYPES.FETCH_MANAGER_DASHBOARD,
    payload: api.managerDashboardObserver(date),
});

export const managerdashboard_observer = (date) => ({
    type: ACTION_TYPES.FETCH_MANAGER_DASHBOARD_OBSERVER,
    payload: api.managerdashboard_observer(date),
});

export const teamsDashboard = (date) => ({
    type: ACTION_TYPES.FETCH_TEAMS_DASHBOARD,
    payload: api.teamsdashboard(date),
});

export const notworkingEmployees = (date) => ({
    type: ACTION_TYPES.FETCH_NOT_WORKING_EMPLOYEES,
    payload: api.notworkingemploees(date),
});

export const orgEmails = (name) => ({
    type: ACTION_TYPES.ORG_EMAILS,
    payload: api.orgemails(name),
});

export const updateuseremail = (email, id) => ({
    type: ACTION_TYPES.UPDATE_USER_EMAIL,
    payload: api.updateuseremail(email, id),
});

export const sendUserInvite = (data) => ({
    type: ACTION_TYPES.SEND_USER_INVITE,
    payload: api.senduserinvite(data),
});

export const DeleteOrgUser = (email, id) => ({
    type: ACTION_TYPES.DELETE_ORG_EMAIL,
    payload: api.deleteorgemail(email, id),
});

export const TeamDetailsDashboard = (date, id) => ({
    type: ACTION_TYPES.TEAM_DETAILS_DASHBOARD,
    payload: api.teamdetailsdashboard(date, id),
});

export const ProjectDetailsDashboard = (date) => ({
    type: ACTION_TYPES.PROJECT_DETAILS_DASHBOARD,
    payload: api.projectdetailsdashboard(date),
});

export const getRolesCheck = () => ({
    type: ACTION_TYPES.GET_ROLES_CHECK,
    payload: api.rolesCheck(),
});

export const getEmpListObserver = () => ({
    type: ACTION_TYPES.GET_EMP_LIST_OBSERVER,
    payload: api.getEmpListObserver(),
});

export const getShowcaseUsersList = (date) => ({
    type: ACTION_TYPES.GET_SHOWCASE_USERS_LIST,
    payload: api.getShowcaseUsersList(date),
});

export const getDashboardLayout = () => ({
    type: ACTION_TYPES.GET_DASHBOARD_LAYOUT,
    payload: api.getDashboardLayout(),
});

export const updateDashboardLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_DASHBOARD_LAYOUT,
    payload: api.updateDashboardLayout(data),
});

export const postDashboardLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_DASHBOARD_LAYOUT,
    payload: api.postDashboardLayout(data),
});

export const getAllProjectTableLayout = () => ({
    type: ACTION_TYPES.GET_ALL_PROJECT_TABLE_LAYOUT,
    payload: api.getAllProjectTableLayout(),
});

export const updateAllProjectTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_ALL_PROJECT_TABLE_LAYOUT,
    payload: api.updateAllProjectTableLayout(data),
});

export const postAllProjectTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_ALL_PROJECT_TABLE_LAYOUT,
    payload: api.postAllProjectTableLayout(data),
});

export const getDashboardArrangement = () => ({
    type: ACTION_TYPES.GET_DASHBOARD_ARRANGEMENT,
    payload: api.getDashboardArrangement(),
});

export const updateDashboardArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_DASHBOARD_ARRANGEMENT,
    payload: api.updateDashboardArrangement(data),
});

export const postDashboardArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_DASHBOARD_ARRANGEMENT,
    payload: api.postDashboardArrangement(data),
});

export const getEmployeeMoodTableData = (date) => ({
    type: ACTION_TYPES.GET_EMPLOYEE_MOOD_TABLE,
    payload: api.getEmployeeMoodTableData(date),
});

export const getEmployeeMoodTableLayout = () => ({
    type: ACTION_TYPES.GET_MOOD_TABLE_LAYOUT,
    payload: api.getEmployeeMoodTableLayout(),
});

export const getEmployeeMoodTableArrangement = () => ({
    type: ACTION_TYPES.GET_MOOD_TABLE_ARRANGEMENT,
    payload: api.getEmployeeMoodTableArrangement(),
});

export const getEmployeeTableLayout = () => ({
    type: ACTION_TYPES.GET_EMPLOYEE_TABLE_LAYOUT,
    payload: api.getEmployeeTableLayout(),
});

export const getEmployeeTableArrangement = () => ({
    type: ACTION_TYPES.GET_EMPLOYEE_TABLE_ARRANGEMENT,
    payload: api.getEmployeeTableArrangement(),
});

export const getTeamTableLayout = () => ({
    type: ACTION_TYPES.GET_TEAM_TABLE_LAYOUT,
    payload: api.getTeamTableLayout(),
});
export const getTeamTableArrangement = () => ({
    type: ACTION_TYPES.GET_TEAM_TABLE_ARRANGEMENT,
    payload: api.getTeamTableArrangement(),
});

export const updateEmployeeMoodTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_MOOD_TABLE_LAYOUT,
    payload: api.updateEmployeeMoodTableLayout(data),
});

export const updateEmployeeMoodTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_MOOD_TABLE_ARRANGEMENT,
    payload: api.updateEmployeeMoodTableArrangement(data),
});

export const updateEmployeeTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_EMPLOYEE_TABLE_LAYOUT,
    payload: api.updateEmployeeTableLayout(data),
});

export const updateEmployeeTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_EMPLOYEE_TABLE_ARRANGEMENT,
    payload: api.updateEmployeeTableArrangement(data),
});

export const updateTeamTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_TEAM_TABLE_LAYOUT,
    payload: api.updateTeamTableLayout(data),
});
export const updateTeamTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_TEAM_TABLE_ARRANGEMENT,
    payload: api.updateTeamTableArrangement(data),
});

export const postEmployeeMoodTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_MOOD_TABLE_LAYOUT,
    payload: api.postEmployeeMoodTableLayout(data),
});

export const postEmployeeMoodTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_MOOD_TABLE_ARRANGEMENT,
    payload: api.postEmployeeMoodTableArrangement(data),
});

export const postEmployeeTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_EMPLOYEE_TABLE_LAYOUT,
    payload: api.postEmployeeTableLayout(data),
});

export const postEmployeeTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_EMPLOYEE_TABLE_ARRANGEMENT,
    payload: api.postEmployeeTableArrangement(data),
});

export const postTeamTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_TEAM_TABLE_LAYOUT,
    payload: api.postTeamTableLayout(data),
});
export const postTeamTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_TEAM_TABLE_ARRANGEMENT,
    payload: api.postTeamTableArrangement(data),
});

export const getAllProjectTableArrangement = () => ({
    type: ACTION_TYPES.GET_ALL_PROJECT_TABLE_ARRANGEMENT,
    payload: api.getAllProjectTableArrangement(),
});

export const updateAllProjectTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_ALL_PROJECT_TABLE_ARRANGEMENT,
    payload: api.updateAllProjectTableArrangement(data),
});

export const postAllProjectTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_ALL_PROJECT_TABLE_ARRANGEMENT,
    payload: api.postAllProjectTableArrangement(data),
});

export const getMoodGraphData = (date) => ({
    type: ACTION_TYPES.GET_MOOD_GRAPH_DATA,
    payload: api.getMoodGraphData(date),
});

export const getAllProjectTableData = (date) => ({
    type: ACTION_TYPES.GET_ALL_PROJECT_TABLE_DATA,
    payload: api.getAllProjectTableData(date),
});


export const getProjectActivity = (date, id) => ({
    type: ACTION_TYPES.GET_PROJECT_ACTIVITY,
    payload: api.getProjectActivity(date, id),
});

export const getProjectWorking = (date, id) => ({
    type: ACTION_TYPES.GET_PROJECT_WORKING,
    payload: api.getProjectWorking(date, id),
});

export const getProjectEmployeesWorkingDetails = (date, id) => ({
    type: ACTION_TYPES.GET_EMPLOYEES_WORKING_IN_PROJECT,
    payload: api.getProjectEmployeesWorkingDetails(date, id),
});

export const getFeatureListing = () => ({
    type: ACTION_TYPES.GET_FEATURE_LISTING,
    payload: api.getFeatureListing(),
});


export const getTeamDashboardLayout = () => ({
    type: ACTION_TYPES.GET_DASHBOARD_LAYOUT,
    payload: api.getTeamDashboardLayout(),
});

export const updateTeamDashboardLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_DASHBOARD_LAYOUT,
    payload: api.updateTeamDashboardLayout(data),
});

export const postTeamDashboardLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_DASHBOARD_LAYOUT,
    payload: api.postTeamDashboardLayout(data),
});

export const getTeamDashboardArrangement = () => ({
    type: ACTION_TYPES.GET_DASHBOARD_ARRANGEMENT,
    payload: api.getTeamDashboardArrangement(),
});

export const updateTeamDashboardArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_DASHBOARD_ARRANGEMENT,
    payload: api.updateTeamDashboardArrangement(data),
});

export const postTeamDashboardArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_DASHBOARD_ARRANGEMENT,
    payload: api.postTeamDashboardArrangement(data),
});

export const getTeamProjectTableLayout = () => ({
    type: ACTION_TYPES.GET_ALL_PROJECT_TABLE_LAYOUT,
    payload: api.getTeamProjectTableLayout(),
});

export const updateTeamProjectTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_ALL_PROJECT_TABLE_LAYOUT,
    payload: api.updateTeamProjectTableLayout(data),
});

export const postTeamProjectTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_ALL_PROJECT_TABLE_LAYOUT,
    payload: api.postTeamProjectTableLayout(data),
});

export const getTeamProjectTableArrangement = () => ({
    type: ACTION_TYPES.GET_ALL_PROJECT_TABLE_ARRANGEMENT,
    payload: api.getTeamProjectTableArrangement(),
});

export const updateTeamProjectTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_ALL_PROJECT_TABLE_ARRANGEMENT,
    payload: api.updateTeamProjectTableArrangement(data),
});

export const postTeamProjectTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_ALL_PROJECT_TABLE_ARRANGEMENT,
    payload: api.postTeamProjectTableArrangement(data),
});

export const getTeamMemberTableLayout = () => ({
    type: ACTION_TYPES.GET_TEAM_TABLE_LAYOUT,
    payload: api.getTeamMemberTableLayout(),
});

export const updateTeamMemberTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_TEAM_TABLE_LAYOUT,
    payload: api.updateTeamMemberTableLayout(data),
});

export const postTeamMemberTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_TEAM_TABLE_LAYOUT,
    payload: api.postTeamMemberTableLayout(data),
});
export const getTeamMemberTableArrangement = () => ({
    type: ACTION_TYPES.GET_TEAM_TABLE_ARRANGEMENT,
    payload: api.getTeamMemberTableArrangement(),
});
export const updateTeamMemberTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_TEAM_TABLE_ARRANGEMENT,
    payload: api.updateTeamMemberTableArrangement(data),
});

export const postTeamMemberTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_TEAM_TABLE_ARRANGEMENT,
    payload: api.postTeamMemberTableArrangement(data),
});

export const getTeamEmployeeWorkingTableLayout = () => ({
    type: ACTION_TYPES.GET_TEAM_TABLE_LAYOUT,
    payload: api.getTeamEmployeeWorkingTableLayout(),
});

export const updateTeamEmployeeWorkingTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_TEAM_TABLE_LAYOUT,
    payload: api.updateTeamEmployeeWorkingTableLayout(data),
});

export const postTeamEmployeeWorkingTableLayout = (data) => ({
    type: ACTION_TYPES.UPDATE_TEAM_TABLE_LAYOUT,
    payload: api.postTeamEmployeeWorkingTableLayout(data),
});
export const getTeamEmployeeWorkingTableArrangement = () => ({
    type: ACTION_TYPES.GET_TEAM_TABLE_ARRANGEMENT,
    payload: api.getTeamEmployeeWorkingTableArrangement(),
});
export const updateTeamEmployeeWorkingTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_TEAM_TABLE_ARRANGEMENT,
    payload: api.updateTeamEmployeeWorkingTableArrangement(data),
});

export const postTeamEmployeeWorkingTableArrangement = (data) => ({
    type: ACTION_TYPES.UPDATE_TEAM_TABLE_ARRANGEMENT,
    payload: api.postTeamEmployeeWorkingTableArrangement(data),
});

export const getProjectWorkingData = (date, id) => ({
    type: ACTION_TYPES.GET_PROJECT_WORKING_DATA,
    payload: api.getProjectWorkingData(date, id),
});

export const getTeamActivity = (date, name) => ({
    type: ACTION_TYPES.GET_TEAM_ACTIVITY,
    payload: api.getTeamActivity(date, name),
});

export const getTeamWorking = (date, name) => ({
    type: ACTION_TYPES.GET_TEAM_WORKING,
    payload: api.getTeamWorking(date, name),
});

export const getUserProjectChange = (date, uuid) => ({
    type: ACTION_TYPES.GET_USER_PROJECT_CHANGE_DATA,
    payload: api.getUserProjectChange(date, uuid),
});
export const getCurrentStatus = (date, time) => ({
    type: ACTION_TYPES.GET_CURRENT_STATUS,
    payload: api.getCurrentStatus(date, time),
});
export const getEmployeeData = (date, time) => ({
    type: ACTION_TYPES.EMPLOYEES_DATA,
    payload: api.getEmployeeData(date, time),
});
