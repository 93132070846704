import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import PrimaryButton from "../../Components/PrimaryButton";
import Layout from "../../Components/Layout";
import { fetchallEmployees } from "../../services/projects/actions";
import {
  ProjectDetailsDashboard,
  managerDashboard,
  getProjectActivity,
  getProjectWorking,
  getProjectEmployeesWorkingDetails,
  getProjectWorkingData,
} from "../../services/dashboard/actions";
import { CalendarIcon, ChevronLeftIcon } from "@heroicons/react/outline";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/global.css";
import { BsGearFill } from "react-icons/bs";
import TimezoneSelect, { allTimezones } from "react-timezone-select";

import { withTranslation } from "react-i18next";
import SelectField from "../../Components/SelectField";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { BeatLoader } from "react-spinners";
import ReactHighcharts from "react-highcharts";
import { getEmpListObserver } from "../../services/dashboard/actions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class DetailedProjectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentdata: props.location.state,
      selectedDate: new Date(),
      selectedTimezone: "",
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const date = query.get("date");
    const timezone = query.get("timezone");
    const dateTemp =
      date === undefined || date === "" || date === null
        ? new Date()
        : new Date(date);
    setTimeout(() => {
      this.setState(
        {
          selectedDate:dateTemp,
          startDate: new Date(moment(dateTemp).startOf('week')),
          endDate: new Date(moment(dateTemp).endOf('week')),
          selectedTimezone:
            timezone === undefined || timezone === ""
              ? timezone == null
                ? ""
                : timezone
              : this.state.selectedTimezone,
        },
        () => {
          this.props.getProjectActivity(
            moment(this.state.endDate).format("YYYY-MM-DD"),
            this.state.parentdata.projectId
          );
          this.props.getProjectWorking(
            moment(dateTemp).format("YYYY-MM-DD"),
            this.state.parentdata.projectId
          );
          this.props.getProjectEmployeesWorkingDetails(
            moment(dateTemp).format("YYYY-MM-DD"),
            this.state.parentdata.projectId
          );

          this.props.getProjectWorkingData(
            moment(dateTemp).format("YYYY-MM-DD"),
            this.state.parentdata.projectId
          );
        }
      );
      // window.history.pushState(
      //   {},
      //   null,
      //   `/dashboard/?project=${this.state.parentdata.projectId}/?date=${moment(
      //     this.state.selectedDate
      //   ).format("YYYY-MM-DD")}`
      // );
      window.history.pushState(
        {},
        null,
        `/projects/?project=${this.state.parentdata.projectId}/?date=${moment(
          this.state.selectedDate
        ).format("YYYY-MM-DD")}`
      );
      this.props.fetchallEmployees();
    }, 100);

    // setTimeout(() => {
    //     this.props.ProjectDetailsDashboard(
    //         moment(this.state.selectedDate).format("YYYY-MM-DD")
    //     );
    //     this.props.managerDashboard(moment(this.state.selectedDate).format("YYYY-MM-DD"));
    // }, 200);
  }

  handleDateChange = (date) => {
    
    this.setState({ selectedDate: date, startDate:new Date(moment(date).startOf('week')),endDate:new Date(moment(date).endOf('week'))  }, () => {
      window.history.pushState(
        {},
        null,
        `/projects/?project=${this.state.parentdata.projectId}/?date=${moment(
          this.state.selectedDate
        ).format("YYYY-MM-DD")}&timezone=${this.state.selectedTimezone}`
      );
      let date = moment(this.state.endDate).format("YYYY-MM-DD");
      let currentDate=  moment(this.state.selectedDate).format("YYYY-MM-DD")
      this.props.getProjectActivity(date, this.state.parentdata.projectId);
      this.props.getProjectWorking(currentDate, this.state.parentdata.projectId);
      this.props.getProjectEmployeesWorkingDetails(
        currentDate,
        this.state.parentdata.projectId
      );
    });
  };

  onTodayClick = () => {
    this.setState({ selectedDate: new Date(), startDate:new Date(moment(new Date()).startOf('week')),endDate:new Date(moment(new Date()).endOf('week')) }, () => {
      window.history.pushState(
        {},
        null,
        `/projects/?project=${this.state.parentdata.projectId}/?date=${moment(
          this.state.selectedDate
        ).format("YYYY-MM-DD")}&timezone=${this.state.selectedTimezone}`
      );

      let date = moment(this.state.endDate).format("YYYY-MM-DD");
      let currentDate=  moment(this.state.selectedDate).format("YYYY-MM-DD")
      this.props.getProjectActivity(date, this.state.parentdata.projectId);
      this.props.getProjectWorking(currentDate, this.state.parentdata.projectId);
      this.props.getProjectEmployeesWorkingDetails(
        currentDate,
        this.state.parentdata.projectId
      );
    });
  };

  render() {
    const { t } = this.props;
    let dashboardKpi =
      this.props.dashboard && this.props.dashboard.ProjectWorking;
    let productivityData =
      this.props.dashboard && this.props.dashboard.ProjectActivity;
    let employeeworkinginproject =
      this.props.dashboard &&
      this.props.dashboard.ProjectWorkingEmpDetails &&
      this.props.dashboard.ProjectWorkingEmpDetails.data;
    let notworkingemployees =
      this.props.dashboard &&
      this.props.dashboard.ProjectWorkingData &&
      this.props.dashboard.ProjectWorkingData.not_working;
      let employeeworkinginprojectname=
      employeeworkinginproject?.map((item)=>item.user_name)
    let arrayDates = [];
    let arrayProductive = [];
    if (productivityData && productivityData.length > 0) {
      for (let val of productivityData) {
        arrayDates.push(moment(val.date).format("ddd"));
        arrayProductive.push(parseInt(val.duration / 60));
      }
    }
    let employees =
      this.props.dashboard && this.props.projects.AllEmployees;
    const ProjectLink = (data) => {
      localStorage.setItem("dgfsdgf", JSON.stringify(data));
      localStorage.setItem(
        "selected Date",
        JSON.stringify(this.state.selectedDate)
      );
      let employeeSelected = employees.find(
        (emp) => emp.user_login_as === data.user_name
      );
      let props1 = {
        selectedEmployee: employeeSelected.user_id,
        selectedDate: this.state.selectedDate,
        prevPath: window.location.pathname,
      };
      this.props.history.push({
        pathname: "/timetracker/",
        state: { from: window.location.pathname, data: props1 },
      });
    };
    
   
    const nextWeekprodGraph = () => {
      let currentDate = new Date(this.state.endDate.getTime()-7*24*60*60*1000);
      let currentDay = currentDate.getDay();
      let daysToAdd = currentDay === 0 ? 7 : 7 - currentDay;
      let startDate = new Date(currentDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
      startDate.setDate(startDate.getDate() + 1);
      let startDayOfWeek = startDate.getDay();
      let daysToSunday = startDayOfWeek === 0 ? 0 : 7 - startDayOfWeek;
      startDate.setDate(startDate.getDate() + daysToSunday);
      
      let today = moment().startOf('day').format("YYYY-MM-DD");
      let startDateFormat = moment(startDate).format("YYYY-MM-DD");
    
      if (startDateFormat !== today) {
        let endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);
        this.setState({ startDate: startDate, endDate: endDate });
        this.props.getProjectActivity(
          moment(endDate).format("YYYY-MM-DD"),
          this.state.parentdata.projectId
        );
      }
    };
    
    

    const lastWeekprodGraph = () => {
      let currentDate = new Date(this.state.endDate);
      let currentDay = currentDate.getDay();
      let daysToSubtract = currentDay === 0 ? 0 : currentDay;
      let startDate = new Date(currentDate.getTime() - (daysToSubtract * 24 * 60 * 60 * 1000));
  
      let startDayOfWeek = startDate.getDay();
      let daysToSunday = startDayOfWeek === 0 ? 7 : startDayOfWeek;
      startDate.setDate(startDate.getDate() - daysToSunday);
  
      let endDate = new Date(startDate.getTime() + (6 * 24 * 60 * 60 * 1000));
      this.setState({ startDate: startDate, endDate: endDate });
      this.props.getProjectActivity(
        moment(endDate).format("YYYY-MM-DD"),
        this.state.parentdata.projectId
      );
    };
    const productivitychart = {
      chart: {
        type: "column",
        height: 250,
        zoomType: "x",
        style: {
          fontFamily: "Lato",
          fontSize: "15px",
        },
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: `${t("average")}`,
        },
      },

      xAxis: {
        categories: arrayDates,
        crosshair: true,
      },
      tooltip: {
        valueSuffix: "Hr",
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          showInLegend: false,
          color: "#684D94",
          name: "Productive",
          data: arrayProductive,
        },
        // {
        //     showInLegend: true,
        //     color: '#9279b9',
        //     name: 'Unproductive',
        //     data: arrayUnproductive,
        // },
        // {
        //     showInLegend: true,
        //     color: '#E6D6F3',
        //     name: 'Undefined',
        //     data: arrayUndefined,
        // },
      ],
    };
    const AttendanceChart = {
      chart: {
        type: "pie",
        height: 250,
        style: {
          fontFamily: "Lato",
          fontSize: "15px",
        },
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.y}</b><br/> <b>Employees:</b>{point.additionalInfo}",
      },
      title: {
        text: "",
      },

      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.y}",
          },
          showInLegend: true,
        },
      },

      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Employees",
          innerSize: "60%",
          data: [
            {
              name: "Offline",
              y: dashboardKpi && dashboardKpi.not_working,
              color: "#0d233a",
              additionalInfo:notworkingemployees?.length>0?notworkingemployees.join(", "):"None",
            },
            {
              name: "Online",
              y: dashboardKpi && dashboardKpi.working,
              color: "#684d94",
              additionalInfo:employeeworkinginprojectname?.length>0?employeeworkinginprojectname.join(", "):"None",
            },
          ],
        },
      ],
    };

   

    return (
        <Layout>
            <div className="bg-white px-2 py-2 border-b border-gray-200 rounded-lg">
                <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
                    <div className="flex text-textprimary text-xl">
                        <nav className="rounded-md w-full">
                            <ol className="list-reset flex">
                                <li>
                                    <Link
                                        // to="/dashboard"
                                        to="/projects"
                                        className="text-primary hover:text-primary">
                                        {/* {t("dashboard")} */}
                                        {t("projects")}
                                    </Link>
                                </li>
                                <li>
                                    <span className="text-textprimary mx-2">/</span>
                                </li>
                                <li className="text-textprimary capitalize">
                                    {this.state.parentdata.projectName}
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="flex flex-shrink-0">
                        <DatePicker
                            className="rounded-md bg-transparent"
                            dateFormat="MMMM dd yyyy"
                            selected={this.state.selectedDate}
                            onChange={(date) => this.handleDateChange(date)}
                        />
                        <CalendarIcon className="-ml-10 w-8"></CalendarIcon>
                        <button
                            type="button"
                            onClick={this.onTodayClick}
                            className="inline-block ml-5 px-8 py-2.5 bg-secondary text-white font-medium text-xs leading-tight uppercase rounded-lg shadow-md hover:bg-secondary hover:shadow-lg focus:bg-secondary focus:shadow-lg focus:outline-none focus:ring-0 active:bg-secondary active:shadow-lg transition duration-150 ease-in-out">
                            {t("today")}{" "}
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex mt-4 items-center bg-gray-100">
                <div className="container mx-auto">
                    <div className="grid gap-3 grid-cols-2 lg:grid-cols-3">
                        <div className="col-span-2 p-5 bg-white rounded shadow-md">
                            <div className="flex items-center justify-between">
                                <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                                    <div className="md:hidden">
                                        <label htmlFor="tabs" className="sr-only">
                                            {t("requestsModule.selectATab")}
                                        </label>
                                        <select
                                            id="tabs"
                                            name="tabs"
                                            onChange={(e) => this.HandleMobileSelectTab(e)}
                                            className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                                            defaultValue={this.state.SelectedTab}>
                                            <option key="Productivity" value="Productivity">
                                                {t("ReportsTab.productivity")}
                                            </option>
                                        </select>
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="-mb-px flex space-x-4" aria-label="Tabs">
                                            <div
                                                key="Productivity"
                                                onClick={() => this.HandleSelectTab("Productivity")}
                                                className={classNames(
                                                    this.state.SelectedTab === "Productivity"
                                                        ? "border-primary text-primary"
                                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                                    "group inline-flex items-center py-2 px-1 border-b-2 font-medium text-md cursor-pointer"
                                                )}
                                                aria-current={
                                                    this.state.SelectedTab === "Productivity"
                                                        ? "page"
                                                        : undefined
                                                }>
                                                <span>{t("ReportsTab.productivity")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    {/* <div className="text-sm text-textprimary flex">
                                                <SelectField
                                                    onSelectChange={(e) => {
                                                        this.handleSelectEmployee(e);
                                                    }}
                                                    name={t("select") + " " + t("employee")}
                                                    arrayOfData={listofAllEmployees}
                                                    id={"name"}></SelectField>
                                            </div> */}
                                    <div className="flex justify-end items-center mt-2">
                                        <button
                                            className=""
                                            onClick={() => {
                                                lastWeekprodGraph();
                                            }}>
                                            <ChevronLeftIcon className="text-gray-600 h-5 w-5" />
                                        </button>
                                        <p className="mx-1 text-gray-500 text-sm">
                                            {moment(this.state.startDate).format("YYYY-MM-DD")}{" "}
                                            <span className="mx-1 text-md">to</span>{" "}
                                            {moment(this.state.endDate).format("YYYY-MM-DD")}
                                        </p>
                                        <button
                                            className=""
                                            onClick={() => {
                                                nextWeekprodGraph();
                                            }}>
                                            <ChevronRightIcon className="text-gray-600 h-5 w-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {this.state.productivitygraphloading ? (
                                <div className="text-center h-64 w-full flex justify-center items-center">
                                    <BeatLoader
                                        color={"#684994"}
                                        productivitygraphloading={
                                            this.state.productivitygraphloading
                                        }
                                        size={15}
                                        margin={2}
                                    />
                                </div>
                            ) : (
                                <div className="mt-6">
                                    <ReactHighcharts config={productivitychart}> </ReactHighcharts>{" "}
                                </div>
                            )}
                        </div>

                        <div className="p-5 bg-white rounded shadow-md lg-m:col-span-2">
                            <div className="text-textprimary text-lg font-semibold">
                                {" "}
                                {t("currentStatus")}
                            </div>
                            <div className="mt-4">
                                {" "}
                                <ReactHighcharts config={AttendanceChart}> </ReactHighcharts>{" "}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white mt-4 px-2 py-2 border-b border-gray-200 rounded-lg">
                <div className="flex items-center flex-wrap sm:flex-nowrap">
                    <div className="mt-3 text-textprimary text-lg font-semibold">
                        {t("empWorking")}
                    </div>
                    <BsGearFill className="ml-4 mt-4 text-lg flex-shrink-0 text-primary hover:cursor-pointer" />
                </div>
                <div className="mt-4 flex flex-col overflow-y-auto bg-gray-100 ">
                    <div className="inline-block min-w-full align-middle ">
                        <div className="shadow-sm ring-1 ring-black ring-opacity-5 ">
                            <table className="min-w-full border-separate ">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className=" top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                            {t("employee")}
                                        </th>
                                        <th
                                            scope="col"
                                            className=" top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                            {t("totalHoursSpend")}
                                        </th>
                                        <th
                                            scope="col"
                                            className=" top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                                            {t("task")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {employeeworkinginproject &&
                                        employeeworkinginproject.map((el, i) => (
                                            <tr key={i}>
                                                <td
                                                    className={classNames(
                                                        i !== employeeworkinginproject.length - 1
                                                            ? "border-b border-gray-200"
                                                            : "",
                                                        "whitespace-nowrap py-4 pl-4 pr-3 text-base cursor-pointer font-medium text-primary sm:pl-6 lg:pl-12"
                                                    )}
                                                    onClick={() => {
                                                        ProjectLink(el);
                                                    }}>
                                                    {el.user_name}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        i !== employeeworkinginproject.length - 1
                                                            ? "border-b border-gray-200"
                                                            : "",
                                                        "whitespace-nowrap px-3 py-4 text-base text-gray-500 hidden sm:table-cell"
                                                    )}>
                                                    {moment
                                                        .duration(el.duration, "minutes")
                                                        .days() *
                                                        24 +
                                                        moment
                                                            .duration(el.duration, "minutes")
                                                            .hours() +
                                                        " hrs " +
                                                        moment
                                                            .duration(el.duration, "minutes")
                                                            .minutes() +
                                                        " mins"}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        i !== employeeworkinginproject.length - 1
                                                            ? "border-b border-gray-200"
                                                            : "",
                                                        "whitespace-nowrap px-3 py-4 text-base text-gray-500 hidden lg:table-cell"
                                                    )}>
                                                    {el.task}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white mt-4 px-2 py-2 border-b border-gray-200 rounded-lg">
                <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
                    <div className="ml-4 mt-4 text-textprimary text-lg font-semibold">
                        {t("project")} {t("empNotLogged")}
                    </div>
                    <div className="ml-4 mt-4 flex-shrink-0"></div>
                </div>
                <div className="flex flex-col overflow-y-auto">
                    <div className="flex flex-wrap p-4">
                        {notworkingemployees &&
                            notworkingemployees.map((el, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-primary bg-gray-100">
                                        <div className="text-base font-normal leading-none max-w-full flex-initial p-2">
                                            {el}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  teams: state.teams,
  projects: state.projects,
});

const mapDispatchToProps = (dispatch) => ({
  ProjectDetailsDashboard: (date) => dispatch(ProjectDetailsDashboard(date)),
  managerDashboard: (date) => dispatch(managerDashboard(date)),
  getProjectActivity: (date, id) => dispatch(getProjectActivity(date, id)),
  getProjectWorking: (date, id) => dispatch(getProjectWorking(date, id)),
  getProjectEmployeesWorkingDetails: (date, id) =>
    dispatch(getProjectEmployeesWorkingDetails(date, id)),
  getProjectWorkingData: (date, id) =>
    dispatch(getProjectWorkingData(date, id)),
  getEmpListObserver: () => dispatch(getEmpListObserver()),
  fetchallEmployees: () => dispatch(fetchallEmployees()),
});

DetailedProjectPage.propTypes = {};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(DetailedProjectPage)
);
