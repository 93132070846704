import React, { Component, Fragment, useRef } from "react";
import { connect } from "react-redux";
import {
    fetchactiveuserslist,
    fetchInactiveuserslist,
    fetchAllWorkstatons,
    fetchInactiveClient,
    CreateTeam,
    CreateClient,
    fetchClient,
    deleteClient,
    CreateTeamPadlock,
    CreateClientPadlock,
    FetchactiveTeams,
    FetchInactiveTeams,
    addclientToTeam,
    ReactivateTeam,
    DeleteteamfromPadlock,
    Deleteteam,
    DeleteUserfromPadlock,
    DeleteUser,
    ReactivateUserFromPadlock,
    ReactivateUser,
    DeleteWorkstation,
    createWorkstation,
    updateWorkstation,
    getUsersfromTeam,
    addmembertoTeaminPadlock,
    addmembertoTeam,
    DeleteTeammember,
    createWorkstationWorkahub,
    createClientWorkahub,
    getObserverList,
    getManagerList,
    activeTeamObserver,
    fetchActiveUsersObserver,
    DeleteTeammembers,
    DeleteTeammembersPadlock,
} from "../../services/teams/actions";
import { teamsDashboard } from "../../services/dashboard/actions";
import Layout from "../../Components/Layout";
import PrimaryButton from "../../Components/PrimaryButton";
import "../../styles/global.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
    UsersIcon,
    UserGroupIcon,
    DesktopComputerIcon,
    DocumentSearchIcon,
    PencilIcon,
    TrashIcon,
    CheckIcon,
    InformationCircleIcon,
    CalendarIcon,
} from "@heroicons/react/solid";
import { ExclamationIcon } from "@heroicons/react/outline";
import { editUserDetails } from "../../services/userProfile/actions";
import { GrUserManager } from "react-icons/gr";
import { LockClosedIcon, OfficeBuildingIcon } from "@heroicons/react/outline";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import CreateTeamModal from "./createteamModal";

import UpdateTeamModal from "./updateTeamModal";
import ConfirmationDialoag from "../../Components/ConfirmationDialogue";
import CreateWorkstationModal from "./createworkstationmodal";
import UpdateWorkstationModal from "./createworkstationmodal";
import { toast } from "react-toastify";

import { Dialog, Transition } from "@headlessui/react";

import { Link } from "react-router-dom";
import InputField from "../../Components/InputField";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
import hideObsPNG from "../../assets/hideObserver.png";
import { BiSortAlt2 } from "react-icons/bi";
import { HiOutlineFilter } from "react-icons/hi";
import { withTranslation } from "react-i18next";
import EmployeePayroll from "./EmployeePayroll";
import Filter from "../../scenes/Teams/Filter";
import SortByEmploye from "../../scenes/Teams/SortByEmploye";
import SortByProjects from "./SortByProjects";
import SortByEmail from "./SortByEmail";
import AddClientToTeamModal from "./addClientToTeam";
import CreateNewClientModal from "./createClientModal";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";

const userRole = localStorage.getItem("ro");
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export const validEmailRegex = RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

class Teams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SelectedTab: "teams",
            isSort: false,
            showAscendingbuttontname: true,
            showDescendingbuttontname: true,
            showDescendingbuttontdate: true,
            showAscendingbuttontdate: true,
            showDescendingbuttontmember: true,
            showAscendingbuttontmember: true,
            showDescendingbuttonename: true,
            showAscendingbuttonename: true,
            showDescendingbuttoneemail: true,
            showAscendingbuttoneemail: true,
            showAscendingbuttoneproject: true,
            showDescendingbuttoneproject: true,
            showAscendingbuttoninename: true,
            showDescendingbuttoninename: true,
            showAscendingbuttonineemail: true,
            showDescendingbuttonineemail: true,
            showAscendingbuttonworkname: true,
            showDescendingbuttonworkname: true,
            showAscendingbuttonworkname2: true,
            showDescendingbuttonworkname2: true,
            sortedTeams: [],
            sortedinactiveTeams: [],
            isSort1: false,
            isSort2: false,
            sortedactiveUsers: [],
            isSort3: false,
            sortedinactiveUsers: [],
            isSort4: false,
            sortedworkstation: [],
            TeamsSubSelectedTab: "Active",
            UsersSubSelectedTab: "Active",
            ClientsSubSelectedTab: "Active", //added
            perPage: 8,
            activeteamspage: 1,
            inactiveteamspage: 1,
            activeuserspage: 1,
            inactiveuserspage: 1,
            activeclientpage: 1,
            inactiveclientpage: 1,
            teamcreationModal: false,
            //
            clientcreationModal: false,
            searchBox: "",
            searchBoxEvent: false,
            totalCountAct: "",
            //
            searchBoxIn: "",
            searchBoxEventIn: false,
            totalCountIn: "",
            orguuid: "",
            teamuuid: "",
            enableDeleteTeamConfirmationModal: false,
            enableDeleteTeamMemberConfirmationModal: false,
            enableTeamReactivateConfirmationModal: false,
            enableDeleteUserConfirmationModal: false,
            enableUserReactivateConfirmationModal: false,
            enableDeleteWorkstationConfirmationModal: false,

            enableDeleteClientConfirmationModal: false,
            workstationcreationModal: false,
            teamupdationModal: false,
            workstationUpdateModal: false,
            listofselectedUsers: [],
            teamName: "",
            userloginas: "",

            workstationname: "",
            workstationemail: "",
            workstationdescription: "",
            workstationId: "",
            clientId: "",
            clientslength: 0,

            SelectedTeamName: "",
            SelectedTeamUUID: "",
            SelectedClientName: "",
            showAddObserverModal: false,
            addObserver: true,
            saveObserver: false,
            listofObserData: [],
            listofSelectedTeamobserver: "",
            observerEmail: "",
            listOfteamsobserUUID: [],
            observerRole: "observer",
            domain_name: "",
            user_uuid: "",

            showAddManagerModal: false,
            addManager: true,
            saveManager: false,
            listofManagerData: [],
            listofSelectedTeamManager: "",
            managerEmail: "",
            listOfteamsmngrUUID: [],
            managerRole: "manger",

            ObserverList: [],
            ManagerList: [],

            deleteObserverModal: false,
            deleteObsEmail: "",
            deleteObsUuid: "",

            deleteManagerModal: false,
            deleteMngrEmail: "",
            deleteMngrUuid: "",

            editObserverModal: false,
            selectedObstoEdit: {},
            editObsFirstName: "",
            editObsLastName: "",
            listofEditObsTeamSelected: "",
            listOfEditObsTeamUUID: "",
            listofEditObsTeamtoRemove: "",
            listofEditObsTeamtoRemoveUUID: "",

            showObserverData: false,
            showObserverDataUsers: false,
            selectedFinancialYear: "2022-2023",
            selectedUser: "",
            selectedClient: "",
            selectedMonth: new Date(),
            showMonthActivity: false,
            urlpath: "",
            isOpen: false,
            isSortOpenEmploye: false,
            isSortOpenProjects: false,
            isSortOpenEmail: false,
            userRole: {},

            showAddClientModal: false,
            isAddClientModalOpen: false,
            showCreateClientModal: false,
            team_name: "",
            // clients: [{
            //   name: 'Sun Technologies',
            // },
            // { name: 'Tracaq Solution' },
            // { name: 'Sun Pictures' },
            // { name: 'Plip Plip Stores' },
            // { name: 'Lolu Sabha Enterprise' }

            // ],
            // clients: [],

            clientData: [],
            teams: [],

            newClientName: "",

            newClientDescription: "",

            isCreateNewClientModalOpen: false,
            isCreateNewClientModalOpenNew: false,
            successMessage: "",
            notificationMessage: "",
            projects: "",

            clientName: "",
            clientDescription: "",

            hoursBooked: "",
            maxRollOverHours: "",
            overBilling: null,
            selectedTeamName: "",
            team_uuid: "",
            selectedTeams: [],

            maxOverBillRate: "",

            name: "",
            description: "",
            hours_booked: "",
            roll_over: "",
            over_billing: false,
            over_billing_rate: "",
            teams: "",
            showOverTimeBilling: false,
            usersRemove: [],
        };
    }

    onRoleChange = (type) => {
        switch (type) {
            case "Regular":
                this.setState({
                    userRole: {
                        is_Admin: false,
                        is_Manager: false,
                        is_Employee: true,
                        is_Observer: false,
                    },
                });
                break;

            case "Admin":
                this.setState({
                    userRole: {
                        is_Admin: true,
                        is_Manager: false,
                        is_Employee: false,
                        is_Observer: false,
                    },
                });
                break;

            case "Manager":
                this.setState({
                    userRole: {
                        is_Admin: false,
                        is_Manager: true,
                        is_Employee: false,
                        is_Observer: false,
                    },
                });
                break;

            case "Observer":
                this.setState({
                    userRole: {
                        is_Admin: false,
                        is_Manager: false,
                        is_Employee: false,
                        is_Observer: true,
                    },
                });
                break;
            default:
                break;
        }
    };

    filterStatus = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    sortEmployeStatus = () => {
        this.setState({
            isSortOpenEmploye: !this.state.isSortOpenEmploye,
        });
    };

    sortProjectsStatus = () => {
        this.setState({
            isSortOpenProjects: !this.state.isSortOpenProjects,
        });
    };

    sortEmailStatus = () => {
        this.setState({
            isSortOpenEmail: !this.state.isSortOpenEmail,
        });
    };

    componentDidMount() {
        let domainName = window.location.href;
        let appNameURL = domainName.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0];

        setTimeout(() => {
            this.setState({
                domain_name: appNameURL,
            });
        }, 100);

        const url = window.location.pathname;
        setTimeout(() => {
            this.setState({ urlpath: url });
        }, 200);

        // setTimeout(() => {
        //     if(url == '/team/attendance' || url == '/reports/productivity' || url == '/reports/hour-report'){
        //         this.setState({SelectedTab : "Attendance"})
        //     }
        // }, 200);
        setTimeout(() => {
            const teamParam = domainName.split("/").at(-1).split("&");
            if (this.state.urlpath === "/team/teams") {
                this.setState({ SelectedTab: "teams" });
            } else if (
                teamParam.length === 3 &&
                this.state.urlpath === `/team/teams/${teamParam[0]}&${teamParam[1]}&${teamParam[2]}`
            ) {
                this.props.getUsersfromTeam(teamParam[2]);
                this.setState({
                    SelectedTab: "TeamMembers",
                    SelectedTeamName: teamParam[0],
                    SelectedTeamUUID: teamParam[1],
                });
                window.history.replaceState({}, null, `/team/${this.state.SelectedTab}`);
                return;
            } else if (this.state.urlpath === "/team/users") {
                this.setState({ SelectedTab: "users" });
            } else if (this.state.urlpath === "/team/workstations") {
                this.setState({ SelectedTab: "workstations" });
            } else if (this.state.urlpath === "/team/observers") {
                this.setState({ SelectedTab: "observers" });
            } else if (this.state.urlpath === "/team/manager") {
                this.setState({ SelectedTab: "manager" });
            } else if (this.state.urlpath === "/team/client") {
                this.setState({ SelectedTab: "client" });
            } else {
                this.setState({ SelectedTab: "teams" });
            }
            if (this.state.urlpath.includes("/team/users")) {
                const query = new URLSearchParams(this.props.location.search);
                const userId = query.get("user_id");
                this.setState({ SelectedTab: "users" }, () => {
                    this.props.fetchactiveuserslist().then((res) => {
                        if (res.value.data) {
                            for (let el of res.value.data) {
                                if (el.user_id === userId) {
                                    this.setState({ selectedUser: el });
                                    break;
                                }
                            }
                        }
                    });
                });
            } else {
                window.history.pushState({}, null, `/team/${this.state.SelectedTab}`);
            }
        }, 300);
        const email = localStorage.getItem("user_email");
        const org_uuid = localStorage.getItem("org_uuid");

        // const storedClientData = localStorage.getItem('clientData')
        // if (storedClientData) {
        //   const clientData = JSON.parse(storedClientData);
        //   this.props.createClient(clientData)
        // }

        // const storedFormData=localStorage.getItem('formData')
        // if(storedFormData){
        //   const formData=JSON.parse(storedFormData)
        // }

        this.props.fetchactiveuserslist();
        this.props.fetchInactiveuserslist();

        this.props.fetchAllWorkstatons(email);

        const months = new Date().getMonth();
        const year = new Date().getFullYear();

        const status = this.state.over_billing;
        //     console.log(year,status,months)
        // this.props.fetchClient(months, year, "True");
        this.props.fetchClient(months, year, "True").then((res) => {
            if (res.value.status === 200) {
                this.setState({ clients: res.value.data });
            } else {
                console.error("Failed to fetch clients data");
            }
        });
        this.props.fetchInactiveClient(months, year, "False").then((res) => {
            if (res.value.status === 200) {
                this.setState({ inactiveclients: res.value.data });
            } else {
                console.error("Failed to fetch clients data");
            }
        });

        this.props.FetchactiveTeams(org_uuid);
        this.props.FetchInactiveTeams(org_uuid);
        this.props.getObserverList().then((res) => {
            this.setState({ ObserverList: res.value.data.users });
        });
        this.props.getManagerList().then((res) => {
            this.setState({ ManagerList: res.value.data.users });
        });
    }

    createTeam = () => {
        this.setState({
            teamcreationModal: true,
        });
    };

    openAddClientModal = () => {
        this.setState({
            showAddClientModal: true,
        });
    };

    closeAddClientModal = () => {
        this.setState({
            showAddClientModal: false,
            selectedClient: null,
        });
    };

    handleClientSelectChange = (e) => {
        this.setState({ selectedClient: e.target.value });
    };

    openCreateClientModal = () => {
        this.setState({
            showCreateClientModal: true,
        });
    };

    openCreateClientModalNew = () => {
        this.setState({
            isCreateNewClientModalOpenNew: true,
        });
    };

    closeCreateClientModal = () => {
        this.setState({
            showCreateClientModal: false,
            newClientName: "",
            newClientDescription: "",
            selectedTeams: [],
        });
    };

    handleCreateNewClient = () => {
        const { newClientName, newClientDescription, selectedTeams } = this.state;

        this.closeCreateClientModal();
    };

    handleAddClientToTeam = (selectedClient, selectedClientName) => {
        // const { selectedClient, clientName, selectedTeamName } = this.state;
        const { selectedTeamName, team_uuid } = this.state;
        this.props.addclientToTeam(selectedClient, team_uuid);

        console.log("Adding client to team:", selectedClient, selectedTeamName);

        const months = new Date().getMonth();
        const year = new Date().getFullYear();

        const status = this.state.over_billing;
        //     console.log(year,status,months)
        // this.props.fetchClient(months, year, "True");
        this.props.fetchClient(months, year, "True").then((res) => {
            if (res.value.status === 200) {
                this.setState({ clients: res.value.data });
            } else {
                console.error("Failed to fetch clients data");
            }
        });

        // const message = `"${selectedTeamName}" is now connected with "${selectedClient}"`;
        const message = `"${selectedTeamName}" is now connected with ${selectedClientName}`;
        const newClient = {
            clientName: selectedClientName,
            selectedTeam: selectedTeamName,
        };

        this.setState((prevState) => ({
            clientData: [...prevState.clientData, newClient],
        }));

        this.setState(
            (prevState) => ({
                successMessage: message,
                isAddClientModalOpen: false,
                selectedClientName: "",
                selectedClient: "",
            }),
            () => {
                setTimeout(() => {
                    this.setState({ successMessage: "" });
                }, 4000);
            }
        );
    };

    // handleCreateClientToTeam = (formData) => {

    //     // const { clientName, selectedTeamName } = this.state;
    //     const { selectedTeamName } = this.state;
    //     const { clientName, selectedTeam,hoursBooked,maxRollOverHours,maxOverBillRate,rate } = formData;

    //     console.log('Adding client to team:', clientName,selectedTeam);

    //     const selectedTeamNames = selectedTeam.map((teamInfo) => teamInfo.name);
    //     console.log(selectedTeamNames,'hii')

    //     const selectedRateName = rate ? rate.label : null;

    //     const newClient= {
    //       clientName,
    //       selectedTeamNames,
    //       hoursBooked,
    //       maxRollOverHours,
    //       maxOverBillRate,
    //       selectedRateName
    //     }
    //     this.setState(prevState=>({
    //       clientData:[...prevState.clientData,newClient]
    //     }))

    //     console.log(newClient,'bro')

    //     this.props.CreateClient(
    //         formData
    //       );
    //     // localStorage.setItem('form-data', JSON.stringify(formData));
    //     console.log('form-data',formData)

    //     const message = `"${selectedTeamName}" is now connected with "${clientName}"`;

    //     this.setState({ successMessage: message });

    //     this.setState({ isAddClientModalOpen: false });

    //     setTimeout(() => {
    //       this.setState({ successMessage: '' });
    //     }, 4000);

    //     this.setState({ isCreateNewClientModalOpen: false });
    //   }

    handleCreateClientToTeam = (formData) => {
        // const { clientName, selectedTeamName } = this.state;
        const { selectedTeamName } = this.state;
        const {
            name,
            description,
            teams,
            hours_booked,
            roll_over,
            over_billing_rate,
            over_billing,
            selectedRate,
        } = formData;

        const teamNamesWithCommas = teams.map((teamInfo) => teamInfo.name + ", ");

        // console.log('Adding client to team:', name, teams);
        const selectedTeamIDs = teams.map((teamInfo) => teamInfo.id + ",");

        let teamNamesString = teamNamesWithCommas.join("");
        teamNamesString = teamNamesString.replace(/,\s*$/, "");

        let teamIdsString = selectedTeamIDs.join("");
        teamIdsString = teamIdsString.replace(/,\s*$/, "");

        if (teamNamesString.length > 0) {
            teamNamesString += ",";
        }

        if (teamIdsString.length > 0) {
            teamIdsString += ",";
        }
        console.log(teamNamesString, "hii");
        console.log(teamIdsString, "hey");

        formData.over_billing = over_billing;
        const newClient = {
            name,
            description,
            selectedTeamNames: teamNamesString,
            hours_booked,
            roll_over,
            over_billing_rate,
            over_billing: true,
            // selectedRateName
            selectedRateName: selectedRate ? selectedRate.label : null,
        };
        this.setState((prevState) => ({
            clientData: [...prevState.clientData, newClient],
        }));

        const formDatafinal = {
            ...formData,
            teams: teamIdsString,
        };

        this.props.CreateClient(formDatafinal);
        const months = new Date().getMonth();
        const year = new Date().getFullYear();

        const status = this.state.over_billing;
        this.props.fetchClient(months, year, "True").then((res) => {
            if (res.value.status === 200) {
                this.setState({ clients: res.value.data });
            } else {
                console.error("Failed to fetch clients data");
            }
        });
        this.props.fetchInactiveClient(months, year, "False").then((res) => {
            if (res.value.status === 200) {
                this.setState({ inactiveclients: res.value.data });
            } else {
                console.error("Failed to fetch clients data");
            }
        });
        // const months = new Date().getMonth() + 1;
        // const year = new Date().getFullYear();

        // this.props.fetchClient(months, year, "True");
        // // localStorage.setItem('form-data', JSON.stringify(formData));

        const message = `"${teamNamesString}" is now connected with "${name}"`;
        // const message = "Client Created";

        this.setState({ successMessage: message });

        this.setState({ isAddClientModalOpen: false });

        setTimeout(() => {
            this.setState({ successMessage: "" });
        }, 4000);

        this.setState({ isCreateNewClientModalOpen: false });
    };

    handleOpenAddClientModal = (teamName) => {
        this.setState({
            isAddClientModalOpen: true,
            SelectedTeamName: teamName,
        });
    };

    openCreateClientModal = () => {
        // Close the addclientmodal and open the createclientmodal
        this.setState({
            isCreateNewClientModalOpen: true,
            isAddClientModalOpen: false, // Close the addclientmodal
        });
    };

    handleOfficeBuildingIconClick(el) {
        this.setState({
            isAddClientModalOpen: true,
            selectedTeamName: el.team.name,
        });
    }

    handleClientNameChange = (e) => {
        this.setState({ name: e.target.value });
    };

    handleClientDescriptionChange = (e) => {
        this.setState({ description: e.target.value });
    };

    handleClientSelectChange = (event) => {
        // this.setState({ selectedClient: event.target.value });
        const selectedClientId = event.target.value;
        const selectedClientName = event.target.options[event.target.selectedIndex].text;

        this.setState({
            selectedClient: selectedClientId,
            selectedClientName: selectedClientName,
        });
    };

    handleHoursBookedChange = (e) => {
        this.setState({ hours_booked: e.target.value });
    };

    handleMaxRollOverHoursChange = (e) => {
        this.setState({ roll_over: e.target.value });
    };

    handleMaxBilRateChange = (e) => {
        this.setState({ over_billing_rate: e.target.value });
    };

    handleEnabledRollingBillingRate = () => {
        this.setState((prevState) => ({
            over_billing: !prevState.over_billing,
        }));
    };

    // setShowOverTimeBilling(!showOverTimeBilling);
    //     // Toggle the value of over_billing
    //     onEnabledRollingTimeChange(!over_billing);

    onModalClose = () => {
        this.setState({
            teamcreationModal: false,
            clientcreationModal: false,
            workstationcreationModal: false,
            enableDeleteTeamConfirmationModal: false,
            enableTeamReactivateConfirmationModal: false,
            enableDeleteUserConfirmationModal: false,
            enableUserReactivateConfirmationModal: false,
            enableDeleteWorkstationConfirmationModal: false,
            enableDeleteClientConfirmationModal: false,
            workstationUpdateModal: false,
            teamupdationModal: false,
            enableDeleteTeamMemberConfirmationModal: false,
        });
    };

    onCreateTeamChange = (e) => {
        this.setState({ teamName: e.target.value });
    };

    handleWorkstationNameChange = (e) => {
        this.setState({ workstationname: e.target.value });
    };

    handleWorkstationEmailChange = () => {
        this.setState({ workstationemail: "helo@gmail.com" });
    };

    handleWorkstationDescriptionChange = (e) => {
        this.setState({ workstationdescription: e.target.value });
    };

    handlecreateWorkstation = () => {
        this.setState({ workstationcreationModal: true });
    };

    handleupdateWorkstation = (name, email, description, id) => {
        if (userRole === "Observer") {
            return;
        }
        this.setState({
            workstationUpdateModal: true,
            workstationname: name,
            workstationemail: email,
            workstationdescription: description,
            workstationId: id,
        });
    };

    onModalWorkstationCreateSubmit = () => {
        //console.log("inside");
        if (
            this.state.workstationdescription === "" ||
            //this.state.workstationemail === "" ||
            this.state.workstationname === ""
        ) {
        } else {
            let data = {
                email: this.state.workstationemail,
                workstation: this.state.workstationname,
                description: this.state.workstationdescription,
            };

            this.props.createWorkstation(data).then((res) => {
                if (res.value.status === 200) {
                    console.log("api");
                    let joinworkahubWorkstation = {
                        org_name: localStorage.getItem("org_name"),
                        username: res.value.data.username,
                        uu_id: res.value.data.userId,
                    };
                    this.props.createWorkstationWorkahub(joinworkahubWorkstation).then((res) => {
                        if (res.value.status === 200) {
                            toast.success("Workstation Created Successfully");
                            const email = localStorage.getItem("user_email");
                            this.props.fetchAllWorkstatons(email);
                        } else {
                            toast.error("Workstation Creation Failed");
                        }
                    });
                } else {
                    toast.error("Workstation Creation Failed");
                }
            });
            this.setState({
                workstationcreationModal: false,
                workstationdescription: "",
                workstationemail: "",
                workstationname: "",
            });
        }
    };

    //   if (
    //     this.state.clientDescription === "" ||
    //     this.state.clientName === ""

    //   ) {
    //   } else {
    //     let data = {
    //       // email: this.state.workstationemail,
    //       // workstation: this.state.workstationname,
    //       // description: this.state.workstationdescription,
    //       name:this.state.clientName,
    //       description:this.state.clientDescription,
    //       teams: this.state.selectedTeamName,
    //       hourse_booked:this.state.hoursBooked,
    //       over_billing_rate:this.state.maxOverBillRate,
    //       roll_over:this.state.maxRollOverHours,
    //       over_billing:this.state.overBilling

    //     };

    //     this.props.createClient(data).then((res) => {
    //       if (res.value.status === 200) {
    //         let joinworkahubClient = {
    //           org_name: localStorage.getItem("org_name"),
    //           username: res.value.data.username,
    //           uu_id: res.value.data.userId,
    //         };
    //         this.props
    //           .createClientWorkahub(joinworkahubClient)
    //           .then((res) => {
    //             if (res.value.status === 200) {
    //               toast.success("Client Created Successfully");
    //               const email = localStorage.getItem("user_email");
    //               this.props.fetchAllClients(email);
    //             } else {
    //               toast.error("Client Creation Failed");
    //             }
    //           });
    //       } else {
    //         toast.error("Client Creation Failed");
    //       }
    //     });
    //     this.setState({
    //       clientcreationModal: false,
    //       clientDescription: "",

    //       clientName: "",
    //     });

    //   }
    // };

    onModalWorkstationUpdateSubmit = () => {
        if (
            this.state.workstationdescription === "" ||
            this.state.workstationemail === "" ||
            this.state.workstationname === ""
        ) {
        } else {
            let data = {
                name: this.state.workstationname,
                id: this.state.workstationId,
                description: this.state.workstationdescription,
            };
            this.props.updateWorkstation(data).then(() => {
                toast.success("Workstation Updated Successfully");
                const email = localStorage.getItem("user_email");
                this.props.fetchAllWorkstatons(email);
            });
            this.setState({
                workstationUpdateModal: false,
                workstationdescription: "",
                workstationemail: "",
                workstationname: "",
            });
        }
    };

    onModalCreateSubmit = () => {
        if (this.state.teamName === "") {
        } else {
            let data = {
                name: this.state.teamName,
                org_uuid: localStorage.getItem("org_uuid"),
            };
            this.props.CreateTeamPadlock(data).then(async (res) => {
                const org_uuid = localStorage.getItem("org_uuid");
                if (res.value.status === 200) {
                    data["uu_id"] = res.value.data.uu_id;
                    await this.props.CreateTeam(data);
                    await this.props.FetchactiveTeams(org_uuid);
                    toast.success("Team Created successfully");
                }
            });
            this.setState({
                teamcreationModal: false,
            });
        }
    };

    onModalTeamUpdateSubmit = async () => {
        if (this.state.teamName === "") {
        } else {
            let updated = true;
            let users = this.state.listofselectedUsers.map((el) => el.value).join(",");
            let usersremove = this.state.usersRemove.map((el) => el.value).join(",");
            let data = {
                users: users,
                name: this.state.teamName,
                team_uuid: this.state.SelectedTeamUUID,
                org_uuid: localStorage.getItem("org_uuid"),
            };
            const org_uuid = localStorage.getItem("org_uuid");
            if (this.state.listofselectedUsers.length > 0) {
                const res = await this.props.addmembertoTeaminPadlock(data);
                if (res.value.status === 200) {
                    let listusers =
                        this.state.listofselectedUsers &&
                        this.state.listofselectedUsers.map((el) => el.value);
                    listusers.map((el) => {
                        let data1 = {
                            uu_id: this.state.SelectedTeamUUID,
                            user_uuid: el,
                            team_name: this.state.teamName,
                        };
                        this.props.addmembertoTeam(data1);
                        return null;
                    });
                } else {
                    updated = false;
                }
            }
            if (this.state.usersRemove.length > 0) {
                const res = await this.props.DeleteTeammembersPadlock(
                    this.state.SelectedTeamUUID,
                    usersremove
                );
                if (res.value.status === 200) {
                    await this.props.DeleteTeammembers(this.state.SelectedTeamUUID, usersremove);
                } else {
                    updated = false;
                }
            }
            this.props.FetchactiveTeams(org_uuid);
            if (updated) {
                toast.success("Team Updated Successfully");
                this.setState({
                    listofselectedUsers: [],
                    usersRemove: [],
                });
            } else {
                toast.error("Something went wrong");
            }

            this.setState({
                teamupdationModal: false,
            });
        }
    };

    onHandleReactivateTeamClick = () => {
        this.props.ReactivateTeam(this.state.orguuid, this.state.teamuuid).then(() => {
            toast.success("Team Reactivated Successfully");
            const org_uuid = localStorage.getItem("org_uuid");
            this.props.FetchactiveTeams(org_uuid);
            this.props.FetchInactiveTeams(org_uuid);
        });
        this.setState({
            TeamsSubSelectedTab: "Active",
            enableTeamReactivateConfirmationModal: false,
        });
    };

    HandleReactivateTeam = (org_uuid, team_uuid) => {
        if (userRole === "Observer") {
            return;
        }
        this.setState({
            enableTeamReactivateConfirmationModal: true,
            orguuid: org_uuid,
            teamuuid: team_uuid,
        });
    };

    onHandleDeleteTeamClick = () => {
        this.props.DeleteteamfromPadlock(this.state.orguuid, this.state.teamuuid).then((res) => {
            this.props.Deleteteam(this.state.orguuid, this.state.teamuuid);
            toast.success("Team Deleted Successfully");
            const org_uuid = localStorage.getItem("org_uuid");
            this.props.FetchactiveTeams(org_uuid);
            this.props.FetchInactiveTeams(org_uuid);
        });
        this.setState({
            enableDeleteTeamConfirmationModal: false,
        });
    };

    HandleSelectTab = (e) => {
        this.setState({
            SelectedTab: e,
            TeamsSubSelectedTab: "Active",
            UsersSubSelectedTab: "Active",
            ClientSubSelectedTab: "Active", //added
            selectedUser: "",
        });
        window.history.pushState({}, null, `/team/${e}`);
    };

    HandleMobileSelectTab = (e) => {
        this.setState({ SelectedTab: e.target.value, selectedUser: "" });
        window.history.pushState({}, null, `/team/${e.target.value}`);
    };

    HandleTeamsSubSelectTab = (e) => {
        this.setState({ TeamsSubSelectedTab: e });
    };

    HandleUsersSubSelectTab = (e) => {
        this.setState({ UsersSubSelectedTab: e });
    };

    HandleMobileTeamsSubSelectTab = (e) => {
        this.setState({ TeamsSubSelectedTab: e.target.value });
    };

    //added
    HandleClientsSubSelectTab = (e) => {
        this.setState({ ClientsSubSelectedTab: e });
    };

    //added
    HandleMobileClientsSubSelectTab = (e) => {
        this.setState({ ClientsSubSelectedTab: e.target.value });
    };

    HandleMobileUsersSubSelectTab = (e) => {
        this.setState({ UsersSubSelectedTab: e.target.value });
    };

    handleActiveTeamsPageChange = (e, val) => {
        this.setState({
            activeteamspage: val,
        });
    };

    handleInactiveTeamsPageChange = (e, val) => {
        this.setState({ inactiveteamspage: val });
    };

    handleActiveUsersPageChange = (e, val) => {
        this.setState({ activeuserspage: val });
    };

    handleInactiveusersPageChange = (e, val) => {
        this.setState({
            inactiveuserspage: val,
        });
    };

    onHandleDeleteTeam = (orguuid, teamuuid) => {
        if (userRole === "Observer") {
            return;
        }
        this.setState({
            enableDeleteTeamConfirmationModal: true,
            orguuid: orguuid,
            teamuuid: teamuuid,
        });
    };

    handleDeleteUser = (useremail, userloginas, uu_id) => {
        if (userRole === "Observer") {
            return;
        }
        this.setState({
            useremail: useremail,
            enableDeleteUserConfirmationModal: true,
            userloginas: userloginas,
            user_uuid: uu_id,
        });
    };

    onHandleDeleteUserClick = () => {
        if (userRole === "Observer") {
            return;
        }
        const orgemail = localStorage.getItem("user_email");
        this.props.DeleteUserfromPadlock(this.state.useremail, orgemail);
        this.props.DeleteUser(this.state.user_uuid).then(() => {
            toast.success("User Deleted Successfully");
            this.props.fetchactiveuserslist();
            this.props.fetchInactiveuserslist();
        });
        this.setState({ enableDeleteUserConfirmationModal: false });
    };

    handleReactivateUser = (useremail) => {
        this.setState({
            useremail: useremail,
            enableUserReactivateConfirmationModal: true,
        });
    };

    onHandleReactivateuserClick = () => {
        const orgemail = localStorage.getItem("user_email");
        this.props.ReactivateUserFromPadlock(this.state.useremail, orgemail);
        this.props.ReactivateUser(this.state.useremail).then(() => {
            toast.success("User Reactivated Successfully");
            this.props.fetchactiveuserslist();
            this.props.fetchInactiveuserslist();
        });
        this.setState({ enableUserReactivateConfirmationModal: false });
    };

    handleDeleteWorkstation = (id) => {
        if (userRole === "Observer") {
            return;
        }
        this.setState({
            workstationId: id,
            enableDeleteWorkstationConfirmationModal: true,
        });
    };

    handleDeleteClient = (id, status) => {
        if (userRole === "Observer") {
            return;
        }
        this.setState({
            clientId: id,
            status: status,
            enableDeleteClientConfirmationModal: true,
        });
    };

    onHandleDeleteWorkstationClick = () => {
        this.props.DeleteWorkstation(this.state.workstationId).then(() => {
            toast.success("Workstation Deleted Successfully");
            const email = localStorage.getItem("user_email");
            this.props.fetchAllWorkstatons(email);
        });
        this.setState({ enableDeleteWorkstationConfirmationModal: false });
    };

    onHandleDeleteClientClick = () => {
        this.props.deleteClient(this.state.clientId, this.state.status).then(() => {
            toast.success("Client Deleted Successfully");
            const months = new Date().getMonth();
            const year = new Date().getFullYear();
            this.props.fetchClient(months, year, "True");
            // this.props.fetchInactiveClient(months,year,"False")

            this.props.fetchInactiveClient(months, year, "False").then((res) => {
                if (res.value.status === 200) {
                    this.setState({ inactiveclients: res.value.data });
                } else {
                    console.error("Failed to fetch clients data");
                }
            });

            this.setState({
                // enableClientConfirmationModal: false,
                enableDeleteClientConfirmationModal: false,
            });
        });
        // this.setState({ enableClientConfirmationModal: false });
        this.setState({ enableDeleteClientConfirmationModal: false });
    };

    paginate = (array, page_size, page_number) => {
        return !array ? [] : array.slice((page_number - 1) * page_size, page_number * page_size);
    };

    GetmembersFromTeam = (el) => {
        if (userRole === "Observer") {
            return;
        }
        this.props.getUsersfromTeam(el.team.id);
        this.setState({
            SelectedTab: "TeamMembers",
            SelectedTeamName: el.team.name,
            SelectedTeamUUID: el.team.team_uuid,
        });
    };

    onHandleEditTeam = (el) => {
        if (userRole === "Observer") {
            return;
        }
        this.props.getUsersfromTeam(el.team.id);
        this.setState({
            teamName: el.team.name,
            SelectedTeamUUID: el.team.team_uuid,
            teamupdationModal: true,
        });
    };

    orderOptions = (values) => {
        return values.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed));
    };

    OneditTeamSelectChange = (values) => {
        this.setState({ listofselectedUsers: values }, () => {});
    };
    OneditTeamSelectChangeRemove = (values) => {
        this.setState({ usersRemove: values }, () => {});
    };

    onDeleteTeamMemeber = (useruuid) => {
        if (userRole === "Observer") {
            return;
        }
        this.setState({ enableDeleteTeamMemberConfirmationModal: true, user_uuid: useruuid });
    };
    onHandleDeleteTeamMember = () => {
        // console.log("star");

        if (userRole === "Observer") {
            return;
        }
        let team_uuid = this.state.SelectedTeamUUID;
        // const org_uuid = localStorage.getItem("org_uuid");
        this.props.DeleteTeammember(team_uuid, this.state.user_uuid).then(() => {
            toast.success("User Removed From the Team");
            const org_uuid = localStorage.getItem("org_uuid");
            this.setState({ SelectedTab: "teams" });
            this.props.FetchactiveTeams(org_uuid);
            this.props.FetchInactiveTeams(org_uuid);
        });

        this.setState({ enableDeleteTeamMemberConfirmationModal: false });
    };

    handleTeamNavigation = (teamID, data, teamName) => {
        let teamid = teamName === "default" ? "0a0d01e4-a661-4cd3-92e4-496ed9e801ae" : teamID;
        this.props.history.push({
            // pathname: `/dashboard/?teamName=${teamName}&date=${this.state.selectedDate}`,
            pathname: `/team/?teamName=${teamName}&date=${this.state.selectedDate}`,
            // state: { teamuuid: teamID, data: data, teamName: teamName },
            state: { teamuuid: teamid, data: data, teamName: teamName },
        });
    };

    render() {
        const { t } = this.props;
        // clients = this.props.teams.clients && [...clients].sort((a,b)=>a.name.localeCompare(b.client_name));
        // inactiveclients = this.props.teams && [...inactiveclients].sort((a,b)=>a.name.localeCompare(b.client_name));
        const { clients } = this.props.teams;
        const { inactiveclients } = this.props.teams;

        let clientsLength = clients && clients.data ? clients.data.length : 0;
        let inactiveclientsLength =
            inactiveclients && inactiveclients.data ? inactiveclients.data.length : 0;
        const {
            isOpen,
            isSortOpenEmploye,
            isSortOpenProjects,
            isSortOpenEmail,
            userRole,

            selectedClient,
        } = this.state;

        const { isAddClientModalOpen } = this.state;

        let activeTeams =
            this.props.teams && this.props.teams.ActiveTeam && this.props.teams.ActiveTeam.info;
        activeTeams =
            activeTeams &&
            [...activeTeams]?.sort((a, b) => b.team.created.localeCompare(a.team.created));
        let lengthofActiveTeams = activeTeams && activeTeams.length;
        let inactiveTeams = this.props.teams.InActiveTeam && this.props.teams.InActiveTeam.info;
        inactiveTeams =
            inactiveTeams &&
            [...inactiveTeams]?.sort((a, b) => b.team.created.localeCompare(a.team.created));
        let lengthofinactiveTeams = inactiveTeams && inactiveTeams.length;

        let activeUsers = this.props.teams.ActiveUsers;
        activeUsers =
            activeUsers &&
            [...activeUsers].sort((a, b) => a.user_first_name.localeCompare(b.user_first_name));
        let listofallactiveusers =
            activeUsers &&
            activeUsers.map((el) => {
                return { value: el.user_id, label: el.user_login_as, isFixed: false };
            });
        let inactiveUsers =
            this.props.teams.InActiveUsers && this.props.teams.InActiveUsers.inactive;
        inactiveUsers =
            inactiveUsers && [...inactiveUsers].sort((a, b) => a.name.localeCompare(b.name));
        let lengthofActiveUsers =
            this.props.teams && this.props.teams.ActiveUsers && this.props.teams.ActiveUsers.length;
        let lengthofinactiveUsers =
            this.props.teams.InActiveUsers &&
            this.props.teams.InActiveUsers.inactive &&
            this.props.teams.InActiveUsers.inactive.length;

        let workstations = this.props.teams.Workstations && this.props.teams.Workstations.dict;
        workstations =
            workstations && [...workstations].sort((a, b) => a.name.localeCompare(b.name));
        let membersoftheteam =
            this.props.teams.UsersFromTeam && this.props.teams.UsersFromTeam.info;
        let listofselectedUsers =
            membersoftheteam &&
            membersoftheteam.map((el) => {
                return { value: el.user_uuid, label: el.username, isFixed: true };
            });

        let teams =
            this.props.teams && this.props.teams.ActiveTeam && this.props.teams.ActiveTeam.info;
        let TeamData =
            teams &&
            teams.map((el) => {
                return { value: el.team.team_uuid, label: el.team.name, isFixed: true };
            });

        // let teamsData = this.props.teams && this.props.ActiveTeam && this.props.ActiveTeam.info;
        // console.log("datateam", TeamData)
        // console.log("teamssssss",teams)
        let teamsData = this.props.teams && this.props.ActiveTeam && this.props.ActiveTeam.info;

        const handleEmployeeProfileNav = (data) => {
            localStorage.setItem("data", JSON.stringify(data));
        };

        const handleTeamSelectedObserver = (e) => {
            const teamname = e.map((el) => {
                return el.label;
            });
            const teamUuid = e.map((el) => {
                return el.value;
            });
            this.setState({
                listofSelectedTeamobserver: teamname,
                listOfteamsobserUUID: teamUuid,
            });
        };

        const handleAddObserver = async () => {
            let emailtest = validEmailRegex.test(this.state.observerEmail);
            if (emailtest) {
                if (this.state.listOfteamsobserUUID.length > 0) {
                    this.setState({ addObserver: false, saveObserver: true });
                    this.state.listofObserData.push({
                        email: this.state.observerEmail,
                        role: this.state.observerRole,
                        teams: this.state.listofSelectedTeamobserver,
                    });
                } else {
                    toast.error("Please select atleast one team");
                }
            } else {
                toast.error("Please enter a valid email address");
            }
        };

        const submitObserverInvite = () => {
            fetch("https://api.trypadlock.com/observer/invite", {
                method: "POST",
                headers: {
                    authorization: "JWT " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    useremail: this.state.observerEmail,
                    type: "observer",
                    role: "observer",
                    teams: this.state.listOfteamsobserUUID.toString(),
                    domain_name: this.state.domain_name,
                }),
            }).then((res) => {
                if (res.status === 200) {
                    toast.success("Observer Invited Successfully");

                    // const newObs={
                    //   roles:"observer",
                    //   user:{
                    //     username:"",
                    //     email:"",
                    //     user_uuid:""
                    //   }
                    // }

                    // this.setState((prev)=>({
                    //   ObserverList:[...prev.ObserverList,newObs]
                    // }))

                    // await this.props.getObserverList();
                    this.setState({
                        observerEmail: "",
                        observerRole: "",
                        listOfteamsobserUUID: [],
                        listofObserData: [],
                        addObserver: true,
                        saveObserver: false,
                        showAddObserverModal: false,
                    });
                } else if (res.status === 401) {
                    let refresh_token = localStorage.getItem("refresh_token");
                    fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            refresh: refresh_token,
                        }),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            localStorage.setItem("access_token", data.access);
                            localStorage.setItem("refresh_token", data.refresh);
                            submitObserverInvite();
                        });
                } else {
                    toast.error("Observer Invitation Failed");
                }
            });
        };

        const handleTeamSelectedManager = (e) => {
            const teamname = e.map((el) => {
                return el.label;
            });
            const teamUuid = e.map((el) => {
                return el.value;
            });
            this.setState({
                listofSelectedTeamManager: teamname,
                listOfteamsmngrUUID: teamUuid,
            });
        };

        const onClickDescending8 = () => {
            if (TeamsSubSelectedTab == "Active") {
                const sortedData = [...activeTeams]?.sort((a, b) =>
                    b.team.name.localeCompare(a.team.name)
                );
                this.setState({
                    sortedTeams: sortedData,
                    isSort: true,
                    sortingOrder: "descendingteamname",
                });
            } else {
                const sortedData = [...inactiveTeams]?.sort((a, b) =>
                    b.team.name.localeCompare(a.team.name)
                );
                this.setState({
                    sortedinactiveTeams: sortedData,
                    isSort1: true,
                    sortingOrder: "descendingteamname",
                });
            }
        };
        const onClickAssending8 = () => {
            if (TeamsSubSelectedTab == "Active") {
                const sortedData = [...activeTeams]?.sort((a, b) =>
                    a.team.name.localeCompare(b.team.name)
                );
                this.setState({
                    sortedTeams: sortedData,
                    isSort: true,
                    sortingOrder: "ascendingteamname",
                });
            } else {
                const sortedData = [...inactiveTeams]?.sort((a, b) =>
                    a.team.name.localeCompare(b.team.name)
                );
                this.setState({
                    sortedinactiveTeams: sortedData,
                    isSort1: true,
                    sortingOrder: "ascendingteamname",
                });
            }
        };

        const onClickDescending = () => {
            if (TeamsSubSelectedTab == "Active") {
                const sortedData = [...activeTeams]?.sort((a, b) =>
                    b.team.created.localeCompare(a.team.created)
                );
                this.setState({
                    sortedTeams: sortedData,
                    isSort: true,
                    sortingOrder: "descendingcreated",
                });
            } else {
                const sortedData = [...inactiveTeams]?.sort((a, b) =>
                    b.team.created.localeCompare(a.team.created)
                );
                this.setState({
                    sortedinactiveTeams: sortedData,
                    isSort1: true,
                    sortingOrder: "descendingcreated",
                });
            }
        };

        const onClickAssending = () => {
            if (TeamsSubSelectedTab == "Active") {
                const sortedData = [...activeTeams]?.sort((a, b) =>
                    a.team.created.localeCompare(b.team.created)
                );
                this.setState({
                    sortedTeams: sortedData,
                    isSort: true,
                    sortingOrder: "ascendingcreated",
                });
            } else {
                const sortedData = [...inactiveTeams]?.sort((a, b) =>
                    a.team.created.localeCompare(b.team.created)
                );
                this.setState({
                    sortedinactiveTeams: sortedData,
                    isSort1: true,
                    sortingOrder: "ascendingcreated",
                });
            }
        };

        const onClickDescending0 = () => {
            if (TeamsSubSelectedTab == "Active") {
                const sortedData = [...activeTeams]?.sort((a, b) => b.count - a.count);
                this.setState({
                    sortedTeams: sortedData,
                    isSort: true,
                    sortingOrder: "descendingcount",
                });
            } else {
                const sortedData = [...inactiveTeams]?.sort((a, b) => b.count - a.count);
                this.setState({
                    sortedinactiveTeams: sortedData,
                    isSort1: true,
                    sortingOrder: "descendingcount",
                });
            }
        };

        const onClickAssending0 = () => {
            if (TeamsSubSelectedTab == "Active") {
                const sortedData = [...activeTeams]?.sort((a, b) => a.count - b.count);
                this.setState({
                    sortedTeams: sortedData,
                    isSort: true,
                    sortingOrder: "ascendingcount",
                });
            } else {
                const sortedData = [...inactiveTeams]?.sort((a, b) => a.count - b.count);
                this.setState({
                    sortedinactiveTeams: sortedData,
                    isSort1: true,
                    sortingOrder: "ascendingcount",
                });
            }
        };

        const onClickDescending1 = () => {
            const sortedData = [...activeUsers]?.sort((a, b) =>
                b.user_first_name.localeCompare(a.user_first_name)
            );
            this.setState({
                sortedactiveUsers: sortedData,
                isSort2: true,
                sortingOrder: "descendingactiveename",
            });
        };

        const onClickAssending1 = () => {
            const sortedData = [...activeUsers].sort((a, b) =>
                a.user_first_name.localeCompare(b.user_first_name)
            );
            this.setState({
                sortedactiveUsers: sortedData,
                isSort2: true,
                sortingOrder: "ascendingactiveename",
            });
        };

        const onClickDescending2 = () => {
            const sortedData = [...activeUsers]?.sort((a, b) =>
                b.user_email.localeCompare(a.user_email)
            );
            this.setState({
                sortedactiveUsers: sortedData,
                isSort2: true,
            });
        };
        const onClickAssending2 = () => {
            const sortedData = [...activeUsers].sort((a, b) =>
                a.user_email.localeCompare(b.user_email)
            );
            this.setState({
                sortedactiveUsers: sortedData,
                isSort2: true,
            });
        };

        const onClickAssending3 = () => {
            const sortedData = [...activeUsers].sort((a, b) => b.projects_count - a.projects_count);
            this.setState({
                sortedactiveUsers: sortedData,
                isSort2: true,
            });
        };

        const onClickDescending3 = () => {
            const sortedData = [...activeUsers]?.sort(
                (a, b) => b.projects_count - a.projects_count
            );
            this.setState({
                sortedactiveUsers: sortedData,
                isSort2: true,
                sortingOrder: "descendingactiveproject",
            });
        };

        const onClickDescending4 = () => {
            const sortedData = [...inactiveUsers]?.sort((a, b) => b.name.localeCompare(a.name));
            this.setState({
                sortedinactiveUsers: sortedData,
                isSort3: true,
                sortingOrder: "descendinginactiveename",
            });
        };
        const onClickAssending4 = () => {
            const sortedData = [...inactiveUsers].sort((a, b) => a.name.localeCompare(b.name));
            this.setState({
                sortedinactiveUsers: sortedData,
                isSort3: true,
                sortingOrder: "ascendinginactiveename",
            });
        };

        const onClickDescending5 = () => {
            const sortedData = [...inactiveUsers]?.sort((a, b) => b.email.localeCompare(a.email));
            this.setState({
                sortedinactiveUsers: sortedData,
                isSort3: true,
                sortingOrder: "descendinginactiveemail",
            });
        };
        const onClickAssending5 = () => {
            const sortedData = [...inactiveUsers].sort((a, b) => a.email.localeCompare(b.email));
            this.setState({
                sortedinactiveUsers: sortedData,
                isSort3: true,
                sortingOrder: "ascendinginactiveemail",
            });
        };

        const onClickDescending6 = () => {
            const sortedData = [...workstations]?.sort((a, b) => b.name.localeCompare(a.name));
            this.setState({
                sortedworkstation: sortedData,
                isSort4: true,
                sortingOrder: "descendingworkname",
            });
        };
        const onClickAssending6 = () => {
            const sortedData = [...workstations].sort((a, b) => a.name.localeCompare(b.name));
            this.setState({
                sortedworkstation: sortedData,
                isSort4: true,
                sortingOrder: "ascendingworkname",
            });
        };

        const onClickDescending7 = () => {
            const sortedData = [...workstations]?.sort((a, b) =>
                b.workstation.localeCompare(a.workstation)
            );
            this.setState({
                sortedworkstation: sortedData,
                isSort4: true,
                sortingOrder: "descendingworkstation",
            });
        };
        const onClickAssending7 = () => {
            const sortedData = [...workstations].sort((a, b) =>
                a.workstation.localeCompare(b.workstation)
            );
            this.setState({
                sortedworkstation: sortedData,
                isSort4: true,
                sortingOrder: "ascendingworkstation",
            });
        };

        const handleAddManager = () => {
            let emailtest = validEmailRegex.test(this.state.managerEmail);
            if (emailtest) {
                if (this.state.listOfteamsmngrUUID.length > 0) {
                    this.setState({ addManager: false, saveManager: true });
                    this.state.listofManagerData.push({
                        email: this.state.managerEmail,
                        role: this.state.managerRole,
                        teams: this.state.listofSelectedTeamManager,
                    });
                } else {
                    toast.error("Please select atleast one team");
                }
            } else {
                toast.error("Please enter a valid email address");
            }
        };

        const submitManagerInvite = () => {
            fetch("https://api.trypadlock.com/manager/invite", {
                method: "POST",
                headers: {
                    authorization: "JWT " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    useremail: this.state.managerEmail,
                    type: "manager",
                    role: "manager",
                    teams: this.state.listOfteamsmngrUUID.toString(),
                    domain_name: this.state.domain_name,
                }),
            }).then((res) => {
                if (res.status === 200) {
                    toast.success("Manager Invited Successfully");
                    this.setState({
                        showAddManagerModal: false,
                        managerEmail: "",
                        managerRole: "",
                        listOfteamsmngrUUID: [],
                        listofManagerData: [],
                        addManager: true,
                        saveManager: false,
                    });
                } else if (res.status === 401) {
                    let refresh_token = localStorage.getItem("refresh_token");
                    fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            refresh: refresh_token,
                        }),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            localStorage.setItem("access_token", data.access);
                            localStorage.setItem("refresh_token", data.refresh);
                            submitObserverInvite();
                        });
                } else {
                    toast.error("Manager Invitation Failed");
                }
            });
        };

        const deActivateObserver = () => {
            fetch("https://api.trypadlock.com/users/userdeactivate", {
                method: "POST",
                headers: {
                    authorization: "JWT " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    admin: localStorage.getItem("user_email"),
                    email: this.state.deleteObsEmail,
                }),
            }).then((res) => {
                if (res.status === 200) {
                    this.setState({
                        deleteObsEmail: "",
                        deleteObsUuid: "",
                        deleteObserverModal: false,
                    });
                } else if (res.status === 401) {
                    let refresh_token = localStorage.getItem("refresh_token");
                    fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            refresh: refresh_token,
                        }),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            localStorage.setItem("access_token", data.access);
                            localStorage.setItem("refresh_token", data.refresh);
                            deActivateObserver();
                        });
                } else {
                    toast.error("Observer Deletion Failed");
                }
            });

            fetch(localStorage.getItem("apiURL") + "/api/employee_active_inactive/", {
                method: "PUT",
                headers: {
                    authorization: "JWT " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    uuid: this.state.deleteObsUuid,
                }),
            }).then((res) => {
                if (res.status === 200) {
                    toast.success("Observer Deactivated Successfully");
                    this.props.getObserverList().then((res) => {
                        this.setState({ ObserverList: res.value.data.users });
                    });
                    this.setState({
                        deleteObsEmail: "",
                        deleteObsUuid: "",
                        deleteObserverModal: false,
                    });
                } else if (res.status === 401) {
                    let refresh_token = localStorage.getItem("refresh_token");
                    fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            refresh: refresh_token,
                        }),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            localStorage.setItem("access_token", data.access);
                            localStorage.setItem("refresh_token", data.refresh);
                            submitObserverInvite();
                        });
                } else {
                    toast.error("Observer Deletion Failed");
                }
            });
        };
        // const deActivateManager = () => {
        //   fetch("https://api.trypadlock.com/users/userdeactivate", {
        //     method: "POST",
        //     headers: {
        //       authorization: "JWT " + localStorage.getItem("access_token"),
        //       "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({
        //       admin: localStorage.getItem("user_email"),
        //       email: this.state.deleteMngrEmail,
        //     }),
        //   }).then((res) => {
        //     if (res.status === 200) {
        //       this.setState({
        //         deleteMngrEmail: "",
        //         deleteMngrUuid: "",
        //         deleteManagerModal: false,
        //       });
        //     } else if (res.status === 401) {
        //       let refresh_token = localStorage.getItem("refresh_token");
        //       fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
        //         method: "POST",
        //         headers: { "Content-Type": "application/json" },
        //         body: JSON.stringify({
        //           refresh: refresh_token,
        //         }),
        //       })
        //         .then((res) => res.json())
        //         .then((data) => {
        //           localStorage.setItem("access_token", data.access);
        //           localStorage.setItem("refresh_token", data.refresh);
        //           submitObserverInvite();
        //         });
        //     } else {
        //       toast.error("Observer Deletion Failed");
        //     }
        //   });

        //   fetch(localStorage.getItem("apiURL") + "/api/employee_active_inactive/", {
        //     method: "PUT",
        //     headers: {
        //       authorization: "JWT " + localStorage.getItem("access_token"),
        //       "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({
        //       uuid: this.state.deleteObsUuid,
        //     }),
        //   }).then((res) => {
        //     if (res.status === 200) {
        //       toast.success("Observer Deactivated Successfully");
        //       this.setState({
        //         deleteMngrEmail: "",
        //         deleteMngrUuid: "",
        //         deleteManagerModal: false,
        //       });
        //     } else if (res.status === 401) {
        //       let refresh_token = localStorage.getItem("refresh_token");
        //       fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
        //         method: "POST",
        //         headers: { "Content-Type": "application/json" },
        //         body: JSON.stringify({
        //           refresh: refresh_token,
        //         }),
        //       })
        //         .then((res) => res.json())
        //         .then((data) => {
        //           localStorage.setItem("access_token", data.access);
        //           localStorage.setItem("refresh_token", data.refresh);
        //           submitObserverInvite();
        //         });
        //     } else {
        //       toast.error("Observer Deletion Failed");
        //     }
        //   });
        // };
        const deleteManager = () => {
            if (this.state.deleteMngrUuid === "") {
                toast.error("User is already deactivated");
                return;
            }
            fetch("https://api.trypadlock.com/users/userdeactivate", {
                method: "POST",
                headers: {
                    authorization: "JWT " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    admin: localStorage.getItem("user_email"),
                    email: this.state.deleteMngrEmail,
                }),
            }).then((res) => {
                if (res.status === 200) {
                    const updated = this.state.ManagerList.filter(
                        (person) => person.user.user_uuid !== this.state.deleteMngrUuid
                    );
                    this.setState({ ManagerList: updated });
                    // this.props.getManagerList().then((res) => {
                    //   this.setState({ ManagerList: res });
                    // });
                    console.log(this.props.getManagerList());
                    this.setState({
                        deleteMngrEmail: "",
                        deleteMngrUuid: "",
                        deleteManagerModal: false,
                    });
                } else if (res.status === 400) {
                    console.log("User is already deactivated");
                    const updated = this.state.ManagerList.filter(
                        (person) => person.user.user_uuid !== this.state.deleteMngrUuid
                    );
                    this.props.getManagerList().then((res) => {
                        this.setState({ ManagerList: updated });
                    });
                    this.setState({ ManagerList: updated });
                    this.setState({
                        ManagerList: updated,
                        deleteMngrEmail: "",
                        deleteMngrUuid: "",
                        deleteManagerModal: false,
                    });
                } else if (res.status === 401) {
                    let refresh_token = localStorage.getItem("refresh_token");
                    fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            refresh: refresh_token,
                        }),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            localStorage.setItem("access_token", data.access);
                            localStorage.setItem("refresh_token", data.refresh);
                            submitObserverInvite();
                        });
                } else {
                    toast.error("Observer Deletion Failed");
                }
            });
        };
        const deActivateManager = () => {
            fetch("https://api.trypadlock.com/users/userdeactivate", {
                method: "POST",
                headers: {
                    authorization: "JWT " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    admin: localStorage.getItem("user_email"),
                    email: this.state.deleteMngrEmail,
                }),
            }).then((res) => {
                if (res.status === 200) {
                    this.setState({
                        deleteMngrEmail: "",
                        deleteMngrUuid: "",
                        deleteManagerModal: false,
                    });
                } else if (res.status === 401) {
                    let refresh_token = localStorage.getItem("refresh_token");
                    fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            refresh: refresh_token,
                        }),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            localStorage.setItem("access_token", data.access);
                            localStorage.setItem("refresh_token", data.refresh);
                            submitObserverInvite();
                        });
                } else {
                    toast.error("Observer Deletion Failed");
                }
            });

            fetch(localStorage.getItem("apiURL") + "/api/employee_active_inactive/", {
                method: "PUT",
                headers: {
                    authorization: "JWT " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    uuid: this.state.deleteObsUuid,
                }),
            }).then((res) => {
                if (res.status === 200) {
                    toast.success("Observer Deactivated Successfully");
                    this.setState({
                        deleteMngrEmail: "",
                        deleteMngrUuid: "",
                        deleteManagerModal: false,
                    });
                } else if (res.status === 401) {
                    let refresh_token = localStorage.getItem("refresh_token");
                    fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            refresh: refresh_token,
                        }),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            localStorage.setItem("access_token", data.access);
                            localStorage.setItem("refresh_token", data.refresh);
                            submitObserverInvite();
                        });
                } else {
                    toast.error("Observer Deletion Failed");
                }
            });
        };

        const handleEditObserverDetaild = () => {
            fetch(localStorage.getItem("apiURL") + "/api/team_observer/", {
                method: "PUT",
                headers: {
                    authorization: "JWT " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    teamAdd: this.state.listOfEditObsTeamUUID.toString(),
                    teamSub: this.state.listofEditObsTeamtoRemoveUUID.toString(),
                    observer: this.state.selectedObstoEdit.user.user_uuid,
                }),
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 401) {
                        let refresh_token = localStorage.getItem("refresh_token");
                        fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                refresh: refresh_token,
                            }),
                        })
                            .then((res) => res.json())
                            .then((data) => {
                                localStorage.setItem("access_token", data.access);
                                localStorage.setItem("refresh_token", data.refresh);
                                handleEditObserverDetaild();
                            });
                    }
                })
                .then((data) => {
                    console.log("dataEDIT OBSERVER", data);
                });
        };

        const handleTeamSelectedEditObserver = (e) => {
            const teamname = e.map((el) => {
                return el.label;
            });
            const teamUuid = e.map((el) => {
                return el.value;
            });
            this.setState({
                listofEditObsTeamSelected: teamname,
                listOfEditObsTeamUUID: teamUuid,
            });
        };

        const handleTeamSelectedEditObserverTOReomove = (e) => {
            const teamname = e.map((el) => {
                return el.label;
            });
            const teamUuid = e.map((el) => {
                return el.value;
            });
            this.setState({
                listofEditObsTeamtoRemove: teamname,
                listofEditObsTeamtoRemoveUUID: teamUuid,
            });
        };

        const swtichObserverData = () => {
            const org_uuid = localStorage.getItem("org_uuid");
            if (this.state.showObserverData) {
                this.setState({ showObserverData: false });
                if (this.state.showObserverData) {
                    this.setState({ showObserverData: false });
                    this.props.FetchactiveTeams(org_uuid);
                } else {
                    this.setState({ showObserverData: true });
                    this.setState({ showObserverData: true });
                    this.props.activeTeamObserver(org_uuid);
                }
            }
        };
        const handleSearchAct = () => {
            const filteredUsers = activeUsers.filter((val) => {
                const fullName = val.user_first_name + " " + val.user_last_name;
                if (this.state.searchBox.trim() === "") {
                    return val;
                } else if (
                    fullName.toLowerCase().includes(this.state.searchBox.toLowerCase()) ||
                    val.user_email.toLowerCase().includes(this.state.searchBox.toLowerCase())
                ) {
                    return val;
                }
            });
            return filteredUsers.length;
        };
        const handleSearchIn = () => {
            const filteredUsers = inactiveUsers.filter((val) => {
                if (this.state.searchBoxIn.trim() === "") {
                    return val;
                } else if (
                    val.name.toLowerCase().includes(this.state.searchBoxIn.toLowerCase()) ||
                    val.email.toLowerCase().includes(this.state.searchBoxIn.toLowerCase())
                ) {
                    return val;
                }
            });
            return filteredUsers.length;
        };
        const swtichObserverDataUsers = () => {
            if (this.state.showObserverDataUsers) {
                this.setState({ showObserverDataUsers: false });
                if (this.state.showObserverDataUsers) {
                    this.setState({ showObserverDataUsers: false });
                    this.props.fetchactiveuserslist();
                } else {
                    this.setState({ showObserverDataUsers: true });
                    this.setState({ showObserverDataUsers: true });
                    this.props.fetchActiveUsersObserver();
                }
            }
        };
        const {
            TeamsSubSelectedTab,
            SelectedTab,
            UsersSubSelectedTab,
            SelectedTeamName,
            ClientsSubSelectedTab,
        } = this.state;

        const handelRoleChange = (userid, role) => {
            const dataa = new FormData();
            dataa.append("user", userid);
            dataa.append("location");
            dataa.append("designation");
            dataa.append("employee", role == "Employee" ? "True" : "False");
            dataa.append("admin", role == "admin" ? "True" : "False");
            dataa.append("observer", role == "Observer" ? "True" : "False");
            dataa.append("manager", role == "Manager" ? "True" : "False");
            dataa.append("username");
            dataa.append("first_name");
            dataa.append("last_name");
            dataa.append("time_zone");
            dataa.append("overworkhours");
            dataa.append("currency");

            this.props.editUserDetails(dataa).then((res) => {
                if (res.value.status === 200) {
                    toast.success("Basic info updated successfully");
                } else {
                    toast.error("Something went wrong");
                }
            });
        };
        return (
            <Layout>
                <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                    <div className="lg:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            Select a tab
                        </label>
                        <select
                            id="tabs"
                            name="tabs"
                            onChange={(e) => this.HandleMobileSelectTab(e)}
                            className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                            defaultValue={this.state.SelectedTab}>
                            <option key="teams" value="teams">
                                Teams
                            </option>
                            <option key="users" value="users">
                                Users
                            </option>
                            {userRole === "Observer" ? null : (
                                <>
                                    <option key="workstations" value="workstations">
                                        Workstations
                                    </option>
                                    <option key="observers" value="observers">
                                        Observers
                                    </option>
                                </>
                            )}
                            <option key="manager" value="manager">
                                Manager
                            </option>

                            <option key="client" value="client">
                                Client
                            </option>
                        </select>
                    </div>
                    <div className="hidden lg:block">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {userRole === "Observer" ? null : (
                                <div
                                    key="teams"
                                    onClick={() => this.HandleSelectTab("teams")}
                                    className={classNames(
                                        this.state.SelectedTab === "teams" ||
                                            this.state.SelectedTab === "TeamMembers"
                                            ? "border-primary text-primary"
                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                        "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                    )}
                                    aria-current={
                                        this.state.SelectedTab === "teams" ? "page" : undefined
                                    }>
                                    <UserGroupIcon
                                        className={classNames(
                                            this.state.SelectedTab === "teams" ||
                                                this.state.SelectedTab === "TeamMembers"
                                                ? "text-primary"
                                                : "text-gray-400 group-hover:text-gray-500",
                                            "-ml-0.5 mr-2 h-5 w-5"
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span>{t("teams")}</span>
                                </div>
                            )}

                            <div
                                key="users"
                                onClick={() => this.HandleSelectTab("users")}
                                className={classNames(
                                    this.state.SelectedTab === "users"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "users" ? "page" : undefined
                                }>
                                <UsersIcon
                                    className={classNames(
                                        this.state.SelectedTab === "users"
                                            ? "text-primary"
                                            : "text-gray-400 group-hover:text-gray-500",
                                        "-ml-0.5 mr-2 h-5 w-5"
                                    )}
                                    aria-hidden="true"
                                />
                                <span>{t("TeamTab.users")}</span>
                            </div>

                            {userRole === "Observer" ? null : (
                                <>
                                    <div
                                        key="workstations"
                                        onClick={() => this.HandleSelectTab("workstations")}
                                        className={classNames(
                                            this.state.SelectedTab === "workstations"
                                                ? "border-primary text-primary"
                                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                            "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                        )}
                                        aria-current={
                                            this.state.SelectedTab === "workstations"
                                                ? "page"
                                                : undefined
                                        }>
                                        <DesktopComputerIcon
                                            className={classNames(
                                                this.state.SelectedTab === "workstations"
                                                    ? "text-primary"
                                                    : "text-gray-400 group-hover:text-gray-500",
                                                "-ml-0.5 mr-2 h-5 w-5"
                                            )}
                                            aria-hidden="true"
                                        />
                                        <span>{t("TeamTab.workstations")}</span>
                                    </div>
                                    <div
                                        key="observers"
                                        onClick={() => this.HandleSelectTab("observers")}
                                        className={classNames(
                                            this.state.SelectedTab === "observers"
                                                ? "border-primary text-primary"
                                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                            "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                        )}
                                        aria-current={
                                            this.state.SelectedTab === "observers"
                                                ? "page"
                                                : undefined
                                        }>
                                        <DocumentSearchIcon
                                            className={classNames(
                                                this.state.SelectedTab === "observers"
                                                    ? "text-primary"
                                                    : "text-gray-400 group-hover:text-gray-500",
                                                "-ml-0.5 mr-2 h-5 w-5"
                                            )}
                                            aria-hidden="true"
                                        />
                                        <span>{t("TeamTab.observers")}</span>
                                    </div>
                                    <div
                                        key="manager"
                                        onClick={() => this.HandleSelectTab("manager")}
                                        className={classNames(
                                            this.state.SelectedTab === "manager"
                                                ? "border-primary text-primary"
                                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                            "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                        )}
                                        aria-current={
                                            this.state.SelectedTab === "manager"
                                                ? "page"
                                                : undefined
                                        }>
                                        <GrUserManager
                                            className={classNames(
                                                this.state.SelectedTab === "manager"
                                                    ? "text-primary"
                                                    : "text-gray-400 group-hover:text-gray-500 opacity-75",
                                                "-ml-0.5 mr-2 h-5 w-5"
                                            )}
                                            aria-hidden="true"
                                        />
                                        <span>{t("TeamTab.manager")}</span>
                                    </div>
                                </>
                            )}

                            {/*  */}

                            <div
                                key="client"
                                onClick={() => this.HandleSelectTab("client")}
                                className={classNames(
                                    this.state.SelectedTab === "client"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "client" ? "page" : undefined
                                }>
                                <OfficeBuildingIcon
                                    className={classNames(
                                        this.state.SelectedTab === "client"
                                            ? "text-primary"
                                            : "text-gray-400 group-hover:text-gray-500",
                                        "-ml-0.5 mr-2 h-5 w-5"
                                    )}
                                    aria-hidden="true"
                                />
                                <span>{t("client")}</span>
                            </div>

                            {/*  */}
                        </nav>
                    </div>
                </div>

                {SelectedTab === "teams" ? (
                    <div className="bg-white mt-4 px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="-ml-4 -mt-2 flex items-center lg-m:items-stretch lg-m:flex-col justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2">
                                <div className="sm:hidden">
                                    <label htmlFor="tabs" className="sr-only">
                                        Select a tab
                                    </label>
                                    <select
                                        id="tabs"
                                        name="tabs"
                                        onChange={(e) => this.HandleMobileTeamsSubSelectTab(e)}
                                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                                        defaultValue={TeamsSubSelectedTab}>
                                        <option key="Active" value="Active">
                                            {t("active")}
                                        </option>
                                        <option key="InActive" value="InActive">
                                            {t("inactive")}
                                        </option>
                                    </select>
                                </div>
                                <div className="hidden sm:block">
                                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                        <div
                                            key="Active"
                                            onClick={() => this.HandleTeamsSubSelectTab("Active")}
                                            className={classNames(
                                                TeamsSubSelectedTab === "Active"
                                                    ? "border-primary text-primary"
                                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                            )}
                                            aria-current={
                                                TeamsSubSelectedTab === "Active"
                                                    ? "page"
                                                    : undefined
                                            }>
                                            {t("active")}
                                            <span
                                                className={classNames(
                                                    TeamsSubSelectedTab === "Active"
                                                        ? "bg-indigo-100 text-primary"
                                                        : "bg-gray-100 text-gray-900",
                                                    "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                                                )}>
                                                {lengthofActiveTeams}
                                            </span>
                                        </div>

                                        <div
                                            key="InActive"
                                            onClick={() => this.HandleTeamsSubSelectTab("InActive")}
                                            className={classNames(
                                                TeamsSubSelectedTab === "InActive"
                                                    ? "border-primary text-primary"
                                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                            )}
                                            aria-current={
                                                TeamsSubSelectedTab === "InActive"
                                                    ? "page"
                                                    : undefined
                                            }>
                                            {t("inactive")}
                                            <span
                                                className={classNames(
                                                    TeamsSubSelectedTab === "InActive"
                                                        ? "bg-indigo-100 text-primary"
                                                        : "bg-gray-100 text-gray-900",
                                                    "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                                                )}>
                                                {lengthofinactiveTeams}
                                            </span>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="ml-4 mt-2 lg-m:flex lg-m:justify-between flex-shrink-0">
                                {/* <button
                                    onClick={() => {
                                        swtichObserverData();
                                    }}
                                    className="translate-y-[3px] mx-2 border border-gray-300 rounded-[5px] p-[6px] px-4 text-gray-500 cursor-pointer hover:border-gray-400">
                                    {this.state.showObserverData ? (
                                        <span className="flex items-center">
                                            <img alt="" src={hideObsPNG} className="h-4" />
                                            {t("hide")} {t("observer")}
                                        </span>
                                    ) : (
                                        <span className="flex items-center">
                                            <DocumentSearchIcon className="h-4" />
                                            {t("show")} {t("observer")}
                                        </span>
                                    )}

                                </button> */}

                                {this.state.successMessage && (
                                    <div
                                        className="bg-white text-black text-lg font-semibold p-2 absolute  right-4  mr-4 rounded shadow "
                                        style={{ borderBottom: "2px solid rgb(37, 206, 124)" }}>
                                        {this.state.successMessage}
                                    </div>
                                )}
                                <PrimaryButton
                                    disabled={userRole === "Observer" ? true : false}
                                    onClick={() => this.createTeam()}
                                    buttontext={`${t("create")} ${t("team")}`}></PrimaryButton>
                            </div>
                        </div>
                        <div className="mt-4 flex flex-col bg-gray-100">
                            <div className="inline-block min-w-full align-middle">
                                <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[64vh] overflow-y-auto">
                                    <table className="min-w-full border-separate">
                                        <thead className="bg-gray-50 sticky top-0 z-10">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className=" border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                    <div className="flex gap-2">
                                                        {t("name")}
                                                        {(this.state.isSort ||
                                                            this.state.isSort1) &&
                                                        this.state.sortingOrder !==
                                                            "ascendingteamname" ? (
                                                            <button
                                                                title="Sort Ascending"
                                                                onClick={onClickAssending8}
                                                                className={`flex flex-col ml-3 sorting-descendingtname`}>
                                                                <IoMdArrowDropup className="my-0 text-xs" />
                                                                <IoMdArrowDropdown className="my-0 text-xs" />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                title="Sort Descending"
                                                                onClick={onClickDescending8}
                                                                className={`flex flex-col ml-3 sorting-ascendingtname`}>
                                                                <IoMdArrowDropup className="my-0 text-xs" />
                                                                <IoMdArrowDropdown className="my-0 text-xs" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </th>
                                                {/* <th
                          scope="col"
                          className=" border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                        >
                          {" "}
                          <div className="flex gap-2">
                          {t("TeamTab.created")} {t("date")}{" "}
                          {
                            <div className={`flex flex-col ml-3 ${(this.state.isSort || this.state.isSort1) && this.state.sortingOrder === "ascending" ? "sorting-ascending" : this.state.sortingOrder === "descending" ? "sorting-descending" : ""}`}>
                            <button
                              className="upArrow"
                              onClick={onClickAssending}
                            >
                              {<IoMdArrowDropup className="my-0 text-xs" />}
                            </button>
                            <button
                              className="downArrow"
                              onClick={onClickDescending}
                            >
                              {<IoMdArrowDropdown className="my-0 text-xs" />}
                            </button>
                          </div>
                          } 
                          </div>

                        </th> */}
                                                <th
                                                    scope="col"
                                                    className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter flex flex-row items-center">
                                                    {" "}
                                                    {t("TeamTab.created")} {t("date")}{" "}
                                                    {(this.state.isSort || this.state.isSort1) &&
                                                    this.state.sortingOrder !==
                                                        "ascendingcreated" ? (
                                                        <button
                                                            title="Sort Ascending"
                                                            onClick={onClickAssending}
                                                            className={`flex flex-col ml-3 sorting-descendingtname`}>
                                                            <IoMdArrowDropup className="my-0 text-xs" />
                                                            <IoMdArrowDropdown className="my-0 text-xs" />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            title="Sort Descending"
                                                            onClick={onClickDescending}
                                                            className={`flex flex-col ml-3 sorting-ascendingtname`}>
                                                            <IoMdArrowDropup className="my-0 text-xs" />
                                                            <IoMdArrowDropdown className="my-0 text-xs" />
                                                        </button>
                                                    )}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className=" border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                    <div className="flex gap-2 justify-center items-start">
                                                        {t("ProjectTasksTab.members")}
                                                        {(this.state.isSort ||
                                                            this.state.isSort1) &&
                                                        this.state.sortingOrder !==
                                                            "ascendingcount" ? (
                                                            <button
                                                                title="Sort Ascending"
                                                                onClick={onClickAssending0}
                                                                className={`flex flex-col ml-3 sorting-descendingtname`}>
                                                                <IoMdArrowDropup className="my-0 text-xs" />
                                                                <IoMdArrowDropdown className="my-0 text-xs" />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                title="Sort Descending"
                                                                onClick={onClickDescending0}
                                                                className={`flex flex-col ml-3 sorting-ascendingtname`}>
                                                                <IoMdArrowDropup className="my-0 text-xs" />
                                                                <IoMdArrowDropdown className="my-0 text-xs" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className=" border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12">
                                                    Edit
                                                </th>
                                            </tr>
                                        </thead>
                                        {this.state.TeamsSubSelectedTab === "Active" ? (
                                            <tbody className="bg-white">
                                                {activeTeams &&
                                                    (this.state.isSort == true
                                                        ? this.state.sortedTeams
                                                        : activeTeams
                                                    ).map((el, i) => (
                                                        <tr key={i}>
                                                            <td
                                                                onClick={() => {
                                                                    this.handleTeamNavigation(
                                                                        // el.team_id,
                                                                        // el,
                                                                        // el.team_name
                                                                        el.team.team_uuid,
                                                                        el.team,
                                                                        el.team.name
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    i !== activeTeams.length - 1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    // "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                                                    "whitespace-wrap py-4 pl-4 pr-3 text-sm font-medium cursor-pointer text-primary order-1 capitalize"
                                                                )}>
                                                                <div className="flex">
                                                                    <span>
                                                                        {el.team.name.includes(
                                                                            "from"
                                                                        ) ? (
                                                                            <span className="flex">
                                                                                <DocumentSearchIcon className="h-4 translate-y-[1px] text-primary mr-1" />{" "}
                                                                                {el.team.name}
                                                                            </span>
                                                                        ) : (
                                                                            el.team.name
                                                                        )}
                                                                    </span>
                                                                    {/* <LockClosedIcon className='ml-4 w-5 text-primary'></LockClosedIcon> */}
                                                                </div>
                                                            </td>
                                                            <td
                                                                className={classNames(
                                                                    i !== activeTeams.length - 1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                                                                )}>
                                                                {moment(el.team.created).format(
                                                                    "YYYY-MM-DD"
                                                                )}
                                                            </td>
                                                            <td
                                                                className={classNames(
                                                                    i !== activeTeams.length - 1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500 hidden lg:table-cell"
                                                                )}>
                                                                <div className="flex justify-center items-start">
                                                                    <UsersIcon className="mr-4 w-5"></UsersIcon>
                                                                    <span
                                                                        className="cursor-pointer text-primary"
                                                                        onClick={() =>
                                                                            this.GetmembersFromTeam(
                                                                                el
                                                                            )
                                                                        }>
                                                                        {el.count}
                                                                    </span>
                                                                </div>
                                                            </td>

                                                            <td
                                                                className={classNames(
                                                                    i !== activeTeams.length - 1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-12"
                                                                )}>
                                                                <div className="flex justify-center items-start">
                                                                    <p
                                                                        className={`${
                                                                            userRole === "Observer"
                                                                                ? "text-primary opacity-70"
                                                                                : "text-primary"
                                                                        }`}
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                isAddClientModalOpen: true,
                                                                                selectedTeamName:
                                                                                    el.team.name,
                                                                                team_uuid:
                                                                                    el.team
                                                                                        .team_uuid,
                                                                            })
                                                                        }>
                                                                        <OfficeBuildingIcon className="mr-4 w-5" />
                                                                        <span className="sr-only">
                                                                            , {el.name}
                                                                        </span>
                                                                    </p>

                                                                    <p
                                                                        className={`${
                                                                            userRole === "Observer"
                                                                                ? "text-primary opacity-70"
                                                                                : "text-primary"
                                                                        }`}
                                                                        onClick={() =>
                                                                            this.onHandleEditTeam(
                                                                                el
                                                                            )
                                                                        }>
                                                                        <PencilIcon className="mr-4 w-5" />
                                                                        <span className="sr-only">
                                                                            , {el.name}
                                                                        </span>
                                                                    </p>
                                                                    <p
                                                                        className={`${
                                                                            userRole === "Observer"
                                                                                ? "text-danger opacity-70"
                                                                                : "text-danger"
                                                                        }`}
                                                                        onClick={() =>
                                                                            this.onHandleDeleteTeam(
                                                                                el.team.org_uuid,
                                                                                el.team.team_uuid
                                                                            )
                                                                        }>
                                                                        <TrashIcon className="w-5" />
                                                                        <span className="sr-only">
                                                                            , {el.name}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        ) : (
                                            <tbody className="bg-white">
                                                {inactiveTeams &&
                                                    (this.state.isSort1 == true
                                                        ? this.state.sortedinactiveTeams
                                                        : inactiveTeams
                                                    ).map((el, i) => (
                                                        <tr key={i}>
                                                            <td
                                                                className={classNames(
                                                                    i !== inactiveTeams.length - 1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                                                )}>
                                                                {" "}
                                                                {el.team.name}{" "}
                                                            </td>
                                                            <td
                                                                className={classNames(
                                                                    i !== inactiveTeams.length - 1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                                                                )}>
                                                                {moment(el.team.created).format(
                                                                    "YYYY-MM-DD"
                                                                )}
                                                            </td>
                                                            <td
                                                                className={classNames(
                                                                    i !== inactiveTeams.length - 1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500 hidden lg:table-cell"
                                                                )}>
                                                                <div className="flex justify-center">
                                                                    <UsersIcon className="mr-4 w-5"></UsersIcon>
                                                                    <span
                                                                        className="cursor-pointer text-primary"
                                                                        onClick={() =>
                                                                            this.GetmembersFromTeam(
                                                                                el
                                                                            )
                                                                        }>
                                                                        {el.count}
                                                                    </span>
                                                                </div>
                                                            </td>

                                                            <td
                                                                className={classNames(
                                                                    i !== inactiveTeams.length - 1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "relative whitespace-nowrap py-4 pr-4 pl-3 text-center text-sm font-medium sm:pr-6 lg:pr-12"
                                                                )}>
                                                                <div
                                                                    className="text-primary hover:text-primary"
                                                                    onClick={() =>
                                                                        this.HandleReactivateTeam(
                                                                            el.team.org_uuid,
                                                                            el.team.team_uuid
                                                                        )
                                                                    }>
                                                                    {" "}
                                                                    Reactivate{" "}
                                                                    <span className="sr-only">
                                                                        , {el.name}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                {this.state.selectedUser && SelectedTab === "users" ? (
                    <EmployeePayroll
                        selectedUser={this.state.selectedUser}
                        onBack={() =>
                            this.setState({ selectedUser: "" }, () =>
                                window.history.replaceState({}, null, "/team/users")
                            )
                        }
                    />
                ) : null}
                {SelectedTab === "users" && !this.state.selectedUser ? (
                    <div className="bg-white mt-4 px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="-ml-4 -mt-2 flex items-center lg-m:items-stretch lg-m:flex-col justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2">
                                <div className="sm:hidden">
                                    <label htmlFor="tabs" className="sr-only">
                                        Select a tab
                                    </label>
                                    <select
                                        id="tabs"
                                        name="tabs"
                                        onChange={(e) => this.HandleMobileUsersSubSelectTab(e)}
                                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                                        defaultValue={UsersSubSelectedTab}>
                                        <option key="Active" value="Active">
                                            {t("active")}
                                        </option>
                                        <option key="InActive" value="InActive">
                                            {t("inactive")}
                                        </option>
                                    </select>
                                </div>
                                <div className="hidden sm:block">
                                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                        <div
                                            key="Active"
                                            onClick={() => this.HandleUsersSubSelectTab("Active")}
                                            className={classNames(
                                                UsersSubSelectedTab === "Active"
                                                    ? "border-primary text-primary"
                                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                            )}
                                            aria-current={
                                                UsersSubSelectedTab === "Active"
                                                    ? "page"
                                                    : undefined
                                            }>
                                            {t("active")}
                                            <span
                                                className={classNames(
                                                    UsersSubSelectedTab === "Active"
                                                        ? "bg-indigo-100 text-primary"
                                                        : "bg-gray-100 text-gray-900",
                                                    "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                                                )}>
                                                {lengthofActiveUsers}
                                            </span>
                                        </div>

                                        <div
                                            key="InActive"
                                            onClick={() => this.HandleUsersSubSelectTab("InActive")}
                                            className={classNames(
                                                UsersSubSelectedTab === "InActive"
                                                    ? "border-primary text-primary"
                                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                            )}
                                            aria-current={
                                                UsersSubSelectedTab === "InActive"
                                                    ? "page"
                                                    : undefined
                                            }>
                                            {t("inactive")}
                                            <span
                                                className={classNames(
                                                    UsersSubSelectedTab === "InActive"
                                                        ? "bg-indigo-100 text-primary"
                                                        : "bg-gray-100 text-gray-900",
                                                    "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                                                )}>
                                                {lengthofinactiveUsers}
                                            </span>
                                        </div>
                                    </nav>
                                </div>
                                {this.state.searchBoxEventIn &&
                                    this.state.UsersSubSelectedTab === "InActive" && (
                                        <div className="font-normal text-xs leading-normal text-gray-600">
                                            No. of Search results found :{" "}
                                            <span className="font-bold text-tiny leading-normal text-black">
                                                {handleSearchIn()}
                                            </span>
                                        </div>
                                    )}
                                {this.state.searchBoxEvent &&
                                    this.state.UsersSubSelectedTab === "Active" && (
                                        <div className="font-normal text-xs leading-normal text-gray-600">
                                            No. of Search results found :{" "}
                                            <span className="font-bold text-tiny leading-normal text-black">
                                                {handleSearchAct()}
                                            </span>
                                        </div>
                                    )}
                            </div>

                            <div className="ml-4 mt-1 flex flex-row gap-4 lg-m:flex lg-m:justify-between flex-shrink-0">
                                {this.state.UsersSubSelectedTab === "Active" && (
                                    <div className="flex justify-between items-center bg-white">
                                        <span className="ml-3 py-0.5 px-2.5 font-medium text-md leading-normal text-black">
                                            Search Employee
                                        </span>
                                        <div className="flex justify-center items-center">
                                            <div className="w-full mx-auto py-1 px-2.5 relative shadow-md justify-end items-center flex border border-[#D1D5DB] hover:border hover:border-[#684D94] rounded-md text-center focus:outline-none">
                                                <input
                                                    placeholder="Search by Name or Email"
                                                    type="text"
                                                    value={this.state.searchBox}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            searchBox: e.target.value,
                                                        });
                                                        if (e.target.value.trim() === "") {
                                                            this.setState({
                                                                searchBoxEvent: false,
                                                            });
                                                        } else {
                                                            this.setState({ searchBoxEvent: true });
                                                        }
                                                    }}
                                                    className={
                                                        "w-full p-0 border-0 text-gray-600 text-xs font-normal placeholder-gray-500 focus:ring-0 sm:text-sm "
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.UsersSubSelectedTab === "InActive" && (
                                    <div className="flex justify-between items-center bg-white">
                                        <span className="ml-3 py-0.5 px-2.5 font-medium text-md leading-normal text-black">
                                            Search Employee
                                        </span>
                                        <div className="flex justify-center items-center">
                                            <div className="w-full mx-auto py-1 px-2.5 relative shadow-md justify-end items-center flex border border-[#D1D5DB] hover:border hover:border-[#684D94] rounded-md text-center focus:outline-none">
                                                <input
                                                    placeholder="Search by Name or Email"
                                                    type="text"
                                                    value={this.state.searchBoxIn}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            searchBoxIn: e.target.value,
                                                        });
                                                        if (e.target.value.trim() === "") {
                                                            this.setState({
                                                                searchBoxEventIn: false,
                                                            });
                                                        } else {
                                                            this.setState({
                                                                searchBoxEventIn: true,
                                                            });
                                                        }
                                                    }}
                                                    className={
                                                        "w-full p-0 border-0 text-gray-600 text-xs font-normal placeholder-gray-500 focus:ring-0 sm:text-sm "
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <Link to="/inviteuser">
                                    <PrimaryButton
                                        disabled={userRole === "Observer" ? true : false}
                                        buttontext={
                                            `${t("invite")}` +
                                            ` ${t("TeamTab.new")}` +
                                            ` ${t("user")}`
                                        }></PrimaryButton>
                                </Link>
                            </div>
                        </div>
                        <div className="mt-4 flex flex-col bg-gray-100">
                            <div className="inline-block min-w-full align-middle">
                                <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[64vh] overflow-y-auto">
                                    {this.state.UsersSubSelectedTab === "Active" ? (
                                        <table className="min-w-full border-separate">
                                            <thead className="bg-gray-50 sticky top-0 z-10 min-w-full">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        // className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12"
                                                        className="flex flex-1 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                        <div className="flex gap-2">
                                                            {t("employee")} {t("name")}
                                                            {this.state.isSort2 &&
                                                            this.state.sortingOrder !==
                                                                "ascendingactiveename" ? (
                                                                <button
                                                                    title="Sort Ascending"
                                                                    onClick={onClickAssending1}
                                                                    className={`flex flex-col ml-3 sorting-descendingtname`}>
                                                                    <IoMdArrowDropup className="my-0 text-xs" />
                                                                    <IoMdArrowDropdown className="my-0 text-xs" />
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    title="Sort Descending"
                                                                    onClick={onClickDescending1}
                                                                    className={`flex flex-col ml-3 sorting-ascendingtname`}>
                                                                    <IoMdArrowDropup className="my-0 text-xs" />
                                                                    <IoMdArrowDropdown className="my-0 text-xs" />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter ">
                                                        <div className="flex gap-2">
                                                            {t("email")}{" "}
                                                            {this.state.isSort2 &&
                                                            this.state.sortingOrder !==
                                                                "ascendingacitveemail" ? (
                                                                <button
                                                                    title="Sort Ascending"
                                                                    onClick={onClickAssending2}
                                                                    className={`flex flex-col ml-3 sorting-descendingtname`}>
                                                                    <IoMdArrowDropup className="my-0 text-xs" />
                                                                    <IoMdArrowDropdown className="my-0 text-xs" />
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    title="Sort Descending"
                                                                    onClick={onClickDescending2}
                                                                    className={`flex flex-col ml-3 sorting-ascendingtname`}>
                                                                    <IoMdArrowDropup className="my-0 text-xs" />
                                                                    <IoMdArrowDropdown className="my-0 text-xs" />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                        <div className="flex gap-2">
                                                            {t("projects")} {t("TeamTab.assigned")}
                                                            {this.state.isSort2 &&
                                                            this.state.sortingOrder !==
                                                                "ascendingactiveproject" ? (
                                                                <button
                                                                    title="Sort Ascending"
                                                                    onClick={onClickAssending3}
                                                                    className={`flex flex-col ml-3 sorting-descendingtname`}>
                                                                    <IoMdArrowDropup className="my-0 text-xs" />
                                                                    <IoMdArrowDropdown className="my-0 text-xs" />
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    title="Sort Descending"
                                                                    onClick={onClickDescending3}
                                                                    className={`flex flex-col ml-3 sorting-ascendingtname`}>
                                                                    <IoMdArrowDropup className="my-0 text-xs" />
                                                                    <IoMdArrowDropdown className="my-0 text-xs" />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="border-b border-gray-300 bg-gray-50 text-sm bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12">
                                                        <span>{t("action")}</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {activeUsers &&
                                                    (this.state.isSort2 == true
                                                        ? this.state.sortedactiveUsers
                                                        : activeUsers
                                                    )
                                                        .filter((val) => {
                                                            const fullName =
                                                                val.user_first_name +
                                                                " " +
                                                                val.user_last_name;
                                                            if (
                                                                this.state.searchBox.trim() === ""
                                                            ) {
                                                                return val;
                                                            } else if (
                                                                fullName
                                                                    .toLowerCase()
                                                                    .includes(
                                                                        this.state.searchBox.toLowerCase()
                                                                    ) ||
                                                                val.user_email
                                                                    .toLowerCase()
                                                                    .includes(
                                                                        this.state.searchBox.toLowerCase()
                                                                    )
                                                            ) {
                                                                return val;
                                                            }
                                                        })
                                                        .map((el, i) => (
                                                            <tr key={i}>
                                                                <td
                                                                    className={classNames(
                                                                        i !== activeUsers.length - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                                                    )}>
                                                                    <div className="flex">
                                                                        <Link
                                                                            className="underline text-primary hover:cursor-pointer"
                                                                            to={
                                                                                "/profile/?user=" +
                                                                                el.user_id
                                                                            }>
                                                                            {el.user_login_as.includes(
                                                                                "from"
                                                                            ) ? (
                                                                                <span className="flex">
                                                                                    <DocumentSearchIcon className="h-4 translate-y-[1px] text-primary mr-1" />{" "}
                                                                                    {el.user_first_name +
                                                                                        " " +
                                                                                        el.user_last_name}
                                                                                </span>
                                                                            ) : (
                                                                                el.user_first_name +
                                                                                " " +
                                                                                el.user_last_name
                                                                            )}
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    className={classNames(
                                                                        i !== activeUsers.length - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                                                                    )}>
                                                                    {el.user_email}
                                                                </td>
                                                                <td
                                                                    className={classNames(
                                                                        i !== activeUsers.length - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500 hidden lg:table-cell"
                                                                    )}>
                                                                    {el.projects_count}
                                                                </td>
                                                                <td
                                                                    className={classNames(
                                                                        i !== activeUsers.length - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-12"
                                                                    )}>
                                                                    <div className="flex items-start justify-center w-full">
                                                                        <Link
                                                                            to={
                                                                                "/profile/?user=" +
                                                                                el.user_id
                                                                            }
                                                                            onClick={() => {
                                                                                handleEmployeeProfileNav(
                                                                                    el
                                                                                );
                                                                            }}
                                                                            className="text-primary border p-2 border-gray-300 rounded-md mr-4 focus:bg-primary focus:text-white hover:bg-gray hover:text-textprimary">
                                                                            View Profile
                                                                        </Link>
                                                                        <div
                                                                            className={`${
                                                                                userRole ===
                                                                                "Observer"
                                                                                    ? "text-primary opacity-70"
                                                                                    : "text-primary"
                                                                            }`}>
                                                                            {/* adding client icon */}
                                                                            {/* <Link to="">
                                    <OfficeBuildingIcon
                                      className="mr-4 w-5 cursor-pointer"
                                      onClick={()=>createClientModal()}
                                    />
                                  </Link> */}
                                                                            <Link to="/edit_Profile">
                                                                                <PencilIcon
                                                                                    className="mr-4 w-5 cursor-pointer"
                                                                                    onClick={() => {
                                                                                        handleEmployeeProfileNav(
                                                                                            el
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </Link>
                                                                            <span className="sr-only">
                                                                                , {el.name}
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className={`${
                                                                                userRole ===
                                                                                "Observer"
                                                                                    ? "text-danger opacity-70"
                                                                                    : "text-danger"
                                                                            }`}
                                                                            onClick={() =>
                                                                                this.handleDeleteUser(
                                                                                    el.user_email,
                                                                                    el.user_login_as,
                                                                                    el.user_id
                                                                                )
                                                                            }>
                                                                            <TrashIcon className="w-5 cursor-pointer" />
                                                                            <span className="sr-only">
                                                                                , {el.name}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                {activeUsers &&
                                                    (this.state.isSort2 == true
                                                        ? this.state.sortedactiveUsers
                                                        : activeUsers
                                                    ).filter((val) => {
                                                        const fullName =
                                                            val.user_first_name +
                                                            " " +
                                                            val.user_last_name;
                                                        if (this.state.searchBox.trim() === "") {
                                                            return val;
                                                        } else if (
                                                            fullName
                                                                .toLowerCase()
                                                                .includes(
                                                                    this.state.searchBox.toLowerCase()
                                                                ) ||
                                                            val.user_email
                                                                .toLowerCase()
                                                                .includes(
                                                                    this.state.searchBox.toLowerCase()
                                                                )
                                                        ) {
                                                            return val;
                                                        }
                                                    }).length === 0 && (
                                                        <tr>
                                                            <td
                                                                colSpan="4"
                                                                className="flex-1 text-center py-6 italic text-[#6B7280]">
                                                                User does not exist
                                                            </td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <table className="min-w-full border-separate">
                                            <thead className="bg-gray-50 top-0 sticky z-10">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="flex flex-1 border-b border-gray-300bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                        <div className="flex gap-2">
                                                            {t("employee")} {t("name")}
                                                            {this.state.isSort3 &&
                                                            this.state.sortingOrder !==
                                                                "ascendinginactiveename" ? (
                                                                <button
                                                                    title="Sort Ascending"
                                                                    onClick={onClickAssending4}
                                                                    className={`flex flex-col ml-3 sorting-descendingtname`}>
                                                                    <IoMdArrowDropup className="my-0 text-xs" />
                                                                    <IoMdArrowDropdown className="my-0 text-xs" />
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    title="Sort Descending"
                                                                    onClick={onClickDescending4}
                                                                    className={`flex flex-col ml-3 sorting-ascendingtname`}>
                                                                    <IoMdArrowDropup className="my-0 text-xs" />
                                                                    <IoMdArrowDropdown className="my-0 text-xs" />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                        <div className="flex gap-2">
                                                            {" "}
                                                            {t("email")}{" "}
                                                            {this.state.isSort3 &&
                                                            this.state.sortingOrder !==
                                                                "ascendinginactiveemail" ? (
                                                                <button
                                                                    title="Sort Ascending"
                                                                    onClick={onClickAssending5}
                                                                    className={`flex flex-col ml-3 sorting-descendingtname`}>
                                                                    <IoMdArrowDropup className="my-0 text-xs" />
                                                                    <IoMdArrowDropdown className="my-0 text-xs" />
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    title="Sort Descending"
                                                                    onClick={onClickDescending5}
                                                                    className={`flex flex-col ml-3 sorting-ascendingtname`}>
                                                                    <IoMdArrowDropup className="my-0 text-xs" />
                                                                    <IoMdArrowDropdown className="my-0 text-xs" />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                                                        {t("TeamTab.relieving")} {t("date")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12">
                                                        {t("settingsTab.edit")}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {inactiveUsers &&
                                                    (this.state.isSort3 == true
                                                        ? this.state.sortedinactiveUsers
                                                        : inactiveUsers
                                                    )
                                                        .sort(
                                                            (a, b) =>
                                                                new Date(b.last_date) -
                                                                new Date(a.last_date)
                                                        )
                                                        .filter((val) => {
                                                            if (
                                                                this.state.searchBoxIn.trim() === ""
                                                            ) {
                                                                return val;
                                                            } else if (
                                                                val.name
                                                                    .toLowerCase()
                                                                    .includes(
                                                                        this.state.searchBoxIn.toLowerCase()
                                                                    ) ||
                                                                val.email
                                                                    .toLowerCase()
                                                                    .includes(
                                                                        this.state.searchBoxIn.toLowerCase()
                                                                    )
                                                            ) {
                                                                return val;
                                                            }
                                                        })
                                                        .map((el, i) => (
                                                            <tr key={i}>
                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            inactiveUsers.length - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                                                    )}>
                                                                    {" "}
                                                                    {el.name}{" "}
                                                                </td>
                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            inactiveUsers.length - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                                                                    )}>
                                                                    {el.email}
                                                                </td>
                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            inactiveUsers.length - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                                                                    )}>
                                                                    {moment(el.last_date).format(
                                                                        "YYYY-MM-DD"
                                                                    )}
                                                                </td>
                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            inactiveUsers.length - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-12"
                                                                    )}>
                                                                    <p
                                                                        className="text-primary hover:text-primary cursor-pointer"
                                                                        onClick={() =>
                                                                            this.handleReactivateUser(
                                                                                el.email
                                                                            )
                                                                        }>
                                                                        {" "}
                                                                        Reactivate{" "}
                                                                        <span className="sr-only">
                                                                            , {el.name}
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                {inactiveUsers &&
                                                    (this.state.isSort3 == true
                                                        ? this.state.sortedinactiveUsers
                                                        : inactiveUsers
                                                    )
                                                        .sort(
                                                            (a, b) =>
                                                                new Date(b.last_date) -
                                                                new Date(a.last_date)
                                                        )
                                                        .filter((val) => {
                                                            if (
                                                                this.state.searchBoxIn.trim() === ""
                                                            ) {
                                                                return val;
                                                            } else if (
                                                                val.name
                                                                    .toLowerCase()
                                                                    .includes(
                                                                        this.state.searchBoxIn.toLowerCase()
                                                                    ) ||
                                                                val.email
                                                                    .toLowerCase()
                                                                    .includes(
                                                                        this.state.searchBoxIn.toLowerCase()
                                                                    )
                                                            ) {
                                                                return val;
                                                            }
                                                        }).length === 0 && (
                                                        <tr>
                                                            <td
                                                                colSpan="4"
                                                                className="flex-1 text-center py-6 italic text-[#6B7280]">
                                                                User does not exist
                                                            </td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                        <Filter
                            isOpen={isOpen}
                            setIsOpen={this.filterStatus}
                            onRoleChange={this.onRoleChange}
                        />
                        <SortByEmploye
                            isSortOpenEmploye={isSortOpenEmploye}
                            sortEmployeStatus={this.sortEmployeStatus}
                            activeUsers={activeUsers}
                        />
                        <SortByProjects
                            isSortOpenProjects={isSortOpenProjects}
                            sortProjectsStatus={this.sortProjectsStatus}
                            activeUsers={activeUsers}
                        />
                        <SortByEmail
                            isSortOpenEmail={isSortOpenEmail}
                            sortEmailStatus={this.sortEmailStatus}
                            activeUsers={activeUsers}
                        />
                        {/* {this.state.UsersSubSelectedTab === "InActive" ? (
              <div className="flex justify-center mt-6 mb-6 paginationstyle">
                <Pagination
                  count={Math.ceil(lengthofinactiveUsers / this.state.perPage)}
                  page={this.state.inactiveuserspage}
                  onChange={this.handleInactiveusersPageChange}
                ></Pagination>
              </div>
            ) : (
              <div className="flex justify-center mt-6 mb-6 paginationstyle">
                <Pagination
                  count={Math.ceil(lengthofActiveUsers / this.state.perPage)}
                  page={this.state.activeuserspage}
                  onChange={this.handleActiveUsersPageChange}
                ></Pagination>
              </div>
            )} */}
                    </div>
                ) : null}
                {SelectedTab === "workstations" ? (
                    <div className="bg-white mt-4 px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="-ml-4 flex items-center justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2"> </div>
                            <div className="ml-4 mt-2 flex-shrink-0">
                                <PrimaryButton
                                    disabled={userRole === "Observer" ? true : false}
                                    onClick={() => this.handlecreateWorkstation()}
                                    buttontext={`${t("create")} ${t(
                                        "TeamTab.workstation"
                                    )}`}></PrimaryButton>
                            </div>
                        </div>
                        <div className="mt-4 flex flex-col overflow-y-auto bg-gray-100">
                            <div className="inline-block min-w-full align-middle">
                                <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[64vh] overflow-auto">
                                    <table className="min-w-full border-separate">
                                        <thead className="bg-gray-50 sticky top-0 z-10">
                                            <tr>
                                                {/* <th
                          scope="col"
                          className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12"
                        ><div className="flex gap-2">
                          {" "}
                          {t("name")}{" "}
                          {
                            <div className={`flex flex-col ml-3 ${this.state.isSort4 && this.state.sortingOrder === "ascending" ? "sorting-ascending" : this.state.sortingOrder === "descending" ? "sorting-descending" : ""}`}>
                            <button
                              className="upArrow"
                              onClick={onClickAssending6}
                            >
                              {<IoMdArrowDropup className="my-0 text-xs" />}
                            </button>
                            <button
                              className="downArrow"
                              onClick={onClickDescending6}
                            >
                              {<IoMdArrowDropdown className="my-0 text-xs" />}
                            </button>
                          </div>
                          }
                          </div>
                        </th> */}
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                    <div className="flex gap-2">
                                                        {" "}
                                                        {t("name")}{" "}
                                                        {this.state.isSort4 &&
                                                        this.state.sortingOrder !==
                                                            "ascendingworkname" ? (
                                                            <button
                                                                title="Sort Ascending"
                                                                onClick={onClickAssending6}
                                                                className={`flex flex-col ml-3 sorting-descendingtname`}>
                                                                <IoMdArrowDropup className="my-0 text-xs" />
                                                                <IoMdArrowDropdown className="my-0 text-xs" />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                title="Sort Descending"
                                                                onClick={onClickDescending6}
                                                                className={`flex flex-col ml-3 sorting-ascendingtname`}>
                                                                <IoMdArrowDropup className="my-0 text-xs" />
                                                                <IoMdArrowDropdown className="my-0 text-xs" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                                                    <div className="flex gap-2">
                                                        {" "}
                                                        {t("TeamTab.workstation")} {t("name")}{" "}
                                                        {this.state.isSort4 &&
                                                        this.state.sortingOrder !==
                                                            "ascendingworkstation" ? (
                                                            <button
                                                                title="Sort Ascending"
                                                                onClick={onClickAssending7}
                                                                className={`flex flex-col ml-3 sorting-descendingtname`}>
                                                                <IoMdArrowDropup className="my-0 text-xs" />
                                                                <IoMdArrowDropdown className="my-0 text-xs" />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                title="Sort Descending"
                                                                onClick={onClickDescending7}
                                                                className={`flex flex-col ml-3 sorting-ascendingtname`}>
                                                                <IoMdArrowDropup className="my-0 text-xs" />
                                                                <IoMdArrowDropdown className="my-0 text-xs" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                                                    {" "}
                                                    {t("TeamTab.workstation")} {t("description")}{" "}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {workstations &&
                                                (this.state.isSort4 == true
                                                    ? this.state.sortedworkstation
                                                    : workstations
                                                ).map((el, i) => (
                                                    <tr key={i}>
                                                        <td
                                                            className={classNames(
                                                                i !== workstations.length - 1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                                            )}>
                                                            {el.name}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                i !== workstations.length - 1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                                                            )}>
                                                            {el.workstation}
                                                        </td>

                                                        <td
                                                            className={classNames(
                                                                i !== workstations.length - 1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                                                            )}>
                                                            {el.description}
                                                        </td>

                                                        <td
                                                            className={classNames(
                                                                i !== workstations.length - 1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-12"
                                                            )}>
                                                            <div className="flex">
                                                                <p
                                                                    className={`${
                                                                        userRole === "Observer"
                                                                            ? "text-primary opacity-70"
                                                                            : "text-primary"
                                                                    }`}
                                                                    onClick={() =>
                                                                        this.handleupdateWorkstation(
                                                                            el.workstation,
                                                                            " ",
                                                                            el.description,
                                                                            el.id
                                                                        )
                                                                    }>
                                                                    <PencilIcon className="mr-4 w-5 cursor-pointer" />
                                                                    <span className="sr-only">
                                                                        , {el.name}
                                                                    </span>
                                                                </p>
                                                                <p
                                                                    className={`${
                                                                        userRole === "Observer"
                                                                            ? "text-danger opacity-70"
                                                                            : "text-danger"
                                                                    }`}
                                                                    onClick={() =>
                                                                        this.handleDeleteWorkstation(
                                                                            el.id
                                                                        )
                                                                    }>
                                                                    <TrashIcon className="w-5 cursor-pointer" />
                                                                    <span className="sr-only">
                                                                        , {el.name}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {SelectedTab === "TeamMembers" ? (
                    <div className="bg-white mt-4 px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                        <button
                            onClick={() => this.setState({ SelectedTab: "teams" })}
                            className="rounded-md shadow-md border max-w-max bg-white border-fiver border-opacity-60 mt-2 py-2 px-4 hover:cursor-pointer">
                            Back
                        </button>
                        <div className="-ml-4 flex items-center justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2 font-lg">
                                {" "}
                                {t("team")} {t("name")}:{" "}
                                <span className="font-bold"> {SelectedTeamName} </span>{" "}
                            </div>
                            <div className="ml-4 mt-2 flex-shrink-0"></div>
                        </div>
                        <div className="mt-4 flex flex-col bg-gray-100">
                            <div className="inline-block min-w-full align-middle">
                                <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[64vh] overflow-y-auto">
                                    <table className="min-w-full border-separate">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                    {" "}
                                                    {t("name")}{" "}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                    {" "}
                                                    Role{" "}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {membersoftheteam &&
                                                membersoftheteam.map((el, i) => (
                                                    <tr key={i}>
                                                        <td
                                                            onLoad={console.log(
                                                                "membersoftheteam",
                                                                membersoftheteam
                                                            )}
                                                            className={classNames(
                                                                i !== membersoftheteam.length - 1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                                            )}>
                                                            {el.username}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                i !== membersoftheteam.length - 1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                                                            )}>
                                                            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                                                <FormControl component="fieldset">
                                                                    <RadioGroup
                                                                        row
                                                                        aria-label="position"
                                                                        name="position"
                                                                        value={
                                                                            el.role === "member" ||
                                                                            el.role ===
                                                                                "employee" ||
                                                                            el.role === "Regular"
                                                                                ? "memeber"
                                                                                : el.role
                                                                        }
                                                                        onChange={(event) =>
                                                                            handelRoleChange(
                                                                                el.user_uuid,
                                                                                event.target.value
                                                                            )
                                                                        }>
                                                                        <FormControlLabel
                                                                            value="memeber"
                                                                            control={
                                                                                <Radio
                                                                                    style={{
                                                                                        color: "#684D94",
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label="Regular"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="Manager"
                                                                            control={
                                                                                <Radio
                                                                                    style={{
                                                                                        color: "#684D94",
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label="Manager"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="Admin"
                                                                            control={
                                                                                <Radio
                                                                                    style={{
                                                                                        color: "#684D94",
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label="Admin"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="Observer"
                                                                            control={
                                                                                <Radio
                                                                                    style={{
                                                                                        color: "#684D94",
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label="Observer"
                                                                        />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </div>
                                                        </td>

                                                        <td
                                                            className={classNames(
                                                                i !== membersoftheteam.length - 1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-12"
                                                            )}>
                                                            <div className="flex">
                                                                <p
                                                                    className={`${
                                                                        userRole === "Observer"
                                                                            ? "text-danger opacity-70"
                                                                            : "text-danger"
                                                                    } hover:text-danger`}>
                                                                    <TrashIcon
                                                                        className="w-5"
                                                                        onClick={() =>
                                                                            this.onDeleteTeamMemeber(
                                                                                el.user_uuid
                                                                            )
                                                                        }
                                                                    />
                                                                    <span className="sr-only">
                                                                        , {el.username}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {SelectedTab === "observers" ? (
                    <div>
                        <div className="bg-white w-full p-5 mt-6">
                            <div className="flex justify-between items-center">
                                <p className="text-gray-500 opacity-80">
                                    {this.state.ObserverList === ""
                                        ? ""
                                        : this.state.ObserverList.length}{" "}
                                    {t("TeamTab.observers")}
                                </p>
                                <PrimaryButton
                                    disabled={userRole === "Observer" ? true : false}
                                    onClick={() => {
                                        this.setState({ showAddObserverModal: true });
                                    }}
                                    buttontext={`${t("add")} ${t("observer")}`}></PrimaryButton>
                                {/* <button className='px-4 p-1 bg-primary rounded-md text-white' onClick={() => {this.setState({showAddObserverModal: true})}}>+ AddObserver</button> */}
                            </div>
                            <div className="mt-5">
                                <div className="bg-white mt-4 border-b border-gray-200 rounded-lg">
                                    <div
                                        className="mt-2 flex flex-col bg-gray-100"
                                        style={{ maxHeight: "auto" }}>
                                        <div className="inline-block min-w-full align-middle">
                                            <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[62vh] overflow-y-auto">
                                                <table
                                                    className="min-w-full border-separate"
                                                    style={{ borderSpacing: 0 }}>
                                                    <thead className="bg-gray-50 top-0 sticky z-10">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                                {t("observer")}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                                {t("TeamTab.orgnaization")}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                                                                {t("team")} {t("access")}
                                                            </th>
                                                            {/* <th
                                                                scope="col"
                                                                className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                                            >
                                                                Last Activity
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                                            >
                                                                Total Activity
                                                            </th> */}
                                                            <th
                                                                scope="col"
                                                                className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-2 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12">
                                                                {t("action")}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white">
                                                        {this.state.ObserverList === ""
                                                            ? ""
                                                            : this.state.ObserverList?.sort(
                                                                  (a, b) =>
                                                                      a.user.username.localeCompare(
                                                                          b.user.username
                                                                      )
                                                              ).map((person, idx) => (
                                                                  <tr key={idx}>
                                                                      <td
                                                                          className={classNames(
                                                                              idx !==
                                                                                  person.length - 1
                                                                                  ? "border-b border-gray-200"
                                                                                  : "",
                                                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                                                          )}>
                                                                          {person.user.username}
                                                                      </td>
                                                                      <td
                                                                          className={classNames(
                                                                              idx !==
                                                                                  person.length - 1
                                                                                  ? "border-b border-gray-200"
                                                                                  : "",
                                                                              "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                                                                          )}>
                                                                          {person.user.username}
                                                                      </td>
                                                                      <td
                                                                          className={classNames(
                                                                              idx !==
                                                                                  person.length - 1
                                                                                  ? "border-b border-gray-200"
                                                                                  : "",
                                                                              "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                                                                          )}>
                                                                          {person.user.username}
                                                                      </td>
                                                                      {/* <td
                                                                    className={classNames(
                                                                        idx !== person.length - 1 ? 'border-b border-gray-200' : '',
                                                                        'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                                                                    )}
                                                                >
                                                                    {person.user.username}
                                                                </td>
                                                                <td
                                                                    className={classNames(
                                                                        idx !== person.length - 1 ? 'border-b border-gray-200' : '',
                                                                        'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                                                                    )}
                                                                >
                                                                    {person.roles}
                                                                </td> */}
                                                                      <td
                                                                          className={classNames(
                                                                              idx !==
                                                                                  person.length - 1
                                                                                  ? "border-b border-gray-200"
                                                                                  : "",
                                                                              "relative whitespace-nowrap py-4 pr-3 pl-5 text-right text-sm font-medium sm:pr-6 lg:pr-12 flex justify-center"
                                                                          )}>
                                                                          <span>
                                                                              <PencilIcon
                                                                                  className="text-primary h-5 w-5 mr-2 cursor-pointer"
                                                                                  onClick={() => {
                                                                                      this.setState(
                                                                                          {
                                                                                              editObserverModal: true,
                                                                                              selectedObstoEdit:
                                                                                                  person,
                                                                                          }
                                                                                      );
                                                                                  }}
                                                                              />
                                                                          </span>
                                                                          <span>
                                                                              <TrashIcon
                                                                                  className="text-primary h-5 w-5 cursor-pointer"
                                                                                  onClick={() => {
                                                                                      this.setState(
                                                                                          {
                                                                                              deleteObserverModal: true,
                                                                                              deleteObsEmail:
                                                                                                  person
                                                                                                      .user
                                                                                                      .email,
                                                                                              deleteObsUuid:
                                                                                                  person
                                                                                                      .user
                                                                                                      .user_uuid,
                                                                                          }
                                                                                      );
                                                                                  }}
                                                                              />
                                                                          </span>
                                                                      </td>
                                                                  </tr>
                                                              ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                {SelectedTab === "manager" ? (
                    <div>
                        <div className="bg-white w-full p-5 mt-6">
                            <div className="flex justify-between items-center">
                                <p className="text-gray-500">
                                    {this.state.ManagerList === ""
                                        ? ""
                                        : this.state.ManagerList.length +
                                          " " +
                                          t("TeamTab.managers")}
                                </p>
                                <PrimaryButton
                                    disabled={userRole === "Observer" ? true : false}
                                    onClick={() => {
                                        this.setState({ showAddManagerModal: true });
                                    }}
                                    buttontext={`${t("add")} ${t(
                                        "TeamTab.manager"
                                    )}`}></PrimaryButton>
                                {/* <button className='px-4 p-1 bg-primary rounded-md text-white' onClick={() => {this.setState({showAddObserverModal: true})}}>+ AddObserver</button> */}
                            </div>
                            <div className="mt-5">
                                <div className="bg-white mt-4 border-b border-gray-200 rounded-lg">
                                    <div
                                        className="mt-2 flex flex-col bg-gray-100"
                                        style={{ maxHeight: "auto" }}>
                                        <div className="inline-block min-w-full align-middle">
                                            <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[64vh] overflow-auto">
                                                <table
                                                    className="min-w-full border-separate"
                                                    style={{ borderSpacing: 0 }}>
                                                    <thead className="bg-gray-50 sticky top-0 z-10">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                                {t("TeamTab.manager")}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                                {t("TeamTab.orgnaization")}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                                                                {t("team")} {t("access")}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                                {t("last")} {t("activity")}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                                {t("total")} {t("activity")}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-2 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12">
                                                                {t("action")}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white">
                                                        {this.state.ManagerList === ""
                                                            ? ""
                                                            : this.state.ManagerList?.sort((a, b) =>
                                                                  a.user.username.localeCompare(
                                                                      b.user.username
                                                                  )
                                                              ).map((person, idx) => (
                                                                  <tr key={idx}>
                                                                      <td
                                                                          className={classNames(
                                                                              idx !==
                                                                                  this.state
                                                                                      .ManagerList
                                                                                      .length -
                                                                                      1
                                                                                  ? "border-b border-gray-200"
                                                                                  : "",
                                                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                                                          )}>
                                                                          {person.user.username}
                                                                      </td>
                                                                      <td
                                                                          className={classNames(
                                                                              idx !==
                                                                                  this.state
                                                                                      .ManagerList
                                                                                      .length -
                                                                                      1
                                                                                  ? "border-b border-gray-200"
                                                                                  : "",
                                                                              "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                                                                          )}>
                                                                          {person.user.username}
                                                                      </td>
                                                                      <td
                                                                          className={classNames(
                                                                              idx !==
                                                                                  this.state
                                                                                      .ManagerList
                                                                                      .length -
                                                                                      1
                                                                                  ? "border-b border-gray-200"
                                                                                  : "",
                                                                              "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                                                                          )}>
                                                                          {person.user.username}
                                                                      </td>
                                                                      <td
                                                                          className={classNames(
                                                                              idx !==
                                                                                  this.state
                                                                                      .ManagerList
                                                                                      .length -
                                                                                      1
                                                                                  ? "border-b border-gray-200"
                                                                                  : "",
                                                                              "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                                                          )}>
                                                                          {person.role}
                                                                      </td>
                                                                      <td
                                                                          className={classNames(
                                                                              idx !==
                                                                                  this.state
                                                                                      .ManagerList
                                                                                      .length -
                                                                                      1
                                                                                  ? "border-b border-gray-200"
                                                                                  : "",
                                                                              "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                                                          )}>
                                                                          {person.role}
                                                                      </td>
                                                                      <td
                                                                          className={classNames(
                                                                              idx !==
                                                                                  this.state
                                                                                      .ManagerList
                                                                                      .length -
                                                                                      1
                                                                                  ? "border-b border-gray-200"
                                                                                  : "",
                                                                              "relative whitespace-nowrap py-4 pr-3 pl-5 text-right text-sm font-medium sm:pr-6 lg:pr-12 flex"
                                                                          )}>
                                                                          <span>
                                                                              <PencilIcon className="text-primary h-5 w-5 mr-2 cursor-pointer" />
                                                                          </span>
                                                                          <span>
                                                                              <TrashIcon
                                                                                  className="text-primary h-5 w-5 cursor-pointer"
                                                                                  onClick={() => {
                                                                                      this.setState(
                                                                                          {
                                                                                              deleteManagerModal: true,
                                                                                              deleteMngrEmail:
                                                                                                  person
                                                                                                      .user
                                                                                                      .email,
                                                                                              deleteMngrUuid:
                                                                                                  person
                                                                                                      .user
                                                                                                      .user_uuid,
                                                                                          }
                                                                                      );
                                                                                  }}
                                                                              />
                                                                          </span>
                                                                      </td>
                                                                  </tr>
                                                              ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                {SelectedTab === "client" ? (
                    <div className="bg-white mt-4 px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="-ml-4 -mt-2 flex items-center lg-m:items-stretch lg-m:flex-col justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2">
                                <div className="sm:hidden">
                                    <label htmlFor="tabs" className="sr-only">
                                        Select a tab
                                    </label>

                                    <select
                                        id="tabs"
                                        name="tabs"
                                        onChange={(e) => this.HandleMobileClientsSubSelectTab(e)}
                                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                                        defaultValue={ClientsSubSelectedTab}>
                                        <option key="Active" value="Active">
                                            {t("active")}
                                        </option>

                                        <option key="InActive" value="InActive">
                                            {t("inactive")}
                                        </option>
                                    </select>
                                </div>

                                <div className="hidden sm:block">
                                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                        <div
                                            key="Active"
                                            onClick={() => this.HandleClientsSubSelectTab("Active")}
                                            className={classNames(
                                                ClientsSubSelectedTab === "Active"
                                                    ? "border-primary text-primary"
                                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",

                                                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                            )}
                                            aria-current={
                                                ClientsSubSelectedTab === "Active"
                                                    ? "page"
                                                    : undefined
                                            }>
                                            {t("active")}

                                            <span
                                                className={classNames(
                                                    ClientsSubSelectedTab === "Active"
                                                        ? "bg-indigo-100 text-primary"
                                                        : "bg-gray-100 text-gray-900",

                                                    "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                                                )}>
                                                {clientsLength}
                                            </span>
                                        </div>

                                        <div
                                            key="InActive"
                                            onClick={() =>
                                                this.HandleClientsSubSelectTab("InActive")
                                            }
                                            className={classNames(
                                                ClientsSubSelectedTab === "InActive"
                                                    ? "border-primary text-primary"
                                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",

                                                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                            )}
                                            aria-current={
                                                ClientsSubSelectedTab === "InActive"
                                                    ? "page"
                                                    : undefined
                                            }>
                                            {t("inactive")}

                                            <span
                                                className={classNames(
                                                    ClientsSubSelectedTab === "InActive"
                                                        ? "bg-indigo-100 text-primary"
                                                        : "bg-gray-100 text-gray-900",

                                                    "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                                                )}>
                                                {inactiveclientsLength}
                                            </span>
                                        </div>
                                    </nav>
                                </div>
                            </div>

                            <div className="ml-4 mt-2 lg-m:flex lg-m:justify-between flex-shrink-0">
                                {this.state.successMessage && (
                                    <div
                                        className="bg-white text-black text-lg font-semibold p-2 absolute  right-4  mr-4 rounded shadow "
                                        style={{ borderBottom: "2px solid rgb(37, 206, 124)" }}>
                                        {this.state.successMessage}
                                    </div>
                                )}

                                <PrimaryButton
                                    disabled={userRole === "Observer" ? true : false}
                                    // onClick={() => this.createTeam()}

                                    // onClick={this.openCreateClientModalNew}

                                    onClick={() =>
                                        this.setState({ isCreateNewClientModalOpen: true })
                                    }
                                    // onClick={() => this.handlecreateClient}

                                    buttontext={`${t("create")} ${t("Client")}`}></PrimaryButton>
                            </div>
                        </div>

                        <div className="mt-4 flex flex-col bg-gray-100">
                            <div className="inline-block min-w-full align-middle">
                                <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[64vh] overflow-y-auto">
                                    {this.state.ClientsSubSelectedTab === "Active" ? (
                                        <table className="min-w-full border-separate">
                                            <thead className="bg-gray-50 sticky top-0 z-10">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                        {" "}
                                                        {t("Client name")}{" "}
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                        {" "}
                                                        {t("Teams")}{" "}
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                                                        {" "}
                                                        {t("Billing Type")}{" "}
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                        {" "}
                                                        {t("Hours spend")}{" "}
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                        {" "}
                                                        {t("Billing amount")}{" "}
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12">
                                                        {t("Action")}

                                                        <span className="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                            </thead>

                                            {clients && clients.data ? (
                                                <tbody className="bg-white">
                                                    {clients.data
                                                        ?.sort((a, b) =>
                                                            a.client_name.localeCompare(
                                                                b.client_name
                                                            )
                                                        )
                                                        .map((client, i) => (
                                                            <tr key={i}>
                                                                <td
                                                                    // onClick={() => {

                                                                    //   this.handleTeamNavigation(

                                                                    //     el.team_id,

                                                                    //     el,

                                                                    //     el.team_name

                                                                    //   );

                                                                    // }}

                                                                    className={classNames(
                                                                        i !== clientsLength - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",

                                                                        "whitespace-nowrap py-4 pl-4 pr-3 text-md font-medium text-primary sm:pl-6 lg:pl-12 underline decoration-text-primary"
                                                                    )}>
                                                                    <div className="flex">
                                                                        <Link
                                                                            to={
                                                                                "/team/client_details?client=" +
                                                                                client.id
                                                                            }
                                                                            className="cursor-pointer">
                                                                            {client.client_name}
                                                                            {/* {this.state.clientName} */}
                                                                        </Link>
                                                                    </div>
                                                                </td>

                                                                <td
                                                                    className={classNames(
                                                                        i !== clientsLength - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",

                                                                        "whitespace-nowrap px-3 py-4 text-md text-gray-500 hidden sm:table-cell"
                                                                    )}>
                                                                    {/* {this.state.selectedTeams} */}
                                                                    {client.teams.join(" , ")}{" "}
                                                                </td>

                                                                <td
                                                                    className={classNames(
                                                                        i !== clientsLength - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",

                                                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                                                                    )}>
                                                                    <div className="flex">
                                                                        <span
                                                                            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"

                                                                            // onClick={() => this.GetmembersFromTeam(el)}
                                                                        >
                                                                            Fixed Rate
                                                                        </span>
                                                                    </div>
                                                                </td>

                                                                <td
                                                                    className={classNames(
                                                                        i !== clientsLength - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",

                                                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                                                                    )}>
                                                                    {client.hours_spent}
                                                                </td>

                                                                <td
                                                                    className={classNames(
                                                                        i !== clientsLength - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",

                                                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                                                                    )}>
                                                                    {client.total_costs}
                                                                </td>

                                                                <td
                                                                    className={classNames(
                                                                        i !== clientsLength - 1
                                                                            ? "border-b border-gray-200"
                                                                            : "",

                                                                        "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-12"
                                                                    )}>
                                                                    <div className="flex">
                                                                        <p
                                                                            className={`${
                                                                                userRole ===
                                                                                "Observer"
                                                                                    ? "text-primary opacity-70"
                                                                                    : "text-primary"
                                                                            }`}

                                                                            // onClick={() => this.onHandleEditTeam(el)}
                                                                        >
                                                                            {/* <PencilIcon className="mr-4 w-5" /> */}

                                                                            {/* <span className="sr-only">, {el.name}</span> */}

                                                                            {/* <span className="sr-only">, name</span> */}
                                                                        </p>

                                                                        <p
                                                                            className={`${
                                                                                userRole ===
                                                                                "Observer"
                                                                                    ? "text-danger opacity-70"
                                                                                    : "text-danger"
                                                                            }`}
                                                                            onClick={() =>
                                                                                this.handleDeleteClient(
                                                                                    client.id,
                                                                                    "False"
                                                                                )
                                                                            }>
                                                                            <TrashIcon className="w-5 cursor-pointer" />

                                                                            <span className="sr-only">
                                                                                ,name
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            ) : (
                                                <div></div>
                                            )}
                                        </table>
                                    ) : (
                                        <table className="min-w-full border-separate">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                        {" "}
                                                        {t("Client name")}{" "}
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                        {" "}
                                                        {t("Teams")}{" "}
                                                    </th>

                                                    {/* <th
                            scope="col"
                            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12"
                          >
                            {t("    ")}

                            <span className="sr-only">Edit</span>
                          </th> */}
                                                </tr>
                                            </thead>

                                            {inactiveclients && inactiveclients.data ? (
                                                <tbody className="bg-white">
                                                    {inactiveclients.data
                                                        ?.sort((a, b) =>
                                                            a.client_name.localeCompare(
                                                                b.client_name
                                                            )
                                                        )
                                                        .map((client, i) => (
                                                            <tr key={i}>
                                                                <td
                                                                    // onClick={() => {

                                                                    //   this.handleTeamNavigation(

                                                                    //     el.team_id,

                                                                    //     el,

                                                                    //     el.team_name

                                                                    //   );

                                                                    // }}

                                                                    className={classNames(
                                                                        i !==
                                                                            inactiveclientsLength -
                                                                                1
                                                                            ? "border-b border-gray-200"
                                                                            : "",

                                                                        "whitespace-nowrap py-4 pl-4 pr-3 text-md font-medium text-primary sm:pl-6 lg:pl-12 underline decoration-text-primary"
                                                                    )}>
                                                                    <div className="flex">
                                                                        {client.client_name}

                                                                        {/* {this.state.clientName} */}
                                                                    </div>
                                                                </td>

                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            inactiveclientsLength -
                                                                                1
                                                                            ? "border-b border-gray-200"
                                                                            : "",

                                                                        "whitespace-nowrap px-3 py-4 text-md text-gray-500 hidden sm:table-cell"
                                                                    )}>
                                                                    {/* {this.state.selectedTeams} */}
                                                                    {client.teams.join(" , ")}{" "}
                                                                </td>
                                                                {/* <td
                                className={classNames(
                                  i !== inactiveclientsLength - 1
                                    ? "border-b border-gray-200"
                                    : "",

                                  "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-12"
                                )}
                              >
                                <div className="flex">
                                  <p
                                    className={`${
                                      userRole === "Observer"
                                        ? "text-primary opacity-70"
                                        : "text-primary"
                                    }`}

                                    // onClick={() => this.onHandleEditTeam(el)}
                                  >
                                    Reactivate Client
                                    
                                  </p>
                                </div>
                              </td> */}
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            ) : (
                                                <div></div>
                                            )}
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>

                        {this.state.TeamsSubSelectedTab === "InActive" ? (
                            lengthofinactiveTeams > 0 ? (
                                <div className="flex justify-center mt-6 mb-6 paginationstyle">
                                    <Pagination
                                        count={Math.ceil(
                                            lengthofinactiveTeams / this.state.perPage
                                        )}
                                        page={this.state.inactiveteamspage}
                                        onChange={this.handleInactiveTeamsPageChange}></Pagination>
                                </div>
                            ) : null
                        ) : lengthofinactiveTeams > 0 ? (
                            <div className="flex justify-center mt-6 mb-6 paginationstyle"></div>
                        ) : null}
                    </div>
                ) : null}

                {this.state.showAddObserverModal ? (
                    <Transition.Root show={this.state.showAddObserverModal} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed z-30 inset-0 overflow-y-auto"
                            onClose={() => {
                                this.setState({
                                    showAddObserverModal: false,
                                    addObserver: true,
                                    saveObserver: false,
                                    listOfteamsobserUUID: [],
                                    listofObserData: [],
                                    listofSelectedTeamobserver: [],
                                });
                            }}>
                            <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0">
                                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                    aria-hidden="true">
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                    <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                        {this.state.addObserver ? (
                                            <>
                                                <h2>Add Observer</h2>
                                                <div className="bg-gray-200 h-1 w-full rounded-md my-5"></div>
                                                <div>
                                                    <div className="mt-6">
                                                        <InputField
                                                            FieldName="Email ID"
                                                            type="text"
                                                            value={this.state.observerEmail}
                                                            onInputChange={(e) => {
                                                                this.setState({
                                                                    observerEmail: e.target.value,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    {/* <div className='mt-6'><InputField FieldName='Role' type='text' onInputChange={(e) => {this.setState({observerRole: e.target.value})}}/></div> */}
                                                </div>
                                                <p className="mt-5 text-sm">Select Teams</p>
                                                <div className="mt-2 z-10">
                                                    <Select
                                                        onChange={(e) => {
                                                            handleTeamSelectedObserver(e);
                                                        }}
                                                        isClearable={
                                                            TeamData &&
                                                            TeamData.some((v) => !v.isFixed)
                                                        }
                                                        options={TeamData}
                                                        isMulti
                                                    />
                                                </div>
                                                <div className="flex justify-end items-center mt-5">
                                                    <button
                                                        className="p-1 px-5 border border-primary text-primary mr-3 rounded-sm"
                                                        onClick={() => {
                                                            this.setState({
                                                                showAddObserverModal: false,
                                                                addObserver: true,
                                                                saveObserver: false,
                                                            });
                                                        }}>
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className="p-1 px-8 bg-primary text-white rounded-sm"
                                                        onClick={() => {
                                                            handleAddObserver();
                                                        }}>
                                                        Save
                                                    </button>
                                                </div>
                                            </>
                                        ) : null}
                                        {this.state.saveObserver ? (
                                            <>
                                                <div className="border-2 border-green-500 rounded-md flex">
                                                    <div className="bg-green-500 p-2">
                                                        <CheckIcon className="h-5 w-5 text-white" />
                                                    </div>
                                                    <div className="p-1 text-gray-500">
                                                        The following observer is ready to be added
                                                        in observer list
                                                    </div>
                                                </div>

                                                <div className="bg-white mt-4 border-b border-gray-200 rounded-lg">
                                                    <div
                                                        className="mt-2 flex flex-col overflow-y-auto bg-gray-100"
                                                        style={{ maxHeight: "auto" }}>
                                                        <div className="inline-block min-w-full align-middle">
                                                            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                                                <table
                                                                    className="min-w-full border-separate"
                                                                    style={{ borderSpacing: 0 }}>
                                                                    <thead className="bg-gray-50">
                                                                        <tr>
                                                                            <th
                                                                                scope="col"
                                                                                className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                                                Email Address
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                                                Role
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                                                Team Access
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="bg-white">
                                                                        {this.state.listofObserData.map(
                                                                            (person, personIdx) => (
                                                                                <tr
                                                                                    key={
                                                                                        person.email
                                                                                    }>
                                                                                    <td
                                                                                        className={classNames(
                                                                                            personIdx !==
                                                                                                person.length -
                                                                                                    1
                                                                                                ? "border-b border-gray-200"
                                                                                                : "",
                                                                                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                                                                        )}>
                                                                                        {
                                                                                            person.email
                                                                                        }
                                                                                    </td>
                                                                                    <td
                                                                                        className={classNames(
                                                                                            personIdx !==
                                                                                                person.length -
                                                                                                    1
                                                                                                ? "border-b border-gray-200"
                                                                                                : "",
                                                                                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                                                                                        )}>
                                                                                        {
                                                                                            person.role
                                                                                        }
                                                                                    </td>
                                                                                    <td
                                                                                        className={classNames(
                                                                                            personIdx !==
                                                                                                person.length -
                                                                                                    1
                                                                                                ? "border-b border-gray-200"
                                                                                                : "",
                                                                                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"
                                                                                        )}>
                                                                                        {person.teams.map(
                                                                                            (
                                                                                                team,
                                                                                                teamIdx
                                                                                            ) =>
                                                                                                team +
                                                                                                " "
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="bg-gray-200 rounded-md h-1 w-full mt-6"></div>

                                                <div className="flex justify-end mt-4">
                                                    <button
                                                        className="border border-primary text-primary p-1 px-4 rounded-sm mr-3"
                                                        onClick={() => {
                                                            this.setState({
                                                                showAddObserverModal: false,
                                                                addObserver: true,
                                                                saveObserver: false,
                                                                listOfteamsobserUUID: [],
                                                                listofObserData: [],
                                                                listofSelectedTeamobserver: [],
                                                            });
                                                        }}>
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className="border border-primary text-primary p-1 px-3 rounded-sm mr-3"
                                                        onClick={() => {
                                                            this.setState({
                                                                addObserver: true,
                                                                saveObserver: false,
                                                                listOfteamsobserUUID: [],
                                                                listofObserData: [],
                                                                listofSelectedTeamobserver: [],
                                                            });
                                                        }}>
                                                        Start Over
                                                    </button>
                                                    <button
                                                        className="bg-primary text-white p-1 px-8 rounded-sm"
                                                        onClick={() => {
                                                            submitObserverInvite();
                                                        }}>
                                                        Save
                                                    </button>
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition.Root>
                ) : null}

                {this.state.showAddManagerModal ? (
                    <Transition.Root show={this.state.showAddManagerModal} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed z-30 inset-0 overflow-y-auto"
                            onClose={() => {
                                this.setState({
                                    showAddManagerModal: false,
                                    addManager: true,
                                    saveManager: false,
                                    listOfteamsmngrUUID: [],
                                    listofManagerData: [],
                                    listofSelectedTeamManager: [],
                                });
                            }}>
                            <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0">
                                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                    aria-hidden="true">
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                    <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                        {this.state.addManager ? (
                                            <>
                                                <h2>Add Manager</h2>
                                                <div className="bg-gray-200 h-1 w-full rounded-md my-5"></div>
                                                <div>
                                                    <div className="mt-6">
                                                        <InputField
                                                            FieldName="Email ID"
                                                            type="text"
                                                            value={this.state.managerEmail}
                                                            onInputChange={(e) => {
                                                                this.setState({
                                                                    managerEmail: e.target.value,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <p className="mt-5 text-sm">Select Teams</p>
                                                <div className="mt-2 z-10">
                                                    <Select
                                                        onChange={(e) => {
                                                            handleTeamSelectedManager(e);
                                                        }}
                                                        isClearable={
                                                            TeamData &&
                                                            TeamData.some((v) => !v.isFixed)
                                                        }
                                                        options={TeamData}
                                                        isMulti
                                                    />
                                                </div>
                                                <div className="flex justify-end items-center mt-5">
                                                    <button
                                                        className="p-1 px-5 border border-primary text-primary mr-3 rounded-sm"
                                                        onClick={() => {
                                                            this.setState({
                                                                showAddManagerModal: false,
                                                                addManager: true,
                                                                saveManager: false,
                                                            });
                                                        }}>
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className="p-1 px-8 bg-primary text-white rounded-sm"
                                                        onClick={() => {
                                                            handleAddManager();
                                                        }}>
                                                        Save
                                                    </button>
                                                </div>
                                            </>
                                        ) : null}
                                        {this.state.saveManager ? (
                                            <>
                                                <div className="border-2 border-green-500 rounded-md flex">
                                                    <div className="bg-green-500 p-2">
                                                        <CheckIcon className="h-5 w-5 text-white" />
                                                    </div>
                                                    <div className="p-1 text-gray-500">
                                                        The following manager is ready to be added
                                                        in manager list
                                                    </div>
                                                </div>

                                                <div className="bg-white mt-4 border-b border-gray-200 rounded-lg">
                                                    <div
                                                        className="mt-2 flex flex-col overflow-y-auto bg-gray-100"
                                                        style={{ maxHeight: "auto" }}>
                                                        <div className="inline-block min-w-full align-middle">
                                                            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                                                <table
                                                                    className="min-w-full border-separate"
                                                                    style={{ borderSpacing: 0 }}>
                                                                    <thead className="bg-gray-50">
                                                                        <tr>
                                                                            <th
                                                                                scope="col"
                                                                                className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                                                Email Address
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                                                Role
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                                                Team Access
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="bg-white">
                                                                        {this.state.listofManagerData.map(
                                                                            (person, personIdx) => (
                                                                                <tr
                                                                                    key={
                                                                                        person.email
                                                                                    }>
                                                                                    <td
                                                                                        className={classNames(
                                                                                            personIdx !==
                                                                                                person.length -
                                                                                                    1
                                                                                                ? "border-b border-gray-200"
                                                                                                : "",
                                                                                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                                                                        )}>
                                                                                        {
                                                                                            person.email
                                                                                        }
                                                                                    </td>
                                                                                    <td
                                                                                        className={classNames(
                                                                                            personIdx !==
                                                                                                person.length -
                                                                                                    1
                                                                                                ? "border-b border-gray-200"
                                                                                                : "",
                                                                                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                                                                                        )}>
                                                                                        {
                                                                                            person.role
                                                                                        }
                                                                                    </td>
                                                                                    <td
                                                                                        className={classNames(
                                                                                            personIdx !==
                                                                                                person.length -
                                                                                                    1
                                                                                                ? "border-b border-gray-200"
                                                                                                : "",
                                                                                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"
                                                                                        )}>
                                                                                        {person.teams.map(
                                                                                            (
                                                                                                team,
                                                                                                teamIdx
                                                                                            ) =>
                                                                                                team +
                                                                                                " "
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="bg-gray-200 rounded-md h-1 w-full mt-6"></div>

                                                <div className="flex justify-end mt-4">
                                                    <button
                                                        className="border border-primary text-primary p-1 px-4 rounded-sm mr-3"
                                                        onClick={() => {
                                                            this.setState({
                                                                showAddManagerModal: false,
                                                                addManager: true,
                                                                saveManager: false,
                                                                listOfteamsmngrUUID: [],
                                                                listofManagerData: [],
                                                                listofSelectedTeamManager: [],
                                                            });
                                                        }}>
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className="border border-primary text-primary p-1 px-3 rounded-sm mr-3"
                                                        onClick={() => {
                                                            this.setState({
                                                                addManager: true,
                                                                saveManager: false,
                                                                listOfteamsmngrUUID: [],
                                                                listofManagerData: [],
                                                                listofSelectedTeamManager: [],
                                                            });
                                                        }}>
                                                        Start Over
                                                    </button>
                                                    <button
                                                        className="bg-primary text-white p-1 px-8 rounded-sm"
                                                        onClick={() => {
                                                            submitManagerInvite();
                                                        }}>
                                                        Save
                                                    </button>
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition.Root>
                ) : null}

                {this.state.editObserverModal ? (
                    <Transition.Root show={this.state.editObserverModal} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed z-30 inset-0 overflow-y-auto"
                            onClose={() => {
                                this.setState({ editObserverModal: false });
                            }}>
                            <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0">
                                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                    aria-hidden="true">
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                    <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                        {this.state.addManager ? (
                                            <>
                                                <h2>Edit Observer</h2>
                                                <div className="bg-gray-200 h-1 w-full rounded-md my-5"></div>
                                                <div className="flex w-full justify-between">
                                                    <div className="mt-6">
                                                        <InputField
                                                            FieldName="First Name"
                                                            type="text"
                                                            value={this.state.editObsFirstName}
                                                            onInputChange={(e) => {
                                                                this.setState({
                                                                    editObsFirstName:
                                                                        e.target.value,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="mt-6">
                                                        <InputField
                                                            FieldName="Last Name"
                                                            type="text"
                                                            value={this.state.editObsLastName}
                                                            onInputChange={(e) => {
                                                                this.setState({
                                                                    editObsLastName: e.target.value,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-8">
                                                    <InputField
                                                        disabled={true}
                                                        FieldName="Email ID"
                                                        type="text"
                                                        onInputChange={() => {}}
                                                        placeholder={`${this.state.selectedObstoEdit.user.email}`}
                                                    />
                                                </div>
                                                <div className="mt-8">
                                                    <InputField
                                                        disabled={true}
                                                        FieldName="Username"
                                                        type="text"
                                                        onInputChange={() => {}}
                                                        placeholder={`${this.state.selectedObstoEdit.user.username}`}
                                                    />
                                                </div>
                                                {/* <div className="mt-8">
                                                    <InputField FieldName="Organization" type="text" onInputChange={() => {}} />
                                                </div> */}
                                                <p className="mt-5 text-sm">Select Teams to Add</p>
                                                <div className="mt-2 z-10">
                                                    <Select
                                                        onChange={(e) => {
                                                            handleTeamSelectedEditObserver(e);
                                                        }}
                                                        isClearable={
                                                            TeamData &&
                                                            TeamData.some((v) => !v.isFixed)
                                                        }
                                                        options={TeamData}
                                                        isMulti
                                                    />
                                                </div>
                                                <p className="mt-5 text-sm">
                                                    Select Teams to Remove from
                                                </p>
                                                <div className="mt-2 z-10">
                                                    <Select
                                                        onChange={(e) => {
                                                            handleTeamSelectedEditObserverTOReomove(
                                                                e
                                                            );
                                                        }}
                                                        isClearable={
                                                            TeamData &&
                                                            TeamData.some((v) => !v.isFixed)
                                                        }
                                                        options={TeamData}
                                                        isMulti
                                                    />
                                                </div>
                                                <div className="flex justify-end items-center mt-5">
                                                    <button
                                                        className="p-1 px-5 border border-primary text-primary mr-3 rounded-sm"
                                                        onClick={() => {
                                                            this.setState({
                                                                editObserverModal: false,
                                                            });
                                                        }}>
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className="p-1 px-8 bg-primary text-white rounded-sm"
                                                        onClick={() => {
                                                            handleEditObserverDetaild();
                                                        }}>
                                                        Save
                                                    </button>
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition.Root>
                ) : null}

                <CreateTeamModal
                    open={this.state.teamcreationModal}
                    onCancelClick={() => this.onModalClose()}
                    onFieldChange={(e) => this.onCreateTeamChange(e)}
                    onSubmitClick={() => this.onModalCreateSubmit()}
                    nameValue={this.state.teamName}
                    submitButtonName={"Submit"}
                    cancelButtonName={"Cancel"}></CreateTeamModal>

                <UpdateTeamModal
                    open={this.state.teamupdationModal}
                    onCancelClick={() => this.onModalClose()}
                    onFieldChange={(e) => this.onCreateTeamChange(e)}
                    onSubmitClick={() => this.onModalTeamUpdateSubmit()}
                    SelectedUsers={this.state.listofselectedUsers}
                    UsersRemove={this.state.usersRemove}
                    teamName={this.state.teamName}
                    onChangeSelect={this.OneditTeamSelectChange}
                    onChangeSelectRemove={this.OneditTeamSelectChangeRemove}
                    isClearable={
                        this.state.listofselectedUsers &&
                        this.state.listofselectedUsers.some((v) => !v.isFixed)
                    }
                    membersoftheteam={listofselectedUsers}
                    isClearableRemove={
                        this.state.usersRemove && this.state.usersRemove.some((v) => !v.isFixed)
                    }
                    listofallusers={listofallactiveusers}
                    submitButtonName={"Submit"}
                    cancelButtonName={"Cancel"}></UpdateTeamModal>

                <AddClientToTeamModal
                    open={isAddClientModalOpen}
                    onCancelClick={() =>
                        this.setState({
                            isAddClientModalOpen: false,
                        })
                    }
                    // onSubmitClick={this.handleAddClientToTeam}
                    onSubmitClick={() =>
                        this.handleAddClientToTeam(
                            this.state.selectedClient,
                            this.state.selectedClientName,
                            this.state.team_uuid
                        )
                    }
                    // onSubmitClick={this.handleAddClient}
                    team_uuid={this.state.team_uuid}
                    selectedTeamName={this.state.selectedTeamName}
                    clients={clients}
                    selectedClient={this.state.selectedClient}
                    onClientSelectChange={this.handleClientSelectChange}
                    submitButtonName={"Submit"}
                    cancelButtonName={"Cancel"}
                    onCreateNewClientClick={() =>
                        this.setState({ isCreateNewClientModalOpen: true })
                    }
                    setSuccessMessage={(message) => this.setState({ successMessage: message })}
                />

                {this.state.isCreateNewClientModalOpen && (
                    <CreateNewClientModal
                        name={this.state.name}
                        teams={TeamData}
                        onCancelClick={() =>
                            this.setState({
                                isCreateNewClientModalOpen: false,
                                name: "",
                                description: "",
                                teams: "",
                                hours_booked: "",
                                roll_over: "",
                                over_billing: false,
                            })
                        }
                        // onSubmitClick={this.handleCreateClientToTeam}
                        onSubmitClick={(formData) => this.handleCreateClientToTeam(formData)}
                        submitButtonName={"Submit"}
                        cancelButtonName={"Cancel"}
                        open={this.state.isCreateNewClientModalOpen}
                        selectedTeams={selectedClient}
                        // selectedTeam={this.state.selectedTeam}
                        onClientNameChange={this.handleClientNameChange}
                        description={this.state.description}
                        onClientDescriptionChange={this.handleClientDescriptionChange}
                        hours_booked={this.state.hours_booked}
                        onHoursBookedChange={this.handleHoursBookedChange}
                        roll_over={this.state.roll_over}
                        onMaxRollOverHoursChange={this.handleMaxRollOverHoursChange}
                        setSuccessMessage={(message) => this.setState({ successMessage: message })}
                        over_billing_rate={this.state.over_billing_rate}
                        onEnabledRollingTimeChange={this.handleEnabledRollingBillingRate}
                        onMaxBillRateChange={this.handleMaxBilRateChange}
                        selectedRate={this.state.selectedRate}
                    />
                )}

                <CreateWorkstationModal
                    open={this.state.workstationcreationModal}
                    ModalHeading={`${t("create")} ${t("TeamTab.workstation")}`}
                    onCancelClick={() => this.onModalClose()}
                    onEmailFieldChange={(e) => this.handleWorkstationEmailChange(e)}
                    onNameFieldChange={(e) => this.handleWorkstationNameChange(e)}
                    onDescriptionFieldChange={(e) => this.handleWorkstationDescriptionChange(e)}
                    onSubmitClick={() => this.onModalWorkstationCreateSubmit()}
                    WorkStationEmailValue={this.state.workstationemail}
                    WorkStationNameValue={this.state.workstationname}
                    WorkStationDescriptionValue={this.state.workstationdescription}
                    submitButtonName={"Submit"}
                    cancelButtonName={"Cancel"}></CreateWorkstationModal>

                <UpdateWorkstationModal
                    open={this.state.workstationUpdateModal}
                    onCancelClick={() => this.onModalClose()}
                    onEmailFieldChange={(e) => this.handleWorkstationEmailChange()}
                    onNameFieldChange={(e) => this.handleWorkstationNameChange(e)}
                    WorkStationEmailValue={this.state.workstationemail}
                    WorkStationNameValue={this.state.workstationname}
                    disableemail={true}
                    ModalHeading={"Update Workstation"}
                    WorkStationDescriptionValue={this.state.workstationdescription}
                    onDescriptionFieldChange={(e) => this.handleWorkstationDescriptionChange(e)}
                    onSubmitClick={() => this.onModalWorkstationUpdateSubmit()}
                    submitButtonName={"Update"}
                    cancelButtonName={"Cancel"}></UpdateWorkstationModal>

                <ConfirmationDialoag
                    open={this.state.enableDeleteTeamConfirmationModal}
                    onCancelClick={() => this.onModalClose()}
                    onSubmitClick={() => this.onHandleDeleteTeamClick()}
                    info={"Are you sure you want to Delete ?"}
                    submitButtonName={"Delete"}
                    cancelButtonName={"Cancel"}></ConfirmationDialoag>

                <ConfirmationDialoag
                    open={this.state.enableDeleteTeamMemberConfirmationModal}
                    onCancelClick={() => this.onModalClose()}
                    onSubmitClick={() => this.onHandleDeleteTeamMember()}
                    info={"Are you sure you want to Delete ?"}
                    submitButtonName={"Delete"}
                    cancelButtonName={"Cancel"}></ConfirmationDialoag>

                <ConfirmationDialoag
                    open={this.state.enableDeleteUserConfirmationModal}
                    onCancelClick={() => this.onModalClose()}
                    onSubmitClick={() => this.onHandleDeleteUserClick()}
                    info={"Are you sure you want to Delete ?"}
                    submitButtonName={"Delete"}
                    cancelButtonName={"Cancel"}></ConfirmationDialoag>

                <ConfirmationDialoag
                    open={this.state.enableDeleteWorkstationConfirmationModal}
                    onCancelClick={() => this.onModalClose()}
                    onSubmitClick={() => this.onHandleDeleteWorkstationClick()}
                    info={"Are you sure you want to Delete ?"}
                    submitButtonName={"Delete"}
                    cancelButtonName={"Cancel"}></ConfirmationDialoag>

                <ConfirmationDialoag
                    open={this.state.enableDeleteClientConfirmationModal}
                    onCancelClick={() => this.onModalClose()}
                    onSubmitClick={() => this.onHandleDeleteClientClick()}
                    info={"Are you sure you want to Delete ?"}
                    submitButtonName={"Delete"}
                    cancelButtonName={"Cancel"}></ConfirmationDialoag>

                <ConfirmationDialoag
                    open={this.state.enableUserReactivateConfirmationModal}
                    onCancelClick={() => this.onModalClose()}
                    onSubmitClick={() => this.onHandleReactivateuserClick()}
                    info={"Are you sure you want to Reactivate ?"}
                    submitButtonName={"Reactivate"}
                    cancelButtonName={"Cancel"}></ConfirmationDialoag>

                <ConfirmationDialoag
                    open={this.state.enableTeamReactivateConfirmationModal}
                    onCancelClick={() => this.onModalClose()}
                    onSubmitClick={() => this.onHandleReactivateTeamClick()}
                    info={"Are you sure you want to Reactivate ?"}
                    submitButtonName={"Reactivate"}
                    cancelButtonName={"Cancel"}></ConfirmationDialoag>

                <ConfirmationDialoag
                    open={this.state.deleteObserverModal}
                    onCancelClick={() => {
                        this.setState({
                            deleteObserverModal: false,
                            deleteObsEmail: "",
                            deleteObsUuid: "",
                        });
                    }}
                    onSubmitClick={() => {
                        deActivateObserver();
                    }}
                    info={"Are you sure you want to Delete ?"}
                    submitButtonName={"Delete"}
                    cancelButtonName={"Cancel"}></ConfirmationDialoag>

                <ConfirmationDialoag
                    open={this.state.deleteManagerModal}
                    onCancelClick={() => {
                        this.setState({
                            deleteManagerModal: false,
                            deleteMngrEmail: "",
                            deleteMngrUuid: "",
                        });
                    }}
                    onSubmitClick={() => {
                        deActivateManager();
                        // deleteManager()
                    }}
                    info={"Are you sure you want to Delete ?"}
                    submitButtonName={"Delete"}
                    cancelButtonName={"Cancel"}></ConfirmationDialoag>
            </Layout>
        );
    }
}
const mapStateToProps = (state) => ({
    teams: state.teams,
    dashboard: state.dashboard,
});

const mapDispatchToProps = (dispatch) => ({
    fetchactiveuserslist: () => dispatch(fetchactiveuserslist()),
    fetchActiveUsersObserver: () => dispatch(fetchActiveUsersObserver()),
    fetchInactiveuserslist: () => dispatch(fetchInactiveuserslist()),
    fetchAllWorkstatons: (email) => dispatch(fetchAllWorkstatons(email)),
    CreateTeam: (data) => dispatch(CreateTeam(data)),
    // fetchClient:(data)=>dispatch(fetchClient(data)),
    fetchClient: (months, year, status) => dispatch(fetchClient(months, year, status)),
    fetchInactiveClient: (months, year, status) =>
        dispatch(fetchInactiveClient(months, year, status)),
    deleteClient: (id, status) => dispatch(deleteClient(id, status)),
    CreateClient: (data) => dispatch(CreateClient(data)),
    CreateTeamPadlock: (data) => dispatch(CreateTeamPadlock(data)),

    FetchactiveTeams: (org_uuid) => dispatch(FetchactiveTeams(org_uuid)),

    FetchInactiveTeams: (org_uuid) => dispatch(FetchInactiveTeams(org_uuid)),

    DeleteteamfromPadlock: (org_uuid, team_uuid) =>
        dispatch(DeleteteamfromPadlock(org_uuid, team_uuid)),
    Deleteteam: (org_uuid, team_uuid) => dispatch(Deleteteam(org_uuid, team_uuid)),
    ReactivateTeam: (org_uuid, team_uuid) => dispatch(ReactivateTeam(org_uuid, team_uuid)),
    DeleteUserfromPadlock: (useremail, orgemail) =>
        dispatch(DeleteUserfromPadlock(useremail, orgemail)),
    DeleteUser: (userloginas) => dispatch(DeleteUser(userloginas)),
    addclientToTeam: (client_id, team_id) => dispatch(addclientToTeam(client_id, team_id)),
    ReactivateUserFromPadlock: (useremail, orgemail) =>
        dispatch(ReactivateUserFromPadlock(useremail, orgemail)),
    ReactivateUser: (useremail) => dispatch(ReactivateUser(useremail)),
    DeleteWorkstation: (id) => dispatch(DeleteWorkstation(id)),
    createWorkstation: (data) => dispatch(createWorkstation(data)),
    updateWorkstation: (data) => dispatch(updateWorkstation(data)),
    getUsersfromTeam: (id) => dispatch(getUsersfromTeam(id)),
    addmembertoTeaminPadlock: (data) => dispatch(addmembertoTeaminPadlock(data)),
    addmembertoTeam: (data) => dispatch(addmembertoTeam(data)),
    DeleteTeammember: (team_uuid, user_uuid) => dispatch(DeleteTeammember(team_uuid, user_uuid)),
    DeleteTeammembers: (team_uuid, user_uuids) =>
        dispatch(DeleteTeammembers(team_uuid, user_uuids)),
    DeleteTeammembersPadlock: (team_uuid, user_uuids) =>
        dispatch(DeleteTeammembersPadlock(team_uuid, user_uuids)),
    createWorkstationWorkahub: (data) => dispatch(createWorkstationWorkahub(data)),
    getObserverList: () => dispatch(getObserverList()),
    getManagerList: () => dispatch(getManagerList()),
    activeTeamObserver: (org_uuid) => dispatch(activeTeamObserver(org_uuid)),
    teamsDashboard: (date) => dispatch(teamsDashboard(date)),
    editUserDetails: (data) => dispatch(editUserDetails(data)),
});

Teams.propTypes = {};

export default withTranslation("common")(connect(mapStateToProps, mapDispatchToProps)(Teams));
