import { ACTION_TYPES } from "../../../constants";
const userprofile = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_PROFILE_IMG_PENDING: {
            return {
                ...state,
                fetchingProfileImg: true,
                fetchingProfileImgFailed: false,
                fetchingProfileImgError: undefined,
                ProfileImg: {},
            };
        }

        case ACTION_TYPES.GET_PROFILE_IMG_FULFILLED: {
            return {
                ...state,
                fetchingProfileImg: false,
                fetchingProfileImgFailed: false,
                fetchingProfileImgError: undefined,
                ProfileImg: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_PROFILE_IMG_REJECTED: {
            return {
                ...state,
                fetchingProfileImg: false,
                fetchingProfileImgFailed: true,
                fetchingProfileImgError: action.payload,
                ProfileImg: {},
            };
        }

        case ACTION_TYPES.UPDATE_PROFILE_IMG_PENDING: {
            return {
                ...state,
                updatingProfileImg: true,
                updatingProfileImgFailed: false,
                updatingProfileImgError: undefined,
                ProfileImgUpdate: {},
            };
        }

        case ACTION_TYPES.UPDATE_PROFILE_IMG_FULFILLED: {
            return {
                ...state,
                updatingProfileImg: false,
                updatingProfileImgFailed: false,
                updatingProfileImgError: undefined,
                ProfileImgUpdate: action.payload.data,
            };
        }
        case ACTION_TYPES.UPDATE_PROFILE_IMG_REJECTED: {
            return {
                ...state,
                updatingProfileImg: false,
                updatingProfileImgFailed: true,
                updatingProfileImgError: action.payload,
                ProfileImgUpdate: {},
            };
        }
        case ACTION_TYPES.GET_USER_SKILLS_PENDING: {
            return {
                ...state,
                fetchingUserSkills: true,
                fetchingUserSkillsFailed: false,
                fetchingUserSkillsError: undefined,
                UserSkills: {},
            };
        }

        case ACTION_TYPES.GET_USER_SKILLS_FULFILLED: {
            return {
                ...state,
                fetchingUserSkills: false,
                fetchingUserSkillsgFailed: false,
                fetchingUserSkillsError: undefined,
                UserSkills: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_USER_SKILLS_REJECTED: {
            return {
                ...state,
                fetchingUserSkills: false,
                fetchingUserSkillsFailed: true,
                fetchingUserSkillsError: action.payload,
                UserSkills: {},
            };
        }

        case ACTION_TYPES.UPDATE_USER_SKILLS_PENDING: {
            return {
                ...state,
                updatingUserSkills: true,
                updatingUserSkillsFailed: false,
                updatingUserSkillsError: undefined,
                UserSkillsUpdate: {},
            };
        }

        case ACTION_TYPES.UPDATE_USER_SKILLS_FULFILLED: {
            return {
                ...state,
                updatingUserSkills: false,
                updatingUserSkillsFailed: false,
                updatingUserSkillsError: undefined,
                UserSkillsUpdate: action.payload.data,
            };
        }
        case ACTION_TYPES.UPDATE_USER_SKILLS_REJECTED: {
            return {
                ...state,
                updatingUserSkills: false,
                updatingUserSkillsFailed: true,
                updatingUserSkillsError: action.payload,
                UserSkillsUpdate: {},
            };
        }

        case ACTION_TYPES.GET_USER_LOC_DESG_PENDING: {
            return {
                ...state,
                fetchingUserLocDesg: true,
                fetchingUserLocDesgFailed: false,
                fetchingUserLocDesgError: undefined,
                UserLocDesg: {},
            };
        }

        case ACTION_TYPES.GET_USER_LOC_DESG_FULFILLED: {
            return {
                ...state,
                fetchingUserLocDesg: false,
                fetchingUserLocDesgFailed: false,
                fetchingUserLocDesgError: undefined,
                UserLocDesg: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_USER_LOC_DESG_REJECTED: {
            return {
                ...state,
                fetchingUserLocDesg: false,
                fetchingUserLocDesgFailed: true,
                fetchingUserLocDesgError: action.payload,
                UserLocDesg: {},
            };
        }

        case ACTION_TYPES.UPDATE_USER_LOC_DESG_PENDING: {
            return {
                ...state,
                updatingUserLocDesg: true,
                updatingUserLocDesgFailed: false,
                updatingUserLocDesgError: undefined,
                UserLocDesgUpdate: {},
            };
        }

        case ACTION_TYPES.UPDATE_USER_LOC_DESG_FULFILLED: {
            return {
                ...state,
                updatingUserLocDesg: false,
                updatingUserLocDesgFailed: false,
                updatingUserLocDesgError: undefined,
                UserLocDesgUpdate: action.payload.data,
            };
        }
        case ACTION_TYPES.UPDATE_USER_LOC_DESG_REJECTED: {
            return {
                ...state,
                updatingUserLocDesg: false,
                updatingUserLocDesgFailed: true,
                updatingUserLocDesgError: action.payload,
                UserLocDesgUpdate: {},
            };
        }
        case ACTION_TYPES.GET_PROJECT_DATA_PENDING: {
            return {
                ...state,
                fetchingUserProjectData: true,
                fetchingUserProjectDataFailed: false,
                fetchingUserProjectDataError: undefined,
                UserProjectData: {},
            };
        }

        case ACTION_TYPES.GET_PROJECT_DATA_FULFILLED: {
            return {
                ...state,
                fetchingUserProjectData: false,
                fetchingUserProjectDataFailed: false,
                fetchingUserProjectDataError: undefined,
                UserProjectData: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_PROJECT_DATA_REJECTED: {
            return {
                ...state,
                fetchingUserProjectData: false,
                fetchingUserProjectDataFailed: true,
                fetchingUserProjectDataError: action.payload,
                UserProjectData: {},
            };
        }
        case ACTION_TYPES.GET_USER_DATA_PENDING: {
            return {
                ...state,
                fetchingUserData: true,
                fetchingUserDataFailed: false,
                fetchingUserDataError: undefined,
                UserData: {},
            };
        }

        case ACTION_TYPES.GET_USER_DATA_FULFILLED: {
            return {
                ...state,
                fetchingUserData: false,
                fetchingUserDataFailed: false,
                fetchingUserDataError: undefined,
                UserData: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_USER_DATA_REJECTED: {
            return {
                ...state,
                fetchingUserData: false,
                fetchingUserDataFailed: true,
                fetchingUserDataError: action.payload,
                UserData: {},
            };
        }
        default:
            return state;
    }
};
export default userprofile;
