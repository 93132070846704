import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { connect } from 'react-redux';
import SelectField from '../../../Components/SelectField';
import DateRangeFilter from './DatePicker';

//dependencies
import moment from 'moment';
import ReactHighcharts from 'react-highcharts';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from 'react-i18next';


//actions
import { fetchallEmployees } from '../../../services/projects/actions';
import { fetchEmployeeApplicationReport, fetchEmployeeWebsiteReport } from '../../../services/reports/actions';

//icons
import { CalendarIcon } from '@heroicons/react/outline'



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function convert(str) {
  var date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join('-');
}

let d = new Date();

let firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
let c = convert(firstDay);

let lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
let cc = convert(lastDay);

class Productivity_tab extends Component {

  constructor() {
    super();
    this.state = {
        activetab: 1,
        startDate: c,
        endDate: cc,
        graphData: [],
        showDateRange: false,
        userId: '',
    }
  }

  componentDidMount() {
    this.props.fetchallEmployees();
    this.empApplicationReport();
  }

  empApplicationReport = () => {
    this.props.fetchEmployeeApplicationReport(this.state.startDate, this.state.endDate, this.state.userId).then((data) => {
      if(data.value.status == 200){
        this.setState({graphData: data.value.data.data});
      } else {
        console.log("Error");
      }
    })
  }

  empWebSiteReport = () => {
    this.props.fetchEmployeeWebsiteReport(this.state.startDate, this.state.endDate, this.state.userId).then((data) => {
      if(data.value.status == 200){
        this.setState({graphData: data.value.data.data});
      } else {
        console.log("Error");
      }
    })
  }

  render() {

    const {t} = this.props;

    const allEmployees = this.props.allEmployees;
    let listofAllEmployees = allEmployees && allEmployees.AllEmployees;

    let Select_emp_dropdown = listofAllEmployees && listofAllEmployees.map((emp, index) => ({
      name: emp.user_first_name + " " + emp.user_last_name, 
      id: emp.user_id,
    }));
    Select_emp_dropdown = listofAllEmployees && [...Select_emp_dropdown]?.sort((a,b)=>a.name.localeCompare(b.name));

    const report = this.props.reports;
    const applicationReportLoading = report && report.isFetchingEmployeesApplicationReport;
    const websiteReportLoading = report && report.isFetchingEmployeeWebsiteReport;


    let arrayProductive = [];
    let arrayUnproductive = [];
    let arrayUndefined = [];
    let arrayDates = [];

    let prodArr =
        this.state.graphData == undefined || this.state.graphData == ""
            ? ""
            : this.state.graphData.map((data) => {
                  if (data == "") {
                      console.log("Empty Data");
                  } else {
                      arrayProductive.push(parseInt(data.productive / 60));
                  }
                  if (data == "") {
                      console.log("Empty Data");
                  } else {
                      arrayUnproductive.push(parseInt(data.unproductive / 60));
                  }
                  if (data == "") {
                      console.log("Empty Data");
                  } else {
                      // arrayUndefined.push(parseInt(data.undefined / 60))
                  }
                  if (data == "") {
                      console.log("error");
                  } else {
                      arrayDates.push(
                          new Date(data.date + "T05:12:59Z").toDateString().slice(4, 11)
                      );
                  }
              });

    const hourlyChart = {
        chart: {
            type: "column",
            height: 250,
            style: {
                fontFamily: "Lato",
                fontSize: "15px",
            },
        },
        title: {
            text: "",
        },
        plotOptions: {
            series: {
                cursor: "pointer",
            },
            column: {
                zones: [
                    {
                        value: 0,
                        color: "#684D94",
                    },
                    {
                        value: 0,
                        color: "#9279b9",
                    },
                    {
                        value: 0,
                        color: "#E6D6F3",
                    },
                ],
                pointPadding: 0.1,
                borderWidth: 0,
            },
        },
        xAxis: {
            categories: arrayDates,
            crosshair: true,
            title: {
              text: "Date",
          },
        },
        yAxis: [
            {
                //   labels: {
                //     format: '{value} %',
                //   },
                title: {
                    text: "Hours",
                },
                //   max: 100
            },
        ],
        tooltip: {
            valueSuffix: "Hr",
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                showInLegend: true,
                color: "#684D94",
                name: `${t("ReportsTab.productive")}`,
                data: arrayProductive,
            },
            {
                showInLegend: true,
                color: "#9279b9",
                name: `${t("ReportsTab.unproductive")}`,
                data: arrayUnproductive,
            },
            // {
            //   showInLegend: true,
            //   color: '#E6D6F3',
            //   name: `${t('ReportsTab.undefined')}`,
            //   data: arrayUndefined,
            // },
        ],
    };

    const handleSelectEmployee = (e) => {
      this.setState({[e.target.value] : e.target.value}, () => {
          if(e.target.value != ""){
            if(this.state.activetab == 1){
              this.props.fetchEmployeeApplicationReport(this.state.startDate, this.state.endDate, e.target.value).then((data) => {
                  this.setState({graphData: data.value.data.data, userId: e.target.value});
                  
              })
            } else {
              this.props.fetchEmployeeWebsiteReport(this.state.startDate, this.state.endDate, e.target.value).then((data) => {
                this.setState({graphData: data.value.data.data, userId: e.target.value});
              })
            }
          } else {
            this.setState({graphData: [], userId: ""});
      }});
    }
    
    const handeThisMonth = () => {
      this.setState({startDate: c, endDate: cc}, () => {
        if(this.state.activetab == 1){
          this.empApplicationReport()
        } else {
          this.empWebSiteReport();
        }
      })
    }

    const handleActiveTab = (tab) => {
      if(this.state.activetab == 1){
        this.empApplicationReport()
      } else {
        this.empWebSiteReport();
      }
    }

    const onChange = (ranges) => {
      let dd = convert(ranges.startDate);
      let rr = convert(ranges.endDate);
      this.setState({startDate: dd, endDate: rr})
      if(this.state.activetab == 1){
        this.empApplicationReport()
      } else {
        this.empWebSiteReport();
      }
    };

    return (
        <div>
            <div className="flex justify-between items-start mb-8 lg:items-center flex-col lg:flex-row mt-5">
                <div className="flex rounded-full justify-center -translate-y-2 items-center mt-5 bg-white">
                    <div
                        className={`transition ease-in-out delay-110  ${
                            this.state.activetab == 1
                                ? "bg-primary text-white"
                                : "bg-white text-gray-500"
                        } font-medium p-2 px-4 rounded-full cursor-pointer`}
                        onClick={() => {
                            this.setState({ activetab: 1 }, () => handleActiveTab());
                        }}>
                        {t("ReportsTab.applications")}
                    </div>
                    <div
                        className={`transition ease-in-out delay-110  ${
                            this.state.activetab == 2
                                ? "bg-primary text-white"
                                : "bg-white text-gray-500"
                        } font-medium p-2 px-4 w-full rounded-full cursor-pointer`}
                        onClick={() => {
                            this.setState({ activetab: 2 }, () => handleActiveTab());
                        }}>
                        {t("ReportsTab.webSites")}
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row">
                    <button
                        className="bg-secondary rounded-full p-2 px-5 w-32 mb-3 sm:mb-0 mt-4 sm:mt-0 sm:w-auto text-white font-medium mr-3"
                        onClick={() => {
                            handeThisMonth();
                        }}>
                        {t("ReportsTab.this")} {t("ReportsTab.month")}
                    </button>
                    <div className="gap-2 px-3 py-2 bg-white flex items-center rounded-full justify-start cursor-pointer">
                    <CalendarIcon
                    className="h-6 w-6"
                   onClick={() => {
                    this.setState({ showDateRange: true });
                  }}
                 />
                <input
                value={`${new Date(
                  this.state.startDate
                ).toDateString()} - ${new Date(
                  this.state.endDate
                ).toDateString()}`}
                disabled
                className="w-[260px] bg-white rounded-full font-medium text-gray-600"
                onClick={() => {
                  this.setState({ showDateRange: true });
                }}
              />
             
            </div>
                </div>
            </div>

            <div className="mt-5 bg-white shadow-md rounded-md">
                <div className="flex justify-between items-center pt-4 my-4 mx-3">
                    <div className="w-52">
                        <SelectField
                            FieldName={t("select") + " " + t("employee")}
                            onSelectChange={(e) => {
                                handleSelectEmployee(e);
                            }}
                            name={"Select Employee"}
                            arrayOfData={Select_emp_dropdown}
                            id={"name"}></SelectField>
                    </div>
                </div>
                {this.state.userId == "" ? (
                    <div className="flex justify-center items-center bg-white rounded-lg mt-3 shadow-md h-64">
                        <div className="flex justify-center items-center flex-col">
                            <img
                                src={require("../../../images/nodata.png")}
                                className="w-16 h-16 mb-2"
                                alt=''
                            />
                            <p className="text-primary font-semibold text-md">
                                {t("ReportsTab.chooseEmployeeToViewData")}
                            </p>
                        </div>
                    </div>
                ) : (
                    <div>
                        {applicationReportLoading ? (
                            <div className="text-center h-5 w-full flex justify-center items-center">
                                <BeatLoader
                                    color={"#684994"}
                                    applicationReportLoading={applicationReportLoading}
                                    size={15}
                                    margin={2}
                                />{" "}
                            </div>
                        ) : (
                            <div className="h-5"></div>
                        )}
                        {websiteReportLoading ? (
                            <div className="text-center h-5 w-full flex justify-center items-center">
                                <BeatLoader
                                    color={"#684994"}
                                    websiteReportLoading={websiteReportLoading}
                                    size={15}
                                    margin={2}
                                />{" "}
                            </div>
                        ) : (
                            <div className="h-5"></div>
                        )}
                        <div className="mt-4">
                            <ReactHighcharts config={hourlyChart} />
                        </div>
                    </div>
                )}
            </div>

            <Transition.Root show={this.state.showDateRange} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto"
                    onClose={() => {
                        this.setState({ showDateRange: false });
                    }}>
                    <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[600px] sm:w-full sm:p-6">
                                <DateRangeFilter onChange={onChange} />
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allEmployees: state.projects,
  reports: state.report,
})

const mapDispatchToProps = (dispatch) => ({
  fetchallEmployees: () => dispatch(fetchallEmployees()),
  fetchEmployeeApplicationReport: (startDate, endDate, userId) => dispatch(fetchEmployeeApplicationReport(startDate, endDate, userId)),
  fetchEmployeeWebsiteReport: (startDate, endDate, userId) => dispatch(fetchEmployeeWebsiteReport(startDate, endDate, userId)),
})

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Productivity_tab));
