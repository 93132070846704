import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { connect } from 'react-redux';
import InputField from '../../Components/InputField';
import { fetchApiKeys, fetchOrgUuid, fetchSecretKeys, postSecretKey, deleteSecret, patchStatusUpdate} from '../../services/settings/action'

import moment from 'moment';
import { toast } from 'react-toastify';

import {EyeIcon, EyeOffIcon, ClipboardCopyIcon, TrashIcon} from '@heroicons/react/outline'
import ConfirmationDialogue from "../../Components/ConfirmationDialogue";
import { withTranslation } from 'react-i18next';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


class IntegrationsTab extends Component{
    constructor() {
        super();
        this.state = {
            org_uuid: "",
            secretKey: "",
            keyName: "",
            tableIdx: 0,
            apiKeyType: "",
            apiKeyStatus: true,
            selectedAPIkey_id: 0,
            showToolTip: false,
            showSecretKey: false,
            showDeleteKey: false,
            showDeleteModal: false,
            showDeactivateApiKey: false,
        }
    }

    componentDidMount() {
        let org_auth_state = localStorage.getItem('s7_auth_state');
        let org_name = org_auth_state == "" || org_auth_state == undefined ? "" : JSON.parse(org_auth_state).org
        let org_uuid = localStorage.getItem('org_uuid');

        this.setState({org_uuid: org_uuid});

        this.props.fetchOrgUuid(org_name);
        this.props.fetchApiKeys(org_uuid);
        this.props.fetchSecretKeys(org_uuid, "API")
    }


    render() {
        const {t} = this.props;
        let setting = this.props.settings;

        let apiKeyList = setting && setting.apiKeys;

        const getSecretKey = (idx, apiName) => {
            this.setState({tableIdx: idx})
            this.props.fetchSecretKeys(this.state.org_uuid, apiName).then((data) => {
                if(data.value.status == 200){
                    this.setState({secretKey: data.value.data.secretkey})
                } else {
                    toast("Something went wrong");
                }
            })
        }

        const copyText = (apiName) => {
            getSecretKey('', apiName);
            if(this.state.secretKey != ""){
                navigator.clipboard.writeText(this.state.secretKey);
                toast("Copied to clipboard");
            }
        }

        const generate_secret_key = () => {
            if(this.state.keyName == ""){
                toast("Please enter a name for the key");
                return;
            } else {
                const dataa = {
                    names: this.state.keyName,
                    org_uuid: this.state.org_uuid
                }

                this.props.postSecretKey(dataa).then((data) => {
                    if(data.value.status == 200){
                        toast("Key generated");
                        this.props.fetchApiKeys(this.state.org_uuid);
                    } else {
                        toast("Something went wrong");
                    }
                })
            }
        }

        const showDeleteModall = (api_id) => {
            this.setState({
                selectedAPIkey_id: api_id,
                showDeleteModal: true
            })
        }

        const deleteApiKey = () => {
            const dataa = {
                org_uuid : this.state.org_uuid,
                id: this.state.selectedAPIkey_id
            }
            this.props.deleteSecret(dataa).then((data) => {
                if(data.value.status == 200){
                    toast("Key Deleted");
                    this.props.fetchApiKeys(this.state.org_uuid);
                    this.setState({showDeleteModal: false})
                } else {
                    toast("Something went wrong");
                }
            })
        }

        const showAPIkeyStatusModal = (api_id, type, status) => {
            this.setState({
                selectedAPIkey_id: api_id,
                apiKeyType: type,
                apiKeyStatus: status,
                showDeactivateApiKey: true
            })
        }

        const updateAPIKey_status = () => {
            if(this.state.apiKeyType == 'deactivate'){
                const dataa = {
                    id: this.state.selectedAPIkey_id,
                    org_uuid: this.state.org_uuid,
                    status: false
                }

                this.props.patchStatusUpdate(dataa).then((data) => {
                    if(data.value.status == 200){
                        toast("Key Deactivated");
                        this.props.fetchApiKeys(this.state.org_uuid);
                        this.setState({showDeactivateApiKey: false})
                    }
                })
            } else {
                const dataa = {
                    id: this.state.selectedAPIkey_id,
                    org_uuid: this.state.org_uuid,
                    status: true
                }
                this.props.patchStatusUpdate(dataa).then((data) => {
                    if(data.value.status == 200){
                        toast("Key Activated");
                        this.props.fetchApiKeys(this.state.org_uuid);
                        this.setState({showDeactivateApiKey: false})
                    }
                })
            }
        }
        
        
        return (
            <div className="bg-white rounded-md shadow-md mt-4 p-5">
                <div>
                    <p className="font-semibold text-lg">{t("settingsTab.apiKeys")}</p>
                </div>
                <div className="h-1 w-full bg-gray-200 rounded-full my-6 mb-8"></div>
                <div className="flex justify-between items-center">
                    <div className="w-3/4">
                        <InputField
                            FieldName={t("settingsTab.key") + " " + t("name")}
                            type="text"
                            value={this.state.keyName}
                            onInputChange={(e) => {
                                this.setState({ keyName: e.target.value });
                            }}
                        />
                    </div>
                    <button
                        className="bg-primary text-white font-medium p-1 px-3 rounded-md shadow-sm"
                        onClick={() => {
                            generate_secret_key();
                        }}>
                        {t("settingsTab.create")}
                    </button>
                </div>

                <div className="bg-white px-2 py-5 mt-4 border-b border-gray-200 sm:px-6 rounded-lg">
                    <div
                        className="mt-2 flex flex-col overflow-y-auto bg-gray-100"
                        style={{ maxHeight: "auto" }}>
                        <div className="inline-block min-w-full align-middle">
                            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                <table
                                    className="min-w-full border-separate"
                                    style={{ borderSpacing: 0 }}>
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12">
                                                S.No
                                            </th>
                                            <th
                                                scope="col"
                                                className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                {t("status")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                                                {t("name")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                {t("access")} {t("settingsTab.key")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                {t("settingsTab.secret")} {t("settingsTab.key")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                {t("date")} {t("and")} {t("time")} {t("of")}{" "}
                                                {t("settingsTab.creation")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12">
                                                {t("action")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {apiKeyList == undefined || apiKeyList.length == 0 ? (
                                            <tr>
                                                <td className="p-2">
                                                    {t("no")} {t("settingsTab.keys")}{" "}
                                                    {t("timeTracker.found")}
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        ) : (
                                            apiKeyList.map((key, idx) => (
                                                <tr key={idx}>
                                                    <td
                                                        className={classNames(
                                                            idx !== apiKeyList.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                                        )}>
                                                        {idx + 1}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            idx !== apiKeyList.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                                                        )}>
                                                        {key.status == true
                                                            ? t("active")
                                                            : t("inactive")}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            idx !== apiKeyList.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                                                        )}>
                                                        {key.name}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            idx !== apiKeyList.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                                        )}>
                                                        <p
                                                            className=""
                                                            onMouseEnter={() => {
                                                                this.setState({
                                                                    showToolTip: true,
                                                                });
                                                            }}
                                                            onMouseLeave={() => {
                                                                this.setState({
                                                                    showToolTip: false,
                                                                });
                                                            }}>
                                                            {key.accesskey.slice(0, 12)}...
                                                        </p>
                                                        {/* <p className={`absolute ${this.state.showToolTip == true ? 'block' : 'hidden'} z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 dark:bg-gray-700`}>{key.accesskey}</p> */}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            idx !== apiKeyList.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                                        )}>
                                                        <p className="flex">
                                                            {this.state.showSecretKey ? (
                                                                <p>
                                                                    {this.state.tableIdx == idx
                                                                        ? `${
                                                                              this.state.secretKey.slice(
                                                                                  0,
                                                                                  12
                                                                              ) + "...."
                                                                          }`
                                                                        : "**********"}
                                                                </p>
                                                            ) : (
                                                                "**********"
                                                            )}
                                                            {this.state.showSecretKey ? (
                                                                <EyeOffIcon
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            showSecretKey: false,
                                                                        });
                                                                    }}
                                                                    className="h-4 w-4 ml-1"
                                                                />
                                                            ) : (
                                                                <EyeIcon
                                                                    onClick={() => {
                                                                        this.setState(
                                                                            { showSecretKey: true },
                                                                            () => {
                                                                                getSecretKey(
                                                                                    idx,
                                                                                    key.name
                                                                                );
                                                                            }
                                                                        );
                                                                    }}
                                                                    className="h-4 w-4 ml-1"
                                                                />
                                                            )}
                                                            <ClipboardCopyIcon
                                                                onClick={() => {
                                                                    copyText(key.name);
                                                                }}
                                                                className="h-4 w-4 ml-1"
                                                            />
                                                        </p>
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            idx !== apiKeyList.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                                        )}>
                                                        {moment(key.created).format("llll")}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            idx !== apiKeyList.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-12"
                                                        )}>
                                                        <span className="flex items-center">
                                                            {key.status == true ? (
                                                                <p
                                                                    className="rounded-md shadow-sm p-1 px-3 bg-primary text-white mr-4 cursor-pointer"
                                                                    onClick={() => {
                                                                        showAPIkeyStatusModal(
                                                                            key.id,
                                                                            "deactivate",
                                                                            false
                                                                        );
                                                                    }}>
                                                                    {t("settingsTab.deactivate")}
                                                                </p>
                                                            ) : (
                                                                <p
                                                                    className="rounded-md shadow-sm p-1 px-3 bg-secondary text-white mr-4 cursor-pointer"
                                                                    onClick={() => {
                                                                        showAPIkeyStatusModal(
                                                                            key.id,
                                                                            "activate",
                                                                            true
                                                                        );
                                                                    }}>
                                                                    {t("settingsTab.activate")}
                                                                </p>
                                                            )}
                                                            <p
                                                                onClick={() => {
                                                                    showDeleteModall(key.id);
                                                                }}
                                                                className="cursor-pointer">
                                                                <TrashIcon className="h-5 w-5 text-red-500" />
                                                            </p>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showDeleteModal ? (
                    <ConfirmationDialogue
                        open={this.state.showDeleteModal}
                        onCancelClick={() => this.setState({ showDeleteModal: false })}
                        onSubmitClick={() => deleteApiKey()}
                        title={t("settingsTab.deleteApiKey")}
                        info={t("settingsTab.confirmDeleteMessage")}
                        submitButtonName={"Delete"}
                        cancelButtonName={"Cancel"}></ConfirmationDialogue>
                ) : null}

                {this.state.showDeactivateApiKey ? (
                    <ConfirmationDialogue
                        open={this.state.showDeactivateApiKey}
                        onCancelClick={() => this.setState({ showDeactivateApiKey: false })}
                        onSubmitClick={() => updateAPIKey_status()}
                        title={`${
                            this.state.apiKeyType == "activate"
                                ? t("settingsTab.activate")
                                : t("settingsTab.deactivate")
                        }`}
                        info={`${
                            this.state.apiKeyType == "activate"
                                ? t("settingsTab.activateConfirmMessage")
                                : t("settingsTab.deactivateConfirmMessage")
                        }`}
                        submitButtonName={`${
                            this.state.apiKeyType == "activate" ? "Activate" : "De-Activate"
                        }`}
                        cancelButtonName={"Cancel"}></ConfirmationDialogue>
                ) : null}
            </div>
        );


    }
}

const mapStateToProps = (state) => ({
    settings: state.settings

});

const mapDispatchToProps = (dispatch) => ({
    fetchApiKeys:(org_uuid) =>dispatch(fetchApiKeys(org_uuid)),
    fetchOrgUuid:(org_name) =>dispatch(fetchOrgUuid(org_name)),
    fetchSecretKeys:(org_uuid, apiName) =>dispatch(fetchSecretKeys(org_uuid, apiName)),
    postSecretKey:(data) =>dispatch(postSecretKey(data)),
    deleteSecret:(data) =>dispatch(deleteSecret(data)),
    patchStatusUpdate:(data) =>dispatch(patchStatusUpdate(data)),
});

IntegrationsTab.propTypes = {

};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(IntegrationsTab));


// getOrgUuid - finished
// getKeys - finished
// postSecretKey
// getSecretKey
// delete_key
// status_update
