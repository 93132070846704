
import React from 'react';
import { Link } from "react-router-dom";
import Layout from '../../../Components/Layout';

import userProfileImage from '../../../assets/user_profile.png';
import { useState } from 'react';
import InputField from '../../../Components/InputField';


function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const EditUserProfilePage = () => {
    const [selectTab, setSelectTab] = useState("PayRoll");
    const [viewDetails, setViewDetails] = useState(false);
    const [activeButton, setActiveButton] = useState("Individual")


    const handleSelectTab = (tabName) => {
        setSelectTab(tabName)
    }

    const handleActiveButton = (buttonName) => {
        setActiveButton(buttonName)
    }



    return (
        <Layout>
            <div className="bg-white p-6 rounded-md shadow-md mt-3 font-normal leading-normal text-lg">
                <nav className="rounded-md w-full">
                    <ol className="list-reset flex">
                        <li>
                            <Link
                                to="/team/client/client_bill"
                                className="text-primary hover:text-primary"
                            >
                                Users
                            </Link>
                        </li>
                        <li>
                            <span className="text-textprimary mx-2">/</span>
                        </li>
                        <li className="text-textprimary">Manage Employee</li>
                    </ol>
                </nav>
            </div>

            <div className="bg-white p-6 rounded-md shadow-md mt-4 flex items-center">
                <div>
                    <img className='w-100 h-100 mr-4' src={userProfileImage} alt="User_Profile" />
                </div>
                <div className='text-[#1D1D1D] text-lg font-bold uppercase leading-normal'>
                    SAIFUL AMEEN
                    <p className="text-primary hover:text-primary lowercase text-xs">Saiful@s7works.io</p>

                </div>
            </div>

            <div className="mt-4 p-4 w-full bg-white rounded-md shadow-md">
                <div className="hidden sm:block pt-4 xl:pt-6">

                    <div className="-mb-px flex space-x-8" aria-label="Tabs">
                        <div
                            key="Basic Info"
                            onClick={() => { handleSelectTab("Basic-Info"); }}
                            className={classNames(
                                selectTab === "overview"
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-md cursor-pointer"
                            )}
                            aria-current={
                                selectTab === "Basic Info" ? "page" : undefined
                            }>
                            Overview
                        </div>

                        <div
                            key="Projects"
                            onClick={() => { handleSelectTab("Projects"); }}
                            className={classNames(
                                selectTab === "Projects"
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-md cursor-pointer"
                            )}
                            aria-current={
                                selectTab === "Projects" ? "page" : undefined
                            }>
                            Projects
                        </div>
                        <div
                            key="PayRoll"
                            onClick={() => { handleSelectTab("PayRoll"); }}
                            className={classNames(
                                selectTab === "Payroll"
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-md cursor-pointer"
                            )}
                            aria-current={
                                selectTab === "Payroll" ? "page" : undefined
                            }>
                            PayRoll
                        </div>

                    </div>


                </div>

                {selectTab === "PayRoll" && (
                    <div className="w-full bg-white mt-4 px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="-ml-4 -mt-2 flex items-center lg-m:items-stretch lg-m:flex-col justify-between flex-wrap sm:flex-nowrap">

                            <div className="ml-4 mt=6 flex flex-row">

                                <div onClick={() => { handleActiveButton("Individual"); }}
                                    className={`px-4 py-4 cursor-pointer leading-normal font-medium text-sm rounded-l-md
                                    ${activeButton === "Individual" ? "bg-primary text-[#F1F1F1]" : "bg-[#F1F1F1] text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                        }`}
                                    aria-current={selectTab === "Individual" ? "page" : undefined}


                                >
                                    Individual
                                </div>

                                <div onClick={() => { handleActiveButton("Projects"); }}
                                    className={`px-4 py-4 cursor-pointer leading-normal font-medium text-sm rounded-md
                                    ${activeButton === "Projects" ? "bg-primary text-[#F1F1F1]" : "bg-[#F1F1F1] text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                        }`}
                                    aria-current={selectTab === "Projects" ? "page" : undefined}>
                                    Projects
                                </div>

                                <div onClick={() => { handleActiveButton("Teams"); }}
                                    className={`px-4 py-4 cursor-pointer leading-normal font-medium text-sm rounded-r-md
                                    ${activeButton === "Teams" ? "bg-primary text-[#F1F1F1]" : "bg-[#F1F1F1] text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                        }`}
                                    aria-current={selectTab === "Teams" ? "page" : undefined}>
                                    Teams
                                </div>
                            </div>


                            <div className="flex relative mt-4 cursor-pointer">

                            </div>
                        </div>


                        {activeButton === "Individual" && (

                            <div className="w-full bg-white mt-4 px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                                <div className="-ml-4 -mt-2 flex flex-row lg-m:items-stretch lg-m:flex-col justify-between flex-wrap sm:flex-nowrap">
                                    <div className="lg:grid lg:grid-cols-5 lg:gap-y-4 lg:gap-x-8 my-8">
                                        <div className="col-span-3">
                                            <div className="w-full mt-6">
                                                <InputField
                                                    value=""
                                                    FieldName="Per hour"
                                                    onInputChange={() => { }}
                                                    type="text"
                                                    placeholder=""
                                                />
                                            </div>
                                            <div className="w-full mt-6">
                                                <InputField
                                                    value=""
                                                    FieldName="Monthly CTC"
                                                    onInputChange={() => { }}
                                                    type="text"
                                                    placeholder="₹ 0"
                                                />
                                            </div>
                                            <div className="w-full mt-6">
                                                <InputField
                                                    value=""
                                                    FieldName="PAN Details"
                                                    onInputChange={() => { }}
                                                    type="text"
                                                />
                                            </div>
                                        </div>

                                        <div className='text-[#1D1D1D] text-lg font-bold uppercase leading-normal'>
                                            EARNING
                                        </div>

                                        <div className="col-span-3 mt-4">
                                            <div className="w-full mt-6">
                                                <InputField
                                                    value=""
                                                    placeholder="₹ 0"
                                                    FieldName="Basic Salary"
                                                    onInputChange={() => { }}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="w-full mt-6">
                                                <InputField
                                                    value=""
                                                    placeholder="₹ 0"
                                                    FieldName="House Rent Allowance"
                                                    onInputChange={() => { }}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="w-full mt-6">
                                                <InputField
                                                    value=""
                                                    placeholder="₹ 0"
                                                    FieldName="Special Allowance"
                                                    onInputChange={() => { }}
                                                    type="text"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-span-2 mt-4">
                                            <div className="w-full mt-6">
                                                <InputField
                                                    value=""
                                                    placeholder="₹ 0"
                                                    FieldName="Travel Allowance"
                                                    onInputChange={() => { }}
                                                    type="text"
                                                />
                                            </div>
                                        </div>

                                        <div className='text-[#1D1D1D] text-lg font-bold uppercase leading-normal'>
                                            Bank Details
                                        </div>

                                        <div className="col-span-3 mt-4">
                                            <div className="w-full mt-6">
                                                <InputField
                                                    value=""
                                                    placeholder=""
                                                    FieldName="Account Number"
                                                    onInputChange={() => { }}
                                                    type="number"
                                                />
                                            </div>
                                            <div className="w-full mt-6">
                                                <InputField
                                                    value=""
                                                    placeholder=""
                                                    FieldName="IIFSC Code"
                                                    onInputChange={() => { }}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="w-full mt-6">
                                                <InputField
                                                    value=""
                                                    placeholder=""
                                                    FieldName="Bank Name"
                                                    onInputChange={() => { }}
                                                    type="text"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-span-2 mt-4">
                                            <div className="w-full mt-6">
                                                <InputField
                                                    value=""
                                                    placeholder=""
                                                    FieldName="Name of Account Holder"
                                                    onInputChange={() => { }}
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                          
                        )}

                        {activeButton === "Projects" && (
                            <div>
                                Projects content
                            </div>
                        )}

                        {activeButton === "Teams" && (
                            <div>
                                Teams content
                            </div>
                        )}





                    </div>
                )}

                {selectTab === "Basic-Info" && (
                    <div>
                        Hello
                    </div>
                )}

                {selectTab === "Projects" && (
                    <div>
                        Hello 2
                    </div>
                )}



            </div>
        </Layout>
    )
}

export default EditUserProfilePage;