import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import _, { isArray } from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import PrimaryButton from "../../Components/PrimaryButton";
import Layout from "../../Components/Layout";
import SelectField from "../../Components/SelectField";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import {
    managerDashboard,
    teamsDashboard,
    notworkingEmployees,
    managerdashboard_observer,
    getRolesCheck,
    getEmpListObserver,
    managerDashboardObserver,
    getShowcaseUsersList,
    getDashboardLayout,
    postDashboardLayout,
    updateDashboardLayout,
    getDashboardArrangement,
    updateDashboardArrangement,
    postDashboardArrangement,
    getAllProjectTableLayout,
    updateAllProjectTableLayout,
    postAllProjectTableLayout,
    getEmployeeMoodTableData,
    getEmployeeMoodTableLayout,
    updateEmployeeMoodTableLayout,
    postEmployeeMoodTableLayout,
    getEmployeeMoodTableArrangement,
    updateEmployeeMoodTableArrangement,
    postEmployeeMoodTableArrangement,
    getTeamTableArrangement,
    getTeamTableLayout,
    getEmployeeTableLayout,
    getEmployeeTableArrangement,
    updateEmployeeTableArrangement,
    updateEmployeeTableLayout,
    updateTeamTableArrangement,
    updateTeamTableLayout,
    postEmployeeTableArrangement,
    postEmployeeTableLayout,
    postTeamTableArrangement,
    postTeamTableLayout,
    getAllProjectTableArrangement,
    updateAllProjectTableArrangement,
    postAllProjectTableArrangement,
    getMoodGraphData,
    getAllProjectTableData,
    getFeatureListing,
    getUserProjectChange,
    getCurrentStatus,
    getEmployeeData,
} from "../../services/dashboard/actions";
import {
    fetchEmployeeAttendance,
    fetchEmployeeWebsiteReportDashboard,
} from "../../services/reports/actions";
import { getShowcaseEntries } from "../../services/timetracker/actions";
import { storeToLocalStorage, showData } from "../../services/globalState/actions";
import { fetchallEmployees } from "../../services/projects/actions";
import { CalendarIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import ReactHighcharts from "react-highcharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/global.css";
import BeatLoader from "react-spinners/BeatLoader";
import InputField from "../../Components/InputField";

import { Dialog, Transition } from "@headlessui/react";
import {
    EyeIcon,
    EyeOffIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    PencilIcon,
} from "@heroicons/react/solid";
import { toast } from "react-toastify";
import { DocumentSearchIcon } from "@heroicons/react/solid";
import ModalShowCaseEntry from "../TimeTracker/modalShowCaseEntry";
import hideObsPng from "../../assets/hideObserver.png";

import { withTranslation } from "react-i18next";

import { BsGearFill } from "react-icons/bs";
import LayoutSetupModal from "./Modals/LayoutSetupModal";
import { CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const userRole = localStorage.getItem("ro");

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
const emojiList = [
    <span className="text-xl">&#128545;</span>,
    <span className="text-xl">&#128532;</span>,
    <span className="text-xl"> &#128528;</span>,
    <span className="text-xl"> &#128578;</span>,
    <span className="text-xl">&#128512;</span>,
];
class Dashboard extends Component {
    constructor() {
        moment.suppressDeprecationWarnings = true;
        super();
        this.state = {
            isSort:false,
            showAscendingbuttonname:true,
            showDescendingbuttonname:true,
            showAscendingbuttontimelogged:true,
            showDescendingbuttontimelogged:true,
            showAscendingbuttonproject:true,
            showDescendingbuttonproject:true,
            showAscendingbuttoneteam:true,
            showDescendingbuttoneteam:true,
            sortedEmployee:[],
            isSort1:false,
            showAscendingbuttonpname:true,
            showDescendingbuttonpname:true,
            showDescendingbuttonhspent:true,
            showAscendingbuttonhspent:true,
            showDescendingbuttonbhours:true,
            showAscendingbuttonbhours:true,
            showDescendingbuttonhrem:true,
            showAscendingbuttonhrem:true,
            showDescendingbuttonhrolled:true,
            showAscendingbuttonhrolled:true,
            sortedProject:[],
            isSort2:false,
            showAscendingbuttontname:true,
            showDescendingbuttontname:true,
            showAscendingbuttontworked:true,
            showDescendingbuttontworked:true,
            showAscendingbuttonpeople:true,
            showDescendingbuttonpeople:true,
            sortedTeam:[],
            today: new Date(),
            selectedDate: new Date(),
            selectedTimezone: "",
            graphData: [],
            selectedEmployee: "",
            productivitygraphloading: true,
            showNewLogin: false,
            showCreatePassword: false,
            obscurePassword: true,
            obscureConfirmPassword: true,
            editButtonClicked: { id: "", value: false },

            newPassword: "",
            confirmNewPassword: "",
            arrayProductive: [],
            startDate: new Date(),
            endDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
            roleUser: localStorage.getItem("roleUser"),
            dashboardData: {},
            observerData: false,
            userRoleList: [],
            isShowcaseModalActive: false,
            selectedUser: "",
            SelectedTab: "Productivity",
            showcaseEntriesList: [],
            showObserverData: false,
            isShowSettingsModal: false,
            modalData: "",
            modalArrangement: "",
            modalHeading: "",
            dashboardLayout: "",
            onSubmitClickHandler: "",
            projectTableLayout: "",
            teamsTableLayout: "",
            employeeWorkingTableLayout: "",
            employeeMoodTableLayout: "",
            dashboardArrangement: "",
            employeeMoodArrangement: "",
            employeeWorkingTableArrangement: "",
            teamsTableArrangement: "",
            projectTableArrangement: "",
            empWorkingCols: 0,
            allProjectsCols: 0,
            teamsCols: 0,
            moodsTableCols: 0,
            selectedEmployeeToExpand: {},
            employeeProjectDetail: "",
            newTaskName: "",
            changedName: "",
        };
    }

    componentDidMount() {
        let allemployeesworkingdata =
            (this.props.dashboard &&
                localStorage.getItem("roleUser") === "admin" &&
                this.props.dashboard.ManagersDashboard &&
                this.props.dashboard.ManagersDashboard.all_emp_working) ||
            (this.props.dashboard &&
                localStorage.getItem("roleUser") !== "admin" &&
                this.props.dashboard.ManagersDashboardObserver &&
                this.props.dashboard.ManagersDashboardObserver.all_emp_working);

        if (allemployeesworkingdata) {
            const newTaskNames = allemployeesworkingdata.map((el) => el.task_list.toString());

            // Use newTaskNames or update the state as needed
            this.setState({ newTaskNames });
        }

        const query = new URLSearchParams(this.props.location.search);
        const date = query.get("date");
        const timezone = query.get("timezone");
        this.props.getRolesCheck().then((res) => {
            this.setState({ userRoleList: res.value.data });
        });
        this.props.getFeatureListing();
        // this.props.getEmpListObserver();
        setTimeout(() => {
            this.props
                .getDashboardLayout()
                .then((res) => {
                    this.setState({ dashboardLayout: res.value.data.data });
                })
                .then(() =>
                    this.props.getDashboardArrangement().then((res) => {
                        this.setState({ dashboardArrangement: res.value.data.data });
                    })
                )
                .then(() =>
                    this.props.getTeamTableLayout().then((res) => {
                        var obj = res.value.data.data;
                        var num = Object.keys(obj).reduce(
                            (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
                            0
                        );
                        this.setState({ teamsTableLayout: obj, teamsCols: num });
                    })
                )
                .then(() =>
                    this.props.getTeamTableArrangement().then((res) => {
                        this.setState({ teamsTableArrangement: res.value.data.data });
                    })
                )
                .then(() =>
                    this.props.getEmployeeTableLayout().then((res) => {
                        var obj = res.value.data.data;
                        var num = Object.keys(obj).reduce(
                            (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
                            0
                        );
                        this.setState({ employeeWorkingTableLayout: obj, empWorkingCols: num });
                    })
                )
                .then(() =>
                    this.props.getEmployeeTableArrangement().then((res) => {
                        this.setState({ employeeWorkingTableArrangement: res.value.data.data });
                    })
                )
                .then(() =>
                    this.props.getEmployeeMoodTableLayout().then((res) => {
                        var obj = res.value.data.data;
                        var num = Object.keys(obj).reduce(
                            (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
                            0
                        );
                        this.setState({
                            employeeMoodTableLayout: obj,
                            moodsTableCols: num,
                        });
                    })
                )
                .then(() =>
                    this.props.getEmployeeMoodTableArrangement().then((res) => {
                        this.setState({ employeeMoodArrangement: res.value.data.data });
                    })
                )
                .then(() =>
                    this.props.getAllProjectTableLayout().then((res) => {
                        var obj = res.value.data.data;
                        delete obj["hours_per_month"];
                        var num = Object.keys(obj).reduce(
                            (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
                            0
                        );
                        this.setState({ projectTableLayout: obj, allProjectsCols: num });
                    })
                )
                .then(() =>
                    this.props.getAllProjectTableArrangement().then((res) => {
                        this.setState({ projectTableArrangement: res.value.data.data });
                    })
                )
                .then(() => {
                    this.setState({
                        selectedDate: !date ? new Date() : new Date(date),
                        selectedTimezone:
                            timezone === undefined || timezone === ""
                                ? timezone === null
                                    ? ""
                                    : timezone
                                : this.state.selectedTimezone,
                    });
                    window.history.pushState(
                        {},
                        null,
                        `/dashboard/?date=${moment(this.state.selectedDate).format(
                            "YYYY-MM-DD"
                        )}&timezone=${this.state.selectedTimezone}`
                    );
                })
                .then(() => {
                    this.checkNewLogin();
                })
                .then(() => {
                    if (localStorage.getItem("roleUser") === "admin") {
                        this.props.managerDashboard(
                            moment(this.state.selectedDate).format("YYYY-MM-DD")
                        );
                        const time = moment(
                            moment(new Date()).format("YYYY-MM-DD") ===
                                moment(this.state.selectedDate).format("YYYY-MM-DD")
                                ? new Date()
                                : new Date(this.state.selectedDate)
                        ).subtract(20, "minutes");
                        this.props.getCurrentStatus(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            time.hours() +
                            ":" +
                            (time.minutes() - (time.minutes() % 10) === 0
                                ? "00"
                                : time.minutes() - (time.minutes() % 10)) +
                            ":00"
                        );
                        this.props.getEmployeeData(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            time.hours() +
                            ":" +
                            (time.minutes() - (time.minutes() % 10) === 0
                                ? "00"
                                : time.minutes() - (time.minutes() % 10)) +
                            ":00"
                        );
                    } else if (localStorage.getItem("roleUser") === "observer") {
                        this.props.managerdashboard_observer(
                            moment(this.state.selectedDate).format("YYYY-MM-DD")
                        );
                    }
                    this.props.teamsDashboard(moment(this.state.selectedDate).format("YYYY-MM-DD"));
                    this.props.getEmployeeMoodTableData(
                        moment(this.state.selectedDate).format("YYYY-MM-DD")
                    );
                    this.props.getAllProjectTableData(
                        moment(this.state.selectedDate).format("YYYY-MM-DD")
                    );
                    this.props.notworkingEmployees(
                        moment(this.state.selectedDate).format("YYYY-MM-DD")
                    );
                    this.props.fetchallEmployees();
                    let presentmonth = moment(new Date()).format("M");
                    let presentyear = moment(new Date()).format("YYYY");
                    let user_id = "";
                    this.props.fetchEmployeeAttendance(presentyear, presentmonth, user_id);

                    let startdate = new Date(
                        this.state.selectedDate.getFullYear(),
                        this.state.selectedDate.getMonth(),
                        this.state.selectedDate.getDate() - this.state.selectedDate.getDay()
                    );
                    let endDate = new Date(startdate.getTime() + 6 * 24 * 60 * 60 * 1000);
                    let update = false;
                    let dataGraph;
                    if (
                        localStorage.getItem("graphData") &&
                        localStorage.getItem("graphTime") &&
                        moment(this.state.selectedDate).isSameOrBefore(
                            moment(localStorage.getItem("graphTime")).add(15, "minutes")
                        ) &&
                        moment(this.state.selectedDate).format("YYYY-MM-DD") ===
                        moment(localStorage.getItem("graphTime")).format("YYYY-MM-DD")
                    ) {
                        dataGraph = _.orderBy(
                            JSON.parse(localStorage.getItem("graphData")),
                            "date",
                            "asc"
                        );
                        if (dataGraph.length === 0 || dataGraph[0].unproductive !== undefined) {
                            update = true;
                        }
                    } else {
                        update = true;
                    }
                    if (!update) {
                        this.setState({
                            graphData: dataGraph,
                            productivitygraphloading: false,
                            startDate: startdate,
                            endDate: endDate,
                        });
                    } else {
                        this.props
                            .fetchEmployeeWebsiteReportDashboard(
                                moment(startdate).format("YYYY-MM-DD"),
                                moment(endDate).format("YYYY-MM-DD")
                            )
                            .then((data) => {
                                this.setState({
                                    graphData: _.orderBy(data.value.data.data, "date", "asc"),
                                    productivitygraphloading: false,
                                    startDate: startdate,
                                    endDate: endDate,
                                });
                                localStorage.setItem(
                                    "graphData",
                                    JSON.stringify(_.orderBy(data.value.data.data, "date", "asc"))
                                );
                                localStorage.setItem("graphTime", this.state.selectedDate);
                            });
                    }
                    this.props.getShowcaseUsersList(
                        moment(this.state.selectedDate).format("YYYY-MM-DD")
                    );
                    this.props.getMoodGraphData(moment(startdate).format("YYYY-MM-DD"));
                })
                .catch((e) => {
                    console.log(e, "error");
                });
        }, 100);
    }

    //     componentDidMount() {
    //         let allemployeesworkingdata =
    //     (this.props.dashboard &&
    //       localStorage.getItem("roleUser") === "admin" &&
    //       this.props.dashboard.ManagersDashboard &&
    //       this.props.dashboard.ManagersDashboard.all_emp_working) ||
    //     (this.props.dashboard &&
    //       localStorage.getItem("roleUser") !== "admin" &&
    //       this.props.dashboard.ManagersDashboardObserver &&
    //       this.props.dashboard.ManagersDashboardObserver.all_emp_working);

    //   if (allemployeesworkingdata) {
    //     const newTaskNames = allemployeesworkingdata.map((el) =>
    //       el.task_list.toString()
    //     );

    //     // Use newTaskNames or update the state as needed
    //     this.setState({ newTaskNames });
    //   }
    //                   //console.log("newtask", this.state.newTaskName);

    //         const query = new URLSearchParams(this.props.location.search);
    //         const date = query.get("date");
    //         const timezone = query.get("timezone");
    //         this.props.getRolesCheck().then((res) => {
    //             this.setState({ userRoleList: res.value.data });
    //         });
    //         this.props.getFeatureListing();
    //         // this.props.getEmpListObserver();
    //         this.props.getDashboardLayout().then((res) => {
    //             this.setState({ dashboardLayout: res.value.data.data });
    //         });
    //         this.props.getDashboardArrangement().then((res) => {
    //             this.setState({ dashboardArrangement: res.value.data.data });
    //         });

    //         this.props.getTeamTableLayout().then((res) => {
    //             var obj = res.value.data.data;
    //             var num = Object.keys(obj).reduce(
    //                 (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
    //                 0
    //             );
    //             this.setState({ teamsTableLayout: obj, teamsCols: num });
    //         });
    //         this.props.getTeamTableArrangement().then((res) => {
    //             this.setState({ teamsTableArrangement: res.value.data.data });
    //         });

    //         this.props.getEmployeeTableLayout().then((res) => {
    //             var obj = res.value.data.data;
    //             var num = Object.keys(obj).reduce(
    //                 (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
    //                 0
    //             );
    //             this.setState({ employeeWorkingTableLayout: obj, empWorkingCols: num });
    //         });
    //         this.props.getEmployeeTableArrangement().then((res) => {
    //             this.setState({ employeeWorkingTableArrangement: res.value.data.data });
    //         });

    //         this.props.getEmployeeMoodTableLayout().then((res) => {
    //             var obj = res.value.data.data;
    //             var num = Object.keys(obj).reduce(
    //                 (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
    //                 0
    //             );
    //             this.setState({
    //                 employeeMoodTableLayout: obj,
    //                 moodsTableCols: num,
    //             });
    //         });
    //         this.props.getEmployeeMoodTableArrangement().then((res) => {
    //             this.setState({ employeeMoodArrangement: res.value.data.data });
    //         });
    //         this.props.getAllProjectTableLayout().then((res) => {
    //             var obj = res.value.data.data;
    //             delete obj["hours_per_month"];
    //             var num = Object.keys(obj).reduce(
    //                 (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
    //                 0
    //             );
    //             this.setState({ projectTableLayout: obj, allProjectsCols: num });
    //         });
    //         this.props.getAllProjectTableArrangement().then((res) => {
    //             this.setState({ projectTableArrangement: res.value.data.data });
    //         });
    //         setTimeout(() => {
    //             this.setState({
    //                 selectedDate: !date ? new Date() : new Date(date),
    //                 selectedTimezone:
    //                     timezone === undefined || timezone === ""
    //                         ? timezone === null
    //                             ? ""
    //                             : timezone
    //                         : this.state.selectedTimezone,
    //             });
    //             window.history.pushState(
    //                 {},
    //                 null,
    //                 `/dashboard/?date=${moment(this.state.selectedDate).format(
    //                     "YYYY-MM-DD"
    //                 )}&timezone=${this.state.selectedTimezone}`
    //             );
    //         }, 100);

    //         this.checkNewLogin();
    //         setTimeout(() => {
    //             if (localStorage.getItem("roleUser") === "admin") {
    //                 this.props.managerDashboard(moment(this.state.selectedDate).format("YYYY-MM-DD"));
    //                 const time = moment(
    //                     moment(new Date()).format("YYYY-MM-DD") ===
    //                         moment(this.state.selectedDate).format("YYYY-MM-DD")
    //                         ? new Date()
    //                         : new Date(this.state.selectedDate)
    //                 ).subtract(20, "minutes");
    //                 this.props.getCurrentStatus(
    //                     moment(this.state.selectedDate).format("YYYY-MM-DD"),
    //                     time.hours() +
    //                         ":" +
    //                         (time.minutes() - (time.minutes() % 10) === 0
    //                             ? "00"
    //                             : time.minutes() - (time.minutes() % 10)) +
    //                         ":00"
    //                 );
    //                 this.props.getEmployeeData(
    //                     moment(this.state.selectedDate).format("YYYY-MM-DD"),
    //                     time.hours() +
    //                         ":" +
    //                         (time.minutes() - (time.minutes() % 10) === 0
    //                             ? "00"
    //                             : time.minutes() - (time.minutes() % 10)) +
    //                         ":00"
    //                 );
    //             } else if (localStorage.getItem("roleUser") === "observer") {
    //                 this.props.managerdashboard_observer(
    //                     moment(this.state.selectedDate).format("YYYY-MM-DD")
    //                 );
    //             }
    //             this.props.teamsDashboard(moment(this.state.selectedDate).format("YYYY-MM-DD"));
    //             this.props.getEmployeeMoodTableData(
    //                 moment(this.state.selectedDate).format("YYYY-MM-DD")
    //             );
    //             this.props.getAllProjectTableData(moment(this.state.selectedDate).format("YYYY-MM-DD"));
    //             this.props.notworkingEmployees(moment(this.state.selectedDate).format("YYYY-MM-DD"));
    //             this.props.fetchallEmployees();
    //             let presentmonth = moment(new Date()).format("M");
    //             let presentyear = moment(new Date()).format("YYYY");
    //             let user_id = "";
    //             this.props.fetchEmployeeAttendance(presentyear, presentmonth, user_id);

    //             let startdate = new Date(
    //                 this.state.selectedDate.getFullYear(),
    //                 this.state.selectedDate.getMonth(),
    //                 this.state.selectedDate.getDate() - this.state.selectedDate.getDay()
    //             );
    //             let endDate = new Date(startdate.getTime() + 7 * 24 * 60 * 60 * 1000);

    //             if (
    //                 localStorage.getItem("graphData") &&
    //                 localStorage.getItem("graphTime") &&
    //                 moment(this.state.selectedDate).isSameOrBefore(
    //                     moment(localStorage.getItem("graphTime")).add(15, "minutes")
    //                 ) &&
    //                 moment(this.state.selectedDate).format("YYYY-MM-DD") ===
    //                     moment(localStorage.getItem("graphTime")).format("YYYY-MM-DD")
    //             ) {
    //                 this.setState({
    //                     graphData: _.orderBy(
    //                         JSON.parse(localStorage.getItem("graphData")),
    //                         "date",
    //                         "asc"
    //                     ),
    //                     productivitygraphloading: false,
    //                     startDate: startdate,
    //                     endDate: endDate,
    //                 });
    //             } else {
    //                 this.props
    //                     .fetchEmployeeWebsiteReportDashboard(
    //                         moment(startdate).format("YYYY-MM-DD"),
    //                         moment(endDate).format("YYYY-MM-DD")
    //                     )
    //                     .then((data) => {
    //                         this.setState({
    //                             graphData: _.orderBy(data.value.data.data, "date", "asc"),
    //                             productivitygraphloading: false,
    //                             startDate: startdate,
    //                             endDate: endDate,
    //                         });
    //                         localStorage.setItem(
    //                             "graphData",
    //                             JSON.stringify(_.orderBy(data.value.data.data, "date", "asc"))
    //                         );
    //                         localStorage.setItem("graphTime", this.state.selectedDate);
    //                     });
    //             }
    //             this.props.getShowcaseUsersList(moment(this.state.selectedDate).format("YYYY-MM-DD"));
    //             this.props.getMoodGraphData(moment(startdate).format("YYYY-MM-DD"));
    //         }, 200);
    //     }

    checkNewLogin = () => {
        fetch(localStorage.getItem("apiURL") + "/api/check_user/", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                marketing_access_key: localStorage.getItem("token"),
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message == true) {
                    this.setState({ showNewLogin: false });
                } else {
                    this.setState({ showNewLogin: true });
                }
            });
    };

    firstpassword = () => {
        if (this.state.newPassword !== this.state.confirmNewPassword) {
            toast("Passwords do not match");
        } else {
            fetch("https://api.trypadlock.com/password/forgot_password_first", {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                },
                body: JSON.stringify({
                    password: this.state.newPassword,
                    token: localStorage.getItem("token"),
                }),
            }).then((response) => {
                if (response.status == 200) {
                    toast("Password changed successfully");
                    this.setState({
                        showNewLogin: false,
                        showCreatePassword: false,
                        newPassword: "",
                        confirmNewPassword: "",
                    });
                } else {
                    toast("Something went wrong");
                }
            });
        }
    };

    handleDateChange = (date) => {
        this.setState({ selectedDate: date, productivitygraphloading: true }, () => {
            window.history.pushState(
                {},
                null,
                `/dashboard/?date=${moment(date).format("YYYY-MM-DD")}&timezone=${this.state.selectedTimezone
                }`
            );
            localStorage.setItem("selected Date", date);

            let todayDate = moment(date).format("YYYY-MM-DD");
            if (this.state.showObserverData) {
                this.props.managerDashboardObserver(todayDate);
            } else {
                this.props.managerDashboard(todayDate);
            }
            const time = moment(new Date()).subtract(20, "minutes");
            this.props.getCurrentStatus(
                todayDate,
                time.hours() +
                ":" +
                (time.minutes() - (time.minutes() % 10) === 0
                    ? "00"
                    : time.minutes() - (time.minutes() % 10)) +
                ":00"
            );
            this.props.getEmployeeData(
                todayDate,
                time.hours() +
                ":" +
                (time.minutes() - (time.minutes() % 10) === 0
                    ? "00"
                    : time.minutes() - (time.minutes() % 10)) +
                ":00"
            );
            this.props.teamsDashboard(todayDate);
            this.props.getEmployeeMoodTableData(todayDate);
            this.props.getAllProjectTableData(todayDate);
            this.props.notworkingEmployees(todayDate);

            let presentmonth = moment(date).format("M");
            let presentyear = moment(date).format("YYYY");
            let user_id = "";
            this.props.fetchEmployeeAttendance(presentyear, presentmonth, user_id);

            let startdate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate() - date.getDay()
            );
            let endDate = moment(new Date(startdate.getTime() + 6 * 24 * 60 * 60 * 1000)).format(
                "YYYY-MM-DD"
            );
            this.setState({ productivitygraphloading: true });
            this.props
                .fetchEmployeeWebsiteReportDashboard(
                    moment(startdate).format("YYYY-MM-DD"),
                    endDate
                )
                .then((data) => {
                    this.setState({
                        graphData: _.orderBy(data.value.data.data, "date", "asc"),
                        productivitygraphloading: false,
                    });
                });
            this.props.getMoodGraphData(moment(startdate).format("YYYY-MM-DD"));
            this.props.getShowcaseUsersList(moment(date).format("YYYY-MM-DD"));
        });
    };

    onTodayClick = () => {
        const currentDate = new Date();
        this.setState({ selectedDate: currentDate, productivitygraphloading: true }, () => {
            const startOfWeek = moment(currentDate).startOf("week", 1).format("YYYY-MM-DD");
            const endOfWeek = moment(currentDate).endOf("week").format("YYYY-MM-DD");
            this.setState(
                {
                    startDate: new Date(startOfWeek),
                    endDate: new Date(endOfWeek),
                },
                () => {
                    window.history.pushState(
                        {},
                        null,
                        `/dashboard/?date=${moment(currentDate).format("YYYY-MM-DD")}&timezone=${this.state.selectedTimezone
                        }`
                    );
                    let todayDate = moment(currentDate).format("YYYY-MM-DD");
                    this.props.managerDashboard(todayDate);
                    const time = moment(new Date()).subtract(20, "minutes");
                    this.props.getCurrentStatus(
                        todayDate,
                        time.hours() +
                        ":" +
                        (time.minutes() - (time.minutes() % 10) === 0
                            ? "00"
                            : time.minutes() - (time.minutes() % 10)) +
                        ":00"
                    );
                    this.props.getEmployeeData(
                        todayDate,
                        time.hours() +
                        ":" +
                        (time.minutes() - (time.minutes() % 10) === 0
                            ? "00"
                            : time.minutes() - (time.minutes() % 10)) +
                        ":00"
                    );
                    this.props.teamsDashboard(todayDate);
                    this.props.getEmployeeMoodTableData(todayDate);
                    this.props.getAllProjectTableData(todayDate);
                    this.props.notworkingEmployees(todayDate);

                    let presentmonth = moment(currentDate).format("M");
                    let presentyear = moment(currentDate).format("YYYY");
                    let user_id = "";
                    this.props.fetchEmployeeAttendance(presentyear, presentmonth, user_id);

                    let startdate = new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        currentDate.getDate() - currentDate.getDay()
                    );
                    let endDate = new Date(startdate.getTime() + 7 * 24 * 60 * 60 * 1000);
                    this.setState({ productivitygraphloading: true });
                    this.props
                        .fetchEmployeeWebsiteReportDashboard(
                            moment(startdate).format("YYYY-MM-DD"),
                            moment(endDate).format("YYYY-MM-DD")
                        )
                        .then((data) => {
                            this.setState({
                                graphData: _.orderBy(data.value.data.data, "date", "asc"),
                                productivitygraphloading: false,
                            });
                        });
                    this.props.getMoodGraphData(moment(startdate).format("YYYY-MM-DD"));
                    this.props.getShowcaseUsersList(moment(currentDate).format("YYYY-MM-DD"));
                }
            );
        });
    };

    handleTeamNavigation = (teamID, data, teamName) => {
        this.props.history.push({
            // pathname: `/dashboard/?teamName=${teamName}&date=${this.state.selectedDate}`,
            pathname: `/team/?teamName=${teamName}&date=${this.state.selectedDate}`,
            state: { teamuuid: teamID, data: data, teamName: teamName },
        });
    };
    handleProjectNavigation = (projectId, data, projectName) => {
        this.props.history.push({
            // pathname: `/dashboard/?project=${projectName}`,
            pathname: `/projects/?project=${projectName}`,
            state: { projectId: projectId, data: data, projectName: projectName },
        });
    };

    handleSelectEmployee = (e) => {
        this.setState({ selectedEmployee: e.target.value }, () => {
            let startdate = moment(this.state.startDate).format("YYYY-MM-DD");
            let endDate = moment(this.state.endDate).format("YYYY-MM-DD");
            if (this.state.selectedEmployee) {
                this.setState({ productivitygraphloading: true });
                this.props.fetchEmployeeWebsiteReportDashboard(startdate, endDate).then((data) => {
                    this.setState({
                        graphData: _.orderBy(data.value.data.data, "date", "asc"),
                        productivitygraphloading: false,
                        selectedEmployee: e.target.value,
                    });
                });
            } else {
                this.setState({ productivitygraphloading: true });
                this.props.fetchEmployeeWebsiteReportDashboard(startdate, endDate).then((data) => {
                    this.setState({
                        graphData: _.orderBy(data.value.data.data, "date", "asc"),
                        productivitygraphloading: false,
                        selectedEmployee: "",
                    });
                });
            }
        });
    };

    HandleSelectTab = (e) => {
        this.setState({ SelectedTab: e });
    };

    HandleMobileSelectTab = (e) => {
        this.setState({ SelectedTab: e.target.value });
    };

    onTimeZoneChange = (timezone) => {
        let z = this.state.today.toLocaleString("en-US", {
            timeZone: timezone.value,
        });
        this.setState({ selectedDate: new Date(z) }, () => {
            window.history.pushState(
                {},
                null,
                `/dashboard/?date=${moment(this.state.selectedDate).format(
                    "YYYY-MM-DD"
                )}&timezone=${timezone.value}`
            );

            let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
            if (this.state.showObserverData) {
                this.props.managerDashboardObserver(date);
            } else {
                this.props.managerDashboard(date);
            }

            const time = moment(new Date(this.state.selectedDate)).subtract(20, "minutes");
            this.props.getCurrentStatus(
                date,
                time.hours() +
                ":" +
                (time.minutes() - (time.minutes() % 10) === 0
                    ? "00"
                    : time.minutes() - (time.minutes() % 10)) +
                ":00"
            );
            this.props.getEmployeeData(
                date,
                time.hours() +
                ":" +
                (time.minutes() - (time.minutes() % 10) === 0
                    ? "00"
                    : time.minutes() - (time.minutes() % 10)) +
                ":00"
            );
            this.props.teamsDashboard(date);
            this.props.notworkingEmployees(date);
        });
    };

    handleRoleChangeAdmin = () => {
        this.setState({ observerData: false });
        if (localStorage.getItem("ro") === "Admin") {
            if (this.state.showObserverData) {
                this.props.managerDashboard(moment(this.state.selectedDate).format("YYYY-MM-DD"));
            } else {
                this.props.managerDashboardObserver(
                    moment(this.state.selectedDate).format("YYYY-MM-DD")
                );
            }
            const time = moment(new Date(this.state.selectedDate)).subtract(20, "minutes");
            this.props.getCurrentStatus(
                moment(this.state.selectedDate).format("YYYY-MM-DD"),
                time.hours() +
                ":" +
                (time.minutes() - (time.minutes() % 10) === 0
                    ? "00"
                    : time.minutes() - (time.minutes() % 10)) +
                ":00"
            );
            this.props.getEmployeeData(
                moment(this.state.selectedDate).format("YYYY-MM-DD"),
                time.hours() +
                ":" +
                (time.minutes() - (time.minutes() % 10) === 0
                    ? "00"
                    : time.minutes() - (time.minutes() % 10)) +
                ":00"
            );
        } else {
            this.props.managerdashboard_observer(
                moment(this.state.selectedDate).format("YYYY-MM-DD")
            );
        }
    };

    dashboardLayoutUpdateHandler = (data, arrangementData) => {
        const newData = {
            team: data[arrangementData["team_table"] - 1]["status"] ? "True" : "False",
            all_employee: data[arrangementData["all_employee"] - 1]["status"] ? "True" : "False",
            project: data[arrangementData["project"] - 1]["status"] ? "True" : "False",
            not_logged: data[arrangementData["not_logged"] - 1]["status"] ? "True" : "False",
            mood: data[arrangementData["mood"] - 1]["status"] ? "True" : "False",
            permission: data[arrangementData["permission"] - 1]["status"] ? "True" : "False",
        };
        const newArrangement = {
            team_table: arrangementData["team_table"],
            all_employee: arrangementData["all_employee"],
            project: arrangementData["project"],
            not_logged: arrangementData["not_logged"],
            mood: arrangementData["mood"],
            permission: arrangementData["permission"],
        };

        if (this.state.dashboardArrangement["edit"]) {
            this.props.updateDashboardArrangement(newArrangement).then((res) => {
                if (res.value.status === 200) {
                    this.setState({
                        dashboardArrangement: arrangementData,
                    });
                }
            });
        } else {
            this.props.postDashboardArrangement(newArrangement).then((res) => {
                if (res.value.status === 200) {
                    this.setState({
                        dashboardArrangement: arrangementData,
                    });
                }
            });
        }
        if (this.state.dashboardLayout["edit"]) {
            this.props.updateDashboardLayout(newData).then((response) => {
                if (response.value.status === 200) {
                    this.props.getDashboardLayout().then((res) => {
                        this.setState({
                            dashboardLayout: res.value.data.data,
                            isShowSettingsModal: false,
                        });
                    });
                }
            });
        } else {
            this.props.postDashboardLayout(newData).then((response) => {
                if (response.value.status === 200) {
                    this.props.getDashboardLayout().then((res) => {
                        this.setState({
                            dashboardLayout: res.value.data.data,
                            isShowSettingsModal: false,
                        });
                    });
                }
            });
        }
    };

    allProjectTableLayoutUpdateHandler = (data, arrangementData) => {
        const newData = {
            hours_booked: data[arrangementData["hours_booked"] - 1]["status"] ? "True" : "False",
            hours_remaining: data[arrangementData["hours_remaining"] - 1]["status"]
                ? "True"
                : "False",
            hours_rolled_over: data[arrangementData["hours_rolled_over"] - 1]["status"]
                ? "True"
                : "False",
        };
        const newArrangement = {
            hours_booked: arrangementData["hours_booked"],
            hours_remaining: arrangementData["hours_remaining"],
            hours_rolled_over: arrangementData["hours_rolled_over"],
        };

        if (this.state.projectTableArrangement["edit"]) {
            this.props.updateAllProjectTableArrangement(newArrangement).then((res) => {
                if (res.value.status === 200) {
                    this.setState({
                        projectTableArrangement: arrangementData,
                    });
                }
            });
        } else {
            this.props.postAllProjectTableArrangement(newArrangement).then((res) => {
                if (res.value.status === 200) {
                    this.setState({
                        projectTableArrangement: arrangementData,
                    });
                }
            });
        }
        if (this.state.projectTableLayout["edit"]) {
            this.props.updateAllProjectTableLayout(newData).then((response) => {
                if (response.value.status === 200) {
                    this.props.getAllProjectTableLayout().then((res) => {
                        var obj = res.value.data.data;
                        delete obj["hours_per_month"];
                        var num = Object.keys(obj).reduce(
                            (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
                            0
                        );
                        this.setState({
                            projectTableLayout: obj,
                            allProjectsCols: num,
                            isShowSettingsModal: false,
                        });
                    });
                }
            });
        } else {
            this.props.postAllProjectTableLayout(newData).then((response) => {
                if (response.value.status === 200) {
                    this.props.getAllProjectTableLayout().then((res) => {
                        var obj = res.value.data.data;
                        delete obj["hours_per_month"];
                        var num = Object.keys(obj).reduce(
                            (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
                            0
                        );
                        this.setState({
                            projectTableLayout: obj,
                            allProjectsCols: num,
                            isShowSettingsModal: false,
                        });
                    });
                }
            });
        }
    };

    employeeMoodLayoutUpdateHandler = (data, arrangementData) => {
        const newData = {
            start_mood: data[arrangementData["start_mood"] - 1]["status"] ? "True" : "False",
            start_note: data[arrangementData["start_note"] - 1]["status"] ? "True" : "False",
            end_mood: data[arrangementData["end_mood"] - 1]["status"] ? "True" : "False",
            end_note: data[arrangementData["end_note"] - 1]["status"] ? "True" : "False",
            username: data[arrangementData["username"] - 1]["status"] ? "True" : "False",
        };
        const newArrangement = {
            start_mood: arrangementData["start_mood"],
            start_note: arrangementData["start_note"],
            end_mood: arrangementData["end_mood"],
            end_note: arrangementData["end_note"],
            username: arrangementData["username"],
        };

        if (this.state.employeeMoodArrangement["edit"]) {
            this.props.updateEmployeeMoodTableArrangement(newArrangement).then((res) => {
                if (res.value.status === 200) {
                    this.setState({
                        employeeMoodArrangement: arrangementData,
                    });
                }
            });
        } else {
            this.props.postEmployeeMoodTableArrangement(newArrangement).then((res) => {
                if (res.value.status === 200) {
                    this.setState({
                        employeeMoodArrangement: arrangementData,
                    });
                }
            });
        }
        if (this.state.employeeMoodTableLayout["edit"]) {
            this.props.updateEmployeeMoodTableLayout(newData).then((response) => {
                if (response.value.status === 200) {
                    this.props.getEmployeeMoodTableLayout().then((res) => {
                        var obj = res.value.data.data;
                        var num = Object.keys(obj).reduce(
                            (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
                            0
                        );
                        this.setState({
                            employeeMoodTableLayout: obj,
                            moodsTableCols: num,
                            isShowSettingsModal: false,
                        });
                    });
                }
            });
        } else {
            this.props.postEmployeeMoodTableLayout(newData).then((response) => {
                if (response.value.status === 200) {
                    this.props.getEmployeeMoodTableLayout().then((res) => {
                        var obj = res.value.data.data;
                        var num = Object.keys(obj).reduce(
                            (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
                            0
                        );
                        this.setState({
                            employeeMoodTableLayout: obj,
                            moodsTableCols: num,
                            isShowSettingsModal: false,
                        });
                    });
                }
            });
        }
    };

    employeeWorkingLayoutUpdateHandler = (data, arrangementData) => {
        const newData = {
            employee_name: data[arrangementData["employee_name"] - 1]["status"] ? "True" : "False",
            task: data[arrangementData["task"] - 1]["status"] ? "True" : "False",
            team: data[arrangementData["team"] - 1]["status"] ? "True" : "False",
            project: data[arrangementData["project"] - 1]["status"] ? "True" : "False",
            time_logged: data[arrangementData["time_logged"] - 1]["status"] ? "True" : "False",
            category: data[arrangementData["category"] - 1]["status"] ? "True" : "False",
        };
        const newArrangement = {
            employee_name: arrangementData["employee_name"],
            task: arrangementData["task"],
            team: arrangementData["team"],
            project: arrangementData["project"],
            time_logged: arrangementData["time_logged"],
            category: arrangementData["category"],
        };
        if (this.state.employeeWorkingTableArrangement["edit"]) {
            this.props.updateEmployeeTableArrangement(newArrangement).then((res) => {
                if (res.value.status === 200) {
                    this.setState({
                        employeeWorkingTableArrangement: arrangementData,
                    });
                }
            });
        } else {
            this.props.postEmployeeTableArrangement(newArrangement).then((res) => {
                if (res.value.status === 200) {
                    this.setState({
                        employeeWorkingTableArrangement: arrangementData,
                    });
                }
            });
        }
        if (this.state.employeeWorkingTableLayout["edit"]) {
            this.props.updateEmployeeTableLayout(newData).then((response) => {
                if (response.value.status === 200) {
                    this.props.getEmployeeTableLayout().then((res) => {
                        var obj = res.value.data.data;
                        var num = Object.keys(obj).reduce(
                            (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
                            0
                        );
                        this.setState({
                            employeeWorkingTableLayout: obj,
                            empWorkingCols: num,
                            isShowSettingsModal: false,
                        });
                    });
                }
            });
        } else {
            this.props.postEmployeeTableLayout(newData).then((response) => {
                if (response.value.status === 200) {
                    this.props.getEmployeeTableLayout().then((res) => {
                        var obj = res.value.data.data;
                        var num = Object.keys(obj).reduce(
                            (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
                            0
                        );
                        this.setState({
                            employeeWorkingTableLayout: obj,
                            empWorkingCols: num,
                            isShowSettingsModal: false,
                        });
                    });
                }
            });
        }
    };

    teamTableLayoutUpdateHandler = (data, arrangementData) => {
        const newData = {
            name: data[arrangementData["name"] - 1]["status"] ? "True" : "False",
            worked: data[arrangementData["worked"] - 1]["status"] ? "True" : "False",
            hours_spend: data[arrangementData["hours_spend"] - 1]["status"] ? "True" : "False",
        };
        const newArrangement = {
            name: arrangementData["name"],
            worked: arrangementData["worked"],
            hours_spend: arrangementData["hours_spend"],
        };
        if (this.state.teamsTableArrangement["edit"]) {
            this.props.updateTeamTableArrangement(newArrangement).then((res) => {
                if (res.value.status === 200) {
                    this.setState({
                        teamsTableArrangement: arrangementData,
                    });
                }
            });
        } else {
            this.props.postTeamTableArrangement(newArrangement).then((res) => {
                if (res.value.status === 200) {
                    this.setState({
                        teamsTableArrangement: arrangementData,
                    });
                }
            });
        }
        if (this.state.teamsTableLayout["edit"]) {
            this.props.updateTeamTableLayout(newData).then((response) => {
                if (response.value.status === 200) {
                    this.props.getTeamTableLayout().then((res) => {
                        var obj = res.value.data.data;
                        var num = Object.keys(obj).reduce(
                            (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
                            0
                        );
                        this.setState({
                            teamsTableLayout: obj,
                            teamsCols: num,
                            isShowSettingsModal: false,
                        });
                    });
                }
            });
        } else {
            this.props.postTeamTableLayout(newData).then((response) => {
                if (response.value.status === 200) {
                    this.props.getTeamTableLayout().then((res) => {
                        var obj = res.value.data.data;
                        var num = Object.keys(obj).reduce(
                            (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
                            0
                        );
                        this.setState({
                            teamsTableLayout: obj,
                            teamsCols: num,
                            isShowSettingsModal: false,
                        });
                    });
                }
            });
        }
    };

    render() {
        const { t } = this.props;
        let managersdashboard = this.props.dashboard && this.props.dashboard.ManagersDashboard;
        let allemployeesworkingdata =
            this.props.dashboard && localStorage.getItem("roleUser") === "admin"
                ? this.props.dashboard.ManagersDashboard &&
                this.props.dashboard.ManagersDashboard.all_emp_working
                : this.props.dashboard.ManagersDashboardObserver &&
                  this.props.dashboard.ManagersDashboardObserver.all_emp_working;
        allemployeesworkingdata = allemployeesworkingdata && [...allemployeesworkingdata]?.sort((a,b)=>a.Employee_name.localeCompare(b.Employee_name))
        let allprojectsdata = this.props.dashboard && this.props.dashboard.AllProjectTableData;
        allprojectsdata = allprojectsdata && [...allprojectsdata]?.sort((a,b)=>a.project_name.localeCompare(b.project_name))
        let dashboardKpi =
            this.props.dashboard && localStorage.getItem("roleUser") === "admin"
                ? this.props.dashboard.ManagersDashboard &&
                this.props.dashboard.ManagersDashboard.Total_emp_working_data
                : this.props.dashboard.ManagersDashboardObserver &&
                this.props.dashboard.ManagersDashboardObserver.Total_emp_working_data;
        let teamsdashboard = this.props.dashboard && this.props.dashboard.TeamsDashboard;
        let unique = new Set();
        let uniqueteamarr = [];
        if (Array.isArray(teamsdashboard)) {
            teamsdashboard.forEach((item) => {
                if (!unique.has(item.team_name)) {
                    unique.add(item.team_name);
                    uniqueteamarr.push(item)
                }
            })
        }
        uniqueteamarr = uniqueteamarr && [...uniqueteamarr]?.sort((a,b)=>a.team_name.localeCompare(b.team_name))
        let PermissonsList =
            this.props.dashboard &&
            this.props.dashboard.FeatureListing &&
            this.props.dashboard.FeatureListing.status;
        let employeeMoodTableData =
            this.props.dashboard &&
            this.props.dashboard.MoodTable &&
            this.props.dashboard.MoodTable.data;
        let notworkingemployees = this.props.dashboard && this.props.dashboard.NotWorkingEmployees;
        let EmployeesAttendance =
            this.props.report && localStorage.getItem("roleUser") === "admin"
                ? this.props.report.EmployeesAttendance
                : this.props.report.EmployeesAttendanceObserver;
        let employees =
            localStorage.getItem("roleUser") === "admin"
                ? this.props.projects && this.props.projects.AllEmployees
                : this.props.dashboard && this.props.dashboard.EmpListObserver;

        let listofAllEmployees =
            employees &&
            employees.map((emp, index) => ({
                name: emp.user_first_name + " " + emp.user_last_name,
                userName: emp.user_login_as,
                id: emp.user_id,
            }));
        let listOFAllShowcaseEmployees =
            this.props.dashboard &&
            this.props.dashboard.showcaseUsersList &&
            this.props.dashboard.showcaseUsersList.map((emp, index) => ({
                name: emp.first_name + " " + emp.last_name,
                id: emp.uu_id,
            }));

        let todaydate = moment(this.state.selectedDate).format("YYYY-MM-DD");
        let todaysemployeesattendance =
            EmployeesAttendance && EmployeesAttendance.filter((el) => el.date == todaydate);
        let lateemployees =
            todaysemployeesattendance &&
            todaysemployeesattendance.filter((el) => el.time > 0 && el.time <= 480);
        let presentemployees =
            todaysemployeesattendance && todaysemployeesattendance.filter((el) => el.time > 0);
        let absentemployees =
            todaysemployeesattendance && todaysemployeesattendance.filter((el) => el.time == 0);
        const handleTeamNavigation3 = (teamName) => {
            this.props.history.push({
                // pathname: `/dashboard/?teamName=${teamName}&date=${this.state.selectedDate}`,
                pathname: `/team/?teamName=${teamName}&date=${this.state.selectedDate}`,
                state: { teamName: teamName },
            });
        };
        const handleTeamNavigation2 = (teamName, data) => {
            const matchingTeam = uniqueteamarr?.find((team) => team.team_name === teamName);
            if (matchingTeam) {
                const { team_id, team_name } = matchingTeam;

                this.props.history.push({
                    pathname: `/team/?teamName=${team_name}&date=${this.state.selectedDate}`,
                    state: { teamuuid: team_id, teamName: team_name, data: data },
                });
            } else {
                handleTeamNavigation3(teamName);
            }
        };

        const handleProjectNavigationProj2 = (data, projectName) => {
            this.props.history.push({
                // pathname: `/dashboard/?project=${projectName}`,
                pathname: `/projects/?project=${projectName}`,
                state: { data: data, projectName: projectName },
            });
        };

        const handleProjectNavigationProject = (projectName, data) => {
            const matchingProj = allprojectsdata?.find((proj) => proj.project_name === projectName);
            if (matchingProj) {
                const { project_id, project_name } = matchingProj;

                this.props.history.push({
                    // pathname: `/dashboard/?project=${projectName}`,
                    pathname: `/projects/?project=${projectName}`,
                    state: { projectId: project_id, data: data, projectName: project_name },
                });
            } else {
                handleProjectNavigationProj2(projectName);
            }
        };

        const onclickAscendingtname = () => {
            const sortedData = [...uniqueteamarr]?.sort((a, b) =>
                a.team_name.localeCompare(b.team_name)
            );
            this.setState({
                sortedTeam:sortedData,
                isSort2:true,
                sortingOrder: "ascendingtname",
            })
        }

        const onclickDescendingtname=()=>{
            const sortedData=[...uniqueteamarr]?.sort((a,b)=>b.team_name.localeCompare(a.team_name))
            this.setState({
                sortedTeam:sortedData,
                isSort2:true,
                sortingOrder: "descendingtname",
            })
        }

        const onclickAscendingtworked=()=>{
            const sortedData=[...uniqueteamarr]?.sort((a,b)=>a.time_worked-b.time_worked)
            this.setState({
                sortedTeam:sortedData,
                isSort2:true,
                sortingOrder: "ascendingtworked",
            })
        }

        const onclickDescendingtworked=()=>{
            const sortedData=[...uniqueteamarr]?.sort((a,b)=>b.time_worked-a.time_worked)
            this.setState({
                sortedTeam:sortedData,
                isSort2:true,
                sortingOrder: "descendingtworked",
            })
        }

        const onclickAscendingpeople=()=>{
            const sortedData=[...uniqueteamarr]?.sort((a,b)=>a.number_of_people-b.number_of_people)
            this.setState({
                sortedTeam:sortedData,
                isSort2:true,
                sortingOrder: "ascendingpeople",
            })
        }

        const onclickDescendingpeople=()=>{
            const sortedData=[...uniqueteamarr]?.sort((a,b)=>b.number_of_people-a.number_of_people)
            this.setState({
                sortedTeam:sortedData,
                isSort2:true,
                sortingOrder: "descendingpeople",
            })
        }

        const onClickDescending = () => {
            const sortedData=[...allemployeesworkingdata]?.sort((a,b)=>b.Employee_name.localeCompare(a.Employee_name))
            this.setState({
                sortedEmployee: sortedData,
                isSort:true,
                sortingOrder: "descendingename",
            });
            };
        
        const onClickAssending = () => {
            const sortedData=[...allemployeesworkingdata]?.sort((a,b)=>a.Employee_name.localeCompare(b.Employee_name))
            this.setState({
                sortedEmployee: sortedData,
                isSort:true,
                sortingOrder: "ascendingename",
            });
        };
        
        const onClickDescending2 = () => {
            const sortedData=[...allemployeesworkingdata]?.sort((a,b)=>b.time_logged2-a.time_logged2);
            this.setState({
                sortedEmployee: sortedData,
                isSort:true,
                sortingOrder: "descendingtimelogged",
            });
            };
        
        
        const onClickAssending2 = () => {
            const sortedData=[...allemployeesworkingdata]?.sort((a,b)=>a.time_logged2-b.time_logged2);
            this.setState({
                sortedEmployee: sortedData,
                isSort:true,
                sortingOrder: "ascendingtimelogged",
            });
        }

        const onClickAscendingproject = () => {
            const sortedData = [...allemployeesworkingdata]?.sort((a, b) =>
                a.project.localeCompare(b.project)
            );
            // const sortedData=[...allemployeesworkingdata]?.sort((a,b)=>{
            //     const aProject=Array.isArray(a.project_list)?a.project_list.join(''):'';
            //     const bProject=Array.isArray(b.project_list)?b.project_list.join(''):'';
            //     return aProject.localeCompare(bProject)
            // })
            this.setState({
                sortedEmployee: sortedData,
                isSort:true,
                sortingOrder: "ascendingproject",
            });
        };

        const onClickDescendingproject = () => {
            const sortedData = [...allemployeesworkingdata]?.sort((a, b) =>
                b.project.localeCompare(a.project)
            );
            // const sortedData=[...allemployeesworkingdata]?.sort((a,b)=>{
            //     const aProject=Array.isArray(a.project_list)?a.project_list.join(''):'';
            //     const bProject=Array.isArray(b.project_list)?b.project_list.join(''):'';
            //     return bProject.localeCompare(aProject)
            // })
            this.setState({
                sortedEmployee: sortedData,
                isSort:true,
                sortingOrder: "descendingproject",
            });
        };

        const onClickAscendingEteam = () => {
            const sortedData = [...allemployeesworkingdata]?.sort((a, b) => {
                const aTeam = Array.isArray(a.team_list) ? a.team_list[0] : a.team_list;
                const bTeam = Array.isArray(b.team_list) ? b.team_list[0] : b.team_list;
                return aTeam.localeCompare(bTeam);
            });
            this.setState({
                sortedEmployee:sortedData,
                isSort:true,
                sortingOrder: "ascendingeteam",
            })
        }

        const onClickDescendingEteam = () => {
            const sortedData = [...allemployeesworkingdata]?.sort((a, b) => {
                const aTeam = Array.isArray(a.team_list) ? a.team_list[0] : a.team_list;
                const bTeam = Array.isArray(b.team_list) ? b.team_list[0] : b.team_list;
                return bTeam.localeCompare(aTeam);
            });
            this.setState({
                sortedEmployee:sortedData,
                isSort:true,
                sortingOrder: "descendingeteam",
            })
        }

        const onClickAscendingpname = () => {
            const sortedData = [...allprojectsdata]?.sort((a, b) =>
                a.project_name.localeCompare(b.project_name)
            );
            this.setState({
                sortedProject:sortedData,
                isSort1:true,
                sortingOrder: "ascendingpname",
            })
        }

        const onClickDescendingpname = () => {
            const sortedData = [...allprojectsdata]?.sort((a, b) =>
                b.project_name.localeCompare(a.project_name)
            );
            this.setState({
                sortedProject:sortedData,
                isSort1:true,
                sortingOrder: "descendingpname",
            })
        }

        const onClickAscendinghspent = () => {
            const sortedData = [...allprojectsdata]?.sort((a, b) => a.hours_spent - b.hours_spent);
            this.setState({
                sortedProject:sortedData,
                isSort1:true,
                sortingOrder: "ascendinghspent",
            })
        }

        const onClickDescendinghspent = () => {
            const sortedData = [...allprojectsdata]?.sort((a, b) => b.hours_spent - a.hours_spent);
            this.setState({
                sortedProject:sortedData,
                isSort1:true,
                sortingOrder: "descendinghspent",
            })
        }

        const onClickAscendingbhours = () => {
            // const sortedData=[...allprojectsdata]?.map((item)=>{
            //     const sorteditem=[...item.data]?.sort((a,b)=>a.hours-b.hours)
            //     return {...item,data:sorteditem}
            // })
            const sortedData = [...allprojectsdata]?.sort(
                (a, b) => a.data[0].hours - b.data[0].hours
            );
            this.setState({
                sortedProject:sortedData,
                isSort1:true,
                sortingOrder: "ascendingbhours",
            })
        }

        const onClickDescendingbhours = () => {
            const sortedData = [...allprojectsdata]?.sort(
                (a, b) => b.data[0].hours - a.data[0].hours
            );
            this.setState({
                sortedProject:sortedData,
                isSort1:true,
                sortingOrder: "descendingbhours",
            })
        }

        const onClickAscendinghrem = () => {
            const sortedData = [...allprojectsdata]?.sort(
                (a, b) => a.data[0].hours_remaining - b.data[0].hours_remaining
            );
            this.setState({
                sortedProject:sortedData,
                isSort1:true,
                sortingOrder: "ascendinghrem",
            })
        }

        const onClickDescendinghrem = () => {
            const sortedData = [...allprojectsdata]?.sort(
                (a, b) => b.data[0].hours_remaining - a.data[0].hours_remaining
            );
            this.setState({
                sortedProject:sortedData,
                isSort1:true,
                sortingOrder: "descendinghrem",
            })
        }

        const onclickAscendinghrolled = () => {
            const sortedData = [...allprojectsdata]?.sort(
                (a, b) => a.data[0].hours_rolled - b.data[0].hours_rolled
            );
            this.setState({
                sortedProject:sortedData,
                isSort1:true,
                sortingOrder: "ascendinghrolled",
            })
        }

        const onclickDescendinghrolled = () => {
            const sortedData = [...allprojectsdata]?.sort(
                (a, b) => b.data[0].hours_rolled - a.data[0].hours_rolled
            );
            this.setState({
                sortedProject:sortedData,
                isSort1:true,
                sortingOrder: "descendinghrolled",
            })
        }

        const AttendanceChart = {
            chart: {
                type: "pie",
                height: 250,
                style: {
                    fontFamily: "Lato",
                    fontSize: "15px",
                },
            },
            tooltip: {
                pointFormat: "<b>{series.name}:</b> {point.additionalInfo}",
            },
            title: {
                text: "",
            },

            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b>: {point.y}",
                    },
                    showInLegend: true,
                },
            },

            credits: {
                enabled: false,
            },
            series: [
                {
                    name: "Employees",
                    innerSize: "60%",
                    data: [
                        {
                            name: "Offline",
                            y:
                                this.props.dashboard &&
                                this.props.dashboard.CurrentStatus &&
                                this.props.dashboard.CurrentStatus.not_working,
                            color: "#0d233a",
                            additionalInfo:
                                this.props.dashboard &&
                                    this.props.dashboard.EmployeesData &&
                                    this.props.dashboard.EmployeesData.not_working &&
                                    this.props.dashboard.EmployeesData.not_working.length > 0
                                    ? this.props.dashboard.EmployeesData.not_working.join(", ")
                                    : "None",
                        },
                        {
                            name: "Online",
                            y:
                                this.props.dashboard &&
                                this.props.dashboard.CurrentStatus &&
                                this.props.dashboard.CurrentStatus.working,
                            color: "#684d94",
                            additionalInfo:
                                this.props.dashboard &&
                                    this.props.dashboard.EmployeesData &&
                                    this.props.dashboard.EmployeesData.working &&
                                    this.props.dashboard.EmployeesData.working.length > 0
                                    ? this.props.dashboard.EmployeesData.working.join(", ")
                                    : "None",
                        },
                    ],
                },
            ],
        };

        // let arrayProductive = [];
        // let arrayUnproductive = [];
        // let arrayUndefined = [];
        // let arrayDates = [];
        // let arrayDates1 = [];
        // this.state.graphData
        //   .sort((a, b) => {
        //     const dateA = moment(a.date, "YYYY-MM-DD");
        //     const dateB = moment(b.date, "YYYY-MM-DD");
        //     return dateA - dateB;
        //   })
        //     let i = 0;
        //     const startDateExists = this.state.graphData.some(data => moment(data.date, "YYYY-MM-DD").isSame(moment(this.state.startDate).add(i, 'days'), 'day'));
        //     if (!startDateExists) {
        //     arrayProductive.push(0);
        //     arrayDates.push(moment(this.state.startDate).format("ddd"));
        //     arrayDates1.push(moment(this.state.startDate).format("ddd, MMM DD, YYYY"));
        //     i++;
        //     }
        //     while (i <= moment(this.state.endDate).diff(moment(this.state.startDate), 'days')) {
        //     const foundData = this.state.graphData.find(data =>
        //         moment(data.date, "YYYY-MM-DD").isSame(moment(this.state.startDate).add(i, 'days'), 'day')
        //     );

        //     if (foundData) {
        //         arrayProductive.push(parseInt((foundData.productive + foundData.undefined ) / 60));
        //         arrayUnproductive.push(parseInt(foundData.unproductive / 60));
        //         arrayUndefined.push(parseInt(foundData.undefined / 60));
        //         arrayDates.push(moment(foundData.date).format("ddd"));
        //         arrayDates1.push(moment(foundData.date).format("ddd, MMM DD, YYYY"));
        //     } else {
        //         arrayProductive.push(0);
        //         arrayDates.push(moment(this.state.startDate).add(i, 'days').format("ddd"));
        //         arrayDates1.push(moment(this.state.startDate).add(i, 'days').format("ddd, MMM DD, YYYY"));
        //     }
        //     i++;
        // }

        let arrayProductive = [];
        // let arrayUnproductive = [];
        // let arrayUndefined = [];
        let arrayDates = [];
        let arrayDates1 = [];
        let prodArr = !this.state.graphData
            ? ""
            : this.state.graphData
                .sort((a, b) => {
                    var dateA = moment(a.date, "YYYY-MM-DD");
                    var dateB = moment(b.date, "YYYY-MM-DD");
                    return dateA - dateB;
                })
                .map((data) => {
                    if (data === "") {
                        console.log("Empty Data");
                    } else {
                        arrayProductive.push(data.duration);
                    }
                    //   if (data === "") {
                    //       console.log("Empty Data");
                    //   } else {
                    //       arrayUnproductive.push(parseInt(data.unproductive / 60));
                    //   }
                    //   if (data === "") {
                    //       console.log("Empty Data");
                    //   } else {
                    //       arrayUndefined.push(parseInt(data.undefined / 60));
                    //   }
                    if (data === "") {
                        console.log("error");
                    } else {
                        arrayDates.push(moment(data.date).format("ddd"));
                        arrayDates1.push(moment(data.date).format("ddd, MMM DD, YYYY"));
                    }
                    return null;
                });
        if (this.state.graphData.length > 0) {
            for (let i = this.state.graphData.length; i < 7; i++) {
                arrayDates.push(
                    moment(this.state.graphData.at(0).date).add(i, "days").format("ddd")
                );
                arrayProductive.push(0);
            }
        }


        let arrayMood =
            this.props.dashboard &&
            this.props.dashboard.MoodGraphData &&
            this.props.dashboard.MoodGraphData.map((entity) => {
                let average = 0;
                let entityList = entity[Object.keys(entity)[0]];
                if (entityList.length === 0) {
                    return average;
                }

                average = entityList.reduce((total, val) => {
                    return (total += val[0]);
                }, 0);
                return Math.round(average / entityList.length);
            });

        const nextWeekprodGraph = () => {
            let currentDate = new Date(this.state.endDate.getTime() - 7 * 24 * 60 * 60 * 1000);
            let currentDay = currentDate.getDay();
            let daysToAdd = currentDay === 0 ? 7 : 7 - currentDay;
            let startDate = new Date(currentDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
            startDate.setDate(startDate.getDate() + 1);
            let startDayOfWeek = startDate.getDay();
            let daysToSunday = startDayOfWeek === 0 ? 0 : 7 - startDayOfWeek;
            startDate.setDate(startDate.getDate() + daysToSunday);
            let endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);

            this.setState({ productivitygraphloading: true });
            this.setState({ startDate: startDate, endDate: endDate });

            this.props
                .fetchEmployeeWebsiteReportDashboard(
                    moment(startDate).format("YYYY-MM-DD"),
                    moment(endDate).format("YYYY-MM-DD")
                )
                .then((data) => {
                    this.setState({
                        graphData: data.value.data.data,
                        productivitygraphloading: false,
                        startDate: startDate,
                        endDate: endDate,
                    });
                });

            this.props.getMoodGraphData(moment(startDate).format("YYYY-MM-DD"));
        };

        const lastWeekprodGraph = () => {
            let currentDate = new Date(this.state.endDate);
            let currentDay = currentDate.getDay();
            let daysToSubtract = currentDay === 0 ? 0 : currentDay;
            let startDate = new Date(currentDate.getTime() - daysToSubtract * 24 * 60 * 60 * 1000);

            let startDayOfWeek = startDate.getDay();
            let daysToSunday = startDayOfWeek === 0 ? 7 : startDayOfWeek;
            startDate.setDate(startDate.getDate() - daysToSunday);

            let endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);
            this.setState({ productivitygraphloading: true });
            this.setState({ startDate: startDate, endDate: endDate });

            this.props
                .fetchEmployeeWebsiteReportDashboard(
                    moment(startDate).format("YYYY-MM-DD"),
                    moment(endDate).format("YYYY-MM-DD")
                )
                .then((data) => {
                    this.setState({
                        graphData: data.value.data.data,
                        productivitygraphloading: false,
                        startDate: startDate,
                        endDate: endDate,
                    });
                });

            this.props.getMoodGraphData(moment(startDate).format("YYYY-MM-DD"));
        };

        const productivitychart = {
            chart: {
                type: "column",
                height: 250,
                zoomType: "x",
                style: {
                    fontFamily: "Lato",
                    fontSize: "15px",
                },
            },
            title: {
                text: "",
            },
            yAxis: {
                title: {
                    text: `${t("average")}`,
                },
            },
            plotOptions: {
                series: {
                    cursor: "pointer",
                    point: {
                        events: {
                            click: (function (component) {
                                return function () {
                                    component.handleDateChange(
                                        arrayDates1 && arrayDates1.length > this.index
                                            ? new Date(arrayDates1[this.index])
                                            : ""
                                    );
                                };
                            })(this),
                        },
                    },
                },
            },
            xAxis: {
                categories: arrayDates,
                crosshair: true,
            },
            tooltip: {
                valueSuffix: "Hr",
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    showInLegend: false,
                    color: "#684D94",
                    name: "Productive",
                    data: arrayProductive,
                },
                // {
                //     showInLegend: true,
                //     color: '#9279b9',
                //     name: 'Unproductive',
                //     data: arrayUnproductive,
                // },
                // {
                //     showInLegend: true,
                //     color: '#E6D6F3',
                //     name: 'Undefined',
                //     data: arrayUndefined,
                // },
            ],
        };
        const employeeMoodchart = {
            chart: {
                type: "spline",
                height: 250,
                zoomType: "x",
                style: {
                    fontFamily: "Lato",
                    fontSize: "15px",
                },
            },
            title: {
                text: "",
            },
            yAxis: {
                title: {
                    text: `${t("average")}`,
                },
            },

            xAxis: {
                categories: arrayDates1,
                crosshair: true,
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    showInLegend: false,
                    color: "#684D94",
                    name: "Happiness",
                    data: arrayMood,
                },
            ],
        };

        const ProjectLink = (data) => {
            localStorage.setItem("dgfsdgf", JSON.stringify(data));
            localStorage.setItem("selected Date", JSON.stringify(this.state.selectedDate));
            let employeeSelected = employees.find((emp) => emp.user_login_as == data.Employee_name);

            let props1 = {
                selectedEmployee: employeeSelected.user_id,
                //   selectedProject: data.project,
                selectedDate: this.state.selectedDate,
                prevPath: window.location.pathname,
            };
            // this.props.selectedEmployee(props1);
            this.props.history.push({
                pathname: "/timetracker/",
                state: { from: window.location.pathname, data: props1 },
            });
        };

        const showCaseModalChange = (data) => {
            let props1 = {
                employeeId: data.id,
                name: data.name,
            };
            this.setState(
                {
                    selectedUser: props1,
                },
                () => {
                    this.props
                        .getShowcaseEntries(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            `user&user_id=${data.id}`
                        )
                        .then((res) => {
                            if (res.value.status === 200 && !res.value.data.message) {
                                this.setState({
                                    showcaseEntriesList: res.value.data.data,
                                    isShowcaseModalActive: !this.state.isShowcaseModalActive,
                                });
                            }
                        });
                }
            );
        };
        const setUserRole = (e) => {
            let ee = e.split(" ");
            setTimeout(() => {
                if (ee[1] === "admin") {
                    localStorage.setItem("roleUser", "admin");
                    localStorage.setItem("ro", "Admin");
                    // this.props.storeToLocalStorage('admin');
                    if (this.state.showObserverData) {
                        this.props.managerDashboardObserver(
                            moment(this.state.selectedDate).format("YYYY-MM-DD")
                        );
                    } else {
                        this.props.managerDashboard(
                            moment(this.state.selectedDate).format("YYYY-MM-DD")
                        );
                    }
                    const time = moment(new Date(this.state.selectedDate)).subtract(20, "minutes");
                    this.props.getCurrentStatus(
                        moment(this.state.selectedDate).format("YYYY-MM-DD"),
                        time.hours() + ":" + (time.minutes() - (time.minutes() % 10)) + ":00"
                    );
                    this.props.getEmployeeData(
                        moment(this.state.selectedDate).format("YYYY-MM-DD"),
                        time.hours() + ":" + (time.minutes() - (time.minutes() % 10)) + ":00"
                    );
                    // showData(true);
                } else if (ee[1] === "observer") {
                    localStorage.setItem("roleUser", "observer");
                    localStorage.setItem("ro", "Observer");
                    // this.props.storeToLocalStorage('observer');
                    this.props.managerdashboard_observer(
                        moment(this.state.selectedDate).format("YYYY-MM-DD")
                    );
                    // showData(true);
                }
                //  else {
                //     localStorage.setItem('roleUser', 'manager')
                //     this.props.managerDashboard(moment(this.state.selectedDate).format("YYYY-MM-DD"));
                //     this.props.storeToLocalStorage('admin');
                // }
            }, 500);
        };

        const showObserverDataToggle = () => {
            if (this.state.showObserverData) {
                this.setState({ showObserverData: false });
                this.props.managerDashboard(moment(this.state.selectedDate).format("YYYY-MM-DD"));
                const time = moment(new Date(this.state.selectedDate)).subtract(20, "minutes");
                this.props.getCurrentStatus(
                    moment(this.state.selectedDate).format("YYYY-MM-DD"),
                    time.hours() + ":" + (time.minutes() - (time.minutes() % 10)) + ":00"
                );
                this.props.getEmployeeData(
                    moment(this.state.selectedDate).format("YYYY-MM-DD"),
                    time.hours() + ":" + (time.minutes() - (time.minutes() % 10)) + ":00"
                );
            } else {
                this.setState({ showObserverData: true });
                this.props.managerDashboardObserver(
                    moment(this.state.selectedDate).format("YYYY-MM-DD")
                );
            }
        };

        // console.log("allprojectdata--",allprojectsdata)

        return (
            <Layout>
                <Transition.Root show={this.state.showNewLogin} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed z-10 inset-0 overflow-y-auto"
                        onClose={() => { }}>
                        <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents.*/}
                            <span
                                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                    {this.state.showCreatePassword === false ? (
                                        <div className="my-5">
                                            <div className="flex flex-col justify-center items-center">
                                                <p className="text-lg font-semibold">
                                                    {t("welcome")}{" "}
                                                    {localStorage.getItem("userName")}
                                                </p>
                                                <p className="text-gray-600 mt-2">
                                                    {t("workahubHelpMoreProductive")}
                                                </p>
                                                <p className="text-gray-600">
                                                    {t("workingFromHome")}
                                                </p>
                                            </div>

                                            <div className="mt-4 flex justify-center items-center">
                                                <button
                                                    className="bg-primary text-white font-semibold p-1 px-4 rounded-md shadow-sm transition duration-100 ease-in-out hover:-translate-y-1 hover:shadow-md"
                                                    onClick={() => {
                                                        this.setState({ showCreatePassword: true });
                                                    }}>
                                                    {t("createPasswordTologin")}
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex flex-col justify-center items-center">
                                            <p className="text-lg font-semibold">
                                                {t("createNewPassword")}
                                            </p>
                                            <div className="flex items-center mt-5 w-full -translate-x-1">
                                                <InputField
                                                    onInputChange={(e) => {
                                                        this.setState({
                                                            newPassword: e.target.value,
                                                        });
                                                    }}
                                                    FieldName={"New Password"}
                                                    type={
                                                        this.state.obscurePassword
                                                            ? "password"
                                                            : "text"
                                                    }
                                                />
                                                {!this.state.obscurePassword ? (
                                                    <EyeOffIcon
                                                        className="h-4 w-4 -ml-10 z-10 hover:cursor-pointer"
                                                        onClick={() => {
                                                            this.setState({
                                                                obscurePassword: true,
                                                            });
                                                        }}
                                                    />
                                                ) : (
                                                    <EyeIcon
                                                        className="h-4 w-4 -ml-10 z-10 hover:cursor-pointer"
                                                        onClick={() => {
                                                            this.setState({
                                                                obscurePassword: false,
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div className="flex items-center mt-4 w-full -translate-x-1">
                                                <InputField
                                                    onInputChange={(e) => {
                                                        this.setState({
                                                            confirmNewPassword: e.target.value,
                                                        });
                                                    }}
                                                    FieldName={"Confirm Password"}
                                                    type={
                                                        this.state.obscureConfirmPassword
                                                            ? "password"
                                                            : "text"
                                                    }
                                                />
                                                {!this.state.obscureConfirmPassword ? (
                                                    <EyeOffIcon
                                                        className="h-4 w-4 -ml-10 z-10 hover:cursor-pointer"
                                                        onClick={() => {
                                                            this.setState({
                                                                obscureConfirmPassword: true,
                                                            });
                                                        }}
                                                    />
                                                ) : (
                                                    <EyeIcon
                                                        className="h-4 w-4 -ml-10 z-10 hover:cursor-pointer"
                                                        onClick={() => {
                                                            this.setState({
                                                                obscureConfirmPassword: false,
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div className="mt-5 flex justify-center items-center">
                                                <button
                                                    className="bg-primary text-white font-semibold p-1 px-4 rounded-md shadow-sm transition duration-100 ease-in-out hover:-translate-y-1 hover:shadow-md"
                                                    onClick={() => {
                                                        this.firstpassword();
                                                    }}>
                                                    {t("letsGetIn")}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* <div className='w-full flex justify-end items-center'>
                    <select className='h-9 rounded-md border-gray-400'  onChange={(e) => {setUserRole(e.target.value)}}>
                       {this.state.userRoleList && this.state.userRoleList.map((el, idx) => (
                           <option key={idx}>{el.org} {el.role}</option>
                       ))}
                    </select>
                </div> */}

                <div className="bg-white px-2 py-3 border-b border-gray-200 rounded-lg shadow-md">
                    <div className="flex items-start max-w-full lg:items-center flex-col lg:flex-row justify-start lg:justify-between flex-wrap lg:flex-nowrap">
                        <div className="flex lg-m:w-full lg-m:justify-between text-textprimary text-lg font-medium mb-5 xl:mb-0">
                            <div className="flex relative">
                                <DatePicker
                                    className="rounded-md"
                                    dateFormat="MMMM dd yyyy"
                                    selected={new Date(this.state.selectedDate)}
                                    onChange={(date) => {
                                        this.handleDateChange(date);
                                        this.setState({
                                            startDate: new Date(
                                                moment(date).startOf("week").format("YYYY-MM-DD")
                                            ),
                                            endDate: new Date(
                                                moment(date).endOf("week").format("YYYY-MM-DD")
                                            ),
                                        });
                                    }}
                                />
                                <CalendarIcon className="absolute top-1 right-2 w-8"></CalendarIcon>
                            </div>
                            <button
                                type="button"
                                onClick={this.onTodayClick}
                                className="inline-block ml-3 px-4 py-2 xl:ml-5 xl:px-8 xl:py-2.5 bg-secondary text-white font-medium text-xs leading-tight uppercase rounded-lg shadow-md hover:bg-secondary hover:shadow-lg focus:bg-secondary focus:shadow-lg focus:outline-none focus:ring-0 active:bg-secondary active:shadow-lg transition duration-150 ease-in-out">
                                {t("today")}
                            </button>
                        </div>
                        <div className="flex lg-m:w-full items-center lg-m:justify-between flex-shrink-0">
                            <div className="flex items-center">
                                <button
                                    onClick={() => {
                                        showObserverDataToggle();
                                    }}
                                    className="translate-y-[1px] mx-2 border border-gray-300 rounded-[5px] p-[6px] text-gray-500 cursor-pointer hover:border-gray-400">
                                    {this.state.showObserverData ? (
                                        <span className="flex items-center">
                                            <img alt="" src={hideObsPng} className="h-4" />
                                            {t("hide")} {t("observer")}
                                        </span>
                                    ) : (
                                        <span className="flex items-center">
                                            <DocumentSearchIcon className="h-4" />
                                            {t("show")} {t("observer")}
                                        </span>
                                    )}
                                </button>
                                {/* <TimezoneSelect
                                placeholder={t("pleaseSelectTimeZone")}
                                className="rounded-lg border-none mr-4 lg:mr-4 w-56"
                                value={this.state.selectedTimezone}
                                timezones={{ ...allTimezones }}
                                onChange={(e) => {
                                    this.setState({ selectedTimezone: e.value });
                                    this.onTimeZoneChange(e);
                                }}
                            /> */}
                                <Link to="/inviteuser">
                                    <PrimaryButton
                                        disabled={
                                            localStorage.getItem("ro") === "Observer" ? true : false
                                        }
                                        buttontext={`${t("invite")} ${t("user")}`}></PrimaryButton>
                                </Link>
                            </div>
                            <div
                                className="flex mr-4 items-center hover:cursor-pointer"
                                disabled={userRole === "observer" ? true : false}>
                                <div
                                    className="pl-1 flex flex-nowrap text-primary items-center"
                                    onClick={() => {
                                        this.setState({
                                            isShowSettingsModal: true,
                                            modalData: this.state.dashboardLayout,
                                            modalArrangement: this.state.dashboardArrangement,
                                            modalHeading: t("layoutSetup"),
                                            onSubmitClickHandler: this.dashboardLayoutUpdateHandler,
                                        });
                                    }}>
                                    <BsGearFill className="mx-2 h-5 w-5" />
                                    <p>{t("layoutSetup")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex mt-4 items-center bg-gray-100">
                    <div className="w-full max-w-full mx-auto">
                        <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-5">
                            <div className="flex items-center justify-between p-5 bg-white rounded shadow-sm">
                                <div>
                                    <div className="text-sm text-gray-400 ">{t("timeLogged")}</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-primary ">
                                            {moment
                                                .duration(
                                                    dashboardKpi && dashboardKpi.total_time_logged,
                                                    "minutes"
                                                )
                                                .days() *
                                                24 +
                                                moment
                                                    .duration(
                                                        dashboardKpi &&
                                                        dashboardKpi.total_time_logged,
                                                        "minutes"
                                                    )
                                                    .hours() +
                                                " hrs " +
                                                moment
                                                    .duration(
                                                        dashboardKpi &&
                                                        dashboardKpi.total_time_logged,
                                                        "minutes"
                                                    )
                                                    .minutes() +
                                                " mins"}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-gray-300">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-8 h-8"
                                        fill="none"
                                        viewBox="0 0 47 46">
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M41.536 30.456a19.21 19.21 0 01-5.675 7.4 19.771 19.771 0 01-8.557 3.937c-3.138.608-6.38.455-9.444-.447a19.673 19.673 0 01-8.129-4.725 19.1 19.1 0 01-4.92-7.902 18.775 18.775 0 01-.564-9.237 18.98 18.98 0 013.923-8.419 19.538 19.538 0 017.497-5.639"></path>
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M43.083 23c0-2.517-.506-5.01-1.49-7.335a19.142 19.142 0 00-4.246-6.218 19.617 19.617 0 00-6.353-4.155A19.953 19.953 0 0023.5 3.833V23h19.583z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-5 bg-white rounded shadow-sm">
                                <div>
                                    <div className="text-sm text-gray-400 ">{t("onlineTime")}</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-primary ">
                                            {moment
                                                .duration(
                                                    dashboardKpi && dashboardKpi.online_time,
                                                    "minutes"
                                                )
                                                .days() *
                                                24 +
                                                moment
                                                    .duration(
                                                        dashboardKpi && dashboardKpi.online_time,
                                                        "minutes"
                                                    )
                                                    .hours() +
                                                " hrs " +
                                                moment
                                                    .duration(
                                                        dashboardKpi && dashboardKpi.online_time,
                                                        "minutes"
                                                    )
                                                    .minutes() +
                                                " mins"}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-gray-300">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-8 h-8"
                                        fill="none"
                                        viewBox="0 0 47 46">
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M41.536 30.456a19.21 19.21 0 01-5.675 7.4 19.771 19.771 0 01-8.557 3.937c-3.138.608-6.38.455-9.444-.447a19.673 19.673 0 01-8.129-4.725 19.1 19.1 0 01-4.92-7.902 18.775 18.775 0 01-.564-9.237 18.98 18.98 0 013.923-8.419 19.538 19.538 0 017.497-5.639"></path>
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M43.083 23c0-2.517-.506-5.01-1.49-7.335a19.142 19.142 0 00-4.246-6.218 19.617 19.617 0 00-6.353-4.155A19.953 19.953 0 0023.5 3.833V23h19.583z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-5 bg-white rounded shadow-sm">
                                <div>
                                    <div className="text-sm text-gray-400 ">
                                        {t("totalIdleTime")}
                                    </div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-primary">
                                            {moment
                                                .duration(
                                                    dashboardKpi && dashboardKpi.total_idle_time,
                                                    "minutes"
                                                )
                                                .days() *
                                                24 +
                                                moment
                                                    .duration(
                                                        dashboardKpi &&
                                                        dashboardKpi.total_idle_time,
                                                        "minutes"
                                                    )
                                                    .hours() +
                                                " hrs " +
                                                moment
                                                    .duration(
                                                        dashboardKpi &&
                                                        dashboardKpi.total_idle_time,
                                                        "minutes"
                                                    )
                                                    .minutes() +
                                                " mins"}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-8 h-8"
                                        fill="none"
                                        viewBox="0 0 47 46">
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M39.167 40.25v-3.833a7.585 7.585 0 00-2.295-5.422 7.92 7.92 0 00-5.539-2.245H15.667a7.92 7.92 0 00-5.54 2.245 7.585 7.585 0 00-2.294 5.422v3.833M23.5 21.083c4.326 0 7.833-3.432 7.833-7.666 0-4.235-3.507-7.667-7.833-7.667-4.326 0-7.833 3.432-7.833 7.667 0 4.234 3.507 7.666 7.833 7.666z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-5 bg-white rounded shadow-sm">
                                <div>
                                    <div className="text-sm text-gray-400 ">{t("empWorking")}</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-primary ">
                                            {dashboardKpi && dashboardKpi.Emp_working}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-gray-300">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-8 h-8"
                                        fill="none"
                                        viewBox="0 0 47 46">
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M39.167 40.25v-3.833a7.585 7.585 0 00-2.295-5.422 7.92 7.92 0 00-5.539-2.245H15.667a7.92 7.92 0 00-5.54 2.245 7.585 7.585 0 00-2.294 5.422v3.833M23.5 21.083c4.326 0 7.833-3.432 7.833-7.666 0-4.235-3.507-7.667-7.833-7.667-4.326 0-7.833 3.432-7.833 7.667 0 4.234 3.507 7.666 7.833 7.666z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-5 bg-white rounded shadow-sm">
                                <div>
                                    <div className="text-sm text-gray-400 ">
                                        {t("empNotLogged")}
                                    </div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-primary">
                                            {dashboardKpi && dashboardKpi.Emp_notlogged}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-8 h-8"
                                        fill="none"
                                        viewBox="0 0 47 46">
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M39.167 40.25v-3.833a7.585 7.585 0 00-2.295-5.422 7.92 7.92 0 00-5.539-2.245H15.667a7.92 7.92 0 00-5.54 2.245 7.585 7.585 0 00-2.294 5.422v3.833M23.5 21.083c4.326 0 7.833-3.432 7.833-7.666 0-4.235-3.507-7.667-7.833-7.667-4.326 0-7.833 3.432-7.833 7.667 0 4.234 3.507 7.666 7.833 7.666z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {listOFAllShowcaseEmployees && listOFAllShowcaseEmployees.length > 0 ? (
                    <div className="mt-4 mx-6 items-center bg-gray-100 2xl-m:mx-4 lg-m:mx-2">
                        <div className="flex px-2 mr-6 mb-4 justify-between items-baseline">
                            <div className="text-textprimary text-lg font-semibold">
                                {t("showCaseEntries")}
                            </div>
                            {listOFAllShowcaseEmployees && listOFAllShowcaseEmployees.length > 0 ? (
                                <Link
                                    to={`/dashboard/showcase/?date=${moment(
                                        this.state.selectedDate
                                    ).format("YYYY-MM-DD")}`}
                                    className="text-primary border-b-2 border-primary hover:cursor-pointer text-sm">
                                    {t("viewAll")}
                                </Link>
                            ) : null}
                        </div>
                        {this.state.isShowcaseModalActive ? (
                            <ModalShowCaseEntry
                                open={this.state.isShowcaseModalActive}
                                fromDashboard={true}
                                userDetails={this.state.selectedUser}
                                handleClose={() => {
                                    this.setState({ isShowcaseModalActive: false });
                                }}
                                handleModalShowChange={() => {
                                    this.setState({
                                        isShowcaseModalActive: !this.state.isShowcaseModalActive,
                                    });
                                }}
                                entryDetails={this.state.showcaseEntriesList}
                            />
                        ) : null}

                        <div className=" overflow-x-auto no-scrollbar flex">
                            {listOFAllShowcaseEmployees.map((e) => {
                                return (
                                    <div className="mr-5 flex flex-col justify-start items-center">
                                        <div
                                            className="border rounded-[2rem] min-w-[5.2rem] max-h-[5.2rem] max-w-[5.2rem] min-h-[5.2rem] bg-gradient-to-b from-[#B92B27] to-[#1565C0]"
                                            onClick={() => {
                                                showCaseModalChange(e);
                                            }}>
                                            <div className=" bg-gray-200 w-full h-full rounded-[2rem] scale-95 m-auto text-center flex flex-col justify-center hover:cursor-pointer">
                                                <div className=" text-gray-500 font-semibold uppercase">
                                                    {e.name
                                                        .split(" ")
                                                        .map((name) => name.charAt(0))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center text-textprimary capitalize">
                                            {e.name.split(" ")[0]}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : null}

                <div className="flex mt-4 items-center bg-gray-100">
                    <div className="w-full max-w-full mx-auto">
                        <div className="grid gap-3 grid-cols-2 lg:grid-cols-3">
                            <div className="col-span-2 p-5 bg-white rounded shadow-md">
                                <div className="flex items-center justify-between">
                                    <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                                        <div className="md:hidden">
                                            <label htmlFor="tabs" className="sr-only">
                                                {t("requestsModule.selectATab")}
                                            </label>
                                            <select
                                                id="tabs"
                                                name="tabs"
                                                onChange={(e) => this.HandleMobileSelectTab(e)}
                                                className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                                                defaultValue={this.state.SelectedTab}>
                                                <option key="Productivity" value="Productivity">
                                                    {t("ReportsTab.productivity")}
                                                </option>
                                                {/* <option key="HappinessGraph" value="HappinessGraph">
                                                    {t("workersHappinessGraph")}
                                                </option> */}
                                            </select>
                                        </div>
                                        <div className="hidden md:block">
                                            <div
                                                className="-mb-px flex space-x-4"
                                                aria-label="Tabs">
                                                <div
                                                    key="Productivity"
                                                    onClick={() =>
                                                        this.HandleSelectTab("Productivity")
                                                    }
                                                    className={classNames(
                                                        this.state.SelectedTab === "Productivity"
                                                            ? "border-primary text-primary"
                                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                                        "group inline-flex items-center py-2 px-1 border-b-2 font-medium text-md cursor-pointer"
                                                    )}
                                                    aria-current={
                                                        this.state.SelectedTab === "Productivity"
                                                            ? "page"
                                                            : undefined
                                                    }>
                                                    <span>{t("ReportsTab.productivity")}</span>
                                                </div>
                                                {/* 
                                                <div
                                                    key="HappinessGraph"
                                                    onClick={() =>
                                                        this.HandleSelectTab("HappinessGraph")
                                                    }
                                                    className={classNames(
                                                        this.state.SelectedTab === "HappinessGraph"
                                                            ? "border-primary text-primary"
                                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                                        "group inline-flex items-center py-2 px-1 border-b-2 font-medium text-md cursor-pointer"
                                                    )}
                                                    aria-current={
                                                        this.state.SelectedTab === "HappinessGraph"
                                                            ? "page"
                                                            : undefined
                                                    }>
                                                    <span>{t("workersHappinessGraph")}</span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        {/* {this.state.SelectedTab !== "HappinessGraph" ? (
                                            <div className="text-sm text-textprimary flex">
                                                <SelectField
                                                    onSelectChange={(e) => {
                                                        this.handleSelectEmployee(e);
                                                    }}
                                                    name={t("select") + " " + t("employee")}
                                                    arrayOfData={listofAllEmployees}
                                                    id={"name"}></SelectField>
                                            </div>
                                        ) : null} */}
                                        <div className="flex justify-end items-center mt-2">
                                            <button
                                                className=""
                                                onClick={() => {
                                                    lastWeekprodGraph();
                                                }}>
                                                <ChevronLeftIcon className="text-gray-600 h-5 w-5" />
                                            </button>
                                            <p className="mx-1 text-gray-500 text-sm">
                                            {moment(this.state.startDate).startOf('week').format("YYYY-MM-DD")}{" "}
                                               <span className="mx-1 text-md">to</span>{" "}
                                               {moment(this.state.endDate).endOf('week').format("YYYY-MM-DD")}
                                            </p>
                                            <button
                                                className=""
                                                onClick={() => {
                                                    nextWeekprodGraph();
                                                }}>
                                                <ChevronRightIcon className="text-gray-600 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {
                                    this.state.SelectedTab !== "HappinessGraph" ? (
                                        this.state.productivitygraphloading ? (
                                            <div className="text-center h-64 w-full flex justify-center items-center">
                                                <BeatLoader
                                                    color={"#684994"}
                                                    productivitygraphloading={
                                                        this.state.productivitygraphloading
                                                    }
                                                    size={15}
                                                    margin={2}
                                                />
                                            </div>
                                        ) : (
                                            <div className="mt-6">
                                                {" "}
                                                <ReactHighcharts config={productivitychart}>
                                                    {" "}
                                                </ReactHighcharts>{" "}
                                            </div>
                                        )
                                    ) : // : (
                                        //     <div className="text-center mt-16">
                                        //         <img
                                        //             alt=""
                                        //             className="m-auto"
                                        //             src={require("../../images/nodata.png")}
                                        //         />
                                        //         <div className="text-lg mt-2 text-textprimary font-semibold">
                                        //             {t("please")} {t("select")} {t("an")}{" "}
                                        //             {t("employee")}
                                        //         </div>
                                        //     </div>
                                        // )
                                        this.state.productivitygraphloading ? (
                                            <div className="text-center h-64 w-full flex justify-center items-center">
                                                <BeatLoader
                                                    color={"#684994"}
                                                    productivitygraphloading={
                                                        this.state.productivitygraphloading
                                                    }
                                                    size={15}
                                                    margin={2}
                                                />
                                            </div>
                                        ) : (
                                            <div className="mt-6">
                                                {" "}
                                                <ReactHighcharts config={employeeMoodchart}>
                                                    {" "}
                                                </ReactHighcharts>{" "}
                                            </div>
                                        )
                                    // : (
                                    //     <div className="text-center mt-16">
                                    //         <img
                                    //             alt=""
                                    //             className="m-auto"
                                    //             src={require("../../images/nodata.png")}
                                    //         />
                                    //         <div className="text-lg mt-2 text-textprimary font-semibold">
                                    //             {t("please")} {t("select")} {t("an")} {t("employee")}
                                    //         </div>
                                    //     </div>
                                    // )
                                }
                            </div>

                            <div className="p-5 bg-white rounded shadow-md lg-m:col-span-2">
                                <div className="text-textprimary text-lg font-semibold">
                                    {" "}
                                    {t("currentStatus")}
                                </div>
                                <div className="mt-4">
                                    {" "}
                                    <ReactHighcharts config={AttendanceChart}>
                                        {" "}
                                    </ReactHighcharts>{" "}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-stretch gap-1">
                    {this.state.dashboardLayout !== "" &&
                        this.state.dashboardLayout.team_table &&
                        this.state.dashboardArrangement ? (
                        <div className={`order-${this.state.dashboardArrangement.team_table}`}>
                            <div className="bg-white mt-4 px-2 pt-1 pb-8 flex flex-col border-b border-gray-200 shadow-md rounded-lg">
                                <div className="flex items-center justify-between flex-wrap md:flex-nowrap">
                                    <div className="ml-4 mt-4 text-textprimary text-lg flex flex-nowrap items-center font-semibold">
                                        <p>{t("teams")}</p>
                                        <div
                                            className="text-primary hover:cursor-pointer"
                                            onClick={() => {
                                                this.setState({
                                                    isShowSettingsModal: true,
                                                    modalData: this.state.teamsTableLayout,
                                                    modalArrangement:
                                                        this.state.teamsTableArrangement,
                                                    modalHeading: "Layout Setup",
                                                    onSubmitClickHandler:
                                                        this.teamTableLayoutUpdateHandler,
                                                });
                                            }}>
                                            <BsGearFill className="mx-2 h-4 w-4" />
                                        </div>
                                    </div>
                                    <div className="ml-4 mt-4 flex-shrink-0">
                                        <Link to="/team">
                                            <PrimaryButton
                                                disabled={
                                                    localStorage.getItem("ro") === "Observer"
                                                        ? true
                                                        : false
                                                }
                                                buttontext={`${t("add")} ${t(
                                                    "team"
                                                )}`}></PrimaryButton>
                                        </Link>
                                    </div>
                                </div>
                                <div className="mt-4 ml-4 flex flex-col overflow-y-auto bg-gray-100">
                                    <div className="inline-block min-w-full align-middle">
                                        <div className="shadow-sm ring-1 ring-black ring-opacity-5 overflow-x-auto">
                                            <table className="border-separate min-w-max w-full">
                                                <thead className="bg-gray-50 min-w-full">
                                                    <tr
                                                        className={`grid grid-cols-${this.state.teamsCols} min-w-full`}>
                                                        {this.state.teamsTableLayout["name"] ? (
                                                           
                                                            <th
                                                                scope="col"
                                                                className={`order-${this.state.teamsTableArrangement["name"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter flex flex-row items-center`}>
                                                                {t("name")}
                                                                {this.state.isSort && this.state.sortingOrder!=="ascendingtname"?(
                                                               <button title="Sort Ascending" onClick={onclickAscendingtname}
                                                               className={`flex flex-col ml-3 sorting-descendingtname`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           ):(
                                                               <button title="Sort Descending" onClick={onclickDescendingtname}
                                                               className={`flex flex-col ml-3 sorting-ascendingtname`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           )}
                                                            </th>
                                                            ) : null}
                                                            {this.state.teamsTableLayout[
                                                                "hours_spend"
                                                            ] ? (
                                                                <th
                                                                    scope="col"
                                                                    className={`order-${this.state.teamsTableArrangement["hours_spend"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter flex flex-row items-center`}>
                                                                    {t("totalHoursSpend")}
                                                                    {this.state.isSort && this.state.sortingOrder!=="ascendingtworked"?(
                                                               <button title="Sort Ascending" onClick={onclickAscendingtworked}
                                                               className={`flex flex-col ml-3 sorting-descendingtworked`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           ):(
                                                               <button title="Sort Descending" onClick={onclickDescendingtworked}
                                                               className={`flex flex-col ml-3 sorting-ascendingtworked`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           )}
                                                                </th>

                                                            ) : null}
                                                            {
                                                                this.state.teamsTableLayout[
                                                                    "worked"
                                                                ] ? (
                                                                    <th
                                                                        scope="col"
                                                                        className={` order-${this.state.teamsTableArrangement["worked"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter flex flex-row items-center`}>
                                                                        {t("worked")}
                                                                        {this.state.isSort && this.state.sortingOrder!=="ascendingpeople"?(
                                                               <button title="Sort Ascending" onClick={onclickAscendingpeople}
                                                               className={`flex flex-col ml-3 sorting-descendingpeople`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           ):(
                                                               <button title="Sort Descending" onClick={onclickDescendingpeople}
                                                               className={`flex flex-col ml-3 sorting-ascendingpeople`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           )}
                                                                    </th>
                                                                ) : null
                                                            }

                                                        </tr>
                                                </thead>
                                                <tbody className="bg-white"> 
                                                    {uniqueteamarr &&
                                                        (this.state.isSort2 == true
                                                            ? this.state.sortedTeam
                                                            : uniqueteamarr
                                                        ).map((el, i) => (
                                                            <tr
                                                                key={i}
                                                                className={`grid grid-cols-${this.state.teamsCols} min-w-full`}>
                                                                {this.state.teamsTableLayout[
                                                                    "name"
                                                                ] ? (
                                                                    <td
                                                                        onClick={() => {
                                                                            this.handleTeamNavigation(
                                                                                el.team_id,
                                                                                el,
                                                                                el.team_name
                                                                            );
                                                                        }}
                                                                        className={classNames(
                                                                            i !==
                                                                                uniqueteamarr.length -
                                                                                1
                                                                                ? "border-b border-gray-200"
                                                                                : "",
                                                                            `order-${this.state.teamsTableArrangement["name"]} whitespace-nowrap py-4 pl-4 pr-3 text-base cursor-pointer font-medium text-primary`
                                                                        )}>
                                                                        {el.team_name}
                                                                    </td>
                                                                ) : null}
                                                                {this.state.teamsTableLayout[
                                                                    "hours_spend"
                                                                ] ? (
                                                                    <td
                                                                        className={classNames(
                                                                            i !==
                                                                                uniqueteamarr.length -
                                                                                1
                                                                                ? "border-b border-gray-200"
                                                                                : "",
                                                                            `order-${this.state.teamsTableArrangement["hours_spend"]} whitespace-nowrap px-3 py-4 text-base text-gray-500 `
                                                                        )}>
                                                                        {moment
                                                                            .duration(
                                                                                el.time_worked,
                                                                                "minutes"
                                                                            )
                                                                            .days() *
                                                                            24 +
                                                                            moment
                                                                                .duration(
                                                                                    el.time_worked,
                                                                                    "minutes"
                                                                                )
                                                                                .hours() +
                                                                            " hrs " +
                                                                            moment
                                                                                .duration(
                                                                                    el.time_worked,
                                                                                    "minutes"
                                                                                )
                                                                                .minutes() +
                                                                            " mins"}
                                                                    </td>
                                                                ) : null}
                                                                {this.state.teamsTableLayout[
                                                                    "worked"
                                                                ] ? (
                                                                    <td
                                                                        className={classNames(
                                                                            i !==
                                                                                uniqueteamarr.length -
                                                                                1
                                                                                ? "border-b border-gray-200"
                                                                                : "",
                                                                            `order-${this.state.teamsTableArrangement["worked"]} whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                        )}>
                                                                        {el.number_of_people}
                                                                    </td>
                                                                ) : null}
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {this.state.dashboardLayout !== "" &&
                        this.state.dashboardLayout.all_employee &&
                        this.state.dashboardArrangement ? (
                        <div
                            className={`w-full max-w-full order-${this.state.dashboardArrangement.all_employee}`}>
                            <div className="bg-white mt-4 px-2 pt-1 pb-8 flex flex-col border-b border-gray-200 shadow-md rounded-lg">
                                <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-4 text-textprimary text-lg flex flex-nowrap items-center font-semibold">
                                        <p>
                                            {t("all")} {t("empWorking")}
                                        </p>
                                        <div
                                            className="text-primary hover:cursor-pointer"
                                            onClick={() => {
                                                this.setState({
                                                    isShowSettingsModal: true,
                                                    modalData:
                                                        this.state.employeeWorkingTableLayout,
                                                    modalArrangement:
                                                        this.state.employeeWorkingTableArrangement,
                                                    modalHeading: t("layoutSetup"),
                                                    onSubmitClickHandler:
                                                        this.employeeWorkingLayoutUpdateHandler,
                                                });
                                            }}>
                                            <BsGearFill className="mx-2 h-4 w-4" />
                                        </div>
                                    </div>
                                    <div className="ml-4 mt-4 flex-shrink-0"></div>
                                </div>
                                <div className="mt-4 ml-4 flex flex-col overflow-y-auto bg-gray-100">
                                    <div className="inline-block min-w-full max-w-full align-middle">
                                        <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[580px] overflow-auto">
                                            <table className="w-full border-separate">
                                                <thead className="bg-gray-50 sticky top-0 z-10">
                                                    <tr
                                                        className={`grid grid-cols-${this.state.empWorkingCols}`}>
                                                        {this.state.employeeWorkingTableLayout[
                                                            "employee_name"
                                                        ] ? (
                                                            <th
                                                                    scope="col"
                                                                className={`order-${this.state.employeeWorkingTableArrangement["employee_name"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter flex flex-row items-center`}>
                                                                {t("employee")} {t("name")}{" "}
                                                                {this.state.isSort && this.state.sortingOrder!=="ascendingename"?(
                                                                    <button title="Sort Ascending" onClick={onClickAssending}
                                                                    className={`flex flex-col ml-3 sorting-descendingename`}>
                                                                        <IoMdArrowDropup className="my-0 text-xs" />
                                                                        <IoMdArrowDropdown className="my-0 text-xs" />
                                                                    </button>
                                                                ):(
                                                                    <button title="Sort Descending" onClick={onClickDescending}
                                                                    className={`flex flex-col ml-3 sorting-ascendingename`}>
                                                                        <IoMdArrowDropup className="my-0 text-xs" />
                                                                        <IoMdArrowDropdown className="my-0 text-xs" />
                                                                    </button>
                                                                )}
                                                            </th>
                                                        ) : null}
                                                        {this.state.employeeWorkingTableLayout[
                                                            "time_logged"
                                                        ] ? (
                                                            <th
                                                                scope="col"
                                                                className={`order-${this.state.employeeWorkingTableArrangement["time_logged"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter flex flex-row items-center`}>
                                                                {t("timeLogged")}
                                                                {this.state.isSort && this.state.sortingOrder!=="ascendingtimelogged"?(
                                                                    <button title="Sort Ascending" onClick={onClickAssending2}
                                                                    className={`flex flex-col ml-3 sorting-ascendingtimelogged`}>
                                                                        <IoMdArrowDropup className="my-0 text-xs" />
                                                                        <IoMdArrowDropdown className="my-0 text-xs" />
                                                                    </button>
                                                                ):(
                                                                    <button title="Sort Descending" onClick={onClickDescending2}
                                                                    className={`flex flex-col ml-3 sorting-descendingtimelogged`}>
                                                                        <IoMdArrowDropup className="my-0 text-xs" />
                                                                        <IoMdArrowDropdown className="my-0 text-xs" />
                                                                    </button>
                                                                )}
                                                            </th>
                                                        ) : null}
                                                        {this.state.employeeWorkingTableLayout[
                                                            "category"
                                                        ] ? (
                                                            <th
                                                                scope="col"
                                                                className={`order-${this.state.employeeWorkingTableArrangement["category"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                                Category
                                                            </th>
                                                        ) : null}
                                                        {this.state.employeeWorkingTableLayout[
                                                            "task"
                                                        ] ? (
                                                            <th
                                                                scope="col"
                                                                className={`order-${this.state.employeeWorkingTableArrangement["task"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                                {t("task")}
                                                            </th>
                                                        ) : null}
                                                        {this.state.employeeWorkingTableLayout[
                                                            "project"
                                                        ] ? (
                                                            <th
                                                                scope="col"
                                                                className={`order-${this.state.employeeWorkingTableArrangement["project"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter flex flex-row items-center`}>
                                                                {t("project")}
                                                                {this.state.isSort && this.state.sortingOrder!=="ascendingproject"?(
                                                                    <button title="Sort Ascending" onClick={onClickAscendingproject}
                                                                    className={`flex flex-col ml-3 sorting-ascendingproject`}>
                                                                        <IoMdArrowDropup className="my-0 text-xs" />
                                                                        <IoMdArrowDropdown className="my-0 text-xs" />
                                                                    </button>
                                                                ):(
                                                                    <button title="Sort Descending" onClick={onClickDescendingproject}
                                                                    className={`flex flex-col ml-3 sorting-descendingproject`}>
                                                                        <IoMdArrowDropup className="my-0 text-xs" />
                                                                        <IoMdArrowDropdown className="my-0 text-xs" />
                                                                    </button>
                                                                )}
                                                            </th>
                                                        ) : null}
                                                        {this.state.employeeWorkingTableLayout[
                                                            "team"
                                                        ] ? (
                                                            <th
                                                                scope="col"
                                                                className={`order-${this.state.employeeWorkingTableArrangement["team"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter flex flex-row items-center`}>
                                                                {t("team")}
                                                                 {this.state.isSort && this.state.sortingOrder!=="ascendingeteam"?(
                                                                    <button title="Sort Ascending" onClick={onClickAscendingEteam}
                                                                    className={`flex flex-col ml-3 sorting-descendingeteam`}>
                                                                        <IoMdArrowDropup className="my-0 text-xs" />
                                                                        <IoMdArrowDropdown className="my-0 text-xs" />
                                                                    </button>
                                                                ):(
                                                                    <button title="Sort Descending" onClick={onClickDescendingEteam}
                                                                    className={`flex flex-col ml-3 sorting-ascendingeteam`}>
                                                                        <IoMdArrowDropup className="my-0 text-xs" />
                                                                        <IoMdArrowDropdown className="my-0 text-xs" />
                                                                    </button>
                                                                )}
                                                            </th>
                                                        ) : null}
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {allemployeesworkingdata &&
                                                        (this.state.isSort == true
                                                            ? this.state.sortedEmployee
                                                            : allemployeesworkingdata
                                                        ).map((el, i) =>
                                                            el.Employee_name ===
                                                                this.state.selectedEmployeeToExpand
                                                                    .userName ? (
                                                                <div>
                                                                    {el.Employee_name ===
                                                                        this.state
                                                                            .selectedEmployeeToExpand
                                                                            .userName &&
                                                                        this.state
                                                                            .employeeProjectDetail ? (
                                                                        <div className="bg-gray-100">
                                                                            {this.state
                                                                                .employeeProjectDetail &&
                                                                                this.state.employeeProjectDetail.map(
                                                                                    (e, idx) => (
                                                                                        <div
                                                                                            key={
                                                                                                "detailed_view" +
                                                                                                idx
                                                                                            }
                                                                                            className={`grid grid-cols-${this.state.empWorkingCols} relative border-b border-gray-200`}>
                                                                                            {this
                                                                                                .state
                                                                                                .employeeWorkingTableLayout[
                                                                                                "employee_name"
                                                                                            ] ? (
                                                                                                <td
                                                                                                    className={classNames(
                                                                                                        i !==
                                                                                                            allemployeesworkingdata.length -
                                                                                                            1
                                                                                                            ? "border-b border-gray-200"
                                                                                                            : "",
                                                                                                        `order-${this.state.employeeWorkingTableArrangement["employee_name"]} py-4 pl-4 pr-3 text-base cursor-pointer font-medium text-primary`
                                                                                                    )}>
                                                                                                    {e.username.includes(
                                                                                                        "from"
                                                                                                    ) ? (
                                                                                                        <span className="flex">
                                                                                                            <DocumentSearchIcon className="h-4 translate-y-[1px] mr-1" />{" "}
                                                                                                            {
                                                                                                                e.username
                                                                                                            }
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        e.username
                                                                                                    )}
                                                                                                </td>
                                                                                            ) : null}
                                                                                            {this
                                                                                                .state
                                                                                                .employeeWorkingTableLayout[
                                                                                                "time_logged"
                                                                                            ] ? (
                                                                                                <td
                                                                                                    className={classNames(
                                                                                                        i !==
                                                                                                            allemployeesworkingdata.length -
                                                                                                            1
                                                                                                            ? "border-b border-gray-200"
                                                                                                            : "",
                                                                                                        `order-${this.state.employeeWorkingTableArrangement["time_logged"]} px-3 py-4 text-base text-gray-500`
                                                                                                    )}>
                                                                                                    {moment
                                                                                                        .duration(
                                                                                                            e.duration,
                                                                                                            "minutes"
                                                                                                        )
                                                                                                        .days() *
                                                                                                        24 +
                                                                                                        moment
                                                                                                            .duration(
                                                                                                                e.duration,
                                                                                                                "minutes"
                                                                                                            )
                                                                                                            .hours() +
                                                                                                        " hrs " +
                                                                                                        moment
                                                                                                            .duration(
                                                                                                                e.duration,
                                                                                                                "minutes"
                                                                                                            )
                                                                                                            .minutes() +
                                                                                                        " mins"}
                                                                                                </td>
                                                                                            ) : null}
                                                                                            {this
                                                                                                .state
                                                                                                .employeeWorkingTableLayout[
                                                                                                "category"
                                                                                            ] ? (
                                                                                                <td
                                                                                                    className={classNames(
                                                                                                        i !==
                                                                                                            allemployeesworkingdata.length -
                                                                                                            1
                                                                                                            ? "border-b border-gray-200"
                                                                                                            : "",
                                                                                                        `order-${this.state.employeeWorkingTableArrangement["category"]} px-3 py-4 text-base text-gray-500 lg:table-cell`
                                                                                                    )}>
                                                                                                    {
                                                                                                        e.category
                                                                                                    }
                                                                                                </td>
                                                                                            ) : null}
                                                                                            {this
                                                                                                .state
                                                                                                .employeeWorkingTableLayout[
                                                                                                "task"
                                                                                            ] ? (
                                                                                                <td
                                                                                                    className={classNames(
                                                                                                        i !==
                                                                                                            allemployeesworkingdata.length -
                                                                                                            1
                                                                                                            ? "border-b border-gray-200"
                                                                                                            : "",
                                                                                                        `order-${this.state.employeeWorkingTableArrangement["task"]} px-3 py-4 text-base text-gray-500 lg:table-cell`
                                                                                                    )}>
                                                                                                    {
                                                                                                        e.task
                                                                                                    }
                                                                                                </td>
                                                                                            ) : null}
                                                                                            {this
                                                                                                .state
                                                                                                .employeeWorkingTableLayout[
                                                                                                "team"
                                                                                            ] ? (
                                                                                                <td
                                                                                                    className={classNames(
                                                                                                        i !==
                                                                                                            allemployeesworkingdata.length -
                                                                                                            1
                                                                                                            ? "border-b border-gray-200"
                                                                                                            : "",
                                                                                                        `order-${this.state.employeeWorkingTableArrangement["team"]} hover:cursor-pointer whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                                                    )}>
                                                                                                    <div className="flex justify-between">
                                                                                                        <div className="max-w-fit">
                                                                                                            {
                                                                                                                e.team
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                            ) : null}
                                                                                            {this
                                                                                                .state
                                                                                                .employeeWorkingTableLayout[
                                                                                                "project"
                                                                                            ] ? (
                                                                                                <td
                                                                                                    className={classNames(
                                                                                                        i !==
                                                                                                            allemployeesworkingdata.length -
                                                                                                            1
                                                                                                            ? "border-b border-gray-200"
                                                                                                            : "",
                                                                                                        `order-${this.state.employeeWorkingTableArrangement["project"]} hover:cursor-pointer whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                                                    )}>
                                                                                                    <div className="flex justify-between">
                                                                                                        <div className="max-w-fit">
                                                                                                            {
                                                                                                                e.project
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                            ) : null}
                                                                                            {idx ===
                                                                                                0 && (
                                                                                                    <ChevronUpIcon
                                                                                                        onClick={
                                                                                                            idx ===
                                                                                                                0
                                                                                                                ? () =>
                                                                                                                    this.setState(
                                                                                                                        {
                                                                                                                            selectedEmployeeToExpand:
                                                                                                                                {},
                                                                                                                        }
                                                                                                                    )
                                                                                                                : () => { }
                                                                                                        }
                                                                                                        className="w-5 cursor-pointer absolute right-0 top-4 h-5 mr-4"
                                                                                                    />
                                                                                                )}
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                        </div>
                                                                    ) : el.Employee_name ===
                                                                        this.state
                                                                            .selectedEmployeeToExpand
                                                                            .userName &&
                                                                        this.state
                                                                            .employeeProjectDetail &&
                                                                        this.state
                                                                            .employeeProjectDetail
                                                                            .length < 2 ? (
                                                                        <div className="flex justify-center border-b border-gray-200 py-4 bg-gray-100">
                                                                            No other project data
                                                                        </div>
                                                                    ) : (
                                                                        el.Employee_name ===
                                                                        this.state
                                                                            .selectedEmployeeToExpand
                                                                            .userName && (
                                                                            <div className="flex justify-center border-b border-gray-200 py-4 bg-gray-100">
                                                                                <CircularProgress
                                                                                    color="rgb(104,77,148)"
                                                                                    className="text-primary"
                                                                                />
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <tr
                                                                    key={i}
                                                                    className={`grid relative grid-cols-${this.state.empWorkingCols
                                                                        } ${el.Employee_name ===
                                                                        this.state
                                                                            .selectedEmployeeToExpand
                                                                            .userName &&
                                                                        "border-x border-gray-200"
                                                                        }`}>
                                                                    {this.state
                                                                        .employeeWorkingTableLayout[
                                                                        "employee_name"
                                                                    ] ? (
                                                                        <td
                                                                            className={classNames(
                                                                                i !==
                                                                                    allemployeesworkingdata.length -
                                                                                    1 &&
                                                                                    this.state
                                                                                        .selectedEmployeeToExpand
                                                                                        .userName !==
                                                                                    el.Employee_name
                                                                                    ? "border-b border-gray-200"
                                                                                    : "",
                                                                                `order-${this.state.employeeWorkingTableArrangement["employee_name"]} py-4 pl-4 pr-3 text-base cursor-pointer font-medium text-primary`
                                                                            )}
                                                                            onClick={() => {
                                                                                ProjectLink(el);
                                                                            }}>
                                                                            {el.Employee_name.includes(
                                                                                "from"
                                                                            ) ? (
                                                                                <span className="flex">
                                                                                    <DocumentSearchIcon className="h-4 translate-y-[1px] mr-1" />{" "}
                                                                                    {
                                                                                        el.Employee_name
                                                                                    }
                                                                                </span>
                                                                            ) : (
                                                                                el.Employee_name
                                                                            )}
                                                                        </td>
                                                                    ) : null}
                                                                    {this.state
                                                                        .employeeWorkingTableLayout[
                                                                        "time_logged"
                                                                    ] ? (
                                                                        <td
                                                                            className={classNames(
                                                                                i !==
                                                                                    allemployeesworkingdata.length -
                                                                                    1 &&
                                                                                    this.state
                                                                                        .selectedEmployeeToExpand
                                                                                        .userName !==
                                                                                    el.Employee_name
                                                                                    ? "border-b border-gray-200"
                                                                                    : "",
                                                                                `order-${this.state.employeeWorkingTableArrangement["time_logged"]} px-3 py-4 text-base text-gray-500`
                                                                            )}>
                                                                            {moment
                                                                                .duration(
                                                                                    el.time_logged2,
                                                                                    "minutes"
                                                                                )
                                                                                .days() *
                                                                                24 +
                                                                                moment
                                                                                    .duration(
                                                                                        el.time_logged2,
                                                                                        "minutes"
                                                                                    )
                                                                                    .hours() +
                                                                                " hrs " +
                                                                                moment
                                                                                    .duration(
                                                                                        el.time_logged2,
                                                                                        "minutes"
                                                                                    )
                                                                                    .minutes() +
                                                                                " mins"}
                                                                        </td>
                                                                    ) : null}
                                                                    {this.state
                                                                        .employeeWorkingTableLayout[
                                                                        "category"
                                                                    ] ? (
                                                                        <td
                                                                            className={classNames(
                                                                                i !==
                                                                                    allemployeesworkingdata.length -
                                                                                    1 &&
                                                                                    this.state
                                                                                        .selectedEmployeeToExpand
                                                                                        .userName !==
                                                                                    el.Employee_name
                                                                                    ? "border-b border-gray-200"
                                                                                    : "",
                                                                                `order-${this.state.employeeWorkingTableArrangement["category"]} px-3 py-4 text-base text-gray-500 lg:table-cell`
                                                                            )}>
                                                                            {el.category}
                                                                        </td>
                                                                    ) : null}
                                                                    {this.state
                                                                        .employeeWorkingTableLayout[
                                                                        "task"
                                                                    ] ? (
                                                                        <td
                                                                            className={classNames(
                                                                                i !==
                                                                                    allemployeesworkingdata.length -
                                                                                    1 &&
                                                                                    this.state
                                                                                        .selectedEmployeeToExpand
                                                                                        .userName !==
                                                                                    el.Employee_name
                                                                                    ? "border-b border-gray-200"
                                                                                    : "",
                                                                                `order-${this.state.employeeWorkingTableArrangement["task"]} px-3 py-4 flex items-center gap-2 text-base text-gray-500`
                                                                            )}>
                                                                            {this.state
                                                                                .editButtonClicked
                                                                                .id !== i ? (
                                                                                <span>
                                                                                    {el.task_list.toString()}
                                                                                </span>
                                                                            ) : (
                                                                                <div>
                                                                                    <textarea
                                                                                        className="w-full h-40 p-2 border rounded-md resize-none"
                                                                                        defaultValue={el.task_list.toString()}
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            const newtaskname =
                                                                                                e
                                                                                                    .target
                                                                                                    .value;
                                                                                            this.setState(
                                                                                                {
                                                                                                    changedName:
                                                                                                        newtaskname,
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    <p
                                                                                        onClick={() => {
                                                                                            // Assuming 'i' is defined somewhere in your code
                                                                                            //const updatedTaskName = el.task_list.toString(); // You might want to change this based on your logic
                                                                                            const updatedArray =
                                                                                                [
                                                                                                    ...this
                                                                                                        .state
                                                                                                        .newTaskName,
                                                                                                ]; // Assuming newTaskName is an array in your state
                                                                                            updatedArray[
                                                                                                i
                                                                                            ] =
                                                                                                this.state.changedName;
                                                                                            this.setState(
                                                                                                {
                                                                                                    newTaskName:
                                                                                                        updatedArray,
                                                                                                    editButtonClicked:{value:false}
                                                                                                }
                                                                                            )
                                                                                        }}
                                                                                        className="text-right cursor-pointer">
                                                                                        yes
                                                                                    </p>
                                                                                </div>
                                                                            )}

                                                                            <PencilIcon
                                                                                key={i}
                                                                                className={`mr-4 w-5  hover:cursor-pointer`}
                                                                                onClick={() => {
                                                                                    this.setState(
                                                                                        (
                                                                                            prevState
                                                                                        ) => ({
                                                                                            editButtonClicked:
                                                                                            {
                                                                                                ...prevState.editButtonClicked,
                                                                                                id: i,
                                                                                                value: !prevState
                                                                                                    .editButtonClicked
                                                                                                    .value,
                                                                                            },
                                                                                        })
                                                                                    );
                                                                                    console.log(
                                                                                        "state",
                                                                                        this.state
                                                                                            .editButtonClicked
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </td>
                                                                    ) : null}
                                                                    {this.state
                                                                        .employeeWorkingTableLayout[
                                                                        "team"
                                                                    ] ? (
                                                                        <td
                                                                            onClick={() => {
                                                                                handleTeamNavigation2(
                                                                                    el.team
                                                                                );
                                                                            }}
                                                                            className={classNames(
                                                                                i !==
                                                                                    allemployeesworkingdata.length -
                                                                                    1 &&
                                                                                    this.state
                                                                                        .selectedEmployeeToExpand
                                                                                        .userName !==
                                                                                    el.Employee_name
                                                                                    ? "border-b border-gray-200"
                                                                                    : "",
                                                                                `order-${this.state.employeeWorkingTableArrangement["team"]} hover:cursor-pointer whitespace-nowrap px-3 py-4 text-base text-primary`
                                                                            )}>
                                                                            <div className="flex justify-between">
                                                                                <div className="max-w-fit">
                                                                                    {el.team_list.toString()}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    ) : null}
                                                                    {this.state
                                                                        .employeeWorkingTableLayout[
                                                                        "project"
                                                                    ] ? (
                                                                        <>
                                                                            {/* <td
                                                                            onClick={()=>{
                                                                                handleProjectNavigationProject(
                                                                                    // el.project
                                                                                    el.project_list, el
                                                                                )
                                                                            }}
                                                                            className={classNames(
                                                                                i !==
                                                                                    allemployeesworkingdata.length -
                                                                                        1 &&
                                                                                    this.state
                                                                                        .selectedEmployeeToExpand
                                                                                        .userName !==
                                                                                        el.Employee_name
                                                                                    ? "border-b border-gray-200"
                                                                                    : "",
                                                                                `order-${this.state.employeeWorkingTableArrangement["project"]} hover:cursor-pointer whitespace-nowrap px-3 py-4 text-base text-primary`
                                                                            )}>
                                                                            <div className="flex justify-between">
                                                                                <div className="max-w-fit">
                                                                                    {el.project_list.toString()}
                                                                                </div>
                                                                            </div>
                                                                        </td>  */}
                                                                            <td
                                                                                className={classNames(
                                                                                    i !==
                                                                                        allemployeesworkingdata.length -
                                                                                        1 &&
                                                                                        this.state
                                                                                            .selectedEmployeeToExpand
                                                                                            .userName !==
                                                                                        el.Employee_name
                                                                                        ? "border-b border-gray-200"
                                                                                        : "",
                                                                                    `order-${this.state.employeeWorkingTableArrangement["project"]} hover:cursor-pointer whitespace-nowrap px-3 py-4 text-base text-primary`
                                                                                )}>
                                                                                <div className="flex justify-between">
                                                                                    <div className="max-w-fit">
                                                                                        {el.project_list.map(
                                                                                            (
                                                                                                project,
                                                                                                index
                                                                                            ) => (
                                                                                                <span
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                    onClick={() => {
                                                                                                        handleProjectNavigationProject(
                                                                                                            project,
                                                                                                            el.project
                                                                                                        );
                                                                                                    }}
                                                                                                    className="">
                                                                                                    {/* {project.trim()} */}
                                                                                                    {index >
                                                                                                        0 &&
                                                                                                        ", "}{" "}
                                                                                                    {project.trim()}
                                                                                                </span>
                                                                                            )
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </>
                                                                    ) : null}
                                                                    {(el.task_list?.length > 1 ||
                                                                        el.project_list?.length >
                                                                        1 ||
                                                                        el.team_list?.length >
                                                                        1) && (
                                                                            <td className="absolute cursor-pointer right-0 top-4 ">
                                                                                <ChevronDownIcon
                                                                                    onClick={() => {
                                                                                        for (let x of listofAllEmployees) {
                                                                                            if (
                                                                                                x.userName ===
                                                                                                el.Employee_name
                                                                                            ) {
                                                                                                this.setState(
                                                                                                    {
                                                                                                        selectedEmployeeToExpand:
                                                                                                            x,
                                                                                                    },
                                                                                                    () =>
                                                                                                        this.props
                                                                                                            .getUserProjectChange(
                                                                                                                moment(
                                                                                                                    this
                                                                                                                        .state
                                                                                                                        .selectedDate
                                                                                                                ).format(
                                                                                                                    "YYYY-MM-DD"
                                                                                                                ),
                                                                                                                x.id
                                                                                                            )
                                                                                                            .then(
                                                                                                                (
                                                                                                                    res
                                                                                                                ) => {
                                                                                                                    this.setState(
                                                                                                                        {
                                                                                                                            employeeProjectDetail:
                                                                                                                                res
                                                                                                                                    .value
                                                                                                                                    .data
                                                                                                                                    .data,
                                                                                                                        }
                                                                                                                    );
                                                                                                                }
                                                                                                            )
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    className="w-5 h-5 mr-4"
                                                                                />
                                                                            </td>
                                                                        )}
                                                                </tr>
                                                            )
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {this.state.dashboardLayout !== "" &&
                        this.state.dashboardLayout.project &&
                        this.state.dashboardArrangement ? (
                        <div className={`order-${this.state.dashboardArrangement.project}`}>
                            <div className="bg-white mt-4 px-2 pt-1 pb-8 flex flex-col border-b border-gray-200 shadow-md rounded-lg">
                                <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-4 text-textprimary text-lg flex flex-nowrap items-center font-semibold">
                                        <p>
                                            {t("all")} {t("projects")}
                                        </p>
                                        <div className="text-primary hover:cursor-pointer">
                                            <BsGearFill
                                                className="mx-2 h-4 w-4"
                                                onClick={() => {
                                                    this.setState({
                                                        isShowSettingsModal: true,
                                                        modalData: this.state.projectTableLayout,
                                                        modalArrangement:
                                                            this.state.projectTableArrangement,
                                                        modalHeading: t("tableSettings"),
                                                        onSubmitClickHandler:
                                                            this.allProjectTableLayoutUpdateHandler,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="ml-4 mt-4 flex-shrink-0"></div>
                                </div>
                                <div className="mt-4 ml-4 flex flex-col overflow-y-auto bg-gray-100">
                                    <div className="inline-block min-w-full align-middle">
                                        <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[580px] overflow-auto">
                                            <table className="min-w-max w-full border-separate">
                                                <thead className="bg-gray-50 min-w-full sticky top-0">
                                                    <tr
                                                        className={`grid grid-cols-${
                                                            this.state.allProjectsCols + 3
                                                        }`}>
                                                        <th
                                                            scope="col"
                                                            className="order-1 top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter flex flex-row items-center">
                                                            {t("project")} {t("name")}
                                                            {this.state.isSort && this.state.sortingOrder!=="ascendingpname"?(
                                                               <button title="Sort Ascending" onClick={onClickAscendingpname}
                                                               className={`flex flex-col ml-3 sorting-descendingpname`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           ):(
                                                               <button title="Sort Descending" onClick={onClickDescendingpname}
                                                               className={`flex flex-col ml-3 sorting-ascendingpname`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           )}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className={`order-1 top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter flex flex-row items-center`}>
                                                            {t("timeTracker.timeSpend")}
                                                            {this.state.isSort && this.state.sortingOrder!=="ascendinghspent"?(
                                                               <button title="Sort Ascending" onClick={onClickAscendinghspent}
                                                               className={`flex flex-col ml-3 sorting-descendinghspent`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           ):(
                                                               <button title="Sort Descending" onClick={onClickDescendinghspent}
                                                               className={`flex flex-col ml-3 sorting-ascendinghspent`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           )}
                                                        </th>

                                                        {this.state.projectTableLayout
                                                            .hours_booked ? (
                                                            <th
                                                                scope="col"
                                                                className={`order-${this.state
                                                                        .projectTableArrangement[
                                                                    "hours_booked"
                                                                    ] + 1
                                                                    } top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter flex flex-row items-center`}>
                                                                {t("ProjectTasksTab.hoursBooked")}
                                                                {this.state.isSort && this.state.sortingOrder!=="ascendingbhours"?(
                                                               <button title="Sort Ascending" onClick={onClickAscendingbhours}
                                                               className={`flex flex-col ml-3 sorting-descendingbhours`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           ):(
                                                               <button title="Sort Descending" onClick={onClickDescendingbhours}
                                                               className={`flex flex-col ml-3 sorting-ascendingbhours`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           )}
                                                            </th>
                                                        ) : null}
                                                        {this.state.projectTableLayout
                                                            .hours_remaining ? (
                                                            <th
                                                                scope="col"
                                                                className={`order-${this.state
                                                                        .projectTableArrangement[
                                                                    "hours_remaining"
                                                                    ] + 1
                                                                    } top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter flex flex-row items-center`}>
                                                                {t(
                                                                    "ProjectTasksTab.hoursRemaining"
                                                                )}
                                                                 {this.state.isSort && this.state.sortingOrder!=="ascendinghrem"?(
                                                               <button title="Sort Ascending" onClick={onClickAscendinghrem}
                                                               className={`flex flex-col ml-3 sorting-ascendinghrem`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           ):(
                                                               <button title="Sort Descending" onClick={onClickDescendinghrem}
                                                               className={`flex flex-col ml-3 sorting-descendinghrem`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           )}
                                                            </th>
                                                        ) : null}
                                                        {this.state.projectTableLayout
                                                            .hours_rolled_over ? (
                                                            <th
                                                                scope="col"
                                                                className={`order-${this.state
                                                                        .projectTableArrangement[
                                                                    "hours_rolled_over"
                                                                    ] + 1
                                                                    } top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter flex flex-row items-center`}>
                                                                {t(
                                                                    "ProjectTasksTab.hoursRolledOver"
                                                                )}
                                                                 {this.state.isSort && this.state.sortingOrder!=="ascendinghrolled"?(
                                                               <button title="Sort Ascending" onClick={onclickAscendinghrolled}
                                                               className={`flex flex-col ml-3 sorting-descendinghrolled`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           ):(
                                                               <button title="Sort Descending" onClick={onclickDescendinghrolled}
                                                               className={`flex flex-col ml-3 sorting-ascendinghrolled`}>
                                                                   <IoMdArrowDropup className="my-0 text-xs" />
                                                                   <IoMdArrowDropdown className="my-0 text-xs" />
                                                               </button>
                                                           )}

                                                            </th>
                                                        ) : null}
                                                        {
                                                            <th
                                                                scope="col"
                                                                className="order-6 top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                                {t("status")}
                                                            </th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {allprojectsdata &&
                                                        (this.state.isSort1 == true
                                                            ? this.state.sortedProject
                                                            : allprojectsdata
                                                        ).map((el, i) => (
                                                            <tr
                                                                key={i}
                                                                className={`grid grid-cols-${this.state.allProjectsCols + 3
                                                                    } min-w-full`}>
                                                                <td
                                                                    onClick={() => {
                                                                        this.handleProjectNavigation(
                                                                            el.project_id,
                                                                            el,
                                                                            el.project_name
                                                                        );
                                                                    }}
                                                                    className={classNames(
                                                                        i !==
                                                                            allprojectsdata.length -
                                                                            1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "order-1 whitespace-nowrap py-4 pl-4 pr-3 text-base cursor-pointer font-medium text-primary"
                                                                    )}>
                                                                    {el.project_name}
                                                                </td>
                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            allprojectsdata.length -
                                                                            1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        `order-1 whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                    )}>
                                                                    {Math.floor(
                                                                        el.hours_spent / 60
                                                                    ) +
                                                                        " hrs " +
                                                                        (
                                                                            el.hours_spent % 60
                                                                        ).toLocaleString("en-US", {
                                                                            minimumIntegerDigits: 2,
                                                                            useGrouping: false,
                                                                        }) +
                                                                        " mins"}
                                                                </td>
                                                                {this.state.projectTableLayout
                                                                    .hours_booked ? (
                                                                    <td
                                                                        className={classNames(
                                                                            i !==
                                                                                allprojectsdata.length -
                                                                                1
                                                                                ? "border-b border-gray-200"
                                                                                : "",
                                                                            `order-${this.state
                                                                                .projectTableArrangement[
                                                                            "hours_booked"
                                                                            ] + 1
                                                                            } whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                        )}>
                                                                        {el["data"][0]["hours"]}
                                                                    </td>
                                                                ) : null}
                                                                {this.state.projectTableLayout
                                                                    .hours_remaining ? (
                                                                    <td
                                                                        className={classNames(
                                                                            i !==
                                                                                allprojectsdata.length -
                                                                                1
                                                                                ? "border-b border-gray-200"
                                                                                : "",
                                                                            `order-${this.state
                                                                                .projectTableArrangement[
                                                                            "hours_remaining"
                                                                            ] + 1
                                                                            } whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                        )}>
                                                                        {
                                                                            el["data"][0][
                                                                            "hours_remaining"
                                                                            ]
                                                                        }
                                                                    </td>
                                                                ) : null}
                                                                {this.state.projectTableLayout
                                                                    .hours_rolled_over ? (
                                                                    <td
                                                                        className={classNames(
                                                                            i !==
                                                                                allprojectsdata.length -
                                                                                1
                                                                                ? "border-b border-gray-200"
                                                                                : "",
                                                                            `order-${this.state
                                                                                .projectTableArrangement[
                                                                            "hours_rolled_over"
                                                                            ] + 1
                                                                            } whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                        )}>
                                                                        {
                                                                            el["data"][0][
                                                                            "hours_rolled"
                                                                            ]
                                                                        }
                                                                    </td>
                                                                ) : null}

                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            allprojectsdata.length -
                                                                            1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "order-6 whitespace-nowrap px-3 py-4 text-base text-gray-500"
                                                                    )}>
                                                                    <div
                                                                        className={
                                                                            el.logged
                                                                                ? "text-secondary"
                                                                                : "text-danger"
                                                                        }>
                                                                        {el.logged
                                                                            ? "Logged"
                                                                            : "Not Logged"}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {this.state.dashboardLayout !== "" &&
                        this.state.dashboardLayout.mood &&
                        this.state.dashboardArrangement &&
                        employeeMoodTableData &&
                        employeeMoodTableData.length > 0 ? (
                        <div className={`order-${this.state.dashboardArrangement.mood}`}>
                            <div className="bg-white mt-4 px-2 pt-1 pb-8 flex flex-col border-b border-gray-200 shadow-md rounded-lg">
                                <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-4 text-textprimary text-lg flex flex-nowrap items-center font-semibold">
                                        <p>
                                            {t("employee")} {t("moods")}
                                        </p>
                                        <div className="text-primary hover:cursor-pointer">
                                            <BsGearFill
                                                className="mx-2 h-4 w-4"
                                                onClick={() => {
                                                    this.setState({
                                                        isShowSettingsModal: true,
                                                        modalData:
                                                            this.state.employeeMoodTableLayout,
                                                        modalHeading: t("tableSettings"),
                                                        modalArrangement:
                                                            this.state.employeeMoodArrangement,
                                                        onSubmitClickHandler:
                                                            this.employeeMoodLayoutUpdateHandler,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="ml-4 mt-4 flex-shrink-0"></div>
                                </div>
                                <div className="mt-4 ml-4 flex flex-col overflow-y-auto bg-gray-100">
                                    <div className="inline-block min-w-full align-middle">
                                        <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                            <table className="min-w-max w-full border-separate">
                                                <thead className="bg-gray-50 min-w-full">
                                                    <tr
                                                        className={`grid grid-cols-${this.state.moodsTableCols}`}>
                                                        {this.state.employeeMoodTableLayout[
                                                            "username"
                                                        ] ? (
                                                            <th
                                                                scope="col"
                                                                className={`order-${this.state.employeeMoodArrangement["username"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                                {t("employee")} {t("name")}
                                                            </th>
                                                        ) : null}
                                                        {this.state.employeeMoodTableLayout[
                                                            "start_mood"
                                                        ] ? (
                                                            <th
                                                                scope="col"
                                                                className={`order-${this.state.employeeMoodArrangement["start_mood"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                                {t("start")} {t("mood")}
                                                            </th>
                                                        ) : null}
                                                        {this.state.employeeMoodTableLayout[
                                                            "start_note"
                                                        ] ? (
                                                            <th
                                                                scope="col"
                                                                className={`order-${this.state.employeeMoodArrangement["start_note"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                                {t("requestsModule.remarks")}
                                                            </th>
                                                        ) : null}
                                                        {this.state.employeeMoodTableLayout[
                                                            "end_mood"
                                                        ] ? (
                                                            <th
                                                                scope="col"
                                                                className={`order-${this.state.employeeMoodArrangement["end_mood"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                                {t("end")} {t("mood")}
                                                            </th>
                                                        ) : null}
                                                        {this.state.employeeMoodTableLayout[
                                                            "end_note"
                                                        ] ? (
                                                            <th
                                                                scope="col"
                                                                className={`order-${this.state.employeeMoodArrangement["end_note"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                                {t("requestsModule.remarks")}
                                                            </th>
                                                        ) : null}
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {employeeMoodTableData &&
                                                        employeeMoodTableData.map((el, i) => (
                                                            <tr
                                                                key={i}
                                                                className={`grid grid-cols-${this.state.moodsTableCols} min-w-full`}>
                                                                {this.state.employeeMoodTableLayout[
                                                                    "username"
                                                                ] ? (
                                                                    <td
                                                                        className={classNames(
                                                                            i !==
                                                                                employeeMoodTableData.length -
                                                                                1
                                                                                ? "border-b border-gray-200"
                                                                                : "",
                                                                            `order-${this.state.employeeMoodArrangement["username"]} whitespace-nowrap py-4 pl-4 pr-3 text-base cursor-pointer font-medium text-primary`
                                                                        )}
                                                                        onClick={() => {
                                                                            ProjectLink(el);
                                                                        }}>
                                                                        {el.username.includes(
                                                                            "from"
                                                                        ) ? (
                                                                            <span className="flex">
                                                                                <DocumentSearchIcon className="h-4 translate-y-[1px] mr-1" />{" "}
                                                                                {el.username}
                                                                            </span>
                                                                        ) : (
                                                                            el.username
                                                                        )}
                                                                    </td>
                                                                ) : null}
                                                                {this.state.employeeMoodTableLayout[
                                                                    "start_mood"
                                                                ] ? (
                                                                    <td
                                                                        className={classNames(
                                                                            i !==
                                                                                employeeMoodTableData.length -
                                                                                1
                                                                                ? "border-b border-gray-200"
                                                                                : "",
                                                                            `order-${this.state.employeeMoodArrangement["start_mood"]} whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                        )}>
                                                                        {el.start_mood
                                                                            ? emojiList[
                                                                            parseInt(
                                                                                el.start_mood
                                                                            ) - 1
                                                                            ]
                                                                            : "—"}
                                                                    </td>
                                                                ) : null}
                                                                {this.state.employeeMoodTableLayout[
                                                                    "start_note"
                                                                ] ? (
                                                                    <td
                                                                        className={classNames(
                                                                            i !==
                                                                                employeeMoodTableData.length -
                                                                                1
                                                                                ? "border-b border-gray-200"
                                                                                : "",
                                                                            `order-${this.state.employeeMoodArrangement["start_note"]} whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                        )}>
                                                                        {el.start_note
                                                                            ? el.start_note
                                                                            : "—"}
                                                                    </td>
                                                                ) : null}
                                                                {this.state.employeeMoodTableLayout[
                                                                    "end_mood"
                                                                ] ? (
                                                                    <td
                                                                        className={classNames(
                                                                            i !==
                                                                                employeeMoodTableData.length -
                                                                                1
                                                                                ? "border-b border-gray-200"
                                                                                : "",
                                                                            `order-${this.state.employeeMoodArrangement["end_mood"]} whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                        )}>
                                                                        {el.end_mood
                                                                            ? emojiList[
                                                                            parseInt(
                                                                                el.end_mood
                                                                            ) - 1
                                                                            ]
                                                                            : "—"}
                                                                    </td>
                                                                ) : null}
                                                                {this.state.employeeMoodTableLayout[
                                                                    "end_note"
                                                                ] ? (
                                                                    <td
                                                                        className={classNames(
                                                                            i !==
                                                                                employeeMoodTableData.length -
                                                                                1
                                                                                ? "border-b border-gray-200"
                                                                                : "",
                                                                            `order-${this.state.employeeMoodArrangement["end_note"]} whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                        )}>
                                                                        {el.end_note
                                                                            ? el.end_note
                                                                            : "—"}
                                                                    </td>
                                                                ) : null}
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {this.state.dashboardLayout !== "" &&
                        this.state.dashboardLayout.permission &&
                        this.state.dashboardArrangement ? (
                        <div className={`order-${this.state.dashboardArrangement.permission}`}>
                            <div className="bg-white mt-4 mb-4 px-2 pb-8 pt-1 flex flex-col border-b border-gray-200 shadow-md rounded-lg">
                                <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-4 text-textprimary text-lg font-semibold">
                                        User Permissions
                                    </div>
                                    <div className="ml-4 mt-4 flex-shrink-0"></div>
                                </div>
                                <div className="mt-4 ml-4 flex flex-col overflow-y-auto bg-gray-100">
                                    <div className="inline-block min-w-full align-middle">
                                        <div className="shadow-sm ring-1 ring-black ring-opacity-5 overflow-x-auto">
                                            <table className="min-w-max w-full border-separate">
                                                <thead className="bg-gray-50 min-w-full">
                                                    <tr className={`grid grid-cols-${3}`}>
                                                        <th
                                                            scope="col"
                                                            className="order-1 top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                            Employee {t("name")}
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            className={`order-1 col-span-2 top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                            Active Permissions
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {PermissonsList &&
                                                        PermissonsList.map((el, i) => (
                                                            <tr
                                                                key={i}
                                                                className={`grid grid-cols-${3} min-w-full`}>
                                                                <td
                                                                    onClick={() => { }}
                                                                    className={classNames(
                                                                        i !==
                                                                            PermissonsList.length -
                                                                            1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        "order-1 whitespace-nowrap py-4 pl-4 pr-3 text-base cursor-pointer font-medium text-primary"
                                                                    )}>
                                                                    {el.user}
                                                                </td>
                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            PermissonsList.length -
                                                                            1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        `order-1 col-span-2 whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                    )}>
                                                                    {el.data.length === 0
                                                                        ? "None"
                                                                        : el.data
                                                                            .map(
                                                                                (feature) =>
                                                                                    feature[
                                                                                    "feature.name"
                                                                                    ]
                                                                            )
                                                                            .join(", ")}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {this.state.dashboardLayout !== "" &&
                        this.state.dashboardLayout.not_logged &&
                        this.state.dashboardArrangement ? (
                        <div className={`order-${this.state.dashboardArrangement.not_logged}`}>
                            <div className="bg-white mt-4 px-2 pt-1 pb-8 flex flex-col border-b border-gray-200 shadow-md rounded-lg">
                                <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-4 text-textprimary text-lg font-semibold">
                                        {t("empNotLogged")}
                                    </div>
                                    <div className="ml-4 mt-4 flex-shrink-0"></div>
                                </div>
                                <div className="flex flex-col overflow-y-auto">
                                    <div className="flex flex-wrap p-4">
                                        {notworkingemployees &&
                                            isArray(notworkingemployees) &&
                                            notworkingemployees.map((el, idx) => {
                                                return (
                                                    <div
                                                        key={idx}
                                                        className="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-primary bg-gray-100">
                                                        <div className="text-base font-normal leading-none max-w-full flex-initial p-2">
                                                            {el.username}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>

                <LayoutSetupModal
                    open={this.state.isShowSettingsModal}
                    onCancelClick={() => {
                        this.setState({ isShowSettingsModal: false });
                    }}
                    modalArrangement={this.state.modalArrangement}
                    modalData={this.state.modalData}
                    onSubmitClick={this.state.onSubmitClickHandler}
                    modalHeading={this.state.modalHeading}
                />
            </Layout>
        );
    }
}
const mapStateToProps = (state) => ({
    dashboard: state.dashboard,
    report: state.report,
    projects: state.projects,
    localStorageState: state.globalLocalStorageReducer,
});

const mapDispatchToProps = (dispatch) => ({
    fetchallEmployees: () => dispatch(fetchallEmployees()),
    managerDashboard: (date) => dispatch(managerDashboard(date)),
    managerdashboard_observer: (date) => dispatch(managerdashboard_observer(date)),
    teamsDashboard: (date) => dispatch(teamsDashboard(date)),
    notworkingEmployees: (date) => dispatch(notworkingEmployees(date)),
    fetchEmployeeAttendance: (year, month, userId) =>
        dispatch(fetchEmployeeAttendance(year, month, userId)),
    fetchEmployeeWebsiteReportDashboard: (startDate, endDate) =>
        dispatch(fetchEmployeeWebsiteReportDashboard(startDate, endDate)),
    storeToLocalStorage: (data) => dispatch(storeToLocalStorage(data)),
    showData: () => dispatch(showData()),
    getRolesCheck: () => dispatch(getRolesCheck()),
    getEmpListObserver: () => dispatch(getEmpListObserver()),
    managerDashboardObserver: (date) => dispatch(managerDashboardObserver(date)),
    getShowcaseEntries: (date, params) => dispatch(getShowcaseEntries(date, params)),
    getShowcaseUsersList: (date) => dispatch(getShowcaseUsersList(date)),
    getDashboardLayout: () => dispatch(getDashboardLayout()),
    updateDashboardLayout: (data) => dispatch(updateDashboardLayout(data)),
    postDashboardLayout: (data) => dispatch(postDashboardLayout(data)),
    getDashboardArrangement: () => dispatch(getDashboardArrangement()),
    updateDashboardArrangement: (data) => dispatch(updateDashboardArrangement(data)),
    postDashboardArrangement: (data) => dispatch(postDashboardArrangement(data)),
    getAllProjectTableLayout: () => dispatch(getAllProjectTableLayout()),
    updateAllProjectTableLayout: (data) => dispatch(updateAllProjectTableLayout(data)),
    postAllProjectTableLayout: (data) => dispatch(postAllProjectTableLayout(data)),
    getEmployeeMoodTableData: (date) => dispatch(getEmployeeMoodTableData(date)),
    getEmployeeMoodTableLayout: () => dispatch(getEmployeeMoodTableLayout()),
    updateEmployeeMoodTableLayout: (data) => dispatch(updateEmployeeMoodTableLayout(data)),
    postEmployeeMoodTableLayout: (data) => dispatch(postEmployeeMoodTableLayout(data)),
    getEmployeeMoodTableArrangement: () => dispatch(getEmployeeMoodTableArrangement()),
    updateEmployeeMoodTableArrangement: (data) =>
        dispatch(updateEmployeeMoodTableArrangement(data)),
    postEmployeeMoodTableArrangement: (data) => dispatch(postEmployeeMoodTableArrangement(data)),
    getTeamTableArrangement: () => dispatch(getTeamTableArrangement()),
    getTeamTableLayout: () => dispatch(getTeamTableLayout()),
    getEmployeeTableLayout: () => dispatch(getEmployeeTableLayout()),
    getEmployeeTableArrangement: () => dispatch(getEmployeeTableArrangement()),
    updateEmployeeTableArrangement: (data) => dispatch(updateEmployeeTableArrangement(data)),
    updateEmployeeTableLayout: (data) => dispatch(updateEmployeeTableLayout(data)),
    updateTeamTableArrangement: (data) => dispatch(updateTeamTableArrangement(data)),
    updateTeamTableLayout: (data) => dispatch(updateTeamTableLayout(data)),
    postEmployeeTableArrangement: (data) => dispatch(postEmployeeTableArrangement(data)),
    postEmployeeTableLayout: (data) => dispatch(postEmployeeTableLayout(data)),
    postTeamTableArrangement: (data) => dispatch(postTeamTableArrangement(data)),
    postTeamTableLayout: (data) => dispatch(postTeamTableLayout(data)),
    getAllProjectTableArrangement: () => dispatch(getAllProjectTableArrangement()),
    updateAllProjectTableArrangement: (data) => dispatch(updateAllProjectTableArrangement(data)),
    postAllProjectTableArrangement: (data) => dispatch(postAllProjectTableArrangement(data)),
    getMoodGraphData: (date) => dispatch(getMoodGraphData(date)),
    getAllProjectTableData: (date) => dispatch(getAllProjectTableData(date)),
    getFeatureListing: () => dispatch(getFeatureListing()),
    getUserProjectChange: (date, uuid) => dispatch(getUserProjectChange(date, uuid)),
    getCurrentStatus: (date, time) => dispatch(getCurrentStatus(date, time)),
    getEmployeeData: (date, time) => dispatch(getEmployeeData(date, time)),
});

Dashboard.propTypes = {};

export default withTranslation("common")(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
