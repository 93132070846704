import { ACTION_TYPES } from "../../../constants";

const projects = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ACTIVE_PROJECTS_PENDING: {
      return {
        ...state,
        isFetchingActiveProjectsList: true,
        isFetchingActiveProjectsFailed: false,
        ActiveProjectsFetchError: undefined,
        ActiveProjects: [],
      };
    }
    case ACTION_TYPES.FETCH_ACTIVE_PROJECTS_FULFILLED: {
      return {
        ...state,
        isFetchingActiveProjectsList: false,
        isFetchingActiveProjectsFailed: false,
        ActiveProjectsFetchError: undefined,
        ActiveProjects: action.payload.data,
      };
    }
    case ACTION_TYPES.FETCH_ACTIVE_PROJECTS_REJECTED: {
      return {
        ...state,
        isFetchingActiveProjectsList: false,
        isFetchingActiveProjectsFailed: true,
        ActiveProjectsFetchError: action.payload,
        ActiveProjects: [],
      };
    }

    case ACTION_TYPES.FETCH_ACTIVE_TASKS_PENDING: {
      return {
        ...state,
        isFetchingActivetasksList: true,
        isFetchingActivetasksFailed: false,
        ActivetasksFetchError: undefined,
        Activetasks: [],
      };
    }
    case ACTION_TYPES.FETCH_ACTIVE_TASKS_FULFILLED: {
      return {
        ...state,
        isFetchingActivetasksList: false,
        isFetchingActivetasksFailed: false,
        ActivetasksFetchError: undefined,
        Activetasks: action.payload.data,
      };
    }
    case ACTION_TYPES.FETCH_ACTIVE_TASKS_REJECTED: {
      return {
        ...state,
        isFetchingActivetasksList: false,
        isFetchingActivetasksFailed: true,
        ActivetasksFetchError: action.payload,
        Activetasks: [],
      };
    }

    case ACTION_TYPES.FETCH_EMPLOYEES_PENDING: {
      return {
        ...state,
        isFetchingEmployeesList: true,
        isFetchingEmployeesFailed: false,
        EmployeesFetchError: undefined,
        AllEmployees: [],
      };
    }
    case ACTION_TYPES.FETCH_EMPLOYEES_FULFILLED: {
      return {
        ...state,
        isFetchingEmployeesList: false,
        isFetchingEmployeesFailed: false,
        EmployeesFetchError: undefined,
        AllEmployees: action.payload.data,
      };
    }
    case ACTION_TYPES.FETCH_EMPLOYEES_REJECTED: {
      return {
        ...state,
        isFetchingEmployeesList: false,
        isFetchingEmployeesFailed: true,
        EmployeesFetchError: action.payload,
        AllEmployees: [],
      };
    }

    case ACTION_TYPES.GET_USERS_FROM_PROJECT_PENDING: {
      return {
        ...state,
        isFetchingUsersFromProjectList: true,
        isFetchingUsersFromProjectFailed: false,
        UsersFromProjectFetchError: undefined,
        UsersFromProject: [],
      };
    }
    case ACTION_TYPES.GET_USERS_FROM_PROJECT_FULFILLED: {
      return {
        ...state,
        isFetchingUsersFromProjectList: false,
        isFetchingUsersFromProjectFailed: false,
        UsersFromProjectFetchError: undefined,
        UsersFromProject: action.payload.data,
      };
    }
    case ACTION_TYPES.GET_USERS_FROM_PROJECT_REJECTED: {
      return {
        ...state,
        isFetchingUsersFromProjectList: false,
        isFetchingUsersFromProjectFailed: true,
        UsersFromProjectFetchError: action.payload,
        UsersFromProject: [],
      };
    }

    case ACTION_TYPES.TASK_LIST_OBSERVER_PENDING: {
      return {
        ...state,
        isFetchingTaskListObserver: true,
        isFetchingTaskListObserverFailed: false,
        TaskListObserverFetchError: undefined,
        TaskListObserver: [],
      };
    }

    case ACTION_TYPES.TASK_LIST_OBSERVER_FULFILLED: {
      return {
        ...state,
        isFetchingTaskListObserver: false,
        isFetchingTaskListObserverFailed: false,
        TaskListObserverFetchError: undefined,
        TaskListObserver: action.payload.data,
      };
    }

    case ACTION_TYPES.TASK_LIST_OBSERVER_REJECTED: {
      return {
        ...state,
        isFetchingTaskListObserver: false,
        isFetchingTaskListObserverFailed: true,
        TaskListObserverFetchError: action.payload,
        TaskListObserver: [],
      };
    }

    case ACTION_TYPES.GET_DETAILED_TASK_INFO_PENDING: {
      return {
        ...state,
        isFetchingDetailedTaskInfo: true,
        isFetchingDetailedTaskInfoFailed: false,
        DetailedTaskInfoFetchError: undefined,
        DetailedTaskInfo: [],
      };
    }

    case ACTION_TYPES.GET_DETAILED_TASK_INFO_FULFILLED: {
      return {
        ...state,
        isFetchingDetailedTaskInfo: false,
        isFetchingDetailedTaskInfoFailed: false,
        DetailedTaskInfoFetchError: undefined,
        DetailedTaskInfo: action.payload.data,
      };
    }

    case ACTION_TYPES.GET_DETAILED_TASK_INFO_REJECTED: {
      return {
        ...state,
        isFetchingDetailedTaskInfo: false,
        isFetchingDetailedTaskInfoFailed: true,
        DetailedTaskInfoFetchError: action.payload,
        DetailedTaskInfo: [],
      };
    }

    case ACTION_TYPES.GET_PROJECT_DETAILS_PENDING: {
      return {
        ...state,
        isFetchingProjectDetails: true,
        isFetchingProjectDetailsFailed: false,
        ProjectDetailsFetchError: undefined,
        projectDetails: [],
      };
    }

    case ACTION_TYPES.GET_PROJECT_DETAILS_FULFILLED: {
      return {
        ...state,
        isFetchingProjectDetails: false,
        isFetchingProjectDetailsFailed: false,
        ProjectDetailsFetchError: undefined,
        projectDetails: action.payload.data.response_data,
      };
    }

    case ACTION_TYPES.GET_PROJECT_DETAILS_REJECTED: {
      return {
        ...state,
        isFetchingProjectDetails: false,
        isFetchingProjectDetailsFailed: true,
        ProjectDetailsFetchError: action.payload,
        projectDetails: [],
      };
    }

    case ACTION_TYPES.ADD_PROJECT_DETAILS_PENDING: {
      return {
        ...state,
        isPostingProjectDetails: true,
        isPostingProjectDetailsFailed: false,
        ProjectDetailsPostError: undefined,
        addProjectDetails: [],
      };
    }

    case ACTION_TYPES.ADD_PROJECT_DETAILS_FULFILLED: {
      return {
        ...state,
        isPostingProjectDetails: false,
        isPostingProjectDetailsFailed: false,
        ProjectDetailsPostError: undefined,
        addProjectDetails: action.payload.data.response_data,
      };
    }

    case ACTION_TYPES.ADD_PROJECT_DETAILS_REJECTED: {
      return {
        ...state,
        isPostingProjectDetails: false,
        isPostingProjectDetailsFailed: true,
        ProjectDetailsPostError: action.payload,
        addProjectDetails: [],
      };
    }

    case ACTION_TYPES.UPDATE_PROJECT_DETAILS_PENDING: {
      return {
        ...state,
        isUpdatingProjectDetails: true,
        isUpdatingProjectDetailsFailed: false,
        ProjectDetailsUpdateError: undefined,
        updateprojectDetails: [],
      };
    }

    case ACTION_TYPES.UPDATE_PROJECT_DETAILS_FULFILLED: {
      return {
        ...state,
        isUpdatingProjectDetails: false,
        isUpdatingProjectDetailsFailed: false,
        ProjectDetailsUpdateError: undefined,
        updateprojectDetails: action.payload.data.response_data,
      };
    }

    case ACTION_TYPES.UPDATE_PROJECT_DETAILS_REJECTED: {
      return {
        ...state,
        isUpdatingProjectDetails: false,
        isUpdatingProjectDetailsFailed: true,
        ProjectDetailsUpdateError: action.payload,
        updateprojectDetails: [],
      };
    }

    case ACTION_TYPES.GET_PROJECT_TABLE_LAYOUT_PENDING: {
      return {
        ...state,
        isFetchingProjectTableLayout: true,
        isFetchingProjectTableLayoutFailed: false,
        ProjectTableLayoutFetchError: undefined,
      };
    }

    case ACTION_TYPES.GET_PROJECT_TABLE_LAYOUT_FULFILLED: {
      return {
        ...state,
        isFetchingProjectTableLayout: false,
        isFetchingProjectTableLayoutFailed: false,
        ProjectTableLayoutFetchError: undefined,
        ProjectTableLayout: action.payload.data,
      };
    }

    case ACTION_TYPES.GET_PROJECT_TABLE_LAYOUT_REJECTED: {
      return {
        ...state,
        isFetchingProjectTableLayout: false,
        isFetchingProjectTableLayoutFailed: true,
        ProjectTableLayoutFetchError: action.payload,
        ProjectTableLayout: [],
      };
    }

    case ACTION_TYPES.UPDATE_PROJECT_TABLE_PENDING: {
      return {
        ...state,
        isUpdatingProjectTableLayout: true,
        isUpdatingProjectTableLayoutFailed: false,
        ProjectTableLayoutUpdateError: undefined,
      };
    }

    case ACTION_TYPES.UPDATE_PROJECT_TABLE_FULFILLED: {
      return {
        ...state,
        isUpdatingProjectTableLayout: false,
        isUpdatingProjectTableLayoutFailed: false,
        ProjectTableLayoutUpdateError: undefined,
        ProjectTableLayoutUpdate: action.payload.data,
      };
    }

    case ACTION_TYPES.UPDATE_PROJECT_TABLE_REJECTED: {
      return {
        ...state,
        isUpdatingProjectTableLayout: false,
        isUpdatingProjectTableLayoutFailed: true,
        ProjectTableLayoutUpdateError: action.payload,
        ProjectTableLayoutUpdate: [],
      };
    }

    case ACTION_TYPES.GET_PROJECT_TABLE_ARRANGEMENT_PENDING: {
      return {
        ...state,
        isFetchingProjectTableArrangement: true,
        isFetchingProjectTableArrangementFailed: false,
        ProjectTableArrangementFetchError: undefined,
      };
    }

    case ACTION_TYPES.GET_PROJECT_TABLE_ARRANGEMENT_FULFILLED: {
      return {
        ...state,
        isFetchingProjectTableArrangement: false,
        isFetchingProjectTableArrangementFailed: false,
        ProjectTableArrangementFetchError: undefined,
        ProjectTableArrangement: action.payload.data,
      };
    }

    case ACTION_TYPES.GET_PROJECT_TABLE_ARRANGEMENT_REJECTED: {
      return {
        ...state,
        isFetchingProjectTableArrangement: false,
        isFetchingProjectTableArrangementFailed: true,
        ProjectTableArrangementFetchError: action.payload,
        ProjectTableArrangement: [],
      };
    }

    case ACTION_TYPES.UPDATE_PROJECT_TABLE_ARRANGEMENT_PENDING: {
      return {
        ...state,
        isUpdatingProjectTableArrangement: true,
        isUpdatingProjectTableArrangementFailed: false,
        ProjectTableArrangementUpdateError: undefined,
      };
    }

    case ACTION_TYPES.UPDATE_PROJECT_TABLE_ARRANGEMENT_FULFILLED: {
      return {
        ...state,
        isUpdatingProjectTableArrangement: false,
        isUpdatingProjectTableArrangementFailed: false,
        ProjectTableArrangementUpdateError: undefined,
        ProjectTableArrangement: action.payload.data,
      };
    }

    case ACTION_TYPES.UPDATE_PROJECT_TABLE_ARRANGEMENT_REJECTED: {
      return {
        ...state,
        isUpdatingProjectTableArrangement: false,
        isUpdatingProjectTableArrangementFailed: true,
        ProjectTableArrangementUpdateError: action.payload,
        ProjectTableArrangement: [],
      };
    }

    case ACTION_TYPES.GET_USERBASED_PROJECTS_PENDING: {
      return {
        ...state,
        isFetchingProjects: true,
        isFetchingProjectsFailed: false,
        ProjectsFetchError: undefined,
      };
    }

    case ACTION_TYPES.GET_USERBASED_PROJECTS_FULFILLED: {
      return {
        ...state,
        isFetchingProjects: false,
        isFetchingProjectsFailed: false,
        ProjectsFetchError: undefined,
        Projects: action.payload.data,
      };
    }

    case ACTION_TYPES.GET_USERBASED_PROJECTS_REJECTED: {
      return {
        ...state,
        isFetchingProjects: false,
        isFetchingProjectsFailed: true,
        ProjectsFetchError: action.payload,
        Projects: [],
      };
    }

    case ACTION_TYPES.GET_USER_BASED_TEAMS_PENDING: {
      return {
        ...state,
        isFetchingTeams: true,
        isFetchingTeamsFailed: false,
        TeamsFetchError: undefined,
      };
    }

    case ACTION_TYPES.GET_USER_BASED_TEAMS_FULFILLED: {
      return {
        ...state,
        isFetchingTeams: false,
        isFetchingTeamsFailed: false,
        TeamsFetchError: undefined,
        Teams: action.payload.data,
      };
    }

    case ACTION_TYPES.GET_USER_BASED_TEAMS_REJECTED: {
      return {
        ...state,
        isFetchingTeams: false,
        isFetchingTeamsFailed: true,
        TeamsFetchError: action.payload,
        Teams: [],
      };
    }

    case ACTION_TYPES.UPDATE_ACTIVE_TASKS_PENDING: {
      return {
        ...state,
        isupdatingActivetask: true,
        isupdatingActivetaskFailed: false,
        isupdatingActivetaskError: undefined,
        Activetask: [],
      };
    }
    case ACTION_TYPES.UPDATE_ACTIVE_TASKS_FULFILLED: {
      return {
        ...state,
        isupdatingActivetask: false,
        isupdatingActivetaskFailed: false,
        isupdatingActivetaskError: undefined,
        Activetask: action.payload.data,
      };
    }
    case ACTION_TYPES.UPDATE_ACTIVE_TASKS_REJECTED: {
      return {
        ...state,
        isupdatingActivetask: false,
        isupdatingActivetaskFailed: true,
        isupdatingActivetaskError: action.payload,
        Activetask: [],
      };
    }
    case ACTION_TYPES.GET_PROJECT_CUSTOM_FIELDS_PENDING: {
      return {
        ...state,
        isFetchingProjectCustomFields: true,
        isFetchingProjectCustomFieldsFailed: false,
        isFetchingProjectCustomFieldsError: undefined,
      };
    }

    case ACTION_TYPES.GET_PROJECT_CUSTOM_FIELDS_FULFILLED: {
      return {
        ...state,
        isFetchingProjectCustomFields: false,
        isFetchingProjectCustomFieldsFailed: false,
        isFetchingProjectCustomFieldsError: undefined,
        ProjectCustomFields: action.payload.data,
      };
    }

    case ACTION_TYPES.GET_PROJECT_CUSTOM_FIELDS_REJECTED: {
      return {
        ...state,
        isFetchingProjectCustomFields: false,
        isFetchingProjectCustomFieldsFailed: true,
        isFetchingProjectCustomFieldsError: action.payload,
        ProjectCustomFields: [],
      };
    }
    case ACTION_TYPES.GET_XERO_PROJECT_PENDING: {
      return {
        ...state,
        isFetchingXeroProjectDetails: true,
        isFetchingXeroProjectDetailsFailed: false,
        isFetchingXeroProjectDetailsError: undefined,
      };
    }

    case ACTION_TYPES.GET_XERO_PROJECT_FULFILLED: {
      return {
        ...state,
        isFetchingXeroProjectDetails: false,
        isFetchingXeroProjectDetailsFailed: false,
        isFetchingXeroProjectDetailsError: undefined,
        XeroProjectDetails: action.payload.data,
      };
    }

    case ACTION_TYPES.GET_XERO_PROJECT_REJECTED: {
      return {
        ...state,
        isFetchingXeroProjectDetails: false,
        isFetchingXeroProjectDetailsFailed: true,
        isFetchingXeroProjectDetailsError: action.payload,
        XeroProjectDetails: [],
      };
    }
    case ACTION_TYPES.UPDATE_XERO_PROJECT_PENDING: {
      return {
        ...state,
        isUpdatingXeroProjectDetails: true,
        isUpdatingXeroProjectDetailsFailed: false,
        isUpdatingXeroProjectDetailsError: undefined,
      };
    }

    case ACTION_TYPES.UPDATE_XERO_PROJECT_FULFILLED: {
      return {
        ...state,
        isUpdatingXeroProjectDetails: false,
        isUpdatingXeroProjectDetailsFailed: false,
        isUpdatingXeroProjectDetailsError: undefined,
        UpdatedXeroProjectDetails: action.payload.data,
      };
    }

    case ACTION_TYPES.UPDATE_XERO_PROJECT_REJECTED: {
      return {
        ...state,
        isUpdatingXeroProjectDetails: false,
        isUpdatingXeroProjectDetailsFailed: true,
        isUpdatingXeroProjectDetailsError: action.payload,
        UpdatedXeroProjectDetails: [],
      };
    }

    case ACTION_TYPES.GET_CATEGORIES_DATA_PENDING: {
      return {
        ...state,
        isFetchingCategoriesData: true,
        isFetchingCategoriesDataFailed: false,
        CategoriesDataFetchError: undefined,
        CategoriesData: [],
      };
    }

    case ACTION_TYPES.GET_CATEGORIES_DATA_FULFILLED: {
      return {
        ...state,
        isFetchingCategoriesData: false,
        isFetchingCategoriesDataFailed: false,
        CategoriesDataFetchError: undefined,
        CategoriesData: action.payload.data.response_data,
      };
    }

    case ACTION_TYPES.GET_CATEGORIES_DATA_REJECTED: {
      return {
        ...state,
        isFetchingCategoriesData: false,
        isFetchingCategoriesDataFailed: true,
        CategoriesDataFetchError: action.payload,
        CategoriesData: [],
      };
    }
    case ACTION_TYPES.GET_NOTES_DATA_PENDING: {
      return {
        ...state,
        isFetchingNotesData: true,
        isFetchingNotesDataFailed: false,
        isFetchingNotesDataError: undefined,
        NotesData: [],
      };
    }

    case ACTION_TYPES.GET_NOTES_DATA_FULFILLED: {
      return {
        ...state,
        isFetchingNotesData: false,
        isFetchingNotesDataFailed: false,
        isFetchingNotesDataError: undefined,
        NotesData: action.payload.data.response_data,
      };
    }

    case ACTION_TYPES.GET_NOTES_DATA_REJECTED: {
      return {
        ...state,
        isFetchingNotesData: false,
        isFetchingNotesDataFailed: true,
        isFetchingNotesDataError: action.payload,
        NotesData: [],
      };
    }
    case ACTION_TYPES.POST_NOTES_DATA_PENDING: {
      return {
        ...state,
        isPostingNote: true,
        isPostingNoteFailed: false,
        isPostingNoteError: undefined,
        PostingNote: [],
      };
    }

    case ACTION_TYPES.POST_NOTES_DATA_FULFILLED: {
      return {
        ...state,
        isPostingNote: false,
        isPostingNoteFailed: false,
        isPostingNoteError: undefined,
        PostingNote: action.payload.data.response_data,
      };
    }

    case ACTION_TYPES.POST_NOTES_DATA_REJECTED: {
      return {
        ...state,
        isPostingNote: false,
        isPostingNoteFailed: true,
        isPostingNoteError: action.payload,
        PostingNote: [],
      };
    }

    case ACTION_TYPES.FETCH_USER_DAILY_REPORTS_PENDING: {
      return {
        ...state,
        isFetchingReport: true,
        isFetchingReportFailed: false,
        isFetchingReportError: undefined,
        UserDailyReport: [],
      };
    }

    case ACTION_TYPES.FETCH_USER_DAILY_REPORTS_FULFILLED: {
      return {
        ...state,
        isFetchingReport: false,
        isFetchingReportFailed: false,
        isFetchingReportError: undefined,
        UserDailyReport: action.payload.data.response_data,
      };
    }

    case ACTION_TYPES.FETCH_USER_DAILY_REPORTS_REJECTED: {
      return {
        ...state,
        isFetchingReport: false,
        isFetchingReportFailed: true,
        isFetchingReportError: action.payload,
        UserDailyReport: [],
      };
    }

    case ACTION_TYPES.FETCH_USER_MONTHLY_REPORTS_PENDING: {
      return {
        ...state,
        isFetchingMonthlyReport: true,
        isFetchingReportMonthlyFailed: false,
        isFetchingReportMonthlyError: undefined,
        UserMonthlyReport: [],
      };
    }

    case ACTION_TYPES.FETCH_USER_MONTHLY_REPORTS_FULFILLED: {
      return {
        ...state,
        isFetchingMonthlyReport: false,
        isFetchingReportMonthlyFailed: false,
        isFetchingReportMonthlyError: undefined,
        UserMonthlyReport: action.payload.data.response_data,
      };
    }

    case ACTION_TYPES.FETCH_USER_MONTHLY_REPORTS_REJECTED: {
      return {
        ...state,
        isFetchingMonthlyReport: false,
        isFetchingReportMonthlyFailed: true,
        isFetchingReportMonthlyError: action.payload,
        UserMonthlyReport: [],
      };
    }

    default:
      return state;
  }
};

export default projects;
