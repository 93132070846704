import { combineReducers } from 'redux';
import teams from '../../services/teams/reducers';
import projects from '../../services/projects/reducers';
import activityIndex from '../../services/activityMonitor/reducers';
import dashboard from '../../services/dashboard/reducers';
import settings from '../../services/settings/reducers'
import get_all_request from '../requests/reducers';
import timetracker from '../timetracker/reducers';
import userprofile from "../userProfile/reducers";
import report from "../reports/reducers";
import empDashboard from "../employee/reducers";
import payments from "../payments/reducers";
import globalLocalStorageReducer from "../globalState/reducers";

export default combineReducers({
    teams,
    projects,
    dashboard,
    settings,
    activityIndex,
    get_all_request,
    timetracker,
    report,
    empDashboard,
    payments,
    globalLocalStorageReducer,
    userprofile,
});