import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { Add, Info } from "@material-ui/icons";
import { CogIcon } from "@heroicons/react/solid";
import { ExpandMoreRounded, KeyboardArrowUp } from "@mui/icons-material";
import ToggleButton from "./ToggleButton";
import AddIndexModal from "./AddIndexModal";
import InputField from "../../Components/InputField";

class IndexesTab extends Component {
    constructor() {
        super();
        this.state = {
            selectedName: "",
            selectedDescription: "",
            selectedWeightage: 0,
            editModal: false,
            showModal: false,
            dropdownIndex: 0,
        };
    }
    componentDidMount() {}
    render() {
        return (
            <div className="bg-white rounded-md shadow-md mt-5 p-5">
                <div className="min-h-[60vh] flex flex-nowrap flex-col gap-4">
                    <div className="w-full flex justify-between items-center">
                        <div className="flex gap-2 text-2xl items-center">
                            <div>Indexes</div>
                            <div className="text-lg">
                                <Info fontSize="inherit" className="text-gray-500" />
                            </div>
                        </div>
                        <button
                            className="py-2 px-5 text-white bg-primary rounded-md"
                            onClick={() =>
                                this.setState({
                                    selectedName: "",
                                    selectedDescription: "",
                                    selectedWeightage: 0,
                                    editModal: false,
                                    showModal: true,
                                })
                            }>
                            Add Index
                        </button>
                    </div>
                    {[1, 2].map((val, index) => (
                        <div
                            key={index + "idexes"}
                            className="bg-white rounded-md shadow-md border-2 border-b-0 border-gray-200 border-opacity-70 p-4">
                            <div className="flex items-center gap-2 justify-between">
                                <div>
                                    <div className="text-lg">Productivity</div>
                                    <div className="text-gray-400 text-tiny">
                                        Lorem ipsum dolor sit amet consectetur. Consectetur placerat
                                        potenti massa dui ut.
                                    </div>
                                </div>
                                <div>234</div>
                                <ToggleButton
                                    color="bg-[#4F46E5]"
                                    checked={index === 0}
                                    onChange={() => {}}
                                />
                                <div className="flex gap-4 items-center">
                                    <CogIcon
                                        className="w-6 h-6 text-gray-500 hover:cursor-pointer"
                                        onClick={() =>
                                            this.setState({
                                                selectedName: "Productivity",
                                                selectedDescription:
                                                    "Lorem ipsum dolor sit amet consectetur. Consectetur placerat potenti massa dui ut.",
                                                selectedWeightage: 0,
                                                editModal: true,
                                                showModal: true,
                                            })
                                        }
                                    />
                                    <button
                                        className="text-3xl"
                                        onClick={() => {
                                            if (this.state.dropdownIndex === index) {
                                                this.setState({ dropdownIndex: -1 });
                                            } else {
                                                this.setState({ dropdownIndex: index });
                                            }
                                        }}>
                                        {this.state.dropdownIndex === index ? (
                                            <KeyboardArrowUp
                                                fontSize="inherit"
                                                className="text-gray-500 -mt-1 hover:cursor-pointer"
                                            />
                                        ) : (
                                            <ExpandMoreRounded
                                                fontSize="inherit"
                                                className="text-gray-500 -mt-1 hover:cursor-pointer"
                                            />
                                        )}
                                    </button>
                                </div>
                            </div>
                            {this.state.dropdownIndex === index && (
                                <div className="w-full h-0 mt-2 border-t border-gray-200"></div>
                            )}
                            {this.state.dropdownIndex === index && (
                                <div className="mt-4">
                                    <div className="flex gap-3 items-center">
                                        <div className="text-lg">Signals</div>
                                        <Add fontSize="small" className="text-primary bg-gray" />
                                    </div>
                                    <div className="flex justify-between items-center md-m:flex-wrap my-4">
                                        <div className="">Mouse Activity</div>
                                        <div className="md-m:w-1/2 w-1/2 xl:w-2/5">
                                            <InputField placeholder="Weightage" type="number" />
                                        </div>
                                        <ToggleButton
                                            color="bg-[#4F46E5]"
                                            checked={index === 0}
                                            onChange={() => {}}
                                        />
                                    </div>
                                    <div className="flex justify-between items-center md-m:flex-wrap my-4">
                                        <div className="">Mouse Activity</div>
                                        <div className="md-m:w-1/2 w-1/2 xl:w-2/5">
                                            <InputField placeholder="Weightage" type="number" />
                                        </div>
                                        <ToggleButton
                                            color="bg-[#4F46E5]"
                                            checked={index === 0}
                                            onChange={() => {}}
                                        />
                                    </div>
                                    <div className="flex justify-end items-center">
                                        <button className="py-2 px-4 rounded-md bg-primary text-white">
                                            Save Changes
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <AddIndexModal
                    open={this.state.showModal}
                    edit={this.state.editModal}
                    onCancelClick={() => this.setState({ showModal: false })}
                    onSubmitClick={() => this.setState({ showModal: false })}
                    submitButtonName={this.state.editModal ? "Save Changes" : "Add Index"}
                    name={this.state.selectedName}
                    description={this.state.selectedDescription}
                    weightage={this.state.selectedWeightage}
                    onDeleteClick={() => {}}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation("common")(connect(mapStateToProps, mapDispatchToProps)(IndexesTab));

// connectors are Xero, office365, zoho, bitrix24, github, jira
