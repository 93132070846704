import React, { Component } from 'react'
import PropTypes from 'prop-types';

import ToggleButton from '../ToggleButton';
import { withTranslation } from 'react-i18next';
import { getOverideFeatureList } from '../../../services/settings/action';
import { connect } from 'react-redux';
import SelectField from '../../../Components/SelectField';
import { Link } from "react-router-dom";

class Global_permisson_tab extends Component {     
    render() {
        const { t } = this.props;
        const {
            enableDisable_customPermission,
            listOfFeatures,
            screenshotTiming,
            handleTimeChange,
            timingArray,
            activeUsers,
            activeTeams,
        } = this.props;
        const overridedBy = this.props.overideFeatureList;
        const getTeamId = (teamName) => {
            if (activeTeams !== undefined || activeTeams.length !== 0) {
                for (let i = 0; i < activeTeams.length; i++) {
                    if (activeTeams[i].team.name === teamName) {
                        return (
                            activeTeams[i].team.team_uuid +
                            "&" +
                            activeTeams[i].team.id
                        );
                    }
                }
            }
        };
        return (
            <div>
                <div className="bg-white px-2 py-1 pb-4 border-b border-gray-200 sm:px-6 rounded-lg my-6">
                    <h1 className="text-primary my-5 font-semibold text-lg">
                        {t("settingsTab.custom")} {t("settingsTab.permissions")}
                    </h1>
                    <div>
                        <div className="mt-8 flex flex-col">
                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        {t("settingsTab.feature")} {t("name")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        {t("description")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-lg">
                                                        {t("settingsTab.overriddenByTeams")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-lg">
                                                        {t("settingsTab.overriddenByUsers")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        {t("status")}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {listOfFeatures === "" ||
                                                listOfFeatures === undefined ||
                                                listOfFeatures.features === undefined ||
                                                overridedBy === undefined ||
                                                overridedBy.length === 0 ||
                                                activeUsers === undefined ||
                                                activeUsers.length === 0 ||
                                                activeTeams === undefined ||
                                                activeTeams.length === 0 ? (
                                                    <tr>
                                                        <td className="text-center" colSpan={4}>
                                                            {t("loading")}...
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    listOfFeatures &&
                                                    listOfFeatures.features.map((feature, idx) => (
                                                        <tr key={idx+"permission"}>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {feature.feature}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {feature.description}
                                                            </td>
                                                            <td className="whitespace-wrap px-3 py-4 text-sm text-gray-500 max-w-lg">
                                                                {overridedBy[idx].teams.length === 0
                                                                    ? "None"
                                                                    : overridedBy[idx].teams.map(
                                                                          (title, id) => {
                                                                              if (
                                                                                  id ===
                                                                                  overridedBy[idx]
                                                                                      .teams
                                                                                      .length -
                                                                                      1
                                                                              ) {
                                                                                  return (
                                                                                      <Link key={id+"override2"}
                                                                                          to={`/team/Teams/${title}&${getTeamId(
                                                                                              title
                                                                                          )}`}>
                                                                                          {title}
                                                                                      </Link>
                                                                                  );
                                                                              } else {
                                                                                  return (
                                                                                      <Link  key={id+"override3"}
                                                                                          to={`/team/Teams/${title}&${getTeamId(
                                                                                              title
                                                                                          )}`}>
                                                                                          {title +
                                                                                              ", "}
                                                                                      </Link>
                                                                                  );
                                                                              }
                                                                          }
                                                                      )}
                                                            </td>
                                                            <td className="whitespace-wrap px-3 py-4 text-sm text-gray-500 max-w-lg">
                                                                {overridedBy[idx].users.length === 0
                                                                    ? "None"
                                                                    : overridedBy[idx].users.map(
                                                                          (title, id) => {
                                                                              if (
                                                                                  id ===
                                                                                  overridedBy[idx]
                                                                                      .users
                                                                                      .length -
                                                                                      1
                                                                              ) {
                                                                                  return (
                                                                                      <Link key={id+"override"}
                                                                                          onClick={() => {
                                                                                              for (
                                                                                                  let i = 0;
                                                                                                  i <
                                                                                                  activeUsers.length;
                                                                                                  i++
                                                                                              ) {
                                                                                                  if (
                                                                                                      activeUsers[
                                                                                                          i
                                                                                                      ]
                                                                                                          .user_login_as ===
                                                                                                      title
                                                                                                  ) {
                                                                                                      localStorage.setItem(
                                                                                                          "data",
                                                                                                          JSON.stringify(
                                                                                                              activeUsers[
                                                                                                                  i
                                                                                                              ]
                                                                                                          )
                                                                                                      );
                                                                                                      break;
                                                                                                  }
                                                                                              }
                                                                                          }}
                                                                                          to={`/employeeProfile`}>
                                                                                          {title}
                                                                                      </Link>
                                                                                  );
                                                                              } else {
                                                                                  return (
                                                                                      <Link   key={id+"override1"}
                                                                                          onClick={() => {
                                                                                              for (
                                                                                                  let i = 0;
                                                                                                  i <
                                                                                                  activeUsers.length;
                                                                                                  i++
                                                                                              ) {
                                                                                                  if (
                                                                                                      activeUsers[
                                                                                                          i
                                                                                                      ]
                                                                                                          .user_login_as ===
                                                                                                      title
                                                                                                  ) {
                                                                                                      localStorage.setItem(
                                                                                                          "data",
                                                                                                          JSON.stringify(
                                                                                                              activeUsers[
                                                                                                                  i
                                                                                                              ]
                                                                                                          )
                                                                                                      );
                                                                                                      break;
                                                                                                  }
                                                                                              }
                                                                                          }}
                                                                                          to={`/employeeProfile`}>
                                                                                          {title +
                                                                                              ", "}
                                                                                      </Link>
                                                                                  );
                                                                              }
                                                                          }
                                                                      )}
                                                            </td>
                                                            <td className="whitespace-nowrap flex align-top px-3 py-4 text-sm text-gray-500">
                                                                <div
                                                                    className={
                                                                        feature.featureid === 6 &&
                                                                        feature.status
                                                                            ? "translate-y-4"
                                                                            : ""
                                                                    }>
                                                                    <ToggleButton
                                                                        checked={feature.status}
                                                                        onChange={() => {
                                                                            enableDisable_customPermission(
                                                                                feature.id,
                                                                                feature.status,
                                                                                feature.data
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>

                                                                {feature.featureid === 6 &&
                                                                feature.status ? (
                                                                    <div className="w-2/5 ml-2">
                                                                        <SelectField
                                                                            onSelectChange={
                                                                                handleTimeChange
                                                                            }
                                                                            FieldName=""
                                                                            name={"Screen Interval"}
                                                                            arrayOfData={
                                                                                this.props
                                                                                    .timingArray
                                                                            }
                                                                            mainclassName={"mt-3"}
                                                                            id={"ScreenShotTime"}
                                                                            defaultValue={
                                                                                (screenshotTiming !==
                                                                                ""
                                                                                    ? screenshotTiming.timing
                                                                                    : "10") + ""
                                                                            }
                                                                            // value={this.state.initialScreenShotIntervalData == "" ? "" : this.state.initialScreenShotIntervalData[0].screenshot_interval}
                                                                        ></SelectField>
                                                                    </div>
                                                                ) : null}
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    features: state.settings,
    overideFeatureList: state.settings.overideFeatureList,
});

const mapDispatchToProps = (dispatch) => ({
    getOverideFeatureList: (ids, type) => dispatch(getOverideFeatureList(ids, type)),
  });
Global_permisson_tab.propTypes = {
    enableDisable_customPermission: PropTypes.func
};
export default withTranslation('common')(connect(mapStateToProps,mapDispatchToProps)(Global_permisson_tab));