import React, { Component } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import { CalendarIcon, ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { withTranslation } from "react-i18next";
import SelectField from "../../../Components/SelectField";
import moment from "moment/moment";
import noDataSVG from "../../../images/nodata.svg";
import { getAppsLogged, getSingleAppLog } from "../../../services/timetracker/actions";
import ReactHighcharts from "react-highcharts";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
class DesktopApps extends Component {
    constructor() {
        super();
        this.state = {
            selectedDate: new Date(),
            currentDate: new Date(),
            projectname: "",
            employeeId: "",
            selectedTimezone: "",
            detailsViewOpened: [],
            applogs: [],
            detailsView: [],
        };
    }
    componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        const empname = query.get("empname");
        const project = query.get("project");
        const paramDate = query.get("date") ? query.get("date").split("-") : null;
        const paramTimezone = query.get("timezone");
        if (empname && empname!=='undefined') {
            this.props
                .getAppsLogged(
                    empname +
                    "&date=" +
                    (query.get("date")
                        ? query.get("date")
                        : moment(this.state.selectedDate).format("YYYY-MM-DD"))
                )
                .then((res) => {
                    if (res.status === 200) {
                        let ar = [];
                        let detView = [];
                        for (let i = 0; i < res.value.data.length; i++) {
                            ar.push(false);
                            detView.push("");
                        }
                        this.setState({
                            applogs: res.value.data,
                            detailsViewOpened: ar,
                            detailsView: detView,
                        });
                    }
                });
        }
        this.setState({
            employeeId: empname ?? "",
            projectname: project ?? "",
            selectedDate: paramDate
                ? new Date(paramDate[0], paramDate[1] - 1, paramDate[2])
                : new Date(),
            selectedTimezone: paramTimezone,
        });
        if (!paramTimezone && sessionStorage.getItem('timezone')) {
            const defaultTimezone = sessionStorage.getItem('timezone');
            this.onTimeZoneChange({ value: defaultTimezone });
        }
    }
    handleDateChange = (date) => {
        this.setState(
            { selectedDate: date, applogs: [], selectedTimezone: this.state.selectedTimezone },
            () => {
                if (this.state.employeeId === "") {
                } else {
                    let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                    this.props
                        .getAppsLogged(this.state.employeeId + "&date=" + date)
                        .then((res) => {
                            if (res.status === 200) {
                                let ar = [];
                                let detView = [];
                                for (let i = 0; i < res.value.data.length; i++) {
                                    ar.push(false);
                                    detView.push("");
                                }
                                this.setState({
                                    applogs: res.value.data,
                                    detailsViewOpened: ar,
                                    detailsView: detView,
                                });
                            }
                        });
                    window.history.pushState(
                        {},
                        null,
                        `/timetracker/applications/?empname=${this.state.employeeId}&date=${moment(
                            this.state.selectedDate
                        ).format("YYYY-MM-DD")}&timezone=${this.state.selectedTimezone}`
                    );
                }
            }
        );
    };

    onEmployeeAppLogChange = (e) => {
        this.setState(
            {
                employeeId: e.target.value,
                showProgramList: false,
                applogs: [],
                selectedTimezone: this.state.selectedTimezone,
            },
            () => {
                if (this.state.employeeId === "") {
                } else {
                    window.history.pushState(
                        {},
                        null,
                        `/timetracker/applications/?empname=${this.state.employeeId}&date=${moment(
                            this.state.selectedDate
                        ).format("YYYY-MM-DD")}&timezone=${this.state.selectedTimezone}`
                    );
                    let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
                    this.props
                        .getAppsLogged(this.state.employeeId + "&date=" + date)
                        .then((res) => {
                            if (res.status === 200) {
                                let ar = [];
                                let detView = [];
                                for (let i = 0; i < res.value.data.length; i++) {
                                    ar.push(false);
                                    detView.push("");
                                }
                                this.setState({
                                    applogs: res.value.data,
                                    detailsViewOpened: ar,
                                    detailsView: detView,
                                });
                            }
                        });
                }
            }
        );
    };

    onTimeZoneChange = (timezone) => {
        let z = this.state.currentDate.toLocaleString("en-US", {
            timeZone: timezone.value,
        });
        this.setState({ selectedDate: new Date(z), selectedTimezone: timezone.value }, () => {
            window.history.pushState(
                {},
                null,
                `/timetracker/applications/?empname=${this.state.employeeId}&date=${moment(
                    this.state.selectedDate
                ).format("YYYY-MM-DD")}&timezone=${timezone.value}`
            );
        });
    };
    render() {
        const { t } = this.props;
        let employees = this.props.projects && this.props.projects.AllEmployees;
        let EmployeeData =
            employees &&
            employees.map((el) => ({
                name: el.user_login_as.includes("from")
                    ? el.user_first_name + " " + el.user_last_name + " (obs)"
                    : el.user_first_name + " " + el.user_last_name,
                id: el.user_id,
            }));

        EmployeeData = EmployeeData && [...EmployeeData]?.sort((a, b) => a.name.localeCompare(b.name))

        let pieChartData = this.state.applogs && this.state.applogs.length > 0 && this.state.applogs.map((val) => ({
            name: val.program,
            y: val.percentage,
        }));
        const useTimeChart = {
            chart: {
                type: "pie",
                height: 400,
                style: {
                    fontFamily: "Lato",
                    fontSize: "15px",
                },
            },
            tooltip: {
                pointFormat: "",
            },
            title: {
                text: "",
            },

            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        format: "<div style='font-size:20px;color:{point.color};'>{point.y}%</div><br /><div style='font-size:16px;color:black;font-weight:400;text-transform:capitalize;'>{point.name}</div>",
                    },
                    showInLegend: false,
                },
            },

            credits: {
                enabled: false,
            },
            series: [
                {
                    data: pieChartData,
                },
            ],
        };
        const storedTimezone = sessionStorage.getItem('timezone') || '';
        const timezone = this.state.selectedTimezone;
        const selectedTimezone = this.state.selectedTimezone !== '' ? this.state.selectedTimezone : storedTimezone;
        return (
            <div>
                <div className="flex items-start lg:items-center flex-col lg:flex-row justify-start lg:justify-between flex-wrap bg-white mt-4 px-4 py-2 border-b border-gray-200 rounded-lg">
                    <div className="flex text-textprimary text-lg font-medium lg-m:w-full">
                        <DatePicker
                            className="rounded-md lg-m:w-full"
                            dateFormat="MMMM dd yyyy"
                            selected={this.state.selectedDate}
                            onChange={(date) => this.handleDateChange(date)}
                        />
                        <CalendarIcon className="-ml-6 z-10 w-5"></CalendarIcon>
                    </div>
                    <div className="flex flex-row flex-wrap items-end justify-between mt-4 lg:mt-0 lg-m:w-full">
                        <div className="ml-0 lg:ml-4 w-56 md-m:w-full">
                            <SelectField
                                onSelectChange={(e) => this.onEmployeeAppLogChange(e)}
                                FieldName={t("select") + " " + t("employee")}
                                name={"employee"}
                                arrayOfData={EmployeeData}
                                id={"employee"}
                                value={
                                    this.state.employeeId === ""
                                        ? "Select Employee"
                                        : this.state.employeeId
                                }></SelectField>
                        </div>
                        <div className="w-60 md-m:w-full ml-0 lg:ml-3 mt-4 md:mt-0">
                            <TimezoneSelect
                                placeholder={t("pleaseSelectTimeZone")}
                                className="rounded-lg border-none mr-4 lg:mr-4 w-60 md-m:w-full"
                                value={this.state.selectedTimezone}
                                timezones={{ ...allTimezones }}
                                // onChange={(e) => this.onTimeZoneChange(e)}
                                onChange={(e) => {
                                    this.setState({
                                        selectedTimezone: e.value,
                                    });
                                    this.onTimeZoneChange(e);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="bg-white mt-4 px-4 py-2 border-b border-gray-200 rounded-lg">
                    <div className="mt-4 border-b border-[#D1D5DB] pb-1">
                        <div className="text-xl font-semibold">
                            {t("timeTracker.desktop")} {t("ReportsTab.applications")}
                        </div>
                        <div className="text-tiny text-[#8C8C8C]">
                            {t("timeTracker.desktopTabinfo")}
                        </div>
                    </div>
                    {this.state.applogs && this.state.applogs.length > 0 ? (
                        <ReactHighcharts config={useTimeChart} />
                    ) : (
                        <div className="h-64 w-full bg-white mt-4 shadow-md rounded-md p-2 flex justify-center items-center">
                            <div className="flex flex-col justify-center items-center mt-5">
                                <img src={noDataSVG} alt="no data" className="h-16 w-16 mb-3" />
                                <div className="text-lg text-primary font-semibold">
                                    {t("timeTracker.noDataForDetailedGraph")}
                                </div>
                            </div>
                        </div>
                    )}
                    {this.state.applogs && this.state.applogs.length > 0 ? (
                        <div className="font-semibold">
                            <div className="flex justify-between shadow rounded-b-md py-2 px-2 lg:px-4 xl:py-4">
                                <div>{t("requestsModule.applicationCap")}</div>
                                <div className="xl:w-80">
                                    {t("total")} {t("timeTracker.timeSpend")}
                                </div>
                            </div>
                            {this.state.applogs && this.state.applogs.length > 0 && this.state.applogs.map((val, index) => (
                                <div
                                    key={val.program + index}
                                    className="py-2 shadow rounded-b-md md:px-2 lg:px-4 C">
                                    <div className="flex justify-between">
                                        <div className="capitalize">{val.program}</div>
                                        <div
                                            className="flex justify-between hover:cursor-pointer xl:w-80"
                                            onClick={() => {
                                                const valOpen = this.state.detailsViewOpened[index];
                                                this.setState({
                                                    detailsViewOpened:
                                                        this.state.detailsViewOpened.map(
                                                            (openVal, idx) =>
                                                                idx === index ? !openVal : openVal
                                                        ),
                                                });
                                                if (!valOpen && !this.state.detailsView[index]) {
                                                    this.props
                                                        .getSingleAppLog(
                                                            `${this.state.employeeId}&date=${moment(
                                                                this.state.selectedDate
                                                            ).format("YYYY-MM-DD")}&program=${val.id
                                                            }`
                                                        )
                                                        .then((res) => {
                                                            let timeList = [];
                                                            let durList = [];
                                                            res.value.data.map((en) => {
                                                                timeList.push(en.time.slice(0, 5));
                                                                durList.push(en.duration);
                                                            });
                                                            let arrList = [
                                                                ...this.state.detailsView,
                                                            ];
                                                            arrList[index] = {
                                                                time: timeList,
                                                                values: durList,
                                                            };
                                                            this.setState({ detailsView: arrList });
                                                        });
                                                }
                                            }}>
                                            <div>{val.time}</div>
                                            {this.state.detailsViewOpened[index] ? (
                                                <ChevronDownIcon className="w-6 h-6 lg:w-7 lg:h-7 lg:mr-2 xl:mr-5" />
                                            ) : (
                                                <ChevronRightIcon className="w-6 h-6 lg:w-7 lg:h-7 lg:mr-2 xl:mr-5" />
                                            )}
                                        </div>
                                    </div>
                                    {this.state.detailsViewOpened[index] &&
                                        (this.state.detailsView[index] ? (
                                            <div className="pt-2">
                                                <ReactHighcharts
                                                    config={{
                                                        chart: {
                                                            type: "column",
                                                            height: 250,
                                                            style: {
                                                                fontFamily: "Lato",
                                                                fontSize: "15px",
                                                            },
                                                        },
                                                        title: {
                                                            text: "",
                                                        },
                                                        plotOptions: {
                                                            series: {
                                                                cursor: "pointer",
                                                                point: {
                                                                    events: {
                                                                        click: (e) => {
                                                                            window.location.href =
                                                                                "/timetracker/screenshots/" +
                                                                                window.location
                                                                                    .search;
                                                                        },
                                                                    },
                                                                },
                                                            },
                                                            column: {
                                                                zones: [
                                                                    {
                                                                        value: 0,
                                                                        color: "#684D94",
                                                                    },
                                                                ],
                                                                pointPadding: 0.1,
                                                                borderWidth: 0,
                                                            },
                                                        },
                                                        xAxis: {
                                                            categories:
                                                                // this.state.detailsView[index].time.map((time)=>{
                                                                //     return moment(time, 'HH:mm').tz(this.state.selectedTimezone).format('HH:mm')
                                                                // }),
                                                                this.state.selectedTimezone ?
                                                                    this.state.detailsView[index].time?.map((time) => {
                                                                        try {
                                                                            if (time) {
                                                                                const newtimearr = moment(time, 'HH:mm').tz(timezone);
                                                                                if (newtimearr.isValid()) {
                                                                                    return newtimearr?.format('HH:mm');
                                                                                } else {
                                                                                    console.error('Invalid time:', time);
                                                                                    return null;
                                                                                }
                                                                            }
                                                                            return null;
                                                                        } catch (error) {
                                                                            console.error('Error formatting time:', error);
                                                                            return null;
                                                                        }
                                                                    }) : this.state.detailsView[index].time,
                                                            // categories:
                                                            //     this.state.detailsView[index].time,
                                                            crosshair: true,
                                                        },
                                                        yAxis: [
                                                            {
                                                                title: {
                                                                    text: "",
                                                                },
                                                            },
                                                        ],
                                                        tooltip: {
                                                            valueSuffix: "m",
                                                        },
                                                        credits: {
                                                            enabled: false,
                                                        },
                                                        series: [
                                                            {
                                                                showInLegend: false,
                                                                color: "#684D94",
                                                                name: "Time Spend",
                                                                data: this.state.detailsView[index]
                                                                    .values,
                                                            },
                                                        ],
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <div className="h-60 w-full text-center">
                                                <BeatLoader color="#684D94" />
                                            </div>
                                        ))}
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    projects: state.projects,
    timetracker: state.timetracker,
    emplistOBS: state.dashboard,
    localStorageState: state.globalLocalStorageReducer,
});

const mapDispatchToProps = (dispatch) => ({
    getAppsLogged: (params) => dispatch(getAppsLogged(params)),
    getSingleAppLog: (params) => dispatch(getSingleAppLog(params)),
});

DesktopApps.propTypes = {};
export default withTranslation("common")(connect(mapStateToProps, mapDispatchToProps)(DesktopApps));

// {this.state.apploguser === "" ? (
//     <div className="mt-12 text-center mb-12">
//         <CollectionIcon className="m-auto w-24 h-24"></CollectionIcon>
//         {t("timeTracker.chooseEmployeeToViewLogs")}
//     </div>
// ) : (
//     <div className="mt-6 flex flex-col overflow-y-auto">
//         {/* <div className="flex flex-wrap">
//             {this.state.applogs &&
//                 this.state.applogs.map((el, idx) => {
//                     return (
//                         <div
//                             key={idx}
//                             className="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-primary bg-gray-100">
//                             <div className="text-base font-normal leading-none max-w-full flex-initial p-2">
//                                 {el}
//                             </div>
//                         </div>
//                     );
//                 })}
//         </div> */}
//     </div>
// )}
