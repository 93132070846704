import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import InputField from "../../Components/InputField";

import SelectField from "../../Components/SelectField";
import { Add, Check, KeyboardArrowDown } from "@material-ui/icons";
import XeroLogo from "../../assets/xero.png";
import { useTranslation } from "react-i18next";
import { getXeroProject, postXeroProject } from "../../services/projects/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
const validEmailRegex = RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
export default function XeroIntegrationToProjectModal(props) {
    const { onCancelClick, onSubmitClick, submitButtonName, cancelButtonName, open, ...rest } =
        props;
    const cancelButtonRef = useRef(null);
    const [xeroConnecting, setXeroConnecting] = useState(false);
    const [xeroConnected, setXeroConnected] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddr, setEmailAddr] = useState("");
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    if (
        (props.connected === false || props.connected === true) &&
        xeroConnected !== props.connected
    ) {
        setXeroConnected(props.connected);
    }
    return (
        <Transition.Root show={open} as={Fragment} {...rest}>
            <Dialog
                as="div"
                className="fixed z-30 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                onClose={() => {
                    setXeroConnecting(false);
                    onCancelClick();
                }}>
                <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="relative inline-block max-w-3xl min-w-[750px] align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg leading-6 font-bold text-textprimary">
                                        Connected Apps
                                    </Dialog.Title>
                                </div>
                                <div className="mt-8">
                                    <Dialog.Description>
                                        {xeroConnecting && props.projectname ? (
                                            <div>
                                                <div>
                                                    Connect{" "}
                                                    <span className="capitalize">
                                                        {props.projectname}
                                                    </span>{" "}
                                                    with Xero
                                                </div>
                                                <div className="text-xs mt-1 text-[#6B7280]">
                                                    Enter recipient name and email address
                                                </div>
                                                <div className="grid grid-cols-2 gap-4 mt-5">
                                                    <div>
                                                        <InputField
                                                            onInputChange={(ev) => {
                                                                setFirstName(ev.target.value);
                                                            }}
                                                            FieldName={`First Name`}
                                                            name={"firstName"}
                                                            value={firstName}
                                                            type={"text"}
                                                            id={"firstName"}></InputField>
                                                    </div>
                                                    <div>
                                                        <InputField
                                                            onInputChange={(ev) => {
                                                                setLastName(ev.target.value);
                                                            }}
                                                            FieldName={`Last Name`}
                                                            name={"lastName"}
                                                            value={lastName}
                                                            type={"text"}
                                                            id={"lastName"}></InputField>
                                                    </div>
                                                    <div className="col-span-2 mt-4">
                                                        <InputField
                                                            onInputChange={(ev) => {
                                                                setEmailAddr(ev.target.value);
                                                            }}
                                                            FieldName={`Email Address`}
                                                            name={"emailAddress"}
                                                            value={emailAddr}
                                                            type={"text"}
                                                            id={"emailAddress"}></InputField>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="min-h-[300px] mt-4 2xl:mt-6">
                                                <div className="w-full border rounded-lg border-gray-300">
                                                    <div className="flex justify-between items-center p-3">
                                                        <div className="h-16 w-16 flex items-center justify-center">
                                                            <img
                                                                className="w-16 h-16 mr-4"
                                                                src={XeroLogo}
                                                                alt="Xero"
                                                            />
                                                        </div>
                                                        <div className="flex-1">
                                                            <div className="font-semibold text-lg">
                                                                Xero
                                                            </div>
                                                            <div className="text-tiny text-textprimary">
                                                                An accounting software for your
                                                                business connect with your bank,
                                                                accountant and bookkeeper and other
                                                                business apps
                                                            </div>
                                                        </div>
                                                        <div className="relative">
                                                            {xeroConnected ? (
                                                                <div className="text-primary py-2 px-3 hover:cursor-pointer">
                                                                    <Check />
                                                                </div>
                                                            ) : (
                                                                <button
                                                                    onClick={() => {
                                                                        dispatch(
                                                                            getXeroProject(
                                                                                props.projectId
                                                                            )
                                                                        ).then((res) => {
                                                                            if (
                                                                                res.value.data.data
                                                                                    .email ||
                                                                                res.value.data.data
                                                                                    .first_name
                                                                            ) {
                                                                                setEmailAddr(
                                                                                    res.value.data
                                                                                        .data.email
                                                                                );
                                                                                setFirstName(
                                                                                    res.value.data
                                                                                        .data
                                                                                        .first_name
                                                                                );
                                                                                setLastName(
                                                                                    res.value.data
                                                                                        .data
                                                                                        .last_name
                                                                                );
                                                                            } else {
                                                                                setEmailAddr("");
                                                                                setFirstName("");
                                                                                setLastName("");
                                                                            }
                                                                            setXeroConnecting(true);
                                                                        });
                                                                    }}
                                                                    className="text-gray-500 py-2 px-3 border border-white outline-none hover:text-black hover:cursor-pointer">
                                                                    <Add />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Dialog.Description>
                                </div>
                            </div>
                            {xeroConnecting && (
                                <div className="mt-6 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => {
                                            if (!(firstName && lastName && emailAddr)) {
                                                toast.error("Please fill all the fields!");
                                            } else if (!validEmailRegex.test(emailAddr)) {
                                                toast.error("Please enter a valid email address!!");
                                            } else {
                                                const formData = new FormData();
                                                formData.append("first_name", firstName);
                                                formData.append("last_name", lastName);
                                                formData.append("email", emailAddr);
                                                formData.append("id", props.projectId);
                                                dispatch(postXeroProject(formData)).then((res) => {
                                                    if (res.value.status === 200) {
                                                        setXeroConnected(true);
                                                        setXeroConnecting(false);
                                                        onSubmitClick();
                                                    }
                                                });
                                            }
                                        }}>
                                        Done
                                    </button>
                                </div>
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
