export const storeToLocalStorage = (state) => {
    return (dispatch) => {
        dispatch({
            type: 'STORE_TO_LOCALSTORAGE',
            payload: state
        })
    }
}

export const showData = (state) => {
    return (dispatch) => {
        dispatch({
            type: 'SHOW_DATA',
            payload: state
        })
    }
}
