import { Component, Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PrimaryButton  from '../../Components/PrimaryButton/index';
import { postURLType } from '../../services/activityMonitor/actions';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';


class UpdateURLType extends Component {
    constructor() {
        super();
        this.state = {
            showNotification : false,
        }
    }

    componentDidMount() {
    }

    render () {

        const {t} = this.props;
        const {onCancelClick, open, data, ...rest} = this.props

        let postURLtype = this.props.posturltype
        let postURLtypeData = postURLtype && postURLtype.PostURLType

        const handleURLType = (type) => {
            let dataa = {
                title: data.title,
                url: data.url,
                type: type
            }

            this.props.postURLType(dataa).then((data) => {
                if(data.action.payload.status == 200){
                    toast.success('URL Type Updated Successfully');
                    this.props.onCancelClick();
                } else {
                    toast.error('Something went wrong');
                    this.props.onCancelClick();
                }
            });

            if(postURLtypeData == ""){
                toast.error('Something went wrong');
                this.props.onCancelClick();
            }
        }
    
        return (
            <Transition.Root show={this.props.open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onCancelClick}>
                    <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
                               <div className='my-4'>
                                   <div className='text-xl font-semibold mb-4'>
                                       Set URL Type
                                   </div>
                                   <div className='flex flex-col sm:flex-row'>
                                       <div className='mr-3 mb-2 sm:mb-0'><PrimaryButton onClick={() => {handleURLType('productive')}} buttontext={t("ReportsTab.productive")} /></div>
                                       <div className='mr-3 mb-2 sm:mb-0'><PrimaryButton onClick={() => {handleURLType('unproductive')}} buttontext={t("ReportsTab.unproductive")} /></div>
                                       <div className=''><PrimaryButton onClick={() => {handleURLType('undefined')}} buttontext={t("ReportsTab.undefined")} /></div>
                                   </div>
                               </div>
                               
                            </div>
                        </Transition.Child>
                    </div>
                    {/* <Example show={this.state.showNotification} close={() => closeShowNotification()}/> */}
                </Dialog>
            </Transition.Root>
        )
   }
}

const mapStateToProps = (state) => ({
    posturltype: state.activityIndex
});

const mapDispatchToProps = (dispatch) => ({
    postURLType: (data) => dispatch(postURLType(data)),
});

UpdateURLType.propTypes = {
    onCancelClick: PropTypes.func.isRequired,
    open: PropTypes.bool,
    data: PropTypes.object,
};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(UpdateURLType));
