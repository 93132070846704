export const ACTION_TYPES = {
    FETCH_ACTIVE_USERS_LIST: "FETCH_ACTIVE_USERS_LIST",
    FETCH_ACTIVE_USERS_LIST_PENDING: "FETCH_ACTIVE_USERS_LIST_PENDING",
    FETCH_ACTIVE_USERS_LIST_FULFILLED: "FETCH_ACTIVE_USERS_LIST_FULFILLED",
    FETCH_ACTIVE_USERS_LIST_REJECTED: "FETCH_ACTIVE_USERS_LIST_REJECTED",

    FETCH_INACTIVE_USERS_LIST: "FETCH_INACTIVE_USERS_LIST",
    FETCH_INACTIVE_USERS_LIST_PENDING: "FETCH_INACTIVE_USERS_LIST_PENDING",
    FETCH_INACTIVE_USERS_LIST_FULFILLED: "FETCH_INACTIVE_USERS_LIST_FULFILLED",
    FETCH_INACTIVE_USERS_LIST_REJECTED: "FETCH_INACTIVE_USERS_LIST_REJECTED",

    FETCH_WORKSTATIONS_LIST: "FETCH_WORKSTATIONS_LIST",
    FETCH_WORKSTATIONS_LIST_PENDING: "FETCH_WORKSTATIONS_LIST_PENDING",
    FETCH_WORKSTATIONS_LIST_FULFILLED: "FETCH_WORKSTATIONS_LIST_FULFILLED",
    FETCH_WORKSTATIONS_LIST_REJECTED: "FETCH_WORKSTATIONS_LIST_REJECTED",

    CREATE_TEAM: "CREATE_TEAM",
    CREATE_TEAM_PENDING: "CREATE_TEAM_PENDING",
    CREATE_TEAM_FULFILLED: "CREATE_TEAM_FULFILLED",
    CREATE_TEAM_REJECTED: "CREATE_TEAM_REJECTED",

    CREATE_CLIENT: "CREATE_CLIENT",
    CREATE_CLIENT_PENDING: "CREATE_CLIENT_PENDING",
    CREATE_CLIENT_FULFILLED: "CREATE_CLIENT_FULFILLED",
    CREATE_CLIENT_REJECTED: "CREATE_CLIENT_REJECTED",

    GET_CLIENT: "GET_CLIENT",
    GET_CLIENT_PENDING: "GET_CLIENT_PENDING",
    GET_CLIENT_FULFILLED: "GET_CLIENT_FULFILLED",
    GET_CLIENT_REJECTED: "GET_CLIENT_REJECTED",

    GET_INACTIVE_CLIENT: "GET_INACTIVE_CLIENT",
    GET_INACTIVE_CLIENT_PENDING: "GET_INACTIVE_CLIENT_PENDING",
    GET_INACTIVE_CLIENT_FULFILLED: "GET_INACTIVE_CLIENT_FULFILLED",
    GET_INACTIVE_CLIENT_REJECTED: "GET_INACTIVE_CLIENT_REJECTED",

    DELETE_CLIENT: "DELETE_CLIENT",
    DELETE_CLIENT_PENDING: "DELETE_CLIENT_PENDING",
    DELETE_CLIENT_FULFILLED: "DELETE_CLIENT_FULFILLED",
    DELETE_CLIENT_REJECTED: "DELETE_CLIENT_REJECTED",

    ADD_CLIENT_TO_TEAM: "ADD_CLIENT_TO_TEAM",
    ADD_CLIENT_TO_TEAM_PENDING: "ADD_CLIENT_TO_TEAM_PENDING",
    ADD_CLIENT_TO_TEAM_FULFILLED: "ADD_CLIENT_TO_TEAM_FULFILLED",
    ADD_CLIENT_TO_TEAM_REJECTED: "ADD_CLIENT_TO_TEAM_REJECTED",

    FETCH_CLIENTS_LIST: "FETCH_CLIENTS_LIST",
    FETCH_CLIENTS_LIST_PENDING: "FETCH_CLIENTS_LIST_PENDING",
    FETCH_CLIENTS_LIST_FULFILLED: "FETCH_CLIENTS_LIST_FULFILLED",
    FETCH_CLIENTS_LIST_REJECTED: "FETCH_CLIENTS_LIST_REJECTED",

    ADD_USER_TO_PROJECT: "ADD_USER_TO_PROJECT",
    ADD_USER_TO_PROJECT_PENDING: "ADD_USER_TO_PROJECT_PENDING",
    ADD_USER_TO_PROJECT_FULFILLED: "ADD_USER_TO_PROJECT_FULFILLED",
    ADD_USER_TO_PROJECT_REJECTED: "ADD_USER_TO_PROJECT_REJECTED",

    CREATE_PROJECT: "CREATE_PROJECT",
    CREATE_PROJECT_PENDING: "CREATE_PROJECT_PENDING",
    CREATE_PROJECT_FULFILLED: "CREATE_PROJECT_FULFILLED",
    CREATE_PROJECT_REJECTED: "CREATE_PROJECT_REJECTED",

    UPDATE_PROJECT: "UPDATE_PROJECT",
    UPDATE_PROJECT_PENDING: "UPDATE_PROJECT_PENDING",
    UPDATE_PROJECT_FULFILLED: "UPDATE_PROJECT_FULFILLED",
    UPDATE_PROJECT_REJECTED: "UPDATE_PROJECT_REJECTED",

    CREATE_TASK: "CREATE_TASK",
    CREATE_TASK_PENDING: "CREATE_TASK_PENDING",
    CREATE_TASK_FULFILLED: "CREATE_TASK_FULFILLED",
    CREATE_TASK_REJECTED: "CREATE_TASK_REJECTED",

    UPDATE_TASK: "UPDATE_TASK",
    UPDATE_TASK_PENDING: "UPDATE_TASK_PENDING",
    UPDATE_TASK_FULFILLED: "UPDATE_TASK_FULFILLED",
    UPDATE_TASK_REJECTED: "UPDATE_TASK_REJECTED",

    DELETE_PROJECT: "DELETE_PROJECT",
    DELETE_PROJECT_PENDING: "DELETE_PROJECT_PENDING",
    DELETE_PROJECT_FULFILLED: "DELETE_PROJECT_FULFILLED",
    DELETE_PROJECT_REJECTED: "DELETE_PROJECT_REJECTED",

    DELETE_TASK: "DELETE_TASK",
    DELETE_TASK_PENDING: "DELETE_TASK_PENDING",
    DELETE_TASK_FULFILLED: "DELETE_TASK_FULFILLED",
    DELETE_TASK_REJECTED: "DELETE_TASK_REJECTED",

    REACTIVATE_TEAM: "REACTIVATE_TEAM",
    REACTIVATE_TEAM_PENDING: "REACTIVATE_TEAM_PENDING",
    REACTIVATE_TEAM_FULFILLED: "REACTIVATE_TEAM_FULFILLED",
    REACTIVATE_TEAM_REJECTED: "REACTIVATE_TEAM_REJECTED",

    ACTIVE_TEAMS: "ACTIVE_TEAMS",
    ACTIVE_TEAMS_PENDING: "ACTIVE_TEAMS_PENDING",
    ACTIVE_TEAMS_FULFILLED: "ACTIVE_TEAMS_FULFILLED",
    ACTIVE_TEAMS_REJECTED: "ACTIVE_TEAMS_REJECTED",

    INACTIVE_TEAMS: "INACTIVE_TEAMS",
    INACTIVE_TEAMS_PENDING: "INACTIVE_TEAMS_PENDING",
    INACTIVE_TEAMS_FULFILLED: "INACTIVE_TEAMS_FULFILLED",
    INACTIVE_TEAMS_REJECTED: "INACTIVE_TEAMS_REJECTED",

    FETCH_ACTIVE_PROJECTS: "FETCH_ACTIVE_PROJECTS",
    FETCH_ACTIVE_PROJECTS_PENDING: "FETCH_ACTIVE_PROJECTS_PENDING",
    FETCH_ACTIVE_PROJECTS_FULFILLED: "FETCH_ACTIVE_PROJECTS_FULFILLED",
    FETCH_ACTIVE_PROJECTS_REJECTED: "FETCH_ACTIVE_PROJECTS_REJECTED",

    FETCH_ACTIVE_TASKS: "FETCH_ACTIVE_TASKS",
    FETCH_ACTIVE_TASKS_PENDING: "FETCH_ACTIVE_TASKS_PENDING",
    FETCH_ACTIVE_TASKS_FULFILLED: "FETCH_ACTIVE_TASKS_FULFILLED",
    FETCH_ACTIVE_TASKS_REJECTED: "FETCH_ACTIVE_TASKS_REJECTED",

    FETCH_EMPLOYEES: "FETCH_EMPLOYEES",
    FETCH_EMPLOYEES_PENDING: "FETCH_EMPLOYEES_PENDING",
    FETCH_EMPLOYEES_FULFILLED: "FETCH_EMPLOYEES_FULFILLED",
    FETCH_EMPLOYEES_REJECTED: "FETCH_EMPLOYEES_REJECTED",

    DELETE_TEAM_PADLOCK: "DELETE_TEAM_PADLOCK",
    DELETE_TEAM_PADLOCK_PENDING: "DELETE_TEAM_PADLOCK_PENDING",
    DELETE_TEAM_PADLOCK_FULFILLED: "DELETE_TEAM_PADLOCK_FULFILLED",
    DELETE_TEAM_PADLOCK_REJECTED: "DELETE_TEAM_PADLOCK_REJECTED",

    DELETE_TEAM: "DELETE_TEAM",
    DELETE_TEAM_PENDING: "DELETE_TEAM_PENDING",
    DELETE_TEAM_FULFILLED: "DELETE_TEAM_FULFILLED",
    DELETE_TEAM_REJECTED: "DELETE_TEAM_REJECTED",

    DELETE_USER_PADLOCK: "DELETE_USER_PADLOCK",
    DELETE_USER_PADLOCK_PENDING: "DELETE_USER_PADLOCK_PENDING",
    DELETE_USER_PADLOCK_FULFILLED: "DELETE_USER_PADLOCK_FULFILLED",
    DELETE_USER_PADLOCK_REJECTED: "DELETE_USER_PADLOCK_REJECTED",

    DELETE_USER: "DELETE_USER",
    DELETE_USER_PENDING: "DELETE_USER_PENDING",
    DELETE_USER_FULFILLED: "DELETE_USER_FULFILLED",
    DELETE_USER_REJECTED: "DELETE_USER_REJECTED",

    REACTIVATE_USER_PADLOCK: "REACTIVATE_USER_PADLOCK",
    REACTIVATE_USER_PADLOCK_PENDING: "REACTIVATE_USER_PADLOCK_PENDING",
    REACTIVATE_USER_PADLOCK_FULFILLED: "REACTIVATE_USER_PADLOCK_FULFILLED",
    REACTIVATE_USER_PADLOCK_REJECTED: "REACTIVATE_USER_PADLOCK_REJECTED",

    REACTIVATE_USER: "REACTIVATE_USER",
    REACTIVATE_USER_PENDING: "REACTIVATE_USER_PENDING",
    REACTIVATE_USER_FULFILLED: "REACTIVATE_USER_FULFILLED",
    REACTIVATE_USER_REJECTED: "REACTIVATE_USER_REJECTED",

    DELETE_WORKSTATION: "DELETE_WORKSTATION",
    DELETE_WORKSTATION_PENDING: "DELETE_WORKSTATION_PENDING",
    DELETE_WORKSTATION_FULFILLED: "DELETE_WORKSTATION_FULFILLED",
    DELETE_WORKSTATION_REJECTED: "DELETE_WORKSTATION_REJECTED",

    CREATE_WORKSTATION: "CREATE_WORKSTATION",
    CREATE_WORKSTATION_PENDING: "CREATE_WORKSTATION_PENDING",
    CREATE_WORKSTATION_FULFILLED: "CREATE_WORKSTATION_FULFILLED",
    CREATE_WORKSTATION_REJECTED: "CREATE_WORKSTATION_REJECTED",

    UPDATE_WORKSTATION: "UPDATE_WORKSTATION",
    UPDATE_WORKSTATION_PENDING: "UPDATE_WORKSTATION_PENDING",
    UPDATE_WORKSTATION_FULFILLED: "UPDATE_WORKSTATION_FULFILLED",
    UPDATE_WORKSTATION_REJECTED: "UPDATE_WORKSTATION_REJECTED",

    GET_USERS_FROM_TEAM: "GET_USERS_FROM_TEAM",
    GET_USERS_FROM_TEAM_PENDING: "GET_USERS_FROM_TEAM_PENDING",
    GET_USERS_FROM_TEAM_FULFILLED: "GET_USERS_FROM_TEAM_FULFILLED",
    GET_USERS_FROM_TEAM_REJECTED: "GET_USERS_FROM_TEAM_REJECTED",

    //activity index
    GET_ACTIVITY_INDEX: "GET_ACTIVITY_INDEX",
    GET_ACTIVITY_INDEX_PENDING: "GET_ACTIVITY_INDEX_PENDING",
    GET_ACTIVITY_INDEX_FULFILLED: "GET_ACTIVITY_INDEX_FULFILLED",
    GET_ACTIVITY_INDEX_REJECTED: "GET_ACTIVITY_INDEX_REJECTED",

    //user urls visited
    GET_USER_URLS: "GET_USER_URLS",
    GET_USER_URLS_PENDING: "GET_USER_URLS_PENDING",
    GET_USER_URLS_FULFILLED: "GET_USER_URLS_FULFILLED",
    GET_USER_URLS_REJECTED: "GET_USER_URLS_REJECTED",

    //all urls
    GET_ALL_URLS: "GET_ALL_URLS",
    GET_ALL_URLS_PENDING: "GET_ALL_URLS_PENDING",
    GET_ALL_URLS_FULFILLED: "GET_ALL_URLS_FULFILLED",
    GET_ALL_URLS_REJECTED: "GET_ALL_URLS_REJECTED",

    //post URL Type
    POST_URL_TYPE: "POST_URL_TYPE",
    POST_URL_TYPE_PENDING: "POST_URL_TYPE_PENDING",
    POST_URL_TYPE_FULFILLED: "POST_URL_TYPE_FULFILLED",
    POST_URL_TYPE_REJECTED: "POST_URL_TYPE_REJECTED",

    //edit URL Type
    EDIT_URL_TYPE: "EDIT_URL_TYPE",
    EDIT_URL_TYPE_PENDING: "EDIT_URL_TYPE_PENDING",
    EDIT_URL_TYPE_FULFILLED: "EDIT_URL_TYPE_FULFILLED",
    EDIT_URL_TYPE_REJECTED: "EDIT_URL_TYPE_REJECTED",

    SEND_USER_INVITE: "SEND_USER_INVITE",
    SEND_USER_INVITE_PENDING: "SEND_USER_INVITE_PENDING",
    SEND_USER_INVITE_FULFILLED: "SEND_USER_INVITE_FULFILLED",
    SEND_USER_INVITE_REJECTED: "SEND_USER_INVITE_REJECTED",

    ORG_EMAILS: "ORG_EMAILS",
    ORG_EMAILS_PENDING: "ORG_EMAILS_PENDING",
    ORG_EMAILS_FULFILLED: "ORG_EMAILS_FULFILLED",
    ORG_EMAILS_REJECTED: "ORG_EMAILS_REJECTED",

    UPDATE_USER_EMAIL: "UPDATE_USER_EMAIL",
    UPDATE_USER_EMAIL_PENDING: "UPDATE_USER_EMAIL_PENDING",
    UPDATE_USER_EMAIL_FULFILLED: "UPDATE_USER_EMAIL_FULFILLED",
    UPDATE_USER_EMAIL_REJECTED: "UPDATE_USER_EMAIL_REJECTED",

    DELETE_ORG_EMAIL: "DELETE_ORG_EMAIL",

    //get all request
    GET_ALL_REQUEST: "GET_ALL_REQUEST",
    GET_ALL_REQUEST_PENDING: "GET_ALL_REQUEST_PENDING",
    GET_ALL_REQUEST_FULFILLED: "GET_ALL_REQUEST_FULFILLED",
    GET_ALL_REQUEST_REJECTED: "GET_ALL_REQUEST_REJECTED",

    //admin approve reject request
    APPROVE_REJECT_REQUEST: "APPROVE_REJECT_REQUEST",
    APPROVE_REJECT_REQUEST_PENDING: "APPROVE_REJECT_REQUEST_PENDING",
    APPROVE_REJECT_REQUEST_FULFILLED: "APPROVE_REJECT_REQUEST_FULFILLED",
    APPROVE_REJECT_REQUEST_REJECTED: "APPROVE_REJECT_REQUEST_REJECTED",

    CREATE_LEAVE_REQUEST: "CREATE_LEAVE_REQUEST",
    CREATE_LEAVE_REQUEST_PENDING: "CREATE_LEAVE_REQUEST_PENDING",
    CREATE_LEAVE_REQUEST_FULFILLED: "CREATE_LEAVE_REQUEST_FULFILLED",
    CREATE_LEAVE_REQUEST_REJECTED: "CREATE_LEAVE_REQUEST_REJECTED",

    DELETE_LEAVE_REQUEST: "DELETE_LEAVE_REQUEST",
    DELETE_LEAVE_REQUEST_PENDING: "DELETE_LEAVE_REQUEST_PENDING",
    DELETE_LEAVE_REQUEST_FULFILLED: "DELETE_LEAVE_REQUEST_FULFILLED",
    DELETE_LEAVE_REQUEST_REJECTED: "DELETE_LEAVE_REQUEST_REJECTED",

    TEAM_DETAILS_DASHBOARD: "TEAM_DETAILS_DASHBOARD",
    TEAM_DETAILS_DASHBOARD_PENDING: "TEAM_DETAILS_DASHBOARD_PENDING",
    TEAM_DETAILS_DASHBOARD_FULFILLED: "TEAM_DETAILS_DASHBOARD_FULFILLED",
    TEAM_DETAILS_DASHBOARD_REJECTED: "TEAM_DETAILS_DASHBOARD_REJECTED",

    PROJECT_DETAILS_DASHBOARD: "PROJECT_DETAILS_DASHBOARD",
    PROJECT_DETAILS_DASHBOARD_PENDING: "PROJECT_DETAILS_DASHBOARD_PENDING",
    PROJECT_DETAILS_DASHBOARD_FULFILLED: "PROJECT_DETAILS_DASHBOARD_FULFILLED",
    PROJECT_DETAILS_DASHBOARD_REJECTED: "PROJECT_DETAILS_DASHBOARD_REJECTED",

    GET_APP_LOG: "GET_APP_LOG",
    GET_APP_LOG_DATA: "GET_APP_LOG_DATA",
    GET_APP_LOG_PENDING: "GET_APP_LOG_PENDING",
    GET_APP_LOG_FULFILLED: "GET_APP_LOG_FULFILLED",
    GET_APP_LOG_REJECTED: "GET_APP_LOG_REJECTED",

    TIME_TRACKER_DATA: "TIME_TRACKER_DATA",
    TIME_TRACKER_DATA_PENDING: "TIME_TRACKER_DATA_PENDING",
    TIME_TRACKER_DATA_FULFILLED: "TIME_TRACKER_DATA_FULFILLED",
    TIME_TRACKER_DATA_REJECTED: "TIME_TRACKER_DATA_REJECTED",

    CPU_INFORMATION_DATA: "CPU_INFORMATION_DATA",
    CPU_INFORMATION_DATA_PENDING: "CPU_INFORMATION_DATA_PENDING",
    CPU_INFORMATION_DATA_FULFILLED: "CPU_INFORMATION_DATA_FULFILLED",
    CPU_INFORMATION_DATA_REJECTED: "CPU_INFORMATION_DATA_REJECTED",

    FETCH_SYSTEM_INFO: "FETCH_SYSTEM_INFO",
    FETCH_SYSTEM_INFO_PENDING: "FETCH_SYSTEM_INFO_PENDING",
    FETCH_SYSTEM_INFO_FULFILLED: "FETCH_SYSTEM_INFO_FULFILLED",
    FETCH_SYSTEM_INFO_REJECTED: "FETCH_SYSTEM_INFO_REJECTED",

    GET_USERS_FROM_PROJECT: "GET_USERS_FROM_PROJECT",
    GET_USERS_FROM_PROJECT_PENDING: "GET_USERS_FROM_PROJECT_PENDING",
    GET_USERS_FROM_PROJECT_FULFILLED: "GET_USERS_FROM_PROJECT_FULFILLED",
    GET_USERS_FROM_PROJECT_REJECTED: "GET_USERS_FROM_PROJECT_REJECTED",

    DELETE_USER_FROM_PROJECT: "DELETE_USER_FROM_PROJECT",
    DELETE_USER_FROM_PROJECT_PENDING: "DELETE_USER_FROM_PROJECT_PENDING",
    DELETE_USER_FROM_PROJECT_FULFILLED: "DELETE_USER_FROM_PROJECT_FULFILLED",
    DELETE_USER_FROM_PROJECT_REJECTED: "DELETE_USER_FROM_PROJECT_REJECTED",

    GET_PAYMENT_PLAN: "GET_PAYMENT_PLAN",
    GET_PAYMENT_PLAN_PENDING: "GET_PAYMENT_PLAN_PENDING",
    GET_PAYMENT_PLAN_FULFILLED: "GET_PAYMENT_PLAN_FULFILLED",
    GET_PAYMENT_PLAN_REJECTED: "GET_PAYMENT_PLAN_REJECTED",

    FETCH_ORG_UUID: "FETCH_ORG_UUID",
    FETCH_ORG_UUID_PENDING: "FETCH_ORG_UUID_PENDING",
    FETCH_ORG_UUID_FULFILLED: "FETCH_ORG_UUID_FULFILLED",
    FETCH_ORG_UUID_REJECTED: "FETCH_ORG_UUID_REJECTED",

    FETCH_API_KEYS: "FETCH_API_KEYS",
    FETCH_API_KEYS_PENDING: "FETCH_API_KEYS_PENDING",
    FETCH_API_KEYS_FULFILLED: "FETCH_API_KEYS_FULFILLED",
    FETCH_API_KEYS_REJECTED: "FETCH_API_KEYS_REJECTED",

    FETCH_SECRET_KEYS: "FETCH_SECRET_KEYS",
    FETCH_SECRET_KEYS_PENDING: "FETCH_SECRET_KEYS_PENDING",
    FETCH_SECRET_KEYS_FULFILLED: "FETCH_SECRET_KEYS_FULFILLED",
    FETCH_SECRET_KEYS_REJECTED: "FETCH_SECRET_KEYS_REJECTED",

    POST_SECRET_KEY: "POST_SECRET_KEYS",
    POST_SECRET_KEY_PENDING: "POST_SECRET_KEY_PENDING",
    POST_SECRET_KEY_FULFILLED: "POST_SECRET_KEY_FULFILLED",
    POST_SECRET_KEY_REJECTED: "POST_SECRET_KEY_REJECTED",

    DELETE_KEY: "DELETE_KEY",
    DELETE_KEY_PENDING: "DELETE_KEY_PENDING",
    DELETE_KEY_FULFILLED: "DELETE_KEY_FULFILLED",
    DELETE_KEY_REJECTED: "DELETE_KEY_REJECTED",

    API_KEY_STATUS_UPDATE: "API_KEY_STATUS_UPDATE",
    API_KEY_STATUS_UPDATE_PENDING: "API_KEY_STATUS_UPDATE_PENDING",
    API_KEY_STATUS_UPDATE_FULFILLED: "API_KEY_STATUS_UPDATE_FULFILLED",
    API_KEY_STATUS_UPDATE_REJECTED: "API_KEY_STATUS_UPDATE_REJECTED",

    GET_FEATURE_UNAVAILABLE: "GET_FEATURE_UNAVAILABLE",
    GET_FEATURE_UNAVAILABLE_PENDING: "GET_FEATURE_UNAVAILABLE_PENDING",
    GET_FEATURE_UNAVAILABLE_FULFILLED: "GET_FEATURE_UNAVAILABLE_FULFILLED",
    GET_FEATURE_UNAVAILABLE_REJECTED: "GET_FEATURE_UNAVAILABLE_REJECTED",

    GET_FEATURE_MARKET: "GET_FEATURE_MARKET",
    GET_FEATURE_MARKET_PENDING: "GET_FEATURE_MARKET_PENDING",
    GET_FEATURE_MARKET_FULFILLED: "GET_FEATURE_MARKET_FULFILLED",
    GET_FEATURE_MARKET_REJECTED: "GET_FEATURE_MARKET_REJECTED",

    FETCH_FEATURE_LIST: "FETCH_FEATURE_LIST",
    FETCH_FEATURE_LIST_PENDING: "FETCH_FEATURE_LIST_PENDING",
    FETCH_FEATURE_LIST_FULFILLED: "FETCH_FEATURE_LIST_FULFILLED",
    FETCH_FEATURE_LIST_REJECTED: "FETCH_FEATURE_LIST_REJECTED",

    FETCH_OVERIDE_FEATURE_LIST: "FETCH_OVERIDE_FEATURE_LIST",
    FETCH_OVERIDE_FEATURE_LIST_PENDING: "FETCH_OVERIDE_FEATURE_LIST_PENDING",
    FETCH_OVERIDE_FEATURE_LIST_FULFILLED: "FETCH_OVERIDE_FEATURE_LIST_FULFILLED",
    FETCH_OVERIDE_FEATURE_LIST_REJECTED: "FETCH_OVERIDE_FEATURE_LIST_REJECTED",

    FETCH_SCREENSHOT_TIMING: "FETCH_SCREENSHOT_TIMING",
    FETCH_SCREENSHOT_TIMING_PENDING: "FETCH_SCREENSHOT_TIMING_PENDING",
    FETCH_SCREENSHOT_TIMING_FULFILLED: "FETCH_SCREENSHOT_TIMING_FULFILLED",
    FETCH_SCREENSHOT_TIMING_REJECTED: "FETCH_SCREENSHOT_TIMING_REJECTED",

    POST_SCREENSHOT_TIMING: "POST_SCREENSHOT_TIMING",
    POST_SCREENSHOT_TIMING_PENDING: "POST_SCREENSHOT_TIMING_PENDING",
    POST_SCREENSHOT_TIMING_FULFILLED: "POST_SCREENSHOT_TIMING_FULFILLED",
    POST_SCREENSHOT_TIMING_REJECTED: "POST_SCREENSHOT_TIMING_REJECTED",

    POST_SCREENSHOT_TIME: "POST_SCREENSHOT_TIME",
    POST_SCREENSHOT_TIME_PENDING: "POST_SCREENSHOT_TIMING_PENDING",
    POST_SCREENSHOT_TIME_FULFILLED: "POST_SCREENSHOT_TIMING_FULFILLED",
    POST_SCREENSHOT_TIME_REJECTED: "POST_SCREENSHOT_TIMING_REJECTED",

    PATCH_SCREENSHOT_TIMING: "PATCH_SCREENSHOT_TIMING",
    PATCH_SCREENSHOT_TIMING_PENDING: "PATCH_SCREENSHOT_TIMING_PENDING",
    PATCH_SCREENSHOT_TIMING_FULFILLED: "PATCH_SCREENSHOT_TIMING_FULFILLED",
    PATCH_SCREENSHOT_TIMING_REJECTED: "PATCH_SCREENSHOT_TIMING_REJECTED",

    GET_FEATURE_KEY: "GET_FEATURE_KEY",
    GET_FEATURE_KEY_PENDING: "GET_FEATURE_KEY_PENDING",
    GET_FEATURE_KEY_FULFILLED: "GET_FEATURE_KEY_FULFILLED",
    GET_FEATURE_KEY_REJECTED: "GET_FEATURE_KEY_REJECTED",

    POST_FEATURE_START: "POST_FEATURE_START",
    POST_FEATURE_START_PENDING: "POST_FEATURE_START_PENDING",
    POST_FEATURE_START_FULFILLED: "POST_FEATURE_START_FULFILLED",
    POST_FEATURE_START_REJECTED: "POST_FEATURE_START_REJECTED",

    DELETE_FEATURE_MARKET: "DELETE_FEATURE_MARKET",
    DELETE_FEATURE_MARKET_PENDING: "DELETE_FEATURE_MARKET_PENDING",
    DELETE_FEATURE_MARKET_FULFILLED: "DELETE_FEATURE_MARKET_FULFILLED",
    DELETE_FEATURE_MARKET_REJECTED: "DELETE_FEATURE_MARKET_FULFILLED",

    DELETE_FEATURE_START: "DELETE_FEATURE_START",
    DELETE_FEATURE_START_PENDING: "DELETE_FEATURE_START_PENDING",
    DELETE_FEATURE_START_FULFILLED: "DELETE_FEATURE_START_FULFILLED",
    DELETE_FEATURE_START_REJECTED: "DELETE_FEATURE_START_REJECTED",

    POST_FEATURE_MARKET: "POST_FEATURE_MARKET",
    POST_FEATURE_MARKET_PENDING: "POST_FEATURE_MARKET_PENDING",
    POST_FEATURE_MARKET_FULFILLED: "POST_FEATURRE_MARKET_FULFILLED",
    POST_FEATURE_MARKET_REJECTED: "POST_FEATURE_MARKET_REJECTED",

    PUT_FEATURE_ENABLE: "PUT_FEATURE_ENABLE",
    PUT_FEATURE_ENABLE_PENDING: "PUT_FEATURE_ENABLE_PENDING",
    PUT_FEATURE_ENABLE_FULFILLED: "PUT_FEATURE_ENABLE_FULFILLED",
    PUT_FEATURE_ENABLE_REJECTED: "PUT_FEATURE_ENABLE_REJECTED",

    PUT_FEATURE_STOP: "PUT_FEATURE_STOP",
    PUT_FEATURE_STOP_PENDING: "PUT_FEATURE_STOP_PENDING",
    PUT_FEATURE_STOP_FULFILLED: "PUT_FEATURE_STOP_FULFILLED",
    PUT_FEATURE_STOP_REJECTED: "PUT_FEATURE_STOP_REJECTED",

    POST_FORGET_PASSWORD: "POST_FORGET_PASSWORD",
    POST_FORGET_PASSWORD_PENDING: "POST_FORGET_PASSWORD_PENDING",
    POST_FORGET_PASSWORD_FULFILLED: "POST_FORGET_PASSWORD_FULFILLED",
    POST_FORGET_PASSWORD_REJECTED: "POST_FORGET_PASSWORD_REJECTED",

    GET_SUBSCRIBE_EMAIL: "GET_SUBSCRIBE_EMAIL",
    GET_SUBSCRIBE_EMAIL_PENDING: "GET_SUBSCRIBE_EMAIL",
    GET_SUBSCRIBE_EMAIL_FULFILLED: "GET_SUBSCRIBE_EMAIL_FULFILLED",
    GET_SUBSCRIBE_EMAIL_REJECTED: "GET_SUBSCRIBE_EMAIL_REJECTED",

    UPDATE_SUBSCRIBE_EMAIL: "UPDATE_SUBSCRIBE_EMAIL",
    UPDATE_SUBSCRIBE_EMAIL_PENDING: "UPDATE_SUBSCRIBE_EMAIL_PENDING",
    UPDATE_SUBSCRIBE_EMAIL_FULFILLED: "UPDATE_SUBSCRIBE_EMAIL_FULFILLED",
    UPDATE_SUBSCRIBE_EMAIL_REJECTED: "UPDATE_SUBSCRIBE_EMAIL_REJECTED",

    GET_ROLE_UPDATE: "GET_ROLE_UPDATE",
    GET_ROLE_UPDATE_PENDING: "GET_ROLE_UPDATE_PENDING",
    GET_ROLE_UPDATE_FULFILLED: "GET_ROLE_UPDATE_FULFILLED",
    GET_ROLE_UPDATE_REJECTED: "GET_ROLE_UPDATE_REJECTED",

    GET_TYPE_INTERVAL: "GET_TYPE_INTERVAL",
    GET_TYPE_INTERVAL_PENDING: "GET_TYPE_INTERVAL_PENDING",
    GET_TYPE_INTERVAL_FULFILLED: "GET_TYPE_INTERVAL_FULFILLED",
    GET_TYPE_INTERVAL_REJECTED: "GET_TYPE_INTERVAL_REJECTED",

    POST_TYPE_INTERVAL: "POST_TYPE_INTERVAL",
    POST_TYPE_INTERVAL_PENDING: "POST_TYPE_INTERVAL_PENDING",
    POST_TYPE_INTERVAL_FULFILLED: "POST_TYPE_INTERVAL_FULFILLED",
    POST_TYPE_INTERVAL_REJECTED: "POST_TYPE_INTERVAL_REJECTED",

    GET_ADDRESS: "GET_ADDRESS",
    GET_ADDRESS_PENDING: "GET_ADDRESS_PENDING",
    GET_ADDRESS_FULFILLED: "GET_ADDRESS_FULFILLED",
    GET_ADDRESS_REJECTED: "GET_ADDRESS_REJECTED",

    POST_TYPE: "POST_TYPE",
    POST_TYPE_PENDING: "POST_TYPE_PENDING",
    POST_TYPE_FULFILLED: "POST_TYPE_FULFILLED",
    POST_TYPE_REJECTED: "POST_TYPE_REJECTED",

    GET_LAST_PAYMENT: "GET_LAST_PAYMENT",
    GET_LAST_PAYMENT_PENDING: "GET_LAST_PAYMENT_PENDING",
    GET_LAST_PAYMENT_FULFILLED: "GET_LAST_PAYMENT_FULFILLED",
    GET_LAST_PAYMENT_REJECTED: "GET_LAST_PAYMENT_REJECTED",

    POST_GENERATE_INVOICE: "POST_GENERATE_INVOICE",
    POST_GENERATE_INVOICE_PENDING: "POST_GENERATE_INVOICE_PENDING",
    POST_GENERATE_INVOICE_FULFILLED: "POST_GENERATE_INVOICE_FULFILLED",
    POST_GENERATE_INVOICE_REJECTED: "POST_GENERATE_INVOICE_REJECTED",

    POST_PDF_GENERATOR: "POST_PDF_GENERATOR",
    POST_PDF_GENERATOR_PENDING: "POST_PDF_GEBERATOR_PENDING",
    POST_PDF_GENERATOR_FULFILLED: "POST_PDF_GENERATOR_FULFILLED",
    POST_PDF_GENERATOR_REJECTED: "POST_PDF_GENERATOR_REJECTED",

    FETCH_MANAGER_DASHBOARD: "FETCH_MANAGER_DASHBOARD",
    FETCH_MANAGER_DASHBOARD_PENDING: "FETCH_MANAGER_DASHBOARD_PENDING",
    FETCH_MANAGER_DASHBOARD_FULFILLED: "FETCH_MANAGER_DASHBOARD_FULFILLED",
    FETCH_MANAGER_DASHBOARD_REJECTED: "FETCH_MANAGER_DASHBOARD_REJECTED",

    FETCH_TEAMS_DASHBOARD: "FETCH_TEAMS_DASHBOARD",
    FETCH_TEAMS_DASHBOARD_PENDING: "FETCH_TEAMS_DASHBOARD_PENDING",
    FETCH_TEAMS_DASHBOARD_FULFILLED: "FETCH_TEAMS_DASHBOARD_FULFILLED",
    FETCH_TEAMS_DASHBOARD_REJECTED: "FETCH_TEAMS_DASHBOARD_REJECTED",

    FETCH_NOT_WORKING_EMPLOYEES: "FETCH_NOT_WORKING_EMPLOYEES",
    FETCH_NOT_WORKING_EMPLOYEES_PENDING: "FETCH_NOT_WORKING_EMPLOYEES_PENDING",
    FETCH_NOT_WORKING_EMPLOYEES_FULFILLED: "FETCH_NOT_WORKING_EMPLOYEES_FULFILLED",
    FETCH_NOT_WORKING_EMPLOYEES_REJECTED: "FETCH_NOT_WORKING_EMPLOYEES_REJECTED",

    POST_PAYPAL_PAYMENT: "POST_PAYPAL_PAYMENT",
    POST_PAYPAL_PAYMENT_PENDING: "POST_PAYPAL_PAYMENT_PENDING",
    POST_PAYPAL_PAYMENT_FULFILLED: "POST_PAYPAL_PAYMENT_FULFILLED",
    POST_PAYPAL_PAYMENT_REJECTED: "POST_PAYPAL_PAYMENT_REJECTED",

    FETCH_EMPLOYEES_ATTENDANCE: "FETCH_EMPLOYEES_ATTENDANCE",
    FETCH_EMPLOYEES_ATTENDANCE_PENDING: "FETCH_EMPLOYEES_ATTENDANCE_PENDING",
    FETCH_EMPLOYEES_ATTENDANCE_FULFILLED: "FETCH_EMPLOYEES_ATTENDANCE_FULFILLED",
    FETCH_EMPLOYEES_ATTENDANCE_REJECTED: "FETCH_EMPLOYEES_ATTENDANCE_REJECTED",

    FETCH_EMPLOYEES_APPLICATION_REPORT: "FETCH_EMPLOYEES_APPLICATION_REPORT",
    FETCH_EMPLOYEES_APPLICATION_REPORT_PENDING: "FETCH_EMPLOYEES_APPLICATION_REPORT_PENDING",
    FETCH_EMPLOYEES_APPLICATION_REPORT_FULFILLED: "FETCH_EMPLOYEES_APPLICATION_REPORT_FULFILLED",
    FETCH_EMPLOYEES_APPLICATION_REPORT_REJECTED: "FETCH_EMPLOYEES_APPLICATION_REPORT_REJECTED",

    FETCH_EMPLOYEE_WEBSITE_REPORT: "FETCH_EMPLOYEE_WEBSITE_REPORT",
    FETCH_EMPLOYEE_WEBSITE_REPORT_PENDING: "FETCH_EMPLOYEE_WEBSITE_REPORT_PENDING",
    FETCH_EMPLOYEE_WEBSITE_REPORT_FULFILLED: "FETCH_EMPLOYEE_WEBSITE_REPORT_FULFILLED",
    FETCH_EMPLOYEE_WEBSITE_REPORT_REJECTED: "FETCH_EMPLOYEE_WEBSITE_REPORT_REJECTED",

    CUSTOM_WEEKLY_GRAPH_DATA: "CUSTOM_WEEKLY_GRAPH_DATA",
    CUSTOM_WEEKLY_GRAPH_DATA_PENDING: "CUSTOM_WEEKLY_GRAPH_DATA_PENDING",
    CUSTOM_WEEKLY_GRAPH_DATA_FULFILLED: "CUSTOM_WEEKLY_GRAPH_DATA_FULFILLED",
    CUSTOM_WEEKLY_GRAPH_DATA_REJECTED: "CUSTOM_WEEKLY_GRAPH_DATA_REJECTED",

    FETCH_HOUR_REPORT_QUICK: "FETCH_HOUR_REPORT_QUICK",
    FETCH_HOUR_REPORT_QUICK_PENDING: "FETCH_HOUR_REPORT_QUICK_PENDING",
    FETCH_HOUR_REPORT_QUICK_FULFILLED: "FETCH_HOUR_REPORT_QUICK_FULFILLED",
    FETCH_HOUR_REPORT_QUICK_REJECTED: "FETCH_HOUR_REPORT_QUICK_REJECTED",

    FETCH_HOUR_REPORT_CUSTOM: "FETCH_HOUR_REPORT_CUSTOM",
    FETCH_HOUR_REPORT_CUSTOM_PENDING: "FETCH_HOUR_REPORT_CUSTOM_PENDING",
    FETCH_HOUR_REPORT_CUSTOM_FULFILLED: "FETCH_HOUR_REPORT_CUSTOM_FULFILLED",
    FETCH_HOUR_REPORT_CUSTOM_REJECTED: "FETCH_HOUR_REPORT_CUSTOM_REJECTED",

    DOWNLOAD_HOUR_REPORT: "DOWNLOAD_HOUR_REPORT",
    DOWNLOAD_HOUR_REPORT_PENDING: "DOWNLOAD_HOUR_REPORT_PENDING",
    DOWNLOAD_HOUR_REPORT_FULFILLED: "DOWNLOAD_HOUR_REPORT_FULFILLED",
    DOWNLOAD_HOUR_REPORT_REJECTED: "DOWNLOAD_HOUR_REPORT_REJECTED",

    ADD_MEMBER_TO_TEAM_IN_PADLOCK: "ADD_MEMBER_TO_TEAM_IN_PADLOCK",
    ADD_MEMBER_TO_TEAM_IN_PADLOCK_PENDING: "ADD_MEMBER_TO_TEAM_IN_PADLOCK_PENDING",
    ADD_MEMBER_TO_TEAM_IN_PADLOCK_FULFILLED: "ADD_MEMBER_TO_TEAM_IN_PADLOCK_FULFILLED",
    ADD_MEMBER_TO_TEAM_IN_PADLOCK_REJECTED: "ADD_MEMBER_TO_TEAM_IN_PADLOCK_REJECTED",

    ADD_MEMBER_TO_TEAM: "ADD_MEMBER_TO_TEAM",
    ADD_MEMBER_TO_TEAM_PENDING: "ADD_MEMBER_TO_TEAM_PENDING",
    ADD_MEMBER_TO_TEAM_FULFILLED: "ADD_MEMBER_TO_TEAM_FULFILLED",
    ADD_MEMBER_TO_TEAM_REJECTED: "ADD_MEMBER_TO_TEAM_REJECTED",

    DELETE_TEAM_MEMBER: "DELETE_TEAM_MEMBER",
    DELETE_TEAM_MEMBER_PENDING: "DELETE_TEAM_MEMBER_PENDING",
    DELETE_TEAM_MEMBER_FULFILLED: "DELETE_TEAM_MEMBER_FULFILLED",
    DELETE_TEAM_MEMBER_REJECTED: "DELETE_TEAM_MEMBER_REJECTED",

    EMP_LOGIN_DETAILS: "EMP_LOGIN_DETAILS",
    EMP_LOGIN_DETAILS_PENDING: "EMP_LOGIN_DETAILS_PENDING",
    EMP_LOGIN_DETAILS_FULFILLED: "EMP_LOGIN_DETAILS_FULFILLED",
    EMP_LOGIN_DETAILS_REJECTED: "EMP_LOGIN_DETAILS_REJECTED",

    EMP_DASHBOARD_DETAILS: "EMP_DASHBOARD_DETAILS",
    EMP_DASHBOARD_DETAILS_PENDING: "EMP_DASHBOARD_DETAILS_PENDING",
    EMP_DASHBOARD_DETAILS_FULFILLED: "EMP_DASHBOARD_DETAILS_FULFILLED",
    EMP_DASHBOARD_DETAILS_REJECTED: "EMP_DASHBOARD_DETAILS_REJECTED",

    EMP_SUMMARY_DETAILS: "EMP_SUMMARY_DETAILS",
    EMP_SUMMARY_DETAILS_PENDING: "EMP_SUMMARY_DETAILS_PENDING",
    EMP_SUMMARY_DETAILS_FULFILLED: "EMP_SUMMARY_DETAILS_FULFILLED",
    EMP_SUMMARY_DETAILS_REJECTED: "EMP_SUMMARY_DETAILS_REJECTED",

    ATTENDANCE_TIMING_DETAILS: "ATTENDANCE_TIMING_DETAILS",
    ATTENDANCE_TIMING_DETAILS_PENDING: "ATTENDANCE_TIMING_DETAILS_PENDING",
    ATTENDANCE_TIMING_DETAILS_FULFILLED: "ATTENDANCE_TIMING_DETAILS_FULFILLED",
    ATTENDANCE_TIMING_DETAILS_REJECTED: "ATTENDANCE_TIMING_DETAILS_REJECTED",

    UPDATE_ATTENDANCE_TIMING_DETAILS: "UPDATE_ATTENDANCE_TIMING_DETAILS",
    UPDATE_ATTENDANCE_TIMING_DETAILS_PENDING: "UPDATE_ATTENDANCE_TIMING_DETAILS_PENDING",
    UPDATE_ATTENDANCE_TIMING_DETAILS_FULFILLED: "UPDATE_ATTENDANCE_TIMING_DETAILS_FULFILLED",
    UPDATE_ATTENDANCE_TIMING_DETAILS_REJECTED: "UPDATE_ATTENDANCE_TIMING_DETAILS_REJECTED",

    GET_USER_PROJECTS: "GET_USER_PROJECTS",
    GET_USER_PROJECTS_PENDING: "GET_USER_PROJECTS_PENDING",
    GET_USER_PROJECTS_FULFILLED: "GET_USER_PROJECTS_FULFILLED",
    GET_USER_PROJECTS_REJECTED: "GET_USER_PROJECTS_REJECTED",

    GET_SAVED_PAYMENT_CARDS: "GET_SAVED_PAYMENT_CARDS",
    GET_SAVED_PAYMENT_CARDS_PENDING: "GET_SAVED_PAYMENT_CARDS_PENDING",
    GET_SAVED_PAYMENT_CARDS_FULFILLED: "GET_SAVED_PAYMENT_CARDS_FULFILLED",
    GET_SAVED_PAYMENT_CARDS_REJECTED: "GET_SAVED_PAYMENT_CARDS_REJECTED",

    PAY_FOR_APP: "PAY_FOR_APP",
    PAY_FOR_APP_PENDING: "PAY_FOR_APP_PENDING",
    PAY_FOR_APP_FULFILLED: "PAY_FOR_APP_FULFILLED",
    PAY_FOR_APP_REJECTED: "PAY_FOR_APP_REJECTED",

    EDIT_TIME_TRACKER_SCREENSHOT_TIMING: "EDIT_TIME_TRACKER_SCREENSHOT_TIMING",
    EDIT_TIME_TRACKER_SCREENSHOT_TIMING_PENDING: "EDIT_TIME_TRACKER_SCREENSHOT_TIMING_PENDING",
    EDIT_TIME_TRACKER_SCREENSHOT_TIMING_FULFILLED: "EDIT_TIME_TRACKER_SCREENSHOT_TIMING_FULFILLED",
    EDIT_TIME_TRACKER_SCREENSHOT_TIMING_REJECTED: "EDIT_TIME_TRACKER_SCREENSHOT_TIMING_REJECTED",

    CREATE_TIME_TRACKER_SCREENSHOT_TIMING: "CREATE_TIME_TRACKER_SCREENSHOT",
    CREATE_TIME_TRACKER_SCREENSHOT_TIMING_PENDING: "CREATE_TIME_TRACKER_SCREENSHOT_TIMING_PENDING",
    CREATE_TIME_TRACKER_SCREENSHOT_TIMING_FULFILLED:
        "CREATE_TIME_TRACKER_SCREENSHOT_TIMING_FULFILLED",
    CREATE_TIME_TRACKER_SCREENSHOT_TIMING_REJECTED:
        "CREATE_TIME_TRACKER_SCREENSHOT_TIMING_REJECTED",

    GET_TIME_TRACKER_SCREENSHOT_INTERVAL: "GET_TIME_TRACKER_SCREENSHOT_INTERVAL",
    GET_TIME_TRACKER_SCREENSHOT_INTERVAL_PENDING: "GET_TIME_TRACKER_SCREENSHOT_INTERVAL_PENDING",
    GET_TIME_TRACKER_SCREENSHOT_INTERVAL_FULFILLED:
        "GET_TIME_TRACKER_SCREENSHOT_INTERVAL_FULFILLED",
    GET_TIME_TRACKER_SCREENSHOT_INTERVAL_REJECTED: "GET_TIME_TRACKER_SCREENSHOT_INTERVAL_REJECTED",

    GET_UNAVAILABLE_FEATURES_TEAM: "GET_UNAVAILABLE_FEATURES_TEAM",
    GET_UNAVAILABLE_FEATURES_TEAM_PENDING: "GET_UNAVAILABLE_FEATURES_TEAM_PENDING",
    GET_UNAVAILABLE_FEATURES_TEAM_FULFILLED: "GET_UNAVAILABLE_FEATURES_TEAM_FULFILLED",
    GET_UNAVAILABLE_FEATURES_TEAM_REJECTED: "GET_UNAVAILABLE_FEATURES_TEAM_REJECTED",

    GET_MARKET_FEATURES_TEAM: "GET_MARKET_FEATURES_TEAM",
    GET_MARKET_FEATURES_TEAM_PENDING: "GET_MARKET_FEATURES_TEAM_PENDING",
    GET_MARKET_FEATURES_TEAM_FULFILLED: "GET_MARKET_FEATURES_TEAM_FULFILLED",
    GET_MARKET_FEATURES_TEAM_REJECTED: "GET_MARKET_FEATURES_TEAM_REJECTED",

    GET_FEATURES_LIST_TEAM: "GET_FEATURES_LIST_TEAM",
    GET_FEATURES_LIST_TEAM_PENDING: "GET_FEATURES_LIST_TEAM_PENDING",
    GET_FEATURES_LIST_TEAM_FULFILLED: "GET_FEATURES_LIST_TEAM_FULFILLED",
    GET_FEATURES_LIST_TEAM_REJECTED: "GET_FEATURES_LIST_TEAM_REJECTED",

    ADD_FEATURE_TEAM: "ADD_FEATURE_TEAM",
    ADD_FEATURE_TEAM_PENDING: "ADD_FEATURE_TEAM_PENDING",
    ADD_FEATURE_TEAM_FULFILLED: "ADD_FEATURE_TEAM_FULFILLED",
    ADD_FEATURE_TEAM_REJECTED: "ADD_FEATURE_TEAM_REJECTED",

    START_FEATURE_TEAM: "START_FEATURE_TEAM",
    START_FEATURE_TEAM_PENDING: "START_FEATURE_TEAM_PENDING",
    START_FEATURE_TEAM_FULFILLED: "START_FEATURE_TEAM_FULFILLED",
    START_FEATURE_TEAM_REJECTED: "START_FEATURE_TEAM_REJECTED",

    DELETE_FEATURE_TEAM: "DELETE_FEATURE_TEAM",
    DELETE_FEATURE_TEAM_PENDING: "DELETE_FEATURE_TEAM_PENDING",
    DELETE_FEATURE_TEAM_FULFILLED: "DELETE_FEATURE_TEAM_FULFILLED",
    DELETE_FEATURE_TEAM_REJECTED: "DELETE_FEATURE_TEAM_REJECTED",

    STOP_FEATURE_TEAM: "STOP_FEATURE_TEAM",
    STOP_FEATURE_TEAM_PENDING: "STOP_FEATURE_TEAM_PENDING",
    STOP_FEATURE_TEAM_FULFILLED: "STOP_FEATURE_TEAM_FULFILLED",
    STOP_FEATURE_TEAM_REJECTED: "STOP_FEATURE_TEAM_REJECTED",

    STOP_CUSTOM_FEATURE_TEAM: "STOP_CUSTOM_FEATURE_TEAM",
    STOP_CUSTOM_FEATURE_TEAM_PENDING: "STOP_CUSTOM_FEATURE_TEAM_PENDING",
    STOP_CUSTOM_FEATURE_TEAM_FULFILLED: "STOP_CUSTOM_FEATURE_TEAM_FULFILLED",
    STOP_CUSTOM_FEATURE_TEAM_REJECTED: "STOP_CUSTOM_FEATURE_TEAM_REJECTED",

    START_CUSTOM_FEATURE_TEAM: "START_CUSTOM_FEATURE_TEAM",
    START_CUSTOM_FEATURE_TEAM_PENDING: "START_CUSTOM_FEATURE_TEAM_PENDING",
    START_CUSTOM_FEATURE_TEAM_FULFILLED: "START_CUSTOM_FEATURE_TEAM_FULFILLED",
    START_CUSTOM_FEATURE_TEAM_REJECTED: "START_CUSTOM_FEATURE_TEAM_REJECTED",

    GET_USER_AVAILABLE_FEATURES: "GET_USER_AVAILABLE_FEATURES",
    GET_USER_AVAILABLE_FEATURES_PENDING: "GET_USER_AVAILABLE_FEATURES_PENDING",
    GET_USER_AVAILABLE_FEATURES_FULFILLED: "GET_USER_AVAILABLE_FEATURES_FULFILLED",
    GET_USER_AVAILABLE_FEATURES_REJECTED: "GET_USER_AVAILABLE_FEATURES_REJECTED",

    GET_USER_UNAVAILABLE_FEATURES: "GET_USER_UNAVAILABLE_FEATURES",
    GET_USER_UNAVAILABLE_FEATURES_PENDING: "GET_USER_UNAVAILABLE_FEATURES_PENDING",
    GET_USER_UNAVAILABLE_FEATURES_FULFILLED: "GET_USER_UNAVAILABLE_FEATURES_FULFILLED",
    GET_USER_UNAVAILABLE_FEATURES_REJECTED: "GET_USER_UNAVAILABLE_FEATURES_REJECTED",

    GET_USER_CUSTOM_PERMISSIONS: "GET_USER_CUSTOM_PERMISSIONS",
    GET_USER_CUSTOM_PERMISSIONS_PENDING: "GET_USER_CUSTOM_PERMISSIONS_PENDING",
    GET_USER_CUSTOM_PERMISSIONS_FULFILLED: "GET_USER_CUSTOM_PERMISSIONS_FULFILLED",
    GET_USER_CUSTOM_PERMISSIONS_REJECTED: "GET_USER_CUSTOM_PERMISSIONS_REJECTED",

    GET_EMPLOYEE_PHONE_NUMBER: "GET_EMPLOYEE_PHONE_NUMBER",
    GET_EMPLOYEE_PHONE_NUMBER_PENDING: "GET_EMPLOYEE_PHONE_NUMBER_PENDING",
    GET_EMPLOYEE_PHONE_NUMBER_FULFILLED: "GET_EMPLOYEE_PHONE_NUMBER_FULFILLED",
    GET_EMPLOYEE_PHONE_NUMBER_REJECTED: "GET_EMPLOYEE_PHONE_NUMBER_REJECTED",

    EDIT_EMPLOYEE_PHONE_NUMBER: "EDIT_EMPLOYEE_PHONE_NUMBER",
    EDIT_EMPLOYEE_PHONE_NUMBER_PENDING: "EDIT_EMPLOYEE_PHONE_NUMBER_PENDING",
    EDIT_EMPLOYEE_PHONE_NUMBER_FULFILLED: "EDIT_EMPLOYEE_PHONE_NUMBER_FULFILLED",
    EDIT_EMPLOYEE_PHONE_NUMBER_REJECTED: "EDIT_EMPLOYEE_PHONE_NUMBER_REJECTED",

    EDIT_EMPLOYEE_BASIC_INFO: "EDIT_EMPLOYEE_BASIC_INFO",
    EDIT_EMPLOYEE_BASIC_INFO_PENDING: "EDIT_EMPLOYEE_BASIC_INFO_PENDING",
    EDIT_EMPLOYEE_BASIC_INFO_FULFILLED: "EDIT_EMPLOYEE_BASIC_INFO_FULFILLED",
    EDIT_EMPLOYEE_BASIC_INFO_REJECTED: "EDIT_EMPLOYEE_BASIC_INFO_REJECTED",

    GET_USERBASED_PROJECTS: "GET_USERBASED_PROJECTS",
    GET_USERBASED_PROJECTS_PENDING: "GET_USERBASED_PROJECTS_PENDING",
    GET_USERBASED_PROJECTS_FULFILLED: "GET_USERBASED_PROJECTS_FULFILLED",
    GET_USERBASED_PROJECTS_REJECTED: "GET_USERBASED_PROJECTS_REJECTED",

    REMOVE_USERBASED_PROJECTS: "REMOVE_USERBASED_PROJECTS",
    REMOVE_USERBASED_PROJECTS_PENDING: "REMOVE_USERBASED_PROJECTS_PENDING",
    REMOVE_USERBASED_PROJECTS_FULFILLED: "REMOVE_USERBASED_PROJECTS_FULFILLED",
    REMOVE_USERBASED_PROJECTS_REJECTED: "REMOVE_USERBASED_PROJECTS_REJECTED",

    ADD_USERBASED_PROJECTS: "ADD_USERBASED_PROJECTS",
    ADD_USERBASED_PROJECTS_PENDING: "ADD_USERBASED_PROJECTS_PENDING",
    ADD_USERBASED_PROJECTS_FULFILLED: "ADD_USERBASED_PROJECTS_FULFILLED",
    ADD_USERBASED_PROJECTS_REJECTED: "ADD_USERBASED_PROJECTS_REJECTED",

    CANCEL_PLAN_ADMIN: "CANCEL_PLAN_ADMIN",
    CANCEL_PLAN_ADMIN_PENDING: "CANCEL_PLAN_ADMIN_PENDING",
    CANCEL_PLAN_ADMIN_FULFILLED: "CANCEL_PLAN_ADMIN_FULFILLED",
    CANCEL_PLAN_ADMIN_REJECTED: "CANCEL_PLAN_ADMIN_REJECTED",

    EMPLOYEE_BRWOSER_ACTIVITY_LOG: "EMPLOYEE_BRWOSER_ACTIVITY_LOG",
    EMPLOYEE_BRWOSER_ACTIVITY_LOG_PENDING: "EMPLOYEE_BRWOSER_ACTIVITY_LOG_PENDING",
    EMPLOYEE_BRWOSER_ACTIVITY_LOG_FULFILLED: "EMPLOYEE_BRWOSER_ACTIVITY_LOG_FULFILLED",
    EMPLOYEE_BRWOSER_ACTIVITY_LOG_REJECTED: "EMPLOYEE_BRWOSER_ACTIVITY_LOG_REJECTED",

    TIMETRACKER_VIEWDETAILS: "TIMETRACKER_VIEWDETAILS",
    TIMETRACKER_VIEWDETAILS_PENDING: "TIMETRACKER_VIEWDETAILS_PENDING",
    TIMETRACKER_VIEWDETAILS_FULFILLED: "TIMETRACKER_VIEWDETAILS_FULFILLED",
    TIMETRACKER_VIEWDETAILS_REJECTED: "TIMETRACKER_VIEWDETAILS_REJECTED",

    CREATE_WORKSTATION_JOINWORKAHUB: "CREATE_WORKSTATION_JOINWORKAHUB",
    CREATE_WORKSTATION_JOINWORKAHUB_PENDING: "CREATE_WORKSTATION_JOINWORKAHUB_PENDING",
    CREATE_WORKSTATION_JOINWORKAHUB_FULFILLED: "CREATE_WORKSTATION_JOINWORKAHUB_FULFILLED",
    CREATE_WORKSTATION_JOINWORKAHUB_REJECTED: "CREATE_WORKSTATION_JOINWORKAHUB_REJECTED",

    SECURE_PAYMENT_STRIPE: "SECURE_PAYMENT_STRIPE",
    SECURE_PAYMENT_STRIPE_PENDING: "SECURE_PAYMENT_STRIPE_PENDING",
    SECURE_PAYMENT_STRIPE_FULFILLED: "SECURE_PAYMENT_STRIPE_FULFILLED",
    SECURE_PAYMENT_STRIPE_REJECTED: "SECURE_PAYMENT_STRIPE_REJECTED",

    GET_OBSERVERS_LIST: "GET_OBSERVERS_LIST",
    GET_OBSERVERS_LIST_PENDING: "GET_OBSERVERS_LIST_PENDING",
    GET_OBSERVERS_LIST_FULFILLED: "GET_OBSERVERS_LIST_FULFILLED",
    GET_OBSERVERS_LIST_REJECTED: "GET_OBSERVERS_LIST_REJECTED",

    // UPDATE_OBSERVERS_LIST: "UPDATE_OBSERVERS_LIST",
    // UPDATE_OBSERVERS_LIST_PENDING: "UPDATE_OBSERVERS_LIST_PENDING",
    // UPDATE_OBSERVERS_LIST_FULFILLED: "UPDATE_OBSERVERS_LIST_FULFILLED",
    // UPDATE_OBSERVERS_LIST_REJECTED: "UPDATE_OBSERVERS_LIST_REJECTED",

    GET_MANAGERS_LIST: "GET_MANAGERS_LIST",
    GET_MANAGERS_LIST_PENDING: "GET_MANAGERS_LIST_PENDING",
    GET_MANAGERS_LIST_FULFILLED: "GET_MANAGERS_LIST_FULFILLED",
    GET_MANAGERS_LIST_REJECTED: "GET_MANAGERS_LIST_REJECTED",

    GET_ROLES_CHECK: "GET_ROLES_CHECK",
    GET_ROLES_CHECK_PENDING: "GET_ROLES_CHECK_PENDING",
    GET_ROLES_CHECK_FULFILLED: "GET_ROLES_CHECK_FULFILLED",
    GET_ROLES_CHECK_REJECTED: "GET_ROLES_CHECK_REJECTED",

    TIME_TRACKER_DATA_OBSERVER: "TIME_TRACKER_DATA_OBSERVER",
    TIME_TRACKER_DATA_OBSERVER_PENDING: "TIME_TRACKER_DATA_OBSERVER_PENDING",
    TIME_TRACKER_DATA_OBSERVER_FULFILLED: "TIME_TRACKER_DATA_OBSERVER_FULFILLED",
    TIME_TRACKER_DATA_OBSERVER_REJECTED: "TIME_TRACKER_DATA_OBSERVER_REJECTED",

    FETCH_MANAGER_DASHBOARD_OBSERVER: "FETCH_MANAGER_DASHBOARD_OBSERVER",
    FETCH_MANAGER_DASHBOARD_OBSERVER_PENDING: "FETCH_MANAGER_DASHBOARD_OBSERVER_PENDING",
    FETCH_MANAGER_DASHBOARD_OBSERVER_FULFILLED: "FETCH_MANAGER_DASHBOARD_OBSERVER_FULFILLED",
    FETCH_MANAGER_DASHBOARD_OBSERVER_REJECTED: "FETCH_MANAGER_DASHBOARD_OBSERVER_REJECTED",

    TASK_LIST_OBSERVER: "TASK_LIST_OBSERVER",
    TASK_LIST_OBSERVER_PENDING: "TASK_LIST_OBSERVER_PENDING",
    TASK_LIST_OBSERVER_FULFILLED: "TASK_LIST_OBSERVER_FULFILLED",
    TASK_LIST_OBSERVER_REJECTED: "TASK_LIST_OBSERVER_REJECTED",

    GET_EMP_LIST_OBSERVER: "GET_EMP_LIST_OBSERVER",
    GET_EMP_LIST_OBSERVER_PENDING: "GET_EMP_LIST_OBSERVER_PENDING",
    GET_EMP_LIST_OBSERVER_FULFILLED: "GET_EMP_LIST_OBSERVER_FULFILLED",
    GET_EMP_LIST_OBSERVER_REJECTED: "GET_EMP_LIST_OBSERVER_REJECTED",

    GET_SYSTEM_INFO_OBSERVER: "GET_SYSTEM_INFO_OBSERVER",
    GET_SYSTEM_INFO_OBSERVER_PENDING: "GET_SYSTEM_INFO_OBSERVER_PENDING",
    GET_SYSTEM_INFO_OBSERVER_FULFILLED: "GET_SYSTEM_INFO_OBSERVER_FULFILLED",
    GET_SYSTEM_INFO_OBSERVER_REJECTED: "GET_SYSTEM_INFO_OBSERVER_REJECTED",

    GET_DETAILED_TASK_INFO: "GET_DETAILED_TASK_INFO",
    GET_DETAILED_TASK_INFO_PENDING: "GET_DETAILED_TASK_INFO_PENDING",
    GET_DETAILED_TASK_INFO_FULFILLED: "GET_DETAILED_TASK_INFO_FULFILLED",
    GET_DETAILED_TASK_INFO_REJECTED: "GET_DETAILED_TASK_INFO_REJECTED",

    WEEKLYCHART_ACTIVITY: "WEEKLYCHART_ACTIVITY",
    WEEKLYCHART_ACTIVITY_PENDING: "WEEKLYCHART_ACTIVITY_PENDING",
    WEEKLYCHART_ACTIVITY_FULFILLED: "WEEKLYCHART_ACTIVITY_FULFILLED",
    WEEKLYCHART_ACTIVITY_REJECTED: "WEEKLYCHART_ACTIVITY_REJECTED",

    PROJECT_CHANGE: "PROJECT_CHANGE",
    PROJECT_CHANGE_PENDING: "PROJECT_CHANGE_PENDING",
    PROJECT_CHANGE_FULFILLED: "PROJECT_CHANGE_FULFILLED",
    PROJECT_CHANGE_REJECTED: "PROJECT_CHANGE_REJECTED",

    GET_PROJECT_LIST: "GET_PROJECT_LIST",
    GET_PROJECT_LIST_PENDING: "GET_PROJECT_LIST_PENDING",
    GET_PROJECT_LIST_FULFILLED: "GET_PROJECT_LIST_FULFILLED",
    GET_PROJECT_LIST_REJECTED: "GET_PROJECT_LIST_REJECTED",

    DELETE_TIME: "DELETE_TIME",
    DELETE_TIME_PENDING: "DELETE_TIME_PENDING",
    DELETE_TIME_FULFILLED: "DELETE_TIME_FULFILLED",
    DELETE_TIME_REJECTED: "DELETE_TIME_REJECTED",

    CANCEL_TIME: "CANCEL_TIME",
    CANCEL_TIME_PENDING: "CANCEL_TIME_PENDING",
    CANCEL_TIME_FULFILLED: "CANCEL_TIME_FULFILLED",
    CANCEL_TIME_REJECTED: "CANCEL_TIME_REJECTED",

    USER_DETAIL: "USER_DETAIL",
    USER_DETAIL_PENDING: "USER_DETAIL_PENDING",
    USER_DETAIL_FULFILLED: "USER_DETAIL_FULFILLED",
    USER_DETAIL_REJECTED: "USER_DETAIL_REJECTED",

    GET_SHOWCASE_ENTRIES: "GET_SHOWCASE_ENTRIES",
    GET_SHOWCASE_ENTRIES_PENDING: "GET_SHOWCASE_ENTRIES_PENDING",
    GET_SHOWCASE_ENTRIES_FULFILLED: "GET_SHOWCASE_ENTRIES_FULFILLED",
    GET_SHOWCASE_ENTRIES_REJECTED: "GET_SHOWCASE_ENTRIES_REJECTED",

    GET_SHOWCASE_USERS_LIST: "GET_SHOWCASE_USERS_LIST",
    GET_SHOWCASE_USERS_LIST_PENDING: "GET_SHOWCASE_USERS_LIST_PENDING",
    GET_SHOWCASE_USERS_LIST_FULFILLED: "GET_SHOWCASE_USERS_LIST_FULFILLED",
    GET_SHOWCASE_USERS_LIST_REJECTED: "GET_SHOWCASE_USERS_LIST_REJECTED",

    GET_DAILY_ACTITVITY_LIST: "GET_DAILY_ACTITVITY_LIST",
    GET_DAILY_ACTITVITY_LIST_PENDING: "GET_DAILY_ACTITVITY_LIST_PENDING",
    GET_DAILY_ACTITVITY_LIST_FULFILLED: "GET_DAILY_ACTITVITY_LIST_FULFILLED",
    GET_DAILY_ACTITVITY_LIST_REJECTED: "GET_DAILY_ACTITVITY_LIST_REJECTED",
    GET_PROJECT_DETAILS: "GET_PROJECT_DETAILS",
    GET_PROJECT_DETAILS_PENDING: "GET_PROJECT_DETAILS_PENDING",
    GET_PROJECT_DETAILS_FULFILLED: "GET_PROJECT_DETAILS_FULFILLED",
    GET_PROJECT_DETAILS_REJECTED: "GET_PROJECT_DETAILS_REJECTED",

    ADD_PROJECT_DETAILS: "ADD_PROJECT_DETAILS",
    ADD_PROJECT_DETAILS_PENDING: "ADD_PROJECT_DETAILS_PENDING",
    ADD_PROJECT_DETAILS_FULFILLED: "ADD_PROJECT_DETAILS_FULFILLED",
    ADD_PROJECT_DETAILS_REJECTED: "ADD_PROJECT_DETAILS_REJECTED",

    UPDATE_PROJECT_DETAILS: "UPDATE_PROJECT_DETAILS",
    UPDATE_PROJECT_DETAILS_PENDING: "UPDATE_PROJECT_DETAILS_PENDING",
    UPDATE_PROJECT_DETAILS_FULFILLED: "UPDATE_PROJECT_DETAILS_FULFILLED",
    UPDATE_PROJECT_DETAILS_REJECTED: "UPDATE_PROJECT_DETAILS_REJECTED",

    UPDATE_DASHBOARD_LAYOUT: "UPDATE_DASHBOARD_LAYOUT",
    UPDATE_DASHBOARD_LAYOUT_PENDING: "UPDATE_DASHBOARD_LAYOUT_PENDING",
    UPDATE_DASHBOARD_LAYOUT_FULFILLED: "UPDATE_DASHBOARD_LAYOUT_FULFILLED",
    UPDATE_DASHBOARD_LAYOUT_REJECTED: "UPDATE_DASHBOARD_LAYOUT_REJECTED",

    GET_DASHBOARD_LAYOUT: "GET_DASHBOARD_LAYOUT",
    GET_DASHBOARD_LAYOUT_PENDING: "GET_DASHBOARD_LAYOUT_PENDING",
    GET_DASHBOARD_LAYOUT_FULFILLED: "GET_DASHBOARD_LAYOUT_FULFILLED",
    GET_DASHBOARD_LAYOUT_REJECTED: "GET_DASHBOARD_LAYOUT_REJECTED",

    UPDATE_DASHBOARD_ARRANGEMENT: "UPDATE_DASHBOARD_ARRANGEMENT",
    UPDATE_DASHBOARD_ARRANGEMENT_PENDING: "UPDATE_DASHBOARD_ARRANGEMENT_PENDING",
    UPDATE_DASHBOARD_ARRANGEMENT_FULFILLED: "UPDATE_DASHBOARD_ARRANGEMENT_FULFILLED",
    UPDATE_DASHBOARD_ARRANGEMENT_REJECTED: "UPDATE_DASHBOARD_ARRANGEMENT_REJECTED",

    GET_DASHBOARD_ARRANGEMENT: "GET_DASHBOARD_ARRANGEMENT",
    GET_DASHBOARD_ARRANGEMENT_PENDING: "GET_DASHBOARD_ARRANGEMENT_PENDING",
    GET_DASHBOARD_ARRANGEMENT_FULFILLED: "GET_DASHBOARD_ARRANGEMENT_FULFILLED",
    GET_DASHBOARD_ARRANGEMENT_REJECTED: "GET_DASHBOARD_ARRANGEMENT_REJECTED",

    UPDATE_ALL_PROJECT_TABLE_LAYOUT: "UPDATE_ALL_PROJECT_TABLE_LAYOUT",
    UPDATE_ALL_PROJECT_TABLE_PENDING: "UPDATE_ALL_PROJECT_TABLE_LAYOUT_PENDING",
    UPDATE_ALL_PROJECT_TABLE_FULFILLED: "UPDATE_ALL_PROJECT_TABLE_LAYOUT_FULFILLED",
    UPDATE_ALL_PROJECT_TABLE_REJECTED: "UPDATE_ALL_PROJECT_TABLE_LAYOUT_REJECTED",

    GET_ALL_PROJECT_TABLE_LAYOUT: "GET_ALL_PROJECT_TABLE_LAYOUT",
    GET_ALL_PROJECT_TABLE_LAYOUT_PENDING: "GET_ALL_PROJECT_TABLE_LAYOUT_PENDING",
    GET_ALL_PROJECT_TABLE_LAYOUT_FULFILLED: "GET_ALL_PROJECT_TABLE_LAYOUT_FULFILLED",
    GET_ALL_PROJECT_TABLE_LAYOUT_REJECTED: "GET_ALL_PROJECT_TABLE_LAYOUT_REJECTED",

    UPDATE_PROJECT_TABLE_LAYOUT: "UPDATE_PROJECT_TABLE_LAYOUT",
    UPDATE_PROJECT_TABLE_PENDING: "UPDATE_PROJECT_TABLE_LAYOUT_PENDING",
    UPDATE_PROJECT_TABLE_FULFILLED: "UPDATE_PROJECT_TABLE_LAYOUT_FULFILLED",
    UPDATE_PROJECT_TABLE_REJECTED: "UPDATE_PROJECT_TABLE_LAYOUT_REJECTED",

    GET_PROJECT_TABLE_LAYOUT: "GET_PROJECT_TABLE_LAYOUT",
    GET_PROJECT_TABLE_LAYOUT_PENDING: "GET_PROJECT_TABLE_LAYOUT_PENDING",
    GET_PROJECT_TABLE_LAYOUT_FULFILLED: "GET_PROJECT_TABLE_LAYOUT_FULFILLED",
    GET_PROJECT_TABLE_LAYOUT_REJECTED: "GET_PROJECT_TABLE_LAYOUT_REJECTED",

    UPDATE_BOOKING_REPORT_TABLE_LAYOUT: "UPDATE_BOOKING_REPORT_TABLE_LAYOUT",
    UPDATE_BOOKING_REPORT_TABLE_LAYOUT_PENDING: "UPDATE_BOOKING_REPORT_TABLE_LAYOUT_PENDING",
    UPDATE_BOOKING_REPORT_TABLE_LAYOUT_FULFILLED: "UPDATE_BOOKING_REPORT_TABLE_LAYOUT_FULFILLED",
    UPDATE_BOOKING_REPORT_TABLE_LAYOUT_REJECTED: "UPDATE_BOOKING_REPORT_TABLE_LAYOUT_REJECTED",

    GET_BOOKING_REPORT_TABLE_LAYOUT: "GET_BOOKING_REPORT_TABLE_LAYOUT",
    GET_BOOKING_REPORT_TABLE_LAYOUT_PENDING: "GET_BOOKING_REPORT_TABLE_LAYOUT_PENDING",
    GET_BOOKING_REPORT_TABLE_LAYOUT_FULFILLED: "GET_BOOKING_REPORT_TABLE_LAYOUT_FULFILLED",
    GET_BOOKING_REPORT_TABLE_LAYOUT_REJECTED: "GET_BOOKING_REPORT_TABLE_LAYOUT_REJECTED",

    GET_BOOKING_REPORT_TABLE: "GET_BOOKING_REPORT_TABLE",
    GET_BOOKING_REPORT_TABLE_PENDING: "GET_BOOKING_REPORT_TABLE_PENDING",
    GET_BOOKING_REPORT_TABLE_FULFILLED: "GET_BOOKING_REPORT_TABLE_FULFILLED",
    GET_BOOKING_REPORT_TABLE_REJECTED: "GET_BOOKING_REPORT_TABLE_REJECTED",

    DOWNLOAD_BOOKING_REPORT_TABLE: "DOWNLOAD_BOOKING_REPORT_TABLE",
    DOWNLOAD_BOOKING_REPORT_TABLE_PENDING: "DOWNLOAD_BOOKING_REPORT_TABLE_PENDING",
    DOWNLOAD_BOOKING_REPORT_TABLE_FULFILLED: "DOWNLOAD_BOOKING_REPORT_TABLE_FULFILLED",
    DOWNLOAD_BOOKING_REPORT_TABLE_REJECTED: "DOWNLOAD_BOOKING_REPORT_TABLE_REJECTED",

    GET_EMPLOYEE_MOOD_TABLE: "GET_EMPLOYEE_MOOD_TABLE",
    GET_EMPLOYEE_MOOD_TABLE_PENDING: "GET_EMPLOYEE_MOOD_TABLE_PENDING",
    GET_EMPLOYEE_MOOD_TABLE_FULFILLED: "GET_EMPLOYEE_MOOD_TABLE_FULFILLED",
    GET_EMPLOYEE_MOOD_TABLE_REJECTED: "GET_EMPLOYEE_MOOD_TABLE_REJECTED",

    GET_MOOD_TABLE_LAYOUT: "GET_MOOD_TABLE_LAYOUT",
    GET_MOOD_TABLE_LAYOUT_PENDING: "GET_MOOD_TABLE_LAYOUT_PENDING",
    GET_MOOD_TABLE_LAYOUT_FULFILLED: "GET_MOOD_TABLE_LAYOUT_FULFILLED",
    GET_MOOD_TABLE_LAYOUT_REJECTED: "GET_MOOD_TABLE_LAYOUT_REJECTED",

    UPDATE_MOOD_TABLE_LAYOUT: "UPDATE_MOOD_TABLE_LAYOUT",
    UPDATE_MOOD_TABLE_LAYOUT_PENDING: "UPDATE_MOOD_TABLE_LAYOUT_PENDING",
    UPDATE_MOOD_TABLE_LAYOUT_FULFILLED: "UPDATE_MOOD_TABLE_LAYOUT_FULFILLED",
    UPDATE_MOOD_TABLE_LAYOUT_REJECTED: "UPDATE_MOOD_TABLE_LAYOUT_REJECTED",

    GET_MOOD_TABLE_ARRANGEMENT: "GET_MOOD_TABLE_ARRANGEMENT",
    GET_MOOD_TABLE_ARRANGEMENT_PENDING: "GET_MOOD_TABLE_ARRANGEMENT_PENDING",
    GET_MOOD_TABLE_ARRANGEMENT_FULFILLED: "GET_MOOD_TABLE_ARRANGEMENT_FULFILLED",
    GET_MOOD_TABLE_ARRANGEMENT_REJECTED: "GET_MOOD_TABLE_ARRANGEMENT_REJECTED",

    UPDATE_MOOD_TABLE_ARRANGEMENT: "UPDATE_MOOD_TABLE_ARRANGEMENT",
    UPDATE_MOOD_TABLE_ARRANGEMENT_PENDING: "UPDATE_MOOD_TABLE_ARRANGEMENT_PENDING",
    UPDATE_MOOD_TABLE_ARRANGEMENT_FULFILLED: "UPDATE_MOOD_TABLE_ARRANGEMENT_FULFILLED",
    UPDATE_MOOD_TABLE_ARRANGEMENT_REJECTED: "UPDATE_MOOD_TABLE_ARRANGEMENT_REJECTED",

    GET_PROJECT_TABLE_ARRANGEMENT: "GET_PROJECT_TABLE_ARRANGEMENT",
    GET_PROJECT_TABLE_ARRANGEMENT_PENDING: "GET_PROJECT_TABLE_ARRANGEMENT_PENDING",
    GET_PROJECT_TABLE_ARRANGEMENT_FULFILLED: "GET_PROJECT_TABLE_ARRANGEMENT_FULFILLED",
    GET_PROJECT_TABLE_ARRANGEMENT_REJECTED: "GET_PROJECT_TABLE_ARRANGEMENT_REJECTED",

    GET_BOOKING_REPORT_TABLE_ARRANGEMENT: "GET_BOOKING_REPORT_TABLE_ARRANGEMENT",
    GET_BOOKING_REPORT_TABLE_ARRANGEMENT_PENDING: "GET_BOOKING_REPORT_TABLE_ARRANGEMENT_PENDING",
    GET_BOOKING_REPORT_TABLE_ARRANGEMENT_FULFILLED:
        "GET_BOOKING_REPORT_TABLE_ARRANGEMENT_FULFILLED",
    GET_BOOKING_REPORT_TABLE_ARRANGEMENT_REJECTED: "GET_BOOKING_REPORT_TABLE_ARRANGEMENT_REJECTED",

    UPDATE_PROJECT_TABLE_ARRANGEMENT: "UPDATE_PROJECT_TABLE_ARRANGEMENT",
    UPDATE_PROJECT_TABLE_ARRANGEMENT_PENDING: "UPDATE_PROJECT_TABLE_ARRANGEMENT_PENDING",
    UPDATE_PROJECT_TABLE_ARRANGEMENT_FULFILLED: "UPDATE_PROJECT_TABLE_ARRANGEMENT_FULFILLED",
    UPDATE_PROJECT_TABLE_ARRANGEMENT_REJECTED: "UPDATE_PROJECT_TABLE_ARRANGEMENT_REJECTED",

    UPDATE_BOOKING_REPORT_ARRANGEMENT: "UPDATE_BOOKING_REPORT_ARRANGEMENT",
    UPDATE_BOOKING_REPORT_ARRANGEMENT_PENDING: "UPDATE_BOOKING_REPORT_ARRANGEMENT_PENDING",
    UPDATE_BOOKING_REPORT_ARRANGEMENT_FULFILLED: "UPDATE_BOOKING_REPORT_ARRANGEMENT_FULFILLED",
    UPDATE_BOOKING_REPORT_ARRANGEMENT_REJECTED: "UPDATE_BOOKING_REPORT_ARRANGEMENT_REJECTED",

    GET_TEAM_TABLE_LAYOUT: "GET_MOOD_TABLE_LAYOUT",
    GET_TEAM_TABLE_LAYOUT_FULFILLED: "GET_TEAM_TABLE_LAYOUT_FULFILLED",
    GET_TEAM_TABLE_LAYOUT_PENDING: "GET_TEAM_TABLE_LAYOUT_PENDING",
    GET_TEAM_TABLE_LAYOUT_REJECTED: "GET_TEAM_TABLE_LAYOUT_REJECTED",

    UPDATE_TEAM_TABLE_LAYOUT: "UPDATE_TEAM_TABLE_LAYOUT",
    UPDATE_TEAM_TABLE_LAYOUT_PENDING: "UPDATE_TEAM_TABLE_LAYOUT_PENDING",
    UPDATE_TEAM_TABLE_LAYOUT_FULFILLED: "UPDATE_TEAM_TABLE_LAYOUT_FULFILLED",
    UPDATE_TEAM_TABLE_LAYOUT_REJECTED: "UPDATE_TEAM_TABLE_LAYOUT_REJECTED",

    GET_TEAM_TABLE_ARRANGEMENT: "GET_TEAM_TABLE_ARRANGEMENT",
    GET_TEAM_TABLE_ARRANGEMENT_PENDING: "GET_TEAM_TABLE_ARRANGEMENT_PENDING",
    GET_TEAM_TABLE_ARRANGEMENT_FULFILLED: "GET_TEAM_TABLE_ARRANGEMENT_FULFILLED",
    GET_TEAM_TABLE_ARRANGEMENT_REJECTED: "GET_TEAM_TABLE_ARRANGEMENT_REJECTED",

    UPDATE_TEAM_TABLE_ARRANGEMENT: "UPDATE_TEAM_TABLE_ARRANGEMENT",
    UPDATE_TEAM_TABLE_ARRANGEMENT_PENDING: "UPDATE_TEAM_TABLE_ARRANGEMENT_PENDING",
    UPDATE_TEAM_TABLE_ARRANGEMENT_FULFILLED: "UPDATE_TEAM_TABLE_ARRANGEMENT_FULFILLED",
    UPDATE_TEAM_TABLE_ARRANGEMENT_REJECTED: "UPDATE_TEAM_TABLE_ARRANGEMENT_REJECTED",

    GET_EMPLOYEE_TABLE_LAYOUT: "GET_EMPLOYEE_TABLE_LAYOUT",
    GET_EMPLOYEE_TABLE_LAYOUT_PENDING: "GET_EMPLOYEE_TABLE_LAYOUT_PENDING",
    GET_EMPLOYEE_TABLE_LAYOUT_FULFILLED: "GET_EMPLOYEE_TABLE_LAYOUT_FULFILLED",
    GET_EMPLOYEE_TABLE_LAYOUT_REJECTED: "GET_EMPLOYEE_TABLE_LAYOUT_REJECTED",

    UPDATE_EMPLOYEE_TABLE_LAYOUT: "UPDATE_EMPLOYEE_TABLE_LAYOUT",
    UPDATE_EMPLOYEE_TABLE_LAYOUT_PENDING: "UPDATE_EMPLOYEE_TABLE_LAYOUT_PENDING",
    UPDATE_EMPLOYEE_TABLE_LAYOUT_FULFILLED: "UPDATE_EMPLOYEE_TABLE_LAYOUT_FULFILLED",
    UPDATE_EMPLOYEE_TABLE_LAYOUT_REJECTED: "UPDATE_EMPLOYEE_TABLE_LAYOUT_REJECTED",

    GET_EMPLOYEE_TABLE_ARRANGEMENT: "GET_EMPLOYEE_TABLE_ARRANGEMENT",
    GET_EMPLOYEE_TABLE_ARRANGEMENT_PENDING: "GET_EMPLOYEE_TABLE_ARRANGEMENT_PENDING",
    GET_EMPLOYEE_TABLE_ARRANGEMENT_FULFILLED: "GET_EMPLOYEE_TABLE_ARRANGEMENT_FULFILLED",
    GET_EMPLOYEE_TABLE_ARRANGEMENT_REJECTED: "GET_EMPLOYEE_TABLE_ARRANGEMENT_REJECTED",

    UPDATE_EMPLOYEE_TABLE_ARRANGEMENT: "UPDATE_EMPLOYEE_TABLE_ARRANGEMENT",
    UPDATE_EMPLOYEE_TABLE_ARRANGEMENT_PENDING: "UPDATE_EMPLOYEE_TABLE_ARRANGEMENT_PENDING",
    UPDATE_EMPLOYEE_TABLE_ARRANGEMENT_FULFILLED: "UPDATE_EMPLOYEE_TABLE_ARRANGEMENT_FULFILLED",
    UPDATE_EMPLOYEE_TABLE_ARRANGEMENT_REJECTED: "UPDATE_EMPLOYEE_TABLE_ARRANGEMENT_REJECTED",

    GET_ALL_PROJECT_TABLE_ARRANGEMENT: "GET_ALL_PROJECT_TABLE_ARRANGEMENT",
    GET_ALL_PROJECT_TABLE_ARRANGEMENT_PENDING: "GET_ALL_PROJECT_TABLE_ARRANGEMENT_PENDING",
    GET_ALL_PROJECT_TABLE_ARRANGEMENT_FULFILLED: "GET_ALL_PROJECT_TABLE_ARRANGEMENT_FULFILLED",
    GET_ALL_PROJECT_TABLE_ARRANGEMENT_REJECTED: "GET_ALL_PROJECT_TABLE_ARRANGEMENT_REJECTED",

    UPDATE_ALL_PROJECT_TABLE_ARRANGEMENT: "UPDATE_ALL_PROJECT_TABLE_ARRANGEMENT",
    UPDATE_ALL_PROJECT_TABLE_ARRANGEMENT_PENDING: "UPDATE_ALL_PROJECT_TABLE_ARRANGEMENT_PENDING",
    UPDATE_ALL_PROJECT_TABLE_ARRANGEMENT_FULFILLED:
        "UPDATE_ALL_PROJECT_TABLE_ARRANGEMENT_FULFILLED",
    UPDATE_ALL_PROJECT_TABLE_ARRANGEMENT_REJECTED: "UPDATE_ALL_PROJECT_TABLE_ARRANGEMENT_REJECTED",

    GET_EMPLOYEE_MOOD: "GET_EMPLOYEE_MOOD",
    GET_EMPLOYEE_MOOD_PENDING: "GET_EMPLOYEE_MOOD_PENDING",
    GET_EMPLOYEE_MOOD_FULFILLED: "GET_EMPLOYEE_MOOD_FULFILLED",
    GET_EMPLOYEE_MOOD_REJECTED: "GET_EMPLOYEE_MOOD_REJECTED",

    GET_MOOD_GRAPH_DATA: "GET_MOOD_GRAPH_DATA",
    GET_MOOD_GRAPH_DATA_PENDING: "GET_MOOD_GRAPH_DATA_PENDING",
    GET_MOOD_GRAPH_DATA_FULFILLED: "GET_MOOD_GRAPH_DATA_FULFILLED",
    GET_MOOD_GRAPH_DATA_REJECTED: "GET_MOOD_GRAPH_DATA_REJECTED",

    GET_ALL_PROJECT_TABLE_DATA: "GET_ALL_PROJECT_TABLE_DATA",
    GET_ALL_PROJECT_TABLE_DATA_PENDING: "GET_ALL_PROJECT_TABLE_DATA_PENDING",
    GET_ALL_PROJECT_TABLE_DATA_FULFILLED: "GET_ALL_PROJECT_TABLE_DATA_FULFILLED",
    GET_ALL_PROJECT_TABLE_DATA_REJECTED: "GET_ALL_PROJECT_TABLE_DATA_REJECTED",

    GET_USER_BASED_PROJECTS: "GET_USER_BASED_PROJECTS",
    GET_USER_BASED_PROJECTS_PENDING: "GET_USER_BASED_PROJECTS_PENDING",
    GET_USER_BASED_PROJECTS_FULFILLED: "GET_USER_BASED_PROJECTS_FULFILLED",
    GET_USER_BASED_PROJECTS_REJECTED: "GET_USER_BASED_PROJECTS_REJECTED",

    GET_USER_BASED_TEAMS: "GET_USER_BASED_TEAMS",
    GET_USER_BASED_TEAMS_PENDING: "GET_USER_BASED_TEAMS_PENDING",
    GET_USER_BASED_TEAMS_FULFILLED: "GET_USER_BASED_TEAMS_FULFILLED",
    GET_USER_BASED_TEAMS_REJECTED: "GET_USER_BASED_TEAMS_REJECTED",

    GET_SINGLE_APP_LOG: "GET_SINGLE_APP_LOG",
    GET_SINGLE_APP_LOG_PENDING: "GET_SINGLE_APP_LOG_PENDING",
    GET_SINGLE_APP_LOG_FULFILLED: "GET_SINGLE_APP_LOG_FULFILLED",
    GET_SINGLE_APP_LOG_REJECTED: "GET_SINGLE_APP_LOG_REJECTED",

    GET_PAYROLL_DATA: "GET_PAYROLL_DATA",
    GET_PAYROLL_DATA_PENDING: "GET_PAYROLL_DATA_PENDING",
    GET_PAYROLL_DATA_FULFILLED: "GET_PAYROLL_DATA_FULFILLED",
    GET_PAYROLL_DATA_REJECTED: "GET_PAYROLL_DATA_REJECTED",

    UPDATE_PAYROLL_DATA: "UPDATE_PAYROLL_DATA",
    UPDATE_PAYROLL_DATA_PENDING: "UPDATE_PAYROLL_DATA_PENDING",
    UPDATE_PAYROLL_DATA_FULFILLED: "UPDATE_PAYROLL_DATA_FULFILLED",
    UPDATE_PAYROLL_DATA_REJECTED: "UPDATE_PAYROLL_DATA_REJECTED",

    GET_SALARY_DAYS_DATA: "GET_SALARY_DAYS_DATA",
    GET_SALARY_DAYS_DATA_PENDING: "GET_SALARY_DAYS_DATA_PENDING",
    GET_SALARY_DAYS_DATA_FULFILLED: "GET_SALARY_DAYS_DATA_FULFILLED",
    GET_SALARY_DAYS_DATA_REJECTED: "GET_SALARY_DAYS_DATA_REJECTED",

    GET_SALARY_DAY_DOWNLOAD: "GET_SALARY_DAY_DOWNLOAD",
    GET_SALARY_DAY_DOWNLOAD_PENDING: "GET_SALARY_DAY_DOWNLOAD_PENDING",
    GET_SALARY_DAY_DOWNLOAD_FULFILLED: "GET_SALARY_DAY_DOWNLOAD_FULFILLED",
    GET_SALARY_DAY_DOWNLOAD_REJECTED: "GET_SALARY_DAY_DOWNLOAD_REJECTED",

    GET_SALARY_YEAR_DATA: "GET_SALARY_YEAR_DATA",
    GET_SALARY_YEAR_DATA_PENDING: "GET_SALARY_YEAR_DATA_PENDING",
    GET_SALARY_YEAR_DATA_FULFILLED: "GET_SALARY_YEAR_DATA_FULFILLED",
    GET_SALARY_YEAR_DATA_REJECTED: "GET_SALARY_YEAR_DATA_REJECTED",

    GET_SALARY_HISTORY: "GET_SALARY_HISTORY",
    GET_SALARY_HISTORY_PENDING: "GET_SALARY_HISTORY_PENDING",
    GET_SALARY_HISTORY_FULFILLED: "GET_SALARY_HISTORY_FULFILLED",
    GET_SALARY_HISTORY_REJECTED: "GET_SALARY_HISTORY_REJECTED",

    GET_USER_SKILLS: "GET_USER_SKILLS",
    GET_USER_SKILLS_PENDING: "GET_USER_SKILLS_PENDING",
    GET_USER_SKILLS_FULFILLED: "GET_USER_SKILLS_FULFILLED",
    GET_USER_SKILLS_REJECTED: "GET_USER_SKILLS_REJECTED",

    GET_PROFILE_IMG: "GET_PROFILE_IMG",
    GET_PROFILE_IMG_PENDING: "GET_PROFILE_IMG_PENDING",
    GET_PROFILE_IMG_FULFILLED: "GET_PROFILE_IMG_FULFILLED",
    GET_PROFILE_IMG_REJECTED: "GET_PROFILE_IMG_REJECTED",

    GET_PROJECT_DATA: "GET_PROJECT_DATA",
    GET_PROJECT_DATA_PENDING: "GET_PROJECT_DATA_PENDING",
    GET_PROJECT_DATA_FULFILLED: "GET_PROJECT_DATA_FULFILLED",
    GET_PROJECT_DATA_REJECTED: "GET_PROJECT_DATA_REJECTED",

    GET_USER_LOC_DESG: "GET_USER_LOC_DESG",
    GET_USER_LOC_DESG_PENDING: "GET_USER_LOC_DESG_PENDING",
    GET_USER_LOC_DESG_FULFILLED: "GET_USER_LOC_DESG_FULFILLED",
    GET_USER_LOC_DESG_REJECTED: "GET_USER_LOC_DESG_REJECTED",

    UPDATE_USER_SKILLS: "UPDATE_USER_SKILLS",
    UPDATE_USER_SKILLS_PENDING: "UPDATE_USER_SKILLS_PENDING",
    UPDATE_USER_SKILLS_FULFILLED: "UPDATE_USER_SKILLS_FULFILLED",
    UPDATE_USER_SKILLS_REJECTED: "UPDATE_USER_SKILLS_REJECTED",

    UPDATE_PROFILE_IMG: "UPDATE_PROFILE_IMG",
    UPDATE_PROFILE_IMG_PENDING: "UPDATE_PROFILE_IMG_PENDING",
    UPDATE_PROFILE_IMG_FULFILLED: "UPDATE_PROFILE_IMG_FULFILLED",
    UPDATE_PROFILE_IMG_REJECTED: "UPDATE_PROFILE_IMG_REJECTED",

    UPDATE_USER_LOC_DESG: "UPDATE_USER_LOC_DESG",
    UPDATE_USER_LOC_DESG_PENDING: "UPDATE_USER_LOC_DESG_PENDING",
    UPDATE_USER_LOC_DESG_FULFILLED: "UPDATE_USER_LOC_DESG_FULFILLED",
    UPDATE_USER_LOC_DESG_REJECTED: "UPDATE_USER_LOC_DESG_REJECTED",

    GET_USER_DATA: "GET_USER_DATA",
    GET_USER_DATA_PENDING: "GET_USER_DATA_PENDING",
    GET_USER_DATA_FULFILLED: "GET_USER_DATA_FULFILLED",
    GET_USER_DATA_REJECTED: "GET_USER_DATA_REJECTED",

    GET_BLOCKED_URLS: "GET_BLOCKED_URLS",
    GET_BLOCKED_URLS_PENDING: "GET_BLOCKED_URLS_PENDING",
    GET_BLOCKED_URLS_FULFILLED: "GET_BLOCKED_URLS_FULFILLED",
    GET_BLOCKED_URLS_REJECTED: "GET_BLOCKED_URLS_REJECTED",

    UPDATE_BLOCKED_URLS: "UPDATE_BLOCKED_URLS",
    UPDATE_BLOCKED_URLS_PENDING: "UPDATE_BLOCKED_URLS_PENDING",
    UPDATE_BLOCKED_URLS_FULFILLED: "UPDATE_BLOCKED_URLS_FULFILLED",
    UPDATE_BLOCKED_URLS_REJECTED: "UPDATE_BLOCKED_URLS_REJECTED",

    GET_PROJECT_ACTIVITY: "GET_PROJECT_ACTIVITY",
    GET_PROJECT_ACTIVITY_PENDING: "GET_PROJECT_ACTIVITY_PENDING",
    GET_PROJECT_ACTIVITY_FULFILLED: "GET_PROJECT_ACTIVITY_FULFILLED",
    GET_PROJECT_ACTIVITY_REJECTED: "GET_PROJECT_ACTIVITY_REJECTED",

    GET_PROJECT_WORKING: "GET_PROJECT_WORKING",
    GET_PROJECT_WORKING_PENDING: "GET_PROJECT_WORKING_PENDING",
    GET_PROJECT_WORKING_FULFILLED: "GET_PROJECT_WORKING_FULFILLED",
    GET_PROJECT_WORKING_REJECTED: "GET_PROJECT_WORKING_REJECTED",

    GET_EMPLOYEES_WORKING_IN_PROJECT: "GET_EMPLOYEES_WORKING_IN_PROJECT",
    GET_EMPLOYEES_WORKING_IN_PROJECT_PENDING: "GET_EMPLOYEES_WORKING_IN_PROJECT_PENDING",
    GET_EMPLOYEES_WORKING_IN_PROJECT_FULFILLED: "GET_EMPLOYEES_WORKING_IN_PROJECT_FULFILLED",
    GET_EMPLOYEES_WORKING_IN_PROJECT_REJECTED: "GET_EMPLOYEES_WORKING_IN_PROJECT_REJECTED",

    GET_FEATURE_LISTING: "GET_FEATURE_LISTING",
    GET_FEATURE_LISTING_PENDING: "GET_FEATURE_LISTING_PENDING",
    GET_FEATURE_LISTING_FULFILLED: "GET_FEATURE_LISTING_FULFILLED",
    GET_FEATURE_LISTING_REJECTED: "GET_FEATURE_LISTING_REJECTED",

    GET_PROJECT_WORKING_DATA: "GET_PROJECT_WORKING_DATA",
    GET_PROJECT_WORKING_DATA_PENDING: "GET_PROJECT_WORKING_DATA_PENDING",
    GET_PROJECT_WORKING_DATA_FULFILLED: "GET_PROJECT_WORKING_DATA_FULFILLED",
    GET_PROJECT_WORKING_DATA_REJECTED: "GET_PROJECT_WORKING_DATA_REJECTED",

    GET_TEAM_ACTIVITY: "GET_TEAM_ACTIVITY",
    GET_TEAM_ACTIVITY_PENDING: "GET_TEAM_ACTIVITY_PENDING",
    GET_TEAM_ACTIVITY_FULFILLED: "GET_TEAM_ACTIVITY_FULFILLED",
    GET_TEAM_ACTIVITY_REJECTED: "GET_TEAM_ACTIVITY_REJECTED",

    GET_TEAM_WORKING: "GET_TEAM_WORKING",
    GET_TEAM_WORKING_PENDING: "GET_TEAM_WORKING_PENDING",
    GET_TEAM_WORKING_FULFILLED: "GET_TEAM_WORKING_FULFILLED",
    GET_TEAM_WORKING_REJECTED: "GET_TEAM_WORKING_REJECTED",

    GET_OFFLINE_DATA: "GET_OFFLINE_DATA",
    GET_OFFLINE_DATA_PENDING: "GET_OFFLINE_DATA_PENDING",
    GET_OFFLINE_DATA_FULFILLED: "GET_OFFLINE_DATA_FULFILLED",
    GET_OFFLINE_DATA_REJECTED: "GET_OFFLINE_DATA_REJECTED",

    UPDATE_ACTIVE_TASKS: "UPDATE_ACTIVE_TASKS",
    UPDATE_ACTIVE_TASKS_PENDING: "UPDATE_ACTIVE_TASKS_PENDING",
    UPDATE_ACTIVE_TASKS_FULFILLED: "UPDATE_ACTIVE_TASKS_FULFILLED",
    UPDATE_ACTIVE_TASKS_REJECTED: "UPDATE_ACTIVE_TASKS_REJECTED",

    GET_PROJECT_CUSTOM_FIELDS: "GET_PROJECT_CUSTOM_FIELDS",
    GET_PROJECT_CUSTOM_FIELDS_PENDING: "GET_PROJECT_CUSTOM_FIELDS_PENDING",
    GET_PROJECT_CUSTOM_FIELDS_FULFILLED: "GET_PROJECT_CUSTOM_FIELDS_FULFILLED",
    GET_PROJECT_CUSTOM_FIELDS_REJECTED: "GET_PROJECT_CUSTOM_FIELDS_REJECTED",

    GET_XERO_INTEGRATION: "GET_XERO_INTEGRATION",
    GET_XERO_INTEGRATION_PENDING: "GET_XERO_INTEGRATION_PENDING",
    GET_XERO_INTEGRATION_FULFILLED: "GET_XERO_INTEGRATION_FULFILLED",
    GET_XERO_INTEGRATION_REJECTED: "GET_XERO_INTEGRATION_REJECTED",

    UPDATE_XERO_INTEGRATION: "UPDATE_XERO_INTEGRATION",
    UPDATE_XERO_INTEGRATION_PENDING: "UPDATE_XERO_INTEGRATION_PENDING",
    UPDATE_XERO_INTEGRATION_FULFILLED: "UPDATE_XERO_INTEGRATION_FULFILLED",
    UPDATE_XERO_INTEGRATION_REJECTED: "UPDATE_XERO_INTEGRATION_REJECTED",

    GET_XERO_PROJECT: "GET_XERO_PROJECT",
    GET_XERO_PROJECT_PENDING: "GET_XERO_PROJECT_PENDING",
    GET_XERO_PROJECT_FULFILLED: "GET_XERO_PROJECT_FULFILLED",
    GET_XERO_PROJECT_REJECTED: "GET_XERO_PROJECT_REJECTED",

    UPDATE_XERO_PROJECT: "UPDATE_XERO_PROJECT",
    UPDATE_XERO_PROJECT_PENDING: "UPDATE_XERO_PROJECT_PENDING",
    UPDATE_XERO_PROJECT_FULFILLED: "UPDATE_XERO_PROJECT_FULFILLED",
    UPDATE_XERO_PROJECT_REJECTED: "UPDATE_XERO_PROJECT_REJECTED",

    GET_USER_PROJECT_CHANGE_DATA: "GET_USER_PROJECT_CHANGE_DATA",
    GET_USER_PROJECT_CHANGE_DATA_PENDING: "GET_USER_PROJECT_CHANGE_DATA_PENDING",
    GET_USER_PROJECT_CHANGE_DATA_FULFILLED: "GET_USER_PROJECT_CHANGE_DATA_FULFILLED",
    GET_USER_PROJECT_CHANGE_DATA_REJECTED: "GET_USER_PROJECT_CHANGE_DATA_REJECTED",

    GET_ACTIVITY_TIME_TRACKER: "GET_ACTIVITY_TIME_TRACKER",
    GET_ACTIVITY_TIME_TRACKER_PENDING: "GET_ACTIVITY_TIME_TRACKER_PENDING",
    GET_ACTIVITY_TIME_TRACKER_FULFILLED: "GET_ACTIVITY_TIME_TRACKER_FULFILLED",
    GET_ACTIVITY_TIME_TRACKER_REJECTED: "GET_ACTIVITY_TIME_TRACKER_REJECTED",

    GET_CURRENT_STATUS: "GET_CURRENT_STATUS",
    GET_CURRENT_STATUS_PENDING: "GET_CURRENT_STATUS_PENDING",
    GET_CURRENT_STATUS_FULFILLED: "GET_CURRENT_STATUS_FULFILLED",
    GET_CURRENT_STATUS_REJECTED: "GET_CURRENT_STATUS_REJECTED",

    GET_TOTAL_HOUR_REPORT: "GET_TOTAL_HOUR_REPORT",
    GET_TOTAL_HOUR_REPORT_PENDING: "GET_TOTAL_HOUR_REPORT_PENDING",
    GET_TOTAL_HOUR_REPORT_FULFILLED: "GET_TOTAL_HOUR_REPORT_FULFILLED",
    GET_TOTAL_HOUR_REPORT_REJECTED: "GET_TOTAL_HOUR_REPORT_REJECTED",

    EMPLOYEES_DATA: "EMPLOYEES_DATA",
    EMPLOYEES_DATA_PENDING: "EMPLOYEES_DATA_PENDING",
    EMPLOYEES_DATA_FULFILLED: "EMPLOYEES_DATA_FULFILLED",
    EMPLOYEES_DATA_REJECTED: "EMPLOYEES_DATA_REJECTED",

    OFFLINE_REQUEST_TIME: "OFFLINE_REQUEST_TIME",
    OFFLINE_REQUEST_TIME_PENDING: "OFFLINE_REQUEST_TIME_PENDING",
    OFFLINE_REQUEST_TIME_FULFILLED: "OFFLINE_REQUEST_TIME_FULFILLED",
    OFFLINE_REQUEST_TIME_REJECTED: "OFFLINE_REQUEST_TIME_REJECTED",

    GET_CATEGORIES_DATA: "GET_CATEGORIES_DATA",
    GET_CATEGORIES_DATA_PENDING: "GET_CATEGORIES_DATA_PENDING",
    GET_CATEGORIES_DATA_FULFILLED: "GET_CATEGORIES_DATA_FULFILLED",
    GET_CATEGORIES_DATA_REJECTED: "GET_CATEGORIES_DATA_REJECTED",

    GET_NOTES_DATA: "GET_NOTES_DATA",
    GET_NOTES_DATA_PENDING: "GET_NOTES_DATA_PENDING",
    GET_NOTES_DATA_FULFILLED: "GET_NOTES_DATA_FULFILLED",
    GET_NOTES_DATA_REJECTED: "GET_NOTES_DATA_REJECTED",

    GET_CLIENT_PROFILE_INFO: "GET_NOTES_DATA",
    GET_CLIENT_PROFILE_PENDING: "GET_NOTES_DATA_PENDING",
    GET_CLIENT_PROFILE_FULFILLED: "GET_NOTES_DATA_FULFILLED",
    GET_CLIENT_PROFILE_REJECTED: "GET_NOTES_DATA_REJECTED",

    POST_NOTES_DATA: "POST_NOTES_DATA",
    POST_NOTES_DATA_PENDING: "POST_NOTES_DATA_PENDING",
    POST_NOTES_DATA_FULFILLED: "POST_NOTES_DATA_FULFILLED",
    POST_NOTES_DATA_REJECTED: "POST_NOTES_DATA_REJECTED",

    FETCH_USER_DAILY_REPORTS: "FETCH_USER_DAILY_REPORTS",
    FETCH_USER_DAILY_REPORTS_PENDING: "FETCH_USER_DAILY_REPORTS_PENDING",
    FETCH_USER_DAILY_REPORTS_FULFILLED: "FETCH_USER_DAILY_REPORTS_FULFILLED",
    FETCH_USER_DAILY_REPORTS_REJECTED: "FETCH_USER_DAILY_REPORTS_REJECTED",

    GET_CLIENT_OVERVIEW: "GET_CLIENT_OVERVIEW",
    GET_CLIENT_TEAM: "GET_CLIENT_TEAM",
    GET_CLIENT_BILLING_HISTORY: "GET_CLIENT_BILLING_HISTORY",
    GET_CLIENT_INVOICE: "GET_CLIENT_INVOICE",
    GET_CLIENT_TEAM_DETAILED: "GET_CLIENT_TEAM_DETAILED",

    EDIT_CLIENT_PROFILE_INFO: "EDIT_CLIENT_PROFILE_INFO",
    EDIT_CLIENT_PROFILE_PENDING: "EDIT_CLIENT_PROFILE_INFO_PENDING",
    EDIT_CLIENT_PROFILE_FULFILLED: "EDIT_CLIENT_PROFILE_INFO_FULFILLED",
    EDIT_CLIENT_PROFILE_REJECTED: "EDIT_CLIENT_PROFILE_INFO_REJECTED",

    ORG_DETAILS: "ORG_DETAILS",
    INVOICE_ACTION: "INVOICE_ACTION",

    FETCH_USER_MONTHLY_REPORTS: "FETCH_USER_MONTHLY_REPORTS",
    FETCH_USER_MONTHLY_REPORTS_PENDING: "FETCH_USER_MONTHLY_REPORTS_PENDING",
    FETCH_USER_MONTHLY_REPORTS_FULFILLED: "FETCH_USER_MONTHLY_REPORTS_FULFILLED",
    FETCH_USER_MONTHLY_REPORTS_REJECTED: "FETCH_USER_MONTHLY_REPORTS_REJECTED",

    FETCH_EVENT_LOGS: "FETCH_EVENT_LOGS",
    FETCH_EVENT_LOGS_PENDING: "FETCH_EVENT_LOGS_PENDING",
    FETCH_EVENT_LOGS_FULFILLED: "FETCH_EVENT_LOGS_FULFILLED",
    FETCH_EVENT_LOGS_REJECTED: "FETCH_EVENT_LOGS_REJECTED",
};

export const API_ENDPOINTS = {
    BASE_URL: "https://api.joinworkahub.com/api/",
    ACTIVE_USER_LIST: "/active_users_list/",
    INACTIVE_USER_LIST: "/employee_inactive_list/",
    WORKSTATIONS_LIST: "/users/workstation?email=",
    // CLIENTS_LIST: "/clients/?email=",
    CREATE_TEAM: "/team/addTeam",
    EDIT_CLIENT_PROFILE_INFO: "/client_get_info/",
    ACTIVE_CLIENTS: "client/listClient",
    INACTIVE_CLIENTS: "client/listinactive",
    ACTIVE_TEAMS: "/team/listTeam/",
    INACTIVE_TEAMS: "/team/listinactive/",
    ACTIVE_PROJECTS: "/active_projects_usercount/",
    ACTIVE_TASKS: "/tasks/",
    FETCH_ALL_EMPLOYEES: "/emp_names/",
    DELETE_TEAM: "/team/",
    DELETE_TEAM_PADLOCK: "/team/teamDelete",
    REACTIVATE_TEAM: "/team/teamAdd",
    DELETE_USER_PADLOCK: "/users/userdeactivate",
    DELETE_USER: "/employee_active_inactive/",
    REACTIVATE_USER_PADLOCK: "/users/reactivate_user",
    REACTIVATE_USER: "/employee_reactivate/",
    DELETE_WORKSTATION: "/users/workstation?id=",

    CREATE_WORKSTATION: "/users/workstation",
    CREATE_CLIENT: "/client/",
    CREATE_WORKSTATION_JOINWORKAHUB: "/workstation/",

    GET_CLIENT_PROFILE_INFO: "/client_get_info/?id=",

    // CREATE_CLIENT_JOINWORKAHUB:'/client/',
    ADD_CLIENT_TO_TEAM: "/client_get_info_team/",
    UPDATE_WORKSTATION: "/users/workstation?id=",
    GET_USERS_FROM_TEAM: "/team/listMember/",

    // GET_CLIENT:"/team/client/",//added
    GET_CLIENT: "/client/", //added
    DELETE_CLIENT: "/client/",
    INACTIVE_CLIENTS: "/client/",

    GET_USERS_FROM_PROJECT: "/project_username_list/?project_name=",
    DELETE_PROJECT: "/delete_project/",
    DELETE_TASK: "/tasks/",
    DELETE_USER_FROM_PROJECT: "/delete_user_from_project/",
    CREATE_TASK: "/tasks/",
    UPDATE_TASK: "/tasks/",
    CREATE_PROJECT: "/create_project/",
    UPDATE_PROJECT: "/project_name_edit/",
    ADD_USER_TO_PROJECT: "/add_user_to_project/",
    GET_PAYMENT_PLAN: "/users/paymentplan?user=1",
    GET_ORG_UUID: "/users/org_uuid?org=",
    GET_API_KEYS: "/users/getkeys?org_uuid=",
    GET_SECRET_KEY: "users/get_secretKey?org_uuid=",
    POST_SECRET_KEY: "/users/generate_secret_key",
    DELETE_KEY: "/users/delete_key",
    API_KEY_STATUS: "users/status_update",
    GET_FEATURE_UNAVAILABLE: "/features/featureUnavailable?type=Org",
    GET_FEATURE_MARKET: "/features/featureMarket?type=Org",
    GET_FEATURE_LIST: "/features/featureList?type=Org",
    GET_OVERIDE_FEATURE_LIST: "/features/featureOveride?featureid=",
    GET_SCREENSHOT_TIMING: "/screenshot_storage/?type=",
    POST_SCREENSHOT_TIMING: "/screenshot/",
    PATCH_SCREENSHOT_TIMING: "screenshot/?id=",
    POST_SCREENSHOT_TIME: "/screenshot_storage/?type=Org",
    POST_FEATURE_START: "/features/featureStart",
    DELETE_FEATURE_MARKET: "/features/featureMarket",
    DELETE_FEATURE_START: "features/featureStart?featureid=",
    POST_FEATURE_MARKET: "/features/featureMarket",
    PUT_FEATURE_ENABLE: "/features/featureEnable?id=",
    PUT_FEATURE_STOP: "/features/featureStop?id=",
    POST_FORGET_PASSWORD: "/password/forgot_password ",
    SUBSCRIBE_EMAIL: "/subscribes_report/",
    GET_ROLE_UPDATE: "/role_update/",
    GET_TYPE_INTERVAL: "/users/type_interval?email=",
    GET_ADDRESS: "/users/getaddress/",
    POST_TYPE: "/users/get_type",
    GET_LAST_PAYMENT: "/users/last_payment?email=",
    POST_GENERATE_INVOICE: "/users/generate_invoice",
    POST_PDF_GENERATOR: "/users/pdf_generator",
    POST_TYPE_INTERVAL: "/users/type_interval",
    FETCH_MANAGER_DASHBOARD: "/manager_dashboard/",
    FETCH_MANAGER_DASHBOARD_OBSERVER: "/manager_dashboard_observer/",
    FETCH_TEAMS_DASHBOARD: "/team_dashboard/?select_date=",
    FETCH_NOT_WORKING_EMPLOYEES: "/notWorking/?select_date=",
    SEND_USER_INVITE: "/users/invite_email/?domain_key=",
    ORG_EMAILS: "/users/emails_list?org=",
    DELETE_ORG_EMAIL: "/users/emails_list?email=",
    UPDATE_USER_EMAIL: "/users/emails_list?email=",
    GET_ALL_REQUEST: "/request/",
    TEAM_DETAILS_DASHBOARD: "/team_detail_dashboard/?select_date=",
    PROJECT_DETAILS_DASHBOARD: "/project_detail_dashboard/?date=",
    GET_APP_LOG: "/programList/?select_date=",
    GET_APP_LOG_DATA: "/program_data/?user_id=",
    TIME_TRACKER_DATA: "/time_tracker_data/?date=",
    TIME_TRACKER_DATA_OBSERVER: "/time_tracker_data_observer/?date=",
    CPU_INFORMATION_DATA: "/systeminfo/?date=",
    FETCH_SYSTEM_INFO: "/systeminfo/?date=",
    GET_ACTIVITY_INDEX: "/index_activity/?date=",
    GET_USER_URLS: "/activity/",
    GET_ALL_URLS: "/production_url/",
    POST_PAYPAL_PAYMENT: "users/pay",
    GET_EMPLOYEES_ATTENDENCE: "/attendance/",
    GET_EMPLOYEE_APPLICATION_REPORT: "/production_app/",
    GET_EMPLOYEE_WEBSITE_REPORT: "/production_urls/",
    GET_EMPLOYEE_WEBSITE_REPORT_DASHBOARD: "/graph_details/",
    CUSTOM_WEEKLY_GRAPH_DATA: "/customize_report/",
    HOUR_QUICK_REPORT: "quick_report/?startdate=",
    HOUR_CUSTOM_REPORT: "customize_report/2022-03-29/2022-03-29/",
    HOUR_REPORT_CSV_DOWNLOAD: "/quick_report_generate/?startdate=",
    ADD_MEMBER_TO_TEAM_IN_PADLOCK: "/team/AddTeamMember/",
    ADD_MEMBER_TO_TEAM: "/team_member/",
    DELETE_TEAM_MEMBER: "/team/DeleteTeamMember/",
    EMP_LOGIN_DETAILS: "user/login/",
    EMP_DASHBOARD_DETAILS: "employee_dashboard/",
    EMP_SUMMARY_DETAILS: "employee_summary_data/",
    ATTENDANCE_TIMING_DETAILS: "timing/",
    GET_USER_PROJECTS: "project_members/",
    GET_SAVED_PAYMENT_CARDS: "users/list_card/",
    PAY_FOR_APP: "users/app_price_payment",
    EDIT_TIME_TRACKER_SCREENSHOT: "/edit_time_tracker/",
    GET_TIME_TRACKER_SCREENSHOT_INTERVAL: "/time_tracker_interval/",

    GET_UNAVAILABLE_FEATURES_TEAM: "/features/featureUnavailable?",
    GET_MARKET_FEATURES_TEAM: "features/featureMarket?",
    GET_FEATURES_LIST_TEAM: "features/featureList?",
    GET_USER_AVAILABLE_FEATURES: "/features/featureMarket?type=User&userid=",
    GET_USER_UNAVAILABLE_FEATURES: "/features/featureUnavailable?type=User&userid=",
    GET_USER_CUSTOM_PERMISSIONS: "/features/featureList?type=User&userid=",

    GET_EMPLOYEE_PHONE_NUMBER: "users/phone_profile?uu_id=",
    EDIT_EMPLOYEE_PHONE_NUMBER: "users/phone_profile?",
    EDIT_EMPLOYEE_BASIC_INFO: "basic_info_creation/",

    GET_USERBASED_PROJECTS: "user_based_projects_list/",
    REMOVE_USERBASED_PROJECTS: "delete_user_from_projectgroup/",
    ADD_USERBASED_PROJECTS: "add_project_to_user/",

    CANCEL_PLAN_ADMIN: "users/admin_deactivate",

    EMPLOYEE_BRWOSER_ACTIVITY_LOG: "title/",
    TIMETRACKER_VIEWDETAIL: "view_details_data/",

    SECURE_PAYMENT_STRIPE: "users/create_paymentIntent",

    GET_OBSERVERS_LIST: "users/observerList",
    GET_MANAGERS_LIST: "users/managerList",

    ROLES_CHECK: "roles_check/",

    TASK_LIST_OBSERVER: "tasks_observer",
    GET_EMP_LIST_OBSERVER: "/emp_names_observer/",

    GET_SYSTEM_INFO_OBSERVER: "systeminfo_observer/",

    GET_DETAILED_TASK_INFO: "taskTime/",
    WEEKLYCHART_ACTIVITY: "activity_weekend/",

    REQUEST_PROJECT_CHANGE: "request/",
    GET_PROJECT_LIST: "project_list",

    DELETE_TIME: "deleted_time/",
    CANCEL_TIME: "cancel_time/",

    GET_USER_DETAIL: "detail_datas/?date=",
    GET_SHOWCASE_ENTRIES: "/showcase_time/?date=",
    GET_SHOWCASE_USERS_LIST: "/show_users/?date=",

    GET_DAILY_ACTITVITY_LIST: "/daily_activitys/?date=",
    FETCH_USER_DAILY_REPORTS: "/daily_report_user/?date=",
    // FETCH_USER_MONTHLY_REPORTS: "/monthly_daily_report/2023-08",
    FETCH_USER_MONTHLY_REPORTS: "/monthly_daily_report/?date",
    GET_PROJECT_DETAILS: "project_Showcase/",

    DASHBOARD_LAYOUT: "/layout/",
    ALL_PROJECT_TABLE_LAYOUT: "/table1/",

    PROJECT_TABLE_LAYOUT: "projecttask/",

    BOOKING_REPORT_TABLE_LAYOUT: "/table2/",
    GET_BOOKING_REPORT: "/booking_report/?start_date=",
    DOWNLOAD_CSV_BOOKING_REPORT: "/booking_csv_report/?start_date=",

    DASHBOARD_ARRANGEMENT: "/table_layout/",
    GET_EMPLOYEE_MOOD: "/moods/?date=",
    GET_MOOD_TABLE_LAYOUT: "/moods_table/",
    GET_MOOD_TABLE_ARRANGEMENT: "/moods_layout_table/",

    PROJECT_TABLE_ARRANGEMENT: "/project_table/",
    BOOKING_REPORT_TABLE_ARRANGEMENT: "/settings_table/",
    TEAM_TABLE_LAYOUT: "/team_table/",
    EMPLOYEE_TABLE_LAYOUT: "/employee_table/",
    TEAM_TABLE_ARRANGEMENT: "/team_layout_table/",
    EMPLOYEE_TABLE_ARRANGEMENT: "/employee_layout_table/",
    ALL_PROJECT_TABLE_ARRANGEMENT: "/project_layout_table/",

    EMPLOYEE_MOODS: "/moods_user/?date=",
    MOOD_GRAPH: "/mood_graph/?start_date=",

    ALL_PROJECT_TABLE_DATA: "project_Dash/?date=",
    USER_PROJECTS: "/user_based_projects/?user_id=",
    USER_TEAMS: "/team/teamBasedUser?user_id=",

    APPS_LOGGED: "/pro_urls/?user_id=",
    SINGLE_APP_LOG: "/pro_single_urls/?user_id=",

    PAYROLL: "/payroll/",
    // SALARY_DAYS: "/salary_days/?user_id=",
    SALARY_DAYS: "/timesheet_history/?user_id=",
    SALARY_DAY_DOWNLOAD: "/salary_day_download/?user_id=",
    SALARY_YEAR: "/salary_year/?user_id=",
    SALARY_HISTORY: "/salary_history/?user_id=",
    USER_SKILLS: "/skills/",
    PROFILE_IMG: "/workahub_profile",
    PROJECT_DATA: "/project_data/",
    USER_LOC_DESG: "/loc_des/",
    USER_DATA: "/user_data/",
    BLOCKED_URLS: "/blocked_urls/",

    PROJECT_WORKING_USERS: "/project_working/",
    PROJECT_ACTIVITY: "/project_activity/",
    EMPLOYEES_WORKING_IN_PROJECT: "/project_detail/",
    EDIT_USER_DETAILS: "/user_profile_edit/",
    FEATURE_LISTING: "users/feature_listing",

    TEAM_DASHBOARD_LAYOUT: "/team_screen_layout/",
    TEAM_DASHBOARD_ARRANGEMENT: "/team_adjust_layout/",
    TEAM_MEMBER_TABLE_LAYOUT: "/team_members_layout/",
    TEAM_MEMBER_TABLE_ARRANGEMENT: "/team_members_adjust/",
    TEAM_PROJECT_TABLE_LAYOUT: "/team_project_details_layouts/",
    TEAM_PROJECT_TABLE_ARRANGEMENT: "/team_project_details_adjust/",
    TEAM_EMPLOYEE_WORKING_TABLE_LAYOUT: "/team_employee_working_layouts/",
    TEAM_EMPLOYEE_WORKING_TABLE_ARRANGEMENT: "/team_employee_working_adjust/",

    PROJECT_WORKING_DATA: "/project_working_data/",
    TEAM_WORKING_USERS: "/team_work/",
    TEAM_ACTIVITY: "/team_activit/",
    TASK_TABLE_LAYOUT: "/task_layouts/",
    TASK_TABLE_ARRANGEMENT: "/task_orders/",

    OFFLINE_DATA: "/data_offline/",
    PROJECT_CUSTOM_FIELDS: "/custom_fields/?id=",
    XERO_INTEGRATION: "/xero_integration/",
    XERO_TENANT: "/xero_tenant/",
    XERO_PROJECT: "/xero_project/",
    USER_PROJECT_CHANGE_DATA: "/tracker_details/?user_id=",
    ACTIVITY_TIME_TRACKER: "/detail_program/?date=",

    CURRENT_STATUS: "/working_employees/?date=",
    TOTAL_HOURS_REPORT: "/total_hours/?start_date=",
    CURRENT_STATUS_EMPLOYEES: "/working_employees_data/?date=",
    UPDATE_REQUEST: "/request_time/",
    PAYPAL_REQUEST: "/paypal/pay?invoice_id=",
    REQUEST_OFFLINE_TIME: "total_request/",
    CATEGORIES_DATA: "/project_datas/",
    UPDATE_OFFLINE_REQUEST: "/total_request/",
    NOTES: "/task_notes_web/",
    CLIENT_DATA_OVERVIEW: "/client_info/?id=",
    CLIENT_DATA_TEAM: "/client_get_info_team/?date=",
    CLIENT_BILLING_HISTORY: "/client_history/?id=",
    CLIENT_INVOICE: "/invoice_billing?id=",
    CLIENT_TEAM_DETAILED: "/client_get_info_team_detailed/",
    ORG_DETAILS: "/org_details/",
    CLIENT_BILLING_DATA: "/client_data_list/?year=",
    DOWNLOAD_INVOICE: "/users/pdf_workahub_generator",
    EMAIL_INVOICE: "/users/email_invoice",
    DELETE_PADLOCK_TEAM_MEMBERS: "/team/DeleteTeamMembers",
    DELETE_TEAM_MEMBERS: "/team_delete_members/",
    EVENTS_LOGS: "/logs?org_uuid=",
};
