import { createStore, applyMiddleware } from 'redux';
// import { createLogger } from 'redux-logger';
import reduxPromiseMiddleWare from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import reducers from './services/reducers';

const store = createStore(reducers, applyMiddleware(
    thunk, reduxPromiseMiddleWare,
));

export default store;