import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import InputField from "../../Components/InputField";
import XeroLogo from "../../assets/xero.png";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { TrashIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";

export default function AddIndexModal(props) {
    const { onCancelClick, onSubmitClick, onDeleteClick, submitButtonName, edit, open, ...rest } =
        props;
    const cancelButtonRef = useRef(null);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [weightage, setWeightage] = useState("");
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    useEffect(() => {
        if (props.name && !name) {
            setName(props.name);
        }
        if (props.description && !description) {
            setDescription(props.description);
        }
        if (props.weightage && !weightage) {
            setWeightage(props.weightage);
        }
    }, [open]);
    return (
        <Transition.Root show={open} as={Fragment} {...rest}>
            <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                onClose={() => {
                    setName("");
                    setDescription("");
                    setWeightage("");
                    onCancelClick();
                }}>
                <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="relative inline-block max-w-3xl min-w-[750px] align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                    <Dialog.Title
                                        as="h3"
                                        className=" flex justify-between items-center text-xl leading-6 font-bold text-textprimary">
                                        <div>{edit ? "Edit Index" : "Add Index"}</div>
                                        <XIcon
                                            className="w-5 h-5 hover:cursor-pointer text-gray-500"
                                            onClick={() => {
                                                setName("");
                                                setDescription("");
                                                setWeightage("");
                                                onCancelClick();
                                            }}
                                        />
                                    </Dialog.Title>
                                </div>
                                <div className="mt-2">
                                    <Dialog.Description as="div">
                                        <div className="pb-2 pt-4 font-semibold">Index Name</div>
                                        <InputField
                                            value={name}
                                            className="text-tiny text-textprimary"
                                            type="text"
                                            onInputChange={(ev) => setName(ev.target.value)}
                                        />
                                        <div className="pb-2 pt-4 font-semibold">Description</div>
                                        <InputField
                                            value={description}
                                            className="text-tiny text-textprimary"
                                            placeholder="Write descripion here..."
                                            type="text"
                                            onInputChange={(ev) => setDescription(ev.target.value)}
                                        />
                                        <div className="pb-2 pt-4 font-semibold">Weightage</div>
                                        <InputField
                                            value={weightage}
                                            className="text-tiny text-textprimary"
                                            type="number"
                                            placeholder={0}
                                            onInputChange={(ev) => setWeightage(ev.target.value)}
                                        />
                                    </Dialog.Description>
                                </div>
                            </div>
                            <div className="mt-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium ${
                                        !name || !description || !weightage
                                            ? "bg-gray-200 text-gray-600"
                                            : "bg-primary text-white hover:bg-primary"
                                    } focus:outline-none sm:ml-3 sm:w-auto sm:text-sm`}
                                    onClick={
                                        !name || !description || !weightage
                                            ? () => {}
                                            : () => {
                                                  setName("");
                                                  setDescription("");
                                                  setWeightage("");
                                                  onDeleteClick();
                                              }
                                    }>
                                    {submitButtonName}
                                </button>
                                {edit && (
                                    <button
                                        type="button"
                                        className="w-full flex gap-1 items-center justify-center rounded-md border text-danger border-danger shadow-sm px-4 py-2 bg-white text-base font-medium focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => {
                                            setName("");
                                            setDescription("");
                                            setWeightage("");
                                            onCancelClick();
                                        }}>
                                        <TrashIcon className="w-5 h-5" />
                                        <div>Delete Index</div>
                                    </button>
                                )}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
