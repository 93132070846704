import React from "react";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { useEffect } from "react";

function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        width: "83%",
    };
}

const newStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        minHeight: "350px",
        maxWidth: "300px",
        backgroundColor: theme.palette.background.paper,
        //border: "2px solid #000",
        boxShadow: theme.shadows[5],
        //padding: theme.spacing(2, 4, 3),
        padding: "50px 20px",
    },
}));

export const ModalProjectSelect = (props) => {
    const classes = newStyles();
    const modalStyle = getModalStyle();
    const open = props.open;
    const [selectedProject, setSelectedProject] = useState("");
    useEffect(() => {
        setSelectedProject(props.selectedProject);
    }, [props.selectedProject]);
    const projectsList = props.projectsList;
    const handleClose = props.handleClose;
    const handleDone = props.handleDone;
    return (
        <Modal open={open} onClose={handleClose}>
            <div style={modalStyle} className={classes.paper}>
                <div
                    className="close_png border -translate-y-7 border-gray-400 z-10"
                    onClick={handleClose}>
                    <img src={require("../../../assets/close.png")} />
                </div>
                <div className="h-full flex flex-col items-center">
                    <div className=" font-bold text-lg text-center">
                        Select the new project
                    </div>
                    <div className=" my-4">
                        {projectsList !== undefined &&
                        projectsList !== "" &&
                        selectedProject !== undefined &&
                        selectedProject !== "" &&
                        projectsList.length > 0
                            ? projectsList.map((proj) => {
                                  return (
                                      <div className=" pl-4" key={proj.id}>
                                          <input
                                              name="projectList"
                                              type="radio"
                                              className=" text-primary"
                                              checked={
                                                  selectedProject.id === proj.id
                                              }
                                              value={proj.id}
                                              id={proj.id}
                                              onChange={(e) => {
                                                  setSelectedProject(proj);
                                              }}
                                          />
                                          <label
                                              className="pl-4"
                                              htmlFor={proj.id}>
                                              {proj.project_name}
                                          </label>
                                      </div>
                                  );
                              })
                            : "Loading"}
                    </div>
                    <button
                        className={` mt-4 inline-block px-4 py-2 min-w-max mr-4 max-h-10 bg-primary text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out`}
                        onClick={() => {
                            handleDone(selectedProject);
                        }}>
                        Done
                    </button>
                </div>
            </div>
        </Modal>
    );
};
