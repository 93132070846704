import { UsersIcon } from '@heroicons/react/solid';
import React, { Component } from 'react'
import PrimaryButton from '../../../Components/PrimaryButton';
import SelectField from '../../../Components/SelectField';
import PropTypes from 'prop-types';
import ToggleButton from '../ToggleButton';
import Layout from '../../../Components/Layout';
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

class Teams_sub_tab extends Component {
    render() {
        const { t } = this.props;
        const {
            HandleTeamPermissionMobileSelectTab,
            HandleTeamPermissionSelectTab,
            TeamPermissionSubSelectedTab,
            onSelectTeamChange,
            enableDisable_customPermission_Team,
            selectedTeam_uuid,
            selectedTeam_FeaturesList,
            listOfFeatures,
            TeamData,
            PermissionSubSelectedTab,
            overidedBy,
            screenshotTiming,
            handleTimeChange,
            activeUsers,
            timingArray,
        } = this.props;
        return (
            //   <Layout>
            <div className="bg-white px-2 py-1 pb-4 border-b border-gray-200 sm:px-6 rounded-lg my-6">
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        {t("requestsModule.selectATab")}
                    </label>
                    <select
                        id="tabs"
                        name="tabs"
                        onChange={(e) => HandleTeamPermissionMobileSelectTab(e)}
                        className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                        defaultValue={TeamPermissionSubSelectedTab}>
                        <option key="Team-Permissions" value="teamscustomPermissions">
                            {t("settingsTab.custom")} {t("settingsTab.permission")}
                        </option>
                        <option key="Team-usersExcluded" value="Team-usersExcluded">
                            {t("settingsTab.custom")} {t("settingsTab.permission")}
                        </option>
                        {/* <option key="Team-Available-Features" value="Team-Available-Features">Available features</option>
        <option key="Team-Unavailable-Features" value="Team-Unavailable-Features">Unavailable features</option> */}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        <div
                            key="Teams"
                            onClick={() => HandleTeamPermissionSelectTab("teamscustomPermissions")}
                            className={classNames(
                                TeamPermissionSubSelectedTab === "teamscustomPermissions"
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                            )}
                            aria-current={
                                PermissionSubSelectedTab === "teamscustomPermissions"
                                    ? "page"
                                    : undefined
                            }>
                            <span>
                                {t("settingsTab.custom")} {t("settingsTab.permission")}
                            </span>
                        </div>
                        <div
                            key="Team-usersExcluded"
                            onClick={() => HandleTeamPermissionSelectTab("Team-usersExcluded")}
                            className={classNames(
                                TeamPermissionSubSelectedTab === "Team-usersExcluded"
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                            )}
                            aria-current={
                                PermissionSubSelectedTab === "Team-usersExcluded"
                                    ? "page"
                                    : undefined
                            }>
                            <span>
                                {t("TeamTab.users")} {t("settingsTab.excluded")}
                            </span>
                        </div>

                        {/* <a key="Users" onClick={() => this.HandleTeamPermissionSelectTab("Team-Available-Features")}
            className={classNames(
                this.state.TeamPermissionSubSelectedTab == "Team-Available-Features"
                    ? 'border-primary text-primary'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer'
            )}
            aria-current={this.state.TeamPermissionSubSelectedTab == "Team-Available-Features" ? 'page' : undefined}
        >
            <span>Available features</span>
        </a>

        <a key="Workstations" onClick={() => this.HandleTeamPermissionSelectTab("Team-Unavailable-Features")}
            className={classNames(
                this.state.TeamPermissionSubSelectedTab == "Team-Unavailable-Features"
                    ? 'border-primary text-primary'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer'
            )}
            aria-current={this.state.TeamPermissionSubSelectedTab == "Team-Unavailable-Features" ? 'page' : undefined}
        >

            <span>Unavailable features</span>
        </a> */}
                    </nav>
                </div>

                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                    <div className="ml-4 mt-5">
                        <SelectField
                            onSelectChange={(e) => onSelectTeamChange(e)}
                            FieldName={t("select") + " " + t("team")}
                            name={"teamName"}
                            arrayOfData={TeamData}
                            value={selectedTeam_uuid}
                            id={"team"}></SelectField>
                    </div>
                    <div className="ml-4 mt-2 flex-shrink-0"></div>
                </div>

                {TeamPermissionSubSelectedTab === "teamscustomPermissions" ? (
                    <div>
                        <div className="mt-8 flex flex-col">
                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        {t("settingsTab.feature")} {t("name")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        {t("description")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-lg">
                                                        {t("settingsTab.overriddenByGlobal")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-lg">
                                                        {t("settingsTab.overriddenByUsers")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        {t("status")}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {selectedTeam_uuid === "" ||
                                                selectedTeam_uuid === undefined ||
                                                activeUsers === undefined ||
                                                activeUsers.length === 0 ? (
                                                    <tr>
                                                        <td
                                                            colSpan={4}
                                                            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                                            {t(
                                                                "settingsTab.selectTeamToViewDetails"
                                                            )}
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                ) : selectedTeam_FeaturesList &&
                                                  selectedTeam_FeaturesList.length > 0 &&
                                                  overidedBy.length > 0 ? (
                                                    selectedTeam_FeaturesList.map((item, idx) => (
                                                        <tr key={idx}>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {item.feature}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {item.description}
                                                            </td>
                                                            <td className="whitespace-wrap px-3 py-4 text-sm text-gray-500">
                                                                {
                                                                    overidedBy[idx].org.length === 0
                                                                        ? "None"
                                                                        : "Global"
                                                                    // overidedBy[
                                                                    //       idx
                                                                    //   ].org.map(
                                                                    //       (
                                                                    //           title,
                                                                    //           id
                                                                    //       ) => {
                                                                    //           if (
                                                                    //               id ===
                                                                    //               overidedBy[
                                                                    //                   idx
                                                                    //               ]
                                                                    //                   .org
                                                                    //                   .length -
                                                                    //                   1
                                                                    //           ) {
                                                                    //               return title;
                                                                    //           } else {
                                                                    //               return (
                                                                    //                   title +
                                                                    //                   ", "
                                                                    //               );
                                                                    //           }
                                                                    //       }
                                                                    //   )
                                                                }
                                                            </td>
                                                            <td className="whitespace-wrap px-3 py-4 text-sm text-gray-500">
                                                                {overidedBy[idx].users.length === 0
                                                                    ? "None"
                                                                    : overidedBy[idx].users.map(
                                                                          (title, id) => {
                                                                              if (
                                                                                  id ===
                                                                                  overidedBy[idx]
                                                                                      .users
                                                                                      .length -
                                                                                      1
                                                                              ) {
                                                                                  return (
                                                                                      <Link
                                                                                          onClick={() => {
                                                                                              for (
                                                                                                  let i = 0;
                                                                                                  i <
                                                                                                  activeUsers.length;
                                                                                                  i++
                                                                                              ) {
                                                                                                  if (
                                                                                                      activeUsers[
                                                                                                          i
                                                                                                      ]
                                                                                                          .user_login_as ===
                                                                                                      title
                                                                                                  ) {
                                                                                                      localStorage.setItem(
                                                                                                          "data",
                                                                                                          JSON.stringify(
                                                                                                              activeUsers[
                                                                                                                  i
                                                                                                              ]
                                                                                                          )
                                                                                                      );
                                                                                                      break;
                                                                                                  }
                                                                                              }
                                                                                          }}
                                                                                          to={`/employeeProfile`}>
                                                                                          {title}
                                                                                      </Link>
                                                                                  );
                                                                              } else {
                                                                                  return (
                                                                                      <Link
                                                                                          onClick={() => {
                                                                                              for (
                                                                                                  let i = 0;
                                                                                                  i <
                                                                                                  activeUsers.length;
                                                                                                  i++
                                                                                              ) {
                                                                                                  if (
                                                                                                      activeUsers[
                                                                                                          i
                                                                                                      ]
                                                                                                          .user_login_as ===
                                                                                                      title
                                                                                                  ) {
                                                                                                      localStorage.setItem(
                                                                                                          "data",
                                                                                                          JSON.stringify(
                                                                                                              activeUsers[
                                                                                                                  i
                                                                                                              ]
                                                                                                          )
                                                                                                      );
                                                                                                      break;
                                                                                                  }
                                                                                              }
                                                                                          }}
                                                                                          to={`/employeeProfile`}>
                                                                                          {title +
                                                                                              ", "}
                                                                                      </Link>
                                                                                  );
                                                                              }
                                                                          }
                                                                      )}
                                                            </td>
                                                            <td className="whitespace-nowrap flex align-top px-3 py-4 text-sm text-gray-500">
                                                                <p
                                                                    className={
                                                                        item.featureid === 6 &&
                                                                        item.status
                                                                            ? "translate-y-4"
                                                                            : ""
                                                                    }>
                                                                    <ToggleButton
                                                                        checked={item.status}
                                                                        onChange={() => {
                                                                            enableDisable_customPermission_Team(
                                                                                item.id,
                                                                                item.status,
                                                                                item.data
                                                                            );
                                                                        }}
                                                                    />
                                                                </p>
                                                                {item.featureid === 6 &&
                                                                item.status ? (
                                                                    <p className="w-2/5 ml-2">
                                                                        <SelectField
                                                                            onSelectChange={
                                                                                handleTimeChange
                                                                            }
                                                                            FieldName=""
                                                                            name={"Screen Interval"}
                                                                            arrayOfData={
                                                                                this.props
                                                                                    .timingArray
                                                                            }
                                                                            mainclassName={"mt-3"}
                                                                            id={"ScreenShotTime"}
                                                                            defaultValue={
                                                                                (screenshotTiming !==
                                                                                ""
                                                                                    ? screenshotTiming.data
                                                                                    : "10") + ""
                                                                            }
                                                                            // value={this.state.initialScreenShotIntervalData == "" ? "" : this.state.initialScreenShotIntervalData[0].screenshot_interval}
                                                                        ></SelectField>
                                                                    </p>
                                                                ) : null}
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            {t("noDataFound")}
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                {TeamPermissionSubSelectedTab === "Team-usersExcluded" ? (
                    <div className="bg-white px-2 py-1 pb-4 border-b border-gray-200 sm:px-6 rounded-lg my-6">
                        <h1 className="text-primary my-6 text-lg font-semibold">
                            {t("TeamTab.users")} {t("settingsTab.excluded")}
                        </h1>
                        <div>
                            <div className="mt-8 flex flex-col">
                                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                            {t("settingsTab.feature")} {t("name")}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            {t("description")}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            {t("TeamTab.users")}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            {t("action")}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {listOfFeatures === "" ? (
                                                        <tr>
                                                            <td>
                                                                {t("loading")}
                                                                ...
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        listOfFeatures &&
                                                        listOfFeatures.features.map(
                                                            (feature, idx) => (
                                                                <tr key={idx}>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                        {feature.feature}
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                        {feature.description}
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex">
                                                                        <UsersIcon className="h-5 w-5 mr-1 text-black" />{" "}
                                                                        <p>
                                                                            {
                                                                                feature.userexcluded
                                                                                    .length
                                                                            }
                                                                        </p>
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                        <PrimaryButton
                                                                            buttontext={t(
                                                                                "settingsTab.setPermissions"
                                                                            )}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {/* {this.state.TeamPermissionSubSelectedTab == "Team-Available-Features"
 ?
    <div>
        <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        Feature Name
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Description
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {this.state.selectedTeam_uuid == ""
                                 ? <tr><td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Select Team to view details</td><td></td><td></td></tr>
                                 :  this.state.selectedTeam_availableFeatures && this.state.selectedTeam_availableFeatures.length > 0 
                                    ? this.state.selectedTeam_availableFeatures.map((item, idx) => (
                                            <tr key={idx}>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.name}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.desciption}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {!item.status ? <PrimaryButton buttontext='Enable' /> : <PrimaryButton buttontext='Disable' onClick={() => {deleteStop_Feature_team(item.id, item.name)}} />}
                                                </td>
                                            </tr>
                                        ))
                                    : <tr><td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">No Data Found</td><td></td><td></td></tr>
                                    }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div> 
 : null
}

{this.state.TeamPermissionSubSelectedTab == "Team-Unavailable-Features"
 ?
    <div>
        <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        Feature Name
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Description
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {this.state.selectedTeam_uuid == ""
                                 ? <tr><td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Select Team to view details</td><td></td><td></td></tr>
                                 : this.state.selectedTeam_unavailableFeatures && this.state.selectedTeam_unavailableFeatures.length > 0 
                                    ?   this.state.selectedTeam_unavailableFeatures.map((item, idx) => (
                                            <tr key={idx}>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.name}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.description}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <PrimaryButton buttontext='Add feature' onClick={() => {addStart_Feature_team(item.id)}} />
                                                </td>
                                            </tr>
                                        ))
                                    : <tr><td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">No Data Found</td><td></td><td></td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
 : null
} */}
            </div>
            //   </Layout>
        );
    }
}

Teams_sub_tab.propTypes = {
    HandleTeamPermissionMobileSelectTab: PropTypes.func,
    HandleTeamPermissionSelectTab: PropTypes.func,
    TeamPermissionSubSelectedTab: PropTypes.string,
    PermissionSubSelectedTab: PropTypes.string,
    TeamData: PropTypes.array,
    onSelectTeamChange: PropTypes.func,
    selectedTeam_uuid: PropTypes.string,
    selectedTeam_FeaturesList: PropTypes.array,
    listOfFeatures: PropTypes.array,
    enableDisable_customPermission_Team: PropTypes.func
};

export default withTranslation('common')(Teams_sub_tab);