import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import "./styles/global.css";

import "./styles/output.css";
import App from "./App.js";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import store from "./store";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import moment from "moment";

import common_en from "./translation/en/common.json";
import common_es from "./translation/es/common.json";
import common_pt from "./translation/pt/common.json";
import common_sa from "./translation/sa/common.json";

const getOSLang = Intl.DateTimeFormat().resolvedOptions().locale;

const lang = getOSLang && getOSLang.split("-");
const setLang =
  lang &&
  localStorage.setItem(
    "language",
    lang[0] == null
      ? "en"
      : lang[0] == "en" || lang[0] == "es" || lang[0] == "pt" || lang[0] == "sa"
      ? lang[0]
      : "en"
  );
i18next.init({
  interpolation: {
    escapeValue: false,
    format: function (value, format, lng) {
      if (value instanceof Date) return moment(value).format(format);
      return value;
    },
  },
  lng:
    lang[0] == null
      ? "en"
      : lang[0] == "en" || lang[0] == "es" || lang[0] == "pt" || lang[0] == "sa"
      ? lang[0]
      : "en",
  resources: {
    en: { common: common_en },
    es: { common: common_es },
    pt: { common: common_pt },
    sa: { common: common_sa },
  },
});

const url = window.location.host;
const orgName = url.split(".");
const org = orgName[0];

const queryParams = new URLSearchParams(window.location.search);
const token = queryParams.get("token");

const urlforAPI = () => {
  if (token) {
    fetch(`https://api.trypadlock.com/org/get_org`, {
      method: "GET",
      headers: {
        authorization: "JWT " + token,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // localStorage.setItem("apiURL", data.url);

        localStorage.setItem("apiURL", "https://api.joinworkahub.com");
        // fetch(`https://api.trypadlock.com/users/frontendData/${data.url}`, {
        // 	method: "GET",
        // })
        // .then((res) => res.json())
        // .then((data) => {
        // 	console.log('dta', data)
        // })
      });
  } else {
    // console.log("TOKEN no availble");
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    if (
      (access_token == undefined ||
        access_token == "undefined" ||
        access_token == null ||
        access_token == "") &&
      (refresh_token == undefined ||
        refresh_token == "undefined" ||
        refresh_token == null ||
        refresh_token == "")
    ) {
      window.location.href = "https://www.joinworkahub.com/en/home";
    }
  }
};
urlforAPI();
Sentry.init({
  dsn: "https://30dc96a2ecc49959895cd57adc899e4e@o4507428137926656.ingest.us.sentry.io/4507428225286144",
  integrations: [new BrowserTracing()],
  maxBreadcrumbs: 50,
  normalizeMaxBreadth: 100,
  environment: "production",
  maxRequestBodySize: "medium",
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <React.StrictMode>
        <App routeLang="" />
      </React.StrictMode>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
