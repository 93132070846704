/* eslint-disable react/jsx-pascal-case */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CheckCircleIcon, UserIcon, UsersIcon } from '@heroicons/react/solid';
import ToggleButton from './ToggleButton'
import PrimaryButton from '../../Components/PrimaryButton';
import {
    FetchactiveTeams,
    fetchactiveuserslist,
} from "../../services/teams/actions";
import { fetchallEmployees } from "../../services/projects/actions";
// import { getFeatureUnavailable, getFeatureMarket, getFeatureList, postFeatureStart, postFeatureMarket, deleteFeatureMarket, deleteFeatureStart, putFeatureStop, putFeatureEnable, getUnavailableFeature_team, getAvailableFeature_team, getPermissions_featureList_team, addFeature_team, startFeature_team, deleteFeature_team, stopFeature_team } from '../../services/settings/action';
import { teamsDashboard } from "../../services/dashboard/actions";
import {
    getFeatureUnavailable,
    getFeatureMarket,
    postScreenshotTime,
    getFeatureList,
    getOverideFeatureList,
    getScreenshotTiming,
    postScreenshotTiming,
    patchScreenshotTiming,
    postFeatureStart,
    postFeatureMarket,
    deleteFeatureMarket,
    deleteFeatureStart,
    putFeatureStop,
    putFeatureEnable,
    getUnavailableFeature_team,
    getAvailableFeature_team,
    getPermissions_featureList_team,
    addFeature_team,
    startFeature_team,
    deleteFeature_team,
    stopFeature_team,
    stopCustomFeat_team,
    startCustomFeat_team,
    useravailablefeatures,
    userunavailablefeatures,
    usercustompermissions,
} from "../../services/settings/action";
import { toast } from "react-toastify";
import SelectField from "../../Components/SelectField";

import moment from "moment";
import Global_permisson_tab from "./Permission_sub_tab/Global_permisson_tab";
import Teams_sub_tab from "./Permission_sub_tab/Teams_sub_tab";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const minuteselect = [
    {
        value: "1",
        minute: "1",
    },
    {
        value: "2",
        minute: "2",
    },
    {
        value: "3",
        minute: "3",
    },
    {
        value: "4",
        minute: "4",
    },
    {
        value: "5",
        minute: "5",
    },
    {
        value: "6",
        minute: "6",
    },
    {
        value: "7",
        minute: "7",
    },
    {
        value: "8",
        minute: "8",
    },
    {
        value: "9",
        minute: "9",
    },
    {
        value: "10",
        minute: "10",
    },
];

class PermissionsTab extends Component {
    constructor(props) {
        super(props);
        this.DefaultClick = this.DefaultClick.bind(this);
        this.state = {
            selectedTeam_FeaturesList: [],
            selectedTeam_unavailableFeatures: [],
            selectedTeam_availableFeatures: [],
            selectedTeam_uuid: "",
            selectedTeam_overrideFeaturesList: [],
            PermissionSubSelectedTab: "global-permission",
            TeamPermissionSubSelectedTab: "teamscustomPermissions",
            UserPermissionSubSelectedTab: "userscustomPermissions",
            teamName: "",
            employee: "",
            useravailablefeatures: [],
            userunavailablefeatures: [],
            usercustompermissions: [],
            userOverridePermissions: [],
            globalScreenshotTime: "",
            teamScreenshotTime: "",
            userScreenshotTime: "",
            screenshotFeatureId: "",
            currentURL: "",
            clicked: false,
        };
      ;
       setTimeout( ()=>{
     this.DefaultClick();
       },1000)
    }
   
    DefaultClick(){
            var params = "";
            var statusParams = ""
            const features =  this.props.features;
            const listOfFeatures = features && features.featureList;
            if (
                listOfFeatures !== undefined &&
                listOfFeatures.features !== undefined &&
                listOfFeatures.features.length !== 0
            ) {
                listOfFeatures.features?.map((feature, idx) => {
                    if (idx == listOfFeatures.features.length - 1) {
                        params += feature.featureid != undefined ? feature.featureid : feature.id;
                        statusParams += feature.status + "";
                    } else {
                        params +=
                            (feature.featureid != undefined ? feature.featureid : feature.id) + ",";
                        statusParams += feature.status + ",";
                    }
                });
            }
     this.props.getOverideFeatureList(params + "&status=" + statusParams, "Org")
            this.setState({ clicked: !this.state.clicked });
        } 

    HandleSelectTab(e) {
        if (e === "global-permission") {
            var params = "";
            var statusParams = "";
            const features = this.props.features;
            const listOfFeatures = features && features.featureList;
            if (
                listOfFeatures !== undefined &&
                listOfFeatures.features !== undefined &&
                listOfFeatures.features.length !== 0
            ) {
                listOfFeatures.features.map((feature, idx) => {
                    if (feature.featureid && feature.featureid === 6) {
                        this.setState({ screenshotFeatureId: feature.id });
                    }
                    if (idx == listOfFeatures.features.length - 1) {
                        params += feature.featureid != undefined ? feature.featureid : feature.id;
                        statusParams += feature.status + "";
                    } else {
                        params +=
                            (feature.featureid != undefined ? feature.featureid : feature.id) + ",";
                        statusParams += feature.status + ",";
                    }
                });
            } 
            this.props.getOverideFeatureList(params + "&status=" + statusParams, "Org").then(() => {
                this.setState({ PermissionSubSelectedTab: e });
                setTimeout(() => {
                    window.history.pushState(
                        {},
                        null,
                        `/settings/permissions/${this.state.PermissionSubSelectedTab}`
                    );
                }, 200);
            });
            this.props.getScreenshotTiming("Org").then((res) => {
                this.setState({ globalScreenshotTime: res.value.data });
            });
        } else {
            this.setState({ PermissionSubSelectedTab: e });
            setTimeout(() => {
                window.history.pushState(
                    {},
                    null,
                    `/settings/permissions/${this.state.PermissionSubSelectedTab}`
                );
            }, 200);
        }
    };

    HandleMobileSelectTab = (e) => {
        if (e.target.value === "global-permission") {
            const features = this.props.features;
            const listOfFeatures = features && features.featureList;
            if (
                listOfFeatures !== undefined &&
                listOfFeatures.features !== undefined &&
                listOfFeatures.features.length !== 0
            ) {
                var params = "";
                var statusParams = "";
                listOfFeatures.features.map((feature, idx) => {
                    if (feature.featureid && feature.featureid === 6) {
                        this.setState({ screenshotFeatureId: feature.id });
                    }
                    if (idx == listOfFeatures.features.length - 1) {
                        params += feature.featureid != undefined ? feature.featureid : feature.id;
                        statusParams += feature.status + "";
                    } else {
                        params +=
                            (feature.featureid != undefined ? feature.featureid : feature.id) + ",";
                        statusParams += feature.status + ",";
                    }
                });
                this.props
                    .getOverideFeatureList(params + "&status=" + statusParams, "Org")
                    .then(() => {
                        this.setState({ PermissionSubSelectedTab: e });
                        setTimeout(() => {
                            window.history.pushState(
                                {},
                                null,
                                `/settings/permissions/${this.state.PermissionSubSelectedTab}`
                            );
                        }, 200);
                    });

                this.props.getScreenshotTiming("Org").then((res) => {
                    this.setState({ globalScreenshotTime: res.value.data });
                });
            }
        } else {
            this.setState({ PermissionSubSelectedTab: e.target.value });
            setTimeout(() => {
                window.history.pushState(
                    {},
                    null,
                    `/settings/permissions/${this.state.PermissionSubSelectedTab}`
                );
            }, 200);
        }
    };

    HandleTeamPermissionSelectTab = (e) => {
        this.setState({ TeamPermissionSubSelectedTab: e });
    };

    HandleTeamPermissionMobileSelectTab = (e) => {
        this.setState({ TeamPermissionSubSelectedTab: e.target.value });
    };

    HandleUserPermissionSelectTab = (e) => {
        this.setState({ UserPermissionSubSelectedTab: e });
    };

    HandleUserPermissionMobileSelectTab = (e) => {
        this.setState({ UserPermissionSubSelectedTab: e.target.value });
    };

    componentDidMount() {
        window.addEventListener("popstate", (ev) => {
            this.setState({
                selectedTeam_uuid: "",
                employee: "",
            });
        });
        const url = window.location.pathname;
        setTimeout(() => {
            this.setState({ currentURL: this.props.pathname });
        }, 100);

        setTimeout(() => {
            if (
                this.state.currentURL ==
                    "/settings/permissions/teams-permission" ||
                this.state.currentURL.split("/").length > 4
            ) {
                this.setState({ PermissionSubSelectedTab: "teams-permission" });
            } else if (
                this.state.currentURL ==
                "/settings/permissions/users-permission"
            ) {
                this.setState({ PermissionSubSelectedTab: "users-permission" });
            } else {
                const features = this.props.features;
                const listOfFeatures = features && features.featureList;
                if (
                    listOfFeatures !== undefined &&
                    listOfFeatures.features !== undefined &&
                    listOfFeatures.features.length !== 0
                ) {
                    var params = "";
                    var statusParams = "";
                    listOfFeatures.features.map((feature, idx) => {
                        if (feature.featureid && feature.featureid === 6) {
                            this.setState({ screenshotFeatureId: feature.id });
                        }
                        if (idx == listOfFeatures.features.length - 1) {
                            params +=
                                feature.featureid != undefined
                                    ? feature.featureid
                                    : feature.id;
                            statusParams += feature.status + "";
                        } else {
                            params +=
                                (feature.featureid != undefined
                                    ? feature.featureid
                                    : feature.id) + ",";
                            statusParams += feature.status + ",";
                        }
                    });
                    this.props
                        .getOverideFeatureList(
                            params + "&status=" + statusParams,
                            "Org"
                        )
                        .then(() => {
                            this.setState({
                                PermissionSubSelectedTab: "global-permission",
                            });
                        });

                    this.props.getScreenshotTiming("Org").then((res) => {
                        this.setState({ globalScreenshotTime: res.value.data });
                    });
                } else {
                    this.setState({
                        PermissionSubSelectedTab: "global-permission",
                    });
                }
            }
            window.history.pushState(
                {},
                null,
                `/settings/permissions/${this.state.PermissionSubSelectedTab}`
            );
        }, 300);

        const org_uuid = localStorage.getItem("org_uuid");
        this.props.FetchactiveTeams(org_uuid);
        this.props.fetchactiveuserslist();
        this.props.getFeatureUnavailable();
        this.props.fetchallEmployees();
        this.props.getFeatureMarket();
        this.props.getFeatureList();
        this.props.teamsDashboard(
            moment(this.state.startDate).format("YYYY-MM-DD")
        );
    }

    onSelectTeamChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
                selectedTeam_uuid: e.target.value,
            },
            () => {
                if (e.target.value === "") {
                    setTimeout(() => {
                        window.history.replaceState(
                            {},
                            null,
                            `/settings/permissions/${this.state.PermissionSubSelectedTab}`
                        );
                    }, 200);
                    this.setState({
                        selectedTeam_FeaturesList: [],
                        selectedTeam_unavailableFeatures: [],
                        selectedTeam_availableFeatures: [],
                        selectedTeam_uuid: "",
                    });
                } else {
                    this.getAllFeatureList_team();
                    let currentTeam = this.props.teams.ActiveTeam.info.filter(
                        (t) => t.team.team_uuid == e.target.value
                    );
                    setTimeout(() => {
                        let urlSplit = window.location.href.split("/");
                        if (urlSplit.length > 6) {
                            window.history.replaceState(
                                {},
                                null,
                                `/settings/permissions/${this.state.PermissionSubSelectedTab}/${currentTeam[0].team.name}`
                            );
                        } else {
                            window.history.pushState(
                                {},
                                null,
                                `/settings/permissions/${this.state.PermissionSubSelectedTab}/${currentTeam[0].team.name}`
                            );
                        }
                    }, 200);
                }
            }
        );
    };

    onSelectEmployeeChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
                screenshotFeatureId: "",
                userScreenshotTime: "",
            },
            () => {
                if (e.target.name === "employee") {
                    let user_id = this.state.employee;

                    let currentUser = this.props.projects.AllEmployees.filter(
                        (e1) => e1.user_id == e.target.value
                    );

                    setTimeout(() => {
                        let urlSplit = window.location.href.split("/");
                        if (urlSplit.length > 6) {
                            window.history.replaceState(
                                {},
                                null,
                                `/settings/permissions/${
                                    this.state.PermissionSubSelectedTab
                                }/${
                                    currentUser[0].user_first_name +
                                    currentUser[0].user_last_name
                                }`
                            );
                        } else {
                            window.history.pushState(
                                {},
                                null,
                                `/settings/permissions/${
                                    this.state.PermissionSubSelectedTab
                                }/${
                                    currentUser[0].user_first_name +
                                    currentUser[0].user_last_name
                                }`
                            );
                        }
                    }, 200);
                    this.props.useravailablefeatures(user_id).then((res) => {
                        this.setState({
                            useravailablefeatures: res.value.data.feature,
                        });
                    });
                    this.props.userunavailablefeatures(user_id).then((res) => {
                        this.setState({
                            userunavailablefeatures: res.value.data.feature,
                        });
                    });
                    this.props.usercustompermissions(user_id).then((res) => {
                        this.setState(
                            {
                                usercustompermissions: res.value.data.features,
                            },
                            () => {
                                const listOfFeatures = res.value.data.features;
                                if (
                                    listOfFeatures !== undefined &&
                                    listOfFeatures.length !== 0
                                ) {
                                    var params = "";
                                    var statusParams = "";
                                    listOfFeatures.map((feature, idx) => {
                                        if (
                                            feature.featureid &&
                                            feature.featureid === 6
                                        ) {
                                            this.setState({
                                                screenshotFeatureId: feature.id,
                                            });
                                        }
                                        if (idx == listOfFeatures.length - 1) {
                                            params +=
                                                feature.featureid != undefined
                                                    ? feature.featureid
                                                    : feature.id;
                                            statusParams += feature.status + "";
                                        } else {
                                            params +=
                                                (feature.featureid != undefined
                                                    ? feature.featureid
                                                    : feature.id) + ",";
                                            statusParams +=
                                                feature.status + ",";
                                        }
                                    });
                                    this.props
                                        .getOverideFeatureList(
                                            params + "&status=" + statusParams,
                                            `User&user_uuid=${user_id}`
                                        )
                                        .then((res) => {
                                            if (res.value.status === 200) {
                                                this.setState({
                                                    userOverridePermissions:
                                                        res.value.data.Status,
                                                });
                                            }
                                        });
                                    this.props
                                        .getScreenshotTiming(
                                            "User&user_uuid=" +
                                                this.state.employee
                                        )
                                        .then((res) => {
                                            this.setState({
                                                userScreenshotTime:
                                                    res.value.data,
                                            });
                                        });
                                }
                            }
                        );
                    });
                }
            }
        );
    };

    getAllFeatureList_team = () => {
        this.props
            .getPermissions_featureList_team(
                "Team",
                this.state.selectedTeam_uuid
            )
            .then((res) => {
                if (res.value.status === 200) {
                    const listOfFeatures = res.value.data.features;
                    if (
                        listOfFeatures !== undefined &&
                        listOfFeatures.length !== 0
                    ) {
                        var params = "";
                        var statusParams = "";
                        listOfFeatures.map((feature, idx) => {
                            if (feature.featureid && feature.featureid === 6) {
                                this.setState({
                                    screenshotFeatureId: feature.id,
                                });
                            }
                            if (idx == listOfFeatures.length - 1) {
                                params +=
                                    feature.featureid != undefined
                                        ? feature.featureid
                                        : feature.id;
                                statusParams += feature.status + "";
                            } else {
                                params +=
                                    (feature.featureid != undefined
                                        ? feature.featureid
                                        : feature.id) + ",";
                                statusParams += feature.status + ",";
                            }
                        });
                        this.props
                            .getOverideFeatureList(
                                params + "&status=" + statusParams,
                                `Team&team_uuid=${this.state.selectedTeam_uuid}`
                            )
                            .then((res) => {
                                if (res.value.status === 200) {
                                    this.setState({
                                        selectedTeam_overrideFeaturesList:
                                            res.value.data.Status,
                                    });
                                }
                            });
                    }
                    this.setState({
                        selectedTeam_FeaturesList: res.value.data.features,
                    });
                }
            });

        this.props
            .getUnavailableFeature_team("Team", this.state.selectedTeam_uuid)
            .then((res) => {
                if (res.value.status === 200) {
                    this.setState({
                        selectedTeam_unavailableFeatures:
                            res.value.data.feature,
                    });
                }
            });

        this.props
            .getAvailableFeature_team("Team", this.state.selectedTeam_uuid)
            .then((res) => {
                if (res.value.status === 200) {
                    this.setState({
                        selectedTeam_availableFeatures: res.value.data.feature,
                    });
                }
            });
        
        this.props
            .getScreenshotTiming(
                "Team&team_uuid=" + this.state.selectedTeam_uuid
            )
            .then((res) => {
                this.setState({ teamScreenshotTime: res.value.data });
            });
    };
    handleScreenShotitme=(e)=>{
        const data = new FormData();
        data.append("storage", e.target.value);
        this.props.postScreenshotTime(data)
       }
    handleScreenShotTimeChange = (event) => {
        if (
            this.state.globalScreenshotTime != "" &&
            this.state.globalScreenshotTime.status === false
        ) {
            let data = {
                feature_id: this.state.screenshotFeatureId,
                type: "Org",
                user_uuid: localStorage.getItem("org_uuid"),
                time: event.target.value,
            };
            this.props.postScreenshotTiming(data).then((res) => {
                if (res.value.status === 200) {
                    this.props.getScreenshotTiming("Org").then((res) => {
                        this.setState({ globalScreenshotTime: res.value.data });
                    });
                }
            });
        } else if (this.state.globalScreenshotTime != "") {
            this.props
                .patchScreenshotTiming(
                    this.state.globalScreenshotTime.id +
                        "&time=" +
                        event.target.value
                )
                .then((res) => {
                    if (res.value.status === 200) {
                        this.setState({
                            globalScreenshotTime: {
                                data: event.target.value,
                                id: this.state.globalScreenshotTime.id,
                                status: true,
                            },
                        });
                    }
                });
        }
    };
    
    handleScreenShotTimeChange_Team = (event) => {
        if (
            this.state.teamScreenshotTime != "" &&
            this.state.teamScreenshotTime.status === false
        ) {
            let data = {
                feature_id: this.state.screenshotFeatureId,
                type: "Team",
                team_uuid: this.state.selectedTeam_uuid,
                time: event.target.value,
            };
            this.props.postScreenshotTiming(data).then((res) => {
                if (res.value.status === 200) {
                    this.props
                        .getScreenshotTiming(
                            "Team&team_uuid=" + this.state.selectedTeam_uuid
                        )
                        .then((res) => {
                            this.setState({
                                teamScreenshotTime: res.value.data,
                            });
                        });
                }
            });
        } else if (this.state.teamScreenshotTime != "") {
            this.props
                .patchScreenshotTiming(
                    this.state.teamScreenshotTime.id +
                        "&time=" +
                        event.target.value
                )
                .then((res) => {
                    if (res.value.status === 200) {
                        this.setState({
                            teamScreenshotTime: {
                                data: event.target.value,
                                id: this.state.teamScreenshotTime.id,
                                status: true,
                            },
                        });
                    }
                });
        }
    };
    handleScreenShotTimeChange_User = (event) => {
        if (
            this.state.userScreenshotTime != "" &&
            this.state.userScreenshotTime.status === false
        ) {
            let data = {
                feature_id: this.state.screenshotFeatureId,
                type: "User",
                user_uuid: this.state.employee,
                time: event.target.value,
            };
            this.props.postScreenshotTiming(data).then((res) => {
                if (res.value.status === 200) {
                    this.props
                        .getScreenshotTiming(
                            "User&user_uuid=" + this.state.employee
                        )
                        .then((res) => {
                            this.setState({
                                userScreenshotTime: res.value.data,
                            });
                        });
                }
            });
        } else if (this.state.userScreenshotTime != "") {
            this.props
                .patchScreenshotTiming(
                    this.state.userScreenshotTime.id +
                        "&time=" +
                        event.target.value
                )
                .then((res) => {
                    if (res.value.status === 200) {
                        this.setState({
                            userScreenshotTime: {
                                data: event.target.value,
                                id: this.state.userScreenshotTime.id,
                                status: true,
                            },
                        });
                    }
                });
        }
    };
    handleAddFeature = (id) => {
        let data = {
            featureid: id,
            type: "User",
            userid: this.state.employee,
        };
        this.props.postFeatureMarket(data).then((res) => {
            toast.success("Feature Added Successfully");
            let user_id = this.state.employee;
            this.props.useravailablefeatures(user_id).then((res) => {
                this.setState({
                    useravailablefeatures: res.value.data.feature,
                });
            });
            this.props.userunavailablefeatures(user_id).then((res) => {
                this.setState({
                    userunavailablefeatures: res.value.data.feature,
                });
            });
            this.props.usercustompermissions(user_id).then((res) => {
                this.setState(
                    {
                        usercustompermissions: res.value.data.features,
                    },
                    () => {
                        const listOfFeatures = res.value.data.features;
                        if (
                            listOfFeatures !== undefined &&
                            listOfFeatures.length !== 0
                        ) {
                            var params = "";
                            var statusParams = "";
                            listOfFeatures.map((feature, idx) => {
                                if (
                                    feature.featureid &&
                                    feature.featureid === 6
                                ) {
                                    this.setState({
                                        screenshotFeatureId: feature.id,
                                    });
                                }
                                if (idx == listOfFeatures.length - 1) {
                                    params +=
                                        feature.featureid != undefined
                                            ? feature.featureid
                                            : feature.id;
                                    statusParams += feature.status + "";
                                } else {
                                    params +=
                                        (feature.featureid != undefined
                                            ? feature.featureid
                                            : feature.id) + ",";
                                    statusParams += feature.status + ",";
                                }
                            });
                            this.props
                                .getOverideFeatureList(
                                    params + "&status=" + statusParams,
                                    `User&user_uuid=${user_id}`
                                )
                                .then((res) => {
                                    if (res.value.status === 200) {
                                        this.setState({
                                            userOverridePermissions:
                                                res.value.data.Status,
                                        });
                                    }
                                });
                            this.props
                                .getScreenshotTiming(
                                    "User&user_uuid=" + this.state.employee
                                )
                                .then((res) => {
                                    this.setState({
                                        userScreenshotTime: res.value.data,
                                    });
                                });
                        }
                    }
                );
            });
        });
    };

    handleUserDisableFeature = (id, name) => {
        let data = {
            featureid: id,
        };
        this.props.deleteFeatureMarket(data).then((res) => {
            toast.success("Feature Disabled Successfully");
            let user_id = this.state.employee;
            this.props.useravailablefeatures(user_id).then((res) => {
                this.setState({
                    useravailablefeatures: res.value.data.feature,
                });
            });
            this.props.userunavailablefeatures(user_id).then((res) => {
                this.setState({
                    userunavailablefeatures: res.value.data.feature,
                });
            });
            this.props.usercustompermissions(user_id).then((res) => {
                this.setState(
                    {
                        usercustompermissions: res.value.data.features,
                    },
                    () => {
                        const listOfFeatures = res.value.data.features;
                        if (
                            listOfFeatures !== undefined &&
                            listOfFeatures.length !== 0
                        ) {
                            var params = "";
                            var statusParams = "";
                            listOfFeatures.map((feature, idx) => {
                                if (
                                    feature.featureid &&
                                    feature.featureid === 6
                                ) {
                                    this.setState({
                                        screenshotFeatureId: feature.id,
                                    });
                                }
                                if (idx == listOfFeatures.length - 1) {
                                    params +=
                                        feature.featureid != undefined
                                            ? feature.featureid
                                            : feature.id;
                                    statusParams += feature.status + "";
                                } else {
                                    params +=
                                        (feature.featureid != undefined
                                            ? feature.featureid
                                            : feature.id) + ",";
                                    statusParams += feature.status + ",";
                                }
                            });
                            this.props
                                .getOverideFeatureList(
                                    params + "&status=" + statusParams,
                                    `User&user_uuid=${user_id}`
                                )
                                .then((res) => {
                                    if (res.value.status === 200) {
                                        this.setState({
                                            userOverridePermissions:
                                                res.value.data.Status,
                                        });
                                    }
                                });
                            this.props
                                .getScreenshotTiming(
                                    "User&user_uuid=" + this.state.employee
                                )
                                .then((res) => {
                                    this.setState({
                                        userScreenshotTime: res.value.data,
                                    });
                                });
                        }
                    }
                );
            });
        });
    };
    getGlobalOverridePermissions = (featuresList) => {
        const listOfFeatures = featuresList.features;
        if (listOfFeatures !== undefined && listOfFeatures.length !== 0) {
            var params = "";
            var statusParams = "";
            listOfFeatures.map((feature, idx) => {
                if (feature.featureid && feature.featureid === 6) {
                    this.setState({ screenshotFeatureId: feature.id });
                }
                if (idx == listOfFeatures.length - 1) {
                    params +=
                        feature.featureid != undefined
                            ? feature.featureid
                            : feature.id;
                    statusParams += feature.status + "";
                } else {
                    params +=
                        (feature.featureid != undefined
                            ? feature.featureid
                            : feature.id) + ",";
                    statusParams += feature.status + ",";
                }
            });
            this.props.getOverideFeatureList(
                params + "&status=" + statusParams,
                "Org"
            );
            this.props.getScreenshotTiming("Org").then((res) => {
                this.setState({ globalScreenshotTime: res.value.data });
            });
        }
    };
    enableDisable_customPermission = async (id, featureStatus, featureData) => {
        if (!featureData) {
            const obj = {
                type: this.state.employee != "" ? "User" : "Org",
                feature: id,
                usersid: this.state.employee,
            };
            const response = await this.props.postFeatureStart(
                JSON.stringify(obj)
            );
            if (
                response.action.payload.status !== 200 &&
                response.action.payload.status !== 400
            ) {
                return;
            }
            this.props.getFeatureList().then((res) => {
                let user_id = this.state.employee;
                if (user_id == "") {
                    this.getGlobalOverridePermissions(res.value.data);
                } else {
                    this.props.useravailablefeatures(user_id).then((res) => {
                        this.setState({
                            useravailablefeatures: res.value.data.feature,
                        });
                    });
                    this.props.userunavailablefeatures(user_id).then((res) => {
                        this.setState({
                            userunavailablefeatures: res.value.data.feature,
                        });
                    });
                    this.props.usercustompermissions(user_id).then((res) => {
                        this.setState(
                            {
                                usercustompermissions: res.value.data.features,
                            },
                            () => {
                                const listOfFeatures = res.value.data.features;
                                if (
                                    listOfFeatures !== undefined &&
                                    listOfFeatures.length !== 0
                                ) {
                                    var params = "";
                                    var statusParams = "";
                                    listOfFeatures.map((feature, idx) => {
                                        if (
                                            feature.featureid &&
                                            feature.featureid === 6
                                        ) {
                                            this.setState({
                                                screenshotFeatureId: feature.id,
                                            });
                                        }
                                        if (idx == listOfFeatures.length - 1) {
                                            params +=
                                                feature.featureid != undefined
                                                    ? feature.featureid
                                                    : feature.id;
                                            statusParams += feature.status + "";
                                        } else {
                                            params +=
                                                (feature.featureid != undefined
                                                    ? feature.featureid
                                                    : feature.id) + ",";
                                            statusParams +=
                                                feature.status + ",";
                                        }
                                    });
                                    this.props
                                        .getOverideFeatureList(
                                            params + "&status=" + statusParams,
                                            `User&user_uuid=${user_id}`
                                        )
                                        .then((res) => {
                                            if (res.value.status === 200) {
                                                this.setState({
                                                    userOverridePermissions:
                                                        res.value.data.Status,
                                                });
                                            }
                                        });
                                    this.props
                                        .getScreenshotTiming(
                                            "User&user_uuid=" +
                                                this.state.employee
                                        )
                                        .then((res) => {
                                            this.setState({
                                                userScreenshotTime:
                                                    res.value.data,
                                            });
                                        });
                                }
                            }
                        );
                    });
                }
            });
        }
        if (featureStatus === true) {
            this.props.putFeatureStop(id).then((data) => {
                if (data.action.payload.status === 200) {
                    toast.success("Feature Disabled Successfully");
                    this.props.getFeatureList().then((res) => {
                        let user_id = this.state.employee;
                        if (user_id == "") {
                            this.getGlobalOverridePermissions(res.value.data);
                        } else {
                            this.props
                                .useravailablefeatures(user_id)
                                .then((res) => {
                                    this.setState({
                                        useravailablefeatures:
                                            res.value.data.feature,
                                    });
                                });
                            this.props
                                .userunavailablefeatures(user_id)
                                .then((res) => {
                                    this.setState({
                                        userunavailablefeatures:
                                            res.value.data.feature,
                                    });
                                });
                            this.props
                                .usercustompermissions(user_id)
                                .then((res) => {
                                    this.setState(
                                        {
                                            usercustompermissions:
                                                res.value.data.features,
                                        },
                                        () => {
                                            const listOfFeatures =
                                                res.value.data.features;
                                            if (
                                                listOfFeatures !== undefined &&
                                                listOfFeatures.length !== 0
                                            ) {
                                                var params = "";
                                                var statusParams = "";
                                                listOfFeatures.map(
                                                    (feature, idx) => {
                                                        if (
                                                            feature.featureid &&
                                                            feature.featureid ===
                                                                6
                                                        ) {
                                                            this.setState({
                                                                screenshotFeatureId:
                                                                    feature.id,
                                                            });
                                                        }
                                                        if (
                                                            idx ==
                                                            listOfFeatures.length -
                                                                1
                                                        ) {
                                                            params +=
                                                                feature.featureid !=
                                                                undefined
                                                                    ? feature.featureid
                                                                    : feature.id;
                                                            statusParams +=
                                                                feature.status +
                                                                "";
                                                        } else {
                                                            params +=
                                                                (feature.featureid !=
                                                                undefined
                                                                    ? feature.featureid
                                                                    : feature.id) +
                                                                ",";
                                                            statusParams +=
                                                                feature.status +
                                                                ",";
                                                        }
                                                    }
                                                );
                                                this.props
                                                    .getOverideFeatureList(
                                                        params +
                                                            "&status=" +
                                                            statusParams,
                                                        `User&user_uuid=${user_id}`
                                                    )
                                                    .then((res) => {
                                                        if (
                                                            res.value.status ===
                                                            200
                                                        ) {
                                                            this.setState({
                                                                userOverridePermissions:
                                                                    res.value
                                                                        .data
                                                                        .Status,
                                                            });
                                                        }
                                                    });
                                                this.props
                                                    .getScreenshotTiming(
                                                        "User&user_uuid=" +
                                                            this.state.employee
                                                    )
                                                    .then((res) => {
                                                        this.setState({
                                                            userScreenshotTime:
                                                                res.value.data,
                                                        });
                                                    });
                                            }
                                        }
                                    );
                                });
                        }
                    });
                } else {
                    toast.error("Something went wrong");
                }
            });

            // const response = await this.props.putFeatureStop(id);
            // console.log(response.action.payload.status);
        } else {
            this.props.putFeatureEnable(id).then((data) => {
                if (data.action.payload.status === 200) {
                    toast.success("Feature Enabled Successfully");
                    this.props.getFeatureList().then((res) => {
                        let user_id = this.state.employee;
                        if (user_id == "") {
                            this.getGlobalOverridePermissions(res.value.data);
                        } else {
                            this.props
                                .useravailablefeatures(user_id)
                                .then((res) => {
                                    this.setState({
                                        useravailablefeatures:
                                            res.value.data.feature,
                                    });
                                });
                            this.props
                                .userunavailablefeatures(user_id)
                                .then((res) => {
                                    this.setState({
                                        userunavailablefeatures:
                                            res.value.data.feature,
                                    });
                                });
                            this.props
                                .usercustompermissions(user_id)
                                .then((res) => {
                                    this.setState(
                                        {
                                            usercustompermissions:
                                                res.value.data.features,
                                        },
                                        () => {
                                            const listOfFeatures =
                                                res.value.data.features;
                                            if (
                                                listOfFeatures !== undefined &&
                                                listOfFeatures.length !== 0
                                            ) {
                                                var params = "";
                                                var statusParams = "";
                                                listOfFeatures.map(
                                                    (feature, idx) => {
                                                        if (
                                                            feature.featureid &&
                                                            feature.featureid ===
                                                                6
                                                        ) {
                                                            this.setState({
                                                                screenshotFeatureId:
                                                                    feature.id,
                                                            });
                                                        }
                                                        if (
                                                            idx ==
                                                            listOfFeatures.length -
                                                                1
                                                        ) {
                                                            params +=
                                                                feature.featureid !=
                                                                undefined
                                                                    ? feature.featureid
                                                                    : feature.id;
                                                            statusParams +=
                                                                feature.status +
                                                                "";
                                                        } else {
                                                            params +=
                                                                (feature.featureid !=
                                                                undefined
                                                                    ? feature.featureid
                                                                    : feature.id) +
                                                                ",";
                                                            statusParams +=
                                                                feature.status +
                                                                ",";
                                                        }
                                                    }
                                                );
                                                this.props
                                                    .getOverideFeatureList(
                                                        params +
                                                            "&status=" +
                                                            statusParams,
                                                        `User&user_uuid=${user_id}`
                                                    )
                                                    .then((res) => {
                                                        if (
                                                            res.value.status ===
                                                            200
                                                        ) {
                                                            this.setState({
                                                                userOverridePermissions:
                                                                    res.value
                                                                        .data
                                                                        .Status,
                                                            });
                                                        }
                                                    });
                                                this.props
                                                    .getScreenshotTiming(
                                                        "User&user_uuid=" +
                                                            this.state.employee
                                                    )
                                                    .then((res) => {
                                                        this.setState({
                                                            userScreenshotTime:
                                                                res.value.data,
                                                        });
                                                    });
                                            }
                                        }
                                    );
                                });
                        }
                    });
                } else {
                    toast.error("Something went wrong");
                }
            });
        }
    };

    enableDisable_customPermission_Team = async (
        id,
        featureStatus,
        featureData
    ) => {
        if (!featureData) {
            const obj = {
                type: "Team",
                feature: id,
                teamid: this.state.selectedTeam_uuid,
            };
            const response = await this.props.postFeatureStart(
                JSON.stringify(obj)
            );
            if (
                response.action.payload.status !== 200 &&
                response.action.payload.status !== 400
            ) {
                return;
            }
            this.getAllFeatureList_team();
        }

        if (featureStatus === true) {
            this.props.stopCustomFeat_team(id).then((data) => {
                if (data.value.status === 200) {
                    toast.success("Feature Disabled Successfully");
                    this.getAllFeatureList_team();
                } else {
                    toast.error("Something went wrong");
                }
            });
        } else {
            this.props.startCustomFeat_team(id).then((data) => {
                if (data.value.status === 200) {
                    toast.success("Feature Enabled Successfully");
                    this.getAllFeatureList_team();
                } else {
                    toast.error("Something went wrong");
                }
            });
        }
    };

    render() {
        const { t } = this.props;
        const features = this.props.features;
        const listOfFeatures = features && features.featureList;
        let teams =
            this.props.teams &&
            this.props.teams.ActiveTeam &&
            this.props.teams.ActiveTeam.info;
        let employees = this.props.projects && this.props.projects.AllEmployees;
        let TeamData =
            teams &&
            teams.map((el) => ({
                name: el.team.name,
                id: el.team.team_uuid,
            }));
        let activeUsers = this.props.teams&&this.props.teams.ActiveUsers;
        let EmployeeData =
            employees &&
            employees.map((el) => ({
                name: el.user_first_name + " " + el.user_last_name,
                id: el.user_id,
            }));
        let timingArray =
            minuteselect &&
            minuteselect.map((el) => ({
                name: el.minute,
            }));

        const getTeamId = (teamName) => {
            if (teams !== undefined || teams.length !== 0) {
                for (let i = 0; i < teams.length; i++) {
                    if (teams[i].team.name === teamName) {
                        return (
                            teams[i].team.team_uuid +
                            "&" +
                            teams[i].team.id
                        );
                    }
                }
            }
        };
        const addStart_Feature_team = (id) => {
            const dataaFeature = {
                featureid: id,
                teamid: this.state.selectedTeam_uuid,
                type: "Team",
            };

            const dataaStart = {
                feature: id,
                teamid: this.state.selectedTeam_uuid,
                type: "Team",
                userid: "",
            };

            this.props.addFeature_team(dataaFeature).then((data) => {
                if (data.value.status === 200) {
                    // toast.success('Feature Added Successfully');
                    this.getAllFeatureList_team();
                } else {
                    toast.error("Something went wrong");
                }
            });

            this.props.startFeature_team(dataaStart).then((data) => {
                if (data.value.status === 200) {
                    toast.success("Feature Added Successfully");
                    this.getAllFeatureList_team();
                } else {
                    toast.error("Something went wrong");
                }
            });
        };

        const deleteStop_Feature_team = (id, featureName) => {
            const dataaFeature = {
                featureid: id,
            };

            let permission =
                this.state.selectedTeam_FeaturesList &&
                this.state.selectedTeam_FeaturesList.filter(
                    (item) => item.feature === featureName
                );
            let permission_id = permission && permission[0].id;

            this.props.deleteFeature_team(dataaFeature).then((data) => {
                if (data.value.status === 200) {
                    this.props.stopFeature_team(permission_id).then((data) => {
                        if (data.value.status === 200) {
                            toast.success("Feature Disabled Successfully");
                            this.getAllFeatureList_team();
                        } else {
                            toast.error("Something went wrong");
                        }
                    });
                } else {
                    toast.error("Something went wrong");
                }
            });
        };
        return (
            <>
                <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg my-6">
                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            {t("requestsModule.selectATab")}
                        </label>
                        <select
                            id="tabs"
                            name="tabs"
                            onChange={(e) => this.HandleMobileSelectTab(e)}
                            className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                            defaultValue={this.state.PermissionSubSelectedTab}>
                            <option key="global-permission" value="global-permission">
                                {t("settingsTab.global")} {t("settingsTab.permissions")}
                            </option>
                            <option key="teams-permission" value="teams-permission">
                                {t("teams")} {t("settingsTab.permission")}
                            </option>
                            <option key="users-permission" value="users-permission">
                                {t("user")} {t("settingsTab.permission")}
                            </option>
                            {/* <option key="Users-Excluded" value="Users-Excluded">Users Excluded</option> */}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <div className="-mb-px flex space-x-8" aria-label="Tabs">
                            <div
                                key="Teams"
                                onClick={() => this.HandleSelectTab("global-permission")}
                                className={classNames(
                                    this.state.PermissionSubSelectedTab === "global-permission"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.PermissionSubSelectedTab === "global-permission"
                                        ? "page"
                                        : undefined
                                }>
                                <span>
                                    {t("settingsTab.global")} {t("settingsTab.permissions")}
                                </span>
                            </div>

                            <div
                                key="Users"
                                onClick={() => this.HandleSelectTab("teams-permission")}
                                className={classNames(
                                    this.state.PermissionSubSelectedTab === "teams-permission"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "teams-permission"
                                        ? "page"
                                        : undefined
                                }>
                                <span>
                                    {t("teams")} {t("settingsTab.permission")}
                                </span>
                            </div>

                            <div
                                key="Workstations"
                                onClick={() => this.HandleSelectTab("users-permission")}
                                className={classNames(
                                    this.state.PermissionSubSelectedTab === "users-permission"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab == "users-permission"
                                        ? "page"
                                        : undefined
                                }>
                                <span>
                                    {t("user")} {t("settingsTab.permission")}
                                </span>
                            </div>
                            {/* <a key="Observers" onClick={() => this.HandleSelectTab("Users-Excluded")}
                                className={classNames(
                                    this.state.PermissionSubSelectedTab == "Users-Excluded"
                                        ? 'border-primary text-primary'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                    'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer'
                                )}
                                aria-current={this.state.SelectedTab == "Users-Excluded" ? 'page' : undefined}
                            >
                                <span>Users Excluded</span>
                            </a> */}
                        </div>
                    </div>
                </div>
                {this.state.PermissionSubSelectedTab === "global-permission" ? (
                    // <div className="bg-white px-2 py-1 pb-4 border-b border-gray-200 sm:px-6 rounded-lg my-6">
                    //     <h1 className='text-primary my-5 font-semibold text-lg'>Custom Permissions</h1>
                    //     <div>
                    //         <div className="mt-8 flex flex-col">
                    //             <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    //                 <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    //                     <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    //                         <table className="min-w-full divide-y divide-gray-300">
                    //                             <thead className="bg-gray-50">
                    //                                 <tr>
                    //                                     <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    //                                         Feature Name
                    //                                     </th>
                    //                                     <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    //                                         Description
                    //                                     </th>
                    //                                     <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    //                                         Status
                    //                                     </th>
                    //                                 </tr>
                    //                             </thead>
                    //                             <tbody className="divide-y divide-gray-200 bg-white">
                    //                                 {listOfFeatures == "" ? <tr><td>Loading..</td></tr> :
                    //                                     listOfFeatures && listOfFeatures.features.map((feature, idx) => (
                    //                                         <tr key={idx}>
                    //                                             <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{feature.feature}</td>
                    //                                             <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{feature.description}</td>
                    //                                             <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><ToggleButton checked={feature.status} onChange={() => { this.enableDisable_customPermission(feature.id, feature.status) }} /></td>
                    //                                         </tr>
                    //                                     ))}
                    //                             </tbody>
                    //                         </table>
                    //                     </div>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div>
                    <Global_permisson_tab
                        enableDisable_customPermission={this.enableDisable_customPermission}
                        timingArray={timingArray}
                        activeTeams={teams}
                        activeUsers={activeUsers}
                        screenshotTiming={this.state.globalScreenshotTime}
                        handleTimeChange={this.handleScreenShotitme}
                        listOfFeatures={listOfFeatures}
                        globalOverideFeaturesList={this.globalOverideFeaturesList}
                    />
                ) : null}
                {this.state.PermissionSubSelectedTab === "teams-permission" ? (
                    <Teams_sub_tab
                        HandleTeamPermissionMobileSelectTab={
                            this.HandleTeamPermissionMobileSelectTab
                        }
                        HandleTeamPermissionSelectTab={this.HandleTeamPermissionSelectTab}
                        TeamPermissionSubSelectedTab={this.state.TeamPermissionSubSelectedTab}
                        onSelectTeamChange={this.onSelectTeamChange}
                        overidedBy={this.state.selectedTeam_overrideFeaturesList}
                        selectedTeam_uuid={this.state.selectedTeam_uuid}
                        selectedTeam_FeaturesList={this.state.selectedTeam_FeaturesList}
                        timingArray={timingArray}
                        activeUsers={activeUsers}
                        screenshotTiming={this.state.teamScreenshotTime}
                        handleTimeChange={this.handleScreenShotTimeChange_Team}
                        enableDisable_customPermission_Team={
                            this.enableDisable_customPermission_Team
                        }
                        PermissionSubSelectedTab={this.state.PermissionSubSelectedTab}
                        TeamData={TeamData}
                    />
                ) : null}

                {this.state.PermissionSubSelectedTab === "users-permission" ? (
                    <div className="bg-white px-2 py-1 pb-4 border-b border-gray-200 sm:px-6 rounded-lg my-6">
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                                {t("requestsModule.selectATab")}
                            </label>
                            <select
                                id="tabs"
                                name="tabs"
                                onChange={(e) => this.HandleUserPermissionMobileSelectTab(e)}
                                className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                                defaultValue={this.state.UserPermissionSubSelectedTab}>
                                <option key="user-Permissions" value="userscustomPermissions">
                                    {t("settingsTab.custom")} {t("settingsTab.permission")}
                                </option>
                                {/* <option key="user-Available-Features" value="User-Available-Features">Available features</option>
                                <option key="User-Unavailable-Features" value="User-Unavailable-Features">Unavailable features</option> */}
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                <a
                                    key="Teams"
                                    onClick={() =>
                                        this.HandleUserPermissionSelectTab("userscustomPermissions")
                                    }
                                    className={classNames(
                                        this.state.UserPermissionSubSelectedTab ===
                                            "userscustomPermissions"
                                            ? "border-primary text-primary"
                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                        "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                    )}
                                    aria-current={
                                        this.state.PermissionSubSelectedTab ===
                                        "userscustomPermissions"
                                            ? "page"
                                            : undefined
                                    }>
                                    <span>
                                        {t("settingsTab.custom")} {t("settingsTab.permission")}
                                    </span>
                                </a>

                                {/* <a key="Users" onClick={() => this.HandleUserPermissionSelectTab("User-Available-Features")}
                                    className={classNames(
                                        this.state.UserPermissionSubSelectedTab == "User-Available-Features"
                                            ? 'border-primary text-primary'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer'
                                    )}
                                    aria-current={this.state.UserPermissionSubSelectedTab == "User-Available-Features" ? 'page' : undefined}
                                >
                                    <span>Available features</span>
                                </a>

                                <a key="Workstations" onClick={() => this.HandleUserPermissionSelectTab("User-Unavailable-Features")}
                                    className={classNames(
                                        this.state.UserPermissionSubSelectedTab == "User-Unavailable-Features"
                                            ? 'border-primary text-primary'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer'
                                    )}
                                    aria-current={this.state.UserPermissionSubSelectedTab == "User-Unavailable-Features" ? 'page' : undefined}
                                >

                                    <span>Unavailable features</span>
                                </a> */}
                            </nav>
                        </div>

                        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                            <div className="mt-5">
                                <SelectField
                                    onSelectChange={(e) => this.onSelectEmployeeChange(e)}
                                    FieldName={t("select") + " " + t("employee")}
                                    name={"employee"}
                                    value={this.state.employee}
                                    arrayOfData={EmployeeData}
                                    mainclassName={"ml-4"}
                                    id={"employee"}></SelectField>
                            </div>
                            <div className="ml-4 mt-2 flex-shrink-0"></div>
                        </div>

                        {this.state.UserPermissionSubSelectedTab === "userscustomPermissions" ? (
                            <div>
                                <div className="mt-8 flex flex-col">
                                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                {t("settingsTab.feature")}{" "}
                                                                {t("name")}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                {t("description")}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                {t(
                                                                    "settingsTab.overriddenByGlobal"
                                                                )}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                {t("settingsTab.overriddenByteams")}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                {t("status")}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        {this.state.employee === "" ? (
                                                            <tr>
                                                                <td
                                                                    colSpan={4}
                                                                    className="p-4 text-md  text-textprimary text-center">
                                                                    {t("please")} {t("select")}{" "}
                                                                    {t("employee")}
                                                                </td>
                                                            </tr>
                                                        ) : this.state.usercustompermissions &&
                                                          this.state.usercustompermissions.length >
                                                              0 ? (
                                                            this.state.usercustompermissions &&
                                                            this.state.usercustompermissions.map(
                                                                (feature, idx) => (
                                                                    <tr key={idx}>
                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                            {feature.feature}
                                                                        </td>
                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                            {feature.description}
                                                                        </td>
                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                            {this.state
                                                                                .userOverridePermissions &&
                                                                            this.state
                                                                                .userOverridePermissions
                                                                                .length > 0 &&
                                                                            this.state
                                                                                .userOverridePermissions[
                                                                                idx
                                                                            ] &&
                                                                            this.state
                                                                                .userOverridePermissions[
                                                                                idx
                                                                            ].org.length > 0
                                                                                ? // ? this.state.userOverridePermissions[
                                                                                  //       idx
                                                                                  //   ].names.map(
                                                                                  //       (
                                                                                  //           title,
                                                                                  //           id
                                                                                  //       ) => {
                                                                                  //           if (
                                                                                  //               id ===
                                                                                  //               this
                                                                                  //                   .state
                                                                                  //                   .userOverridePermissions[
                                                                                  //                   idx
                                                                                  //               ]
                                                                                  //                   .names
                                                                                  //                   .length -
                                                                                  //                   1
                                                                                  //           ) {
                                                                                  //               if (
                                                                                  //                   title ===
                                                                                  //                   localStorage.getItem(
                                                                                  //                       "org_name"
                                                                                  //                   )
                                                                                  //               ) {
                                                                                  //                   return "Global";
                                                                                  //               }
                                                                                  //               return title;
                                                                                  //           } else {
                                                                                  //               if (
                                                                                  //                   title ===
                                                                                  //                   localStorage.getItem(
                                                                                  //                       "org_name"
                                                                                  //                   )
                                                                                  //               ) {
                                                                                  //                   return "Global, ";
                                                                                  //               }
                                                                                  //               return (
                                                                                  //                   title +
                                                                                  //                   ", "
                                                                                  //               );
                                                                                  //           }
                                                                                  //       }
                                                                                  //   )
                                                                                  "Global"
                                                                                : "None"}
                                                                        </td>
                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                            {this.state
                                                                                .userOverridePermissions &&
                                                                            this.state
                                                                                .userOverridePermissions
                                                                                .length > 0 &&
                                                                            this.state
                                                                                .userOverridePermissions[
                                                                                idx
                                                                            ] &&
                                                                            this.state
                                                                                .userOverridePermissions[
                                                                                idx
                                                                            ].teams.length > 0
                                                                                ? this.state.userOverridePermissions[
                                                                                      idx
                                                                                  ].teams.map(
                                                                                      (
                                                                                          title,
                                                                                          id
                                                                                      ) => {
                                                                                          if (
                                                                                              id ===
                                                                                              this
                                                                                                  .state
                                                                                                  .userOverridePermissions[
                                                                                                  idx
                                                                                              ]
                                                                                                  .teams
                                                                                                  .length -
                                                                                                  1
                                                                                          ) {
                                                                                              return (
                                                                                                  <Link
                                                                                                      to={`/team/Teams/${title}&${getTeamId(
                                                                                                          title
                                                                                                      )}`}>
                                                                                                      {
                                                                                                          title
                                                                                                      }
                                                                                                  </Link>
                                                                                              );
                                                                                          } else {
                                                                                              return (
                                                                                                  <Link
                                                                                                      to={`/team/Teams/${title}&${getTeamId(
                                                                                                          title
                                                                                                      )}`}>
                                                                                                      {title +
                                                                                                          ", "}
                                                                                                  </Link>
                                                                                              );
                                                                                          }
                                                                                      }
                                                                                  )
                                                                                : "None"}
                                                                        </td>
                                                                        <td className="whitespace-nowrap flex align-top px-3 py-4 text-sm text-gray-500">
                                                                            <p
                                                                                className={
                                                                                    feature.featureid ===
                                                                                        6 &&
                                                                                    feature.status
                                                                                        ? "translate-y-4"
                                                                                        : ""
                                                                                }>
                                                                                <ToggleButton
                                                                                    checked={
                                                                                        feature.status
                                                                                    }
                                                                                    onChange={() => {
                                                                                        this.enableDisable_customPermission(
                                                                                            feature.id,
                                                                                            feature.status,
                                                                                            feature.data
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </p>
                                                                            {feature.featureid ===
                                                                                6 &&
                                                                            feature.status &&
                                                                            this.state
                                                                                .userScreenshotTime !==
                                                                                "" ? (
                                                                                <div className="w-2/5 ml-2">
                                                                                    <SelectField
                                                                                        onSelectChange={
                                                                                            this
                                                                                                .handleScreenShotTimeChange_User
                                                                                        }
                                                                                        FieldName=""
                                                                                        name={
                                                                                            "Screen Interval"
                                                                                        }
                                                                                        arrayOfData={
                                                                                            timingArray
                                                                                        }
                                                                                        mainclassName={
                                                                                            "mt-3"
                                                                                        }
                                                                                        id={
                                                                                            "ScreenShotTime"
                                                                                        }
                                                                                        defaultValue={
                                                                                            (this
                                                                                                .state
                                                                                                .userScreenshotTime !==
                                                                                            ""
                                                                                                ? this
                                                                                                      .state
                                                                                                      .userScreenshotTime
                                                                                                      .data
                                                                                                : "10") +
                                                                                            ""
                                                                                        }
                                                                                        // value={this.state.initialScreenShotIntervalData == "" ? "" : this.state.initialScreenShotIntervalData[0].screenshot_interval}
                                                                                    ></SelectField>
                                                                                </div>
                                                                            ) : null}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )
                                                        ) : (
                                                            <tr>
                                                                <td className="p-4 text-md  text-textprimary">
                                                                    {t("no")}{" "}
                                                                    {t("settingsTab.available")}{" "}
                                                                    {t("settingsTab.features")}{" "}
                                                                    {t("timeTracker.found")}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {/* {this.state.UserPermissionSubSelectedTab == "User-Available-Features"
                         ?
                            <div>
                                <div className="mt-8 flex flex-col">
                                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                Feature Name
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                Description
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                Status
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        {this.state.employee == "" ? <tr><td className='p-4 text-md  text-textprimary'>Please Select Employee</td></tr> :
                                                            this.state.useravailablefeatures && this.state.useravailablefeatures.length > 0 ?
                                                                this.state.useravailablefeatures && this.state.useravailablefeatures.map((feature, idx) => (
                                                                    <tr key={idx}>
                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{feature.name}</td>
                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{feature.desciption}</td>
                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                            {!feature.status ? <PrimaryButton buttontext='Enable' /> : <PrimaryButton buttontext='Disable' onClick={() => this.handleUserDisableFeature(feature.id, feature.name)} />}
                                                                        </td>
                                                                    </tr>
                                                                )) : <tr><td className='p-4 text-md  text-textprimary'>No Available Features Found</td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                         : null
                        }

                        {this.state.UserPermissionSubSelectedTab == "User-Unavailable-Features"
                         ?
                            <div>
                                <div className="mt-8 flex flex-col">
                                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                Feature Name
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                Description
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        {this.state.employee == "" ? <tr><td className='p-4 text-md  text-textprimary'>Please Select Employee</td></tr> :
                                                            this.state.userunavailablefeatures && this.state.userunavailablefeatures.length > 0 ?
                                                                this.state.userunavailablefeatures && this.state.userunavailablefeatures.map((feature, idx) => (
                                                                    <tr key={idx}>
                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{feature.name}</td>
                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{feature.description}</td>
                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                            <PrimaryButton buttontext='Add feature' onClick={() => this.handleAddFeature(feature.id)} />
                                                                        </td>
                                                                    </tr>
                                                                )) : <tr><td className='p-4 text-md  text-textprimary'>No Available Features Found</td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                         : null
                        } */}
                    </div>
                ) : null}

                {/* {this.state.PermissionSubSelectedTab == "Users-Excluded" ?
                    <div className="bg-white px-2 py-1 pb-4 border-b border-gray-200 sm:px-6 rounded-lg my-6">
                        <h1 className='text-primary my-6 text-lg font-semibold'>Users Excluded</h1>
                        <div>
                            <div className="mt-8 flex flex-col">
                                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                            Feature Name
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Description
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Users
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {listOfFeatures == "" ? <tr><td>Loading...</td></tr> :
                                                        listOfFeatures && listOfFeatures.features.map((feature, idx) => (
                                                            <tr key={idx}>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{feature.feature}</td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{feature.description}</td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex"><UsersIcon className="h-5 w-5 mr-1 text-black" /> <p>{feature.userexcluded.length}</p></td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                    <PrimaryButton buttontext='Set Permissions' />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null} */}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    features: state.settings,
    projects: state.projects,
    teams: state.teams,
});

const mapDispatchToProps = (dispatch) => ({
    postScreenshotTime:(data)=> dispatch(postScreenshotTime(data)),
    getFeatureUnavailable: () => dispatch(getFeatureUnavailable()),
    getFeatureMarket: () => dispatch(getFeatureMarket()),
    getFeatureList: () => dispatch(getFeatureList()),
    fetchallEmployees: () => dispatch(fetchallEmployees()),
    postFeatureMarket: (data) => dispatch(postFeatureMarket(data)),
    postFeatureStart: (data) => dispatch(postFeatureStart(data)),
    getOverideFeatureList: (ids, type) =>
        dispatch(getOverideFeatureList(ids, type)),
    getScreenshotTiming: (type) => dispatch(getScreenshotTiming(type)),
    postScreenshotTiming: (data) => dispatch(postScreenshotTiming(data)),
    patchScreenshotTiming: (idTime) => dispatch(patchScreenshotTiming(idTime)),
    deleteFeatureMarket: (data) => dispatch(deleteFeatureMarket(data)),
    deleteFeatureStart: (data) => dispatch(deleteFeatureStart(data)),
    FetchactiveTeams: (org_uuid) => dispatch(FetchactiveTeams(org_uuid)),
    fetchactiveuserslist: () => dispatch(fetchactiveuserslist()),
    putFeatureStop: (data) => dispatch(putFeatureStop(data)),
    putFeatureEnable: (data) => dispatch(putFeatureEnable(data)),

    teamsDashboard: (date) => dispatch(teamsDashboard(date)),

    getUnavailableFeature_team: (type, teamId) =>
        dispatch(getUnavailableFeature_team(type, teamId)),
    getAvailableFeature_team: (type, teamId) =>
        dispatch(getAvailableFeature_team(type, teamId)),
    getPermissions_featureList_team: (type, teamId) =>
        dispatch(getPermissions_featureList_team(type, teamId)),
    addFeature_team: (data) => dispatch(addFeature_team(data)),
    startFeature_team: (data) => dispatch(startFeature_team(data)),
    deleteFeature_team: (data) => dispatch(deleteFeature_team(data)),
    stopFeature_team: (data) => dispatch(stopFeature_team(data)),
    stopCustomFeat_team: (data) => dispatch(stopCustomFeat_team(data)),
    startCustomFeat_team: (data) => dispatch(startCustomFeat_team(data)),
    useravailablefeatures: (id) => dispatch(useravailablefeatures(id)),
    userunavailablefeatures: (id) => dispatch(userunavailablefeatures(id)),
    usercustompermissions: (id) => dispatch(usercustompermissions(id)),
});

PermissionsTab.propTypes = {};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(PermissionsTab));
