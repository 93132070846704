import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ReactSortable } from "react-sortablejs";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@material-ui/core";

export default function LayoutSetupModal(props) {
    // const { onCancelClick, onSubmitClick, submitButtonName, cancelButtonName, open, ...rest } =
    const { open, onCancelClick, modalHeading, modalData, onSubmitClick, modalArrangement } = props;
    const cancelButtonRef = useRef(null);
    const { t } = useTranslation("common");
    const sixDotsDesign = (
        <div className="flex max-w-[13px] -translate-y-0.5 flex-wrap justify-between h-4 mr-2 hover:cursor-pointer">
            <div className=" rounded-full bg-gray-400 overflow-hidden mb-0.5 min-h-[5px] min-w-[5px] max-h-[5px] max-w-[5px]"></div>
            <div className=" rounded-full bg-gray-400 overflow-hidden mb-0.5 min-h-[5px] min-w-[5px] max-h-[5px] max-w-[5px]"></div>
            <div className=" rounded-full bg-gray-400 overflow-hidden mb-0.5 min-h-[5px] min-w-[5px] max-h-[5px] max-w-[5px]"></div>
            <div className=" rounded-full bg-gray-400 overflow-hidden mb-0.5 min-h-[5px] min-w-[5px] max-h-[5px] max-w-[5px]"></div>
            <div className=" rounded-full bg-gray-400 overflow-hidden min-h-[5px] min-w-[5px] max-h-[5px] max-w-[5px]"></div>
            <div className=" rounded-full bg-gray-400 overflow-hidden min-h-[5px] min-w-[5px] max-h-[5px] max-w-[5px]"></div>
        </div>
    );
    const [modalOption, setmodalOption] = useState([]);
    const [modalOptionArrangement, setmodalOptionArrangement] = useState("");

    useEffect(() => {
        if (modalData !== "" && modalData && Object.keys(modalData).length > 0) {
            var modalList = Object.keys(modalData).map((key) => {
                if (key === "id" || key === "edit") {
                    return null;
                }
                return { label: key, status: modalData[key] };
            });
            var modalOptions = modalList.filter((val) => val !== null && val !== undefined);

            if (modalArrangement && modalArrangement !== "") {
                for (var i = 0; i < modalOptions.length; i++) {
                    var index = modalArrangement[modalOptions[i]["label"]] - 1;
                    if (index !== i) {
                        var temp = modalOptions[index];
                        modalOptions[index] = modalOptions[i];
                        modalOptions[i] = temp;
                    }
                }
                setmodalOptionArrangement({ ...modalArrangement });
            }
            setmodalOption(modalOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalData, open]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-30 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                // onClose={onCancelClick}>
                onClose={() => {}}>
                <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="relative w-[85vw] inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8  sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg leading-6 font-bold text-textprimary">
                                        {modalHeading ? modalHeading : "Layout Settings"}
                                    </Dialog.Title>
                                </div>
                                <div className="mt-4">
                                    <Dialog.Description>
                                        <div className="pl-4">
                                            {modalOption && modalOption.length > 0 ? (
                                                <ReactSortable
                                                    className="flex flex-col"
                                                    list={modalOption}
                                                    setList={(newList) => {
                                                        if (
                                                            modalOptionArrangement &&
                                                            modalArrangement
                                                        ) {
                                                            var modalArrng = modalOptionArrangement;
                                                            for (
                                                                var i = 0;
                                                                i < newList.length;
                                                                i++
                                                            ) {
                                                                modalArrng[newList[i].label] =
                                                                    i + 1;
                                                            }
                                                            setmodalOptionArrangement(modalArrng);
                                                        }
                                                        return setmodalOption(newList);
                                                    }}>
                                                    {modalOption.map((opt, idx) => {
                                                        if (!opt) {
                                                            return null;
                                                        }
                                                        return (
                                                            <div
                                                                key={opt.label + idx}
                                                                className={`flex items-center order-${
                                                                    modalOptionArrangement[
                                                                        opt.label
                                                                    ]
                                                                }`}>
                                                                {sixDotsDesign}
                                                                <Checkbox
                                                                    id={opt.label}
                                                                    checked={opt.status}
                                                                    style={{
                                                                        color: "#684D94",
                                                                        padding: "4px",
                                                                    }}
                                                                    onChange={() => {
                                                                        let modalOptionData = [
                                                                            ...modalOption,
                                                                        ];
                                                                        modalOptionData[
                                                                            idx
                                                                        ].status =
                                                                            !modalOptionData[idx]
                                                                                .status;
                                                                        setmodalOption(
                                                                            modalOptionData
                                                                        );
                                                                    }}
                                                                />
                                                                <label
                                                                    htmlFor={opt.label}
                                                                    className="capitalize">
                                                                    {opt.label.split("_").join(" ")}
                                                                </label>
                                                            </div>
                                                        );
                                                    })}
                                                </ReactSortable>
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                    </Dialog.Description>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => {
                                        onSubmitClick(modalOption, modalOptionArrangement);
                                    }}>
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:w-auto sm:text-sm"
                                    onClick={onCancelClick}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
