import axios from "axios";
import {
  createLocalAuthState,
  destroyLocalAuthState,
  getLocalAuthState,
} from "../services/localstorage";
import moment from "moment";

export const padlocktransport = axios.create({
  baseURL: "https://api.trypadlock.com/",
  timeout: 50000000,
  headers: {
    Authorization: "JWT " + localStorage.getItem("access_token"),
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export const transport = axios.create({
  withCredentials: false,
  baseURL: localStorage.getItem("apiURL") + "/api/",
  // baseURL: 'https://api.joinworkahub.com' + '/api/',
  timeout: 50000000,
  headers: {
    Authorization: "JWT " + localStorage.getItem("access_token"),
    accept: "application/json",
  },
});

transport.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const originalRequest = error.config;
        var recheck = false;
        if (error.response == undefined) recheck = true;

        if (recheck == false && error.response.status === 401) recheck = true;

        if (recheck == true) {
            const refresh_token = localStorage.getItem("refresh_token");

            return fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
                method: "post",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    refresh: refresh_token,
                }),
            })
                .then((response) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then((res, data) => {
                    if (res[0] === 401) {
                        SecureLogoutUser();
                    } else {
                        localStorage.setItem("access_token", res[1].access);
                        localStorage.setItem("refresh_token", res[1].refresh);
                        transport.defaults.headers["Authorization"] = "JWT " + res[1].access;
                        originalRequest.headers["Authorization"] = "JWT " + res[1].access;
                        return transport(originalRequest);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    SecureLogoutUser();
                });
        }
        return error.response;
    }
);
padlocktransport.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const originalRequest = error.config;
        var recheck = false;

        if (error.response == undefined) recheck = true;

        if (recheck == false && error.response.status === 401) recheck = true;

        if (recheck == true) {
            const refresh_token = localStorage.getItem("refresh_token");

            return fetch(localStorage.getItem("apiURL") + "/api/token/refresh/", {
                method: "post",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    refresh: refresh_token,
                }),
            })
                .then((response) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then((res, data) => {
                    if (res[0] === 401) {
                        SecureLogoutUser();
                    } else {
                        localStorage.setItem("access_token", res[1].access);
                        localStorage.setItem("refresh_token", res[1].refresh);
                        padlocktransport.defaults.headers["Authorization"] = "JWT " + res[1].access;
                        originalRequest.headers["Authorization"] = "JWT " + res[1].access;
                        return padlocktransport(originalRequest);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        return error.response;
    }
);

export const IsUserLoggedIn = () => {
  // checks whether the user is loggedIn or not
  const config = {
    method: "GET",
    url: "/user/auth_status/",
    withCredentials: true,
  };

  transport(config)
    .then((response) => {
      const userRoleState = localStorage.getItem("ro");
      localStorage.setItem("userName", response.data.username);
      if (
        response.data.user_status == true ||
        response.data.is_manager == true ||
        response.data.is_superuser == true
      ) {
        createLocalAuthState(response.data);
        window.location.href = "/dashboard";
        const userAuthState = JSON.parse(localStorage.getItem("s7_auth_state"));
        // const userRole = userRoleState && userRoleState == 'Observer'
        const isSuperUser = userAuthState && userAuthState.is_superuser;
        isSuperUser ||
        userRoleState == "Observer" ||
        userRoleState == "Manager" ||
        userRoleState == "Admin"
          ? (window.location.href = "/dashboard")
          : (window.location.href = "/emp/dashboard");
      } else {
        destroyLocalAuthState();
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

const isuserLoggedIn = (apiURL) => {
  console.log("API ULRL AUTH STAUS", apiURL);
  fetch(apiURL + "/api/user/auth_status", {
    method: "GET",
    headers: {
      authorization: "JWT " + localStorage.getItem("access_token"),
    },
  })
    .then((res) => res.json())
    .then((response) => {
      const userRoleState = localStorage.getItem("ro");
      localStorage.setItem("userName", response.username);
      if (
        response.user_status == true ||
        response.is_manager == true ||
        response.is_superuser == true
      ) {
        createLocalAuthState(response);
        window.location.href = "/dashboard";
        const userAuthState = JSON.parse(localStorage.getItem("s7_auth_state"));
        // const userRole = userRoleState && userRoleState == 'Observer'
        const isSuperUser = userAuthState && userAuthState.is_superuser;
        isSuperUser ||
        userRoleState == "Observer" ||
        userRoleState == "Manager" ||
        userRoleState == "Admin"
          ? (window.location.href = "/dashboard")
          : (window.location.href = "/emp/dashboard");
      } else {
        destroyLocalAuthState();
      }
    });
};

export const AuthRedirectHandler = function (props, apiURL) {
  const userRole = localStorage.getItem("ro");
  isuserLoggedIn(apiURL);
  // IsUserLoggedIn();
  var data = getLocalAuthState();
  if (data.user_status && !data.is_Manager && !data.is_superuser) {
    props.history.push(props.employeeInitialRoute);
  } else if (data.user_status && data.is_Manager && !data.is_superuser) {
    props.history.push(props.managerInitialRoute);
  } else if (data.user_status && data.is_Manager && data.is_superuser) {
    props.history.push(props.employerInitialRoute);
  }
};

function refresh() {
  window.location.reload();
}

export const SecureLoginUser = (username, password, organization, props) => {
  const config = {
    method: "POST",
    url: "/token/obtain/",
    data: {
      username: username,
      password: password,
      organisation: organization,
    },
  };
  transport(config)
    .then((res) => {
      if (res.status == 200) {
        transport.defaults.headers["Authorization"] = "JWT " + res.data.access;
        localStorage.setItem("access_token", res.data.access);
        localStorage.setItem("refresh_token", res.data.refresh);
        createLocalAuthState(res.data);
        AuthRedirectHandler(props);
      }
    })
    .catch((err) => {
      console.log("[Error] unsuccessful login attempt", err, props);
    });
};

export const SecureLoginUserMkt = (username, user_id, props, apiURL) => {
  const config = {
    method: "POST",
    url: "/token/obtain/",
    data: {
      username: username,
      uu_id: user_id,
    },
  };
  transport(config)
    .then((res) => {
      if (res.status == 200) {
        transport.defaults.headers["Authorization"] = "JWT " + res.data.access;
        localStorage.setItem("access_token", res.data.access);
        localStorage.setItem("refresh_token", res.data.refresh);
        createLocalAuthState(res.data);
        AuthRedirectHandler(props);
      }
    })
    .catch((err) => {
      console.log("[Error] unsuccessful login attempt", err, props);
    });
};

export const SecureLoginMkt = (username, user_id, props, apiURL) => {
  console.log("apiURL", apiURL);
  fetch(apiURL + "/api/token/obtain/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      uu_id: user_id,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log("RES otken obtain", res);
      // if(res.status == 200){
      transport.defaults.headers["Authorization"] = "JWT " + res.access;
      localStorage.setItem("access_token", res.access);
      localStorage.setItem("refresh_token", res.refresh);
      createLocalAuthState(res);
      AuthRedirectHandler(props, apiURL);
      // }
    });
};

export const GetOrganizationUUID = (orgname) => {
  const config = {
    method: "GET",
    url: `users/org_uuid?org=${orgname}`,
  };
  padlocktransport(config)
    .then((res) => {
      if (res.status == 200) {
        localStorage.setItem("org_uuid", res.data.org_uuid);
      }
    })
    .catch((err) => {
      console.log("[Error] unsuccessful login attempt", err);
    });
};

export const SecureLogoutUser = (domainName) => {
  const orgss = localStorage.getItem("s7_auth_state");
  const orgs = JSON.parse(orgss).org;
  var urls;
  if (
    domainName == `${localStorage.getItem("org_name")}.joinworkahub.com` ||
    "app.joinworkahub.com"
  ) {
    urls = "https://joinworkahub.com/remove?token=" + btoa(orgs);
  } else {
    urls = "https://samstapontheshoulder.com/remove?token=" + btoa(orgs);
  }
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  transport.defaults.headers["Authorization"] = null;
  destroyLocalAuthState();
  window.location.href = urls;
};

//to register the employee
export const SecureRegisterUser = (
  joined_date,
  user_name,
  user_email,
  password,
  first_name,
  last_name,
  props
) => {
  // Logs user in
  const config = {
    method: "POST",
    url: "/registration_form/",
    withCredentials: true,
    data: {
      user_name: user_name,
      password: password,
      user_email: user_email,
      first_name: first_name,
      last_name: last_name,
      joined_date: moment(joined_date).format("YYYY-MM-DD"),
    },
  };
  transport(config)
    .then((res) => {
      createLocalAuthState(res.data);
      AuthRedirectHandler(props);
    })
    .catch((err) => {
      console.log("[Error] unsuccessful login attempt", err, props);
    });
};
