import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'

import {toast} from 'react-toastify';
import PrimaryButton from '../../Components/PrimaryButton';

import { getFeatureUnavailable, getFeatureMarket, getFeatureList, postFeatureStart, postFeatureMarket, deleteFeatureMarket, deleteFeatureStart, putFeatureStop, putFeatureEnable } from '../../services/settings/action';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class FeaturesTab extends Component {

    constructor() {
        super();
        this.state = {
            selectedTab:"available-features",
            currentURL: "",
        }
    }

    componentDidMount() {
        const url = window.location.pathname;
        setTimeout(() => {
            this.setState({ currentURL: url })
        }, 100);


        setTimeout(() => {
            if (this.state.currentURL == "/settings/features/available-features") {
                this.setState({ selectedTab: "available-features" });
            } else if (this.state.currentURL == "/settings/features/unavailable-features") {
                this.setState({ selectedTab: "unavailable-features" });
            }
            setTimeout(() => {
                window.history.pushState({}, null, `/settings/features/${this.state.selectedTab}`);
            }, 150);
        }, 200);

        this.props.getFeatureUnavailable();
        this.props.getFeatureMarket();
        this.props.getFeatureList();
    }

    HandleSelectTab = e =>{
        this.setState({selectedTab:e})
        setTimeout(() => {
            window.history.pushState({}, null, `/settings/features/${this.state.selectedTab}`);
        }, 200);
    }

    HandleMobileSelectTab = e =>{
        this.setState({selectedTab:e.target.value})
        setTimeout(() => {
            window.history.pushState({}, null, `/settings/features/${this.state.selectedTab}`);
        }, 200);
    }

    render() {
        const {t} = this.props;
        const features = this.props.features
        const listOfUnavailableFeatures = features && features.getFeatureUnavailable;

        const listOfAvailableFeatures = features && features.getFeatureMarket;

        const listOfFeatures = features && features.featureList;



        const EnableFeature = (id) => {
            const dataa = {
                feature: id,
                type: "Org",
                userid: "",
            };

            const FeatureMarket = {
                featureid: id,
                type: "Org",
            };

            this.props.postFeatureMarket(FeatureMarket).then((data) => {
                if(data.action.payload.status == 200){
                    this.props.getFeatureUnavailable();
                    this.props.getFeatureMarket();
                } else {
                    console.log('ERror marketFeatures')
                }
            })

            this.props.postFeatureStart(dataa).then((data) => {
                if(data.action.payload.status == 200){
                    toast.success('Feature Enabled Successfully');
                    this.props.getFeatureUnavailable();
                } else if(data.action.payload.status == 400){
                    toast.error('Feature Already Enabled');
                } else {
                    toast.error('Something went wrong');
                }
            })
        }

        const disableFeature = (id, featureName) => {
            const dataa = {
                featureid: id
            }
            this.props.deleteFeatureMarket(dataa).then((data) => {
                if(data.action.payload.status == 200){
                    this.props.getFeatureUnavailable();
                    this.props.getFeatureMarket();
                }
            })
        
            let permission = listOfFeatures && listOfFeatures.features.filter(item => item.feature == featureName);
            let permission_id = permission && permission[0].id;

            this.props.deleteFeatureStart(permission_id).then((data) => {
                if(data.action.payload.status == 200){
                    toast.success('Feature Disabled Successfully');
                    this.props.getFeatureUnavailable();
                    this.props.getFeatureList();
                    this.props.getFeatureMarket();
                } else {
                    toast.error('Something went wrong');
                }
            })

        }

        return (
            <div>
                <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg my-6">
                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            {t("requestsModule.selectATab")}
                        </label>
                        <select
                            id="tabs"
                            name="tabs"
                            onChange={(e) => this.HandleMobileSelectTab(e)}
                            className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                            defaultValue={this.state.selectedTab}>
                            <option key="available-features" value="available-features">
                                {t("settingsTab.available")} {t("settingsTab.features")}
                            </option>
                            <option key="unavailable-features" value="unavailable-features">
                                {t("settingsTab.unavailable")} {t("settingsTab.features")}
                            </option>
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            <div
                                key="Users"
                                onClick={() => this.HandleSelectTab("available-features")}
                                className={classNames(
                                    this.state.selectedTab == "available-features"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab == "available-features"
                                        ? "page"
                                        : undefined
                                }>
                                <span>
                                    {t("settingsTab.available")} {t("settingsTab.features")}
                                </span>
                            </div>
                            <div
                                key="Workstations"
                                onClick={() => this.HandleSelectTab("unavailable-features")}
                                className={classNames(
                                    this.state.selectedTab == "unavailable-features"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab == "unavailable-features"
                                        ? "page"
                                        : undefined
                                }>
                                <span>
                                    {t("settingsTab.unavailable")} {t("settingsTab.features")}
                                </span>
                            </div>
                        </nav>
                    </div>
                </div>
                {this.state.selectedTab == "available-features" ? (
                    <div className="bg-white px-2 py-1 pb-4 border-b border-gray-200 sm:px-6 rounded-lg my-6">
                        <h1 className="text-primary my-6 text-md px-4 font-semibold">
                            {t("settingsTab.available")} {t("settingsTab.features")}
                        </h1>
                        <div className="px-4 sm:px-4 lg:px-5">
                            <div className="mt-8 flex flex-col">
                                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                            {t("settingsTab.feature")} {t("name")}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            {t("description")}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            {t("action")}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {listOfAvailableFeatures == "" ? (
                                                        <tr>
                                                            <td>
                                                                {t("no")}{" "}
                                                                {t("settingsTab.features")}{" "}
                                                                {t("timeTracker.found")}
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        listOfAvailableFeatures &&
                                                        listOfAvailableFeatures.feature.map(
                                                            (feature, idx) => (
                                                                <tr key={idx}>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                        {feature.name}
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                        {feature.desciption}
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                        {feature.status ==
                                                                        "true" ? (
                                                                            <PrimaryButton
                                                                                buttontext={t(
                                                                                    "settingsTab.enable"
                                                                                )}
                                                                            />
                                                                        ) : (
                                                                            <PrimaryButton
                                                                                buttontext={t(
                                                                                    "settingsTab.disable"
                                                                                )}
                                                                                onClick={() => {
                                                                                    disableFeature(
                                                                                        feature.id,
                                                                                        feature.name
                                                                                    );
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {this.state.selectedTab == "unavailable-features" ? (
                    <div className="bg-white px-2 py-2 pb-4 border-b border-gray-200 sm:px-6 rounded-lg my-6">
                        <h1 className="text-primary my-6 text-md px-4 font-semibold">
                            {t("settingsTab.unavailable")} {t("settingsTab.features")}
                        </h1>
                        <div className="px-4 sm:px-4 lg:px-5">
                            <div className="mt-8 flex flex-col">
                                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                            {t("settingsTab.feature")} {t("name")}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            {t("description")}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            {t("action")}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {listOfUnavailableFeatures.feature == "" ? (
                                                        <tr>
                                                            <td className="p-4 text-md font-semibold text-gray-500">
                                                                {t("no")}{" "}
                                                                {t("settingsTab.unavailable")}{" "}
                                                                {t("settingsTab.Features")}{" "}
                                                                {t("timeTracker.found")}
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        listOfUnavailableFeatures.feature &&
                                                        listOfUnavailableFeatures.feature.map(
                                                            (feature, idx) => (
                                                                <tr key={idx}>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                        {feature.name}
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                        {feature.description}
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                        <PrimaryButton
                                                                            buttontext={t(
                                                                                "settingsTab.addFeature"
                                                                            )}
                                                                            onClick={() => {
                                                                                EnableFeature(
                                                                                    feature.id
                                                                                );
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    features: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
    getFeatureUnavailable: () => dispatch(getFeatureUnavailable()),
    getFeatureMarket: () => dispatch(getFeatureMarket()),
    getFeatureList: () => dispatch(getFeatureList()),

    postFeatureMarket: (data) => dispatch(postFeatureMarket(data)),
    postFeatureStart: (data) => dispatch(postFeatureStart(data)),

    deleteFeatureMarket: (data) => dispatch(deleteFeatureMarket(data)),
    deleteFeatureStart: (data) => dispatch(deleteFeatureStart(data)),

    putFeatureStop: (data) => dispatch(putFeatureStop(data)),
    putFeatureEnable: (data) => dispatch(putFeatureEnable(data)),

});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(FeaturesTab));
