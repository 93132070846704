import { Component } from "react"
import { Dialog } from "@headlessui/react";
import { BsSearch } from "react-icons/bs";



class SortByEmploye extends Component {
    state = { searchInput: "", showSearch: true, }

    onChangeSearch = (event) => {
        const { searchInput } = this.state
        this.setState({
            searchInput: event.target.value
        })
        console.log(searchInput)
    }

    getSearchResults = () => {
        const { searchInput } = this.state
        const { activeUsers } = this.props
        let searchResults
        if (searchInput) {
            searchResults = activeUsers.filter(eachUser => eachUser.user_first_name.toLowerCase().includes(searchInput.toLowerCase()))
            return (<> <div className="flex flex-col justify-start">
                <ul className="flex flex-col mt-[13.5px]">
                    {searchResults.map(eachResult => (
                        <li className="flex items-center justify-start mb-[10px]">
                            <input type="radio" onClcik={this.handleClick} id={eachResult.id} className="text-[#684D94] focus:ring-0 focus:ring-offset-0" />
                            <label htmlFor={eachResult.id} className="text-black font-medium text-[16px] font-inter ml-[8px]">{eachResult.user_first_name}</label>
                        </li>
                    ))}
                </ul>
            </div></>)
        } else {
            return null
        }

    }

    onClickSearch = () => {
        const { showSearch } = this.state
        this.setState(prevState => ({
            showSearch: !prevState.showSearch

        }))
        console.log(this.state.showSearch)
    }

    sortAtoZ = () => {
        const { activeUsers } = this.props
        console.log("A to Z")
        activeUsers.sort((a, b) => {
            if (a.user_first_name < b.user_first_name) {
                return -1;
            }
            if (a.user_first_name > b.user_first_name) {
                return 1;
            }
            return 0;
        })

    }


    sortZtoA = () => {
        const { activeUsers } = this.props
        console.log("Z to A")

        activeUsers.sort((a, b) => {
            if (a.user_first_name > b.user_first_name) {
                return -1;
            }
            if (a.user_first_name < b.user_first_name) {
                return 1;
            }
            return 0;
        })
    }

    recentlyAdded = () => {
        const { activeUsers } = this.props
        activeUsers()
    }

    handleClick = () => {
        console.log("Click")
    }
    render() {
        const { searchInput, showSearch } = this.state
        const { isSortOpenEmploye, sortEmployeStatus } = this.props
        // const searchResults=this.getSearchResults()

        // console.log(searchResults)
        return (
            <Dialog
                open={isSortOpenEmploye ?? false}
                onClose={() => {
                    sortEmployeStatus();
                }}
                className="min-w-full">
                <Dialog.Panel className="fixed inset-0 w-3/12 z-10 flex justify-start mt-[280px] ml-[480px] mr-auto">
                    <div className="bg-white p-4 min-w-full absolute mt-4 rounded-md shadow-lg">
                        <div className="flex flex-row justify-between">
                            <Dialog.Title>
                                <h1 className="text-black font-medium text-[16px] font-inter">
                                    Sort by
                                </h1>
                            </Dialog.Title>
                            <button className="focus:ring-0 focus-ring-offset-0 text-[#1055EB] underline font-medium text-[12px] font-inter">
                                Reset
                            </button>
                        </div>
                        <div className="mt-[16px] mb-[16px] flex flex-row items-center bg-transparent border-solid px-[9px] border-2 border-[#D1D5DB] rounded ">
                            <BsSearch className="" />
                            <input
                                type="text"
                                placeholder="Search by name"
                                className="h-8 border-transparent focus:ring-0 focus-ring-offset-0"
                                onClick={this.onClickSearch}
                                onChange={this.onChangeSearch}
                            />
                        </div>
                        <Dialog.Description>
                            <div className="flex flex-col">
                                {showSearch ? (
                                    <>
                                        <div className="flex flex-row items-center mt-[13.5px]">
                                            <input
                                                type="radio"
                                                onClick={this.sortAtoZ}
                                                id="checkbox"
                                                value={searchInput}
                                                className="text-[#684D94] focus:ring-0 focus:ring-offset-0"
                                            />
                                            <label
                                                htmlFor="checkbox"
                                                className="text-black font-medium text-[16px] font-inter ml-[8px]">
                                                Sort by A-Z
                                            </label>
                                        </div>
                                        <div className="flex flex-row items-center mt-[16px]">
                                            <input
                                                type="radio"
                                                onClick={this.sortZtoA}
                                                id="checkbox"
                                                className="text-[#684D94] focus:ring-0 focus:ring-offset-0"
                                            />
                                            <label
                                                htmlFor="checkbox"
                                                className="text-black font-medium text-[16px] font-inter ml-[8px]">
                                                Sort by Z-A
                                            </label>
                                        </div>
                                        <div className="flex flex-row items-center  mt-[16px]">
                                            <input
                                                type="radio"
                                                onClick={this.recentlyAdded}
                                                id="checkbox"
                                                className="text-[#684D94] focus:ring-0 focus:ring-offset-0"
                                            />
                                            <label
                                                htmlFor="checkbox"
                                                className="text-black font-medium text-[16px] font-inter ml-[8px]">
                                                Recently Added
                                            </label>
                                        </div>
                                        <div className="flex flex-row items-center  mt-[16px]">
                                            <input
                                                type="radio"
                                                onClick={this.handleClick}
                                                id="checkbox"
                                                className="text-[#684D94] focus:ring-0 focus:ring-offset-0"
                                            />
                                            <label
                                                htmlFor="checkbox"
                                                className="text-black font-medium text-[16px] font-inter ml-[8px]">
                                                Oldest to New
                                            </label>
                                        </div>
                                        <div className="flex flex-row items-center  mt-[16px]">
                                            <input
                                                type="radio"
                                                onClick={this.handleClick}
                                                id="checkbox"
                                                className="text-[#684D94] focus:ring-0 focus:ring-offset-0"
                                            />
                                            <label
                                                htmlFor="checkbox"
                                                className="text-black font-medium text-[16px] font-inter ml-[8px]">
                                                New to Oldest
                                            </label>
                                        </div>
                                    </>
                                ) : (
                                    this.getSearchResults()
                                )}
                            </div>
                        </Dialog.Description>
                    </div>
                </Dialog.Panel>
            </Dialog>
        );
    }


}
export default SortByEmploye