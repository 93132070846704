import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../../Components/Layout';

import microsoftLogo from '../../images/microsoft.png';
import appleLogo from '../../images/apple.png';
import linuxLogo from '../../images/linux.png';
import { withTranslation } from 'react-i18next';



class Download extends Component {
    constructor() {
        super();
        this.state = {
            domain_name: "",
        }
    }

    componentDidMount() {
        const url = window.location.href;
        let appNameURL = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
        setTimeout(() => {
            this.setState({domain_name: appNameURL});
        }, 200);
    }

    WindowsDownload64Bit() {
        let file;
        setTimeout(() => {
            if(this.state.domain_name == `${localStorage.getItem('org_name')}.joinworkahub.com`){
                file = "https://workahub-builds.s3.ap-south-1.amazonaws.com/workahub.exe";
            } else {
                file = "https://workahub-builds.s3.ap-south-1.amazonaws.com/workahub.exe";
            }
          window.location.href = file;
        }, 100);
    }
    
    WindowsDownload32bit(){
        let file;
        setTimeout(() => {
            if(this.state.domain_name == `${localStorage.getItem('org_name')}.joinworkahub.com`){
                file = 'https://s7works.s3.amazonaws.com/workahub/windows/workahub_32.exe'
            } else {
                file = "";
            }
            window.location.href = file;
        }, 100);
    }

    LinuxDownload() {
        setTimeout(() => {
          const response = {
            // file: "https://s7works.s3.amazonaws.com/workahub/download/linux/workahub_1.0.0_amd64.deb",
            file: "https://workahub-builds.s3.ap-south-1.amazonaws.com/workahub_1.0.1_amd64.deb",
          };
          window.location.href = response.file;
        }, 100);
    }
    
    MacDownload() {
        setTimeout(() => {
          const response = {
            // file: "https://s7works.s3.amazonaws.com/workahub/download/darwin/Workahub.dmg",
            // file: "https://workahub-builds.s3.ap-south-1.amazonaws.com/Workahub.dmg",
              file: "https://workahub-builds.s3.ap-south-1.amazonaws.com/Workahub.dmg",
          };
          window.location.href = response.file;
        }, 100);
    }

    render() {
        const {t} = this.props;
        return (
            <Layout>
                <div className="bg-white shadow-md rounded-md p-4">
                    <div className="text-3xl font-bold flex justify-center my-4">
                        {t("downloadTab.downloadDeskApp")}:
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 m-5 content-center">
                        <div className="shadow-md rounded-md h-80 flex justify-center items-center flex-col p-4 hover:shadow-lg">
                            <img
                                alt=""
                                src={microsoftLogo}
                                className="h-28 w-28  xl:translate-y-1"
                            />
                            <div className="text-lg font-semibold my-4  xl:translate-y-1">
                                Windows
                            </div>
                            <div className="flex flex-col 2xl:flex-row  xl:translate-y-1">
                                <button
                                    className="flex text-tiny mr-0 2xl:mr-3 bg-primary p-2 rounded-md text-white justify-center items-center mb-2 2xl:mb-0 flex-row "
                                    onClick={() => {
                                        this.WindowsDownload32bit();
                                    }}>
                                    {t("downloadBtn")}{" "}
                                    <p className="text-xs ml-1 text-gray">(32-bit)</p>
                                </button>
                                <button
                                    className="flex text-tiny bg-primary p-2 rounded-md text-white justify-center items-center flex-row"
                                    onClick={() => {
                                        this.WindowsDownload64Bit();
                                    }}>
                                    {t("downloadBtn")}{" "}
                                    <p className="text-xs ml-1 text-gray">(64-bit)</p>
                                </button>
                            </div>
                        </div>
                        <div className="shadow-md rounded-md flex justify-center items-center flex-col p-4 hover:shadow-lg">
                            <img alt="" src={appleLogo} className="h-28 w-28" />
                            <div className="text-lg font-semibold my-4">MacOS</div>
                            <button
                                className="flex text-tiny bg-primary p-2 rounded-md text-white justify-center px-4 items-center"
                                onClick={() => {
                                    this.MacDownload();
                                }}>
                                {t("downloadBtn")}
                            </button>
                        </div>
                        <div className="shadow-md rounded-md flex justify-center items-center flex-col p-4 hover:shadow-lg">
                            <img alt="" src={linuxLogo} className="h-28 w-28" />
                            <div className="text-lg font-semibold mt-2 my-4">Linux</div>
                            <button
                                className="flex text-tiny bg-primary p-2 rounded-md text-white justify-center px-4 items-center"
                                onClick={() => {
                                    this.LinuxDownload();
                                }}>
                                {t("downloadBtn")}
                            </button>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

Download.propTypes = {

};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Download));