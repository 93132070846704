import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import PrimaryButton from "../../Components/PrimaryButton";
import Layout from "../../Components/Layout";
import { getProjectDetails } from "../../services/projects/actions";
import {
  TeamDetailsDashboard,
  ProjectDetailsDashboard,
  managerDashboard,
  getTeamDashboardLayout,
  getTeamDashboardArrangement,
  getTeamEmployeeWorkingTableArrangement,
  getTeamEmployeeWorkingTableLayout,
  getTeamMemberTableArrangement,
  getTeamMemberTableLayout,
  getTeamProjectTableArrangement,
  getTeamProjectTableLayout,
  updateTeamDashboardLayout,
  updateTeamDashboardArrangement,
  updateTeamEmployeeWorkingTableArrangement,
  updateTeamEmployeeWorkingTableLayout,
  updateTeamMemberTableArrangement,
  updateTeamMemberTableLayout,
  updateTeamProjectTableArrangement,
  updateTeamProjectTableLayout,
  postTeamDashboardLayout,
  postTeamDashboardArrangement,
  postTeamEmployeeWorkingTableArrangement,
  postTeamEmployeeWorkingTableLayout,
  postTeamMemberTableArrangement,
  postTeamMemberTableLayout,
  postTeamProjectTableArrangement,
  postTeamProjectTableLayout,
  getTeamWorking,
  getTeamActivity,
} from "../../services/dashboard/actions";
import {
  FetchactiveTeams,
  getUsersfromTeam,
} from "../../services/teams/actions";
import { CalendarIcon } from "@heroicons/react/outline";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/global.css";
import { BsGearFill } from "react-icons/bs";
import TimezoneSelect, { allTimezones } from "react-timezone-select";

import { withTranslation } from "react-i18next";
import LayoutSetupModal from "./Modals/LayoutSetupModal";
import { BeatLoader } from "react-spinners";
import ReactHighcharts from "react-highcharts";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { fetchallEmployees } from "../../services/projects/actions";
import { getEmpListObserver } from "../../services/dashboard/actions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class TeamDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentdata: props.location.state,
      selectedDate: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      selectedTimezone: "",
      isShowSettingsModal: false,
      modalData: "",
      modalArrangement: "",
      modalHeading: "",
      dashboardLayout: "",
      onSubmitClickHandler: "",
      dashboardArrangement: "",
      employeeWorkingArrangement: "",
      employeeWorkingLayout: "",
      memberTableArrangement: "",
      memberTableLayout: "",
      projectTableArrangement: "",
      projectTableLayout: "",
      projectTableCols: 4,
      memberTableCols: 3,
      employeeWorkingTableCols: 3,
    };
  }

  componentDidMount() {
    this.props.getTeamDashboardLayout().then((res) => {
      this.setState({ dashboardLayout: res.value.data.data });
    });
    this.props.getTeamDashboardArrangement().then((res) => {
      this.setState({ dashboardArrangement: res.value.data.data });
    });
    this.props.getTeamEmployeeWorkingTableArrangement().then((res) => {
      this.setState({ employeeWorkingArrangement: res.value.data.data });
    });
    this.props.getTeamEmployeeWorkingTableLayout().then((res) => {
      var obj = res.value.data.data;
      var num = Object.keys(obj).reduce(
        (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
        0
      );
      this.setState({
        employeeWorkingLayout: obj,
        employeeWorkingTableCols: num,
      });
    });
    this.props.getTeamMemberTableArrangement().then((res) => {
      this.setState({ memberTableArrangement: res.value.data.data });
    });
    this.props.getTeamMemberTableLayout().then((res) => {
      var obj = res.value.data.data;
      var num = Object.keys(obj).reduce(
        (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
        0
      );
      this.setState({ memberTableLayout: obj, memberTableCols: num });
    });
    this.props.getTeamProjectTableArrangement().then((res) => {
      this.setState({ projectTableArrangement: res.value.data.data });
    });
    this.props.getTeamProjectTableLayout().then((res) => {
      var obj = res.value.data.data;
      var num = Object.keys(obj).reduce(
        (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
        0
      );
      this.setState({ projectTableLayout: obj, projectTableCols: num });
    });
    setTimeout(() => {
      const query = new URLSearchParams(this.props.location.search);
      const date = query.get("date");
      const timezone = query.get("timezone");
      const dateTemp =
        date === undefined || date === "" || date === null
          ? new Date()
          : new Date(date);
      this.setState({
        selectedDate: dateTemp,
        startDate: new Date(moment(dateTemp).startOf('week')),
          endDate: new Date(moment(dateTemp).endOf('week')),
        selectedTimezone:
          timezone === undefined || timezone === ""
            ? timezone == null
              ? ""
              : timezone
            : this.state.selectedTimezone,
      });
      // window.history.pushState(
      //   {},
      //   null,
      //   `/dashboard/?teamName=${this.state.parentdata.teamName}&date=${moment(
      //     this.state.selectedDate
      //   ).format("YYYY-MM-DD")}`
      // );
      window.history.pushState(
        {},
        null,
        `/team/?teamName=${this.state.parentdata.teamName}&date=${moment(
          this.state.selectedDate
        ).format("YYYY-MM-DD")}`
      );
    }, 100);

    // window.history.pushState({}, null, `/dashboard/${this.state.parentdata.teamName}/?date=${moment(this.state.selectedDate).format('YYYY-MM-DD')}`);

    const org_uuid = localStorage.getItem("org_uuid");

    setTimeout(() => {
      this.props.FetchactiveTeams(org_uuid).then((res) => {
        let teams = res.value.data.info;
        let teamFilter =
          teams === "" || teams === undefined
            ? ""
            : teams.find((t) => {
                return t.team.name === this.state.parentdata.teamName;
              });
        this.props.getUsersfromTeam(teamFilter.team.id);
      });
      this.props.getTeamActivity(
        moment(this.state.endDate).format("YYYY-MM-DD"),
        this.state.parentdata.teamName
      );
      this.props.getTeamWorking(
        moment(this.state.selectedDate).format("YYYY-MM-DD"),
        this.state.parentdata.teamName
      );
      // let date = moment(this.state.selectedDate).format("YYYY-MM-DD")
      this.props.TeamDetailsDashboard(
        moment(this.state.selectedDate).format("YYYY-MM-DD"),
        this.state.parentdata.teamuuid
      );
      this.props.ProjectDetailsDashboard(
        moment(this.state.selectedDate).format("YYYY-MM-DD")
      );

      this.props.managerDashboard(
        moment(this.state.selectedDate).format("YYYY-MM-DD")
      );
    }, 200);
  }

  handleDateChange = (dateVal) => {
    this.setState({ selectedDate: dateVal,startDate:new Date(moment(dateVal).startOf('week')),endDate:new Date(moment(dateVal).endOf('week')) }, () => {
      window.history.pushState(
        {},
        null,
        `/team/?teamName=${this.state.parentdata.teamName}&date=${moment(
          this.state.selectedDate
        ).format("YYYY-MM-DD")}&timezone=${this.state.selectedTimezone}`
      );
      let endforgraphdate = moment(this.state.endDate).format("YYYY-MM-DD");
      let date=  moment(this.state.selectedDate).format("YYYY-MM-DD")
      this.props.TeamDetailsDashboard(date, this.state.parentdata.teamuuid);
      this.props.ProjectDetailsDashboard(date);
      this.props.managerDashboard(date);
      this.props.getTeamActivity(endforgraphdate, this.state.parentdata.teamName);
      this.props.getTeamWorking(date, this.state.parentdata.teamName);
    });
  };

  onTodayClick = () => {
    this.setState({ selectedDate: new Date(), startDate:new Date(moment(new Date()).startOf('week')),endDate:new Date(moment(new Date()).endOf('week')) }, () => {
      window.history.pushState(
        {},
        null,
        `/team/teamName=${this.state.parentdata.teamName}&date=${moment(
          this.state.selectedDate
        ).format("YYYY-MM-DD")}&timezone=${this.state.selectedTimezone}`
      );

      let date = moment(new Date()).format("YYYY-MM-DD");
      let endforgraphdate = moment(this.state.endDate).format("YYYY-MM-DD");
      this.props.TeamDetailsDashboard(date, this.state.parentdata.teamuuid);
      this.props.ProjectDetailsDashboard(date);
      this.props.managerDashboard(date);
      this.props.getTeamActivity(endforgraphdate, this.state.parentdata.teamName);
      this.props.getTeamWorking(date, this.state.parentdata.teamName);
    });
  };

  handleProjectNavigation = (data) => {
    let activrProjectsList =
      this.props.projects && this.props.projects.projectDetails;
    let projectSelected = activrProjectsList.find(
      (pro) => pro.project_name == data.project
    );
    let projectId = projectSelected.project_id;
    let projectName = projectSelected.project_name;
    this.props.history.push({
      pathname: `/projects/?project=${projectName}`,
      state: {
        projectId: projectId,
        data: projectSelected,
        projectName: projectName,
      },
    });
  };

  dashboardLayoutUpdateHandler = (data, arrangementData) => {
    const newData = {
      employee_working: data[arrangementData["employee_working"] - 1]["status"]
        ? "True"
        : "False",
      project_details: data[arrangementData["project_details"] - 1]["status"]
        ? "True"
        : "False",
      team_members: data[arrangementData["team_members"] - 1]["status"]
        ? "True"
        : "False",
    };
    const newArrangement = {
      employee_working: arrangementData["employee_working"],
      project_details: arrangementData["project_details"],
      team_members: arrangementData["team_members"],
    };

    if (this.state.dashboardArrangement["edit"]) {
      this.props.updateTeamDashboardArrangement(newArrangement).then((res) => {
        if (res.value.status === 200) {
          this.setState({
            dashboardArrangement: arrangementData,
          });
        }
      });
    } else {
      this.props.postTeamDashboardArrangement(newArrangement).then((res) => {
        if (res.value.status === 200) {
          this.setState({
            dashboardArrangement: arrangementData,
          });
        }
      });
    }
    if (this.state.dashboardLayout["edit"]) {
      this.props.updateTeamDashboardLayout(newData).then((response) => {
        if (response.value.status === 200) {
          this.props.getTeamDashboardLayout().then((res) => {
            this.setState({
              dashboardLayout: res.value.data.data,
              isShowSettingsModal: false,
            });
          });
        }
      });
    } else {
      this.props.postTeamDashboardLayout(newData).then((response) => {
        if (response.value.status === 200) {
          this.props.getTeamDashboardLayout().then((res) => {
            this.setState({
              dashboardLayout: res.value.data.data,
              isShowSettingsModal: false,
            });
          });
        }
      });
    }
  };

  projectTableLayoutUpdateHandler = (data, arrangementData) => {
    const newData = {
      employee_worked: data[arrangementData["employee_worked"] - 1]["status"]
        ? "True"
        : "False",
      hours_spend: data[arrangementData["hours_spend"] - 1]["status"]
        ? "True"
        : "False",
      project_name: data[arrangementData["project_name"] - 1]["status"]
        ? "True"
        : "False",
      task: data[arrangementData["task"] - 1]["status"] ? "True" : "False",
    };
    const newArrangement = {
      employee_worked: arrangementData["employee_worked"],
      hours_spend: arrangementData["hours_spend"],
      project_name: arrangementData["project_name"],
      task: arrangementData["task"],
    };

    if (this.state.projectTableArrangement["edit"]) {
      this.props
        .updateTeamProjectTableArrangement(newArrangement)
        .then((res) => {
          if (res.value.status === 200) {
            this.setState({
              projectTableArrangement: arrangementData,
            });
          }
        });
    } else {
      this.props.postTeamProjectTableArrangement(newArrangement).then((res) => {
        if (res.value.status === 200) {
          this.setState({
            projectTableArrangement: arrangementData,
          });
        }
      });
    }
    if (this.state.projectTableLayout["edit"]) {
      this.props.updateTeamProjectTableLayout(newData).then((response) => {
        if (response.value.status === 200) {
          this.props.getTeamProjectTableLayout().then((res) => {
            var obj = res.value.data.data;
            var num = Object.keys(obj).reduce(
              (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
              0
            );
            this.setState({
              projectTableLayout: obj,
              isShowSettingsModal: false,
              projectTableCols: num,
            });
          });
        }
      });
    } else {
      this.props.postTeamProjectTableLayout(newData).then((response) => {
        if (response.value.status === 200) {
          this.props.getTeamProjectTableLayout().then((res) => {
            var obj = res.value.data.data;
            var num = Object.keys(obj).reduce(
              (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
              0
            );
            this.setState({
              projectTableLayout: obj,
              isShowSettingsModal: false,
              projectTableCols: num,
            });
          });
        }
      });
    }
  };

  teamMemberTableLayoutUpdateHandler = (data, arrangementData) => {
    const newData = {
      email: data[arrangementData["email"] - 1]["status"] ? "True" : "False",
      employee: data[arrangementData["employee"] - 1]["status"]
        ? "True"
        : "False",
      role: data[arrangementData["role"] - 1]["status"] ? "True" : "False",
    };
    const newArrangement = {
      email: arrangementData["email"],
      employee: arrangementData["employee"],
      role: arrangementData["role"],
    };

    if (this.state.memberTableArrangement["edit"]) {
      this.props
        .updateTeamMemberTableArrangement(newArrangement)
        .then((res) => {
          if (res.value.status === 200) {
            this.setState({
              memberTableArrangement: arrangementData,
            });
          }
        });
    } else {
      this.props.postTeamMemberTableArrangement(newArrangement).then((res) => {
        if (res.value.status === 200) {
          this.setState({
            memberTableArrangement: arrangementData,
          });
        }
      });
    }
    if (this.state.memberTableLayout["edit"]) {
      this.props.updateTeamMemberTableLayout(newData).then((response) => {
        if (response.value.status === 200) {
          this.props.getTeamMemberTableLayout().then((res) => {
            var obj = res.value.data.data;
            var num = Object.keys(obj).reduce(
              (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
              0
            );
            this.setState({
              memberTableLayout: obj,
              isShowSettingsModal: false,
              memberTableCols: num,
            });
          });
        }
      });
    } else {
      this.props.postTeamMemberTableLayout(newData).then((response) => {
        if (response.value.status === 200) {
          this.props.getTeamMemberTableLayout().then((res) => {
            var obj = res.value.data.data;
            var num = Object.keys(obj).reduce(
              (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
              0
            );
            this.setState({
              memberTableLayout: obj,
              isShowSettingsModal: false,
              memberTableCols: num,
            });
          });
        }
      });
    }
  };
  empWorkingLayoutUpdateHandler = (data, arrangementData) => {
    const newData = {
      employee: data[arrangementData["employee"] - 1]["status"]
        ? "True"
        : "False",
      hours_spend: data[arrangementData["hours_spend"] - 1]["status"]
        ? "True"
        : "False",
      task: data[arrangementData["task"] - 1]["status"] ? "True" : "False",
    };
    const newArrangement = {
      employee: arrangementData["employee"],
      hours_spend: arrangementData["hours_spend"],
      task: arrangementData["task"],
    };

    if (this.state.employeeWorkingArrangement["edit"]) {
      this.props
        .updateTeamEmployeeWorkingTableArrangement(newArrangement)
        .then((res) => {
          if (res.value.status === 200) {
            this.setState({
              employeeWorkingArrangement: arrangementData,
            });
          }
        });
    } else {
      this.props
        .postTeamEmployeeWorkingTableArrangement(newArrangement)
        .then((res) => {
          if (res.value.status === 200) {
            this.setState({
              employeeWorkingArrangement: arrangementData,
            });
          }
        });
    }
    if (this.state.employeeWorkingLayout["edit"]) {
      this.props
        .updateTeamEmployeeWorkingTableLayout(newData)
        .then((response) => {
          if (response.value.status === 200) {
            this.props.getTeamEmployeeWorkingTableLayout().then((res) => {
              var obj = res.value.data.data;
              var num = Object.keys(obj).reduce(
                (a, item) =>
                  a + (item !== "edit" && obj[item] === true ? 1 : 0),
                0
              );
              this.setState({
                employeeWorkingLayout: obj,
                isShowSettingsModal: false,
                employeeWorkingTableCols: num,
              });
            });
          }
        });
    } else {
      this.props
        .postTeamEmployeeWorkingTableLayout(newData)
        .then((response) => {
          if (response.value.status === 200) {
            this.props.getTeamEmployeeWorkingTableLayout().then((res) => {
              var obj = res.value.data.data;
              var num = Object.keys(obj).reduce(
                (a, item) =>
                  a + (item !== "edit" && obj[item] === true ? 1 : 0),
                0
              );
              this.setState({
                employeeWorkingLayout: res.value.data.data,
                isShowSettingsModal: false,
                employeeWorkingTableCols: num,
              });
            });
          }
        });
    }
  };
  render() {
    const { t } = this.props;

    let membersoftheteam =
      this.props.teams &&
      this.props.teams.UsersFromTeam &&
      this.props.teams.UsersFromTeam.info;
    let employeeworkinginteam =
      this.props.dashboard && this.props.dashboard.TeamsDetailsDashboard;
    
    let empworkName =
        typeof employeeworkinginteam !== "string"
            ? employeeworkinginteam?.map((ele) => ele.Employee_name)
            : [];
    //  let employeenotworkinginteam = membersoftheteam?.filter((item)=>!employeeworkinginteam.includes(item.username))
    // let employeenotworkinginteamname=employeenotworkinginteam?.map((item)=>item.username)
    let dashboardKpi =
        this.props.dashboard &&
        this.props.dashboard.ManagersDashboard &&
        this.props.dashboard.ManagersDashboard.Total_emp_working_data;
    let currentStatus = this.props.dashboard && this.props.dashboard.TeamWorking;
    let projectdetailsinteam = this.props.dashboard && this.props.dashboard.ProjectDetailsDashboard;
    let filterprojectname = projectdetailsinteam?.filter(
        (name) =>
            name.project?.trim().toLowerCase() ===
            this.state.parentdata.teamName.trim().toLowerCase()
    );
    let finalprojectdetailsinteam =
        filterprojectname?.length > 0 ? filterprojectname : projectdetailsinteam;
    let productivityData = this.props.dashboard && this.props.dashboard.TeamActivity;

    let arrayDates = [];
    let arrayProductive = [];
    if (productivityData && productivityData.length > 0) {
        for (let val of productivityData) {
            arrayDates.push(moment(val.date).format("ddd"));
            arrayProductive.push(parseInt(val.duration / 60));
        }
    }
    let employees =
        localStorage.getItem("roleUser") === "admin"
            ? this.props.projects && this.props.projects.AllEmployees
            : this.props.dashboard && this.props.dashboard.EmpListObserver;
    const ProjectLink = (data, empName) => {
        localStorage.setItem("dgfsdgf", JSON.stringify(data));
        localStorage.setItem("selected Date", JSON.stringify(this.state.selectedDate));
        let employeeSelected = employees.find((emp) => emp.user_login_as == data[empName]);

        let props1 = {
            selectedEmployee: employeeSelected.user_id,
            //   selectedProject: data.project,
            selectedDate: this.state.selectedDate,
            prevPath: window.location.pathname,
        };
        // this.props.selectedEmployee(props1);
        this.props.history.push({
            pathname: "/timetracker/",
            state: { from: window.location.pathname, data: props1 },
        });
    };
    const nextWeekprodGraph = () => {
      let currentDate = new Date(this.state.endDate.getTime()-7*24*60*60*1000);
      let currentDay = currentDate.getDay();
      let daysToAdd = currentDay === 0 ? 7 : 7 - currentDay;
      let startDate = new Date(currentDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
      startDate.setDate(startDate.getDate() + 1);
      let startDayOfWeek = startDate.getDay();
      let daysToSunday = startDayOfWeek === 0 ? 0 : 7 - startDayOfWeek;
      startDate.setDate(startDate.getDate() + daysToSunday);
      
      let today = moment().startOf('day').format("YYYY-MM-DD");
      let startDateFormat = moment(startDate).format("YYYY-MM-DD");
    
      if (startDateFormat !== today) {
        let endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);
        this.setState({ startDate: startDate, endDate: endDate });
        this.props.getTeamActivity(
          moment(endDate).format("YYYY-MM-DD"),
          this.state.parentdata.teamName
        );
      }
    };

    const lastWeekprodGraph = () => {
      let currentDate = new Date(this.state.endDate);
      let currentDay = currentDate.getDay();
      let daysToSubtract = currentDay === 0 ? 0 : currentDay;
      let startDate = new Date(currentDate.getTime() - (daysToSubtract * 24 * 60 * 60 * 1000));
  
      let startDayOfWeek = startDate.getDay();
      let daysToSunday = startDayOfWeek === 0 ? 7 : startDayOfWeek;
      startDate.setDate(startDate.getDate() - daysToSunday);
  
      let endDate = new Date(startDate.getTime() + (6 * 24 * 60 * 60 * 1000));
      this.setState({ startDate: startDate, endDate: endDate });
      this.props.getTeamActivity(
        moment(endDate).format("YYYY-MM-DD"),
        this.state.parentdata.teamName
      );
    };
    const productivitychart = {
        chart: {
            type: "column",
            height: 250,
            zoomType: "x",
            style: {
                fontFamily: "Lato",
                fontSize: "15px",
            },
        },
        title: {
            text: "",
        },
        yAxis: {
            title: {
                text: `${t("average")}`,
            },
        },

        xAxis: {
            categories: arrayDates,
            crosshair: true,
        },
        tooltip: {
            valueSuffix: "Hr",
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                showInLegend: false,
                color: "#684D94",
                name: "Productive",
                data: arrayProductive,
            },
        ],
    };
    const AttendanceChart = {
        chart: {
            type: "pie",
            height: 250,
            style: {
                fontFamily: "Lato",
                fontSize: "15px",
            },
        },
        tooltip: {
            pointFormat:
                "{series.name}: <b>{point.y}</b><br/> <b>Employees:</b>{point.additionalInfo}",
        },
        title: {
            text: "",
        },

        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.y}",
                },
                showInLegend: true,
            },
        },

        credits: {
            enabled: false,
        },
        series: [
            {
                name: "Employees",
                innerSize: "60%",
                data: [
                    {
                        name: "Offline",
                        y: currentStatus && currentStatus.not_working,
                        color: "#0d233a",
                    },
                    {
                        name: "Online",
                        y: currentStatus && currentStatus.working,
                        color: "#684d94",
                        additionalInfo: empworkName?.length > 0 ? empworkName.join(", ") : "None",
                    },
                ],
            },
        ],
    };
    return (
        <Layout>
            <div className="bg-white px-2 py-2 border-b border-gray-200 rounded-lg">
                <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
                    <div className="flex text-textprimary text-xl">
                        <nav className="rounded-md w-full">
                            <ol className="list-reset flex">
                                <li>
                                    <Link
                                        // to="/dashboard"
                                        to="/team"
                                        className="text-primary hover:text-primary">
                                        {/* {t("dashboard")} */}
                                        {t("team")}
                                    </Link>
                                </li>
                                <li>
                                    <span className="text-textprimary mx-2">/</span>
                                </li>
                                <li className="text-textprimary">
                                    {this.state.parentdata.teamName}
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="flex flex-shrink-0">
                        <DatePicker
                            className="rounded-md"
                            dateFormat="MMMM dd yyyy"
                            selected={this.state.selectedDate}
                            onChange={(date) => this.handleDateChange(date)}
                        />
                        <CalendarIcon className="-ml-10 z-10 w-8"></CalendarIcon>
                        <button
                            type="button"
                            onClick={this.onTodayClick}
                            className="inline-block ml-5 px-8 py-2.5 bg-secondary text-white font-medium text-xs leading-tight uppercase rounded-lg shadow-md hover:bg-secondary hover:shadow-lg focus:bg-secondary focus:shadow-lg focus:outline-none focus:ring-0 active:bg-secondary active:shadow-lg transition duration-150 ease-in-out">
                            {t("today")}{" "}
                        </button>
                        <div
                            className="flex pl-1 mr-4 min-w-max flex-nowrap text-primary items-center hover:cursor-pointer"
                            onClick={() => {
                                this.setState({
                                    isShowSettingsModal: true,
                                    modalData: this.state.dashboardLayout,
                                    modalArrangement: this.state.dashboardArrangement,
                                    modalHeading: t("layoutSetup"),
                                    onSubmitClickHandler: this.dashboardLayoutUpdateHandler,
                                });
                            }}>
                            <BsGearFill className="mx-2 h-5 w-5" />
                            <p>{t("layoutSetup")}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex mt-4 items-center bg-gray-100">
                <div className="container mx-auto">
                    <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
                        <div className="flex items-center justify-between p-5 bg-white rounded shadow-sm">
                            <div>
                                <div className="text-sm text-gray-400 ">{t("timeLogged")}</div>
                                <div className="flex items-center pt-1">
                                    <div className="text-xl font-medium text-primary ">
                                        {moment
                                            .duration(
                                                dashboardKpi && dashboardKpi.total_time_logged,
                                                "minutes"
                                            )
                                            .days() *
                                            24 +
                                            moment
                                                .duration(
                                                    dashboardKpi && dashboardKpi.total_time_logged,
                                                    "minutes"
                                                )
                                                .hours() +
                                            " hrs " +
                                            moment
                                                .duration(
                                                    dashboardKpi && dashboardKpi.total_time_logged,
                                                    "minutes"
                                                )
                                                .minutes() +
                                            " mins"}
                                    </div>
                                </div>
                            </div>
                            <div className="text-gray-300">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-8 h-8"
                                    fill="none"
                                    viewBox="0 0 47 46">
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M41.536 30.456a19.21 19.21 0 01-5.675 7.4 19.771 19.771 0 01-8.557 3.937c-3.138.608-6.38.455-9.444-.447a19.673 19.673 0 01-8.129-4.725 19.1 19.1 0 01-4.92-7.902 18.775 18.775 0 01-.564-9.237 18.98 18.98 0 013.923-8.419 19.538 19.538 0 017.497-5.639"></path>
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M43.083 23c0-2.517-.506-5.01-1.49-7.335a19.142 19.142 0 00-4.246-6.218 19.617 19.617 0 00-6.353-4.155A19.953 19.953 0 0023.5 3.833V23h19.583z"></path>
                                </svg>
                            </div>
                        </div>
                        <div className="flex items-center justify-between p-5 bg-white rounded shadow-sm">
                            <div>
                                <div className="text-sm text-gray-400 ">{t("onlineTime")}</div>
                                <div className="flex items-center pt-1">
                                    <div className="text-xl font-medium text-primary ">
                                        {moment
                                            .duration(
                                                dashboardKpi && dashboardKpi.online_time,
                                                "minutes"
                                            )
                                            .days() *
                                            24 +
                                            moment
                                                .duration(
                                                    dashboardKpi && dashboardKpi.online_time,
                                                    "minutes"
                                                )
                                                .hours() +
                                            " hrs " +
                                            moment
                                                .duration(
                                                    dashboardKpi && dashboardKpi.online_time,
                                                    "minutes"
                                                )
                                                .minutes() +
                                            " mins"}
                                    </div>
                                </div>
                            </div>
                            <div className="text-gray-300">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-8 h-8"
                                    fill="none"
                                    viewBox="0 0 47 46">
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M41.536 30.456a19.21 19.21 0 01-5.675 7.4 19.771 19.771 0 01-8.557 3.937c-3.138.608-6.38.455-9.444-.447a19.673 19.673 0 01-8.129-4.725 19.1 19.1 0 01-4.92-7.902 18.775 18.775 0 01-.564-9.237 18.98 18.98 0 013.923-8.419 19.538 19.538 0 017.497-5.639"></path>
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M43.083 23c0-2.517-.506-5.01-1.49-7.335a19.142 19.142 0 00-4.246-6.218 19.617 19.617 0 00-6.353-4.155A19.953 19.953 0 0023.5 3.833V23h19.583z"></path>
                                </svg>
                            </div>
                        </div>

                        <div className="flex items-center justify-between p-5 bg-white rounded shadow-sm">
                            <div>
                                <div className="text-sm text-gray-400 ">{t("empWorking")}</div>
                                <div className="flex items-center pt-1">
                                    <div className="text-xl font-medium text-primary ">
                                        {currentStatus?.working}
                                    </div>
                                </div>
                            </div>
                            <div className="text-gray-300">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-8 h-8"
                                    fill="none"
                                    viewBox="0 0 47 46">
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M39.167 40.25v-3.833a7.585 7.585 0 00-2.295-5.422 7.92 7.92 0 00-5.539-2.245H15.667a7.92 7.92 0 00-5.54 2.245 7.585 7.585 0 00-2.294 5.422v3.833M23.5 21.083c4.326 0 7.833-3.432 7.833-7.666 0-4.235-3.507-7.667-7.833-7.667-4.326 0-7.833 3.432-7.833 7.667 0 4.234 3.507 7.666 7.833 7.666z"></path>
                                </svg>
                            </div>
                        </div>
                        <div className="flex items-center justify-between p-5 bg-white rounded shadow-sm">
                            <div>
                                <div className="text-sm text-gray-400 ">{t("empNotLogged")}</div>
                                <div className="flex items-center pt-1">
                                    <div className="text-xl font-medium text-primary">
                                        {currentStatus?.not_working}
                                    </div>
                                </div>
                            </div>
                            <div className="text-gray-400">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-8 h-8"
                                    fill="none"
                                    viewBox="0 0 47 46">
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M39.167 40.25v-3.833a7.585 7.585 0 00-2.295-5.422 7.92 7.92 0 00-5.539-2.245H15.667a7.92 7.92 0 00-5.54 2.245 7.585 7.585 0 00-2.294 5.422v3.833M23.5 21.083c4.326 0 7.833-3.432 7.833-7.666 0-4.235-3.507-7.667-7.833-7.667-4.326 0-7.833 3.432-7.833 7.667 0 4.234 3.507 7.666 7.833 7.666z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex mt-4 items-center bg-gray-100">
                <div className="container mx-auto">
                    <div className="grid gap-3 grid-cols-2 lg:grid-cols-3">
                        <div className="col-span-2 p-5 bg-white rounded shadow-md">
                            <div className="flex items-center justify-between">
                                <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                                    <div>
                                        <div className="-mb-px flex space-x-4" aria-label="Tabs">
                                            <div
                                                key="Productivity"
                                                className={classNames(
                                                    "border-primary text-primary",
                                                    "group inline-flex items-center py-2 px-1 border-b-2 font-medium text-md cursor-pointer"
                                                )}
                                                aria-current="page">
                                                <span>{t("ReportsTab.productivity")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex justify-end items-center mt-2">
                                        <button
                                            className=""
                                            onClick={() => {
                                                lastWeekprodGraph();
                                            }}>
                                            <ChevronLeftIcon className="text-gray-600 h-5 w-5" />
                                        </button>
                                        <p className="mx-1 text-gray-500 text-sm">
                                            {moment(this.state.startDate).format("YYYY-MM-DD")}{" "}
                                            <span className="mx-1 text-md">to</span>{" "}
                                            {moment(this.state.endDate).format("YYYY-MM-DD")}
                                        </p>
                                        <button
                                            className=""
                                            onClick={() => {
                                                nextWeekprodGraph();
                                            }}>
                                            <ChevronRightIcon className="text-gray-600 h-5 w-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {this.state.productivitygraphloading ? (
                                <div className="text-center h-64 w-full flex justify-center items-center">
                                    <BeatLoader
                                        color={"#684994"}
                                        productivitygraphloading={
                                            this.state.productivitygraphloading
                                        }
                                        size={15}
                                        margin={2}
                                    />
                                </div>
                            ) : (
                                <div className="mt-6">
                                    <ReactHighcharts config={productivitychart}></ReactHighcharts>
                                </div>
                            )}
                        </div>

                        <div className="p-5 bg-white rounded shadow-md lg-m:col-span-2">
                            <div className="text-textprimary text-lg font-semibold">
                                {" "}
                                {t("currentStatus")}
                            </div>
                            <div className="mt-4">
                                <ReactHighcharts config={AttendanceChart}></ReactHighcharts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white mt-4 px-2 py-1 flex flex-col border-b border-gray-200 sm:px-6 rounded-lg">
                {this.state.dashboardLayout !== "" &&
                    this.state.dashboardLayout.employee_working &&
                    this.state.dashboardArrangement && (
                        <div
                            className={`order-${this.state.dashboardArrangement.employee_working}`}>
                            <div className="flex items-center flex-wrap sm:flex-nowrap">
                                <div className="mt-3 text-textprimary text-lg font-semibold">
                                    {t("empWorking")}
                                </div>
                                <BsGearFill
                                    className="ml-4 mt-4 text-lg flex-shrink-0 text-primary hover:cursor-pointer"
                                    onClick={() => {
                                        this.setState({
                                            isShowSettingsModal: true,
                                            modalData: this.state.employeeWorkingLayout,
                                            modalArrangement: this.state.employeeWorkingArrangement,
                                            modalHeading: t("layoutSetup"),
                                            onSubmitClickHandler:
                                                this.empWorkingLayoutUpdateHandler,
                                        });
                                    }}
                                />
                            </div>
                            <div className="mt-4 flex flex-col overflow-y-auto bg-gray-100 ">
                                <div className="inline-block min-w-full align-middle ">
                                    <div className="shadow-sm ring-1 ring-black ring-opacity-5 ">
                                        <table className="min-w-max w-full border-separate ">
                                            <thead className="bg-gray-50 min-w-full">
                                                <tr
                                                    className={`grid grid-cols-${this.state.employeeWorkingTableCols}`}>
                                                    {this.state.employeeWorkingLayout.employee && (
                                                        <th
                                                            scope="col"
                                                            className={`order-${this.state.employeeWorkingArrangement["employee"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                            {t("employee")}
                                                        </th>
                                                    )}
                                                    {this.state.employeeWorkingLayout
                                                        .hours_spend && (
                                                        <th
                                                            scope="col"
                                                            className={`order-${this.state.employeeWorkingArrangement["hours_spend"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                            {t("totalHoursSpend")}
                                                        </th>
                                                    )}
                                                    {this.state.employeeWorkingLayout.task && (
                                                        <th
                                                            scope="col"
                                                            className={`order-${this.state.employeeWorkingArrangement["task"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                            {t("task")}
                                                        </th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {employeeworkinginteam &&
                                                    typeof employeeworkinginteam !== "string" &&
                                                    employeeworkinginteam.map((el, i) => (
                                                        <tr
                                                            key={i}
                                                            className={`grid grid-cols-${this.state.employeeWorkingTableCols}`}>
                                                            {this.state.employeeWorkingLayout
                                                                .employee && (
                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            employeeworkinginteam.length -
                                                                                1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        `order-${this.state.employeeWorkingArrangement["employee"]} whitespace-nowrap py-4 pl-4 pr-3 text-base cursor-pointer font-medium text-primary`
                                                                    )}
                                                                    onClick={() => {
                                                                        ProjectLink(
                                                                            el,
                                                                            "Employee_name"
                                                                        );
                                                                    }}>
                                                                    {el.Employee_name}
                                                                </td>
                                                            )}
                                                            {this.state.employeeWorkingLayout
                                                                .hours_spend && (
                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            employeeworkinginteam.length -
                                                                                1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        `order-${this.state.employeeWorkingArrangement["hours_spend"]} whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                    )}>
                                                                    {moment
                                                                        .duration(
                                                                            el.time_logged2,
                                                                            "minutes"
                                                                        )
                                                                        .days() *
                                                                        24 +
                                                                        moment
                                                                            .duration(
                                                                                el.time_logged2,
                                                                                "minutes"
                                                                            )
                                                                            .hours() +
                                                                        " hrs " +
                                                                        moment
                                                                            .duration(
                                                                                el.time_logged2,
                                                                                "minutes"
                                                                            )
                                                                            .minutes() +
                                                                        " mins"}
                                                                </td>
                                                            )}
                                                            {this.state.employeeWorkingLayout
                                                                .task && (
                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            employeeworkinginteam.length -
                                                                                1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        `order-${this.state.employeeWorkingArrangement["task"]} whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                    )}>
                                                                    {el.task}
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                {this.state.dashboardLayout !== "" &&
                    this.state.dashboardLayout.project_details &&
                    this.state.dashboardArrangement && (
                        <div className={`order-${this.state.dashboardArrangement.project_details}`}>
                            <div className="flex items-center flex-wrap sm:flex-nowrap">
                                <div className="mt-3 text-textprimary text-lg font-semibold">
                                    {t("project")} {t("details")}
                                </div>
                                <BsGearFill
                                    className="ml-4 mt-4 text-lg flex-shrink-0 text-primary hover:cursor-pointer"
                                    onClick={() => {
                                        this.setState({
                                            isShowSettingsModal: true,
                                            modalData: this.state.projectTableLayout,
                                            modalArrangement: this.state.projectTableArrangement,
                                            modalHeading: t("layoutSetup"),
                                            onSubmitClickHandler:
                                                this.projectTableLayoutUpdateHandler,
                                        });
                                    }}
                                />
                            </div>
                            <div className="mt-4 flex flex-col overflow-y-auto bg-gray-100 ">
                                <div className="inline-block min-w-full align-middle ">
                                    <div className="shadow-sm ring-1 ring-black ring-opacity-5 ">
                                        <table className="min-w-max w-full border-separate ">
                                            <thead className="bg-gray-50 min-w-full">
                                                <tr
                                                    className={`grid grid-cols-${this.state.projectTableCols}`}>
                                                    {this.state.projectTableLayout[
                                                        "project_name"
                                                    ] && (
                                                        <th
                                                            scope="col"
                                                            className={`order-${this.state.projectTableArrangement["project_name"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                            {t("project")} {t("name")}{" "}
                                                        </th>
                                                    )}
                                                    {this.state.projectTableLayout[
                                                        "hours_spend"
                                                    ] && (
                                                        <th
                                                            scope="col"
                                                            className={`order-${this.state.projectTableArrangement["hours_spend"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                            {t("totalHoursSpend")}
                                                        </th>
                                                    )}
                                                    {this.state.projectTableLayout["task"] && (
                                                        <th
                                                            scope="col"
                                                            className={`order-${this.state.projectTableArrangement["task"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                            {t("task")}
                                                        </th>
                                                    )}
                                                    {this.state.projectTableLayout[
                                                        "employee_worked"
                                                    ] && (
                                                        <th
                                                            scope="col"
                                                            className={`order-${this.state.projectTableArrangement["employee_worked"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                            {t("employees")} {t("worked")}{" "}
                                                        </th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {finalprojectdetailsinteam &&
                                                    finalprojectdetailsinteam.map((el, i) => (
                                                        <tr
                                                            key={i}
                                                            className={`grid grid-cols-${this.state.projectTableCols}`}>
                                                            {this.state.projectTableLayout[
                                                                "project_name"
                                                            ] && (
                                                                <td
                                                                    onClick={() => {
                                                                        this.handleProjectNavigation(
                                                                            el
                                                                        );
                                                                    }}
                                                                    className={classNames(
                                                                        i !==
                                                                            finalprojectdetailsinteam.length -
                                                                                1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        `order-${this.state.projectTableArrangement["project_name"]} whitespace-nowrap py-4 pl-4 pr-3 text-base cursor-pointer font-medium text-primary`
                                                                    )}>
                                                                    {el.project}
                                                                </td>
                                                            )}
                                                            {this.state.projectTableLayout[
                                                                "hours_spend"
                                                            ] && (
                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            finalprojectdetailsinteam.length -
                                                                                1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        `order-${this.state.projectTableArrangement["hours_spend"]} whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                    )}>
                                                                    {el.time_logged}
                                                                </td>
                                                            )}
                                                            {this.state.projectTableLayout[
                                                                "task"
                                                            ] && (
                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            finalprojectdetailsinteam.length -
                                                                                1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        `order-${this.state.projectTableArrangement["task"]} whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                    )}>
                                                                    {el.task}
                                                                </td>
                                                            )}
                                                            {this.state.projectTableLayout[
                                                                "employee_worked"
                                                            ] && (
                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            finalprojectdetailsinteam.length -
                                                                                1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        `order-${this.state.projectTableArrangement["employee_worked"]} whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                    )}>
                                                                    {el.number_of_users}
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                {this.state.dashboardLayout !== "" &&
                    this.state.dashboardLayout.team_members &&
                    this.state.dashboardArrangement && (
                        <div className={`order-${this.state.dashboardArrangement.team_members}`}>
                            <div className="flex items-center flex-wrap sm:flex-nowrap">
                                <div className="mt-3 text-textprimary text-lg font-semibold">
                                    {t("team")} {t("ProjectTasksTab.members")}
                                </div>
                                <BsGearFill
                                    className="ml-4 mt-4 text-lg flex-shrink-0 text-primary hover:cursor-pointer"
                                    onClick={() => {
                                        this.setState({
                                            isShowSettingsModal: true,
                                            modalData: this.state.memberTableLayout,
                                            modalArrangement: this.state.memberTableArrangement,
                                            modalHeading: t("layoutSetup"),
                                            onSubmitClickHandler:
                                                this.teamMemberTableLayoutUpdateHandler,
                                        });
                                    }}
                                />
                            </div>
                            <div className="mt-4 flex flex-col overflow-y-auto bg-gray-100 ">
                                <div className="inline-block min-w-full align-middle ">
                                    <div className="shadow-sm ring-1 ring-black ring-opacity-5 ">
                                        <table className="min-w-max w-full border-separate ">
                                            <thead className="bg-gray-50 min-w-full">
                                                <tr
                                                    className={`grid grid-cols-${this.state.memberTableCols} min-w-full`}>
                                                    {this.state.memberTableLayout["employee"] && (
                                                        <th
                                                            scope="col"
                                                            className={`order-${this.state.memberTableArrangement["employee"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                            {t("employee")} {t("name")}
                                                        </th>
                                                    )}
                                                    {this.state.memberTableLayout["email"] && (
                                                        <th
                                                            scope="col"
                                                            className={`order-${this.state.memberTableArrangement["email"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                            {t("email")}
                                                        </th>
                                                    )}
                                                    {this.state.memberTableLayout["role"] && (
                                                        <th
                                                            scope="col"
                                                            className={`order-${this.state.memberTableArrangement["role"]} top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                            {t("settingsTab.role")}
                                                        </th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {membersoftheteam &&
                                                    membersoftheteam.map((el, i) => (
                                                        <tr
                                                            key={i}
                                                            className={`grid grid-cols-${this.state.memberTableCols} min-w-full`}>
                                                            {this.state.memberTableLayout[
                                                                "employee"
                                                            ] && (
                                                                <td
                                                                    onClick={() => {
                                                                        ProjectLink(el, "username");
                                                                    }}
                                                                    className={classNames(
                                                                        i !==
                                                                            membersoftheteam.length -
                                                                                1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        `order-${this.state.memberTableArrangement["employee"]} whitespace-nowrap py-4 pl-4 pr-3 text-base cursor-pointer font-medium text-primary`
                                                                    )}>
                                                                    {el.username}
                                                                </td>
                                                            )}
                                                            {this.state.memberTableLayout[
                                                                "email"
                                                            ] && (
                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            membersoftheteam.length -
                                                                                1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        `order-${this.state.memberTableArrangement["email"]} whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                    )}>
                                                                    {el.email}
                                                                </td>
                                                            )}
                                                            {this.state.memberTableLayout[
                                                                "role"
                                                            ] && (
                                                                <td
                                                                    className={classNames(
                                                                        i !==
                                                                            membersoftheteam.length -
                                                                                1
                                                                            ? "border-b border-gray-200"
                                                                            : "",
                                                                        `order-${this.state.memberTableArrangement["role"]} whitespace-nowrap px-3 py-4 text-base text-gray-500`
                                                                    )}>
                                                                    {el.role}
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
            <LayoutSetupModal
                open={this.state.isShowSettingsModal}
                onCancelClick={() => {
                    this.setState({ isShowSettingsModal: false });
                }}
                modalArrangement={this.state.modalArrangement}
                modalData={this.state.modalData}
                onSubmitClick={this.state.onSubmitClickHandler}
                modalHeading={this.state.modalHeading}
            />
        </Layout>
    );
  }
}
const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  teams: state.teams,
  projects: state.projects,
});

const mapDispatchToProps = (dispatch) => ({
  FetchactiveTeams: (org_uuid) => dispatch(FetchactiveTeams(org_uuid)),
  getUsersfromTeam: (id) => dispatch(getUsersfromTeam(id)),
  TeamDetailsDashboard: (date, id) => dispatch(TeamDetailsDashboard(date, id)),
  ProjectDetailsDashboard: (date) => dispatch(ProjectDetailsDashboard(date)),
  managerDashboard: (date) => dispatch(managerDashboard(date)),
  getTeamDashboardLayout: () => dispatch(getTeamDashboardLayout()),
  getTeamDashboardArrangement: () => dispatch(getTeamDashboardArrangement()),
  getTeamEmployeeWorkingTableArrangement: () =>
    dispatch(getTeamEmployeeWorkingTableArrangement()),
  getTeamEmployeeWorkingTableLayout: () =>
    dispatch(getTeamEmployeeWorkingTableLayout()),
  getTeamMemberTableArrangement: () =>
    dispatch(getTeamMemberTableArrangement()),
  getTeamMemberTableLayout: () => dispatch(getTeamMemberTableLayout()),
  getTeamProjectTableArrangement: () =>
    dispatch(getTeamProjectTableArrangement()),
  getTeamProjectTableLayout: () => dispatch(getTeamProjectTableLayout()),
  updateTeamDashboardLayout: (data) =>
    dispatch(updateTeamDashboardLayout(data)),
  updateTeamDashboardArrangement: (data) =>
    dispatch(updateTeamDashboardArrangement(data)),
  updateTeamEmployeeWorkingTableArrangement: (data) =>
    dispatch(updateTeamEmployeeWorkingTableArrangement(data)),
  updateTeamEmployeeWorkingTableLayout: (data) =>
    dispatch(updateTeamEmployeeWorkingTableLayout(data)),
  updateTeamMemberTableArrangement: (data) =>
    dispatch(updateTeamMemberTableArrangement(data)),
  updateTeamMemberTableLayout: (data) =>
    dispatch(updateTeamMemberTableLayout(data)),
  updateTeamProjectTableArrangement: (data) =>
    dispatch(updateTeamProjectTableArrangement(data)),
  updateTeamProjectTableLayout: (data) =>
    dispatch(updateTeamProjectTableLayout(data)),
  postTeamDashboardLayout: (data) => dispatch(postTeamDashboardLayout(data)),
  postTeamDashboardArrangement: (data) =>
    dispatch(postTeamDashboardArrangement(data)),
  postTeamEmployeeWorkingTableArrangement: (data) =>
    dispatch(postTeamEmployeeWorkingTableArrangement(data)),
  postTeamEmployeeWorkingTableLayout: (data) =>
    dispatch(postTeamEmployeeWorkingTableLayout(data)),
  postTeamMemberTableArrangement: (data) =>
    dispatch(postTeamMemberTableArrangement(data)),
  postTeamMemberTableLayout: (data) =>
    dispatch(postTeamMemberTableLayout(data)),
  postTeamProjectTableArrangement: (data) =>
    dispatch(postTeamProjectTableArrangement(data)),
  postTeamProjectTableLayout: (data) =>
    dispatch(postTeamProjectTableLayout(data)),
  getTeamActivity: (date, name) => dispatch(getTeamActivity(date, name)),
  getTeamWorking: (date, name) => dispatch(getTeamWorking(date, name)),
  getEmpListObserver: () => dispatch(getEmpListObserver()),
  fetchallEmployees: () => dispatch(fetchallEmployees()),
  getProjectDetails: () => dispatch(getProjectDetails()),
});

TeamDashboard.propTypes = {};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(TeamDashboard)
);
