import React from 'react';
import PropTypes from 'prop-types';

const PrimaryButton = (props) => {
    const { onClick, buttontext, disabled, ...rest} = props
    return (
        <button
            {...rest}
            type="button"
            onClick={onClick}
            disabled={disabled}
            className={`inline-block px-6 py-2.5 ${disabled ? 'bg-primary opacity-70' : 'bg-primary'} text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-primary hover:shadow-lg focus:bg-primary focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary active:shadow-lg transition duration-150 ease-in-out`}
        >
            {buttontext}
        </button>

    );
};

PrimaryButton.propTypes = {
    buttontext:PropTypes.string.isRequired,
    disabled:PropTypes.bool
};


export default PrimaryButton;