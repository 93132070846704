import { ACTION_TYPES } from "../../../constants";
import api from "../api";

export const fetchActivityIndex = (date) => ({
  type: ACTION_TYPES.GET_ACTIVITY_INDEX,
  payload: api.fetchActivityIndex(date),
});

export const fetchAllUserURLs = (date, uu_id) => ({
  type: ACTION_TYPES.GET_USER_URLS,
  payload: api.fetchAllUserURLs(date, uu_id),
});

export const fetchallurls = () => ({
  type: ACTION_TYPES.GET_ALL_URLS,
  payload: api.fetchallurls(),
});

export const postURLType = (data) => ({
  type: ACTION_TYPES.POST_URL_TYPE,
  payload: api.postURLType(data),
});

export const editURLType = (data) => ({
  type: ACTION_TYPES.EDIT_URL_TYPE,
  payload: api.editURLType(data),
});
