/* eslint-disable import/no-anonymous-default-export */
import { API_ENDPOINTS } from "../../constants";
import { transport } from "../../Networks/Auth";

export default {
    fetchEmployeeAttendance: (year, month, userId) => {
        let config = {
            url:
                API_ENDPOINTS.GET_EMPLOYEES_ATTENDENCE +
                "?year=" +
                year +
                "&month=" +
                month +
                "&user_id=" +
                userId,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    fetchEmployeeApplicationReport: (startDate, endDate, userId) => {
        let config = {
            url:
                API_ENDPOINTS.GET_EMPLOYEE_APPLICATION_REPORT +
                "?start_date=" +
                startDate +
                "&end_date=" +
                endDate +
                "&username=" +
                userId,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    fetchEmployeeWebsiteReport: (startDate, endDate, userId, type) => {
        let data = "&username=" + userId;
        if (type === "all" || userId === "") {
            data = "&type=all";
        } else {
            data += "&type=user";
        }
        let config = {
            url:
                API_ENDPOINTS.GET_EMPLOYEE_WEBSITE_REPORT +
                "?start_date=" +
                startDate +
                "&end_date=" +
                endDate +
                data,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },
    fetchEmployeeWebsiteReportDashboard: (startDate, endDate) => {
        let config = {
            url:
                API_ENDPOINTS.GET_EMPLOYEE_WEBSITE_REPORT_DASHBOARD +
                "?start_date=" +
                startDate +
                "&end_date=" +
                endDate,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    fetchHourQuickReport: (startDate, endDate) => {
        let config = {
            url: API_ENDPOINTS.HOUR_QUICK_REPORT + startDate + "&enddate=" + endDate,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    fetchHourCustomReport: () => {
        let config = {
            url: API_ENDPOINTS.HOUR_CUSTOM_REPORT,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    downloadHourReport: (startDate, endDate) => {
        let config = {
            url: API_ENDPOINTS.HOUR_REPORT_CSV_DOWNLOAD + startDate + "&enddate=" + endDate,
            method: "GET",
            withCredentials: true,
            responseType: "blob",
        };
        return transport(config);
    },

    fetchAttendanceTimings: () => {
        let config = {
            url: API_ENDPOINTS.ATTENDANCE_TIMING_DETAILS,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    updateAttendanceTimings: (data) => {
        let config = {
            url: API_ENDPOINTS.ATTENDANCE_TIMING_DETAILS,
            method: "POST",
            withCredentials: true,
            data: data,
        };
        return transport(config);
    },
    fetchBookingReportTableLayout: () => {
        let config = {
            url: API_ENDPOINTS.BOOKING_REPORT_TABLE_LAYOUT,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    updateBookingReportTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.BOOKING_REPORT_TABLE_LAYOUT,
            method: "PUT",
            withCredentials: true,
            data: data,
        };
        return transport(config);
    },
    postBookingReportTableLayout: (data) => {
        let config = {
            url: API_ENDPOINTS.BOOKING_REPORT_TABLE_LAYOUT,
            method: "POST",
            withCredentials: true,
            data: data,
        };
        return transport(config);
    },
    getBookingReportTable: (date) => {
        let config = {
            url: API_ENDPOINTS.GET_BOOKING_REPORT + date,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },
    downloadBookingReportTable: (date) => {
        let config = {
            url: API_ENDPOINTS.DOWNLOAD_CSV_BOOKING_REPORT + date,
            method: "POST",
            withCredentials: true,
            responseType: "blob",
        };
        return transport(config);
    },
    fetchBookingReportTableArrangement: () => {
        let config = {
            url: API_ENDPOINTS.BOOKING_REPORT_TABLE_ARRANGEMENT,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    updateBookingReportTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.BOOKING_REPORT_TABLE_ARRANGEMENT,
            method: "PUT",
            withCredentials: true,
            data: data,
        };
        return transport(config);
    },
    postBookingReportTableArrangement: (data) => {
        let config = {
            url: API_ENDPOINTS.BOOKING_REPORT_TABLE_ARRANGEMENT,
            method: "POST",
            withCredentials: true,
            data: data,
        };
        return transport(config);
    },
    getTotalHourReportTable: (date) => {
        let config = {
            url: API_ENDPOINTS.TOTAL_HOURS_REPORT + date,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },
};
