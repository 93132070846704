import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import DateRangeFilter from "./DatePicker";
import {
    fetchBookingReportTableLayout,
    updateBookingReportTableLayout,
    postBookingReportTableLayout,
    getBookingReportTable,
    downloadBookingCSVReport,
    fetchBookingReportTableArrangement,
    updateBookingReportTableArrangement,
    postBookingReportTableArrangement,
} from "../../../services/reports/actions";
import { fetchactiveprojects } from "../../../services/projects/actions";
import FileSaver from "file-saver";

import { FetchactiveTeams } from "../../../services/teams/actions";
//icons
import { CalendarIcon, DownloadIcon } from "@heroicons/react/outline";
import { BsGearFill, BsSearch } from "react-icons/bs";

import { HiFilter } from "react-icons/hi";
import { Checkbox } from "@material-ui/core";
//dependencies
import BeatLoader from "react-spinners/BeatLoader";
import LayoutSetupModal from "../../Dashboard/Modals/LayoutSetupModal";

import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";

function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}

let d = new Date();

let firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
let c = convert(firstDay);

let lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
let cc = convert(lastDay);

class BookingReport_tab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activetab: 1,
            startDate: c,
            endDate: cc,
            empAttendance: [],
            selectedEmployee: "",
            userId: "",
            showDateRange: false,
            showSettingsModal: false,
            workingHours: 0,
            setWorkingHours: 0,
            reportTableLayout: "",
            reportTableArrangement: "",
            isShowFilterModal: false,
            filterTeams: [],
            searchedTeam: "",
            searchedProject:"",
            filterProject:[],
            numRows: 0,
        };
    }

    componentDidMount() {
        this.props.fetchactiveprojects();
        setTimeout(() => {
            window.history.pushState({}, null, `/reports/booking-report`);
        }, 200);
        this.props.fetchBookingReportTableLayout().then((res) => {
            var obj = res.value.data.data;
            var num = Object.keys(obj).reduce(
                (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
                0
            );
            this.setState({
                reportTableLayout: obj,
                numRows: num,
            });
        });
        this.props.fetchBookingReportTableArrangement().then((res) => {
            this.setState({
                reportTableArrangement: res.value.data.data,
            });
        });
        this.props.FetchactiveTeams(localStorage.getItem("org_uuid"));
        this.props.getBookingReportTable(`${this.state.startDate}&last_date=${this.state.endDate}`);
    }

    fetchAttendanceTiming = () => {
        this.props.fetchAttendanceTimings().then((res) => {
            if (res.value.status === 200) {
                this.setState({
                    workingHours: res.value.data.number,
                });
            } else {
                this.setState({
                    workingHours: 480,
                });
            }
        });
    };

    onSubmitClickHandler = (data, arrangementData) => {
        const newData = {
            team_name: data[arrangementData["team_name"] - 1]["status"] ? "True" : "False",
            project_name: data[arrangementData["project_name"] - 1]["status"] ? "True" : "False",
            description: data[arrangementData["description"] - 1]["status"] ? "True" : "False",
            hours_booked: data[arrangementData["hours_booked"] - 1]["status"] ? "True" : "False",
            hours_remaining: data[arrangementData["hours_remaining"] - 1]["status"]
                ? "True"
                : "False",
            hours_rolled_over: data[arrangementData["hours_rolled_over"] - 1]["status"]
                ? "True"
                : "False",
        };

        const newArrangement = {
            team_name: arrangementData["team_name"],
            project_name: arrangementData["project_name"],
            description: arrangementData["description"],
            hours_per_month: arrangementData["hours_per_month"],
            hours_booked: arrangementData["hours_booked"],
            hours_reamining: arrangementData["hours_remaining"],
            hours_rolled_over: arrangementData["hours_rolled_over"],
        };
        if (this.state.reportTableArrangement["edit"]) {
            this.props.updateBookingReportTableArrangement(newArrangement).then((response) => {
                if (response.value.status === 200) {
                    this.setState({ reportTableArrangement: arrangementData });
                }
            });
        } else {
            this.props.postBookingReportTableArrangement(newArrangement).then((response) => {
                if (response.value.status === 200) {
                    this.setState({ reportTableArrangement: arrangementData });
                }
            });
        }

        if (this.state.reportTableLayout["edit"]) {
            this.props.updateBookingReportTableLayout(newData).then((response) => {
                if (response.value.status === 200) {
                    this.props.fetchBookingReportTableLayout().then((res) => {
                        var obj = res.value.data.data;
                        var num = Object.keys(obj).reduce(
                            (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
                            0
                        );
                        this.setState({
                            reportTableLayout: obj,
                            numRows: num,
                            showSettingsModal: false,
                        });
                    });
                }
            });
        } else {
            this.props.postBookingReportTableLayout(newData).then((response) => {
                if (response.value.status === 200) {
                    this.props.fetchBookingReportTableLayout().then((res) => {
                        var obj = res.value.data.data;
                        var num = Object.keys(obj).reduce(
                            (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
                            0
                        );
                        this.setState({
                            reportTableLayout: obj,
                            numRows: num,
                            showSettingsModal: false,
                        });
                    });
                }
            });
        }
    };
    render() {
        const { t } = this.props;

        const handeThisMonth = () => {
            this.setState({ startDate: c, endDate: cc }, () => {
                this.props.getBookingReportTable(`${c}&last_date=${cc}`);
            });
        };
        const teams =
            this.props.teams && this.props.teams.ActiveTeam && this.props.teams.ActiveTeam.info;
        let activeprojects =
            this.props.projects &&
            this.props.projects.ActiveProjects &&
            this.props.projects.ActiveProjects.response_data;
        let activeProjectsList=
            activeprojects &&
            activeprojects.map((el)=>{
                return {
                    value:el.project_id,label:el.project_name
                }
            })
        const teamsList =
            teams &&
            teams.map((el) => {
                return { value: el.team.team_uuid, label: el.team.name };
            });
        const reportsData = this.props.attendance.BookingReportTable;
        const onChange = (ranges) => {
            let dd = convert(ranges.startDate);
            let rr = convert(ranges.endDate);
            this.setState({ startDate: dd, endDate: rr });
            this.props.getBookingReportTable(`${dd}&last_date=${rr}`);
        };

        const exportXLSX = () => {
            this.props.downloadBookingCSVReport(`${this.state.startDate}`).then((data) => {
                if (data.value.status === 200) {
                    var blob = new Blob([data.value.data], { type: "application/xlsx" });
                    FileSaver.saveAs(blob, `Booking_report_${this.state.startDate}.xlsx`);
                }
            });
        };

        return (
            <div>
                <div className="flex justify-between items-start mb-8 lg:items-center flex-col lg:flex-row mt-5">
                    {/* <div className='flex rounded-full justify-center -translate-y-2 items-center mt-5 bg-white'>
                    <div className={`transition ease-in-out delay-110  ${this.state.activetab == 1 ? "bg-primary text-white" : "bg-white text-gray-500"} font-medium p-2 px-4 rounded-full cursor-pointer`} onClick={() => {this.setState({activetab: 1})}}>Overview</div>
                    <div className={`transition ease-in-out delay-110  ${this.state.activetab == 2 ? "bg-primary text-white" : "bg-white text-gray-500"} font-medium p-2 px-4 w-full rounded-full cursor-pointer`} onClick={() => {this.setState({activetab: 2})}}>Detailed</div>
                </div> */}
                    <div className="flex flex-col sm:flex-row">
                        <button
                            className="bg-secondary rounded-full p-2 px-5 w-32 mb-3 sm:mb-0 mt-4 sm:mt-0 sm:w-auto text-white font-medium mr-3"
                            onClick={() => {
                                handeThisMonth();
                            }}>
                            {t("ReportsTab.this")} {t("ReportsTab.month")}
                        </button>
                        <div className="gap-2 px-3 py-2 bg-white flex items-center rounded-full justify-start cursor-pointer">
                          <CalendarIcon
                           className="h-6 w-6"
                           onClick={() => {
                           this.setState({ showDateRange: true });
                          }}
                         />
                         <input
                        value={`${new Date(
                        this.state.startDate
                           ).toDateString()} - ${new Date(
                             this.state.endDate
                        ).toDateString()}`}
                        disabled
                        className="w-[260px] bg-white rounded-full font-medium text-gray-600"
                         onClick={() => {
                        this.setState({ showDateRange: true });
                        }}
                         />
             
            </div>
                    </div>
                </div>

                <div className="bg-white px-4 py-5 mt-4 border-b border-gray-200 sm:px-6 rounded-lg">
                    <div className="flex relative justify-between items-center mt-2">
                        <div className=" font-semibold text-2xl">
                            {t("ReportsTab.booking")} {t("ReportsTab.report")}
                        </div>
                        <div className=" flex items-center">
                            <div>
                                <HiFilter
                                    className="h-6 w-6 mr-2 cursor-pointer"
                                    onClick={() => {
                                        this.setState({
                                            isShowFilterModal: !this.state.isShowFilterModal,
                                            // searchedTeam: "",
                                            searchedProject: "",
                                        });
                                    }}
                                />
                            </div>
                            <div>
                                <BsGearFill
                                    className="h-5 w-5 mr-2 cursor-pointer"
                                    onClick={() => {
                                        this.setState({ showSettingsModal: true });
                                    }}
                                />
                            </div>
                            <div>
                                <DownloadIcon
                                    className="h-6 w-6 cursor-pointer"
                                    onClick={() => {
                                        exportXLSX();
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className={`absolute right-12 top-10 shadow-md ring-2 bg-white p-4 z-10 ring-black ring-opacity-5 border-0 rounded-xl w-[320px] max-w-[320px] ${
                                this.state.isShowFilterModal ? "" : "hidden"
                            }`}
                            // className={`absolute right-12 top-10 shadow-md ring-2 bg-white p-4 z-10 ring-black ring-opacity-5 border-0 rounded-xl w-[320px] max-w-[320px]`}
                        >
                            <div className=" text-xl mb-2">{t("ReportsTab.filter")}</div>
                            {/* <div className="text-gray-500 my-2">{t("teams")}</div> */}
                            <div className="text-gray-500 my-2">{t("projects")}</div>
                            <div className="relative mb-1">
                                <div className="absolute text-textprimary top-2.5 left-2 h-4 w-4">
                                    <BsSearch className="w-full h-full" />
                                </div>
                                {/* <input
                                    className="w-full rounded-md border-primary text-xs py-1 pl-8"
                                    type="text"
                                    value={this.state.searchedTeam}
                                    onChange={(ev) => {
                                        this.setState({ searchedTeam: ev.target.value });
                                    }}
                                /> */}
                                <input
                                    className="w-full rounded-md border-primary text-xs py-1 pl-8"
                                    type="text"
                                    value={this.state.searchedProject}
                                    onChange={(ev) => {
                                        this.setState({ searchedProject: ev.target.value });
                                    }}
                                />
                            </div>
                            <div className=" text-sm text-gray-500 my-2">
                                {/* {t("ReportsTab.suggested")} {t("teams")} */}
                                {t("ReportsTab.suggested")} {t("projects")}
                            </div>
                            {/* {teamsList &&
                                teamsList.map((team) => {
                                    if (
                                        this.state.searchedTeam !== "" &&
                                        !team.label
                                            .toLowerCase()
                                            .includes(this.state.searchedTeam.toLowerCase())
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <div key={team.label + team.value}>
                                            <div>
                                                <Checkbox
                                                    id={team.value}
                                                    // checked={false}
                                                    defaultChecked={false}
                                                    style={{
                                                        color: "#684D94",
                                                        padding: "4px",
                                                    }}
                                                    onChange={(ev) => {
                                                        let teamsArr = [...this.state.filterTeams];
                                                        if (ev.target.checked) {
                                                            teamsArr.push(team.label.toLowerCase());
                                                        } else {
                                                            teamsArr =
                                                                this.state.filterTeams.filter(
                                                                    (val) => {
                                                                        return (
                                                                            val.toLowerCase() !==
                                                                            team.label.toLowerCase()
                                                                        );
                                                                    }
                                                                );
                                                        }
                                                        this.setState({ filterTeams: teamsArr });
                                                    }}
                                                />
                                                <label htmlFor="teamName" className="capitalize">
                                                    {team.label}
                                                </label>
                                            </div>
                                        </div>
                                    );
                                })} */}
                                {activeProjectsList &&
                                    activeProjectsList.map((project) => {
                                    if (
                                        this.state.searchedProject !== "" &&
                                        !project.label
                                            .toLowerCase()
                                            .includes(this.state.searchedProject.toLowerCase())
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <div key={project.label + project.value}>
                                            <div>
                                                <Checkbox
                                                    id={`${project.value}`}
                                                    // checked={false}
                                                    defaultChecked={false}
                                                    style={{
                                                        color: "#684D94",
                                                        padding: "4px",
                                                    }}
                                                    onChange={(ev) => {
                                                        let projectsArr = [
                                                            ...this.state.filterProject,
                                                        ];
                                                        if (ev.target.checked) {
                                                            projectsArr.push(
                                                                project.label.toLowerCase()
                                                            );
                                                        } else {
                                                            projectsArr =
                                                                this.state.filterProject.filter(
                                                                    (val) => {
                                                                        return (
                                                                            val.toLowerCase() !==
                                                                            project.label.toLowerCase()
                                                                        );
                                                                    }
                                                                );
                                                        }
                                                        this.setState({
                                                            filterProject: projectsArr,
                                                        });
                                                    }}
                                                />

                                                <label htmlFor="teamName" className="capitalize">
                                                    {project.label}
                                                </label>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <div
                        className="mt-4 flex flex-col overflow-y-auto bg-gray-100"
                        style={{ maxHeight: "auto" }}>
                        <div className="inline-block min-w-full align-middle">
                            <div className="shadow-sm ring-1 ring-black ring-opacity-5 border-0 rounded-xl max-h-[580px] overflow-auto">
                                <table className="min-w-max w-full border-separate rounded-xl">
                                    <thead className="bg-gray-50 min-w-full sticky top-0 z-10">
                                        <tr
                                            className={`grid grid-cols-${this.state.numRows} min-w-full`}>
                                            {this.state.reportTableLayout["project_name"] ? (
                                                <th
                                                    scope="col"
                                                    className={`order-${this.state.reportTableArrangement["project_name"]} border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 px-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                    {t("project")} {t("name")}
                                                </th>
                                            ) : null}
                                            {this.state.reportTableLayout["team_name"] ? (
                                                <th
                                                    scope="col"
                                                    className={`order-${this.state.reportTableArrangement["team_name"]} border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell`}>
                                                    {t("team")} {t("name")}
                                                </th>
                                            ) : null}
                                            {this.state.reportTableLayout["description"] ? (
                                                <th
                                                    scope="col"
                                                    className={`order-${this.state.reportTableArrangement["description"]} border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell`}>
                                                    {t("description")}
                                                </th>
                                            ) : null}
                                            {this.state.reportTableLayout["hours_booked"] ? (
                                                <th
                                                    scope="col"
                                                    className={`order-${this.state.reportTableArrangement["hours_booked"]} border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell`}>
                                                    {t("ProjectTasksTab.hoursBooked")}
                                                </th>
                                            ) : null}
                                            {this.state.reportTableLayout["hours_remaining"] ? (
                                                <th
                                                    scope="col"
                                                    className={`order-${this.state.reportTableArrangement["hours_remaining"]} border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell`}>
                                                    {t("ProjectTasksTab.hoursRemaining")}
                                                </th>
                                            ) : null}
                                            {this.state.reportTableLayout["hours_rolled_over"] ? (
                                                <th
                                                    scope="col"
                                                    className={`order-${this.state.reportTableArrangement["hours_rolled_over"]} border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell`}>
                                                    {t("ProjectTasksTab.hoursRolledOver")}
                                                </th>
                                            ) : null}
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {/* {reportsData && reportsData.length > 0 ? (
                                            reportsData.map((reportRow) => {
                                                if (
                                                    this.state.filterTeams.length > 0 &&
                                                    !this.state.filterTeams.includes(
                                                        reportRow["team_name"].toLowerCase()
                                                    )
                                                ) {
                                                    return null;
                                                }
                                                return (
                                                    <tr
                                                        className={`grid grid-cols-${this.state.numRows} min-w-full`}
                                                        key={
                                                            reportRow["project_name"] +
                                                            reportRow["team_name"]
                                                        }>
                                                        {this.state.reportTableLayout[
                                                            "project_name"
                                                        ] ? (
                                                            <td
                                                                className={`order-${this.state.reportTableArrangement["project_name"]} py-4 px-3 text-base font-medium text-primary`}>
                                                                <Link to="/projects">
                                                                    {reportRow["project_name"]}
                                                                </Link>
                                                            </td>
                                                        ) : null}
                                                        {this.state.reportTableLayout[
                                                            "team_name"
                                                        ] ? (
                                                            <td
                                                                className={`order-${this.state.reportTableArrangement["team_name"]} px-3 py-4 text-base text-gray-500 sm:table-cell`}>
                                                                {reportRow["team_name"]}
                                                            </td>
                                                        ) : null}
                                                        {this.state.reportTableLayout[
                                                            "description"
                                                        ] ? (
                                                            <td
                                                                className={`order-${this.state.reportTableArrangement["description"]} px-3 py-4 text-base text-gray-500 sm:table-cell`}>
                                                                {reportRow["description"]}
                                                            </td>
                                                        ) : null}
                                                        {this.state.reportTableLayout[
                                                            "hours_booked"
                                                        ] ? (
                                                            <td
                                                                className={`order-${this.state.reportTableArrangement["hours_booked"]} px-3 py-4 text-base text-gray-500 sm:table-cell`}>
                                                                {reportRow["hours_booked"]}
                                                            </td>
                                                        ) : null}
                                                        {this.state.reportTableLayout[
                                                            "hours_remaining"
                                                        ] ? (
                                                            <td
                                                                className={`order-${this.state.reportTableArrangement["hours_remaining"]} px-3 py-4 text-base text-gray-500 sm:table-cell`}>
                                                                {reportRow["hours_remaining"]}
                                                            </td>
                                                        ) : null}
                                                        {this.state.reportTableLayout[
                                                            "hours_rolled_over"
                                                        ] ? (
                                                            <td
                                                                className={`order-${this.state.reportTableArrangement["hours_rolled_over"]} px-3 py-4 text-base text-gray-500 lg:table-cell`}>
                                                                {reportRow["hours_rolled"]}
                                                            </td>
                                                        ) : null}
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr className="min-w-full flex justify-center">
                                                <td className="text-center flex-1">
                                                    <BeatLoader />
                                                </td>
                                            </tr>
                                        )} */}

                                        {reportsData && reportsData.length > 0 ? (
                                            this.state.filterProject.length === 0 ? (
                                                reportsData?.sort((a,b)=>a.project_name.localeCompare(b.project_name)).map((reportRow) => {
                                                    return (
                                                        <tr
                                                            className={`grid grid-cols-${this.state.numRows} min-w-full`}
                                                            key={
                                                                reportRow["project_name"] +
                                                                reportRow["team_name"]
                                                            }>
                                                            {this.state.reportTableLayout[
                                                                "project_name"
                                                            ] ? (
                                                                <td
                                                                    className={`order-${this.state.reportTableArrangement["project_name"]} py-4 px-3 text-base font-medium text-primary`}>
                                                                    <Link to="/projects">
                                                                        {reportRow["project_name"]}
                                                                    </Link>
                                                                </td>
                                                            ) : null}
                                                            {this.state.reportTableLayout[
                                                                "team_name"
                                                            ] ? (
                                                                <td
                                                                    className={`order-${this.state.reportTableArrangement["team_name"]} px-3 py-4 text-base text-gray-500 sm:table-cell`}>
                                                                    {reportRow["team_name"]}
                                                                </td>
                                                            ) : null}
                                                            {this.state.reportTableLayout[
                                                                "description"
                                                            ] ? (
                                                                <td
                                                                    className={`order-${this.state.reportTableArrangement["description"]} px-3 py-4 text-base text-gray-500 sm:table-cell`}>
                                                                    {reportRow["description"]}
                                                                </td>
                                                            ) : null}
                                                            {this.state.reportTableLayout[
                                                                "hours_booked"
                                                            ] ? (
                                                                <td
                                                                    className={`order-${this.state.reportTableArrangement["hours_booked"]} px-3 py-4 text-base text-gray-500 sm:table-cell`}>
                                                                    {reportRow["hours_booked"]}
                                                                </td>
                                                            ) : null}
                                                            {this.state.reportTableLayout[
                                                                "hours_remaining"
                                                            ] ? (
                                                                <td
                                                                    className={`order-${this.state.reportTableArrangement["hours_remaining"]} px-3 py-4 text-base text-gray-500 sm:table-cell`}>
                                                                    {reportRow["hours_remaining"]}
                                                                </td>
                                                            ) : null}
                                                            {this.state.reportTableLayout[
                                                                "hours_rolled_over"
                                                            ] ? (
                                                                <td
                                                                    className={`order-${this.state.reportTableArrangement["hours_rolled_over"]} px-3 py-4 text-base text-gray-500 lg:table-cell`}>
                                                                    {reportRow["hours_rolled"]}
                                                                </td>
                                                            ) : null}
                                                        </tr>
                                                    )
                                                })
                                            ) :
                                                (
                                                    this.state.filterProject.length > 0 &&
                                                        reportsData.some((reportRow) =>
                                                            this.state.filterProject.includes(reportRow["project_name"].toLowerCase())
                                                        ) ?
                                                        (
                                                            reportsData.map((reportRow) => {
                                                                const projectInFilter = this.state.filterProject.includes(
                                                                    reportRow["project_name"].toLowerCase()
                                                                );
                                                                if (projectInFilter) {

                                                                    return (
                                                                        <tr
                                                                            className={`grid grid-cols-${this.state.numRows} min-w-full`}
                                                                            key={
                                                                                reportRow["project_name"] +
                                                                                reportRow["team_name"]
                                                                            }>
                                                                            {this.state.reportTableLayout[
                                                                                "project_name"
                                                                            ] ? (
                                                                                <td
                                                                                    className={`order-${this.state.reportTableArrangement["project_name"]} py-4 px-3 text-base font-medium text-primary`}>
                                                                                    <Link to="/projects">
                                                                                        {reportRow["project_name"]}
                                                                                    </Link>
                                                                                </td>
                                                                            ) : null}
                                                                            {this.state.reportTableLayout[
                                                                                "team_name"
                                                                            ] ? (
                                                                                <td
                                                                                    className={`order-${this.state.reportTableArrangement["team_name"]} px-3 py-4 text-base text-gray-500 sm:table-cell`}>
                                                                                    {reportRow["team_name"]}
                                                                                </td>
                                                                            ) : null}
                                                                            {this.state.reportTableLayout[
                                                                                "description"
                                                                            ] ? (
                                                                                <td
                                                                                    className={`order-${this.state.reportTableArrangement["description"]} px-3 py-4 text-base text-gray-500 sm:table-cell`}>
                                                                                    {reportRow["description"]}
                                                                                </td>
                                                                            ) : null}
                                                                            {this.state.reportTableLayout[
                                                                                "hours_booked"
                                                                            ] ? (
                                                                                <td
                                                                                    className={`order-${this.state.reportTableArrangement["hours_booked"]} px-3 py-4 text-base text-gray-500 sm:table-cell`}>
                                                                                    {reportRow["hours_booked"]}
                                                                                </td>
                                                                            ) : null}
                                                                            {this.state.reportTableLayout[
                                                                                "hours_remaining"
                                                                            ] ? (
                                                                                <td
                                                                                    className={`order-${this.state.reportTableArrangement["hours_remaining"]} px-3 py-4 text-base text-gray-500 sm:table-cell`}>
                                                                                    {reportRow["hours_remaining"]}
                                                                                </td>
                                                                            ) : null}
                                                                            {this.state.reportTableLayout[
                                                                                "hours_rolled_over"
                                                                            ] ? (
                                                                                <td
                                                                                    className={`order-${this.state.reportTableArrangement["hours_rolled_over"]} px-3 py-4 text-base text-gray-500 lg:table-cell`}>
                                                                                    {reportRow["hours_rolled"]}
                                                                                </td>
                                                                            ) : null}
                                                                        </tr>
                                                                    )
                                                                }
                                                                else {
                                                                    return null
                                                                }
                                                            })
                                                        ) : (
                                                            <tr className="min-w-full flex justify-center">
                                                                <td className="text-lg text-center font-bold p-4">
                                                                    No data found
                                                                </td>
                                                            </tr>
                                                        )

                                                )

                                        ) : (
                                            <tr className="min-w-full flex justify-center">
                                                <td className="text-center flex-1">
                                                    <BeatLoader />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <LayoutSetupModal
                    open={this.state.showSettingsModal}
                    onCancelClick={() => {
                        this.setState({ showSettingsModal: false });
                    }}
                    modalArrangement={this.state.reportTableArrangement}
                    modalData={this.state.reportTableLayout}
                    onSubmitClick={this.onSubmitClickHandler}
                    modalHeading="Table Settings"
                />
                <Transition.Root show={this.state.showDateRange} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed z-10 inset-0 overflow-y-auto"
                        onClose={() => {
                            this.setState({ showDateRange: false });
                        }}>
                        <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[600px] sm:w-full sm:p-6">
                                    <DateRangeFilter onChange={onChange} />
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    allEmployees: state.projects,
    attendance: state.report,
    teams: state.teams,
    projects: state.projects
});

const mapDispatchToProps = (dispatch) => ({
    fetchBookingReportTableLayout: () => dispatch(fetchBookingReportTableLayout()),
    updateBookingReportTableLayout: (data) => dispatch(updateBookingReportTableLayout(data)),
    postBookingReportTableLayout: (data) => dispatch(postBookingReportTableLayout(data)),
    FetchactiveTeams: (org_uuid) => dispatch(FetchactiveTeams(org_uuid)),
    getBookingReportTable: (date) => dispatch(getBookingReportTable(date)),
    downloadBookingCSVReport: (date) => dispatch(downloadBookingCSVReport(date)),
    fetchBookingReportTableArrangement: () => dispatch(fetchBookingReportTableArrangement()),
    updateBookingReportTableArrangement: (data) =>
        dispatch(updateBookingReportTableArrangement(data)),
    postBookingReportTableArrangement: (data) => dispatch(postBookingReportTableArrangement(data)),
    fetchactiveprojects: () => dispatch(fetchactiveprojects()),
});

export default withTranslation("common")(
    connect(mapStateToProps, mapDispatchToProps)(BookingReport_tab)
);
