import { ACTION_TYPES } from "../../../constants";

const activityIndex = (state = {}, action) => {
  switch (action.type) {
    //activityIndex
    case ACTION_TYPES.GET_ACTIVITY_INDEX_PENDING: {
      return {
        ...state,
        isFetchingActivityIndex: true,
        isFetchingActivityIndexFailed: false,
        ActivityFetchError: undefined,
        ActivityIndex: [],
      };
    }
    case ACTION_TYPES.GET_ACTIVITY_INDEX_FULFILLED: {
      return {
        ...state,
        isFetchingActivityIndex: false,
        isFetchingActivityIndexFailed: false,
        ActivityFetchError: undefined,
        ActivityIndex: action.payload.data,
      };
    }
    case ACTION_TYPES.GET_ACTIVITY_INDEX_REJECTED: {
      return {
        ...state,
        isFetchingActivityIndex: false,
        isFetchingActivityIndexFailed: true,
        ActivityFetchError: action.payload,
        ActivityIndex: [],
      };
    }

    //User urls
    case ACTION_TYPES.GET_USER_URLS_PENDING: {
      return {
        ...state,
        isFetchingUserURLs: true,
        isFetchingUserURLsFailed: false,
        UserURLsFetchError: undefined,
        UserURLs: [],
      };
    }
    case ACTION_TYPES.GET_USER_URLS_FULFILLED: {
      return {
        ...state,
        isFetchingUserURLs: false,
        isFetchingUserURLsFailed: false,
        UserURLsFetchError: undefined,
        UserURLs: action.payload.data,
      };
    }
    case ACTION_TYPES.GET_USER_URLS_REJECTED: {
      return {
        ...state,
        isFetchingUserURLs: false,
        isFetchingUserURLsFailed: true,
        UserURLsFetchError: action.payload,
        UserURLs: [],
      };
    }

    //ALL URLS
    case ACTION_TYPES.GET_ALL_URLS_PENDING: {
      return {
        ...state,
        isFetchingAllURLs: true,
        isFetchingAllURLsFailed: false,
        AllURLsFetchError: undefined,
        AllURLs: [],
      };
    }

    case ACTION_TYPES.GET_ALL_URLS_FULFILLED: {
      return {
        ...state,
        isFetchingAllURLs: false,
        isFetchingAllURLsFailed: false,
        AllURLsFetchError: undefined,
        AllURLs: action.payload.data,
      };
    }

    case ACTION_TYPES.GET_ALL_URLS_REJECTED: {
      return {
        ...state,
        isFetchingAllURLs: false,
        isFetchingAllURLsFailed: true,
        AllURLsFetchError: action.payload,
        AllURLs: [],
      };
    }

    //POST URL TYPE
    case ACTION_TYPES.POST_URL_TYPE_PENDING: {
      return {
        ...state,
        isPostingURLType: true,
        isPostingURLTypeFailed: false,
        PostURLTypeFetchError: undefined,
        PostURLType: [],
      };
    }

    case ACTION_TYPES.POST_URL_TYPE_FULFILLED: {
      return {
        ...state,
        isPostingURLType: false,
        isPostingURLTypeFailed: false,
        PostURLTypeFetchError: undefined,
        PostURLType: action.payload.data,
      };
    }

    case ACTION_TYPES.POST_URL_TYPE_REJECTED: {
      return {
        ...state,
        isPostingURLType: false,
        isPostingURLTypeFailed: true,
        PostURLTypeFetchError: action.payload,
        PostURLType: [],
      };
    }

    //EDIT URL TYPE
    case ACTION_TYPES.EDIT_URL_TYPE_PENDING: {
      return {
        ...state,
        isEditingURLType: true,
        isEditingURLTypeFailed: false,
        EditURLTypeFetchError: undefined,
        EditURLType: [],
      };
    }

    case ACTION_TYPES.EDIT_URL_TYPE_FULFILLED: {
      return {
        ...state,
        isEditingURLType: false,
        isEditingURLTypeFailed: false,
        EditURLTypeFetchError: undefined,
        EditURLType: action.payload.data,
      };
    }

    case ACTION_TYPES.EDIT_URL_TYPE_REJECTED: {
      return {
        ...state,
        isEditingURLType: false,
        isEditingURLTypeFailed: true,
        EditURLTypeFetchError: action.payload,
        EditURLType: [],
      };
    }

    default:
      return state;
  }
};

export default activityIndex;
