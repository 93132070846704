import { ACTION_TYPES } from '../../../constants/index';

const empDashboard = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.EMP_LOGIN_DETAILS_PENDING: {
            return {
                ...state,
                isFetchingEmpLoginDetails: true,
                isFetchingEmpLoginDetailsFailed: false,
                EmpLoginDetailsFetchError: undefined,
                EmpLoginDetails: {},
            };
        }

        case ACTION_TYPES.EMP_LOGIN_DETAILS_FULFILLED: {
            return {
                ...state,
                isFetchingEmpLoginDetails: false,
                isFetchingEmpLoginDetailsFailed: false,
                EmpLoginDetailsFetchError: undefined,
                EmpLoginDetails: action.payload.data,
            };
        }

        case ACTION_TYPES.EMP_LOGIN_DETAILS_REJECTED: {
            return {
                ...state,
                isFetchingEmpLoginDetails: false,
                isFetchingEmpLoginDetailsFailed: true,
                EmpLoginDetailsFetchError: action.payload,
                EmpLoginDetails: {},
            };
        }

        //employee dsahboard

        case ACTION_TYPES.EMP_DASHBOARD_DETAILS_PENDING: {
            return {
                ...state,
                isFetchingEmpDashboard: true,
                isFetchingEmpDashboardFailed: false,
                EmpDashboardFetchError: undefined,
                EmpDashboard: [],
            };
        }

        case ACTION_TYPES.EMP_DASHBOARD_DETAILS_FULFILLED: {
            return {
                ...state,
                isFetchingEmpDashboard: false,
                isFetchingEmpDashboardFailed: false,
                EmpDashboardFetchError: undefined,
                EmpDashboard: action.payload.data,
            };
        }

        case ACTION_TYPES.EMP_DASHBOARD_DETAILS_REJECTED: {
            return {
                ...state,
                isFetchingEmpDashboard: false,
                isFetchingEmpDashboardFailed: true,
                EmpDashboardFetchError: action.payload,
                EmpDashboard: [],
            };
        }

        //employe summary

        case ACTION_TYPES.EMP_SUMMARY_DETAILS_PENDING: {
            return {
                ...state,
                isFetchingEmpSummary: true,
                isFetchingEmpSummaryFailed: false,
                EmpSummaryFetchError: undefined,
                EmpSummary: [],
            };
        }

        case ACTION_TYPES.EMP_SUMMARY_DETAILS_FULFILLED: {
            return {
                ...state,
                isFetchingEmpSummary: false,
                isFetchingEmpSummaryFailed: false,
                EmpSummaryFetchError: undefined,
                EmpSummary: action.payload.data,
            };
        }

        case ACTION_TYPES.EMP_SUMMARY_DETAILS_REJECTED: {
            return {
                ...state,
                isFetchingEmpSummary: false,
                isFetchingEmpSummaryFailed: true,
                EmpSummaryFetchError: action.payload,
                EmpSummary: [],
            };
        }

        //employee leave request

        case ACTION_TYPES.CREATE_LEAVE_REQUEST_PENDING: {
            return {
                ...state,
                isCreatingLeaveRequest: true,
                isCreatingLeaveRequestFailed: false,
                LeaveRequestCreationError: undefined,
                LeaveRequest: [],
            };
        }

        case ACTION_TYPES.CREATE_LEAVE_REQUEST_FULFILLED: {
            return {
                ...state,
                isCreatingLeaveRequest: false,
                isCreatingLeaveRequestFailed: false,
                LeaveRequestCreationError: undefined,
                LeaveRequest: action.payload.data,
            };
        }

        case ACTION_TYPES.CREATE_LEAVE_REQUEST_REJECTED: {
            return {
                ...state,
                isCreatingLeaveRequest: false,
                isCreatingLeaveRequestFailed: true,
                LeaveRequestCreationError: action.payload,
                LeaveRequest: [],
            };
        }

        //delete leave request

        case ACTION_TYPES.DELETE_LEAVE_REQUEST_PENDING: {
            return {
                ...state,
                isDeletingLeaveRequest: true,
                isDeletingLeaveRequestFailed: false,
                LeaveRequestDeletionError: undefined,
                LeaveRequest: [],
            };
        }

        case ACTION_TYPES.DELETE_LEAVE_REQUEST_FULFILLED: {
            return {
                ...state,
                isDeletingLeaveRequest: false,
                isDeletingLeaveRequestFailed: false,
                LeaveRequestDeletionError: undefined,
                LeaveRequest: action.payload.data,
            };
        }

        case ACTION_TYPES.DELETE_LEAVE_REQUEST_REJECTED: {
            return {
                ...state,
                isDeletingLeaveRequest: false,
                isDeletingLeaveRequestFailed: true,
                LeaveRequestDeletionError: action.payload,
                LeaveRequest: [],
            };
        }

        //user projects

        case ACTION_TYPES.GET_USER_PROJECTS_PENDING: {
            return {
                ...state,
                isFetchingUserProjects: true,
                isFetchingUserProjectsFailed: false,
                UserProjectsFetchError: undefined,
                UserProjects: [],
            };
        }

        case ACTION_TYPES.GET_USER_PROJECTS_FULFILLED: {
            return {
                ...state,
                isFetchingUserProjects: false,
                isFetchingUserProjectsFailed: false,
                UserProjectsFetchError: undefined,
                UserProjects: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_USER_PROJECTS_REJECTED: {
            return {
                ...state,
                isFetchingUserProjects: false,
                isFetchingUserProjectsFailed: true,
                UserProjectsFetchError: action.payload,
                UserProjects: [],
            };
        }

        case ACTION_TYPES.GET_DAILY_ACTITVITY_LIST_PENDING: {
            return {
                ...state,
                isFetchingDailyActivityList: true,
                isFetchingDailyActivityListFailed: false,
                dailyActivityListFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_DAILY_ACTITVITY_LIST_FULFILLED: {
            return {
                ...state,
                isFetchingDailyActivityList: false,
                isFetchingDailyActivityListFailed: false,
                dailyActivityListFetchError: undefined,
                dailyActivityList: action.payload.data.data,
            };
        }

        case ACTION_TYPES.GET_DAILY_ACTITVITY_LIST_REJECTED: {
            return {
                ...state,
                isFetchingDailyActivityList: false,
                isFetchingDailyActivityListFailed: true,
                dailyActivityListFetchError: action.payload,
                dailyActivityList: [],
            };
        }

        default: {
            return state;
        }
    }
}

export default empDashboard;