import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../../Components/Layout';
import { fetchactiveprojects, fetchactiveTasks, fetchallEmployees } from '../../services/projects/actions';
import { FetchactiveTeams } from '../../services/teams/actions';
import PrimaryButton from '../../Components/PrimaryButton';
import ConfirmationDialogue from "../../Components/ConfirmationDialogue";
import InputField from '../../Components/InputField';
import SelectField from '../../Components/SelectField';
import Pagination from '@mui/material/Pagination';
import '../../styles/global.css';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


class ReusableComponents extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            name: '',
            selectname: '',
            pageFrom: 1,
            perPage: 2,
            page: 1
        }
    }

    componentDidMount() {
        const org_uuid = localStorage.getItem("org_uuid");
        this.props.fetchactiveprojects()
        this.props.fetchactiveTasks()
        this.props.fetchallEmployees()
        this.props.FetchactiveTeams(org_uuid);
    }

    OpenModal = () => {
        this.setState({ open: true })
    }
    onModalClose = () => {
        this.setState({ open: false })
    }
    onModalSubmit = () => {
        this.setState({ open: false })
    }

    onHandleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            console.log(this.state.selectname)
        })
    }

    handlePageChange = (e, val) => {
        this.setState({
            pageFrom: ((val * this.state.perPage) - (this.state.perPage - 1)),
            page: val
        })
    }

    paginate(array, page_size, page_number) {
        return array.slice((page_number - 1) * page_size, page_number * page_size)
    }


    render() {

        let activeprojects = this.props.projects && this.props.projects.ActiveProjects && this.props.projects.ActiveProjects.response_data
        let activetasks = this.props.projects && this.props.projects.Activetasks;
        let employees = this.props.projects && this.props.projects.AllEmployees;
        let teams = this.props.teams && this.props.teams.ActiveTeam && this.props.teams.ActiveTeam.info;
        let lengthofActiveTeams = teams && teams.length;
        let SelectData = employees && employees.map(el => ({
            name: el.user_first_name,
            id: el.user_id
        }))
        const people = [
            { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
            { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
            { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
            { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
            { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },


            // More people...
        ]
        return (
            <Layout>
                <div>
                    <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Confirmation Modal</h3>
                            </div>
                            <div className="ml-4 mt-2 flex-shrink-0">
                                <PrimaryButton onClick={() => this.OpenModal()} buttontext="Open Modal"></PrimaryButton>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white px-4 py-5 mt-4 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Input Field</h3>
                            </div>
                            <div className="ml-4 mt-2 flex-shrink-0">
                                <InputField
                                    onInputChange={(e) => this.onHandleChange(e)}
                                    FieldName={"Name"}
                                    name={"name"}
                                    type={"text"}
                                    id={"name"}
                                    placeholder={""}>
                                </InputField>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white px-4 py-5 mt-4 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Select Field</h3>
                            </div>
                            <div className="ml-4 mt-2 flex-shrink-0">
                                <SelectField
                                    onSelectChange={(e) => this.onHandleChange(e)}
                                    FieldName={"Name"}
                                    name={"selectname"}
                                    arrayOfData={SelectData}
                                    id={"name"}
                                >
                                </SelectField>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white px-4 py-5 mt-4 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Pagination</h3>
                            </div>
                            <div className="ml-4 mt-2 flex-shrink-0 paginationstyle">
                               <Pagination count={Math.ceil(lengthofActiveTeams / this.state.perPage)} page={this.state.page} onChange={this.handlePageChange}></Pagination>
                            </div>
                        </div>
                    </div>

                   
                    <div className="bg-white px-4 py-5 mt-4 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div className="mt-2 flex flex-col overflow-y-auto bg-gray-100" style={{ maxHeight: "200px" }}>
                            <div className="inline-block min-w-full align-middle">
                                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                    <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12"
                                                >
                                                    Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                                >
                                                    Title
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                                                >
                                                    Email
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                                >
                                                    Role
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12"
                                                >
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {people.map((person, personIdx) => (
                                                <tr key={person.email}>
                                                    <td
                                                        className={classNames(
                                                            personIdx !== people.length - 1 ? 'border-b border-gray-200' : '',
                                                            'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12'
                                                        )}
                                                    >
                                                        {person.name}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            personIdx !== people.length - 1 ? 'border-b border-gray-200' : '',
                                                            'whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell'
                                                        )}
                                                    >
                                                        {person.title}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            personIdx !== people.length - 1 ? 'border-b border-gray-200' : '',
                                                            'whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell'
                                                        )}
                                                    >
                                                        {person.email}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            personIdx !== people.length - 1 ? 'border-b border-gray-200' : '',
                                                            'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                                                        )}
                                                    >
                                                        {person.role}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            personIdx !== people.length - 1 ? 'border-b border-gray-200' : '',
                                                            'relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-12'
                                                        )}
                                                    >
                                                        <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                            Edit<span className="sr-only">, {person.name}</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                    <ConfirmationDialogue
                        open={this.state.open}
                        onCancelClick={() => this.onModalClose()}
                        onSubmitClick={() => this.onModalSubmit()}
                        title={"Deactivate account"}
                        info={"Are you sure you want to deactivate your account?"}
                        submitButtonName={"Deactivate"}
                        cancelButtonName={"Cancel"}
                    >
                    </ConfirmationDialogue>



                </div>

            </Layout>
        );


    }
}
const mapStateToProps = (state) => ({
    projects: state.projects,
    teams: state.teams
});

const mapDispatchToProps = (dispatch) => ({
    fetchactiveprojects: () => dispatch(fetchactiveprojects()),
    fetchactiveTasks: () => dispatch(fetchactiveTasks()),
    fetchallEmployees: () => dispatch(fetchallEmployees()),
    FetchactiveTeams: (org_uuid) => dispatch(FetchactiveTeams(org_uuid)),

});

ReusableComponents.propTypes = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ReusableComponents);