import api from "../api";
import { ACTION_TYPES } from "../../../constants";

export const getBlockedUrls = (params) => ({
    type: ACTION_TYPES.GET_BLOCKED_URLS,
    payload: api.getBlockedUrls(params),
});

export const updateBlockedUrls = (data) => ({
    type: ACTION_TYPES.UPDATE_BLOCKED_URLS,
    payload: api.updateBlockedUrls(data),
});

export const postBlockedUrls = (data) => ({
    type: ACTION_TYPES.UPDATE_BLOCKED_URLS,
    payload: api.postBlockedUrls(data),
});

export const deleteBlockedUrls = (data) => ({
    type: ACTION_TYPES.UPDATE_BLOCKED_URLS,
    payload: api.deleteBlockedUrls(data),
});
