import { Component, Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import PropTypes from 'prop-types';
import { render } from '@testing-library/react';
import { connect } from 'react-redux';
import { fetchallurls, editURLType } from '../../services/activityMonitor/actions';
import { PencilIcon } from '@heroicons/react/solid';
import PrimaryButton from '../../Components/PrimaryButton';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const userRole = localStorage.getItem('ro');

class ViewAllUrls_modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditURLTypeModal: true,
            selectedURL: "",
        }
    }

    componentDidMount() {
        this.props.fetchallurls();
    }

    render () {

        const {t} = this.props;
        let allURLs = this.props.allURLs;
        let listofAllURLS = allURLs && allURLs.AllURLs
        
        const {onCancelClick, open, ...rest} = this.props

        const handleURLType = (type) => {
            if(userRole == 'Observer'){return}
            let dataa = {
                title: type.title,
                url: type.url,
                id: type.id,
                type: type.type
            }

            this.props.editURLType(dataa).then((data) => {
                if(data.action.payload.status == 200){
                    toast.success('URL Type Updated Successfully');
                    this.props.onCancelClick();
                } else {
                    toast.error('Something went wrong');
                    this.props.onCancelClick();
                }
            })
        }
    
        return (
            <div>
            <Transition.Root show={this.props.open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onCancelClick}>
                    <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all max-w-sm sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                                <div className='flex justify-end text-lg font-bold cursor-pointer' onClick={onCancelClick}>X</div>
                                <div className='text-2xl font-semibold'>
                                {t("ReportsTab.selectUrlEdit")}
                                </div>
                                <div>
                                    {/* <div className='flex justify-between my-5'>
                                        <div className='flex-1 text-lg font-semibold'>
                                            URLs
                                        </div>
                                        <div className='flex-1 text-lg font-semibold hidden sm:block'>
                                            Title
                                        </div>
                                    </div> */}
                                    <div>
                                        {listofAllURLS == undefined || listofAllURLS == 0
                                        ? <div>{t("laoding")}...</div> 
                                        :
                                        //  listofAllURLS.data && listofAllURLS.data.map((url, idx) => (
                                             <>
                                            
                                        {/* //     <div key={idx} className="flex flex-col">
                                        //         <div className='flex flex-row'>
                                        //             <PencilIcon className='h-5 w-5 mr-3 text-black cursor-pointer' onClick={() => {this.setState({selectedURL : {url: url.url, id: url.id, title: url.title, type: url.type}, showEditURLTypeModal: true})}}/>
                                        //             <a href={url.url} target={'_blank'} className='mr-4 flex-1 text-blue-500 hover:underline mb-8 truncate sm:break-words flex'>{url.url == null ? "" : url.url.length > 50 ? `${url.url.slice(0, 30)}...` : url.url}</a>
                                        //             <div className='flex-1 mr-8 break-words'>{url.title == null ? "" : url.title.length > 80 ? `${url.title.slice(0, 50)}...` : url.title}</div>
                                        //         </div> */}


                                            <div className="mt-2 flex flex-col overflow-y-auto bg-gray-100" style={{ maxHeight: "auto" }}>
                                            <div className="inline-block min-w-full align-middle">
                                                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                                    <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
                                                        <thead className="bg-gray-50">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12"
                                                                >
                                                                    {t("title")}
                                                                </th>
                                                        
                                                                <th
                                                                    scope="col"
                                                                    className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                                                >
                                                                    {t("ReportsTab.URL")}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-white">
                                                            {listofAllURLS.data == undefined
                                                            ? 
                                                                <tr>
                                                                    <td className='text-black text-2xl '>Loading...</td>
                                                                </tr>
                                                            : 
                                                            listofAllURLS.data && listofAllURLS.data.map((url, idx) => (
                                                                <>
                                                                <tr key={idx}>
                                                                    
                                                                    <td
                                                                        className={classNames(
                                                                            idx !== listofAllURLS.data.length - 1 ? 'border-b border-gray-200' : '',
                                                                            'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12'
                                                                        )}
                                                                    >
                                                                        {/* <PencilIcon className='h-5 w-5 mr-3 text-black cursor-pointer' onClick={() => {this.setState({selectedURL : {url: url.url, id: url.id, title: url.title, type: url.type}, showEditURLTypeModal: true})}}/> */}

                                                                        <p className='flex flex-1 items-center text-ellipsis sm:break-words text-tiny'><PencilIcon className='h-4 w-4 mr-4 cursor-pointer' onClick={() => {this.setState({selectedURL : {url: url.url, id: url.id, title: url.title, type: url.type}, showEditURLTypeModal: true})}}/> {url.title.length > 20 ? url.title.slice(0, 55) + "...." : url.title}</p>
                                                                       
                                                                    </td>
                     
                                                                    <td
                                                                        className={classNames(
                                                                            idx !== listofAllURLS.data.length - 1 ? 'border-b border-gray-200' : '',
                                                                            'whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell'
                                                                        )}
                                                                    >
                                                                        <a href={url.url} target='_blank' className='break-words text-blue-500 hover:underline'> 
                                                                            {listofAllURLS.data  == "" ? "N/A" : url.url.length > 30 ? url.url.slice(0, 55) + "......" : url.url}               
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                 
                                                                    {this.state.showEditURLTypeModal && this.state.selectedURL.id == url.id
                                                                    ? 
                                                                    <tr>
                                                                        <td>
                                                                            <div className='flex flex-col mt-2 mb-8 shadow-md border border-gray-300 p-8 rounded-lg'>
                                                                                <p className='mb-3'>Current Type: {this.state.selectedURL.type}</p>
                                                                                <div className='flex flex-col my-2 mb-5 sm:flex-row'>
                                                                                <div className='mr-6 mb-2 sm:mb-0'><PrimaryButton onClick={() => {handleURLType({title: url.title, id: url.id, url: url.url, type: 'productive'})}} buttontext={t("ReportsTab.productive")} /></div>
                                                                                <div className='mr-6 mb-2 sm:mb-0'><PrimaryButton onClick={() => {handleURLType({title: url.title, id: url.id, url: url.url, type: 'unproductive'})}} buttontext={t("ReportsTab.unproductive")} /></div>
                                                                                <div className=''><PrimaryButton onClick={() => {handleURLType({title: url.title, id: url.id, url: url.url, type: 'undefined'})}} buttontext={t("ReportsTab.undefined")} /></div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td></td>
                                                                    </tr> 
                                                                    : null}
                                                                
                                                            </>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            </div>
                                                

                      
                                            {/* </div> */}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            </div>
        )
   }
}

const mapStateToProps = (state) => ({
    allURLs: state.activityIndex
});

const mapDispatchToProps = (dispatch) => ({
    fetchallurls: () => dispatch(fetchallurls()),
    editURLType: (data) => dispatch(editURLType(data))
});

ViewAllUrls_modal.propTypes = {
    onCancelClick: PropTypes.func.isRequired,   
    open: PropTypes.bool,
};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ViewAllUrls_modal));
