import { API_ENDPOINTS } from "../../constants";
import { transport, padlocktransport } from "../../Networks/Auth";

export default {
  fetchActivityIndex: (date) => {
    let config = {
      url: API_ENDPOINTS.GET_ACTIVITY_INDEX + date,
      method: "GET",
      withCredentials: true,
    };
    return transport(config);
  },

  fetchAllUserURLs: (date, uu_id) => {
    let config = {
      url: API_ENDPOINTS.GET_USER_URLS + "?date=" + date + "&uu_id=" + uu_id,
      method: "GET",
      withCredentials: true,
    };
    return transport(config);
  },

  fetchallurls: () => {
    let config = {
      url: API_ENDPOINTS.GET_ALL_URLS,
      method: "GET",
      withCredentials: true,
    };
    return transport(config);
  },

  postURLType: (data) => {
    let config = {
      url: API_ENDPOINTS.GET_ALL_URLS,
      method: "POST",
      data: data,
    };
    return transport(config);
  },

  editURLType: (data) => {
    let config = {
      url:
        API_ENDPOINTS.GET_ALL_URLS +
        "/?id=" +
        data.id +
        "&title=" +
        data.title +
        "&url=" +
        data.url +
        "&type=" +
        data.type,
      method: "PUT",
    };
    return transport(config);
  },
};
