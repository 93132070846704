import {
    Add,
    ArrowBackIos,
    Check,
    KeyboardArrowDown,
    Visibility,
    VisibilityOff,
} from "@material-ui/icons";
import React, { Component } from "react";
import XeroLogo from "../../assets/xero.png";
import Office365Logo from "../../assets/office365.png";
import ZohoLogo from "../../assets/zoho.png";
import BitrixLogo from "../../assets/bitrix24.png";
import GithubLogo from "../../assets/github.png";
import JiraLogo from "../../assets/jira.png";
import InputField from "../../Components/InputField";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { integrateXero, getXeroTenant, disconnectXero } from "../../services/settings/action";
import { toast } from "react-toastify";

class ConnectorsTab extends Component {
    constructor() {
        super();
        this.state = {
            xeroConnected: false,
            xeroDisconnect: false,
            xeroConnectingStep: 0,
            showObscureText: false,
            clientID: "",
            grandType: "",
            secretKey: "",
            selectedOrgIdAndTt: 1,
            tenantList: "",
        };
    }
    componentDidMount() {
        this.props.integrateXero({}).then((res) => {
            if (res.value.status) {
                this.setState({ xeroConnected: true });
            }
        });
    }
    render() {
        return (
            <div className="bg-white rounded-md shadow-md mt-4 p-5">
                <div className="min-h-[70vh]">
                    <div className="text-2xl font-semibold">Connectors</div>
                    <div className="text-[#6B7280]">
                        Select and connect tools you use to integrate with your workahub
                    </div>
                    {this.state.xeroConnectingStep === 0 ? (
                        <div className="w-full border rounded-lg border-gray-300 mt-4 2xl:mt-6">
                            <div className="flex justify-between items-center p-3">
                                <div className="h-16 w-16 flex items-center justify-center">
                                    <img className="w-16 h-16 mr-4" src={XeroLogo} alt="Xero" />
                                </div>
                                <div className="flex-1">
                                    <div className="font-semibold text-lg">Xero</div>
                                    <div className="text-tiny text-textprimary">
                                        Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                        Dolore, corrupti.
                                    </div>
                                </div>
                                <div className="relative">
                                    {this.state.xeroConnected ? (
                                        <button
                                            className="text-white flex bg-primary rounded py-2 px-3 items-center hover:cursor-pointer"
                                            onClick={() => {
                                                this.setState({ xeroDisconnect: true });
                                            }}>
                                            <Check fontSize="small" />
                                            <div>Connected</div>
                                            <KeyboardArrowDown />
                                        </button>
                                    ) : (
                                        <button
                                            onClick={() => this.setState({ xeroConnectingStep: 1 })}
                                            className="text-gray-500 flex border border-gray-300 rounded py-2 px-3 items-center hover:text-black hover:border-primary hover:cursor-pointer">
                                            <Add fontSize="small" />
                                            <div>Connect</div>
                                        </button>
                                    )}
                                    <div
                                        className={`absolute bg-white border border-gray-200 py-1 w-full ${
                                            this.state.xeroDisconnect
                                                ? "-bottom-14 left-0"
                                                : "hidden"
                                        }`}>
                                        <button
                                            className="w-full bg-gray py-2 hover:bg-primary hover:text-white"
                                            onClick={() => {
                                                this.props.disconnectXero().then((res) => {
                                                    if (res.value.status === 200) {
                                                        this.setState({
                                                            xeroDisconnect: false,
                                                            xeroConnected: false,
                                                        });
                                                    }
                                                });
                                            }}>
                                            Disconnect
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="flex justify-between items-center p-3 border-t border-gray-300">
                            <div className="h-16 w-16 flex items-center justify-center">
                                <img
                                    className="w-12 h-12 mr-4"
                                    src={Office365Logo}
                                    alt="Office365"
                                />
                            </div>
                            <div className="flex-1">
                                <div className="font-semibold text-lg">Office365</div>
                                <div className="text-tiny text-textprimary">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                    Dolore, corrupti.
                                </div>
                            </div>
                            <button className="text-gray-500 flex border border-gray-300 rounded py-1 pl-2 pr-3 items-center hover:cursor-pointer">
                                <Add fontSize="small" />
                                <div>Connect</div>
                            </button>
                        </div>
                        <div className="flex justify-between items-center p-3 border-t border-gray-300">
                            <div className="h-16 w-16 flex items-center justify-center">
                                <img className="w-16 h-8 mr-4" src={ZohoLogo} alt="zoho" />
                            </div>
                            <div className="flex-1">
                                <div className="font-semibold text-lg">Zoho</div>
                                <div className="text-tiny text-textprimary">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                    Dolore, corrupti.
                                </div>
                            </div>
                            <button className="text-gray-500 flex border border-gray-300 rounded py-1 pl-2 pr-3 items-center hover:cursor-pointer">
                                <Add fontSize="small" />
                                <div>Connect</div>
                            </button>
                        </div>
                        <div className="flex justify-between items-center p-3 border-t border-gray-300">
                            <div className="h-16 w-16 flex items-center">
                                <img className="w-16 h-12 mr-4" src={BitrixLogo} alt="bitrix24" />
                            </div>
                            <div className="flex-1">
                                <div className="font-semibold text-lg">Bitrix24</div>
                                <div className="text-tiny text-textprimary">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                    Dolore, corrupti.
                                </div>
                            </div>
                            <button className="text-gray-500 flex border border-gray-300 rounded py-1 pl-2 pr-3 items-center hover:cursor-pointer">
                                <Add fontSize="small" />
                                <div>Connect</div>
                            </button>
                        </div>
                        <div className="flex justify-between items-center p-3 border-t border-gray-300">
                            <div className="h-16 w-16 flex items-center justify-center">
                                <img className="w-12 h-12 mr-4" src={GithubLogo} alt="Github" />
                            </div>
                            <div className="flex-1">
                                <div className="font-semibold text-lg">Github</div>
                                <div className="text-tiny text-textprimary">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                    Dolore, corrupti.
                                </div>
                            </div>
                            <button className="text-gray-500 flex border border-gray-300 rounded py-1 pl-2 pr-3 items-center hover:cursor-pointer">
                                <Add fontSize="small" />
                                <div>Connect</div>
                            </button>
                        </div>
                        <div className="flex justify-between items-center p-3 border-t border-gray-300">
                            <div className="h-16 w-16 flex items-center justify-center">
                                <img className="w-12 h-12 mr-4" src={JiraLogo} alt="Jira" />
                            </div>
                            <div className="flex-1">
                                <div className="font-semibold text-lg">Jira</div>
                                <div className="text-tiny text-textprimary">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                    Dolore, corrupti.
                                </div>
                            </div>
                            <button className="text-gray-500 flex border border-gray-300 rounded py-1 pl-2 pr-3 items-center hover:cursor-pointer">
                                <Add fontSize="small" />
                                <div>Connect</div>
                            </button>
                        </div> */}
                        </div>
                    ) : (
                        <div className="p-1">
                            <div className="flex text-xl py-4 leading-[1.5rem] gap-2 items-center text-[#1D1D1D]">
                                <ArrowBackIos
                                    fontSize="inherit"
                                    className="hover:cursor-pointer"
                                    onClick={() =>
                                        this.setState({
                                            xeroConnectingStep: this.state.xeroConnectingStep - 1,
                                        })
                                    }
                                />
                                <div>Xero Integration</div>
                            </div>
                            {this.state.xeroConnectingStep === 1 ? (
                                <div className="grid grid-cols-1 py-4 pl-5 md:grid-cols-2 md:gap-4 lg:gap-6 2xl:gap-12 lg:max-w-[80%]">
                                    <InputField
                                        className="focus:border-none focus:outline-none"
                                        value={this.state.clientID}
                                        onInputChange={(ev) =>
                                            this.setState({ clientID: ev.target.value })
                                        }
                                        FieldName="Client ID"
                                    />
                                    <InputField
                                        className="focus:border-none focus:outline-none"
                                        value={this.state.grandType}
                                        onInputChange={(ev) =>
                                            this.setState({ grandType: ev.target.value })
                                        }
                                        FieldName="Grand Type"
                                    />
                                    <div className="relative">
                                        <InputField
                                            className="focus:border-none focus:outline-none pr-9"
                                            value={this.state.secretKey}
                                            onInputChange={(ev) =>
                                                this.setState({ secretKey: ev.target.value })
                                            }
                                            type={this.state.showObscureText ? "text" : "password"}
                                            FieldName="Secret Key"
                                        />
                                        <div
                                            className="absolute right-4 top-2 text-gray-400 hover:cursor-pointer"
                                            onClick={() =>
                                                this.setState({
                                                    showObscureText: !this.state.showObscureText,
                                                })
                                            }>
                                            {!this.state.showObscureText ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="py-4 pl-5">
                                    <div>Select Org id and Tentent type</div>
                                    {this.state.tenantList &&
                                        this.state.tenantList.map((val, index) => (
                                            <button
                                                key={index}
                                                className="flex p-4 gap-2 items-start"
                                                onClick={() =>
                                                    this.setState({
                                                        selectedOrgIdAndTt: val.tenant_id,
                                                    })
                                                }>
                                                <div className="rounded-full mt-1 w-5 h-5 border border-gray-300 flex justify-center items-center bg-white">
                                                    <div
                                                        className={`rounded-full w-2.5 h-2.5 ${
                                                            this.state.selectedOrgIdAndTt ===
                                                            val.tenant_id
                                                                ? "bg-primary"
                                                                : "bg-white"
                                                        }`}></div>
                                                </div>
                                                <div>
                                                    <div className="text-left font-semibold">
                                                        {val.tenant_name}
                                                    </div>
                                                    <div className="text-xs text-gray-500 pt-0.5">
                                                        {val.tenant_id}
                                                    </div>
                                                </div>
                                            </button>
                                        ))}
                                </div>
                            )}
                            <div className="flex justify-end py-4 gap-4 lg:max-w-[80%]">
                                <button className="py-1.5 px-5 text-gray-500 border border-gray-300 rounded hover:text-black">
                                    Cancel
                                </button>
                                {this.state.xeroConnectingStep === 1 ? (
                                    <button
                                        className="py-1.5 px-5 text-white bg-primary border border-primary rounded hover:bg-opacity-90"
                                        onClick={() => {
                                            if (
                                                this.state.clientID &&
                                                this.state.secretKey &&
                                                this.state.grandType
                                            ) {
                                                this.props.getXeroTenant().then((res) => {
                                                    if (res.value.status === 200) {
                                                        this.setState({
                                                            tenantList: res.value.data.data,
                                                            xeroConnectingStep:
                                                                this.state.xeroConnectingStep + 1,
                                                        });
                                                    } else {
                                                        this.setState({
                                                            xeroConnectingStep:
                                                                this.state.xeroConnectingStep + 1,
                                                        });
                                                    }
                                                });
                                            } else {
                                                toast.error("Please fill all the fields.");
                                            }
                                        }}>
                                        Next
                                    </button>
                                ) : (
                                    <button
                                        className="py-1.5 px-5 text-white bg-primary border border-primary rounded hover:bg-opacity-90"
                                        onClick={() => {
                                            const data = new FormData();
                                            data.append("clientId", this.state.clientID);
                                            data.append("clientSecret", this.state.secretKey);
                                            data.append("tenantId", this.state.selectedOrgIdAndTt);
                                            data.append("grantType", this.state.grandType);
                                            this.props.integrateXero(data).then((res) => {
                                                if (res.value.status === 200) {
                                                    this.setState({
                                                        xeroConnectingStep: 0,
                                                        xeroConnected: true,
                                                    });
                                                }
                                            });
                                        }}>
                                        Connect
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    integrateXero: (data) => dispatch(integrateXero(data)),
    getXeroTenant: () => dispatch(getXeroTenant()),
    disconnectXero: () => dispatch(disconnectXero()),
});

export default withTranslation("common")(
    connect(mapStateToProps, mapDispatchToProps)(ConnectorsTab)
);

// connectors are Xero, office365, zoho, bitrix24, github, jira
