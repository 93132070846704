import { ACTION_TYPES } from '../../../constants';

const timetracker = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_APP_LOG_PENDING: {
            return {
                ...state,
                isFetchingapplogListList: true,
                isFetchingapplogListFailed: false,
                applogListFetchError: undefined,
                applogList: [],
            };
        }
        case ACTION_TYPES.GET_APP_LOG_FULFILLED: {
            return {
                ...state,
                isFetchingapplogListList: false,
                isFetchingapplogListFailed: false,
                applogListFetchError: undefined,
                applogList: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_APP_LOG_REJECTED: {
            return {
                ...state,
                isFetchingapplogListList: false,
                isFetchingapplogListFailed: true,
                applogListFetchError: action.payload,
                applogList: [],
            };
        }

        case ACTION_TYPES.TIME_TRACKER_DATA_PENDING: {
            return {
                ...state,
                isFetchingTimeTrackerDataList: true,
                isFetchingTimeTrackerDataFailed: false,
                TimeTrackerDataFetchError: undefined,
                TimeTrackerData: {},
            };
        }
        case ACTION_TYPES.TIME_TRACKER_DATA_FULFILLED: {
            return {
                ...state,
                isFetchingTimeTrackerDataList: false,
                isFetchingTimeTrackerDataFailed: false,
                TimeTrackerDataFetchError: undefined,
                TimeTrackerData: action.payload.data,
            };
        }
        case ACTION_TYPES.TIME_TRACKER_DATA_REJECTED: {
            return {
                ...state,
                isFetchingTimeTrackerDataList: false,
                isFetchingTimeTrackerDataFailed: true,
                TimeTrackerDataFetchError: action.payload,
                TimeTrackerData: {},
            };
        }

        case ACTION_TYPES.TIME_TRACKER_DATA_OBSERVER_PENDING: {
            return {
                ...state,
                isFetchingTimeTrackerDataObserverList: true,
                isFetchingTimeTrackerDataObserverFailed: false,
                TimeTrackerDataObserverFetchError: undefined,
                TimeTrackerDataObserver: {},
            };
        }

        case ACTION_TYPES.TIME_TRACKER_DATA_OBSERVER_FULFILLED: {
            return {
                ...state,
                isFetchingTimeTrackerDataObserverList: false,
                isFetchingTimeTrackerDataObserverFailed: false,
                TimeTrackerDataObserverFetchError: undefined,
                TimeTrackerDataObserver: action.payload.data,
            };
        }

        case ACTION_TYPES.TIME_TRACKER_DATA_OBSERVER_REJECTED: {
            return {
                ...state,
                isFetchingTimeTrackerDataObserverList: false,
                isFetchingTimeTrackerDataObserverFailed: true,
                TimeTrackerDataObserverFetchError: action.payload,
                TimeTrackerDataObserver: {},
            };
        }

        case ACTION_TYPES.FETCH_SYSTEM_INFO_PENDING: {
            return {
                ...state,
                isFetchingsystemInfoList: true,
                isFetchingsystemInfoFailed: false,
                systemInfoFetchError: undefined,
                systemInfo: [],
            };
        }
        case ACTION_TYPES.FETCH_SYSTEM_INFO_FULFILLED: {
            return {
                ...state,
                isFetchingsystemInfoList: false,
                isFetchingsystemInfoFailed: false,
                systemInfoFetchError: undefined,
                systemInfo: action.payload.data,
            };
        }
        case ACTION_TYPES.FETCH_SYSTEM_INFO_REJECTED: {
            return {
                ...state,
                isFetchingsystemInfoList: false,
                isFetchingsystemInfoFailed: true,
                systemInfoFetchError: action.payload,
                systemInfo: [],
            };
        }

        case ACTION_TYPES.CUSTOM_WEEKLY_GRAPH_DATA_PENDING: {
            return {
                ...state,
                isFetchingcustomweeklygraphdataList: true,
                isFetchingcustomweeklygraphdataFailed: false,
                customweeklygraphdataFetchError: undefined,
                customweeklygraphdata: {},
            };
        }
        case ACTION_TYPES.CUSTOM_WEEKLY_GRAPH_DATA_FULFILLED: {
            return {
                ...state,
                isFetchingcustomweeklygraphdataList: false,
                isFetchingcustomweeklygraphdataFailed: false,
                customweeklygraphdataFetchError: undefined,
                customweeklygraphdata: action.payload.data,
            };
        }
        case ACTION_TYPES.CUSTOM_WEEKLY_GRAPH_DATA_REJECTED: {
            return {
                ...state,
                isFetchingcustomweeklygraphdataList: false,
                isFetchingcustomweeklygraphdataFailed: true,
                customweeklygraphdataFetchError: action.payload,
                customweeklygraphdata: {},
            };
        }

        //edit timetracker timing

        case ACTION_TYPES.EDIT_TIME_TRACKER_SCREENSHOT_TIMING: {
            return {
                ...state,
                isFetchingEditTimetrackerTimingList: true,
                isFetchingEditTimetrackerTimingFailed: false,
                EditTimetrackerTimingFetchError: undefined,
                EditTimetrackerTiming: {},
            };
        }

        case ACTION_TYPES.EDIT_TIME_TRACKER_SCREENSHOT_TIMING_FULFILLED: {
            return {
                ...state,
                isFetchingEditTimetrackerTimingList: false,
                isFetchingEditTimetrackerTimingFailed: false,
                EditTimetrackerTimingFetchError: undefined,
                EditTimetrackerTiming: action.payload.data,
            };
        }

        case ACTION_TYPES.EDIT_TIME_TRACKER_SCREENSHOT_TIMING_REJECTED: {
            return {
                ...state,
                isFetchingEditTimetrackerTimingList: false,
                isFetchingEditTimetrackerTimingFailed: true,
                EditTimetrackerTimingFetchError: action.payload,
                EditTimetrackerTiming: {},
            };
        }

        //create timetracker timing

        case ACTION_TYPES.CREATE_TIME_TRACKER_SCREENSHOT_TIMING: {
            return {
                ...state,
                isFetchingCreateTimetrackerTimingList: true,
                isFetchingCreateTimetrackerTimingFailed: false,
                CreateTimetrackerTimingFetchError: undefined,
                CreateTimetrackerTiming: {},
            };
        }

        case ACTION_TYPES.CREATE_TIME_TRACKER_SCREENSHOT_TIMING_FULFILLED: {
            return {
                ...state,
                isFetchingCreateTimetrackerTimingList: false,
                isFetchingCreateTimetrackerTimingFailed: false,
                CreateTimetrackerTimingFetchError: undefined,
                CreateTimetrackerTiming: action.payload.data,
            };
        }

        case ACTION_TYPES.CREATE_TIME_TRACKER_SCREENSHOT_TIMING_REJECTED: {
            return {
                ...state,
                isFetchingCreateTimetrackerTimingList: false,
                isFetchingCreateTimetrackerTimingFailed: true,
                CreateTimetrackerTimingFetchError: action.payload,
                CreateTimetrackerTiming: {},
            };
        }

        //get time tracer interval

        case ACTION_TYPES.GET_TIME_TRACKER_SCREENSHOT_INTERVAL: {
            return {
                ...state,
                isFetchingGetTimetrackerIntervalList: true,
                isFetchingGetTimetrackerIntervalFailed: false,
                GetTimetrackerIntervalFetchError: undefined,
                GetTimetrackerInterval: {},
            };
        }

        case ACTION_TYPES.GET_TIME_TRACKER_SCREENSHOT_INTERVAL_FULFILLED: {
            return {
                ...state,
                isFetchingGetTimetrackerIntervalList: false,
                isFetchingGetTimetrackerIntervalFailed: false,
                GetTimetrackerIntervalFetchError: undefined,
                GetTimetrackerInterval: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_TIME_TRACKER_SCREENSHOT_INTERVAL_REJECTED: {
            return {
                ...state,
                isFetchingGetTimetrackerIntervalList: false,
                isFetchingGetTimetrackerIntervalFailed: true,
                GetTimetrackerIntervalFetchError: action.payload,
                GetTimetrackerInterval: {},
            };
        }

        case ACTION_TYPES.EMPLOYEE_BRWOSER_ACTIVITY_LOG_PENDING: {
            return {
                ...state,
                isFetchingEmployeeBrowserActivityLogList: true,
                isFetchingEmployeeBrowserActivityLogFailed: false,
                EmployeeBrowserActivityLogFetchError: undefined,
                EmployeeBrowserActivityLog: {},
            };
        }

        case ACTION_TYPES.EMPLOYEE_BRWOSER_ACTIVITY_LOG_FULFILLED: {
            return {
                ...state,
                isFetchingEmployeeBrowserActivityLogList: false,
                isFetchingEmployeeBrowserActivityLogFailed: false,
                EmployeeBrowserActivityLogFetchError: undefined,
                EmployeeBrowserActivityLog: action.payload.data,
            };
        }

        case ACTION_TYPES.EMPLOYEE_BRWOSER_ACTIVITY_LOG_REJECTED: {
            return {
                ...state,
                isFetchingEmployeeBrowserActivityLogList: false,
                isFetchingEmployeeBrowserActivityLogFailed: true,
                EmployeeBrowserActivityLogFetchError: action.payload,
                EmployeeBrowserActivityLog: {},
            };
        }

        case ACTION_TYPES.TIMETRACKER_VIEWDETAILS_PENDING: {
            return {
                ...state,
                isFetchingTimetrackerViewdetailsList: true,
                isFetchingTimetrackerViewdetailsFailed: false,
                TimetrackerViewdetailsFetchError: undefined,
                TimetrackerViewdetails: {},
            };
        }

        case ACTION_TYPES.TIMETRACKER_VIEWDETAILS_FULFILLED: {
            return {
                ...state,
                isFetchingTimetrackerViewdetailsList: false,
                isFetchingTimetrackerViewdetailsFailed: false,
                TimetrackerViewdetailsFetchError: undefined,
                TimetrackerViewdetails: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_SYSTEM_INFO_OBSERVER_REJECTED: {
            return {
                ...state,
                isFetchingSystemInfoObserverList: false,
                isFetchingSystemInfoObserverFailed: true,
                SystemInfoObserverFetchError: action.payload,
                SystemInfoObserver: {},
            };
        }

        case ACTION_TYPES.GET_SYSTEM_INFO_OBSERVER_FULFILLED: {
            return {
                ...state,
                isFetchingSystemInfoObserverList: false,
                isFetchingSystemInfoObserverFailed: false,
                SystemInfoObserverFetchError: undefined,
                SystemInfoObserver: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_SYSTEM_INFO_OBSERVER_PENDING: {
            return {
                ...state,
                isFetchingSystemInfoObserverList: true,
                isFetchingSystemInfoObserverFailed: false,
                SystemInfoObserverFetchError: undefined,
                SystemInfoObserver: {},
            };
        }

        case ACTION_TYPES.WEEKLYCHART_ACTIVITY_PENDING: {
            return {
                ...state,
                isFetchingWeeklyActivity: true,
                isFetchingWeeklyActivityFailed: false,
                isFetchingWeeklyActivityError: undefined,
                WeeklyChartActivity: {},
            };
        }

        case ACTION_TYPES.WEEKLYCHART_ACTIVITY_FULFILLED: {
            return {
                ...state,
                isFetchingWeeklyActivity: false,
                isFetchingWeeklyActivityFailed: false,
                isFetchingWeeklyActivityError: undefined,
                WeeklyChartActivity: action.payload.data,
            };
        }
        case ACTION_TYPES.WEEKLYCHART_ACTIVITY_REJECTED: {
            return {
                ...state,
                isFetchingWeeklyActivity: false,
                isFetchingWeeklyActivityFailed: true,
                isFetchingWeeklyActivityError: action.payload,
                WeeklyChartActivity: {},
            };
        }
        case ACTION_TYPES.PROJECT_CHANGE_PENDING: {
            return {
                ...state,
                isRequestingProjectChange: true,
                isRequestingProjectChangeFailed: false,
                isRequestingProjectChangeError: undefined,
                projectChange: {},
            };
        }

        case ACTION_TYPES.PROJECT_CHANGE_FULFILLED: {
            return {
                ...state,
                isRequestingProjectChange: false,
                isRequestingProjectChangeFailed: false,
                isRequestingProjectChangeError: undefined,
                projectChange: action.payload.data,
            };
        }
        case ACTION_TYPES.PROJECT_CHANGE_REJECTED: {
            return {
                ...state,
                isRequestingProjectChange: false,
                isRequestingProjectChangeFailed: true,
                isRequestingProjectChangeError: action.payload,
                projectChange: {},
            };
        }

        case ACTION_TYPES.GET_PROJECT_LIST_PENDING: {
            return {
                ...state,
                fetchingProjectList: true,
                fetchingProjectListFailed: false,
                fetchingProjectListError: undefined,
                projectList: {},
            };
        }

        case ACTION_TYPES.GET_PROJECT_LIST_FULFILLED: {
            return {
                ...state,
                fetchingProjectList: false,
                fetchingProjectListFailed: false,
                fetchingProjectListError: undefined,
                projectList: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_PROJECT_LIST_REJECTED: {
            return {
                ...state,
                fetchingProjectList: false,
                fetchingProjectListFailed: true,
                fetchingProjectListError: action.payload,
                projectList: {},
            };
        }

        case ACTION_TYPES.DELETE_TIME_PENDING: {
            return {
                ...state,
                isdeleteTime: true,
                isdeleteTimeFailed: false,
                isdeleteTimeError: undefined,
                deleteTime: {},
            };
        }

        case ACTION_TYPES.DELETE_TIME_FULFILLED: {
            return {
                ...state,
                isdeleteTime: false,
                isdeleteTimeFailed: false,
                isdeleteTimeError: undefined,
                deleteTime: action.payload.data,
            };
        }
        case ACTION_TYPES.DELETE_TIME_REJECTED: {
            return {
                ...state,
                isdeleteTime: false,
                isdeleteTimeFailed: true,
                isdeleteTimeError: action.payload,
                deleteTime: {},
            };
        }

        case ACTION_TYPES.CANCEL_TIME_PENDING: {
            return {
                ...state,
                isCancelTime: true,
                isCancelTimeFailed: false,
                isCancelTimeError: undefined,
                cancelTime: {},
            };
        }

        case ACTION_TYPES.CANCEL_TIME_FULFILLED: {
            return {
                ...state,
                isCancelTime: false,
                isCancelTimeFailed: false,
                isCancelTimeError: undefined,
                cancelTime: action.payload.data,
            };
        }
        case ACTION_TYPES.CANCEL_TIME_REJECTED: {
            return {
                ...state,
                isCancelTime: false,
                isCancelTimeFailed: true,
                isCancelTimeError: action.payload,
                cancelTime: {},
            };
        }

        case ACTION_TYPES.USER_DETAIL_PENDING: {
            return {
                ...state,
                isFetchingUserDetail: true,
                isFetchingUserDetailFailed: false,
                isFetchingUserDetailError: undefined,
                userDetail: {},
            };
        }

        case ACTION_TYPES.USER_DETAIL_FULFILLED: {
            return {
                ...state,
                isFetchingUserDetail: false,
                isFetchingUserDetailFailed: false,
                isFetchingUserDetailError: undefined,
                userDetail: action.payload.data,
            };
        }
        case ACTION_TYPES.USER_DETAIL_REJECTED: {
            return {
                ...state,
                isFetchingUserDetail: false,
                isFetchingUserDetailFailed: true,
                isFetchingUserDetailError: action.payload,
                userDetail: {},
            };
        }

        case ACTION_TYPES.GET_SHOWCASE_ENTRIES_PENDING: {
            return {
                ...state,
                isFetchingShowcaseEntries: true,
                isFetchingShowcaseEntriesFailed: false,
                isFetchingShowcaseEntriesError: undefined,
                showcaseEntries: {},
            };
        }

        case ACTION_TYPES.GET_SHOWCASE_ENTRIES_FULFILLED: {
            return {
                ...state,
                isFetchingShowcaseEntries: false,
                isFetchingShowcaseEntriesFailed: false,
                isFetchingShowcaseEntriesError: undefined,
                showcaseEntries: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_SHOWCASE_ENTRIES_REJECTED: {
            return {
                ...state,
                isFetchingShowcaseEntries: false,
                isFetchingShowcaseEntriesFailed: true,
                isFetchingShowcaseEntriesError: action.payload,
                showcaseEntries: {},
            };
        }
        case ACTION_TYPES.GET_EMPLOYEE_MOOD_PENDING: {
            return {
                ...state,
                isFetchingEmployeeMood: true,
                isFetchingEmployeeMoodFailed: false,
                isFetchingEmployeeMoodError: undefined,
                EmployeeMood: {},
            };
        }

        case ACTION_TYPES.GET_EMPLOYEE_MOOD_FULFILLED: {
            return {
                ...state,
                isFetchingEmployeeMood: false,
                isFetchingEmployeeMoodFailed: false,
                isFetchingEmployeeMoodError: undefined,
                EmployeeMood: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_EMPLOYEE_MOOD_REJECTED: {
            return {
                ...state,
                isFetchingEmployeeMood: false,
                isFetchingEmployeeMoodFailed: true,
                isFetchingEmployeeMoodError: action.payload,
                EmployeeMood: {},
            };
        }

        case ACTION_TYPES.GET_SINGLE_APP_LOG_PENDING: {
            return {
                ...state,
                isFetchingSingleAppLog: true,
                isFetchingSingleAppLogFailed: false,
                isFetchingSingleAppLogError: undefined,
                SingleAppLog: {},
            };
        }

        case ACTION_TYPES.GET_SINGLE_APP_LOG_FULFILLED: {
            return {
                ...state,
                isFetchingSingleAppLog: false,
                isFetchingSingleAppLogFailed: false,
                isFetchingSingleAppLogError: undefined,
                SingleAppLog: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_SINGLE_APP_LOG_REJECTED: {
            return {
                ...state,
                isFetchingSingleAppLog: false,
                isFetchingSingleAppLogFailed: true,
                isFetchingSingleAppLogError: action.payload,
                SingleAppLog: {},
            };
        }

        case ACTION_TYPES.GET_OFFLINE_DATA_PENDING: {
            return {
                ...state,
                isFetchingOfflineData: true,
                isFetchingOfflineDataFailed: false,
                isFetchingOfflineDataError: undefined,
                OfflineData: {},
            };
        }

        case ACTION_TYPES.GET_OFFLINE_DATA_FULFILLED: {
            return {
                ...state,
                isFetchingOfflineData: false,
                isFetchingOfflineDataFailed: false,
                isFetchingOfflineDataError: undefined,
                OfflineData: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_OFFLINE_DATA_REJECTED: {
            return {
                ...state,
                isFetchingOfflineData: false,
                isFetchingOfflineDataFailed: true,
                isFetchingOfflineDataError: action.payload,
                OfflineData: {},
            };
        }

        case ACTION_TYPES.GET_ACTIVITY_TIME_TRACKER_PENDING: {
            return {
                ...state,
                isFetchingActivityTimeTracker: true,
                isFetchingActivityTimeTrackerFailed: false,
                isFetchingActivityTimeTrackerError: undefined,
                ActivityTimeTracker: {},
            };
        }

        case ACTION_TYPES.GET_ACTIVITY_TIME_TRACKER_FULFILLED: {
            return {
                ...state,
                isFetchingActivityTimeTracker: false,
                isFetchingActivityTimeTrackerFailed: false,
                isFetchingActivityTimeTrackerError: undefined,
                ActivityTimeTracker: action.payload.data,
            };
        }
        case ACTION_TYPES.GET_ACTIVITY_TIME_TRACKER_REJECTED: {
            return {
                ...state,
                isFetchingActivityTimeTracker: false,
                isFetchingActivityTimeTrackerFailed: true,
                isFetchingActivityTimeTrackerError: action.payload,
                ActivityTimeTracker: {},
            };
        }
        default:
            return state;
    }
}

export default timetracker;