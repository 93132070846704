import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import DateRangeFilter from "./DatePicker";
import { getTotalHourReportTable } from "../../../services/reports/actions";
//icons
import { CalendarIcon, DownloadIcon } from "@heroicons/react/outline";
// import { BsGearFill, BsSearch } from "react-icons/bs";

// import { HiFilter } from "react-icons/hi";
// import { Checkbox } from "@material-ui/core";
//dependencies
import BeatLoader from "react-spinners/BeatLoader";

import { withTranslation } from "react-i18next";

function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}

let d = new Date();

let firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
let c = convert(firstDay);

let lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
let cc = convert(lastDay);

class TotalHoursReport_tab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: c,
            endDate: cc,
            showDateRange: false,
            showSettingsModal: false,
            workingHours: 0,
            isShowFilterModal: false,
            filterTeams: [],
            searchedTeam: "",
        };
    }

    componentDidMount() {
        setTimeout(() => {
            window.history.pushState({}, null, `/reports/total-hour-report`);
        }, 200);
        this.props.getTotalHourReportTable(
            `${this.state.startDate}&end_date=${this.state.endDate}`
        );
    }

    render() {
        const { t } = this.props;

        const handeThisMonth = () => {
            this.setState({ startDate: c, endDate: cc }, () => {
                this.props.getTotalHourReportTable(`${c}&end_date=${cc}`);
            });
        };
        const reportsData = this.props.report.TotalHourReport;
        const onChange = (ranges) => {
            let dd = convert(ranges.startDate);
            let rr = convert(ranges.endDate);
            this.setState({ startDate: dd, endDate: rr });
            this.props.getTotalHourReportTable(`${dd}&end_date=${rr}`);
        };

        const exportXLSX = () => {
            // this.props.downloadBookingCSVReport(`${this.state.startDate}`).then((data) => {
            //     if (data.value.status === 200) {
            //         var blob = new Blob([data.value.data], { type: "application/xlsx" });
            //         FileSaver.saveAs(blob, `Booking_report_${this.state.startDate}.xlsx`);
            //     }
            // });
        };

        return (
            <div>
                <div className="flex justify-between items-start mb-8 lg:items-center flex-col lg:flex-row mt-5">
                    <div className="flex flex-col sm:flex-row">
                        <button
                            className="bg-secondary rounded-full p-2 px-5 w-32 mb-3 sm:mb-0 mt-4 sm:mt-0 sm:w-auto text-white font-medium mr-3"
                            onClick={() => {
                                handeThisMonth();
                            }}>
                            {t("ReportsTab.this")} {t("ReportsTab.month")}
                        </button>
                        <div className="gap-2 px-3 py-2 bg-white flex items-center rounded-full justify-start cursor-pointer">
                        <CalendarIcon
                         className="h-6 w-6"
                          onClick={() => {
                       this.setState({ showDateRange: true });
                        }}
                          />
                      <input
                       value={`${new Date(
                      this.state.startDate
                     ).toDateString()} - ${new Date(
                      this.state.endDate
                      ).toDateString()}`}
                  disabled
                  className="w-[260px] bg-white rounded-full font-medium text-gray-600"
                  onClick={() => {
                  this.setState({ showDateRange: true });
                  }}
                  />
             
                 </div>
                    </div>
                </div>

                <div className="bg-white px-4 py-5 mt-4 border-b border-gray-200 sm:px-6 rounded-lg">
                    <div className="flex relative justify-between items-center mt-2">
                        <div className=" font-semibold text-2xl">
                            Total {t("hour")} {t("ReportsTab.report")}
                        </div>
                        {/* <div className="flex items-center">
                            <div>
                                <HiFilter
                                    className="h-6 w-6 mr-2 cursor-pointer"
                                    onClick={() => {
                                        this.setState({
                                            isShowFilterModal: !this.state.isShowFilterModal,
                                            searchedTeam: "",
                                        });
                                    }}
                                />
                            </div>
                            <div>
                                <BsGearFill
                                    className="h-5 w-5 mr-2 cursor-pointer"
                                    onClick={() => {
                                        this.setState({ showSettingsModal: true });
                                    }}
                                />
                            </div>
                            <div>
                                <DownloadIcon
                                    className="h-6 w-6 cursor-pointer"
                                    onClick={() => {
                                        exportXLSX();
                                    }}
                                />
                            </div>
                        </div> */}
                        {/* <div
                            className={`absolute right-12 top-10 shadow-md ring-2 bg-white p-4 z-10 ring-black ring-opacity-5 border-0 rounded-xl w-[320px] max-w-[320px] ${
                                this.state.isShowFilterModal ? "" : "hidden"
                            }`}
                            // className={`absolute right-12 top-10 shadow-md ring-2 bg-white p-4 z-10 ring-black ring-opacity-5 border-0 rounded-xl w-[320px] max-w-[320px]`}
                        >
                            <div className=" text-xl mb-2">{t("ReportsTab.filter")}</div>
                            <div className="text-gray-500 my-2">{t("teams")}</div>
                            <div className="relative mb-1">
                                <div className="absolute text-textprimary top-2.5 left-2 h-4 w-4">
                                    <BsSearch className="w-full h-full" />
                                </div>
                                <input
                                    className="w-full rounded-md border-primary text-xs py-1 pl-8"
                                    type="text"
                                    value={this.state.searchedTeam}
                                    onChange={(ev) => {
                                        this.setState({ searchedTeam: ev.target.value });
                                    }}
                                />
                            </div>
                            <div className=" text-sm text-gray-500 my-2">
                                {t("ReportsTab.suggested")} {t("teams")}
                            </div>
                            {teamsList &&
                                teamsList.map((team) => {
                                    if (
                                        this.state.searchedTeam !== "" &&
                                        !team.label
                                            .toLowerCase()
                                            .includes(this.state.searchedTeam.toLowerCase())
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <div key={team.label + team.value}>
                                            <div>
                                                <Checkbox
                                                    id={team.value}
                                                    // checked={false}
                                                    defaultChecked={false}
                                                    style={{
                                                        color: "#684D94",
                                                        padding: "4px",
                                                    }}
                                                    onChange={(ev) => {
                                                        let teamsArr = [...this.state.filterTeams];
                                                        if (ev.target.checked) {
                                                            teamsArr.push(team.label.toLowerCase());
                                                        } else {
                                                            teamsArr =
                                                                this.state.filterTeams.filter(
                                                                    (val) => {
                                                                        return (
                                                                            val.toLowerCase() !==
                                                                            team.label.toLowerCase()
                                                                        );
                                                                    }
                                                                );
                                                        }
                                                        this.setState({ filterTeams: teamsArr });
                                                    }}
                                                />
                                                <label htmlFor="teamName" className="capitalize">
                                                    {team.label}
                                                </label>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div> */}
                    </div>
                    <div
                        className="mt-4 flex flex-col overflow-y-auto bg-gray-100"
                        style={{ maxHeight: "auto" }}>
                        <div className="inline-block min-w-full align-middle">
                            <div className="shadow-sm ring-1 ring-black ring-opacity-5 border-0 rounded-xl max-h-[63vh] overflow-auto">
                                <table className="min-w-max w-1/2 border border-gray-200 border-separate rounded-xl">
                                    <thead className="bg-gray-50 sticky top-0 z-10">
                                        <tr className={``}>
                                            <th
                                                scope="col"
                                                className={`border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 px-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                {t("user")} {t("name")}
                                            </th>
                                            <th
                                                scope="col"
                                                className={`border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell`}>
                                                {t("requestsModule.duration")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {reportsData &&
                                        reportsData.data &&
                                        reportsData.data.length > 0 ? (
                                            reportsData.data?.sort((a,b)=>a.username.localeCompare(b.username)).map((reportRow, index) => {
                                                return (
                                                    <tr className={``} key={"reportRow-" + index}>
                                                        <td
                                                            className={`py-4 px-3 text-base font-medium text-primary`}>
                                                            {reportRow["username"]}
                                                        </td>
                                                        <td
                                                            className={`px-3 py-4 text-base text-gray-500 lg:table-cell`}>
                                                            {reportRow["duration"] +
                                                                (reportRow["duration"] <= 1
                                                                    ? "hr"
                                                                    : "hrs")}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr className="min-w-full flex justify-center">
                                                <td className="text-center flex-1">
                                                    <BeatLoader />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Transition.Root show={this.state.showDateRange} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed z-10 inset-0 overflow-y-auto"
                        onClose={() => {
                            this.setState({ showDateRange: false });
                        }}>
                        <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[600px] sm:w-full sm:p-6">
                                    <DateRangeFilter onChange={onChange} />
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    report: state.report,
});

const mapDispatchToProps = (dispatch) => ({
    getTotalHourReportTable: (date) => dispatch(getTotalHourReportTable(date)),
});

export default withTranslation("common")(
    connect(mapStateToProps, mapDispatchToProps)(TotalHoursReport_tab)
);
