import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import {
  SecureLoginUserMkt,
  GetOrganizationUUID,
  SecureLoginMkt,
} from "../../Networks/Auth";

export class Redirect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }
  async getIp() {
    let ip = "127.0.0.1";
    try {
      const res = await axios.get("https://api.ipify.org?format=json");
      if (res.data.ip) {
        return res.data;
      } else {
        return { ip: ip };
      }
    } catch (error) {
      return { ip: ip };
    }
  }

  tokenVerify(data, token){
    fetch(localStorage.getItem("apiURL") + "/api/token_verify/", {
        method: "POST",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((response) => {
            fetch(
                localStorage.getItem("apiURL") + `/api/role_check/?marketing_access_key=${token}`,
                {
                    method: "GET",
                }
            )
                .then((res) => res.json())
                .then((dataa) => {
                    localStorage.setItem("ro", dataa.message);
                });
            if (response.username) {
                localStorage.setItem("user_email", response.email);
                localStorage.setItem("org_name", response.org_name);
                localStorage.setItem("org_username", response.username);
                const username = response.username;
                const uu_id = response.uu_id;
                const orgname = response.org_name;
                // SecureLoginUserMkt(username, uu_id, this.props, localStorage.getItem('apiURL'))
                SecureLoginMkt(username, uu_id, this.props, localStorage.getItem("apiURL"));
                GetOrganizationUUID(orgname, localStorage.getItem("apiURL"));
            } else {
                window.location.href = "https://joinworkahub.com/siginemail";
            }
        })
        .catch((error) => {
            console.log(error);
            this.tokenVerify(data, token);
        });
  }

  componentDidMount() {
    setTimeout(async () => {
      const query = new URLSearchParams(this.props.location.search);

      let token = query.get("token");
      localStorage.setItem("token", token);
      this.getIp().then((dataa) => {
        var date1 = new Date();
        const data = {
          marketing_access_key: token,
          ipaddress: dataa.ip,
          date: moment(date1).format("YYYY-MM-DD"),
        };
        if (token != null && token != "") {
          this.tokenVerify(data, token)
        } else if (token != "") {
          // alert("Invalid user")
        }
      });
    }, 200);
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading)
      return (
        <div
          style={{
            backgroundColor: "white",
            height: "100%",
            display: "flex",
            justifyItems: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            className="image loader"
            src={require("../../images/load.gif")}
            alt="Loading"
          />
        </div>
      );
  }
}

export default Redirect;
