import PropTypes from 'prop-types';

export default function InputField(props) {
    const { onInputChange, type, name, placeholder, FieldName, id, value, ...rest } = props;
    return (
        <div
            className={
                "w-full mx-auto relative border border-gray-300 rounded-md px-3 py-2 shadow-sm  focus-within:border-primary " +
                (props.disabled ? "bg-[#EAEAEA]" : "")
            }>
            <label
                htmlFor="name"
                className="absolute -top-2 left-3 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                {FieldName}
            </label>
            <input
                value={value ?? ""}
                {...rest}
                type={type}
                name={name}
                id={id}
                disabled={props.disabled}
                onChange={onInputChange}
                className={
                    "block w-full border-0 p-0 disabled:bg-[#EAEAEA] disabled:text-[#6B7280] text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm " +
                    props.className
                }
                placeholder={placeholder}
            />
        </div>
    );
}

InputField.propTypes = {
    onInputChange: PropTypes.func.isRequired,
    FieldName: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string
};