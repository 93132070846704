import React, { Component, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { DownloadIcon } from "@heroicons/react/outline";
import Layout from "../../Components/Layout";
import {
  fetchactiveprojects,
  fetchactiveTasks,
  fetchallEmployees,
  createTask,
  getUsersfromtheProject,
  DeleteProject,
  DeleteTask,
  DeleteUserFromProject,
  updateTask,
  createProject,
  updateProject,
  AdduserToTheProject,
  taskLsitObserver,
  deatiledTaskInfo,
  fetchActiveTaskOserver,
  getProjectDetails,
  postProjectHrsBooked,
  updateProjectHrsBooked,
  getProjectTableLayout,
  updateProjectTableLayout,
  postProjectTableLayout,
  getProjectTableArrangement,
  updateProjectTableArrangement,
  postProjectTableArrangement,
  getUserBasedProjects,
  getUserBasedTeams,
  getTaskTableArrangement,
  getTaskTableLayout,
  updateTaskTableArrangement,
  updateTaskTableLayout,
  postTaskTableArrangement,
  postTaskTableLayout,
  getNotes,
  addNote,
  fetchUserDailyReport,
  fetchUserMonthlyReport,
} from "../../services/projects/actions";
import PrimaryButton from "../../Components/PrimaryButton";
import { FetchactiveTeams } from "../../services/teams/actions";
import { fetchEmpLoginDetail } from "../../services/employee/actions";
import {
  BriefcaseIcon,
  UsersIcon,
  PencilIcon,
  TrashIcon,
  DocumentSearchIcon,
  ChevronDownIcon,
  CalendarIcon,
  XIcon,
} from "@heroicons/react/solid";

import FileSaver from "file-saver";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ConfirmationDialoag from "../../Components/ConfirmationDialogue";
import CreateTaskModal from "./createTaskModal";
import UpdateTaskModal from "./updateTaskModal";
import CreateProjectModal from "./createProjectModal";
import UpdateProjectModal from "./createProjectModal";
import AddUserToProjectModal from "./addusertoprojectModal";
import SelectField from "../../Components/SelectField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/global.css";
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from "react-toastify";
import { IoIosArrowBack, IoMdArrowDropdown, IoMdArrowDropup, IoMdClose } from "react-icons/io";
import {
  storeToLocalStorage,
  showData,
} from "../../services/globalState/actions";
import hideObsPng from "../../assets/hideObserver.png";

import { withTranslation } from "react-i18next";
import { BsGearFill } from "react-icons/bs";
import LayoutSetupModal from "../Dashboard/Modals/LayoutSetupModal";
import moment from "moment";
import InputField from "../../Components/InputField";
import { Checkbox } from "@mui/material";
import CustomDateModal from "./customDateModal";
import union from "../../assets/Union.svg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import XeroIntegrationToProjectModal from "./xeroIntegrationModal";
import { Link } from "react-router-dom";
const userRole = localStorage.getItem("ro");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}
function convertMintoHr(minutes) {
  let hours = minutes / 60;
  let minutesLeft = minutes % 60;
  return `${Math.trunc(hours)}hr : ${minutesLeft}min`;
}

class Projects extends Component {
  constructor() {
    super();
    this.state = {
        SelectedTab: "projects",
        SelectedProjectName: "",
        selectedProjectId: "",
        selectedUsers: [],
        selectedTeam: "",
        loading: true,
        deleteProjectConfirmationModal: false,
        deleteTaskConfirmationModal: false,
        deleteUserFromProjectConfirmationModal: false,
        addUsertotheProjectModal: false,
        usertotheproject: "",
        taskcreationModal: false,
        projectcreationModal: false,
        taskupdationModal: false,
        projectupdationModal: false,
        taskname: "",
        taskId: "",
        projectId: "",
        taskemployeename: "",
        taskprojectname: "",
        taskteamname: "",
        projectName: "",
        teamName: "",
        projectDescription: "",
        showTaskDetailedView: false,
        taksDetail_selectedTab: "tasknote",
        selectedTaskData: [],
        projectmembers: [],
        taskStartDate: "",
        taskEndDate: "",
        taskStatus: "",

        showAddtaskModal: false,
        detailedTaskData: [],
        showObserverData: false,
        tableHrsColsToShow: [],
        showSettingsTable: false,
        hoursData: [],
        status_every: true,
        tableRowsData: "",
        tableRowsArrangement: "",
        numRows: 0,
        selectedProject: "",
        selectedDate: new Date(),
        selectedEmails: [],
        invoiceSendType: "automate",
        paymentTerms: "",
        openCustomDateModal: false,
        selectedModalLayout: "",
        selectedModalArrangement: "",
        modalUpdateHandler: "",
        numTaskCols: 0,
        taskTableLayout: "",
        taskTableArrangement: "",
        customFields: [],
        xeroIntegrate: false,
        xeroConnected: false,
        selectedBillRate: "",
        selectedCurrency: "usd",
        taskNotes: [],
        noteVal: "",
        noteFiles: [],
        isDownload: false,
        selectedReportValue: "Daily Report",
        selectReportType: "PDF",
        UsersDailyReports: [],
        dailyWorkingHours: "",
        dailyReportSelectedDate: new Date(),
        empDetails: "",
        projectSortOrder: "ascendingprojectname",
        taskSortOrder: "ascendingtaskname",
    };
  }

  componentDidMount() {
    const url = window.location.pathname;
    setTimeout(() => {
      this.setState({ urlpath: url });
    }, 200);
    this.props.fetchEmpLoginDetail().then((res) => {
      this.setState({ empDetails: res.value.data })
    })

    this.props.getProjectTableLayout().then((res) => {
      var obj = res.value.data.data;
      var num = obj
        ? Object.keys(obj).reduce(
          (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
          0
        )
        : 0;
      this.setState({ tableRowsData: obj, numRows: num });
    });
    this.props.getTaskTableLayout().then((res) => {
      var obj = res.value.data.data;
      var num = obj
        ? Object.keys(obj).reduce(
          (a, item) => a + (item !== "edit" && obj[item] === true ? 1 : 0),
          0
        )
        : 0;
      this.setState({ taskTableLayout: obj, numTaskCols: num });
    });
    this.props.getProjectTableArrangement().then((res) => {
      this.setState({ tableRowsArrangement: res.value.data.data });
    });
    this.props.getTaskTableArrangement().then((res) => {
      this.setState({ taskTableArrangement: res.value.data.data });
    });
    this.props
      .fetchUserDailyReport(
        moment(this.state.dailyReportSelectedDate).format("YYYY-MM-DD")
      )
      .then((res) => {
        if (res.value.status === 200) {
          this.setState({
            UsersDailyReports: res.value.data.response_data,
            dailyWorkingHours: res.value.data.total_hours,
          });
        }
      });

    this.props.fetchUserMonthlyReport().then((res) => {
      if (res.value.status === 200) {
      }
    });
    setTimeout(() => {
      if (localStorage.getItem("ro") === "Observer") {
        this.setState({ SelectedTab: "tasks" });
      } else {
        if (this.state.urlpath === "/projects") {
          if (localStorage.getItem("ro") === "observer") {
            this.setState({ SelectedTab: "tasks" });
            window.history.pushState({}, null, `/${this.state.SelectedTab}`);
          } else {
            this.setState({ SelectedTab: "projects" });
          }
        } else if (this.state.urlpath === "/tasks") {
          this.setState({ SelectedTab: "tasks" });
        } else if (this.state.urlpath.includes("/tasks/taskname=")) {
          const taskname = this.props.match?.params?.taskname?.split("=")[1];
          if (!taskname) {
            this.setState({ SelectedTab: "tasks" });
            window.history.pushState({}, null, `/${this.state.SelectedTab}`);
          } else {
            this.props.fetchactiveTasks().then((res) => {
              if (res.value.status === 200) {
                for (let task of res.value.data) {
                  if (task.name === taskname) {
                    this.setState({
                      showTaskDetailedView: true,
                      SelectedTab: "",
                      selectedTaskData: task,
                    });
                    this.handleDetailedTaskInfo(taskname, task.id);
                    break;
                  }
                }
              }
            });
          }
        } else {
          this.setState({ SelectedTab: "projects" });
        }
      }
      if (!this.state.urlpath.includes("/tasks/taskname=")) {
        window.history.pushState({}, null, `/${this.state.SelectedTab}`);
      }
    }, 300);
    const org_uuid = localStorage.getItem("org_uuid");
    // this.props.fetchactiveprojects().then((res) => {
    //     this.setState({ loading: false });
    // });
    if (localStorage.getItem("ro") !== "Observer") {
      this.props.getProjectDetails().then((res) => {
        this.setState({ loading: false });
      });
    }
    if (localStorage.getItem("ro") === "Observer") {
      this.props.taskLsitObserver();
    }
    this.props.fetchactiveTasks();
    if(localStorage.getItem("roleUser") !== "employee"){
      this.props.fetchallEmployees();
    }
    this.props.FetchactiveTeams(org_uuid);
  }

  HandleMobileSelectTab = (e) => {
    this.setState({
      SelectedTab: e.target.value,
      showTaskDetailedView: false,
    });
    window.history.pushState({}, null, `/${e.target.value}`);
  };

  handleMobileTaskDetailTab = (e) => {
    this.setState({ taksDetail_selectedTab: e.target.value });
    window.history.pushState({}, null, `/${e.target.value}`);
  };

  HandleSelectTab = (e) => {
    if (e === "tasks") {
      this.setState({ selectedProject: "" });
    }
    this.setState({ SelectedTab: e, showTaskDetailedView: false });
    window.history.pushState({}, null, `/${e}`);
  };

  handleTaskSelectDetailTab = (e) => {
    this.setState({ taksDetail_selectedTab: e });
    // window.history.pushState({}, null, `${window.location.pathname}/${e}`);
  };

  handlecreateProject = () => {
    this.setState({
      projectcreationModal: true,
      projectName: "",
      projectDescription: "",
      selectedUsers: [],
    });
  };

  minToHrsConversion = (mins) => {
    let hours = mins / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    let duration = `${rhours}hr ${rminutes}m`;
    return duration;
  };

  onReportTypeChange = (e) => {
    this.setState({
      selectReportType: e.target.value,
    });
  };

  onReportValueChange = (e) => {
    this.setState({
      selectedReportValue: e.target.value,
    });
  };

  onDownload = () => {
    let isDownload = this.state.isDownload;
    let currentReportOption = this.state.selectedReportValue;
    let currentMonth = this.state.selectedDate.toLocaleString("default", {
      month: "long",
    });
    const dateString = this.state.selectedDate
      .toLocaleString("default", {
        date: "",
      })
      .split(",")[0]
      .split("/");
    let currentYear = dateString[2];

    const exportPDF = () => {
      if (currentReportOption === "Daily Report") {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        const title = `Employee Name : ${toTitleCase(this.state.empDetails.username)}`;
        const reportText = "Daily Report";
        const headers = [
          ["PROJECT NAME", "TEAM NAME", "TASK NAME", "TIME WORKED"],
        ];
        const totalDuration = this.minToHrsConversion(
          this.state.dailyWorkingHours.total_duration
        );
        let data;
        if (this.state.UsersDailyReports.length > 0) {
          data = this.state.UsersDailyReports.map((elt) => {
            let duration = this.minToHrsConversion(elt.duration);

            return [elt.project_name, elt.team_name, elt.task, duration];
          });
        } else {
          data = ["", "", "", "0hr 0m"];
        }
        const month = this.state.dailyReportSelectedDate.toLocaleString(
          "default",
          {
            month: "short",
          }
        );
        const dateString = this.state.dailyReportSelectedDate
          .toLocaleString("default", {
            date: "",
          })
          .split(",")[0]
          .split("/");
        const date = dateString[1];
        const year = dateString[2];
        let content = {
          startY: 70,
          head: headers,
          theme: "grid",
          body: data,
          headStyles: { fillColor: "#D1D5DB", textColor: "#6B7280" },
        };

        doc.setFontSize(14);
        doc.setFont("normal");
        doc.text(title, marginLeft, 60);

        doc.setFontSize(20);
        doc.setFont("bolder");
        doc.text(reportText, 250, 30);
        doc.setFontSize(14);

        doc.setFont("lighter");
        doc.text(`${month} ${date}, ${year}`, 480, 60);
        doc.setTextColor("black");

        doc.autoTable(content);

        doc.setFontSize(16);
        doc.setFont("bolder");

        doc.text(
          `Total Time Worked: ${totalDuration}`,
          365,
          doc.lastAutoTable.finalY + 20
        );
        doc.save("Daily_report.pdf");
      } else {
        this.props.fetchUserMonthlyReport().then((data) => {
          if (data.value.status === 200) {
            var blob = new Blob([data.value.data], { type: "application/pdf" });
            FileSaver.saveAs(blob, `Monthly_report.pdf`);
          }
        });
      }
    };

    return (
      isDownload && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={() => this.setState({ isDownload: false })}
          ></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg py-2 mx-auto bg-white rounded-xl shadow-lg">
              <div className="mt-2 sm:flex flex flex-col">
                <div className="min-w-full">
                  <div className="flex flex-row items-center justify-between mx-6 my-3">
                    <p>Download Report</p>
                    <IoMdClose
                      className="text-3xl text-gray-500 cursor-pointer"
                      onClick={() => {
                        this.setState({
                          isDownload: false,
                        });
                      }}
                    />
                  </div>
                  <hr className="border-gray-400" />
                </div>
                <div className="m-6 flex flex-col">
                  <div>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        value={this.state.selectedReportValue}
                      >
                        <FormControlLabel
                          value="Daily Report"
                          control={
                            <Radio
                              style={{
                                color: "#684D94",
                              }}
                            />
                          }
                          onChange={this.onReportValueChange}
                          label="Daily Report"
                        />
                        <FormControlLabel
                          value="Monthly Report"
                          control={
                            <Radio
                              style={{
                                color: "#684D94",
                              }}
                            />
                          }
                          onChange={this.onReportValueChange}
                          label="Monthly Report"
                        />
                      </RadioGroup>
                    </FormControl>
                    <div className="w-52 flex items-center mt-2 mb-2">
                      <DatePicker
                        className="rounded-md w-52 text-black cursor-pointer"
                        dateFormat="MMMM dd yyyy"
                        selected={this.state.dailyReportSelectedDate}
                        value={
                          this.state.selectedReportValue === "Daily Report"
                            ? new Date()
                            : `${currentMonth} ${currentYear}`
                        }
                        startDate={new Date()}
                        placeholderText={"Select Date"}
                        onChange={
                          (date) => {
                            this.setState(
                              {
                                dailyReportSelectedDate: date,
                              },
                              () => {
                                this.props
                                  .fetchUserDailyReport(
                                    moment(
                                      this.state.dailyReportSelectedDate
                                    ).format("YYYY-MM-DD")
                                  )
                                  .then((res) => {
                                    if (res.value.status === 200) {
                                      this.setState({
                                        UsersDailyReports:
                                          res.value.data.response_data,
                                        dailyWorkingHours:
                                          res.value.data.total_hours,
                                      });
                                    }
                                  });
                              }
                            );
                          }
                          // , () => {
                          // this.handleScreenshotDateChange(date)
                          // });
                        }
                      />
                      <CalendarIcon className="-ml-10 z-10 w-6"></CalendarIcon>
                    </div>
                    <p>Format type</p>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        value={this.state.selectReportType}
                      >
                        <FormControlLabel
                          value="XLSX"
                          control={
                            <Radio
                              style={{
                                color: "#684D94",
                              }}
                            />
                          }
                          onChange={this.onReportTypeChange}
                          label="XLSX"
                        />
                        <FormControlLabel
                          value="PDF"
                          control={
                            <Radio
                              style={{
                                color: "#684D94",
                              }}
                            />
                          }
                          onChange={this.onReportTypeChange}
                          label="PDF"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <button
                  onClick={() => {
                    exportPDF();
                  }}
                  className="bg-primary text-white mx-6 mb-2 p-2 text-sm rounded-md"
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  onModalCreateProjetSubmit = (hoursFunc, customFields) => {
    // if(userRole == 'observer'){return}
    if (!this.state.selectedTeam) {
      toast.error("Please select a team");
    } else {
      let users = this.state.selectedUsers.map((el) => el.value).join(",");
      const formdata = new FormData();
      for (let i = 0; i < customFields.length; i++) {
        if (customFields[i]["key"]) {
          formdata.append("key", customFields[i]["key"].toString().trim());
          formdata.append("value", customFields[i]["value"].toString().trim());
        }
      }
      formdata.append("projectName", this.state.projectName);
      formdata.append("projectDespriction", this.state.projectDescription);
      formdata.append("userName", users);
      formdata.append(
        "team_id",
        this.state.selectedTeam !== "" ? this.state.selectedTeam["value"] : ""
      );
      formdata.append(
        "team_name",
        this.state.selectedTeam !== "" ? this.state.selectedTeam["label"] : ""
      );
      this.props.createProject(formdata).then((res) => {
        if (res.value.status === 201) {
          hoursFunc(res.value.data);
          this.props.getProjectDetails();
          toast.success("Project Created Successfully");
        }
      });
      this.props.getProjectDetails();
      this.setState({ projectcreationModal: false });
    }
  };

  onModalUpdateProjetSubmit = (customFields) => {
    // if(userRole == 'observer'){return}
    if (!this.state.selectedTeam) {
      toast.error("Please select a team");
    } else {
      this.setState({ projectupdationModal: false });
      const formdata = new FormData();
      for (let i = 0; i < customFields.length; i++) {
        if (customFields[i]["key"]) {
          formdata.append("keys", customFields[i]["key"].toString().trim());
          formdata.append("values", customFields[i]["value"].toString().trim());
        }
      }
      formdata.append("id", this.state.projectId);
      formdata.append("project_name", this.state.projectName);
      formdata.append("project_description", this.state.projectDescription);
      if (this.state.selectedUsers) {
        formdata.append(
          "userName",
          this.state.selectedUsers.map((el) => el.value).join(",")
        );
      }
      formdata.append(
        "team_id",
        this.state.selectedTeam !== "" ? this.state.selectedTeam["value"] : ""
      );
      formdata.append(
        "team_name",
        this.state.selectedTeam !== "" ? this.state.selectedTeam["label"] : ""
      );
      this.props.updateProject(formdata).then((res) => {
        this.props.getProjectDetails();
        toast.success("Project Updated Successfully");
      });
    }
  };

  onUpdateProject = (
    name,
    description,
    id,
    status_every,
    data,
    team_name,
    customFiled,
    amount,
    currency
  ) => {
    // if(userRole == 'observer'){return}
    this.props.getUsersfromtheProject(name);
    let membersoftheProject =
      this.props.projects &&
      this.props.projects.UsersFromProject &&
      this.props.projects.UsersFromProject.users &&
      this.props.projects.UsersFromProject.users[0];

    let allusersintheproject =
      membersoftheProject &&
      membersoftheProject.map((el) => {
        return {
          value: el.username,
          label: el.first_name + el.last_name,
        };
      });

    let teams =
      this.props.teams &&
      this.props.teams.ActiveTeam &&
      this.props.teams.ActiveTeam.info;
    let listOfTeams = teams && teams.filter((el) => el.team.name === team_name);
    var teamval =
      listOfTeams && listOfTeams.length > 0
        ? {
          value: listOfTeams[0].team.team_uuid,
          label: listOfTeams[0].team.name,
        }
        : "";
    this.setState({
      projectupdationModal: true,
      customFields: customFiled,
      projectName: name,
      selectedBillRate: amount,
      selectedCurrency: currency,
      projectDescription: description,
      projectId: id,
      selectedUsers: allusersintheproject,
      projectmembers: membersoftheProject,
      status_every: status_every,
      hoursData: data,
      selectedTeam: teamval,

    });
  };

  onCreateProjectNameChange = (e) => {
    this.setState({ projectName: e.target.value });
  };
  members = () => {
    let membersoftheProject =
      this.props.projects &&
      this.props.projects.UsersFromProject &&
      this.props.projects.UsersFromProject.users &&
      this.props.projects.UsersFromProject.users[0];

    return membersoftheProject
  }
  onCreateProjectDescriptionChange = (e) => {
    this.setState({ projectDescription: e.target.value });
  };

  handleChangeuserName = (values) => {
    this.setState({ selectedUsers: values });
  };
  handleChangeTeamName = (values) => {
    this.setState({ selectedTeam: values });
  };

  GetUsersFromtheProject(el) {
    this.setState({
      SelectedProjectName: el.project_name,
      selectedProjectId: el.project_id,
      SelectedTab: "TeamMembers",
    });

     this.props.getUsersfromtheProject(el.project_name);
  }

  handleDeleteProject = (name) => {
    // if(userRole == 'observer'){return}
    this.setState({
      deleteProjectConfirmationModal: true,
      SelectedProjectName: name,
    });
  };

  onModalClose = () => {
    this.setState({
      deleteProjectConfirmationModal: false,
      projectcreationModal: false,
      taskcreationModal: false,
      projectupdationModal: false,
      taskupdationModal: false,
      deleteUserFromProjectConfirmationModal: false,
      deleteTaskConfirmationModal: false,
      addUsertotheProjectModal: false,
      openCustomDateModal: false,
     });
  };

  handleProjectNavigation = (projectId, data, projectName) => {
    this.props.history.push({
      // pathname: `/dashboard/?project=${projectName}`,
      pathname: `/projects/?project=${projectName}`,
      state: { projectId: projectId, data: data, projectName: projectName },
    });
  };

  onHandleDeleteProjectClick = () => {
    // if(userRole == 'observer'){return}
    this.setState({ deleteProjectConfirmationModal: false });
    this.props.DeleteProject(this.state.SelectedProjectName).then((res) => {
      // this.props.fetchactiveprojects();
      this.props.getProjectDetails();
      toast.success(
        `${this.state.SelectedProjectName} Project Deleted Successfully`
      );
    });
  };

  handleDeleteTask = (id) => {
    // if(userRole == 'observer'){
    //     return
    // }
    this.setState({ deleteTaskConfirmationModal: true, taskId: id });
  };

  onHandleDeleteTaskClick = () => {
    this.setState({ deleteTaskConfirmationModal: false });
    this.props.DeleteTask(this.state.taskId).then(() => {
      this.props.fetchactiveTasks();
      toast.success("Task Deleted Successfully");
    });
  };

  DeleteUserFromProject = (username) => {
    // if(userRole == 'observer'){return}
    this.setState({
      deleteUserFromProjectConfirmationModal: true,
      selectedUserNameFromProject: username,
    });
  };

  onHandleDeleteUserFromProjectClick = () => {
    // if(userRole == 'observer'){return}
    let data = {
      userName: this.state.selectedUserNameFromProject,
      projectName: this.state.SelectedProjectName,
    };
    this.props.DeleteUserFromProject(data).then((res) => {
      this.props.getUsersfromtheProject(this.state.SelectedProjectName);
      // this.props.fetchactiveprojects();
      this.props.getProjectDetails();
      toast.success(
        `${this.state.selectedUserNameFromProject} Deleted from ${this.state.SelectedProjectName} Project Successfully`
      );
    });
    this.setState({ deleteUserFromProjectConfirmationModal: false });
  };

  handleTaskCreation = () => {
    // if(userRole == 'observer'){
    //     return
    // }
    this.setState({
      taskcreationModal: true,
      taskname: "",
      taskemployeename: "",
      taskprojectname: "",
      taskteamname: "",
    });
  };

  handleUpdateTaskCreation = (selectedTaskData, empData) => {
    let taskData = { ...selectedTaskData };
    if (empData && empData.length > 0) {
      taskData.username = empData.filter(
        (el) => el.id[0] === selectedTaskData.username
      )[0].id;
    }
    this.setState({
      selectedTaskData: taskData,
      taskupdationModal: true,
    });
  };

  onModalUpdateTaskSubmit = () => {
    // if(userRole == 'observer'){return}
    if (
      !this.state.taskname &&
      !this.state.taskemployeename &&
      !this.state.taskprojectname &&
      !this.state.taskteamname
    ) {
      let data = {
        id: this.state.taskId,
        team: this.state.taskteamname,
        project: this.state.taskprojectname,
        username: this.state.taskemployeename,
      };
      this.setState({ taskupdationModal: false });
      this.props.updateTask(data).then(() => {
        this.props.fetchactiveTasks();
        toast.success("Task Updated Successfully");
      });
    }
  };

  onModalCreateTaskSubmit = () => {
    // if(userRole == 'observer'){return}
    if (
      !this.state.taskname &&
      !this.state.taskemployeename &&
      !this.state.taskprojectname &&
      !this.state.taskteamname
    ) {
      this.setState({ taskcreationModal: false });
      let data = {
        task: this.state.taskname,
        team: this.state.taskteamname,
        project: this.state.taskprojectname,
        username: this.state.taskemployeename,
      };
      this.props.createTask(data).then(() => {
        this.props.fetchactiveTasks();
        toast.success("Task Created Successfully");
      });
    }
  };

  onCreateTaskChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  AddUsertotheProject = () => {
    this.setState({ addUsertotheProjectModal: true });
  };

  HandleUserAddtotheProject = () => {
    // if(userRole == 'observer'){return}
    let data = {
      uu_id: this.state.usertotheproject.split(",")[1],
      project_id: this.state.selectedProjectId,
    };
    this.props.AdduserToTheProject(data).then((res) => {
      this.props.getUsersfromtheProject(this.state.SelectedProjectName);
      // this.props.fetchactiveprojects();
      this.props.getProjectDetails();
      toast.success("User Added Successfully");
    });
    this.setState({ addUsertotheProjectModal: false });
  };

  handleUserChangetotheProject = (e) => {
    this.setState({
      usertotheproject: e.target.value,
    });
  };

  handleDetailedTaskInfo = (taskname, taskId) => {
    this.setState({
      taskNotes: [],
    });
    const data = {
      task: taskname,
    };
    this.props.deatiledTaskInfo(data).then((res) => {
      this.setState({
        detailedTaskData: res.value.data,
      });
    });
    this.props.getNotes(taskId).then((res) => {
      if (res.value.status === 200) {
        let arr = res.value.data;
        arr.reverse();
        this.setState({
          taskNotes: arr,
        });
      }
    });
  };
  onSubmitClickHandler = (data, arrangementData) => {
    const newData = {
      hours_per_month: data[arrangementData["hours_per_month"] - 1]["status"]
        ? "True"
        : "False",
      hours_booked: data[arrangementData["hours_booked"] - 1]["status"]
        ? "True"
        : "False",
      hours_remaining: data[arrangementData["hours_remaining"] - 1]["status"]
        ? "True"
        : "False",
      hours_rolled_over: data[arrangementData["hours_rolled_over"] - 1][
        "status"
      ]
        ? "True"
        : "False",
    };

    const newArrangement = {
      hours_per_month: arrangementData["hours_per_month"],
      hours_booked: arrangementData["hours_booked"],
      hours_remaining: arrangementData["hours_remaining"],
      hours_rolled_over: arrangementData["hours_rolled_over"],
    };
    if (this.state.tableRowsArrangement["edit"]) {
      this.props
        .updateProjectTableArrangement(newArrangement)
        .then((response) => {
          if (response.value.status === 200) {
            this.setState({
              tableRowsArrangement: arrangementData,
            });
          }
        });
    } else {
      this.props
        .postProjectTableArrangement(newArrangement)
        .then((response) => {
          if (response.value.status === 200) {
            this.setState({
              tableRowsArrangement: arrangementData,
            });
          }
        });
    }
    if (this.state.tableRowsData["edit"]) {
      this.props.updateProjectTableLayout(newData).then((response) => {
        if (response.value.status === 200) {
          this.props.getProjectTableLayout().then((res) => {
            var obj = res.value.data.data;
            var num = obj
              ? Object.keys(obj).reduce(
                (a, item) =>
                  a + (item !== "edit" && obj[item] === true ? 1 : 0),
                0
              )
              : 0;
            this.setState({
              tableRowsData: obj,
              numRows: num,
              showSettingsTable: false,
            });
          });
        }
      });
    } else {
      this.props.postProjectTableLayout(newData).then((response) => {
        if (response.value.status === 200) {
          this.props.getProjectTableLayout().then((res) => {
            var obj = res.value.data.data;
            var num = obj
              ? Object.keys(obj).reduce(
                (a, item) =>
                  a + (item !== "edit" && obj[item] === true ? 1 : 0),
                0
              )
              : 0;
            this.setState({
              tableRowsData: obj,
              numRows: num,
              showSettingsTable: false,
            });
          });
        }
      });
    }
  };

  onTaskLayoutSubmitClickHandler = (data, arrangementData) => {
    const newData = {
      team_name: data[arrangementData["team_name"] - 1]["status"]
        ? "True"
        : "False",
      project_name: data[arrangementData["project_name"] - 1]["status"]
        ? "True"
        : "False",
      user_name: data[arrangementData["user_name"] - 1]["status"]
        ? "True"
        : "False",
      start_date: data[arrangementData["start_date"] - 1]["status"]
        ? "True"
        : "False",
      end_date: data[arrangementData["end_date"] - 1]["status"]
        ? "True"
        : "False",
      status_task: data[arrangementData["status_task"] - 1]["status"]
        ? "True"
        : "False",
      custom_fields: data[arrangementData["custom_fields"] - 1]["status"]
        ? "True"
        : "False",
    };

    const newArrangement = {
      team_name: arrangementData["team_name"],
      project_name: arrangementData["project_name"],
      user_name: arrangementData["user_name"],
      start_date: arrangementData["start_date"],
      end_date: arrangementData["end_date"],
      status_task: arrangementData["status_task"],
      custom_fields: arrangementData["custom_fields"],
    };
    if (this.state.taskTableArrangement["edit"]) {
      this.props.updateTaskTableArrangement(newArrangement).then((response) => {
        if (response.value.status === 200) {
          this.setState({
            taskTableArrangement: arrangementData,
          });
        }
      });
    } else {
      this.props.postTaskTableArrangement(newArrangement).then((response) => {
        if (response.value.status === 200) {
          this.setState({
            taskTableArrangement: arrangementData,
          });
        }
      });
    }
    if (this.state.taskTableLayout["edit"]) {
      this.props.updateTaskTableLayout(newData).then((response) => {
        if (response.value.status === 200) {
          this.props.getTaskTableLayout().then((res) => {
            var obj = res.value.data.data;
            var num = obj
              ? Object.keys(obj).reduce(
                (a, item) =>
                  a + (item !== "edit" && obj[item] === true ? 1 : 0),
                0
              )
              : 0;
            this.setState({
              taskTableLayout: obj,
              numTaskCols: num,
              showSettingsTable: false,
            });
          });
        }
      });
    } else {
      this.props.postTaskTableLayout(newData).then((response) => {
        if (response.value.status === 200) {
          this.props.getTaskTableLayout().then((res) => {
            var obj = res.value.data.data;
            var num = obj
              ? Object.keys(obj).reduce(
                (a, item) =>
                  a + (item !== "edit" && obj[item] === true ? 1 : 0),
                0
              )
              : 0;
            this.setState({
              taskTableLayout: obj,
              numTaskCols: num,
              showSettingsTable: false,
            });
          });
        }
      });
    }
  };
  render() {
    const { t } = this.props;
    let userRole = localStorage.getItem("roleUser").toLocaleLowerCase();
    if (this.props.localStorageState) {
      let userRole =
        this.props.localStorageState && this.props.localStorageState;
    }
    let activeprojects =
      this.props.projects &&
      this.props.projects.ActiveProjects &&
      this.props.projects.ActiveProjects.response_data;

    let activrProjectsList =
      this.props.projects && this.props.projects.projectDetails;
      activrProjectsList = activrProjectsList && [...activrProjectsList]?.sort((a,b)=>a.project_name.localeCompare(b.project_name))

    let activetasks = this.props.projects && this.props.projects.Activetasks;
    let employees = this.props.projects && this.props.projects.AllEmployees;
    let teams =
      this.props.teams &&
      this.props.teams.ActiveTeam &&
      this.props.teams.ActiveTeam.info;
    let membersoftheProject =
      this.props.projects &&
      this.props.projects.UsersFromProject &&
      this.props.projects.UsersFromProject.users &&
      this.props.projects.UsersFromProject.users[0];
    const { SelectedTab, SelectedProjectName } = this.state;
    let EmployeeData =
      employees &&
      employees.map((el) => ({
        name: el.user_first_name + " " + el.user_last_name,
        id: [el.user_login_as, el.user_id],
      }));

    let ProjectData =
      activrProjectsList &&
      activrProjectsList.map((el) => ({
        name: el.project_name,
        id: el.project_name,
      }));
    let ProjectDataId =
      activrProjectsList &&
      activrProjectsList.map((el) => ({
        name: el.project_name,
        id: el.project_id,
      }));
    let TeamData =
      teams &&
      teams.map((el) => ({
        name: el.team.name,
        id: el.team.name,
      }));

    let listofallemployees =
      employees &&
      employees.map((el) => {
        return {
          value: el.user_login_as,
          label: el.user_first_name + " " + el.user_last_name,
        };
      });
    let listOfTeams =
      teams &&
      teams.map((el) => ({
        value: el.team.team_uuid,
        label: el.team.name,
      }));
    const statusArray = [
      "Not Yet Started",
      "Active",
      "Ongoing",
      "Completed",
      "Incomplete",
    ];
    const priorityArray = ["High", "Medium", "Low"];

    let listOfStatus =
      statusArray &&
      statusArray.map((el) => ({
        name: el,
      }));

    let listOfPriority =
      priorityArray &&
      priorityArray.map((el) => ({
        name: el,
      }));
    let Data = activrProjectsList && activrProjectsList
    activrProjectsList && Data.forEach((el) =>
      el.team_name ? el.team_name : el.team_name = "default"
    )
    let filterDataProjectTab
    if (this.state.teamName !== "") {
      const filteredTeamData =
        activrProjectsList && Data.filter((el) => el.team_name === this.state.teamName);
      filterDataProjectTab =
        activrProjectsList && Data.length === 0
          ? []
          : this.state.teamName === ""
            ? Data
            : filteredTeamData;
      // console.log(Data,"lfilterteamdata")
    }

    if (this.state.teamName === "" && this.state.projectName !== "") {
      const filteredProjectData =
        activrProjectsList && Data.filter((el) => el.project_name === this.state.projectName);
      filterDataProjectTab =
        activrProjectsList && Data.length === 0
          ? []
          : this.state.projectName === ""
            ? Data
            : filteredProjectData;
    }

    if (this.state.projectName !== "" && this.state.teamName !== "") {
      const filteredteamandprojectdata =
        activrProjectsList &&
        Data.filter(
          (el) =>
            el.team_name === this.state.teamName &&
            el.project_name === this.state.projectName
        );
      filterDataProjectTab =
        activrProjectsList && Data.length === 0
          ? []
          : this.state.projectName === ""
            ? Data
            : filteredteamandprojectdata;
    }

    filterDataProjectTab =
      activrProjectsList && activrProjectsList.length === 0
        ? []
        : this.state.teamName === "" && this.state.projectName === ""
          ? activrProjectsList
          : filterDataProjectTab;
    filterDataProjectTab?.sort((a,b)=>{
      if(this.state.projectSortOrder === "descendingprojectname"){
        return b.project_name?.localeCompare(a.project_name);
      }
      if(this.state.projectSortOrder === "ascendingprojectteam"){
        return a.team_name?.localeCompare(b.team_name);
      }
      if(this.state.projectSortOrder === "descendingprojectteam"){
        return b.team_name?.localeCompare(a.team_name);
      }
      if(this.state.projectSortOrder === "ascendingprojectteam"){
        return a.team_name?.localeCompare(b.team_name);
      }
      if(this.state.projectSortOrder === "descendingprojectteam"){
        return b.team_name?.localeCompare(a.team_name);
      }
      if(this.state.projectSortOrder === "ascendingprojectdesc"){
        return a.project_description?.localeCompare(b.project_description);
      }
      if(this.state.projectSortOrder === "descendingprojectdesc"){
        return b.project_description?.localeCompare(a.project_description);
      }
      if(this.state.projectSortOrder === "ascendingprojectmembers"){
        return a.employee_count-b.employee_count;
      }
      if(this.state.projectSortOrder === "descendingprojectmembers"){
        return b.employee_count- a.employee_count;
      }
      if(this.state.projectSortOrder === "ascendingprojectempbillrate"){
        return a.amount-b.amount;
      }
      if(this.state.projectSortOrder === "descendingprojectempbillrate"){
        return b.amount- a.amount;
      }
      if(this.state.projectSortOrder === "ascendingprojecthourspermonth"){
        return a.hours_spent - b.hours_spent;
      }
      if(this.state.projectSortOrder === "descendingprojecthourspermonth"){
        return b.hours_spent - a.hours_spent;
      }
      if(this.state.projectSortOrder === "ascendingprojecthoursbooked"){
        return a.data[0]["hours"] - b.data[0]["hours"];
      }
      if(this.state.projectSortOrder === "descendingprojecthoursbooked"){
        return b.data[0]["hours"] - a.data[0]["hours"];
      }
      if(this.state.projectSortOrder === "ascendingprojecthoursremaining"){
        return a.data[0]["hours_remaining"] - b.data[0]["hours_remaining"];
      }
      if(this.state.projectSortOrder === "descendingprojecthoursremaining"){
        return b.data[0]["hours_remaining"] - a.data[0]["hours_remaining"];
      }
      if(this.state.projectSortOrder === "ascendingprojecthoursrollover"){
        return a.data[0]["hours_rolled"]-b.data[0]["hours_rolled"];
      }
      if(this.state.projectSortOrder === "descendingprojecthoursrollover"){
        return b.data[0]["hours_rolled"] - a.data[0]["hours_rolled"];
      }
      return a.project_name?.localeCompare(b.project_name);
    })
    let filterData;
    let data =
      localStorage.getItem("ro") === "Observer"
        ? this.props.projects && this.props.projects.TaskListObserver
        : this.props.projects && this.props.projects.Activetasks;
    if (this.state.teamName !== "") {
      const filteredTeamData =
        data && data.filter((el) => el.team === this.state.teamName);
      filterData =
        data && data.length === 0
          ? []
          : this.state.teamName === ""
            ? data
            : filteredTeamData;
    }

    if (this.state.teamName === "" && this.state.projectName !== "") {
      const filteredProjectData =
        data && data.filter((el) => el.project === this.state.projectName);
      filterData =
        data && data.length === 0
          ? []
          : this.state.projectName === ""
            ? data
            : filteredProjectData;
    }

    if (this.state.projectName !== "" && this.state.teamName !== "") {
      const filteredteamandprojectdata =
        data &&
        data.filter(
          (el) =>
            el.team === this.state.teamName &&
            el.project === this.state.projectName
        );
      filterData =
        data && data.length === 0
          ? []
          : this.state.projectName === ""
            ? data
            : filteredteamandprojectdata;
    }
    filterData =
      data && data.length === 0
        ? []
        : this.state.teamName === "" && this.state.projectName === ""
          ? data
          : filterData;
    filterData?.sort((a,b)=>{
      if(this.state.taskSortOrder === "descendingtaskname"){
        return b.name?.localeCompare(a.name);
      }
      if(this.state.taskSortOrder === "ascendingtaskteamname"){
        return a.team?.localeCompare(b.team);
      }
      if(this.state.taskSortOrder === "descendingtaskteamname"){
        return b.team?.localeCompare(a.team);
      }
      if(this.state.taskSortOrder === "ascendingtaskprojectname"){
        return a.project?.localeCompare(b.project);
      }
      if(this.state.taskSortOrder === "descendingtaskprojectname"){
        return b.project?.localeCompare(a.project);
      }
      if(this.state.taskSortOrder === "ascendingtaskusername"){
        return a.username?.localeCompare(b.username);
      }
      if(this.state.taskSortOrder === "descendingtaskusername"){
        return b.username?.localeCompare(a.username);
      }
      if(this.state.taskSortOrder === "ascendingtaskstartdate"){
        let sd1 = new Date(a.start_date);
        let sd2 = new Date(b.start_date);
        return sd1.getTime() - sd2.getTime();
      }
      if(this.state.taskSortOrder === "descendingtaskstartdate"){
        let sd1 = new Date(b.start_date);
        let sd2 = new Date(a.start_date);
        return sd1.getTime() - sd2.getTime();
      }
      if(this.state.taskSortOrder === "ascendingtaskenddate"){
        let sd1 = new Date(a.end_date);
        let sd2 = new Date(b.end_date);
        return sd1.getTime() - sd2.getTime();
      }
      if(this.state.taskSortOrder === "descendingtaskenddate"){
        let sd1 = new Date(b.end_date);
        let sd2 = new Date(a.end_date);
        return sd1.getTime() - sd2.getTime();
      }
      if(this.state.taskSortOrder === "ascendingtaskstatus"){
        return a.task_status?.localeCompare(b.task_status);
      }
      if(this.state.taskSortOrder === "descendingtaskstatus"){
        return b.task_status?.localeCompare(a.task_status);
      }
      return a.name?.localeCompare(b.name);
    });
    let TeamDataProjectTab =
      activrProjectsList &&
      activrProjectsList.map((el) => (el.team_name ? el.team_name : "default")).filter((item, index) =>
        activrProjectsList.map((el) => (el.team_name ? el.team_name : "default")).indexOf(item) == index
      );

    // console.log(TeamDataProjectTab)

        // console.log("filetrdata",filterData)

    const showObserverDataToggle = () => {
      if (this.state.showObserverData) {
        this.setState({ showObserverData: false });
        this.props.fetchactiveTasks();
      } else {
        this.setState({ showObserverData: true });
        this.props.fetchActiveTaskOserver();
      }
    };
    const smoothScroll = (target) => {
      var scrollContainer = target;
      do {
        //find scroll container
        scrollContainer = scrollContainer.parentNode;
        if (!scrollContainer) return;
        scrollContainer.scrollTop += 1;
      } while (scrollContainer.scrollTop === 0);
      var targetY = 0;
      do {
        //find the top of target relatively to the container
        if (target === scrollContainer) break;
        targetY += target.offsetTop;
      } while ((target = target.offsetParent));
      window.scroll = function (c, a, b, i) {
        i++;
        if (i > 27) return;
        c.scrollTop = a + ((b - a) / 30) * i;
        setTimeout(function () {
          window.scroll(c, a, b, i);
        }, 20);
      };
      // start scrolling
      window.scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
    };

    const productivitychart = {
      chart: {
        type: "column",
        height: 250,
        style: {
          fontFamily: "Lato",
          fontSize: "15px",
        },
      },
      title: {
        text: "",
      },
      yAxis: [
        {
          labels: {
            format: "{value} h",
          },
          title: {
            text: " ",
          },
          max: 20,
        },
      ],
      plotOptions: {
        column: {
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                let [hours, AMorPM] = this.category.split(" ");
                if (hours === "12") {
                  hours = "0";
                }
                if (AMorPM === "PM") {
                  hours = parseInt(hours, 10) + 12;
                }
                smoothScroll(document.getElementById(hours));
              },
            },
          },
        },
      },
      xAxis: {
        categories: ["10/05", "11/05", "12/05", "13/05", "14/05"],
        crosshair: true,
      },
      tooltip: {
        valueSuffix: "h",
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          showInLegend: false,
          name: "hourlyPercentage",
          data: ["0", "20", "40", "50", "60"],
        },
      ],
    };

    const indexchart = {
      chart: {
        type: "column",
        height: 250,
        style: {
          fontFamily: "Lato",
          fontSize: "15px",
        },
      },
      title: {
        text: "",
      },
      yAxis: [
        {
          labels: {
            format: "{value} %",
          },
          title: {
            text: "",
          },
          max: 100,
        },
      ],
      plotOptions: {
        series: {
          cursor: "pointer",
          // point: {
          //     events: {
          //         click: (function (component) {
          //             return function () {
          //                 component.handleDateChange(
          //                     arrayDates1 && arrayDates1.length > this.index
          //                         ? new Date(arrayDates1[this.index])
          //                         : ""
          //                 );
          //             };
          //         })(this),
          //     },
          // },
        },
      },
      xAxis: {
        categories: ["10/05", "11/05", "12/05", "13/05", "14/05"],
        crosshair: true,
      },
      tooltip: {
        valueSuffix: "Hr",
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          showInLegend: false,
          color: "#684D94",
          name: "Productive",
          data: ["100%", "100%", "100%", "100%", "100%"],
        },
      ],
    };
    return (
      <Layout>
        <div className="bg-white px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              onChange={(e) => this.HandleMobileSelectTab(e)}
              className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
              defaultValue={this.state.SelectedTab}
            >
              {userRole === "observer" ? null : (
                <option key="projects" value="projects">
                  Projects
                </option>
              )}
              <option key="Tasks" value="tasks">
                Tasks
              </option>
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {userRole === "observer" ? null : (
                <div
                  key="Teams"
                  onClick={() => this.HandleSelectTab("projects")}
                  className={classNames(
                    this.state.SelectedTab === "projects" ||
                      this.state.SelectedTab === "TeamMembers"
                      ? "border-primary text-primary"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                  )}
                  aria-current={
                    this.state.SelectedTab === "projects" ||
                      this.state.SelectedTab === "TeamMembers"
                      ? "page"
                      : undefined
                  }
                >
                  <BriefcaseIcon
                    className={classNames(
                      this.state.SelectedTab === "projects" ||
                        this.state.SelectedTab === "TeamMembers"
                        ? "text-primary"
                        : "text-gray-400 group-hover:text-gray-500",
                      "-ml-0.5 mr-2 h-5 w-5"
                    )}
                    aria-hidden="true"
                  />
                  <span>{t("projects")}</span>
                </div>
              )}

              <div
                key="Users"
                onClick={() => this.HandleSelectTab("tasks")}
                className={classNames(
                  this.state.SelectedTab === "tasks" ||
                    this.state.showTaskDetailedView
                    ? "border-primary text-primary"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                )}
                aria-current={
                  this.state.SelectedTab === "tasks" ? "page" : undefined
                }
              >
                <BriefcaseIcon
                  className={classNames(
                    this.state.SelectedTab === "tasks" ||
                      this.state.showTaskDetailedView
                      ? "text-primary"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5"
                  )}
                  aria-hidden="true"
                />
                <span>{t("tasks")}</span>
              </div>
            </nav>
          </div>
        </div>
        {this.state.selectedProject && SelectedTab === "projects" ? (
          <div className="bg-white mt-4 px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
            <div className="flex justify-between items-center">
              <div
                className="rounded-md shadow-md border border-fiver border-opacity-60 py-1 px-4 hover:cursor-pointer"
                onClick={() => this.setState({ selectedProject: "" })}
              >
                Back
              </div>
              <div className="relative min-w-max">
                <div className="absolute right-2 top-2 z-[1]">
                  <ChevronDownIcon color="#6B7280" width={25} height={25} />
                </div>
                <div className="absolute -top-2 left-2 px-1 py-0.5 bg-[#ffffff] text-xs z-[1]">
                  Select Month
                </div>
                <DatePicker
                  selected={this.state.selectedDate}
                  className="rounded-md min-w-max w-56"
                  dateFormat="MMM yyyy"
                  onChange={(date) => this.setState({ selectedDate: date })}
                  showMonthYearPicker
                />
              </div>
            </div>
            <div className="mt-4 flex justify-between items-start">
              <div>
                <div className="text-2xl">Team Workahub</div>
                <div className=" text-[#6B7280]">Workahub Project Title</div>
              </div>
              <div className="flex text-[#6B7280] text-tiny">
                <CalendarIcon className="-ml-6 w-5 mr-2"></CalendarIcon>
                <div>
                  {moment(this.state.selectedDate).format("MMM DD, YYYY")}
                </div>
              </div>
            </div>
            <div className="flex justify-between items-start p-4 pb-6 border-b border-fiver border-opacity-60">
              <div className="pl-2">
                <div className=" xl:text-xl">
                  For the Month of{" "}
                  {moment(this.state.selectedDate).format("MMMM YYYY")}
                </div>
                <div className="text-xl xl:text-3xl text-primary font-semibold">
                  $2322
                </div>
              </div>
              <div className="rounded-md text-[#ffffff] py-1.5 px-4 bg-primary hover:cursor-pointer">
                Download Invoice
              </div>
            </div>
            <div className="xl:max-w-7xl">
              <div className="mt-6 mb-4 text-xl xl:text-3xl">
                Invoice Settings
              </div>
              <div className="flex items-start min-w-full">
                <div className="lg:w-1/2 xl:w-1/3">
                  <div className="text-lg">Invoice to</div>
                  <InputField
                    FieldName=""
                    className="py-1"
                    name={"invoiceto"}
                    type={"text"}
                    id={"invoiceto"}
                    onInputChange={() => { }}
                    placeholder={"Enter customer name"}
                  />
                </div>
                <div className="flex-1 lg-m:text-tiny ml-4 xl:ml-8">
                  <div className="text-lg">Email to</div>
                  <div className="w-full rounded-md border border-fiver flex flex-wrap items-center px-1.5 py-1 focus-within:border-primary">
                    {this.state.selectedEmails.map((email, index) => {
                      return (
                        <div className="mx-1.5 px-2 py-0.5 flex items-center rounded-md border border-fiver">
                          <div className="p-0 m-0">{email}</div>
                          <XIcon
                            className="ml-2 hover:cursor-pointer"
                            onClick={() => {
                              this.setState({
                                selectedEmails:
                                  this.state.selectedEmails.filter(
                                    (em, idx) => idx !== index
                                  ),
                              });
                            }}
                            width={16}
                            height={16}
                          />
                        </div>
                      );
                    })}
                    <input
                      type={"text"}
                      onChange={(ev) => {
                        const val = ev.target.value;
                        if (val.at(-1) === ",") {
                          var mailformat =
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                          if (!val.split(",")[0].match(mailformat)) {
                            alert("Invalid email");
                            ev.target.value = val.split(",")[0];
                          } else {
                            this.setState({
                              selectedEmails: [
                                ...this.state.selectedEmails,
                                val.split(",")[0],
                              ],
                            });
                            ev.target.value = "";
                          }
                        }
                      }}
                      className="border-0 focus:ring-0 flex-1 px-1.5 min-w-min"
                      placeholder={
                        !this.state.selectedEmails.length
                          ? "Enter email address, use comma to seperate"
                          : ""
                      }
                    />
                  </div>
                  <div className="text-xs lg:text-tiny opacity-50 pl-2 pr-1 pt-1">
                    You can enter multiple recipients to send invoice
                  </div>
                </div>
              </div>
              <div className="flex py-4">
                <div className="flex">
                  <Checkbox
                    id="automateInvoice"
                    style={{ color: "#684D94" }}
                    checked={this.state.invoiceSendType === "automate"}
                    onClick={() =>
                      this.setState({
                        invoiceSendType: "automate",
                      })
                    }
                  />
                  <label
                    htmlFor="automateInvoice"
                    className="flex items-center"
                  >
                    <div
                      className={`pl-1.5 ${this.state.invoiceSendType !== "automate" &&
                        "opacity-60"
                        }`}
                    >
                      Send Automate Invoice
                    </div>
                  </label>
                </div>
                <div className="flex ml-4">
                  <Checkbox
                    id="immediateInvoice"
                    style={{ color: "#684D94" }}
                    checked={this.state.invoiceSendType === "immediate"}
                    onClick={() =>
                      this.setState({
                        invoiceSendType: "immediate",
                      })
                    }
                  />
                  <label
                    htmlFor="immediateInvoice"
                    className="flex items-center"
                  >
                    <div
                      className={`pl-1.5 ${this.state.invoiceSendType !== "immediate" &&
                        "opacity-60"
                        }`}
                    >
                      Immediate Invoice
                    </div>
                  </label>
                </div>
              </div>
              <div className="flex items-center py-2">
                <div className=" text-lg font-semibold min-w-max">
                  Select payment terms
                </div>
                <div className={"ml-0 lg:ml-4 w-1/2 xl:w-1/3 md-m:w-full"}>
                  <SelectField
                    onSelectChange={(e) => {
                      this.setState({
                        paymentTerms: e.target.value,
                      });
                    }}
                    FieldName=""
                    name={"paymentTerms"}
                    arrayOfData={[
                      { name: "opt1", id: "opt1" },
                      { name: "opt3", id: "opt3" },
                      { name: "opt2", id: "opt2" },
                    ]}
                    id={"employee"}
                    value={
                      this.state.paymentTerms === ""
                        ? "Select Payment terms"
                        : this.state.paymentTerms
                    }
                  />
                </div>
                <div
                  className="flex text-primary hover:cursor-pointer items-center min-w-max ml-2"
                  onClick={() =>
                    this.setState({
                      openCustomDateModal: true,
                    })
                  }
                >
                  <CalendarIcon className="z-10 w-5 mx-2"></CalendarIcon>
                  <div>Create Custom Date</div>
                </div>
              </div>
              <div className="flex w-full justify-end py-4 my-6 border-t-2 border-fiver border-opacity-50">
                <div className=" rounded-md bg-primary text-white py-2 px-4 text-tiny">
                  Save Changes
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {SelectedTab === "projects" && !this.state.selectedProject ? (
          <div className="bg-white mt-4 px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
            {userRole !== "observer" && userRole !== "employee" ? (
              <div className="-ml-4 flex items-center justify-between flex-wrap sm:flex-nowrap">
                <div className="ml-4 mt-2"></div>
                {/* <div className="ml-4 mt-2 flex flex-row gap-2 w-full mx-4">
                  {userRole !== "observer" ? (
                    <SelectField
                      onSelectChange={(e) => this.onCreateTaskChange(e)}
                      FieldName={`${t("select")} ${t("project")}`}
                      name={"projectName"}
                      arrayOfData={ProjectData}
                      // mainclassName={"ml-4"}
                      id={"project"}
                    ></SelectField>
                  ) : null}

                  <SelectField
                    onSelectChange={(e) => this.onCreateTaskChange(e)}
                    FieldName={`${t("select")} ${t("team")}`}
                    name={"teamName"}
                    arrayOfData={TeamData}
                    id={"team"}
                  ></SelectField>

                </div> */}
                <div className="ml-4 mt-2 flex items-center text-primary flex-shrink-0">
                  <div
                    className="flex mr-4 items-center hover:cursor-pointer"
                    disabled={userRole === "observer" ? true : false}
                    onClick={() => {
                      this.setState({
                        showSettingsTable: true,
                        selectedModalLayout: this.state.tableRowsData,
                        selectedModalArrangement:
                          this.state.tableRowsArrangement,
                        modalUpdateHandler: this.onSubmitClickHandler,
                      });
                    }}
                  >
                    <BsGearFill className="mr-2" />
                    <p className="font-semibold">{t("settings")}</p>
                  </div>
                  <PrimaryButton
                    disabled={userRole === "observer" ? true : false}
                    onClick={() => this.handlecreateProject()}
                    buttontext={`${t("create")} ${t("project")}`}
                  ></PrimaryButton>
                </div>
              </div>
            ) : null}
            {userRole === "employee" ? (
              <div className="flex flex-row	justify-end">
                {/* <div className="w-20">
                  <SelectField
                  onSelectChange={(e) => this.onCreateTaskChange(e)}
                  FieldName={`${t("select")} ${t("project")}`}
                  name={"projectName"}
                  arrayOfData={ProjectData}
                  mainclassName={"ml-4"}
                  id={"project"}
                ></SelectField>
                </div> */}
                <DownloadIcon
                  className="h-6 w-6 cursor-pointer mt-2 text-right"
                  onClick={() => {
                    this.setState({
                      isDownload: true,
                    });
                  }}
                />
              </div>
            ) : null}

            {this.onDownload()}
            <div className="mt-4 h-full flex flex-col bg-gray-100">
              <div className="inline-block min-w-full align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[65vh] overflow-y-auto">
                  {userRole === "employee" ? (
                    <table className="min-w-full">
                      <thead className="bg-gray-50 sticky top-0">
                        <tr
                          className={`grid grid-cols-[minmax(0,2fr)_minmax(0,4fr)_minmax(0,1fr)] min-w-max w-full`}
                        >
                          <th
                            scope="col"
                            className="top-0 order-1 border border-gray-300 rounded-tl-lg bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                          >
                            {t("project")} {t("name")}
                          </th>
                          <th
                            scope="col"
                            className="top-0 order-1 border border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                          >
                            {t("Description")}
                          </th>
                          <th
                            scope="col"
                            className="top-0 order-1 border border-gray-300 bg-gray-50 rounded-tr-lg bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                          >
                            {t("ProjectTasksTab.members")}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {filterDataProjectTab &&
                          filterDataProjectTab.map((el, i) => (
                            <tr
                              key={i}
                              className={`grid grid-cols-[minmax(0,2fr)_minmax(0,4fr)_minmax(0,1fr)] min-w-full`}
                            >
                              <td
                                className={classNames(
                                  i !== filterDataProjectTab.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  `whitespace-wrap border-l border-b border-gray-300 ${i === filterDataProjectTab.length - 1
                                    ? "rounded-bl-lg"
                                    : ""
                                  } py-4 pl-4 pr-3 text-sm font-medium text-gray-900 order-1 capitalize`
                                )}
                              >
                                {el.project_name}
                              </td>
                              <td
                                className={classNames(
                                  i !== filterDataProjectTab.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  `whitespace-wrap border-x border-b border-gray-300 px-3 py-4 text-sm text-gray-500 order-1`
                                )}
                              >
                                {el.project_description}
                              </td>
                              <td
                                className={classNames(
                                  i !== filterDataProjectTab.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  `whitespace-nowrap border-r border-b border-gray-300 ${i === filterDataProjectTab.length - 1
                                    ? "rounded-br-lg"
                                    : ""
                                  } px-3 py-4 text-sm text-gray-500 order-1`
                                )}
                              >
                                <div className="flex">
                                  <UsersIcon className="mr-4 w-5"></UsersIcon>
                                  <span
                                    className="cursor-pointer text-primary"
                                    onClick={() =>
                                      this.GetUsersFromtheProject(el)
                                    }
                                  >
                                    {el.employee_count}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <table className="min-w-full border-separate">
                      <thead className="bg-gray-50 sticky top-0 z-10">
                        <tr
                          className={`grid grid-cols-${this.state.numRows +
                            (userRole === "observer"
                              ? //  || userRole === "employee"
                              4
                              : 5)
                            } min-w-max w-full`}
                        >
                          <th
                            scope="col"
                            className="top-0 order-1 flex gap-2 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                          >
                            {t("project")} {t("name")}
                            {this.state.projectSortOrder !==
                                  "ascendingprojectname" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"ascendingprojectname"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"descendingprojectname"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                          </th>
                          {userRole !== "observer" ? (
                            //  && userRole !== "employee"
                            <th
                              scope="col"
                              className="top-0 order-1 flex gap-2 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                            >
                              {t("team")} {t("name")}
                              {this.state.projectSortOrder !==
                                  "ascendingprojectteam" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"ascendingprojectteam"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"descendingprojectteam"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                            </th>
                          ) : null}
                          {userRole === "observer" ? (
                            // || userRole === "employee"
                            <th
                              scope="col"
                              className="top-0 order-1 flex gap-2 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                            >
                              {t("project")} {t("description")}
                              {this.state.projectSortOrder !==
                                  "ascendingprojectdesc" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"ascendingprojectdesc"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"descendingprojectdesc"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                            </th>
                          ) : null}
                          <th
                            scope="col"
                            className="top-0 order-1 flex gap-2 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                          >
                            {t("ProjectTasksTab.members")}
                            {this.state.projectSortOrder !==
                                  "ascendingprojectmembers" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"ascendingprojectmembers"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"descendingprojectmembers"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                          </th>
                          {userRole !== "observer" ? (
                            // && userRole !== "employee"
                            <th
                              scope="col"
                              className="top-0 order-1 flex gap-2 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                            >
                              {t("ProjectTasksTab.empBillRate")}
                              {this.state.projectSortOrder !==
                                  "ascendingprojectempbillrate" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"ascendingprojectempbillrate"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"descendingprojectempbillrate"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                            </th>
                          ) : null}
                          {userRole !== "observer" ? (
                            // && userRole !== "employee"
                            this.state.tableRowsData !== "" &&
                              this.state.tableRowsData["hours_per_month"] ? (
                              <th
                                scope="col"
                                className={`order-${1 +
                                  this.state.tableRowsArrangement[
                                  "hours_per_month"
                                  ]
                                } top-0 flex gap-2 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter`}
                              >
                                {t("ProjectTasksTab.hoursPerMonth")}
                                {this.state.projectSortOrder !==
                                  "ascendingprojecthourspermonth" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"ascendingprojecthourspermonth"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"descendingprojecthourspermonth"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                              </th>
                            ) : null
                          ) : null}
                          {userRole !== "observer" ? (
                            //  && userRole !== "employee"
                            this.state.tableRowsData !== "" &&
                              this.state.tableRowsData["hours_booked"] ? (
                              <th
                                scope="col"
                                className={`order-${1 +
                                  this.state.tableRowsArrangement[
                                  "hours_booked"
                                  ]
                                } top-0 flex gap-2 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter`}
                              >
                                {t("ProjectTasksTab.hoursBooked")}
                                {this.state.projectSortOrder !==
                                  "ascendingprojecthoursbooked" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"ascendingprojecthoursbooked"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"descendingprojecthoursbooked"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                              </th>
                            ) : null
                          ) : null}
                          {this.state.tableRowsData !== "" &&
                            this.state.tableRowsData["hours_remaining"] ? (
                            <th
                              scope="col"
                              className={`order-${1 +
                                this.state.tableRowsArrangement[
                                "hours_remaining"
                                ]
                              } top-0 flex gap-2 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter`}
                            >
                              {t("ProjectTasksTab.hoursRemaining")}
                              {this.state.projectSortOrder !==
                                  "ascendingprojecthoursremaining" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"ascendingprojecthoursremaining"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"descendingprojecthoursremaining"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                            </th>
                          ) : null}
                          {this.state.tableRowsData !== "" &&
                            this.state.tableRowsData["hours_rolled_over"] ? (
                            <th
                              scope="col"
                              className={`order-${1 +
                                this.state.tableRowsArrangement[
                                "hours_rolled_over"
                                ]
                              } top-0 flex gap-2 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter`}
                            >
                              {t("ProjectTasksTab.hoursRolledOver")}
                              {this.state.projectSortOrder !==
                                  "ascendingprojecthoursrollover" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"ascendingprojecthoursrollover"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          projectSortOrder:"descendingprojecthoursrollover"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                            </th>
                          ) : null}
                          {userRole !== "observer" &&
                            userRole !== "employee" ? (
                            <th
                              scope="col"
                              className="top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter order-8"
                            >
                              {t("action")}
                            </th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {filterDataProjectTab &&
                          filterDataProjectTab.map((el, i) => (
                            <tr
                              key={i}
                              className={`grid grid-cols-${this.state.numRows +
                                (userRole === "observer"
                                  ? //  ||
                                  // userRole === "employee"
                                  4
                                  : 5)
                                } min-w-full`}
                            >
                              <td
                                onClick={() => {
                                  this.handleProjectNavigation(
                                    el.project_id,
                                    el,
                                    el.project_name
                                  );
                                }}
                                className={classNames(
                                  i !== filterDataProjectTab.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  `whitespace-wrap py-4 pl-4 pr-3 text-sm font-medium cursor-pointer text-primary order-1 capitalize`
                                )}
                              // onClick={() => {
                              //     text-primary underline hover:cursor-pointer
                              //     this.setState({
                              //         selectedProject: el,
                              //     });
                              // }}
                              >
                                {el.project_name}
                              </td>
                              {userRole !== "observer" ? (
                                // &&
                                // userRole !== "employee"
                                <td
                                  className={classNames(
                                    i !== filterDataProjectTab.length - 1
                                      ? "border-b border-gray-200"
                                      : "",
                                    `whitespace-wrap px-3 py-4 text-sm text-gray-500 order-1`
                                  )}
                                >
                                  {el.team_name ? el.team_name : "default"}
                                </td>
                              ) : null}
                              {userRole === "observer" ? (
                                //  ||
                                // userRole === "employee"
                                <td
                                  className={classNames(
                                    i !== filterDataProjectTab.length - 1
                                      ? "border-b border-gray-200"
                                      : "",
                                    `whitespace-wrap px-3 py-4 text-sm text-gray-500 order-1`
                                  )}
                                >
                                  {el.project_description}
                                </td>
                              ) : null}
                              <td
                                className={classNames(
                                  i !== filterDataProjectTab.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  `whitespace-nowrap px-3 py-4 text-sm text-gray-500 order-1`
                                )}
                              >
                                <div className="flex">
                                  <UsersIcon className="mr-4 w-5"></UsersIcon>
                                  <span
                                    className="cursor-pointer text-primary"
                                    onClick={() =>
                                      this.GetUsersFromtheProject(el)
                                    }
                                  >
                                    {el.employee_count}
                                  </span>
                                </div>
                              </td>
                              {userRole !== "observer" ? (
                                // &&
                                // userRole !== "employee"
                                <td
                                  className={classNames(
                                    i !== filterDataProjectTab.length - 1
                                      ? "border-b border-gray-200"
                                      : "",
                                    `whitespace-nowrap px-3 py-4 text-sm text-gray-500 order-1`
                                  )}
                                >
                                  {el.currency === "usd" ? "$" : "₹"}
                                  {el.amount}
                                </td>
                              ) : null}
                              {userRole !== "observer" ? (
                                //  &&
                                // userRole !== "employee"
                                this.state.tableRowsData !== "" &&
                                  this.state.tableRowsData["hours_per_month"] ? (
                                  <td
                                    className={classNames(
                                      i !== filterDataProjectTab.length - 1
                                        ? "border-b border-gray-200"
                                        : "",
                                      `whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 order-${1 +
                                      this.state.tableRowsArrangement[
                                      "hours_per_month"
                                      ]
                                      }`
                                    )}
                                  >
                                    {el.hours_spent}
                                  </td>
                                ) : null
                              ) : null}
                              {userRole !== "observer" ? (
                                //  &&
                                // userRole !== "employee"
                                this.state.tableRowsData !== "" &&
                                  this.state.tableRowsData["hours_booked"] ? (
                                  <td
                                    className={classNames(
                                      i !== filterDataProjectTab.length - 1
                                        ? "border-b border-gray-200"
                                        : "",
                                      `whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 order-${1 +
                                      this.state.tableRowsArrangement[
                                      "hours_booked"
                                      ]
                                      }`
                                    )}
                                  >
                                    {el.data[0].hours}
                                  </td>
                                ) : null
                              ) : null}
                              {userRole !== "observer" ? (
                                //  &&
                                // userRole !== "employee"
                                this.state.tableRowsData !== "" &&
                                  this.state.tableRowsData["hours_remaining"] ? (
                                  <td
                                    className={classNames(
                                      i !== filterDataProjectTab.length - 1
                                        ? "border-b border-gray-200"
                                        : "",
                                      `whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 order-${1 +
                                      this.state.tableRowsArrangement[
                                      "hours_remaining"
                                      ]
                                      }`
                                    )}
                                  >
                                    {el.data[0]["hours_remaining"]}
                                  </td>
                                ) : null
                              ) : null}
                              {userRole !== "observer" ? (
                                //  &&
                                // userRole !== "employee"
                                this.state.tableRowsData !== "" &&
                                  this.state.tableRowsData[
                                  "hours_rolled_over"
                                  ] ? (
                                  <td
                                    className={classNames(
                                      i !== filterDataProjectTab.length - 1
                                        ? "border-b border-gray-200"
                                        : "",
                                      `whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 order-${1 +
                                      this.state.tableRowsArrangement[
                                      "hours_rolled_over"
                                      ]
                                      }`
                                    )}
                                  >
                                    {el.data[0]["hours_rolled"]}
                                  </td>
                                ) : null
                              ) : null}
                              {userRole !== "observer" ? (
                                // &&
                                // userRole !== "employee"
                                <td
                                  className={classNames(
                                    i !== filterDataProjectTab.length - 1
                                      ? "border-b border-gray-200"
                                      : "",
                                    "relative whitespace-nowrap py-4 pl-3 text-right text-sm font-medium order-8"
                                  )}
                                >
                                  <div className="flex">
                                    <div
                                      className={`${userRole === "observer"
                                        ? "text-primary opacity-70"
                                        : "text-primary"
                                        } hover:text-primary`}
                                    >
                                      <img
                                        src={union}
                                        alt=""
                                        className="mr-4 w-4 hover:cursor-pointer"
                                        onClick={() =>
                                          this.setState({
                                            xeroIntegrate: true,
                                            projectName: el.project_name,
                                            projectId: el.project_id,
                                            xeroConnected: el.xero_status
                                              ? true
                                              : false,
                                          })
                                        }
                                      />
                                    </div>
                                    <div
                                      className={`${userRole === "observer"
                                        ? "text-primary opacity-70"
                                        : "text-primary"
                                        } hover:text-primary`}
                                    >
                                      <PencilIcon
                                        className="mr-4 w-5  hover:cursor-pointer"
                                        onClick={() =>
                                          this.onUpdateProject(
                                            el.project_name,
                                            el.project_description,
                                            el.project_id,
                                            el.status_every,
                                            el.data,
                                            el.team_name,
                                            el.customFiled,
                                            el.amount,
                                            el.currency
                                          )
                                        }
                                      />
                                      <span className="sr-only">
                                        , {el.project_name}
                                      </span>
                                    </div>
                                    <div
                                      className={`${userRole === "observer"
                                        ? "text-danger opacity-70"
                                        : "text-danger"
                                        } hover:text-danger hover:cursor-pointer`}
                                    >
                                      <TrashIcon
                                        className="w-5"
                                        onClick={() =>
                                          this.handleDeleteProject(
                                            el.project_name
                                          )
                                        }
                                      />
                                      <span className="sr-only">
                                        , {el.project_name}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              ) : null}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {SelectedTab === "tasks" ? (
          <div className="bg-white mt-4 px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
            <div className="flex items-center justify-end flex-wrap sm:flex-nowrap">
              <div
                className={
                  userRole !== "observer" ? "w-full" : "min-w-max w-52"
                }
              >
                <SelectField
                  onSelectChange={(e) => this.onCreateTaskChange(e)}
                  FieldName={`${t("select")} ${t("team")}`}
                  name={"teamName"}
                  arrayOfData={TeamData}
                  id={"team"}
                ></SelectField>

              </div>

              {userRole !== "observer" ? (
                <SelectField
                  onSelectChange={(e) => this.onCreateTaskChange(e)}
                  FieldName={`${t("select")} ${t("project")}`}
                  name={"projectName"}
                  arrayOfData={ProjectData}
                  mainclassName={"ml-4"}
                  id={"project"}
                ></SelectField>
              ) : null}

              {userRole !== "observer" && userRole !== "employee" ? (
                <div className="ml-4 mt-4 flex items-center flex-shrink-0">
                  <div

                    className="flex mr-4 items-center hover:cursor-pointer text-primary"
                    disabled={userRole === "observer" ? true : false}
                    onClick={() => {
                      this.setState({
                        showSettingsTable: true,
                        selectedModalLayout: this.state.taskTableLayout,
                        selectedModalArrangement:
                          this.state.taskTableArrangement,
                        modalUpdateHandler: this.onTaskLayoutSubmitClickHandler,
                      });
                    }}
                  >
                    <BsGearFill className="mx-2" />
                    <p className="font-semibold">{t("settings")}</p>
                  </div>
                  <button
                    onClick={() => {
                      showObserverDataToggle();
                    }}
                    className="mx-2 border border-gray-300 rounded-[5px] p-[6px] px-4 text-gray-500 cursor-pointer hover:border-gray-400"
                  >
                    {this.state.showObserverData ? (
                      <span className="flex items-center">
                        <img alt="" src={hideObsPng} className="h-4" />
                        {t("hide")} {t("observer")}
                      </span>
                    ) : (
                      <span className="flex items-center">
                        <DocumentSearchIcon className="h-4" />
                        {t("show")} {t("observer")}
                      </span>
                    )}
                  </button>
                  <PrimaryButton
                    disabled={userRole === "observer" ? true : false}
                    onClick={() => this.handleTaskCreation()}
                    buttontext={`${t("create")} ${t("task")}`}
                  ></PrimaryButton>
                </div>
              ) : null}
            </div> 
            <div className="mt-[5px]">
              {
               localStorage.getItem("roleUser")==="employee" ?(
                <PrimaryButton
                      disabled={userRole === "observer" ? true : false}
                      onClick={() => this.handleTaskCreation()}
                      buttontext={`${t("create")} ${t("task")}`}  
                    ></PrimaryButton>
                  ):null
                 }
              </div>
              
            <div className="mt-4 flex flex-col bg-gray-100">
              <div className="inline-block min-w-full align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[63vh] overflow-auto max-w-full">
                  <table className="w-full">
                    <thead className="bg-gray-50 sticky top-0 z-10">
                      <tr
                        className={`grid grid-cols-${this.state.numTaskCols +
                          (userRole !== "observer" && userRole !== "employee"
                            ? 2
                            : 1)
                          } min-w-max w-full`}
                      >
                        <th
                          scope="col"
                          className="top-0 flex gap-2 order-1 min-w-max whitespace-nowrap rounded-tl-lg border border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                        >
                          {t("task")} {t("name")}
                          {this.state.taskSortOrder !==
                                  "ascendingtaskname" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          taskSortOrder:"ascendingtaskname"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          taskSortOrder:"descendingtaskname"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                        </th>
                        {this.state.taskTableLayout !== "" &&
                          this.state.taskTableLayout["team_name"] && (
                            <th
                              scope="col"
                              className={`order-${
                                1 + this.state.taskTableArrangement["team_name"]
                              } top-0 flex gap-2 border min-w-max whitespace-nowrap border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter`}
                            >
                              {t("team")} {t("name")}
                              {this.state.taskSortOrder !==
                                  "ascendingtaskteamname" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          taskSortOrder:"ascendingtaskteamname"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          taskSortOrder:"descendingtaskteamname"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                            </th>
                          )}
                        {this.state.taskTableLayout !== "" &&
                          this.state.taskTableLayout["project_name"] && (
                            <th
                              scope="col"
                              className={`order-${1 +
                                this.state.taskTableArrangement["project_name"]
                              } top-0 flex gap-2 border min-w-max whitespace-nowrap border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter`}
                            >
                              {t("project")} {t("name")}
                              {this.state.taskSortOrder !==
                                  "ascendingtaskprojectname" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          taskSortOrder:"ascendingtaskprojectname"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          taskSortOrder:"descendingtaskprojectname"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                            </th>
                          )}
                        {this.state.taskTableLayout !== "" &&
                          this.state.taskTableLayout["user_name"] && (
                            <th
                              scope="col"
                              className={`order-${
                                1 + this.state.taskTableArrangement["user_name"]
                              } top-0 flex gap-2 border min-w-max whitespace-nowrap border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter`}
                            >
                              {t("user")} {t("name")}
                              {this.state.taskSortOrder !==
                                  "ascendingtaskusername" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          taskSortOrder:"ascendingtaskusername"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          taskSortOrder:"descendingtaskusername"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                            </th>
                          )}
                        {this.state.taskTableLayout !== "" &&
                          this.state.taskTableLayout["start_date"] && (
                            <th
                              scope="col"
                              className={`order-${1 +
                                this.state.taskTableArrangement["start_date"]
                              } top-0 flex gap-2 border min-w-max whitespace-nowrap border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter`}
                            >
                              {t("start")} {t("date")}
                              {this.state.taskSortOrder !==
                                  "ascendingtaskstartdate" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          taskSortOrder:"ascendingtaskstartdate"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          taskSortOrder:"descendingtaskstartdate"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                            </th>
                          )}
                        {this.state.taskTableLayout !== "" &&
                          this.state.taskTableLayout["end_date"] && (
                            <th
                              scope="col"
                              className={`order-${
                                1 + this.state.taskTableArrangement["end_date"]
                              } top-0 flex gap-2 border min-w-max whitespace-nowrap border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter`}
                            >
                              {t("end")} {t("date")}
                              {this.state.taskSortOrder !==
                                  "ascendingtaskenddate" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          taskSortOrder:"ascendingtaskenddate"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          taskSortOrder:"descendingtaskenddate"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                            </th>
                          )}
                        {this.state.taskTableLayout !== "" &&
                          this.state.taskTableLayout["status_task"] && (
                            <th
                              scope="col"
                              className={`order-${1 +
                                this.state.taskTableArrangement["status_task"]
                              } top-0 flex gap-2 border min-w-max whitespace-nowrap border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter`}
                            >
                              {t("status")}
                              {this.state.projectSortOrder !==
                                  "ascendingtaskstatus" ? (
                                  <button
                                      title="Sort Ascending"
                                      onClick={()=>{
                                        this.setState({
                                          taskSortOrder:"ascendingtaskstatus"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              ) : (
                                  <button
                                      title="Sort Descending"
                                      onClick={()=>{
                                        this.setState({
                                          taskSortOrder:"descendingtaskstatus"
                                        });
                                      }}
                                      className={`flex flex-col ml-3`}>
                                      <IoMdArrowDropup className="my-0 text-xs" />
                                      <IoMdArrowDropdown className="my-0 text-xs" />
                                  </button>
                              )}
                            </th>
                          )}
                        {this.state.taskTableLayout !== "" &&
                          this.state.taskTableLayout["status_task"] && (
                            <th
                              scope="col"
                              className={`order-${1 +
                                this.state.taskTableArrangement["status_task"]
                                } top-0 border min-w-max whitespace-nowrap border-gray-300 bg-gray-50 ${userRole === "observer" ||
                                  userRole === "employee"
                                  ? "rounded-tr-lg"
                                  : ""
                              } top-0 sticky bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter capitalize`}
                            >
                              {t("settingsTab.custom")}{" "}
                              {t("ProjectTasksTab.fields")}
                            </th>
                          )}
                        {userRole !== "observer" && userRole !== "employee" ? (
                          <th
                            scope="col"
                            className="top-0 order-9 border rounded-tr-lg border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 text-left backdrop-blur backdrop-filter"
                          >
                            {t("action")}
                          </th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {filterData &&
                        filterData.map((el, i) => (
                          <tr
                            key={i}
                            className={`grid grid-cols-${this.state.numTaskCols +
                              (userRole !== "observer" &&
                                userRole !== "employee"
                                ? 2
                                : 1)
                              } min-w-full`}
                          >
                            <td
                              className={classNames(
                                i === activetasks.length - 1
                                  ? "border-b border-gray-200 rounded-bl-lg"
                                  : "",
                                "whitespace-wrap border-b border-x border-gray-200 order-1 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 cursor-pointer"
                              )}
                              onClick={() => {
                                this.setState({
                                  showTaskDetailedView: true,
                                  SelectedTab: "",
                                  selectedTaskData: el,
                                });
                                window.history.pushState(
                                  {},
                                  "",
                                  "tasks/taskname=" + el.name
                                );
                                this.handleDetailedTaskInfo(el.name, el.id);
                              }}
                            >
                              {this.state.showObserverData ? (
                                <span className="flex">
                                  <DocumentSearchIcon className="h-4 translate-y-[1px] text-primary mr-1" />{" "}
                                  {el.name}
                                </span>
                              ) : (
                                <span className="text-primary">{el.name}</span>
                              )}
                            </td>
                            {this.state.taskTableLayout !== "" &&
                              this.state.taskTableLayout["team_name"] && (
                                <td
                                  className={classNames(
                                    i === activetasks.length - 1
                                      ? "border-b border-gray-200"
                                      : "",
                                    `order-${1 +
                                    this.state.taskTableArrangement[
                                    "team_name"
                                    ]
                                    } whitespace-wrap border-b border-r border-gray-200 px-3 py-4 text-sm text-gray-500`
                                  )}
                                >
                                  {/* {el.team ? el.team : "default"} */}
                                  {el.team ? el.team : "default"}
                                </td>
                              )}
                            {this.state.taskTableLayout !== "" &&
                              this.state.taskTableLayout["project_name"] && (
                                <td
                                  className={classNames(
                                    i === activetasks.length - 1
                                      ? "border-b border-gray-200"
                                      : "",
                                    `order-${1 +
                                    this.state.taskTableArrangement[
                                    "project_name"
                                    ]
                                    } whitespace-wrap border-b border-r border-gray-200 px-3 py-4 text-sm text-gray-500`
                                  )}
                                >
                                  {el.project ? el.project : "default"}
                                </td>
                              )}
                            {this.state.taskTableLayout !== "" &&
                              this.state.taskTableLayout["user_name"] && (
                                <td
                                  className={classNames(
                                    i === activetasks.length - 1
                                      ? "border-b border-gray-200"
                                      : "",
                                    `order-${1 +
                                    this.state.taskTableArrangement[
                                    "user_name"
                                    ]
                                    } whitespace-wrap border-b border-r border-gray-200 px-3 py-4 text-sm text-gray-500`
                                  )}
                                >
                                  {el.username}
                                </td>
                              )}
                            {this.state.taskTableLayout !== "" &&
                              this.state.taskTableLayout["start_date"] && (
                                <td
                                  className={classNames(
                                    i === activetasks.length - 1
                                      ? "border-b border-gray-200"
                                      : "",
                                    `order-${1 +
                                    this.state.taskTableArrangement[
                                    "start_date"
                                    ]
                                    } whitespace-nowrap border-b border-r border-gray-200 px-3 py-4 text-sm text-gray-500`
                                  )}
                                >
                                  {el.start_date}
                                </td>
                              )}
                            {this.state.taskTableLayout !== "" &&
                              this.state.taskTableLayout["end_date"] && (
                                <td
                                  className={classNames(
                                    i === activetasks.length - 1
                                      ? "border-b border-gray-200"
                                      : "",
                                    `order-${1 +
                                    this.state.taskTableArrangement[
                                    "end_date"
                                    ]
                                    } whitespace-nowrap border-b border-r border-gray-200 px-3 py-4 text-sm text-gray-500`
                                  )}
                                >
                                  {el.end_date}
                                </td>
                              )}
                            {this.state.taskTableLayout !== "" &&
                              this.state.taskTableLayout["status_task"] && (
                                <td
                                  className={classNames(
                                    i === activetasks.length - 1
                                      ? "border-b border-gray-200"
                                      : "",
                                    `order-${1 +
                                    this.state.taskTableArrangement[
                                    "status_task"
                                    ]
                                    } whitespace-nowrap border-b border-r border-gray-200 px-3 py-4 text-sm text-gray-500`
                                  )}
                                >
                                  {el.task_status}
                                </td>
                              )}
                            {this.state.taskTableLayout !== "" &&
                              this.state.taskTableLayout["status_task"] && (
                                <td
                                  className={classNames(
                                    i === activetasks.length - 1
                                      ? "border-b border-gray-200"
                                      : "",
                                    `order-${1 +
                                    this.state.taskTableArrangement[
                                    "status_task"
                                    ]
                                    } whitespace-wrap border-b border-r border-gray-200 ${userRole === "employee" ||
                                      userRole === "observer"
                                      ? "rounded-br-lg"
                                      : ""
                                    } px-3 py-4 text-sm text-gray-500`
                                  )}
                                >
                                  {el.customField
                                    ? Object.keys(el.customField).length > 0 &&
                                    JSON.stringify(el.customField)
                                    : "None"}
                                </td>
                              )}
                            {userRole !== "observer" &&
                              userRole !== "employee" ? (
                              <td
                                className={classNames(
                                  i === activetasks.length - 1
                                    ? "border-b border-gray-200 rounded-br-lg"
                                    : "",
                                  "relative order-9 whitespace-nowrap border-b border-r border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium"
                                )}
                              >
                                <div className="flex">
                                  {userRole !== "Employee" && (
                                    <p
                                      className={`${userRole === "observer"
                                        ? "text-primary opacity-70"
                                        : "text-primary"
                                        } hover:text-primary`}
                                    >
                                      <PencilIcon
                                        className="mr-4 w-5 cursor-pointer"
                                        onClick={() =>
                                          this.handleUpdateTaskCreation(
                                            el,
                                            EmployeeData
                                          )
                                        }
                                      />
                                      <span className="sr-only">
                                        , {el.name}
                                      </span>
                                    </p>
                                  )}
                                  <p
                                    className={`${userRole === "observer"
                                      ? "text-danger opacity-70"
                                      : "text-danger"
                                      } hover:text-danger`}
                                  >
                                    <TrashIcon
                                      className="w-5 cursor-pointer"
                                      onClick={() =>
                                        this.handleDeleteTask(el.id)
                                      }
                                    />
                                    <span className="sr-only">, {el.name}</span>
                                  </p>
                                </div>
                              </td>
                            ) : null}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {SelectedTab === "TeamMembers" ? (
          <div className="bg-white mt-4 px-2 py-1 border-b border-gray-200 sm:px-6 rounded-lg">
            <div className="-ml-4 flex items-center justify-between flex-wrap sm:flex-nowrap">
              <div className="ml-4 mt-2 font-lg">
                {" "}
                {t("team")} {t("name")}:{" "}
                <span className="font-bold"> {SelectedProjectName} </span>{" "}
              </div>
              {userRole !== "employee" ? (
                <div className="ml-4 mt-2 flex-shrink-0">
                  <PrimaryButton
                    disabled={userRole === "observer" ? true : false}
                    onClick={() => this.AddUsertotheProject()}
                    buttontext={`${t("add")} ${t("user")}`}
                  ></PrimaryButton>
                </div>
              ) : null}
            </div>
            <div className="mt-4 flex flex-col overflow-y-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                  <table className="min-w-full border-separate">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className=" top-0 z-10 border-y border-x border-gray-300 rounded-tl-lg bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12"
                        >
                          {" "}
                          {t("ProjectTasksTab.members")} {t("name")}{" "}
                        </th>
                        <th
                          scope="col"
                          className={`top-0 z-10 hidden border-y border-r border-gray-300 ${userRole === "employee" ? "rounded-tr-lg" : ""
                            } bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell`}
                        >
                          {" "}
                          Role{" "}
                        </th>
                        {userRole !== "employee" ? (
                          <th
                            scope="col"
                            className=" top-0 z-10 border-b border-t border-r rounded-tr-lg border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-12"
                          >
                            Edit
                          </th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {membersoftheProject &&
                        membersoftheProject.map((el, i) => (
                          <tr key={i}>
                            <td
                              className={classNames(
                                i === membersoftheProject.length - 1
                                  ? "border-b border-gray-200 rounded-bl-lg"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 border-l border-b border-gray-200 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                              )}
                            >
                              {el.username}
                            </td>
                            <td
                              className={classNames(
                                i === membersoftheProject.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                `whitespace-nowrap px-3 py-4 border-x border-b ${userRole === "employee" &&
                                  i === membersoftheProject.length - 1
                                  ? "rounded-br-lg"
                                  : ""
                                } border-gray-200 text-sm text-gray-500 hidden sm:table-cell`
                              )}
                            >
                              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                <FormControl component="fieldset">
                                  <RadioGroup
                                    row
                                    aria-label="position"
                                    name="position"
                                    value={
                                      el.is_superuser ? "Manager" : "member"
                                    }
                                  >
                                    <FormControlLabel
                                      value="member"
                                      control={
                                        <Radio
                                          style={{
                                            color: "#684D94",
                                          }}
                                        />
                                      }
                                      label="Regular"
                                    />
                                    <FormControlLabel
                                      value="Manager"
                                      control={
                                        <Radio
                                          style={{
                                            color: "#684D94",
                                          }}
                                        />
                                      }
                                      label="Manager"
                                    />
                                    <FormControlLabel
                                      value="Admin"
                                      control={
                                        <Radio
                                          style={{
                                            color: "#684D94",
                                          }}
                                        />
                                      }
                                      label="Admin"
                                    />
                                    <FormControlLabel
                                      value="Observer"
                                      control={
                                        <Radio
                                          style={{
                                            color: "#684D94",
                                          }}
                                        />
                                      }
                                      label="Observer"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            </td>
                            {userRole !== "employee" ? (
                              <td
                                className={classNames(
                                  i === membersoftheProject.length - 1
                                    ? "border-b border-gray-200 rounded-br-lg"
                                    : "",
                                  "relative whitespace-nowrap border-b border-r border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-12"
                                )}
                              >
                                <div className="flex justify-center items-center">
                                  <div
                                    className={`${userRole === "observer"
                                      ? "text-danger opacity-70"
                                      : "text-danger"
                                      } hover:text-danger hover:cursor-pointer`}
                                  >
                                    <TrashIcon
                                      className="w-5"
                                      onClick={() =>
                                        this.DeleteUserFromProject(el.username)
                                      }
                                    />
                                    <span className="sr-only">
                                      , {el.username}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            ) : null}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.showTaskDetailedView ? (
          <div>
            <Link
              to="/tasks"
              className="bg-white border border-primary h-8 w-8 rounded-full mt-5 flex justify-center items-center"
              onClick={() => {
                this.setState({
                  showTaskDetailedView: false,
                  SelectedTab: "tasks",
                });
              }}
            >
              <IoIosArrowBack className="text-gray-500" />
            </Link>
            <div className="bg-white shadow-md rounded-md p-6 mt-6">
              <p className="font-medium text-lg xl:text-xl 2xl:text-2xl">
                {this.state.selectedTaskData.name}
              </p>
              <div className="bg-white mt-4 border-b border-gray-200 rounded-lg">
                <div
                  className="mt-2 flex flex-col overflow-y-auto bg-gray-100 rounded-xl"
                  style={{ maxHeight: "auto" }}
                >
                  <div className="inline-block min-w-full align-middle">
                    <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                      <table
                        className="min-w-full border-separate"
                        style={{ borderSpacing: 0 }}
                      >
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12"
                            >
                              {t("projects")}
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                            >
                              {t("teams")}
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                            >
                              {t("start")} {t("date")}
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                            >
                              {t("end")} {t("date")}
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                            >
                              {t("status")}
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                            >
                              PRIORITY
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          <tr>
                            <td
                              className={classNames(
                                "border-b border-gray-200",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                              )}
                            >
                              {this.state.selectedTaskData.project}
                            </td>
                            <td
                              className={classNames(
                                "border-b border-gray-200",
                                "whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
                              )}
                            >
                              {this.state.selectedTaskData.team}
                            </td>
                            <td
                              className={classNames(
                                "border-b border-gray-200",
                                "whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"
                              )}
                            >
                              {this.state.selectedTaskData.start_date}
                            </td>
                            <td
                              className={classNames(
                                "border-b border-gray-200",
                                "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                              )}
                            >
                              {this.state.selectedTaskData.end_date}
                            </td>
                            <td
                              className={classNames(
                                "border-b border-gray-200",
                                "whitespace-nowrap px-3 py-4 text-sm " +
                                (this.state.selectedTaskData?.task_status ===
                                  "Incomplete"
                                  ? "text-danger"
                                  : this.state.selectedTaskData
                                    ?.task_status === "not yet started"
                                    ? "text-[#92400E]"
                                    : "text-[#047857]")
                              )}
                            >
                              {this.state.selectedTaskData.task_status}
                            </td>
                            <td
                              className={classNames(
                                "border-b border-gray-200",
                                "whitespace-nowrap px-1 py-2 text-sm "
                              )}
                            >
                              <div
                                className={
                                  "w-fit px-4 py-0.5 rounded-full " +
                                  (this.state.selectedTaskData.priority ===
                                    "High"
                                    ? "text-danger bg-[#FEE2E2]"
                                    : this.state.selectedTaskData.priority ===
                                      "Medium"
                                      ? "text-[#92400E] bg-[#FEF3C7]"
                                      : "text-gray-600 bg-gray-300")
                                }
                              >
                                {this.state.selectedTaskData.priority}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.selectedTaskData?.note && (
                <div className="pt-4">
                  <div className="text-[#6B7280] text-tiny font-medium pb-1.5">
                    Task Description
                  </div>
                  <p className="font-medium text-[#1D1D1D]">
                    {this.state.selectedTaskData?.note}
                  </p>
                </div>
              )}
              {/* this is for bar chart */}
              {/* <div className="flex mt-6 items-center">
                <div className="w-full mx-auto">
                  <div className="grid gap-4 sm:grid-cols-1 lg:grid-cols-2">
                    <div className="p-5 bg-white rounded shadow-md">
                      <div className="text-lg text-[#1D1D1D] font-Lato leading-normal font-medium mb-5 ml-3">
                        Hours Spend
                      </div>
                      <ReactHighcharts
                        config={productivitychart}
                      ></ReactHighcharts>
                    </div>

                    <div className="p-5 bg-white rounded shadow-md">
                      <div className="text-lg text-[#1D1D1D] font-Lato leading-normal font-medium mb-5 ml-3">
                        Activity Index
                      </div>
                      <ReactHighcharts config={indexchart}></ReactHighcharts>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="bg-white p-5 shadow-md rounded-md mt-6">
              <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="tabs"
                  name="tabs"
                  onChange={(e) => this.handleMobileTaskDetailTab(e)}
                  className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                  defaultValue={this.state.taksDetail_selectedTab}
                >
                  <option key="tasknote" value="tasknote">
                    Task Notes
                  </option>
                  <option key="activitylog" value="activitylog">
                    Activity Log
                  </option>
                </select>
              </div>
              <div className="hidden sm:flex justify-between items-center w-full">
                <div>
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    <div
                      key="Teams"
                      onClick={() => this.handleTaskSelectDetailTab("tasknote")}
                      className={classNames(
                        this.state.taksDetail_selectedTab === "tasknote"
                          ? "border-primary text-primary"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                        "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                      )}
                      aria-current={
                        this.state.taksDetail_selectedTab === "tasknote"
                          ? "page"
                          : undefined
                      }
                    >
                      <BriefcaseIcon
                        className={classNames(
                          this.state.taksDetail_selectedTab === "tasknote"
                            ? "text-primary"
                            : "text-gray-400 group-hover:text-gray-500",
                          "-ml-0.5 mr-2 h-5 w-5"
                        )}
                        aria-hidden="true"
                      />
                      <span>Task Note</span>
                    </div>

                    <div
                      key="Users"
                      onClick={() =>
                        this.handleTaskSelectDetailTab("activitylog")
                      }
                      className={classNames(
                        this.state.taksDetail_selectedTab === "activitylog"
                          ? "border-primary text-primary"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                        "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                      )}
                      aria-current={
                        this.state.taksDetail_selectedTab === "activitylog"
                          ? "page"
                          : undefined
                      }
                    >
                      <BriefcaseIcon
                        className={classNames(
                          this.state.taksDetail_selectedTab === "activitylog"
                            ? "text-primary"
                            : "text-gray-400 group-hover:text-gray-500",
                          "-ml-0.5 mr-2 h-5 w-5"
                        )}
                        aria-hidden="true"
                      />
                      <span>Activity Log</span>
                    </div>
                  </nav>
                </div>
                <div className="flex items-center gap-3 text-tiny">
                  <button
                    onClick={() => { }}
                    className="font-semibold hidden text-primary"
                  >
                    Request
                  </button>
                  <button
                    className="cursor-pointer rounded font-medium bg-primary text-white px-3 py-2"
                    onClick={() => {
                      this.setState({
                        showAddtaskModal: true,
                      });
                    }}
                  >
                    + Add Note
                  </button>
                </div>
              </div>
              {this.state.taksDetail_selectedTab === "tasknote" ? (
                <div>
                  <div className="mt-5">
                    {this.state.taskNotes?.length > 0 ? (
                      <div className="px-4 w-full mt-6 flex flex-col gap-6">
                        {this.state.taskNotes?.map((note, idx) => (
                          <div key={"tasknote" + idx}>
                            <div className="flex justify-between items-center">
                              <div className="flex gap-4 items-center">
                                <p className="h-10 xl:h-12 w-10 xl:w-12 rounded-full">
                                  {note?.pic ? (
                                    <img
                                      className="min-w-full min-h-full w-full h-full rounded-full"
                                      src={note.pic}
                                      alt="profilephotonote"
                                    />
                                  ) : (
                                    <div className="border-2 h-full w-full uppercase rounded-full text-xl border-gray-400 bg-gray-200 text-gray-500 text-center flex items-center justify-center">
                                      {note.user_name
                                        .split(" ")
                                        .map((word) => word.charAt(0))}
                                    </div>
                                  )}
                                </p>
                                <p>{note.user_name}</p>
                              </div>
                              <p className="text-[#6B7280] text-tiny">
                                {moment(note.time ?? new Date())
                                  .format("DD MMM")
                                  .toLowerCase()}
                              </p>
                            </div>
                            <div className="px-10 xl:px-12">
                              <p className="text-[#1D1D1D] py-1 lg:text-lg">
                                {note.note}
                              </p>
                              {note?.images?.length > 0 && (
                                <p className="text-[#6B7280] text-tiny pb-1">
                                  {note?.images?.length + " files"}
                                </p>
                              )}
                              {note?.images?.length > 0 && (
                                <div className="flex gap-4">
                                  {note.images.map((noteAttachmment, idx) => (
                                    <img
                                      key={"noteAttachment" + idx}
                                      className="w-64 h-32 object-contain"
                                      src={noteAttachmment}
                                      alt="attachment"
                                    />
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="px-10 w-full text-center py-4 italic mt-6">
                        No note data found
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {this.state.taksDetail_selectedTab === "activitylog" ? (
                <div className="mt-4">
                  <div className="bg-white mt-4 border-gray-200 rounded-lg">
                    <div
                      className="mt-2 flex flex-col overflow-y-auto bg-gray-100 rounded-xl"
                      style={{ maxHeight: "auto" }}
                    >
                      <div className="inline-block min-w-full align-middle">
                        <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[640px] overflow-y-auto">
                          <table
                            className="min-w-full border-separate"
                            style={{
                              borderSpacing: 0,
                            }}
                          >
                            <thead className="bg-gray-50 sticky top-0 z-10">
                              <tr>
                                <th
                                  scope="col"
                                  className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12"
                                >
                                  DATE
                                </th>
                                <th
                                  scope="col"
                                  className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                >
                                  ASSIGNED EMPLOYEE
                                </th>
                                <th
                                  scope="col"
                                  className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                >
                                  START TIME
                                </th>
                                <th
                                  scope="col"
                                  className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                >
                                  END TIME
                                </th>
                                <th
                                  scope="col"
                                  className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                                >
                                  HOURS SPEND
                                </th>
                                <th
                                  scope="col"
                                  className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                >
                                  STATUS
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              {this.state.detailedTaskData?.data?.length > 0 ? (
                                this.state.detailedTaskData.data.map(
                                  (item, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td
                                          className={classNames(
                                            "border-b border-gray-200",
                                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                          )}
                                        >
                                          {item.date}
                                        </td>
                                        <td
                                          className={classNames(
                                            "border-b border-gray-200",
                                            "whitespace-nowrap px-3 py-2 text-sm text-gray-500 sm:table-cell"
                                          )}
                                        >
                                          {item.user_name}
                                        </td>
                                        <td
                                          className={classNames(
                                            "border-b border-gray-200",
                                            "whitespace-nowrap px-3 py-2 text-sm text-gray-500 sm:table-cell"
                                          )}
                                        >
                                          {item.start_time}
                                        </td>
                                        <td
                                          className={classNames(
                                            "border-b border-gray-200",
                                            "whitespace-nowrap px-3 py-2 text-sm text-gray-500 sm:table-cell"
                                          )}
                                        >
                                          {item.end_time}
                                        </td>
                                        <td
                                          className={classNames(
                                            "border-b border-gray-200",
                                            "whitespace-nowrap px-3 py-2 text-sm text-gray-500 sm:table-cell"
                                          )}
                                        >
                                          {convertMintoHr(item.duration)}
                                        </td>
                                        <td
                                          className={classNames(
                                            "border-b border-gray-200",
                                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
                                          )}
                                        >
                                          <span
                                            className={`px-2.5 py-[2px] text-center rounded-[10px] ${item.duration === 0
                                              ? "text-red-800 bg-red-100"
                                              : "text-green-800 bg-green-100"
                                              }`}
                                          >
                                            {item.duration === 0
                                              ? "Not Logged"
                                              : "Logged"}
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )
                              ) : (
                                <tr className="w-full">
                                  <td
                                    colSpan="4"
                                    className="text-center p-4 italic text-gray-500"
                                  >
                                    No data found.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        <LayoutSetupModal
          open={this.state.showSettingsTable}
          onCancelClick={() => {
            this.setState({ showSettingsTable: false });
          }}
          modalArrangement={this.state.selectedModalArrangement}
          modalData={this.state.selectedModalLayout}
          onSubmitClick={this.state.modalUpdateHandler}
          modalHeading={`${t("projects")} ${t("and")} ${t("tasks")} ${t(
            "settings"
          )}`}
        />
        <ConfirmationDialoag
          open={this.state.deleteProjectConfirmationModal}
          onCancelClick={() => this.onModalClose()}
          onSubmitClick={() => this.onHandleDeleteProjectClick()}
          info={"Are you sure you want to Delete ?"}
          submitButtonName={"Delete"}
          cancelButtonName={"Cancel"}
        ></ConfirmationDialoag>

        <ConfirmationDialoag
          open={this.state.deleteTaskConfirmationModal}
          onCancelClick={() => this.onModalClose()}
          onSubmitClick={() => this.onHandleDeleteTaskClick()}
          info={"Are you sure you want to Delete ?"}
          submitButtonName={"Delete"}
          cancelButtonName={"Cancel"}
        ></ConfirmationDialoag>

        <ConfirmationDialoag
          open={this.state.deleteUserFromProjectConfirmationModal}
          onCancelClick={() => this.onModalClose()}
          onSubmitClick={() => this.onHandleDeleteUserFromProjectClick()}
          info={"Are you sure you want to Delete ?"}
          submitButtonName={"Delete"}
          cancelButtonName={"Cancel"}
        ></ConfirmationDialoag>
        <XeroIntegrationToProjectModal
          open={this.state.xeroIntegrate}
          onCancelClick={() => this.setState({ xeroIntegrate: false })}
          projectname={this.state.projectName}
          projectId={this.state.projectId}
          onSubmitClick={() => {
            this.setState({ xeroIntegrate: false });
          }}
          connected={this.state.xeroConnected}
        />
        <CreateProjectModal
          open={this.state.projectcreationModal}
          onCancelClick={() => this.onModalClose()}
          handlenameChange={(e) => this.onCreateProjectNameChange(e)}
          onSubmitClick={(func, custom) =>
            this.onModalCreateProjetSubmit(func, custom)
          }
          handledescriptionhandlechange={(e) =>
            this.onCreateProjectDescriptionChange(e)
          }
          selectedusers={this.state.selectedUsers}
          projectname={this.state.projectName}
          projectdescription={this.state.projectDescription}
          useroptions={listofallemployees}
          selectusershandlechange={this.handleChangeuserName}
          selectedTeam={this.state.selectedTeam}
          teamOptions={listOfTeams}
          selectteamhandlechange={this.handleChangeTeamName}
          submitButtonName={"Submit"}
          cancelButtonName={"Cancel"}
          postHours={this.props.postProjectHrsBooked}
          refreshList={this.props.fetchactiveTasks}

        ></CreateProjectModal>

        <UpdateProjectModal
          open={this.state.projectupdationModal}
          onCancelClick={() => this.onModalClose()}
          handlenameChange={(e) => this.onCreateProjectNameChange(e)}
          onSubmitClick={(custom) => this.onModalUpdateProjetSubmit(custom)}
          handledescriptionhandlechange={(e) =>
            this.onCreateProjectDescriptionChange(e)
          }
          editMode={true}
          customFields={this.state.customFields}
          projectname={this.state.projectName}
          projectId={this.state.projectId}
          projectdescription={this.state.projectDescription}
          selectedTeam={this.state.selectedTeam}
          teamOptions={listOfTeams}
          empBillRate={this.state.selectedBillRate}
          currency={this.state.selectedCurrency}
          selectteamhandlechange={this.handleChangeTeamName}
          submitButtonName={"Submit"}
          hoursData={this.state.hoursData}
          everyMonthSame={this.state.status_every}
          postHours={this.props.postProjectHrsBooked}
          updateHours={this.props.updateProjectHrsBooked}
          cancelButtonName={"Cancel"}

        ></UpdateProjectModal>

        <CreateTaskModal
          open={this.state.taskcreationModal}
          onCancelClick={() => this.onModalClose()}
          onTaskFieldChange={(e) => this.onCreateTaskChange(e)}
          onSubmitClick={() => this.onModalCreateTaskSubmit()}
          onEmployeeHandleChange={(e) => this.onCreateTaskChange(e)}
          onProjectHandleChange={(e) => this.onCreateTaskChange(e)}
          onTeamHandleChange={(e) => this.onCreateTaskChange(e)}
          employeeData={EmployeeData}
          getUserBasedProjects={this.props.getUserBasedProjects}
          getUserBasedTeams={this.props.getUserBasedTeams}
          submitButtonName={"Submit"}
          cancelButtonName={"Cancel"}
          ProjectDataId={ProjectDataId}
          statusArray={listOfStatus}
          priorityArray={listOfPriority}
          selectedusers={this.state.selectedUsers}
          useroptions={listofallemployees}
          selectusershandlechange={this.handleChangeuserName}
          refreshList={this.props.fetchactiveTasks}
          role={userRole}
          prjtdata={ProjectData}
          teamdata={TeamData}
        ></CreateTaskModal>

        <AddUserToProjectModal
          open={this.state.addUsertotheProjectModal}
          onCancelClick={() => this.onModalClose()}
          onSubmitClick={() => this.HandleUserAddtotheProject()}
          onEmployeeHandleChange={(e) => this.handleUserChangetotheProject(e)}
          employeeData={EmployeeData}
          submitButtonName={"Submit"}
          cancelButtonName={"Cancel"}
          members={membersoftheProject ? membersoftheProject : [{ username: "hello", id: ["jeo", "world"] }]}
        ></AddUserToProjectModal>

        <CustomDateModal
          open={this.state.openCustomDateModal}
          onCancelClick={() => this.onModalClose()}
          onSubmitClick={() => {
            this.setState({ openCustomDateModal: false });
          }}
          submitButtonName={"Save"}
        ></CustomDateModal>

        <UpdateTaskModal
          open={this.state.taskupdationModal}
          onCancelClick={() => this.onModalClose()}
          onTaskFieldChange={(e) => this.onCreateTaskChange(e)}
          onSubmitClick={() => this.onModalUpdateTaskSubmit()}
          onEmployeeHandleChange={(e) => this.onCreateTaskChange(e)}
          onProjectHandleChange={(e) => this.onCreateTaskChange(e)}
          onTeamHandleChange={(e) => this.onCreateTaskChange(e)}
          employeeData={EmployeeData}
          getUserBasedProjects={this.props.getUserBasedProjects}
          getUserBasedTeams={this.props.getUserBasedTeams}
          editMode={true}
          ProjectDataId={ProjectDataId}
          selectedTaskData={this.state.selectedTaskData}
          statusArray={listOfStatus}
          priorityArray={listOfPriority}
          selectedusers={this.state.selectedUsers}
          submitButtonName={"Update"}
          cancelButtonName={"Cancel"}
          refreshList={this.props.fetchactiveTasks}
        ></UpdateTaskModal>

        {this.state.loading ? (
          <div className="text-center h-64 w-full flex justify-center items-center">
            <FadeLoader
              color={"#684994"}
              loading={this.state.loading}
              height={30}
              width={12}
              radius={2}
              margin={25}
            />
          </div>
        ) : null}

        {this.state.showAddtaskModal ? (
          <div>
            <Transition.Root show={this.state.showAddtaskModal} as={Fragment}>
              <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                onClose={() => {
                  this.setState({ showAddtaskModal: false });
                }}
              >
                <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
                      <div className="p-6">
                        <p className="font-semibold text-lg">Add Note</p>
                        <div className="mt-4">
                          <textarea
                            onChange={(ev) =>
                              this.setState({
                                noteVal: ev.target.value,
                              })
                            }
                            rows={4}
                            value={this.state.noteVal}
                            placeholder="write a new note here..."
                            className="w-full resize-none"
                          />
                          <input
                            type="file"
                            onChange={(ev) =>
                              this.setState({
                                noteFiles: Object.values(ev.target.files),
                              })
                            }
                            accept="image/png,image/jpg,image/jpeg"
                            multiple
                          />
                        </div>
                        <div className="mt-4 w-full flex justify-end">
                          <PrimaryButton
                            buttontext="POST"
                            onClick={() => {
                              if (!this.state.noteVal) {
                                toast.error("Note text required");
                                return;
                              }
                              const data = new FormData();
                              data.append(
                                "type",
                                this.state.noteFiles.length > 0
                                  ? "image"
                                  : "text"
                              );
                              data.append(
                                "task_id",
                                this.state.selectedTaskData.id
                              );
                              if (this.state.noteFiles.length > 0) {
                                for (let val of this.state.noteFiles) {
                                  data.append("files", val);
                                }
                              }
                              data.append("note", this.state.noteVal);
                              this.props.addNote(data).then((res) => {
                                if (res.value.status === 200) {
                                  this.props
                                    .getNotes(this.state.selectedTaskData.id)
                                    .then((res) => {
                                      if (res.value.status === 200) {
                                        let arr = res.value.data;
                                        arr.reverse();
                                        this.setState({
                                          taskNotes: arr,
                                        });
                                      }
                                      this.setState({
                                        noteVal: "",
                                        noteFiles: [],
                                        showAddtaskModal: false,
                                      });
                                    });
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
          </div>
        ) : null}
      </Layout>
    );
  }
}
const mapStateToProps = (state) => ({
  projects: state.projects,
  teams: state.teams,
  localStorageState: state.globalLocalStorageReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEmpLoginDetail: () => dispatch(fetchEmpLoginDetail()),
  fetchactiveprojects: () => dispatch(fetchactiveprojects()),
  fetchactiveTasks: () => dispatch(fetchactiveTasks()),
  fetchallEmployees: () => dispatch(fetchallEmployees()),
  FetchactiveTeams: (org_uuid) => dispatch(FetchactiveTeams(org_uuid)),
  getUsersfromtheProject: (name) => dispatch(getUsersfromtheProject(name)),
  DeleteProject: (name) => dispatch(DeleteProject(name)),
  DeleteTask: (id) => dispatch(DeleteTask(id)),
  DeleteUserFromProject: (data) => dispatch(DeleteUserFromProject(data)),
  createTask: (data) => dispatch(createTask(data)),
  updateTask: (data) => dispatch(updateTask(data)),
  createProject: (data) => dispatch(createProject(data)),
  updateProject: (data) => dispatch(updateProject(data)),
  AdduserToTheProject: (data) => dispatch(AdduserToTheProject(data)),
  taskLsitObserver: () => dispatch(taskLsitObserver()),
  deatiledTaskInfo: (taskname) => dispatch(deatiledTaskInfo(taskname)),
  storeToLocalStorage: () => dispatch(storeToLocalStorage()),
  fetchActiveTaskOserver: () => dispatch(fetchActiveTaskOserver()),
  getProjectDetails: () => dispatch(getProjectDetails()),
  updateProjectHrsBooked: (data) => dispatch(updateProjectHrsBooked(data)),
  postProjectHrsBooked: (data) => dispatch(postProjectHrsBooked(data)),
  getProjectTableLayout: () => dispatch(getProjectTableLayout()),
  updateProjectTableLayout: (data) => dispatch(updateProjectTableLayout(data)),
  postProjectTableLayout: (data) => dispatch(postProjectTableLayout(data)),
  getProjectTableArrangement: () => dispatch(getProjectTableArrangement()),
  updateProjectTableArrangement: (data) =>
    dispatch(updateProjectTableArrangement(data)),
  postProjectTableArrangement: (data) =>
    dispatch(postProjectTableArrangement(data)),
  getUserBasedProjects: (userId) => dispatch(getUserBasedProjects(userId)),
  getUserBasedTeams: (userId) => dispatch(getUserBasedTeams(userId)),
  getTaskTableArrangement: () => dispatch(getTaskTableArrangement()),
  getTaskTableLayout: () => dispatch(getTaskTableLayout()),
  updateTaskTableArrangement: (data) =>
    dispatch(updateTaskTableArrangement(data)),
  updateTaskTableLayout: (data) => dispatch(updateTaskTableLayout(data)),
  postTaskTableArrangement: (data) => dispatch(postTaskTableArrangement(data)),
  postTaskTableLayout: (data) => dispatch(postTaskTableLayout(data)),
  getNotes: (task_id) => dispatch(getNotes(task_id)),
  addNote: (data) => dispatch(addNote(data)),
  fetchUserDailyReport: (date) => dispatch(fetchUserDailyReport(date)),
  fetchUserMonthlyReport: () => dispatch(fetchUserMonthlyReport()),
});

Projects.propTypes = {};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(Projects)
);
