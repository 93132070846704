import React from "react";
import map_pin from "../../images/icons/map_pin.svg";
export const MapMarker = ({ address, onClick, active, time, selected, value }) => {
    return (
        <div className={`relative ${selected && "z-50"}`} onClick={onClick}>
            <img src={map_pin} className={selected ? "w-14 h-14" : "w-10 h-10"} alt="map pin" />
            <div
                className={`absolute top-0 bg-transparent ${
                    selected ? "w-14 h-14 py-2.5 left-[17px]" : "w-11 h-10 py-1.5 left-[11.7px]"
                }`}>
                <div
                    className={`bg-[#E5E7EB] border rounded-full border-[#6B7280] text-center w-max font-semibold overflow-x-auto no-scrollbar ${
                        selected
                            ? "py-0.5 text-xs h-[22px] w-[22px] max-w-[22px]"
                            : " py-0.5 text-[8px] w-[16.5px] max-w-[16.5px]"
                    }`}>
                    {value}
                </div>
            </div>
            <div
                className={`bg-white absolute bottom-16 left-[-4.5rem] w-52 p-2.5 isolate rounded-md ${
                    !selected && "hidden"
                }`}>
                <div className="text-[10px] text-secondary">{active && "Last Active"}</div>
                <div className=" text-xs text-primary py-0.5">{time}</div>
                <div className="text-tiny text-textprimary">{address}</div>
            </div>
            <div
                className="rounded-sm absolute -top-3 left-5"
                style={{
                    width: 0,
                    height: 0,
                    borderTop: "0.9rem solid white",
                    borderLeft: "0.5rem solid transparent",
                    borderRight: "0.5rem solid transparent",
                    display: !selected && "none",
                }}></div>
        </div>
    );
};
