import React,{ useState } from 'react';
import Layout from '../../../Components/Layout';
import { useHistory } from "react-router-dom";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DatePicker from "react-datepicker";
import orgPlaceholder from "../../../assets/orglogoplaceholder.svg";
import xcircle from "../../../assets/xcircle.svg";
import plusIcon from "../../../assets/Plus.svg";
import { useEffect } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
    downloadInvoice,
    getClientBillingInvoice,
    sendInvoiceEmail,
} from "../../../services/teams/actions";
import LoadingModal from "../../../Components/LoadingModal";
import { toast } from "react-toastify";
import FileSaver from "file-saver";

const PreviewInvoice = () => {
    const [tags, setTags] = useState([]);
    const [viaEmail, setViaEmail] = useState(false);
    const [xero, setXero] = useState(false);
    const [download, setDownload] = useState(false);
    const [downloadClick, setDownloadClick] = useState(false);
    const [billDate, setBillDate] = useState(new Date());
    const [dueDate, setDueDate] = useState(new Date());
    const [invoiceData, setInvoiceData] = useState({
        company: "",
        org_pic: "",
        org_address1: "",
        org_address2: "",
        org_city: "",
        org_state: "",
        org_country: "",
        org_zip: "",
        org_phone: "",
        client_name: "",
        description: "",
        client_email: "",
        address1: "",
        address2: "",
        state: "",
        city: "",
        Zip: "",
        phone: "",
        country: "",
        bill_rate: 0,
        teams: [],
        total: 0,
    });
    const [teams, setTeams] = useState([]);
    const [discount, setDiscount] = useState({ val: 0, enteredAmount: 0, type: "%" });
    const [invoice, setInvoice] = useState("12324");
    const params = new URLSearchParams(window.location.search);
    const dispatch = useDispatch();
    const history = useHistory();
    const handleKeyDown = (e) => {
        if (e.key !== "Enter") return;
        const value = e.target.value;
        if (!value.trim() || tags.includes(value)) return;
        var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (value.trim().match(mailformat)) {
            setTags([...tags, value]);
        } else {
            toast.error("invalid email");
        }
        e.target.value = "";
    };
    const handleRemoveTag = (index) => {
        setTags(tags.filter((el, i) => i !== index));
    };
    useEffect(() => {
        const date = new Date(params.get("date"));
        dispatch(
            getClientBillingInvoice(params.get("client"), date.getFullYear(), date.getMonth() + 1)
        ).then((res) => {
            if (res.value.status === 200) {
                setInvoiceData(res.value.data);
                setTeams(res.value.data.teams);
            }
        });
    }, []);
    const downloadPdf = async () => {
        const data = {
            invoice: invoice,
            billing_date: moment(billDate).format("MMMM DD YYYY"),
            due_date: moment(dueDate).format("MMMM DD YYYY"),
            company_city: invoiceData.city,
            company_name: invoiceData.client_name,
            company_address_1: invoiceData.address1,
            company_address_2: invoiceData.address2,
            company_email: invoiceData.client_email,
            company_phone: invoiceData.phone,
            org_phone: invoiceData.org_phone,
            image_logo: invoiceData.org_pic,
            org_email: localStorage.getItem("user_email"),
            org_city: invoiceData.org_city,
            org_names: invoiceData.company ?? localStorage.getItem("org_name"),
            org_address1: invoiceData.org_address1,
            org_address2: invoiceData.org_address2,
            sub_total: teams?.reduce(
                (sub, val) =>
                    sub +
                    Math.round(
                        (parseInt(val.duration.split(" ")[0]) +
                            parseInt(val.duration.split(" ")[2]) / 60) *
                            val.billing
                    ),
                0
            ),
            discount: discount.val,
            total:
                teams?.reduce(
                    (sub, val) =>
                        sub +
                        Math.round(
                            (parseInt(val.duration.split(" ")[0]) +
                                parseInt(val.duration.split(" ")[2]) / 60) *
                                val.billing
                        ),
                    0
                ) - discount.val,
            percentage: discount.enteredAmount,
            datas: teams.map((teamval) => ({
                duration: teamval.duration,
                team: teamval.teams,
                rate: teamval.billing,
                cost: Math.round(
                    (parseInt(teamval.duration.split(" ")[0]) +
                        parseInt(teamval.duration.split(" ")[2]) / 60) *
                        teamval.billing
                ),
            })),
        };
        dispatch(downloadInvoice(data)).then((res) => {
            if (res.value.status === 200) {
                var blob = new Blob([res.value.data], { type: "application/pdf" });
                FileSaver.saveAs(
                    blob,
                    invoiceData.client_email + "_" + invoiceData.client_name + ".pdf"
                );
            }
        });
    };
    const emailPdf = async () => {
        const data = {
            emails: tags,
            invoice_number: invoice,
            invoice_date: moment(billDate).format("MM/D/YYYY"),
            due_amount:
                teams?.reduce(
                    (sub, val) =>
                        sub +
                        Math.round(
                            (parseInt(val.duration.split(" ")[0]) +
                                parseInt(val.duration.split(" ")[2]) / 60) *
                                val.billing
                        ),
                    0
                ) - discount.val,
            due_date: moment(dueDate).format("MM/D/YYYY"),
            month_year: moment(billDate).format("MMMM YYYY"),
            org_name: invoiceData.company ?? localStorage.getItem("org_name"),
            client_name: invoiceData.client_name,
        };
        dispatch(sendInvoiceEmail(data)).then((res) => {
            if (res.value.status === 200) {
                toast.success("Invoice email sent");
                setTags([]);
                setDownloadClick(false);
            }
        });
    };
    return (
        <Layout>
            <div className="pb-3 flex gap-6">
                <button
                    onClick={() => history.goBack()}
                    className="rounded-md shadow-md border max-w-max bg-white border-fiver border-opacity-60 py-2 px-4 cursor-pointer">
                    Back
                </button>
                <span className="leading-normal font-medium text-2xl">Preview Invoice</span>
            </div>
            <div className="w-full flex items-start justify-between pb-3 mt-4 gap-3 bg-white">
                <div
                    id="invoiceDiv"
                    className="bg-white rounded-md shadow-md max-h-[842px] min-w-[720px] w-[210mm]">
                    <div className="pt-5 pb-4 text-center text-2xl font-medium text-[#1D1D1D] bg-white">
                        Invoice
                    </div>
                    <div className="p-4 flex flex-col justify-center gap-4 bg-white">
                        <div className="w-full flex justify-between items-center bg-white">
                            <div className="flex items-center bg-gray-200 rounded justify-center h-14 w-36 ">
                                <img
                                    src={invoiceData.org_pic ? invoiceData.org_pic : orgPlaceholder}
                                    alt="logo"
                                    className="h-full"
                                />
                            </div>
                            <div className="grid grid-col-2 gap-2 bg-white">
                                <div className="flex gap-3 justify-between items-center bg-white">
                                    <span className="leading-normal text-xs font-normal">
                                        Invoice no.
                                    </span>
                                    <div className="w-[120px] flex justify-center items-center">
                                        <div className="w-full mx-auto px-1 relative justify-end items-center flex border border-[#D1D5DB] hover:border hover:border-[#684D94] rounded-md text-center focus:outline-none">
                                            <input
                                                placeholder="Eg:225306"
                                                type="number"
                                                value={invoice}
                                                onChange={(ev) => setInvoice(ev.target.value)}
                                                className={
                                                    "w-full p-0 text-center border-0 text-gray-900 text-xs font-normal placeholder-gray-500 focus:ring-0 sm:text-sm "
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-3 justify-between items-center bg-white">
                                    <span className="leading-normal text-xs font-normal">
                                        Bill date
                                    </span>
                                    <div className="w-[120px] h-7 flex justify-center items-center">
                                        <div className="w-full mx-auto relative  flex justify-end items-center">
                                            <DatePicker
                                                className="rounded-md cursor-pointer w-[120px] h-7 text-xs font-normal border-[#D1D5DB] focus:border focus:border-[#684D94] text-center focus:outline-none focus:ring-0 sm:text-sm"
                                                dateFormat="MMMM dd yyyy"
                                                selected={billDate}
                                                onChange={(date) => setBillDate(date)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-3 justify-between items-center bg-white">
                                    <span className="leading-normal text-xs font-normal">
                                        Due date
                                    </span>
                                    <div className="w-[120px] h-7 flex justify-center items-center">
                                        <div className="w-full mx-auto relative  flex justify-end items-center">
                                            <DatePicker
                                                className="rounded-md cursor-pointer w-[120px] h-7 text-xs font-normal border-[#D1D5DB] focus:border focus:border-[#684D94] text-center focus:outline-none focus:ring-0 sm:text-sm"
                                                dateFormat="MMMM dd yyyy"
                                                selected={dueDate}
                                                onChange={(date) => setDueDate(date)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex gap-2">
                            <div className="w-full flex flex-col gap-2.5 rounded-xl bg-[#F2F2F2] p-4">
                                <span className="text-[#1D1D1D] text-tiny font-medium leading-normal">
                                    From
                                </span>
                                <div className="px-[5px] flex flex-col gap-2.5 justify-center items-start">
                                    <span className="text-[#1D1D1D] text-tiny font-medium leading-normal">
                                        {invoiceData.company ?? localStorage.getItem("org_name")}
                                    </span>
                                    <span className="text-[#1D1D1D] text-xs font-medium leading-[29px]">
                                        {invoiceData.org_address1}
                                    </span>
                                    <span className="text-[#1D1D1D] text-xs font-medium leading-[29px]">
                                        {invoiceData.org_address2}
                                    </span>
                                    <span className="text-[#1D1D1D] text-xs font-medium leading-[29px]">
                                        {invoiceData.org_city}
                                    </span>
                                    <span className="text-[#1D1D1D] text-xs font-medium leading-[29px]">
                                        {localStorage.getItem("user_email")}
                                    </span>
                                    <span className="text-[#1D1D1D] text-xs font-medium leading-[29px]">
                                        {invoiceData.org_phone}
                                    </span>
                                </div>
                            </div>

                            <div className="w-full rounded-xl bg-[#F2F2F2] p-4 flex flex-col gap-1.5">
                                <span className="text-[#1D1D1D] text-tiny font-medium leading-normal">
                                    To
                                </span>
                                <div className="px-[5px] flex flex-col gap-2.5 justify-center items-start">
                                    <div className="text-[#1D1D1D] text-tiny font-medium leading-normal">
                                        {invoiceData.client_name}
                                    </div>
                                    <div className="w-full justify-center bg-[#F2F2F2] items-center flex text-center focus:outline-none">
                                        <input
                                            type="text"
                                            placeholder="Address Line 1(optional)"
                                            value={invoiceData.address1}
                                            onChange={(ev) =>
                                                setInvoiceData({
                                                    ...invoiceData,
                                                    address1: ev.target.value,
                                                })
                                            }
                                            className={
                                                "block w-full border-0 p-0 pb-1 text-[#1D1D1D] text-xs font-medium leading-normal border-b border-[#D1D5DB] placeholder-[#6B7280] focus:ring-0 focus:border-b focus:border-[#684D94] sm:text-sm"
                                            }
                                            style={{ backgroundColor: "#F2F2F2" }}
                                        />
                                    </div>
                                    <div className="w-full justify-center bg-[#F2F2F2] items-center flex text-center focus:outline-none">
                                        <input
                                            type="text"
                                            placeholder="Address Line 2(optional)"
                                            value={invoiceData.address2}
                                            onChange={(ev) =>
                                                setInvoiceData({
                                                    ...invoiceData,
                                                    address2: ev.target.value,
                                                })
                                            }
                                            className={
                                                "block w-full border-0 p-0 pb-1 text-[#1D1D1D] text-xs font-medium leading-normal border-b border-[#D1D5DB] placeholder-[#6B7280] focus:ring-0 focus:border-b focus:border-[#684D94] sm:text-sm"
                                            }
                                            style={{ backgroundColor: "#F2F2F2" }}
                                        />
                                    </div>
                                    <div className="w-full justify-center bg-[#F2F2F2] items-center flex text-center focus:outline-none">
                                        <input
                                            type="text"
                                            placeholder="City(optional)"
                                            value={invoiceData.city}
                                            onChange={(ev) =>
                                                setInvoiceData({
                                                    ...invoiceData,
                                                    city: ev.target.value,
                                                })
                                            }
                                            className={
                                                "block w-full border-0 p-0 pb-1 text-[#1D1D1D] text-xs font-medium leading-normal border-b border-[#D1D5DB] placeholder-[#6B7280] focus:ring-0 focus:border-b focus:border-[#684D94] sm:text-sm"
                                            }
                                            style={{ backgroundColor: "#F2F2F2" }}
                                        />
                                    </div>
                                    <div className="w-full justify-center bg-[#F2F2F2] items-center flex text-center focus:outline-none">
                                        <input
                                            type="email"
                                            placeholder="Email Address"
                                            value={invoiceData.client_email}
                                            onChange={(ev) =>
                                                setInvoiceData({
                                                    ...invoiceData,
                                                    client_email: ev.target.value,
                                                })
                                            }
                                            className={
                                                "block w-full border-0 p-0 pb-1 text-[#1D1D1D] text-xs font-medium leading-normal border-b border-[#D1D5DB] placeholder-[#6B7280] focus:ring-0 focus:border-b focus:border-[#684D94] sm:text-sm"
                                            }
                                            style={{ backgroundColor: "#F2F2F2" }}
                                        />
                                    </div>
                                    <div className="w-full justify-center bg-[#F2F2F2] items-center flex text-center focus:outline-none">
                                        <input
                                            type="text"
                                            placeholder="Contact Number"
                                            value={invoiceData.phone}
                                            onChange={(ev) =>
                                                setInvoiceData({
                                                    ...invoiceData,
                                                    phone: ev.target.value,
                                                })
                                            }
                                            className={
                                                "block w-full border-0 p-0 pb-1 text-[#1D1D1D] text-xs font-medium leading-normal border-b border-[#D1D5DB] placeholder-[#6B7280] focus:ring-0 focus:border-b focus:border-[#684D94] sm:text-sm"
                                            }
                                            style={{ backgroundColor: "#F2F2F2" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*last table  */}
                        <div className="flex flex-col rounded-lg">
                            <div className="inline-block min-w-full align-middle">
                                <div>
                                    <table className="min-w-full">
                                        <thead className="bg-[#F2F2F2]">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className=" bg-[#F2F2F2] bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-xs font-medium leading-[18px] text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                    Team
                                                </th>
                                                <th
                                                    scope="col"
                                                    className=" bg-[#F2F2F2] bg-opacity-75 px-3 py-3.5 text-left text-xs font-medium leading-[18px] text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                    Time Spent
                                                </th>
                                                <th
                                                    scope="col"
                                                    className=" bg-[#F2F2F2] bg-opacity-75 px-3 py-3.5 text-left text-xs font-medium leading-[18px] text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                    Rate in USD
                                                </th>
                                                <th
                                                    scope="col"
                                                    className=" bg-[#F2F2F2] bg-opacity-75 px-3 py-3.5 text-left text-xs font-medium leading-[18px] text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                                    Amount in USD
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {teams?.map((val, index) => (
                                                <tr key={"teaminvoice" + index}>
                                                    <td className="whitespace-nowrap py-4 pr-3 sm:pl-4">
                                                        <input
                                                            type="text"
                                                            value={val.teams}
                                                            onChange={(ev) => {
                                                                const teamsTemp = [...teams];
                                                                teamsTemp[index].teams =
                                                                    ev.target.value;
                                                                setTeams(teamsTemp);
                                                            }}
                                                            className={
                                                                "block border-0 p-0 pb-1 text-[#1D1D1D] text-xs font-normal leading-normal border-b border-[#D1D5DB] placeholder-[#6B7280] focus:ring-0 focus:border-b focus:border-[#684D94] sm:text-sm"
                                                            }
                                                            style={{ backgroundColor: "white" }}
                                                        />
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 pr-3 sm:pl-4">
                                                        <input
                                                            type="text"
                                                            value={val.duration}
                                                            disabled
                                                            className={
                                                                "block border-0 p-0 pb-1 text-[#1D1D1D] text-xs font-normal leading-normal placeholder-[#6B7280] focus:ring-0 focus:border-b focus:border-[#684D94] sm:text-sm"
                                                            }
                                                            style={{ backgroundColor: "white" }}
                                                        />
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 pr-3 sm:pl-4">
                                                        <input
                                                            type="text"
                                                            value={val.billing}
                                                            onChange={(ev) => {
                                                                const teamsTemp = [...teams];
                                                                teamsTemp[index].billing =
                                                                    ev.target.value;
                                                                setTeams(teamsTemp);
                                                            }}
                                                            className={
                                                                "block border-0 p-0 pb-1 text-[#1D1D1D] text-xs font-normal leading-normal border-b border-[#D1D5DB] placeholder-[#6B7280] focus:ring-0 focus:border-b focus:border-[#684D94] sm:text-sm"
                                                            }
                                                            style={{ backgroundColor: "white" }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="flex gap-2 justify-between items-center">
                                                            <span className="text-xs font-medium leading-[18px]">
                                                                {Math.round(
                                                                    (parseInt(
                                                                        val.duration.split(" ")[0]
                                                                    ) +
                                                                        parseInt(
                                                                            val.duration.split(
                                                                                " "
                                                                            )[2]
                                                                        ) /
                                                                            60) *
                                                                        val.billing
                                                                )}
                                                            </span>
                                                            <img
                                                                onClick={() => {
                                                                    setTeams(
                                                                        teams.filter(
                                                                            (_, idx) =>
                                                                                idx !== index
                                                                        )
                                                                    );
                                                                }}
                                                                src={xcircle}
                                                                alt="x-logo"
                                                                className="w-5 h-5 cursor-pointer"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between w-full">
                            <div className="w-full"></div>
                            <div className="w-full flex flex-col gap-3 p-4">
                                <div className="w-full flex justify-between items-center">
                                    <span className="leading-[22px] font-normal text-tiny">
                                        Sub-total
                                    </span>
                                    <span className="leading-[22px] font-normal text-tiny">
                                        {teams?.reduce(
                                            (sub, val) =>
                                                sub +
                                                Math.round(
                                                    (parseInt(val.duration.split(" ")[0]) +
                                                        parseInt(val.duration.split(" ")[2]) / 60) *
                                                        val.billing
                                                ),
                                            0
                                        )}{" "}
                                        USD
                                    </span>
                                </div>
                                <div className="w-full flex justify-between items-center ">
                                    <span className="leading-[22px] font-normal text-tiny">
                                        Discount
                                    </span>

                                    <div className="flex justify-start items-center gap-4">
                                        <div className="w-[61px]">
                                            <input
                                                type="number"
                                                value={discount.enteredAmount}
                                                onChange={(ev) => {
                                                    setDiscount({
                                                        ...discount,
                                                        enteredAmount: ev.target.value,
                                                        val:
                                                            discount.type === "%"
                                                                ? (ev.target.value / 100) *
                                                                  teams?.reduce(
                                                                      (sub, val) =>
                                                                          sub +
                                                                          Math.round(
                                                                              (parseInt(
                                                                                  val.duration.split(
                                                                                      " "
                                                                                  )[0]
                                                                              ) +
                                                                                  parseInt(
                                                                                      val.duration.split(
                                                                                          " "
                                                                                      )[2]
                                                                                  ) /
                                                                                      60) *
                                                                                  val.billing
                                                                          ),
                                                                      0
                                                                  )
                                                                : ev.target.value,
                                                    });
                                                }}
                                                className={
                                                    "block remove-arrow w-full border-0 p-0 text-[#1D1D1D] text-xs font-normal leading-normal border-b border-[#D1D5DB] placeholder-[#6B7280] focus:ring-0 focus:border-b focus:border-[#684D94] sm:text-sm"
                                                }
                                                style={{ backgroundColor: "white" }}
                                            />
                                        </div>
                                        <div className="w-[61px] border-b border-[#D1D5DB] mt-2">
                                            <select
                                                className={
                                                    "block border-0 p-0 w-full text-[#1D1D1D] text-xs font-normal leading-normal placeholder-[#6B7280] focus:ring-0 focus:border-b focus:border-[#684D94] sm:text-sm"
                                                }
                                                value={discount.type}
                                                onChange={(ev) => {
                                                    if (ev.target.value === "%") {
                                                        const total = teams?.reduce(
                                                            (sub, val) =>
                                                                sub +
                                                                Math.round(
                                                                    (parseInt(
                                                                        val.duration.split(" ")[0]
                                                                    ) +
                                                                        parseInt(
                                                                            val.duration.split(
                                                                                " "
                                                                            )[2]
                                                                        ) /
                                                                            60) *
                                                                        val.billing
                                                                ),
                                                            0
                                                        );

                                                        setDiscount({
                                                            ...discount,
                                                            type: ev.target.value,
                                                            val:
                                                                (discount.enteredAmount / 100) *
                                                                total,
                                                        });
                                                    } else {
                                                        setDiscount({
                                                            ...discount,
                                                            val: discount.enteredAmount,
                                                            type: ev.target.value,
                                                        });
                                                    }
                                                }}
                                                style={{ backgroundColor: "white" }}>
                                                <option value="%">%</option>
                                                <option value="USD">USD</option>
                                            </select>
                                        </div>
                                    </div>

                                    <span className="leading-[22px] font-normal text-tiny">
                                        {discount.val} USD
                                    </span>
                                </div>
                                <div className="w-full flex justify-between items-center">
                                    <span className="text-base font-bold text-[#1D1D1D] leading-[22px]">
                                        Total
                                    </span>
                                    <span className="text-base font-bold text-[#1D1D1D] leading-[22px]">
                                        {teams?.reduce(
                                            (sub, val) =>
                                                sub +
                                                Math.round(
                                                    (parseInt(val.duration.split(" ")[0]) +
                                                        parseInt(val.duration.split(" ")[2]) / 60) *
                                                        val.billing
                                                ),
                                            0
                                        ) - discount.val}{" "}
                                        USD
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-3">
                            <span className="text-[#6B7280] font-semibold text-tiny leading-[22px]">
                                Note
                            </span>
                            <div className="flex justify-between items-center p-4 border-b border-[#D1D5DB]">
                                <span className="text-[#1D1D1D] text-xs font-normal leading-normal">
                                    This is computer generated invoice. Signature not required
                                </span>
                                <img
                                    src={plusIcon}
                                    alt="plus_icon"
                                    className="h-5 w-5 cursor-pointer"
                                />
                            </div>
                        </div>

                        <div></div>
                    </div>
                </div>
                {/* sending method */}
                <div className="bg-white rounded-md shadow-md">
                    <div className="p-4 flex flex-col gap-4 max-h-[500px] w-[468px]">
                        <span className="text-[#1D1D1D] text-base font-medium leading-normal">
                            Send invoice via
                        </span>
                        <div className="flex flex-col gap-3 border-b-2 border-[#EAEAEA] pb-3">
                            <div className="w-full flex justify-between">
                                <span className="text-[#1D1D1D] text-base font-medium leading-5">
                                    Email
                                </span>
                                <div
                                    className="w-[18px] h-[18px] cursor-pointer"
                                    onClick={() => setViaEmail((prev) => !prev)}>
                                    {viaEmail ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </div>
                            </div>
                            {viaEmail ? (
                                <>
                                    <div className="w-full flex items-start justify-start flex-wrap border border-[#D1D5DB] rounded-lg p-2">
                                        <div className="flex gap-1 flex-wrap w-full">
                                            {tags &&
                                                tags.map((tag, index) => (
                                                    <div
                                                        className="flex justify-center items-center px-1 border-2 border-[#684D94] rounded-md gap-2"
                                                        key={index}>
                                                        <span className="leading-4 text-xs font-medium">
                                                            {tag}
                                                        </span>
                                                        <span
                                                            className="text-center cursor-pointer"
                                                            onClick={() => handleRemoveTag(index)}>
                                                            &times;
                                                        </span>
                                                    </div>
                                                ))}
                                        </div>
                                        <input
                                            onKeyDown={handleKeyDown}
                                            type="email"
                                            placeholder="type your email and press enter"
                                            className="flex-1 p-2 text-[#6B7280] border-none focus:ring-0"
                                        />
                                    </div>
                                    <button
                                        disabled={tags.length === 0}
                                        onClick={() => {
                                            setDownloadClick(true);
                                            setTimeout(() => {
                                                emailPdf();
                                            }, 300);
                                        }}
                                        className="w-full p-4 rounded-md bg-[#684D94] text-center text-white text-base font-medium leading-normal">
                                        Email Invoice
                                    </button>
                                </>
                            ) : null}
                        </div>

                        <div className="flex flex-col gap-3 border-b-2 border-[#EAEAEA] pb-3">
                            <div className="w-full flex justify-between">
                                <span className="text-[#1D1D1D] text-base font-medium leading-5">
                                    Xero
                                </span>
                                <div
                                    className="w-[18px] h-[18px] cursor-pointer"
                                    onClick={() => setXero((prev) => !prev)}>
                                    {xero ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </div>
                            </div>
                            {xero ? (
                                <button className="w-full p-4 rounded-md bg-[#684D94] text-center text-white text-base font-medium leading-normal">
                                    Connect to Xero
                                </button>
                            ) : null}
                        </div>
                        <div className="flex flex-col gap-3 border-b-2 border-[#EAEAEA] pb-3">
                            <div className="w-full flex justify-between">
                                <span className="text-[#1D1D1D] text-base font-medium leading-5">
                                    Download Invoice
                                </span>
                                <div
                                    className="w-[18px] h-[18px] cursor-pointer"
                                    onClick={() => setDownload((prev) => !prev)}>
                                    {download ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </div>
                            </div>
                            {download ? (
                                <button
                                    onClick={() => {
                                        downloadPdf();
                                    }}
                                    className="w-full p-4 rounded-md bg-[#684D94] text-center text-white text-base font-medium leading-normal">
                                    Download Invoice
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <LoadingModal open={downloadClick} />
        </Layout>
    );
};

export default PreviewInvoice;