import React, {useState, useEffect} from 'react';
import Layout from "../../../Components/Layout";
import { useHistory } from "react-router-dom";
import OfficeIcon from '../../../assets/Office.svg';
import InputField from "../../../Components/InputField";
import moment from "moment";
import PhoneInput, {
    isValidPhoneNumber,
    formatPhoneNumberIntl,
  } from "react-phone-number-input";
  import flags from "react-phone-number-input/flags";
  import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
  import { toast } from "react-toastify";
  import SelectField from "../../../Components/SelectField";
import {
  getClientProfileInfo,
  editClientProfileInfo, 
  getClientOverview,
} from "../../../services/teams/actions";
import { useDispatch } from "react-redux";
const ClientProfile=()=>{
    const[clientPhoneNumber, setClientPhoneNumber]=useState("");
    const [clientDataOverview, setClientDataOverview] = useState({});
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    const emailRegex= /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const selectedDate = params.get("date");
    const [clientProfile, setClientProfile]=useState({});
    const history= useHistory();
    const dispatch = useDispatch();
    useEffect(()=>{
      dispatch(getClientProfileInfo(id)).then((res)=>{
          if(res.value.status === 200){
              setClientProfile(res.value.data)
          }
      })
    },[id]);

    useEffect(() => {
      const dateObject = new Date(selectedDate);
      getOverview(dateObject);
  }, [id]);
  
  const getOverview = (date) => {
       const id = params.get("id");
      dispatch(
          getClientOverview(
              moment(date).format("YYYY-MM-DD"),
              id,
              date.getMonth() + 1,
              date.getFullYear()
          )
      ).then((res) => {
          if (res.value.status === 200) {
              setClientDataOverview(res.value.data);
          }
      });
  };
    const handleBasicClientInfo=()=>{
      const profileData = new FormData();
      profileData.append("id", id);
      profileData.append("email", clientProfile["email"]);
      profileData.append("phone", clientProfile["phone"]);
      profileData.append("address1", clientProfile["address1"]);
      profileData.append("address2", clientProfile["address2"]);
      profileData.append("city", clientProfile["city"]);
      profileData.append("state", clientProfile["state"]);
      profileData.append("country", clientProfile["country"]);
      profileData.append("zip", clientProfile["zip"]);
      if(!isValidPhoneNumber(clientPhoneNumber)){
        toast.error("Please enter a valid Phone number");
        return;
      }else if(!emailRegex.test(clientProfile["email"])){
        toast.error("Please enter a valid Email address");
        return;
      }else{
        dispatch(editClientProfileInfo(profileData, id)).then((res)=>{
          if(res.value.status === 200){
            toast.success('Client information updated successfully')
            setTimeout(()=>{
                  history.goBack();
            },2000)
          }else{
            toast.error('Fail to update the informatio');
          }
         })
      }  
     };
    return(
       <Layout>
        <div className="bg-white p-6 rounded-md shadow-md mt-3 font-normal leading-normal text-lg">
            <nav className="rounded-md w-full">
              <ol className="list-reset flex">
                <li>
                  <button
                     onClick={() => history.goBack()}
                    className="text-primary hover:text-primary"
                  >
                    Clients
                  </button>
                </li>
                <li>
                  <span className="text-textprimary mx-2">/</span>
                </li>
                <li className="text-textprimary">Manage Clients</li>
              </ol>
            </nav>
          </div>
          <div className="bg-white p-6 rounded-md shadow-md mt-4 flex items-center">
            <div>
              <img
                src={OfficeIcon}
                alt="officeBuilding"
                className="w-8 h-8 mr-4"
              />
            </div>
            <div className='text-[#1D1D1D] text-lg font-bold uppercase leading-normal'>
            {clientDataOverview?.client_name}
            </div>
          </div>
          <div className="mt-4 p-4 w-full bg-white rounded-md shadow-md">
            <div className='text-[#684D94] w-[130px] text-base font-medium border-b-2 border-[#684D94] pb-1'>Client Information</div>
                  <div className="lg:grid lg:grid-cols-5 lg:gap-y-4 lg:gap-x-8 my-8">
                  <div className="col-span-4">
                      <div className="w-full mt-6">
                        {/* <InputField
                          value={clientDataOverview?.client_name}
                          FieldName="Client Name"
                          onInputChange={() => {
                          }}
                          disable="true"
                          type="text"
                        /> */}
                        <div className="w-full mx-auto relative border border-gray-300 rounded-md px-4 py-2 shadow-sm">
                         <span
                              className="absolute -top-2 left-3 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                            Client Name
                         </span>
                         <span className='block w-full border-0 p-0'>
                            {clientDataOverview?.client_name}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-4">
                      <div className="w-full mt-6">
                        {/* <InputField
                          value={clientDataOverview?.description}
                          FieldName="Client Description"
                          onInputChange={() => {
                          }}
                          disable='true'
                          type="text"
                        /> */}
                        <div className="w-full mx-auto relative border border-gray-300 rounded-md px-4 py-2 shadow-sm">
                         <span
                              className="absolute -top-2 left-3 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                            Client Description
                         </span>
                         <span className='block w-full border-0 p-0'>
                            {clientDataOverview?.description}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="w-full mt-6">
                        <InputField
                         value={
                          !clientProfile
                            ? ""
                            : clientProfile.email
                        }
                        FieldName="Email Address"
                        onInputChange={(e) => {
                          const details = {
                            ...clientProfile,
                          };
                          details.email = e.target.value;
                          setClientProfile(details);
                        }}
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="col-span-2">
                      <div className="w-full mt-6 border border-gray-300 focus-within:border-gray-500 relative px-3 py-1 rounded-md">
                        <div className="absolute -top-2 left-3 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                          Phone Number
                        </div>
                        <PhoneInput
                          flags={flags}
                          displayInitialValueAsLocalNumber={true}
                          defaultCountry="IN"
                          onChange={(val) =>
                            {
                              setClientPhoneNumber(formatPhoneNumberIntl(val));
                              const details={
                                ...clientProfile
                              }
                              details.phone=clientPhoneNumber;
                              setClientProfile(details);
                            }
                             
                            }
                            value={clientProfile.phone}
                            placeholder={
                              !clientPhoneNumber
                                ? "No Contact found"
                                : ""
                            }
                        />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="w-full mt-6">
                        <InputField
                         value={
                          !clientProfile
                            ? ""
                            : clientProfile.address1
                        }
                        FieldName="Address Line 1"
                        onInputChange={(e) => {
                          const details = {
                            ...clientProfile,
                          };
                          details.address1 = e.target.value;
                          setClientProfile(details);
                        }}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="w-full mt-6">
                        <InputField
                          value={
                            !clientProfile
                              ? ""
                              : clientProfile.address2
                          }
                          FieldName="Address Line 2"
                          onInputChange={(e) => {
                            const details = {
                              ...clientProfile,
                            };
                            details.address2 = e.target.value;
                            setClientProfile(details);
                          }}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="w-full mt-6">
                        <InputField
                          value={
                            !clientProfile
                              ? ""
                              : clientProfile.city
                          }
                          FieldName="City"
                          onInputChange={(e) => {
                            const details = {
                              ...clientProfile,
                            };
                            details.city = e.target.value;
                            setClientProfile(details);
                          }}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="w-full mt-6 relative">
                        <div className="absolute -top-2 left-3 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                          Country
                        </div>
                        <CountryDropdown
                        value={clientProfile.country}
                      className="w-full text-tiny rounded border-gray-300 focus:border-primary focus:outline-none hover:cursor-pointer"
                        onChange={(e) => {
                          const details = {
                            ...clientProfile,
                          };
                         details.country = e;
                         setClientProfile(details);
                         }}
                        />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="w-full mt-6 relative">
                        <div className="absolute -top-2 left-3 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                          State
                        </div>
                        <RegionDropdown
                            country={clientProfile.country}
                            value={clientProfile.state}
                            classes="w-full text-tiny rounded border-gray-300 focus:border-primary focus:outline-none hover:cursor-pointer"
                            onChange={(e) => {
                            const details = {
                             ...clientProfile,
                               };
                               details.state = e;
                             setClientProfile(details)
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="w-full mt-6">
                        <InputField
                         value={
                          !clientProfile
                            ? ""
                            : clientProfile.zip
                        }
                        FieldName="Pin Code"
                        onInputChange={(e) => {
                          const details = {
                            ...clientProfile,
                          };
                          details.zip = e.target.value;
                          setClientProfile(details);
                        }}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-span-5">
                      <div className="flex justify-start items-center mt-4">
                        <button
                          className="bg-primary text-white p-1 px-6 rounded-md shadow-md"
                          onClick={() => {
                            handleBasicClientInfo()
                          }}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                    </div>
                </div>
       </Layout>
    )
}

export default ClientProfile;