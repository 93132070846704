import { CalendarIcon, InformationCircleIcon } from "@heroicons/react/solid";
import React, { Component } from "react";
import moment from "moment";
import ReactHighcharts from "react-highcharts";
import SelectField from "../../../Components/SelectField";
import { BeatLoader } from "react-spinners";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
    getSalaryDaysData,
    getSalaryYearData,
    getSalaryDayDownload,
} from "../../../services/teams/actions";
import { SaveAltRounded } from "@material-ui/icons";
import FileSaver from "file-saver";
class EmployeePayroll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMonthActivity: false,
            startDate: new Date(),
            yearOptions: [],
            selectedFinancialYear: new Date().getFullYear(),
        };
    }
    componentDidMount() {
        let yearsOption = [];
        for (let currentYear = new Date().getFullYear(); currentYear >= 2019; currentYear--) {
            yearsOption.push({ name: currentYear + " - " + (currentYear + 1), id: currentYear });
        }
        this.setState({ yearOptions: yearsOption });
        setTimeout(() => {
            const query = new URLSearchParams(window.location.search);
            const userId = query.get("user_id");
            this.props.getSalaryDaysData(
                userId +
                    "&start_date=" +
                    moment(this.state.startDate).startOf("month").format("YYYY-MM-DD") +
                    "&end_date=" +
                    moment(this.state.startDate).endOf("month").format("YYYY-MM-DD")
            );
            this.props.getSalaryYearData(
                userId +
                    "&start_year=" +
                    this.state.selectedFinancialYear +
                    "&end_year=" +
                    (this.state.selectedFinancialYear + 1)
            );
        }, 200);
    }

    render() {
        const userDetails = this.props.selectedUser;
        let salaryData = this.props.teams && this.props.teams.SalaryDaysData;
        let yearSalaryData = this.props.teams && this.props.teams.SalaryYearData;
        let yearSalaryChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        for (let i = 0; i < (yearSalaryData && yearSalaryData.length); i++) {
            yearSalaryChartData[yearSalaryData[i].month - 1] = yearSalaryData[i].cost;
        }
        const chartData = {
            chart: {
                type: "spline",
                height: 350,
                style: {
                    fontFamily: "Lato",
                    fontSize: "15px",
                },
            },
            title: {
                text: "",
            },
            plotOptions: {
                series: {
                    cursor: "pointer",
                    allowPointSelect: true,
                },
                column: {
                    zones: [
                        {
                            value: 0,
                            color: "#684D94",
                        },
                    ],
                    pointPadding: 0.1,
                    borderWidth: 0,
                },
            },
            xAxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
                crosshair: true,
            },
            yAxis: [
                {
                    title: {
                        text: "",
                    },
                },
            ],
            tooltip: {
                valuePrefix: "$",
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    showInLegend: false,
                    color: "#684D94",
                    name: "In Hand Salary",
                    point: {
                        events: {
                            select: (e) => {
                                // let endTimeHour = parseInt(e.target.category.slice(0, 2));
                                // let endTimeMin = parseInt(e.target.category.slice(3, 5));
                                // this.onDataPointSelect(endTimeHour, endTimeMin);
                            },
                        },
                    },
                    data: yearSalaryChartData,
                },
            ],
        };
        const downloadHourReportCSV = () => {
            this.props
                .getSalaryDayDownload(
                    userDetails["user_id"] +
                        "&start_date=" +
                        moment(this.state.startDate).startOf("month").format("YYYY-MM-DD") +
                        "&end_date=" +
                        moment(this.state.startDate).endOf("month").format("YYYY-MM-DD")
                )
                .then((data) => {
                    if (data.value.status === 200) {
                        var blob = new Blob([data.value.data], { type: "application/xlsx" });
                        FileSaver.saveAs(blob, "SalaryReport.xlsx");
                    }
                });
        };
        return (
            <>
                {userDetails && (
                    <div className="bg-white mt-4 px-2 h-full py-1 border-b border-gray-200 sm:px-6 rounded-lg">
                        <div
                            className="rounded-md shadow-md border max-w-max border-fiver border-opacity-60 py-1 px-4 hover:cursor-pointer"
                            onClick={() => {
                                if (this.state.showMonthActivity) {
                                    this.setState({ showMonthActivity: false });
                                } else {
                                    this.props.onBack();
                                }
                            }}>
                            Back
                        </div>
                        <div className="flex mt-4 2xl:mt-6 justify-between items-center">
                            <div className="flex">
                                <div className="border-2 text-2xl h-16 w-16 uppercase rounded-full border-gray-400 bg-gray-200 text-gray-500 text-center flex flex-col justify-center">
                                    {userDetails["user_first_name"].charAt(0) +
                                        userDetails["user_last_name"].charAt(0)}
                                </div>
                                <div className="ml-2">
                                    <div className="text-2xl capitalize">
                                        {userDetails["user_first_name"]}{" "}
                                        {userDetails["user_last_name"]}
                                    </div>
                                    <div className="text-gray-500">{userDetails["user_email"]}</div>
                                </div>
                            </div>
                            {this.state.showMonthActivity && (
                                <button
                                    className="flex text-primary items-center hover:cursor-pointer"
                                    onClick={downloadHourReportCSV}>
                                    <SaveAltRounded fontSize="small" />
                                    <div className="pl-2 text-lg">Download</div>
                                </button>
                            )}
                        </div>

                        {salaryData && salaryData.data ? (
                            <div>
                                <div className="my-2 pt-4">
                                    <div className="flex mx-4 2xl:mx-6 py-2">
                                        <div className="flex-1">
                                            <div className="text-2xl font-bold capitalize py-0.5">
                                                {salaryData.org}
                                            </div>
                                            <div className="text-textprimary pl-1">
                                                for{" "}
                                                {moment(this.state.startDate)
                                                    .startOf("month")
                                                    .format("MMM D") +
                                                    " - " +
                                                    moment(this.state.startDate)
                                                        .endOf("month")
                                                        .format("MMM D")}
                                            </div>
                                            <div className="flex text-xs text-gray-500 items-center py-1">
                                                <InformationCircleIcon className="w-5 h-5 mr-1" />
                                                <div>
                                                    This is the estimated payroll for the current
                                                    month, Generated amount is for till to date.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-end min-w-max">
                                            <div className="flex text-[#6B7280] text-tiny mb-2">
                                                <CalendarIcon className="w-5 mr-2"></CalendarIcon>
                                                <div>
                                                    {moment(this.state.startDate).format(
                                                        "MMM DD, YYYY"
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="rounded-md text-white px-6 py-2 text-center text-xl bg-primary hover:cursor-pointer"
                                                onClick={() =>
                                                    this.setState({ showMonthActivity: true })
                                                }>
                                                {salaryData.data.length > 0 ? (
                                                    salaryData.data[0]["currency"] === "inr" ? (
                                                        <>&#8377;</>
                                                    ) : (
                                                        "$"
                                                    )
                                                ) : (
                                                    "$"
                                                )}
                                                {salaryData.total_amount}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.showMonthActivity ? (
                                    <div className="py-4">
                                        <table className="min-w-max w-full border-separate rounded-xl">
                                            <thead className="bg-gray-50 min-w-full">
                                                <tr className={`grid grid-cols-6 min-w-full`}>
                                                    <th
                                                        scope="col"
                                                        className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 px-3 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter`}>
                                                        Date
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell`}>
                                                        Projects
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell`}>
                                                        Tasks
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell`}>
                                                        Time Spend
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell`}>
                                                        Bill Rate/hr
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className={`sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-base font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell`}>
                                                        Total Bill
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {salaryData &&
                                                salaryData.data &&
                                                salaryData.data.length > 0 ? (
                                                    salaryData.data.map((reportRow, idx) => {
                                                        if (
                                                            idx > 0 &&
                                                            salaryData.data[idx - 1].date ===
                                                                reportRow.date
                                                        ) {
                                                            if (
                                                                salaryData.data[idx - 1].project ===
                                                                reportRow.project
                                                            ) {
                                                                return (
                                                                    <tr
                                                                        className={`grid grid-cols-6 min-w-full ${
                                                                            (idx ===
                                                                                salaryData.data
                                                                                    .length -
                                                                                    1 ||
                                                                                (idx <
                                                                                    salaryData.data
                                                                                        .length -
                                                                                        1 &&
                                                                                    salaryData.data[
                                                                                        idx + 1
                                                                                    ].date !==
                                                                                        reportRow.date)) &&
                                                                            "border-b border-gray-300"
                                                                        }`}
                                                                        key={reportRow.date + idx}>
                                                                        <td
                                                                            className={`px-3 py-2 text-base sm:table-cell`}></td>
                                                                        <td
                                                                            className={`px-3 py-2 text-base sm:table-cell`}>
                                                                            {reportRow.project}
                                                                        </td>
                                                                        <td
                                                                            className={`px-3 py-2 text-base sm:table-cell`}>
                                                                            {reportRow.task}
                                                                        </td>
                                                                        <td
                                                                            className={`px-3 py-2 text-base sm:table-cell`}>
                                                                            {Math.floor(
                                                                                parseInt(
                                                                                    reportRow.time
                                                                                ) / 60
                                                                            ).toLocaleString(
                                                                                "en-US",
                                                                                {
                                                                                    minimumIntegerDigits: 2,
                                                                                    useGrouping: false,
                                                                                }
                                                                            )}
                                                                            hr:
                                                                            {(
                                                                                parseInt(
                                                                                    reportRow.time
                                                                                ) % 60
                                                                            ).toLocaleString(
                                                                                "en-US",
                                                                                {
                                                                                    minimumIntegerDigits: 2,
                                                                                    useGrouping: false,
                                                                                }
                                                                            )}
                                                                            mins
                                                                        </td>
                                                                        <td
                                                                            className={`px-3 py-2 text-base sm:table-cell`}>
                                                                            {reportRow[
                                                                                "currency"
                                                                            ] === "inr" ? (
                                                                                <>&#8377;</>
                                                                            ) : (
                                                                                "$"
                                                                            )}
                                                                            {reportRow.rate}
                                                                        </td>

                                                                        <td
                                                                            className={`px-3 py-2 text-base lg:table-cell`}>
                                                                            {reportRow[
                                                                                "currency"
                                                                            ] === "inr" ? (
                                                                                <>&#8377;</>
                                                                            ) : (
                                                                                "$"
                                                                            )}
                                                                            {reportRow.bill}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                            return (
                                                                <tr
                                                                    className={`grid grid-cols-6 min-w-full ${
                                                                        (idx ===
                                                                            salaryData.data.length -
                                                                                1 ||
                                                                            (idx <
                                                                                salaryData.data
                                                                                    .length -
                                                                                    1 &&
                                                                                salaryData.data[
                                                                                    idx + 1
                                                                                ].date !==
                                                                                    reportRow.date)) &&
                                                                        "border-b border-gray-300"
                                                                    }`}
                                                                    key={reportRow.date + idx}>
                                                                    <td
                                                                        className={`px-3 py-2 text-base sm:table-cell`}></td>
                                                                    <td
                                                                        className={`px-3 py-2 text-base sm:table-cell`}></td>
                                                                    <td
                                                                        className={`px-3 py-2 text-base sm:table-cell`}>
                                                                        {reportRow.task}
                                                                    </td>
                                                                    <td
                                                                        className={`px-3 py-2 text-base sm:table-cell`}>
                                                                        {Math.floor(
                                                                            parseInt(
                                                                                reportRow.time
                                                                            ) / 60
                                                                        ).toLocaleString("en-US", {
                                                                            minimumIntegerDigits: 2,
                                                                            useGrouping: false,
                                                                        })}
                                                                        hr:
                                                                        {(
                                                                            parseInt(
                                                                                reportRow.time
                                                                            ) % 60
                                                                        ).toLocaleString("en-US", {
                                                                            minimumIntegerDigits: 2,
                                                                            useGrouping: false,
                                                                        })}
                                                                        mins
                                                                    </td>
                                                                    <td
                                                                        className={`px-3 py-2 text-base sm:table-cell`}>
                                                                        {reportRow["currency"] ===
                                                                        "inr" ? (
                                                                            <>&#8377;</>
                                                                        ) : (
                                                                            "$"
                                                                        )}
                                                                        {reportRow.rate}
                                                                    </td>

                                                                    <td
                                                                        className={`px-3 py-2 text-base lg:table-cell`}>
                                                                        {reportRow["currency"] ===
                                                                        "inr" ? (
                                                                            <>&#8377;</>
                                                                        ) : (
                                                                            "$"
                                                                        )}
                                                                        {reportRow.bill}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                        return (
                                                            <tr
                                                                className={`grid grid-cols-6 min-w-full  ${
                                                                    (idx ===
                                                                        salaryData.data.length -
                                                                            1 ||
                                                                        (idx <
                                                                            salaryData.data.length -
                                                                                1 &&
                                                                            salaryData.data[idx + 1]
                                                                                .date !==
                                                                                reportRow.date)) &&
                                                                    "border-b border-gray-300"
                                                                }`}
                                                                key={reportRow.date + idx}>
                                                                <td
                                                                    className={`px-3 py-2 text-base sm:table-cell`}>
                                                                    {reportRow.date}
                                                                </td>
                                                                <td
                                                                    className={`px-3 py-2 text-base sm:table-cell`}>
                                                                    {reportRow.project}
                                                                </td>
                                                                <td
                                                                    className={`px-3 py-2 text-base sm:table-cell`}>
                                                                    {reportRow.task}
                                                                </td>
                                                                <td
                                                                    className={`px-3 py-2 text-base sm:table-cell`}>
                                                                    {Math.floor(
                                                                        parseInt(reportRow.time) /
                                                                            60
                                                                    ).toLocaleString("en-US", {
                                                                        minimumIntegerDigits: 2,
                                                                        useGrouping: false,
                                                                    })}
                                                                    hr:
                                                                    {(
                                                                        parseInt(reportRow.time) %
                                                                        60
                                                                    ).toLocaleString("en-US", {
                                                                        minimumIntegerDigits: 2,
                                                                        useGrouping: false,
                                                                    })}
                                                                    mins
                                                                </td>
                                                                <td
                                                                    className={`px-3 py-2 text-base sm:table-cell`}>
                                                                    {reportRow["currency"] ===
                                                                    "inr" ? (
                                                                        <>&#8377;</>
                                                                    ) : (
                                                                        "$"
                                                                    )}
                                                                    {reportRow.rate}
                                                                </td>

                                                                <td
                                                                    className={`px-3 py-2 text-base lg:table-cell`}>
                                                                    {reportRow["currency"] ===
                                                                    "inr" ? (
                                                                        <>&#8377;</>
                                                                    ) : (
                                                                        "$"
                                                                    )}
                                                                    {reportRow.bill}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : salaryData &&
                                                  salaryData.data &&
                                                  salaryData.data.length === 0 ? (
                                                    <tr className="min-w-full flex justify-center items-center h-28">
                                                        <td
                                                            className="text-center flex-1"
                                                            colSpan={6}>
                                                            No Data of user for the month found
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr className="min-w-full flex justify-center">
                                                        <td
                                                            className="text-center flex-1"
                                                            colSpan={6}>
                                                            <BeatLoader
                                                                size={20}
                                                                color="rgba(104, 77, 148,1)"
                                                            />
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div className="border-t border-gray-200 pt-2 mx-4 2xl:mx-6">
                                        <div className="my-2 pb-4">
                                            <ReactHighcharts config={chartData} />
                                        </div>
                                        <div className="flex justify-end">
                                            <div className="relative w-1/3 min-w-[20rem]">
                                                <div className="absolute bg-white px-1 py-0.5 text-xs left-4 -top-1">
                                                    Financial Year
                                                </div>
                                                <SelectField
                                                    onSelectChange={(e) => {
                                                        this.setState(
                                                            {
                                                                selectedFinancialYear: parseInt(
                                                                    e.target.value
                                                                ),
                                                            },
                                                            () =>
                                                                this.props.getSalaryYearData(
                                                                    userDetails["user_id"] +
                                                                        "&start_year=" +
                                                                        e.target.value +
                                                                        "&end_year=" +
                                                                        (parseInt(e.target.value) +
                                                                            1)
                                                                )
                                                        );
                                                    }}
                                                    FieldName=""
                                                    name={"financialYear"}
                                                    arrayOfData={this.state.yearOptions}
                                                    id={"employee"}
                                                    hideDefault={true}
                                                    value={this.state.selectedFinancialYear}
                                                />
                                            </div>
                                        </div>
                                        <div className="px-4 py-4 2xl:px-6">
                                            <table className="w-full text-left border-separate rounded-xl">
                                                <thead>
                                                    <tr>
                                                        <th className="border-b border-gray-300 bg-gray-200 bg-opacity-50 p-3">
                                                            Pay Date
                                                        </th>
                                                        <th className="border-b border-gray-300 bg-gray-200 bg-opacity-50 p-3">
                                                            Gross Salary
                                                        </th>
                                                        {/* <th className="border-b border-gray-300 bg-gray-200 bg-opacity-50 p-3">
                                                            Deduction
                                                        </th>
                                                        <th className="border-b border-gray-300 bg-gray-200 bg-opacity-50 p-3">
                                                            In Hand Salary
                                                        </th> */}
                                                        <th className="border-b border-gray-300 bg-gray-200 bg-opacity-50 p-3">
                                                            YTD Net
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="w-full">
                                                    {yearSalaryData && yearSalaryData.length > 0 ? (
                                                        yearSalaryData.map((val) => (
                                                            <tr>
                                                                <td
                                                                    className="border-b border-gray-300 px-3 py-2 text-primary underline hover:cursor-pointer"
                                                                    style={{
                                                                        textUnderlineOffset:
                                                                            "0.25rem",
                                                                    }}
                                                                    onClick={() =>
                                                                        this.setState(
                                                                            {
                                                                                showMonthActivity: true,
                                                                                startDate: new Date(
                                                                                    parseInt(
                                                                                        val.year
                                                                                    ),
                                                                                    parseInt(
                                                                                        val.month
                                                                                    ) - 1,
                                                                                    1
                                                                                ),
                                                                            },
                                                                            () =>
                                                                                this.props.getSalaryDaysData(
                                                                                    userDetails[
                                                                                        "user_id"
                                                                                    ] +
                                                                                        "&start_date=" +
                                                                                        moment(
                                                                                            new Date(
                                                                                                parseInt(
                                                                                                    val.year
                                                                                                ),
                                                                                                parseInt(
                                                                                                    val.month
                                                                                                ) -
                                                                                                    1,
                                                                                                1
                                                                                            )
                                                                                        )
                                                                                            .startOf(
                                                                                                "month"
                                                                                            )
                                                                                            .format(
                                                                                                "YYYY-MM-DD"
                                                                                            ) +
                                                                                        "&end_date=" +
                                                                                        moment(
                                                                                            new Date(
                                                                                                parseInt(
                                                                                                    val.year
                                                                                                ),
                                                                                                parseInt(
                                                                                                    val.month
                                                                                                ) -
                                                                                                    1,
                                                                                                1
                                                                                            )
                                                                                        )
                                                                                            .endOf(
                                                                                                "month"
                                                                                            )
                                                                                            .format(
                                                                                                "YYYY-MM-DD"
                                                                                            )
                                                                                )
                                                                        )
                                                                    }>
                                                                    {new Date(
                                                                        parseInt(val.year),
                                                                        parseInt(val.month) - 1,
                                                                        1
                                                                    ).toLocaleString("en-US", {
                                                                        month: "long",
                                                                        day: "numeric",
                                                                        year: "numeric",
                                                                    })}
                                                                </td>
                                                                <td className="border-b border-gray-300 px-3 py-2">
                                                                    {val["currency"] === "inr" ? (
                                                                        <>&#8377;</>
                                                                    ) : (
                                                                        "$"
                                                                    )}
                                                                    {val.cost}
                                                                </td>
                                                                {/* <td className="border-b border-gray-300 px-3 py-2">
                                                                    $755
                                                                </td>
                                                                <td className="border-b border-gray-300 px-3 py-2">
                                                                    $2345
                                                                </td> */}
                                                                <td className="border-b border-gray-300 px-3 py-2">
                                                                    {val["currency"] === "inr" ? (
                                                                        <>&#8377;</>
                                                                    ) : (
                                                                        "$"
                                                                    )}
                                                                    {val.ynet ?? val.cost}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td
                                                                className="text-center py-4"
                                                                colSpan={3}>
                                                                No Data Found for the Year{" "}
                                                                {this.state.selectedFinancialYear +
                                                                    " - " +
                                                                    (this.state
                                                                        .selectedFinancialYear +
                                                                        1)}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : salaryData && salaryData.status === "no data found" ? (
                            <div className=" w-full h-[20vh] flex items-center justify-center text-lg">
                                No Salary Data for the user exist
                            </div>
                        ) : (
                            <div className="w-full h-[30vh] flex justify-center items-end">
                                <BeatLoader size={20} color="rgba(104, 77, 148,1)" />
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    teams: state.teams,
});

const mapDispatchToProps = (dispatch) => ({
    getSalaryDaysData: (params) => dispatch(getSalaryDaysData(params)),
    getSalaryYearData: (params) => dispatch(getSalaryYearData(params)),
    getSalaryDayDownload: (params) => dispatch(getSalaryDayDownload(params)),
});

export default withTranslation("common")(
    connect(mapStateToProps, mapDispatchToProps)(EmployeePayroll)
);
