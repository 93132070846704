import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../../Components/Layout';
import InputField from '../../Components/InputField';
import PrimaryButton from '../../Components/PrimaryButton';
import PaymentsTab from './PaymentsTab';
import IntegrationsTab from './IntegrationsTab';
import PermissionsTab from './PermissionsTab';
import FeaturesSettingTab from './FeatureSettingsTab';
import GeneralSettingTab from './GeneralSettingTab';
import FeaturesTab from './FeaturesTab';
import { withTranslation } from 'react-i18next';
import ConnectorsTab from "./ConnectorsTab";
import IndexesTab from "./IndexesTab";
import EventsTab from "./EventsTab";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

class Settings extends Component {
    constructor() {
        super();
        this.state = {
            SelectedTab: "general-settings",
            PaymentsSubSelectedTab: "Plan",
            PermissionSubSelectedTab: "permissions",
            GenerelSettingdSubSelectedTab: "account-Settings",
            urlpath: "",
        };
    }

    HandleSelectTab = (e) => {
        this.setState({ SelectedTab: e });
        setTimeout(() => {
            window.history.pushState({}, null, `/settings/${this.state.SelectedTab}`);
        }, 200);
    };

    HandleMobileSelectTab = (e) => {
        this.setState({ SelectedTab: e.target.value });
        window.history.pushState({}, null, `/settings/${this.state.SelectedTab}`);
    };

    componentDidMount() {
        const url = window.location.pathname;
        setTimeout(() => {
            this.setState({ urlpath: url });
        }, 100);

        // setTimeout(() => {
        //     if(url == '/settings/payments' || url == '/settings/integrations' || url == '/settings/permissions' || url == '/settings/general-settings' || url == '/settings/Features'){
        //         this.setState({SelectedTab : "payments"})
        //     }
        // }, 200);
        setTimeout(() => {
            if (this.state.urlpath === "/settings/payments") {
                this.setState({ SelectedTab: "payments" });
            } else if (this.state.urlpath === "/settings/integrations") {
                this.setState({ SelectedTab: "integrations" });
            } else if (this.state.urlpath === "/settings/permissions") {
                this.setState({ SelectedTab: "permissions" });
            } else if (this.state.urlpath === "/settings/permissions/global-permission") {
                this.setState({ SelectedTab: "permissions" });
            } else if (this.state.urlpath === "/settings/permissions/users-permission") {
                this.setState({ SelectedTab: "permissions" });
            } else if (this.state.urlpath === "/settings/permissions/teams-permission") {
                this.setState({ SelectedTab: "permissions" });
            } else if (this.state.urlpath === "/settings/general-settings") {
                this.setState({ SelectedTab: "general-settings" });
            } else if (this.state.urlpath === "/settings/general-settings/account-settings") {
                this.setState({ SelectedTab: "general-settings" });
            } else if (this.state.urlpath === "/settings/general-settings/email-settings") {
                this.setState({ SelectedTab: "general-settings" });
            } else if (this.state.urlpath === "/settings/general-settings/organisation-settings") {
                this.setState({ SelectedTab: "general-settings" });
            } else if (this.state.urlpath === "/settings/features") {
                this.setState({ SelectedTab: "features" });
            } else if (this.state.urlpath === "/settings/features/available-features") {
                this.setState({ SelectedTab: "features" });
            } else if (this.state.urlpath === "/settings/features/unavailable-features") {
                this.setState({ SelectedTab: "features" });
            } else if (this.state.urlpath === "/settings/connectors") {
                this.setState({ SelectedTab: "connectors" });
            } else if (this.state.urlpath === "/settings/events") {
                this.setState({ SelectedTab: "events" });
            }
            // else if (this.state.urlpath === "/settings/indexes") {
            //     this.setState({ SelectedTab: "indexes" });
            // }
            else {
                this.setState({ SelectedTab: "general-settings" });
            }
            setTimeout(() => {
                window.history.pushState({}, null, `/settings/${this.state.SelectedTab}`);
            }, 200);
        }, 200);
    }

    render() {
        const { t } = this.props;
        return (
            <Layout>
                <div className="bg-white px-2 py-1 border-b border-gray-200 lg:px-6 rounded-lg">
                    <div className="lg:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            {t("requestsModule.selectATab")}
                        </label>
                        <select
                            id="tabs"
                            name="tabs"
                            onChange={(e) => this.HandleMobileSelectTab(e)}
                            className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                            defaultValue={this.state.SelectedTab}>
                            <option key="general-settings" value="general-settings">
                                {t("settingsTab.generalSettings")}
                            </option>
                            <option key="permissions" value="permissions">
                                {t("settingsTab.permissions")}
                            </option>
                            <option key="integrations" value="integrations">
                                {t("integrations")}
                            </option>
                            <option key="payments" value="payments">
                                {t("settingsTab.payments")}
                            </option>
                            {/* <option key="integrations" value="integrations">
                                {t("integrations")}
                            </option> */}
                            <option key="features" value="features">
                                {t("settingsTab.features")}
                            </option>
                            <option key="Indexes" value="indexes">
                                Indexes
                            </option>
                            <option key="Connectors" value="connectors">
                                Connectors
                            </option>
                            <option key="Events" value="events">
                                Events
                            </option>
                        </select>
                    </div>
                    <div className="hidden lg:block">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            <div
                                key="Observers"
                                onClick={() => this.HandleSelectTab("general-settings")}
                                className={classNames(
                                    this.state.SelectedTab === "general-settings"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "general-settings"
                                        ? "page"
                                        : undefined
                                }>
                                <span>{t("settingsTab.generalSettings")}</span>
                            </div>

                            <div
                                key="Workstations"
                                onClick={() => this.HandleSelectTab("permissions")}
                                className={classNames(
                                    this.state.SelectedTab === "permissions"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "permissions" ? "page" : undefined
                                }>
                                <span>{t("settingsTab.permissions")}</span>
                            </div>

                            <div
                                key="Users"
                                onClick={() => this.HandleSelectTab("integrations")}
                                className={classNames(
                                    this.state.SelectedTab === "integrations"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "integrations" ? "page" : undefined
                                }>
                                <span>{t("integrations")}</span>
                            </div>

                            <div
                                key="Teams"
                                onClick={() => this.HandleSelectTab("payments")}
                                className={classNames(
                                    this.state.SelectedTab === "payments" ||
                                        this.state.SelectedTab === "TeamMembers"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "payments" ? "page" : undefined
                                }>
                                <span>{t("settingsTab.payments")}</span>
                            </div>

                            <div
                                key="features"
                                onClick={() => this.HandleSelectTab("features")}
                                className={classNames(
                                    this.state.SelectedTab === "features"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "features" ? "page" : undefined
                                }>
                                <span>{t("add") + " " + t("settingsTab.features")}</span>
                            </div>
                            {/* <div
                                key="Indexes"
                                onClick={() => this.HandleSelectTab("indexes")}
                                className={classNames(
                                    this.state.SelectedTab === "indexes"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "indexes" ? "page" : undefined
                                }>
                                <span>Indexes</span>
                            </div> */}
                            <div
                                key="Connectors"
                                onClick={() => this.HandleSelectTab("connectors")}
                                className={classNames(
                                    this.state.SelectedTab === "connectors"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "connectors" ? "page" : undefined
                                }>
                                <span>Connectors</span>
                            </div>
                            <div
                                key="Events"
                                onClick={() => this.HandleSelectTab("events")}
                                className={classNames(
                                    this.state.SelectedTab === "events"
                                        ? "border-primary text-primary"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-md cursor-pointer"
                                )}
                                aria-current={
                                    this.state.SelectedTab === "events" ? "page" : undefined
                                }>
                                <span>Events</span>
                            </div>
                        </nav>
                    </div>
                </div>
                {this.state.SelectedTab === "payments" ? (
                    <PaymentsTab pathname={this.state.urlpath} />
                ) : null}
                {this.state.SelectedTab === "integrations" ? (
                    <IntegrationsTab pathname={this.state.urlpath} />
                ) : null}
                {this.state.SelectedTab === "permissions" ? (
                    <PermissionsTab pathname={this.state.urlpath} />
                ) : null}
                {this.state.SelectedTab === "general-settings" ? (
                    <GeneralSettingTab pathname={this.state.urlpath} />
                ) : null}
                {this.state.SelectedTab === "features" ? (
                    <FeaturesSettingTab pathname={this.state.urlpath} />
                ) : null}
                {this.state.SelectedTab === "connectors" ? (
                    <ConnectorsTab pathname={this.state.urlpath} />
                ) : null}
                {this.state.SelectedTab === "events" ? (
                    <EventsTab pathname={this.state.urlpath} />
                ) : null}
                {/* {this.state.SelectedTab === "indexes" ? (
                    <IndexesTab pathname={this.state.urlpath} />
                ) : null} */}
            </Layout>
        );
    }
}
const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

Settings.propTypes = {

};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Settings));



