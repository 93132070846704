import { CalendarIcon } from "@heroicons/react/outline";
import DatePicker from "react-datepicker";
import React, { Component } from "react";
import Layout from "../../Components/Layout";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import SelectField from "../../Components/SelectField";
import { fetchactiveprojects } from "../../services/projects/actions";
import ModalShowCaseEntry from "../TimeTracker/modalShowCaseEntry";
import { Divider } from "@material-ui/core";
import { getShowcaseEntries } from "../../services/timetracker/actions";
import { ThreeSixtySharp } from "@material-ui/icons";
import FadeLoader from "react-spinners/FadeLoader";
class ShowCaseDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date(),
            selectedProject: "",
            showcaseEntriesList: [],
            selectedTask: "",
            selectedEntry: "",
            isShowcaseModalActive: false,
            isLoading: false,
        };
    }
    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const date = query.get("date");
        if (date) {
            this.setState({ selectedDate: new Date(date) }, () => {
                this.setState({ isLoading: true });
                this.props.fetchactiveprojects().then((res) => {
                    if (res.value.status === 200) {
                        var params = "";
                        params += res.value.data.response_data.map((project) => project.project_id);
                        // moment(this.state.selectedDate).format("YYYY-MM-DD"),
                        this.props.getShowcaseEntries(date, `all&list=${params}`).then((res1) => {
                            if (res1.value.status === 200) {
                                this.setState({ showcaseEntriesList: res1.value.data.data });
                            }
                        });
                    }
                    this.setState({ isLoading: false });
                });
            });
        } else {
            this.setState({ isLoading: true });
            this.props.fetchactiveprojects().then((res) => {
                if (res.value.status === 200) {
                    var params = "";
                    params += res.value.data.response_data.map((project) => project.project_id);
                    // moment(this.state.selectedDate).format("YYYY-MM-DD"),
                    this.props
                        .getShowcaseEntries(
                            moment(this.state.selectedDate).format("YYYY-MM-DD"),
                            `all&list=${params}`
                        )
                        .then((res1) => {
                            if (res1.value.status === 200) {
                                this.setState({ showcaseEntriesList: res1.value.data.data });
                            }
                        });
                }
                this.setState({ isLoading: false });
            });
        }
        // if (tab !== "showCase") {
        //     this.setState({ selectedProject: tab });
        // }
    }
    handleDateChange = (date) => {
        this.setState({ selectedDate: date }, () => {
            this.setState({ isLoading: true });
            this.props.fetchactiveprojects().then((res) => {
                if (res.value.status === 200) {
                    var params = "";
                    params += res.value.data.response_data.map((project) => project.project_id);
                    // moment(this.state.selectedDate).format("YYYY-MM-DD"),
                    this.props
                        .getShowcaseEntries(moment(date).format("YYYY-MM-DD"), `all&list=${params}`)
                        .then((res1) => {
                            if (res1.value.status === 200) {
                                this.setState({ showcaseEntriesList: res1.value.data.data });
                            }
                        });
                }
                this.setState({ isLoading: false });
            });
        });
    };

    handleViewAllEntriesForProject = (projectId, projectName) => {
        this.setState({ isLoading: true });
        this.setState({ selectedProject: projectName, showcaseEntriesList: [] });
        this.props
            .getShowcaseEntries(
                moment(this.state.selectedDate).format("YYYY-MM-DD"),
                `single&project_id=${projectId}`
            )
            .then((res) => {
                if (res.value.status === 200) {
                    this.setState({ showcaseEntriesList: res.value.data.data });
                }
                this.setState({ isLoading: false });
            });
    };
    render() {
        let activeprojects =
            this.props.projects &&
            this.props.projects.ActiveProjects &&
            this.props.projects.ActiveProjects.response_data;
        let ProjectData =
            activeprojects &&
            activeprojects.map((el) => ({
                name: el.project_name,
                id: el.project_name,
                projectId: el.project_id,
            }));
        const { t } = this.props;
        const onProjectShowcaseChange = (e) => {
            for (var i = 0; i < ProjectData.length; i++) {
                if (e.target.value === ProjectData[i].id) {
                    this.handleViewAllEntriesForProject(
                        ProjectData[i].projectId,
                        ProjectData[i].name
                    );
                    return;
                }
            }

            this.setState({ selectedProject: "", showcaseEntriesList: [] }, () => {
                this.setState({ isLoading: true });
                this.props.fetchactiveprojects().then((res) => {
                    if (res.value.status === 200) {
                        var params = "";
                        params += res.value.data.response_data.map((project) => project.project_id);
                        this.props
                            .getShowcaseEntries(
                                moment(this.state.selectedDate).format("YYYY-MM-DD"),
                                `all&list=${params}`
                            )
                            .then((res1) => {
                                if (res1.value.status === 200) {
                                    this.setState({
                                        showcaseEntriesList: res1.value.data.data,
                                    });
                                }
                            });
                    }
                    this.setState({ isLoading: false });
                });
            });
        };
        return (
            <Layout>
                <div className="bg-white px-2 py-4 mb-4 border-b border-gray-200 rounded-lg">
                    <div className=" flex items-center flex-wrap sm:flex-nowrap lg:pl-2">
                        <ChevronLeftIcon
                            className="rounded-full h-6 w-6 translate-y-0.5 hover:cursor-pointer"
                            onClick={() => {
                                if (window.history.state === null) {
                                    window.history.replaceState({}, null, "/dashboard");
                                    window.history.go(0);
                                } else {
                                    window.history.back();
                                }
                            }}
                        />
                        <div className="text-textprimary text-xl mx-4">
                            {`${t("dashboard")} > ${t("showCaseEntries")}` +
                                (this.state.selectedProject !== "" &&
                                this.state.selectedProject !== undefined
                                    ? " > " + this.state.selectedProject
                                    : "")}
                        </div>
                    </div>
                </div>
                <div className="bg-white px-2 py-4 mb-4 border-b border-gray-200 rounded-lg">
                    <div className=" flex items-center flex-wrap sm:flex-nowrap lg:pl-2">
                        <div>
                            <div className="mb-1 text-sm text-textprimary">{`${t("select")} ${t(
                                "date"
                            )}`}</div>
                            <div className="flex flex-shrink-0 relative">
                                <DatePicker
                                    className="rounded-md border-fiver"
                                    dateFormat="MMMM dd yyyy"
                                    selected={this.state.selectedDate}
                                    onChange={(date) => this.handleDateChange(date)}
                                />
                                <CalendarIcon className="absolute top-1 right-1 w-8"></CalendarIcon>
                            </div>
                        </div>
                        <div className=" ml-4 w-1/3 lg:ml-10">
                            <SelectField
                                onSelectChange={(e) => onProjectShowcaseChange(e)}
                                FieldName={t("select") + " " + t("project")}
                                name={"projectName"}
                                arrayOfData={ProjectData}
                                mainclassName={"lg:ml-3"}
                                defaultOption={t("allProjects")}
                                id={"project"}
                                value={
                                    this.state.selectedProject === ""
                                        ? ""
                                        : this.state.selectedProject
                                }></SelectField>
                        </div>
                    </div>
                </div>
                {this.state.showcaseEntriesList && this.state.showcaseEntriesList.length > 0 ? (
                    <ModalShowCaseEntry
                        open={this.state.isShowcaseModalActive}
                        fromDashboard={false}
                        userDetails={{
                            name: this.state.selectedEntry ? this.state.selectedEntry.username : "",
                        }}
                        handleClose={() => {
                            this.setState({ isShowcaseModalActive: false });
                        }}
                        handleModalShowChange={() => {
                            this.setState({
                                isShowcaseModalActive: !this.state.isShowcaseModalActive,
                            });
                        }}
                        entryDetails={[this.state.selectedEntry]}
                    />
                ) : null}
                {this.state.showcaseEntriesList &&
                this.state.showcaseEntriesList.length > 0 &&
                !this.state.isLoading &&
                this.state.showcaseEntriesList[0] &&
                this.state.showcaseEntriesList[0].main_list.length > 0 ? (
                    <div className="m-4">
                        {this.state.showcaseEntriesList.map((projectEntry) => {
                            if (
                                this.state.selectedProject === projectEntry.project_name &&
                                (!projectEntry.main_list || projectEntry.main_list.length === 0)
                            ) {
                                return (
                                    <div className="text-center pt-8 bg-white bg-opacity-50 rounded shadow-sm">
                                        <img
                                            alt=""
                                            className="m-auto"
                                            src={require("../../images/nodata.png")}
                                        />
                                        <div className="text-lg mt-2 text-textprimary font-medium">
                                            {t("timeTracker.noRecordsFound")}
                                        </div>
                                    </div>
                                );
                            }
                            if (!projectEntry.main_list || projectEntry.main_list.length === 0) {
                                return null;
                            }
                            return (
                                <div className="mb-4">
                                    <div className="flex justify-between">
                                        <div>
                                            {this.state.selectedProject === "" ? (
                                                <div className="font-semibold text-primary underline mb-1.5">
                                                    {`${t("project")}: ${
                                                        projectEntry.project_name
                                                    } (${
                                                        projectEntry.total_tasks
                                                            ? projectEntry.total_tasks
                                                            : projectEntry.total_entries
                                                    } ${t("taskss")})`}
                                                </div>
                                            ) : (
                                                <div className="font-semibold mb-1.5 text-textprimary">
                                                    {`${t("task")}: ` + projectEntry.task}
                                                </div>
                                            )}
                                            <div className="text-gray-500 text-sm font-semibold">
                                                {`${t("totalEntries")}: ${
                                                    projectEntry.total_entries
                                                }`}
                                            </div>
                                        </div>
                                        {this.state.selectedProject === "" ? (
                                            <button
                                                className="text-indigo-700 text-sm  my-2 px-3 rounded-md bg-indigo-100 shadow-sm"
                                                onClick={() => {
                                                    this.handleViewAllEntriesForProject(
                                                        projectEntry.project_id,
                                                        projectEntry.project_name
                                                    );
                                                }}>
                                                {t("viewAllEntries")}
                                            </button>
                                        ) : null}
                                    </div>
                                    <div className="flex my-4 overflow-x-auto">
                                        {projectEntry.main_list.map((entry, indx) => {
                                            return (
                                                <div
                                                    key={indx}
                                                    className={
                                                        "mr-4 min-w-[250px] w-[250px] overflow-hidden rounded-lg border-2 bg-white border-gray shadow-sm" +
                                                        (this.state.selectedProject === ""
                                                            ? " min-h-[250px] h-[250px]"
                                                            : " min-h-[230px] h-[230px]")
                                                    }>
                                                    {entry.video ? (
                                                        <video
                                                            src={entry.image}
                                                            className="min-w-[250px] max-w-[250px] min-h-[150px] max-h-[150px] hover:cursor-pointer"
                                                            onClick={() => {
                                                                this.setState({
                                                                    selectedEntry: entry,
                                                                    isShowcaseModalActive: true,
                                                                });
                                                            }}></video>
                                                    ) : (
                                                        <img
                                                            src={entry.image}
                                                            alt=""
                                                            className="min-w-[250px] max-w-[250px] min-h-[150px] max-h-[150px] hover:cursor-pointer"
                                                            onClick={() => {
                                                                this.setState({
                                                                    selectedEntry: entry,
                                                                    isShowcaseModalActive: true,
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                    <div className="p-2">
                                                        <p className=" text-sm font-semibold">
                                                            {entry.file_name}
                                                        </p>
                                                        <p className="text-xs text-gray-400 mb-1">
                                                            {`${t("by")} ${entry.username}`}
                                                        </p>
                                                        {this.state.selectedProject === "" ? (
                                                            <p className="text-sm text-gray-400">
                                                                {`${t("task")}: ${entry.task}`}
                                                            </p>
                                                        ) : null}
                                                        <p className="text-sm text-gray-400">
                                                            {`${t("team")}: ${entry.team}`}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <Divider />
                                </div>
                            );
                        })}
                    </div>
                ) : this.state.isLoading ? (
                    <div className="flex h-96 justify-center items-center">
                        <FadeLoader color={"#684994"} />
                    </div>
                ) : (
                    <div className="text-center pt-8 bg-white bg-opacity-50 rounded shadow-sm">
                        <img alt="" className="m-auto" src={require("../../images/nodata.png")} />
                        <div className="text-lg mt-2 text-textprimary font-medium">
                            {t("timeTracker.noRecordsFound")}
                        </div>
                    </div>
                )}
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({
    projects: state.projects,
    timetracker: state.timetracker,
});
const mapDispatchToProps = (dispatch) => ({
    fetchactiveprojects: () => dispatch(fetchactiveprojects()),
    getShowcaseEntries: (date, params) => dispatch(getShowcaseEntries(date, params)),
});

export default withTranslation("common")(
    connect(mapStateToProps, mapDispatchToProps)(ShowCaseDashboard)
);
