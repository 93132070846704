import Reac, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import InputField from "../../Components/InputField";
import { useTranslation } from "react-i18next";
import { MinusIcon, PlusIcon, XIcon } from "@heroicons/react/outline";
import Cropper from "react-easy-crop";
import { RedoRounded, UndoRounded } from "@material-ui/icons";
import Slider from "@mui/material/Slider";

export const ChangeProfileModal = (props) => {
    const { onCancelClick, onSubmitClick, open } = props;
    const cancelButtonRef = useRef(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1.1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState("");
    const fileRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState("");
    const { t } = useTranslation("common");
    const readFile = (file) => {
        const reader = new FileReader();
        reader.addEventListener(
            "load",
            (e) => {
                const data = e.target.result;
                setSelectedImage(data);
            },
            false
        );
        reader.readAsDataURL(file);
    };
    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener("load", () => resolve(image));
            image.addEventListener("error", (error) => reject(error));
            image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
            image.src = url;
        });
    function getRadianAngle(degreeValue) {
        return (degreeValue * Math.PI) / 180;
    }
    function rotateSize(width, height, rotation) {
        const rotRad = getRadianAngle(rotation);

        return {
            width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        };
    }
    async function getCroppedImg(
        imageSrc,
        pixelCrop,
        rotation = 0,
        flip = { horizontal: false, vertical: false }
    ) {
        const image = await createImage(imageSrc);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
            return null;
        }

        const rotRad = getRadianAngle(rotation);

        // calculate bounding box of the rotated image
        const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
            image.width,
            image.height,
            rotation
        );

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth;
        canvas.height = bBoxHeight;

        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
        ctx.rotate(rotRad);
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
        ctx.translate(-image.width / 2, -image.height / 2);

        // draw rotated image
        ctx.drawImage(image, 0, 0);

        // croppedAreaPixels values are bounding box relative
        // extract the cropped image using these values
        const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        // paste generated rotate image at the top left corner
        ctx.putImageData(data, 0, 0);
        // As Base64 string
        return fetch(canvas.toDataURL("image/jpeg"))
            .then(function (res) {
                return res.arrayBuffer();
            })
            .then(function (buf) {
                return new File([buf], "profilepic" + new Date().toISOString() + ".jpeg", {
                    type: "image/jpeg",
                });
            });
        // As a blob
        // return new Promise((resolve, reject) => {
        //     canvas.toBlob((file) => {
        //         resolve(URL.createObjectURL(file));
        //     }, "image/jpeg");
        // });
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                onClose={() => {
                    setSelectedImage("");
                    setZoom(1.1);
                    setRotation(0);
                    setCrop({ x: 0, y: 0 });
                    setCroppedImage("");
                    setCroppedAreaPixels(null);
                    onCancelClick();
                }}>
                <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-bold text-textprimary flex justify-between">
                                        <div>Change Profile picture</div>
                                        <XIcon
                                            className="w-5 h-5 hover:cursor-pointer"
                                            onClick={() => {
                                                setSelectedImage("");
                                                setZoom(1.1);
                                                setRotation(0);
                                                setCrop({ x: 0, y: 0 });
                                                setCroppedImage("");
                                                setCroppedAreaPixels(null);
                                                onCancelClick();
                                            }}
                                        />
                                    </Dialog.Title>
                                </div>
                                <div className="mt-4">
                                    {croppedImage && <img src={croppedImage} alt="fsfd" />}
                                    {selectedImage ? (
                                        <div className="w-full">
                                            <div className="relative w-full bg-black py-2">
                                                <img
                                                    src={selectedImage}
                                                    alt="new profile"
                                                    className="-z-10 mx-auto opacity-0"
                                                />
                                                <Cropper
                                                    image={selectedImage}
                                                    classes="mx-auto"
                                                    crop={crop}
                                                    rotation={rotation}
                                                    zoom={zoom}
                                                    cropShape="round"
                                                    showGrid={false}
                                                    aspect={1}
                                                    onCropChange={setCrop}
                                                    onRotationChange={setRotation}
                                                    minZoom={1.1}
                                                    onCropComplete={(
                                                        croppedArea,
                                                        croppedAreaPixels
                                                    ) => {
                                                        setCroppedAreaPixels(croppedAreaPixels);
                                                    }}
                                                    onZoomChange={setZoom}
                                                />
                                            </div>
                                            <div className="flex text-gray-400 pt-2">
                                                <div>
                                                    <div className="text-tiny pb-1">Rotate</div>
                                                    <div className="flex">
                                                        <div
                                                            className="w-6 h-6 flex justify-center items-center border rounded-full mr-2 hover:cursor-pointer"
                                                            onClick={() =>
                                                                setRotation(rotation + 90)
                                                            }>
                                                            <RedoRounded
                                                                className="rotate-[-60deg]"
                                                                fontSize="small"
                                                            />
                                                        </div>
                                                        <div
                                                            className="w-6 h-6 flex justify-center items-center border rounded-full hover:cursor-pointer"
                                                            onClick={() =>
                                                                setRotation(rotation - 90)
                                                            }>
                                                            <UndoRounded
                                                                className="rotate-[60deg]"
                                                                fontSize="small"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pl-6 flex-1 max-w-xs">
                                                    <div className="text-tiny pb-1">Zoom</div>
                                                    <div className="flex items-center">
                                                        <MinusIcon
                                                            className="w-6 h-6 mr-2 hover:cursor-pointer"
                                                            onClick={() => {
                                                                if (zoom > 1.1) {
                                                                    setZoom(zoom - 0.1);
                                                                }
                                                            }}
                                                        />
                                                        <Slider
                                                            value={zoom}
                                                            min={1.1}
                                                            max={5}
                                                            step={0.1}
                                                            sx={{
                                                                marginLeft: "0.8rem",
                                                                color: "#684D94",
                                                                "& .MuiSlider-thumb": {
                                                                    marginTop: "0px",
                                                                    width: "1rem",
                                                                    height: "1rem",
                                                                    color: "#684D94",
                                                                },
                                                                "& .MuiSlider-track": {
                                                                    height: "0.25rem",
                                                                    color: "#684D94",
                                                                },
                                                            }}
                                                            onChange={(e, zoom) => setZoom(zoom)}
                                                        />
                                                        <PlusIcon
                                                            className="w-6 h-6 ml-2 hover:cursor-pointer"
                                                            onClick={() => {
                                                                if (zoom < 5) {
                                                                    setZoom(zoom + 0.1);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="h-32 w-full text-xs border-2 border-dashed border-gray-200 text-gray-400 rounded-lg px-10 text-center flex flex-col justify-around py-2"
                                            onDragOver={(e) => {
                                                e.preventDefault();
                                            }}
                                            onDrop={(ev) => {
                                                ev.preventDefault();
                                                if (
                                                    ev.dataTransfer.files[0].type.split("/")[0] ===
                                                    "image"
                                                ) {
                                                    readFile(ev.dataTransfer.files[0]);
                                                }
                                            }}>
                                            <div>Drag and drop a picture here</div>
                                            <div className="flex items-center">
                                                <div className="flex-1 border-t h-0" />
                                                <div className="px-1">OR</div>
                                                <div className="flex-1 border-t h-0" />
                                            </div>
                                            <input
                                                type="file"
                                                hidden
                                                multiple={false}
                                                ref={fileRef}
                                                onChange={(ev) => {
                                                    if (
                                                        ev.target.files[0].type.split("/")[0] ===
                                                        "image"
                                                    ) {
                                                        readFile(ev.target.files[0]);
                                                    }
                                                }}
                                            />
                                            <button
                                                className="bg-primary text-white rounded-md w-max mx-auto py-2 px-6"
                                                onClick={() => fileRef.current.click()}>
                                                Browse my computer
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {selectedImage && (
                                <div className="mt-4 sm:flex justify-end">
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-tiny font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:w-auto"
                                        onClick={() => {
                                            setSelectedImage("");
                                            setZoom(1.1);
                                            setRotation(0);
                                            setCrop({ x: 0, y: 0 });
                                            setCroppedImage("");
                                            setCroppedAreaPixels(null);
                                            onCancelClick();
                                        }}
                                        ref={cancelButtonRef}>
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-tiny font-medium text-white hover:bg-primary focus:outline-none sm:ml-3 sm:w-auto"
                                        onClick={() => {
                                            getCroppedImg(
                                                selectedImage,
                                                croppedAreaPixels,
                                                rotation
                                            ).then((croppedImage) => {
                                                const finalImage = croppedImage;
                                                setCroppedImage(croppedImage);
                                                onSubmitClick(croppedImage);

                                                setSelectedImage("");
                                                setZoom(1.1);
                                                setRotation(0);
                                                setCrop({ x: 0, y: 0 });
                                                setCroppedImage("");
                                                setCroppedAreaPixels(null);
                                            });
                                        }}>
                                        Make as Profile Picture
                                    </button>
                                </div>
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
