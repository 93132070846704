import { ACTION_TYPES } from "../../../constants";
import api from "../api";

export const fetchactiveprojects = () => ({
  type: ACTION_TYPES.FETCH_ACTIVE_PROJECTS,
  payload: api.fetchActiveProjects(),
});

export const fetchactiveTasks = () => ({
  type: ACTION_TYPES.FETCH_ACTIVE_TASKS,
  payload: api.fetchActiveTasks(),
});

export const fetchActiveTaskOserver = () => ({
  type: ACTION_TYPES.FETCH_ACTIVE_TASKS,
  payload: api.fetchActiveTaskOserver(),
});

export const fetchallEmployees = () => ({
  type: ACTION_TYPES.FETCH_EMPLOYEES,
  payload: api.fetchAllEmployees(),
});

export const getUsersfromtheProject = (name) => ({
  type: ACTION_TYPES.GET_USERS_FROM_PROJECT,
  payload: api.fetchallusersFromProject(name),
});

export const DeleteProject = (name) => ({
  type: ACTION_TYPES.DELETE_PROJECT,
  payload: api.deleteProject(name),
});

export const DeleteTask = (id) => ({
  type: ACTION_TYPES.DELETE_TASK,
  payload: api.deleteTask(id),
});

export const DeleteUserFromProject = (data) => ({
  type: ACTION_TYPES.DELETE_USER_FROM_PROJECT,
  payload: api.deleteUserFromProject(data),
});

export const createTask = (data) => ({
  type: ACTION_TYPES.CREATE_TASK,
  payload: api.createTask(data),
});

export const updateTask = (data) => ({
  type: ACTION_TYPES.UPDATE_TASK,
  payload: api.updateTask(data),
});

export const createProject = (data) => ({
  type: ACTION_TYPES.CREATE_PROJECT,
  payload: api.createProject(data),
});

export const updateProject = (data) => ({
  type: ACTION_TYPES.UPDATE_PROJECT,
  payload: api.updateproject(data),
});

export const AdduserToTheProject = (data) => ({
  type: ACTION_TYPES.ADD_USER_TO_PROJECT,
  payload: api.addusertoproject(data),
});

export const taskLsitObserver = () => ({
  type: ACTION_TYPES.TASK_LIST_OBSERVER,
  payload: api.taskListObserver(),
});

export const deatiledTaskInfo = (taskname) => ({
  type: ACTION_TYPES.GET_DETAILED_TASK_INFO,
  payload: api.detailedTaskInfo(taskname),
});

export const getProjectDetails = () => ({
  type: ACTION_TYPES.GET_PROJECT_DETAILS,
  payload: api.getProjectDetails(),
});

export const postProjectHrsBooked = (data) => ({
  type: ACTION_TYPES.ADD_PROJECT_DETAILS,
  payload: api.postProjectHrsBooked(data),
});

export const updateProjectHrsBooked = (data) => ({
  type: ACTION_TYPES.UPDATE_PROJECT_DETAILS,
  payload: api.updateProjectHrsBooked(data),
});

export const getProjectTableLayout = () => ({
  type: ACTION_TYPES.GET_PROJECT_TABLE_LAYOUT,
  payload: api.getProjectTableLayout(),
});

export const updateProjectTableLayout = (data) => ({
  type: ACTION_TYPES.UPDATE_PROJECT_TABLE_LAYOUT,
  payload: api.updateProjectTableLayout(data),
});

export const postProjectTableLayout = (data) => ({
  type: ACTION_TYPES.UPDATE_PROJECT_TABLE_LAYOUT,
  payload: api.postProjectTableLayout(data),
});

export const getProjectTableArrangement = () => ({
  type: ACTION_TYPES.GET_PROJECT_TABLE_ARRANGEMENT,
  payload: api.getProjectTableArrangement(),
});

export const updateProjectTableArrangement = (data) => ({
  type: ACTION_TYPES.UPDATE_PROJECT_TABLE_ARRANGEMENT,
  payload: api.updateProjectTableArrangement(data),
});

export const postProjectTableArrangement = (data) => ({
  type: ACTION_TYPES.UPDATE_PROJECT_TABLE_ARRANGEMENT,
  payload: api.postProjectTableArrangement(data),
});

export const getUserBasedProjects = (userId) => ({
  type: ACTION_TYPES.GET_PROJECT_TABLE_ARRANGEMENT,
  payload: api.getUserBasedProjects(userId),
});

export const getUserBasedTeams = (userId) => ({
  type: ACTION_TYPES.GET_PROJECT_TABLE_ARRANGEMENT,
  payload: api.getUserBasedTeams(userId),
});

export const getTaskTableLayout = () => ({
  type: ACTION_TYPES.GET_PROJECT_TABLE_LAYOUT,
  payload: api.getTaskTableLayout(),
});

export const updateTaskTableLayout = (data) => ({
  type: ACTION_TYPES.UPDATE_PROJECT_TABLE_LAYOUT,
  payload: api.updateTaskTableLayout(data),
});

export const postTaskTableLayout = (data) => ({
  type: ACTION_TYPES.UPDATE_PROJECT_TABLE_LAYOUT,
  payload: api.postTaskTableLayout(data),
});

export const getTaskTableArrangement = () => ({
  type: ACTION_TYPES.GET_PROJECT_TABLE_ARRANGEMENT,
  payload: api.getTaskTableArrangement(),
});

export const updateTaskTableArrangement = (data) => ({
  type: ACTION_TYPES.UPDATE_PROJECT_TABLE_ARRANGEMENT,
  payload: api.updateTaskTableArrangement(data),
});

export const postTaskTableArrangement = (data) => ({
  type: ACTION_TYPES.UPDATE_PROJECT_TABLE_ARRANGEMENT,
  payload: api.postTaskTableArrangement(data),
});

export const updateActiveTask = (data) => ({
  type: ACTION_TYPES.UPDATE_ACTIVE_TASKS,
  payload: api.updateActiveTask(data),
});

export const postActiveTask = (data) => ({
  type: ACTION_TYPES.UPDATE_ACTIVE_TASKS,
  payload: api.postActiveTask(data),
});

export const getProjectCustomFields = (id) => ({
  type: ACTION_TYPES.GET_PROJECT_CUSTOM_FIELDS,
  payload: api.getProjectCustomFields(id),
});
export const getXeroProject = (id) => ({
  type: ACTION_TYPES.GET_XERO_PROJECT,
  payload: api.getXeroProject(id),
});
export const postXeroProject = (data) => ({
  type: ACTION_TYPES.UPDATE_XERO_PROJECT,
  payload: api.postXeroProject(data),
});

export const getCategoriesData = (data) => ({
  type: ACTION_TYPES.GET_CATEGORIES_DATA,
  payload: api.getCategoriesData(data),
});
export const getNotes = (task_id) => ({
  type: ACTION_TYPES.GET_NOTES_DATA,
  payload: api.getNotes(task_id),
});
export const addNote = (data) => ({
  type: ACTION_TYPES.POST_NOTES_DATA,
  payload: api.addNote(data),
});

export const fetchUserDailyReport = (date) => ({
  type: ACTION_TYPES.FETCH_USER_DAILY_REPORTS,
  payload: api.fetchUserDailyReports(date),
});

export const fetchUserMonthlyReport = () => ({
  type: ACTION_TYPES.FETCH_USER_MONTHLY_REPORTS,
  payload: api.fetchUserMonthlyReports(),
});
