import { ACTION_TYPES } from "../../../constants";

const dashboard = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_MANAGER_DASHBOARD_PENDING: {
            return {
                ...state,
                isFetchingManagersDashboardList: true,
                isFetchingManagersDashboardFailed: false,
                ManagersDashboardFetchError: undefined,
                ManagersDashboard: [],
            };
        }
        case ACTION_TYPES.FETCH_MANAGER_DASHBOARD_FULFILLED: {
            return {
                ...state,
                isFetchingManagersDashboardList: false,
                isFetchingManagersDashboardFailed: false,
                ManagersDashboardFetchError: undefined,
                ManagersDashboard: action.payload.data,
            };
        }
        case ACTION_TYPES.FETCH_MANAGER_DASHBOARD_REJECTED: {
            return {
                ...state,
                isFetchingManagersDashboardList: false,
                isFetchingManagersDashboardFailed: true,
                ManagersDashboardFetchError: action.payload,
                ManagersDashboard: [],
            };
        }

        case ACTION_TYPES.FETCH_MANAGER_DASHBOARD_OBSERVER_PENDING: {
            return {
                ...state,
                isFetchingManagersDashboardObserverList: true,
                isFetchingManagersDashboardObserverFailed: false,
                ManagersDashboardObserverFetchError: undefined,
                ManagersDashboardObserver: [],
            };
        }

        case ACTION_TYPES.FETCH_MANAGER_DASHBOARD_OBSERVER_FULFILLED: {
            return {
                ...state,
                isFetchingManagersDashboardObserverList: false,
                isFetchingManagersDashboardObserverFailed: false,
                ManagersDashboardObserverFetchError: undefined,
                ManagersDashboardObserver: action.payload.data,
            };
        }

        case ACTION_TYPES.FETCH_MANAGER_DASHBOARD_OBSERVER_REJECTED: {
            return {
                ...state,
                isFetchingManagersDashboardObserverList: false,
                isFetchingManagersDashboardObserverFailed: true,
                ManagersDashboardObserverFetchError: action.payload,
                ManagersDashboardObserver: [],
            };
        }

        case ACTION_TYPES.FETCH_TEAMS_DASHBOARD_PENDING: {
            return {
                ...state,
                isFetchingTeamsDashboardList: true,
                isFetchingTeamsDashboardFailed: false,
                TeamsDashboardFetchError: undefined,
                TeamsDashboard: [],
            };
        }
        case ACTION_TYPES.FETCH_TEAMS_DASHBOARD_FULFILLED: {
            return {
                ...state,
                isFetchingTeamsDashboardList: false,
                isFetchingTeamsDashboardFailed: false,
                TeamsDashboardFetchError: undefined,
                TeamsDashboard: action.payload.data,
            };
        }
        case ACTION_TYPES.FETCH_TEAMS_DASHBOARD_REJECTED: {
            return {
                ...state,
                isFetchingTeamsDashboardList: false,
                isFetchingTeamsDashboardFailed: true,
                TeamsDashboardFetchError: action.payload,
                TeamsDashboard: [],
            };
        }

        case ACTION_TYPES.FETCH_NOT_WORKING_EMPLOYEES_PENDING: {
            return {
                ...state,
                isFetchingNotWorkingEmployeesList: true,
                isFetchingNotWorkingEmployeesFailed: false,
                NotWorkingEmployeesFetchError: undefined,
                NotWorkingEmployees: [],
            };
        }
        case ACTION_TYPES.FETCH_NOT_WORKING_EMPLOYEES_FULFILLED: {
            return {
                ...state,
                isFetchingNotWorkingEmployeesList: false,
                isFetchingNotWorkingEmployeesFailed: false,
                NotWorkingEmployeesFetchError: undefined,
                NotWorkingEmployees: action.payload.data,
            };
        }
        case ACTION_TYPES.FETCH_NOT_WORKING_EMPLOYEES_REJECTED: {
            return {
                ...state,
                isFetchingNotWorkingEmployeesList: false,
                isFetchingNotWorkingEmployeesFailed: true,
                NotWorkingEmployeesFetchError: action.payload,
                NotWorkingEmployees: [],
            };
        }

        case ACTION_TYPES.ORG_EMAILS_PENDING: {
            return {
                ...state,
                isFetchingorganizationEmailsList: true,
                isFetchingorganizationEmailsFailed: false,
                organizationEmailsFetchError: undefined,
                organizationEmails: [],
            };
        }
        case ACTION_TYPES.ORG_EMAILS_FULFILLED: {
            return {
                ...state,
                isFetchingorganizationEmailsList: false,
                isFetchingorganizationEmailsFailed: false,
                organizationEmailsFetchError: undefined,
                organizationEmails: action.payload.data,
            };
        }
        case ACTION_TYPES.ORG_EMAILS_REJECTED: {
            return {
                ...state,
                isFetchingorganizationEmailsList: false,
                isFetchingorganizationEmailsFailed: true,
                organizationEmailsFetchError: action.payload,
                organizationEmails: [],
            };
        }

        case ACTION_TYPES.TEAM_DETAILS_DASHBOARD_PENDING: {
            return {
                ...state,
                isFetchingTeamsDetailsDashboardList: true,
                isFetchingTeamsDetailsDashboardFailed: false,
                TeamsDetailsDashboardFetchError: undefined,
                TeamsDetailsDashboard: [],
            };
        }
        case ACTION_TYPES.TEAM_DETAILS_DASHBOARD_FULFILLED: {
            return {
                ...state,
                isFetchingTeamsDetailsDashboardList: false,
                isFetchingTeamsDetailsDashboardFailed: false,
                TeamsDetailsDashboardFetchError: undefined,
                TeamsDetailsDashboard: action.payload.data,
            };
        }
        case ACTION_TYPES.TEAM_DETAILS_DASHBOARD_REJECTED: {
            return {
                ...state,
                isFetchingTeamsDetailsDashboardList: false,
                isFetchingTeamsDetailsDashboardFailed: true,
                TeamsDetailsDashboardFetchError: action.payload,
                TeamsDetailsDashboard: [],
            };
        }

        case ACTION_TYPES.PROJECT_DETAILS_DASHBOARD_PENDING: {
            return {
                ...state,
                isFetchingProjectDetailsDashboardList: true,
                isFetchingProjectDetailsDashboardFailed: false,
                ProjectDetailsDashboardFetchError: undefined,
                ProjectDetailsDashboard: [],
            };
        }
        case ACTION_TYPES.PROJECT_DETAILS_DASHBOARD_FULFILLED: {
            return {
                ...state,
                isFetchingProjectDetailsDashboardList: false,
                isFetchingProjectDetailsDashboardFailed: false,
                ProjectDetailsDashboardFetchError: undefined,
                ProjectDetailsDashboard: action.payload.data,
            };
        }
        case ACTION_TYPES.PROJECT_DETAILS_DASHBOARD_REJECTED: {
            return {
                ...state,
                isFetchingProjectDetailsDashboardList: false,
                isFetchingProjectDetailsDashboardFailed: true,
                ProjectDetailsDashboardFetchError: action.payload,
                ProjectDetailsDashboard: [],
            };
        }

        case ACTION_TYPES.GET_ROLES_CHECK_PENDING: {
            return {
                ...state,
                isFetchingRolesCheckList: true,
                isFetchingRolesCheckFailed: false,
                RolesCheckFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_ROLES_CHECK_FULFILLED: {
            return {
                ...state,
                isFetchingRolesCheckList: false,
                isFetchingRolesCheckFailed: false,
                RolesCheckFetchError: undefined,
                RolesCheck: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_ROLES_CHECK_REJECTED: {
            return {
                ...state,
                isFetchingRolesCheckList: false,
                isFetchingRolesCheckFailed: true,
                RolesCheckFetchError: action.payload,
                RolesCheck: [],
            };
        }

        case ACTION_TYPES.GET_EMP_LIST_OBSERVER_PENDING: {
            return {
                ...state,
                isFetchingEmpListObserverList: true,
                isFetchingEmpListObserverFailed: false,
                EmpListObserverFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_EMP_LIST_OBSERVER_FULFILLED: {
            return {
                ...state,
                isFetchingEmpListObserverList: false,
                isFetchingEmpListObserverFailed: false,
                EmpListObserverFetchError: undefined,
                EmpListObserver: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_EMP_LIST_OBSERVER_REJECTED: {
            return {
                ...state,
                isFetchingEmpListObserverList: false,
                isFetchingEmpListObserverFailed: true,
                EmpListObserverFetchError: action.payload,
                EmpListObserver: [],
            };
        }

        case ACTION_TYPES.GET_SHOWCASE_USERS_LIST_PENDING: {
            return {
                ...state,
                isFetchingShowcaseUsersList: true,
                isFetchingShowcaseUsersListFailed: false,
                ShowcaseUsersListFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_SHOWCASE_USERS_LIST_FULFILLED: {
            return {
                ...state,
                isFetchingShowcaseUsersList: false,
                isFetchingShowcaseUsersListFailed: false,
                ShowcaseUsersListFetchError: undefined,
                showcaseUsersList: action.payload.data.data,
            };
        }

        case ACTION_TYPES.GET_SHOWCASE_USERS_LIST_REJECTED: {
            return {
                ...state,
                isFetchingShowcaseUsersList: false,
                isFetchingShowcaseUsersListFailed: true,
                ShowcaseUsersListFetchError: action.payload,
                showcaseUsersList: [],
            };
        }
        case ACTION_TYPES.GET_DASHBOARD_LAYOUT_PENDING: {
            return {
                ...state,
                isFetchingDashboardLayout: true,
                isFetchingDashboardLayoutFailed: false,
                DashboardLayoutFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_DASHBOARD_LAYOUT_FULFILLED: {
            return {
                ...state,
                isFetchingDashboardLayout: false,
                isFetchingDashboardLayoutFailed: false,
                DashboardLayoutFetchError: undefined,
                DashboardLayout: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_DASHBOARD_LAYOUT_REJECTED: {
            return {
                ...state,
                isFetchingDashboardLayout: false,
                isFetchingDashboardLayoutFailed: true,
                DashboardLayoutFetchError: action.payload,
                DashboardLayout: [],
            };
        }

        case ACTION_TYPES.UPDATE_DASHBOARD_LAYOUT_PENDING: {
            return {
                ...state,
                isUpdatingDashboardLayout: true,
                isUpdatingDashboardLayoutFailed: false,
                DashboardLayoutUpdateError: undefined,
            };
        }

        case ACTION_TYPES.UPDATE_DASHBOARD_LAYOUT_FULFILLED: {
            return {
                ...state,
                isUpdatingDashboardLayout: false,
                isUpdatingDashboardLayoutFailed: false,
                DashboardLayoutUpdateError: undefined,
                DashboardLayoutUpdate: action.payload.data,
            };
        }

        case ACTION_TYPES.UPDATE_DASHBOARD_LAYOUT_REJECTED: {
            return {
                ...state,
                isUpdatingDashboardLayout: false,
                isUpdatingDashboardLayoutFailed: true,
                DashboardLayoutUpdateError: action.payload,
                DashboardLayoutUpdate: [],
            };
        }

        case ACTION_TYPES.GET_ALL_PROJECT_TABLE_LAYOUT_PENDING: {
            return {
                ...state,
                isFetchingAllProjectTableLayout: true,
                isFetchingAllProjectTableLayoutFailed: false,
                AllProjectTableLayoutFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_ALL_PROJECT_TABLE_LAYOUT_FULFILLED: {
            return {
                ...state,
                isFetchingAllProjectTableLayout: false,
                isFetchingAllProjectTableLayoutFailed: false,
                AllProjectTableLayoutFetchError: undefined,
                AllProjectTableLayout: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_ALL_PROJECT_TABLE_LAYOUT_REJECTED: {
            return {
                ...state,
                isFetchingAllProjectTableLayout: false,
                isFetchingAllProjectTableLayoutFailed: true,
                AllProjectTableLayoutFetchError: action.payload,
                AllProjectTableLayout: [],
            };
        }

        case ACTION_TYPES.UPDATE_ALL_PROJECT_TABLE_PENDING: {
            return {
                ...state,
                isUpdatingAllProjectTableLayout: true,
                isUpdatingAllProjectTableLayoutFailed: false,
                AllProjectTableLayoutUpdateError: undefined,
            };
        }

        case ACTION_TYPES.UPDATE_ALL_PROJECT_TABLE_FULFILLED: {
            return {
                ...state,
                isUpdatingAllProjectTableLayout: false,
                isUpdatingAllProjectTableLayoutFailed: false,
                AllProjectTableLayoutUpdateError: undefined,
                AllProjectTableLayoutUpdate: action.payload.data,
            };
        }

        case ACTION_TYPES.UPDATE_ALL_PROJECT_TABLE_REJECTED: {
            return {
                ...state,
                isUpdatingAllProjectTableLayout: false,
                isUpdatingAllProjectTableLayoutFailed: true,
                AllProjectTableLayoutUpdateError: action.payload,
                AllProjectTableLayoutUpdate: [],
            };
        }

        case ACTION_TYPES.GET_DASHBOARD_ARRANGEMENT_PENDING: {
            return {
                ...state,
                isFetchingDashboardArrangement: true,
                isFetchingDashboardArrangementFailed: false,
                DashboardArrangementFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_DASHBOARD_ARRANGEMENT_FULFILLED: {
            return {
                ...state,
                isFetchingDashboardArrangement: false,
                isFetchingDashboardArrangementFailed: false,
                DashboardArrangementFetchError: undefined,
                DashboardArrangement: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_DASHBOARD_ARRANGEMENT_REJECTED: {
            return {
                ...state,
                isFetchingDashboardArrangement: false,
                isFetchingDashboardArrangementFailed: true,
                DashboardArrangementFetchError: action.payload,
                DashboardArrangement: [],
            };
        }

        case ACTION_TYPES.UPDATE_DASHBOARD_ARRANGEMENT_PENDING: {
            return {
                ...state,
                isUpdatingDashboardArrangement: true,
                isUpdatingDashboardArrangementFailed: false,
                DashboardArrangementUpdateError: undefined,
            };
        }

        case ACTION_TYPES.UPDATE_DASHBOARD_ARRANGEMENT_FULFILLED: {
            return {
                ...state,
                isUpdatingDashboardArrangement: false,
                isUpdatingDashboardArrangementFailed: false,
                DashboardArrangementUpdateError: undefined,
                DashboardArrangementUpdate: action.payload.data,
            };
        }

        case ACTION_TYPES.UPDATE_DASHBOARD_ARRANGEMENT_REJECTED: {
            return {
                ...state,
                isUpdatingDashboardArrangement: false,
                isUpdatingDashboardArrangementFailed: true,
                DashboardArrangementUpdateError: action.payload,
                DashboardArrangementUpdate: [],
            };
        }

        case ACTION_TYPES.GET_EMPLOYEE_MOOD_TABLE_PENDING: {
            return {
                ...state,
                isFetchingMoodTable: true,
                isFetchingMoodTableFailed: false,
                MoodTableFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_EMPLOYEE_MOOD_TABLE_FULFILLED: {
            return {
                ...state,
                isFetchingMoodTable: false,
                isFetchingMoodTableFailed: false,
                MoodTableFetchError: undefined,
                MoodTable: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_EMPLOYEE_MOOD_TABLE_REJECTED: {
            return {
                ...state,
                isFetchingMoodTable: false,
                isFetchingMoodTableFailed: true,
                MoodTableFetchError: action.payload,
                MoodTable: [],
            };
        }

        case ACTION_TYPES.GET_MOOD_TABLE_LAYOUT_PENDING: {
            return {
                ...state,
                isFetchingMoodTableLayout: true,
                isFetchingMoodTableLayoutFailed: false,
                MoodTableLayoutFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_MOOD_TABLE_LAYOUT_FULFILLED: {
            return {
                ...state,
                isFetchingMoodTableLayout: false,
                isFetchingMoodTableLayoutFailed: false,
                MoodTableLayoutFetchError: undefined,
                MoodTableLayout: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_MOOD_TABLE_LAYOUT_REJECTED: {
            return {
                ...state,
                isFetchingMoodTableLayout: false,
                isFetchingMoodTableLayoutFailed: true,
                MoodTableLayoutFetchError: action.payload,
                MoodTableLayout: [],
            };
        }

        case ACTION_TYPES.GET_MOOD_TABLE_ARRANGEMENT_PENDING: {
            return {
                ...state,
                isFetchingMoodTableArrangement: true,
                isFetchingMoodTableArrangementFailed: false,
                MoodTableArrangementFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_MOOD_TABLE_ARRANGEMENT_FULFILLED: {
            return {
                ...state,
                isFetchingMoodTableArrangement: false,
                isFetchingMoodTableArrangementFailed: false,
                MoodTableArrangementFetchError: undefined,
                MoodTableArrangement: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_MOOD_TABLE_ARRANGEMENT_REJECTED: {
            return {
                ...state,
                isFetchingMoodTableArrangement: false,
                isFetchingMoodTableArrangementFailed: true,
                MoodTableArrangementFetchError: action.payload,
                MoodTableArrangement: [],
            };
        }

        case ACTION_TYPES.GET_TEAM_TABLE_LAYOUT_PENDING: {
            return {
                ...state,
                isFetchingTeamTableLayout: true,
                isFetchingTeamTableLayoutFailed: false,
                TeamTableLayoutFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_TEAM_TABLE_LAYOUT_FULFILLED: {
            return {
                ...state,
                isFetchingTeamTableLayout: false,
                isFetchingTeamTableLayoutFailed: false,
                TeamTableLayoutFetchError: undefined,
                TeamTableLayout: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_TEAM_TABLE_LAYOUT_REJECTED: {
            return {
                ...state,
                isFetchingTeamTableLayout: false,
                isFetchingTeamTableLayoutFailed: true,
                TeamTableLayoutFetchError: action.payload,
                TeamTableLayout: [],
            };
        }

        case ACTION_TYPES.GET_EMPLOYEE_TABLE_LAYOUT_PENDING: {
            return {
                ...state,
                isFetchingEmployeeTableLayout: true,
                isFetchingEmployeeTableLayoutFailed: false,
                EmployeeTableLayoutFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_EMPLOYEE_TABLE_LAYOUT_FULFILLED: {
            return {
                ...state,
                isFetchingEmployeeTableLayout: false,
                isFetchingEmployeeTableLayoutFailed: false,
                EmployeeTableLayoutFetchError: undefined,
                EmployeeTableLayout: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_EMPLOYEE_TABLE_LAYOUT_REJECTED: {
            return {
                ...state,
                isFetchingEmployeeTableLayout: false,
                isFetchingEmployeeTableLayoutFailed: true,
                EmployeeTableLayoutFetchError: action.payload,
                EmployeeTableLayout: [],
            };
        }

        case ACTION_TYPES.GET_TEAM_TABLE_ARRANGEMENT_PENDING: {
            return {
                ...state,
                isFetchingTeamTableArrangement: true,
                isFetchingTeamTableArrangementFailed: false,
                TeamTableArrangementFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_TEAM_TABLE_ARRANGEMENT_FULFILLED: {
            return {
                ...state,
                isFetchingTeamTableArrangement: false,
                isFetchingTeamTableArrangementFailed: false,
                TeamTableArrangementFetchError: undefined,
                TeamTableArrangement: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_TEAM_TABLE_ARRANGEMENT_REJECTED: {
            return {
                ...state,
                isFetchingTeamTableArrangement: false,
                isFetchingTeamTableArrangementFailed: true,
                TeamTableArrangementFetchError: action.payload,
                TeamTableArrangement: [],
            };
        }

        case ACTION_TYPES.GET_EMPLOYEE_TABLE_ARRANGEMENT_PENDING: {
            return {
                ...state,
                isFetchingEmployeeTableArrangement: true,
                isFetchingEmployeeTableArrangementFailed: false,
                EmployeeTableArrangementFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_EMPLOYEE_TABLE_ARRANGEMENT_FULFILLED: {
            return {
                ...state,
                isFetchingEmployeeTableArrangement: false,
                isFetchingEmployeeTableArrangementFailed: false,
                EmployeeTableArrangementFetchError: undefined,
                EmployeeTableArrangement: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_EMPLOYEE_TABLE_ARRANGEMENT_REJECTED: {
            return {
                ...state,
                isFetchingEmployeeTableArrangement: false,
                isFetchingEmployeeTableArrangementFailed: true,
                EmployeeTableArrangementFetchError: action.payload,
                EmployeeTableArrangement: [],
            };
        }

        case ACTION_TYPES.UPDATE_MOOD_TABLE_LAYOUT_PENDING: {
            return {
                ...state,
                isUpdatingMoodTableLayout: true,
                isUpdatingMoodTableLayoutFailed: false,
                MoodTableLayoutUpdateError: undefined,
            };
        }

        case ACTION_TYPES.UPDATE_MOOD_TABLE_LAYOUT_FULFILLED: {
            return {
                ...state,
                isUpdatingMoodTableLayout: false,
                isUpdatingMoodTableLayoutFailed: false,
                MoodTableLayoutUpdateError: undefined,
                MoodTableLayout: action.payload.data,
            };
        }

        case ACTION_TYPES.UPDATE_MOOD_TABLE_LAYOUT_REJECTED: {
            return {
                ...state,
                isUpdatingMoodTableLayout: false,
                isUpdatingMoodTableLayoutFailed: true,
                MoodTableLayoutUpdateError: action.payload,
                MoodTableLayout: [],
            };
        }

        case ACTION_TYPES.UPDATE_MOOD_TABLE_ARRANGEMENT_PENDING: {
            return {
                ...state,
                isUpdatingMoodTableArrangement: true,
                isUpdatingMoodTableArrangementFailed: false,
                MoodTableArrangementUpdateError: undefined,
            };
        }

        case ACTION_TYPES.UPDATE_MOOD_TABLE_ARRANGEMENT_FULFILLED: {
            return {
                ...state,
                isUpdatingMoodTableArrangement: false,
                isUpdatingMoodTableArrangementFailed: false,
                MoodTableArrangementUpdateError: undefined,
                MoodTableArrangement: action.payload.data,
            };
        }

        case ACTION_TYPES.UPDATE_MOOD_TABLE_ARRANGEMENT_REJECTED: {
            return {
                ...state,
                isUpdatingMoodTableArrangement: false,
                isUpdatingMoodTableArrangementFailed: true,
                MoodTableArrangementUpdateError: action.payload,
                MoodTableArrangement: [],
            };
        }

        case ACTION_TYPES.UPDATE_TEAM_TABLE_LAYOUT_PENDING: {
            return {
                ...state,
                isUpdatingTeamTableLayout: true,
                isUpdatingTeamTableLayoutFailed: false,
                TeamTableLayoutUpdateError: undefined,
            };
        }

        case ACTION_TYPES.UPDATE_TEAM_TABLE_LAYOUT_FULFILLED: {
            return {
                ...state,
                isUpdatingTeamTableLayout: false,
                isUpdatingTeamTableLayoutFailed: false,
                TeamTableLayoutUpdateError: undefined,
                TeamTableLayout: action.payload.data,
            };
        }

        case ACTION_TYPES.UPDATE_TEAM_TABLE_LAYOUT_REJECTED: {
            return {
                ...state,
                isUpdatingTeamTableLayout: false,
                isUpdatingTeamTableLayoutFailed: true,
                TeamTableLayoutUpdateError: action.payload,
                TeamTableLayout: [],
            };
        }

        case ACTION_TYPES.UPDATE_EMPLOYEE_TABLE_LAYOUT_PENDING: {
            return {
                ...state,
                isUpdatingEmployeeTableLayout: true,
                isUpdatingEmployeeTableLayoutFailed: false,
                EmployeeTableLayoutUpdateError: undefined,
            };
        }

        case ACTION_TYPES.UPDATE_EMPLOYEE_TABLE_LAYOUT_FULFILLED: {
            return {
                ...state,
                isUpdatingEmployeeTableLayout: false,
                isUpdatingEmployeeTableLayoutFailed: false,
                EmployeeTableLayoutUpdatingError: undefined,
                EmployeeTableLayout: action.payload.data,
            };
        }

        case ACTION_TYPES.UPDATE_EMPLOYEE_TABLE_LAYOUT_REJECTED: {
            return {
                ...state,
                isUpdatingEmployeeTableLayout: false,
                isUpdatingEmployeeTableLayoutFailed: true,
                EmployeeTableLayoutUpdateError: action.payload,
                EmployeeTableLayout: [],
            };
        }

        case ACTION_TYPES.UPDATE_TEAM_TABLE_ARRANGEMENT_PENDING: {
            return {
                ...state,
                isFetchingTeamTableArrangement: true,
                isFetchingTeamTableArrangementFailed: false,
                TeamTableArrangementFetchError: undefined,
            };
        }

        case ACTION_TYPES.UPDATE_TEAM_TABLE_ARRANGEMENT_FULFILLED: {
            return {
                ...state,
                isUpdatingTeamTableArrangement: false,
                isUpdatingTeamTableArrangementFailed: false,
                TeamTableArrangementUpdateError: undefined,
                TeamTableArrangement: action.payload.data,
            };
        }

        case ACTION_TYPES.UPDATE_TEAM_TABLE_ARRANGEMENT_REJECTED: {
            return {
                ...state,
                isUpdatingTeamTableArrangement: false,
                isUpdatingTeamTableArrangementFailed: true,
                TeamTableArrangementUpdateError: action.payload,
                TeamTableArrangement: [],
            };
        }

        case ACTION_TYPES.UPDATE_EMPLOYEE_TABLE_ARRANGEMENT_PENDING: {
            return {
                ...state,
                isUpdatingEmployeeTableArrangement: true,
                isUpdatingEmployeeTableArrangementFailed: false,
                EmployeeTableArrangementUpdateError: undefined,
            };
        }

        case ACTION_TYPES.UPDATE_EMPLOYEE_TABLE_ARRANGEMENT_FULFILLED: {
            return {
                ...state,
                isUpdatingEmployeeTableArrangement: false,
                isUpdatingEmployeeTableArrangementFailed: false,
                EmployeeTableArrangementUpdateError: undefined,
                EmployeeTableArrangement: action.payload.data,
            };
        }

        case ACTION_TYPES.UPDATE_EMPLOYEE_TABLE_ARRANGEMENT_REJECTED: {
            return {
                ...state,
                isUpdatingEmployeeTableArrangement: false,
                isUpdatingEmployeeTableArrangementFailed: true,
                EmployeeTableArrangementUpdateError: action.payload,
                EmployeeTableArrangement: [],
            };
        }

        case ACTION_TYPES.GET_ALL_PROJECT_TABLE_ARRANGEMENT_PENDING: {
            return {
                ...state,
                isFetchingAllProjectTableArrangement: true,
                isFetchingAllProjectTableArrangementFailed: false,
                AllProjectTableArrangementFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_ALL_PROJECT_TABLE_ARRANGEMENT_FULFILLED: {
            return {
                ...state,
                isFetchingAllProjectTableArrangement: false,
                isFetchingAllProjectTableArrangementFailed: false,
                AllProjectTableArrangementFetchError: undefined,
                AllProjectTableArrangement: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_ALL_PROJECT_TABLE_ARRANGEMENT_REJECTED: {
            return {
                ...state,
                isFetchingAllProjectTableArrangement: false,
                isFetchingAllProjectTableArrangementFailed: true,
                AllProjectTableLayoutArrangementError: action.payload,
                AllProjectTableArrangement: [],
            };
        }

        case ACTION_TYPES.UPDATE_ALL_PROJECT_TABLE_ARRANGEMENT_PENDING: {
            return {
                ...state,
                isUpdatingAllProjectTableArrangement: true,
                isUpdatingAllProjectTableArrangementFailed: false,
                AllProjectTableArrangementUpdateError: undefined,
            };
        }

        case ACTION_TYPES.UPDATE_ALL_PROJECT_TABLE_ARRANGEMENT_FULFILLED: {
            return {
                ...state,
                isUpdatingAllProjectTableArrangement: false,
                isUpdatingAllProjectTableArrangementFailed: false,
                AllProjectTableArrangementUpdateError: undefined,
                AllProjectTableArrangementUpdate: action.payload.data,
            };
        }

        case ACTION_TYPES.UPDATE_ALL_PROJECT_TABLE_ARRANGEMENT_REJECTED: {
            return {
                ...state,
                isUpdatingAllProjectTableArrangement: false,
                isUpdatingAllProjectTableArrangementFailed: true,
                AllProjectTableArrangementUpdateError: action.payload,
                AllProjectTableArrangementUpdate: [],
            };
        }
        case ACTION_TYPES.GET_MOOD_GRAPH_DATA_PENDING: {
            return {
                ...state,
                isFetchingMoodGraphData: true,
                isFetchingMoodGraphDataFailed: false,
                MoodGraphDataFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_MOOD_GRAPH_DATA_FULFILLED: {
            return {
                ...state,
                isFetchingMoodGraphData: false,
                isFetchingMoodGraphDataFailed: false,
                MoodGraphDataFetchError: undefined,
                MoodGraphData: action.payload.data.data,
            };
        }

        case ACTION_TYPES.GET_MOOD_GRAPH_DATA_REJECTED: {
            return {
                ...state,
                isFetchingMoodGraphData: false,
                isFetchingMoodGraphDataFailed: true,
                MoodGraphDataError: action.payload,
                MoodGraphData: [],
            };
        }
        case ACTION_TYPES.GET_ALL_PROJECT_TABLE_DATA_PENDING: {
            return {
                ...state,
                isFetchingAllProjectTableData: true,
                isFetchingAllProjectTableDataFailed: false,
                AllProjectTableDataFetchError: undefined,
            };
        }

        case ACTION_TYPES.GET_ALL_PROJECT_TABLE_DATA_FULFILLED: {
            return {
                ...state,
                isFetchingAllProjectTableData: false,
                isFetchingAllProjectTableDataFailed: false,
                AllProjectTableDataFetchError: undefined,
                AllProjectTableData: action.payload.data.response_data,
            };
        }

        case ACTION_TYPES.GET_ALL_PROJECT_TABLE_DATA_REJECTED: {
            return {
                ...state,
                isFetchingAllProjectTableData: false,
                isFetchingAllProjectTableDataFailed: true,
                AllProjectTableDataError: action.payload,
                AllProjectTableData: [],
            };
        }

        case ACTION_TYPES.GET_PROJECT_ACTIVITY_PENDING: {
            return {
                ...state,
                isFetchingProjectActivity: true,
                isFetchingProjectActivityFailed: false,
                isFetchingProjectActivityError: undefined,
                ProjectActivity: [],
            };
        }

        case ACTION_TYPES.GET_PROJECT_ACTIVITY_FULFILLED: {
            return {
                ...state,
                isFetchingProjectActivity: false,
                isFetchingProjectActivityFailed: false,
                isFetchingProjectActivityError: undefined,
                ProjectActivity: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_PROJECT_ACTIVITY_REJECTED: {
            return {
                ...state,
                isFetchingProjectActivity: false,
                isFetchingProjectActivityFailed: true,
                isFetchingProjectActivityError: action.payload,
                ProjectActivity: [],
            };
        }

        case ACTION_TYPES.GET_PROJECT_WORKING_PENDING: {
            return {
                ...state,
                isFetchingProjectWorking: true,
                isFetchingProjectWorkingFailed: false,
                isFetchingProjectWorkingError: undefined,
                ProjectWorking: [],
            };
        }

        case ACTION_TYPES.GET_PROJECT_WORKING_FULFILLED: {
            return {
                ...state,
                isFetchingProjectWorking: false,
                isFetchingProjectWorkingFailed: false,
                isFetchingProjectWorkingError: undefined,
                ProjectWorking: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_PROJECT_WORKING_REJECTED: {
            return {
                ...state,
                isFetchingProjectWorking: false,
                isFetchingProjectWorkingFailed: true,
                isFetchingProjectWorkingError: action.payload,
                ProjectWorking: [],
            };
        }

        case ACTION_TYPES.GET_EMPLOYEES_WORKING_IN_PROJECT_PENDING: {
            return {
                ...state,
                isFetchingProjectWorkingEmpDetails: true,
                isFetchingProjectWorkingEmpDetailsFailed: false,
                isFetchingProjectWorkingEmpDetailsError: undefined,
                ProjectWorkingEmpDetails: [],
            };
        }

        case ACTION_TYPES.GET_EMPLOYEES_WORKING_IN_PROJECT_FULFILLED: {
            return {
                ...state,
                isFetchingProjectWorkingEmpDetails: false,
                isFetchingProjectWorkingEmpDetailsFailed: false,
                isFetchingProjectWorkingEmpDetailsError: undefined,
                ProjectWorkingEmpDetails: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_EMPLOYEES_WORKING_IN_PROJECT_REJECTED: {
            return {
                ...state,
                isFetchingProjectWorkingEmpDetails: false,
                isFetchingProjectWorkingEmpDetailsFailed: true,
                isFetchingProjectWorkingEmpDetailsError: action.payload,
                ProjectWorkingEmpDetails: [],
            };
        }

        case ACTION_TYPES.GET_FEATURE_LISTING_PENDING: {
            return {
                ...state,
                isFetchingFeatureListing: true,
                isFetchingFeatureListingFailed: false,
                isFetchingFeatureListingError: undefined,
                FeatureListing: {},
            };
        }

        case ACTION_TYPES.GET_FEATURE_LISTING_FULFILLED: {
            return {
                ...state,
                isFetchingFeatureListing: false,
                isFetchingFeatureListingFailed: false,
                isFetchingFeatureListingError: undefined,
                FeatureListing: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_FEATURE_LISTING_REJECTED: {
            return {
                ...state,
                isFetchingFeatureListing: false,
                isFetchingFeatureListingFailed: true,
                isFetchingFeatureListingError: action.payload,
                FeatureListing: {},
            };
        }

        case ACTION_TYPES.GET_PROJECT_WORKING_DATA_PENDING: {
            return {
                ...state,
                isFetchingProjectWorkingData: true,
                isFetchingProjectWorkingDataFailed: false,
                isFetchingProjectWorkingDataError: undefined,
                ProjectWorkingData: {},
            };
        }

        case ACTION_TYPES.GET_PROJECT_WORKING_DATA_FULFILLED: {
            return {
                ...state,
                isFetchingProjectWorkingData: false,
                isFetchingProjectWorkingDataFailed: false,
                isFetchingProjectWorkingDataError: undefined,
                ProjectWorkingData: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_PROJECT_WORKING_DATA_REJECTED: {
            return {
                ...state,
                isFetchingProjectWorkingData: false,
                isFetchingProjectWorkingDataFailed: true,
                isFetchingProjectWorkingDataError: action.payload,
                ProjectWorkingData: {},
            };
        }

        case ACTION_TYPES.GET_TEAM_ACTIVITY_PENDING: {
            return {
                ...state,
                isFetchingTeamActivity: true,
                isFetchingTeamActivityFailed: false,
                isFetchingTeamActivityError: undefined,
                TeamActivity: [],
            };
        }

        case ACTION_TYPES.GET_TEAM_ACTIVITY_FULFILLED: {
            return {
                ...state,
                isFetchingTeamActivity: false,
                isFetchingTeamActivityFailed: false,
                isFetchingTeamActivityError: undefined,
                TeamActivity: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_TEAM_ACTIVITY_REJECTED: {
            return {
                ...state,
                isFetchingTeamActivity: false,
                isFetchingTeamActivityFailed: true,
                isFetchingTeamActivityError: action.payload,
                TeamActivity: [],
            };
        }

        case ACTION_TYPES.GET_TEAM_WORKING_PENDING: {
            return {
                ...state,
                isFetchingTeamWorking: true,
                isFetchingTeamWorkingFailed: false,
                isFetchingTeamWorkingError: undefined,
                TeamWorking: [],
            };
        }

        case ACTION_TYPES.GET_TEAM_WORKING_FULFILLED: {
            return {
                ...state,
                isFetchingTeamWorking: false,
                isFetchingTeamWorkingFailed: false,
                isFetchingTeamWorkingError: undefined,
                TeamWorking: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_TEAM_WORKING_REJECTED: {
            return {
                ...state,
                isFetchingTeamWorking: false,
                isFetchingTeamWorkingFailed: true,
                isFetchingTeamWorkingError: action.payload,
                TeamWorking: [],
            };
        }

        case ACTION_TYPES.GET_USER_PROJECT_CHANGE_DATA_PENDING: {
            return {
                ...state,
                isFetchingProjectTimeData: true,
                isFetchingProjectTimeDataFailed: false,
                isFetchingProjectTimeDataError: undefined,
                ProjectTimeData: [],
            };
        }

        case ACTION_TYPES.GET_USER_PROJECT_CHANGE_DATA_FULFILLED: {
            return {
                ...state,
                isFetchingProjectTimeData: false,
                isFetchingProjectTimeDataFailed: false,
                isFetchingProjectTimeDataError: undefined,
                ProjectTimeData: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_USER_PROJECT_CHANGE_DATA_REJECTED: {
            return {
                ...state,
                isFetchingProjectTimeData: false,
                isFetchingProjectTimeDataFailed: true,
                isFetchingProjectTimeDataError: action.payload,
                ProjectTimeData: [],
            };
        }
        case ACTION_TYPES.GET_CURRENT_STATUS_PENDING: {
            return {
                ...state,
                isFetchingCurrentStatus: true,
                isFetchingCurrentStatusFailed: false,
                isFetchingCurrentStatusError: undefined,
                CurrentStatus: [],
            };
        }

        case ACTION_TYPES.GET_CURRENT_STATUS_FULFILLED: {
            return {
                ...state,
                isFetchingCurrentStatus: false,
                isFetchingCurrentStatusFailed: false,
                isFetchingCurrentStatusError: undefined,
                CurrentStatus: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_CURRENT_STATUS_REJECTED: {
            return {
                ...state,
                isFetchingCurrentStatus: false,
                isFetchingCurrentStatusFailed: true,
                isFetchingCurrentStatusError: action.payload,
                CurrentStatus: [],
            };
        }
        case ACTION_TYPES.EMPLOYEES_DATA_PENDING: {
            return {
                ...state,
                isFetchingEmployeesData: true,
                isFetchingEmployeesDataFailed: false,
                isFetchingEmployeesDataError: undefined,
                EmployeesData: [],
            };
        }

        case ACTION_TYPES.EMPLOYEES_DATA_FULFILLED: {
            return {
                ...state,
                isFetchingEmployeesData: false,
                isFetchingEmployeesDataFailed: false,
                isFetchingEmployeesDataError: undefined,
                EmployeesData: action.payload.data,
            };
        }

        case ACTION_TYPES.EMPLOYEES_DATA_REJECTED: {
            return {
                ...state,
                isFetchingEmployeesData: false,
                isFetchingEmployeesDataFailed: true,
                isFetchingEmployeesDataError: action.payload,
                EmployeesData: [],
            };
        }
        default:
            return state;
    }
};

export default dashboard;
