import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import InputField from "../../Components/InputField";
import { CalendarIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getClientBillingData } from "../../services/teams/actions";
import { CircularProgress } from "@mui/material";

const ClientBilling = () => {
    const [selectedDate, setSelectedDate] = useState(moment(new Date()).format("YYYY-MM"));
    const [clientBillingData, setClientBillingData] = useState([]);
    const [loading, setloading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        getData(selectedDate);
    }, []);
    const getData = (date) => {
        setloading(true);
        dispatch(getClientBillingData(date.split("-")[0], date.split("-")[1])).then((res) => {
            if (res.value.status === 200) {
                setClientBillingData(res.value?.data?.data);
            }
            setloading(false);
        });
    };
    return (
        <Layout>
            <div className="w-full bg-white px-6 pt-6 rounded-md shadow-md mt-4">
                <div className="-ml-4 -mt-2 flex items-center lg-m:items-stretch lg-m:flex-col justify-between flex-wrap sm:flex-nowrap">
                    <div className="ml-4 mt-2">
                        <span className="text-[#1D1D1D] text-2xl font-medium leading-normal">
                            Client Billing
                        </span>
                    </div>
                    <div className="flex relative mt-4 cursor-pointer">
                        {/* <DatePicker
                                    className="rounded-md cursor-pointer w-full"
                                    dateFormat="MMMM dd yyyy"
                                    selected={new Date(selectedDate)}
                                />
                                <CalendarIcon className="absolute top-2 right-2 w-6"></CalendarIcon> */}
                        <InputField
                            value={selectedDate}
                            className="cursor-pointer"
                            FieldName="Select Month"
                            onInputChange={(e) => {
                                getData(e.target.value);
                                setSelectedDate(e.target.value);
                            }}
                            type="Month"
                        />
                    </div>
                </div>
                <div className="mt-4 flex flex-col overflow-y-auto bg-gray-100 rounded-lg">
                    <div className="inline-block min-w-full align-middle">
                        <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[73vh] overflow-y-auto">
                            <table className="min-w-full border-separate">
                                <thead className="bg-gray-50 sticky top-0 z-10">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                            Client Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                            Teams
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                            Hours Spend
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                            Billing Amount
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-[#6B7280] backdrop-blur backdrop-filter lg:table-cell">
                                            Preview Invoice
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {loading ? (
                                        <tr className="w-full">
                                            <td></td>
                                            <td></td>
                                            <td
                                                colSpan="5"
                                                className="w-full pt-4 flex items-center justify-center">
                                                <CircularProgress />
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    ) : (
                                        clientBillingData?.map((instance, index) => (
                                            <tr key={"clientbillinginstance" + index}>
                                                <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                    {instance.client_name}
                                                </td>
                                                <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                    {instance.teams.join(", ")}
                                                </td>
                                                <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                    {instance.duration}
                                                </td>
                                                <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-4 border-b border-gray-200">
                                                    ${instance.billing}
                                                </td>
                                                <td className="whitespace-nowrap underline py-4 pl-4 pr-3 text-[#684D94] text-tiny font-medium leading-5 cursor-pointer text-sm sm:pl-4 border-b border-gray-200">
                                                    <Link
                                                        to={`/bill/preview_invoice?client=${instance.id}&date=${selectedDate}`}>
                                                        Preview Invoice
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default ClientBilling;
