export const createLocalAuthState = (data) => {
    // Creates json string named as auth state in localStorage
    try {

        const stringifiedData = JSON.stringify(data)
        console.log(stringifiedData)
        localStorage.setItem('s7_auth_state', stringifiedData)

    } catch(err) {

        console.log('[Error] saving data in localstorage', err)

    }
}

export const getLocalAuthState = () => {
    const defaultAuth = {
        user_status: false,
        is_superuser: false,
        is_Manager:false,
    }
    try {
        const stringifiedData = localStorage.getItem('s7_auth_state')
        return(stringifiedData === null ? defaultAuth : JSON.parse(stringifiedData))

    } catch(err) {
        return defaultAuth
    }
}

export const destroyLocalAuthState = () => {
    try {

        localStorage.clear();

    } catch(err) {

        console.log('[Error] destroying data in localStorage', err)

    }
}

// export const loginToken = (first_name, last_name, password, username) => {
//     return function(dispatch){
//         handleSubmit.loginFetch(first_name, last_name, password, username)
//     .then(json => {
//     if(json.token){
//     localStorage.setItem('token',json.token)
//     dispatch({
//     type: "SET_USER",
//     payload: json
//     })
//     } else {
//     console.log(json.error)
//     }
//     })
//     }
//     }