import React, { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import Pay from "./Pay";
import PaypalLogo from "../../assets/paypallogo.svg";
//actions
import { fetchSavedcards } from "../../services/payments/actions";
import { toast } from "react-toastify";

//icons
import { CreditCardIcon } from "@heroicons/react/outline";
import AddNewCard from "./AddNewCard";

import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getPaypalPaymentRequest } from "../../services/settings/action";

const stripePromise = loadStripe("pk_live_8TSDRYUEWcKegk80c0R96LEb00IeVwJBMg");
// const stripePromise = loadStripe("pk_test_51K7d9WSAc82N6dqg0ylVe3x7AGSKKEY43TybfqNYrAbhkgaii1Y4fBr4vVdf56Ws29wSTJgnCWK1huNXspxsuGHS004hJLHcdq");

class SavedCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedCards: [],
      selectedCard: {},
      showPayModal: false,
      checkboxChecked: false,

      showAddNewCardModal: false,
      clientSecret: "",
    };
  }

  componentDidMount() {
    this.fetchSavedcards();
    this.getPaymentIntent();
  }

  fetchSavedcards = async () => {
    const email = localStorage.getItem("user_email");
    const dataa = { email: email };
    this.props.fetchSavedcards(dataa).then((res) => {
      if (res.value.status == 200) {
        this.setState({ savedCards: res.value.data });
      }
    });
  };

  getPaymentIntent = () => {
    fetch("http://localhost:8080/create_payment_intent", {
      method: "POST",
      headers: {
        authorization: "JWT " + localStorage.getItem("access_token"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: this.props.invoiceID,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ clientSecret: data.clientSecret });
      });
  };

  render() {
    const { closeSavedCards, paySuccesRedirect, invoiceID } = this.props;

    const handleClose = () => {
      closeSavedCards();
      this.setState({ selectedCard: {} });
    };

    const proceedToPay = () => {
      // if(this.state.checkboxChecked == false){
      //     toast('Please select a card to Proceed');
      //     return;
      // } else if(this.state.selectedCard == {}){
      //     toast('Please select a card to Proceed');
      //     return;
      // }
      this.setState({ showPayModal: true });
    };

    const appearence = {
      theme: "stripe",
    };

    const clientSecret = this.state.clientSecret;

    const options = {
      clientSecret,
      appearence,
    };

    return (
      <div className="bg-white rounded-md shadow-md p-5 mt-4">
        <div>
          <div className="flex justify-between items-center">
            <p className="text-2xl font-semibold">Saved Cards</p>
            <div className="flex gap-2 xl:gap-4 items-center">
              <button
                onClick={() => {
                  this.props
                    .getPaypalPaymentRequest(this.props.invoiceID)
                    .then((res) => {
                      if (res.value.status === 200) {
                        window.open(res.value.data.url, "_blank");
                      }
                    });
                }}
                className="w-24 border rounded-md border-primary bg-yellow-100 py-1 px-4"
              >
                <img src={PaypalLogo} alt="paypal button" />
              </button>
              <div
                className="bg-secondary py-1 px-3 flex items-center rounded-md transition duration-150 ease-in-out hover:-translate-y-1 cursor-pointer shadow-sm hover:shadow-md"
                onClick={() => {
                  this.setState({ showAddNewCardModal: true });
                }}
              >
                <CreditCardIcon className="h-5 w-5 text-white mr-3" />{" "}
                <p className="font-medium text-white">Add New card</p>
              </div>
            </div>
          </div>
          <div className="h-1 bg-gray-200 rounded-full w-full my-4"></div>
          {this.state.savedCards == "" ? (
            <p>No Saved Cards Found</p>
          ) : (
            this.state.savedCards.map((card, idx) => (
              <div key={idx} className="flex items-center mt-4">
                <input
                  type="checkbox"
                  checked={this.state.checkboxChecked === idx}
                  value={card.last}
                  className="mr-2 rounded-md text-primary"
                  onChange={() => {
                    this.setState({ checkboxChecked: idx });
                    setTimeout(() => {
                      this.state.checkboxChecked == idx
                        ? this.setState({ selectedCard: card })
                        : this.setState({ selectedCard: {} });
                    }, 100);
                  }}
                />
                <p className="text-md font-medium">
                  A/c No. **** **** **** {card.last}
                </p>
              </div>
            ))
          )}
          <div className="flex items-center mt-5">
            <button
              className="bg-primary text-white rounded-md p-1 px-6 shadow-sm transition duration-150 ease-in-out hover:-translate-y-1 hover:shadow-md mr-3"
              onClick={() => {
                proceedToPay();
              }}
            >
              Procced to Payment
            </button>
            <button
              className="border border-gray-500 rounded-md p-1 px-3 shadow-sm transition duration-150 ease-in-out hover:-translate-y-1 hover:shadow-md"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </button>
          </div>
        </div>

        {this.state.showPayModal
          ? clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <Pay
                  open={this.state.showPayModal}
                  onCancelClick={() => {
                    this.setState({ showPayModal: false });
                  }}
                  cardDetails={this.state.selectedCard}
                  paySuccesRedirect={paySuccesRedirect}
                  invoiceID={invoiceID}
                  clientSecret={clientSecret}
                />
              </Elements>
            )
          : null}
        {this.state.showAddNewCardModal ? (
          <Elements stripe={stripePromise}>
            <AddNewCard
              open={this.state.showAddNewCardModal}
              onCancelClick={() => {
                this.setState({ showAddNewCardModal: false });
              }}
            />
          </Elements>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchSavedcards: (email) => dispatch(fetchSavedcards(email)),
  getPaypalPaymentRequest: (invoiceID) =>
    dispatch(getPaypalPaymentRequest(invoiceID)),
});

SavedCards.propTypes = {
  closeSavedCards: PropTypes.func,
  paySuccesRedirect: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedCards);
