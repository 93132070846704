import { ACTION_TYPES } from "../../../constants";

const report = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_EMPLOYEES_ATTENDANCE_PENDING: {
            return {
                ...state,
                isFetchingEmployeesAttendance: true,
                isFetchingEmployeesAttendanceFailed: false,
                EmployeesAttendanceFetchError: undefined,
                EmployeesAttendance: [],
            };
        }

        case ACTION_TYPES.FETCH_EMPLOYEES_ATTENDANCE_FULFILLED: {
            return {
                ...state,
                isFetchingEmployeesAttendance: false,
                isFetchingEmployeesAttendanceFailed: false,
                EmployeesAttendanceFetchError: undefined,
                EmployeesAttendance: action.payload.data,
            };
        }

        case ACTION_TYPES.FETCH_EMPLOYEES_ATTENDANCE_REJECTED: {
            return {
                ...state,
                isFetchingEmployeesAttendance: false,
                isFetchingEmployeesAttendanceFailed: true,
                EmployeesAttendanceFetchError: action.payload,
                EmployeesAttendance: [],
            };
        }

        //employee application report
        case ACTION_TYPES.FETCH_EMPLOYEES_APPLICATION_REPORT_PENDING: {
            return {
                ...state,
                isFetchingEmployeesApplicationReport: true,
                isFetchingEmployeesApplicationReportFailed: false,
                EmployeesApplicationReportFetchError: undefined,
                EmployeesApplicationReport: [],
            };
        }

        case ACTION_TYPES.FETCH_EMPLOYEES_APPLICATION_REPORT_FULFILLED: {
            return {
                ...state,
                isFetchingEmployeesApplicationReport: false,
                isFetchingEmployeesApplicationReportFailed: false,
                EmployeesApplicationReportFetchError: undefined,
                EmployeesApplicationReport: action.payload.data,
            };
        }

        case ACTION_TYPES.FETCH_EMPLOYEES_APPLICATION_REPORT_REJECTED: {
            return {
                ...state,
                isFetchingEmployeesApplicationReport: false,
                isFetchingEmployeesApplicationReportFailed: true,
                EmployeesApplicationReportFetchError: action.payload,
                EmployeesApplicationReport: [],
            };
        }

        //employe website report
        case ACTION_TYPES.FETCH_EMPLOYEE_WEBSITE_REPORT_PENDING: {
            return {
                ...state,
                isFetchingEmployeeWebsiteReport: true,
                isFetchingEmployeeWebsiteReportFailed: false,
                EmployeeWebsiteReportFetchError: undefined,
                EmployeeWebsiteReport: [],
            };
        }

        case ACTION_TYPES.FETCH_EMPLOYEE_WEBSITE_REPORT_FULFILLED: {
            return {
                ...state,
                isFetchingEmployeeWebsiteReport: false,
                isFetchingEmployeeWebsiteReportFailed: false,
                EmployeeWebsiteReportFetchError: undefined,
                EmployeeWebsiteReport: action.payload.data,
            };
        }

        case ACTION_TYPES.FETCH_EMPLOYEE_WEBSITE_REPORT_REJECTED: {
            return {
                ...state,
                isFetchingEmployeeWebsiteReport: false,
                isFetchingEmployeeWebsiteReportFailed: true,
                EmployeeWebsiteReportFetchError: action.payload,
                EmployeeWebsiteReport: [],
            };
        }

        //hour report quick
        case ACTION_TYPES.FETCH_HOUR_REPORT_QUICK_PENDING: {
            return {
                ...state,
                isFetchingHourQuickReport: true,
                isFetchingHourQuickReportFailed: false,
                HourQuickReportFetchError: undefined,
                HourQuickReport: [],
            };
        }

        case ACTION_TYPES.FETCH_HOUR_REPORT_QUICK_FULFILLED: {
            return {
                ...state,
                isFetchingHourQuickReport: false,
                isFetchingHourQuickReportFailed: false,
                HourQuickReportFetchError: undefined,
                HourQuickReport: action.payload.data,
            };
        }

        case ACTION_TYPES.FETCH_HOUR_REPORT_QUICK_REJECTED: {
            return {
                ...state,
                isFetchingHourQuickReport: false,
                isFetchingHourQuickReportFailed: true,
                HourQuickReportFetchError: action.payload,
                HourQuickReport: [],
            };
        }

        //hour report custom

        case ACTION_TYPES.FETCH_HOUR_REPORT_CUSTOM_PENDING: {
            return {
                ...state,
                isFetchingHourCustomReport: true,
                isFetchingHourCustomReportFailed: false,
                HourCustomReportFetchError: undefined,
                HourCustomReport: [],
            };
        }

        case ACTION_TYPES.FETCH_HOUR_REPORT_CUSTOM_FULFILLED: {
            return {
                ...state,
                isFetchingHourCustomReport: false,
                isFetchingHourCustomReportFailed: false,
                HourCustomReportFetchError: undefined,
                HourCustomReport: action.payload.data,
            };
        }

        case ACTION_TYPES.FETCH_HOUR_REPORT_CUSTOM_REJECTED: {
            return {
                ...state,
                isFetchingHourCustomReport: false,
                isFetchingHourCustomReportFailed: true,
                HourCustomReportFetchError: action.payload,
                HourCustomReport: [],
            };
        }

        //download hour report

        case ACTION_TYPES.DOWNLOAD_HOUR_REPORT_PENDING: {
            return {
                ...state,
                isDownloadingHourReport: true,
                isDownloadingHourReportFailed: false,
                HourReportDownloadError: undefined,
                HourReportDownload: [],
            };
        }

        case ACTION_TYPES.DOWNLOAD_HOUR_REPORT_FULFILLED: {
            return {
                ...state,
                isDownloadingHourReport: false,
                isDownloadingHourReportFailed: false,
                HourReportDownloadError: undefined,
                HourReportDownload: action.payload.data,
            };
        }

        case ACTION_TYPES.DOWNLOAD_HOUR_REPORT_REJECTED: {
            return {
                ...state,
                isDownloadingHourReport: false,
                isDownloadingHourReportFailed: true,
                HourReportDownloadError: action.payload,
                HourReportDownload: [],
            };
        }

        //attendance timing

        case ACTION_TYPES.ATTENDANCE_TIMING_DETAILS_PENDING: {
            return {
                ...state,
                isFetchingAttendanceTiming: true,
                isFetchingAttendanceTimingFailed: false,
                AttendanceTimingFetchError: undefined,
                AttendanceTiming: [],
            };
        }

        case ACTION_TYPES.ATTENDANCE_TIMING_DETAILS_FULFILLED: {
            return {
                ...state,
                isFetchingAttendanceTiming: false,
                isFetchingAttendanceTimingFailed: false,
                AttendanceTimingFetchError: undefined,
                AttendanceTiming: action.payload.data,
            };
        }

        case ACTION_TYPES.ATTENDANCE_TIMING_DETAILS_REJECTED: {
            return {
                ...state,
                isFetchingAttendanceTiming: false,
                isFetchingAttendanceTimingFailed: true,
                AttendanceTimingFetchError: action.payload,
                AttendanceTiming: [],
            };
        }

        //update attendane timing

        case ACTION_TYPES.UPDATE_ATTENDANCE_TIMING_DETAILS: {
            return {
                ...state,
                isUpdatingAttendanceTiming: true,
                isUpdatingAttendanceTimingFailed: false,
                AttendanceTimingUpdateError: undefined,
                AttendanceTiming: [],
            };
        }

        case ACTION_TYPES.UPDATE_ATTENDANCE_TIMING_DETAILS_FULFILLED: {
            return {
                ...state,
                isUpdatingAttendanceTiming: false,
                isUpdatingAttendanceTimingFailed: false,
                AttendanceTimingUpdateError: undefined,
                AttendanceTiming: action.payload.data,
            };
        }

        case ACTION_TYPES.UPDATE_ATTENDANCE_TIMING_DETAILS_REJECTED: {
            return {
                ...state,
                isUpdatingAttendanceTiming: false,
                isUpdatingAttendanceTimingFailed: true,
                AttendanceTimingUpdateError: action.payload,
                AttendanceTiming: [],
            };
        }

        case ACTION_TYPES.GET_BOOKING_REPORT_TABLE_LAYOUT_PENDING: {
            return {
                ...state,
                isFetchingBookingReportTableLayout: true,
                isFetchingBookingReportTableLayoutFailed: false,
                BookingReportTableLayoutFetchError: undefined,
                BookingReportTableLayout: [],
            };
        }

        case ACTION_TYPES.GET_BOOKING_REPORT_TABLE_LAYOUT_FULFILLED: {
            return {
                ...state,
                isFetchingBookingReportTableLayout: false,
                isFetchingBookingReportTableLayoutFailed: false,
                BookingReportTableLayoutFetchError: undefined,
                BookingReportTableLayout: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_BOOKING_REPORT_TABLE_LAYOUT_REJECTED: {
            return {
                ...state,
                isFetchingBookingReportTableLayout: false,
                isFetchingBookingReportTableLayoutFailed: true,
                BookingReportTableLayoutFetchError: action.payload,
                BookingReportTableLayout: [],
            };
        }

        case ACTION_TYPES.UPDATE_BOOKING_REPORT_TABLE_LAYOUT_PENDING: {
            return {
                ...state,
                isUpdatingBookingReportTableLayout: true,
                isUpdatingBookingReportTableLayoutFailed: false,
                BookingReportTableLayoutUpdateError: undefined,
                BookingReportTableLayout: [],
            };
        }

        case ACTION_TYPES.UPDATE_BOOKING_REPORT_TABLE_LAYOUT_FULFILLED: {
            return {
                ...state,
                isUpdatingBookingReportTableLayout: false,
                isUpdatingBookingReportTableLayoutFailed: false,
                BookingReportTableLayoutUpdateError: undefined,
                BookingReportTableLayout: action.payload.data,
            };
        }

        case ACTION_TYPES.UPDATE_BOOKING_REPORT_TABLE_LAYOUT_REJECTED: {
            return {
                ...state,
                isUpdatingBookingReportTableLayout: false,
                isUpdatingBookingReportTableLayoutFailed: true,
                BookingReportTableLayoutUpdateError: action.payload,
                BookingReportTableLayout: [],
            };
        }

        case ACTION_TYPES.GET_BOOKING_REPORT_TABLE_PENDING: {
            return {
                ...state,
                isFetchingBookingReportTable: true,
                isFetchingBookingReportTableFailed: false,
                BookingReportTableFetchError: undefined,
                BookingReportTable: [],
            };
        }

        case ACTION_TYPES.GET_BOOKING_REPORT_TABLE_FULFILLED: {
            return {
                ...state,
                isFetchingBookingReportTable: false,
                isFetchingBookingReportTableFailed: false,
                BookingReportTableFetchError: undefined,
                BookingReportTable: action.payload.data.data,
            };
        }

        case ACTION_TYPES.GET_BOOKING_REPORT_TABLE_REJECTED: {
            return {
                ...state,
                isFetchingBookingReportTable: false,
                isFetchingBookingReportTableFailed: true,
                BookingReportTableFetchError: action.payload,
                BookingReportTable: [],
            };
        }

        case ACTION_TYPES.DOWNLOAD_BOOKING_REPORT_TABLE_PENDING: {
            return {
                ...state,
                isDownloadingBookingReportTable: true,
                isDownloadingBookingReportTableFailed: false,
                BookingReportTableDownloadError: undefined,
                BookingReportTableDownload: [],
            };
        }

        case ACTION_TYPES.DOWNLOAD_BOOKING_REPORT_TABLE_FULFILLED: {
            return {
                ...state,
                isDownloadingBookingReportTable: false,
                isDownloadingBookingReportTableFailed: false,
                BookingReportTableDownloadError: undefined,
                BookingReportTableDownload: action.payload.data,
            };
        }

        case ACTION_TYPES.DOWNLOAD_BOOKING_REPORT_TABLE_REJECTED: {
            return {
                ...state,
                isDownloadingBookingReportTable: false,
                isDownloadingBookingReportTableFailed: true,
                BookingReportTableDownloadError: action.payload,
                BookingReportTableDownload: [],
            };
        }

        case ACTION_TYPES.GET_BOOKING_REPORT_TABLE_ARRANGEMENT_PENDING: {
            return {
                ...state,
                isFetchingBookingReportTableArrangement: true,
                isFetchingBookingReportTableArrangementFailed: false,
                BookingReportTableArrangementFetchError: undefined,
                BookingReportTableArrangement: [],
            };
        }

        case ACTION_TYPES.GET_BOOKING_REPORT_TABLE_ARRANGEMENT_FULFILLED: {
            return {
                ...state,
                isFetchingBookingReportTableArrangement: false,
                isFetchingBookingReportTableArrangementFailed: false,
                BookingReportTableArrangementFetchError: undefined,
                BookingReportTableArrangement: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_BOOKING_REPORT_TABLE_ARRANGEMENT_REJECTED: {
            return {
                ...state,
                isFetchingBookingReportTableArrangement: false,
                isFetchingBookingReportTableArrangementFailed: true,
                BookingReportTableArrangementFetchError: action.payload,
                BookingReportTableArrangement: [],
            };
        }

        case ACTION_TYPES.UPDATE_BOOKING_REPORT_ARRANGEMENT_PENDING: {
            return {
                ...state,
                isUpdatingBookingReportTableArrangement: true,
                isUpdatingBookingReportTableArrangementFailed: false,
                BookingReportTableArrangementUpdateError: undefined,
                BookingReportTableArrangement: [],
            };
        }

        case ACTION_TYPES.UPDATE_BOOKING_REPORT_ARRANGEMENT_FULFILLED: {
            return {
                ...state,
                isUpdatingBookingReportTableArrangement: false,
                isUpdatingBookingReportTableArrangementFailed: false,
                BookingReportTableArrangementUpdateError: undefined,
                BookingReportTableArrangement: action.payload.data,
            };
        }

        case ACTION_TYPES.UPDATE_BOOKING_REPORT_ARRANGEMENT_REJECTED: {
            return {
                ...state,
                isUpdatingBookingReportTableArrangement: false,
                isUpdatingBookingReportTableArrangementFailed: true,
                BookingReportTableArrangementUpdateError: action.payload,
                BookingReportTableArrangement: [],
            };
        }

        case ACTION_TYPES.GET_TOTAL_HOUR_REPORT_PENDING: {
            return {
                ...state,
                isFetchingTotalHourReport: true,
                isFetchingTotalHourReportFailed: false,
                isFetchingTotalHourReportError: undefined,
                TotalHourReport: [],
            };
        }

        case ACTION_TYPES.GET_TOTAL_HOUR_REPORT_FULFILLED: {
            return {
                ...state,
                isFetchingTotalHourReport: false,
                isFetchingTotalHourReportFailed: false,
                isFetchingTotalHourReportError: undefined,
                TotalHourReport: action.payload.data,
            };
        }

        case ACTION_TYPES.GET_TOTAL_HOUR_REPORT_REJECTED: {
            return {
                ...state,
                isFetchingTotalHourReport: false,
                isFetchingTotalHourReportFailed: true,
                isFetchingTotalHourReportError: action.payload,
                TotalHourReport: [],
            };
        }

        default:
            return state;
    }
}

export default report;