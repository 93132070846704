/* eslint-disable react/jsx-pascal-case */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Dialog, Menu, Transition } from "@headlessui/react";
import Layout from "../../Components/Layout";
import SelectField from "../../Components/SelectField";
import ViewAllUrls_modal from "./ViewAllUrls_modal";
import UpdateURLtype_modal from "./UpdateURLtype_modal";
import FilterTable_model from "./filterTable_model";

//actions
import { fetchallEmployees } from "../../services/projects/actions";
import {
  fetchActivityIndex,
  fetchAllUserURLs,
} from "../../services/activityMonitor/actions";
import {
  fetchEmployeeAttendance,
  fetchEmployeeWebsiteReport,
} from "../../services/reports/actions";

import { getEmpListObserver } from "../../services/dashboard/actions";
//dependencies
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactHighcharts from "react-highcharts";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import BeatLoader from "react-spinners/BeatLoader";

import { withTranslation } from "react-i18next";

//icons
import {
  LinkIcon,
  PencilIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import {
  CalendarIcon,
  DownloadIcon,
  SelectorIcon,
  UserIcon,
} from "@heroicons/react/outline";

const VerticalDivier = () => (
  <div className={`h-10 w-0.5 bg-white hidden lg:block`}> </div>
);

const ProgressBar = ({ data }) => (
  <div className="w-full bg-gray-200 h-3 rounded-full">
    <div
      className="bg-primary h-3 rounded-full"
      style={{ width: data + "%" }}
    ></div>
  </div>
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class ActivityMoniter extends Component {
  constructor() {
    super();
    this.state = {
      showUserURL: false,
      selectedTimezone: "",
      startDate: new Date(),
      endDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
      today: new Date(),
      selectedEmp: "",
      showbanner: true,
      showTrends: false,
      showAllUrlsModal: false,
      showUpdateURlTypeModal: false,
      selectedURL: "",
      showDownload: false,
      col1: true,
      col2: true,
      col3: true,
      col4: true,
      col5: true,
      col6: true,
      showFilterTable: false,
      filteredDataTable: [],
      empName: "",
      graphData: [],
      startDategraph: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - new Date().getDay()
      ),
      endDategraph: new Date(new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - new Date().getDay()
      ).getTime() + 7 * 24 * 60 * 60 * 1000),
      selectedDate: new Date(),
      productivitygraphloading: true,
      selectedEmployee: "",
    };
  }
  componentDidMount() {
    const url = window.location.pathname;
    setTimeout(() => {
      this.setState({ urlpath: url });
    }, 200);
    const query = new URLSearchParams(this.props.location.search);
    const emp = query.get("employee");
    const date = query.get("date");
    const timezone = query.get("timezone");

    setTimeout(() => {
      this.setState({
        empName: emp == undefined || emp == "" || emp == null ? "" : emp,
        startDate:
          date == undefined || date == "" || date == null
            ? new Date()
            : new Date(date),
        selectedTimezone:
          timezone == undefined || timezone == ""
            ? timezone == null
              ? ""
              : timezone
            : this.state.selectedTimezone,
      });

      window.history.pushState(
        {},
        null,
        `/activity-moniter/?employee=${this.state.empName}&date=${moment(
          this.state.startDate
        ).format("YYYY-MM-DD")}&timezone=${this.state.selectedTimezone}`
      );
    }, 100);

    setTimeout(() => {
      this.props.fetchallEmployees();
      if (this.state.empName != "") {
        this.fetchActivityIndex(
          moment(this.state.startDate).format("YYYY-MM-DD")
        );
        setTimeout(() => {
          this.selectedEmpparam();
        }, 1000);
      } else {
        this.fetchActivityIndex(
          moment(this.state.startDate).format("YYYY-MM-DD")
        );
      }
    }, 200);
    let startdate = new Date(
      this.state.startDategraph.getFullYear(),
      this.state.startDategraph.getMonth(),
      1
    );
    let endDate = new Date(startdate.getTime() + 7 * 24 * 60 * 60 * 1000);
    if (this.state.selectedEmployee) {
      this.props
        .fetchEmployeeWebsiteReport(
          moment(startdate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD"),
          this.state.selectedEmployee
        )
        .then((data) => {
          this.setState({
            graphData: data.value.data.data,
            productivitygraphloading: false,
          });
        });
    } else {
      this.setState({
        productivitygraphloading: false,
      });
    }
  }

  convertMintoHr(minutes) {
    let hours = minutes / 60;
    let minutesLeft = minutes % 60;
    return `${Math.trunc(hours)}hr : ${minutesLeft}min`;
  }

  fetchActivityIndex = (date) => {
    this.props.fetchActivityIndex(date).then((value) => {
      this.setState({
        filteredDataTable: value.value.data,
      });
    });
  };

  render() {
    const { t } = this.props;
    let empList = this.props.allEmployees;
    let listofAllEmployees = empList && empList.AllEmployees;

    let actIndex = this.props.activityIndex;
    let listofActivityIndex = actIndex && actIndex.ActivityIndex;

    let isFetchingUserUrls = actIndex && actIndex.isFetchingUserURLs;
    let errorFetchingActivityIndex =
      (actIndex && actIndex.isFetchingUserURLs) ||
      (actIndex && actIndex.isFetchingUserURLsFailed);

    let listofUserURLs = actIndex && actIndex.UserURLs;

    let Select_emp_dropdown =
      listofAllEmployees &&
      listofAllEmployees.map((el) => ({
        name: el.user_login_as,
        // id: el.user_id
      }));

    let listofAllEmployeesgraph =
      listofAllEmployees &&
      listofAllEmployees.map((emp, index) => ({
        name: emp.user_first_name + " " + emp.user_last_name,
        id: emp.user_id,
      }));

    let employees =
      localStorage.getItem("roleUser") === "admin"
        ? listofAllEmployees
        : this.props.dashboard && this.props.dashboard.EmpListObserver;

    const ProjectLink = (data) => {
      localStorage.setItem("dgfsdgf", JSON.stringify(data));
      localStorage.setItem(
        "selected Date",
        JSON.stringify(this.state.selectedDate)
      );

      let employeeSelected = employees.find(
        (emp) => emp.user_login_as == data.username
      );
      let props1 = {
        selectedEmployee: employeeSelected.user_id,
        //   selectedProject: data.project,
        selectedDate: this.state.selectedDate,
        prevPath: window.location.pathname,
      };
      // this.props.selectedEmployee(props1);
      this.props.history.push({
        pathname: "/timetracker/activitylog/",
        state: { from: window.location.pathname, data: props1 },
      });
    };

    this.selectedEmpparam = () => {
      let empActivity = listofActivityIndex.find(
        (emp) => emp.username === this.state.empName
      );
      let emp = empList.AllEmployees.find(
        (emp) => emp.user_login_as === this.state.empName
      );
      let uu_id = emp.user_id;
      this.props.fetchAllUserURLs(
        moment(this.state.startDate).format("YYYY-MM-DD"),
        uu_id
      );
      this.setState({
        selectedEmp: empActivity,
        showTrends: true,
        filteredDataTable: [empActivity],
        empName: this.state.empName,
      });
    };

    this.redirectToUserURLs = (username) => {
      let emp = empList.AllEmployees.find(
        (emp) => emp.user_login_as === username
      );
      let uu_id = emp.user_id;
      let empActivity = listofActivityIndex.find(
        (emp) => emp.username === username
      );
      this.props.fetchAllUserURLs(
        moment(this.state.startDate).format("YYYY-MM-DD"),
        uu_id
      );
      this.setState({
        showUserURL: true,
        selectedEmp: empActivity,
      });
    };

    this.onSelectEmployee = (e) => {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          let empActivity = listofActivityIndex.find(
            (emp) => emp.username === e.target.value
          );
          if (e.target.value == "") {
            window.history.pushState(
              {},
              null,
              `/activity-moniter/?employee=${e.target.value}&date=${moment(
                this.state.startDate
              ).format("YYYY-MM-DD")}&timezone=${this.state.selectedTimezone}`
            );
            this.setState({
              filteredDataTable: listofActivityIndex,
              showTrends: false,
              selectedEmp: "",
              listofUserURLs: "",
              empName: "",
            });
          } else {
            window.history.pushState(
              {},
              null,
              `/activity-moniter/?employee=${e.target.value}&date=${moment(
                this.state.startDate
              ).format("YYYY-MM-DD")}&timezone=${this.state.selectedTimezone}`
            );
            let emp = empList.AllEmployees.find(
              (emp) => emp.user_login_as === e.target.value
            );
            let uu_id = emp.user_id;

            this.props.fetchAllUserURLs(
              moment(this.state.startDate).format("YYYY-MM-DD"),
              uu_id
            );
            this.setState({
              selectedEmp: empActivity,
              showTrends: empActivity ? true : false,
              filteredDataTable: empActivity ? [empActivity] : empActivity,
              empName: e.target.value,
            });
          }
        }
      );
    };

    this.handleSelectEmployeeProductivitygraph = (e) => {
      this.setState({ selectedEmployee: e.target.value }, () => {
        if (this.state.selectedEmployee != "") {
          this.setState({ productivitygraphloading: true });
          let startdate = moment(this.state.startDategraph).format(
            "YYYY-MM-DD"
          );
          let endDate = moment(this.state.endDategraph).format("YYYY-MM-DD");
          this.props
            .fetchEmployeeWebsiteReport(startdate, endDate, e.target.value)
            .then((data) => {
              this.setState({
                graphData: data.value.data.data,
                productivitygraphloading: false,
                selectedEmployee: e.target.value,
              });
            });
        } else {
          this.setState({ graphData: [] });
        }
      });
    };

    const exportPDF = () => {
      const unit = "pt";
      const size = "A4";
      const orientation = "portrait";
      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);
      const title = "Employees Report";
      const date = moment(this.state.startDate).format("dddd, MMMM Do YYYY");
      const headers = [
        [
          "Name",
          "Total Time",
          "Productive",
          "% Productive",
          "Unproductive",
          "% Unproductive",
        ],
      ];
      const data =
        this.state.filteredDataTable &&
        this.state.filteredDataTable.map((el) => [
          el.username,
          this.convertMintoHr(el.onlinetime),
          this.convertMintoHr(el.productiontime),
          el.productiveindex.toFixed(),
          this.convertMintoHr(el.onlinetime - el.productiontime),
          el.unproductiveindex.toFixed(),
        ]);
      let content = {
        startY: 80,
        head: headers,
        body: data,
        theme: "grid",
      };
      doc.text(title, marginLeft, 40);
      doc.setFontSize(10);
      doc.setTextColor(105, 105, 105);
      doc.text(date, marginLeft, 55);
      doc.autoTable(content);
      doc.save(
        `${moment(this.state.startDate).format("YYYY-MM-DD")}-Employee-Report` +
        ".pdf"
      );
    };

    const userSpecificReportPDF = () => {
      const dataa = [];
      const urldataa = [];
      let emp = this.state.selectedEmp;
      const urldata = listofUserURLs.map((el) => [
        el.title.substring(0, 60),
        el.url.substring(0, 60),
        el.duration,
      ]);
      dataa.push([
        emp.username,
        this.convertMintoHr(emp.idletime),
        this.convertMintoHr(emp.onlinetime),
        this.convertMintoHr(emp.productiontime),
        emp.productiveindex,
        emp.unproductiveindex,
      ]);
      urldataa.push();
      const unit = "pt";
      const size = "A4";
      const orientation = "portrait";
      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);
      const title = "Employees Report";
      const headers = [
        [
          "Name",
          "Idle Time",
          "Total Time",
          "Productive",
          "% Productive",
          "% Unproductive",
        ],
      ];
      const headersurls = [["Title", "URL", "Duration"]];
      const data = dataa;
      let content = {
        startY: 50,
        head: headers,
        body: data,
        theme: "grid",
      };
      let content1 = {
        startY: 110,
        head: headersurls,
        body: urldata,
        theme: "grid",
      };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.autoTable(content1);
      doc.save("EmployeeReport.pdf");
    };

    const CSVheaders = [
      { label: "Name", key: "username" },
      { label: "Total Time", key: "onlinetime" },
      { label: "Productive", key: "productiontime" },
      { label: "% Productive", key: "productiveindex" },
      { label: "Unproductive", key: "idletime" },
      { label: "% Unproductive", key: "unproductiveindex" },
    ];

    const CSVReport = {
      data: this.state.filteredDataTable && this.state.filteredDataTable,
      headers: CSVheaders,
      filename:
        `${moment(this.state.startDate).format("YYYY-MM-DD")}-Employee-Report` +
        ".csv",
    };

    const exportCSV = () => {
      const link = document.getElementById("csv");
      document.body.appendChild(link);
      link.click();
    };

    const userSpecificCSVHeaders = [
      { label: "Name", key: "username" },
      { label: "Total Time", key: "onlinetime" },
      { label: "Productive", key: "productiontime" },
      { label: "% Productive", key: "productiveindex" },
      { label: "Unproductive", key: "idletime" },
      { label: "% Unproductive", key: "unproductiveindex" },
      { label: "Title", key: "title" },
      { label: "URL", key: "url" },
      { label: "Duration", key: "duration" },
    ];

    const dataUsrCSV = [];

    // let emp = this.state.selectedEmp;
    // dataUsrCSV.push([emp.username, this.convertMintoHr(emp.idletime), this.convertMintoHr(emp.onlinetime), this.convertMintoHr(emp.productiontime), emp.productiveindex, (emp.unproductiveindex)]);

    const activityData = listofActivityIndex;
    const urlData =
      listofUserURLs == undefined
        ? []
        : listofUserURLs.map((el) => [
          el.title.substring(0, 60),
          el.url.substring(0, 60),
          el.duration,
        ]);

    // const d = dataUsrCSV.push(urlData);

    const userSpecificCSVReport = {
      data: dataUsrCSV,
      headers: userSpecificCSVHeaders,
      filename: "EmployeeReport.csv",
    };

    const exportUserSpecificCSV = () => {
      const link = document.getElementById("csvuser");
      document.body.appendChild(link);
      link.click();
    };

    const stackedChart = {
      chart: {
        type: "column",
        marginLeft: 150,
        height: 150,
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        visible: false,
        categories: ["App Usage"],
        tickLength: 0,
        gridLineColor: "#ffffff",
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        min: 0,
        gridLineColor: "#ffffff",
        labels: {
          enabled: false,
        },
        title: {
          text: "",
        },
        stackLabels: {
          enabled: false,
        },
      },
      legend: {
        align: "left",
        x: 10,
        layout: "vertical",
        verticalAlign: "middle",
        padding: 15,
        itemMarginTop: 2,
        itemMarginBottom: 4,
        itemStyle: {
          lineHeight: "8px",
          fontWeight: "500",
        },

        y: 25,
        floating: true,
        shadow: false,
      },
      tooltip: {
        headerFormat: "<b>{point.x}</b><br/>",
      },
      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: false,
          },
        },
        series: {
          pointWidth: 280,
        },
      },
      series: [
        {
          name: "Productive",
          data: [
            this.state.selectedEmp && this.state.selectedEmp.productiveindex,
          ],
          color: "#38A437",
        },
        {
          name: "Unproductive",
          data: [
            this.state.selectedEmp && this.state.selectedEmp.unproductiveindex,
          ],
          color: "#D61201",
        },
      ],
    };

    const filterColumn1 = () => {
      this.setState({ col1: !this.state.col1 });
    };
    const filterColumn2 = () => {
      this.setState({ col2: !this.state.col2 });
    };
    const filterColumn3 = () => {
      this.setState({ col3: !this.state.col3 });
    };
    const filterColumn4 = () => {
      this.setState({ col4: !this.state.col4 });
    };
    const filterColumn5 = () => {
      this.setState({ col5: !this.state.col5 });
    };
    const filterColumn6 = () => {
      this.setState({ col6: !this.state.col6 });
    };

    const onTimeZoneChange = (timezone) => {
      let z = this.state.today.toLocaleString("en-US", {
        timeZone: timezone.value,
      });
      this.setState(
        { selectedDate: new Date(z), selectedTimezone: timezone.value },
        () => {
          let date = moment(this.state.selectedDate).format("YYYY-MM-DD");
          this.fetchActivityIndex(date);
          window.history.pushState(
            {},
            null,
            `/activity-moniter/?employee=${this.state.selectedEmp
            }&date=${moment(this.state.startDate).format(
              "YYYY-MM-DD"
            )}&timezone=${timezone.value}`
          );
        }
      );
    };

    let arrayProductive = [];
    let arrayUnproductive = [];
    let arrayUndefined = [];
    let arrayDates = [];

    let prodArr =
      this.state.graphData == undefined || this.state.graphData == ""
        ? ""
        : this.state.graphData.map((data) => {
          if (data == "") {
            console.log("Empty Data");
          } else {
            arrayProductive.push(parseInt(data.productive / 60));
          }
          if (data == "") {
            console.log("Empty Data");
          } else {
            arrayUnproductive.push(parseInt(data.unproductive / 60));
          }
          if (data == "") {
            console.log("Empty Data");
          } else {
            //   arrayUndefined.push(parseInt(data.undefined / 60));
          }
          if (data == "") {
            console.log("error");
          } else {
            arrayDates.push(moment(data.date).format("ddd"));
          }
        });

    const productivitychart = {
      chart: {
        type: "area",
        height: 250,
        zoomType: "x",
        style: {
          fontFamily: "Lato",
          fontSize: "15px",
        },
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: t("average"),
        },
      },

      xAxis: {
        categories: arrayDates,
        crosshair: true,
      },
      tooltip: {
        valueSuffix: "Hr",
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          showInLegend: true,
          color: "#684D94",
          name: t("ReportsTab.productive"),
          data: arrayProductive,
        },
        {
          showInLegend: true,
          color: "#9279b9",
          name: t("ReportsTab.unproductive"),
          data: arrayUnproductive,
        },
        // {
        //     showInLegend: true,
        //     color: "#E6D6F3",
        //     name: t("ReportsTab.undefined"),
        //     data: arrayUndefined,
        // },
      ],
    };

    const nextWeekprodGraph = () => {
      let startdate = this.state.endDategraph;
      let endDate = new Date(startdate.getTime() + 7 * 24 * 60 * 60 * 1000);
      // this.setState(
      //   { startDategraph: startdate, endDategraph: endDate },
      //   () => {
      this.props
        .fetchEmployeeWebsiteReport(
          moment(startdate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD"),
          this.state.selectedEmployee
        )
        .then((data) => {
          this.setState({
            graphData: data.value.data.data,
            productivitygraphloading: false,
            startDategraph: startdate,
            endDategraph: endDate,
          });
        });
      //   }
      // );
    };

    const lastWeekprodGraph = () => {
      let startdate = new Date(
        this.state.startDategraph.getTime() - 7 * 24 * 60 * 60 * 1000
      );
      let endDate = this.state.startDategraph;
      // this.setState(
      //   { startDategraph: startdate, endDategraph: endDate },
      //   () => {
      this.props
        .fetchEmployeeWebsiteReport(
          moment(startdate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD"),
          this.state.selectedEmployee
        )
        .then((data) => {
          this.setState({
            graphData: data.value.data.data,
            productivitygraphloading: false,
            startDategraph: startdate,
            endDategraph: endDate,
          });
        });
      //   }
      // );
    };

    return (
      <Layout>
        <div className="col-span-2 p-5 bg-white rounded shadow-md">
          <div className="flex items-center justify-between">
            <div className="text-textprimary text-lg font-semibold">
              {t("ReportsTab.productivity")}
            </div>
            <div className="text-sm text-textprimary flex">
              <SelectField
                onSelectChange={(e) => {
                  this.handleSelectEmployeeProductivitygraph(e);
                }}
                name={"Select Employee"}
                arrayOfData={listofAllEmployeesgraph}
                id={"name"}
              ></SelectField>
            </div>
          </div>

          <div className="flex justify-end items-center mt-2">
            <button
              className=""
              onClick={() => {
                lastWeekprodGraph();
              }}
            >
              <ChevronLeftIcon className="text-gray-600 h-5 w-5" />
            </button>
            <p className="mx-1 text-gray-500 text-sm">
              {moment(this.state.startDategraph).format("YYYY-MM-DD")}{" "}
              <span className="mx-1 text-md">to</span>{" "}
              {moment(this.state.endDategraph).format("YYYY-MM-DD")}
            </p>
            <button
              className=""
              onClick={() => {
                nextWeekprodGraph();
              }}
            >
              <ChevronRightIcon className="text-gray-600 h-5 w-5" />
            </button>
          </div>

          {this.state.productivitygraphloading ? (
            <div className="text-center h-64 w-full flex justify-center items-center">
              <BeatLoader
                color={"#684994"}
                productivitygraphloading={this.state.productivitygraphloading}
                size={15}
                margin={2}
              />
            </div>
          ) : this.state.selectedEmployee !== "" ? (
            <div className="mt-6">
              {" "}
              <ReactHighcharts config={productivitychart}>
                {" "}
              </ReactHighcharts>{" "}
            </div>
          ) : (
            <div className="text-center mt-16">
              <img
                alt=""
                className="m-auto"
                src={require("../../images/nodata.png")}
              />
              <div className="text-lg mt-2 text-textprimary font-semibold">
                {t("please")} {t("select")} {t("an")} {t("employee")}
              </div>
            </div>
          )}
        </div>
        {/* top container selecte emp/timezone/date */}
        <div className="shadow-md rounded-md bg-white px-2">
          <div
            className={`flex flex-col lg-m:items-stretch lg:flex-row p-2 gap-2 mt-8 bg-primary rounded-t ${this.state.showbanner == false ? "rounded-b" : ""
              } justify-evenly items-center`}
          >
            <p className={`text-white mx-2`}>
              {t("activityMonitorModule.websAndAppUsage")}
            </p>
            <VerticalDivier />

            <div className="mx-2 w-52 lg-m:w-[97%]">
              <SelectField
                onSelectChange={(e) => this.onSelectEmployee(e)}
                name={"Select Employee"}
                arrayOfData={Select_emp_dropdown}
                id={"name"}
              // value={this.state.empName == "" ? "" : this.state.empName}
              ></SelectField>
            </div>
            <VerticalDivier />

            <div className="flex items-center">
              <Menu as="div" className="relative ">
                <div>
                  <Menu.Button className="max-w-md p-1 flex items-center lg:items-stretch text-sm rounded-full">
                    <span className="sr-only">Open user menu</span>
                    <DownloadIcon className="h-6 w-6 text-white" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  {this.state.showUserURL || this.state.selectedEmp != "" ? (
                    <Menu.Items className="origin-top-right relative lg:absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white cursor-pointer">
                      <Menu.Item
                        onClick={() => {
                          userSpecificReportPDF();
                        }}
                      >
                        {({ active }) => (
                          <p
                            className={classNames(
                              active
                                ? "bg-primary text-white"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm "
                            )}
                          >
                            {t("activityMonitorModule.exportAs")}{" "}
                            {t("activityMonitorModule.pdf")}
                          </p>
                        )}
                      </Menu.Item>
                      {/* <Menu.Item onClick={() => {exportUserSpecificCSV()}}>
                                            {({ active }) => (
                                                    <p className={classNames(active ? 'bg-primary text-white' : 'text-gray-700','block px-4 py-2 text-sm ')}>
                                                        Export as CSV
                                                    </p>
                                            )}
                                        </Menu.Item> */}
                    </Menu.Items>
                  ) : (
                    <Menu.Items className="origin-top-right relative lg:absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white cursor-pointer">
                      <Menu.Item
                        onClick={() => {
                          exportPDF();
                        }}
                      >
                        {({ active }) => (
                          <p
                            className={classNames(
                              active
                                ? "bg-primary text-white"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm "
                            )}
                          >
                            {t("activityMonitorModule.exportAs")}{" "}
                            {t("activityMonitorModule.pdf")}
                          </p>
                        )}
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          exportCSV();
                        }}
                      >
                        {({ active }) => (
                          <p
                            className={classNames(
                              active
                                ? "bg-primary text-white"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm "
                            )}
                          >
                            {t("activityMonitorModule.exportAs")}{" "}
                            {t("activityMonitorModule.csv")}
                          </p>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  )}
                </Transition>
              </Menu>
            </div>
            <div style={{ display: "none" }}>
              <CSVLink
                {...CSVReport}
                data={
                  this.state.filteredDataTable == undefined
                    ? []
                    : this.state.filteredDataTable
                }
                id="csv"
                style={{ display: "none" }}
              ></CSVLink>
            </div>
            <div style={{ display: "none" }}>
              <CSVLink
                {...userSpecificCSVReport}
                data={dataUsrCSV == undefined ? [] : dataUsrCSV && dataUsrCSV}
                id="csvuser"
                style={{ display: "none" }}
              ></CSVLink>
            </div>

            <VerticalDivier />

            <div className="w-52  lg-m:w-[97%] mx-2">
              <TimezoneSelect
                placeholder="Select Timezone"
                styles={{
                  borderRadius: "5px",
                  outline: "none",
                  border: "none",
                }}
                labelStyle={{ color: "pink", borderRadius: "20px" }}
                value={this.state.selectedTimezone}
                timezones={{ ...allTimezones }}
                onChange={(e) => {
                  this.setState({ selectedTimezone: e.value });
                  onTimeZoneChange(e);
                }}
              />
            </div>
            <VerticalDivier />

            <div className="w-52  lg-m:w-[97%] mx-2 md:mx-3 flex items-center relative">
              <DatePicker
                className="rounded-md w-52  lg-m:w-full text-black cursor-pointer"
                selected={this.state.startDate}
                value={this.state.startDate}
                startDate={this.state.startDate}
                onChange={(date) => {
                  this.setState({ startDate: date }, () => {
                    window.history.pushState(
                      {},
                      null,
                      `/activity-moniter/?employee=${this.state.empName
                      }&date=${moment(this.state.startDate).format(
                        "YYYY-MM-DD"
                      )}&timezone=${this.state.selectedTimezone}`
                    );
                    this.props
                      .fetchActivityIndex(
                        moment(this.state.startDate).format("YYYY-MM-DD")
                      )
                      .then((data) => {
                        if (data.value.status == 200) {
                          this.setState({
                            filteredDataTable: data.value.data,
                          });
                        }
                      });
                  });
                }}
              />
              <CalendarIcon className="absolute right-2 top-2 w-6"></CalendarIcon>
            </div>
          </div>
          {this.state.showbanner ? (
            <div className="p-4 flex flex-col justify-between items-center sm:flex-row">
              <p className="text-tiny mb-3 sm:mb-0">
                {t("activityMonitorModule.gotItText")}
              </p>
              <p
                className="text-secondary rounded-md font-semibold cursor-pointer p-1 hover:bg-green-100"
                onClick={() => {
                  this.setState({ showbanner: false });
                }}
              >
                {t("activityMonitorModule.gotItBTN")}{" "}
              </p>
            </div>
          ) : null}
        </div>

        {this.state.showUserURL ? (
          // User URLs visited container
          <div className="mt-6">
            <div className="flex justify-between">
              <div
                className="shadow-md py-2 px-4 rounded-md bg-white cursor-pointer hover:bg-gray ease-in-out "
                onClick={() => {
                  this.setState({ showUserURL: false, selectedEmp: "" });
                }}
              >
                Back
              </div>
              <div
                className="bg-primary p-2 rounded-md text-white font-normal cursor-pointer"
                onClick={() => {
                  this.setState({ showAllUrlsModal: true });
                }}
              >
                View All URLs
              </div>
            </div>

            {this.state.selectedEmp == "" ? (
              <div className="bg-white shadow-md rounded-lg p-4 mt-6">
                <p className="text-xl font-normal">
                  {t("activityMonitorModule.visitedUrlText")}{" "}
                </p>
              </div>
            ) : (
              <>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 mt-6 shadow-md rounded-md items-start">
                  <div className="bg-white rounded-md shadow-md h-full px-4 py-2">
                    <p className="text-gray-400 text-base">
                      {t("activityMonitorModule.totalTimeTracked")}
                    </p>
                    <p className="text-2xl font-semibold mt-2">
                      {this.convertMintoHr(
                        this.state.selectedEmp == undefined
                          ? ""
                          : this.state.selectedEmp.onlinetime
                      )}
                    </p>
                  </div>

                  <div className="bg-white rounded-md shadow-md h-full px-4 py-2">
                    <p className="text-gray-400 text-base">
                      {t("activityMonitorModule.idleMins")}
                    </p>
                    <p className="text-2xl font-semibold mt-2">
                      {this.convertMintoHr(
                        this.state.selectedEmp == undefined
                          ? ""
                          : this.state.selectedEmp.idletime
                      )}
                    </p>
                  </div>
                  {/* <VerticalDivier color={"black"} height={"10"}/> */}

                  <div className="bg-white rounded-md shadow-md h-full px-4 py-2">
                    <p className="text-gray-400 text-base">
                      {t("activityMonitorModule.productiveTime")}
                    </p>
                    <p className="text-2xl font-semibold mt-2">
                      {this.convertMintoHr(
                        this.state.selectedEmp == undefined
                          ? ""
                          : this.state.selectedEmp.productiontime
                      )}
                    </p>
                    <div className="flex items-center justify-center mt-3">
                      <p className="text-tiny font-light text-gray-400 mr-4">
                        {this.state.selectedEmp == undefined
                          ? ""
                          : this.state.selectedEmp.productiveindex.toFixed()}
                        %
                      </p>
                      <ProgressBar
                        data={
                          this.state.selectedEmp == null
                            ? 0
                            : this.state.selectedEmp.productiveindex
                        }
                        className="translate-y-6"
                      />
                    </div>
                  </div>
                  {/* <VerticalDivier color={"black"} height={"10"}/> */}

                  <div className="bg-white rounded-md shadow-md h-full px-4 py-2">
                    <p className="text-gray-400 text-base">
                      {t("activityMonitorModule.unProductiveTime")}
                    </p>
                    <p className="text-2xl font-semibold mt-2">
                      {this.convertMintoHr(
                        this.state.selectedEmp == undefined
                          ? ""
                          : this.state.selectedEmp.idletime
                      )}
                    </p>
                    <div className="flex items-center justify-center mt-3">
                      <p className="text-tiny font-light text-gray-400 mr-4">
                        {this.state.selectedEmp == undefined
                          ? ""
                          : this.state.selectedEmp.unproductiveindex.toFixed()}
                        %
                      </p>
                      <ProgressBar
                        data={
                          this.state.selectedEmp == null
                            ? 0
                            : this.state.selectedEmp.unproductiveindex
                        }
                        className="translate-y-6"
                      />
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow-md rounded-md mt-8 p-4">
                  <p className="text-gray-400 font-medium">
                    {t("activityMonitorModule.topUsedWebsiteAndApps")}
                  </p>
                  {isFetchingUserUrls ? (
                    <div>Loading...</div>
                  ) : errorFetchingActivityIndex == true ? (
                    <div className="text-xl font-semibold mx-5 my-5">
                      {t("noDataFound")}
                    </div>
                  ) : (
                    <div
                      className="mt-2 flex flex-col overflow-y-auto bg-gray-100"
                      style={{ maxHeight: "auto" }}
                    >
                      <div className="inline-block min-w-full align-middle">
                        <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                          <table
                            className="min-w-full border-separate"
                            style={{ borderSpacing: 0 }}
                          >
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12"
                                >
                                  {t("title")}
                                </th>

                                <th
                                  scope="col"
                                  className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                >
                                  {t("activityMonitorModule.URL")}
                                </th>

                                <th
                                  scope="col"
                                  className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                                >
                                  {t("requestsModule.duration")}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              {listofUserURLs == undefined ? (
                                <tr>
                                  <td className="text-black text-2xl ">
                                    Loading{t("loading")}...
                                  </td>
                                </tr>
                              ) : (
                                listofUserURLs &&
                                listofUserURLs.map((el, personIdx) => (
                                  <tr key={personIdx}>
                                    <td
                                      className={classNames(
                                        personIdx !== listofUserURLs.length - 1
                                          ? "border-b border-gray-200"
                                          : "",
                                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                      )}
                                    >
                                      <p className="flex flex-1 items-center text-ellipsis sm:break-words text-tiny">
                                        <PencilIcon
                                          className="h-4 w-4 mr-4 cursor-pointer"
                                          onClick={() => {
                                            this.setState({
                                              showUpdateURlTypeModal: true,
                                              selectedURL: {
                                                title: el.title,
                                                url: el.url,
                                              },
                                            });
                                          }}
                                        />{" "}
                                        {el.title.length > 20
                                          ? el.title.slice(0, 55) + "...."
                                          : el.title}
                                      </p>
                                    </td>
                                    <td
                                      className={classNames(
                                        personIdx !== listofUserURLs.length - 1
                                          ? "border-b border-gray-200"
                                          : "",
                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                                      )}
                                    >
                                      <a
                                        href={el.url}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="break-words text-blue-500 hover:underline"
                                      >
                                        {listofUserURLs == ""
                                          ? "N/A"
                                          : el.url.length > 30
                                            ? el.url.slice(0, 55) + "......"
                                            : el.url}
                                      </a>
                                    </td>
                                    <td
                                      className={classNames(
                                        personIdx !== listofUserURLs.length - 1
                                          ? "border-b border-gray-200"
                                          : "",
                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                                      )}
                                    >
                                      {listofUserURLs == ""
                                        ? "N/A"
                                        : el.duration}
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            {/* Trends container */}
            <div className="shadow-md mt-6 bg-white rounded-md">
              <div className="flex justify-between items-center p-4">
                <p className="text-lg font-semibold">
                  {t("activityMonitorModule.trends")}
                </p>
                <p
                  onClick={() => {
                    this.setState({ showTrends: !this.state.showTrends });
                  }}
                  className="cursor-pointer"
                >
                  {this.state.showTrends
                    ? t("hide") + " " + t("activityMonitorModule.trends")
                    : t("show") + " " + t("activityMonitorModule.trends")}
                </p>
              </div>
              <div className="h-0.5 w-full bg-gray-200"></div>
              {this.state.showTrends == false ? (
                <div className="p-4 text-gray-500">
                  {this.state.selectedEmp != ""
                    ? t("activityMonitorModule.trendTextToShowGraph")
                    : t("activityMonitorModule.trendText")}
                </div>
              ) : this.state.selectedEmp == "" ? null : (
                <div>
                  <ReactHighcharts config={stackedChart}></ReactHighcharts>
                </div>
              )}
            </div>

            {/* Activity table container */}
            <div className="mt-6 shadow-md">
              <div className="bg-white px-4 py-5 mt-4 border-b border-gray-200 sm:px-5 rounded-lg">
                <div
                  onClick={() => {
                    this.setState({ showFilterTable: true });
                  }}
                  className="flex justify-end items-center font-semibold mb-2 cursor-pointer"
                >
                  <SelectorIcon className="h-5 w-5" />{" "}
                  {t("activityMonitorModule.column")}
                </div>
                <div
                  className="mt-2 flex flex-col overflow-y-auto bg-gray-100"
                  style={{ maxHeight: "auto" }}
                >
                  <div className="inline-block min-w-full align-middle">
                    <div className="shadow-sm ring-1 ring-black ring-opacity-5 max-h-[580px] overflow-auto">
                      <table
                        className="min-w-full border-separate"
                        style={{ borderSpacing: 0 }}
                      >
                        <thead className="bg-gray-50 sticky top-0 z-10">
                          <tr>
                            {this.state.col1 && (
                              <th
                                scope="col"
                                className="border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-12"
                              >
                                {t("name")}
                              </th>
                            )}
                            {this.state.col2 && (
                              <th
                                scope="col"
                                className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                              >
                                {t("activityMonitorModule.totalComputerTime")}
                              </th>
                            )}
                            {this.state.col3 && (
                              <th
                                scope="col"
                                className="hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                              >
                                {t("ReportsTab.productive")}
                              </th>
                            )}
                            {this.state.col4 && (
                              <th
                                scope="col"
                                className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                              >
                                % {t("ReportsTab.productive")}
                              </th>
                            )}
                            {this.state.col5 && (
                              <th
                                scope="col"
                                className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                              >
                                {t("ReportsTab.unproductive")}
                              </th>
                            )}
                            {this.state.col6 && (
                              <th
                                scope="col"
                                className="border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                              >
                                % {t("ReportsTab.unproductive")}
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {listofActivityIndex == undefined ? (
                            <tr>
                              <td className="text-black text-2xl ">
                                {t("loading")}...
                              </td>
                            </tr>
                          ) : !this.state.filteredDataTable ? (
                            <tr>
                              <td
                                className="text-black text-xl text-center"
                                colSpan={6}
                              >
                                No Data Available For User
                              </td>
                            </tr>
                          ) : (
                            this.state.filteredDataTable &&
                            this.state.filteredDataTable?.sort((a, b) => a.username.localeCompare(b.username)).map(
                              (person, personIdx) => (
                                <tr key={personIdx}>
                                  {this.state.col1 && (
                                    <td
                                      className={classNames(
                                        personIdx !==
                                          this.state.filteredDataTable.length -
                                          1
                                          ? "border-b border-gray-200"
                                          : "",
                                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-12"
                                      )}
                                    >
                                      <div className="flex">
                                        {this.state.filteredDataTable == "" ? (
                                          "No Data Available For User"
                                        ) : (
                                          <p
                                            className="cursor-pointer text-primary"
                                            onClick={() => {
                                              // this.redirectToUserURLs(
                                              //   person.username,
                                              //   person.email
                                              // );
                                              ProjectLink(person);
                                            }}
                                          >
                                            {person.username}
                                          </p>
                                        )}
                                      </div>
                                    </td>
                                  )}
                                  {this.state.col2 && (
                                    <td
                                      className={classNames(
                                        personIdx !==
                                          this.state.filteredDataTable.length -
                                          1
                                          ? "border-b border-gray-200"
                                          : "",
                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                                      )}
                                    >
                                      {this.state.filteredDataTable == ""
                                        ? "N/A"
                                        : this.convertMintoHr(
                                          person.onlinetime
                                        )}
                                    </td>
                                  )}
                                  {this.state.col3 && (
                                    <td
                                      className={classNames(
                                        personIdx !==
                                          this.state.filteredDataTable.length -
                                          1
                                          ? "border-b border-gray-200"
                                          : "",
                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                                      )}
                                    >
                                      {this.state.filteredDataTable == ""
                                        ? "N/A"
                                        : this.convertMintoHr(
                                          person.productiontime
                                        )}
                                    </td>
                                  )}
                                  {this.state.col4 && (
                                    <td
                                      className={classNames(
                                        personIdx !==
                                          this.state.filteredDataTable.length -
                                          1
                                          ? "border-b border-gray-200"
                                          : "",
                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                      )}
                                    >
                                      {
                                        <ProgressBar
                                          data={
                                            this.state.filteredDataTable == ""
                                              ? 0
                                              : person.productiveindex
                                          }
                                        />
                                      }
                                    </td>
                                  )}
                                  {this.state.col5 && (
                                    <td
                                      className={classNames(
                                        personIdx !==
                                          this.state.filteredDataTable.length -
                                          1
                                          ? "border-b border-gray-200"
                                          : "",
                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                      )}
                                    >
                                      {this.state.filteredDataTable == ""
                                        ? "N/A"
                                        : this.convertMintoHr(
                                          person.onlinetime -
                                          person.productiontime
                                        )}
                                    </td>
                                  )}
                                  {this.state.col6 && (
                                    <td
                                      className={classNames(
                                        personIdx !==
                                          this.state.filteredDataTable.length -
                                          1
                                          ? "border-b border-gray-200"
                                          : "",
                                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                      )}
                                    >
                                      {
                                        <ProgressBar
                                          data={
                                            this.state.filteredDataTable
                                              ? person.unproductiveindex
                                              : 0
                                          }
                                        />
                                      }
                                    </td>
                                  )}
                                </tr>
                              )
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {this.state.showAllUrlsModal ? (
          <ViewAllUrls_modal
            open={this.state.showAllUrlsModal}
            onCancelClick={() => {
              this.setState({ showAllUrlsModal: false });
            }}
          />
        ) : null}

        {this.state.showUpdateURlTypeModal ? (
          <UpdateURLtype_modal
            open={this.state.showUpdateURlTypeModal}
            onCancelClick={() => {
              this.setState({ showUpdateURlTypeModal: false });
            }}
            data={this.state.selectedURL}
          />
        ) : null}
        {this.state.showFilterTable ? (
          <FilterTable_model
            open={this.state.showFilterTable}
            onCancelClick={() => {
              this.setState({ showFilterTable: false });
            }}
            data={{
              col1: this.state.col1,
              col2: this.state.col2,
              col3: this.state.col3,
              col4: this.state.col4,
              col5: this.state.col5,
              col6: this.state.col6,
            }}
            filterColumn1={() => {
              filterColumn1();
            }}
            filterColumn2={() => {
              filterColumn2();
            }}
            filterColumn3={() => {
              filterColumn3();
            }}
            filterColumn4={() => {
              filterColumn4();
            }}
            filterColumn5={() => {
              filterColumn5();
            }}
            filterColumn6={() => {
              filterColumn6();
            }}
          />
        ) : null}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  allEmployees: state.projects,
  activityIndex: state.activityIndex,
  dashboard: state.dashboard,
});

const mapDispatchToProps = (dispatch) => ({
  fetchallEmployees: () => dispatch(fetchallEmployees()),
  fetchActivityIndex: (date) => dispatch(fetchActivityIndex(date)),
  fetchAllUserURLs: (date, uu_id) => dispatch(fetchAllUserURLs(date, uu_id)),
  fetchEmployeeWebsiteReport: (startDate, endDate, userId) =>
    dispatch(fetchEmployeeWebsiteReport(startDate, endDate, userId)),
  getEmpListObserver: () => dispatch(getEmpListObserver()),
});

ActivityMoniter.propTypes = {};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(ActivityMoniter)
);
