/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import SelectField from "../../Components/SelectField";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "@heroicons/react/outline";
import moment from "moment";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import { JsonForms } from "@jsonforms/react";
import { materialRenderers } from "@jsonforms/material-renderers";
import { connect } from "react-redux";
import { updateActiveTask, getProjectCustomFields } from "../../services/projects/actions";

function UpdateTaskModal(props) {
    const {
        onCancelClick,
        onSubmitClick,
        submitButtonName,
        cancelButtonName,
        onTaskFieldChange,
        open,
        refreshList,
        selectedTaskData,
        statusArray,
        priorityArray,
        ...rest
    } = props;
    const cancelButtonRef = useRef(null);

    const [taskStartDate, setTaskStartDate] = useState(
        new Date(
            selectedTaskData.start_date == undefined ||
            selectedTaskData.start_date == "" ||
            selectedTaskData.start_date == null
                ? new Date()
                : selectedTaskData.start_date
        )
    );
    const [taskEndDate, setTaskEndDate] = useState(new Date());
    const [team, setTeam] = useState(selectedTaskData.team);
    const [taskName, setTaskName] = useState(selectedTaskData.name);
    const [username, setUsername] = useState("");
    const [taskNote, setTaskNote] = useState(selectedTaskData.note);
    const [taskStatus, setTaskStatus] = useState(selectedTaskData.task_status);
    const [taskPriority, setTaskPriority] = useState(selectedTaskData.priority);
    const [taskFiles, setTaskFiles] = useState("");
    const [taskUserAssigned, setTaskUserAssigned] = useState("");
    const [project, setProject] = useState("");
    const [ProjectData, setProjectData] = useState([]);
    const [ProjectDataId, setProjectDataId] = useState([]);

    const [TeamData, setTeamData] = useState([]);
    const [customFields, setCustomFields] = useState("");
    const [customFieldData, setCustomFieldData] = useState("");
    var files = [];
    var files_array = [];

    const { t } = useTranslation("common");
    useEffect(() => {
        if (
            props.selectedTaskData &&
            props.selectedTaskData.username &&
            props.selectedTaskData.username.length > 0 &&
            props.ProjectDataId
        ) {
            const projectName = props.selectedTaskData.project
                ? props.selectedTaskData.project.toLowerCase()
                : "default";
            setProject(projectName);
            setCustomFieldData(props.selectedTaskData.customField);
            setTaskStartDate(new Date(props.selectedTaskData.start_date));
            setTaskEndDate(new Date(props.selectedTaskData.end_date));
            setTaskStatus(props.selectedTaskData.task_status);
            setTaskPriority(props.selectedTaskData.priority);
            setTeam(props.selectedTaskData.team ? props.selectedTaskData.team : "default");
            setProjectDataId(props.ProjectDataId);
            for (let proj of props.ProjectDataId) {
                if (projectName.toLowerCase() === proj.name.toLowerCase()) {
                    props.getProjectCustomFields(proj.id).then((res) => {
                        const custFields = res.value.data;
                        let properties = {};
                        if (Object.keys(custFields).length > 0) {
                            for (let field of Object.keys(custFields)) {
                                properties[field] = {
                                    type: "string",
                                    enum: custFields[field].split(","),
                                };
                            }
                            setCustomFields({
                                type: "object",
                                properties: properties,
                            });
                        }
                    });
                    break;
                }
            }
            setTimeout(() => {
                handleUserAssigned(
                    typeof props.selectedTaskData.username !== "string"
                        ? props.selectedTaskData.username.join(",")
                        : props.selectedTaskData.username
                );
            }, 200);
        }
    }, [props.selectedTaskData]);
    const handleProjectChange = (e) => {
        setProject(e.target.value);
        for (let project of ProjectDataId) {
            if (project.name.toLowerCase() === e.target.value.toLowerCase()) {
                props.getProjectCustomFields(project.id).then((res) => {
                    const custFields = res.value.data;
                    let properties = {};
                    if (Object.keys(custFields).length > 0) {
                        for (let field of Object.keys(custFields)) {
                            properties[field] = {
                                type: "string",
                                enum: custFields[field].split(","),
                            };
                        }
                        setCustomFields({
                            type: "object",
                            properties: properties,
                        });
                    }
                });
                break;
            }
        }
    };
    const handleTeamChange = (e) => {
        setTeam(e.target.value);
    };

    const handleUserAssigned = (value) => {
        if (!value) {
            setProjectData([]);
            setProjectDataId([]);
        } else {
            props.getUserBasedProjects(value.split(",")[1]).then((res) => {
                setProjectData(
                    res.value.data.map((project) => {
                        return { name: project, id: project.toLowerCase() };
                    })
                );
            });
            props.getUserBasedTeams(value.split(",")[1]).then((res) => {
                setTeamData(
                    res.value.data.data.map((team) => {
                        return { name: team.name, id: team.team_uuid };
                    })
                );
                if (team) {
                    for (let teamt of res.value.data.data) {
                        if (team.toLowerCase() === teamt.name.toLowerCase()) {
                            setTeam(teamt.team_uuid);
                            break;
                        }
                    }
                }
            });
        }
        setTaskUserAssigned(value);
    };

    const handleStatusChange = (e) => {
        setTaskStatus(e.target.value);
    };

    const handlePriorityChange = (e) => {
        setTaskPriority(e.target.value);
    };

    const updateTask = () => {
        const formdata = new FormData();
        formdata.append("id", selectedTaskData.id);
        if (taskName && taskName !== "undefined") {
            formdata.append("task", taskName);
        }
        if (project && project !== "undefined") {
            formdata.append("project", project);
        }
        if (team && team !== "undefined") {
            for (let val of TeamData) {
                if (val.id === team) {
                    formdata.append("team", val.name);
                    break;
                }
            }
        }
        if (taskStartDate && taskStartDate !== "undefined") {
            formdata.append("start_date", moment(taskStartDate).format("YYYY-MM-DD"));
        }
        if (taskEndDate && taskEndDate !== "undefined") {
            formdata.append("end_date", moment(taskEndDate).format("YYYY-MM-DD"));
        }
        if (taskNote && taskNote !== "undefined") {
            formdata.append("note", taskNote);
        }
        if (taskStatus && taskStatus !== "undefined") {
            formdata.append("task_status", taskStatus);
        }
        if (taskPriority && taskPriority !== "undefined") {
            formdata.append("priority", taskPriority);
        }
        if (taskFiles && taskFiles !== "undefined") {
            formdata.append("files", taskFiles);
        }
        if (taskUserAssigned && taskUserAssigned !== "undefined") {
            formdata.append("userAssigned", taskUserAssigned.split(",")[0]);
            formdata.append("username", taskUserAssigned.split(",")[0]);
        }
        for (let val in customFieldData) {
            if (customFieldData[val] !== val) {
                formdata.append("keys", val);
                formdata.append("values", customFieldData[val]);
            }
        }
        props.updateActiveTask(formdata).then((res) => {
            if (res.value.status === 200) {
                toast.success("Task Updated Successfully");
                onCancelClick();
                refreshList();
            } else {
                toast.error("Something went wrong");
            }
        });
    };

    const handleImages = (e) => {
        files.push(e.target.files);
        for (var i = 0; i < files.length; i++) {
            files_array.push(files[0][i]);
        }
        setTaskFiles(files_array);
    };
    return (
        <Transition.Root show={open} as={Fragment} {...rest}>
            <Dialog
                as="div"
                className="fixed z-30 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                onClose={onCancelClick}>
                <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
                            <div className="flex">
                                <div className="bg-[#F3F4F6] p-6">
                                    <p className="text-lg font-semibold">
                                        {t("update")} {t("task")}
                                    </p>
                                    <p className="mt-4 text-md">
                                        {t("task")} {t("name")}
                                    </p>
                                    <input
                                        onChange={(e) => {
                                            setTaskName(e.target.value);
                                        }}
                                        type="text"
                                        className="border rounded-sm w-full"
                                        defaultValue={selectedTaskData.name}
                                    />
                                    <div className="mt-3">
                                        <p className="text-md">{t("task")} Description</p>
                                        <textarea
                                            rows={3}
                                            className="border w-full"
                                            onChange={(e) => {
                                                setTaskNote(e.target.value);
                                            }}
                                            defaultValue={selectedTaskData.note}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                handleImages(e);
                                            }}
                                            multiple={true}
                                        />
                                    </div>
                                    <div>
                                        {taskFiles == ""
                                            ? "No Images"
                                            : taskFiles.map((el, idx) => <img src={el} alt="" />)}
                                    </div>
                                    <div className="mt-3">
                                        <p>Assigned {t("employee")}</p>
                                        <div className="z-50 flex-shrink-0">
                                            {/* <Select
                                            value={props.selectedusers}
                                            onChange={(e) => {handleUserAssigned(e)}}
                                            options={props.useroptions}
                                            isMulti
                                        /> */}

                                            <SelectField
                                                onSelectChange={(e) => {
                                                    handleUserAssigned(e.target.value);
                                                    console.log(e.target.value)
                                                    console.log(taskUserAssigned)
                                                }}
                                                name={`${t("select")}` + ` ${t("employee")}`}
                                                arrayOfData={props.employeeData}
                                                value={taskUserAssigned}
                                                id={"name"}

                                                ></SelectField>
                                        </div>
                                    </div>
                                    <div className="flex justify-between mt-2">
                                        <div className="mr-2">
                                            <p className="text-md">Start {t("date")}</p>
                                            <div className="w-48 flex items-center">
                                                <DatePicker
                                                    selected={taskStartDate}
                                                    value={taskStartDate}
                                                    startDate={taskStartDate}
                                                    className={`rounded-md w-48 text-black cursor-pointer`}
                                                    onChange={(date) => {
                                                        setTaskStartDate(date);
                                                    }}
                                                />
                                                <CalendarIcon className="-ml-10 z-20 w-6"></CalendarIcon>
                                            </div>
                                        </div>
                                        <div>
                                            <p className="text-md">End {t("date")}</p>
                                            <div className="w-48 flex items-center">
                                                <DatePicker
                                                    className="rounded-md w-48 text-black cursor-pointer"
                                                    selected={taskEndDate}
                                                    value={taskEndDate}
                                                    startDate={taskEndDate}
                                                    onChange={(date) => {
                                                        setTaskEndDate(date);
                                                    }}
                                                />
                                                <CalendarIcon className="-ml-10 z-10 w-6"></CalendarIcon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <div className="w-full h-[450px] max-h-[450px] overflow-y-auto py-2">
                                        <div className="px-6 py-2">
                                            <div className="mt-2 flex-shrink-0">
                                                <SelectField
                                                    onSelectChange={(e) => {
                                                        handleProjectChange(e);
                                                    }}
                                                    FieldName={`${t("project")}`}
                                                    name={"taskprojectname"}
                                                    value={project}
                                                    arrayOfData={ProjectData}
                                                    id={"project"}></SelectField>
                                            </div>

                                            <div className="mt-4 flex-shrink-0">
                                                <SelectField
                                                    onSelectChange={(e) => {
                                                        handleTeamChange(e);
                                                    }}
                                                    FieldName={`${t("team")}`}
                                                    name={"taskteamname"}
                                                    arrayOfData={TeamData}
                                                    value={team}
                                                    id={"tean"}></SelectField>
                                            </div>
                                        </div>
                                        <div className="h-[2px] w-full bg-gray-200 " />
                                        <div className="p-6">
                                            <div className="flex-shrink-0">
                                                <SelectField
                                                    onSelectChange={(e) => {
                                                        handleStatusChange(e);
                                                    }}
                                                    FieldName={`${t("status")}`}
                                                    name={"taskprojectname"}
                                                    arrayOfData={props.statusArray}
                                                    value={taskStatus ? taskStatus : ""}
                                                    id={"project"}></SelectField>
                                            </div>

                                            <div className="mt-2 flex-shrink-0">
                                                <SelectField
                                                    onSelectChange={(e) => {
                                                        handlePriorityChange(e);
                                                    }}
                                                    FieldName={"Priority"}
                                                    name={"taskteamname"}
                                                    arrayOfData={props.priorityArray}
                                                    value={taskPriority ? taskPriority : ""}
                                                    id={"tean"}></SelectField>
                                            </div>
                                        </div>
                                        {customFields && customFields.properties && (
                                            <div className="h-[2px] w-full bg-gray-200 " />
                                        )}
                                        {customFields && customFields.properties && (
                                            <div className="px-6 py-2">
                                                <div>Custom Fields</div>
                                                {/* <div key={index + index} className="py-2">
                                                <div className="flex gap-4 pb-3 items-center">
                                                    <div className="text-gray-400">
                                                        Key&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </div>
                                                    <InputField
                                                        value={field.key}
                                                        className="focus:border-none focus:outline-none"
                                                        onInputChange={(e) => {
                                                            let fields = [...customFields];
                                                            fields[index]["key"] = e.target.value;
                                                            setCustomFields(fields);
                                                        }}
                                                    />
                                                </div>
                                                <div className="flex gap-4 items-center">
                                                    <div className="text-gray-400">Value</div>
                                                    <InputField
                                                        value={field.value}
                                                        className="focus:border-none focus:outline-none"
                                                        onInputChange={(e) => {
                                                            let fields = [...customFields];
                                                            fields[index]["value"] = e.target.value;
                                                            setCustomFields(fields);
                                                        }}
                                                    />
                                                </div>
                                            </div> */}
                                                {customFields.properties && (
                                                    <JsonForms
                                                        renderers={materialRenderers}
                                                        schema={customFields}
                                                        data={customFieldData}
                                                        onChange={({ data }) =>
                                                            setCustomFieldData(data)
                                                        }
                                                    />
                                                )}
                                                {/* <button
                                            className="flex gap-2 text-primary whitespace-nowrap flex-nowrap items-center"
                                            onClick={() => {
                                                let fields = [...customFields];
                                                fields.push({ key: "", value: "" });
                                                setCustomFields(fields);
                                            }}>
                                            <PlusIcon className="w-4 h-4" />
                                            <div>Add Activity</div>
                                        </button> */}
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex justify-end items-center px-6 pt-2 mb-3">
                                        <button
                                            className="p-1 px-3 rounded-md border border-gray-300 mr-3"
                                            onClick={onCancelClick}>
                                            {t("requestsModule.cancel")}
                                        </button>
                                        <button
                                            className="p-1 px-3 rounded-md bg-primary text-white"
                                            onClick={() => {
                                                updateTask();
                                            }}>
                                            {t("update")}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* <div className='p-6'>
                                <div className="sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-bold text-textprimary">{props.editMode ? "Create Task" : "Create Task"}</Dialog.Title>
                                    </div>
                                    <div className='mt-8'>
                                        <Dialog.Description>
                                            <InputField
                                                onInputChange={onTaskFieldChange}
                                                FieldName={"Name"}
                                                name={"taskname"}
                                                type={"text"}
                                                disabled={props.editMode ? true : false}
                                                value={props.tasknamevalue}
                                                id={"name"}
                                                placeholder={""}>
                                            </InputField>

                                        
                                            <div className="mt-4 flex-shrink-0">
                                                <SelectField
                                                    onSelectChange={props.onEmployeeHandleChange}
                                                    FieldName={"Employee"}
                                                    defaultValue={props.userDefaultValue}
                                                    name={"taskemployeename"}
                                                    arrayOfData={props.employeeData}
                                                    id={"employee"}
                                                >
                                                </SelectField>
                                            </div>

                                        
                                            <div className="mt-4 flex-shrink-0">
                                                <SelectField
                                                    onSelectChange={props.onProjectHandleChange}
                                                    FieldName={"Project"}
                                                    name={"taskprojectname"}
                                                    arrayOfData={props.projectData}
                                                    defaultValue={props.projectDefaultValue}
                                                    id={"project"}
                                                >
                                                </SelectField>
                                            </div>

                                        
                                            <div className="mt-4 flex-shrink-0">
                                                <SelectField
                                                    onSelectChange={props.onTeamHandleChange}
                                                    FieldName={"Team"}
                                                    name={"taskteamname"}
                                                    arrayOfData={props.teamData}
                                                    defaultValue={props.teamDefaultValue}
                                                    id={"tean"}
                                                >
                                                </SelectField>
                                            </div>

                                        </Dialog.Description>
                                        
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={onSubmitClick}
                                    >
                                        {submitButtonName}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={onCancelClick}
                                        ref={cancelButtonRef}
                                    >
                                        {cancelButtonName}
                                    </button>
                                </div>
                            </div> */}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    updateActiveTask: (data) => dispatch(updateActiveTask(data)),
    getProjectCustomFields: (id) => dispatch(getProjectCustomFields(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateTaskModal);
