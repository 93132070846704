import { API_ENDPOINTS } from '../../constants/index';
import { transport, padlocktransport } from '../../Networks/Auth';

export default {
    fetchEmpLoginDetail: () => {
        let config = {
            url: API_ENDPOINTS.EMP_LOGIN_DETAILS,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    fetchEmpDashboard: (date) => {
        let config = {
            url: API_ENDPOINTS.EMP_DASHBOARD_DETAILS + date,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    fetchEmpSummary: (date) => {
        let config = {
            url: API_ENDPOINTS.EMP_SUMMARY_DETAILS + date,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },

    createLeaveRequest: (data) => {
        let config = {
            url: API_ENDPOINTS.GET_ALL_REQUEST,
            method: "POST",
            data: data,
        };
        return transport(config);
    },

    deleteLeaveRequest: (data) => {
        let config = {
            url: API_ENDPOINTS.GET_ALL_REQUEST,
            method: "DELETE",
            data: data,
        };
        return transport(config);
    },

    getUserProject: () => {
        let config = {
            url: API_ENDPOINTS.GET_USER_PROJECTS,
            method: "GET",
            withCredentials: true,
        };
        return transport(config);
    },
    getDailyActivityList: (date) => {
        let config = {
            url: API_ENDPOINTS.GET_DAILY_ACTITVITY_LIST + date,
            method: "GET",
        };
        return transport(config);
    },
};